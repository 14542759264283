import { find } from "linkifyjs";

import type { TypedWrappedFieldProps } from "@js/forms/utils";

const isMailToLink = (link: string) => link.startsWith("mailto:");
export const getLinksFromText = (text: string) =>
  find(text)
    .map(({ href }) => href)
    .filter((link) => !isMailToLink(link));

export const getInitialExcludedLinks = ({
  textValue,
  linksFromTextValue,
}: {
  textValue?: string;
  linksFromTextValue: string[] | "";
}) => {
  if (!textValue) {
    return [];
  }

  const foundLinks = getLinksFromText(textValue);
  const linksRemovedByUser = foundLinks.filter(
    (foundLink) =>
      !linksFromTextValue || !linksFromTextValue.includes(foundLink),
  );

  return linksRemovedByUser;
};

export const getExcludedLinks = ({
  foundLinks,
  currentExcludedLinks,
}: {
  foundLinks: string[];
  currentExcludedLinks: string[];
}) => {
  if (!currentExcludedLinks.length) {
    return [];
  }

  // keep only the excluded links that still exist in the text value
  return currentExcludedLinks.filter((excludedLink) =>
    foundLinks.includes(excludedLink),
  );
};

export type Link = { value: string; id: string };

export const deduplicateLinks = (
  links: TypedWrappedFieldProps<Link[] | "">,
  foundLinks: string[],
  excludedLinksFromTextValue: string[],
): string[] => {
  const linksFromComponent = Array.isArray(links.input.value)
    ? links.input.value.map((link) => link.value)
    : [];

  const uniqueLinksFromText = [...new Set(foundLinks)];
  const isExcluded = (link: string) =>
    excludedLinksFromTextValue.includes(link);
  const isAlreadyAddedFromComponent = (link: string) =>
    linksFromComponent.includes(link);

  const filteredLinks = uniqueLinksFromText.filter(
    (link) => !isExcluded(link) && !isAlreadyAddedFromComponent(link),
  );

  return filteredLinks;
};
