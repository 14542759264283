import React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";

import { FRONTEND_ADMIN_URL } from "../apps/common/constants";
import { lazyWithRetry } from "../utils";

import { ACCESS_RULE } from "./constants";
import { ProtectedRoute } from "./protected-route";

const AdminDashboardWithdrawalsPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/withdrawals"
    ),
);
const AdminDashboardMoneyFlowPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/money-flow"
    ),
);

const AdminDashboardOfferReportPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/offer-report"
    ),
);
const AdminDashboardTalentsWithJobsReportPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/talents-with-jobs-report"
    ),
);
const AdminDashboardAllJobsReportPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/all-jobs-report"
    ),
);
const AdminDashboardCustomerInvoiceReportPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/customer-invoice-report"
    ),
);
const TokenTaxesReportPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/token-taxes-report"
    ),
);
const AdminDashboardReferralsReportPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/referrals-report"
    ),
);
const AdminDashboardTokenBalanceReportPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/token-balance-report"
    ),
);

const AdminDashboardRewardsPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "admin-views" */ "../apps/admin/views/rewards"),
);
const AdminDashboardRewardsAddRewardRulePage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/rewards/add"
    ),
);
const AdminDashboardRewardsRewardRulesPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/rewards/reward-rules"
    ),
);
const AdminDashboardRewardsRewardsPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/rewards/rewards"
    ),
);

const TokenVestingToolLayoutPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/token-vesting-tool"
    ),
);
const CreateTokenGrantPlanPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/token-vesting-tool/create-token-grant-plan"
    ),
);
const ListOfTokenGrantPlans = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/token-vesting-tool/list-of-token-grant-plans"
    ),
);
const TokenGrantsHistoryList = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/token-vesting-tool/token-grants-history-list"
    ),
);

const SuggestedCategoriesReport = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/suggested-categories-report"
    ),
);

const DepositReport = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-views" */ "../apps/admin/views/deposit-report"
    ),
);

const HelpOfferPaymentsReportPage = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "admin-views" */ "../apps/admin/views/help-offer-payments-report"
  );
  return { default: module.HelpOfferPaymentsReportPage };
});

const AdminRoutes = (
  <Route
    path={FRONTEND_ADMIN_URL}
    element={
      <ProtectedRoute rules={[ACCESS_RULE.AUTHENTICATED]}>
        <Outlet />
      </ProtectedRoute>
    }
  >
    <Route index element={<Navigate to="/" />} />
    <Route
      path="referrals_report"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_REFERRALS_REPORT]}>
          <AdminDashboardReferralsReportPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="deposit_report"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_DEPOSIT_REPORT]}>
          <DepositReport />
        </ProtectedRoute>
      }
    />
    <Route
      path="talents_with_jobs_report"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_TALENTS_WITH_JOBS_REPORT]}>
          <AdminDashboardTalentsWithJobsReportPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="offers_report"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_OFFER_REPORT]}>
          <AdminDashboardOfferReportPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="all_jobs_report"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_ALL_JOBS_REPORT]}>
          <AdminDashboardAllJobsReportPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="money_flow"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_MONEY_FLOW_DASHBOARD]}>
          <AdminDashboardMoneyFlowPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="rewards"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_REWARDS_CENTER]}>
          <AdminDashboardRewardsPage />
        </ProtectedRoute>
      }
    >
      <Route index element={<AdminDashboardRewardsAddRewardRulePage />} />
      <Route path="add" element={<AdminDashboardRewardsAddRewardRulePage />} />
      <Route
        path="rewards"
        element={<AdminDashboardRewardsRewardRulesPage />}
      />
      <Route path="granted" element={<AdminDashboardRewardsRewardsPage />} />
    </Route>
    <Route
      path="withdrawals"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_TOKEN_WITHDRAWALS]}>
          <AdminDashboardWithdrawalsPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="customer_invoice_report"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_CUSTOMER_INVOICE_REPORT]}>
          <AdminDashboardCustomerInvoiceReportPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="token_taxes_report"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.CAN_MANAGE_TOKEN_TAXES_REPORT]}>
          <TokenTaxesReportPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="token_balance_report"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_TOKEN_BALANCE_REPORT]}>
          <AdminDashboardTokenBalanceReportPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="token_vesting_tool"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_TOKEN_GRANT_PLANS]}>
          <TokenVestingToolLayoutPage />
        </ProtectedRoute>
      }
    >
      <Route
        path="create_token_grant_plan"
        element={
          <ProtectedRoute rules={[ACCESS_RULE.CAN_MANAGE_TOKEN_GRANT_PLANS]}>
            <CreateTokenGrantPlanPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="list_of_token_grant_plans"
        element={
          <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_TOKEN_GRANT_PLANS]}>
            <ListOfTokenGrantPlans />
          </ProtectedRoute>
        }
      />
      <Route
        path="token_grants_history_list"
        element={
          <ProtectedRoute rules={[ACCESS_RULE.CAN_VIEW_TOKEN_GRANT_PLANS]}>
            <TokenGrantsHistoryList />
          </ProtectedRoute>
        }
      />
    </Route>
    <Route
      path="suggested_categories_report"
      element={
        <ProtectedRoute
          rules={[ACCESS_RULE.CAN_VIEW_SUGGESTED_CATEGORIES_REPORT]}
        >
          <SuggestedCategoriesReport />
        </ProtectedRoute>
      }
    />
    <Route
      path="help_offer_payments_report"
      element={
        <ProtectedRoute
          rules={[ACCESS_RULE.CAN_VIEW_HELP_OFFER_PAYMENTS_REPORT]}
        >
          <HelpOfferPaymentsReportPage />
        </ProtectedRoute>
      }
    />
  </Route>
);

export default AdminRoutes;
