import type { StickerValue } from "@js/types/give-and-get-help";

const StickerValues: string[] = Object.values(ENUMS.StickerEnum);

const isStickerValue = (value: unknown): value is StickerValue =>
  typeof value === "string" && StickerValues.includes(value);

export const getHoveredSticker = (
  xPos: number,
  yPos: number,
): StickerValue | undefined => {
  const hoveredStickerElement = document
    .elementFromPoint(xPos, yPos)
    ?.closest(`[data-sticker]`);
  if (!hoveredStickerElement) {
    return;
  }

  const hoveredStickerValue =
    hoveredStickerElement.getAttribute("data-sticker");
  if (!isStickerValue(hoveredStickerValue)) {
    return;
  }

  return hoveredStickerValue;
};
