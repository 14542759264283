import { actionTypes } from "@js/apps/give-and-get-help/action-types";
import type { PostLocationType } from "@js/apps/give-and-get-help/types";
import type { AppThunkAction } from "@js/store";
import type { IPost } from "@js/types/give-and-get-help";

import { CreatePostModalContent } from "./create-post-modal-content";
import { EditPostModalContent } from "./edit-post-modal-content";
import {
  CreatePostModalInstance,
  EditPostModalInstance,
} from "./modal-instances";

type OpenClosePostModalPayload = {
  composer_location: PostLocationType;
  spaceId?: number;
};

export const openCreatePostModal =
  ({
    composer_location,
    spaceId,
  }: OpenClosePostModalPayload): AppThunkAction<void> =>
  (dispatch) => {
    dispatch({
      type: actionTypes["create/modal-opened"],
      payload: { composer_location },
    });

    CreatePostModalInstance.open({
      children: <CreatePostModalContent spaceId={spaceId} />,
    });
  };

export const openEditPostModal = (postId: number, initialValues: IPost) => {
  return EditPostModalInstance.open({
    closeButton: false,
    padding: false,
    disableEscapeKeyDown: true,
    keepOnBackdropClick: true,
    children: (
      <EditPostModalContent postId={postId} initialValues={initialValues} />
    ),
  });
};
