import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { useBanUserFromPostingMutation } from "@js/apps/auth/api";
import { useHasGroupPermission, useUser } from "@js/apps/common/hooks";
import { useReport } from "@js/apps/common/hooks/use-report";
import { actionTypes } from "@js/apps/give-and-get-help/action-types";
import {
  useDeletePostCommentMutation,
  useTrackSharePostMutation,
} from "@js/apps/give-and-get-help/api";
import { useIsCreator } from "@js/apps/give-and-get-help/hooks/is-creator";
import { useDeleteSpaceUserMutation } from "@js/apps/spaces/api";
import { useIsSpaceAdmin } from "@js/apps/spaces/hooks/use-is-space-admin";
import { Snackbar } from "@js/components/snackbar";
import {
  useAppDispatch,
  useAppSelector,
  useCopyToClipboard,
  useNavigate,
} from "@js/hooks";
import type { IPost, PostComment } from "@js/types/give-and-get-help";
import { castToInteger, typeGuard } from "@js/utils";

import { openEditPostModal } from "../../components/create-or-edit-post-modal/modal/open-modal";
import { usePostContext } from "../../context";
import { usePostLocationContext } from "../../context/post-location";
import { PostLocation } from "../../context/post-location";
import { useFixedDeletePostMutation } from "../use-fixed-delete-post-mutation";

import { setUserBanned } from "./actions";

type UseGetPostsActionsArg = {
  entity: IPost | PostComment;
  type: "post" | "comment" | "user";
  isCelebratoryPost?: boolean;
};

export const useGetPostsActions = ({
  entity,
  type,
  isCelebratoryPost,
}: UseGetPostsActionsArg) => {
  const { spaceId } = useParams();
  const navigate = useNavigate();
  const [trackSharePost] = useTrackSharePostMutation();
  const user = useUser();
  const [banUserFromPosting] = useBanUserFromPostingMutation();
  const authorId = entity.freelancer.user.id;
  const isBanned: boolean = useAppSelector(
    (state) => state.gghActionsMenu.banList[authorId] || false,
  );
  const { handleReportPost, handleReportPublicPost } = useReport();
  const [deletePost] = useFixedDeletePostMutation({ postId: entity.id });
  const [deleteComment] = useDeletePostCommentMutation();
  const dispatch = useAppDispatch();
  const postLocation = usePostLocationContext();
  const { isPublic } = usePostContext();
  const isSpaceContext = postLocation === PostLocation.Values.space;

  const isPostAuthor = authorId === user?.id;

  const { isSpaceAdmin } = useIsSpaceAdmin({
    spaceId: isSpaceContext ? castToInteger(spaceId) : undefined,
  });

  const [banSpaceUser] = useDeleteSpaceUserMutation();

  const memoizedURLCopiedCallback = useCallback(
    () => Snackbar.success("URL successfully copied!"),
    [],
  );
  const { handleCopy } = useCopyToClipboard(memoizedURLCopiedCallback);

  const isCreator = useIsCreator(entity);
  const isModerator = useHasGroupPermission(SETTINGS.GROUP.POST_MODERATORS);

  const isEntityPost = type === "post";

  const canDeletePost = isCreator || isModerator || isSpaceAdmin;
  const actionVisibility = {
    banUser: (isModerator || isSpaceAdmin) && !isPublic && !isPostAuthor,
    edit: (isCreator || isModerator) && !isPublic && !isCelebratoryPost,
    copyLink: !isPublic,
    report: !isCreator,
    delete: canDeletePost && !isPublic,
  };

  const _handleCopy = (text: string) => {
    if (user) {
      trackSharePost({
        id: entity.id,
        kind: ENUMS.SharePostEventKind.COPY_LINK,
      });
    }
    dispatch({
      type: actionTypes["copy-clicked"],
      payload: {
        entity,
        linkUrl: text,
        entityType: type,
        post_location: postLocation,
      },
    });
    handleCopy(text);
  };

  const handleMenuClick = () => {
    dispatch({
      type: actionTypes["menu-clicked"],
      payload: { entity, entityType: type, post_location: postLocation },
    });
  };

  const banUser = useCallback((): Promise<void> => {
    const banFunction = isSpaceContext
      ? banSpaceUser({
          memberId: authorId,
          spaceId: castToInteger(spaceId) as number,
        })
      : banUserFromPosting({
          userId: authorId,
          postId: entity.id,
        });

    return banFunction
      .unwrap()
      .then(() => {
        Snackbar.success("User banned successfully.");
        dispatch(setUserBanned(authorId));
      })
      .catch(() => Snackbar.error("Banning user failed."));
  }, [
    entity,
    isSpaceContext,
    banUserFromPosting,
    banSpaceUser,
    dispatch,
    authorId,
    spaceId,
  ]);

  const report = () => {
    dispatch({
      type: actionTypes["report-clicked"],
      payload: { entity, entityType: type, post_location: postLocation },
    });

    const reportHandler = isPublic
      ? handleReportPublicPost({ id: entity.id, type })
      : handleReportPost({ id: entity.id, type });

    reportHandler();
  };

  const deleteUserPost = useCallback((): Promise<unknown> => {
    // side effect for that action: useHandleDeleteSinglePost
    if (type === "comment") {
      return deleteComment({
        id: entity.id,
        parent_id: entity.parent_id as number,
      })
        .unwrap()
        .catch(() => Snackbar.error("Something went wrong."));
    }

    return deletePost({ id: entity.id })
      .unwrap()
      .then(() => {
        Snackbar.success("Post deleted successfully.");

        if (
          typeGuard<IPost | PostComment, IPost>(entity, "space") &&
          entity.space &&
          postLocation === PostLocation.Values.single_space_post_view
        ) {
          navigate(`/spaces/${entity.space}`, { replace: true });
          return;
        }

        if (postLocation === PostLocation.Values.single_post_view) {
          navigate("/feed", { replace: true });
        }
      })
      .catch(() => Snackbar.error("Something went wrong."));
  }, [entity, deletePost, deleteComment, navigate, type, postLocation]);

  const editPost = (handleEditClick?: () => void) => {
    if (isEntityPost) {
      openEditPostModal(entity.id, entity as IPost);
    } else if (handleEditClick) {
      handleEditClick();
    }
  };

  return {
    /** Doesn't re-fetch user's ban status, uses state that is set to true after `banUser` is called */
    isBanned,
    isPostAuthor,
    banUser,
    report,
    deleteUserPost,
    handleCopy: _handleCopy,
    handleMenuClick,
    editPost,
    actionVisibility,
  };
};
