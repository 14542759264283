import {
  FiltersBottomActionsContainer,
  FiltersBottomContainer,
  FiltersCarouselContainer,
  FiltersClearAllButton,
  ResultsCount,
} from "@js/apps/common/components/filters/components";
import { FiltersCarousel } from "@js/apps/common/components/filters/components/filters-carousel";
import { SEARCH_FILTER_DATA_TYPES } from "@js/apps/common/components/filters/constants";
import {
  RoleFilter,
  SkillsFilter,
} from "@js/apps/common/components/filters/fields";
import {
  CommitmentFilter,
  JobTypeFilter,
  LocationFilter,
  RateFilter,
} from "@js/apps/jobs/apps/listing/fields";
import { useResetQueryExceptUTM } from "@js/hooks/use-reset-query-except-utm";

export const PublicJobListingFiltersForm = ({
  total,
  isAnyFilterApplied,
}: {
  total: number;
  isAnyFilterApplied: boolean;
}) => {
  const { resetQueryExceptUTM } = useResetQueryExceptUTM();

  const clearFilters = () => {
    resetQueryExceptUTM();
  };

  return (
    <>
      <FiltersCarouselContainer>
        <FiltersCarousel items={FILTER_ITEMS_FOR_PUBLIC_JOBS_LISTING} />
      </FiltersCarouselContainer>
      <FiltersBottomContainer>
        <FiltersBottomActionsContainer>
          <ResultsCount
            dataType={SEARCH_FILTER_DATA_TYPES.jobs}
            count={total}
          />
          {isAnyFilterApplied && (
            <FiltersClearAllButton onClick={clearFilters} />
          )}
        </FiltersBottomActionsContainer>
      </FiltersBottomContainer>
    </>
  );
};

const FILTER_ITEMS_FOR_PUBLIC_JOBS_LISTING = [
  <RoleFilter key="role-filter" />,
  <SkillsFilter key="skills-filter" />,
  <RateFilter key="rate-filter" />,
  <LocationFilter key="location-filter" />,
  <CommitmentFilter key="commitment-filter" />,
  <JobTypeFilter key="job-type-filter" />,
];
