import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useNavigate } from "@js/hooks";

export const SignUpBYOTCongrats = () => {
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="column" maxWidth={500} gap={2} p={2}>
      <Typography component="p" variant="title">
        Step 3 - Congrats!
      </Typography>
      <Button
        variant="primary"
        onClick={() => {
          navigate("/");
        }}
      >
        Complete checklist
      </Button>
    </Box>
  );
};
