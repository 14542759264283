import type { Space } from "@js/types/spaces";

import { JoinThisSpaceModalContent } from "./join-this-space-modal-content";
import { JoinThisSpaceModalInstance } from "./join-this-space-modal-instance";

import styles from "./styles.module.scss";

export const openJoinThisSpaceModal = (space: Space) => {
  JoinThisSpaceModalInstance.open({
    children: <JoinThisSpaceModalContent space={space} />,
    padding: false,
    className: styles.modal,
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "small",
      className: styles.closeButton,
    },
  });
};
