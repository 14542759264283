import type { ComponentPropsWithoutRef } from "react";

import { RoundedBox } from "@hexocean/braintrust-ui-components";
import type { Job } from "@js/types/jobs";

import {
  ContractTypeDetail,
  DeadlineDetail,
  LocationDetail,
  TimezoneDetail,
  WorkTimeDetail,
} from "../details";

type JobDetailsBasicDetailsProps = {
  job: Job;
  boxProps: ComponentPropsWithoutRef<typeof RoundedBox>;
};

export const JobDetailsBasicDetails = ({
  job,
  boxProps,
}: JobDetailsBasicDetailsProps) => {
  return (
    <RoundedBox {...boxProps}>
      <LocationDetail locations={job.locations} variant="details" />
      <TimezoneDetail
        timezones={job.timezones}
        timezoneOverlap={job.timezone_overlap}
        variant="details"
      />
      <WorkTimeDetail
        expectedHoursPerWeek={job.expected_hours_per_week}
        variant="details"
        shortened
      />
      <ContractTypeDetail job={job} variant="details" />
      <DeadlineDetail job={job} variant="details" />
    </RoundedBox>
  );
};
