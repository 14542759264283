import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../store";
import type { HelpOffer } from "../../types/give-and-get-help";

export type OnrampSate = {
  orderId: string | null;
  wasNotConfirmed: boolean;
  offer: HelpOffer | null;
  isPollingForWalletStatusEnabled: boolean;
  isCreatingWalletModalOpen: boolean;
};

const initialState: OnrampSate = {
  orderId: null,
  wasNotConfirmed: false,
  offer: null,
  isPollingForWalletStatusEnabled: false,
  isCreatingWalletModalOpen: false,
};

const onrampSlice = createSlice({
  name: "onramp",
  initialState,
  reducers: {
    setWasNotConfirmed(state, action: PayloadAction<boolean>) {
      state.wasNotConfirmed = action.payload;
    },

    setOnrampOrderId(state, action: PayloadAction<string | null>) {
      state.orderId = action.payload;
    },

    setOnrampOffer(state, action: PayloadAction<HelpOffer | null>) {
      state.offer = action.payload;
    },

    setIsPollingForWalletStatusEnabled(state, action: PayloadAction<boolean>) {
      state.isPollingForWalletStatusEnabled = action.payload;
    },

    setIsCreatingWalletModalOpen(state, action: PayloadAction<boolean>) {
      state.isCreatingWalletModalOpen = action.payload;
    },
  },
});

export const selectOnrampId = (state: RootState) => {
  return state.onramp.orderId;
};

export const selectOnrampWasConfirmed = (state: RootState) => {
  return state.onramp.wasNotConfirmed;
};

export const selectOnrampOffer = (state: RootState) => {
  return state.onramp.offer;
};

export const selectIsPollingForWalletStatusEnabled = (state: RootState) => {
  return state.onramp.isPollingForWalletStatusEnabled;
};

export const selectIsCreatingWalletModalOpen = (state: RootState) => {
  return state.onramp.isCreatingWalletModalOpen;
};

export const {
  setOnrampOrderId,
  setWasNotConfirmed,
  setOnrampOffer,
  setIsPollingForWalletStatusEnabled,
  setIsCreatingWalletModalOpen,
} = onrampSlice.actions;

export default onrampSlice.reducer;
