import React from "react";
import cs from "classnames";

import type { CheckboxFieldProps } from "@js/forms/fields/checkbox";
import { CheckboxField } from "@js/forms/fields/checkbox";

import styles from "./styles.module.scss";

type FiltersEnhancedCheckboxProps = CheckboxFieldProps;

export const FiltersEnhancedCheckbox = ({
  className,
  ...rest
}: FiltersEnhancedCheckboxProps) => {
  return (
    <CheckboxField className={cs(styles.checkboxCont, className)} {...rest} />
  );
};
