import { useCallback, useEffect, useState } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import {
  FILE_UPLOAD_STATE_STATUS,
  FileDropzone,
  FileDropzonePlaceholderDashed,
  getFileWithId,
  useFieldDeleteUpload,
  useUploadFiles,
  validateFilesCount,
} from "@js/apps/file-upload";

import type {
  PostFileAttachmentPreviewData,
  UploadedPostFileAttachmentData,
} from "../post-attachments/model";

type PostFilesUploadFieldProps = {
  shouldShowUploader: boolean;
  onlyImages?: boolean;
} & TypedWrappedFieldProps<
  Array<PostFileAttachmentPreviewData | UploadedPostFileAttachmentData>
>;

const uploadType = ENUMS.UploadType.POST_ATTACHMENT_UPLOAD_TYPE;

export const PostFilesUploadField = ({
  input: { onChange, value },
  meta: { error, submitFailed },
  shouldShowUploader,
  onlyImages,
}: PostFilesUploadFieldProps) => {
  const { uploadFiles, fileUploadsState, errors, deleteFile, isUploading } =
    useUploadFiles();
  const [filesValidationError, setFilesValidationError] = useState<
    string | null
  >(null);

  const onDrop = useCallback(
    (files: File[]) => {
      setFilesValidationError(null);

      if (!files.length) {
        return;
      }

      const validateFilesCountError = validateFilesCount({
        newFiles: files,
        currentFilesCount: value?.length ?? 0,
        maxFiles: 3,
      });

      if (validateFilesCountError) {
        setFilesValidationError(validateFilesCountError);
        return;
      }

      const filesWithIds = files.map((file) => getFileWithId(file));

      const mappedLoadingFiles = filesWithIds.map((fileWithId) => {
        return {
          fileId: fileWithId.fileId,
          name: fileWithId.name,
          size: fileWithId.size,
          isImage: fileWithId.type.includes("image"),
          isLoading: true,
        };
      });

      onChange([...(value ?? []), ...mappedLoadingFiles]);

      uploadFiles({
        files: filesWithIds,
        uploadType,
      });
    },
    [uploadFiles, onChange, value],
  );

  useFieldDeleteUpload({ attachments: value, deleteFile, fileUploadsState });

  useEffect(() => {
    if (!value) {
      return;
    }

    const mappedValue = value
      .map((attachmentData) => {
        const isAttachmentUploaded = !!attachmentData.attachment;
        if (isAttachmentUploaded) {
          return attachmentData;
        }

        const uploadingAttachmentData =
          attachmentData as UploadedPostFileAttachmentData;

        const attachmentUploadState = fileUploadsState.find(
          (fileUploadState) =>
            fileUploadState.fileId === uploadingAttachmentData.fileId,
        );
        if (!attachmentUploadState) {
          return uploadingAttachmentData;
        }

        const isErrorOnUpload =
          attachmentUploadState.status === FILE_UPLOAD_STATE_STATUS.ERROR;
        if (isErrorOnUpload) {
          return null;
        }
        const isStillUploading =
          attachmentUploadState.status === FILE_UPLOAD_STATE_STATUS.UPLOADING;
        if (isStillUploading || !attachmentUploadState.file) {
          return uploadingAttachmentData;
        }

        const attachmentDataWithUploadedFile = {
          ...uploadingAttachmentData,
          isLoading: false,
          id: attachmentUploadState.file.id,
          attachment: attachmentUploadState.file.attachment,
        };

        return attachmentDataWithUploadedFile;
      })
      .filter(
        (attachmentData): attachmentData is UploadedPostFileAttachmentData =>
          Boolean(attachmentData),
      );

    onChange(mappedValue);
  }, [fileUploadsState, onChange, value]);

  const formError =
    submitFailed && typeof error === "string" ? error : undefined;

  if (!shouldShowUploader) {
    return <></>;
  }

  return (
    <FileDropzone
      dropzoneOptions={{
        onDrop,
        multiple: true,
        accept: onlyImages ? { "image/*": [] } : undefined,
        maxSize: SETTINGS.POST_FILE_SIZE_LIMIT,
      }}
      error={formError ?? filesValidationError ?? errors?.[0]}
    >
      {({ isDragActive, isFocused }) => (
        <FileDropzonePlaceholderDashed
          isFocused={isFocused}
          isDragActive={isDragActive}
          isLoading={isUploading}
          subtitle="Max size: 10MB"
          sx={{ py: "50px !important" }}
          title={
            <DropzoneTitle
              isDragActive={isDragActive}
              onlyImages={onlyImages}
            />
          }
        />
      )}
    </FileDropzone>
  );
};

const DropzoneTitle = ({
  isDragActive,
  onlyImages,
}: {
  isDragActive: boolean;
  onlyImages?: boolean;
}) => {
  return (
    <Typography
      component="label"
      size="large"
      sx={{ cursor: "pointer" }}
      color="dark-violet"
    >
      {isDragActive ? (
        <>Drop {onlyImages ? "images" : "files"} here...</>
      ) : (
        <>Drag {onlyImages ? "image" : "file"} to upload</>
      )}
    </Typography>
  );
};
