import { SubmissionError } from "redux-form";
import axios from "axios";

import { Snackbar } from "@js/components/snackbar";

import type { SchedulePromptFormData } from "../messenger/components";

import type { AccountInfoFormValues } from "./forms/account-info";

export const saveAccountInfo = (
  values: Partial<AccountInfoFormValues> & { place_id?: string },
) => {
  return new Promise((resolve, reject) =>
    axios
      .patch(`/api/account_settings/account_info/`, values)
      .then((response) => resolve(response.data))
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );
};

export const updateAccountInfo = (values: SchedulePromptFormData) => {
  return new Promise((resolve, reject) =>
    axios
      .patch(`/api/account_settings/account_info/`, values)
      .then((response) => resolve(response.data))
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );
};

export const deleteAccount = () => {
  return axios
    .post("/api/user/delete/")
    .then(() => {
      Snackbar.info("Please check your email to confirm account deleting.");
    })
    .catch((error) => {
      Snackbar.error(
        `Failed to delete your account. ${
          error.response.data._error ||
          error.response.data.detail ||
          "Unknown error."
        }`,
        { persist: true },
      );
    });
};
