import { Box } from "@hexocean/braintrust-ui-components";
import {
  LinkedInIcon,
  LinkIcon,
  XIcon,
} from "@hexocean/braintrust-ui-components/Icons";

type ShareMenuIconProps = {
  isMobile: boolean;
};

type ShareMenuItemIconProps = ShareMenuIconProps & {
  icon: JSX.Element;
  bgcolor: string;
};

export const ShareMenuLinkedInIcon = ({ isMobile }: ShareMenuIconProps) => (
  <ShareMenuItemIcon
    bgcolor="var(--soft-blue)"
    isMobile={isMobile}
    icon={
      <LinkedInIcon
        sx={{
          fontSize: isMobile ? 32 : 22,
          color: "#0A66C2",
        }}
      />
    }
  />
);

export const ShareMenuLinkIcon = ({ isMobile }: ShareMenuIconProps) => (
  <ShareMenuItemIcon
    bgcolor="var(--soft-green)"
    isMobile={isMobile}
    icon={<LinkIcon sx={{ fontSize: isMobile ? 28 : 20 }} />}
  />
);

export const ShareMenuXIcon = ({ isMobile }: ShareMenuIconProps) => (
  <ShareMenuItemIcon
    bgcolor="var(--soft-teal)"
    isMobile={isMobile}
    icon={
      <XIcon
        viewBox="-150 -150 1560 1595"
        sx={{
          color: "#000",
          fontSize: isMobile ? 30 : 20,
        }}
      />
    }
  />
);

const ShareMenuItemIcon = ({
  icon,
  bgcolor,
  isMobile,
}: ShareMenuItemIconProps) => {
  return (
    <Box
      borderRadius="50%"
      bgcolor={bgcolor}
      width={isMobile ? "48px" : "32px"}
      height={isMobile ? "48px" : "32px"}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {icon}
    </Box>
  );
};
