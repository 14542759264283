import type { AccountType } from "@js/types/auth";

import type { AvatarWithBadgeProps } from "../avatar-with-badge";
import { AvatarWithBadge } from "../avatar-with-badge";

export type UserAvatarProps = Omit<AvatarWithBadgeProps, "user"> & {
  hideBadge?: boolean;
  hideBorder?: boolean;
  user: AvatarWithBadgeProps["user"] & { freelancer_approved?: boolean };
};

export const UserAvatar = ({
  approved,
  user,
  hideBadge,
  alt,
  ...props
}: UserAvatarProps) => {
  const accountType: AccountType =
    "freelancer_approved" in user ? "freelancer" : "employer";

  const avatarUser = {
    ...user,
    account_type: accountType,
  };
  return (
    <AvatarWithBadge
      alt={alt || ""}
      approved={!hideBadge && (user?.freelancer_approved || approved)}
      user={avatarUser}
      {...props}
    />
  );
};
