import { useEffect } from "react";

import { useInitCoinbasePaymentQuery } from "@js/apps/on-ramp/api";
import { closeCreatingWalletModal } from "@js/apps/on-ramp/components/creating-wallet-modal/";
import {
  selectIsCreatingWalletModalOpen,
  selectIsPollingForWalletStatusEnabled,
  setIsPollingForWalletStatusEnabled,
} from "@js/apps/on-ramp/reducer";
import { useAppDispatch, useAppSelector } from "@js/hooks";

const POLLING_INTERVAL_IN_MS = 10_000;

const usePollWalletStatus = () => {
  const dispatch = useAppDispatch();

  const isPollingForWalletStatusEnabled = useAppSelector(
    selectIsPollingForWalletStatusEnabled,
  );

  const isCreatingWalletModalOpen = useAppSelector(
    selectIsCreatingWalletModalOpen,
  );

  const { data, isLoading, isError, error } = useInitCoinbasePaymentQuery(
    undefined,
    {
      pollingInterval: POLLING_INTERVAL_IN_MS,
      skip: !isPollingForWalletStatusEnabled,
    },
  );

  useEffect(() => {
    if (!isPollingForWalletStatusEnabled) return;

    const isWalletReady = data && !data.wallet_creation_in_progress;

    if (isWalletReady) {
      dispatch(setIsPollingForWalletStatusEnabled(false));
      closeCreatingWalletModal();
    }
  }, [isPollingForWalletStatusEnabled, data, dispatch]);

  useEffect(() => {
    if (isError) {
      console.error("Error initializing onramp: ", error);
    }
  }, [error, isError]);

  return {
    data,
    isLoading,
    isCreatingWalletModalOpen,
    isPollingForWalletStatusEnabled,
  };
};

export { POLLING_INTERVAL_IN_MS, usePollWalletStatus };
