import type { MouseEvent } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";
import _ from "underscore";

import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { AttachmentLinkIcon } from "@hexocean/braintrust-ui-components/Icons";
import { postFooterActionButtonClicked } from "@js/apps/give-and-get-help/actions";
import type { ReplyFooterActions } from "@js/apps/give-and-get-help/components";
import {
  useCommentContext,
  usePostContext,
  usePostLocationContext,
} from "@js/apps/give-and-get-help/context";
import { useDisableSpecificPostActions } from "@js/apps/give-and-get-help/hooks/use-disable-specific-post-actions";
import type { ReplyType } from "@js/apps/give-and-get-help/types";
import { useQueryParams } from "@js/hooks/query-params";
import { useAppDispatch } from "@js/hooks/redux";
import { assertUnreachable } from "@js/utils/typescript";

import type { ReplyOpenedField } from "../reply-form";

export type ReplyFormActionsProps = {
  isLoading: boolean;
  canGiveOffer: boolean;
  disabled?: boolean;
  replyType: ReplyType;
  isExpanded: boolean;
};

export const ReplyFormActions = ({
  replyType,
  isLoading,
  canGiveOffer,
  disabled,
  isExpanded,
}: ReplyFormActionsProps) => {
  const isMobile = useMediaQuery("sm");
  const buttonSize = isMobile || isExpanded ? "x-small" : "small";

  return (
    <Wrapper>
      {isExpanded && (
        <ReplyFooterActionButtons
          replyType={replyType}
          canGiveOffer={canGiveOffer}
        />
      )}
      <Button
        disabled={isLoading || disabled}
        variant="primary"
        size={buttonSize}
        shape="squared"
        type="submit"
        onClick={(ev: MouseEvent<HTMLElement>) => {
          const shouldStopSubmit = !isExpanded;
          if (shouldStopSubmit) {
            ev.preventDefault();
          }
        }}
      >
        Reply
      </Button>
    </Wrapper>
  );
};

type EditReplyFormActionsProps = Omit<ReplyFormActionsProps, "isExpanded"> & {
  onCancel: () => void;
};

export const EditReplyFormActions = ({
  isLoading,
  canGiveOffer,
  disabled,
  onCancel,
  replyType,
}: EditReplyFormActionsProps) => {
  return (
    <Wrapper>
      <ReplyFooterActionButtons
        replyType={replyType}
        canGiveOffer={canGiveOffer}
      />
      <Button
        variant="black-outlined"
        size="x-small"
        shape="squared"
        type="submit"
        disabled={isLoading || disabled}
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button variant="positive" size="x-small" shape="squared" type="submit">
        Save changes
      </Button>
    </Wrapper>
  );
};

type WrapperProps = {
  children: React.ReactNode;
};

const Wrapper = ({ children }: WrapperProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        gap: 1,
        justifyContent: "end",
        alignItems: "center",
      }}
    >
      {children}
    </Stack>
  );
};

type ReplyFooterActionButtonsProps = ReplyActionsButtonsWithContextProps;

export const ReplyFooterActionButtons = (
  props: ReplyFooterActionButtonsProps,
) => {
  switch (props.replyType) {
    case "reply-to-post": {
      return <ReplyActionButtonsWithPostContext {...props} />;
    }
    case "reply-to-comment":
    case "edit-comment": {
      return <ReplyActionButtonsWithPostAndCommentContext {...props} />;
    }
    default: {
      assertUnreachable(props.replyType);
      return null;
    }
  }
};

type ReplyActionsButtonsWithContextProps = {
  replyType: ReplyType;
  canGiveOffer?: boolean;
};
const ReplyActionButtonsWithPostContext = (
  props: ReplyActionsButtonsWithContextProps,
) => {
  const dispatch = useAppDispatch();
  const postLocation = usePostLocationContext();
  const { hashtag } = useQueryParams();
  const postContext = usePostContext();

  const contextData = {
    post: postContext?.postData,
  };

  const handleClick = (buttonName: ReplyFooterActions) => {
    dispatch(
      postFooterActionButtonClicked({
        mode: props.replyType,
        name: buttonName,
        post_location: postLocation,
        hashtag_id: hashtag,
        ...contextData,
      }),
    );
  };

  return (
    <Field
      name="openedFields"
      component={ReplyActions}
      handleClick={handleClick}
      {...props}
    />
  );
};

const ReplyActionButtonsWithPostAndCommentContext = (
  props: ReplyActionsButtonsWithContextProps,
) => {
  const dispatch = useAppDispatch();
  const postLocation = usePostLocationContext();
  const commentContext = useCommentContext();
  const postContext = usePostContext();
  const { hashtag } = useQueryParams();

  const contextData = {
    comment: commentContext?.comment,
    post: postContext?.postData,
  };

  const handleClick = (buttonName: ReplyFooterActions) => {
    dispatch(
      postFooterActionButtonClicked({
        mode: props.replyType,
        name: buttonName,
        post_location: postLocation,
        hashtag_id: hashtag,
        ...contextData,
      }),
    );
  };

  return (
    <Field
      name="openedFields"
      component={ReplyActions}
      handleClick={handleClick}
      {...props}
    />
  );
};

type ReplyActionsProps = {
  canGiveOffer?: boolean;
  handleClick: (buttonName: ReplyFooterActions) => void;
} & TypedWrappedFieldProps<"" | ReplyOpenedField[]>;

const ReplyActions = ({
  handleClick,
  canGiveOffer,
  input,
}: ReplyActionsProps) => {
  const disablePostActions = useDisableSpecificPostActions();

  const toggleFields = (fieldNames: ReplyOpenedField[]) => {
    const currentOpenedFields = input.value || [];
    const areFieldsOpened = fieldNames.every((fieldName) =>
      currentOpenedFields.includes(fieldName),
    );
    if (areFieldsOpened) {
      input.onChange(
        currentOpenedFields.filter(
          (openedField) => !fieldNames.includes(openedField),
        ),
      );
    } else {
      input.onChange(_.uniq([...currentOpenedFields, ...fieldNames]));
    }
  };

  return (
    <>
      <AddAttachmentButton
        disabled={disablePostActions}
        onClick={() => handleClick("add-attachment-reply")}
        toggleFields={toggleFields}
      />
      {!!canGiveOffer && (
        <AddOfferButton
          disabled={disablePostActions}
          onClick={() => handleClick("add-offer")}
          toggleFields={toggleFields}
        />
      )}
    </>
  );
};

type AddAttachmentButtonProps = {
  onClick: () => void;
  toggleFields: (fieldNames: ReplyOpenedField[]) => void;
  disabled: boolean;
};

export const AddAttachmentButton = ({
  onClick,
  toggleFields,
  disabled,
}: AddAttachmentButtonProps) => {
  return (
    <Box sx={{ position: "relative", mr: -0.5 }}>
      <Tooltip title="Add an attachment" placement="top" arrow={false}>
        <IconButton
          aria-label="Add attachment"
          variant="transparent"
          size="medium"
          sx={{
            position: "absolute",
            transform: "translateY(-50%)",
            top: 0,
            right: 0,
            "&:hover": { backgroundColor: "var(--yellow)" },
            p: "8px !important;",
          }}
          onClick={() => {
            toggleFields(["attachment_file", "link"]);
            onClick();
          }}
          disabled={disabled}
        >
          <AttachmentLinkIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export const AddOfferButton = ({
  onClick,
  disabled,
  toggleFields,
}: AddAttachmentButtonProps) => {
  return (
    <Button
      aria-label="Add offer"
      variant="black-outlined"
      size="x-small"
      shape="squared"
      onClick={() => {
        toggleFields(["offer"]);
        onClick();
      }}
      disabled={disabled}
    >
      Add an offer
    </Button>
  );
};
