import type { FC, PropsWithChildren } from "react";

import {
  Divider,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  BraintrustIcon,
  InfoIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { getHelpOfferTotalBudget } from "@js/apps/give-and-get-help/utils/common";
import type { HelpOffer } from "@js/types/give-and-get-help";
import { formatTokenAmount, round } from "@js/utils";

import styles from "./styles.module.scss";

export const ReceiptDetails: FC<
  PropsWithChildren<{
    offer: HelpOffer;
  }>
> = ({ offer, children }) => {
  const isMobile = useMediaQuery("sm");
  const textSize = isMobile ? "small" : "medium";

  const budgetMoreThanFee = +offer.budget > +offer.fee;
  const missingTokens = offer.missing_tokens;
  const totalBudget = String(getHelpOfferTotalBudget(offer));
  const totalToBuy = Number(missingTokens) ? missingTokens : totalBudget;
  const totalDisplayed = getTotalDisplayed({ totalToBuy });

  return (
    <div className={styles.receiptDetails}>
      <Divider color="beige" />
      {budgetMoreThanFee && (
        <div className={styles.receiptDetailsRow}>
          <Typography component="p" variant="paragraph" size={textSize}>
            {offer.author.user.first_name_possessive} offer price
          </Typography>
          <Typography component="p" variant="paragraph" size={textSize}>
            <BraintrustIcon
              sx={{
                fontSize: "inherit",
                verticalAlign: "text-bottom",
              }}
            />{" "}
            {offer.budget} BTRST
          </Typography>
        </div>
      )}
      {budgetMoreThanFee && (
        <div className={styles.receiptDetailsRow}>
          <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
            <Typography component="p" variant="paragraph" size={textSize}>
              {SETTINGS.ONRAMP_PAYMENT_PERCENTAGE_FEE_RATE}% service fee
            </Typography>
            <Tooltip
              style={{ cursor: "pointer", verticalAlign: "middle" }}
              title="This fee is calculated from the offer price."
            >
              <InfoIcon
                style={{
                  color: "var(--dark-blue)",
                  fontSize: isMobile ? 20 : 24,
                }}
              />
            </Tooltip>
          </div>
          <Typography component="p" variant="paragraph" size={textSize}>
            <BraintrustIcon
              sx={{
                fontSize: "inherit",
                verticalAlign: "text-bottom",
              }}
            />{" "}
            {+offer.fee} BTRST
          </Typography>
        </div>
      )}
      <div className={styles.receiptDetailsRow}>
        <Typography component="p" variant="label" size={textSize}>
          {!!Number(missingTokens) ? "Total you need to buy" : "Total"}
        </Typography>
        <Tooltip title={totalToBuy}>
          <span>
            <Typography
              component="p"
              variant="label"
              size={textSize}
              sx={{ cursor: "default" }}
            >
              <BraintrustIcon
                sx={{
                  fontSize: "inherit",
                  verticalAlign: "text-bottom",
                }}
              />{" "}
              {totalDisplayed} BTRST
            </Typography>
          </span>
        </Tooltip>
      </div>
      {children}
      <Divider color="beige" />
    </div>
  );
};

const getTotalDisplayed = ({ totalToBuy }: { totalToBuy: string }): string => {
  return formatTokenAmount(round(totalToBuy, 0.001));
};
