import type { FC } from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { Emoji } from "@js/components/emoji";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import type { AppDispatch } from "@js/store";

export const InsightsSuccessModal: FC = () => {
  const isMobile = useMediaQuery("sm");

  return (
    <Box
      id="insight-success-modal"
      width={isMobile ? "90vw" : "629px"}
      maxWidth="629px"
      height="100%"
      padding={isMobile ? 2 : 4}
      boxSizing="border-box"
      display="flex"
      gap={2}
      flexDirection="column"
    >
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <IconButton
          variant="primary"
          size="x-small"
          aria-label="Close location mismatch modal"
          onClick={() => ModalInstance.close()}
          sx={{ border: "solid white 0.5rem", margin: "-1rem -1rem 0 0" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        display="flex"
        borderRadius={2}
        textAlign="center"
        sx={{
          background: "var(--medium-green)",
          "& img": {
            maxWidth: "100%",
          },
          margin: "-3rem 0 1rem 0",
          height: isMobile ? "100%" : "306.82px",
          width: isMobile ? "auto" : "565px",
        }}
      >
        <img
          src={`${SETTINGS.STATIC_URL}jobs/purchase-insight-success.svg`}
          width="100%"
          height="auto"
          alt="job-insight-image"
        />
      </Box>

      <Typography
        component="h6"
        variant="title"
        size="small"
        fontWeight={500}
        style={{
          verticalAlign: "baseline",
        }}
      >
        Application Insights is active{" "}
        <Emoji
          size="1.625rem"
          emoji="🙌"
          style={{
            lineHeight: "1.625rem",
          }}
        />
      </Typography>

      <Box>
        <Typography component="p" variant="paragraph" size="large">
          Find your perfect match and get personalized feedback for 30 days.
          View your purchase history{" "}
          <RouterLink
            to="/settings/purchases/"
            onClick={() => {
              ModalInstance.close();
            }}
            style={{
              textDecoration: "underline",
            }}
          >
            here.
          </RouterLink>
        </Typography>
      </Box>

      <Box textAlign="end" marginTop={2}>
        <Button
          variant="primary"
          shape="squared"
          RouterLink={RouterLink}
          to="jobs"
          onClick={() => {
            ModalInstance.close();
          }}
        >
          Browse jobs
        </Button>
      </Box>
    </Box>
  );
};

export const openSuccessPurchaseModal = (dispatch: AppDispatch): void => {
  ModalInstance.open({
    padding: false,
    keepOnBackdropClick: true,
    children: <InsightsSuccessModal />,
    onClose: () => {
      dispatch(fetchCurrentUser({ refetchInOtherTabs: true })).catch(
        () => null,
      );
    },
  });
};
