import type { SyntheticEvent } from "react";
import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import type { RatingProps } from "@hexocean/braintrust-ui-components";
import { Rating } from "@hexocean/braintrust-ui-components";

export type RatingPropsFieldProps = RatingProps &
  TypedWrappedFieldProps<number>;

const ReduxFormRatingFieldFactory =
  (Component: React.FC<React.PropsWithChildren<RatingProps>>) =>
  ({ input, meta: { error, touched }, ...props }: RatingPropsFieldProps) => {
    const onChange = (ev: SyntheticEvent) => {
      const target = ev.target as HTMLInputElement;
      input.onChange(target.value === null ? null : Number(target.value));
    };

    return (
      <Component
        {...props}
        {...input}
        onChange={onChange}
        error={touched && error}
      />
    );
  };

export const RatingField = ReduxFormRatingFieldFactory(Rating);
