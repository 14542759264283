import type { FreelancerLocationMismatch } from "@js/apps/admin/types";

import { LocationMismatchModalContent } from "./content";
import { LocationMismatchModalFooter } from "./footer";
import { LocationMismatchModalHeader } from "./header";

import styles from "../styles.module.scss";

export type LocationMismatchModalComponentProps = {
  user: FreelancerLocationMismatch["user"];
  billingLocationMismatch: FreelancerLocationMismatch["billing_location_mismatch"];
  lastActiveSessionMismatch: FreelancerLocationMismatch["last_active_session_mismatch"];
  billingLocation: FreelancerLocationMismatch["billing_location"];
  lastActiveSession: FreelancerLocationMismatch["last_active_session"];
  profileLocation: FreelancerLocationMismatch["profile_location"];
  onClose: () => void;
  onMessageUserClick: () => void;
};

export const LocationMismatchModalComponent = ({
  user,
  billingLocationMismatch,
  lastActiveSessionMismatch,
  billingLocation,
  lastActiveSession,
  profileLocation,
  onClose,
  onMessageUserClick,
}: LocationMismatchModalComponentProps) => {
  return (
    <div className={styles.modal}>
      <LocationMismatchModalHeader
        userFirstName={user.first_name}
        sessionLocationMismatch={!!lastActiveSessionMismatch}
        billingLocationMismatch={!!billingLocationMismatch}
        onClose={onClose}
      />
      <LocationMismatchModalContent
        profileLocation={profileLocation}
        billingLocation={billingLocation}
        lastActiveSession={lastActiveSession}
        billingLocationMismatch={billingLocationMismatch}
        lastActiveSessionMismatch={lastActiveSessionMismatch}
      />
      <LocationMismatchModalFooter
        user={user}
        onMessageUserClick={onMessageUserClick}
      />
    </div>
  );
};
