import { useMemo } from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { getResultsURL } from "@js/apps/universal-search/helpers";
import { useDebouncedUniversalSearchPhrase } from "@js/apps/universal-search/universal-search-slice";
import { RouterLink } from "@js/components/link";

import type { UniversalTabValue } from "../../constants";
import { useCloseSearchContext } from "../../context/close-search-context";

export type UniversalSearchEmptyCommunicationProps = {
  tabsWithData: {
    value: UniversalTabValue;
    name: string;
  }[];
  currentTabName: string;
};

export const UniversalSearchEmptyCommunication = ({
  tabsWithData,
  currentTabName,
}: UniversalSearchEmptyCommunicationProps) => {
  const searchPhrase = useDebouncedUniversalSearchPhrase();
  const handleCloseSearch = useCloseSearchContext();

  const tabsButtonsElement = useMemo(() => {
    const tabsComponents = tabsWithData.map((tab) => {
      const searchURL = getResultsURL(searchPhrase, tab.value);
      return (
        <RouterLink
          key={tab.value}
          to={searchURL}
          style={{ color: "var(--info-2)", textDecoration: "underline" }}
          onClick={handleCloseSearch}
        >
          {tab.name}
        </RouterLink>
      );
    });

    const tabsElement =
      tabsComponents.length === 2 ? (
        <>
          {tabsComponents[0]} and {tabsComponents[1]}.
        </>
      ) : (
        <>{tabsComponents[0]}.</>
      );

    return tabsElement;
  }, [tabsWithData, searchPhrase, handleCloseSearch]);

  return (
    <Typography
      component="span"
      variant="paragraph"
      size="medium"
      sx={{ display: "inline-block" }}
    >
      No results in {currentTabName}, but we did find something in{" "}
      <Typography
        component="span"
        variant="paragraph"
        size="medium"
        sx={{ whiteSpace: "nowrap" }}
      >
        {tabsButtonsElement}
      </Typography>
    </Typography>
  );
};
