import { z } from "zod";

import type { LocationValue } from "@js/components/autocomplete-new/google-places/types";

export const OnboardingOrganizationFieldsSchema = z.object({
  name: z.string(),
  organization_size: z.nativeEnum(ENUMS.OrganizationSize).optional(),
  industry_id: z.number().optional(),
  website: z.string().optional(),
  location_full: z.custom<LocationValue>().nullable().optional(),
});

export type OnboardingOrganizationFields = z.infer<
  typeof OnboardingOrganizationFieldsSchema
>;
