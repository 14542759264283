import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

import type { GetEmployerInvoicesParams } from "@js/apps/invoices/types";
import { processEmployerInvoicesParams } from "@js/apps/invoices/utils";
import { formatDate, isDateValid } from "@js/utils/date";
import { DateFormats } from "@js/utils/date/types";
import { parseListQueryParam } from "@js/utils/rtkq";

import type { EmployerInvoicesTableColumnField } from "../types";

export const EXPORT_CSV_RANGE_OPTION_VALUE = {
  PREVIOUS_7_DAYS: "PREVIOUS_7_DAYS",
  CURRENT_MONTH: "CURRENT_MONTH",
  PREVIOUS_MONTH: "PREVIOUS_MONTH",
  THIS_YEAR: "THIS_YEAR",
  ALL_TIME: "ALL_TIME",
  CUSTOM_RANGE: "CUSTOM_RANGE",
} as const;

export type ExportCSVRangeOptionValue = ObjectValues<
  typeof EXPORT_CSV_RANGE_OPTION_VALUE
>;

export const EXPORT_CSV_RANGE_OPTION_LABELS: Record<
  ExportCSVRangeOptionValue,
  string
> = {
  PREVIOUS_7_DAYS: "Previous 7 days",
  CURRENT_MONTH: "Current month",
  PREVIOUS_MONTH: "Previous month",
  THIS_YEAR: "This year",
  ALL_TIME: "All time",
  CUSTOM_RANGE: "Custom range",
};

export type ExportCSVDateRangeOption = {
  label: string;
  value: ExportCSVRangeOptionValue;
} & Required<Pick<GetEmployerInvoicesParams, "date_after" | "date_before">>;

export type ExportCSVCustomDateRange = Partial<
  Pick<ExportCSVDateRangeOption, "date_after" | "date_before">
>;

export const getExportCSVDateRangeOptions = (
  employerCreatedAt: string | null,
): ExportCSVDateRangeOption[] => {
  const today = dayjs().utc();
  const firstDayOfCurrentMonth = dayjs().utc().startOf("month");
  const firstDayOfPreviousMonth = dayjs()
    .utc()
    .subtract(1, "month")
    .startOf("month");

  const lastDayOfPreviousMonth = dayjs()
    .utc()
    .subtract(1, "month")
    .endOf("month");

  const firstDayOfCurrentYear = dayjs().utc().startOf("year");
  const employerJoinedDate = isDateValid(employerCreatedAt)
    ? dayjs(employerCreatedAt).utc()
    : today;

  const dateSevenDaysBefore = dayjs().utc().subtract(7, "days");

  const options = [
    {
      label: EXPORT_CSV_RANGE_OPTION_LABELS.PREVIOUS_7_DAYS,
      date_after: dateSevenDaysBefore,
      date_before: today,
      value: EXPORT_CSV_RANGE_OPTION_VALUE.PREVIOUS_7_DAYS,
    },
    {
      label: EXPORT_CSV_RANGE_OPTION_LABELS.CURRENT_MONTH,
      date_after: firstDayOfCurrentMonth,
      date_before: today,
      value: EXPORT_CSV_RANGE_OPTION_VALUE.CURRENT_MONTH,
    },
    {
      label: EXPORT_CSV_RANGE_OPTION_LABELS.PREVIOUS_MONTH,
      date_after: firstDayOfPreviousMonth,
      date_before: lastDayOfPreviousMonth,
      value: EXPORT_CSV_RANGE_OPTION_VALUE.PREVIOUS_MONTH,
    },
    {
      label: EXPORT_CSV_RANGE_OPTION_LABELS.THIS_YEAR,
      date_after: firstDayOfCurrentYear,
      date_before: today,
      value: EXPORT_CSV_RANGE_OPTION_VALUE.THIS_YEAR,
    },
    {
      label: EXPORT_CSV_RANGE_OPTION_LABELS.ALL_TIME,
      date_after: employerJoinedDate,
      date_before: today,
      value: EXPORT_CSV_RANGE_OPTION_VALUE.ALL_TIME,
    },
  ];

  return options.map((option) => {
    return {
      ...option,
      date_after: formatDate(option.date_after, DateFormats["1970-12-31"]),
      date_before: formatDate(option.date_before, DateFormats["1970-12-31"]),
    };
  });
};

export const formatCustomRangeDate = (
  date: string | undefined,
  format: DateFormats,
) => {
  return date && isDateValid(date) ? formatDate(date, format) : "...";
};

const COLUMNS_FOR_EXPORT_MAP: Partial<
  Record<EmployerInvoicesTableColumnField, string>
> = {
  date_from: "start_date",
  date_to: "end_date",
  item_amount: "line_item_amount",
  freelancer__user__email: "freelancer_email",
};

export const processColumnsParamForExport = (
  columns: EmployerInvoicesTableColumnField[],
) => {
  const mappedColumns = columns.map((column) => {
    const mappedColumn = COLUMNS_FOR_EXPORT_MAP[column] ?? column;

    return mappedColumn;
  });

  return parseListQueryParam(mappedColumns);
};

type GetSelectedDateRangeParamsArg = {
  selectedOption: ExportCSVRangeOptionValue;
  customRange: ExportCSVCustomDateRange | undefined;
  options: ExportCSVDateRangeOption[];
};
export const getSelectedDateRangeParams = ({
  options,
  selectedOption,
  customRange,
}: GetSelectedDateRangeParamsArg):
  | Pick<GetEmployerInvoicesParams, "date_after" | "date_before">
  | undefined => {
  if (
    selectedOption === EXPORT_CSV_RANGE_OPTION_VALUE.CUSTOM_RANGE &&
    customRange
  ) {
    return customRange;
  }

  const selectedOptionRange = options.find(
    (option) => option.value === selectedOption,
  );
  if (!selectedOptionRange) {
    return;
  }

  return {
    date_after: selectedOptionRange.date_after,
    date_before: selectedOptionRange.date_before,
  };
};

type GetDownloadInvoicesCSVParamsArg = {
  filters: GetEmployerInvoicesParams;
  columns: EmployerInvoicesTableColumnField[];
} & GetSelectedDateRangeParamsArg;

export const getDownloadInvoicesCSVParams = ({
  selectedOption,
  customRange,
  options,
  filters,
  columns,
}: GetDownloadInvoicesCSVParamsArg) => {
  const selectedDateRange = getSelectedDateRangeParams({
    selectedOption,
    customRange,
    options,
  });

  if (!selectedDateRange) {
    return;
  }

  const { date_before, date_after } = selectedDateRange;
  const filtersWithSelectedDateRange = {
    ...filters,
    date_after,
    date_before,
  };
  const params = processEmployerInvoicesParams(filtersWithSelectedDateRange);
  const columnsParam = processColumnsParamForExport(columns);

  delete params.page;

  return { ...params, columns: columnsParam, format: "csv" };
};

const getDateAfterFormat = ({
  dateAfter,
  dateBefore,
}: {
  dateBefore: Dayjs;
  dateAfter: Dayjs;
}) => {
  const shouldShowDateAfterYear =
    dateAfter.get("year") !== dateBefore.get("year");

  if (shouldShowDateAfterYear) {
    return DateFormats["Jan 1, 1970"];
  }

  return DateFormats["Jan 1"];
};

export const formatDateRange = ({
  date_before,
  date_after,
}: {
  date_before?: string;
  date_after?: string;
}) => {
  const dateAfter =
    date_after && isDateValid(date_after) ? dayjs.utc(date_after) : undefined;
  const dateBefore =
    date_before && isDateValid(date_before)
      ? dayjs.utc(date_before)
      : undefined;

  if (dateAfter && dateBefore) {
    const dateAfterFormatted = formatDate(
      dateAfter,
      getDateAfterFormat({ dateAfter, dateBefore }),
    );

    const dateBeforeFormatted = formatDate(
      dateBefore,
      DateFormats["Jan 1, 1970"],
    );

    return `${dateAfterFormatted} - ${dateBeforeFormatted}`;
  }

  if (dateAfter) {
    return `Invoices created on or after ${formatDate(dateAfter, DateFormats["Jan 1, 1970"])}`;
  }

  if (dateBefore) {
    return `Invoices created on or before ${formatDate(dateBefore, DateFormats["Jan 1, 1970"])}`;
  }

  return ``;
};

export const getInitialCustomRange = (
  filters: GetEmployerInvoicesParams,
): ExportCSVCustomDateRange | undefined => {
  if (!filters.date_after && !filters.date_before) {
    return;
  }

  return { date_after: filters.date_after, date_before: filters.date_before };
};

export const getInitialSelectedOption = (
  initialCustomRange: ExportCSVCustomDateRange | undefined,
): ExportCSVRangeOptionValue => {
  if (initialCustomRange) {
    return EXPORT_CSV_RANGE_OPTION_VALUE.CUSTOM_RANGE;
  }

  return EXPORT_CSV_RANGE_OPTION_VALUE.CURRENT_MONTH;
};
