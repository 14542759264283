import { useMemo } from "react";

import { Loader } from "@hexocean/braintrust-ui-components";
import type { ProfileCompletionStep } from "@js/apps/freelancer/components/profile-completion-sequence/const";
import { PROFILE_COMPLETION_STEPS } from "@js/apps/freelancer/components/profile-completion-sequence/const";
import { CompletionSequenceContext } from "@js/apps/freelancer/components/profile-completion-sequence/context/completion-sequence-context";
import { ModalInstance } from "@js/components/modal";
import { assertUnreachable } from "@js/utils";

import { useProfileCompletionSequence } from "./hooks/use-profile-completion-sequence";
import {
  ProfileCompletionSequenceAboutStep,
  ProfileCompletionSequenceRoleStep,
  ProfileCompletionSequenceSkillsStep,
  ProfileCompletionSequenceWorkExperienceStep,
} from "./steps";

import styles from "./styles.module.scss";

export const openProfileCompletionSequenceModal = () => {
  ModalInstance.open({
    children: <CompletionSequence />,
    closeButton: false,
    keepOnBackdropClick: true,
    padding: false,
    className: styles.wrapper,
  });
};

const CompletionSequence = () => {
  const { handleStepCompleted, stepToDisplay } = useProfileCompletionSequence();

  const providerValuesMemo = useMemo(
    () => ({
      handleStepCompleted,
    }),
    [handleStepCompleted],
  );

  return (
    <CompletionSequenceContext.Provider value={providerValuesMemo}>
      <CompletionStep stepToDisplay={stepToDisplay} />
    </CompletionSequenceContext.Provider>
  );
};

const CompletionStep = ({
  stepToDisplay,
}: {
  stepToDisplay: ProfileCompletionStep | null;
}) => {
  if (!stepToDisplay) {
    return <Loader centered />;
  }

  switch (stepToDisplay) {
    case PROFILE_COMPLETION_STEPS.ABOUT:
      return <ProfileCompletionSequenceAboutStep />;
    case PROFILE_COMPLETION_STEPS.ROLE:
      return <ProfileCompletionSequenceRoleStep />;
    case PROFILE_COMPLETION_STEPS.SKILLS:
      return <ProfileCompletionSequenceSkillsStep />;
    case PROFILE_COMPLETION_STEPS.WORK_EXPERIENCE:
      return <ProfileCompletionSequenceWorkExperienceStep />;
    default:
      assertUnreachable(stepToDisplay);
      return null;
  }
};
