import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import type { LocationValue } from "@js/components/autocomplete-new/google-places/types";
import {
  filterOutDuplicatedUSAValue,
  getLocationValueUniqueId,
} from "@js/components/autocomplete-new/google-places/utils";
import type { PopularLocation } from "@js/types/common";

export type RecommendedLocationButtonSelectProps = TypedWrappedFieldProps<
  LocationValue[]
> & {
  options: PopularLocation[];
  label: string;
};

export const RecommendedLocationButtonSelect = ({
  label,
  options,
  input,
}: RecommendedLocationButtonSelectProps) => {
  const valueIds = input.value.map(getLocationValueUniqueId);

  return (
    <Box mt={2}>
      <Typography
        component="label"
        display="block"
        mb={1}
        variant="label"
        size="small"
        fontWeight={400}
      >
        {label}
      </Typography>
      <Box display="flex" gap={1} flexWrap="wrap">
        {filterOutDuplicatedUSAValue(options).map((opt) => {
          const valueId = getLocationValueUniqueId(opt);
          const isSelected = isValueSelected(valueIds, valueId);

          const handleClick = () => {
            if (isSelected) {
              const newValue = input.value.filter((val) => {
                return getLocationValueUniqueId(val) !== valueId;
              });

              input.onChange(newValue);
            } else {
              input.onChange(
                filterOutDuplicatedUSAValue([...input.value, opt]),
              );
            }
          };

          return (
            <Button
              key={opt.location}
              variant={isSelected ? "medium-violet" : "white-violet"}
              shape="squared"
              onClick={handleClick}
            >
              <Typography component="span" size="small">
                {opt.location}
              </Typography>
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

const isValueSelected = (valueIds: string[], valueId: string) => {
  if (
    valueId === SETTINGS.USA_PLACE_ID ||
    valueId === ENUMS.JobCustomLocationType.UNITED_STATES_ONLY
  ) {
    return (
      valueIds.includes(SETTINGS.USA_PLACE_ID) ||
      valueIds.includes(ENUMS.JobCustomLocationType.UNITED_STATES_ONLY)
    );
  }

  return valueIds.includes(valueId);
};
