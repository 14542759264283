import { Button, Grid, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
  LinkIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { Date } from "@js/components/date";
import { RouterLink } from "@js/components/link";
import { Sanitize } from "@js/components/sanitize";
import type { WorkSampleItem } from "@js/types/freelancer";
import { DateFormats } from "@js/utils/date/types";

type ProjectModalItemProps = {
  item: WorkSampleItem;
  index: number;
  lastIndex: number;
  openDifferentProject: (index: number) => void;
};

type ProjectItemSectionsProps = JSX.Element | null;

export default ({
  item,
  index,
  lastIndex,
  openDifferentProject,
}: ProjectModalItemProps) => {
  let completionDate: ProjectItemSectionsProps = null;
  let url: ProjectItemSectionsProps = null;
  let description: ProjectItemSectionsProps = null;
  let images: ProjectItemSectionsProps = null;
  const isMobile = useMediaQuery("sm");

  if (item.completion_date) {
    completionDate = (
      <div className="work-sample-item-completion-date">
        <CalendarIcon style={{ fontSize: "16px" }} />{" "}
        <Date
          date={item.completion_date}
          format={DateFormats["January 1st, 1970"]}
        />
      </div>
    );
  }

  if (item.url) {
    url = (
      <Button
        href={item.url}
        target="_blank"
        rel="noopener noreferrer"
        variant="secondary"
        color="primary"
        className="work-sample-item-nav-button"
        RouterLink={RouterLink}
      >
        <LinkIcon /> View link
      </Button>
    );
  }

  if (item.description) {
    description = (
      <Grid item xs={12} className="work-sample-item-description">
        <Sanitize input={item.description} />
      </Grid>
    );
  }
  if (item.portfolio_item_images.length) {
    images = (
      <Grid item xs={12} className="work-sample-item-images">
        {item.portfolio_item_images.map((image, i) => (
          <div key={image.id} className="work-sample-item-images-image">
            <a href={image.image.attachment.file}>
              <img
                src={image.image.attachment.file}
                alt={i === 0 ? "Portfolio images" : ""}
                width="auto"
                height="322"
              />
            </a>
          </div>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container className="work-sample-item">
      <Grid container className="work-sample-item-nav">
        <Button
          disabled={index === 0}
          onClick={() => {
            if (index !== 0) {
              openDifferentProject(index - 1);
            }
          }}
          variant="tertiary"
          inverse
          color="primary"
          className="work-sample-item-nav-button"
        >
          <ArrowLeftIcon /> {isMobile ? "" : "Previous project"}
        </Button>
        <Button
          disabled={index === lastIndex}
          onClick={() => {
            if (index !== lastIndex) {
              openDifferentProject(index + 1);
            }
          }}
          variant="tertiary"
          inverse
          color="primary"
          className="work-sample-item-nav-button"
        >
          {isMobile ? "" : "Next project"} <ArrowRightIcon />
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Typography
          component="span"
          variant="title"
          color="primary"
          fontWeight={500}
        >
          {item.title}
        </Typography>
        {completionDate}
      </Grid>
      <Grid item xs={6} className="work-sample-item-right">
        {url}
      </Grid>
      <Grid item xs={12}>
        {description}
      </Grid>
      {images}
    </Grid>
  );
};
