import { type ReactElement } from "react";

import type { IPost } from "@js/types/give-and-get-help";

import { SharePostMenuComponent } from "./component";
import { useSharePostMenu } from "./hook";

type SharePostMenuProps = {
  post: IPost;
  postUrl: string;
  anchor?: ReactElement;
  style?: React.CSSProperties;
};

export const SharePostMenu = ({
  post,
  postUrl,
  anchor,
  style,
}: SharePostMenuProps) => {
  const {
    showPulseAnimation,
    isMobile,
    shareLinks,
    handleCopyPostLinkClick,
    getTitleForService,
    handleSharePostButtonClick,
    handleTrackSharePost,
  } = useSharePostMenu({ post, postUrl });

  return (
    <SharePostMenuComponent
      anchor={anchor}
      style={style}
      isMobile={isMobile}
      shareLinks={shareLinks}
      showPulseAnimation={showPulseAnimation}
      onCopyLinkClick={handleCopyPostLinkClick}
      getTitleForService={getTitleForService}
      onSharePostButtonClick={handleSharePostButtonClick}
      handleTrackSharePost={handleTrackSharePost}
    />
  );
};
