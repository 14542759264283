import { AvatarGridGraphic } from "@js/apps/common/components/avatar-grid-graphic";
import type { Participant } from "@js/types/messenger";
type ScheduleDelighterProps = {
  userName: string;
  userProfile: Participant;
  recipientName: string;
  recipientProfile: Participant;
};

export const ScheduleDelighter = ({
  userName,
  userProfile,
  recipientName,
  recipientProfile,
}: ScheduleDelighterProps) => {
  return (
    <AvatarGridGraphic
      leftName={recipientName}
      leftAvatarProfile={recipientProfile}
      rightName={userName}
      rightAvatarProfile={userProfile}
    ></AvatarGridGraphic>
  );
};
