import React from "react";

import { Button } from "@hexocean/braintrust-ui-components";
import {
  CloseIcon,
  LongArrowLeftIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

export type HeaderButtonProps = {
  backLink: string;
  isEdit?: boolean;
  onClick?: () => void;
};

export const HeaderButton = ({
  isEdit,
  backLink,
  onClick,
}: HeaderButtonProps) => {
  const config = isEdit
    ? {
        startIcon: <CloseIcon />,
        label: "Exit without saving",
      }
    : {
        startIcon: <LongArrowLeftIcon />,
        label: "Back",
      };

  return (
    <Button
      variant="transparent"
      shape="squared"
      to={backLink}
      sx={{
        display: "grid",
        placeItems: "center",
        marginRight: 3,
      }}
      startIcon={config.startIcon}
      RouterLink={RouterLink}
      onClick={onClick}
    >
      {config.label}
    </Button>
  );
};
