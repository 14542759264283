import { Button } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { AddCircleOutlineIcon } from "@hexocean/braintrust-ui-components/Icons";

import type { CTAProps } from "./use-get-cta-props";
import { useGetCTAProps } from "./use-get-cta-props";

export const CTABtn = ({
  isMember,
  spaceId,
  isSpaceMemberFetching,
}: {
  isMember?: boolean;
  spaceId: number;
  isSpaceMemberFetching?: boolean;
}) => {
  const { label, onClick, hideIcon, isJoining } = useGetCTAProps(
    spaceId,
    isMember,
  );

  return (
    <CTABtnComponent
      label={label}
      onClick={onClick}
      hideIcon={hideIcon}
      isJoining={isJoining || isSpaceMemberFetching}
    />
  );
};

export const CTABtnComponent = ({
  label,
  onClick,
  hideIcon,
  isJoining,
}: CTAProps) => {
  const isMobile = useMediaQuery(650);

  return (
    <Button
      variant="primary"
      size={isMobile ? "x-small" : "small"}
      onClick={onClick}
      disabled={isJoining}
    >
      {!hideIcon && <AddCircleOutlineIcon style={{ marginRight: "8px" }} />}
      {label}
    </Button>
  );
};
