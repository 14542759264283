import { memo } from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { EmployerBidListBid } from "@js/types/jobs";

import { BidAvatarWithScoreMatch } from "../../bid-avatar-with-score-match";

type BidListItemAvatarProps = {
  bid: EmployerBidListBid;
};

export const BidListItemAvatar = memo(({ bid }: BidListItemAvatarProps) => {
  return (
    <Box width={73} height={73} minWidth={73}>
      <RouterLink
        to={bid.freelancer.user.profile_url}
        target="_blank"
        className="job-bids-list-item__user-link"
      >
        <BidAvatarWithScoreMatch bid={bid} />
      </RouterLink>
    </Box>
  );
});
