import type { RefetchBidList } from "@js/apps/jobs/context/refetch-bids-context";
import { ModalInstance } from "@js/components/modal";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { RejectOfferMessageWrapper } from "./reject-offer-message-wrapper";

import style from "./style.module.scss";

type OpenRejectOfferMessageModalProps = {
  nextUrl?: string;
  refetchBidList?: RefetchBidList | null;
  bid?: EmployerBidListBid;
  bidList?: number[];
  job?: Job;
};

export const openRejectOfferMessageModal = ({
  nextUrl,
  refetchBidList,
  bid,
  bidList,
  job,
}: OpenRejectOfferMessageModalProps) => {
  ModalInstance.open({
    className: style.makeOfferMessageModal,
    keepOnBackdropClick: true,
    children: (
      <RejectOfferMessageWrapper
        bid={bid}
        refetchBidList={refetchBidList}
        nextUrl={nextUrl}
        bidList={bidList}
        job={job}
      />
    ),
    padding: false,
    closeButton: true,
    closeButtonProps: {
      style: {
        marginRight: "-8px",
        top: 0,
        right: "12px",
        padding: "16px",
      },
    },
  });
};
