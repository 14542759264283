import type { FC } from "react";
import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";

import { ActionButtons } from "./action-buttons";
import { PurchaseSummary } from "./purchase-summary";

export const BTRSTCheckoutForm: FC = () => {
  return (
    <Box flex={1} display="flex" flexDirection="column" gap={2.5}>
      <PurchaseSummary />
      <ActionButtons />
    </Box>
  );
};
