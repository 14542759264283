import { useEffect } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field, FieldArray, unregisterField } from "redux-form";

import { Box, FormHelperText } from "@hexocean/braintrust-ui-components";
import { userStartTypingPollQuestion } from "@js/apps/give-and-get-help/actions";
import {
  POST_FIELD_POLL_OPTIONS,
  POST_FIELD_POLL_TEXT,
} from "@js/apps/give-and-get-help/components/create-poll/constants";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import {
  CREATE_POST_FORM_ID,
  EDIT_POST_FORM_ID,
} from "@js/apps/give-and-get-help/form/constants";
import { useOnUserStartedTyping } from "@js/apps/give-and-get-help/hooks";
import type { TextFieldProps } from "@js/forms/fields";
import { TextField } from "@js/forms/fields";
import {
  maxLength,
  maxNumberOfValues,
  minNumberOfValues,
  required,
} from "@js/forms/validators";
import { useAppDispatch } from "@js/hooks";

import { useCreatePostOpenedFieldsContext } from "../../context";
import { usePostValidation } from "../../hooks/create-or-edit-post";
import { AttachmentActionsWrapper } from "../post-attachments/attachment-actions-wrapper";

import type { PollTextFieldArrayProps } from "./answers-field";
import { PollTextFieldArray } from "./answers-field";

type PollFieldsWithValidationProps = PollTextFieldArrayProps;

const PollFieldsWithValidation = (props: PollFieldsWithValidationProps) => {
  const { meta, shouldValidate } = props;
  const { error } = meta;

  return (
    <>
      <PollTextFieldArray {...props} />
      {/* meta.submitFailed will be changed when user tries to go to the next step */}
      {(shouldValidate || meta.submitFailed) && !!error && (
        <Box mt={2}>
          <FormHelperText error>{error}</FormHelperText>
        </Box>
      )}
    </>
  );
};

const pollOptionMaxLength = maxLength(50);

export const CreatePoll = ({
  disablePoll,
  isEditing,
}: {
  disablePoll?: boolean;
  isEditing: boolean | undefined;
}) => {
  const {
    pollOptions: { errors, MIN_POLL_OPTIONS, MAX_POLL_OPTIONS, shouldValidate },
  } = usePostValidation({ isEditing });
  const { toggleField } = useCreatePostOpenedFieldsContext();
  const dispatch = useAppDispatch();

  const formId = isEditing ? EDIT_POST_FORM_ID : CREATE_POST_FORM_ID;

  useEffect(() => {
    return () => {
      dispatch(unregisterField(formId, POST_FIELD_POLL_TEXT));
      dispatch(unregisterField(formId, POST_FIELD_POLL_OPTIONS));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemovePollClick = () => {
    toggleField("poll");
  };

  return (
    <AttachmentActionsWrapper
      onRemoveClick={!disablePoll ? handleRemovePollClick : undefined}
      showBorder
      roundedBorder
      closeButtonAlwaysVisible={!disablePoll}
    >
      <Field
        id={POST_FIELD_POLL_TEXT}
        name={POST_FIELD_POLL_TEXT}
        component={PollQuestionField}
        label="Poll question"
        placeholder="Write your poll question here. Ex: Should I go with option A or B?"
        maxLength={500}
        autoFocus
        validate={[required]}
      />

      <Box mt={4}>
        <FieldArray
          name={POST_FIELD_POLL_OPTIONS}
          disabled={disablePoll}
          component={PollFieldsWithValidation}
          shouldValidate={shouldValidate}
          singleFieldValidator={[pollOptionMaxLength]}
          validate={[
            withoutEmptyFields(
              minNumberOfValues(MIN_POLL_OPTIONS, errors.minNumberOfValues),
            ),
            withoutEmptyFields(
              maxNumberOfValues(MAX_POLL_OPTIONS, errors.maxNumberOfValues),
            ),
          ]}
        />
      </Box>
    </AttachmentActionsWrapper>
  );
};

type ValueCountValidator = (values: unknown[]) => string | undefined;

const withoutEmptyFields = (validator: ValueCountValidator) => {
  return (values: Array<{ text: string }>) => {
    const nonEmptyValues = (values || []).filter((value) => value.text);
    return validator(nonEmptyValues);
  };
};

type PollQuestionFieldProps = TextFieldProps & TypedWrappedFieldProps<string>;

const PollQuestionField = ({
  input,
  meta,
  ...rest
}: PollQuestionFieldProps) => {
  const location = usePostLocationContext();
  useOnUserStartedTyping({
    input,
    meta,
    action: userStartTypingPollQuestion({
      composer_location: location,
    }),
  });

  return <TextField {...rest} input={input} meta={meta} />;
};
