import { ReferModalContent } from "@js/apps/common/components";

export const ReferAndEarnSection = () => {
  return (
    <ReferModalContent
      hideImage
      bgcolor="medium-violet"
      titleSize="medium"
      descriptionProps={{ size: "medium" }}
      type="talent"
      copyLinkProps={{
        shortCopyText: true,
        smallText: true,
      }}
      borderRadius={4}
    />
  );
};
