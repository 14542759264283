import { UniversalSearchContextProvider } from "@js/apps/universal-search/context/universal-search-context";

import { ReferAndEarnLazy } from "../../lazy";
import { FreelancerDashboardLayout } from "../layout";

export const EarnPage = () => {
  return (
    <UniversalSearchContextProvider initialTab="CAREER_HELP">
      <FreelancerDashboardLayout pageTitle="Earn">
        <ReferAndEarnLazy />
      </FreelancerDashboardLayout>
    </UniversalSearchContextProvider>
  );
};
