import { useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import dayjs from "dayjs";

import { Box } from "@hexocean/braintrust-ui-components";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import {
  CertificatesModule,
  EducationModule,
  ExperienceModule,
  GetCareerHelp,
  GetToKnowMeCard,
  GiveGetHelpProfileModule,
  TopSkillsSection,
  WorkHistoryModule,
} from "@js/apps/freelancer/components";
import { ProfileProjectsModule } from "@js/apps/freelancer/components/profile-projects-module";
import { PostLocation } from "@js/apps/give-and-get-help/context";
import { GetCareerHelpFeatureFlagWrapper } from "@js/apps/jobs/components/get-career-help-feature-flag-wrapper";
import { TalentProfileReviewModule } from "@js/apps/reviews/components";
import { useIdParam } from "@js/hooks/use-id-param";
import { LocalStorage } from "@js/services";

import { useGetFreelancerProfileCompletionQuery } from "../../api";
import { useGetFreelancerPublicProfileQuery } from "../../api";
import { MySpacesModule } from "../../components/my-spaces-module";

export const FreelancerProfilePageAbout = () => {
  const [showGetCareerHelpBanner, setShowCareerHelpBannerBanner] =
    useState(false);

  const user = useUser();
  const freelancerId = user?.freelancer;

  const currentProfileId = useIdParam();
  const { data: profile } = useGetFreelancerPublicProfileQuery(
    currentProfileId ?? skipToken,
  );
  const { isEmployer } = useAccountType();
  const workSampleItems = profile?.portfolio_items || [];

  const { data: profileCompletion } = useGetFreelancerProfileCompletionQuery(
    {
      freelancerId: freelancerId as number,
    },
    { skip: !freelancerId },
  );

  const isFreelancer = user?.account_type === ENUMS.AccountType.FREELANCER;
  const isFreelancerHomePage = location.pathname
    ? location.pathname.startsWith("/talent/")
    : false;

  useEffect(() => {
    const determineGetCareerHelpBannerVisibility = () => {
      let showBanner = false;

      if (isFreelancer && isFreelancerHomePage) {
        const dismissTime = LocalStorage.getItem(
          LocalStorage.keys.PROFILE_NUDGE_DIMISS_TIMER,
        );

        if (!dismissTime) {
          showBanner = true;
        } else {
          const twoHoursLater = new Date(
            parseInt(dismissTime) + 2 * 60 * 60 * 1000,
          );

          if (new Date() > twoHoursLater) {
            showBanner = true;
          }
        }
      }

      setShowCareerHelpBannerBanner(showBanner);
    };

    determineGetCareerHelpBannerVisibility();
  }, [isFreelancer, isFreelancerHomePage]);

  if (!profile) return null;

  const handleDismiss = () => {
    LocalStorage.setItem(
      LocalStorage.keys.PROFILE_NUDGE_DIMISS_TIMER,
      String(new Date().getTime()),
    );
    setShowCareerHelpBannerBanner(false);
  };

  const isRecentSignUp = (signUpDate: string): boolean => {
    if (!signUpDate) return false;
    const currentDate = dayjs();
    const signUpDateParsed = dayjs(signUpDate);
    const timeDifference = currentDate.diff(signUpDateParsed, "hour");
    return timeDifference <= 24;
  };

  const shouldShowBannerDueToSignUpOrCompletion =
    (profileCompletion && profileCompletion?.completion_percent < 100) ||
    isRecentSignUp(user?.created || "");

  return (
    <>
      <Box>
        <PostLocation.Provider value={PostLocation.Values.role_jobs}>
          <GetCareerHelpFeatureFlagWrapper>
            {showGetCareerHelpBanner &&
              shouldShowBannerDueToSignUpOrCompletion && (
                <GetCareerHelp onDismiss={handleDismiss} />
              )}
          </GetCareerHelpFeatureFlagWrapper>
        </PostLocation.Provider>
      </Box>
      <Box className="profile-page-content__details-section">
        <GetToKnowMeCard profile={profile} />
        <ExperienceModule profile={profile} />
        <TopSkillsSection profile={profile} user={user} chipColor="cool-grey" />
        <ProfileProjectsModule
          profile={profile}
          workSampleItems={workSampleItems}
        />
        <Box className="profile-page-content__details-section--bottom">
          <Box display="flex" flexDirection="column">
            {!isEmployer && <GiveGetHelpProfileModule profile={profile} />}
            <MySpacesModule spaces={profile.spaces} />
            <CertificatesModule profile={profile} />
          </Box>

          <Box>
            <WorkHistoryModule profile={profile} />
            <EducationModule profile={profile} />
            <TalentProfileReviewModule profile={profile} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
