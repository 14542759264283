import type { MouseEventHandler } from "react";
import React from "react";

import { Tooltip } from "@hexocean/braintrust-ui-components";
import type { StickerValue } from "@js/types/give-and-get-help";

import { StickerIconButton } from "../../sticker-icon-button";

export type StickerReactionButtonDesktopProps = {
  tooltipContent: string;
  onClick: MouseEventHandler<HTMLElement>;
  onKeyDown: (
    event: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => void;
  disabled: boolean;
  selected: boolean;
  reactionValue: StickerValue;
};
export const StickerReactionButtonDesktop = ({
  disabled,
  selected,
  onClick,
  onKeyDown,
  tooltipContent,
  reactionValue,
}: StickerReactionButtonDesktopProps) => {
  return (
    <Tooltip
      placement="top"
      textAlign="center"
      maxWidth={216}
      fontWeight={500}
      title={tooltipContent}
    >
      <span>
        <StickerIconButton
          onClick={onClick}
          onKeyDown={onKeyDown}
          disabled={disabled}
          selected={selected}
          reactionValue={reactionValue}
        />
      </span>
    </Tooltip>
  );
};
