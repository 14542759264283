import type {
  CreatePostAttachment,
  LinkAttachment,
  PostFileAttachmentPreviewData,
  PreparedAttachment,
  SimplePostFileAttachment,
  UploadedPostFileAttachmentData,
} from "../model";

export const prepareAttachments = (
  attachments: (CreatePostAttachment | PostFileAttachmentPreviewData)[],
): PreparedAttachment[] => {
  return attachments.map((item) => {
    if ("url" in item) {
      return prepareLinkAttachment(item as LinkAttachment);
    }
    if ("attachment" in item) {
      return prepareFileAttachment(item as PostFileAttachmentPreviewData);
    }

    return prepareUploadedFileAttachment(item);
  });
};

export const prepareLinkAttachment = (
  linkAttachment: LinkAttachment,
): LinkAttachment => {
  return {
    ...linkAttachment,
  };
};

const transparentPixel =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42m" +
  "NkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
const fallbackImage = "/static/give-get-help/loading-large.png";
export const prepareUploadedFileAttachment = (
  postFileAttachment: UploadedPostFileAttachmentData,
): SimplePostFileAttachment => {
  if (!postFileAttachment.attachment || !postFileAttachment.id) {
    const result: SimplePostFileAttachment = {
      id: postFileAttachment.fileId,
      ...postFileAttachment,
      isImage: !!postFileAttachment.isImage,
    };

    return result;
  }

  const result: SimplePostFileAttachment = {
    id: postFileAttachment.id,
    name: postFileAttachment.name,
    size: postFileAttachment.attachment.file_size || postFileAttachment.size,
    file: postFileAttachment.attachment.file,
    isImage: !!postFileAttachment.isImage,
    isLoading: postFileAttachment.isLoading,
  };

  if (postFileAttachment.isImage) {
    result.thumbnail = postFileAttachment.attachment.thumbnail;
    result.previewSource =
      postFileAttachment.attachment.file || fallbackImage || transparentPixel;
  }

  return result;
};

export const prepareFileAttachment = (
  fileAttachment: PostFileAttachmentPreviewData,
): SimplePostFileAttachment => {
  const isImage = Boolean(
    fileAttachment?.attachment?.mime?.startsWith("image"),
  );

  const result = {
    name: fileAttachment.attachment.name,
    id: fileAttachment.id,
    link: fileAttachment.attachment.file,
    size: fileAttachment.attachment.file_size,
    file: fileAttachment.attachment.file,
    isImage,
  } as SimplePostFileAttachment;

  if (isImage) {
    result.thumbnail = fileAttachment.thumbnail;
    result.previewSource =
      fileAttachment.attachment.file || fallbackImage || transparentPixel;
  }

  return result;
};
