import { Box, Chip } from "@hexocean/braintrust-ui-components";
import type { TalentReviewsSummaryTag } from "@js/types/reviews";

type ReviewTagsProps = {
  tags?: TalentReviewsSummaryTag[];
};

export const ReviewTags = ({ tags }: ReviewTagsProps) => {
  if (!tags) return null;

  return (
    <Box display="flex" flexWrap="wrap" gap={1}>
      {tags.map(({ name, count }) => {
        return (
          <Chip
            key={name}
            className="review-module__tag-chip"
            LabelProps={{
              size: "small",
              fontWeight: 400,
            }}
            label={`${name} (${count})`}
          />
        );
      })}
    </Box>
  );
};
