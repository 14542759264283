import type { PaymentTypeObject } from "./types";

export const OFFER_FIELDS = {
  payment_amount: "payment_amount",
  project_duration: "project_duration",
  anticipated_weekly_hours: "anticipated_weekly_hours",
  payment_type: "payment_type",
  proposed_start_date: "proposed_start_date",
  proposed_end_date: "proposed_end_date",
  note_to_talent: "note_to_talent",
  require_paperwork: "require_paperwork",
  talent_has_own_equipment: "talent_has_own_equipment",
  deposit_payment_method: "deposit_payment_method",
} as const;

export const JOB_PAYMENT_TYPE_LABELS: PaymentTypeObject = {
  [ENUMS.JobPaymentType.HOURLY]: "per hour",
  [ENUMS.JobPaymentType.ANNUAL]: "per year",
  [ENUMS.JobPaymentType.FIXED_PRICE]: "fixed rate",
} as const;
