import { useAppSelector } from "@js/hooks";
import type { Location } from "@js/types/common";

export function useGetFreelancerLocationFromProfile():
  | Location
  | null
  | undefined {
  const location = useAppSelector(
    (state) => state.freelancer.freelancerProfile?.location_full,
  );

  return location;
}
