import { useSearchParams } from "react-router-dom";

export const NEW_EMPLOYER_SELF_SERVE_PARAM = "new_employer_self_serve";

export const useNewClientSignUpSelfServe = (): boolean => {
  const [searchParams] = useSearchParams();
  const hasAccessedOnboardingFormViaCTALink = searchParams.has(
    NEW_EMPLOYER_SELF_SERVE_PARAM,
  );

  return hasAccessedOnboardingFormViaCTALink;
};
