import React from "react";
import { Field } from "redux-form";

import { RequirementField } from "./requirement-field";
import type {
  LocationAndTimezoneContainerProps,
  RequirementFieldProps,
} from "./types";

const LocationRequirementField = ({
  job,
}: Pick<RequirementFieldProps, "job">) => {
  return (
    <Field
      name="location_requirement_met"
      component={RequirementField}
      type="location"
      job={job}
    />
  );
};

const TimezoneRequirementField = ({
  job,
}: Pick<RequirementFieldProps, "job">) => {
  return (
    <Field
      name="timezone_requirement_met"
      component={RequirementField}
      type="timezone"
      job={job}
    />
  );
};

export const LocationAndTimezoneRequirements = ({
  job,
}: LocationAndTimezoneContainerProps) => {
  const workFromAnywhere = !job.locations?.length && !job.timezones?.length;
  const locationStronglyRequired = !!job.locations_strongly_required;
  const timezoneStronglyRequired = !!job.timezone_required;

  const fields: Array<JSX.Element> = [];

  if (workFromAnywhere) {
    return null;
  }

  if (locationStronglyRequired) {
    fields.push(<LocationRequirementField job={job} key="location" />);
  }

  if (timezoneStronglyRequired) {
    fields.push(<TimezoneRequirementField job={job} key="timezone" />);
  }

  return (
    <React.Fragment key="location-timezone-requirements">
      {fields}
    </React.Fragment>
  );
};
