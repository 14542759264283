import type {
  ForwardRefExoticComponent,
  MouseEventHandler,
  RefAttributes,
  TouchEventHandler,
} from "react";

import type { PopoverProps } from "@hexocean/braintrust-ui-components";
import { Popover } from "@hexocean/braintrust-ui-components";
import { FramerLazyMotionWrapper } from "@js/apps/common/framer";
import { STICKERS } from "@js/apps/give-and-get-help/constants";
import type { StickerValue } from "@js/types/give-and-get-help";

import { AnimatedStickerButton } from "./animated-sticker-button";
import { useStickersPopover } from "./use-stickers-popover";

import styles from "./stickers-popover.module.scss";

export type AnchorProps = {
  isOpen: boolean;
  onClick?: MouseEventHandler;
  disabled: boolean;
  isTouchDevice: boolean;
  onTouchStart?: TouchEventHandler<HTMLElement>;
  onTouchEnd?: TouchEventHandler<HTMLElement>;
  onTouchMove?: TouchEventHandler<HTMLElement>;
};

type StickersPopoverProps = {
  onStickerSelect: (value: StickerValue) => void;
  disabled: boolean;

  AnchorComponent: ForwardRefExoticComponent<
    AnchorProps & RefAttributes<HTMLButtonElement>
  >;
};

export const StickersPopover = ({
  onStickerSelect,
  disabled,
  AnchorComponent,
}: StickersPopoverProps) => {
  const {
    btnRef,
    anchorEl,
    isOpen,
    isTouchDevice,
    hoveredSticker,
    handleTouchEnd,
    handleTouchStart,
    handleClick,
    handlePopoverClose,
    handleSelectSticker,
    handleTouchMoveThrottled,
  } = useStickersPopover({
    onStickerSelect,
  });

  return (
    <FramerLazyMotionWrapper featureType={"domAnimation"}>
      <StyledPopover
        anchor={
          <AnchorComponent
            disabled={disabled}
            ref={btnRef}
            isTouchDevice={isTouchDevice}
            isOpen={isOpen}
            aria-label="open sticker menu"
            onTouchStart={isTouchDevice ? handleTouchStart : undefined}
            onTouchEnd={isTouchDevice ? handleTouchEnd : undefined}
            onTouchMove={handleTouchMoveThrottled}
            onClick={isTouchDevice ? undefined : handleClick}
          />
        }
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        onClick={(ev) => ev.stopPropagation()}
      >
        {STICKERS.map((sticker) => {
          const isHovered = hoveredSticker === sticker.value;

          return (
            <AnimatedStickerButton
              key={sticker.value}
              onSelect={handleSelectSticker}
              shouldAnimate={isHovered}
              {...sticker}
            />
          );
        })}
      </StyledPopover>
    </FramerLazyMotionWrapper>
  );
};

type StyledPopoverProps = { onClose: () => void } & PopoverProps;

const StyledPopover = ({ children, onClose, ...rest }: StyledPopoverProps) => {
  return (
    <Popover
      {...rest}
      PaperProps={{
        sx: {
          display: "flex",
          gap: "9px",
          padding: "8px 12px",
          mb: 1,
          mt: -1,
          borderRadius: "64px",
          overflow: "visible",
        },
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      componentsProps={{
        backdrop: {
          className: styles.popoverBackdrop,
        },
      }}
      anchorPosition={{
        top: 100,
        left: 0,
      }}
      onClose={onClose}
    >
      {children}
    </Popover>
  );
};
