import type { WrappedFieldMetaProps } from "redux-form";
import { isString, omit, pick } from "underscore";

import { useFormErrors } from "@js/forms/hooks";
import { pluralize } from "@js/utils";

export const useCreateOrEditFormErrors = ({
  meta,
  ignoredFields,
}: {
  meta: WrappedFieldMetaProps;
  ignoredFields?: string[];
}): {
  errors: Record<string, string>;
  requiredErrors: Partial<Record<string, string>>;
  generalFormError: string;
  errorMessage: string;
} => {
  const { generalFormError, errors: _errors } = useFormErrors(meta);

  const errors: Record<string, string> = omit(_errors, ignoredFields || []);

  const requiredErrors = pick(errors, (value) => {
    return isString(value) && value.includes("required");
  });

  const otherErrors = pick(errors, (value) => {
    return !isString(value) || (isString(value) && !value.includes("required"));
  });

  const getMessage = () => {
    const requiredErrorsQuantity = Object.keys(requiredErrors).length;
    if (requiredErrorsQuantity > 0)
      return `You are missing ${requiredErrorsQuantity} required field${pluralize(
        requiredErrorsQuantity,
      )}`;

    const otherErrorsQuantity = Object.keys(otherErrors).length;
    if (otherErrorsQuantity > 0) return `There is an error in your form`;

    return "";
  };

  const errorMessage = getMessage();

  return {
    errors,
    requiredErrors,
    generalFormError,
    errorMessage,
  };
};
