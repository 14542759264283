import {
  ToggleButton,
  ToggleButtonGroup,
} from "@hexocean/braintrust-ui-components";
import type { NotificationSetting } from "@js/types/notifications";

import { useNotificationSettings } from "../allow-notifications-switch/use-unsubscribe-all-talent-notifications";

type NotificationToggleButtonProps = {
  notification: NotificationSetting;
};

export const NotificationToggleButton = ({
  notification,
}: NotificationToggleButtonProps) => {
  const { updateNotifications, areNotificationsAllowed } =
    useNotificationSettings();

  if (!notification.options) return;

  return (
    <ToggleButtonGroup
      value={
        areNotificationsAllowed
          ? notification.value
          : ENUMS.CareerHelpNotificationOptions.NEVER
      }
      exclusive
      onChange={(_, value) => {
        if (value === null) return;
        updateNotifications({ ...notification, value });
      }}
      squared
    >
      {notification.options.map(([value, label]) => {
        return (
          <ToggleButton key={value} value={value}>
            {label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};
