import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Grid,
  Loader,
  SwipeableDrawer,
} from "@hexocean/braintrust-ui-components";
import { SetHasReactionChangedContext } from "@js/apps/jobs/context/set-has-reaction-changed-context";

import { useBidDrawer } from "../../hooks/bid-drawer";

import { BidDetailsHeader, HEADER_HEIGHT } from "./header";
import { BidDetailsLeftPanel } from "./left-panel";
import { BidListRightPanel } from "./right-panel";

export const BidDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    bidToDisplay,
    totalBidsCount,
    currentBidIndex,
    goToPreviousBid,
    goToNextBid,
    handleExited,
    setHasReactionChanged,
    locationMismatchData,
    job,
    isBidsFetching,
    isLocationMismatchLoading,
  } = useBidDrawer();
  const { bidId } = useParams();

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  useEffect(() => {
    setIsOpen(!!bidId);
  }, [bidId]);

  const setHasReactionChangedContextProviderValue = useMemo(
    () => ({
      setHasReactionChanged,
    }),
    [setHasReactionChanged],
  );

  const showDrawerLoader = !job || isBidsFetching;
  const showDrawerContent = !!job && !isBidsFetching;
  const showBidLoader = !bidToDisplay || isLocationMismatchLoading;
  const showBidContent = !!bidToDisplay && !isLocationMismatchLoading;

  return (
    <SwipeableDrawer
      open={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      anchor="right"
      SlideProps={{
        onExited: handleExited,
      }}
      PaperProps={{
        sx: {
          width: { xs: "100vw", md: "90vw", lg: "72vw" },
          maxWidth: "1300px",
        },
      }}
    >
      {showDrawerLoader && <Loader centered />}
      {showDrawerContent && (
        <Box>
          <Grid container flexDirection="column">
            <BidDetailsHeader
              totalBids={totalBidsCount}
              currentBidIndex={currentBidIndex}
              goToNextBid={goToNextBid}
              goToPreviousBid={goToPreviousBid}
              closeDrawer={handleClose}
            />
            <Grid item xs={12}>
              <Grid
                container
                p={3}
                pt={2}
                spacing={1}
                mt={0}
                style={{
                  backgroundColor: "var(--off-white)",
                  minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
                }}
              >
                {showBidLoader && <Loader centered />}
                {showBidContent && (
                  <>
                    <BidDetailsLeftPanel bid={bidToDisplay} job={job} />
                    <SetHasReactionChangedContext.Provider
                      value={setHasReactionChangedContextProviderValue}
                    >
                      <BidListRightPanel
                        bid={bidToDisplay}
                        closeDrawer={handleClose}
                        locationMismatchData={locationMismatchData}
                        job={job}
                      />
                    </SetHasReactionChangedContext.Provider>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </SwipeableDrawer>
  );
};
