import { useMemo } from "react";

import { useGetPopularSkillsQuery } from "@js/apps/skills/api";
import type { Skill } from "@js/types/admin";

import { filterOutSelectedSkills } from "../helpers";
import type { SimpleSkill } from "../types";

type UseGetPopularSkillsOptionsFun = <T>(args: {
  mapSkillToOption: (skill: SimpleSkill) => T;
  role: number | undefined;
  selectedSkills: number[];
  skillsLimit?: number;
}) => {
  allPopularSkills: Skill[];
  popularSkillsOptions: T[];
};

export const useGetPopularSkillsOptions: UseGetPopularSkillsOptionsFun = ({
  mapSkillToOption,
  role,
  selectedSkills,
  skillsLimit = 13,
}) => {
  const { data: popularSkills } = useGetPopularSkillsQuery({
    roleIds: role ? [role] : undefined,
  });

  const options = useMemo(() => {
    if (!popularSkills?.length) return [];

    return filterOutSelectedSkills({
      skills: popularSkills,
      selectedSkills,
    })
      .slice(0, skillsLimit)
      .map(mapSkillToOption);
  }, [popularSkills, selectedSkills, skillsLimit, mapSkillToOption]);

  return {
    popularSkillsOptions: options,
    allPopularSkills: popularSkills || [],
  };
};
