import type { WrappedFieldProps } from "redux-form";

import { SelectField } from "@js/forms/fields";

export const OrganizationSizeField = ({ input, meta }: WrappedFieldProps) => {
  const organizationSizeOptions = Object.values(ENUMS.OrganizationSize).map(
    (value) => ({
      value,
      label: ENUMS.OrganizationSizeLabels[value],
    }),
  );

  return (
    <>
      <SelectField
        id="organization_size"
        options={organizationSizeOptions}
        label="Organization size*"
        placeholder="Select organization size"
        menuVariant="violet"
        input={input}
        meta={meta}
      />
    </>
  );
};
