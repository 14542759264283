import { useUser } from "@js/apps/common/hooks";

import { JobsListingPage } from "../../apps/listing/views";
import { PublicJobsListingPage } from "../../apps/public-listing";

export const JobsListing = () => {
  const user = useUser();

  if (!user) {
    return <PublicJobsListingPage />;
  }

  return <JobsListingPage />;
};
