import { Box, Typography } from "@hexocean/braintrust-ui-components";

import { BidInterviewIcon } from "./bid-interview-icon";

export const BidInterviewSummary = ({
  score,
  summary,
}: {
  score: string | null;
  summary: string | null;
}) => {
  const invalidScore = !parseFloat(score?.toString() || "");

  return (
    <Box
      sx={{
        padding: 2,
        marginBottom: 3,
        borderRadius: 4,
        background:
          "linear-gradient(119.92deg, rgba(212, 228, 249, 0.5) 1.7%, rgba(229, 215, 251, 0.5) 44.69%, rgba(255, 245, 227, 0.5) 80.85%, rgba(255, 253, 189, 0.5) 102.16%)",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", margin: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <BidInterviewIcon />
          <Typography component="h2">
            <Typography
              component="span"
              variant="label"
              size="large"
              mr={1}
              ml={1}
            >
              {invalidScore ? "-" : score}
            </Typography>
            <Typography
              component="span"
              variant="paragraph"
              size="large"
              sx={{
                letterSpacing: "-0.002em",
              }}
            >
              AI Interview score
            </Typography>
          </Typography>
        </Box>
        <Typography component="h3" variant="paragraph" size="medium">
          {invalidScore ? "Summary not available" : summary}
        </Typography>
      </Box>
    </Box>
  );
};
