import { useGetTalentExampleProfilesQuery } from "@js/apps/freelancer/api";
import type { Role } from "@js/types/roles";

export const useGetExampleTalentProfiles = (freelancerRole?: Role) => {
  const { isLoading, data: exampleTalents } = useGetTalentExampleProfilesQuery({
    role: freelancerRole?.id,
  });

  return {
    isLoading,
    exampleTalents: exampleTalents?.results,
  };
};
