import { useABExperiment } from "@js/apps/experiments";

export const useAIRInterview = () => {
  const { isParticipating, experiment } = useABExperiment(
    "Async AIR Interview",
  );

  const isAIRInterviewEnabled =
    experiment?.variant ===
    SETTINGS.EXPERIMENT_ENUMS[
      SETTINGS.EXPERIMENT_ENUMS.Experiments.AsyncJobInterview
    ].AsyncJobInterviewVariant;

  return {
    showAIRInterview: isParticipating && isAIRInterviewEnabled,
  };
};
