import React, { useCallback } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { Box, Checkbox, Typography } from "@hexocean/braintrust-ui-components";

type Option = {
  value: string;
  label: string;
};

type AssignOwnersOptionsProps = {
  input: TypedWrappedFieldProps<string[]>["input"];
  meta: TypedWrappedFieldProps<string[]>["meta"];
  options: Option[] | ReadonlyArray<Option>;
};

export const AssignOwnersOptionsField = ({
  input,
  meta,
  options,
}: AssignOwnersOptionsProps) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      let newValues: string[];

      if (!checked) {
        newValues = input.value.filter(
          (optionValue) => optionValue !== event.target.value,
        );
      } else {
        newValues = [...input.value, event.target.value];
      }

      input.onChange(newValues);
    },
    [input],
  );

  return (
    <Box display="flex" flexDirection="column">
      {options.map((option) => {
        const isChecked = input.value.includes(option.value);

        return (
          <Checkbox
            key={option.value}
            checked={isChecked}
            label={option.label}
            value={option.value}
            onChange={handleChange}
          />
        );
      })}
      {meta.error && meta.touched && (
        <Typography mb={2} component="p" variant="paragraph" error>
          {meta.error}
        </Typography>
      )}
    </Box>
  );
};
