import React from "react";
import cs from "classnames";

import { assertUnreachable } from "@js/utils/typescript";

import { RouterLink } from "../link";

import styles from "./style.module.scss";

type LogoProps = {
  variant?: "full" | "full-white" | "full-black" | "symbol" | "symbol-white";
  size?: number;
  className?: string;
  href?: string;
  alt?: string;
  noLink?: boolean;
};

export const Logo = ({
  size,
  className,
  variant,
  href,
  alt = "Braintrust Token",
  noLink,
}: LogoProps): JSX.Element => {
  const fileName = getFileName(variant);
  const { width, height } = getImageSize(variant, size);
  const logoClassName = cs(styles.logo, className, {
    [styles.logoBlack]: variant === "full-black",
  });

  const img = (
    <img
      src={`${SETTINGS.STATIC_URL}${fileName}`}
      alt={alt}
      width={width}
      height={height}
    />
  );

  if (noLink) {
    return <span className={logoClassName}>{img}</span>;
  }

  return (
    <RouterLink to={href || "/"} className={logoClassName}>
      {img}
    </RouterLink>
  );
};

const getFileName = (variant: LogoProps["variant"]): string => {
  switch (variant) {
    case "full-white":
    case "full-black":
      return "logo/logo-white.svg";
    case "symbol":
      return "logo/logo-symbol.svg";
    case "symbol-white":
      return "logo/logo-symbol-white.svg";
    case "full":
    case undefined:
      return "logo/logo.svg";
    default:
      assertUnreachable(variant);
      return "logo/logo.svg";
  }
};

const getImageSize = (
  variant: LogoProps["variant"],
  size?: number,
): { width: number; height: number } => {
  const isSymbol = variant === "symbol" || variant === "symbol-white";
  const width = isSymbol ? 45 : 142;
  const imgWidth = size ?? width;
  const height = isSymbol ? imgWidth : imgWidth / 4.55;

  return { width: imgWidth, height };
};
