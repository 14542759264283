import React from "react";

import { FilePreview, ImagePreview } from "../../attachment-preview";
import { LinkPreviewManager } from "../../attachment-preview/link-preview-manager";
import { PreviewLoadingFiles } from "../../file-preview";
import type { PreparedAttachment } from "../model";

export type AttachmentPreviewProps = {
  attachment: PreparedAttachment;
  showThumbnail?: boolean;
  openNewTab?: boolean;
};

export const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({
  attachment,
  showThumbnail,
  ...props
}) => {
  if ("source" in attachment) {
    return <LinkPreviewManager linkAttachment={attachment} {...props} />;
  }

  if (attachment.isLoading) {
    return (
      <PreviewLoadingFiles
        isImage={attachment.isImage}
        fileName={attachment.name}
      />
    );
  }

  if (!attachment.isImage && attachment.file) {
    return (
      <FilePreview
        name={attachment.name}
        fileLink={attachment.file}
        size={attachment.size}
      />
    );
  }

  if (attachment.isImage && attachment.previewSource) {
    return (
      <ImagePreview
        title={attachment.name}
        src={attachment.previewSource}
        thumbnail={attachment.thumbnail}
        showThumbnail={showThumbnail}
        size={attachment.size}
      />
    );
  }

  return null;
};
