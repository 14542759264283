import type React from "react";
import { useCallback, useEffect, useRef } from "react";

import { useReport } from "@js/apps/common/hooks/use-report";
import { getRedirectionLink } from "@js/hooks/anchor-redirect-warning";
import type { Message } from "@js/types/messenger";

import { openRedirectionWarningModal } from "../components/redirect-warning-modal";

export const useMessageRedirectWarning = <T extends HTMLElement>(
  message: Message,
) => {
  const ref = useRef<T | null>(null);

  const { handleReportMessage } = useReport();
  const report = handleReportMessage({
    id: message.id,
    type: "message",
    room: message.room,
    name: message.author?.public_name || "",
  });

  const handleMessageClick = useCallback(
    (ev: MouseEvent | React.MouseEvent) =>
      handleMessageImplicitLinkClick(ev, report),
    [report],
  );

  const refCallback = useCallback(
    (node: T | null) => {
      if (!node) return;
      node.addEventListener("click", handleMessageClick);
      ref.current = node;
    },
    [handleMessageClick],
  );

  useEffect(() => {
    return () => {
      if (!!ref.current) {
        ref.current.removeEventListener("click", handleMessageClick);
      }
    };
  }, [handleMessageClick]);

  return {
    anchorWrapperRef: refCallback,
  };
};

const handleMessageImplicitLinkClick = (
  e: MouseEvent | React.MouseEvent,
  report: () => void,
) => {
  const href = getRedirectionLink(e);

  if (!href) return;

  openRedirectionWarningModal(href, report);
};
