import { createContext, useContext } from "react";

export type CreatePostAttachmentFieldType =
  | "attachment_file"
  | "attachment_image"
  | "link"
  | "poll"
  | "offer";

type CreatePostOpenedFieldsContextType = {
  openedFields: CreatePostAttachmentFieldType[];
  toggleField: (fieldName: CreatePostAttachmentFieldType) => void;
  openField: (fieldName: CreatePostAttachmentFieldType) => void;
};

export const CreatePostOpenedFieldsContext = createContext<
  CreatePostOpenedFieldsContextType | undefined
>(undefined);

export const useCreatePostOpenedFieldsContext = () => {
  const data = useContext(CreatePostOpenedFieldsContext);
  if (!data) {
    throw new Error("CreatePostOpenedFieldsContext is missing");
  }

  return data;
};
