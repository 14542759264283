import type {
  GetRoomListReturnType,
  GetRoomReturnType,
  RoomMessagesPaginatedResult,
} from "@js/types/messenger";

export const patchRoomListOnUnmarkMessageAsSpam = ({
  draft,
  roomId,
}: {
  draft: GetRoomListReturnType;
  roomId: number;
}) => {
  const updatedRoom = draft.results.find((room) => room.id === roomId);
  if (!updatedRoom) {
    return;
  }
  patchRoomOnUnmarkMessageAsSpam({ room: updatedRoom });
};

export const patchRoomOnUnmarkMessageAsSpam = ({
  room,
}: {
  room: GetRoomReturnType;
}) => {
  room.is_last_message_spam = false;
};

export const patchRoomMessagesOnUnmarkMessageAsSpam = ({
  draft,
  messageId,
}: {
  draft: RoomMessagesPaginatedResult;
  messageId: number;
}) => {
  const updatedMessage = draft.results.find(
    (message) => message.id === messageId,
  );
  if (!updatedMessage) {
    return;
  }
  updatedMessage.is_spam = false;
};

export const patchRoomListOnGetSingleRoom = ({
  draft,
  room,
}: {
  draft: GetRoomListReturnType;
  room: GetRoomReturnType;
}) => {
  const roomExists = draft.results.some(
    (existingRoom) => existingRoom.id === room.id,
  );
  if (roomExists) {
    return;
  }

  draft.results.push(room);
};
