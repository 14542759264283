import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch } from "@js/hooks";

import { clickSortReferrals } from "../../actions";
import type {
  EmployerReferralsOrderByType,
  OrderByDirectionType,
  TalentReferralsOrderByType,
  UserReferralType,
} from "../../types";

type UseReferralsSortType<T> = {
  defaultOrderByParamValue: T;
  orderByOptions: ReadonlyArray<{ label: string; value: T }>;
  defaultOrderDirection: OrderByDirectionType;
  type: UserReferralType;
};

const setSearchParamsOptions = {
  state: { disableRemountIfTheSameLocation: true, noScroll: true },
};

export const useReferralsSort = <
  T extends EmployerReferralsOrderByType | TalentReferralsOrderByType,
>({
  orderByOptions,
  defaultOrderByParamValue,
  defaultOrderDirection,
  type,
}: UseReferralsSortType<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const currentOrderBy = useMemo(
    () =>
      orderByOptions.find(
        (el) =>
          el.value ===
          (searchParams.get("order_by") || defaultOrderByParamValue),
      ),
    [defaultOrderByParamValue, orderByOptions, searchParams],
  ) as { label: string; value: T };

  const currentOrderDirection = (searchParams.get("order_dir") ||
    defaultOrderDirection) as OrderByDirectionType;

  const sortByHandler = (paramValue: T) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);

      newParams.set("order_by", paramValue as string);

      return newParams;
    }, setSearchParamsOptions);
    dispatch(
      clickSortReferrals(type, {
        sortBy: paramValue,
      }),
    );
  };

  const sortDirectionHandler = (paramValue: OrderByDirectionType) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);

      newParams.set("order_dir", paramValue);

      return newParams;
    }, setSearchParamsOptions);
  };

  return {
    sortByHandler,
    currentOrderBy,
    sortDirectionHandler,
    currentOrderDirection,
  };
};
