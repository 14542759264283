import type { ReactNode } from "react";
import React from "react";
import type { HelmetProps } from "react-helmet";
import { Helmet } from "react-helmet";

export const PageHead = ({
  children,
  ...rest
}: { children: ReactNode } & HelmetProps) => {
  return (
    // defer=false is required to update title in inactive tab: https://github.com/nfl/react-helmet/issues/315
    <>
      <Helmet defer={false} {...rest}>
        {children}
      </Helmet>
    </>
  );
};
