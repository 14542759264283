import { useCallback } from "react";

import { useUser } from "@js/apps/common/hooks";

import { useNavigate } from "../navigate";

export const useCheckIsEmailVerified = () => {
  const user = useUser();
  const navigate = useNavigate();

  const redirectIfUnverified = useCallback(() => {
    if (!user?.is_verified) {
      navigate("/email_confirm/");
    }
  }, [user, navigate]);

  return {
    redirectIfUnverified,
  };
};
