import {
  useAddNoteMutation,
  useDeleteNoteMutation,
  useGetNotesQuery,
} from "@js/apps/admin/api";
import type { AddNoteArgs, DeleteNoteArgs } from "@js/apps/admin/types";

import { useAddOrDeleteNotes } from "./add-delete-notes-handlers";
import { useScrollToHighlightedAnchor } from "./scroll-to-highlighted-notes";
import type { UseNotesTypes } from "./types";

export const useNotes: UseNotesTypes = (id, objectName) => {
  const highlightedNote = useScrollToHighlightedAnchor();
  const { data: notes, isLoading } = useGetNotesQuery({
    target_id: id,
    target_name: objectName,
  });
  const [addNoteHandler] = useAddNoteMutation();
  const [deleteNoteHandler] = useDeleteNoteMutation();

  const addNote = async (args: AddNoteArgs) => {
    return addNoteHandler(args).unwrap();
  };

  const deleteNote = async (args: DeleteNoteArgs) => {
    return deleteNoteHandler(args).unwrap();
  };

  const { openDeleteNoteModal, onSubmit, onSubmitSuccess } =
    useAddOrDeleteNotes(addNote, deleteNote);

  return {
    fetchingNotes: isLoading,
    notes: notes ?? [],
    onDeleteNote: openDeleteNoteModal,
    highlightedNote,
    onSubmit,
    onSubmitSuccess,
  };
};
