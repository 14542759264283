import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { GetInspiredBtn } from "@js/apps/give-and-get-help/components/get-inspired-btn";
import { BackButton } from "@js/apps/give-and-get-help/components/header/back-button";

export type FeedHeaderProps = {
  showBackButton?: boolean;
  showGetInspiredButton?: boolean;
  isSinglePostView?: boolean;
};

export const FeedHeader = ({
  showBackButton,
  showGetInspiredButton,
  isSinglePostView,
}: FeedHeaderProps) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Box component="header" sx={{ mb: 4 }}>
      {showBackButton && <BackButton />}
      <Typography
        component={isSinglePostView ? "h3" : "h1"}
        variant="title"
        textAlign="center"
        size={isMobile ? "medium" : "large"}
        fontWeight={400}
      >
        Get career help
      </Typography>

      <Typography
        component={isSinglePostView ? "h4" : "h2"}
        textAlign="center"
        variant={isMobile ? "paragraph" : "title"}
        size={isMobile ? "large" : "small"}
        fontWeight={400}
      >
        or share your expertise with the Braintrust community
      </Typography>

      {showGetInspiredButton && <GetInspiredBtn />}
    </Box>
  );
};
