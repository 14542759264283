import type { SvgIconProps } from "@hexocean/braintrust-ui-components";
import type { IconElementType } from "@hexocean/braintrust-ui-components/Icons";
import { STICKERS_MAP } from "@js/apps/give-and-get-help/constants";
import type { StickerValue } from "@js/types/give-and-get-help";

function getStickerIcon(reactionValue: StickerValue): React.FC<SvgIconProps>;
function getStickerIcon(
  reactionValue: string,
): React.FC<SvgIconProps> | undefined;
function getStickerIcon(
  reactionValue: string | StickerValue,
): IconElementType | undefined {
  if (isStickerValue(reactionValue))
    return STICKERS_MAP[reactionValue].IconComponent;
}

export { getStickerIcon };

export const isStickerValue = (
  reactionValue: string,
): reactionValue is StickerValue =>
  !!STICKERS_MAP[reactionValue as StickerValue];
