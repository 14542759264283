import { useEffect, useState } from "react";

import { useWebsocket } from "@js/hooks";
import type { MessageRoom } from "@js/types/messenger";

export const useSendCalendarLinkPromptShown = (
  room: MessageRoom,
  isImpersonatedSession: boolean,
): {
  shouldShowCalendarLinkPrompt: boolean;
  setShouldShowCalendarPromptFalse: () => void;
} => {
  const { send } = useWebsocket();
  const [shouldShowCalendarLinkPrompt, setShouldShowCalendarLinkPrompt] =
    useState(room.should_show_calendar_link_prompt);

  useEffect(() => {
    setShouldShowCalendarLinkPrompt(room.should_show_calendar_link_prompt);
  }, [room]);

  const setShouldShowCalendarPromptFalse = () => {
    if (!shouldShowCalendarLinkPrompt) return;
    setShouldShowCalendarLinkPrompt(false);
    if (!isImpersonatedSession) {
      send(ENUMS.StreamType.CALENDAR_LINK_PROMPT_SHOWN, {
        room: room.id,
      });
    }
  };

  return { shouldShowCalendarLinkPrompt, setShouldShowCalendarPromptFalse };
};
