import dayjs from "dayjs";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { DateTimeFormats } from "@js/utils/date/types";

export type LocationMismatchItemProps = {
  label: string;
  location: string;
  date?: string;
};

export const LocationMismatchItem = ({
  label,
  location,
  date,
}: LocationMismatchItemProps) => {
  return (
    <Box display="flex" gap={1} flexDirection="column" width="50%">
      <Typography component="p" variant="label" size="medium" color="grey-2">
        {label}
      </Typography>
      <div>
        <Typography component="p" variant="paragraph" size="large">
          {location}
        </Typography>
        {!!date && (
          <Typography
            component="p"
            variant="paragraph"
            size="small"
            color="grey-2"
          >
            {formatDate(date)}
          </Typography>
        )}
      </div>
    </Box>
  );
};

const formatDate = (date: string) => {
  return dayjs(date).format(DateTimeFormats["January 1st 1970, 0:00:00 pm"]);
};
