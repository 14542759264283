import type { ComponentProps } from "react";

import { useNewClientSignUpInvitation } from "@js/apps/onboarding/hooks/new-client-signup-invitation";
import { useNewClientSignUpSelfServe } from "@js/apps/onboarding/hooks/new-client-signup-self-serve";
import type { TestimonialsCarousel } from "@js/apps/onboarding/views/layout/user-comments-carousel";
import { assertUnreachable } from "@js/utils";

type TestimonialsSlidesVariant = "user testimonials" | "client testimonials";
type Flow = ComponentProps<typeof TestimonialsCarousel>["flow"];

export const useTestimonialsSlidesVariant = (
  flow: Flow,
): TestimonialsSlidesVariant => {
  const isClientSelfServe = useNewClientSignUpSelfServe();
  const { isClientInvitation } = useNewClientSignUpInvitation();

  const isClientSignUpFlow = isClientInvitation || isClientSelfServe;

  if (flow === "sign-in") {
    return "user testimonials";
  }

  if (flow === "sign-up") {
    return isClientSignUpFlow ? "client testimonials" : "user testimonials";
  }

  assertUnreachable(flow);
  return flow;
};
