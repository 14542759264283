import { useABExperiment } from "@js/apps/experiments";

export const usePaidResumeHelp = () => {
  const { isParticipating, experiment } = useABExperiment("Paid Resume Help");

  const isPaidResumeHelpEnabled =
    experiment?.variant ===
    SETTINGS.EXPERIMENT_ENUMS[
      SETTINGS.EXPERIMENT_ENUMS.Experiments.PaidResumeHelp
    ]?.PaidResumeHelpEnabled;

  return {
    paidResumeHelp: isParticipating && isPaidResumeHelpEnabled,
  };
};
