import cs from "classnames";

import { Box } from "@hexocean/braintrust-ui-components";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import { JobItemActions } from "@js/apps/jobs/components/job-item/components/actions";
import { JobItemApplicationStatus } from "@js/apps/jobs/components/job-item/components/job-item-application-status";
import type { MainJobListingJob } from "@js/types/jobs";

import { JobItemTags } from "../tags";

import styles from "./styles.module.scss";

type JobItemCTASectionProps = {
  job: MainJobListingJob;
  jobType: EnumType<typeof ENUMS.JobType>;
};

export const JobItemTopTagsSection = ({
  job,
  jobType,
}: JobItemCTASectionProps) => {
  const statusForFreelancer = job.status_for_freelancer;
  const isNodeStaff = useIsNodeStaff();
  const shouldDisplayNodeStaffActions =
    isNodeStaff ||
    !statusForFreelancer ||
    statusForFreelancer === ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS;

  return (
    <Box
      className={cs(styles.tags, {
        [styles.tagsActions]: shouldDisplayNodeStaffActions,
      })}
    >
      <JobItemTags job={job} jobType={jobType} />

      <Box marginLeft={1}>
        {shouldDisplayNodeStaffActions ? (
          <JobItemActions job={job} />
        ) : (
          <JobItemApplicationStatus
            statusForFreelancer={statusForFreelancer}
            job={job}
          />
        )}
      </Box>
    </Box>
  );
};
