import { useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { skipToken } from "@reduxjs/toolkit/query";

import {
  useGetByotCSVDataQuery,
  useSendOffersMutation,
} from "@js/apps/byot/api";
import { MessageModalInstance } from "@js/apps/byot/components/message-modal/";
import type { BYOTFormValues } from "@js/apps/byot/types";
import { Snackbar } from "@js/components/snackbar";
import { useNavigate } from "@js/hooks/navigate";

import { reducer } from "./reducer";

const defaultMessage = `Hey 👋,

We are moving to Braintrust for our invoicing. Please set up your Braintrust account as soon as possible!`;

export const useUploadBYOTCsv = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, {
    error: null,
    file: null,
    isProcessing: false,
  });

  const [sendOffers] = useSendOffersMutation();
  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    control,
  } = useForm<BYOTFormValues>({
    defaultValues: {
      note_to_talent: defaultMessage,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    if (!state.file) {
      return;
    }

    try {
      await sendOffers(data).unwrap();
      MessageModalInstance.close();
      Snackbar.toast({
        header: `${data.result.length} Talent offers are sending!`,
        buttonText: "Okay",
        content: `The Talent you’ve added is being invited to join you on Braintrust. You can soon manage their projects and pay their invoices directly on the platform.`,
      });
      navigate("/employer/dashboard/my_talent/");
    } catch (error: unknown) {
      const err = error as { data: { _error: string } };
      Snackbar.error(err.data._error);
    }
  });

  const { currentData } = useGetByotCSVDataQuery(
    state.file?.id ? { id: state.file.id } : skipToken,
  );

  useEffect(() => {
    /** 'currentData' is 'undefined' on every fetch so we can use it like that. We can't rely on 'data' */
    if (!currentData) return;
    const isError = !!currentData.errors?.length;

    if (isError) {
      dispatch({
        type: "fetched_data_from_csv_with_errors",
      });
    } else {
      dispatch({ type: "fetched_data_from_csv" });
      setValue("id", currentData.id);
      setValue("result", currentData.result);
      Snackbar.success("Your CSV has been successfully uploaded!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData]);

  const disableSendOffersButton =
    !currentData || state.isProcessing || !!state.error;
  const isProcessedSuccessfully = !!(
    !state.error &&
    !state.isProcessing &&
    state.file
  );
  const numberOfOffers = currentData?.result.length;

  return {
    onSubmit,
    control,
    isSubmitting,
    dispatch,
    error: state.error,
    isProcessing: state.isProcessing,
    fileName: state.file?.attachment.name,
    isProcessedSuccessfully,
    disableSendOffersButton,
    numberOfOffers,
  };
};
