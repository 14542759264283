import { API } from "@js/api";

import { freelancerApi } from "../freelancer/api";
import { jobsApi } from "../jobs/api";

import { addSavedJobId, deleteSavedJob, deleteSavedJobId } from "./utils";

type BookmarkedJob = {
  id: string;
  job: string;
};

const JOBS_ENDPOINTS_TO_UPDATE = [
  "getJobs",
  "getEmployerOpenJobs",
  "getRelevantJobs",
  "getSimilarJobs",
  "getNewestJobs",
] as const;

export const bookmarksApi = API.injectEndpoints({
  endpoints: (build) => ({
    bookmarkJob: build.mutation<BookmarkedJob, number>({
      query: (jobId) => ({
        url: "/manage_saved_elements/jobs/",
        method: "POST",
        data: { job: jobId },
      }),
      async onCacheEntryAdded(
        id,
        { cacheDataLoaded, cacheEntryRemoved, getState, dispatch },
      ) {
        try {
          const data = await cacheDataLoaded;
          const state = getState();

          dispatch(
            jobsApi.util.updateQueryData("getJob", { id }, (draft) => {
              draft.saved_job_id = Number(data.data.id);
            }),
          );

          for (const endpoint of JOBS_ENDPOINTS_TO_UPDATE) {
            const argsArr = jobsApi.util.selectCachedArgsForQuery(
              state,
              endpoint,
            );

            for (const args of argsArr) {
              dispatch(
                jobsApi.util.updateQueryData(endpoint, args, (draft) => {
                  addSavedJobId(data.data, draft);
                }),
              );
            }
          }

          // freelancer jobs invitations
          const jobsInvitationsQueriesArgs =
            freelancerApi.util.selectCachedArgsForQuery(
              state,
              "getFreelancerJobInvitations",
            );

          for (const args of jobsInvitationsQueriesArgs) {
            dispatch(
              freelancerApi.util.updateQueryData(
                "getFreelancerJobInvitations",
                args,
                (draft) => {
                  addSavedJobId(data.data, draft);
                },
              ),
            );
          }
        } catch (_) {
          /* empty */
        }

        await cacheEntryRemoved;
      },
      invalidatesTags: (_result, error, id) =>
        !!error
          ? [
              { type: "Jobs", id: "LIST" },
              { type: "Jobs", id },
              "EmployerOpenJobs",
              "RelevantJobs",
              "SimilarJobs",
              "NewestJobs",
              "SavedJobs",
              "FreelancerJobInvitations",
            ]
          : ["SavedJobs"],
    }),
    unbookmarkJob: build.mutation<any, { savedJobId: number; jobId: number }>({
      query: ({ savedJobId }) => ({
        url: `/manage_saved_elements/jobs/${savedJobId}/`,
        method: "DELETE",
      }),
      async onQueryStarted(
        { savedJobId, jobId },
        { dispatch, getState, queryFulfilled },
      ) {
        const patchResults: Array<{ undo: () => void }> = [];
        const state = getState();

        const savedJobsPatchResults = dispatch(
          jobsApi.util.updateQueryData("getSavedJobs", undefined, (draft) => {
            deleteSavedJob(savedJobId, draft);
          }),
        );
        patchResults.push(savedJobsPatchResults);

        const jobPatchResult = dispatch(
          jobsApi.util.updateQueryData("getJob", { id: jobId }, (draft) => {
            draft.saved_job_id = null;
          }),
        );
        patchResults.push(jobPatchResult);

        for (const endpoint of JOBS_ENDPOINTS_TO_UPDATE) {
          const argsArr = jobsApi.util.selectCachedArgsForQuery(
            state,
            endpoint,
          );

          for (const args of argsArr) {
            const jobsEndpointPatchResult = dispatch(
              jobsApi.util.updateQueryData(endpoint, args, (draft) => {
                deleteSavedJobId(savedJobId, draft);
              }),
            );

            patchResults.push(jobsEndpointPatchResult);
          }
        }

        // freelancer jobs invitations
        const jobsInvitationsQueriesArgs =
          freelancerApi.util.selectCachedArgsForQuery(
            state,
            "getFreelancerJobInvitations",
          );

        for (const args of jobsInvitationsQueriesArgs) {
          const jobInvitationsPatchResult = dispatch(
            freelancerApi.util.updateQueryData(
              "getFreelancerJobInvitations",
              args,
              (draft) => {
                deleteSavedJobId(savedJobId, draft);
              },
            ),
          );

          patchResults.push(jobInvitationsPatchResult);
        }
        try {
          await queryFulfilled;
        } catch (_) {
          patchResults.forEach((patchResult) => patchResult.undo());
        }
      },
      invalidatesTags: (_result, error, args) =>
        !!error
          ? [
              { type: "Jobs", id: "LIST" },
              { type: "Jobs", id: args.jobId },
              "EmployerOpenJobs",
              "RelevantJobs",
              "SimilarJobs",
              "NewestJobs",
              "SavedJobs",
              "FreelancerJobInvitations",
            ]
          : [],
    }),
  }),
});

export const { useBookmarkJobMutation, useUnbookmarkJobMutation } =
  bookmarksApi;
