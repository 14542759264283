import type { ChangeEvent } from "react";

import {
  Stack,
  TextField,
  Typography,
} from "@hexocean/braintrust-ui-components";

type CommonTeamMembersModalMessageProps = {
  message: string;
  onChange: (newMessage: string) => void;
};

const MAX_MESSAGE_LENGTH = 1000;

export const CommonTeamMembersModalMessage = ({
  message,
  onChange,
}: CommonTeamMembersModalMessageProps) => {
  const handleTextFieldChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const newValue = ev.target.value;
    if (newValue.length > MAX_MESSAGE_LENGTH) {
      onChange(newValue.slice(0, MAX_MESSAGE_LENGTH));

      return;
    }

    onChange(newValue);
  };

  return (
    <Stack>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 2,
          mb: 1,
        }}
      >
        <Typography
          htmlFor="messageToShare"
          component="label"
          variant="label"
          size="small"
        >
          Send a message (optional)
        </Typography>
        <Typography
          component="span"
          variant="paragraph"
          size="small"
          color="grey-2"
        >
          {message.length} / {MAX_MESSAGE_LENGTH}
        </Typography>
      </Stack>
      <TextField
        id="messageToShare"
        value={message}
        onChange={handleTextFieldChange}
        placeholder="Enter a message for your team"
        minRows={3}
        maxRows={5}
        multiline
        sx={{
          ".MuiOutlinedInput-root": {
            padding: "16px 12px",
          },
          textarea: {
            padding: "0 !important",
          },
        }}
      />
    </Stack>
  );
};
