import { useAccountType } from "@js/apps/common/hooks";
import { useUser } from "@js/apps/common/hooks";

import { useIsUserVerified } from "../use-is-user-verified";

export const useIsEmployerUserVerified = () => {
  const { isEmployer } = useAccountType();
  const isUserVerified = useIsUserVerified();
  const user = useUser();
  const isImpersonatedSession = !!user?.is_impersonated_session;

  return isEmployer && (isUserVerified || isImpersonatedSession);
};
