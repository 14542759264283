import { useEffect } from "react";

import { Loader } from "@hexocean/braintrust-ui-components";
import { fetchEvents } from "@js/apps/freelancer/actions";
import { EventList } from "@js/apps/freelancer/components/event-list";
import { useAppDispatch, useAppSelector } from "@js/hooks";

export const EventsSection = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchEvents({ page_size: 5 }));
  }, [dispatch]);

  const loading = useAppSelector((state) => state.freelancer.fetchingEvents);
  const events =
    useAppSelector((state) => state.freelancer.events?.results) || [];

  if (loading) {
    return <Loader />;
  }

  return <EventList eventList={events} />;
};
