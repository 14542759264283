import type { GetCommentsReturnPayload } from "@js/apps/give-and-get-help/types";
import { assertUnreachable } from "@js/utils";

import { isHelpOfferStatusChangedRelatedEvent } from "../help-offer-update-recipe";

import {
  addComment,
  addCommentReaction,
  deleteCommentReaction,
  isCommentRelatedEvent,
  patchCommentsOnCommentDeleted,
  updateComment,
  updateCommentHelpOfferStatus,
} from "./helpers";

export const commentsUpdateRecipe =
  (postId: number) =>
  (event: MessageEvent, draft: GetCommentsReturnPayload) => {
    if (!event.data) return;

    if (isHelpOfferStatusChangedRelatedEvent(event)) {
      const eventData = event.data;

      updateCommentHelpOfferStatus(eventData, draft);
    }

    if (!isCommentRelatedEvent(event)) {
      return;
    }

    const eventData = event.data;
    const broadcastType = eventData.broadcast_type;

    switch (broadcastType) {
      case ENUMS.BroadcastType.POST_ADDED:
        addComment(eventData, draft, postId);
        break;

      case ENUMS.BroadcastType.POST_DELETED:
        patchCommentsOnCommentDeleted(eventData.data, draft);
        break;

      case ENUMS.BroadcastType.POST_UPDATED:
        updateComment(eventData, draft, postId);
        break;

      case ENUMS.BroadcastType.REACTION_ADDED:
        addCommentReaction(eventData, draft);
        break;

      case ENUMS.BroadcastType.REACTION_DELETED:
        deleteCommentReaction(eventData, draft);
        break;
      default:
        assertUnreachable(broadcastType);
    }
  };
