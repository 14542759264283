import type { WrappedFieldMetaProps } from "redux-form";

import { useFormErrors } from "@js/forms/hooks";
import { getErrorsForRequiredFields, pluralize } from "@js/utils";

import { OFFER_FIELDS } from "../../constants";

const REQUIRED_FIELDS = [
  OFFER_FIELDS.proposed_start_date,
  OFFER_FIELDS.proposed_end_date,
  OFFER_FIELDS.deposit_payment_method,
];

export const useOfferErrorSectionErrors = (meta: WrappedFieldMetaProps) => {
  const { generalFormError, errors } = useFormErrors(meta);

  const requiredErrors = getErrorsForRequiredFields(errors, REQUIRED_FIELDS);

  const _errorsLength = Object.keys(requiredErrors).length;

  const proposedDatesErrorsLength = [
    OFFER_FIELDS.proposed_start_date,
    OFFER_FIELDS.proposed_end_date,
  ].filter((key) => !!requiredErrors[key]).length;

  const isAnyProposedDateEmpty =
    !!requiredErrors[OFFER_FIELDS.proposed_start_date] ||
    !!requiredErrors[OFFER_FIELDS.proposed_end_date];

  const getMessage = () => {
    const length =
      _errorsLength -
      proposedDatesErrorsLength +
      (isAnyProposedDateEmpty ? 1 : 0);
    if (length) {
      return `You are missing ${length} required field${pluralize(length)}`;
    }
  };

  return {
    requiredErrors,
    generalFormError,
    getMessage,
  };
};
