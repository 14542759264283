import { useCallback } from "react";

import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useBoostFreelancerBidMutation } from "@js/apps/freelancer/api";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
export const useBoostFreelancerBid = () => {
  const dispatch = useAppDispatch();
  const [boostFreelancerBidMutation] = useBoostFreelancerBidMutation();

  const boostFreelancerBid = useCallback(
    async (bidId: number, callback: () => void) => {
      try {
        await boostFreelancerBidMutation({ id: bidId }).unwrap();
        Snackbar.success("Boost Job Successful.");
        await dispatch(fetchCurrentUser({ refetchInOtherTabs: true }));
        callback();
      } catch (error) {
        Snackbar.error("Error Boosting you Bid.");
        throw error;
      }
    },
    [boostFreelancerBidMutation, dispatch],
  );

  return {
    boostFreelancerBid,
  };
};
