import { useEffect, useMemo, useState } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import _ from "underscore";

import type { ComboBoxProps } from "@hexocean/braintrust-ui-components";
import { ComboBox, TextField } from "@hexocean/braintrust-ui-components";
import type { EmployerSearchMinimalResult } from "@js/apps/employer/api";
import {
  useGetEmployerSearchItemQuery,
  useGetEmployersSearchQuery,
} from "@js/apps/employer/api";

import { useSearchPhrase } from "../../hooks/search-phrase";

type EmployerSearchComboboxFieldProps = TypedWrappedFieldProps<number | null> &
  Partial<
    Omit<ComboBoxProps<EmployerSearchMinimalResult, false>, "component">
  > & {
    label?: string;
  };

export const EmployerSearchComboboxField = ({
  input,
  meta,
  label,
  ...props
}: EmployerSearchComboboxFieldProps) => {
  const [value, setValue] = useState<EmployerSearchMinimalResult | null>(null);
  const { onInputChange, searchPhrase } = useSearchPhrase();

  const { data: options } = useGetEmployersSearchQuery({
    search: searchPhrase,
  });

  const { data: initialItem, isLoading: initialTaxonomyLoading } =
    useGetEmployerSearchItemQuery(Number(meta.initial), {
      skip: meta.dirty || !meta.initial || !!value, // "dirty" is cleared with every search so we need to rely on "value" too
    });

  useEffect(() => {
    if (initialItem) {
      setValue(initialItem);
    }
  }, [initialItem]);

  useEffect(() => {
    if (!input.value) {
      setValue(null);
    }
  }, [input.value]);

  const optionsWithValueOption = useMemo(() => {
    const processedOptions = [...(options ?? []), value].filter(
      (option): option is EmployerSearchMinimalResult => Boolean(option),
    );
    const uniqueOptions = _.uniq(processedOptions, "id");

    return uniqueOptions;
  }, [options, value]);

  return (
    <ComboBox<EmployerSearchMinimalResult, false>
      initialTaxonomiesLoading={initialTaxonomyLoading}
      options={optionsWithValueOption}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="standard"
            label={label || "Employer search"}
            error={!!meta.error}
            helperText={meta.error}
          />
        );
      }}
      onInputChange={onInputChange}
      value={value}
      onChange={(_ev, valueArg) => {
        setValue(valueArg);
        input.onChange(valueArg?.id || null);
      }}
      {...props}
    />
  );
};
