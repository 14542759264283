import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { CUSTOM_JOB_LOCATIONS_NEW_FORMAT } from "@js/apps/common/constants";
import {
  type LocationValue,
  LocationValueType,
} from "@js/components/autocomplete-new/google-places/types";
import { useGoogleMaps } from "@js/hooks";
import { pluralize } from "@js/utils";

export const useTalentLocationFilter = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [selectedLocations, setSelectedLocations] = React.useState<
    LocationValue[]
  >([]);
  const [params] = useSearchParams();
  const { getPlaceDetails } = useGoogleMaps();
  const selectedLocation = params.get("place_id");
  const selectedCustomLocation = params.get("custom_location");

  const parsedLocationsIds = React.useMemo(
    () => parseParams(selectedLocation),
    [selectedLocation],
  );

  const selectedCustomLocations: LocationValue[] = React.useMemo(() => {
    const parsedLocations: LocationValue[] = [];
    parseParams(selectedCustomLocation).forEach((customLocation) => {
      const found = CUSTOM_JOB_LOCATIONS_NEW_FORMAT.find(
        (location) => location.custom_location === customLocation,
      );
      if (found) {
        const isUSA =
          found.custom_location ===
          ENUMS.JobCustomLocationType.UNITED_STATES_ONLY;

        parsedLocations.push(
          isUSA ? { ...found, location: "United States" } : found,
        );
      }
    });

    return parsedLocations;
  }, [selectedCustomLocation]);

  const isActive = !!selectedLocation || !!selectedCustomLocation;
  const locationsToInit = [
    ...selectedLocations,
    ...selectedCustomLocations,
  ].filter(Boolean);

  React.useEffect(() => {
    if (parsedLocationsIds.length > 0) {
      Promise.all(
        parsedLocationsIds.map((place) => {
          return getPlaceDetails({
            placeId: place,
            fields: SETTINGS.GOOGLE_PLACES_DETAILS,
          }).then((value) => {
            return value;
          });
        }),
      ).then((value) => {
        setSelectedLocations(
          value.map((loc) => {
            return {
              location_type: LocationValueType.google,
              place_id: loc?.place_id as string,
              location: loc?.formatted_address as string,
            };
          }),
        );
      });
    }
    setSelectedLocations([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedLocationsIds]);

  const label = useMemo(() => {
    if (locationsToInit.length > 0 && isActive) {
      const labelMainPart = locationsToInit[0]?.location;
      const labelExtensions =
        locationsToInit.length - 1 > 0
          ? `& ${locationsToInit.length - 1} other${pluralize(
              locationsToInit.length - 1,
            )}`
          : "";
      return `${labelMainPart} ${labelExtensions}`;
    }

    return "Location";
  }, [locationsToInit, isActive]);

  return {
    label,
    isOpen,
    setIsOpen,
    initialLocation:
      selectedLocation || selectedCustomLocation ? locationsToInit : [],
    isActive,
  };
};

const parseParams = (param: string | null) => (param ? param.split(",") : []);
