import { Typography } from "@hexocean/braintrust-ui-components";

type LabelProps = {
  children: string;
  htmlFor?: string;
  isMobile?: boolean;
  component?: "p" | "label";
};

export const Label = ({
  children,
  htmlFor,
  component = "p",
  isMobile = false,
}: LabelProps) => {
  return (
    <Typography
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        mb: isMobile ? 0 : 1,
      }}
      component={component}
      htmlFor={htmlFor}
      variant="label"
      size="medium"
      mb={1}
    >
      {children}
      <Typography component="span">&nbsp;(Optional)</Typography>
    </Typography>
  );
};
