import { useMemo } from "react";
import { SubmissionError } from "redux-form";

import { fetchCurrentUser } from "@js/apps/auth/actions";
import { updateFreelancerProfile } from "@js/apps/freelancer/actions";
import type { AccountInfoFormData } from "@js/apps/freelancer/forms/account-info";
import { prepareUpdateFreelancerCustomizationExternalProfilesValue } from "@js/apps/freelancer/hooks/account-info/utils";
import { useAppDispatch } from "@js/hooks";
import type { Freelancer } from "@js/types/freelancer";

import {
  useGetFreelancerExternalProfilesQuery,
  useLazyGetFreelancerProfileCompletionQuery,
  useUpdateFreelancerCustomizationMutation,
} from "../../api";

export const useAccountInfo = (profile: Freelancer | undefined) => {
  const dispatch = useAppDispatch();

  const [getFreelancerProfileCompletion] =
    useLazyGetFreelancerProfileCompletionQuery();
  const { data: externalProfiles, isLoading } =
    useGetFreelancerExternalProfilesQuery();

  const [updateFreelancerCustomization] =
    useUpdateFreelancerCustomizationMutation();

  const initialValues = useMemo<AccountInfoFormData>(
    () => ({
      user: {
        first_name: profile?.user?.first_name || "",
        last_name: profile?.user?.last_name || "",
      },
      external_profiles: externalProfiles,
      location_full: profile?.location_full || null,
    }),
    [
      externalProfiles,
      profile?.location_full,
      profile?.user.first_name,
      profile?.user.last_name,
    ],
  );

  const onSubmit = async (values: AccountInfoFormData) => {
    if (!profile) return;

    const preparedExternalProfiles =
      prepareUpdateFreelancerCustomizationExternalProfilesValue(values);

    try {
      await dispatch(
        updateFreelancerProfile({
          freelancerId: profile.id,
          updatedValues: {
            user: {
              first_name: values.user.first_name,
              last_name: values.user.last_name,
            },
            location_full: values.location_full,
          },
        }),
      );
      // it invalidates freelancer public profile so we want to make sure it is executed later
      await updateFreelancerCustomization({
        id: profile.id,
        data: {
          external_profiles: preparedExternalProfiles,
          user: {
            first_name: profile?.user?.first_name,
            last_name: profile?.user?.last_name || "",
          },
        },
      }).unwrap();
    } catch (error: any) {
      throw new SubmissionError(error.data);
    }
  };

  const onSubmitSuccess = async () => {
    if (!profile) return;
    dispatch(fetchCurrentUser());
    return await getFreelancerProfileCompletion({
      freelancerId: profile.id,
    }).unwrap();
  };

  return {
    onSubmit,
    onSubmitSuccess,
    initialValues,
    isLoading,
  };
};
