import type { ReactNode } from "react";
import React from "react";

import styles from "./team-member-invitations-item-container.module.scss";

type TeamMemberInvitationsItemContainerProps = { children: ReactNode };

export const TeamMemberInvitationsItemContainer = ({
  children,
}: TeamMemberInvitationsItemContainerProps) => {
  return (
    <div className={styles.itemWrapper}>
      <div className={styles.itemGridContainer}>{children}</div>
    </div>
  );
};
