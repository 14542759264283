import { useEffect } from "react";

import { useUser } from "@js/apps/common/hooks";

import { openAutoBlockWarningModal } from "../../components/auto-block-warning-modal/auto-block-modal-instance";

export const useAutoBlockUserWarningModal = () => {
  const user = useUser();

  useEffect(() => {
    if (user?.is_blocked) {
      openAutoBlockWarningModal({});
    }
  }, [user?.is_blocked]);
};
