import { useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import {
  createTaxonomyItem,
  searchTaxonomyItems,
} from "@js/apps/common/actions";
import { useUser } from "@js/apps/common/hooks";
import {
  createNewCertificate,
  deleteCertificate,
  updateCertificate,
} from "@js/apps/freelancer/actions";
import { CERTIFICATES_FORM_ID } from "@js/apps/freelancer/forms/certificates-form/constants";
import { ADD_ANOTHER_CERTIFICATE } from "@js/apps/freelancer/hooks/edit-certificates/constants";
import { CommonConfirmationModal, ModalInstance } from "@js/components/modal";
import { useAppDispatch, useDebouncedMutation } from "@js/hooks";
import type { AppDispatch } from "@js/store";
import type { Certificate, Issuer } from "@js/types/common";
import type { FreelancerCertificate } from "@js/types/freelancer";
import { deepClone, isEqualTaxonomyName } from "@js/utils";

import {
  freelancerApi,
  useGetFreelancerPublicProfileQuery,
  useUpdateCertificatesPositionsMutation,
} from "../../api";
import type { CertificatesFormData } from "../../forms/certificates-form";

export const useCertificates = () => {
  const dispatch = useAppDispatch();
  const [submitType, setSubmitType] = useState<string | null>(null);

  const freelancerId = useUser()?.freelancer;
  const { data: profile } = useGetFreelancerPublicProfileQuery(
    freelancerId ?? skipToken,
  );
  const [certificateList, setCertificateList] = useState<
    FreelancerCertificate[]
  >(profile?.freelancer_certificates || []);
  const [updateCertificatesPositions] = useDebouncedMutation(
    useUpdateCertificatesPositionsMutation,
  );
  const createCertificate = (phrase: string) =>
    dispatch(createTaxonomyItem(phrase, "certificates", "new_certificate"));
  const createIssuer = (phrase: string) =>
    dispatch(createTaxonomyItem(phrase, "certificates", "issuer"));

  const onSubmit = async (values: CertificatesFormData) => {
    const newValue = deepClone(values);

    if (typeof newValue.new_certificate === "string") {
      const foundCertificates = (await searchTaxonomyItems({
        phrase: newValue.new_certificate,
        endpoint: "certificates",
        immediate: true,
        params: {},
        dispatch,
      })) as Certificate[];

      let certificate = foundCertificates.find(
        ({ name }) => name === newValue.new_certificate,
      );

      if (
        !certificate ||
        !isEqualTaxonomyName(certificate.name, newValue.new_certificate)
      ) {
        certificate = (await createCertificate(
          newValue.new_certificate,
        )) as Certificate;
      }

      newValue.new_certificate = certificate.id;
    }

    if (newValue.issuer) {
      const foundIssuer = (await searchTaxonomyItems({
        phrase: newValue.issuer,
        endpoint: "certificates",
        immediate: true,
        params: {},
        dispatch,
      })) as Issuer[];
      let issuer = foundIssuer.find(({ name }) => name === newValue.issuer);

      if (!issuer || !isEqualTaxonomyName(issuer.name, newValue.issuer)) {
        issuer = (await createIssuer(newValue.issuer)) as Issuer;
      }
    }

    if (newValue.id) {
      return dispatch(updateCertificate(newValue.id, newValue)).then((data) => {
        setCertificateList((prev) =>
          prev.map((certificate) =>
            certificate.id === data.id ? data : certificate,
          ),
        );
      });
    }

    return dispatch(createNewCertificate(newValue)).then((data) => {
      setCertificateList((prev) => [...prev, data]);
    });
  };

  const onSubmitSuccess = async (
    _result: unknown,
    methodDispatch: AppDispatch,
    { reset }: { reset?: (id: string) => void },
  ) => {
    methodDispatch(
      freelancerApi.util.invalidateTags(["FreelancerPublicProfile"]),
    );

    if (submitType === ADD_ANOTHER_CERTIFICATE) {
      reset?.(CERTIFICATES_FORM_ID);
      setSubmitType(null);
    } else {
      ModalInstance.close();
      CommonConfirmationModal.close();
    }
  };

  const getInitialValues = (
    editedCertificateId: number,
  ): CertificatesFormData | undefined => {
    if (!profile) return;

    const editedCertificate = certificateList.find(
      (certificate) => certificate.id === editedCertificateId,
    );

    if (!editedCertificateId || !editedCertificate) return;

    return {
      id: editedCertificate.id,
      new_certificate: editedCertificate.certificate
        ? editedCertificate.certificate.name
        : editedCertificate.certificate,
      issuer: editedCertificate.issuer,
      year_of_issuing: String(editedCertificate.year_of_issuing),
    };
  };

  const onDeleteCertificate = async (certificateId: number) => {
    await dispatch(deleteCertificate(certificateId)).then(() => {
      setCertificateList((prev) =>
        prev.filter(({ id }) => id !== certificateId),
      );
    });

    dispatch(freelancerApi.util.invalidateTags(["FreelancerPublicProfile"]));
    CommonConfirmationModal.close();
  };

  const onUpdateCertificateSuccess = async () => {
    dispatch(freelancerApi.util.invalidateTags(["FreelancerPublicProfile"]));
    CommonConfirmationModal.close();
  };

  const onReorderCertificates = async (
    itemPositions: FreelancerCertificate[],
  ) => {
    await updateCertificatesPositions({ itemPositions });
    setCertificateList(itemPositions);
  };

  return {
    onSubmit,
    getInitialValues,
    onDeleteCertificate,
    onSubmitSuccess,
    onUpdateCertificateSuccess,
    setSubmitType,
    certificateList,
    onReorderCertificates,
  };
};
