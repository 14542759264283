import { Route } from "react-router-dom";

import { ACCESS_RULE } from "@js/routes/constants";
import { ProtectedRoute } from "@js/routes/protected-route";

import { CreateJobMainPage, CreateJobSetupPage } from "../views/create-job";
import { CreateJobFormLayoutWrapper } from "../views/create-job/create-job-form-wrapper";
import { EditJobMainPage, EditJobSetUpPage } from "../views/edit-job";
import { EditJobFormLayoutWrapper } from "../views/edit-job/edit-job-form-wrapper";

export const CreateJobRoutes = (
  <Route
    path=""
    element={
      <ProtectedRoute
        rules={[
          ACCESS_RULE.CAN_MANAGE_JOBS,
          ACCESS_RULE.EMPLOYER_HAS_ADDRESS_SET,
        ]}
      >
        <CreateJobFormLayoutWrapper />
      </ProtectedRoute>
    }
  >
    <Route path=":id" element={<CreateJobMainPage />} />
    <Route path=":id/set_up" element={<CreateJobSetupPage />} />
    <Route index element={<CreateJobSetupPage />} />
  </Route>
);

export const EditJobRoutes = (
  <Route
    path=""
    element={
      <ProtectedRoute
        rules={[
          ACCESS_RULE.CAN_MANAGE_JOBS,
          ACCESS_RULE.EMPLOYER_HAS_ADDRESS_SET,
        ]}
      >
        <EditJobFormLayoutWrapper />
      </ProtectedRoute>
    }
  >
    <Route index element={<EditJobMainPage />} />
    <Route path="set_up" element={<EditJobSetUpPage />} />
  </Route>
);
