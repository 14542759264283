import cx from "classnames";

import type { CoreLayoutProps } from "@js/layouts/core";
import { CoreLayout } from "@js/layouts/core";

import style from "./style.module.scss";

type FullPageWithStickyHeaderLayoutProps = {
  header: React.ReactNode;
  content: React.ReactNode;
  headerClassName?: string;
  contentClassName?: string;
  pageTitle?: CoreLayoutProps["pageTitle"];
};

export const FullPageWithStickyHeaderLayout = ({
  header,
  headerClassName,
  content,
  contentClassName,
  pageTitle,
}: FullPageWithStickyHeaderLayoutProps) => {
  return (
    <CoreLayout pageTitle={pageTitle} className={style.container}>
      <div className={cx(style.stickyHeader, headerClassName)}>{header}</div>
      <div className={cx(style.contentContainer, contentClassName)}>
        {content}
      </div>
    </CoreLayout>
  );
};
