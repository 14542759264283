import { useUser } from "@js/apps/common/hooks";
import { clickTrendingHashtag } from "@js/apps/give-and-get-help/actions";
import type { HashtagOption } from "@js/apps/give-and-get-help/components/trending-hashtags/trending-hashtags-config";
import { assignColors } from "@js/apps/give-and-get-help/components/trending-hashtags/trending-hashtags-config";
import { HASHTAG_QUERY_PARAM } from "@js/apps/give-and-get-help/constants";
import { openSignUpModal } from "@js/apps/onboarding/components/sign-up-modal";
import { useAppDispatch, useNavigate } from "@js/hooks";

import { useGetSpaceTrendingHashtagsQuery } from "../../api";

export const useTrendingHashtags = (spaceId: number) => {
  const { data: trendingHashtags } = useGetSpaceTrendingHashtagsQuery({
    id: spaceId,
  });
  const user = useUser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const hashtagToOptions = !!trendingHashtags
    ? trendingHashtags.map((hashtag) => ({
        label: hashtag.name,
        value: hashtag.id,
      }))
    : [];
  const optionsWithColors = assignColors(hashtagToOptions);
  const showPostsWithHashtag = (hashtag: HashtagOption) => {
    dispatch(
      clickTrendingHashtag({
        hashtag_id: hashtag.value as number,
      }),
    );
    window.scrollTo({ top: 0 });
    navigate(
      `/spaces/${spaceId}/discussion?${HASHTAG_QUERY_PARAM}=${hashtag.value}`,
    );
  };

  const handleHashtagClick = (hashtag: HashtagOption) => {
    if (!user) {
      return openSignUpModal();
    }
    showPostsWithHashtag(hashtag);
  };

  return {
    showHashtagsList: !!trendingHashtags && trendingHashtags.length > 0,
    optionsWithColors,
    handleHashtagClick,
  };
};
