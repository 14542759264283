import type { ReactNode } from "react";
import { Outlet, Route, useLocation } from "react-router-dom";

import { Box, Tab, Typography } from "@hexocean/braintrust-ui-components";
import { Tabs } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import { SimpleForm } from "./form";
import { SingleFieldForm } from "./single-field";

const TABS = [
  {
    path: "/rhf/form",
    label: "Form",
  },
  {
    path: "/rhf/field",
    label: "Field",
  },
];

type RhfTestFormPageProps = {
  children: ReactNode;
};

const RhfTestFormPage = ({ children }: RhfTestFormPageProps) => {
  const location = useLocation();

  return (
    <Box p={3}>
      <Tabs value={location.pathname} normalizePath>
        {TABS.map((tab) => (
          <Tab
            key={tab.path}
            value={tab.path}
            component={RouterLink}
            to={tab.path}
            label={
              <Typography component="span" variant="label">
                {tab.label}
              </Typography>
            }
          />
        ))}
      </Tabs>
      {children}
    </Box>
  );
};

export const RhfTestRoutes =
  process.env.DEPLOYMENT !== "production" ? (
    <Route
      path="rhf"
      data-ignored={true}
      element={
        <RhfTestFormPage>
          <Outlet />
        </RhfTestFormPage>
      }
    >
      <Route path="form" element={<SimpleForm />} />
      <Route path="field" element={<SingleFieldForm />} />
    </Route>
  ) : null;
