import { useCallback } from "react";
import { SubmissionError } from "redux-form";

import { useUser } from "@js/apps/common/hooks/use-user";
import { useGetManagedEmployerQuery } from "@js/apps/employer/hooks";
import { formatAIGeneratedJobToFormData } from "@js/apps/employer/hooks/generate-ai/utils";
import { useGenerateJobDescriptionUsingAIMutation } from "@js/apps/jobs/api";
import { GENERATE_AI_JOB_DESCRIPTION } from "@js/apps/jobs/apps/create-job/action-types";
import { useJobRole } from "@js/apps/roles/hooks";
import { useGetSkillsQuery } from "@js/apps/skills/api";
import type { ReduxFormConfiguration } from "@js/forms/components/redux-form";
import { useNavigate } from "@js/hooks";
import type { AppDispatch } from "@js/store";
import type { AIGeneratedJob, ManagedJob } from "@js/types/jobs";

import type { JobOverviewFormData } from "../../forms/job-overview";

type JobOverviewFormConfigProps = ReduxFormConfiguration<
  JobOverviewFormData,
  any
>;

type useGenerateAIConfig = () => {
  onSubmit: JobOverviewFormConfigProps["onSubmit"];
};

export const useGenerateAI: useGenerateAIConfig = () => {
  const { data: employerProfile } = useGetManagedEmployerQuery();

  const userProfile = useUser();
  const navigate = useNavigate();
  const { roleOptions: roles } = useJobRole();
  const { data: skills } = useGetSkillsQuery({});
  const [generateJobDescriptionUsingAI] =
    useGenerateJobDescriptionUsingAIMutation();

  const formatToJobData = useCallback(
    (result: AIGeneratedJob): Partial<ManagedJob> => {
      return formatAIGeneratedJobToFormData({
        data: result,
        roles,
        skills: skills || [],
      });
    },
    [roles, skills],
  );

  const onSubmit: JobOverviewFormConfigProps["onSubmit"] = useCallback(
    async (values: JobOverviewFormData, dispatch: AppDispatch) => {
      const employer_id = employerProfile?.id || userProfile?.id;

      if (!employer_id) return;

      try {
        await generateJobDescriptionUsingAI({
          employer_id,
          short_description: values.job_overview,
        })
          .unwrap()
          .then((result) => {
            dispatch({
              type: GENERATE_AI_JOB_DESCRIPTION,
              payload: formatToJobData(result),
            });
            navigate("/jobs/add_new");
          });
      } catch (error: any) {
        throw new SubmissionError({
          _error:
            error.data?.detail ||
            "Oops! It seems there's an issue generating a job description. Please try again later",
        });
      }
    },
    [
      employerProfile?.id,
      generateJobDescriptionUsingAI,
      userProfile?.id,
      formatToJobData,
      navigate,
    ],
  );

  return { onSubmit };
};
