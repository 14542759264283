import { useEffect, useRef, useState } from "react";

import type { ProfileCompletionStep } from "@js/apps/freelancer/components/profile-completion-sequence/const";
import { getFirstUnfinishedSection } from "@js/apps/freelancer/components/profile-completion-sequence/utils";
import { useCurrentFreelancerProfileCompletion } from "@js/apps/freelancer/hooks/use-current-freelancer-profile-completion";
import { useSuppressProfileCompletionSuccessModal } from "@js/apps/freelancer/hooks/use-profile-completion-success/hooks";
import { ModalInstance } from "@js/components/modal";
import type { ProfileCompletion } from "@js/types/freelancer";

export const useProfileCompletionSequence = () => {
  const [stepToDisplay, setStepToDisplay] =
    useState<ProfileCompletionStep | null>(null);
  const { data: profileCompletion } = useCurrentFreelancerProfileCompletion();
  const prevProfileCompletion = useRef(profileCompletion);
  useSuppressProfileCompletionSuccessModal();
  const [
    canReactToNextProfileCompletionChange,
    setCanReactToNextProfileCompletionChange,
  ] = useState(false);

  useEffect(() => {
    if (!profileCompletion) return;

    // initial setup
    if (!stepToDisplay) {
      handleNewUnfinishedSection(profileCompletion, setStepToDisplay);
    }
  }, [profileCompletion, stepToDisplay]);

  const handleStepCompleted = () => {
    setCanReactToNextProfileCompletionChange(true);
  };

  useEffect(() => {
    if (
      profileCompletion?.completion_percent ===
      prevProfileCompletion.current?.completion_percent
    ) {
      // When data is not fetched yet, and canReactToNextProfileCompletionChange is true, we should not yet react to the change
      return;
    }

    if (canReactToNextProfileCompletionChange && profileCompletion) {
      setCanReactToNextProfileCompletionChange(false);
      handleNewUnfinishedSection(profileCompletion, setStepToDisplay);
      prevProfileCompletion.current = profileCompletion;
    }
  }, [canReactToNextProfileCompletionChange, profileCompletion]);

  return {
    handleStepCompleted,
    stepToDisplay,
  };
};

const handleNewUnfinishedSection = (
  newProfileCompletionData: ProfileCompletion,
  setStepToDisplay: (step: ProfileCompletionStep) => void,
) => {
  const firstUnfinishedSection = getFirstUnfinishedSection(
    newProfileCompletionData,
  );
  if (firstUnfinishedSection) {
    setStepToDisplay(firstUnfinishedSection);
  } else {
    ModalInstance.close();
  }
};
