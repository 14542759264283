import React from "react";

import { Loader, Typography } from "@hexocean/braintrust-ui-components";
import { MODULE_EDIT_MODAL_PROPERTIES } from "@js/apps/freelancer/constants";
import { AccountInfoForm } from "@js/apps/freelancer/forms/account-info";
import { useAccountInfo } from "@js/apps/freelancer/hooks";
import { ModalInstance } from "@js/components/modal";
import type { Freelancer } from "@js/types/freelancer";

export const openGeneralInfoModal = (profile: Freelancer) =>
  ModalInstance.open({
    children: <GeneralInfoModalContent profile={profile} />,
    ...MODULE_EDIT_MODAL_PROPERTIES,
    className: "profile-page-modal profile-page-modal-account-info",
  });

const GeneralInfoModalContent = ({ profile }: { profile: Freelancer }) => {
  const { isLoading, ...formConfig } = useAccountInfo(profile);

  if (isLoading) {
    return <Loader centered />;
  }

  return (
    <AccountInfoForm
      headerComponent={
        <Typography component="h3" variant="title" size="small" mb={4}>
          👋 Bio
        </Typography>
      }
      {...formConfig}
      onSubmitSuccess={() => {
        formConfig.onSubmitSuccess();
        ModalInstance.close();
      }}
    />
  );
};
