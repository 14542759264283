import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { touch } from "redux-form";

import type { SliderProps } from "@hexocean/braintrust-ui-components";
import { Slider } from "@hexocean/braintrust-ui-components";

export type SliderFieldProps = SliderProps & TypedWrappedFieldProps<number[]>;

export const ReduxFormSliderFieldFactory =
  (Component: React.FC<React.PropsWithChildren<SliderProps>>) =>
  ({ input, meta, ...rest }: SliderFieldProps): JSX.Element => {
    return (
      <Component
        {...rest}
        value={input.value}
        onChange={(_event, value) => {
          input.onChange(value as number[]);
          meta.dispatch(touch(meta.form, input.name));
        }}
      />
    );
  };

export const SliderField = ReduxFormSliderFieldFactory(Slider);
