import React from "react";
import type { QueryDataMap } from "urijs";
import URI from "urijs";

import type { TypographyProps } from "@hexocean/braintrust-ui-components";
import { Box, Button } from "@hexocean/braintrust-ui-components";
import { CopyIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks/use-user";
import type { ReferType } from "@js/apps/dashboard/types";
import { Snackbar } from "@js/components/snackbar";
import { useCopyToClipboard } from "@js/hooks";

import type { OptionalParams } from "../../services";
import type { CopyLinkBoxProps } from "../copy-link-box";
import { CopyLinkBox } from "../copy-link-box";
import type { ReferCardBoxProps, ReferCardProps } from "../refer-card";
import { ReferCard } from "../refer-card";
import { ReferSocialsButtons } from "../refer-socials-buttons";

import { useReferModalContent } from "./use-refer-modal-content";

import styles from "./styles.module.scss";

export type ReferModalContentProps = {
  headline?: string;
  subtext?: string;
  finePrint?: string;
  params?: QueryDataMap;
  hideShareLink?: boolean;
  hideImage?: boolean;
  titleSize?: TypographyProps["size"];
  descriptionProps?: ReferCardProps["descriptionProps"];
  footerProps?: ReferCardProps["footerProps"];
  shareLinksParams?: OptionalParams;
  dictionary?: { [key: string]: string };
  bgcolor?: string;
  copyLinkProps?: Pick<
    CopyLinkBoxProps,
    "shortCopyText" | "smallText" | "iconColorVariant" | "variant"
  >;
  type: ReferType;
} & ReferCardBoxProps;

export const ReferModalContent: React.FC<ReferModalContentProps> = ({
  headline = SETTINGS.REFERRAL_HEADLINE,
  subtext = SETTINGS.REFERRAL_SUBTEXT,
  finePrint = SETTINGS.REFERRAL_FINE_PRINT,
  hideShareLink,
  hideImage = false,
  params = {},
  shareLinksParams = {
    subject: SETTINGS.REFERRAL_SHARE_COPY_EMAIL_SUBJECT,
    body: SETTINGS.REFERRAL_SHARE_COPY_EMAIL_BODY,
    twitterText: SETTINGS.REFERRAL_TWITTER_SHARE_COPY,
  },
  dictionary = {},
  titleSize = "large",
  bgcolor,
  copyLinkProps,
  type,
  ...rest
}) => {
  const { referralLink, shareLinks, handleCopy } = useReferModalContent({
    params,
    shareLinksParams,
    dictionary,
  });

  return (
    <ReferCard
      title={headline}
      description={subtext}
      imgSrc={
        hideImage
          ? ""
          : `${SETTINGS.STATIC_URL}referral/share-job-modal-infographic.svg`
      }
      footer={finePrint}
      titleSize={titleSize}
      bgcolor={bgcolor}
      {...rest}
    >
      <Box
        display="flex"
        mt={4}
        flexWrap={hideImage ? "wrap" : "nowrap"}
        flexDirection={{ xs: "column", lg: "row" }}
      >
        {!hideShareLink && (
          <Box mb={2} minWidth={0} width="100%">
            <CopyLinkBox
              referralLink={referralLink}
              handleCopy={handleCopy}
              type={type}
              {...copyLinkProps}
            />
          </Box>
        )}
        <ReferSocialsButtons
          shareLinks={shareLinks}
          hideShareLink={hideShareLink}
          referralLink={referralLink}
          handleCopy={handleCopy}
          type={type}
        />
      </Box>
    </ReferCard>
  );
};

type ReferGrantJobModalContentProps = {
  headline?: string;
  subtext?: string;
  finePrint?: string;
  hideShareLink?: boolean;
  hideImage?: boolean;
  titleSize?: TypographyProps["size"];
  descriptionProps?: ReferCardProps["descriptionProps"];
  footerProps?: ReferCardProps["footerProps"];
  jobId: number;
  type: ReferType;
};

export const ReferGrantJobModalContent: React.FC<
  ReferGrantJobModalContentProps
> = ({
  headline = "Share This Job.",
  subtext = "Grants are not eligible for BTRST referral bonuses at this time.",
  finePrint,
  hideShareLink = false,
  hideImage = false,
  titleSize = "large",
  descriptionProps,
  footerProps,
  jobId,
  type,
}) => {
  const { handleCopy } = useCopyToClipboard(() =>
    Snackbar.success("URL successfully copied!"),
  );
  const user = useUser();

  if (!user || !jobId) {
    return null;
  }

  const referralLink = new URI(
    `${window.location.origin}/jobs/${jobId}`,
  ).toString();

  return (
    <ReferCard
      title={headline}
      description={subtext}
      imgSrc={
        hideImage
          ? ""
          : `${SETTINGS.STATIC_URL}referral/share-job-modal-infographic.svg`
      }
      footer={finePrint}
      titleSize={titleSize}
      descriptionProps={descriptionProps}
      footerProps={footerProps}
    >
      <Box
        maxWidth={hideImage ? "90%" : "100%"}
        display="flex"
        mt={3}
        flexWrap={hideImage ? "wrap" : "nowrap"}
        flexDirection={{ xs: "column", lg: "row" }}
      >
        {!hideShareLink && (
          <Box mr={{ md: 0, lg: 1 }} mb={1} minWidth={0} width="100%">
            <CopyLinkBox
              referralLink={referralLink}
              handleCopy={handleCopy}
              type={type}
            />
          </Box>
        )}
        <Box mt={{ xs: 1.5, lg: 0 }} flexShrink={0}>
          {hideShareLink && (
            <Button
              variant="secondary"
              size="small"
              startIcon={<CopyIcon />}
              onClick={() => handleCopy(referralLink)}
              className={styles.referModalBtn}
            >
              Copy Link
            </Button>
          )}
        </Box>
      </Box>
    </ReferCard>
  );
};
