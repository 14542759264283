import type {
  BoxProps,
  ButtonProps,
  TypographyProps,
} from "@hexocean/braintrust-ui-components";
import { Box, Button, Tooltip } from "@hexocean/braintrust-ui-components";
import { SectionSubtitle } from "@js/apps/settings/forms/helpers";

type PaymentMethodsActionBarProps = {
  cannotAddPaymentMethodReason?: string;
  canAddPaymentMethod: boolean;
  creatingPaymentMethod: boolean;
  title?: string;
  description?: string;
  buttonLabel?: string;
  buttonProps: ButtonProps;
  titleProps?: Partial<TypographyProps>;
  containerProps?: BoxProps;
};

export const PaymentMethodsActionBar = ({
  canAddPaymentMethod,
  cannotAddPaymentMethodReason,
  creatingPaymentMethod,
  title,
  description,
  buttonLabel,
  buttonProps,
  titleProps,
  containerProps,
}: PaymentMethodsActionBarProps) => {
  return (
    <SectionSubtitle
      title={title}
      description={description}
      action={
        <Tooltip
          title={cannotAddPaymentMethodReason}
          disabled={
            canAddPaymentMethod || !cannotAddPaymentMethodReason?.length
          }
        >
          <Box display="flex">
            <Button
              {...buttonProps}
              disabled={creatingPaymentMethod || !canAddPaymentMethod}
            >
              {buttonLabel}
            </Button>
          </Box>
        </Tooltip>
      }
      containerProps={containerProps}
      {...titleProps}
    />
  );
};
