import type { FormErrors, WrappedFieldProps } from "redux-form";
import * as Sentry from "@sentry/react";

import type { WithRecaptchaCode } from "@js/types/common";
import type { ExecuteRecaptchaConfig } from "@js/utils";
import { executeRecaptcha } from "@js/utils/captcha";

const waitForErrorsToBeRendered = (delayMs = 50) =>
  new Promise((res) => setTimeout(res, delayMs));

export const scrollToFirstError = async (
  formId: string,
  errors: FormErrors,
  fieldName = "",
) => {
  if (!errors || typeof errors !== "object") {
    return;
  }

  await waitForErrorsToBeRendered();

  let resultFieldName = fieldName;
  for (const [key, value] of Object.entries(errors)) {
    if (!value) {
      continue;
    }

    if (typeof value === "string") {
      resultFieldName += fieldName ? `[${key}]` : key;
      scrollFieldWithErrorIntoView(formId, resultFieldName);

      return;
    }

    if (Array.isArray(value)) {
      resultFieldName += fieldName ? `.${key}` : key;
      scrollFieldWithErrorIntoView(formId, resultFieldName);

      return;
    }

    resultFieldName += fieldName ? `[${key}]` : key;

    scrollToFirstError(formId, value, resultFieldName);

    return;
  }
};

const scrollFieldWithErrorIntoView = (formId: string, fieldName: string) => {
  const element = getErrorLabelElement(formId, fieldName);

  if (!element || !("scrollIntoView" in element)) {
    return;
  }

  if (element instanceof HTMLElement) {
    element?.focus({ preventScroll: true });
  }

  element.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};

const getErrorLabelElement = (
  formId: string,
  fieldName: string,
): Element | null | undefined => {
  const subselectors = [
    `[id="select-${fieldName}"]`,
    `[id="${fieldName}"]`,
    ".Mui-error",
    ".error",
    ".section-panel--error",
    ".error-section",
    ".general-form-error",
  ];
  // must be last

  const findElementInForm = (subSelectors: string[]) => {
    const formElement = document.querySelector(`#${formId}`);

    if (!formElement) {
      return null;
    }

    let element;

    for (const subselector of subSelectors) {
      element = formElement.querySelector(subselector);

      if (element) break;
    }

    return element;
  };

  return findElementInForm(subselectors);
};

export const maxLength = (value: string, max: number) => {
  if (value && value.length > max) {
    return value.substring(0, max);
  }

  return value;
};

export const getValuesWithReCaptchaCode = async <
  V extends Record<string, unknown>,
>(
  values: V,
  actionName: EnumType<typeof ENUMS.CaptchaActions>,
  config?: ExecuteRecaptchaConfig,
): Promise<WithRecaptchaCode<V>> => {
  let recaptchaResponse: string | undefined = "";

  try {
    recaptchaResponse = await executeRecaptcha(actionName, config);
  } catch (error) {
    Sentry.captureException("Recaptcha execute issue", { extra: { error } });
  }

  return {
    ...values,
    "g-recaptcha-response": recaptchaResponse,
  };
};

export const shouldDisplayError = <T = string>(
  field: TypedWrappedFieldProps<T> | TypedWrappedFieldProps<T>[],
) => {
  const fields = Array.isArray(field) ? field : [field];

  const submitFailed = fields.reduce((prev, current) => {
    if (!current?.meta) return false;

    return current.meta.submitFailed || prev;
  }, false);

  return isError(field) && (submitFailed || isTouched(field));
};

export const isError = <T>(
  field: TypedWrappedFieldProps<T> | TypedWrappedFieldProps<T>[],
) => {
  const fields = Array.isArray(field) ? field : [field];

  const isErrorInFields = fields.reduce((prev, current) => {
    if (!current?.meta) return false;

    const errors = Array.isArray(current.meta.error)
      ? current.meta.error
      : [current.meta.error];
    const filtered = errors.filter((e) => !!e);

    return filtered.length > 0 || prev;
  }, false);

  return isErrorInFields;
};

export const isTouched = <T>(
  field: TypedWrappedFieldProps<T> | TypedWrappedFieldProps<T>[],
): boolean => {
  const fields = Array.isArray(field) ? field : [field];

  const fieldWasTouched = fields.reduce((prev, current) => {
    if (!current?.meta) return false;

    return current.meta.touched || prev;
  }, false);

  return fieldWasTouched;
};

export const areAllTouched = (
  field: WrappedFieldProps | WrappedFieldProps[],
): boolean => {
  const fields = Array.isArray(field) ? field : [field];

  const notTouched = fields.some((el) => el.meta.touched === false);
  return !notTouched;
};

export const getError = <T = string>(
  field: TypedWrappedFieldProps<T> | TypedWrappedFieldProps<T>[],
) => {
  const fields = Array.isArray(field) ? field : [field];

  return fields.find((f) => !!f.meta.error)?.meta.error || "";
};

export type TypedWrappedFieldProps<T = ""> = Omit<
  WrappedFieldProps,
  "input"
> & {
  input: Omit<WrappedFieldProps["input"], "value" | "onChange"> & {
    value: T;
    onChange: (newValue: T) => void;
  };
};

export type TypedWrappedFieldInputProps<T> = TypedWrappedFieldProps<T>["input"];
