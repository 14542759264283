import { useMemo } from "react";

import { useAppSelector } from "@js/hooks";

import {
  canBeRejected,
  getBids,
} from "../../components/bulk-select-actions/bid-bulk-selection-slice";

export const useSelectedRejectableIds = () => {
  const bids = useAppSelector(getBids);
  const selectedRejectableIds = useMemo(
    () =>
      bids.filter((bid) => canBeRejected(bid)).map((selected) => selected.id),
    [bids],
  );

  return { selectedRejectableIds };
};
