import cs from "classnames";

import { IconButton } from "@hexocean/braintrust-ui-components";
import {
  SortDownIcon,
  SortUpIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import type { OrderByDirectionType } from "../../types";

import type { ReferralsSortButtonColorVariantsType } from "./referrals-sort-button";

import style from "./style.module.scss";

type ReferralsSortDirectionButtonFunctionalProps = {
  onChange: (paramValue: OrderByDirectionType) => void;
  direction: OrderByDirectionType;
};

export type ReferralsSortDirectionButtonAppearanceProps = {
  colorVariant: ReferralsSortButtonColorVariantsType;
};

type ReferralsSortDirectionButtonProps =
  ReferralsSortDirectionButtonFunctionalProps &
    ReferralsSortDirectionButtonAppearanceProps;

export const ReferralsSortDirectionButton = ({
  onChange,
  direction,
  colorVariant,
}: ReferralsSortDirectionButtonProps) => {
  const isAscending = direction === "asc";

  const onChangeHandler = () => {
    const newSortDirection = direction === "desc" ? "asc" : "desc";
    onChange(newSortDirection);
  };

  return (
    <IconButton
      aria-label="Sort order"
      variant={`medium-${colorVariant}`}
      name="referrals-sort-order-button"
      onClick={onChangeHandler}
      className={cs(style.sortIconButton)}
      size="medium"
    >
      {isAscending ? (
        <SortUpIcon sx={{ color: "#3B3B3B" }} />
      ) : (
        <SortDownIcon sx={{ color: "#3B3B3B" }} />
      )}
    </IconButton>
  );
};
