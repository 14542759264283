import type { ReactNode } from "react";

import {
  Box,
  Button,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import type { GetEmployerInvoiceStatisticsResponse } from "@js/apps/invoices/types";
import { openPaymentModal } from "@js/apps/payments/components/payment-modal";
import { openOTPDisabledWarningModal } from "@js/apps/settings/components/otp-auth/otp-disabled-warning";
import { formatCurrency, pluralize } from "@js/utils";

import { EmployerInvoicesStatisticValue } from "./employer-invoices-statistic-value";
import { EmployerInvoicesStatisticWrapper } from "./employer-invoices-statistic-wrapper";

type EmployerInvoiceDueStatisticProps = {
  isLoading: boolean;
} & DueInvoicesImportantItemsProps;

export const EmployerInvoicesDueStatistic = ({
  isLoading,
  data,
}: EmployerInvoiceDueStatisticProps) => {
  return (
    <EmployerInvoicesStatisticWrapper data-testid="due-statistics">
      <Stack
        sx={{
          gap: { xs: 1, md: 2 },
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        <EmployerInvoicesStatisticValue
          isLoading={isLoading}
          value={data?.due_within_30_days_amount}
          label="Due within 30 days"
        />
        {!isLoading && <DueInvoicesImportantItemsActions data={data} />}
      </Stack>
    </EmployerInvoicesStatisticWrapper>
  );
};

type DueInvoicesImportantItemsProps = {
  data:
    | Pick<
        GetEmployerInvoiceStatisticsResponse,
        | "due_within_30_days_amount"
        | "days_until_due_date"
        | "next_due_invoices"
        | "overdue_invoices"
        | "total_invoices_count"
        | "next_due_invoices_amount"
      >
    | undefined;
};

const DueInvoicesImportantItemsActions = ({
  data,
}: DueInvoicesImportantItemsProps) => {
  const user = useUser();

  const handlePayInvoices = (invoiceIds: number[]) => {
    if (!user?.is_otp_enabled) {
      openOTPDisabledWarningModal();
      return;
    }

    const invoiceIdsToPay = invoiceIds.slice(
      0,
      SETTINGS.INVOICES_PAYMENT_LIMIT,
    );

    openPaymentModal({
      invoiceIds: invoiceIdsToPay,
    });
  };

  switch (true) {
    case data?.total_invoices_count === 0:
      return <NoInvoicesAction />;
    case !!data?.overdue_invoices?.length:
      return (
        <PayOverdueInvoicesAction
          overdueInvoices={data.overdue_invoices}
          onPayInvoicesClick={() => handlePayInvoices(data.overdue_invoices)}
        />
      );
    case data && !!data.next_due_invoices?.length:
      return (
        <PayDueInvoicesAction
          nextDueInvoices={data.next_due_invoices}
          nextDueInvoicesAmount={data.next_due_invoices_amount}
          nextDueInvoicesDays={data.days_until_due_date ?? 0}
          onPayInvoicesClick={() => handlePayInvoices(data.next_due_invoices)}
        />
      );
    default:
      return <NoDueInvoicesAction />;
  }
};

type DueInvoiceImportantItemsActionContainerProps = {
  title?: string;
  content: ReactNode;
  action?: ReactNode;
  variant: "green" | "red";
};

const InvoicesActionContainer = ({
  title,
  content,
  action,
  variant,
}: DueInvoiceImportantItemsActionContainerProps) => {
  return (
    <Stack
      sx={{
        py: 2,
        px: { xs: 2, md: 3 },
        backgroundColor: `var(--medium-${variant})`,
        borderRadius: "10px",
        maxWidth: "300px",
        minHeight: "126px",
        justifyContent: "center",
      }}
    >
      {title && (
        <Typography component="h3" variant="label" size="medium" sx={{ mb: 1 }}>
          {title}
        </Typography>
      )}
      <Typography component="p" variant="paragraph" size="medium">
        {content}
      </Typography>
      {action && <Box sx={{ mt: "10px" }}>{action}</Box>}
    </Stack>
  );
};

const PayOverdueInvoicesAction = ({
  overdueInvoices,
  onPayInvoicesClick,
}: {
  overdueInvoices: number[];
  onPayInvoicesClick: () => void;
}) => {
  const overdueInvoicesCount = overdueInvoices.length;
  const content =
    overdueInvoicesCount > 1
      ? `Catch up on your ${overdueInvoicesCount} overdue invoices.`
      : "Catch up on your overdue invoice.";

  const handlePayOverueInvoicesClick = () => {
    onPayInvoicesClick();
  };

  return (
    <InvoicesActionContainer
      content={content}
      variant="red"
      action={
        <Button
          variant="primary"
          shape="squared"
          size="x-small"
          onClick={handlePayOverueInvoicesClick}
        >
          Pay overdue invoices
        </Button>
      }
    />
  );
};

const PayDueInvoicesAction = ({
  nextDueInvoices,
  nextDueInvoicesAmount,
  nextDueInvoicesDays,
  onPayInvoicesClick,
}: {
  nextDueInvoices: number[];
  nextDueInvoicesAmount: string;
  nextDueInvoicesDays: number;
  onPayInvoicesClick: () => void;
}) => {
  const paymentAmount = formatCurrency(nextDueInvoicesAmount ?? 0);
  const daysText =
    nextDueInvoicesDays > 0
      ? `in ${nextDueInvoicesDays} day${pluralize(nextDueInvoicesDays, { zeroPlural: true })}`
      : "today";
  const nextDueInvoicesCount = nextDueInvoices.length;
  const content =
    nextDueInvoicesCount > 1 ? (
      <>
        There are <strong>{nextDueInvoicesCount} invoices</strong> totaling{" "}
        <strong>{paymentAmount}</strong> due {daysText}.
      </>
    ) : (
      <>
        Next invoice amount of <strong>{paymentAmount}</strong> is due{" "}
        {daysText}.
      </>
    );

  const buttonLabel = `Pay invoice${pluralize(nextDueInvoicesCount, { zeroPlural: true })}`;

  const handlePayDueInvoicesClick = () => {
    onPayInvoicesClick();
  };

  return (
    <InvoicesActionContainer
      content={content}
      variant="green"
      action={
        <Button
          variant="primary"
          shape="squared"
          size="x-small"
          onClick={handlePayDueInvoicesClick}
        >
          {buttonLabel}
        </Button>
      }
    />
  );
};

const NoDueInvoicesAction = () => {
  return (
    <InvoicesActionContainer
      content="There are no outstanding invoices, keep up the great work."
      variant="green"
      title="🎉 You’re all caught up!"
    />
  );
};

const NoInvoicesAction = () => {
  return (
    <InvoicesActionContainer
      content="Once talent has submitted their invoices they will appear here."
      variant="green"
      title="There are no invoices yet."
    />
  );
};
