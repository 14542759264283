import { useEffect, useMemo, useState } from "react";

import { type Location } from "@js/apps/common/components/filters/config";
import { useAccountType } from "@js/apps/common/hooks";
import { useGetPostCategoriesQuery } from "@js/apps/give-and-get-help/api";
import { createHelpCategoryString } from "@js/apps/jobs/apps/listing/utils/create-help-category-string";
import { createSkillsString } from "@js/apps/jobs/apps/listing/utils/create-skills-string";
import { useGoogleMaps } from "@js/hooks";
import type { Skill } from "@js/types/admin";

export const WORK_FROM_ANYWHERE_LABEL = "Work from anywhere";

export const useCreateSkillsLabelsString = (skillsObjectsArray: Skill[]) => {
  const skillsLabelsString = useMemo(
    () => createSkillsString(skillsObjectsArray),
    [skillsObjectsArray],
  );

  if (!skillsObjectsArray) {
    return null;
  }

  return skillsLabelsString;
};

export const useCreateHelpCategoryString = (id?: number) => {
  const { isEmployer } = useAccountType();
  const { data } = useGetPostCategoriesQuery(undefined, {
    skip: !id || isEmployer,
  });

  return createHelpCategoryString(id, data);
};

export const createJobLocationString = (filters: Location) => {
  const { work_from_anywhere, custom_location, city, country_code } = filters;
  const custom_locations = custom_location?.split(",");
  const isCustomLocationSelected =
    custom_locations &&
    custom_locations.every(
      (location) => location in ENUMS.JobCustomLocationTypeLabels,
    );
  const isMyLocationSelected = city && country_code;
  const myLocation = `${city}, ${country_code}`;

  if (work_from_anywhere) {
    if (isCustomLocationSelected) {
      return `${WORK_FROM_ANYWHERE_LABEL}, ${custom_locations
        .map(
          (location) =>
            ENUMS.JobCustomLocationTypeLabels[
              location as keyof typeof ENUMS.JobCustomLocationTypeLabels
            ],
        )
        .join(", ")}`;
    }

    if (isMyLocationSelected) {
      return `${WORK_FROM_ANYWHERE_LABEL}, ${myLocation}`;
    }

    return WORK_FROM_ANYWHERE_LABEL;
  }

  if (isCustomLocationSelected) {
    return custom_locations
      .map(
        (location) =>
          ENUMS.JobCustomLocationTypeLabels[
            location as keyof typeof ENUMS.JobCustomLocationTypeLabels
          ],
      )
      .join(", ");
  }

  if (isMyLocationSelected) {
    return myLocation;
  }

  return null;
};

export const useCreateTalentLocationString = (
  filters: Location,
): string | null => {
  const { getPlaceDetails } = useGoogleMaps();
  const [googlePlacesString, setGooglePlacesString] = useState<string | null>(
    null,
  );

  const { custom_location, place_id } = filters;

  useEffect(() => {
    const placeIds = place_id?.split(",");
    if (placeIds?.length) {
      setGooglePlacesString("...");
      const promises = placeIds.map((id) => getPlaceDetails({ placeId: id }));
      Promise.all(promises)
        .then((result) => {
          const joinedLocations = result
            .map((place) => place?.formatted_address)
            .join(", ");
          setGooglePlacesString(joinedLocations);
        })
        .catch(() => {
          setGooglePlacesString(null);
        });
    }
  }, [getPlaceDetails, place_id]);

  const getCustomLocationsString = () => {
    const custom_locations = custom_location?.split(",");
    const isCustomLocationSelected =
      custom_locations &&
      custom_locations.every(
        (customLocation) => customLocation in ENUMS.JobCustomLocationTypeLabels,
      );

    if (isCustomLocationSelected) {
      return custom_locations
        .map(
          (customLocation) =>
            ENUMS.JobCustomLocationTypeLabels[
              customLocation as keyof typeof ENUMS.JobCustomLocationTypeLabels
            ],
        )
        .join(", ");
    }

    return null;
  };

  return (
    [getCustomLocationsString(), googlePlacesString]
      .filter(Boolean)
      .join(", ") || null
  );
};

type CreateCommitment = (
  availabilityFrom: number | undefined,
  availabilityTo: number | undefined,
) => string | null;

export const createCommitmentString: CreateCommitment = (
  availabilityFrom,
  availabilityTo,
) => {
  if (!availabilityFrom || !availabilityTo) return null;
  if (availabilityFrom === availabilityTo) return `${availabilityFrom} hrs/wk`;
  return `${availabilityFrom}-${availabilityTo} hrs/wk`;
};

export const createJobTypeString = (jobType: string | undefined) => {
  if (typeof jobType !== "string") return null;
  const jobTypeString = jobType
    .split(",")
    .map(
      (item) => ENUMS.JobTypeLabels[item as keyof typeof ENUMS.JobTypeLabels],
    )
    .join(", ");
  return jobTypeString.length ? jobTypeString : null;
};

export const createAppliedRecentlyString = (
  appliedRecently?: boolean,
): string | null =>
  typeof appliedRecently === "boolean"
    ? appliedRecently
      ? "Applied to a job in the last 30 days"
      : null
    : null;

export const createLookingForWorkString = (
  lookingForWorkOptionString?: string,
): string | null => {
  if (!lookingForWorkOptionString) {
    return null;
  }

  const lookingForWorkString = lookingForWorkOptionString
    .split(",")
    .map(
      (option) =>
        ENUMS.FreelancerAvailabilityForWorkLabels[
          option as keyof typeof ENUMS.FreelancerAvailabilityForWorkLabels
        ],
    )
    .join(", ");

  return lookingForWorkString.length ? lookingForWorkString : null;
};
