import { type ReactNode } from "react";

import { Stack } from "@hexocean/braintrust-ui-components";

type PeopleTopContainerProps = {
  children: ReactNode;
};

export const PeopleTopContainer = ({ children }: PeopleTopContainerProps) => {
  return (
    <Stack
      sx={{
        flexDirection: { sm: "row" },
        flexWrap: { sm: "wrap" },
        alignItems: { sm: "start", md: "center" },
        gap: 1,
        mb: { xs: 3, md: 4 },
        px: { xs: 3, md: 4 },
      }}
    >
      {children}
    </Stack>
  );
};
