import React from "react";

import { useActivePost } from "../../hooks/use-active-post";
import { usePostsPage } from "../../hooks/use-posts-page";
import { FeedSorting } from "../feed-sorting";
import { PostsLoadingState } from "../ggh-loading-state";
import { VirtualizedPostsSection } from "../virtualized-posts-section";

export const FeedPostsSection = () => {
  const {
    posts,
    hasMore,
    handleFetchingMore,
    loading,
    loadingPosts,
    fetchingPosts,
    trendingHashtags,
  } = usePostsPage();
  const {
    postsToDisplay,
    activePostId,
    isFetchingActivePost,
    isLoadingActivePost,
  } = useActivePost({
    posts,
    loadingPosts,
  });

  return (
    <>
      <FeedSorting />
      {loading || isLoadingActivePost ? (
        <PostsLoadingState />
      ) : (
        <VirtualizedPostsSection
          posts={postsToDisplay}
          activePostId={activePostId}
          hasMore={hasMore}
          fetchMore={handleFetchingMore}
          fetchingPosts={fetchingPosts || isFetchingActivePost}
          trendingHashtags={trendingHashtags}
        />
      )}
    </>
  );
};
