import type {
  AriaRole,
  ComponentProps,
  ElementType,
  MouseEvent,
  ReactNode,
} from "react";
import cs from "classnames";

import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Button } from "@hexocean/braintrust-ui-components";
import { handleKeyDownConfirm } from "@js/utils/keyboard";

type InteractiveTagOwnProps<E extends ElementType> = {
  className?: string;
  tag?: E;
  children: ReactNode;
  role?: AriaRole;
  tabIndex?: number;
  onClick: (e?: MouseEvent) => void;
};

type InteractiveTagProps<E extends ElementType> = E extends typeof Button
  ? Omit<ButtonProps, "variant"> & InteractiveTagOwnProps<E>
  : InteractiveTagOwnProps<E> &
      Omit<ComponentProps<E>, keyof InteractiveTagOwnProps<E>>;

export const InteractiveTag = <E extends ElementType = typeof Button>({
  children,
  tag,
  onClick,
  className,
  ...otherProps
}: InteractiveTagProps<E>) => {
  const Tag = tag ?? Button;

  return (
    <Tag
      className={cs("pointer", { className })}
      onClick={onClick}
      role="button"
      onKeyDown={handleKeyDownConfirm(onClick)}
      variant="transparent"
      fontWeight={400}
      {...otherProps}
    >
      {children}
    </Tag>
  );
};
