import type { CSSProperties } from "react";

import { CUSTOM_EMOJIS_CATEGORY, findSpecificEmoji } from "./custom-emojis";

type EmojiProps = {
  emoji: string;
  size?: string | number;
  className?: string;
  style?: CSSProperties;
};

export const Emoji = ({
  emoji,
  size = "16px",
  className,
  style,
}: EmojiProps) => {
  // eg. custom 'braintrust' post category emoji
  const customEmojiData = findSpecificEmoji(
    CUSTOM_EMOJIS_CATEGORY.CUSTOM.id,
    emoji,
  );

  if (customEmojiData) {
    return (
      <img
        width={size}
        height={size}
        alt={customEmojiData.name}
        src={customEmojiData.skins[0].src}
        style={{
          display: "inline-block",
          ...style,
        }}
        className={className}
      />
    );
  }

  // This is only to not crash app if there are icons from other set (added before fix)
  return (
    <span
      style={{
        display: "inline-block",
        fontSize: size,
        overflow: "hidden",
        lineHeight: "initial",
        ...style,
      }}
      className={className}
    >
      {emoji}
    </span>
  );
};
