import React from "react";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { useAccountType } from "@js/apps/common/hooks";
import { CompanyName } from "@js/apps/freelancer/components";
import { PaymentMethodsSection } from "@js/apps/payments/components/payment-methods";
import { Section, SectionSubtitle } from "@js/apps/settings/forms/helpers";
import { WithdrawalMethodsSection } from "@js/apps/withdrawal/components";
import { WithdrawalMethodsContainer } from "@js/apps/withdrawal/containers/withdrawal-methods";

import { SettingsLayout } from "../layout";

import { TaxDocuments } from "./tax-documents";

const PaymentsPage = (): JSX.Element => {
  const { isFreelancer, isEmployer } = useAccountType();

  return (
    <SettingsLayout pageTitle="Payments">
      {isFreelancer && <FreelancerContent />}
      {isEmployer && <EmployerContent />}
    </SettingsLayout>
  );
};

const EmployerContent = () => {
  return (
    <Section title="Payments">
      <PaymentMethodsSection />
    </Section>
  );
};

const FreelancerContent = () => {
  return (
    <>
      <Section title="Banking Information">
        <WithdrawalMethodsContainer
          render={(props) => {
            const { creatingWithdrawalMethod, onChooseWithdrawalMethod } =
              props;

            return (
              <>
                <SectionSubtitle
                  title="Methods"
                  description="Before we can pay you for a hired project, you need to provide your withdrawal method."
                  action={
                    <Button
                      disabled={creatingWithdrawalMethod}
                      variant="secondary"
                      onClick={onChooseWithdrawalMethod}
                    >
                      Add withdrawal method
                    </Button>
                  }
                />
                <WithdrawalMethodsSection {...props} />
              </>
            );
          }}
        />
      </Section>
      <Section title="Tax Documents">
        <TaxDocuments />
      </Section>
      <Section title="Invoice display name">
        <Typography
          className="max-width-600"
          component="p"
          color="secondary"
          mb={4}
        >
          You can add an optional company name to your invoices for reporting
          purposes.
        </Typography>
        <CompanyName />
      </Section>
    </>
  );
};

export default PaymentsPage;
