import { useCallback, useMemo } from "react";

import { shareApplicantBulkClicked } from "@js/apps/employer/actions";
import { openShareBidsWithTeamMembersModal } from "@js/apps/employer/components/share-bids-with-team-members-modal";
import { useCanShareWithTeamMembers } from "@js/apps/employer/hooks/use-can-share-with-team-members";
import { useBidsContext } from "@js/apps/jobs/context/refetch-bids-context";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { useHideProposalsMutation } from "../../api";
import { hideApplications } from "../../components/bid-actions-menu/hide-applications";
import {
  openInterviewRequestMessage,
  openRejectOfferMessageModal,
} from "../../components/bid-messages-modals";
import {
  getIsAnyOfSelectedBidsUnapproved,
  getSelectedBidsTotal,
  resetBidsTotal,
} from "../../components/bulk-select-actions/bid-bulk-selection-slice";
import { useSelectedBidsIds } from "../use-selected-bids-ids";
import { useSelectedInterviewableBids } from "../use-selected-interviewable-bids";
import { useSelectedInterviewableIds } from "../use-selected-interviewable-ids";
import { useSelectedRejectableIds } from "../use-selected-rejectable-ids";

export const useBulkSelectActions = (
  job: Job,
  bidList: EmployerBidListBid[],
) => {
  const [hideProposals] = useHideProposalsMutation();
  const selectedBidsTotal = useAppSelector(getSelectedBidsTotal);
  const { selectedBidsIds } = useSelectedBidsIds();
  const isAnyOfSelectedBidsUnapproved = useAppSelector(
    getIsAnyOfSelectedBidsUnapproved,
  );
  const { refetchBidList } = useBidsContext();
  const dispatch = useAppDispatch();
  const { selectedInterviewableIds } = useSelectedInterviewableIds();
  const { selectedInterviewableBids } = useSelectedInterviewableBids();
  const { selectedRejectableIds } = useSelectedRejectableIds();
  const canSendRejection = selectedRejectableIds.length > 0;
  const canSendInterviewRequest =
    selectedInterviewableIds.length > 0 && job.is_open;

  const handleHideProposals = async (bidIds: number[], isHidden: boolean) => {
    await hideProposals({ bidIds, isHidden });
  };

  const areAllSelectedBidsHidden = useMemo(() => {
    return (
      bidList.filter((el) => selectedBidsIds.includes(el.id) && el.is_hidden)
        .length === selectedBidsIds.length
    );
  }, [bidList, selectedBidsIds]);

  const bulkHideBids = useCallback(
    (isShowingHiddenBids: boolean) => {
      hideApplications(
        selectedBidsIds,
        handleHideProposals,
        isShowingHiddenBids,
      );
      dispatch(resetBidsTotal());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedBidsIds, handleHideProposals],
  );

  const getBid = useCallback(() => {
    if (selectedInterviewableIds.length === 1) {
      const bidId = selectedInterviewableIds[0];
      return bidList.find((bid) => bid.id === bidId);
    }
  }, [selectedInterviewableIds, bidList]);

  const bulkSendInterviewRequest = useCallback(() => {
    openInterviewRequestMessage({
      job,
      refetchBidList,
      bid: getBid(),
      interviewableBids: selectedInterviewableBids,
    });
  }, [job, refetchBidList, getBid, selectedInterviewableBids]);

  const bulkRejectOffer = useCallback(() => {
    if (selectedRejectableIds.length === 1) {
      const bid = bidList.find((el) => el.id === selectedRejectableIds[0]);
      if (bid) {
        openRejectOfferMessageModal({
          refetchBidList,
          bid: bid,
          nextUrl: `/jobs/${job.id}/proposals/${bid.id}/rejection_feedback`,
          job,
        });
      }
    } else {
      openRejectOfferMessageModal({
        refetchBidList,
        bidList: selectedRejectableIds,
        job,
      });
    }
  }, [selectedRejectableIds, bidList, refetchBidList, job]);

  const canShareBidsWithTeamMembers = useCanShareWithTeamMembers();

  const handleShareBidsClick = () => {
    dispatch(shareApplicantBulkClicked());
    openShareBidsWithTeamMembersModal({
      jobOwnerId: job.creator?.id,
      bidIds: selectedBidsIds,
    });
  };

  return {
    bulkHideBids,
    bulkSendInterviewRequest,
    bulkRejectOffer,
    handleShareBidsClick,
    canShareBidsWithTeamMembers,
    selectedBidsTotal,
    canSendInterviewRequest,
    canSendRejection,
    isAnyOfSelectedBidsUnapproved,
    areAllSelectedBidsHidden,
  };
};
