import { LocationMismatchAlert } from "@js/components/location-mismatch-alert";

import { CloseButton, Title } from "./components";

import styles from "../../styles.module.scss";

export type LocationMismatchModalHeaderProps = {
  userFirstName: string;
  sessionLocationMismatch: boolean;
  billingLocationMismatch: boolean;
  onClose: () => void;
};

export const LocationMismatchModalHeader = ({
  userFirstName,
  sessionLocationMismatch,
  billingLocationMismatch,
  onClose,
}: LocationMismatchModalHeaderProps) => {
  return (
    <div className={styles.modalHeader}>
      <Title text="Location mismatch" />
      <CloseButton onClose={onClose} />
      <LocationMismatchAlert
        userFirstName={userFirstName}
        sessionLocationMismatch={sessionLocationMismatch}
        billingLocationMismatch={billingLocationMismatch}
      />
    </div>
  );
};
