import { Typography } from "@hexocean/braintrust-ui-components";

export const ReferTopSection = () => {
  return (
    <div>
      <Typography
        variant="title"
        component="h1"
        size="large"
        mb={4}
        fontWeight={400}
      >
        Refer and Earn
      </Typography>

      <div>
        <Typography
          variant="title"
          component="h2"
          size="medium"
          fontWeight={400}
          mb={2.75}
        >
          Grow the network. Earn BTRST.
        </Typography>

        <Typography variant="paragraph" component="p" size="large">
          Spread the word to potential clients and talent, and if they join and
          use Braintrust, you’ll get rewarded in BTRST, giving you ownership in
          the network. All you have to do is share the link!
        </Typography>
      </div>
    </div>
  );
};
