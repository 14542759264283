import type { FC } from "react";
import React from "react";
import type { ConfigProps } from "redux-form";
import { Field } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { createFormInstance } from "@js/forms/components";
import { TextareaField, TextField } from "@js/forms/fields";

export type GetToKnowMeFormData = {
  introduction_headline: string | null;
  introduction: string | null;
};

const GetToKnowMeFormInstance = createFormInstance<
  GetToKnowMeFormData,
  unknown
>("get-to-know-me-form");

type GetToKnowMeFormProps = {
  onSubmit: ConfigProps<GetToKnowMeFormData>["onSubmit"];
  onSubmitSuccess: ConfigProps<GetToKnowMeFormData>["onSubmitSuccess"];
  initialValues: GetToKnowMeFormData;
};

export const GetToKnowMeForm: FC<GetToKnowMeFormProps> = (props) => {
  return (
    <GetToKnowMeFormInstance {...props}>
      <Box>
        <Box display="flex" gap={1} alignItems="center">
          <Box fontSize={30}>👋</Box>
          <Typography component="h3" variant="title" size="small">
            Bio
          </Typography>
        </Box>
        <Typography
          component="p"
          variant="paragraph"
          size="large"
          mt={2}
          mb={4}
        >
          Introduce yourself to the community.
        </Typography>
        <Box mb={4}>
          <Field
            id="introduction_headline"
            name="introduction_headline"
            component={TextField}
            maxLength={55}
            label="Headline*"
            showCount
            variant="outlined"
            placeholder="Ex: Product Design Manager"
          />
        </Box>
        <Field
          label="Your bio*"
          name="introduction"
          id="introduction"
          component={TextareaField}
          maxLength={1000}
          placeholder="Add a short bio to showcase your best self."
          InputProps={{
            style: { paddingLeft: 0 },
          }}
        />
        <Box mt={4} display="flex" justifyContent="flex-end">
          <Button variant="positive" shape="squared" type="submit">
            Save
          </Button>
        </Box>
      </Box>
    </GetToKnowMeFormInstance>
  );
};
