import { Field, reduxForm } from "redux-form";

import { Form } from "@js/forms/components/form";
import { NumberField, SelectField, TextField } from "@js/forms/fields";
import { countrySelectOptions, enumToOptions } from "@js/utils";

import { StripeACHBankAccountTypesWithLabels } from "../../constants";
import type { CreateACHPaymentMethodStripeResult } from "../../types";

export const ACH_FORM_ID = "ACH_METHOD_FORM";

export type ACHMethodFormData = Pick<
  CreateACHPaymentMethodStripeResult,
  | "account_holder_name"
  | "account_holder_type"
  | "account_number"
  | "routing_number"
  | "country"
>;

export const ACHForm = reduxForm<ACHMethodFormData, unknown>({
  form: ACH_FORM_ID,
  enableReinitialize: true,
})(({ submit, submitting, error }) => (
  <Form onSubmit={submitting ? null : submit} error={error}>
    <Field
      className="form-line"
      id="country"
      name="country"
      component={SelectField}
      variant="standard"
      options={countrySelectOptions}
      label="Country"
    />
    <Field
      className="form-line"
      id="account_holder_type"
      name="account_holder_type"
      component={SelectField}
      variant="standard"
      options={enumToOptions(StripeACHBankAccountTypesWithLabels)}
      label="Account Type"
    />
    <Field
      className="form-line"
      variant="standard"
      id="account_holder_name"
      name="account_holder_name"
      component={TextField}
      label="Account Holder Name"
      size="small"
    />
    <Field
      className="form-line"
      variant="standard"
      id="routing_number"
      name="routing_number"
      component={NumberField}
      inputProps={{
        allowNegative: false,
      }}
      label="Routing Number"
      size="small"
    />
    <Field
      className="form-line"
      variant="standard"
      id="account_number"
      name="account_number"
      component={NumberField}
      label="Account Number"
      inputProps={{
        allowNegative: false,
      }}
      size="small"
    />
  </Form>
));
