import React, { type ReactNode } from "react";

import {
  Button,
  type ButtonVariants,
  Stack,
} from "@hexocean/braintrust-ui-components";

export type ModalConfirmCommonProps = {
  children?: React.ReactNode;
  onCancel?: () => void;
  onConfirm?: (() => void) | (() => Promise<void>);
  onConfirm2?: (() => void) | (() => Promise<void>);
  confirmButtonType?: "submit";
  cancelText?: string | ReactNode;
  confirmText?: string | ReactNode;
  confirmText2?: string | ReactNode;
  cancelButtonVariant?: ButtonVariants;
  confirmButtonVariant?: ButtonVariants;
  confirmButtonStyle?: React.CSSProperties;
  confirmButtonVariant2?: ButtonVariants;
  confirmDisabled?: boolean;
  confirmDisabled2?: boolean;
  buttonsSquareShape?: boolean;
  buttonsOnEnds?: boolean;
};

export const ModalConfirmContent: React.FC<ModalConfirmCommonProps> = ({
  children,
  onCancel,
  cancelButtonVariant,
  buttonsSquareShape,
  cancelText,
  onConfirm,
  confirmButtonType,
  confirmButtonVariant,
  confirmDisabled,
  confirmButtonStyle,
  confirmText,
  onConfirm2,
  confirmButtonVariant2,
  confirmDisabled2,
  confirmText2,
  buttonsOnEnds,
}) => {
  return (
    <>
      {children}
      <Stack
        direction="row"
        sx={{
          gap: 1,
          justifyContent: buttonsOnEnds ? "space-between" : "end",
          flexWrap: "wrap",
          mt: "45px",
        }}
      >
        {!!onCancel && (
          <Button
            variant={cancelButtonVariant || "secondary"}
            shape={buttonsSquareShape ? "squared" : "default"}
            onClick={onCancel}
          >
            {cancelText || "Cancel"}
          </Button>
        )}
        {!!onConfirm && (
          <Button
            variant={confirmButtonVariant || "primary"}
            disabled={confirmDisabled}
            aria-disabled={confirmDisabled}
            shape={buttonsSquareShape ? "squared" : "default"}
            onClick={onConfirm}
            type={confirmButtonType}
            style={confirmButtonStyle}
          >
            {confirmText || "Okay"}
          </Button>
        )}
        {!!onConfirm2 && (
          <Button
            variant={confirmButtonVariant2 || "primary"}
            aria-disabled={confirmDisabled2}
            disabled={confirmDisabled2}
            shape={buttonsSquareShape ? "squared" : "default"}
            onClick={onConfirm2}
          >
            {confirmText2 || "Okay"}
          </Button>
        )}
      </Stack>
    </>
  );
};
