import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { generateRateOptions } from "@js/apps/jobs/utils";
import { DollarNumberField } from "@js/forms/fields/number/number";
import { getError, shouldDisplayError } from "@js/forms/utils";
import type { Job } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

import { RateFieldPanel } from "./components";
import {
  PaymentAmountCustomField,
  renderDefaultPopoverContent,
} from "./payment-amount-field";

type FreelanceJobBidRateFieldProps = {
  title: string;
  description: string;
  job: Job;
  id: string;
} & TypedWrappedFieldProps<string>;

export const FreelanceJobBidRateField = ({
  title,
  description,
  job,
  ...props
}: FreelanceJobBidRateFieldProps) => {
  const displayError = shouldDisplayError(props);

  return (
    <RateFieldPanel
      title={title}
      description={description}
      displayError={displayError}
      error={getError(props)}
    >
      <FreelanceRateComponent job={job} {...props} />
    </RateFieldPanel>
  );
};

type FreelanceRateComponentProps = Omit<
  FreelanceJobBidRateFieldProps,
  "title" | "description"
>;

const FreelanceRateComponent = ({
  job,
  ...props
}: FreelanceRateComponentProps) => {
  switch (job.payment_type) {
    case ENUMS.JobPaymentType.HOURLY: {
      const hourlyRateOptions = generateRateOptions(
        job.budget_minimum_usd,
        job.budget_maximum_usd,
      );

      return (
        <PaymentAmountCustomField
          options={hourlyRateOptions}
          renderPopoverContent={() =>
            renderDefaultPopoverContent(job.payment_type)
          }
          renderLabel={{
            initial: "Custom",
            selected: (value) => `${value}`,
          }}
          {...props}
        />
      );
    }
    case ENUMS.JobPaymentType.ANNUAL:
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      return (
        <DollarNumberField
          hideError
          name="payment_amount"
          {...props}
          input={props.input}
          placeholder="Enter amount"
          inputProps={{ style: { paddingLeft: "5px" } }}
          sx={{
            "& input::placeholder": {
              color: "var(--grey-3)",
            },
          }}
          size="medium"
          inputTextSize="medium"
        />
      );
    }
    default: {
      assertUnreachable(job.payment_type);
      return null;
    }
  }
};
