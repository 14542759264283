import { useMemo } from "react";

import { formatDate, getToday } from "@js/utils";
import { DateFormats } from "@js/utils/date/types";

export const useInvoiceIssueDate = () => {
  const date = useMemo(() => {
    const today = getToday({ type: "date", utc: true });

    return formatDate(today, DateFormats["1970-12-31"]);
  }, []);

  return date;
};
