import { JOB_LOCATION } from "@js/apps/jobs/context";
import type { SearchEventEntityTypeKey } from "@js/types/tracking";

import type { SearchEvent } from "./types";

export const getSearchEntityType = (
  dataType: SearchEventEntityTypeKey,
): SearchEvent["search_entity_type"] => {
  return ENUMS.SearchEventEntityType[dataType];
};

// since introducing new universal search, we want to treat location of search events from job_listing as legacy_job_search
export const jobListingToLegacySearch = (
  location: EnumType<typeof JOB_LOCATION>,
): EnumType<typeof JOB_LOCATION> => {
  if (
    location !== JOB_LOCATION.job_listing &&
    location !== JOB_LOCATION.jobs_landing_page
  )
    return location;

  return JOB_LOCATION.legacy_job_search;
};
