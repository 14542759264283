import React from "react";
import type { Opts } from "linkifyjs";

import type { TypographyProps } from "@hexocean/braintrust-ui-components";
import { Typography } from "@hexocean/braintrust-ui-components";

import { RouterLink } from "../link";

type RenderLinkProps = {
  attributes: Omit<TypographyProps, "component">;
  content: React.ReactNode;
};

export const getLinkifyRenderOptions = (): Opts => {
  const renderLink = ({ attributes, content }: RenderLinkProps) => {
    const { href, ...props } = attributes;

    return (
      <Typography
        component="link"
        to={href}
        RouterLink={RouterLink}
        fontWeight={500}
        variant="link"
        color="blue"
        noTextDecoration
        {...props}
      >
        {content}
      </Typography>
    );
  };

  return {
    rel: "noopener noreferrer",
    target: {
      url: "_blank",
    },
    render: {
      url: renderLink,
    },
  };
};
