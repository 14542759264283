import { useCallback, useState } from "react";

export type ExpandBid = (id: number) => void;
export type IsBidExpanded = (id: number) => boolean;

export const useExpandedBids = () => {
  const [expandedBids, setExpandedBids] = useState<number[]>([]);

  const expandBid = useCallback((id: number) => {
    setExpandedBids((p) =>
      p.includes(id) ? p.filter((v) => v !== id) : [...p, id],
    );
  }, []);

  const isBidExpanded = useCallback(
    (id: number) => expandedBids.includes(id),
    [expandedBids],
  );

  return {
    expandedBids,
    expandBid,
    isBidExpanded,
  };
};
