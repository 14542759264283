import { IconButton, Menu } from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import { shareProfileClicked } from "@js/apps/employer/actions";
import { openShareTalentWithTeamMembersModal } from "@js/apps/employer/components/share-talent-with-team-members-modal";
import { useCanShareWithTeamMembers } from "@js/apps/employer/hooks/use-can-share-with-team-members";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import { useAppDispatch } from "@js/hooks";
import type { Talent } from "@js/types/freelancer";

type TalentCardItemActionMenuProps = {
  talent: Talent;
  jobId?: number;
};

export const TalentCardItemActionMenu = ({
  talent,
  jobId,
}: TalentCardItemActionMenuProps) => {
  const canShareTalentWithMembers = useCanShareWithTeamMembers();
  const user = useUser();
  const dispatch = useAppDispatch();
  const { isEmployer } = useAccountType();
  const { openMessengerModal } = useOpenMessengerModal();

  if (!isEmployer || !user?.is_verified) {
    return <></>;
  }

  const canMessageTalent = !!jobId;

  const handleMessageClick = () => {
    if (!jobId) {
      return;
    }
    openMessengerModal({
      jobId: jobId,
      context: { participants: [user?.id, talent.user.id] },
    });
  };

  const handleShareProfileClick = () => {
    dispatch(shareProfileClicked());
    openShareTalentWithTeamMembersModal({ talentId: talent.id });
  };

  return (
    <Menu
      anchor={
        <IconButton
          aria-label="Open talent card menu"
          aria-controls={`talent-menu-${talent.id}`}
          aria-haspopup="true"
          variant="transparent"
          sx={{ position: "absolute", right: "8px", top: "15px" }}
        >
          <MoreVertIcon />
        </IconButton>
      }
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      {canMessageTalent && (
        <Menu.Item onClick={handleMessageClick}>Message</Menu.Item>
      )}
      {canShareTalentWithMembers && (
        <Menu.Item onClick={handleShareProfileClick}>Share profile</Menu.Item>
      )}
    </Menu>
  );
};
