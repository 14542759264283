import {
  Box,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";

import { JoinClientsReferralProgramForm } from "../../views/refer-clients-program/join-clients-referral-program-form";

import styles from "./style.module.scss";

type ModalContentProps = {
  onSubmitSuccess: () => void;
};

export const openSubmitLinkedInUrlModal = (props: ModalContentProps) => {
  ModalInstance.open({
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
    },
    className: styles.submitLinkedInUrlModal,
    children: <ModalContent {...props} />,
  });
};

const ModalContent = ({ onSubmitSuccess }: ModalContentProps) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Box>
      <Typography
        component="h3"
        variant={isMobile ? "paragraph" : "title"}
        size={isMobile ? "large" : "small"}
        fontWeight={400}
        mb="24px"
        mr="48px"
      >
        Submit your LinkedIn URL
      </Typography>

      <Typography component="p" variant="paragraph" mb="16px">
        Meet directly with the Braintrust team to maximize your referral
        potential by submitting your LinkedIn URL so we can review your network.
        <br />
        <br />
        If you're a good match for Braintrust, we will email to schedule time!
        As a reminder, we'll never reach out to your Connections without your
        permission.
      </Typography>

      <Box width="100%">
        <JoinClientsReferralProgramForm onSubmitSuccess={onSubmitSuccess} />
      </Box>
    </Box>
  );
};
