import { Outlet } from "react-router-dom";

import { FormLayoutWrapper } from "@js/apps/common/components";
import { CREATE_JOB_FORM_ID } from "@js/apps/jobs/apps/create-job/constants";
import { useCreateJob } from "@js/apps/jobs/apps/create-job/hooks/create-job";
import { JobFormContextProvider } from "@js/apps/jobs/context/job-form-context/job-form-context";
import type { OverwriteContentComponentProps } from "@js/layouts/app";
import { AppLayout } from "@js/layouts/app";

import { useJobDescriptionUpdate } from "../../hooks/use-job-description-update";

export const CreateJobFormLayoutWrapper = () => {
  return (
    <AppLayout
      disableSubheaderPortal
      OverwriteContentComponent={CreateJobWrapperContent}
      children={null}
    />
  );
};

export const CreateJobWrapperContent = (
  props: OverwriteContentComponentProps,
) => {
  const { loading, updateSubmitType, lastSavedDraft, ...formProps } =
    useCreateJob();
  const updateJobDescriptionContext = useJobDescriptionUpdate({
    formId: CREATE_JOB_FORM_ID,
  });

  return (
    <FormLayoutWrapper
      formId={CREATE_JOB_FORM_ID}
      formProps={formProps}
      loading={loading}
      {...props}
    >
      <JobFormContextProvider
        {...updateJobDescriptionContext}
        updateSubmitType={updateSubmitType}
        jobDraft={lastSavedDraft}
      >
        <Outlet />
      </JobFormContextProvider>
    </FormLayoutWrapper>
  );
};
