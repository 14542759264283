import { useGetJobQuery } from "@js/apps/jobs/api";

import { useFilteredBidList } from "../use-filtered-bid-list";

export const useJobBids = (jobId: number) => {
  const {
    bidList,
    isBidListLoading,
    isBidListFetching,
    refetchBidList,
    bidFilters,
    areFiltersDefault,
    total,
    isLastPage,
    resetFilters,
  } = useFilteredBidList({ jobId });
  const {
    data: job,
    refetch: refetchJob,
    isLoading: isJobLoading,
  } = useGetJobQuery({ id: Number(jobId) });

  return {
    loading: !job || isJobLoading,
    jobBidsLoading: isBidListLoading,
    jobBidsFetching: isBidListFetching,
    job,
    bidList,
    totalBidsCount: total || 0,
    refetchBidList,
    refetchJob,
    areFiltersDefault,
    isShowingHiddenBids: !!bidFilters?.is_hidden,
    isLastPage,
    resetFilters,
  };
};
