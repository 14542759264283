import { Field } from "redux-form";

import {
  Box,
  Button,
  Popover,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { SettingsRoundedIcon } from "@hexocean/braintrust-ui-components/Icons";
import { VisibilitySettingsSelectField } from "@js/apps/jobs/apps/create-job/fields/visibility-setting-field";

export const VisibilitySettingsPopover = () => {
  return (
    <Popover
      anchor={
        <Button
          variant="white-violet"
          shape="squared"
          startIcon={<SettingsRoundedIcon />}
        >
          Visibility
        </Button>
      }
    >
      <Box p={3} display="flex" flexDirection="column" gap={1}>
        <Typography component="p">Post visibility</Typography>
        <Field name="is_private" component={VisibilitySettingsSelectField} />
      </Box>
    </Popover>
  );
};
