import {
  useGetUniversalSearchPopularJobsQuery,
  useGetUniversalSearchPopularPostsQuery,
  useGetUniversalSearchPopularTalentQuery,
} from "@js/apps/universal-search/api";

import type { UniversalTabValue } from "../constants";
import { useDebouncedUniversalSearchPhrase } from "../universal-search-slice";

export const useUniversalSearchPopularSearches = ({
  currentTab,
  open,
}: {
  currentTab: UniversalTabValue;
  open: boolean;
}) => {
  const search = useDebouncedUniversalSearchPhrase();
  const commonSkip = !!search || !open;
  const {
    data: popularJobsResult,
    isLoading: isLoadingPopularJobsResult,
    isFetching: isFetchingPopularJobsResult,
  } = useGetUniversalSearchPopularJobsQuery(
    {},
    { skip: commonSkip || currentTab !== "JOBS" },
  );
  const {
    data: popularPostsResult,
    isLoading: isLoadingPopularPostsResult,
    isFetching: isFetchingPopularPostsResult,
  } = useGetUniversalSearchPopularPostsQuery(
    {},
    { skip: commonSkip || currentTab !== "CAREER_HELP" },
  );
  const {
    data: popularTalentResult,
    isLoading: isLoadingPopularTalentResult,
    isFetching: isFetchingPopularTalentResult,
  } = useGetUniversalSearchPopularTalentQuery(
    {},
    { skip: commonSkip || currentTab !== "PEOPLE" },
  );

  return {
    popularData: {
      jobResults: popularJobsResult,
      postResults: popularPostsResult,
      talentResults: popularTalentResult,
    },
    isLoadingPopularData:
      isLoadingPopularJobsResult ||
      isLoadingPopularPostsResult ||
      isLoadingPopularTalentResult,
    isFetchingPopularData:
      isFetchingPopularJobsResult ||
      isFetchingPopularPostsResult ||
      isFetchingPopularTalentResult,
  };
};
