import type { ReactNode } from "react";

import { Tooltip } from "@hexocean/braintrust-ui-components";
import type { Job } from "@js/types/jobs";

type CannotMakeActionProps = {
  job: Job;
  children: ReactNode;
};

export const CannotMakeAction = ({ job, children }: CannotMakeActionProps) => {
  return (
    <Tooltip
      title="You cannot perform this action because job is completed"
      open={!!job.completed_at ? undefined : false}
    >
      <span>{children}</span>
    </Tooltip>
  );
};
