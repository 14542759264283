import type { ReactNode } from "react";
import { useEffect } from "react";
import smartlookClient from "smartlook-client";

import { useUser } from "@js/apps/common/hooks";
import { useGetManagedEmployerQuery } from "@js/apps/employer/hooks";
import { useAppSelector } from "@js/hooks";

export const SmartlookProvider = ({ children }: { children: ReactNode }) => {
  const user = useUser();
  const freelancer = useAppSelector((state) => state.freelancer);
  const { data: employerProfile } = useGetManagedEmployerQuery();

  useEffect(() => {
    if (!smartlookClient.initialized() && SETTINGS.SMARTLOOK_PROJECT_ID) {
      smartlookClient.init(SETTINGS.SMARTLOOK_PROJECT_ID);
    }
  }, []);

  useEffect(() => {
    if (user && smartlookClient.initialized()) {
      smartlookClient.identify(user.id, {
        is_staff:
          user.has_node_staff_permissions && !user.is_impersonated_session,
        public_name: user.public_name,
        email: user.email,
        company_name: freelancer?.freelancerProfile?.company_name || "",
        talent_hired_count: employerProfile?.talent_hired_count || "",
        organization_size: employerProfile?.organization_size || "",
      });
    }
  }, [
    user,
    freelancer?.freelancerProfile?.company_name,
    employerProfile?.talent_hired_count,
    employerProfile?.organization_size,
  ]);

  return children;
};
