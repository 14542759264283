import React from "react";
import uuid4 from "uuid4/browser";

import type { PlaceDetailsResponse } from "@js/hooks";
import { useGoogleMaps } from "@js/hooks";

import { useGetFreelancerLocationFromProfile } from "../use-get-freelancer-location-from-profile";

export const usePredictedFreelancerLocationDetails = () => {
  const [
    predictedFreelancerLocationDetails,
    setPredictedFreelancerLocationDetails,
  ] = React.useState<PlaceDetailsResponse | null>(null);
  const [sessionToken, setSessionToken] = React.useState<string>();

  const { getPlacePredictions, placePredictions, getPlaceDetails } =
    useGoogleMaps();
  const locationFromProfile = useGetFreelancerLocationFromProfile();

  React.useEffect(() => {
    // without setTimeout it placesService hangs out in loading state sometime, not sure why
    setTimeout(() => {
      if (!locationFromProfile) return;
      const token = uuid4();
      setSessionToken(token);
      getPlacePredictions({
        input: locationFromProfile.location,
        // to be consistent with location filter in jobs search. hook is not used anywhere else for now
        types: "regions_without_sublocality",
        sessionToken: token,
      });
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationFromProfile]);

  React.useEffect(() => {
    if (placePredictions.length > 0) {
      getPlaceDetails(
        {
          sessionToken,
          placeId: placePredictions[0].place_id,
          fields: SETTINGS.GOOGLE_PLACES_DETAILS,
        },
        (placeDetails) => {
          setPredictedFreelancerLocationDetails(placeDetails);
        },
      );
    }
    // there is no dynamic input, so it's the easiest way to avoid duplicated request
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placePredictions.length]);

  return {
    predictedFreelancerLocationDetails,
  };
};
