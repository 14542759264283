import { Field } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { Modal, ModalConfirm } from "@js/components/modal";
import { PasswordField } from "@js/forms/fields/password";

const PasswordConfirmationModalInstance = Modal("password-confirmation", {
  disablePortal: true,
});

export const openPasswordConfirmationModal = () =>
  PasswordConfirmationModalInstance.open();

export const closePasswordConfirmationModal = () =>
  PasswordConfirmationModalInstance.close();

export const PasswordConfirmationField = () => {
  return (
    <PasswordConfirmationModalInstance>
      <div>
        <Typography component="h4" variant="title" fontWeight={400} mb={2}>
          Password confirmation
        </Typography>
        <Typography component="p" mb={4}>
          Please confirm the email address change by providing your password.
        </Typography>
      </div>
      <ModalConfirm
        onConfirm="submit"
        confirmText="Confirm"
        onCancel={PasswordConfirmationModalInstance.close}
      >
        <Field
          id="password-confirmation"
          name="code"
          component={PasswordField}
          label="Password"
          autoFocus
        />
      </ModalConfirm>
    </PasswordConfirmationModalInstance>
  );
};
