import { useParams } from "react-router-dom";

import { useAccountType } from "@js/apps/common/hooks";
import { useGetMySpacesQuery } from "@js/apps/spaces/api";

type Notifications = {
  areNotificationsTurnOn: boolean | undefined;
};

export const useSpaceNotifications = (): Notifications => {
  const { isEmployer } = useAccountType();
  const { spaceId } = useParams();
  const { data } = useGetMySpacesQuery(undefined, {
    skip: isEmployer,
  });

  const currentSpace = data?.find((space) => space.id === Number(spaceId));
  const areNotificationsTurnOn = currentSpace?.notifications_enabled;

  return { areNotificationsTurnOn };
};
