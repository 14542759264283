import React, { Component } from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import { getFormError, SubmissionError } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { submitActionAfterOTPConfirmation } from "@js/apps/common/forms/fields/otp/otp-auth-modal";
import { openOTPDisabledWarningModal } from "@js/apps/settings/components/otp-auth";
import { AddRecipientsModal } from "@js/apps/withdrawal/components/add-recipients/recipients-listing/recipients-modal";
import { Modal, ModalConfirm } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type { RootState } from "@js/store";
import type { WithdrawalMethodType } from "@js/types/withdrawals";
import { formatErrorForSnackbar } from "@js/utils";
import { mapTypedDispatchToProps } from "@js/utils/store";

import {
  addStripeMethodRecipients,
  createStripeWithdrawalMethod as createStripeWithdrawal,
  createTransferWiseWithdrawalMethod as createTransferWiseWithdrawal,
  deleteRecipient,
  deleteWithdrawalMethod,
  fetchWithdrawalMethods,
  setAsDefaultWithdrawalMethod as setAsDefaultWithdrawal,
} from "../actions";
import { AddRecipients } from "../components/add-recipients";
import {
  AddWithdrawalMethod,
  AddWithdrawalMethodModal,
} from "../components/add-withdrawal-method";
import type { AddRecipientsFormData } from "../forms/add-recipients";
import { ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID } from "../forms/add-transferwise-withdrawal";

export type WithdrawalMethodsContainerRenderProps = Omit<
  ConnectedProps<typeof connector>,
  "user" | "dispatch"
> & {
  createTransferWiseWithdrawalMethod: (
    withdrawalMethod: WithdrawalMethodType,
  ) => Promise<{ key: string }>;
  setAsDefaultWithdrawalMethod: (
    withdrawalMethod: WithdrawalMethodType,
  ) => void;
  onWithdrawalMethodDelete: (withdrawalMethod: WithdrawalMethodType) => void;
  onAddBankingInformationClick: () => void;
  onChooseWithdrawalMethod: () => void;
  onAddRecipientsClick: (withdrawalMethod: WithdrawalMethodType) => void;
  addStripeRecipients: (recipients: AddRecipientsFormData) => Promise<any>;
  handledStripeMethod: WithdrawalMethodType | null;
  deleteStripeRecipient: (recipient: number) => void;
  choosenWithdrawalMethod: EnumType<typeof ENUMS.WithdrawalContentType> | null;
  createStripeWithdrawalMethod: () => Promise<void>;
};

type WithdrawalMethodsContainerClassType = ConnectedProps<typeof connector> & {
  render(props: WithdrawalMethodsContainerRenderProps): React.ReactNode;
};

class WithdrawalMethodsContainerComponent extends Component<WithdrawalMethodsContainerClassType> {
  DeleteWithdrawalMethodConfirmModal;

  ChooseWithdrawalMethodModal;

  state = {
    choosenWithdrawalMethod: null,
    handledStripeMethod: null,
  };

  constructor(props: WithdrawalMethodsContainerClassType) {
    super(props);

    this.DeleteWithdrawalMethodConfirmModal = Modal(
      "delete-payment-method-confirm",
      {
        closeButton: false,
      },
    );

    this.ChooseWithdrawalMethodModal = Modal("choose-withdrawal-method", {
      closeButton: false,
    });
  }

  componentDidMount() {
    this.props.dispatch(fetchWithdrawalMethods());
  }

  onChooseWithdrawalMethod = () =>
    this.props.user?.is_otp_enabled
      ? this.ChooseWithdrawalMethodModal.open()
      : openOTPDisabledWarningModal();

  onWithdrawalMethodDelete = (withdrawalMethod: WithdrawalMethodType) => {
    this.DeleteWithdrawalMethodConfirmModal.open({
      children: (
        <ModalConfirm
          onCancel={this.DeleteWithdrawalMethodConfirmModal.close}
          onConfirm={() => {
            this.DeleteWithdrawalMethodConfirmModal.close();
            submitActionAfterOTPConfirmation({
              onSubmit: ({ code, is_backup_code }) => {
                return deleteWithdrawalMethod(withdrawalMethod.id, {
                  code,
                  is_backup_code,
                })
                  .then(() => this.props.dispatch(fetchWithdrawalMethods()))
                  .catch((error) => {
                    handleResponseError(
                      error,
                      "Failed to delete banking information.",
                    );
                  });
              },
            });
          }}
        >
          Are you sure you want to delete this banking information?
        </ModalConfirm>
      ),
    });
  };

  onAddBankingInformationClick = () => {
    this.setState({
      choosenWithdrawalMethod:
        ENUMS.WithdrawalContentType.TransferWiseWithdrawalMethod,
    });

    return this.props.user?.is_otp_enabled
      ? AddWithdrawalMethodModal.open()
      : openOTPDisabledWarningModal();
  };

  createTransferWiseWithdrawalMethod = (
    withdrawalMethod: WithdrawalMethodType,
  ) =>
    this.props
      .dispatch(createTransferWiseWithdrawal(withdrawalMethod))
      .then(() => this.props.dispatch(fetchWithdrawalMethods()));

  setAsDefaultWithdrawalMethod = (withdrawalMethod: WithdrawalMethodType) => {
    return this.props.user?.is_otp_enabled
      ? submitActionAfterOTPConfirmation({
          onSubmit: ({ code, is_backup_code }) => {
            return setAsDefaultWithdrawal(withdrawalMethod.id, {
              code,
              is_backup_code,
            })
              .then(() => this.props.dispatch(fetchWithdrawalMethods()))
              .catch((error) => {
                handleResponseError(
                  error,
                  "Failed to set banking information as default.",
                );
              });
          },
        })
      : openOTPDisabledWarningModal();
  };

  createStripeWithdrawalMethod = () =>
    this.props.dispatch(createStripeWithdrawal()).then(() => {
      this.props.dispatch(fetchWithdrawalMethods());
    });

  onAddStripeCryptoAccountClick = () => {
    this.setState({
      choosenWithdrawalMethod:
        ENUMS.WithdrawalContentType.StripeWithdrawalMethod,
    });

    return this.props.user?.is_otp_enabled
      ? AddWithdrawalMethodModal.open()
      : openOTPDisabledWarningModal();
  };

  onAddRecipientsClick = (withdrawalMethod: WithdrawalMethodType) => {
    this.setState({
      handledStripeMethod: withdrawalMethod,
    });

    return this.props.user?.is_otp_enabled
      ? AddRecipientsModal.open()
      : openOTPDisabledWarningModal();
  };

  addStripeRecipients = (recipients: AddRecipientsFormData) =>
    this.props
      .dispatch(addStripeMethodRecipients(recipients))
      .then(() => {
        this.props.dispatch(fetchWithdrawalMethods());
      })
      .catch((error) => {
        handleResponseError(error);
      });

  deleteStripeRecipient = (recipient: number) =>
    submitActionAfterOTPConfirmation({
      onSubmit: ({ code }) =>
        deleteRecipient(recipient, code)
          .then(() => {
            this.props.dispatch(fetchWithdrawalMethods());
          })
          .catch((error) => {
            handleResponseError(error);
          }),
    });

  render() {
    const {
      render,
      errors,
      fetchingWithdrawalMethods,
      formRequirements,
      withdrawalMethods,
      creatingWithdrawalMethod,
    } = this.props;
    const { choosenWithdrawalMethod, handledStripeMethod } = this.state;

    const {
      onWithdrawalMethodDelete,
      onAddBankingInformationClick,
      onAddStripeCryptoAccountClick,
      createTransferWiseWithdrawalMethod,
      createStripeWithdrawalMethod,
      DeleteWithdrawalMethodConfirmModal,
      setAsDefaultWithdrawalMethod,
      ChooseWithdrawalMethodModal,
      onChooseWithdrawalMethod,
      onAddRecipientsClick,
      addStripeRecipients,
      deleteStripeRecipient,
    } = this;

    return (
      <>
        {render({
          errors,
          fetchingWithdrawalMethods,
          withdrawalMethods,
          creatingWithdrawalMethod,
          formRequirements,
          createTransferWiseWithdrawalMethod,
          setAsDefaultWithdrawalMethod,
          onWithdrawalMethodDelete,
          onAddBankingInformationClick,
          onChooseWithdrawalMethod,
          onAddRecipientsClick,
          addStripeRecipients,
          handledStripeMethod,
          deleteStripeRecipient,
          choosenWithdrawalMethod,
          createStripeWithdrawalMethod,
        })}
        <DeleteWithdrawalMethodConfirmModal>
          <ModalConfirm>
            Are you sure you want to delete this withdrawal method?
          </ModalConfirm>
        </DeleteWithdrawalMethodConfirmModal>
        <ChooseWithdrawalMethodModal>
          <Box>
            <Typography mb={3} component="h1" variant="title" size="small">
              Please choose your payout method
            </Typography>
            <Box>
              {SETTINGS.STRIPE_WITHDRAWALS_ENABLED && (
                <Button
                  variant="secondary"
                  onClick={onAddStripeCryptoAccountClick}
                  fullWidth
                >
                  Stripe account
                </Button>
              )}
            </Box>
            <Box mt={2}>
              <Button
                variant="secondary"
                fullWidth
                onClick={onAddBankingInformationClick}
              >
                Add bank account
              </Button>
            </Box>
            <Box mt={2}>
              <Button
                variant="secondary"
                fullWidth
                onClick={ChooseWithdrawalMethodModal.close}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </ChooseWithdrawalMethodModal>
        <AddRecipients
          addRecipients={addStripeRecipients}
          handledMethod={handledStripeMethod}
          deleteRecipient={deleteStripeRecipient}
        />
        <AddWithdrawalMethod
          errors={errors}
          formRequirements={formRequirements}
          createTransferWiseWithdrawalMethod={
            createTransferWiseWithdrawalMethod
          }
          createStripeWithdrawalMethod={createStripeWithdrawalMethod}
          creatingWithdrawalMethod={creatingWithdrawalMethod}
          choosenWithdrawalMethod={choosenWithdrawalMethod}
        />
      </>
    );
  }
}

const errorSelector = getFormError(ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID);
export const handleResponseError = (
  error: Record<string, any>,
  errorMsg = "Sorry! An unexpected error occurred",
) => {
  if (!error) return;
  const { data } = error.response;
  handleFormError(data);
  // most of these payment related errors are quite lengthy
  // lets give users some more time to read them before snackbar auto hides
  Snackbar.error(data?._error || formatErrorForSnackbar(data) || errorMsg, {
    autoHideDuration: 10000,
  });
};

const handleFormError = (data: Record<string, string>) => {
  if (!data) return;

  if (data?.code || data?.code_is_required || data?.company_node) {
    throw new SubmissionError(data);
  }
};

const connector = connect(
  (state: RootState) => ({
    user: state.auth.user,
    fetchingWithdrawalMethods: state.withdrawal.fetchingWithdrawalMethods,
    withdrawalMethods: state.withdrawal.withdrawalMethods,
    creatingWithdrawalMethod: state.withdrawal.creatingWithdrawalMethod,
    formRequirements: state.withdrawal.formRequirements,
    errors: errorSelector(state) || null,
  }),
  mapTypedDispatchToProps,
);

export const WithdrawalMethodsContainer = connector(
  WithdrawalMethodsContainerComponent,
);
