import { filterApplied } from "@js/apps/common/actions";
import { useSearchFilterLocationContext } from "@js/apps/common/context/search-filter-location-context";
import { useSendSearchEventMutation } from "@js/apps/tracking/api";
import {
  setSearchEventQueryId,
  useSearchEventQueryId,
} from "@js/apps/tracking/search-event-slice";
import { useAppDispatch } from "@js/hooks";
import { createSearchEventTrackingPayload } from "@js/middlewares/analytics/user-tracking";
import type { FilterAppliedPayload } from "@js/types/common";
import type {
  SearchEventEntityTypeKey,
  SearchEventOption,
} from "@js/types/tracking";

export const useHandleFilterApplied = () => {
  const dispatch = useAppDispatch();
  const searchContext = useSearchFilterLocationContext();
  const searchQueryId = useSearchEventQueryId(searchContext);
  const [sendSearchEvent] = useSendSearchEventMutation();

  const getFilterAppliedSearchQueryId = async () => {
    if (
      !searchContext ||
      searchContext === ENUMS.SearchEventOption.MAIN_SEARCH_BOX
    ) {
      return;
    }

    // wait for window.location to be updated with the latest applied filter value
    await delay(50);

    const params = new URLSearchParams(window.location.search);
    const searchParamValue = params.get("search");
    const isSearchActive = !!searchParamValue;
    if (!isSearchActive) {
      return searchQueryId;
    }

    const entityTypeKey =
      mapFilterAppliedSearchContextToEntityTypeKey(searchContext);
    if (!entityTypeKey) {
      return;
    }

    const eventPayload = createSearchEventTrackingPayload({
      searchQuery: searchParamValue,
      searchContext: searchContext,
      entityTypeKey,
      location: window.location,
    });

    const response = await sendSearchEvent(eventPayload)
      .unwrap()
      .catch(() => null);

    if (!response) {
      return;
    }

    setSearchEventQueryId({
      id: response.id,
      option: searchContext,
    });

    return response.id;
  };

  const handleFilterApplied = async (
    arg: Pick<FilterAppliedPayload, "filter_type" | "filter_value">,
  ) => {
    const filterAppliedSearchQueryId = await getFilterAppliedSearchQueryId();

    dispatch(
      filterApplied({
        ...arg,
        search_query_id: filterAppliedSearchQueryId,
      }),
    );
  };

  return { handleFilterApplied };
};

const mapFilterAppliedSearchContextToEntityTypeKey = (
  searchContext: Exclude<
    SearchEventOption,
    typeof ENUMS.SearchEventOption.MAIN_SEARCH_BOX
  >,
): SearchEventEntityTypeKey | undefined => {
  switch (searchContext) {
    case ENUMS.SearchEventOption.JOB_SEARCH_BOX:
      return "JOBS";
    case ENUMS.SearchEventOption.TALENT_SEARCH_BOX:
      return "TALENT";
    case ENUMS.SearchEventOption.BID_SEARCH_BOX:
      return "BID";
    default:
      searchContext satisfies never;
      return;
  }
};

const delay = (delayMs: number) =>
  new Promise((resolve) => setTimeout(resolve, delayMs));
