export const CUSTOM_RATE_VALUE = "CUSTOM_RATE_VALUE";

export const RATE_OPTIONS = [
  { value: 50, label: "$50/hr", tooltipTitle: "$104,000/yr" },
  { value: 75, label: "$75/hr", tooltipTitle: "$156,000/yr" },
  { value: 100, label: "$100/hr", tooltipTitle: "$208,000/yr" },
  { value: 125, label: "$125/hr", tooltipTitle: "$260,000/yr" },
];

export const isRateOptionSelected = (selectedValue: number) => {
  return RATE_OPTIONS.some(({ value }) => {
    return value === selectedValue;
  });
};
