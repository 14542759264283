import { useEffect, useRef, useState } from "react";
import _ from "underscore";

import { Box, Loader } from "@hexocean/braintrust-ui-components";
import type { PdfViewerProps } from "@js/apps/common/components/pdf-viewer";
import { PdfViewer } from "@js/apps/common/components/pdf-viewer";

export const EmbeddedResumeViewer = ({
  sourceFile,
}: {
  sourceFile: string;
}) => {
  const contentBox = useRef<HTMLDivElement>(null);
  const [pdfLoading, setPdfLoading] = useState(true);
  const [pdfHasPassword, setPdfHasPassword] = useState(false);
  const [scale, setScale] = useState(0.98);
  const [pageWidth, setPageWidth] = useState(595);

  useEffect(() => {
    const handleResize = () => {
      if (contentBox.current) {
        const contentBoxWidth =
          contentBox.current?.getBoundingClientRect().width || 0;

        const newScale = contentBoxWidth / pageWidth;
        setScale(newScale);
      }
    };
    const debouncedResize = _.debounce(handleResize, 300);
    handleResize();
    window.addEventListener("resize", debouncedResize);

    return () => removeEventListener("resize", debouncedResize);
  }, [pageWidth]);

  const onLoadSuccess: PdfViewerProps["onLoadSuccess"] = async (pdf) => {
    try {
      const page = await pdf.getPage(1);
      const width = page.view[2];
      setPageWidth(width);
      setPdfLoading(false);
    } catch (e) {
      setPdfLoading(false);
      void e;
    }
  };

  const onPassword = () => {
    setPdfHasPassword(true);
  };

  return (
    <Box
      ref={contentBox}
      border="1px solid var(--soft-beige)"
      borderRadius="16px"
      overflow="hidden"
      textAlign="center"
    >
      {pdfHasPassword && (
        <Box p={12}>
          Preview can't be shown because this document is password-protected.
        </Box>
      )}
      {!pdfHasPassword && (
        <>
          {pdfLoading && (
            <Box p={12}>
              <Loader />
            </Box>
          )}
          <PdfViewer
            sourceFile={sourceFile}
            onLoadSuccess={onLoadSuccess}
            onPassword={onPassword}
            adjustCustomScale={() => scale}
          />
        </>
      )}
    </Box>
  );
};
