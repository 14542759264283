import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { CompleteProfileButton } from "@js/apps/jobs/components/complete-profile-button";
import { JobStatusBox } from "@js/apps/jobs/components/job-status/components/job-status-box";

type CompleteProfileStatusBoxProps = {
  longTile?: boolean;
};
export const CompleteProfileStatusBox = ({
  longTile,
}: CompleteProfileStatusBoxProps) => {
  return (
    <JobStatusBox color="var(--medium-green)">
      <Typography
        mb={1.5}
        component="h2"
        variant="title"
        size="small"
        fontWeight={400}
      >
        Complete your profile to apply for jobs!
      </Typography>
      {longTile && (
        <Typography
          mb={2}
          component="h3"
          variant="paragraph"
          maxWidth="fit-content"
        >
          When your profile is 100%, you can apply to jobs and go through our
          Approved Talent screening.
        </Typography>
      )}
      <JobStatusBox.Content longTile={longTile}>
        <CompleteProfileButton />
      </JobStatusBox.Content>
    </JobStatusBox>
  );
};
