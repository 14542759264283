import { FieldArray } from "redux-form";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@hexocean/braintrust-ui-components";
import { InvoiceItemField } from "@js/apps/invoices/fields/invoice-item-field";

import styles from "./style.module.scss";

export const InvoiceItemsTable = () => {
  return (
    <div className={`table-wrapper ${styles.invoiceTable}`}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell id="date-range">Date Range</TableCell>
            <TableCell id="po">PO/Order # (Optional)</TableCell>
            <TableCell id="description">Description</TableCell>
            <TableCell id="unit-price" align="right">
              Unit Price
            </TableCell>
            <TableCell id="quantity" aria-label="Quantity" align="right">
              Qty
            </TableCell>
            <TableCell id="amount" align="right">
              Amount
            </TableCell>
            <TableCell id="remove" />
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray
            rerenderOnEveryChange
            name="new_items"
            component={InvoiceItemField}
          />
        </TableBody>
      </Table>
    </div>
  );
};
