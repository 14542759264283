import { useEffect, useState } from "react";
import { useBlocker } from "react-router-dom";
import { hasSubmitSucceeded, isDirty, isSubmitting, submit } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import type { ModalInstanceProps } from "@js/components/modal";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch, useAppSelector, useNavigate } from "@js/hooks";
import { assertUnreachable } from "@js/utils";

export const WARNING_MODAL_TYPE = {
  EDIT_PROFILE: "EDIT_PROFILE",
  NEW_BID: "NEW_BID",
  UPLOAD_RESUME: "UPLOAD_RESUME",
  AI_INTERVIEW_QUESTION: "AI_INTERVIEW_QUESTION",
} as const;

export type UseUnsavedChangesWarningArg = {
  formId: string;
  hideUnsavedChangesWarningURLs?: string[];
  leaveMessage?: string;
  unsavedChangesWarning?: boolean;
  unsavedChangesWarningModelType?: keyof typeof WARNING_MODAL_TYPE;
};

export const useUnsavedChangesWarning = ({
  formId,
  hideUnsavedChangesWarningURLs = [],
  leaveMessage = "Reload site?\nChanges that you made may not be saved",
  unsavedChangesWarning,
  unsavedChangesWarningModelType,
}: UseUnsavedChangesWarningArg) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isFormDirty = useAppSelector((state) => isDirty(formId)(state));
  const [nextPathname, setNextPathname] = useState<string>();
  const _hasSubmitSucceeded = useAppSelector((state) =>
    hasSubmitSucceeded(formId)(state),
  );
  const _isSubmitting = useAppSelector((state) => isSubmitting(formId)(state));

  const isBlocked =
    !!unsavedChangesWarning &&
    isFormDirty &&
    !_hasSubmitSucceeded &&
    !_isSubmitting;

  const blocker = useBlocker(isBlocked);

  useEffect(() => {
    const handleBeforeUnload = (ev: BeforeUnloadEvent) => {
      ev.returnValue = leaveMessage;
      return leaveMessage;
    };
    if (isBlocked) {
      global.addEventListener("beforeunload", handleBeforeUnload);
    }
    if (blocker.state === "blocked" && isBlocked) {
      const { pathname } = blocker.location;
      const hide = hideUnsavedChangesWarningURLs.includes(pathname);

      if (!hide && unsavedChangesWarningModelType) {
        const onConfirm = (submitForm: boolean) => {
          setNextPathname(pathname);
          if (submitForm) {
            dispatch(submit(formId));
          }
        };

        const onCancel = () => {
          blocker.proceed?.();
        };

        displayUnsavedChangesWarningModal({
          onCancel,
          onConfirm,
          unsavedChangesWarningModelType,
        });
      } else if (!hide) {
        const leave = window.confirm(leaveMessage);
        if (leave) {
          blocker.proceed?.();
        }
      } else {
        blocker.proceed?.();
      }
    } else if (blocker.state === "blocked" && !isBlocked) {
      blocker.reset?.();
    }
    return () => {
      global.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [
    navigate,
    blocker,
    isBlocked,
    dispatch,
    formId,
    isFormDirty,
    unsavedChangesWarningModelType,
    hideUnsavedChangesWarningURLs,
    leaveMessage,
  ]);

  useEffect(() => {
    if (_isSubmitting || !nextPathname) return;

    if (_hasSubmitSucceeded) {
      navigate(nextPathname);
    }
  }, [_hasSubmitSucceeded, _isSubmitting, navigate, nextPathname]);
};

type ModalContentProps = {
  onSecondaryClick: () => void;
  onPrimaryClick: () => void;
  title: React.ReactNode;
  description: string;
  primaryText: string;
  secondaryText: string;
};

const ModalContent = ({
  onSecondaryClick,
  onPrimaryClick,
  title,
  description,
  primaryText,
  secondaryText,
}: ModalContentProps) => {
  return (
    <Box mt={4}>
      <Typography component="p" mb={1} variant="title">
        {title}
      </Typography>
      <Typography component="p">{description}</Typography>
      <Box mt={4} gap={1} display="flex" justifyContent="flex-end">
        <Button variant="secondary" onClick={onSecondaryClick}>
          {secondaryText}
        </Button>
        <Button variant="primary" onClick={onPrimaryClick}>
          {primaryText}
        </Button>
      </Box>
    </Box>
  );
};

type DisplayUnsavedChangesWarningModalProps = {
  onCancel: () => void;
  onConfirm: (submitForm: boolean) => void;
  unsavedChangesWarningModelType: keyof typeof WARNING_MODAL_TYPE;
};

const displayUnsavedChangesWarningModal = async ({
  onCancel,
  onConfirm,
  unsavedChangesWarningModelType,
}: DisplayUnsavedChangesWarningModalProps) => {
  try {
    await new Promise((resolve, reject) => {
      const closeWarning = (submitForm: boolean) => {
        onConfirm(submitForm);
        resolve(submitForm);
      };

      const leavePage = () => {
        onCancel();
        reject();
      };

      const openModal = ({
        children,
        ...config
      }: {
        children: React.ReactNode;
      } & ModalInstanceProps) => {
        ModalInstance.open({
          className: "max-width-500",
          closeButton: true,
          onClose: reject,
          ...(config || {}),
          children,
        });
      };

      const warningType = unsavedChangesWarningModelType as EnumType<
        typeof WARNING_MODAL_TYPE
      >;

      switch (warningType) {
        case WARNING_MODAL_TYPE.EDIT_PROFILE: {
          openModal({
            children: (
              <ModalContent
                title="Save your changes?"
                description="Oops! Looks like you have some unsaved changes on your profile.
                                        Do you want to save before you leave?"
                primaryText="Yes, save my changes"
                secondaryText="Exit without saving"
                onPrimaryClick={() => closeWarning(true)}
                onSecondaryClick={leavePage}
              />
            ),
          });
          break;
        }
        case WARNING_MODAL_TYPE.UPLOAD_RESUME: {
          openModal({
            children: (
              <ModalContent
                title="Save your resume?"
                description="Oops! Looks like you haven't saved your resume. Do you want to save it before you leave?"
                primaryText="Yes, save my resume"
                secondaryText="Exit without saving"
                onPrimaryClick={() => closeWarning(true)}
                onSecondaryClick={leavePage}
              />
            ),
          });
          break;
        }
        case WARNING_MODAL_TYPE.NEW_BID: {
          openModal({
            children: (
              <ModalContent
                title={
                  <>
                    You’ll lose your <br /> application if you leave.
                  </>
                }
                description="If you leave this page, your application won’t be saved and you’ll have to start over again 😱"
                primaryText="Leave page anyway"
                secondaryText="Stay Here"
                onPrimaryClick={leavePage}
                onSecondaryClick={ModalInstance.close}
              />
            ),
          });
          break;
        }
        case WARNING_MODAL_TYPE.AI_INTERVIEW_QUESTION: {
          openModal({
            children: (
              <Box padding="2px">
                <Typography
                  component="h1"
                  variant="title"
                  mb={2}
                  size="small"
                  fontWeight={400}
                >
                  You have unsaved changes
                </Typography>
                <Typography component="p" mb={4} size="medium">
                  You haven't saved the changes you made to your interview. If
                  you leave your changes will be lost.
                </Typography>
                <Box
                  mt={4}
                  gap={1}
                  display="flex"
                  justifyContent="flex-start"
                  height="48px"
                >
                  <Button
                    variant="secondary"
                    shape="squared"
                    sx={{ padding: "11px 20px !important", textWrap: "nowrap" }}
                    onClick={() => closeWarning(false)}
                  >
                    Continue editing
                  </Button>
                  <Button
                    variant="dark-violet"
                    shape="squared"
                    sx={{ padding: "11px 20px !important", textWrap: "nowrap" }}
                    onClick={leavePage}
                  >
                    Discard changes
                  </Button>
                </Box>
              </Box>
            ),
            closeButtonProps: {
              sx: {
                backgroundColor: "var(--grey-5) !important",
                height: "42px",
                width: "42px",
              },
            },
            className: "max-width-600",
          });
          break;
        }
        default: {
          assertUnreachable(warningType);
        }
      }
    });
    return false;
  } catch (_cancelled) {
    // user cancelled; do nothing
    return true;
  } finally {
    ModalInstance.close();
  }
};
