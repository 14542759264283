import { useEffect, useState } from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { MODULE_EDIT_MODAL_PROPERTIES } from "@js/apps/freelancer/constants";
import { EducationForm } from "@js/apps/freelancer/forms/education-form";
import { useEducation } from "@js/apps/freelancer/hooks/edit-education";
import { ModalInstance } from "@js/components/modal";

import { EducationCard } from "../card";

import {
  openDeleteEducationModal,
  openEditEducationModal,
} from "./edit-and-remove-education";

export const openCreateEducationModal = () =>
  ModalInstance.open({
    children: <ModalContent />,
    ...MODULE_EDIT_MODAL_PROPERTIES,
    className: "profile-page-modal profile-page-modal-education",
  });

const ModalContent = () => {
  const {
    getInitialValues,
    onSubmit,
    onDeleteEducation,
    onSubmitSuccess,
    profile,
    setSubmitType,
    onUpdateEducationSuccess,
  } = useEducation();

  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (!!profile && !profile.freelancer_schools.length) setShowForm(true);
  }, [profile]);

  return (
    <>
      <Box display="flex" gap={1} alignItems="center">
        <Typography
          component="h3"
          variant="title"
          fontWeight={400}
          size="small"
        >
          Education
        </Typography>
      </Box>

      {!!profile && !!profile.freelancer_schools.length && (
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          borderBottom="1px solid var(--grey-4)"
          mt={4}
        >
          {profile.freelancer_schools.map((school) => {
            return (
              <EducationCard
                key={school.id}
                school={school}
                editMode
                onEdit={() =>
                  openEditEducationModal({
                    initialValues: getInitialValues(school.id),
                    onSubmit,
                    onSubmitSuccess: onUpdateEducationSuccess,
                    setSubmitType,
                  })
                }
                onDelete={() =>
                  openDeleteEducationModal({
                    education: school,
                    onDelete: onDeleteEducation,
                  })
                }
              />
            );
          })}
        </Box>
      )}

      <Box mt={2}>
        {showForm ? (
          <>
            <Typography component="p" size="large" mb={3}>
              Add a degree
            </Typography>
            <EducationForm
              onSubmit={onSubmit}
              onSubmitSuccess={onSubmitSuccess}
              setSubmitType={setSubmitType}
            />
          </>
        ) : (
          <Button
            variant="tertiary"
            type="submit"
            onClick={() => setShowForm(true)}
          >
            Add a degree
          </Button>
        )}
      </Box>
    </>
  );
};
