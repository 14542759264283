export const BidInterviewIcon = () => {
  return (
    <img
      src={`${SETTINGS.STATIC_URL}jobs/ai-job-icon-star.svg`}
      alt="AI job interview icon"
      width={30}
      height={30}
    />
  );
};
