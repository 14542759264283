import React from "react";

import type { EmployerOwnJob } from "@js/types/jobs";

import { EmployerMyJobListItem } from "./list-item";

import styles from "./style.module.scss";

export const EmployerMyJobList = ({ jobs }: { jobs: EmployerOwnJob[] }) => {
  return (
    <div className={styles.myJobList}>
      {!!jobs.length ? (
        jobs.map((job) => <EmployerMyJobListItem key={job.id} job={job} />)
      ) : (
        <span>Nothing to display - you haven’t added any jobs yet.</span>
      )}
    </div>
  );
};
