import { useEffect } from "react";

import { TOGGLE_HIDE_REQUEST_LOADER } from "@js/apps/common/action-types";
import { setPostsToShowPulseAnimation } from "@js/apps/common/actions";
import { useFeedSortForm } from "@js/apps/common/components/filters/config";
import { useGetTrendingHashtagsQuery } from "@js/apps/give-and-get-help/api";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { useFeedVisits } from "../use-feed-visits";
import { useInfinityPostLoading } from "../use-infinity-post-loading";
import { useSubscribeFeed } from "../websocket-subscribe";

export const usePostsPage = () => {
  useSubscribeFeed();
  const {
    filters: { ordering },
  } = useFeedSortForm();
  const {
    posts,
    hasMore,
    handleFetchingMore,
    loadingPosts,
    fetchingPosts,
    visitedFeedThreeTimes,
    postsCount,
  } = useInfinityPostLoading({ ordering });

  useFeedVisits({ visitedFeedThreeTimes });
  const { data: hashtags, isLoading: loadingHashtags } =
    useGetTrendingHashtagsQuery();
  const dispatch = useAppDispatch();
  const hideRequestLoader = useAppSelector(
    (state) => state.common.hideRequestLoader,
  );

  useEffect(() => {
    if (!hideRequestLoader) {
      dispatch({
        type: TOGGLE_HIDE_REQUEST_LOADER,
      });
    }

    return () => {
      dispatch({
        type: TOGGLE_HIDE_REQUEST_LOADER,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setPostsToShowPulseAnimation([]));
    };
  }, [dispatch]);

  return {
    loading: loadingPosts || loadingHashtags,
    loadingHashtags,
    loadingPosts,
    posts,
    hasMore,
    handleFetchingMore,
    fetchingPosts,
    trendingHashtags: hashtags || [],
    postsCount,
  };
};
