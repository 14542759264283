import { createContext, useContext, useMemo, useState } from "react";

type IsCategorySuggestedContextType = {
  isCategorySuggested: boolean;
  setIsCategorySuggested: React.Dispatch<React.SetStateAction<boolean>>;
};

const IsCategorySuggestedContext =
  createContext<IsCategorySuggestedContextType | null>(null);

export const IsCategorySuggestedContextProvider = ({
  initialValue,
  children,
}: {
  initialValue: boolean;
  children: JSX.Element;
}) => {
  const [isCategorySuggested, setIsCategorySuggested] = useState(initialValue);
  const value = useMemo(
    () => ({
      isCategorySuggested,
      setIsCategorySuggested,
    }),
    [isCategorySuggested, setIsCategorySuggested],
  );

  return (
    <IsCategorySuggestedContext.Provider value={value}>
      {children}
    </IsCategorySuggestedContext.Provider>
  );
};

export const useIsCategorySuggestedContext = () => {
  const context = useContext(IsCategorySuggestedContext);

  if (!context) {
    throw new Error("IsCategorySuggestedContext is missing");
  }

  return context;
};
