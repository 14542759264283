import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { Emoji } from "@js/components/emoji";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";

export const BoostSuccessModal = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery("sm");

  return (
    <Box
      id="boost-success-modal"
      width={isMobile ? "90vw" : "629px"}
      maxWidth="629px"
      height="100%"
      padding={isMobile ? 2 : 4}
      boxSizing="border-box"
      display="flex"
      gap={2}
      flexDirection="column"
    >
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <IconButton
          variant="primary"
          size="x-small"
          aria-label="Close location mismatch modal"
          onClick={() => ModalInstance.close()}
          sx={{ border: "solid white 0.5rem", margin: "-1rem -1rem 0 0" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        display="flex"
        borderRadius={2}
        textAlign="center"
        sx={{
          background: "var(--medium-blue)",
          "& img": {
            maxWidth: "100%",
          },
          margin: "-3rem 0 1rem 0",
          height: isMobile ? "100%" : "306.82px",
          width: isMobile ? "auto" : "565px",
        }}
      >
        <img
          src={`${SETTINGS.STATIC_URL}jobs/purchase-boost-success.svg`}
          width="100%"
          height="auto"
          alt="job-insight-image"
        />
      </Box>

      <Typography component="h6" variant="title" size="small" fontWeight={500}>
        Your 3 Application Boosts are now ready{" "}
        <Emoji
          size="1.625rem"
          emoji="🙌"
          style={{
            lineHeight: "1.625rem",
          }}
        />
      </Typography>

      <Box>
        <Typography component="p" variant="paragraph" size="large">
          Boost your applications to increase your chance of getting hired. View
          your purchase history{" "}
          <RouterLink
            to="/settings/purchases/"
            onClick={() => {
              dispatch(fetchCurrentUser());
              ModalInstance.close();
            }}
            style={{
              textDecoration: "underline",
            }}
          >
            here.
          </RouterLink>
        </Typography>
      </Box>

      <Box textAlign="end" marginTop={2}>
        <Button
          variant="primary"
          shape="squared"
          RouterLink={RouterLink}
          to="/talent/dashboard/my_jobs/my_proposals/"
          onClick={() => {
            ModalInstance.close();
          }}
        >
          View My Applications
        </Button>
      </Box>
    </Box>
  );
};
