import type { OfferNotesFilters } from "@js/apps/admin/components/notes-list";
import {
  NotesList,
  OfferNotesList,
} from "@js/apps/admin/components/notes-list";
import {
  CloseModalOnLocationChange,
  ModalInstance,
} from "@js/components/modal";

export const closeNotesModal = ModalInstance.close;

export const openNotesModal = (
  id: number,
  noteType: EnumType<typeof ENUMS.NoteContentType>,
) => {
  ModalInstance.open({
    children: (
      <CloseModalOnLocationChange closeModal={closeNotesModal}>
        <NotesList id={id} objectName={noteType} />
      </CloseModalOnLocationChange>
    ),
  });
};

export const openOfferNotesModal = (
  id: number,
  filters?: OfferNotesFilters,
) => {
  ModalInstance.open({
    children: (
      <CloseModalOnLocationChange closeModal={closeNotesModal}>
        <OfferNotesList filters={filters} id={id} />
      </CloseModalOnLocationChange>
    ),
  });
};
