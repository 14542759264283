import { type ReactNode, useEffect, useRef } from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import type { PostComment } from "@js/types/give-and-get-help";

type CommentReplyFormWrapperProps = {
  children: ReactNode;
  scrollCommentReplyFormIntoView: boolean;
  commentToReply: PostComment | undefined;
  className?: string;
};

export const CommentReplyFormWrapper = ({
  children,
  className,
  scrollCommentReplyFormIntoView,
  commentToReply,
}: CommentReplyFormWrapperProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollCommentReplyFormIntoView || !commentToReply) {
      return;
    }

    requestAnimationFrame(() => {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    });
  }, [commentToReply, scrollCommentReplyFormIntoView]);

  return (
    <Box ref={ref} className={className}>
      {children}
    </Box>
  );
};
