import { useState } from "react";

export const useTeamMemberInvitations = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const isNotEmpty = !!inputValue.trim();
  const isOpenAndNotEmpty = isOpen && isNotEmpty;

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return {
    inputValue,
    setInputValue,
    isOpen: isOpenAndNotEmpty,
    handleOpen,
    handleClose,
  };
};
