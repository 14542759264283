import React from "react";

import { Loader } from "@hexocean/braintrust-ui-components";
import type { WithdrawalMethodsContainerRenderProps } from "@js/apps/withdrawal/containers/withdrawal-methods";

import { WithdrawalMethodsList } from "../withdrawal-methods-list";

export const WithdrawalMethodsSection = ({
  fetchingWithdrawalMethods,
  withdrawalMethods,
  setAsDefaultWithdrawalMethod,
  onAddRecipientsClick,
  onWithdrawalMethodDelete,
}: WithdrawalMethodsContainerRenderProps) => {
  let content: React.ReactElement;

  if (fetchingWithdrawalMethods) {
    content = <Loader />;
  } else {
    content = (
      <WithdrawalMethodsList
        withdrawalMethods={withdrawalMethods}
        onDelete={(withdrawalMethod) =>
          onWithdrawalMethodDelete(withdrawalMethod)
        }
        onSetAsDefault={setAsDefaultWithdrawalMethod}
        onAddRecipientsClick={onAddRecipientsClick}
      />
    );
  }

  return <div className="mb++">{content}</div>;
};
