import React from "react";
import { useLocation } from "react-router-dom";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { ArrowRightTopIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

import style from "./styles.module.scss";

export const PublicJobsListingHeader = () => {
  const isMobile = useMediaQuery("sm");
  const { search } = useLocation();

  return (
    <Box mb={4.5}>
      <Box display="flex" justifyContent="center" mb={2} textAlign="center">
        <Typography
          variant="title"
          component="h1"
          size={isMobile ? "medium" : "large"}
          fontWeight={400}
        >
          Braintrust finds jobs that work for you
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mb={2.5} textAlign="center">
        <Typography
          variant={isMobile ? "label" : "title"}
          component="h2"
          size={isMobile ? "large" : "small"}
          fontWeight={400}
        >
          Create a free profile to access hundreds of jobs
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          variant="positive"
          to={`/auth/sign_up${search}`}
          RouterLink={RouterLink}
        >
          <Typography
            variant="paragraph"
            component="span"
            size="medium"
            fontWeight={500}
          >
            Sign up to apply
          </Typography>
          <ArrowRightTopIcon className={style.signUpButtonIcon} />
        </Button>
      </Box>
    </Box>
  );
};
