import { ListItemIcon, Menu } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  BackLeftIcon,
  CancelIcon,
  FlagIcon,
  MessageIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useDeclineHelpOffer } from "@js/apps/common/hooks/use-help-offer/use-decline-help-offer";
import { useReport } from "@js/apps/common/hooks/use-report";
import { useHelpOfferRefund } from "@js/apps/messenger/hooks/use-refund-offer";
import { useRemoveHelpOffer } from "@js/apps/messenger/hooks/use-remove-help-offer";
import { RouterLink } from "@js/components/link";
import type { HelpOffer } from "@js/types/give-and-get-help";
import type { HelpOfferCreatedSystemMessage } from "@js/types/messenger";

type HelpOfferActionMenuProps = {
  offer: HelpOffer | HelpOfferCreatedSystemMessage["system_message_target"];
  offerReceiverName: string;
  isOfferAuthor: boolean;
  children: React.ReactElement;
  enableRemoveOfferAction?: boolean;
  enableToRequestRefund?: boolean;
  enableReportOfferAction?: boolean;
  refundRequested?: boolean;
  helperFirstName?: string;
  reported?: boolean | null;
  isCompleted?: boolean;
  isPending?: boolean;
};

export const HelpOfferActionMenu = ({
  offer,
  offerReceiverName,
  isOfferAuthor,
  children,
  enableRemoveOfferAction,
  enableToRequestRefund,
  enableReportOfferAction,
  refundRequested,
  helperFirstName,
  reported,
  isPending,
}: HelpOfferActionMenuProps) => {
  const { handleReportHelpOffer } = useReport();
  const { handleDecline } = useDeclineHelpOffer({ offer });

  const handleRemoveHelpOffer = useRemoveHelpOffer(offer.id, offerReceiverName);
  const handleHelpOfferRefund = useHelpOfferRefund({
    helperFirstName,
    offer,
    offerId: offer.id,
  });

  const isMobile = useMediaQuery("sm");
  const isMedium = useMediaQuery("md");
  const isLarge = useMediaQuery("lg");

  const isMobileView = isMobile || (!isMedium && isLarge);

  const colorRed = { color: "var(--negative-1)" };

  const isOfferActive =
    !!offer.accepted_at && offer.status !== ENUMS.HelpOfferStatus.COMPLETED;
  const canDeclineOffer = !isOfferAuthor && isMobileView && isPending;

  const reportOffer = handleReportHelpOffer({
    id: offer.id,
    name: offer.author.user.first_name,
    type: refundRequested ? "refund" : "offer",
    isActive: isOfferActive,
    isAsker: !isOfferAuthor,
  });

  const showMenu =
    enableToRequestRefund ||
    enableReportOfferAction ||
    enableRemoveOfferAction ||
    reported ||
    canDeclineOffer;

  if (!showMenu) {
    return <></>;
  }

  return (
    <Menu id={`system-message-action-menu-${offer.id}`} anchor={children}>
      {enableToRequestRefund && (
        <Menu.Item
          onClick={handleHelpOfferRefund}
          sx={colorRed}
          key="request-refund-menu-item"
        >
          <ListItemIcon>
            <BackLeftIcon sx={colorRed} />
          </ListItemIcon>
          Request refund
        </Menu.Item>
      )}
      {enableReportOfferAction && (
        <Menu.Item
          onClick={reportOffer}
          sx={colorRed}
          key="report-offer-menu-item"
        >
          <ListItemIcon>
            <FlagIcon sx={colorRed} />
          </ListItemIcon>
          Report offer
        </Menu.Item>
      )}
      {enableRemoveOfferAction && (
        <Menu.Item onClick={handleRemoveHelpOffer} sx={colorRed}>
          <ListItemIcon sx={colorRed}>
            <CancelIcon />
          </ListItemIcon>
          Remove offer
        </Menu.Item>
      )}
      {reported && (
        <RouterLink
          target="_blank"
          to={`mailto:${SETTINGS.SUPPORT_EMAIL}`}
          rel="noreferrer"
          className="no-decoration"
        >
          <Menu.Item>
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>
            Contact support
          </Menu.Item>
        </RouterLink>
      )}
      {canDeclineOffer && (
        <Menu.Item onClick={handleDecline} sx={colorRed}>
          <ListItemIcon sx={colorRed}>
            <CancelIcon />
          </ListItemIcon>
          Decline offer
        </Menu.Item>
      )}
    </Menu>
  );
};
