import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import _ from "underscore";

import { useEffectRef } from "@js/hooks/use-effect-ref";

export const useReferralSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    () => searchParams.get("search") || "",
  );
  const setSearchParamsRef = useEffectRef(setSearchParams);

  const setSearchURLParam = useCallback(
    (newValue?: string) => {
      setSearchParamsRef.current(
        (prevParams) => {
          const newParams = new URLSearchParams(prevParams);

          if (newValue) {
            newParams.set("search", newValue);
          } else {
            newParams.delete("search");
          }

          return newParams;
        },
        {
          replace: true,
          state: { noScroll: true, disableRemountIfTheSameLocation: true },
        },
      );
    },
    [setSearchParamsRef],
  );

  const updateSearchParamsDebounced = useMemo(() => {
    return _.debounce((newValue: string) => {
      setSearchURLParam(newValue);
    }, 250);
  }, [setSearchURLParam]);

  const handleUpdateSearch = useCallback(
    (newValue: string) => {
      setSearchValue(newValue);
      updateSearchParamsDebounced(newValue);
    },
    [updateSearchParamsDebounced],
  );

  const handleSearchClear = () => {
    setSearchValue("");
    setSearchURLParam();
  };

  return { handleUpdateSearch, handleSearchClear, searchValue };
};
