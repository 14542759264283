import {
  clickCopyLink,
  clickEmailLink,
  clickLinkedInLink,
  clickTwitterLink,
} from "@js/apps/dashboard/actions";
import type { ReferType } from "@js/apps/dashboard/types";
import { useAppDispatch } from "@js/hooks";

import { useCtaClickedContext } from "../../context";

type UseReferSocialButtonsProps = {
  type: ReferType;
  handleCopy: (text: string) => void;
  referralLink: string;
};

export const useReferSocialButtons = ({
  type,
  referralLink,
  handleCopy,
}: UseReferSocialButtonsProps) => {
  const { ctaClicked } = useCtaClickedContext();
  const dispatch = useAppDispatch();

  const handleEmailClick = () => {
    ctaClicked(ENUMS.UserClicksEventCTAName.SHARE_VIA_EMAIL);
    dispatch(clickEmailLink(type));
  };

  const handleLinkedinClick = () => {
    ctaClicked(ENUMS.UserClicksEventCTAName.SHARE_VIA_LINKEDIN);
    dispatch(clickLinkedInLink(type));
  };

  const handleTwitterClick = () => {
    ctaClicked(ENUMS.UserClicksEventCTAName.SHARE_VIA_TWITTER);
    dispatch(clickTwitterLink(type));
  };

  const handleCopyLinkClick = () => {
    handleCopy(referralLink);
    ctaClicked(ENUMS.UserClicksEventCTAName.COPY_LINK);
    dispatch(clickCopyLink(type));
  };

  return {
    handleEmailClick,
    handleLinkedinClick,
    handleTwitterClick,
    handleCopyLinkClick,
  };
};
