import {
  Box,
  Button,
  Loader,
  Pagination,
  PaginationWrapper,
} from "@hexocean/braintrust-ui-components";
import {
  KeyboardArrowRightIcon,
  SaveOutlinedIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { SearchListingHeader } from "@js/apps/common/components";
import { SearchViewHeaderContainer } from "@js/apps/common/components/search-view-header-container";
import { SavedJobFiltersDrawer } from "@js/apps/jobs/apps/listing/components";
import { useSearchJobs } from "@js/apps/jobs/apps/listing/hooks";
import { WithJobActionsContext } from "@js/apps/jobs/components/job-actions";
import { JobsListing } from "@js/apps/jobs/components/listing";
import type { JOB_LOCATION } from "@js/apps/jobs/context";
import { JobLocationContext } from "@js/apps/jobs/context";
import { UniversalSearchContextProvider } from "@js/apps/universal-search/context/universal-search-context";
import { clearFiltersTalentsAndJobsUniversalSearch } from "@js/apps/universal-search/helpers";
import { RouterLink } from "@js/components/link";
import { AppLayout } from "@js/layouts/app";

import { SearchJobsListFilters } from "../../components/search-jobs-list-filters";

import styles from "./search-jobs.module.scss";

type SearchJobsPageProps = {
  location?: EnumType<typeof JOB_LOCATION>;
};

export const SearchJobsPage = ({
  location = JobLocationContext.Values.job_search,
}: SearchJobsPageProps) => {
  const {
    searchTerm,
    jobs,
    total,
    loading,
    withJobActionContextProviderValue,
    filters,
    isAnyFilterApplied,
    isSavedFiltersDrawerOpen,
    loadNewFilters,
    deleteSavedFilter,
    setIsSavedFiltersDrawerOpen,
    setIsSavedFiltersDrawerOpenHandler,
  } = useSearchJobs({ location });

  return (
    <UniversalSearchContextProvider initialTab="JOBS">
      <AppLayout
        pageTitle="Search jobs"
        className="wider"
        bgcolor="var(--white)"
      >
        <SavedJobFiltersDrawer
          location={location}
          onChange={loadNewFilters}
          open={isSavedFiltersDrawerOpen}
          setOpen={setIsSavedFiltersDrawerOpen}
          onRemove={deleteSavedFilter}
        />
        <Box className={styles.listingPage}>
          <JobLocationContext.Provider value={location}>
            <Header
              searchTerm={searchTerm}
              handleSavedSearchesClick={setIsSavedFiltersDrawerOpenHandler}
            />
            <Box mt={2} sx={{ width: "100%" }}>
              <SearchJobsListFilters
                count={total}
                filters={filters}
                isAnyFilterApplied={isAnyFilterApplied}
              />

              <WithJobActionsContext.Provider
                value={withJobActionContextProviderValue}
              >
                <Box className={styles.listingPageContent}>
                  {loading ? (
                    <Box mt={4} position={"relative"}>
                      <Loader centered />
                    </Box>
                  ) : (
                    <>
                      <JobsListing jobs={jobs} searchResults />
                      <PaginationWrapper mt={0}>
                        <Pagination
                          RouterLink={RouterLink}
                          count={total}
                          perPage={SETTINGS.JOBS_LISTING_JOBS_PER_PAGE}
                        />
                      </PaginationWrapper>
                    </>
                  )}
                </Box>
              </WithJobActionsContext.Provider>
            </Box>
          </JobLocationContext.Provider>
        </Box>
      </AppLayout>
    </UniversalSearchContextProvider>
  );
};

const Header = ({
  searchTerm,
  handleSavedSearchesClick,
}: {
  searchTerm?: string;
} & CtaButtonsProps) => {
  const headerText = searchTerm
    ? `Job results for "${searchTerm}"`
    : "Job results";

  return (
    <SearchViewHeaderContainer>
      <SearchListingHeader hideUnderline size="medium">
        {headerText}
      </SearchListingHeader>
      <CtaButtons handleSavedSearchesClick={handleSavedSearchesClick} />
    </SearchViewHeaderContainer>
  );
};

type CtaButtonsProps = {
  handleSavedSearchesClick: () => void;
};
const CtaButtons = ({ handleSavedSearchesClick }: CtaButtonsProps) => {
  const handleCtaClick = () => {
    clearFiltersTalentsAndJobsUniversalSearch("JOBS");
  };

  return (
    <Box display="flex" gap={1} flexShrink={0}>
      <Button
        RouterLink={RouterLink}
        to="/jobs/"
        variant="secondary"
        endIcon={<KeyboardArrowRightIcon />}
        onClick={handleCtaClick}
      >
        Go to Jobs
      </Button>
      <Button
        variant="primary"
        startIcon={<SaveOutlinedIcon />}
        onClick={handleSavedSearchesClick}
      >
        Saved searches
      </Button>
    </Box>
  );
};
