import { useMemo } from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { SortableList } from "@js/components/sortable-list";

import type { MAX_TOP_SKILLS_COUNT } from "../const";
import { isMaxSelectedTopSkillsCountReached } from "../helpers";

import type { SkillListItem } from "./item";
import { SelectedSkillItem } from "./item";

import styles from "./style.module.scss";

type SelectedSkillsListBaseProps = {
  error?: string | string[];
  handleFavorite: (value: SkillListItem) => void;
  handleRemove: (value: SkillListItem) => void;
  handleReorder: (value: SkillListItem[]) => void;
  selectedSkillsCount: number;
  options: SkillListItem[];
  maxTopSkillsCount: EnumType<typeof MAX_TOP_SKILLS_COUNT>;
};

export const SelectedSkillsList = ({
  error,
  selectedSkillsCount,
  options,
  handleReorder,
  handleFavorite,
  handleRemove,
  maxTopSkillsCount,
}: SelectedSkillsListBaseProps) => {
  const selectedSuperpowerQuantity = useMemo(
    () => options.filter((option) => option.is_superpower).length,
    [options],
  );
  const maxTopSkillsCountReached = isMaxSelectedTopSkillsCountReached(
    maxTopSkillsCount,
    options,
  );

  return (
    <div className={styles.list}>
      {error && (
        <Typography component="p" mb={1} color="negative">
          {error}
        </Typography>
      )}
      {selectedSkillsCount > 0 && (
        <Typography
          component="p"
          size="small"
          variant="label"
          ml={5}
          color="grey-2"
        >
          Skills - {selectedSkillsCount}
        </Typography>
      )}
      <SortableList
        items={options}
        useDragHandle={false}
        reorderAction={handleReorder}
        getUniqueItemKey={(skill) => skill.id}
        stickToContainerBorders
        isDraggable={(skill) =>
          skill.is_superpower && selectedSuperpowerQuantity > 1
        }
      >
        {(skill) => (
          <SelectedSkillItem
            key={skill.id}
            skill={skill}
            onFavorite={handleFavorite}
            onRemove={handleRemove}
            quantityFavoriteSkills={selectedSuperpowerQuantity}
            maxTopSkillsCountReached={maxTopSkillsCountReached}
          />
        )}
      </SortableList>
    </div>
  );
};
