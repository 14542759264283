import { useCallback, useEffect, useMemo } from "react";

import { ModalInstance } from "@js/components/modal";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { EmployerInvoiceMinimal } from "@js/types/invoices";

import { fetchDependentEmployerInvoices } from "../../actions";

type UsePayDependentInvoicesFirstProps = {
  invoicesThatCannotBePaid: EmployerInvoiceMinimal[];
  onConfirmIncludingInvoices: (toInclude: number[]) => void;
};

export const usePayDependentInvoicesFirst = ({
  invoicesThatCannotBePaid,
  onConfirmIncludingInvoices,
}: UsePayDependentInvoicesFirstProps) => {
  const dispatch = useAppDispatch();

  const fetchingDependentInvoicesList = useAppSelector(
    (state) => state.invoices.fetchingDependentInvoicesList,
  );

  const dependentInvoicesList = useAppSelector(
    (state) => state.invoices.dependentInvoicesList,
  );

  useEffect(() => {
    if (invoicesThatCannotBePaid && !!invoicesThatCannotBePaid.length) {
      dispatch(
        fetchDependentEmployerInvoices({
          ids: invoicesThatCannotBePaid
            .map(({ dependent_on: dependentOn }) => dependentOn)
            .join(","),
        }),
      );
    }
  }, [dispatch, invoicesThatCannotBePaid]);

  const dependentThatCantBePaid = useMemo(() => {
    return (
      dependentInvoicesList &&
      dependentInvoicesList.filter((i) => !i.can_be_paid)
    );
  }, [dependentInvoicesList]);

  const handleConfirm = useCallback(() => {
    const dependentInvoices = invoicesThatCannotBePaid
      .map(({ dependent_on: dependentOn }) => dependentOn)
      .filter(Boolean) as number[];

    onConfirmIncludingInvoices(dependentInvoices);

    ModalInstance.close();
  }, [invoicesThatCannotBePaid, onConfirmIncludingInvoices]);

  return {
    fetchingDependentInvoicesList,
    dependentThatCantBePaid,
    dependentInvoicesList,
    handleConfirm,
  };
};
