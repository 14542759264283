// https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (_e) {
    return true;
  }
};

export const iframePostMessage = (
  data: { redirect: string },
  targetOrigins = SETTINGS.IFRAME_ALLOWED_TARGET_ORIGINS,
) => {
  targetOrigins.forEach((targetOrigin) => {
    try {
      window.top?.postMessage(data, targetOrigin);
    } catch (_e) {
      // ignore
      console.error(
        "The target origin provided " +
          targetOrigin +
          " does not match recipient window's origin.",
      );
    }
  });
};
