import * as actions from "./action-types";

/** @description Only for `Modal` component implementation; use `SomeModalInstance.open()` */
export const openModalAction = (modalId: string) => ({
  type: actions.OPEN_MODAL,
  payload: modalId,
});

/** @description Only for `Modal` component implementation; use `SomeModalInstance.close()` */
export const closeModalAction = (modalId: string) => ({
  type: actions.CLOSE_MODAL,
  payload: modalId,
});
