import type { CSSProperties, ReactNode } from "react";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useMemo } from "react";
import React from "react";
import { useNavigationType } from "react-router-dom";
import type { VirtuosoHandle } from "react-virtuoso";
import { Virtuoso } from "react-virtuoso";
import _ from "underscore";

import { Box, Stack } from "@hexocean/braintrust-ui-components";
import type { IPost } from "@js/types/give-and-get-help";

import { usePostLocationContext } from "../../context/post-location";
import type { Hashtag } from "../../types";
import { PostsListEndMessage } from "../posts-list-end-message";
import { PostsListLoadingState } from "../posts-list-loading-state";
import type { TrendingHashtagsProps } from "../trending-hashtags/trending-hashtags";

import {
  getFeedRenderedItemIndex,
  setFeedRenderedItemIndex,
} from "./rendered-item-index";
import type { PickedPostItemProps } from "./virtualized-posts-list-item";
import { postItemContent } from "./virtualized-posts-list-item";

export type VirtualizedPostsListProps = {
  posts: IPost[];
  trendingHashtagsProps?: Omit<TrendingHashtagsProps, "hashtags">;
  trendingHashtags?: Hashtag[];
  hasMore: boolean;
  fetchMore: () => void;
  fetchingPosts?: boolean;
} & PickedPostItemProps;

const TWO_POSTS_FROM_BOTTOM_PX = 2500;

export const VirtualizedPostsList: React.FC<VirtualizedPostsListProps> = ({
  posts,
  trendingHashtagsProps,
  trendingHashtags,
  hasMore,
  fetchMore,
  fetchingPosts,
  ...postItemProps
}) => {
  const ref = useRef<VirtuosoHandle>(null);
  const location = usePostLocationContext();
  const [showEndMessageDelayed, setShowEndMessageDelayed] = useState(false);
  const navigationType = useNavigationType();
  const [initialTopMostItemIndex] = useState(() =>
    getFeedRenderedItemIndex({ navigationType, location }),
  );

  useEffect(() => {
    // do not show end message on mount as posts might render after 1 render circle
    setShowEndMessageDelayed(!hasMore);
  }, [hasMore]);

  const handleItemsRenderDebounced = useMemo(() => {
    return _.debounce((items: Array<{ index: number }>) => {
      const [firstRenderedItem] = items;
      if (!firstRenderedItem) {
        return;
      }

      setFeedRenderedItemIndex({ index: firstRenderedItem.index, location });
    }, 100);
  }, [location]);

  const handleBackToTopClick = () => {
    ref.current?.scrollToIndex({
      index: 0,
      align: "start",
      behavior: "smooth",
    });
  };

  return (
    <Box mt={2}>
      <Virtuoso
        ref={ref}
        useWindowScroll
        totalCount={posts.length}
        defaultItemHeight={1500}
        components={Components}
        itemsRendered={handleItemsRenderDebounced}
        atBottomThreshold={TWO_POSTS_FROM_BOTTOM_PX}
        increaseViewportBy={2000}
        initialTopMostItemIndex={initialTopMostItemIndex ?? 0}
        atBottomStateChange={(atBottom) => {
          // use atBottomStateChange instead of endReached to use customized atBottomThreshold
          if (!atBottom || !hasMore || fetchingPosts) {
            return;
          }

          fetchMore();
        }}
        itemContent={(index) =>
          postItemContent({
            post: posts[index],
            index,
            postsCount: posts.length,
            trendingHashtags,
            trendingHashtagsProps,
            location,
            ...postItemProps,
          })
        }
      />

      {fetchingPosts && <PostsListLoadingState />}
      {showEndMessageDelayed && (
        <PostsListEndMessage onBackToTopClick={handleBackToTopClick} />
      )}
    </Box>
  );
};

const Components = {
  List: React.forwardRef<
    HTMLDivElement,
    { style?: CSSProperties; children?: ReactNode }
  >(({ style, children }, listRef) => {
    return (
      <Stack sx={{ gap: 2 }} style={style} ref={listRef}>
        {children}
      </Stack>
    );
  }),
};
