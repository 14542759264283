import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Snackbar } from "@js/components/snackbar";
import { useQueryParams } from "@js/hooks/query-params";

export const NOTIFICATION_UNSUBSCRIBE_TYPE = "notification_unsubscribe_type";

export const useShowSnackbarFromQueryParam = () => {
  const [, setSearchParams] = useSearchParams();
  const query = useQueryParams();
  const notificationType = query[NOTIFICATION_UNSUBSCRIBE_TYPE];

  useEffect(() => {
    if (!notificationType) return;

    showUnsubscribeNotificationSnackbar(notificationType);

    setSearchParams(
      (prevState) => {
        prevState.delete(NOTIFICATION_UNSUBSCRIBE_TYPE);
        return prevState;
      },
      { replace: true },
    );
  }, [notificationType, setSearchParams]);
};

type NotificationMessageKey = keyof typeof ENUMS.UnsubscribeResultMessage;

export const showUnsubscribeNotificationSnackbar = (
  notificationType: NotificationMessageKey,
) => {
  const message = ENUMS.UnsubscribeResultMessage[notificationType];

  if (!message) return;

  if (message === ENUMS.UnsubscribeResultMessage.FAILED) {
    return Snackbar.error(message);
  }

  return Snackbar.success(message);
};
