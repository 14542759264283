import type { OptionWithPrimitiveValue } from "@js/types/common";

export type HashtagOption = OptionWithPrimitiveValue & {
  label: string;
  iconBackgroundColor: string;
  iconColor: string;
};

export const trendingHashtagsPosition = 2; // index after which trending hashtags component will appear

const trendingHashtagsColors = [
  { iconColor: "--dark-blue", iconBackgroundColor: "--medium-blue" },
  { iconColor: "--dark-orange", iconBackgroundColor: "--medium-orange" },
  { iconColor: "--dark-green", iconBackgroundColor: "--medium-green" },
  { iconColor: "--dark-violet", iconBackgroundColor: "--medium-violet" },
  { iconColor: "--dark-yellow", iconBackgroundColor: "--medium-yellow" },
  { iconColor: "--dark-teal", iconBackgroundColor: "--medium-teal" },
];

export const assignColors = (
  options: OptionWithPrimitiveValue[],
): HashtagOption[] => {
  return options.map(({ value, label }, index) => ({
    value,
    label: String(label),
    iconColor: `var(${
      trendingHashtagsColors[index % trendingHashtagsColors.length].iconColor
    })`,
    iconBackgroundColor: `var(${
      trendingHashtagsColors[index % trendingHashtagsColors.length]
        .iconBackgroundColor
    })`,
  }));
};
