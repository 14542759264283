import type { AnyAction } from "@reduxjs/toolkit";

import { AVATAR_FIELD_UPLOADING } from "@js/apps/employer/action-types";

import type { EmployerState } from "../../types/employer";

const INITIAL_STATE: EmployerState = {
  avatarFieldUploading: false,
};

export default (state = INITIAL_STATE, action: AnyAction): EmployerState => {
  switch (action.type) {
    case AVATAR_FIELD_UPLOADING:
      return {
        ...state,
        avatarFieldUploading: action.payload,
      };

    default:
      return state;
  }
};
