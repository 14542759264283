import React, { useEffect } from "react";
import classNames from "classnames";

import { PageHead } from "@js/components/page-head";
import { PageTitle } from "@js/components/page-title";

import { CurrentBuildData } from "../../components/dev-current-build-copy";

export type CoreLayoutProps = {
  children: React.ReactNode;
  className?: string;
  pageTitle?: string;
  marginTop?: number;
  hideMetaTags?: boolean; // in case tags are set by an individual page
};

export const CoreLayout = ({
  children,
  className = "",
  pageTitle = SETTINGS.OG_TAG_DEFAULT_TITLE,
  marginTop = 0,
  hideMetaTags,
}: CoreLayoutProps) => {
  useEffect(() => {
    CoreLayout.depth += 1;
  }, []);

  return (
    <div
      className={classNames("core-layout", className)}
      style={{ zIndex: 900 + CoreLayout.depth, marginTop }}
    >
      {!hideMetaTags && (
        <>
          <PageHead>
            <meta property="og:title" content={SETTINGS.OG_TAG_DEFAULT_TITLE} />
            <meta
              name="description"
              content={SETTINGS.OG_TAG_DEFAULT_DESCRIPTION}
            />
            <meta
              property="og:description"
              content={SETTINGS.OG_TAG_DEFAULT_DESCRIPTION}
            />
            <meta
              property="og:image"
              content={SETTINGS.STATIC_URL + SETTINGS.OG_TAG_DEFAULT_IMAGE}
            />
          </PageHead>
          <PageTitle>{pageTitle}</PageTitle>
        </>
      )}
      {children}
      {process.env.DEPLOYMENT !== "production" && <CurrentBuildData />}
    </div>
  );
};

CoreLayout.depth = 0;
