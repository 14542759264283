import { useMemo } from "react";
import type { WrappedFieldProps } from "redux-form";

import {
  Box,
  ComboBox,
  TextField,
  Typography,
} from "@hexocean/braintrust-ui-components";

import type { HourOption } from "./constants";
import { HOUR_OPTIONS } from "./constants";

import styles from "./styles.module.scss";

type HoursRangeSelectorProps = {
  working_hours_start: WrappedFieldProps;
  working_hours_end: WrappedFieldProps;
  disabled?: boolean;
};

export const HoursRangeSelector = ({
  working_hours_start,
  working_hours_end,
  disabled,
}: HoursRangeSelectorProps) => {
  const { selectedStartHour, selectedEndHour, hoursOptions, error } =
    useSelectRangeHoursField({
      fromHourField: working_hours_start,
      toHourField: working_hours_end,
    });

  return (
    <Box mb={2}>
      <Typography
        component="label"
        size="medium"
        fontWeight={500}
        color={disabled ? "grey-3" : undefined}
      >
        Working hours
      </Typography>
      <Box mt={1} display="flex" alignItems="center">
        <ComboBox
          className={styles.hourSelector}
          initialTaxonomiesLoading={false}
          options={hoursOptions}
          getOptionLabel={(option) => option.label}
          disabled={disabled}
          disableClearable={selectedStartHour !== null}
          value={selectedStartHour}
          onChange={(_event, selectedHour: HourOption | null) => {
            working_hours_start.input.onChange(selectedHour?.value ?? null);
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  ["aria-label"]: "Start working hour",
                }}
              />
            );
          }}
        />
        <Typography component="span" size="medium" mx={{ xs: 1, sm: 2 }}>
          to
        </Typography>
        <ComboBox
          className={styles.hourSelector}
          initialTaxonomiesLoading={false}
          options={hoursOptions}
          getOptionLabel={(option) => option.label}
          disabled={disabled}
          disableClearable={selectedEndHour !== null}
          value={selectedEndHour}
          onChange={(_event, selectedHour: HourOption | null) => {
            working_hours_end.input.onChange(selectedHour?.value ?? null);
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  ["aria-label"]: "End working hour",
                }}
              />
            );
          }}
        />
      </Box>
      {!!error && (
        <Typography component="p" size="small" error>
          {error}
        </Typography>
      )}
    </Box>
  );
};

type UseSelectRangeHoursFieldArgs = {
  fromHourField: WrappedFieldProps;
  toHourField: WrappedFieldProps;
};

const useSelectRangeHoursField = ({
  fromHourField,
  toHourField,
}: UseSelectRangeHoursFieldArgs) => {
  const selectedStartHour = useMemo(
    () =>
      HOUR_OPTIONS.find(({ value }) => value === fromHourField.input.value) ||
      null,
    [fromHourField.input.value],
  );
  const selectedEndHour = useMemo(
    () =>
      HOUR_OPTIONS.find(({ value }) => value === toHourField.input.value) ||
      null,
    [toHourField.input.value],
  );

  const fromFieldError = fromHourField.meta.touched && fromHourField.meta.error;
  const toFieldError = toHourField.meta.touched && toHourField.meta.error;
  const error = fromFieldError || toFieldError;

  return {
    selectedStartHour,
    selectedEndHour,
    hoursOptions: HOUR_OPTIONS,
    error,
  };
};
