import { useQueryParams } from "@js/hooks";

export const useCheckIfRedirectedToReview = () => {
  const query = useQueryParams();
  const { content_type: contentType, write_review: writeReviewParam } = query;

  return {
    reviewType: contentType,
    isRedirectedToReview: writeReviewParam?.toLowerCase() === "true",
  };
};
