import React from "react";

import { JobStatusBox } from "@js/apps/jobs/components/job-status/components/job-status-box";
import type { JobStatusBoxProps } from "@js/apps/jobs/components/job-status/types";

export const NotAcceptingProposals = (props: JobStatusBoxProps) => {
  return (
    <JobStatusBox color="var(--negative-1)" {...props}>
      <div>
        <JobStatusBox.Header>Job Paused</JobStatusBox.Header>
        <JobStatusBox.Subheader>
          Not Accepting Applications
        </JobStatusBox.Subheader>
      </div>
    </JobStatusBox>
  );
};
