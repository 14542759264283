import { useEffect, useState } from "react";

/**
 * Counts down the time in seconds based on the specified delay
 *
 * @param delay time in ms.
 */
export const useTimer = (
  delay: number,
): {
  isTimerOn: boolean;
  startTimer: () => void;
  resetTimer: () => void;
} => {
  const delayInit = () => {
    if (delay % 1000 === 0) {
      return delay;
    } else {
      throw Error(`Timer - the number given (${delay}) is indivisible by 1000`);
    }
  };
  const [isTimerOn, setIsTimerOn] = useState(false);
  const [timerTime, setTimerTime] = useState<number>(delayInit());
  const [timerKey, setTimerKey] = useState<number>(0);

  useEffect(() => {
    if (timerTime <= 0 && isTimerOn) {
      window.clearInterval(timerKey);
      setIsTimerOn(false);
    }
  }, [timerTime, isTimerOn, timerKey]);

  const startTimer = () => {
    if (!isTimerOn) {
      if (timerTime === 0) {
        setTimerTime(delayInit());
      }

      setIsTimerOn(true);

      const timer = window.setInterval(() => {
        setTimerTime((prevState) => prevState - 1000);
      }, 1000);

      setTimerKey(timer);
    }
  };

  const resetTimer = () => {
    setTimerTime(delayInit());
  };

  return {
    isTimerOn: timerTime !== 0,
    startTimer,
    resetTimer,
  };
};
