import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { useAppSelector } from "@js/hooks/redux";
import type { RootState } from "@js/store";
import type { IPost, PostComment } from "@js/types/give-and-get-help";

type PostsAddedCommentsByUser = Record<
  IPost["id"],
  PostComment["id"][] | undefined
>;

type PostsCommentsState = {
  addedLvl1CommentsByUser: PostsAddedCommentsByUser;
};

const initialState: PostsCommentsState = {
  addedLvl1CommentsByUser: {},
};

export const postsCommentsSlice = createSlice({
  name: "posts-comments-module",
  initialState,
  reducers: {
    markAddedComment(
      state,
      action: PayloadAction<{
        postId: IPost["id"];
        commentId: PostComment["id"];
      }>,
    ) {
      const { postId, commentId } = action.payload;
      const currentPostAddedComments = state.addedLvl1CommentsByUser[postId];

      state.addedLvl1CommentsByUser[postId] = currentPostAddedComments
        ? [...new Set([commentId, ...currentPostAddedComments])]
        : [commentId];
    },
  },
});

export const { markAddedComment } = postsCommentsSlice.actions;

const getAddedLvl1CommentsByUser = (state: RootState) =>
  state.postsComments.addedLvl1CommentsByUser;

const defaultPostAddedComments: number[] = [];
export const useAddedLvl1CommentsByUser = ({
  postId,
}: {
  postId: IPost["id"];
}): number[] => {
  const addedLvl1CommentsByUser = useAppSelector(getAddedLvl1CommentsByUser);
  const addedLvl1CommentsToPost = addedLvl1CommentsByUser[postId];

  return addedLvl1CommentsToPost ?? defaultPostAddedComments;
};
