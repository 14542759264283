import { useState } from "react";
import { Field } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useUploadResume } from "@js/apps/freelancer/hooks/use-upload-resume";
import { AddResumeField } from "@js/apps/jobs/forms/fields";
import { createFormInstance, WARNING_MODAL_TYPE } from "@js/forms/components";
import { useUnsavedChangesWarning } from "@js/forms/hooks";
import type { Freelancer } from "@js/types/freelancer";
import type { Attachment } from "@js/types/uploads";

import styles from "./style.module.scss";

type FormData = {
  resume: { id: number; attachment: Attachment };
};

export const UPLOAD_RESUME_FORM_ID = "upload-resume";

const UploadResumeFormInstance = createFormInstance<FormData, unknown>(
  UPLOAD_RESUME_FORM_ID,
);

export const FreelancerProfilePageUploadResumeForm = ({
  id,
}: {
  id: Freelancer["id"];
}) => {
  const { error, onSubmit } = useUploadResume(id);
  const [showSaveButton, setShowSaveButton] = useState(false);

  const handleChange = (values: { resume?: { id?: number } }) => {
    if (!!values?.resume?.id) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  };

  useUnsavedChangesWarning({
    formId: UPLOAD_RESUME_FORM_ID,
    unsavedChangesWarning: true,
    unsavedChangesWarningModelType: WARNING_MODAL_TYPE.UPLOAD_RESUME,
  });

  return (
    <UploadResumeFormInstance
      onSubmit={onSubmit}
      error={error}
      onChange={handleChange}
    >
      <Box textAlign="center">
        <Typography component="h4" variant="title" size="small" mt={4} mb={2}>
          Add a resume to your profile
        </Typography>
        <Field
          name="resume"
          component={AddResumeField}
          hideCheckbox
          uploaderClassName={styles.uploader}
        />
        {showSaveButton && (
          <Button variant="primary" type="submit" className={styles.saveButton}>
            Save
          </Button>
        )}
      </Box>
    </UploadResumeFormInstance>
  );
};
