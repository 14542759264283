import { Box, Tooltip } from "@hexocean/braintrust-ui-components";
import { BoltIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useOptionTooltip } from "@js/apps/available-for-work/hooks/use-option-tooltip";
import { Badge } from "@js/components/badge";
import type { Freelancer } from "@js/types/freelancer";
import type { TalentInviteSuggestion } from "@js/types/jobs";

type LookingForWorkBadgeProps = {
  availabilityForWork: boolean;
  withTooltip?: boolean;
  freelancer?: Freelancer | TalentInviteSuggestion;
};

export const LookingForWorkBadge = ({
  availabilityForWork,
  withTooltip = true,
  freelancer,
}: LookingForWorkBadgeProps) => {
  const tooltipTitle = useOptionTooltip(
    freelancer?.availability_for_work_options,
  );
  if (!availabilityForWork) return null;

  const badge = (
    <Badge color="var(--dark-violet)" backgroundColor="var(--medium-violet)">
      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <BoltIcon
          style={{
            height: "13px",
            width: "13px",
            fill: "var(--dark-violet)",
          }}
        />
        Looking for Work
      </div>
    </Badge>
  );

  if (withTooltip) {
    return (
      <Tooltip
        title={tooltipTitle}
        color="var(--black)"
        sx={{ display: "flex" }}
      >
        <Box>{badge}</Box>
      </Tooltip>
    );
  }

  return badge;
};
