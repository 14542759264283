import { HelpOfferButtonsLeaveReview } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/help-offer-buttons-leave-review";

import { ActionBarOfferButtons } from "./action-bar-offer-buttons/action-bar-offer-buttons";
import type { ActionBarOfferButtonsProps } from "./action-bar-offer-buttons";
import { HelpOfferActionMenuWrapper } from "./help-offer-action-menu-wrapper";

import styles from "./styles.module.scss";

type ActionBarOfferStatusProps = Pick<
  ActionBarOfferButtonsProps,
  "offer" | "isOfferAuthor" | "onMessageHelper"
>;

export const ActionBarOfferStatus = ({
  offer,
  isOfferAuthor,
  onMessageHelper,
}: ActionBarOfferStatusProps): JSX.Element | null => {
  return (
    <div className={styles.helpOfferButtons}>
      <ActionBarOfferButtons
        offer={offer}
        isOfferAuthor={isOfferAuthor}
        type="messenger"
        CompletedStatusComponent={HelpOfferButtonsLeaveReview}
        onMessageHelper={onMessageHelper}
      />
      <HelpOfferActionMenuWrapper offer={offer} isOfferAuthor={isOfferAuthor} />
    </div>
  );
};
