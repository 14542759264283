import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";

import { useJoinThisSpace } from "../../hooks/use-join-this-space";

export const useSpaceCard = (spaceId: number, showMobileVariant?: boolean) => {
  const mobileVariant = useMediaQuery("sm") || !!showMobileVariant;
  const { handleJoinSpaceClick, isJoining } = useJoinThisSpace(spaceId);

  return { mobileVariant, handleJoinSpaceClick, isJoining };
};
