import { fetchSavedFilters } from "@js/apps/common/components/save-filters/actions";
import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { SAVED_FILTERS_ENDPOINT } from "../../const";

export const useSavedTalentsFilters = () => {
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(fetchSavedFilters(SAVED_FILTERS_ENDPOINT.TALENTS));
  });

  const savedTalentFilters = useAppSelector(
    (state) => state.savedFilters.filtersTalents,
  );

  return {
    savedTalentFilters,
  };
};
