import { useMemo } from "react";

import { useGetMySpacesQuery } from "@js/apps/spaces/api";

type UseIsSpaceMemberProps = {
  spaceId: number | undefined;
};

export const useIsSpaceMember = ({ spaceId }: UseIsSpaceMemberProps) => {
  const { data: talentSpaces, isLoading, isFetching } = useGetMySpacesQuery();

  const isSpaceMember = useMemo(() => {
    if (!spaceId) {
      return false;
    }

    return !!talentSpaces?.some((space) => space.id === spaceId);
  }, [spaceId, talentSpaces]);

  return {
    isSpaceMember,
    isLoading,
    isFetching,
  };
};
