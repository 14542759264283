import { useEffect, useState } from "react";

import type { TimezoneOffset } from "@js/types/common";
import { getLocalTime } from "@js/utils";

export const useGetLocalTime = (timezone_offset?: TimezoneOffset) => {
  const [localTime, setLocalTime] = useState<string>();

  useEffect(() => {
    if (!timezone_offset) {
      return;
    }

    const interval = setInterval(() => {
      setLocalTime(getLocalTime(timezone_offset));
    }, 1000);

    return () => clearInterval(interval);
  }, [timezone_offset]);

  return localTime;
};
