import { memo } from "react";

import { Box, Divider } from "@hexocean/braintrust-ui-components";
import { JobActionButton } from "@js/apps/jobs/components/job-action-buttons";
import { JobItemTopTagsSection } from "@js/apps/jobs/components/job-item/components/job-item-top-tags-section";
import type { MainJobListingJob } from "@js/types/jobs";

import { ScoreMatchBadge } from "../avatar-with-score-match-badge";
import { JobSkills } from "../job-skills";

import {
  JobItemBasicInfo,
  JobItemBudgetShareAndBookmarkSection,
  JobItemButtons,
  JobItemEmployerData,
  JobItemExpandBtn,
  JobItemExpandedContent,
  JobItemHeader,
} from "./components";
import { useJobItem } from "./hooks";

import styles from "./styles.module.scss";

export type JobItemProps = {
  job: MainJobListingJob;
  invitesTab?: boolean;
  resultPosition?: number;
  searchResults?: boolean;
};

export const JobItem = memo(
  ({
    job,
    resultPosition,
    searchResults,
    invitesTab = false,
  }: JobItemProps) => {
    const {
      isMobile,
      isTablet,
      jobType,
      skillToDisplay,
      expanded,
      handleSetExpanded,
      isApplied,
      location,
    } = useJobItem({
      job,
      searchResults,
      resultPosition,
    });

    const statusForFreelancer = job.status_for_freelancer;

    return (
      <Box className={styles.wrapper} tabIndex={0}>
        <Box className={styles.topSection}>
          <JobItemEmployerData employer={job.employer} />
          <Box className={styles.header}>
            <JobItemHeader
              title={job.title}
              id={job.id}
              resultPosition={resultPosition}
              searchResults={searchResults}
            />
            <JobItemButtons
              isInvitesTab={invitesTab}
              isMobile={isMobile}
              isTablet={isTablet}
              job={job}
            >
              <JobActionButton
                job={job}
                bidId={job.bid_id}
                statusForFreelancer={statusForFreelancer}
                fullWidth={isMobile}
                size={isMobile ? "x-small" : "medium"}
                resultPosition={resultPosition}
                searchResults={searchResults}
              />
            </JobItemButtons>

            <JobItemTopTagsSection job={job} jobType={jobType} />

            <JobItemBasicInfo job={job} />
            <JobItemBudgetShareAndBookmarkSection job={job} />
            <Box className={styles.divider}>
              <Divider color="beige" />
            </Box>
            <Box className={styles.footer}>
              <Box display="flex" alignItems="center" flexWrap="wrap" gap="8px">
                <ScoreMatchBadge
                  isApplied={isApplied}
                  matchLabel={job.match_label}
                  jobId={job.id}
                />
                <Box marginTop="8px">
                  <JobSkills skills={skillToDisplay} />
                </Box>
              </Box>
              <JobItemExpandBtn
                expanded={expanded}
                onExpand={handleSetExpanded}
                jobId={job.id}
              />
            </Box>
          </Box>
        </Box>

        {expanded && (
          <JobItemExpandedContent
            job={job}
            isApplied={isApplied}
            resultPosition={resultPosition}
            searchResults={searchResults}
            location={location}
          />
        )}
      </Box>
    );
  },
);
