import { fetchSavedFilters } from "@js/apps/common/components/save-filters/actions";
import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { SAVED_FILTERS_ENDPOINT } from "../../const";

export const useSavedJobsFilters = () => {
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(fetchSavedFilters(SAVED_FILTERS_ENDPOINT.JOBS));
  });

  const savedJobFilters = useAppSelector(
    (state) => state.savedFilters.filtersJobs,
  );

  return {
    savedJobFilters,
  };
};
