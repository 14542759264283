import { useCallback, useMemo } from "react";
import { SubmissionError } from "redux-form";

import { useUser } from "@js/apps/common/hooks";
import { interestedClicked } from "@js/apps/give-and-get-help/actions";
import {
  useCreateHelpOfferMessageMutation,
  useUpdatePostMutation,
} from "@js/apps/give-and-get-help/api";
import { usePostContext } from "@js/apps/give-and-get-help/context";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import { useDisableSpecificPostActions } from "@js/apps/give-and-get-help/hooks/use-disable-specific-post-actions";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import { openSignUpModal } from "@js/apps/onboarding/components/sign-up-modal";
import { CommonConfirmationModal } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";
import type { PostComment } from "@js/types/give-and-get-help";

import { ConfirmRevokeOfferModalContent } from "./confirm-revoke-offer-modal-content";

import styles from "./style.module.scss";

export const useCommentOfferBox = (postComment: PostComment) => {
  const { isPublic, postData } = usePostContext();
  const user = useUser();
  const dispatch = useAppDispatch();
  const { openMessengerModal } = useOpenMessengerModal();
  const postLocation = usePostLocationContext();
  const disablePostActions = useDisableSpecificPostActions();
  const { freelancer, help_offer: helpOffer } = postComment;
  const helpOfferStatus = helpOffer?.status;
  const { CREATED, PENDING, DECLINED, ACCEPTED, COMPLETED } =
    ENUMS.HelpOfferStatus;
  const isPostCreator = user?.id === postData?.freelancer.user.id;
  const isCommentAuthor = useMemo(
    () => (user ? freelancer.user.id === user.id : false),
    [freelancer, user],
  );
  const hasCreatedOrPendingStatus =
    helpOfferStatus === CREATED || helpOfferStatus === PENDING;

  const status = {
    offerDeclined: helpOfferStatus === DECLINED && isPostCreator,
    offerAccepted:
      (helpOfferStatus === ACCEPTED || helpOfferStatus === COMPLETED) &&
      isPostCreator,
  };

  const getLabel = () => {
    const helpTo = `${postData?.freelancer.user.first_name} ${
      postData?.freelancer.user.last_name ?? ""
    }`;
    const helpFrom = `${freelancer.user.first_name} ${
      freelancer.user.last_name ?? ""
    }`;

    if (isCommentAuthor) {
      return `Give 1:1 help to ${helpTo} for `;
    }

    if (status.offerAccepted) {
      return `1:1 help for `;
    }

    return `Get 1:1 help from ${helpFrom} for `;
  };

  const getButtonLabel = () => {
    if (status.offerAccepted) {
      return `Message ${freelancer.user.first_name}`;
    }

    if (status.offerDeclined) {
      return "Offer declined";
    }

    return "I'm interested";
  };

  const label = getLabel();
  const buttonLabel = getButtonLabel();

  const [updatePost] = useUpdatePostMutation();
  const [createHelpOfferMessage] = useCreateHelpOfferMessageMutation();

  const handleRemoveOffer = useCallback(async () => {
    await updatePost({
      ...postComment,
      budget: null,
    })
      .unwrap()
      .catch((error) => {
        throw new SubmissionError(error.data.text);
      });

    CommonConfirmationModal.close();
  }, [updatePost, postComment]);

  const removeOffer = () => {
    CommonConfirmationModal.open({
      onClose: () => CommonConfirmationModal.close(),
      closeButton: true,
      closeButtonProps: {
        variant: "tertiary",
        size: "x-small",
      },
      className: styles.revokeOfferModal,
      children: (
        <ConfirmRevokeOfferModalContent handleRemoveOffer={handleRemoveOffer} />
      ),
    });
  };

  const handleMessageButtonClick = useCallback(async () => {
    if (!helpOffer) return;

    if (isPostCreator) {
      await createHelpOfferMessage({ offerId: helpOffer.id });
    }

    if (!user?.id) {
      return;
    }

    openMessengerModal({
      context: {
        participants: [freelancer.user.id, user.id],
        initiated_by_help_offer_request: true,
      },
    });

    dispatch(
      interestedClicked({
        post: postData,
        comment: postComment,
        post_location: postLocation,
      }),
    );
  }, [
    openMessengerModal,
    createHelpOfferMessage,
    freelancer.user.id,
    helpOffer,
    isPostCreator,
    user?.id,
    postComment,
    postData,
    dispatch,
    postLocation,
  ]);

  const publicOnClick = () => {
    openSignUpModal();
  };

  return {
    helpOffer,
    isCommentAuthor,
    hasCreatedOrPendingStatus,
    disablePostActions,
    labels: { label, buttonLabel },
    status,
    removeOffer: isPublic ? publicOnClick : removeOffer,
    onClick: isPublic ? publicOnClick : handleMessageButtonClick,
  };
};
