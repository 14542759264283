import { useEffect } from "react";

import { useGetFreelancerArticlesQuery } from "@js/apps/freelancer/api";
import { Snackbar } from "@js/components/snackbar";

const ARTICLES_VISIBLE = 5;

export const useArticles = () => {
  const { data: articles, isError } = useGetFreelancerArticlesQuery({
    page_size: ARTICLES_VISIBLE,
  });

  useEffect(() => {
    if (isError) {
      Snackbar.error({ message: "Failed to fetch articles" });
    }
  }, [isError]);

  const hasArticles = (articles && articles.count > 0) ?? false;

  return { hasArticles, articles };
};
