import React from "react";
import { change } from "redux-form";

import type {
  ButtonSelectPropsBase,
  OptionBase,
} from "@hexocean/braintrust-ui-components";
import { useButtonSelect } from "@hexocean/braintrust-ui-components";
import {
  EarthIcon,
  LockSmallIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { ReduxFormButtonSelectFactory } from "@js/forms/fields/button-select";
import { useAppDispatch } from "@js/hooks";

import { CREATE_JOB_FORM_ID } from "../../constants";

import type { VisibilityOption } from "./visibility-setting";
import { VisibilitySetting } from "./visibility-setting";

const visibilityOptions: VisibilityOption[] = [
  {
    icon: <EarthIcon sx={{ fontSize: "16px" }} />,
    iconColor: { selected: "var(--positive-2)", notSelected: "var(--black)" },
    label: "Public",
    description: "Everyone on Braintrust can view this job",
    value: false,
  },
  {
    icon: <LockSmallIcon sx={{ fontSize: "16px" }} />,
    iconColor: { selected: "var(--negative-2)", notSelected: "var(--black)" },
    label: "Private",
    description: "Only invited Talent can view this job",
    value: true,
  },
];

type VisibilitySettingProps = Omit<
  ButtonSelectPropsBase<OptionBase>,
  "options" | "mapObjectValueToKey"
> & {
  isPreview?: boolean;
  error?: React.ReactNode;
};

const VisibilitySettingsSelect = ({
  isPreview,
  value,
  onChange,
  error,
}: VisibilitySettingProps) => {
  const { isSelected, handleChange } = useButtonSelect({
    value,
    onChange,
  });
  const dispatch = useAppDispatch();

  const handleClick = (option: VisibilityOption) => {
    if (isPreview) {
      return;
    }
    dispatch(change(CREATE_JOB_FORM_ID, "auto_invite_talent", !option.value));

    handleChange(option);
  };

  return (
    <>
      {visibilityOptions.map((option) => {
        const _isSelected = isSelected(option);
        const _isDisabled = !!(isPreview && option.value !== value);

        return (
          <VisibilitySetting
            key={option.value.toString()}
            onClick={() => handleClick(option)}
            {...option}
            selected={_isSelected}
            disabled={_isDisabled}
          />
        );
      })}
      {error && <div className="general-form-error">{error}</div>}
    </>
  );
};

export const VisibilitySettingsSelectField = ReduxFormButtonSelectFactory(
  VisibilitySettingsSelect,
);
