import * as React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import {
  RhfCheckboxField,
  RhfDatePicker,
  RhfNumberField,
  RhfSelect,
  RhfTextField,
  RhfUserSearchComboboxField,
} from "../fields";

/**
 * Dumb form experiment with. Consider if it is worth to push changes (if it shows something valuable for other devs).
 * Rendered at: /rhf/form
 **/

const formSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Required" })
    .max(3, { message: "Too long" }),
  age: z.number().min(1, { message: "Required" }),
  amount: z.number({ coerce: true }).min(5, { message: "To small" }),
  amount_string: z.string().min(5, { message: "Too short" }),
  date: z.string().refine(
    (val) => {
      if (val === "Invalid Date") {
        return false;
      }

      return true;
    },
    { message: "Invalid date" },
  ),
  user: z
    .number()
    .min(1, { message: "Required" })
    .nullable()
    .refine(
      (val) => {
        if (val === null) {
          return false;
        }

        return true;
      },
      { message: "Required" },
    ),
  is: z.boolean(),
});

type FormValues = z.infer<typeof formSchema>;

export const SimpleForm = () => {
  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: {
      name: "",
      user: 2,
    },
    mode: "onTouched",
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (data: FormValues) => {
    // eslint-disable-next-line no-console
    console.log("onSubmit: ", data);
    reset();
  };

  const options = new Array(5).fill("x").map((_val, index) => ({
    value: index + 10,
    label: `${index + 10} years`,
  }));

  return (
    <div>
      <h1>Notes:</h1>
      <ul>
        <li>
          don't use handleSubmit 2nd argument as it infers wrong submit errors
          types -- handle submission errors directly in onSubmit{" "}
        </li>
        <li>
          avoid: z.coerce.number() -- it uses just Number() under the hood so
          empty string and null values will be parsed to number 0 which will be
          not visible within the field but will be send to the backend. Use
          zodNumberField and zodNumberFieldOptional instead.
        </li>
        <li>
          be careful with: z.coerce.date() -- it useful to validate (minDate
          etc), but transforms string to Date object. We send string to the
          backend, so you need to transform with dayjs.format().{" "}
        </li>
        <li>
          every field should be covered with tests that verifies if input is
          connected properly
        </li>
        <li>
          to reset some fields requires `null` value, but sometimes it is not
          respected by backend -- remember to transform to `undefined` then.
        </li>
      </ul>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RhfTextField
          id="first-name-id"
          label="First name"
          control={control}
          name="name"
        />
        <RhfSelect
          id="first-name-id"
          label="First name"
          control={control}
          name="age"
          options={options}
        />

        <RhfNumberField
          id="amount-id"
          label="Amount (number)"
          control={control}
          name="amount"
        />

        <RhfNumberField
          id="amount-id2"
          label="Amount (string)"
          control={control}
          name="amount_string"
        />

        <RhfDatePicker
          id="date-id"
          label="Date"
          control={control}
          name="date"
        />

        <RhfUserSearchComboboxField
          id="user-id"
          label="User"
          control={control}
          name="user"
        />

        <div>
          <RhfCheckboxField
            id="is-id"
            label="Is boolean"
            control={control}
            name="is"
          />
        </div>

        <input type="submit" />
        <input type="reset" onClick={() => reset()} />
        <input
          type="button"
          value="Clear user"
          onClick={() => reset({ user: null })}
        />
      </form>
    </div>
  );
};
