import type { YearsOfExperienceRange } from "@js/apps/common/components/filters/config";
import { getYearsOfExperienceLabel } from "@js/apps/common/components/filters/fields/years-of-experience-filter";

export const createYearsOfExperienceString = (
  experience?: string,
): string | null => {
  if (typeof experience !== "string") return null;

  const experienceRanges = experience.split(",") as YearsOfExperienceRange[];
  return getYearsOfExperienceLabel(experienceRanges);
};
