import { Fragment } from "react";
import _ from "underscore";

import { Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import type { StripeACHPayment } from "@js/types/payments";

type StripeACHPaymentProps = {
  paymentMethod: StripeACHPayment;
};

export const StripeACH = ({ paymentMethod }: StripeACHPaymentProps) => {
  const accountNumberStr = "" + paymentMethod.method.account_number;
  const isSmall = useMediaQuery("sm");
  const mockedMaskedNumber = isSmall ? 6 : 11;

  return (
    <div className="stripe-ach-payment">
      <Typography component="span" size="small">
        {isSmall ? "" : "ACCOUNT NO: "}
        {_.times(mockedMaskedNumber, (i) => (
          <Fragment key={i}>&bull; </Fragment>
        ))}
        {accountNumberStr.slice(-4)}
      </Typography>
      <Typography
        component="p"
        size="small"
        sx={{ textTransform: "uppercase" }}
      >
        CURRENCY: {paymentMethod.method.currency}
      </Typography>
    </div>
  );
};
