import { lazyWithRetry } from "@js/utils/lazy-with-retry";

export const NFTLayoutLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "nft-views" */ "@js/apps/nft/views/layout"
  );
  return { default: module.NFTLayout };
});

export const NFTMembershipPageLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "nft-views" */ "@js/apps/nft/views/membership"
  );
  return { default: module.NFTMembershipPage };
});

export const NFTManagementPageLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "nft-views" */ "@js/apps/nft/views/management"
  );
  return { default: module.NFTManagementPage };
});

export const NFTMintPageLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "nft-views" */ "@js/apps/nft/views/mint"
  );
  return { default: module.NFTMintPage };
});
