import type { TypedWrappedFieldProps } from "redux-form";

import { usePendingUploadSnackbar } from "../../hooks/use-pending-upload-snackbar";
import { useSelectedDisplayedImage } from "../../hooks/use-selected-displayed-image";
import type { ExistingFile, UploadedFile } from "../../types";
import { FileUploadFieldLabel } from "../file-upload-field-label";

import {
  ImageUploadWithCropper,
  type ImageUploadWithCropperProps,
} from "./image-upload-with-cropper";

export type ImageUploadWithCropperFieldProps = {
  label?: string;
  existingImages?: Array<ExistingFile>;
} & TypedWrappedFieldProps<number> &
  Omit<
    ImageUploadWithCropperProps,
    "onUpload" | "onBeforeUpload" | "onAfterUpload"
  >;

export const ImageUploadWithCropperField = ({
  label,
  input,
  meta,
  displayedImage,
  existingImages,
  ...rest
}: ImageUploadWithCropperFieldProps) => {
  const { onBeforeUpload, onAfterUpload } = usePendingUploadSnackbar({
    input,
    meta,
  });

  const onUpload = (uploadedFile: UploadedFile) => {
    input.onChange(uploadedFile.id);
  };

  const { value } = input;
  const { selectedDisplayedImage } = useSelectedDisplayedImage({
    displayedImage,
    existingImages,
    value,
  });

  return (
    <>
      {!!label && <FileUploadFieldLabel>{label}</FileUploadFieldLabel>}
      <ImageUploadWithCropper
        {...rest}
        displayedImage={selectedDisplayedImage}
        onBeforeUpload={onBeforeUpload}
        onAfterUpload={onAfterUpload}
        onUpload={onUpload}
      />
    </>
  );
};
