import { Box } from "@hexocean/braintrust-ui-components";
import { SearchResultsFiltersForm } from "@js/apps/universal-search/filters/search-results-filters";
import { useUniversalSearchFiltersForm } from "@js/apps/universal-search/hooks/use-universal-search-filters-form";

import { UniversalSearchItemsCounter } from "../universal-search-items-counter";

export type PostsFiltersProps = {
  postsLength?: number;
};

export const PostsFilters = ({ postsLength }: PostsFiltersProps) => {
  const { transformInitialValues, showCounter } =
    useUniversalSearchFiltersForm();
  return (
    <SearchResultsFiltersForm
      form="search-results-filters"
      transformInitialValues={transformInitialValues}
    >
      <PostCounter postsLength={postsLength} showCounter={showCounter} />
    </SearchResultsFiltersForm>
  );
};

type PostCounterProps = {
  postsLength?: number;
  showCounter?: boolean;
};

export const PostCounter = ({
  postsLength = 0,
  showCounter = false,
}: PostCounterProps) => {
  if (!showCounter) {
    return null;
  }

  return (
    <Box className="post-counter-and-sort">
      <UniversalSearchItemsCounter
        itemsLength={postsLength}
        tab="CAREER_HELP"
        isHeader={false}
      />
    </Box>
  );
};
