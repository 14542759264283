import type { BYOTError } from "@js/apps/byot/types";
import type { UploadedFile } from "@js/apps/file-upload/types";

type State = {
  file: UploadedFile | null;
  isProcessing: boolean;
  error: BYOTError | null;
};

type Action =
  | {
      type: "start_processing";
    }
  | {
      type: "uploaded_with_error";
      payload: string;
    }
  | {
      type: "uploaded";
      payload: UploadedFile;
    }
  | {
      type: "fetched_data_from_csv_with_errors";
    }
  | {
      type: "fetched_data_from_csv";
    };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "start_processing": {
      // uploading a file
      return {
        file: null,
        isProcessing: true,
        error: null,
      };
    }
    case "uploaded_with_error": {
      return {
        file: null,
        isProcessing: false,
        error: {
          type: "upload",
          message: action.payload,
        },
      };
    }
    case "uploaded": {
      // file uploaded, started fetching data from csv (result and errors)
      return {
        file: action.payload,
        isProcessing: true,
        error: null,
      };
    }
    case "fetched_data_from_csv_with_errors": {
      return {
        ...state,
        isProcessing: false,
        error: {
          type: "processing",
          csvWithErrorsUrl: `/api/byot_csv_files/${(state.file as UploadedFile).id}/`,
        },
      };
    }
    case "fetched_data_from_csv": {
      return {
        ...state,
        isProcessing: false,
        error: null,
      };
    }
    default: {
      return state;
    }
  }
};
