import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

import { ResumeViewer } from "@js/apps/common/components/resume-viewer";
import { useIsProfileOwnerContext } from "@js/apps/common/profile/is-profile-owner-context";
import { DeleteResumeButton } from "@js/apps/freelancer/components/profile-resume/delete-resume";
import { useNavigate } from "@js/hooks";

import { useGetFreelancerPublicProfileQuery } from "../../api";

import { FreelancerProfilePageUploadResumeForm } from "./upload-resume/freelancer-profile-page-upload-resume-form";

export const FreelancerProfilePageResume = () => {
  const { isProfileOwner } = useIsProfileOwnerContext();
  const { id } = useParams();
  const { data: profile } = useGetFreelancerPublicProfileQuery(
    id ? Number(id) : skipToken,
  );
  const uploadedResume = profile?.resume;

  const navigate = useNavigate();

  const shouldHaveAccessToResume = uploadedResume || isProfileOwner;

  useEffect(() => {
    if (!shouldHaveAccessToResume) {
      navigate(`/talent/${profile?.id}/`);
    }
  }, [profile, navigate, shouldHaveAccessToResume]);

  if (!shouldHaveAccessToResume || !profile) return null;

  if (!uploadedResume)
    return <FreelancerProfilePageUploadResumeForm id={profile.id} />;

  return (
    <div>
      <ResumeViewer
        resumeFile={uploadedResume.attachment?.file}
        resumeFileDownloadUrl={uploadedResume.attachment?.file_download_url}
        rightButtonSlot={isProfileOwner ? <DeleteResumeButton /> : undefined}
      />
    </div>
  );
};
