import {
  Box,
  Card,
  Carousel,
  Grid,
  Loader,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { ReviewCard } from "@js/apps/reviews/components/review-card";
import { useReviewList } from "@js/apps/reviews/components/review-list/hooks/review-list";
import type { EmployerReview } from "@js/types/employer";
import type { TalentReview } from "@js/types/reviews";

import style from "./style.module.scss";

export type ReviewListProps = {
  direction?: "row" | "column";
  reviewSubject: EnumType<typeof ENUMS.AccountType>;
  reviewSubjectId: number;
};

export const ReviewList = ({
  reviewSubject,
  reviewSubjectId,
  direction = "row",
}: ReviewListProps) => {
  const { profile, loading, reviewList, isOwnProfile } = useReviewList({
    reviewSubject,
    reviewSubjectId,
  });
  const isMobile = useMediaQuery("sm");

  if (!profile) {
    return null;
  }

  if (loading) {
    return <Loader className="center" />;
  }

  if (!reviewList.length) {
    return <p>There are no reviews yet.</p>;
  }

  return isMobile ? (
    <Box>
      <Carousel
        slides={reviewList.map((review: EmployerReview | TalentReview) => (
          <Grid
            container
            key={`${review.id}${review.reviewer.id}`}
            className={style.mobileReview}
          >
            <Card className={style.mobileReviewCard}>
              <ReviewCard
                review={review}
                profile={profile}
                isOwnProfile={isOwnProfile}
              />
            </Card>
          </Grid>
        ))}
      ></Carousel>
    </Box>
  ) : (
    <Grid container item spacing={4} xs={12} direction={direction}>
      {reviewList.map((review: EmployerReview | TalentReview) => (
        <Grid
          key={`${review.id}${review.reviewer.id}`}
          item
          sm={12}
          md={12}
          lg={direction === "row" ? 6 : 12}
        >
          <ReviewCard
            review={review}
            profile={profile}
            isOwnProfile={isOwnProfile}
          />
        </Grid>
      ))}
    </Grid>
  );
};
