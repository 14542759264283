import React from "react";

import { Box, Button } from "@hexocean/braintrust-ui-components";

import style from "./style.module.scss";

export type FilterFooterProps = {
  onReset: () => void;
  onApply: () => void;
  disableApply?: boolean;
  hideClear?: boolean;
  singleValue?: boolean;
};

export const FilterFooter: React.FC<FilterFooterProps> = ({
  onReset,
  onApply,
  disableApply,
  hideClear,
  singleValue,
}) => {
  return (
    <Box className={style.filterFooter}>
      {!hideClear && (
        <Button
          variant="transparent-grey"
          shape="squared"
          sx={{
            marginLeft: -2,
          }}
          className="typography--small"
          onClick={() => {
            onReset();
          }}
        >
          {singleValue ? "Clear" : "Clear all"}
        </Button>
      )}
      <Button
        onClick={() => {
          onApply();
        }}
        variant="dark-violet"
        sx={{
          marginLeft: "auto",
        }}
        disabled={disableApply}
        shape="squared"
      >
        Apply
      </Button>
    </Box>
  );
};
