import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { useUser } from "@js/apps/common/hooks";
import { useMounted } from "@js/hooks";
import { SEARCH_PARAMS } from "@js/routes/constants";

import { useCreatePostOpenedFields } from "../../hooks";

export const useGetOpenFieldsProviderValue = () => {
  const { openedFields, toggleField, resetAllFields, openField } =
    useCreatePostOpenedFields();

  const openedFieldsProviderValue = useMemo(
    () => ({ openedFields, toggleField, openField }),
    [openedFields, toggleField, openField],
  );

  return {
    resetAllFields,
    openedFieldsProviderValue,
  };
};

export const useOpenCreatePostModalFromParams = (openModal: () => void) => {
  const mounted = useMounted();
  const user = useUser();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const newPostFromUrl =
      searchParams
        .get(SEARCH_PARAMS.POST_MODAL_VISIBLE)
        ?.toLocaleLowerCase() === "true";

    if (!mounted && newPostFromUrl && user) openModal();
  }, [mounted, searchParams, user, openModal]);
};
