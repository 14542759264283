import { z } from "zod";

const RTKMutationError = z.object({
  status: z.number().optional(),
  data: z.object({
    _error: z.string().array().optional(),
  }),
});

export { RTKMutationError };
