import { useMemo } from "react";
import cs from "classnames";
import _ from "underscore";

import {
  Member,
  NoMemberOption,
} from "@js/apps/spaces/components/invite-to-space-modal/member";
import type { MemberInviteData } from "@js/apps/spaces/components/invite-to-space-modal/types";
import { useSpaceInviteMemberAutocomplete } from "@js/apps/spaces/components/space-invite-member-autocomplete/use-space-invite-member-autocomplete";
import { InvitationsAutocomplete } from "@js/components/autocomplete-new/invitations";
import { EMAIL_REGEX } from "@js/forms/validators";

import styles from "./styles.module.scss";

export const SpaceInviteMemberAutocomplete = ({
  onChange,
  value,
  spaceId,
  ...props
}: {
  onChange: (newValue: MemberInviteData[]) => void;
  value: MemberInviteData[];
  spaceId: number;
}) => {
  const {
    inputValue,
    setInputValue,
    members,
    isLoading,
    emailInvalid,
    isOpen,
    setIsOpen,
    checkIsEmailAlreadyInvited,
    isEmailAlreadySent,
  } = useSpaceInviteMemberAutocomplete({ spaceId });

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const optionsWithValue: MemberInviteData[] = useMemo(() => {
    return _.uniq([...members, ...value], "id");
  }, [members, value]);

  return (
    <InvitationsAutocomplete
      placeholder="Add people"
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      multiple
      filterSelectedOptions
      options={isLoading ? [] : optionsWithValue}
      loading={isLoading}
      inputValue={inputValue}
      value={value}
      sx={{ mb: { xs: 2, sm: 3 } }}
      onInputChange={(_event, input) => {
        const trimmedInput = input.trim();

        if (trimmedInput !== "" && trimmedInput.match(EMAIL_REGEX)) {
          checkIsEmailAlreadyInvited({
            spaceId,
            email: trimmedInput,
          });
        }
        setInputValue(input);
      }}
      noOptionsText={<NoMemberOption />}
      onChange={(_event, newValue, reason) => {
        if (!newValue) {
          return;
        }

        if (reason === "clear" || reason === "removeOption") {
          setInputValue("");
          return;
        }

        onChange?.(newValue);
      }}
      renderOption={(renderOptionProps, member: MemberInviteData) => {
        return (
          <li
            {...renderOptionProps}
            key={member.id || member.inputValue}
            className={cs(styles.option, renderOptionProps.className)}
          >
            <Member member={member} isMenuOption />
          </li>
        );
      }}
      filterOptions={(options, params) => {
        const optionsCopy = [...options];
        // Suggest the creation of a new value
        const trimmedInput = params.inputValue.trim();
        const optionExists = optionsCopy.some((option) => {
          if (typeof option === "string") {
            return option === trimmedInput;
          }

          return option.inputValue === trimmedInput;
        });

        if (
          trimmedInput !== "" &&
          trimmedInput.match(EMAIL_REGEX) &&
          !optionExists &&
          !emailInvalid
        ) {
          optionsCopy.push({
            already_invited: !!isEmailAlreadySent,
            avatar_thumbnail: null,
            badge: undefined,
            country: undefined,
            first_name: "",
            gravatar: "",
            has_avatar_set: false,
            last_name: "",
            inputValue: trimmedInput,
            public_name: trimmedInput,
            avatar: null,
            role_id: null,
          });
        }

        return optionsCopy;
      }}
      getOptionLabel={(option) => {
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.public_name;
      }}
      {...props}
    />
  );
};
