import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { getTitle } from "@js/apps/jobs/forms/fields/payment-amount/utils";
import { ErrorItem, ErrorSection } from "@js/components/error-section";
import type { Job } from "@js/types/jobs";

import { useBidErrorSectionErrors } from "./use-bid-error-section-errors";

export const BidErrorSection = ({
  meta,
  job,
}: TypedWrappedFieldProps & { job?: Job | null }) => {
  const { requiredErrors, getMessage } = useBidErrorSectionErrors(meta);

  if (!meta.submitFailed) {
    return <></>;
  }

  const displayErrorSectionMessage = (errors: Record<string, string>) => {
    if (errors["detail"] === "Not found.")
      return "Application has been canceled";

    return getMessage();
  };

  const errorSectionMessage = displayErrorSectionMessage(requiredErrors);

  return (
    <ErrorSection
      meta={meta}
      requiredErrors={requiredErrors}
      errorMessage={errorSectionMessage}
    >
      <>
        <ErrorItem
          names={["payment_amount"]}
          sectionName="Rate"
          message={job ? getTitle(job.payment_type, job.job_type) : ""}
          sectionLink={false}
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={["location_requirement_met"]}
          sectionName="Location"
          sectionLink={false}
          message="Do you meet the location requirement for this job?"
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={["timezone_requirement_met"]}
          sectionName="Timezone"
          sectionLink={false}
          message="Do you meet the timezone requirement for this job?"
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={[
            "freelancer_availability",
            "freelancer_availability_specific_date",
          ]}
          sectionName="Availability"
          message="When are you available to start?"
          sectionLink={false}
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={["calendar_url"]}
          sectionName="Booking calendar"
          message="Enter a valid URL"
          sectionLink={false}
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={["resume"]}
          sectionName="Resume"
          message="Please include your resume"
          sectionLink={false}
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={["attachments_ids"]}
          sectionName="Attachments"
          sectionLink={false}
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={["new_application_answers"]}
          sectionName="Application Answers"
          message="Please answer all client's questions."
          sectionLink={false}
          requiredErrors={requiredErrors}
        />
      </>
    </ErrorSection>
  );
};
