import { useAccountType } from "@js/apps/common/hooks";
import {
  useGetNotificationsSettingsQuery,
  useUpdateNotificationsSettingsMutation,
} from "@js/apps/settings/api";
import type { NotificationSetting } from "@js/types/notifications";

const useNotificationSettings = () => {
  const { data } = useGetNotificationsSettingsQuery();

  const [updateNotificationsMutationTrigger] =
    useUpdateNotificationsSettingsMutation();

  const { isEmployer } = useAccountType();

  const areNotificationsAllowed = !data
    ? false
    : (data.general_notifications_settings.find(
        ({ notification }) =>
          notification === ENUMS.NotificationTypes.ALL_NOTIFICATIONS,
      )?.value as boolean);

  const updateNotifications = (notificationToUpdate: NotificationSetting) => {
    if (isEmployer) {
      updateNotificationsMutationTrigger([notificationToUpdate]);
      return;
    }

    if (areNotificationsAllowed) {
      updateNotificationsMutationTrigger([notificationToUpdate]);
      return;
    }

    if (!data) return;

    const newSettings = data.general_notifications_settings.map(
      (notification) => {
        if (notification.notification === notificationToUpdate.notification) {
          return notificationToUpdate;
        }

        if (notification.group === ENUMS.NotificationGroup.NO_GROUP) {
          return { ...notification, value: true };
        }

        const currentValue = notification.value;

        const newValue =
          typeof currentValue === "boolean"
            ? false
            : ENUMS.CareerHelpNotificationOptions.NEVER;

        return { ...notification, value: newValue };
      },
    );

    updateNotificationsMutationTrigger(newSettings);
  };

  return {
    updateNotifications,
    areNotificationsAllowed: isEmployer ? true : areNotificationsAllowed,
  };
};

export { useNotificationSettings };
