import { useMemo } from "react";

import {
  defaultPublicJobFiltersParamsValues,
  usePublicJobFilters,
} from "@js/apps/common/components/filters";

export const useProcessedPublicJobsFilters = () => {
  const { filters, isAnyFilterApplied } = usePublicJobFilters();

  const publicJobsFilters = useMemo(() => processFilters(filters), [filters]);

  return { filters: publicJobsFilters, isAnyFilterApplied };
};

const publicJobsFiltersKeys = Object.keys(defaultPublicJobFiltersParamsValues);

const processFilters = (filters: Record<string, unknown>) => {
  const onlyPublicJobsFilters = Object.fromEntries(
    Object.entries(filters).filter(([key]) =>
      publicJobsFiltersKeys.includes(key),
    ),
  );

  return onlyPublicJobsFilters;
};
