import React from "react";

import type { StackProps } from "@hexocean/braintrust-ui-components";
import { Stack } from "@hexocean/braintrust-ui-components";

type UploadLinkedinConnectionsContainerProps = StackProps;

export const uploadDropzoneBackgroundColor = "#f5eeff";

export const UploadLinkedinConnectionsContainer = ({
  children,
  ...rest
}: UploadLinkedinConnectionsContainerProps) => {
  return (
    <Stack
      {...rest}
      sx={{
        position: "relative",
        backgroundColor: uploadDropzoneBackgroundColor,
        border: "2px dashed var(--medium-violet)",
        width: { xs: "100%", sm: "400px" },
        borderRadius: 4,
        textAlign: "center",
        alignItems: "center",
        overflow: "hidden",
        ...rest.sx,
      }}
    >
      {children}
    </Stack>
  );
};
