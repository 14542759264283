import type { Location } from "react-router-dom";

import type {
  SearchEventEntityTypeKey,
  SearchEventOption,
} from "@js/types/tracking";

import { getSearchEntityType } from "./helpers";
import type { SearchEvent } from "./types";

type SearchEventTrackingPayload = {
  searchQuery: string | undefined;
  searchContext: SearchEventOption;
  location: Location | Window["location"];
  entityTypeKey: SearchEventEntityTypeKey;
};

export const createSearchEventTrackingPayload = ({
  searchQuery,
  searchContext,
  location,
  entityTypeKey,
}: SearchEventTrackingPayload): SearchEvent => {
  const { search } = location;

  const searchEntityType = getSearchEntityType(entityTypeKey);
  const urlSearchFilter = new URLSearchParams(search);
  urlSearchFilter.delete("search");

  const searchFilter = decodeURIComponent(urlSearchFilter.toString());
  const appendSearchFilters =
    searchContext !== ENUMS.SearchEventOption.MAIN_SEARCH_BOX;

  return {
    search_query: searchQuery,
    search_box_option: searchContext,
    search_entity_type: searchEntityType,
    search_filter: appendSearchFilters
      ? decodeURIComponent(searchFilter)
      : undefined,
  };
};
