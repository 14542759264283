import type { AppLayoutProps } from "@js/layouts/app";
import { AppLayout } from "@js/layouts/app";

type EmployerDashboardLayoutProps = {
  contentClassName?: string;
} & Pick<AppLayoutProps, "bgcolor" | "children" | "pageTitle" | "setBgOnBody">;

export const EmployerDashboardLayout = ({
  contentClassName,
  children,
  ...rest
}: EmployerDashboardLayoutProps) => {
  return (
    <AppLayout flexColumn className={contentClassName} {...rest}>
      {children}
    </AppLayout>
  );
};
