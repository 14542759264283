import { useParams } from "react-router-dom";

import { useStoreReferrerAndRedirect } from "../../hooks/use-store-referrer-and-redirect";

export const InvitationRedirect = () => {
  const { referrer } = useParams();

  if (!referrer) {
    throw new Error("Invalid referral link");
  }

  useStoreReferrerAndRedirect(referrer);

  return null;
};
