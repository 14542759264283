import { useEffect } from "react";

import { fetchPublicInvitationDetails } from "@js/apps/auth/actions";
import { useAppDispatch, useAppSelector, useQueryParams } from "@js/hooks";

import { useStoredReferrer } from "../use-stored-referrer";

export const useSignUpInvitationData = () => {
  const { invitation_key, job_id } = useQueryParams();
  const dispatch = useAppDispatch();
  const referrer = useStoredReferrer();
  const publicInvitationDetails = useAppSelector(
    (state) => state.auth.publicInvitationDetails,
  );
  const fetchingPublicInvitationDetails = useAppSelector(
    (state) => state.auth.fetchPublicInvitationDetails,
  );

  useEffect(() => {
    if (referrer || invitation_key || job_id) {
      dispatch(
        fetchPublicInvitationDetails({
          referrer,
          invitation_key,
          job_id,
        }),
      );
    }
  }, [dispatch, invitation_key, job_id, referrer]);

  return { publicInvitationDetails, fetchingPublicInvitationDetails };
};
