import { useCallback, useState } from "react";
import { arrayRemoveAll, change, clearFields } from "redux-form";

import type { CreatePostAttachmentFieldType } from "@js/apps/give-and-get-help/context";
import {
  CREATE_POST_FORM_ID,
  EDIT_POST_FORM_ID,
} from "@js/apps/give-and-get-help/form/constants";
import { useAppDispatch } from "@js/hooks";

export type UseCreatePostOpenedFieldsArg = {
  initialOpenedFields?: CreatePostAttachmentFieldType[];
  isEditingAPost?: boolean;
};

export const useCreatePostOpenedFields = ({
  initialOpenedFields = [],
  isEditingAPost,
}: UseCreatePostOpenedFieldsArg = {}) => {
  const dispatch = useAppDispatch();
  const [openedFields, setOpenedFields] =
    useState<CreatePostAttachmentFieldType[]>(initialOpenedFields);

  const formId = isEditingAPost ? EDIT_POST_FORM_ID : CREATE_POST_FORM_ID;

  const toggleField = useCallback(
    (fieldName: CreatePostAttachmentFieldType) => {
      setOpenedFields((prev) => {
        if (fieldName === "attachment_file") {
          if (prev.includes(fieldName)) {
            dispatch(clearFields(formId, false, false, fieldName));
            return prev.filter((value) => value !== fieldName);
          }
          return [
            ...prev.filter((value) => value !== "attachment_image"),
            fieldName,
          ];
        }

        if (fieldName === "attachment_image") {
          if (prev.includes(fieldName)) {
            dispatch(clearFields(formId, false, false, fieldName));
            return prev.filter((value) => value !== fieldName);
          }
          return [
            ...prev.filter((value) => value !== "attachment_file"),
            fieldName,
          ];
        }

        if (fieldName === "poll") {
          if (prev.includes(fieldName)) {
            // clearFields setting value to initial value so it needs to be cleared manually
            dispatch(change(formId, "poll_text", null));
            dispatch(arrayRemoveAll(formId, "poll_options"));
            return prev.filter((value) => value !== fieldName);
          }
          return [...prev, fieldName];
        }

        if (prev.includes(fieldName)) {
          dispatch(clearFields(formId, false, false, fieldName));
          return prev.filter((value) => value !== fieldName);
        }
        return [...prev, fieldName];
      });
    },
    [dispatch, formId],
  );

  const openField = useCallback(
    (fieldName: CreatePostAttachmentFieldType) => {
      if (!openedFields.includes(fieldName)) {
        toggleField(fieldName);
      }
    },
    [toggleField, openedFields],
  );

  const resetAllFields = () => setOpenedFields([]);

  return {
    openedFields,
    toggleField,
    resetAllFields,
    openField,
  };
};
