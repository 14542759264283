import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { Modal } from "@js/components/modal";

import styles from "./style.module.scss";

export const AutoBlockWarningModalInstance = Modal(
  "auto-block-modal-warning-modal",
  {
    keepOnBackdropClick: false,
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
      className: styles.closeButton,
    },
    padding: false,
    className: styles.modal,
  },
);

const AutoBlockWarningModal = () => {
  return (
    <Box className={styles.autoBlockWarningContent}>
      <Box>
        <Typography component="h2" fontWeight={500} size="medium">
          ⚠️ Your account is under verification.
        </Typography>
        <Typography component="p" mt={2}>
          We do periodic checks on new accounts to verify the authenticity of
          the company posting. Please contact our support team to re-enable
          account features:{" "}
          <Typography
            ml={0.25}
            component="a"
            variant="paragraph"
            size="medium"
            href={`mailto:${SETTINGS.SUPPORT_EMAIL}`}
            RouterLink={RouterLink}
          >
            {SETTINGS.SUPPORT_EMAIL}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export const openAutoBlockWarningModal = ({
  onClose,
}: {
  onClose?: () => void;
}) =>
  AutoBlockWarningModalInstance.open({
    onClose,
    children: <AutoBlockWarningModal />,
  });

export const closeAutoBlockWarningModal = () =>
  AutoBlockWarningModalInstance.close();
