export const CUSTOM_EMOJIS_CATEGORY = {
  CUSTOM: {
    id: "custom",
    name: "Custom",
  },
} as const;

const CUSTOM_EMOJIS = [
  {
    id: CUSTOM_EMOJIS_CATEGORY.CUSTOM.id,
    name: CUSTOM_EMOJIS_CATEGORY.CUSTOM.name,
    emojis: [
      ...SETTINGS.CUSTOM_EMOJIS.map((emoji) => ({
        id: emoji.id,
        name: emoji.label,
        keywords: [...emoji.keywords],
        skins: [{ src: `${SETTINGS.STATIC_URL}${emoji.icon}` }],
      })),
    ],
  },
];

type CustomEmojiCategories = "custom";

type CustomEmojiData = {
  id: string;
  name: string;
  keywords: string[];
  skins: Array<{
    src: string;
  }>;
};

export const findSpecificEmoji = (
  category: CustomEmojiCategories,
  emojiToSearch: string,
): CustomEmojiData | undefined => {
  const searchedCategory = CUSTOM_EMOJIS.find((cat) => cat.id === category);

  if (!searchedCategory) return undefined;

  return searchedCategory.emojis.find((emoji) => emoji.id === emojiToSearch);
};
