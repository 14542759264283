import { useState } from "react";
import * as Sentry from "@sentry/react";
import { useStripe } from "@stripe/react-stripe-js";

import { Snackbar } from "@js/components/snackbar";
import type { PaymentMethod, PaymentTransaction } from "@js/types/payments";
import { assertUnreachable } from "@js/utils";

import { useLazyGetPaymentTransactionQuery } from "../../api";

export const useHandleTransaction = () => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();

  const [getTransaction] = useLazyGetPaymentTransactionQuery();

  const handleTransactionCreated = async (
    transaction: PaymentTransaction,
    paymentMethod: PaymentMethod,
  ) => {
    if (!stripe) {
      console.error("Stripe is not available");
      Snackbar.error(
        "Stripe is not available, please refresh the page and try again.",
      );
      return;
    }

    const paymentContentType = paymentMethod.content_type;

    switch (paymentContentType) {
      case ENUMS.PaymentContentType.StripeCreditCardPaymentMethod: {
        if (transaction.gateway_response.client_secret) {
          setLoading(true);

          return await stripe
            .confirmCardPayment(transaction.gateway_response.client_secret, {
              payment_method: paymentMethod.method.payment_method_id,
            })
            .then(async (response) => {
              if (response.error) {
                setLoading(false);

                Sentry.captureException(
                  // @ts-ignore -- Error 2nd arg isn't supported by all browsers
                  new Error(`Payment Failed: ${response.error?.code}`, {
                    cause: response.error,
                  }),
                );
              }

              await pollTransactionStatus(transaction);

              return response;
            })
            .finally(() => {
              setLoading(false);
            });
        }

        return null;
      }
      case ENUMS.PaymentContentType.StripeACHPaymentMethod:
        return null;
      default:
        assertUnreachable(paymentContentType);
    }
  };

  const pollTransactionStatus = async (transaction: PaymentTransaction) => {
    setLoading(true);

    return getTransaction(transaction.id)
      .unwrap()
      .then((updatedTransaction) => {
        if (updatedTransaction.status === ENUMS.PaymentStatus.SUCCESS) {
          setLoading(false);
          return transaction;
        } else if (updatedTransaction.status === ENUMS.PaymentStatus.FAILURE) {
          setLoading(false);
          return transaction;
        } else {
          return new Promise((resolve) =>
            setTimeout(() => {
              resolve(pollTransactionStatus(transaction));
            }, 2000),
          );
        }
      })
      .catch((error) => {
        Sentry.captureException("PollingTransactionStatus", {
          extra: { error },
        });
        Snackbar.error("Something went wrong, please try again.");
      });
  };

  return {
    handleTransactionCreated,
    loading,
  };
};
