import React from "react";

import { Stack } from "@hexocean/braintrust-ui-components";
import type { EmployerBidListBid } from "@js/types/jobs";

type RecommendationBadgeProps = {
  bid: EmployerBidListBid;
};

export const RecommendationBadge = ({ bid }: RecommendationBadgeProps) => {
  if (
    (bid.status !== ENUMS.BidStatus.IN_REVIEW_AFTER_AI_INTERVIEW &&
      bid.status !== ENUMS.BidStatus.INTERVIEWING) ||
    !bid.interview ||
    !bid.interview.is_completed ||
    bid.interview.status !== ENUMS.JobInterviewStatus.ROUND_1 ||
    !bid.interview.rating ||
    !parseFloat(bid.interview.overall_score || "0")
  ) {
    return null;
  }

  return (
    <Stack
      direction="row"
      className={`recommendation-badge recommendation-badge--${bid.interview.rating.scorecard_status}`}
    >
      <img
        src={`${SETTINGS.STATIC_URL}jobs/ai-job-icon-star.svg`}
        alt="ai job"
        height={18}
        width={18}
      />
      {bid.interview.rating.recommended_rating}
    </Stack>
  );
};
