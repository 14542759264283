// new events names has to be added in backend too
import type { To } from "react-router";
import amplitude from "amplitude-js";

import type { Events } from "@js/services/analytics/constants";
import {
  LOG_EVENTS_INTO_CONSOLE,
  SEND_TO_SERVER,
} from "@js/services/analytics/constants";
import {
  eventPropertiesMiddleware,
  pruneUnsentTrackingEvents,
} from "@js/services/analytics/utils";

const instanceMock = {
  logEvent: () => {
    /* void */
  },
} as unknown as amplitude.AmplitudeClient;

export const logEvent = (
  event: EnumType<typeof Events>,
  eventProperties?: Record<string, unknown>,
) => {
  const analyticsInstance = SETTINGS.TRACKING_ENABLED
    ? amplitude.getInstance()
    : instanceMock;

  const transformedEventProperties = eventPropertiesMiddleware(
    event,
    eventProperties,
  );

  if (LOG_EVENTS_INTO_CONSOLE) {
    console.debug("-- Analytics Event: ", event, transformedEventProperties);
  }

  if (SEND_TO_SERVER) {
    analyticsInstance.logEvent(
      event,
      transformedEventProperties,
      undefined,
      () => {
        pruneUnsentTrackingEvents();
      },
    );
  }
};

export type LogViewEventProperties = Record<string, unknown> & {
  hyperlink: To;
};

export const logViewEvent = (
  event: string,
  eventProperties?: LogViewEventProperties,
) => {
  const analyticsInstance = SETTINGS.TRACKING_ENABLED
    ? amplitude.getInstance()
    : instanceMock;

  if (LOG_EVENTS_INTO_CONSOLE) {
    console.debug("-- Analytics Event: ", event, eventProperties);
  }

  if (SEND_TO_SERVER) {
    analyticsInstance.logEvent(event, eventProperties, undefined, () => {
      pruneUnsentTrackingEvents();
    });
  }
};
