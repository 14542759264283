import React, { useCallback, useEffect, useMemo } from "react";
import type { To } from "react-router-dom";

import { resetGlobalAPIState } from "@js/api";
import { AuthComponent } from "@js/apps/auth/components";
import type { LoginFormData } from "@js/apps/auth/forms/login";
import {
  closeLoginOTPModal,
  openLoginOTPModal,
} from "@js/apps/auth/forms/login";
import {
  FRONTEND_STORAGE_KEYS,
  useLazyGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { useWithRecaptcha } from "@js/apps/common/hooks";
import { getValuesWithReCaptchaCode } from "@js/forms/utils";
import {
  useAppDispatch,
  useNavigate,
  useQueryParams,
  useShowSnackbarFromQueryParam,
} from "@js/hooks";

import type { LoginProps } from "../../actions";
import { fetchCurrentUser, postUserLogin } from "../../actions";
import { login } from "../../actions";
import { EmailLoginForm } from "../../forms/login";
import {
  syncLoginRemoveWatcher,
  syncLoginSaveToLocalStorage,
  syncLoginWatchLocalStorage,
} from "../../utils";
import { AuthSignInLayout } from "../layout/sign-in-layout";

const AuthLoginPage = () => {
  const {
    message: messageCode,
    next,
    error_code: errorCode,
  } = useQueryParams() as Record<string, string | undefined>;
  const [getStorageValue] = useLazyGetStorageValueQuery();
  const [setStorageValue] = useSetStorageValueMutation();
  const navigate = useNavigate();
  const isExtraAuthRequired =
    messageCode === ENUMS.AuthFailedErrorCodes.EXTRA_AUTH_REQUIRED;
  useWithRecaptcha();
  const dispatch = useAppDispatch();

  useShowSnackbarFromQueryParam();
  const initialValues: Partial<LoginFormData> = useMemo(() => {
    const loginFormInitialValues: Partial<LoginFormData> = {
      is_backup_code: false,
    };

    if (isExtraAuthRequired) {
      loginFormInitialValues.connect = true;
    }

    return loginFormInitialValues;
  }, [isExtraAuthRequired]);

  const assertFalseOnIsFirstLogin = useCallback(() => {
    getStorageValue({ key: FRONTEND_STORAGE_KEYS.IS_FIRST_LOGIN_TO_APP }, true)
      .unwrap()
      .then((result) => {
        if (!result) {
          return;
        }

        return setStorageValue({
          key: FRONTEND_STORAGE_KEYS.IS_FIRST_LOGIN_TO_APP,
          value: false,
        });
      })
      .catch(() => null);
  }, [getStorageValue, setStorageValue]);

  const onSubmitEmail = useCallback(
    async (values: LoginProps, nextUrl?: string) => {
      return await login(values)
        .then(() => dispatch(resetGlobalAPIState()))
        .then(assertFalseOnIsFirstLogin)
        .then(async () => {
          syncLoginSaveToLocalStorage();
          const user = await dispatch(fetchCurrentUser());
          await dispatch(postUserLogin(user));

          closeLoginOTPModal();

          const isConfirmationUrl =
            !!nextUrl && decodeURIComponent(nextUrl).includes("/api/confirm");

          if (isConfirmationUrl) {
            window.open(nextUrl, "_self");
            return;
          }

          const to: To =
            nextUrl && decodeURIComponent(nextUrl).includes("/auth/logout")
              ? "/"
              : nextUrl || "/";

          navigate(to);
        })
        .catch((exception) => {
          if (exception?.errors?.otp_is_required) {
            openLoginOTPModal();
          } else {
            // Re-throw so validation errors can be shown.
            throw exception;
          }
        });
    },
    [assertFalseOnIsFirstLogin, dispatch, navigate],
  );

  const handleSubmit = useCallback(
    async (values: LoginFormData) => {
      const valuesWithReCaptchaCode = await getValuesWithReCaptchaCode(
        values,
        "login",
      );

      return onSubmitEmail(valuesWithReCaptchaCode, next);
    },
    [next, onSubmitEmail],
  );

  useEffect(() => {
    syncLoginWatchLocalStorage();
    return () => syncLoginRemoveWatcher();
  }, []);

  return (
    <AuthSignInLayout pageTitle="Sign in">
      <AuthComponent
        title="Sign in"
        titleEmoji="👋"
        messageCode={messageCode}
        errorCode={errorCode}
      >
        <EmailLoginForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          isExtraAuthRequired={isExtraAuthRequired}
        />
      </AuthComponent>
    </AuthSignInLayout>
  );
};

export default AuthLoginPage;
