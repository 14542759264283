import React, { useState } from "react";
import copy from "copy-to-clipboard";

import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { FileCopyOutlinedIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./style.module.scss";

export const CurrentBuildData = () => {
  const [copiedRecently, setCopiedRecently] = useState(false);

  const showCopiedMessage = (time: number) => {
    setCopiedRecently(true);

    setTimeout(() => {
      setCopiedRecently(false);
    }, time * 1000);
  };

  const handleCopy = () => {
    const copiedText = `${process.env.BUILD_DATA_CURRENT_BRANCH} ${process.env.BUILD_DATA_COMMIT_HASH}`;
    copy(copiedText);
    showCopiedMessage(3);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      className={styles.dataWrapper}
      onClick={handleCopy}
    >
      {copiedRecently && (
        <Typography component="div" variant="paragraph" mr={1}>
          <span className={styles.widget}>Copied!</span>
        </Typography>
      )}
      <IconButton
        className={styles.copyButton}
        variant="secondary"
        inverse
        aria-label="Copy"
      >
        <FileCopyOutlinedIcon className={styles.icon} color="secondary" />
      </IconButton>
      <Typography component="div" variant="paragraph" color="secondary" ml={1}>
        <span className={styles.widget}>
          {process.env.BUILD_DATA_CURRENT_BRANCH}
        </span>
      </Typography>
      <Typography component="div" variant="paragraph" color="tertiary" ml={1}>
        <span className={styles.widget}>
          {process.env.BUILD_DATA_COMMIT_HASH}
        </span>
      </Typography>
    </Box>
  );
};
