import { useEffect, useMemo } from "react";
import type { Dayjs } from "dayjs";

import { Typography } from "@hexocean/braintrust-ui-components";
import { useCountdown } from "@js/hooks";
import { dateDifference, formatSeconds } from "@js/utils";

import styles from "./style.module.scss";

const END_MESSAGE =
  "The time to edit the invoice has expired. Attempting to save changes may now fail.";
const MAX_DURATION = 30; //minutes

const useTimeLeft = (timeToUnlock: string | Dayjs) => {
  const duration = useMemo(
    () => dateDifference(timeToUnlock, undefined, "s"),
    [timeToUnlock],
  );

  const { countdown, startCountdown } = useCountdown(
    duration / 60 > MAX_DURATION ? MAX_DURATION * 60 : duration,
  ); //sometimes we got little more then max duration

  useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  return { countdown, duration };
};

export const InvoiceEditTimer = ({
  timeToUnlock,
}: {
  timeToUnlock: string | Dayjs;
}) => {
  const { countdown, duration } = useTimeLeft(timeToUnlock);
  if (duration <= 0) {
    return null;
  }
  return (
    <div className={styles.timer} data-testid="invoice-edit-timer">
      <Typography variant="paragraph" component="p" size="small">
        {countdown < 1
          ? END_MESSAGE
          : `Time left to edit: ${formatSeconds(countdown)}`}
      </Typography>
    </div>
  );
};
