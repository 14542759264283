import React from "react";
import type { WrappedFieldProps } from "redux-form";
import cx from "classnames";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import {
  Box,
  ButtonCore,
  Typography,
  useButtonSelect,
} from "@hexocean/braintrust-ui-components";

import styles from "./style.module.scss";

type OptionCheckboxProps = WrappedFieldProps & {
  options: ({ value: string; icon: JSX.Element; label: string } & BoxProps)[];
  labelledBy: string;
  onSelect?: (value: string) => void;
  className?: string;
};

export const OptionCheckbox: React.FC<OptionCheckboxProps> = ({
  input,
  options,
  labelledBy,
  onSelect,
  className,
}) => {
  const { handleChange, isSelected, containerA11yProps, itemA11yProps } =
    useButtonSelect({
      value: input.value,
      onChange: input.onChange,
      multiple: true,
      labelledBy,
    });

  return (
    <div className={`${styles.container} ${className}`} {...containerA11yProps}>
      {options.map((option) => {
        const { value, label, icon, ...restOptionData } = option;
        const _isSelected = isSelected(option);

        return (
          <Box
            key={value}
            {...itemA11yProps(_isSelected)}
            className={cx(styles.option, {
              [styles.optionActive]: _isSelected,
            })}
            component={ButtonCore}
            onClick={() => {
              if (!_isSelected && onSelect) {
                onSelect(value);
              }
              handleChange(option);
            }}
            {...restOptionData}
          >
            {icon}
            <Typography
              variant="label"
              component="span"
              noWrap
              size="medium"
              maxWidth="100%"
              title={label}
            >
              {label}
            </Typography>
          </Box>
        );
      })}
    </div>
  );
};
