import { COMPLETION_AREA_TO_COMPONENT_TYPE_MAP } from "@js/apps/freelancer/constants";
import type { ProfileCompletion } from "@js/types/freelancer";

const getUnfinishedSections = (profileCompletion: ProfileCompletion) => {
  if (profileCompletion.completion_percent === 100) {
    return null;
  }

  const allUnfinishedSections = profileCompletion.missing_areas?.map(
    (area) => COMPLETION_AREA_TO_COMPONENT_TYPE_MAP[area.keywords].type,
  );

  if (allUnfinishedSections?.length === 0) {
    return null;
  }

  return allUnfinishedSections;
};

export const getFirstUnfinishedSection = (
  profileCompletion: ProfileCompletion,
) => {
  const unfinishedSections = getUnfinishedSections(profileCompletion);

  return unfinishedSections ? unfinishedSections[0] : null;
};
