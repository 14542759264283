import { useEffect, useRef } from "react";

import { useGetJobsQuery } from "@js/apps/jobs/api";
import { Snackbar } from "@js/components/snackbar";
import { useResetQueryExceptUTM } from "@js/hooks/use-reset-query-except-utm";

import { useProcessedPublicJobsFilters } from "../use-processed-public-jobs-filters";

export const usePublicJobsListing = () => {
  const { resetQueryExceptUTM } = useResetQueryExceptUTM();
  const { filters, isAnyFilterApplied } = useProcessedPublicJobsFilters();
  const { data: jobsData, isLoading, error } = useGetJobsQuery(filters);

  const handleError = () => {
    Snackbar.error("Wrong filter value provided, failed to fetch jobs");
    resetQueryExceptUTM();
  };
  const handleErrorRef = useRef(handleError);

  useEffect(() => {
    if (!error) {
      return;
    }

    handleErrorRef.current();
  }, [error, handleErrorRef]);

  return {
    jobs: jobsData?.results || [],
    total: jobsData?.count || 0,
    loading: isLoading,
    isAnyFilterApplied,
  };
};
