import { Button } from "@hexocean/braintrust-ui-components";
import { LongArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { EmployerOfferStatusWithJobTitle } from "@js/apps/jobs/apps/offers/components/offer-status-with-job-title";
import type { useCreateOrEditOfferMeta } from "@js/apps/jobs/apps/offers/hooks";
import type { CreateOrEditOfferFormData } from "@js/apps/jobs/apps/offers/types";
import { prepareSubmitButtonLabel } from "@js/apps/jobs/apps/offers/utils";

import styles from "./style.module.scss";

export type ReviewBeforeSendingModalHeaderProps = {
  onSubmit?: (x?: CreateOrEditOfferFormData) => void;
  onGoBack: () => void;
  meta: ReturnType<typeof useCreateOrEditOfferMeta>;
  values?: CreateOrEditOfferFormData;
};

export const ReviewBeforeSendingModalHeader = ({
  onSubmit,
  onGoBack,
  meta,
  values,
}: ReviewBeforeSendingModalHeaderProps) => {
  return (
    <header className={styles.wrapper}>
      <Button
        className={styles.backBtn}
        shape="squared"
        size="medium"
        variant="no-padding"
        onClick={onGoBack}
        startIcon={<LongArrowLeftIcon />}
      >
        Back
      </Button>
      <div className={styles.offerStatus}>
        {meta && (
          <EmployerOfferStatusWithJobTitle
            offerStatus={meta.status}
            jobId={meta.job_id}
            jobTitle={meta.job_title}
            showTitle
          />
        )}
      </div>

      <Button
        className={styles.submitBtn}
        shape="squared"
        size="medium"
        variant="positive"
        onClick={() => onSubmit && onSubmit()}
        type="submit"
      >
        {prepareSubmitButtonLabel(values?.deposit_payment_method?.content_type)}
      </Button>
    </header>
  );
};
