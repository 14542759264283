import { PostViewedContextProvider } from "../../context/post-viewed";
import { PostsContextProvider } from "../../context/posts";
import { PostsListEmptyState } from "../posts-list-empty-state";

import type { PostsListProps } from "./posts-list";
import { PostsList } from "./posts-list";

type PostsSectionProps = {
  fetchingPosts?: boolean;
} & PostsListProps;

export const PostsSection = ({
  fetchingPosts,
  ...postsListProps
}: PostsSectionProps) => {
  const displayEmptyState =
    postsListProps.posts?.length === 0 && !fetchingPosts;

  if (displayEmptyState) {
    return <PostsListEmptyState />;
  }

  return (
    <PostsContextProvider>
      <PostViewedContextProvider>
        <PostsList {...postsListProps} />
      </PostViewedContextProvider>
    </PostsContextProvider>
  );
};
