import { useMemo } from "react";

import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
} from "@js/apps/common/frontend-storage";
import { useUser } from "@js/apps/common/hooks/use-user";
import { useCurrentFreelancerProfileCompletion } from "@js/apps/freelancer/hooks/use-current-freelancer-profile-completion";
import { isDaysFromTodayEqualOrHigher } from "@js/utils";

const DAYS_THRESHOLD = 2;

export const useShowCompleteProfileTopBar = () => {
  const user = useUser();
  const isFreelancerApproved = !!user?.freelancer_approved;
  const { data: profileCompletion } = useCurrentFreelancerProfileCompletion({
    skip: isFreelancerApproved,
  });
  const isProfileIncomplete =
    !!profileCompletion && !profileCompletion?.profile_completion_hit_at;
  const shouldDisplayTopBar = !isFreelancerApproved && isProfileIncomplete;

  const {
    data: storedDate,
    isLoading,
    isError,
  } = useGetStorageValueQuery(
    { key: FRONTEND_STORAGE_KEYS.complete_profile_top_bar_dismissed_at },
    { skip: !shouldDisplayTopBar },
  );

  const showCompleteProfileTopBar = useMemo(() => {
    if (!shouldDisplayTopBar || isLoading || isError) {
      return false;
    }

    if (!storedDate || typeof storedDate !== "string") {
      return true;
    }
    const shouldDisplayTopBarAgain = isDaysFromTodayEqualOrHigher(
      storedDate,
      DAYS_THRESHOLD,
    );

    return shouldDisplayTopBarAgain;
  }, [isLoading, shouldDisplayTopBar, storedDate, isError]);

  return { showCompleteProfileTopBar };
};
