import type { ElementRef } from "react";
import { useEffect, useRef, useState } from "react";

export const useShowMessage = () => {
  const [showMessage, setShowMessage] = useState(false);
  const scrollToRef = useRef<ElementRef<"div">>(null);

  useEffect(() => {
    if (showMessage && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showMessage]);

  const toggleBtn = () => setShowMessage((prev) => !prev);

  return {
    showMessage,
    toggleBtn,
    scrollToRef,
  };
};
