export type LoadScriptProps = {
  src: string;
  id: string;
  async?: boolean;
  defer?: boolean;
};

export const loadScript = ({
  src,
  id,
  async = false,
  defer = false,
}: LoadScriptProps) =>
  new Promise((resolve, reject) => {
    const _script = document.createElement("script");
    _script.id = id;
    _script.src = src;
    _script.async = async;
    _script.defer = defer;

    _script.addEventListener("load", () => {
      resolve({ successful: true });
    });
    _script.addEventListener("error", (err) => {
      // @ts-ignore: Fix types
      reject(new Error(`loadScript failed - ${src}`, { cause: err }));
    });

    const existingScript = document.getElementById(_script.id);

    if (existingScript) {
      existingScript.parentNode?.removeChild(existingScript);
      document.head.appendChild(_script);
    } else {
      document.head.appendChild(_script);
    }
  });

export const unloadScript = async (id: string) => {
  const scriptElement = document.querySelectorAll<HTMLScriptElement>(`#${id}`);

  Array.from(scriptElement).forEach((element) => element.remove());
};
