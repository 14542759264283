import { SET_SAVED_FILTER_NAME } from "@js/apps/common/components/save-filters/action-types";
import type { FilterLocation } from "@js/apps/filters/types";

export const savedFilterLoaded = ({
  location,
  name,
}: {
  location: FilterLocation;
  name: string | null;
}) => {
  return {
    type: SET_SAVED_FILTER_NAME,
    payload: { location, name },
  };
};
