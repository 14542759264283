import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";

type UploadLinkedinConnectionsNoteProps = {
  transparent?: boolean;
};

export const UploadLinkedinConnectionsNote = ({
  transparent,
}: UploadLinkedinConnectionsNoteProps) => {
  return (
    <Typography
      component="p"
      variant="paragraph"
      size="small"
      color="grey-1"
      sx={{
        textAlign: "center",
        fontSize: "11px !important",
        lineHeight: "24px !important",
        mt: 0.5,
        opacity: transparent ? "0" : "1",
      }}
    >
      *LinkedIn will email your connections about 10 minutes after requested.
    </Typography>
  );
};
