import { memo } from "react";

import type { AvatarProps } from "@hexocean/braintrust-ui-components";
import { Avatar } from "@hexocean/braintrust-ui-components";
import {
  Box,
  CircularProgressDecorator,
} from "@hexocean/braintrust-ui-components";
import type { EmployerBidListBid } from "@js/types/jobs";

import { getMatchCircleMeta } from "./helpers";

import styles from "./styles.module.scss";

type BidAvatarWithScoreMatchProps = {
  bid: EmployerBidListBid;
  avatarTextSize?: AvatarProps["textSize"];
  avatarTextVariant?: AvatarProps["textVariant"];
};

export const BidAvatarWithScoreMatch = memo(
  ({
    bid,
    avatarTextSize,
    avatarTextVariant,
  }: BidAvatarWithScoreMatchProps) => {
    const { value, color } = getMatchCircleMeta({
      matchLabelKey: bid.match_label,
    });
    return (
      <Box className={styles.container}>
        <CircularProgressDecorator value={value} color={color} thickness={2.5}>
          <Box
            sx={{
              borderRadius: "50%",
              padding: "2.5px",
              height: "100%",
              width: "100%",
            }}
          >
            <Avatar
              src={bid.freelancer.user}
              size={"full"}
              textSize={avatarTextSize}
              textVariant={avatarTextVariant}
            />
          </Box>
        </CircularProgressDecorator>
      </Box>
    );
  },
);
