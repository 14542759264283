import { SubmissionError } from "redux-form";

import { Snackbar } from "@js/components/snackbar";

import type { EmployerDefaultJobOwners } from "../../api";
import {
  useChangeDefaultOwnersMutation,
  useGetEmployerDefaultJobOwnersQuery,
} from "../../api";
import type { AssignOwnersFormDataTypes } from "../../components/assign-owners-modal";

type useAssignOwnersArgs = {
  employerId: number;
  onSubmitSuccess: () => void;
};

export const useAssignOwners = ({
  employerId,
  onSubmitSuccess,
}: useAssignOwnersArgs) => {
  const [changeDefaultOwners] = useChangeDefaultOwnersMutation();
  const {
    data: employerDefaultJobOwners,
    refetch: refetchEmployerDefaultOwners,
    isSuccess: employerDefaultJobOwnersFetched,
  } = useGetEmployerDefaultJobOwnersQuery(Number(employerId));

  const initialValues = getInitialValues(employerDefaultJobOwners);

  const onSubmit = (values: AssignOwnersFormDataTypes) => {
    return changeDefaultOwners({
      employerId,
      ...values,
    })
      .unwrap()
      .then(() => Snackbar.success(`Owners assigned!`))
      .catch((error) => {
        throw new SubmissionError(error.data);
      });
  };

  const _onSubmitSuccess = () => {
    refetchEmployerDefaultOwners();
    onSubmitSuccess();
  };

  return {
    employerDefaultJobOwnersFetched,
    initialValues,
    onSubmit,
    onSubmitSuccess: _onSubmitSuccess,
  };
};

const getInitialValues = (jobOwners?: EmployerDefaultJobOwners) => {
  const mapToId = (owners: Array<{ id: number }>) => {
    return owners.map((owner) => owner.id);
  };

  if (jobOwners) {
    return {
      default_op_owners: mapToId(jobOwners.default_op_owners),
      default_sale_owners: mapToId(jobOwners.default_sale_owners),
      assign_owner_options: [
        ENUMS.AssignOwnersOptions.NEW_JOBS,
        ENUMS.AssignOwnersOptions.OPEN_JOBS,
      ],
    };
  }

  return {
    default_op_owners: null,
    default_sale_owners: null,
    assign_owner_options: [
      ENUMS.AssignOwnersOptions.NEW_JOBS,
      ENUMS.AssignOwnersOptions.OPEN_JOBS,
    ],
  };
};
