import { useMemo, useState } from "react";

import { Box, Stack } from "@hexocean/braintrust-ui-components";
import { FilePreview } from "@js/apps/give-and-get-help/components/attachment-preview/file";
import { ImagePreview } from "@js/apps/give-and-get-help/components/attachment-preview/image";
import { LinkPreview } from "@js/apps/give-and-get-help/components/attachment-preview/link";
import type { LinkMetadata } from "@js/types/common";
import type { Message } from "@js/types/messenger";
import type { MessageAttachment } from "@js/types/messenger";

import { TextMessageActionMenu } from "../message/text-message-action-menu";

export type MessageAttachmentsProps = {
  messageAttachments: MessageAttachment[] | null;
  calendar_link: string | null;
  calendar_link_data?: LinkMetadata;
  report: {
    isOwner: boolean;
    message: Message;
  };
  isOwner: boolean;
};
export const MessageAttachments = ({
  messageAttachments,
  calendar_link,
  calendar_link_data,
  report,
  isOwner,
}: MessageAttachmentsProps) => {
  const isReportingAvailable =
    typeof report?.message?.author?.id === "number" && !report?.isOwner;
  const [isReportingVisible, setIsReportingVisible] = useState(false);

  const handleReportingVisibility = (value: boolean) => {
    setIsReportingVisible(value);
  };

  const filteredFileAttachments = useMemo(
    () =>
      (messageAttachments ?? []).filter(
        (messageAttachment) => !!messageAttachment?.attachment?.file,
      ),
    [messageAttachments],
  );

  const showFileAttachments = !!filteredFileAttachments?.length;
  const showCalendarLinkAttachment = calendar_link && calendar_link_data;
  const showAttachments = showCalendarLinkAttachment || showFileAttachments;

  if (!showAttachments) {
    return <></>;
  }

  return (
    <>
      {showCalendarLinkAttachment && (
        <Box sx={{ mt: 1 }}>
          <LinkPreview {...calendar_link_data} />
        </Box>
      )}
      {showFileAttachments && (
        <Box
          sx={{ position: "relative", mt: 1, width: "fit-content" }}
          onMouseEnter={() => handleReportingVisibility(true)}
          onMouseLeave={() => handleReportingVisibility(false)}
        >
          <Stack
            component="ul"
            direction="row"
            sx={{
              gap: 1,
              flexWrap: "wrap",
              listStyle: "none",
              m: 0,
              p: 0,
              flexDirection: isOwner ? "row-reverse" : undefined,
            }}
          >
            {filteredFileAttachments.map((messageAttachment) => {
              return (
                <li key={messageAttachment.id}>
                  <MessageFileAttachment data={messageAttachment} />
                </li>
              );
            })}
          </Stack>
          {isReportingAvailable && (
            <TextMessageActionMenu
              isOwner={report?.isOwner}
              showMoreButton={isReportingVisible}
              setShowMoreButton={handleReportingVisibility}
              messageId={report.message?.id}
              roomId={report.message.room}
              messageAuthorName={report.message.author?.first_name as string}
            />
          )}
        </Box>
      )}
    </>
  );
};

const MessageFileAttachment = ({ data }: { data: MessageAttachment }) => {
  const isImage = Boolean(data?.attachment?.mime?.startsWith("image"));
  const { name, file, file_size } = data.attachment;

  if (isImage && file) {
    return (
      <ImagePreview
        title={name}
        src={file}
        thumbnail={data.thumbnail}
        showThumbnail
        size={file_size}
      />
    );
  }

  return <FilePreview name={name} fileLink={file} size={file_size} />;
};
