import { useCallback } from "react";

import { ModalInstance } from "@js/components/modal";

type UseOnboardingPopup = (args: {
  onEmployerAccountClick: () => void;
  onPersonalAccountClick: () => void;
}) => {
  handleEmployerAccountClick: () => void;
  handlePersonalAccountClick: () => void;
};

export const useOnboardingPopup: UseOnboardingPopup = ({
  onEmployerAccountClick,
  onPersonalAccountClick,
}) => {
  const handleEmployerAccountClick = useCallback(() => {
    ModalInstance.close();
    return onEmployerAccountClick();
  }, [onEmployerAccountClick]);

  const handlePersonalAccountClick = useCallback(() => {
    ModalInstance.close();
    return onPersonalAccountClick();
  }, [onPersonalAccountClick]);

  return { handleEmployerAccountClick, handlePersonalAccountClick };
};
