import type { WrappedFieldProps } from "redux-form";
import _ from "underscore";

import { Loader } from "@hexocean/braintrust-ui-components";
import { useJobSelectList } from "@js/apps/invoices/hooks/job-select-list";

import { useEmployerAddress } from "./hook";

export const EmployerAddress = ({ input }: WrappedFieldProps) => {
  const { data: jobSelectList } = useJobSelectList();
  const job = _.findWhere(jobSelectList, {
    id: input.value,
  });

  const employerId = job?.employer?.id || "all";

  const { employersAddress, fetchingEmployersAddress } =
    useEmployerAddress(employerId);

  return (
    <>
      {fetchingEmployersAddress && <Loader />}
      {employersAddress && employersAddress.text}
    </>
  );
};
