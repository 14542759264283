import React, { useEffect, useState } from "react";

import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { Snackbar } from "@js/components/snackbar";
import { useCountdown, useNavigate, useQueryParams } from "@js/hooks";

import { confirmEmailChange } from "../../actions";

const ConfirmEmailChangePage = () => {
  const query = useQueryParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { startCountdown, countdown, countdownFinished } = useCountdown();

  const action = query.action;
  const key = query.key;

  useEffect(() => {
    if (key && action === ENUMS.ConfirmAction.CONFIRM_CHANGE_EMAIL) {
      confirmEmailChange(key)
        .then(() => {
          setTimeout(() => {
            setLoading(false);
            startCountdown();
          }, 1000);
        })
        .catch((error) => {
          Snackbar.error(error.confirmation_key);
          setTimeout(() => {
            navigate("/");
          }, 2500);
        });
    } else {
      Snackbar.error("Wrong action type or missing key.");
      setTimeout(() => {
        navigate("/");
      }, 2500);
    }
  }, [action, key, startCountdown, navigate]);

  useEffect(() => {
    if (countdownFinished) {
      window.location.href = "/";
    }
  }, [countdownFinished]);

  return loading ? (
    <Loader centered />
  ) : (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography component="h1" variant="title">
        Email confirmed successfully!
      </Typography>
      <Typography component="h2" size="large">
        You will be redirected to the login page in {countdown}...
      </Typography>
    </Box>
  );
};

export default ConfirmEmailChangePage;
