import {
  type LocationValue,
  LocationValueType,
} from "@js/components/autocomplete-new/google-places/types";

type IsOnlyLocationResult =
  | {
      isOnlyLocation: boolean;
      location: LocationValue;
    }
  | { isOnlyLocation: false; location: null };

export const isOnlyLocation = (
  data: Array<LocationValue>,
): IsOnlyLocationResult => {
  if (data.length) {
    const singleLocationData = data[data.length - 1];

    if (singleLocationData.location_type === LocationValueType.custom) {
      return {
        isOnlyLocation: singleLocationData.custom_location.includes("only"),
        location: singleLocationData,
      };
    }
  }

  return {
    isOnlyLocation: false,
    location: null,
  };
};

export const filterSelectedAndCustomOptions = (
  option: LocationValue,
  inputValue: string,
) => {
  if (!option) {
    return false;
  }

  const isCustom = option.location_type === LocationValueType.custom;

  if (inputValue) {
    return !isCustom && !option.selected;
  }

  return option.selected || isCustom;
};

export const sortSelectedAndCustomOptions = (
  a: LocationValue,
  b: LocationValue,
) => {
  // sort options so selected are displayed at top
  if (a.selected) {
    return -1;
  }

  if (b.selected) {
    return 1;
  }

  return 0;
};

export const filterDuplicatedOptions = (
  option: LocationValue,
  index: number,
  array: LocationValue[],
) => {
  return (
    array.findIndex((item) => {
      if (
        item.location_type === LocationValueType.custom &&
        option.location_type === LocationValueType.custom
      ) {
        return item.custom_location === option.custom_location;
      }

      if (
        item.location_type === LocationValueType.google &&
        option.location_type === LocationValueType.google
      ) {
        return item.place_id === option.place_id;
      }

      return undefined;
    }) === index
  );
};

export const handleOptionDisabled = (
  option: LocationValue,
  data: LocationValue[],
) => {
  const optionId =
    option.location_type === LocationValueType.custom
      ? option.custom_location
      : null;

  if (data.length === 1) {
    const currentSelectedLocation = data[0];
    const isOnly =
      currentSelectedLocation &&
      currentSelectedLocation.location_type === LocationValueType.custom &&
      currentSelectedLocation.custom_location.includes("only");

    if (isOnly) {
      if (optionId === currentSelectedLocation.custom_location) {
        return false;
      }

      return true;
    }
  }
  return false;
};

export const getLocationName = (location: LocationValue): string => {
  return location.location || "";
};

export const isLocationOptionOnTheList = (
  locations: LocationValue[],
  option: LocationValue,
) => {
  return !!locations.find((loc) => {
    if (
      loc.location_type === LocationValueType.custom &&
      option.location_type === LocationValueType.custom
    ) {
      return loc.custom_location === option.custom_location;
    }

    if (
      loc.location_type === LocationValueType.google &&
      option.location_type === LocationValueType.google
    ) {
      return loc.place_id === option.place_id;
    }

    return false;
  });
};
