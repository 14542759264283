import type { TypedWrappedFieldProps } from "redux-form";
import _ from "underscore";

import {
  ComboBoxMultiple,
  PaperWithVioletOptions,
  TextField,
} from "@hexocean/braintrust-ui-components";

import { NotFoundText, SearchIcon } from "../components";
import { useJobFormSkills } from "../hooks/use-job-form-skills";
import { StyledSkillsAutocomplete } from "../styled";

export const CreateJobSkillsCombobox = ({
  input,
  meta,
}: TypedWrappedFieldProps<number[]>) => {
  const { data: options, isLoading } = useJobFormSkills();

  return (
    <ComboBoxMultiple<number | { id: number; name: string }, true>
      initialTaxonomiesLoading={isLoading}
      value={input.value}
      options={options || []}
      isOptionEqualToValue={(option, val) => {
        if (typeof val === "number" && option && typeof option !== "number") {
          return val === option.id;
        }

        if (
          val &&
          typeof val !== "number" &&
          option &&
          typeof option !== "number"
        ) {
          return val.id === option.id;
        }

        return false;
      }}
      component={StyledSkillsAutocomplete}
      PaperComponent={PaperWithVioletOptions}
      onChange={(_ev, values) => {
        const unique = _.uniq(values, false);
        const ids = unique.map((val) => {
          if (typeof val === "number") {
            return val;
          } else {
            return val.id;
          }
        });
        input.onChange(ids);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            size="small"
            helperText={meta.error}
            error={meta.error?.length > 0}
            placeholder="Search skills"
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
            }}
          />
        );
      }}
      noOptionsText={<NotFoundText />}
      getOptionLabel={(option) => {
        if (!option || typeof option === "number") {
          return "";
        }

        return option.name;
      }}
      groupBy={(option) => {
        if (typeof option === "number") {
          return "";
        }

        const firstLetter = option.name[0].toUpperCase();
        return /[0-9]/.test(firstLetter) ? "0-9" : firstLetter;
      }}
      disableClearable
      disableValueRemoval
      filterSelectedOptions
    />
  );
};
