import { Box, Loader } from "@hexocean/braintrust-ui-components";
import { useDashboardPosts } from "@js/apps/freelancer/hooks/use-dashboards-posts";
import { PostsSection } from "@js/apps/give-and-get-help/components/posts-section";
import { useRedirectToActivePost } from "@js/apps/give-and-get-help/hooks/redirect-on-post-element-click";

import style from "./style.module.scss";

export const DasboardPostsSection = () => {
  const redirectToActivePost = useRedirectToActivePost();

  const { relevantPosts, loading } = useDashboardPosts();

  if (loading)
    return (
      <Box className={style.postsSectionLoader}>
        <Loader />
      </Box>
    );

  return (
    <PostsSection
      posts={relevantPosts}
      onViewCommentClick={redirectToActivePost}
      disablePostSubscription
      trendingHashtagsProps={{ className: style.trendingHashtags }}
    />
  );
};
