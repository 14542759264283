import type { Employer } from "@js/types/employer";

import type { EmployerLogoWithNameProps } from "../employer-logo-with-name";
import { EmployerLogoWithName } from "../employer-logo-with-name";

export type EmployerLogoWithNameAndColorProps = {
  employer: EmployerLogoWithNameProps["employer"] & {
    name_color: Employer["name_color"];
  };
} & Partial<EmployerLogoWithNameProps>;

export const EmployerLogoWithNameAndColor = ({
  employer,
  jobId,
  noUrl,
  size = "small",
  nameStyle = { fontSize: "18px" },
  gap = 10,
  openInNewTab,
  ...props
}: EmployerLogoWithNameAndColorProps) => {
  const { logo_thumbnail, name_color } = employer;

  return (
    <EmployerLogoWithName
      jobId={jobId}
      noUrl={noUrl}
      imageSource={logo_thumbnail}
      color={`var(${name_color || "--link"})`}
      employer={employer}
      size={size}
      nameVariant="label"
      nameSize="medium"
      nameStyle={nameStyle}
      gap={gap}
      openInNewTab={openInNewTab}
      {...props}
    />
  );
};
