import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import calendar from "dayjs/plugin/calendar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import durationPlugin from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localeData from "dayjs/plugin/localeData";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjsUtc from "dayjs/plugin/utc";

dayjs.extend(advancedFormat);
dayjs.extend(dayjsUtc);
dayjs.extend(isSameOrBefore);
dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(durationPlugin);
dayjs.extend(customParseFormat);
