import { useLocation, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

import { Tab, Tabs, Typography } from "@hexocean/braintrust-ui-components";
import { useIsProfileOwnerContext } from "@js/apps/common/profile/is-profile-owner-context";
import { RouterLink } from "@js/components/link";

import { useGetFreelancerPublicProfileQuery } from "../../api";

export const FreelancerProfilePageTabs = () => {
  const { pathname } = useLocation();
  const { isProfileOwner } = useIsProfileOwnerContext();
  const { id } = useParams();
  const { data: profile } = useGetFreelancerPublicProfileQuery(
    id ? Number(id) : skipToken,
  );
  const hasUploadedResume = profile?.resume;

  const shouldTabsBeRendered = hasUploadedResume || isProfileOwner;

  if (!shouldTabsBeRendered) {
    return null;
  }

  const PROFILE_TABS = [
    { path: `/talent/${id}/`, label: "About" },
    { path: `/talent/${id}/resume/`, label: "Resume" },
  ];

  const value = PROFILE_TABS.map((item) => item.path).includes(pathname)
    ? pathname
    : PROFILE_TABS[0].path;

  return (
    <Tabs
      value={value}
      sx={{
        mt: 3,
        mb: 5,
        "& .MuiTabs-flexContainer": {
          gap: 4,
        },
      }}
    >
      {PROFILE_TABS.map(({ path, label }) => (
        <Tab
          key={label}
          value={path}
          component={RouterLink}
          inactiveColorVariable="grey-3"
          to={path}
          sx={{ pb: 1 }}
          label={
            <Typography
              component="span"
              variant="title"
              size="small"
              fontWeight={400}
            >
              {label}
            </Typography>
          }
        />
      ))}
    </Tabs>
  );
};
