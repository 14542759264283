import { useEffect } from "react";

import type { FileUploadWithMetaState } from "@js/apps/file-upload";
import { useEffectRef } from "@js/hooks/use-effect-ref";

export type UseFieldDeleteUploadArg = {
  attachments: unknown[] | null | "";
  fileUploadsState: FileUploadWithMetaState[];
  deleteFile: (fileId: string) => void;
};

export const useFieldDeleteUpload = ({
  fileUploadsState,
  attachments,
  deleteFile,
}: UseFieldDeleteUploadArg) => {
  const fileUploadsStateRef = useEffectRef(fileUploadsState);
  const deleteFileRef = useEffectRef(deleteFile);

  useEffect(() => {
    if (!attachments) {
      return;
    }

    const fileUploadsToDelete = fileUploadsStateRef.current.filter(
      ({ fileId, isLatestUpload, errorMessage }) => {
        if (isLatestUpload && errorMessage) return false;

        const attachmentExists = attachments.some(
          (attachment) =>
            !!attachment &&
            typeof attachment === "object" &&
            "fileId" in attachment &&
            attachment.fileId === fileId,
        );

        return !attachmentExists;
      },
    );

    fileUploadsToDelete.forEach((fileUploadToDelete) =>
      deleteFileRef.current(fileUploadToDelete.fileId),
    );
  }, [attachments, fileUploadsStateRef, deleteFileRef]);
};
