import { stripTrailingSlash } from "@js/utils";

export const getAIInterviewUrl = ({
  jobId,
  talentId,
}: {
  jobId: number;
  talentId: number;
}) => {
  return `${stripTrailingSlash(SETTINGS.AIR_UI_URL)}/?job_id=${jobId}&talent_id=${talentId}`;
};
