import type { FooterActionButtonClicked } from "@js/apps/give-and-get-help/actions";
import type { FooterActions } from "@js/apps/give-and-get-help/components";
import type {
  CreateOrEditPostMode,
  ReplyType,
} from "@js/apps/give-and-get-help/types";
import { Events } from "@js/services/analytics/constants";
import { postEventProperties } from "@js/services/analytics/constants";
import { logEvent } from "@js/services/analytics/event-logging";
import { assertUnreachable } from "@js/utils/typescript";

type Mode = CreateOrEditPostMode | ReplyType;
type ActionName = FooterActions;

const replyEventMappings = {
  "add-attachment-reply": Events.ADD_ATTACHMENT_REPLY,
  "add-offer": Events.ADD_OFFER_REPLY,
};

const postEventMappings = {
  "post-file": Events.ADD_FILE,
  "post-image": Events.ADD_IMAGE,
  "post-link": Events.ADD_LINK,
  "post-poll": Events.ADD_POLL,
};

const getEventType = (
  name: ActionName,
  mode: Mode,
): EnumType<typeof Events> | null => {
  switch (mode) {
    case "reply-to-post":
    case "reply-to-comment": {
      return replyEventMappings[name as keyof typeof replyEventMappings];
    }
    case "create-post": {
      return postEventMappings[name as keyof typeof postEventMappings];
    }
    case "edit-post":
    case "edit-comment":
      return null;
    default: {
      assertUnreachable(mode);
      return null;
    }
  }
};

const getEventPayload = ({
  comment,
  post,
  post_location: postLocation,
  hashtag_id,
  mode,
}: Pick<
  FooterActionButtonClicked,
  "post" | "comment" | "post_location" | "mode" | "hashtag_id"
>) => {
  const postId = comment?.id ?? post?.id;
  const slotPosition = post?.slot_position;
  const postLevel = comment?.level ?? post?.level;

  if (!postId && !slotPosition && !postLocation) return undefined;

  switch (mode) {
    case "reply-to-post":
    case "reply-to-comment": {
      const eventProperties: Record<string, unknown> = {
        [postEventProperties.post_id]: postId,
        [postEventProperties.slot_position]: slotPosition,
        [postEventProperties.post_location]: postLocation,
        [postEventProperties.post_level]: postLevel,
      };

      if (postLocation === "hashtag") {
        eventProperties[postEventProperties.hashtag_id] = hashtag_id;
      }

      return eventProperties;
    }
    case "create-post": {
      return {
        [postEventProperties.composer_location]: postLocation,
      };
    }
    case "edit-post":
    case "edit-comment":
      return null;
    default: {
      assertUnreachable(mode);
      return null;
    }
  }
};

export const footerActionButtonsAnalyticsHandler = (
  payload: FooterActionButtonClicked,
): void => {
  const { name, mode, comment, post_location, post, hashtag_id } = payload;
  const eventType = getEventType(name, mode);
  const eventPayload = getEventPayload({
    comment,
    post,
    post_location,
    mode,
    hashtag_id,
  });

  if (eventType) {
    logEvent(eventType, eventPayload ?? {});
  }
};
