import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { CancelTokenSource } from "axios";

import { useEffectRef } from "@js/hooks/use-effect-ref";

import { FILE_UPLOAD_STATE_STATUS } from "../../constants";
import { getCancelTokenSource, uploadFile } from "../../helpers/file-upload";
import type {
  FileUploadWithMetaState,
  FileWithId,
  UploadedFile,
  UploadFileArg,
} from "../../types";
import { useUploadsState } from "../use-uploads-state";

export type UseUploadFilesArg = {
  onBeforeUpload?: () => void;
  onAfterUpload?: () => void;
  onUpload?: (uploadedFiles: UploadedFile[]) => void;
};

export const useUploadFiles = ({
  onBeforeUpload,
  onAfterUpload,
  onUpload,
}: UseUploadFilesArg = {}) => {
  const onUploadRef = useEffectRef(onUpload);
  const [hasUploaded, setHasUploaded] = useState<boolean>(false);
  const cancelTokenSourcesRef = useRef<Record<string, CancelTokenSource>>({});

  const {
    fileUploadsState,
    onPrepareUploadStart,
    onPrepareUploadFinish,
    removeFileUpload,
    resetFileUploadsState,
  } = useUploadsState();

  const onBeforeUploadRef = useEffectRef(onBeforeUpload);
  const onAfterUploadRef = useEffectRef(onAfterUpload);

  const cancelFileUpload = useCallback((fileId: string) => {
    cancelTokenSourcesRef.current?.[fileId]?.cancel();

    delete cancelTokenSourcesRef.current?.[fileId];
  }, []);

  const cancelAllFilesUpload = useCallback(() => {
    Object.values(cancelTokenSourcesRef.current).forEach(
      (cancelTokenSource) => {
        cancelTokenSource.cancel();
      },
    );
    cancelTokenSourcesRef.current = {};
  }, []);

  const deleteFile = useCallback(
    (fileId: string) => {
      cancelTokenSourcesRef.current[fileId]?.cancel();

      removeFileUpload(fileId);
    },
    [removeFileUpload],
  );

  const deleteAllFiles = useCallback(() => {
    cancelAllFilesUpload();
    resetFileUploadsState();
  }, [cancelAllFilesUpload, resetFileUploadsState]);

  const uploadFiles = useCallback(
    async ({
      files,
      uploadType,
      timeoutMs,
    }: Pick<UploadFileArg, "uploadType"> & {
      files: FileWithId[];
      timeoutMs?: number;
    }) => {
      onBeforeUploadRef.current?.();
      onPrepareUploadStart(files, timeoutMs);

      const promises = files.map(async (file) => {
        const cancelTokenSource = getCancelTokenSource();
        cancelTokenSourcesRef.current[file.fileId] = cancelTokenSource;

        const response = await uploadFile({
          file,
          uploadType,
          cancelToken: cancelTokenSource.token,
        });

        delete cancelTokenSourcesRef.current[file.fileId];

        onPrepareUploadFinish([
          { fileId: file.fileId, uploadResponse: response },
        ]);
      });

      setHasUploaded(true);
      await Promise.all(promises);
    },
    [onPrepareUploadStart, onPrepareUploadFinish, onBeforeUploadRef],
  );

  const isUploading = useMemo(
    () =>
      fileUploadsState.some(
        ({ status }) => status === FILE_UPLOAD_STATE_STATUS.UPLOADING,
      ),
    [fileUploadsState],
  );

  const errorsOnLatestUpload = useMemo(() => {
    return fileUploadsState
      .filter(
        (state): state is FileUploadWithMetaState & { errorMessage: string } =>
          state.isLatestUpload &&
          state.status === FILE_UPLOAD_STATE_STATUS.ERROR &&
          typeof state.errorMessage === "string",
      )
      .map(({ errorMessage }) => errorMessage);
  }, [fileUploadsState]);

  const isErrorOnLatestUpload = !!errorsOnLatestUpload.length;

  useEffect(() => {
    if (isUploading || !onUploadRef.current) {
      return;
    }

    const lastUploadCompletedFiles = fileUploadsState
      .filter(
        (
          fileState,
        ): fileState is FileUploadWithMetaState & { file: UploadedFile } =>
          fileState.isLatestUpload &&
          fileState.status === FILE_UPLOAD_STATE_STATUS.COMPLETED &&
          !!fileState.file,
      )
      .map((fileState) => fileState.file);

    if (!lastUploadCompletedFiles.length) {
      return;
    }

    // call the upload callback with only the latest uploaded files ...
    // ... as we are not aware of the order of previously uploaded files ...
    // ... that some components might want to control
    onUploadRef.current(lastUploadCompletedFiles);
  }, [fileUploadsState, onUploadRef, isUploading]);

  useEffect(() => {
    if (!hasUploaded || isUploading) {
      return;
    }

    onAfterUploadRef.current?.();
  }, [hasUploaded, isUploading, onAfterUploadRef]);

  // cleanup effect
  useEffect(
    () => () => {
      cancelAllFilesUpload();
    },
    [cancelAllFilesUpload],
  );

  return {
    uploadFiles,
    cancelFileUpload,
    cancelAllFilesUpload,
    deleteFile,
    deleteAllFiles,
    isUploading,
    fileUploadsState,
    isError: isErrorOnLatestUpload,
    errors: errorsOnLatestUpload,
  };
};
