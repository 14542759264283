import { Box, Typography } from "@hexocean/braintrust-ui-components";
import type { Job, SkillForJob } from "@js/types/jobs";

import { JobSkills } from "../../job-skills";

import styles from "./styles.module.scss";

type SkillSectionContentProps = {
  job: Job;
};

export const SkillSectionContent = ({ job }: SkillSectionContentProps) => {
  const topSkills: Array<SkillForJob> = [];
  const otherSkills: Array<SkillForJob> = [];

  job.job_skills.forEach((skill) => {
    if (skill.is_top) {
      topSkills.push(skill.skill);
    } else {
      otherSkills.push(skill.skill);
    }
  });

  return (
    <Box display="flex" gap={2} flexDirection="column">
      {!!topSkills?.length && (
        <JobDescriptionSkills title="Top Skills" skills={topSkills} />
      )}
      {!!otherSkills?.length && (
        <OtherSkills
          title={!!topSkills?.length ? "Other Skills" : "Skills"}
          skills={otherSkills}
        />
      )}
    </Box>
  );
};

type JobDescriptionSkillsProps = {
  title: string;
  skills: Array<SkillForJob>;
};

const JobDescriptionSkills = ({ title, skills }: JobDescriptionSkillsProps) => {
  return (
    <Box>
      <Typography
        component="h4"
        color="primary"
        mb={1}
        size="small"
        fontWeight={500}
      >
        {title}
      </Typography>
      <Box className={styles.skills}>
        <JobSkills skills={skills} />
      </Box>
    </Box>
  );
};

type OtherSkillsProps = {
  title: string;
  skills: Array<SkillForJob>;
  topSkills?: boolean;
};

const OtherSkills = ({ ...props }: OtherSkillsProps) => {
  return (
    <Box>
      <JobDescriptionSkills {...props} />
    </Box>
  );
};
