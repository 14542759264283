import {
  EnableOTPAuthModalInstance,
  OTPAuthBackupCodesModalInstance,
  OTPDisabledWarningModalInstance,
} from "@js/apps/settings/components/otp-auth";

export const OTPModals = () => {
  return (
    <>
      <OTPDisabledWarningModalInstance />
      <OTPAuthBackupCodesModalInstance />
      <EnableOTPAuthModalInstance />
    </>
  );
};
