import type { ChangeEvent } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box, Checkbox } from "@hexocean/braintrust-ui-components";
import { boostButtonClicked } from "@js/apps/jobs/actions";
import { getError, shouldDisplayError } from "@js/forms/utils";
import { useAppDispatch } from "@js/hooks";

import { BoostFieldPanel } from "./components";
import { getFieldDescription } from "./utils";

type BoostFieldProps = {
  boost: number;
  statusForFreelancer: EnumType<typeof ENUMS.JobFreelancerStatus> | undefined;
  isEditPage?: boolean;
  is_boosted?: boolean;
};

const FREELANCER_STATUSES_TO_SHOW_BOOST_ITEM: EnumType<
  typeof ENUMS.JobFreelancerStatus
>[] = [
  ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS,
  ENUMS.JobFreelancerStatus.INVITED_BY_CLIENT,
  ENUMS.JobFreelancerStatus.INVITED_BY_MATCHER,
  ENUMS.JobFreelancerStatus.IN_REVIEW,
  ENUMS.JobFreelancerStatus.APPLICATION_SENT,
  ENUMS.JobFreelancerStatus.PENDING_APPROVAL,
];

export const BoostItem = ({
  boost,
  isEditPage,
  is_boosted,
  statusForFreelancer,
}: BoostFieldProps) => {
  const title = "Boost your application.";
  const description = getFieldDescription(boost, isEditPage, is_boosted);

  if (
    !statusForFreelancer ||
    !FREELANCER_STATUSES_TO_SHOW_BOOST_ITEM.includes(statusForFreelancer)
  ) {
    return null;
  }

  return (
    <Field
      id="boost-application"
      name="is_boosted"
      title={title}
      boost={boost}
      description={description}
      isEditPage={isEditPage}
      is_boosted={is_boosted}
      component={BoostApplicationField}
    />
  );
};

type BoostRateFieldProps = {
  title: string;
  description: string;
  id: string;
  boost: number;
  isEditPage?: boolean;
  is_boosted?: boolean;
} & TypedWrappedFieldProps<boolean>;

const BoostApplicationField = ({
  title,
  description,
  boost,
  isEditPage,
  is_boosted,
  ...props
}: BoostRateFieldProps) => {
  const displayError = shouldDisplayError(props);
  const dispatch = useAppDispatch();
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event?.target?.checked;

    props.input.onChange(isChecked);

    if (isChecked === true) {
      dispatch(boostButtonClicked());
    }
  };

  return (
    <Box
      sx={{
        background:
          "linear-gradient(91.94deg, #4E86CF -2.36%, #D4E4F9 28.58%, #4E86CF 60.5%, #B0CAED 100.61%);",
        padding: "1px",
        borderRadius: "16px",
        "@media (min-width: 960px) and (max-width: 1280px)": {
          minWidth: "440px",
        },
      }}
      marginBottom={3}
    >
      <BoostFieldPanel
        title={title}
        description={description}
        displayError={displayError}
        isChecked={props.input.value}
        error={getError(props)}
      >
        {boost > 0 && (
          <Checkbox
            disabled={isEditPage && is_boosted}
            checked={props.input.value}
            onChange={handleCheckboxChange}
            className="fill-out-section-panel__checkbox"
          />
        )}
      </BoostFieldPanel>
    </Box>
  );
};
