import { useRequestHelpOfferRevisionMutation } from "@js/apps/give-and-get-help/api";
import { Snackbar } from "@js/components/snackbar";
import type { HelpOffer } from "@js/types/give-and-get-help";

type UseRequestHelpOfferRevisionConfig = {
  offer: HelpOffer;
};

export const useRequestHelpOfferRevision = ({
  offer,
}: UseRequestHelpOfferRevisionConfig) => {
  const [requestRevision, { isLoading: isRequestingRevision }] =
    useRequestHelpOfferRevisionMutation();

  const handleRequestRevision = () => {
    requestRevision({
      offerId: offer.id,
      data: { budget: String(offer.budget) },
    })
      .unwrap()
      .then(() => Snackbar.info("Revision requested"))
      .catch(() => Snackbar.error("Something went wrong."));
  };

  return {
    handleRequestRevision,
    isRequestingRevision,
  };
};
