import { useEffect } from "react";

import type { InitCoinbasePaymentResponseWithExistingWallet } from "@js/apps/on-ramp/api";
import { useAppDispatch } from "@js/hooks";

import { usePollWalletStatus } from "../use-poll-wallet-status";

type UseCheckCreatingWalletStatusArgs = {
  onWalletCreated: (
    props: InitCoinbasePaymentResponseWithExistingWallet,
  ) => void;
};

export const useCheckCreatingWalletStatus = ({
  onWalletCreated,
}: UseCheckCreatingWalletStatusArgs) => {
  const dispatch = useAppDispatch();

  const {
    data,
    isLoading,
    isPollingForWalletStatusEnabled,
    isCreatingWalletModalOpen,
  } = usePollWalletStatus();

  useEffect(() => {
    if (!isPollingForWalletStatusEnabled) return;

    const isWalletCreated = data && !data.wallet_creation_in_progress;

    if (!isWalletCreated) return;

    if (isCreatingWalletModalOpen) {
      onWalletCreated(data);
    }
  }, [
    data,
    onWalletCreated,
    isPollingForWalletStatusEnabled,
    dispatch,
    isCreatingWalletModalOpen,
  ]);

  return {
    data,
    isLoading,
    isPollingForWalletStatusEnabled,
  };
};
