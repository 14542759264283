import type { ReactNode } from "react";

import { Badge, Box } from "@hexocean/braintrust-ui-components";

const notificationLabel = (count: number) => {
  if (count === 0) {
    return "no notifications";
  }

  if (count === 1) {
    return "1 notification";
  }

  if (count > 99) {
    return "more than 99 notifications";
  }

  return `${count} notifications`;
};

type SpaceNotificationCounterProps = {
  notificationCounter: number;
  children: ReactNode;
};

export const SpaceNotificationCounter = ({
  notificationCounter,
  children,
}: SpaceNotificationCounterProps) => {
  if (!notificationCounter) {
    return <Box aria-label={notificationLabel(0)}>{children}</Box>;
  }

  return (
    <Badge
      aria-label={notificationLabel(notificationCounter)}
      badgeContent={notificationCounter}
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexShrink: 1,
        gap: 1,
        justifyContent: "space-between",
        "& .MuiBadge-badge": {
          backgroundColor: "var(--negative-2)",
          color: "var(--white)",
          borderRadius: "10px",
          transform: "unset",
          position: "static",
          transformOrigin: "unset",
        },
      }}
    >
      {children}
    </Badge>
  );
};
