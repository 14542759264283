import {
  Avatar,
  Paper,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";

import styles from "./how-to-post-questions.module.scss";

export const HowToPostQuestions = () => {
  return (
    <Stack
      component="ul"
      className={styles.questionsContainer}
      sx={{
        gap: { xs: 1.5, md: 2 },
      }}
    >
      {QUESTIONS.map((question) => {
        return <Question key={question.id} {...question} />;
      })}
    </Stack>
  );
};

type QuestionProps = {
  question: string;
  avatarSrc: string;
};
const Question = ({ question, avatarSrc }: QuestionProps) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Paper component="li" variant="outlined" className={styles.question}>
      <Stack direction="row" sx={{ gap: 2 }}>
        <Avatar
          src={{
            first_name: "",
            has_avatar_set: true,
            avatar_thumbnail: avatarSrc,
            gravatar: "",
          }}
          size={isMobile ? "sm" : undefined}
        />
        <Typography
          component="p"
          variant="paragraph"
          size={isMobile ? "small" : "medium"}
        >
          {question}
        </Typography>
      </Stack>
    </Paper>
  );
};

const commonQuestion =
  "What is the salary negotiation strategy to not come as rude?";

const QUESTIONS = [
  {
    id: 1,
    question: commonQuestion,
    avatarSrc: `${SETTINGS.STATIC_URL}example-avatars/example-6.png`,
  },
  {
    id: 2,
    question: commonQuestion,
    avatarSrc: `${SETTINGS.STATIC_URL}example-avatars/example-6.png`,
  },
  {
    id: 3,
    question: commonQuestion,
    avatarSrc: `${SETTINGS.STATIC_URL}example-avatars/example-5.png`,
  },
];
