import { useCallback } from "react";
import { closeSnackbar } from "notistack";

import { Typography } from "@hexocean/braintrust-ui-components";
import {
  useLazyGetJobDraftsQuery,
  usePublishPendingJobsMutation,
} from "@js/apps/jobs/api";
import { RouterLink } from "@js/components/link";
import { Snackbar } from "@js/components/snackbar";
import { pluralize } from "@js/utils";

export const useHandleAutoPublishDrafts = () => {
  const [publishPendingJobs] = usePublishPendingJobsMutation();
  const [getDrafts] = useLazyGetJobDraftsQuery();

  const getDraftsCount = useCallback(async () => {
    const { data: drafts } = await getDrafts({});
    if (!drafts) {
      return 0;
    }

    return drafts.results.filter((draft) => draft?.public_after_verification)
      .length;
  }, [getDrafts]);

  const handlePublishDrafts = useCallback(async () => {
    const draftCounts = await getDraftsCount();
    if (!draftCounts) {
      return;
    }

    const response = await publishPendingJobs()
      .unwrap()
      .catch(() => null);

    if (!response) {
      Snackbar.error(SOME_DRAFTS_NOT_PUBLISHED);
      return;
    }

    preparePublishedJobsSummary(response);
  }, [getDraftsCount, publishPendingJobs]);

  return {
    handlePublishDrafts,
  };
};

const preparePublishedJobsSummary = ({
  created,
  failed,
}: {
  created: number;
  failed: number;
}) => {
  const oneCreated = created === 1;
  const oneFailed = failed === 1;
  const message = (
    <>
      <Typography
        variant="paragraph"
        component="span"
        sx={{ display: "block" }}
      >
        {<b>{created}</b>} job
        {pluralize(created, {
          zeroPlural: true,
        })}{" "}
        {oneCreated ? "was" : "were"} posted.{<br />}
        {<b>{failed}</b>} job
        {pluralize(failed, {
          zeroPlural: true,
        })}{" "}
        {oneFailed ? "has" : "have"} errors that need to be corrected.
      </Typography>
      <Typography
        variant="paragraph"
        component="span"
        mt={2}
        sx={{ display: "block" }}
      >
        Please visit{" "}
        <RouterLink
          to="employer/dashboard/my_jobs"
          onClick={() => closeSnackbar()}
          style={{ textDecoration: "underline" }}
        >
          My Jobs
        </RouterLink>{" "}
        for details.
      </Typography>
    </>
  );

  Snackbar.toast({
    header: "Thank you for verifying.",
    buttonText: "Got it",
    content: message,
  });
};

const SOME_DRAFTS_NOT_PUBLISHED =
  "We cannot publish some of your jobs. Please check your drafts.";
