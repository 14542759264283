import type { OrderingType } from "@js/apps/common/components/filters/config";
import { createFiltersFormAndHook } from "@js/apps/filters/";

export type SearchResultsFilters = {
  ordering: OrderingType;
};

const defaultFormValues: SearchResultsFilters = {
  ordering: "-created",
};

export const [SearchResultsFiltersForm, useSearchResultsFilters] =
  createFiltersFormAndHook<SearchResultsFilters, SearchResultsFilters>({
    useAllURLParams: true,
    defaultFormValues: defaultFormValues,
    defaultParamsValues: defaultFormValues,
    submitOnChange: true,
  });
