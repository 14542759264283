import React from "react";
import { Field, reduxForm } from "redux-form";

import { Box, Button } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { Form } from "@js/forms/components/form";
import { TextField } from "@js/forms/fields/text";
import { required } from "@js/forms/validators";

export type ForgotPasswordFormValues = {
  email: string;
};

export const ForgotPasswordForm = reduxForm<ForgotPasswordFormValues>({
  form: "forgotPassword",
})(({ submit, error, submitting, pristine }) => {
  return (
    <Box
      component={Form}
      className="password-recover-form"
      justifyContent="center"
      onSubmit={submitting ? undefined : submit}
      error={error}
    >
      <Box mb={3} px={0} width={1} className="password-recover-form-row">
        <Field
          id="email"
          name="email"
          variant="outlined"
          validate={[required]}
          color="secondary"
          component={TextField}
          label="Email address"
        />
      </Box>
      <Box px={0} width={1} className="password-recover-form-row">
        <Button
          fullWidth
          variant="primary"
          disabled={pristine || submitting}
          type="submit"
        >
          Reset Password
        </Button>
        <Button
          fullWidth
          style={{ marginTop: 12 }}
          variant="transparent"
          to="/auth/login/"
          RouterLink={RouterLink}
        >
          Return to login
        </Button>
      </Box>
    </Box>
  );
});
