import { useCallback } from "react";
import { isEmpty } from "underscore";

import { openGeneralInfoModal } from "@js/apps/freelancer/components/profnet-profile-page-head/open-general-info-modal";
import { useFreelancerProfile } from "@js/apps/freelancer/hooks";

export type UseLocationWarningBadgeType = {
  handleCtaMessage: () => void;
  isLocationSet: boolean;
  isFreelancer: boolean;
};

export const useLocationWarningBadge = (): UseLocationWarningBadgeType => {
  const freelancer = useFreelancerProfile();

  const openAccountEditModal = useCallback(() => {
    if (!freelancer) {
      return;
    }
    openGeneralInfoModal(freelancer);
  }, [freelancer]);

  const isLocationSet = !isEmpty(freelancer?.location_full);

  const handleCtaMessage = () => {
    if (isLocationSet) return;
    openAccountEditModal();
  };

  return {
    isFreelancer: !!freelancer,
    handleCtaMessage,
    isLocationSet,
  };
};
