import { useSearchEvents } from "@js/apps/common/hooks";
import type { SearchEventEntityTypeKey } from "@js/types/tracking";

import type { UniversalTabValue } from "../constants";
import { UNIVERSAL_SEARCH_TABS } from "../constants";

export const useSearchEventsUniversalSearch = (
  currentTab: UniversalTabValue,
) => {
  const { sendSearchEventDebounced } = useSearchEvents(
    mapTabToDataType(currentTab),
  );

  return { handleSearchEvent: sendSearchEventDebounced };
};

const mapTabToDataType = (tab: UniversalTabValue): SearchEventEntityTypeKey => {
  if (tab === UNIVERSAL_SEARCH_TABS.PEOPLE) return "TALENT";

  return tab;
};
