import React from "react";
import classnames from "classnames";

import { IconButton, Loader } from "@hexocean/braintrust-ui-components";
import { KeyboardArrowDownIcon } from "@hexocean/braintrust-ui-components/Icons";

type SimpleAccordionProps = {
  expanded: boolean;
  loading: boolean;
  header: React.ReactNode;
  name: string;
  onClick: () => void;
};

import style from "./style.module.scss";

export const SimpleAccordion: React.FC<
  React.PropsWithChildren<SimpleAccordionProps>
> = ({ children, expanded, loading, header, name, onClick }) => {
  return (
    <div data-testid={`accordion-${name}`}>
      {/* {/* Disable click-events-have-key-events as keyboard users could focus on inside button */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={style.simpleAccordionHeader}
        onClick={onClick}
        id="accordion-header"
        role="button"
        aria-expanded={expanded}
      >
        <IconButton
          aria-label={expanded ? "Shrink accordion" : "Expand accordion"}
          className={classnames(style.simpleAccordionButton, {
            [style.simpleAccordionButtonExpanded]: expanded,
          })}
          variant="transparent"
        >
          <KeyboardArrowDownIcon />
        </IconButton>
        {header}
      </div>
      {expanded ? (
        loading ? (
          <div className="posr" style={{ minHeight: 100 }}>
            <Loader centered />
          </div>
        ) : (
          children
        )
      ) : null}
    </div>
  );
};

export * from "./hooks";
