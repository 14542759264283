import { useCallback, useState } from "react";
import { change } from "redux-form";

import { getFileWithId, useUploadSingleFile } from "@js/apps/file-upload";
import { useAppDispatch } from "@js/hooks";
import { prepareDOMInput } from "@js/services";
import type { UploadedJob } from "@js/types/jobs";

import { UPLOAD_JOB_DESCRIPTION } from "../action-types";
import { useLazyGetUploadedJobDescriptionDataQuery } from "../api";
import type { CREATE_JOB_FORM_ID, EDIT_JOB_FORM_ID } from "../constants";

const JOB_TITLE_FIELD_NAME = "title";
const JOB_DESCRIPTION_FIELD_NAME = "description";

type UseJobDescriptionUpdateProps = {
  formId: typeof CREATE_JOB_FORM_ID | typeof EDIT_JOB_FORM_ID;
};

export const useJobDescriptionUpdate = ({
  formId,
}: UseJobDescriptionUpdateProps) => {
  const dispatch = useAppDispatch();
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [fetchUploaded, { isFetching }] =
    useLazyGetUploadedJobDescriptionDataQuery();
  const [lastFormUpdateSourceFileId, setLastFormUpdateSourceFileId] =
    useState<number>(); // used to remount lexical editor and update description

  const updateJobFormState = useCallback(
    ({
      title,
      description,
    }: {
      title?: string;
      description?: string;
    }): void => {
      if (title) {
        dispatch(change(formId, JOB_TITLE_FIELD_NAME, title));
      }
      if (description) {
        dispatch(change(formId, JOB_DESCRIPTION_FIELD_NAME, description));
      }
    },
    [dispatch, formId],
  );

  const updateJobForm = useCallback(
    (uploadedFileId: number): void => {
      setUpdateError(null);
      fetchUploaded(uploadedFileId)
        .unwrap()
        .then(({ result }) => {
          const titleValue = result.title;
          const descriptionValue = result.description;

          if (!titleValue && !descriptionValue)
            throw Error("both values are null");

          const uploadedJobData: UploadedJob = {
            title: titleValue ?? undefined,
            description: descriptionValue
              ? prepareDOMInput(descriptionValue)
              : undefined,
            flow_entry: "upload",
          };

          updateJobFormState(uploadedJobData);
          dispatch({
            type: UPLOAD_JOB_DESCRIPTION,
            payload: uploadedJobData,
          });
          setLastFormUpdateSourceFileId(uploadedFileId);
        })
        .catch(() => {
          setUpdateError("Failed to load data from the uploaded file");
        });
    },
    [dispatch, fetchUploaded, updateJobFormState],
  );

  const {
    uploadedFile,
    uploadSingleFile,
    resetFileState,
    uploadingFileName,
    isUploading,
    error,
  } = useUploadSingleFile({
    onUpload: (file) => {
      updateJobForm(file.id);
    },
  });

  const updateJobFormWithFileDataFinished = uploadedFile
    ? uploadedFile.id === lastFormUpdateSourceFileId
    : false;

  const uploadJobDescriptionFile = useCallback(
    (file: File) => {
      const fileWithId = getFileWithId(file);
      uploadSingleFile({
        file: fileWithId,
        uploadType: ENUMS.UploadType.JOB_DESCRIPTION_FILE_UPLOAD_TYPE,
        timeoutMs: 45_000,
      });
    },
    [uploadSingleFile],
  );

  return {
    updateJobFormWithFileDataError: updateError,
    isFetchingJobDataFromUploadedFile: isFetching,
    updateJobFormWithFileDataFinished: updateJobFormWithFileDataFinished,
    uploadJobDescriptionFile,
    uploadingFileName,
    lastFormUpdateSourceFileId,
    resetUploadedFileState: resetFileState,
    uploadError: error,
    isUploading,
    setUploadDescriptionError: setUpdateError,
  };
};
