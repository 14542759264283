export const getPaymentTypeOrder = (
  jobType: EnumType<typeof ENUMS.JobType>,
) => {
  switch (jobType) {
    case ENUMS.JobType.GRANT: {
      return [
        ENUMS.JobPaymentType.FIXED_PRICE,
        ENUMS.JobPaymentType.HOURLY,
        ENUMS.JobPaymentType.ANNUAL,
      ];
    }
    case ENUMS.JobType.FREELANCE: {
      return [
        ENUMS.JobPaymentType.HOURLY,
        ENUMS.JobPaymentType.FIXED_PRICE,
        ENUMS.JobPaymentType.ANNUAL,
      ];
    }
    case ENUMS.JobType.DIRECT_HIRE: {
      return [
        ENUMS.JobPaymentType.ANNUAL,
        ENUMS.JobPaymentType.HOURLY,
        ENUMS.JobPaymentType.FIXED_PRICE,
      ];
    }
    default: {
      return [
        ENUMS.JobPaymentType.FIXED_PRICE,
        ENUMS.JobPaymentType.ANNUAL,
        ENUMS.JobPaymentType.HOURLY,
      ];
    }
  }
};
