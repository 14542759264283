import { useShowInsightFeatureFlag } from "./hooks";

type ApplicationInsightFeatureFlagWrapperProps = {
  children: React.ReactNode;
};

export const ApplicationInsightFeatureFlagWrapper = ({
  children,
}: ApplicationInsightFeatureFlagWrapperProps) => {
  const showInsight = useShowInsightFeatureFlag();

  return showInsight ? <>{children}</> : null;
};
