import type { RefObject } from "react";
import { useEffect, useState } from "react";

import { Snackbar } from "@js/components/snackbar";

import { useGetRoomMessagesQuery } from "../api";

import { useLoadMoreMessagesOnScroll } from "./use-load-more-messages-on-scroll";

type UseRoomMessagesArg = {
  roomId: number;
  elementRef: RefObject<HTMLElement>;
};

export const useRoomMessages = ({ roomId, elementRef }: UseRoomMessagesArg) => {
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const { currentData, isLoading, isFetching, error } = useGetRoomMessagesQuery(
    { room: roomId, cursor },
  );

  useEffect(() => {
    if (!error) {
      return;
    }

    Snackbar.error("Failed to fetch chat messages.");
  }, [error]);

  useLoadMoreMessagesOnScroll({
    elementRef,
    currentData,
    setCursor,
    isFetching,
  });

  return { currentData, isLoading };
};
