import React from "react";
import AllState from "@static/public-job-page/allstate.svg";
import Atlassian from "@static/public-job-page/atlassian.svg";
import BlackDecker from "@static/public-job-page/black-decker.svg";
import Five9 from "@static/public-job-page/five-9.svg";
import IBM from "@static/public-job-page/IBM.svg";
import Lucra from "@static/public-job-page/lucra.svg";
import Nasa from "@static/public-job-page/nasa.svg";
import Nestle from "@static/public-job-page/nestle.svg";
import NextDoor from "@static/public-job-page/next-door.svg";
import Nike from "@static/public-job-page/nike.svg";
import Porsche from "@static/public-job-page/porsche.svg";
import UnderArmour from "@static/public-job-page/under-armour.svg";

import {
  Box,
  Carousel,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { NotLoggedInApplyButton } from "@js/apps/jobs/components";
import { AcceptingProposalsStatus } from "@js/apps/jobs/components/job-status/common/accepting-proposals";
import { NotAcceptingProposalsPublic } from "@js/apps/jobs/components/job-status/public/not-accepting-proposals";
import { RateSectionModule } from "@js/apps/jobs/components/rate";
import { getApplyButtonLabelByStatus } from "@js/apps/jobs/views/public-job-details/helpers";
import type { Job } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

type JobType = {
  job: Job;
};

export const PublicRateComponent = ({ job }: JobType) => {
  return (
    <RoundedBox height="100%">
      <RateSectionModule job={job} />
    </RoundedBox>
  );
};

export const SocialProofModule = ({
  jobId,
  jobStatus,
}: {
  jobId: number;
  jobStatus: EnumType<typeof ENUMS.JobStatus>;
}) => {
  const isMobile = useMediaQuery(641);

  return (
    <RoundedBox mt={3}>
      <div className="social-proof-module__header-section">
        <div>
          <Typography
            className="social-proof-module__title"
            component="h3"
            variant="title"
            size="small"
            fontWeight={400}
          >
            Access the world’s best jobs.
          </Typography>
          <Typography component="p" variant="paragraph">
            Keep 100% of what you earn.
          </Typography>
        </div>
        <NotLoggedInApplyButton
          className="social-proof-module__cta"
          jobId={jobId}
          variant="positive-2"
          shape="squared"
          size="small"
          fullWidth={isMobile}
          label={getApplyButtonLabelByStatus(jobStatus)}
        />
      </div>
      <Carousel
        // the number of slides must be at least 2 times greater than the number of displayed ones
        slides={[...LOGO_ICONS, ...LOGO_ICONS].map(({ Icon, key }, index) => {
          return (
            <Box
              key={key}
              width="fit-content"
              className="social-proof-module__logo"
              bgcolor={`var(--${BG_COLORS[index % BG_COLORS.length]})`}
            >
              <Box>
                <Icon />
              </Box>
            </Box>
          );
        })}
        className="social-proof-module__carousel"
        observer
        observeParents
        spaceBetween={8}
        loop
        autoplay
      />
    </RoundedBox>
  );
};

const BG_COLORS = [
  "soft-yellow",
  "soft-green",
  "soft-red",
  "soft-violet",
  "soft-blue",
  "soft-grey",
];

const LOGO_ICONS = [
  { Icon: IBM, key: "IMB-icon" },
  { Icon: AllState, key: "AllState-icon" },
  { Icon: Atlassian, key: "Atlassian-icon" },
  { Icon: BlackDecker, key: "BlackDecker-icon" },
  { Icon: Five9, key: "Five9-icon" },
  { Icon: Lucra, key: "Lucra-icon" },
  { Icon: Nasa, key: "Nasa-icon" },
  { Icon: Nestle, key: "Nestle-icon" },
  { Icon: NextDoor, key: "NextDoor-icon" },
  { Icon: Nike, key: "Nike-icon" },
  { Icon: Porsche, key: "Porsche-icon" },
  { Icon: UnderArmour, key: "UnderArmour-icon" },
];

type PublicJobStatusProps = {
  job: Job;
  className?: string;
};

export const PublicJobStatus = ({ job, ...props }: PublicJobStatusProps) => {
  switch (job.job_status) {
    case ENUMS.JobStatus.OPEN:
    case ENUMS.JobStatus.ACTIVE_NOT_FILLED:
      return (
        <AcceptingProposalsStatus
          {...props}
          content={
            <NotLoggedInApplyButton
              className="social-proof-module__cta"
              jobId={job.id}
              variant="positive-2"
              shape="squared"
              size="small"
              fullWidth
            />
          }
        />
      );
    case ENUMS.JobStatus.ON_HOLD:
      return <NotAcceptingProposalsPublic size="medium" status="On hold" />;
    case ENUMS.JobStatus.COMPLETED:
    case ENUMS.JobStatus.COMPLETED_NOT_HIRED:
    case ENUMS.JobStatus.ACTIVE:
      return <NotAcceptingProposalsPublic size="medium" status="Closed" />;
    case ENUMS.JobStatus.ACTIVE_FILLED:
    case ENUMS.JobStatus.COMPLETED_HIRED:
      return <NotAcceptingProposalsPublic size="medium" status="Job filled" />;

    default:
      assertUnreachable(job.job_status);
      return null;
  }
};
