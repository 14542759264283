import type { ReactNode } from "react";
import { useMemo } from "react";
import cs from "classnames";

import { Box, type BoxProps } from "@hexocean/braintrust-ui-components";

import styles from "./public-top-header.module.scss";

type PublicTopHeaderProps = {
  children: ReactNode;
  offsetByScrollbar?: boolean;
} & BoxProps;

export const PublicTopHeader = ({
  children,
  className,
  offsetByScrollbar,
  ...rest
}: PublicTopHeaderProps) => {
  const scrollBarWidth = useMemo(
    () => (offsetByScrollbar ? getScrollbarWidth() : 0),
    [offsetByScrollbar],
  );

  return (
    <Box
      {...rest}
      style={{ "--scrollbar-width": `${scrollBarWidth}px` }}
      component="header"
      className={cs(styles.header, className)}
    >
      {children}
    </Box>
  );
};

const getScrollbarWidth = () => {
  const scrollDiv = document.createElement("div");

  scrollDiv.style.overflow = "scroll";
  scrollDiv.style.position = "absolute";
  scrollDiv.style.top = "-9999px";

  // Append the div to the document body
  document.body.appendChild(scrollDiv);

  // Calculate the scrollbar width
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

  // Remove the div from the document body
  document.body.removeChild(scrollDiv);

  return scrollbarWidth;
};
