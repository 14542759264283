import type { RefObject } from "react";
import { useEffect } from "react";

type UseTriggerActionOnScrollParams<T> = {
  action: (element: T) => void;
  elementRef: RefObject<T>;
  scrollTopOffset: number;
};

export const useTriggerActionOnScroll = <T extends HTMLElement>({
  action,
  elementRef,
  scrollTopOffset,
}: UseTriggerActionOnScrollParams<T>) => {
  useEffect(() => {
    const element = elementRef.current;

    if (!element) return;

    element.onscroll = async () => {
      if (scrollTopOffset && element.scrollTop < scrollTopOffset) {
        action(element);
      }
    };

    return () => {
      element.onscroll = null;
    };
  }, [action, elementRef, scrollTopOffset]);
};
