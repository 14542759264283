import type { FormErrors } from "redux-form";
import { getFormSubmitErrors } from "redux-form";

import { useJobSelectList } from "@js/apps/invoices/hooks/job-select-list";
import { useAppSelector } from "@js/hooks";
import { isErrorWithMessage } from "@js/types/errors";

import type { InvoiceFormData } from "../../types";
import { INVOICE_FORM_ID } from "../../types";

export const InvoiceErrors = () => {
  const { data: jobSelectList } = useJobSelectList();
  const hasWithdrawalMethod = useAppSelector(
    (state) => !!(state.withdrawal as any).withdrawalMethods.length,
  );

  const submitErrors = useAppSelector((state) =>
    getFormSubmitErrors(INVOICE_FORM_ID)(state),
  );

  const errors = getErrorMessages({
    noWithdrawalMethod: !hasWithdrawalMethod,
    noJobs: !jobSelectList.length,
    noInvoiceableJobs: !jobSelectList.some((job) => job.invoice_can_be_created),
    submitErrors,
  });

  if (!errors.length) return null;

  return (
    <div className="general-form-error">
      {errors.map((message) => {
        return <p key={message}>{message}</p>;
      })}
    </div>
  );
};

type getErrorMessagesArgs = {
  noJobs: boolean;
  noWithdrawalMethod: boolean;
  noInvoiceableJobs: boolean;
  submitErrors: FormErrors<InvoiceFormData>;
};

const getErrorMessages = ({
  noJobs,
  noWithdrawalMethod,
  noInvoiceableJobs,
  submitErrors,
}: getErrorMessagesArgs) => {
  const errors: string[] = [];

  const newItemsGeneralError = isErrorWithMessage(submitErrors?.new_items)
    ? submitErrors?.new_items?._error
    : null;

  if (noWithdrawalMethod && noInvoiceableJobs) {
    errors.push(
      `Banking information must be defined before issuing an invoice.`,
    );
  }
  if (noJobs) {
    errors.push(
      `There are no jobs that can be invoiced yet. Search for a job to get hired!`,
    );
  }

  if (newItemsGeneralError) {
    errors.push(newItemsGeneralError as string);
  }

  return errors;
};
