import { Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import styles from "./job-not-found.module.scss";

const JobNotFound = () => {
  return (
    <>
      <img
        className={styles.image}
        src={`${SETTINGS.STATIC_URL}jobs/bkg-empty-no-job.png`}
        alt=""
        width="250"
        height="250"
      />
      <Typography
        component="h1"
        className={styles.message}
        variant="title"
        size="large"
      >
        Job not found.
      </Typography>
      <Typography
        component="p"
        variant="title"
        size="small"
        fontWeight={400}
        className={styles.info}
      >
        Return to the <RouterLink to="/">homepage</RouterLink> or contact us
        about the problem
      </Typography>
    </>
  );
};

export default JobNotFound;
