import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

import { useGetPostQuery } from "@js/apps/give-and-get-help/api";
import type { IPost } from "@js/types/give-and-get-help";
import { castToInteger } from "@js/utils";

export const useActivePost = ({
  posts,
  loadingPosts,
}: {
  posts: IPost[];
  loadingPosts: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const activePost = Number(searchParams.get("activePost"));
  const activePostId = castToInteger(activePost);

  const activePostDataInPosts = useMemo(() => {
    return !!activePostId && posts.find((post) => post.id === activePostId);
  }, [activePostId, posts]);

  const shouldUseActivePostData =
    !!activePostId && !activePostDataInPosts && !loadingPosts;

  const {
    data: activeSinglePostData,
    isFetching: isFetchingActivePost,
    isFetching: isLoadingActivePost,
    isError: isActivePostError,
  } = useGetPostQuery(
    shouldUseActivePostData ? { id: activePostId } : skipToken,
  );

  const postsToDisplay = useMemo(() => {
    if (activePostDataInPosts) {
      return getPostsWithActiveFirst(activePostDataInPosts, posts);
    }

    if (!shouldUseActivePostData || isActivePostError) {
      return posts;
    }

    if (!activeSinglePostData) {
      return [];
    }

    return getPostsWithActiveFirst(activeSinglePostData, posts);
  }, [
    posts,
    shouldUseActivePostData,
    activeSinglePostData,
    isActivePostError,
    activePostDataInPosts,
  ]);

  return {
    postsToDisplay,
    activePostId,
    isFetchingActivePost,
    isLoadingActivePost,
  };
};

const getPostsWithActiveFirst = (actviePostData: IPost, posts: IPost[]) => {
  return [
    actviePostData,
    ...posts.filter((post) => post.id !== actviePostData.id),
  ];
};
