import { Avatar, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  ArrowLeftIcon,
  ClockIcon,
  LocationPinIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useMessengerContext } from "@js/apps/messenger/context/messenger-context";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import { useGetLocalTime } from "@js/hooks";
import type { Participant } from "@js/types/messenger";

import { useMessagesContext } from "../../context";
import { ActionsMenu } from "../actions-menu";

import styles from "./styles.module.scss";

type MessageBoxHeaderProps = {
  participant: Participant;
  isReportedByUser: boolean;
};

export const MessageBoxHeader = ({
  participant,
  isReportedByUser,
}: MessageBoxHeaderProps) => {
  const { setActiveRoom } = useMessengerContext();
  const { inModal } = useMessagesContext();
  const isMobile = useMediaQuery("md");

  const participantLocalTime = useGetLocalTime(participant.timezone_offset);
  const shouldShowBackArrow = !inModal && isMobile && setActiveRoom;

  return (
    <div className={styles.messagesBoxHeader}>
      {shouldShowBackArrow && (
        <ArrowLeftIcon
          className={styles.messagesBoxBackIcon}
          onClick={() => !!setActiveRoom && setActiveRoom(undefined)}
        />
      )}

      <RouterLink to={participant.profile_url}>
        <Avatar
          size={isMobile ? "sm" : "md"}
          src={participant}
          className={styles.messagesBoxAvatar}
        />
      </RouterLink>
      <div className={styles.messagesBoxInfoWrapper}>
        <Typography
          RouterLink={RouterLink}
          component="p"
          onClick={() => ModalInstance.close()}
          to={participant.profile_url}
          variant={isMobile ? "paragraph" : "title"}
          size={isMobile ? "medium" : "small"}
          fontWeight={400}
          className={styles.messagesBoxTitle}
          style={{ textDecoration: "none", fontSize: "1rem" }}
          ellipsis
        >
          {participant.public_name}
        </Typography>
        <div className={styles.messagesBoxLocationBox}>
          {participant.location && (
            <div className={styles.messagesBoxLocationBoxItem}>
              <LocationPinIcon sx={{ fontSize: 16 }} /> {participant.location}
            </div>
          )}
          {participant.timezone_abbreviation && participant.timezone_offset && (
            <div className={styles.messagesBoxLocationBoxItem}>
              <ClockIcon sx={{ fontSize: 16 }} />
              {participantLocalTime} {`- ${participant.timezone_abbreviation}`}
            </div>
          )}
        </div>
      </div>
      <ActionsMenu user={participant} isReportedByUser={isReportedByUser} />
    </div>
  );
};
