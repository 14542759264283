import { type FC } from "react";

import { Box } from "@hexocean/braintrust-ui-components";

import { BTRSTCheckoutForm } from "./btrst-checkout-form";
import { HeaderSection } from "./header-section";
import { useProductCheckoutModalContext } from "./product-checkout-modal-context";
import { StripeCheckoutForm } from "./stripe-checkout-form";

export const ProductCheckoutModal: FC = () => {
  const { modeOfPayment } = useProductCheckoutModalContext();

  return (
    <Box
      padding={{ xs: 2.5, md: 4 }}
      display="flex"
      gap={2.5}
      flexDirection="column"
      height="100%"
    >
      <HeaderSection />
      <Box flex={1} display="flex">
        {modeOfPayment === "stripe" && <StripeCheckoutForm />}
        {modeOfPayment === "btrst" && <BTRSTCheckoutForm />}
      </Box>
    </Box>
  );
};
