import { closePostEtiquettePanel } from "@js/apps/freelancer/actions";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { useMarkCreatingPostAsVisitedMutation } from "../../api";

type Error = {
  status: number;
  data: {
    _error: string;
  };
};

export const useEtiquette = () => {
  const [markCreatePostAsVisited] = useMarkCreatingPostAsVisitedMutation();
  const user = useAppSelector(
    (state) => state.freelancer.freelancerProfile?.user,
  );
  const showEtiquettePanel = !user?.has_started_creating_post;
  const dispatch = useAppDispatch();
  const closeEtiquettePanel = async () => {
    markCreatePostAsVisited()
      .unwrap()
      .then(() => {
        dispatch(closePostEtiquettePanel());
      })
      .catch((error: Error) => console.error(error));
  };
  return { showEtiquettePanel, closeEtiquettePanel };
};
