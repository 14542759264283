export const EMAIL_VERIFIED = "EMAIL_VERIFIED";
export const REGISTERED = "REGISTERED";
export const FETCH_TIMEZONES_SUCCESS = "FETCH_TIMEZONES_SUCCESS";
export const FETCH_CURRENT_USER = "FETCH_CURRENT_USER";
export const FETCH_CURRENT_USER_SUCCESS = "FETCH_CURRENT_USER_SUCCESS";
export const FETCH_CURRENT_USER_FAILED = "FETCH_CURRENT_USER_FAILED";
// it is dispatched directly in few places across the app, need to be refactored
export const UPDATE_USER = "UPDATE_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const FETCH_PUBLIC_INVITATION_DETAILS =
  "FETCH_PUBLIC_INVITATION_DETAILS";
export const FETCH_PUBLIC_INVITATION_DETAILS_SUCCESS =
  "FETCH_PUBLIC_INVITATION_DETAILS_SUCCESS";
export const FETCH_PUBLIC_INVITATION_DETAILS_FAILED =
  "FETCH_PUBLIC_INVITATION_DETAILS_FAILED";
