import { FreelancerDashboardWelcomeLazy } from "../../lazy";
import { FreelancerDashboardLayout } from "../layout";

export const FreelancerDashboardWelcomePage = () => {
  return (
    <FreelancerDashboardLayout
      pageTitle="Welcome"
      pageColor="var(--soft-violet)"
    >
      <FreelancerDashboardWelcomeLazy />
    </FreelancerDashboardLayout>
  );
};
