import { useEffect } from "react";

import {
  setAutoOpenHelpOfferReviewsModal,
  setAutoOpenJobReviewsModal,
} from "@js/apps/reviews/slice";
import { showWriteReviewFormAfterOpen } from "@js/apps/reviews/slice";
import { useAppDispatch, useQueryParams } from "@js/hooks";
import type { EmployerPublicProfile } from "@js/types/employer";
import type { FreelancerPublic } from "@js/types/freelancer";

export const useAutomaticallyOpenWriteReviewModal = ({
  profile,
}: {
  profile: FreelancerPublic | EmployerPublicProfile;
}) => {
  const dispatch = useAppDispatch();
  const query = useQueryParams();

  const canOpenReviewModal = !!(
    profile.can_user_review_job || profile.review_count
  );

  const contentType: EnumType<typeof ENUMS.ReviewType> | undefined =
    query.content_type;

  useEffect(() => {
    switch (contentType) {
      case ENUMS.ReviewType.HELP_OFFER_REVIEW:
        if (query.write_review?.toLowerCase() === "true") {
          dispatch(setAutoOpenHelpOfferReviewsModal(true));
        }
        return;
      case ENUMS.ReviewType.COWORKER_REVIEW:
      case ENUMS.ReviewType.EMPLOYMENT_REVIEW:
      case ENUMS.ReviewType.WORK_REVIEW:
      case undefined:
        if (
          query.write_review?.toLowerCase() === "true" &&
          canOpenReviewModal
        ) {
          dispatch(setAutoOpenJobReviewsModal(true));
          dispatch(showWriteReviewFormAfterOpen(true));
        }
        return;
      default:
        contentType satisfies never;
        return;
    }
  }, [query.write_review, canOpenReviewModal, dispatch, contentType]);
};
