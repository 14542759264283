import React from "react";

import {
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@hexocean/braintrust-ui-components";
import {
  InstagramIcon,
  LinkedinSVGIcon,
  XIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { Logo } from "@js/components/logo";

import styles from "./styles.module.scss";

const RESOURCES_LIST = [
  {
    title: "Terms",
    url: SETTINGS.TERMS_DOC_URL,
  },
  {
    title: "Privacy",
    url: SETTINGS.PRIVACY_DOC_URL,
  },
  {
    title: "Cookie-policy",
    url: SETTINGS.COOKIE_POLICY_DOC_URL,
  },
  {
    title: "Network Dashboard",
    url: SETTINGS.NETWORK_DASHBOARD_URL,
  },
  {
    title: "Referral Program Terms",
    url: SETTINGS.REFERRAL_PROGRAM_TERMS_URL,
  },
  {
    title: "Referral Program Future Token Interest (FTI)",
    url: SETTINGS.REFERRAL_PROGRAM_FUTURE_TOKEN_INTEREST_URL,
  },
  {
    title: "Code of Conduct",
    url: SETTINGS.CODE_OF_CONDUCT_URL,
  },
  {
    title: "Network Standards",
    url: SETTINGS.NETWORK_STANDARDS_URL,
  },
];

export const AppLayoutFooter: React.FC = () => {
  const { spacing } = useTheme();

  return (
    <footer className={styles.appFooter}>
      <div className={styles.appFooterWrapper}>
        <Grid container spacing={3}>
          <Grid item lg={9} md={8} sm={6} xs={12}>
            <Logo />
            <Typography component="p" mt={3} mb={3}>
              We’re creating the future of work,
              <br />
              to benefit enterprise and talent alike.
            </Typography>
            <IconButton
              href={SETTINGS.LINKEDIN_URL}
              target="_blank"
              rel="noopener noreferrer"
              variant="tertiary"
              style={{ marginRight: spacing(2) }}
              size="medium"
              RouterLink={RouterLink}
              aria-label="Open linkedIn"
            >
              <LinkedinSVGIcon style={{ fontSize: 25 }} />
            </IconButton>
            <IconButton
              href={SETTINGS.TWITTER_URL}
              target="_blank"
              rel="noopener noreferrer"
              variant="tertiary"
              style={{ marginRight: spacing(2) }}
              size="medium"
              RouterLink={RouterLink}
              aria-label="Open Twitter"
            >
              <XIcon style={{ fontSize: 25 }} viewBox="-150 -150 1560 1595" />
            </IconButton>
            <IconButton
              href={SETTINGS.INSTAGRAM_URL}
              target="_blank"
              rel="noopener noreferrer"
              variant="tertiary"
              size="medium"
              RouterLink={RouterLink}
              aria-label="Open Instagram"
            >
              <InstagramIcon style={{ fontSize: 25 }} />
            </IconButton>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Typography component="h4" variant="label" size="medium" mb={1}>
              Resources
            </Typography>
            <ul className="list-reset">
              {RESOURCES_LIST.map(({ title, url }) => (
                <li key={title}>
                  <Typography
                    component="a"
                    to={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="label"
                    size="small"
                    RouterLink={RouterLink}
                  >
                    {title}
                  </Typography>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" size="small" mt={5}>
              The Braintrust Token is an ERC-20 token issued on the Ethereum
              blockchain network by the Braintrust Technology Foundation, a
              nonprofit foundation. Braintrust Tokens do not represent any right
              to or claim on the Braintrust network or any other person or
              entity, and has been adopted by the Braintrust network and users
              for various activities on the network only, such as for staking,
              governance, voting and payment purposes.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
};
