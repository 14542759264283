import { useMemo } from "react";

import {
  type BoxProps,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import type { PurchaseUpgradeParams } from "@js/apps/auth/api";

type UseHeaderSectionParams = {
  upgradeType: PurchaseUpgradeParams["upgrade_type"];
};

export const useHeaderSection = ({ upgradeType }: UseHeaderSectionParams) => {
  const isTablet = useMediaQuery("sm");

  const title = useMemo(
    () => `Application ${upgradeType}${upgradeType === "boost" ? "s" : ""}`,
    [upgradeType],
  );

  const subText = useMemo(() => {
    switch (upgradeType) {
      case "boost":
        return "Get to the top of the applicant list to increase your chances of getting hired.";

      case "insights":
        return "Identify promising opportunities and refine your job search with personalized feedback.";

      default:
        return "";
    }
  }, [upgradeType]);

  const backgroundColor = useMemo(() => {
    switch (upgradeType) {
      case "boost":
        return "var(--soft-blue)";

      case "insights":
        return "var(--soft-green)";

      default:
        return "";
    }
  }, [upgradeType]);

  const productImageSx: BoxProps["sx"] = useMemo(() => {
    switch (upgradeType) {
      case "boost":
        return {
          height: "auto",
          marginTop: "26px",
          textAlign: "center",
          "& img": {
            maxWidth: "500px",
          },
        };

      case "insights":
        return {
          height: "auto",
          width: "auto",
        };

      default:
        return {
          height: "auto",
          width: "auto",
        };
    }
  }, [upgradeType]);

  const productImage = useMemo(() => {
    switch (upgradeType) {
      case "boost":
        return `${SETTINGS.STATIC_URL}jobs/${isTablet ? "purchase-boost-description-mobile" : "purchase-boost-description"}.png`;

      case "insights":
        return `${SETTINGS.STATIC_URL}jobs/purchase-insights-description.png`;

      default:
        return "";
    }
  }, [isTablet, upgradeType]);

  const productBackgroundImage = useMemo(() => {
    switch (upgradeType) {
      case "boost":
        return `${SETTINGS.STATIC_URL}jobs/purchase-boost-bg.svg`;

      case "insights":
        return `${SETTINGS.STATIC_URL}jobs/purchase-insights-bg.svg`;

      default:
        return "";
    }
  }, [upgradeType]);

  const showBoostBanner = useMemo(() => upgradeType === "boost", [upgradeType]);

  return {
    backgroundColor,
    productBackgroundImage,
    productImage,
    productImageSx,
    showBoostBanner,
    subText,
    title,
  };
};
