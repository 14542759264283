import React, { forwardRef } from "react";
import type { LinkProps } from "react-router-dom";
import { Link as ReactRouterLink } from "react-router-dom";

import { useCurrentPath } from "@js/hooks/use-current-path";
import { trimURL } from "@js/utils/url";

export type RouterLinkProps = Omit<LinkProps, "to" | "onClick"> &
  React.RefAttributes<HTMLAnchorElement> & {
    href?: string;
    forceRemount?: boolean;
    activeClassName?: string;
    to?: LinkProps["to"];
    onClick?: (params?: any) => void;
    ref?: string;
    target?: string;
    rel?: string;
  };

export const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props: RouterLinkProps, ref) => {
    const {
      to,
      href,
      target,
      rel,
      forceRemount = false,
      onClick = () => {
        return undefined;
      },
      activeClassName,
      className,
      ...rest
    } = props;

    const calculatedProps = {
      to,
      onClick,
      href,
      target,
      rel,
      className,
    };

    // Required for some pages, that render links on wrapping route, and don't update them on location change
    const currentPath = useCurrentPath();
    const isActive =
      to && typeof to === "string" && trimURL(to) === trimURL(currentPath);
    if (!forceRemount && to && isActive) {
      calculatedProps.onClick = (e) => {
        e?.preventDefault();
        onClick?.();
      };
    }

    const AComponent = "a";
    let Component: "a" | typeof ReactRouterLink = ReactRouterLink;

    if (
      typeof href === "string" &&
      !(
        href.toLowerCase().startsWith("http://") ||
        href.toLowerCase().startsWith("https://") ||
        href.startsWith("mailto:")
      )
    ) {
      calculatedProps.to = href;
    } else if (href) {
      Component = AComponent;
      calculatedProps.href = href;
      delete calculatedProps.to;
    }

    if (
      to &&
      typeof to === "string" &&
      (to.toLowerCase().startsWith("http://") ||
        to.toLowerCase().startsWith("https://") ||
        to.startsWith("data:") ||
        to.startsWith("mailto:"))
    ) {
      Component = AComponent;
      calculatedProps.href = to;
      delete calculatedProps.to;
    }

    if (target === "_blank" && to && typeof to === "string") {
      Component = AComponent;
      calculatedProps.href = to;
      calculatedProps.target = target;
      calculatedProps.rel = "noopener";
    }

    if (isActive) {
      calculatedProps.className = [calculatedProps.className, activeClassName]
        .filter(Boolean)
        .join(" ");
    }

    if (Component === "a") {
      return <Component ref={ref} {...calculatedProps} {...rest} />;
    }

    return (
      <Component
        ref={ref}
        {...calculatedProps}
        to={calculatedProps.to ?? ""}
        {...rest}
        state={{
          prevPath: currentPath,
          ...props.state,
        }}
      />
    );
  },
);

type ConditionalRouterLinkProps = {
  noLink: boolean;
  children: React.ReactNode;
} & RouterLinkProps;

export const ConditionalRouterLink = ({
  noLink,
  children,
  ...props
}: ConditionalRouterLinkProps) => {
  if (noLink) {
    return children;
  }

  return <RouterLink {...props}>{children}</RouterLink>;
};
