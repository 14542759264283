import { useCompleteHelpOffer } from "@js/apps/common/hooks/use-help-offer/use-complete-help-offer";
import { useRequestHelpOfferApproval } from "@js/apps/common/hooks/use-help-offer/use-request-help-offer-approval";
import type { HelpOfferButtonsStatusProps } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/types";

import { HelpOfferButtonPrimary } from "./help-offer-button-primary";

export const HelpOfferButtonsAccepted = ({
  offer,
  isOfferAuthor,
}: HelpOfferButtonsStatusProps): JSX.Element | null => {
  const { handleCompleteOffer, isAccepting } = useCompleteHelpOffer({ offer });
  const { handleRequestApproval, isRequestingApproval } =
    useRequestHelpOfferApproval({ offer });

  const disableButtons = isRequestingApproval || isAccepting;

  return (
    <>
      <HelpOfferButtonPrimary
        onClick={isOfferAuthor ? handleRequestApproval : handleCompleteOffer}
        disabled={disableButtons}
      >
        Mark complete
      </HelpOfferButtonPrimary>
    </>
  );
};
