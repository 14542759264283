import { useEffect, useState } from "react";
import type { OptionsObject } from "notistack";
import { useSnackbar } from "notistack";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useTimer } from "@js/hooks/timer";

export const useInvitationNotificationManager = () => {
  const [isOpenFirstNotification, setIsOpenFirstNotification] = useState(false);
  const [isOpenSecondNotification, setIsOpenSecondNotification] =
    useState(false);
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { isTimerOn, resetTimer, startTimer } = useTimer(4000);

  const showNotification = (
    snackbarMessage: string,
    snackbarOptions: OptionsObject,
  ) => {
    const snackKey = enqueueSnackbar(snackbarMessage, {
      content: () => (
        <Box
          sx={{
            color: "var(--white)",
            opacity: 0.9,
            borderRadius: "10px",
            px: 2,
            py: 3,
            bgcolor: "var(--black)",
          }}
        >
          <Typography color="white" component="p" variant="paragraph">
            {snackbarMessage}
          </Typography>
        </Box>
      ),
      ...snackbarOptions,
    });

    return snackKey;
  };

  const notificationOptions: OptionsObject = {
    persist: true,
    transitionDuration: {
      enter: 300,
      exit: 300,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
  };

  useEffect(() => {
    if (isOpenFirstNotification && isTimerOn) {
      showNotification(
        `Nice work! Your invitation has been sent. 🎉`,
        notificationOptions,
      );
    }

    if (isOpenSecondNotification && isTimerOn) {
      showNotification(
        `Nice work! Your invitations have been sent. 🎉`,
        notificationOptions,
      );
    }

    if (!isTimerOn) {
      closeSnackbar();
      if (isOpenFirstNotification) setIsOpenFirstNotification(false);
      if (isOpenSecondNotification) setIsOpenSecondNotification(false);
    }

    return () => {
      closeSnackbar();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenFirstNotification, isOpenSecondNotification, isTimerOn]);

  const handleInviteNotification = () => {
    if (!isOpenFirstNotification && !isOpenSecondNotification) {
      startTimer();
      setIsOpenFirstNotification(true);
    }

    if (isOpenFirstNotification && !isOpenSecondNotification) {
      if (isTimerOn) resetTimer();
      if (isOpenFirstNotification) {
        closeSnackbar();
        setIsOpenFirstNotification(false);
      }
      setIsOpenSecondNotification(true);
    }

    if (!isOpenFirstNotification && isOpenSecondNotification) {
      if (isTimerOn) resetTimer();
    }
  };

  return {
    handleInviteNotification,
  };
};
