import { Typography } from "@hexocean/braintrust-ui-components";
import type { GetEmployerInvoiceStatisticsResponse } from "@js/apps/invoices/types";
import { pluralize } from "@js/utils";

import { EmployerInvoicesStatisticValue } from "./employer-invoices-statistic-value";
import { EmployerInvoicesStatisticWrapper } from "./employer-invoices-statistic-wrapper";

type EmployerInvoiceOverdueStatisticProps = {
  isLoading: boolean;
  data:
    | Pick<
        GetEmployerInvoiceStatisticsResponse,
        "overdue_amount" | "overdue_invoices"
      >
    | undefined;
};

export const EmployerInvoicesOverdueStatistic = ({
  isLoading,
  data,
}: EmployerInvoiceOverdueStatisticProps) => {
  const isOverdueHigherThanZero =
    !!data?.overdue_amount &&
    !isNaN(+data?.overdue_amount) &&
    +data?.overdue_amount > 0;

  const overdueInvoicesCount = data?.overdue_invoices?.length ?? 0;

  return (
    <EmployerInvoicesStatisticWrapper data-testid="overdue-statistics">
      <EmployerInvoicesStatisticValue
        isLoading={isLoading}
        value={data?.overdue_amount}
        label="Overdue invoices"
        sx={{
          color: isOverdueHigherThanZero ? "var(--negative-2)" : undefined,
        }}
        helperText={<HelperText overdueInvoicesCount={overdueInvoicesCount} />}
      />
    </EmployerInvoicesStatisticWrapper>
  );
};

const HelperText = ({
  overdueInvoicesCount,
}: {
  overdueInvoicesCount: number;
}) => {
  return (
    <Typography component="p" variant="paragraph" size="medium">
      {overdueInvoicesCount
        ? `Across ${overdueInvoicesCount} invoice${pluralize(overdueInvoicesCount)}`
        : "There are no overdue invoices"}
    </Typography>
  );
};
