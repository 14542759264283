import type { FC } from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";

import { SectionPanel } from "../utils";

import { ProfileArticle } from "./article";
import { FeaturedProfileArticle } from "./featured-article";
import { useArticles } from "./hooks";

export const NewsAndArticlesSection: FC = () => {
  const isMobile = useMediaQuery("sm");
  const isTablet = useMediaQuery("md");
  const showMobileLayout = isMobile || isTablet;
  const { articles, hasArticles } = useArticles();
  const [featuredArticle, ...otherArticles] = articles?.results ?? [];

  if (!hasArticles) {
    return <></>;
  }

  return (
    <SectionPanel
      title="Braintrust news and articles"
      linkName="View all"
      linkSize="large"
      link="https://www.usebraintrust.com/blog"
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        gap={showMobileLayout ? "16px" : "24px"}
        width="100%"
      >
        {!!featuredArticle ? (
          <>
            <Box width={1}>
              <FeaturedProfileArticle article={featuredArticle} />
            </Box>
            {otherArticles.map((article) => (
              <Box
                key={article.title}
                width={{
                  xs: "100%",
                  sm: "calc(50% - 16px)",
                  md: "100%",
                  lg: "calc(50% - 16px)",
                }}
              >
                <ProfileArticle article={article} />
              </Box>
            ))}
          </>
        ) : (
          <Box>No articles available</Box>
        )}
      </Box>
    </SectionPanel>
  );
};
