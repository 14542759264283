import { CreateOrEditPostModalContent } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal";
import { EditPostForm } from "@js/apps/give-and-get-help/form";
import { useCreateOrEditPost } from "@js/apps/give-and-get-help/hooks/create-or-edit-post";
import type { IPost } from "@js/types/give-and-get-help";

export type EditPostModalContentProps = {
  postId: number;
  initialValues: IPost;
};

export const EditPostModalContent = ({
  postId,
  initialValues,
}: EditPostModalContentProps) => {
  const { handleSubmit, onSubmitSuccess, mappedInitialValues } =
    useCreateOrEditPost(initialValues);

  const { poll_text, poll_options } = initialValues;
  const showPoll = poll_text || poll_options?.length;
  const linksEditOnClickActive = !!initialValues?.links_metadata.length;
  const disablePoll =
    !!initialValues?.poll_text && !!initialValues?.poll_votes_count;

  return (
    <EditPostForm
      onSubmit={handleSubmit}
      onSubmitSuccess={onSubmitSuccess}
      initialValues={mappedInitialValues}
    >
      <CreateOrEditPostModalContent
        editedPostId={postId}
        linksEditOnClickActive={linksEditOnClickActive}
        disablePoll={disablePoll}
        initialOpenedFields={showPoll ? ["poll"] : undefined}
      />
    </EditPostForm>
  );
};
