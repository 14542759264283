import type { UniversalTabValue } from "../../constants";
import type { UniversalSearchCurrentData } from "../../hooks/use-universal-search-form";

type EmptyStateTabs = {
  currentTabName: string;
  tabs: Array<{ value: UniversalTabValue; name: string }>;
};

export const EMPTY_STATE_TABS: Record<UniversalTabValue, EmptyStateTabs> = {
  CAREER_HELP: {
    currentTabName: "Career Help",
    tabs: [
      { value: "JOBS", name: "Jobs" },
      { value: "PEOPLE", name: "People" },
    ],
  },
  JOBS: {
    currentTabName: "Jobs",
    tabs: [
      { value: "CAREER_HELP", name: "Career Help" },
      { value: "PEOPLE", name: "People" },
    ],
  },
  PEOPLE: {
    currentTabName: "People",
    tabs: [
      { value: "JOBS", name: "Jobs" },
      { value: "CAREER_HELP", name: "Career Help" },
    ],
  },
};

export const TAB_TO_RESULTS_MAP: Record<
  UniversalTabValue,
  keyof UniversalSearchCurrentData
> = {
  CAREER_HELP: "postResults",
  PEOPLE: "talentResults",
  JOBS: "jobResults",
};
