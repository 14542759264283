import type { ReactElement } from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { TalentOfferStatus as TalentOfferStatusType } from "@js/types/jobs";

import { EmployerOfferStatus, TalentOfferStatus } from "../offer-status";

import style from "./style.module.scss";

const MAX_JOB_TITLE_LENGTH = 30;

export type EmployerOfferStatusWithJobTitleProps = {
  offerStatus: EnumType<typeof ENUMS.OfferStatus> | null;
  jobId: OfferStatusWithJobTitleProps["jobId"];
  jobTitle: OfferStatusWithJobTitleProps["jobTitle"];
  showTitle: boolean;
};

export const EmployerOfferStatusWithJobTitle = ({
  offerStatus,
  jobId,
  jobTitle,
  showTitle,
}: EmployerOfferStatusWithJobTitleProps) => {
  return (
    <OfferStatusWithJobTitle
      status={<EmployerOfferStatus offerStatus={offerStatus} />}
      jobId={jobId}
      jobTitle={jobTitle}
      showTitle={showTitle}
    />
  );
};

export type TalentOfferStatusWithJobTitleProps = {
  offerStatus: TalentOfferStatusType;
  jobId: OfferStatusWithJobTitleProps["jobId"];
  jobTitle: OfferStatusWithJobTitleProps["jobTitle"];
};

export const TalentOfferStatusWithJobTitle = ({
  offerStatus,
  jobId,
  jobTitle,
}: TalentOfferStatusWithJobTitleProps) => {
  return (
    <OfferStatusWithJobTitle
      status={<TalentOfferStatus offerStatus={offerStatus} />}
      jobId={jobId}
      jobTitle={jobTitle}
    />
  );
};

type OfferStatusWithJobTitleProps = {
  status: ReactElement;
  jobId: number;
  jobTitle: string;
  showTitle?: boolean;
};

export const OfferStatusWithJobTitle = ({
  status,
  jobId,
  jobTitle,
  showTitle = true,
}: OfferStatusWithJobTitleProps) => {
  const truncatedTitle =
    jobTitle.length > MAX_JOB_TITLE_LENGTH
      ? jobTitle.substring(0, MAX_JOB_TITLE_LENGTH) + "..."
      : jobTitle;

  return (
    <Box className={style.offerStatusWrapper}>
      {status}
      {showTitle && (
        <Box className={style.offerStatusWrapperJobTitle}>
          <Typography
            component="p"
            size="small"
            variant="label"
            style={{ textWrap: "nowrap" }}
          >
            for{" "}
          </Typography>
          <Typography
            component="link"
            variant="paragraph"
            to={`/jobs/${jobId}/`}
            target="_blank"
            ellipsis
            title={
              jobTitle.length > MAX_JOB_TITLE_LENGTH ? jobTitle : undefined
            }
            RouterLink={RouterLink}
          >
            {truncatedTitle}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
