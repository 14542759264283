import { useState } from "react";

import { useGetSpacesQuery } from "@js/apps/spaces/api";

const PAGE_SIZE = 10;
const INITIAL_PAGE = 1;

export const useSpacesList = () => {
  const { spaces, fetchMore, isLoading, hasMore } = useSpacesInfiniteLoading();

  return {
    spaces,
    isLoading,
    fetchMore,
    hasMore,
  };
};

const useSpacesInfiniteLoading = () => {
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
  const { data: spaces, isLoading } = useGetSpacesQuery(
    { page: currentPage },
    {
      refetchOnFocus: false,
      refetchOnMountOrArgChange: false,
      refetchOnReconnect: false,
    },
  );

  const fetchMore = () => {
    const currentCount = spaces?.results?.length ?? 0;
    const totalCount = spaces?.count ?? 0;

    if (!spaces || currentCount >= totalCount) {
      return;
    }

    const nextPage = Math.floor(spaces.results.length / PAGE_SIZE) + 1;
    setCurrentPage(nextPage);
  };

  const hasMore = !!spaces?.next;

  return {
    spaces: spaces?.results,
    isLoading,
    fetchMore,
    hasMore,
  };
};
