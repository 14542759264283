import { useEffect } from "react";

import { useWebsocket } from "@js/hooks";

import { useMySpaces } from "../use-my-spaces";

export const useMySpacesSubscribe = () => {
  const { send, isRunning } = useWebsocket();
  const { spacesIds } = useMySpaces();

  useEffect(() => {
    if (isRunning && spacesIds.length > 0) {
      send(ENUMS.StreamType.SPACES_SUBSCRIBE, { spaces_ids: spacesIds });
    }
  }, [isRunning, send, spacesIds]);

  useEffect(() => {
    if (isRunning) {
      send(ENUMS.StreamType.MY_SPACES_LIST_SUBSCRIBE);
    }
  }, [isRunning, send]);
};
