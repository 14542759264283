import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import type { EnhanceElementProps } from "@hexocean/braintrust-ui-components";
import { Box, Button } from "@hexocean/braintrust-ui-components";
import { MenuArrowDownIcon } from "@hexocean/braintrust-ui-components/Icons";
import { DollarNumberField, MenuSelectField } from "@js/forms/fields";
import { enumToOptions } from "@js/utils";

import { JOB_PAYMENT_TYPE_LABELS } from "../../constants";
import type { PaymentTypeObject } from "../../types";

type AmountAndPaymentTypeFieldProps = {
  payment_amount: TypedWrappedFieldProps<string>;
  payment_type: TypedWrappedFieldProps<EnumType<typeof ENUMS.JobPaymentType>>;
  radius?: string | boolean | undefined;
};

const PLACEHOLDERS: PaymentTypeObject = {
  [ENUMS.JobPaymentType.HOURLY]: "100",
  [ENUMS.JobPaymentType.ANNUAL]: "100,000",
  [ENUMS.JobPaymentType.FIXED_PRICE]: "10,000",
} as const;

type AnchorProps = {
  anchorProps: EnhanceElementProps;
  isOpen: boolean;
};

const renderAnchor = (
  { anchorProps, isOpen }: AnchorProps,
  paymentType: TypedWrappedFieldProps<EnumType<typeof ENUMS.JobPaymentType>>,
) => {
  const {
    "aria-controls": ariaControls,
    "aria-haspopup": ariaHasPopup,
    ...props
  } = anchorProps;

  return (
    <Button
      aria-controls={ariaControls}
      aria-haspopup={ariaHasPopup}
      style={{ padding: "6px 8px" }}
      shape="squared"
      variant="transparent-with-text-hover"
      {...props}
    >
      {JOB_PAYMENT_TYPE_LABELS[paymentType.input.value]}
      <Box
        sx={{
          ml: 1,
          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
        }}
      >
        <MenuArrowDownIcon />
      </Box>
    </Button>
  );
};

export const AmountAndPaymentTypeField = ({
  payment_amount,
  payment_type,
  radius,
}: AmountAndPaymentTypeFieldProps) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Box sx={{ borderRadius: "9px" }} width={138}>
        <DollarNumberField
          id="pay-rate-id"
          size="large"
          radius={radius}
          placeholder={PLACEHOLDERS[payment_type.input.value]}
          hideError
          InputProps={{
            style: { boxShadow: `var(--elevation-4)`, borderRadius: "9px" },
          }}
          {...payment_amount}
        />
      </Box>
      <MenuSelectField
        {...payment_type}
        menuItemClassName="menu-item--violet"
        disableMenuItemRipple
        type="radio"
        anchor={(props) => renderAnchor(props, payment_type)}
        options={enumToOptions(JOB_PAYMENT_TYPE_LABELS)}
      />
    </Box>
  );
};
