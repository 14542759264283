import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import type { FreelancerStatsSummaryType } from "@js/types/dashboard";

import {
  ReferralEmptyState,
  ReferralsStatsContainer,
  ReferralsStatsListContainer,
  ReferralsStatsSummaryContainer,
  ReferralsStatsSummaryMainSection,
} from "../referral-stats";

import {
  TalentReferralsList,
  type TalentReferralsListProps,
} from "./talent-referrals-list";
import { TalentReferralsStatsSummary } from "./talent-referrals-stats-summary";

type TalentReferralsProps = {
  summary: FreelancerStatsSummaryType;
} & TalentReferralsListProps;

export const TalentReferrals = ({
  summary,
  referrals,
  fetchMore,
  loading,
  filters,
}: TalentReferralsProps) => {
  const showSummaryEmptyState = !summary.referred_count;
  const showReferralsList = !!summary.referred_count;

  return (
    <ReferralsStatsContainer color="green">
      <ReferralsStatsSummaryContainer>
        <ReferralsStatsSummaryMainSection
          title="Your talent referrals"
          totalBalance={String(summary.total_earned)}
          tooltipText="Total BTRST you have earned from referring talent."
        />
        <Box
          sx={{
            mr: !showSummaryEmptyState ? "20px" : undefined,
            flexGrow: { xs: 1, md: 0 },
          }}
        >
          <TalentReferralsStatsSummary summary={summary} />
        </Box>

        {showSummaryEmptyState && (
          <ReferralEmptyState
            bgColor="var(--medium-green)"
            title="You haven’t referred any Talent, yet."
            description="Grab your referral link above and start inviting Talent to
              Braintrust. All the BTRST you earn from your Talent referral
              activities will be tracked here."
          />
        )}
      </ReferralsStatsSummaryContainer>
      {showReferralsList && (
        <ReferralsStatsListContainer dividerColor="var(--beige)">
          <TalentReferralsList
            fetchMore={fetchMore}
            loading={loading}
            referrals={referrals}
            filters={filters}
          />
        </ReferralsStatsListContainer>
      )}
    </ReferralsStatsContainer>
  );
};
