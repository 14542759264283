import { lazyWithRetry } from "@js/utils";

import type { PhoneFieldProps } from "./phone-field";

const PhoneField = lazyWithRetry<PhoneFieldProps>(async () => {
  const module = await import(
    /* webpackChunkName: "formatted-phone-number" */ "./phone-field"
  );

  return { default: module.PhoneField };
});

export { PhoneField };
