import { Box, Typography } from "@hexocean/braintrust-ui-components";

import type { UseUniversalSearchEmptyState } from "../../hooks/use-universal-search-empty-state";
import { useUniversalSearchEmptyState } from "../../hooks/use-universal-search-empty-state";
import { useUniversalSearchPaused } from "../../universal-search-slice";
import { UniversalSearchLoader } from "../universal-search-loader";

import { UniversalSearchEmptyStateInitial } from "./universal-search-empty-state-initial";
import { UniversalSearchEmptyStateOtherTabs } from "./universal-search-empty-state-other-tabs";

type UniversalSearchEmptyStateProps = UseUniversalSearchEmptyState;

export const UniversalSearchEmptyState = ({
  ...useUniversalSearchArg
}: UniversalSearchEmptyStateProps) => {
  const searchPaused = useUniversalSearchPaused();
  const { tabsWithData, emptyStateSettings, isLoading, currentData } =
    useUniversalSearchEmptyState(useUniversalSearchArg);

  if (isLoading) {
    return <UniversalSearchLoader />;
  }

  if (!tabsWithData.length) {
    return <NoResults />;
  }

  if (!searchPaused) {
    return (
      <UniversalSearchEmptyStateInitial
        currentTabName={emptyStateSettings.currentTabName}
        tabsWithData={tabsWithData}
      />
    );
  }

  return (
    <UniversalSearchEmptyStateOtherTabs
      tabsWithData={tabsWithData}
      currentData={currentData}
      currentTabName={emptyStateSettings.currentTabName}
    />
  );
};

const NoResults = () => {
  return (
    <Box sx={{ display: "grid", placeItems: "center", minHeight: "5rem" }}>
      <Typography
        variant="paragraph"
        size="large"
        component="span"
        color="grey-2"
      >
        No results
      </Typography>
    </Box>
  );
};
