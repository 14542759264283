import { useCallback } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { sortBySuperpower } from "@js/apps/skills/fields/helpers";

import { MAX_TOP_SKILLS_COUNT } from "../const";
import { SelectedSkillsList } from "../selected-skills-list";
import type { SkillListItem } from "../selected-skills-list/item";

type TalentProfileSelectedSkillsListProps = TypedWrappedFieldProps<
  SkillListItem[]
> & {
  error?: string;
};

export const TalentProfileSelectedSkillsList = (
  props: TalentProfileSelectedSkillsListProps,
) => {
  const { input, error } = props;
  const { handleFavorite, handleRemove, handleReorder } =
    useTalentProfileSelectedSkillsList({ input });

  const selectedSkillsCount = input.value.length;

  return (
    <SelectedSkillsList
      error={error}
      selectedSkillsCount={selectedSkillsCount}
      handleFavorite={handleFavorite}
      handleRemove={handleRemove}
      handleReorder={handleReorder}
      options={input.value}
      maxTopSkillsCount={MAX_TOP_SKILLS_COUNT.talent_profile}
    />
  );
};

const useTalentProfileSelectedSkillsList = ({
  input,
}: {
  input: TypedWrappedFieldProps<SkillListItem[]>["input"];
}) => {
  const handleChangeWithSort = useCallback(
    (value: Array<SkillListItem>) => {
      const newValue = [...value].sort(sortBySuperpower);
      return input.onChange(newValue);
    },
    [input],
  );

  const handleFavorite = useCallback(
    (selectedSkill: SkillListItem) => {
      handleChangeWithSort(
        input.value.map((item) => {
          if (item.skill.id === selectedSkill.skill.id) {
            return {
              ...item,
              is_superpower: !item.is_superpower,
            };
          }

          return item;
        }),
      );
    },
    [input, handleChangeWithSort],
  );

  const handleRemove = useCallback(
    (selectedSkill: SkillListItem) => {
      handleChangeWithSort(
        input.value.filter(
          (skill) => skill.skill.id !== selectedSkill.skill.id,
        ),
      );
    },
    [input, handleChangeWithSort],
  );

  const handleReorder = useCallback(
    (value: SkillListItem[]) => {
      handleChangeWithSort(value);
    },
    [handleChangeWithSort],
  );

  return {
    handleFavorite,
    handleRemove,
    handleReorder,
  };
};
