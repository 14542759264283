import { useMemo } from "react";

import { useGetCompanyNodesIdAndNameOnlyQuery } from "@js/apps/admin/api";
import type { CreateEmployerInvitationPayload } from "@js/apps/employer/api";
import { useCreateEmployerInvitationMutation } from "@js/apps/employer/api";

export const useCreateNewEmployer = () => {
  const { data: companyNodes } = useGetCompanyNodesIdAndNameOnlyQuery();
  const [createInvitationMutation] = useCreateEmployerInvitationMutation();

  const createEmployerInvitation = (data: CreateEmployerInvitationPayload) => {
    return createInvitationMutation(data).unwrap();
  };

  const companyNodeOptions = useMemo(() => {
    return companyNodes?.map((node) => ({
      label: node.name,
      value: node.id,
    }));
  }, [companyNodes]);

  return {
    companyNodeOptions,
    createEmployerInvitation,
  };
};
