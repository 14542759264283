import {
  useGetUniversalSearchJobsQuery,
  useGetUniversalSearchPostsQuery,
  useGetUniversalSearchTalentQuery,
} from "@js/apps/universal-search/api";

import type { UniversalTabValue } from "../constants";
import { useDebouncedUniversalSearchPhrase } from "../universal-search-slice";

export const useUniversalSearchSearches = ({
  currentTab,
  open,
}: {
  currentTab: UniversalTabValue;
  open: boolean;
}) => {
  const search = useDebouncedUniversalSearchPhrase();
  const commonSkip = !search || !open;
  const {
    data: jobResults,
    isLoading: isLoadingJobsResult,
    isFetching: isFetchingJobsResult,
  } = useGetUniversalSearchJobsQuery(
    { search },
    { skip: commonSkip || currentTab !== "JOBS" },
  );
  const {
    data: postResults,
    isLoading: isLoadingPostsResult,
    isFetching: isFetchingPostsResult,
  } = useGetUniversalSearchPostsQuery(
    { search },
    { skip: commonSkip || currentTab !== "CAREER_HELP" },
  );
  const {
    data: talentResults,
    isLoading: isLoadingTalentResult,
    isFetching: isFetchingTalentResult,
  } = useGetUniversalSearchTalentQuery(
    { search },
    { skip: commonSkip || currentTab !== "PEOPLE" },
  );

  return {
    searchData: {
      jobResults,
      postResults,
      talentResults,
    },
    isLoadingSearchData:
      isLoadingJobsResult || isLoadingPostsResult || isLoadingTalentResult,
    isFetchingSearchData:
      isFetchingJobsResult || isFetchingPostsResult || isFetchingTalentResult,
  };
};
