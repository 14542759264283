import { type ConfigProps, SubmissionError } from "redux-form";

import type { AddNoteFormData } from "@js/apps/admin/forms";
import type { AddNoteArgs, DeleteNoteArgs } from "@js/apps/admin/types";
import { Modal, ModalConfirmAsync } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type { AppDispatch } from "@js/store";
import type { NoteItem } from "@js/types/admin";

export const DeleteNoteModalInstance = Modal("delete-note-modal", {
  closeButton: false,
});

type UseAddOrDeleteNotes = (
  addNote: (value: AddNoteArgs) => Promise<NoteItem>,
  deleteNote: (value: DeleteNoteArgs) => Promise<void>,
) => {
  openDeleteNoteModal(
    note: NoteItem,
    targetName: EnumType<typeof ENUMS.NoteContentType>,
  ): void;
  onSubmit(values: AddNoteFormData): void;
  onSubmitSuccess: ConfigProps<AddNoteFormData>["onSubmitSuccess"];
};

export const useAddOrDeleteNotes: UseAddOrDeleteNotes = (
  addNote,
  deleteNote,
) => {
  const openDeleteNoteModal = (
    note: NoteItem,
    targetName: EnumType<typeof ENUMS.NoteContentType>,
  ) =>
    DeleteNoteModalInstance.open({
      children: (
        <ModalConfirmAsync
          confirmText="Delete Comment"
          onCancel={DeleteNoteModalInstance.close}
          onConfirm={async () => {
            await deleteNote({
              id: note.id,
              target_name: targetName,
            })
              .then(() => Snackbar.success("Comment successfully deleted."))
              .catch(() => {
                Snackbar.error("Something went wrong...");
              });
            DeleteNoteModalInstance.close();
          }}
        >
          Are you sure you want to delete the comment?
        </ModalConfirmAsync>
      ),
    });

  const onSubmit = (values: AddNoteFormData) => {
    addNote({
      content: values.content,
      target: {
        object_name: values.objectName,
        object_id: values.id,
      },
    }).catch((error) => {
      throw new SubmissionError(error.data);
    });
  };

  const onSubmitSuccess = (
    _result: null,
    _dispatch: AppDispatch,
    props: { reset: () => void },
  ) => {
    props.reset();
    Snackbar.success("Comment successfully added.");
  };

  return {
    openDeleteNoteModal,
    onSubmit,
    onSubmitSuccess,
  };
};
