import React from "react";
import ReactDOM from "react-dom";

import { SiteNotificationButton } from "@js/apps/notifications/components/site-notification-button";
import { useLiveSiteNotifications } from "@js/apps/notifications/hooks/use-live-site-notifications";

import { SiteNotificationItem } from "../site-notification";

export const LiveSiteNotifications = () => {
  const { hideNotification, classNames, notificationsToDisplay } =
    useLiveSiteNotifications();
  const portalsElement = document.getElementById("portals");

  if (!portalsElement) return null;

  return ReactDOM.createPortal(
    <div className="live-site-notifications">
      {notificationsToDisplay.map((siteNotification) => {
        return (
          <SiteNotificationItem
            live
            key={siteNotification.id}
            notification={siteNotification}
            closeNotificationsPopup={() =>
              hideNotification(siteNotification.id)
            }
            className={classNames[siteNotification.id]}
          >
            {!!siteNotification.buttons.length && (
              <div className="right live-site-notification-buttons">
                {siteNotification.buttons.map((button) => (
                  <SiteNotificationButton
                    button={button}
                    key={button.id}
                    context={siteNotification.context}
                    hideNotification={hideNotification}
                  />
                ))}
              </div>
            )}
          </SiteNotificationItem>
        );
      })}
    </div>,
    portalsElement,
  );
};
