import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { Snackbar } from "@js/components/snackbar";

type LimitCategory = "LOCATIONS" | "TIMEZONES";

export const useLocationAndTimezoneNotification = () => {
  const isMobile = useMediaQuery(960);

  const showLimitNotification = (
    limitCategory: LimitCategory,
    limit: number,
  ) => {
    const category = limitCategory === "LOCATIONS" ? "locations" : "timezones";
    const message = `🙈 The limit is ${limit} ${category}`;

    Snackbar.custom(message, {
      content: () => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={isMobile ? "450px" : "800px"}
        >
          <Box
            sx={{
              color: "var(--white)",
              opacity: 0.9,
              borderRadius: "10px",
              px: 2,
              py: 3,
              bgcolor: "var(--black)",
            }}
          >
            <Typography color="white" component="p" variant="paragraph">
              {message}
            </Typography>
          </Box>
        </Box>
      ),
      transitionDuration: {
        enter: 300,
        exit: 300,
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  };

  return {
    showLimitNotification,
  };
};
