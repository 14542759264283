import { useMemo } from "react";

import { useReactionsDrawerData } from "../store/stickers-module-slice";

export const useReactionsData = () => {
  const { entityReactions = [], selectedReactionValue } =
    useReactionsDrawerData() ?? {};

  const nonZeroCountReactions = useMemo(
    () => entityReactions.filter(({ count }) => count > 0),
    [entityReactions],
  );

  const processedSelectedReaction = useMemo(() => {
    if (!nonZeroCountReactions.length) {
      return;
    }

    const selected = nonZeroCountReactions.find(
      ({ sticker }) => sticker === selectedReactionValue,
    );
    if (selected) {
      return selected;
    }

    return nonZeroCountReactions[0];
  }, [selectedReactionValue, nonZeroCountReactions]);

  return {
    selectedReaction: processedSelectedReaction,
    reactions: nonZeroCountReactions,
  };
};
