import { useState } from "react";

import { Button, Menu } from "@hexocean/braintrust-ui-components";
import { StyledMenuItem } from "@js/apps/common/components/filters/components";

import type { ReferralsSortButtonColorVariantsType } from "./referrals-sort-button";

export type ReferralsSortByButtonAppearanceProps = {
  colorVariant: ReferralsSortButtonColorVariantsType;
};

type ReferralsSortByButtonFunctionalProps<T> = {
  onChange: (key: T) => void;
  options: ReadonlyArray<{ label: string; value: T }>;
  currentValue: { label: string; value: T };
};

export const ReferralsSortByButton = <T,>({
  onChange,
  options,
  currentValue,
  colorVariant,
}: ReferralsSortByButtonAppearanceProps &
  ReferralsSortByButtonFunctionalProps<T>) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  return (
    <Menu
      className="referrals-sort-menu"
      anchor={
        <Button
          variant="transparent"
          onClick={() => setIsMenuExpanded(true)}
          fontWeight={400}
          sx={{ whiteSpace: "nowrap", px: "8px!important" }}
        >
          <span>
            Sort by <b>{currentValue.label}</b>
          </span>
        </Button>
      }
      minMenuWidth="172px"
      open={isMenuExpanded}
      onClose={() => setIsMenuExpanded(false)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {options.map((option) => {
        const { value, label } = option;
        return (
          <StyledMenuItem
            key={value as string}
            selected={value === currentValue.value}
            onClick={() => {
              onChange(value);
              setIsMenuExpanded(false);
            }}
            colorVariant={colorVariant}
          >
            {label}
          </StyledMenuItem>
        );
      })}
    </Menu>
  );
};
