import { useGetRelevantPostsQuery } from "@js/apps/give-and-get-help/api";
import type { IPost } from "@js/types/give-and-get-help";

export const useDashboardPosts = (): {
  relevantPosts: IPost[];
  loading: boolean;
} => {
  const { data: relevantPosts, isFetching: loading } =
    useGetRelevantPostsQuery();

  return {
    relevantPosts: relevantPosts || [],
    loading,
  };
};
