import type { RefObject } from "react";
import React from "react";

import { styled } from "@hexocean/braintrust-ui-components";

import { BookmarksIntroModal } from "../bookmarks-intro-modal";
import { useBookmarksIntroduce } from "../bookmarks-introduce/use-bookmarks-introduce";

import modalStyle from "../bookmarks-intro-modal/styles.module.scss";

export type BookmarksIntroduceProps = {
  anchorRef: RefObject<HTMLButtonElement>;
};

export const BookmarksIntroduce = ({ anchorRef }: BookmarksIntroduceProps) => {
  const { hasBookmarksIntroduced, handleClose, modalPosition, bookmarkAdded } =
    useBookmarksIntroduce({ anchorRef });

  if (hasBookmarksIntroduced || !bookmarkAdded) return null;

  return (
    <StyledModalWrapper position={modalPosition}>
      <BookmarksIntroModal onClose={handleClose} />
    </StyledModalWrapper>
  );
};

type StyledModalWrapperProps = {
  position: {
    top: string;
    right: string;
  };
};

export const StyledModalWrapper = styled("div")<StyledModalWrapperProps>(
  ({ position }) => ({
    [`& .${modalStyle.modal}`]: {
      position: "absolute",
      top: position.top,
      right: position.right,
    },
  }),
);
