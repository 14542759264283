import type { WrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box, Button } from "@hexocean/braintrust-ui-components";
import { LongArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useFormErrors } from "@js/forms/hooks";

export type ReviewJobsModalHeaderProps = {
  handleSkipClick: () => void;
  handleBackClick: () => void;
  noDraftsToPost: boolean;
};

export const ReviewJobsModalHeader = ({
  handleBackClick,
  handleSkipClick,
  noDraftsToPost,
}: ReviewJobsModalHeaderProps) => {
  return (
    <Box
      display="flex"
      py={2.5}
      px={4}
      borderBottom="1px solid var(--grey-4)"
      alignItems="center"
      justifyContent="space-between"
    >
      <Button
        variant="transparent"
        shape="squared"
        onClick={handleBackClick}
        sx={{ display: "grid", placeItems: "center" }}
        startIcon={<LongArrowLeftIcon />}
      >
        Back
      </Button>
      <Box>
        <Button
          variant="secondary"
          shape="squared"
          onClick={handleSkipClick}
          disabled={noDraftsToPost}
        >
          Skip
        </Button>
        <Field
          name=""
          component={PostJobButton}
          noDraftsToPost={noDraftsToPost}
        />
      </Box>
    </Box>
  );
};

export const PostJobButton = ({
  meta,
  noDraftsToPost,
}: WrappedFieldProps & {
  noDraftsToPost: boolean;
}) => {
  const { errors } = useFormErrors(meta);
  const isAnyError = Object.keys(errors).length > 0;

  return (
    <Button
      disabled={isAnyError || noDraftsToPost}
      variant="positive"
      type="submit"
      shape="squared"
      sx={{ ml: "8px" }}
    >
      Post job
    </Button>
  );
};
