import type { ChangeEventHandler, ReactElement } from "react";
import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Box, Button } from "@hexocean/braintrust-ui-components";
import { MessageInput } from "@js/apps/jobs/apps/bids/components/make-offer-message/input";
import { Form } from "@js/forms/components/form";
import { reduxForm } from "@js/forms/fields";
import { shouldDisplayError } from "@js/forms/utils";

import style from "./style.module.scss";
export type SendMessageFormValues = { message: string };

type ExtendedSendMessageFormProps = {
  ButtonProps?: ButtonProps;
  minRows?: number;
  additionalButtons?: ReactElement[];
  bulkRejection?: boolean;
  onSubmit: (values: SendMessageFormValues) => void;
};

export const BidSendMessage = reduxForm<
  SendMessageFormValues,
  ExtendedSendMessageFormProps
>()(({
  submitting,
  submit,
  ButtonProps,
  minRows,
  additionalButtons,
  bulkRejection,
}) => {
  return (
    <Box
      component={Form}
      onSubmit={submitting ? null : submit}
      display="flex"
      flexDirection="column"
    >
      {!bulkRejection && (
        <Field
          name="message"
          minRows={minRows}
          maxLength={600}
          component={InputBaseField}
          disabled={submitting}
        />
      )}
      <Box mt={bulkRejection ? 0 : 2} display="flex" columnGap={1}>
        <Button
          shape="squared"
          variant="medium-violet"
          className={style.messageModalBtn}
          disabled={submitting}
          {...ButtonProps}
        />
        {additionalButtons?.map((button) => {
          return React.cloneElement(button, { key: button.key });
        })}
      </Box>
    </Box>
  );
});

type InputBaseFieldProps = TypedWrappedFieldProps<string> & {
  minRows?: number;
  disabled?: boolean;
};

const InputBaseField = ({
  input,
  meta,
  minRows = 6,
  disabled,
}: InputBaseFieldProps) => {
  const displayError = shouldDisplayError({ input, meta });

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    input.onChange(event.target.value);
  };

  return (
    <MessageInput
      value={input.value}
      error={meta.error}
      displayError={displayError}
      disabled={disabled}
      minRows={minRows}
      onChange={handleChange}
    />
  );
};
