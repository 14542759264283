import { useEffect, useState } from "react";
import type { WrappedFieldProps } from "redux-form";

import type {
  ComboBoxProps,
  TextFieldProps,
} from "@hexocean/braintrust-ui-components";
import { TextField } from "@hexocean/braintrust-ui-components";
import { ComboBox } from "@hexocean/braintrust-ui-components";
import type { UserSearchParams, UserSearchResult } from "@js/apps/auth/api";
import {
  useGetUserSearchItemQuery,
  useGetUserSearchQuery,
} from "@js/apps/auth/api";

import { useSearchPhrase } from "../../hooks/search-phrase";
import { useSynchronizeSingleValueInternalStateWithFormValue } from "../../hooks/synchronize-with-form-value";

type UserSearchComboboxFieldProps = WrappedFieldProps &
  Partial<Omit<ComboBoxProps<UserSearchResult, false>, "component">> & {
    label?: string;
    endpointParams?: Partial<UserSearchParams>;
    variant?: TextFieldProps["variant"];
  };

export const UserSearchComboboxField = ({
  input,
  meta,
  label,
  endpointParams,
  variant = "standard",
  ...props
}: UserSearchComboboxFieldProps) => {
  const [value, setValue] = useState<UserSearchResult | null>(null);
  const { onInputChange, searchPhrase } = useSearchPhrase();

  const { data: options } = useGetUserSearchQuery({
    search: searchPhrase,
    ...endpointParams,
  });

  const { data: initialItem, isLoading: initialTaxonomyLoading } =
    useGetUserSearchItemQuery(Number(meta.initial), {
      skip: meta.dirty || !meta.initial || !!value, // "dirty" is cleared with every search so we need to rely on "value" too
    });

  useSynchronizeSingleValueInternalStateWithFormValue({
    initialTaxonomyLoading,
    formValue: input.value,
    isValueEqualFormValue: input.value === value?.id,
    findOption: (option) => {
      return option.id === input.value;
    },
    setValue,
    options,
  });

  useEffect(() => {
    if (initialItem) {
      setValue(initialItem);
    }
  }, [initialItem]);

  return (
    <ComboBox<UserSearchResult, false>
      initialTaxonomiesLoading={initialTaxonomyLoading}
      options={options || []}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant={variant}
            label={label || "User"}
            error={!!meta.error}
            helperText={meta.error}
          />
        );
      }}
      onInputChange={onInputChange}
      value={value}
      onChange={(_ev, valueArg) => {
        setValue(valueArg);
        input.onChange(valueArg?.id || null);
      }}
      getOptionLabel={(option) => option.name_and_email}
      {...props}
    />
  );
};
