import React from "react";
import cs from "classnames";

import { Box } from "@hexocean/braintrust-ui-components";
import {
  ClearIcon,
  EditPencilIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import styles from "./styles.module.scss";

type AttachmentActionsWrapperProps = {
  onRemoveClick: (() => void) | undefined;
  onEditLinkClick?: () => void;
  closeButtonAlwaysVisible?: boolean;
  showBorder?: boolean;
  roundedBorder?: boolean;
  isFromText?: boolean;
};

export const AttachmentActionsWrapper: React.FC<
  React.PropsWithChildren<AttachmentActionsWrapperProps>
> = ({
  onRemoveClick,
  onEditLinkClick,
  closeButtonAlwaysVisible,
  showBorder,
  roundedBorder,
  children,
  isFromText,
}) => {
  const showClearIcon = !!onRemoveClick;
  return (
    <Box
      className={cs(styles.wrapper, {
        [styles["wrapper-border"]]: showBorder,
        [styles["rounded"]]: roundedBorder,
        [styles["editMode"]]: onEditLinkClick && !isFromText,
      })}
    >
      {children}
      {showClearIcon && (
        <ClearIcon
          onClick={onRemoveClick}
          data-testid="remove-attachment-button"
          className={cs(styles.closeIcon, {
            [styles["close-visible"]]: closeButtonAlwaysVisible,
          })}
        />
      )}

      {onEditLinkClick && !isFromText && (
        <EditPencilIcon onClick={onEditLinkClick} className={styles.editIcon} />
      )}
    </Box>
  );
};
