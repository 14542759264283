import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { getError, shouldDisplayError } from "@js/forms/utils";
import type { Job } from "@js/types/jobs";

import { RateFieldPanel } from "./components";
import {
  getYesOrCustomPaymentAmountOptions,
  PaymentAmountCustomField,
  renderDefaultPopoverContent,
} from "./payment-amount-field";

type DirectHireJobBidRateFieldProps = {
  title: string;
  description: string;
  job: Job;
  id: string;
} & TypedWrappedFieldProps<string>;

export const DirectHireJobBidRateField = ({
  title,
  description,
  job,
  ...props
}: DirectHireJobBidRateFieldProps) => {
  const options = getYesOrCustomPaymentAmountOptions(job.budget_minimum_usd);
  const displayError = shouldDisplayError(props);

  return (
    <RateFieldPanel
      title={title}
      description={description}
      displayError={displayError}
      error={getError(props)}
    >
      <PaymentAmountCustomField
        options={options}
        renderPopoverContent={() =>
          renderDefaultPopoverContent(job.payment_type)
        }
        renderLabel={{
          initial: "No, my rate is...",
          selected: (value) => `No, my rate is ${value}`,
        }}
        {...props}
      />
    </RateFieldPanel>
  );
};
