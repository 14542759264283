import { Modal } from "@js/components/modal";

import styles from "./style.module.scss";

const WITHDRAWAL_MODAL_ID = "withdrawal-modal";

export const WithdrawalModalInstance = Modal(WITHDRAWAL_MODAL_ID, {
  className: `${styles.modal} overflow-initial`,
  keepOnBackdropClick: true,
  closeButton: false,
  padding: false,
});
