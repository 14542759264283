import { useMemo, useState } from "react";

import type { JobLocationKey } from "@js/apps/jobs/context";

import { useJobListing } from "../listing";

export type UseSearchJobsArg = { location: JobLocationKey };

export const useSearchJobs = ({ location }: UseSearchJobsArg) => {
  const {
    filters,
    isAnyFilterApplied,
    loadNewFilters,
    deleteSavedFilter,
    jobs,
    loading,
    total,
    refetchJobs,
  } = useJobListing({
    location,
  });
  const [isSavedFiltersDrawerOpen, setIsSavedFiltersDrawerOpen] =
    useState(false);

  const setIsSavedFiltersDrawerOpenHandler = () => {
    setIsSavedFiltersDrawerOpen(true);
  };

  const withJobActionContextProviderValue = useMemo(
    () => ({
      afterAction: refetchJobs,
    }),
    [refetchJobs],
  );

  return {
    searchTerm: filters.search,
    withJobActionContextProviderValue,
    jobs,
    total,
    loading,
    filters,
    isAnyFilterApplied,
    isSavedFiltersDrawerOpen,
    setIsSavedFiltersDrawerOpen,
    setIsSavedFiltersDrawerOpenHandler,
    loadNewFilters,
    deleteSavedFilter,
  };
};
