import type { FC } from "react";
import { useEffect, useState } from "react";

import { MODULE_EDIT_MODAL_PROPERTIES } from "@js/apps/freelancer/constants";
import { ProjectsModuleEditForm } from "@js/apps/freelancer/forms/projects-module-form";
import { useFreelancerProjectsManager } from "@js/apps/freelancer/hooks/use-freelancer-projects-manager/use-freelancer-projects";
import { Modal } from "@js/components/modal";
import type { PortfolioImage, WorkSampleItem } from "@js/types/freelancer";
import { assertUnreachable } from "@js/utils";

import { openProjectDeletionConfirmation } from "../../project-deletion-confirmation";
import { ProfileProjectsModuleList } from "../profile-projects-module-edit-list";

export type ProjectView = "PROJECT_LIST" | "EDIT_MODAL" | "ADD_MODAL";

export const ProfileProjectsModuleModal = Modal(
  "projects-module-my-view-modal",
  {
    ...MODULE_EDIT_MODAL_PROPERTIES,
    closeButton: false,
  },
);

const useProjectModule = (
  initialView: ProjectView,
  handleProjectModuleModalClose: () => void,
) => {
  const {
    projects,
    handleAddProject,
    handleDeleteProject,
    handleEditProject,
    handleProjectListReorder,
  } = useFreelancerProjectsManager();
  const [currentView, setCurrentView] = useState<ProjectView>(initialView);
  const [initialValues, setInitialValues] = useState({});
  const [existingImages, setExistingImages] = useState<PortfolioImage[]>([]);

  useEffect(() => {
    if (projects.length === 0 && currentView !== "ADD_MODAL") {
      handleProjectModuleModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, currentView]);

  const onSubmitProject = async (values: WorkSampleItem) => {
    if (values.id) {
      await handleEditProject(values);
    } else {
      await handleAddProject(values);
    }
  };

  const onSubmitProjectSuccess = () => {
    setCurrentView("PROJECT_LIST");
  };

  const generateExistingImages = (project?: WorkSampleItem) => {
    const projectImages: PortfolioImage[] = [];

    if (
      project &&
      project.portfolio_item_images &&
      project.portfolio_item_images.length > 0
    ) {
      project.portfolio_item_images.forEach((item) => {
        projectImages.push(item.image);
      });
    }

    if (project && project.main_image && project.main_image.image) {
      projectImages.push(project.main_image.image);
    }

    setExistingImages(projectImages);
  };

  const generateInitialValues = (project?: WorkSampleItem) => {
    let values = {};

    if (project) {
      values = {
        main_image_id: Number(project.main_image?.image.id),
        images_ids: project.portfolio_item_images
          ? project.portfolio_item_images.map(({ image: { id } }) => Number(id))
          : [],
        ...project,
      };
    }

    setInitialValues(values);
  };

  const handleProjectDeletion = (projectId: number, projectName: string) => {
    openProjectDeletionConfirmation({
      onConfirm: async () => await handleDeleteProject(projectId),
      projectName,
    });
  };

  const handleProjectEdit = (project?: WorkSampleItem) => {
    generateExistingImages(project);
    generateInitialValues(project);
    setCurrentView("EDIT_MODAL");
  };

  const handleProjectAdd = () => {
    generateExistingImages();
    generateInitialValues();
    setCurrentView("ADD_MODAL");
  };

  const onCloseEditModal = () => {
    if (currentView === "EDIT_MODAL" || currentView === "ADD_MODAL") {
      setCurrentView("PROJECT_LIST");
    }
  };

  return {
    currentView,
    projects,
    editFormProps: {
      onSubmit: onSubmitProject,
      onSubmitSuccess: onSubmitProjectSuccess,
      onClose: onCloseEditModal,
      initialValues,
      existingImages,
      isEditMode: currentView === "EDIT_MODAL",
    },
    isEmptyState: !projects.length,
    handleProjectDeletion,
    handleProjectEdit,
    handleProjectAdd,
    handleProjectModuleModalClose,
    handleProjectListReorder,
  };
};

type ProfileProjectModuleModalManagerProps = {
  onClose: () => void;
  initialView: ProjectView;
};

export const ProfileProjectModuleModalManager: FC<
  ProfileProjectModuleModalManagerProps
> = ({ onClose, initialView }) => {
  const {
    currentView,
    projects,
    editFormProps,
    isEmptyState,
    handleProjectDeletion,
    handleProjectEdit,
    handleProjectAdd,
    handleProjectModuleModalClose,
    handleProjectListReorder,
  } = useProjectModule(initialView, onClose);

  switch (currentView) {
    case "PROJECT_LIST":
      return (
        <ProfileProjectsModuleList
          onDelete={(projectId, projectName) =>
            handleProjectDeletion(projectId, projectName)
          }
          onEdit={handleProjectEdit}
          onAdd={handleProjectAdd}
          onClose={handleProjectModuleModalClose}
          onReorder={handleProjectListReorder}
          projects={projects}
        />
      );
    case "EDIT_MODAL":
    case "ADD_MODAL":
      return (
        <ProjectsModuleEditForm
          {...editFormProps}
          isEmptyState={isEmptyState}
        />
      );
    default: {
      assertUnreachable(currentView);
      return null;
    }
  }
};
