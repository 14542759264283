import { useState } from "react";

import { FreelancerDashboardOffersLazy } from "../../lazy";
import { MyWorkListingPage } from "../my-work-listing-page";

export const MyOffersListingPage = () => {
  const [showClosedJobOffers, setShowClosedJobOffers] = useState(false);

  const checkboxProps = {
    checkboxText: "Show past offers",
    isChecked: showClosedJobOffers,
    setIsChecked: setShowClosedJobOffers,
  };

  return (
    <MyWorkListingPage pageTitle="Offers" checkboxProps={checkboxProps}>
      <FreelancerDashboardOffersLazy
        showClosedJobOffers={showClosedJobOffers}
      />
    </MyWorkListingPage>
  );
};
