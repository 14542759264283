export const SOCKET_URL = `${
  window.location.protocol === "https:" ? "wss" : "ws"
}://${window.location.host}/ws/user/`;

export const PING_INTERVAL_MS = 5000;
export const CHECK_STATUS_INTERVAL = 1000;
export const SEND_PENDING_MESSAGES_INTERVAL = 1000;
const PONG_ROUNDTRIP_MS = 1000;
export const PING_PONG_FAILURE_TIME_LIMIT =
  2 * PING_INTERVAL_MS + PONG_ROUNDTRIP_MS;

export const SocketStatus = {
  IDLE: "@websocket/IDLE",
  CONNECTING_INITIALIZED: "@websocket/CONNECTING_INITIALIZED",
  CONNECTING_FAILED: "@websocket/CONNECTING_FAILED",
  CONNECTED: "@websocket/CONNECTED",
  DISCONNECTED: "@websocket/DISCONNECTED",
  RUNNING: "@websocket/RUNNING",
  TRANSITIONING: "@websocket/TRANSITIONING",
  WAITING_FOR_PING_PONG: "@websocket/WAITING_FOR_PING_PONG",
  CONNECTING: "@websocket/CONNECTING",
  RECONNECTING: "@websocket/RECONNECTING",
  NOT_RUNNING: "@websocket/NOT_RUNNING",
} as const;

export type WebSocketClientStatus = EnumType<typeof SocketStatus>;

// https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
export const SocketReadyStateMap = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

export const PING_MESSAGE = {
  stream: ENUMS.StreamType.PING,
  data: { type: "PING" },
};

export const BACKEND_REVISION = "BACKEND_REVISION";
