import { Stack } from "@hexocean/braintrust-ui-components";

import styles from "./styles.module.scss";

export type MembersListProps = {
  children: React.ReactNode;
};
export const MembersList = ({ children }: MembersListProps) => {
  return (
    <Stack component="ul" className={styles.memberList}>
      {children}
    </Stack>
  );
};
