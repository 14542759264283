import type { MouseEvent } from "react";

import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Button, Tooltip } from "@hexocean/braintrust-ui-components";
import { BoltIcon } from "@hexocean/braintrust-ui-components/Icons";
import { boostButtonClicked } from "@js/apps/jobs/actions";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";

import { openBoostModal } from "../job-item/components/open-boost-modal/open-boost-modal";

import style from "./style.module.scss";

type BoostJobButtonProps = {
  statusForFreelancer: EnumType<typeof ENUMS.JobFreelancerStatus> | undefined;
  fullWidth?: boolean;
  boostCredit?: number;
  bidId?: number;
  isOpen?: boolean;
} & Pick<
  ButtonProps,
  "variant" | "sx" | "style" | "className" | "color" | "size" | "shape"
>;

const FREELANCER_STATUSES_TO_SHOW_BOOST_BUTTON: EnumType<
  typeof ENUMS.JobFreelancerStatus
>[] = [
  ENUMS.JobFreelancerStatus.APPLICATION_SENT,
  ENUMS.JobFreelancerStatus.IN_REVIEW,
];

export const BoostJobButton = ({
  fullWidth,
  boostCredit,
  bidId,
  isOpen,
  statusForFreelancer,
  ...props
}: BoostJobButtonProps) => {
  const dispatch = useAppDispatch();
  if (
    !statusForFreelancer ||
    !FREELANCER_STATUSES_TO_SHOW_BOOST_BUTTON.includes(statusForFreelancer)
  ) {
    return null;
  }
  const isBoostCredit = boostCredit ? true : false;
  const btnStyle = isBoostCredit
    ? style.boostButtonJob
    : style.inactiveBoostButtonJob;

  const btnInActiveStyle = !isOpen ? style.inactiveBoostButtonJob : "";

  const tooltipText = isBoostCredit
    ? ""
    : "You don't have enough Boost credits";

  const btnVariant = "positive";

  const openBoostConfirmationModal = () => {
    if (isOpen && (boostCredit ?? 0 > 0)) {
      openBoostModal({ boostCredit: boostCredit ?? 0, bidId: bidId ?? -1 });
      dispatch(boostButtonClicked());
    }
  };

  const handleMouseDown = (e: MouseEvent<HTMLElement>) => {
    if (e.button === 0) return;
  };

  return (
    <>
      <Tooltip disabled={!isOpen || isBoostCredit} title={tooltipText}>
        <Button
          {...props}
          fullWidth={fullWidth}
          variant={btnVariant}
          disableTouchRipple={!isOpen || !isBoostCredit}
          onClick={openBoostConfirmationModal}
          className={!isOpen ? btnInActiveStyle : btnStyle}
          onMouseDown={handleMouseDown}
          rel="noopener noreferrer"
          target="_blank"
          RouterLink={RouterLink}
          startIcon={<BoltIcon sx={{ fill: "currentcolor !important" }} />}
        >
          Boost
        </Button>
      </Tooltip>
    </>
  );
};
