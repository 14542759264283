import type { TypedWrappedFieldProps } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { ButtonSelectField } from "@js/forms/fields/button-select";

import type { HoursOverlap } from "../../config";
import { HOURS_OVERLAP_MAP } from "../../config";

export type HoursOverlapProps = {
  hours_overlap: TypedWrappedFieldProps<HoursOverlap>;
};

const hoursOverlapOptions = [
  HOURS_OVERLAP_MAP.few_hours,
  HOURS_OVERLAP_MAP.full_work_day,
];

export const HoursOverlapField = ({ hours_overlap }: HoursOverlapProps) => {
  const handleChange = (value: unknown) => {
    hours_overlap.input.onChange(value as HoursOverlap | null);
  };

  return (
    <Box>
      <Typography
        component="label"
        variant="paragraph"
        size="small"
        id="hours-overlap"
        sx={{ mb: 1, display: "inline-block" }}
      >
        How much should Talent work hours overlap with the job workday?
      </Typography>
      <ButtonSelectField
        multiple={false}
        hideError
        labelledBy="hours-overlap"
        fontWeight={400}
        typographySize="small"
        variant="white-violet"
        shape="squared"
        options={hoursOverlapOptions}
        input={{ ...hours_overlap.input, onChange: handleChange }}
        meta={hours_overlap.meta}
      />
    </Box>
  );
};
