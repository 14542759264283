import type { ReactNode } from "react";
import { useMemo } from "react";
import { useCallback } from "react";

import { useEffectRef } from "@js/hooks/use-effect-ref";

import { useCropAndUploadImage } from "../../hooks/use-crop-and-upload-image";
import type {
  CropAndUploadOptions,
  UploadedFile,
  UploadType,
} from "../../types";
import { FileDropzone } from "../file-dropzone";
import type { FileDropzonePlaceholderProps } from "../file-dropzone-placeholder";
import { FileDropzonePlaceholder } from "../file-dropzone-placeholder";

export type ImageUploadWithCropperProps = {
  uploadType: UploadType;
  subtitle?: string;
  showUploadedImagePreview?: boolean;
  displayedImage?: string;
  cropperOptions?: CropAndUploadOptions;
  children?: ReactNode;
  onBeforeUpload?: () => void;
  onAfterUpload?: () => void;
  onUpload?: (uploadedFile: UploadedFile) => void;
  fileDropzonePlaceholderProps?: Pick<
    FileDropzonePlaceholderProps,
    "className" | "sx"
  >;
};

export const ImageUploadWithCropper = ({
  uploadType,
  subtitle,
  cropperOptions,
  children,
  showUploadedImagePreview = true,
  onBeforeUpload,
  onAfterUpload,
  onUpload,
  displayedImage,
  fileDropzonePlaceholderProps,
}: ImageUploadWithCropperProps) => {
  const {
    cropAndUploadImage,
    uploadedFile,
    isUploading,
    error,
    resetFileState,
  } = useCropAndUploadImage({ onBeforeUpload, onUpload, onAfterUpload });
  const cropperOptionsRef = useEffectRef(cropperOptions);

  const onDrop = useCallback(
    (files: File[]) => {
      resetFileState();

      const [file] = files;
      if (!file) {
        return;
      }

      cropAndUploadImage({
        file,
        uploadType,
        ...cropperOptionsRef.current,
      });
    },
    [uploadType, cropperOptionsRef, cropAndUploadImage, resetFileState],
  );

  const displayedImagePreview = useMemo(() => {
    if (displayedImage) {
      return displayedImage;
    }

    if (!showUploadedImagePreview) {
      return;
    }

    return uploadedFile?.attachment.file;
  }, [displayedImage, showUploadedImagePreview, uploadedFile]);

  return (
    <FileDropzone
      dropzoneOptions={{ onDrop, multiple: false, accept: { "image/*": [] } }}
      error={error}
    >
      {({ isDragActive, isFocused }) => (
        <FileDropzonePlaceholder
          isDragActive={isDragActive}
          isFocused={isFocused}
          subtitle={subtitle}
          imagePreview={displayedImagePreview}
          isLoading={isUploading}
          {...fileDropzonePlaceholderProps}
        >
          {children}
        </FileDropzonePlaceholder>
      )}
    </FileDropzone>
  );
};
