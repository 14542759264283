import { Box, Loader } from "@hexocean/braintrust-ui-components";
import { VirtualizedPostsSection } from "@js/apps/give-and-get-help/components/virtualized-posts-section";
import type { Space } from "@js/types/spaces";

import { useDiscussionPost } from "../../hooks/use-discussion-post";
import { useIsSpaceMember } from "../../hooks/use-is-space-member";
import { openJoinThisSpaceModal } from "../join-the-space-modal/join-this-space-modal";
import { SpacePostsWrapper } from "../space-posts-wrapper";

import styles from "./styles.module.scss";

export const SpacePostSection = ({ spaceData }: { spaceData: Space }) => {
  const { posts, hasMore, handleFetchingMore, loading, fetchingPosts } =
    useDiscussionPost();
  const { isSpaceMember } = useIsSpaceMember({ spaceId: spaceData.id });

  if (loading)
    return (
      <Box display="flex" justifyContent="center" width="100%">
        <Loader />
      </Box>
    );

  return (
    <div className={styles.posts}>
      <SpacePostsWrapper
        onBlockedInteraction={() => openJoinThisSpaceModal(spaceData)}
        isSpaceMember={isSpaceMember}
      >
        <VirtualizedPostsSection
          posts={posts}
          hasMore={hasMore}
          fetchMore={handleFetchingMore}
          fetchingPosts={fetchingPosts}
        />
      </SpacePostsWrapper>
    </div>
  );
};
