import { type MouseEvent, useState } from "react";

import { useIsNodeStaff } from "@js/apps/common/hooks";
import { useEmployerId } from "@js/apps/employer/hooks";
import { postJobClicked } from "@js/apps/jobs/actions";
import { openCreateJobThrottlingModal } from "@js/apps/jobs/apps/create-job/views/create-job/create-job-throttling-modal";
import {
  JOB_FORM_SUBMIT_TYPE,
  useUpdateSubmitType,
} from "@js/apps/jobs/context/job-form-context/job-form-context";
import { useGetJobFlowEntry } from "@js/apps/jobs/hooks/use-get-job-flow-entry";
import { useAppDispatch } from "@js/hooks";

import { useGetHasEmployerExceededDailyJobPostingLimitQuery } from "../../api";

export const usePostJobButton = () => {
  const dispatch = useAppDispatch();
  const updateSubmitType = useUpdateSubmitType();
  const employerId = useEmployerId();
  const isNodeStaff = useIsNodeStaff();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { data, isFetching } =
    useGetHasEmployerExceededDailyJobPostingLimitQuery(
      { employerId: employerId as number },
      { skip: isNodeStaff || !employerId || hasSubmitted },
    );
  const { flow_entry } = useGetJobFlowEntry();

  const hasExceededDailyJobsLimit =
    !isNodeStaff &&
    employerId &&
    !isFetching &&
    !!data?.has_exceeded_daily_job_posting_limit;

  const handleBtnClick = (ev: MouseEvent<HTMLElement>) => {
    if (isFetching) {
      return ev.preventDefault();
    }

    if (hasExceededDailyJobsLimit) {
      ev.preventDefault();
      openCreateJobThrottlingModal();

      return;
    }

    setHasSubmitted(true);
    updateSubmitType(JOB_FORM_SUBMIT_TYPE.job);
    dispatch(postJobClicked({ flow_entry }));
  };

  return { handleBtnClick, disabled: isFetching };
};
