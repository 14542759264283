import { type FC, useMemo } from "react";

import {
  Box,
  Chip,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { BoltIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useLazyGetTotalJobBidsQuery } from "@js/apps/jobs/api";

import TooltipContent from "./tooltip-content";

type BoostedJobBadgeProps = {
  jobId: number;
  statusForFreelancer?: EnumType<typeof ENUMS.JobFreelancerStatus>;
};

// Used for talents for their application info
export const BoostedJobBadge: FC<BoostedJobBadgeProps> = ({
  jobId,
  statusForFreelancer,
}) => {
  const [getTotalJobBidsQuery, { isLoading, data }] =
    useLazyGetTotalJobBidsQuery();

  // These conditions are also based on how it was handled in frontend/js/apps/jobs/components/job-status/freelancer/status-for-freelancer.tsx
  const hideTotalApplicants = useMemo(
    () =>
      statusForFreelancer === ENUMS.JobFreelancerStatus.JOB_FILLED ||
      statusForFreelancer === ENUMS.JobFreelancerStatus.APPLICATION_REJECTED ||
      statusForFreelancer === ENUMS.JobFreelancerStatus.JOB_ON_HOLD ||
      statusForFreelancer === ENUMS.JobFreelancerStatus.JOB_CLOSED,
    [statusForFreelancer],
  );

  return (
    <>
      <Tooltip
        onOpen={() => !hideTotalApplicants && getTotalJobBidsQuery({ jobId })}
        title={
          <TooltipContent
            hideTotalApplicants={hideTotalApplicants}
            totalBidsCount={data?.bids_count}
            isLoading={isLoading}
          />
        }
      >
        <span>
          <Chip
            sx={{ backgroundColor: "var(--medium-blue) !important" }}
            label={
              <Box display="flex" gap={0.75} alignItems="center">
                <Box>
                  <BoltIcon
                    sx={{
                      margin: "0 -2px",
                      fontSize: "12px !important",
                      color: "var(--info-2) !important",
                      fill: "var(--info-2) !important",
                    }}
                  />
                </Box>
                <Typography
                  component="p"
                  variant="paragraph"
                  size="small"
                  sx={{ color: "var(--dark-blue)" }}
                >
                  Boosted
                </Typography>
              </Box>
            }
            size="x-small"
          />
        </span>
      </Tooltip>
    </>
  );
};
