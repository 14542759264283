import React, { useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Fields } from "redux-form";
import { skipToken } from "@reduxjs/toolkit/query";

import {
  Box,
  Loader,
  PaperWithVioletOptions,
  StyleAutocompleteMediumVioletChips,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  LocationPinIcon,
  MenuArrowDownIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { filterApplied } from "@js/apps/common/actions";
import type { TalentLocationFormValues } from "@js/apps/common/components/filters";
import { TalentLocationForm } from "@js/apps/common/components/filters";
import { useTalentLocationFilter } from "@js/apps/common/hooks/talent-location-filter";
import { RecommendedLocationButtonSelect } from "@js/apps/freelancer/forms/fields/recommended-location-button-select";
import type { BidRecommendedLocation } from "@js/apps/jobs/apps/bids/api";
import { useGetBidRecommendedLocationsQuery } from "@js/apps/jobs/apps/bids/api";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components";
import type { LocationValue } from "@js/components/autocomplete-new/google-places/types";
import { GoogleComboboxMultipleField } from "@js/forms/fields";
import { isError } from "@js/forms/utils";
import { useAppDispatch } from "@js/hooks";
import { PossibleFilters } from "@js/types/common";

import styles from "./styles.module.scss";

const formId = "bid-talent-filters__location";

export const BidsTalentLocationFilter = ({ jobId }: { jobId: number }) => {
  const { label, isOpen, setIsOpen, initialLocation, isActive } =
    useTalentLocationFilter();

  const dispatch = useAppDispatch();
  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    dispatch(
      filterApplied({
        filter_type: PossibleFilters.LOCATION,
        filter_value: values,
      }),
    );
  };

  const handleSubmitSuccess = () => {
    setIsOpen(false);
  };

  return (
    <JobPopoverFilterButton
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      startIcon={<LocationPinIcon />}
      isActive={isActive}
      label={label}
      anchorClassName={styles.bidTalentLocationFilterAnchor}
      popoverContent={
        <TalentLocationForm
          transformInitialValues={(values) => {
            return {
              ...values,
              location: initialLocation,
            } as unknown as TalentLocationFormValues;
          }}
          form={formId}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitSideAction={handleSubmitSideAction}
        >
          {({ submit }) => {
            return (
              <Fields
                submit={submit}
                names={["location", "include_verified_locations_only"]}
                jobId={jobId}
                component={TalentLocationFilterField}
              />
            );
          }}
        </TalentLocationForm>
      }
    />
  );
};

type TalentLocationFilterFieldProps = {
  submit: () => void;
  jobId: number;
  location: TypedWrappedFieldProps<LocationValue[]>;
};

const TalentLocationFilterField = ({
  submit,
  location,
  jobId,
}: TalentLocationFilterFieldProps) => {
  const {
    data: bidRecommendedLocations,
    isLoading: isLoadingBidRecommendedLocations,
  } = useGetBidRecommendedLocationsQuery(jobId ? { jobId } : skipToken);

  const handleReset = () => {
    location.input.onChange([]);
  };

  const error = isError([
    location,
    // no error in others
  ]);

  const recommendedByJobRequired = bidRecommendedLocations?.required_locations;

  const recommendedLocationsSectionLabel = recommendedByJobRequired
    ? "Required locations"
    : "Talent locations";

  const recommendedLocations = bidRecommendedLocations?.locations;

  const options = useMemo(
    () =>
      (recommendedLocations || []).map((option) => {
        return {
          ...option,
          location: `${option.location} ${option.bids_count ? `(${option.bids_count})` : ""}`,
        };
      }),
    [recommendedLocations],
  );

  return (
    <JobPopoverFilterButton.Content
      onReset={handleReset}
      onApply={submit}
      disableApply={error}
    >
      <Box p={1}>
        <Typography
          component="h3"
          mb={1}
          variant="label"
          size="large"
          lineHeight={1.4}
          fontWeight={500}
        >
          Select Talent location 📌
        </Typography>

        <GoogleComboboxMultipleField<true>
          data-testid="multiple-location-field"
          id="location"
          component={StyleAutocompleteMediumVioletChips}
          PaperComponent={PaperWithVioletOptions}
          noErrorOnTouch
          disableClearable
          label={undefined}
          locationIcon={false}
          useCustomLocations
          placesServiceTypes={"regions_without_sublocality"}
          placeholder={
            !!location.input.value?.length
              ? ""
              : "Select a country, state, or city"
          }
          popupIcon={<MenuArrowDownIcon />}
          fullWidth
          input={{
            ...location.input,
            onChange: (value) => {
              location.input.onChange(value as LocationValue[]);
            },
          }}
          meta={location.meta}
        />
        <PopularLocationsButtonSelect
          input={location.input}
          meta={location.meta}
          label={recommendedLocationsSectionLabel}
          options={options}
          isLoading={isLoadingBidRecommendedLocations}
        />
      </Box>
    </JobPopoverFilterButton.Content>
  );
};

export type PopularLocationButtonSelectProps = TypedWrappedFieldProps<
  LocationValue[]
> & {
  options: BidRecommendedLocation[];
  label: string;
  isLoading?: boolean;
};

const PopularLocationsButtonSelect = ({
  input,
  meta,
  options,
  label,
  isLoading,
}: PopularLocationButtonSelectProps) => {
  if (isLoading) return <Loader />;
  if (!options.length) return null;

  return (
    <RecommendedLocationButtonSelect
      input={input}
      meta={meta}
      options={options}
      label={label}
    />
  );
};
