import { useEffect, useState } from "react";

export const useIsImageBackgroundTransparent = (src: string | null) => {
  const [isTransparent, setIsTransparent] = useState(false);

  useEffect(() => {
    if (!src) return;

    const element = document.createElement("canvas");
    element.setAttribute("id", "canvas");

    const context = element.getContext("2d");

    const image = new Image();
    image.crossOrigin = "Anonymous";

    image.onload = () => {
      element.width = image.width;
      element.height = image.height;

      context?.drawImage(image, 0, 0);

      const imgData = context?.getImageData(
        0,
        0,
        element.width,
        element.height,
      ).data;
      const transparent = checkIsTransparency(imgData);

      if (transparent) {
        setIsTransparent(transparent);
      }
    };

    // add query param to avoid CORS error
    image.src = src + `${src.includes("?") ? "&" : "?"}for_canvas=true`;

    return () => {
      image.onload = null;
    };
  }, [src]);

  return isTransparent;
};

// function based on similar thread in stackoverflow
//https://stackoverflow.com/questions/25922963/detecting-image-transparency-using-html5-canvas-has-false-positives
const checkIsTransparency = (data: Uint8ClampedArray | undefined) => {
  if (!data) {
    return false;
  }

  let isTransparent = false;
  for (let i = 0; i < data.length; i += 4) {
    if (data[i + 3] < 255) {
      isTransparent = true;
    }
  }
  return isTransparent;
};
