import { API } from "@js/api";

type GetByotCSVDataParams = {
  id: number;
  download_csv_with_errors?: boolean;
};

export type GetByotCSVDataRespone = {
  id: number;
  result: ByotCSVRecord[];
  errors?: Array<ByotErrors>;
};

type ByotErrors = {
  [K in keyof ByotCSVRecord]: string[];
};

type ByotCSVRecord = {
  first_name: string;
  last_name: string;
  talent_email_address: string;
  job_title: string;
  start_date: string;
  end_date: string;
  pay_rate: string;
  rate_type: string;
  est_hours: string;
};

type SendOffersPayload = {
  id: number;
  result: ByotCSVRecord[];
  note_to_talent?: string;
};

type ResendOfferPayload = {
  user_id: number;
};

type RegisterBYOTTalentPayload = {
  offerId: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  accept_terms_of_use: boolean;
};

const byotAPI = API.injectEndpoints({
  endpoints: (build) => ({
    getByotCSVData: build.query<GetByotCSVDataRespone, GetByotCSVDataParams>({
      query: ({ id, ...params }) => ({
        url: `/byot_csv_files/${id}`,
        method: "GET",
        params,
      }),
    }),
    sendOffers: build.mutation<void, SendOffersPayload>({
      query: (data) => ({
        url: `/byot_csv_files/${data.id}/send_offers/`,
        method: "POST",
        data,
      }),
    }),
    resendOffer: build.mutation<void, ResendOfferPayload>({
      query: (data) => ({
        url: `/byot_csv_files/resend_offer/`,
        method: "POST",
        data,
      }),
    }),
    registerBYOTTalent: build.mutation<void, RegisterBYOTTalentPayload>({
      query: ({ offerId, ...data }) => ({
        url: `/user/byot_registration/?offer_id=${offerId}`,
        method: "POST",
        data,
      }),
    }),
  }),
});

export const {
  useGetByotCSVDataQuery,
  useSendOffersMutation,
  useResendOfferMutation,
  useRegisterBYOTTalentMutation,
} = byotAPI;
