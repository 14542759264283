import type {
  PaymentMethod,
  StripeACHPayment,
  StripeCreditCardPayment,
} from "@js/types/payments";

export const isVerifiedACHPaymentMethod = (paymentMethod: PaymentMethod) => {
  return (
    !!paymentMethod &&
    isACHPaymentMethod(paymentMethod) &&
    !!paymentMethod.method.verified_at
  );
};

export const isACHPaymentMethod = (
  paymentMethod: PaymentMethod,
): paymentMethod is StripeACHPayment => {
  return (
    paymentMethod.content_type ===
    ENUMS.PaymentContentType.StripeACHPaymentMethod
  );
};

export const isCCPaymentMethod = (
  paymentMethod: PaymentMethod,
): paymentMethod is StripeCreditCardPayment => {
  return (
    paymentMethod.content_type ===
    ENUMS.PaymentContentType.StripeCreditCardPaymentMethod
  );
};
