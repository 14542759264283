import { useCallback } from "react";

import { useIsNodeStaff } from "@js/apps/common/hooks/is-node-staff";
import { useUser } from "@js/apps/common/hooks/use-user";
import { useAppDispatch } from "@js/hooks";

import { loginAs as loginAsAction } from "../../actions";

type UseLoginAsType = (id: number) => {
  canLoginAs: boolean;
  loginAs: () => void;
};

export const useLoginAs: UseLoginAsType = (id) => {
  const user = useUser();
  const isNodeStaff = useIsNodeStaff();
  const dispatch = useAppDispatch();
  const canLoginAs = !!isNodeStaff && !user?.is_impersonated_session && !!id;

  const loginAs = useCallback(() => {
    return dispatch(loginAsAction(id));
  }, [dispatch, id]);

  return {
    canLoginAs,
    loginAs,
  };
};
