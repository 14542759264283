import {
  maxValue,
  minValue,
  requiredWithSpecificMessage,
} from "@js/forms/validators";

import { MAX_YEARS_OF_EXPERIENCE, MIN_YEARS_OF_EXPERIENCE } from "../constants";

export const experienceValidator = (value: number) => {
  const required = requiredWithSpecificMessage(
    "You must enter a valid number.",
  );
  const maxYear = maxValue(MAX_YEARS_OF_EXPERIENCE);
  const minYear = minValue(MIN_YEARS_OF_EXPERIENCE + 1);

  const isRequired = required(value);
  const hasValidMaxYear = maxYear(value);
  const hasValidMinYear = minYear(value);

  return isRequired || hasValidMaxYear || hasValidMinYear;
};

export const requiredRole = requiredWithSpecificMessage(
  "You must select a role",
);
