import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { JobInterviewGradingSummary } from "@js/apps/jobs/components/job-interview-grading-summary";
import type { JobInterviewScorecard } from "@js/types/jobs";
import { capitalize } from "@js/utils";

import { BidInterviewIcon } from "./bid-interview-icon";

export const BidInterviewGrading = ({
  scorecards,
}: {
  scorecards: JobInterviewScorecard[];
}) => {
  return (
    <Box mt={5}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        mb={3}
      >
        <BidInterviewIcon />
        <Typography component="h2" variant="paragraph" size="large" ml={1}>
          Grading Criteria
        </Typography>
      </Box>
      <Box>
        {scorecards.map(({ id, feedback, score, attribute_key }) => (
          <Box
            key={id}
            sx={{
              borderBottom: "1px solid var(--soft-beige)",
              ":last-child": {
                borderBottomWidth: 0,
              },
            }}
            mb={4}
            pb={3}
          >
            <JobInterviewGradingSummary
              grade={score}
              title={capitalize(attribute_key.toLowerCase())}
              description={feedback}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
