export const isTalentPathname = (pathname: string): boolean =>
  /^\/talent\/[0-9]\d*/.test(pathname);

type TopBarVisiblePartHeightParams = {
  isElementVisible: boolean;
  elementHeight: number;
  scrollOffset: number;
};

export const getTopBarVisiblePartHeight = ({
  isElementVisible,
  elementHeight,
  scrollOffset,
}: TopBarVisiblePartHeightParams): number => {
  if (!isElementVisible) {
    return 0;
  }

  const visibleHeight = elementHeight - scrollOffset;
  return Math.max(0, visibleHeight);
};
