import "./setup";

import type { ConfigType, Dayjs, ManipulateType } from "dayjs";
import dayjs from "dayjs";
import type { DurationUnitType } from "dayjs/plugin/duration";

import type { TimezoneOffset } from "@js/types/common";
import { generateNumberList } from "@js/utils/numbers";
import { pluralize } from "@js/utils/string";
import { assertUnreachable } from "@js/utils/typescript";

import type {
  DateFormatsValue,
  IsoDateOrIsoDateTime,
  TimeFormatsValue,
} from "./types";
import { DateFormats, DateTimeFormats } from "./types";

export type DayjsInput = ConfigType | IsoDateOrIsoDateTime;

export const formatDatetime = (
  date: DayjsInput,
  format: TimeFormatsValue = DateTimeFormats["1970-12-31T0:00:00Z"],
) => {
  return dayjs(date).format(format);
};

/**
 * Formats a Dayjs date object into a string according to the given format.
 * @see getToday - for getting the current date that can be formatted by this function.
 *
 * @param {NonNullable<DayjsInput>} date - The date to be formatted. It must be a non-null Dayjs input.
 * @param {DateFormatsValue} [format] - The format string to be used for formatting the date. If not provided,
 *                                      a default format specific to Dayjs will be used.
 * @returns {string} The formatted date string.
 *
 * @example
 * // Format the current date as 'YYYY-MM-DD'
 * const today = getToday({ type: 'date' });
 * const formattedDate = formatDate(today, 'YYYY-MM-DD');
 */
export const formatDate = (
  date: NonNullable<DayjsInput>,
  format?: DateFormatsValue,
) => {
  return dayjs.utc(date).format(format);
};

type GetDateOptions = {
  type?: "date" | "datetime";
  utc?: boolean;
};

/**
 * Retrieves the current date (and optionally time with timezone), formatted according to the provided options.
 *
 * @param {GetDateOptions} [params] - An options object to specify the type and timezone.
 * @returns {string} The current date (and optionally time) as a formatted string.
 *
 * @example
 * // Get the current date as 'YYYY-MM-DD'
 * const currentDate = getToday({ type: 'date' });
 *
 * // Get the current datetime in UTC as 'YYYY-MM-DDTHH:mm:ssZ'
 * const currentDateTimeUTC = getToday({ type: 'datetime', utc: true });
 */
export const getToday = (
  params: GetDateOptions = {
    type: "datetime",
  },
) => {
  const { type, utc } = params;
  const format =
    type === "date"
      ? DateFormats["1970-12-31"]
      : DateTimeFormats["1970-12-31T00:00:00Z"];
  if (utc) {
    return dayjs.utc().format(format);
  }

  return dayjs().format(format);
};

// Fixes dates on Safari browser:
// valid: 2019-01-01
// invalid: 2019-1-1, 2019-01-1 or 2019-1-01
export const insertLeadingZerosInDate = (
  year: number,
  month: number,
  day?: number,
) => {
  const _month = month < 10 ? `0${month}` : month;
  const _day = !!day ? (day < 10 ? `0${day}` : day) : "";
  return `${year}-${_month}${_day && `-${_day}`}`;
};

export const backendToTime = (dtStr: DayjsInput) =>
  dayjs(dtStr, SETTINGS.BACKEND_DATE_TIME_FORMAT);

export const yearsDateRange = (start: number, end: number, rest: boolean) => {
  return generateNumberList(start, end, rest ? "-01-01" : "").reverse();
};

export const FIRST_POSTED_JOB_DATE = "2019-06-01";
export const getMonthsDateRange = (
  startDate: string | Dayjs = dayjs(FIRST_POSTED_JOB_DATE),
  endDate: string | Dayjs = dayjs(),
) => {
  const _startDate = dayjs.isDayjs(startDate) ? startDate : dayjs(startDate);
  const _endDate = dayjs.isDayjs(endDate) ? endDate : dayjs(endDate);

  const dates = [_startDate];
  let nextDate = _startDate.clone().add(1, "month");
  while (nextDate.isSameOrBefore(_endDate)) {
    dates.push(nextDate.clone());
    nextDate = nextDate.add(1, "month");
  }
  return dates.reverse().map((date) => ({
    label: date.format(DateFormats["January 1970"]),
    value: date.format(DateFormats["1970-12-31"]),
  }));
};

export const dateToFromNowDaily = (date: DayjsInput) => {
  const fromNow = dayjs(date).add(1, "day").fromNow();

  return dayjs(date).calendar(null, {
    lastWeek: "[" + fromNow + "] ",
    lastDay: "[yesterday]",
    sameDay: "[today]",
    nextDay: "[tomorrow]",
    nextWeek: "[" + fromNow + "]",
    sameElse: () => fromNow,
  });
};

export const dateFromNow = (date: DayjsInput) => {
  const fromNow = dayjs(date).fromNow();

  if (fromNow === "a day ago") {
    return "yesterday";
  }

  return fromNow;
};

export const addToDate = (
  amount: number,
  unit: ManipulateType,
  date?: DayjsInput,
) => dayjs(date).add(amount, unit);

export const subtractFromDate = (
  amount: number,
  unit: ManipulateType,
  date?: DayjsInput,
) => dayjs(date).subtract(amount, unit);

export const getDateYear = (date?: DayjsInput) => {
  return Number(formatDate(date ?? getToday(), DateFormats["yearFourDigits"]));
};

export const getDateMonth = (date?: DayjsInput) =>
  Number(formatDate(date ?? getToday(), DateFormats["monthTwoDigits"]));

export const isDateAfterToday = (date: DayjsInput) =>
  dayjs().isBefore(date, "day");

export const isDateBeforeUTCToday = (date: DayjsInput) =>
  dayjs.utc(date).isBefore(dayjs(), "day");

export const getMonths = () => dayjs.months();

export const getMonthName = (monthNumber: number) => {
  return dayjs()
    .month(monthNumber - 1)
    .format(DateFormats.January);
};

export const isDateValid = (date: DayjsInput, format?: string) =>
  dayjs(date, format, true).isValid();

export const getDateFromTimeStamp = (value: number) => dayjs.unix(value);

export const formatCalendarDate = (date: DayjsInput, format?: object) => {
  return dayjs(date).calendar(null, format);
};

export const getLocalDate = (date: DayjsInput) => dayjs.utc(date).local();

// @param precision - number of days after date is displayed in April 25, 2022 format
export const exactDateFromNow = (
  date: string | Dayjs = dayjs(),
  precision = 7,
) => {
  const fromNow = dayjs(date).fromNow();
  const daysDifference = dateDifference(dayjs(), date, "days");
  if (daysDifference > precision)
    return dayjs(date).format(DateFormats["January 1, 1970"]);
  if (fromNow === "an hour ago") return "1 hour ago";
  if (fromNow === "a minute ago") return "1 minute ago";
  if (fromNow === "a day ago") return "1 day ago";

  return fromNow;
};

export const showRelativeDateAfterDays = (date: DayjsInput, day: number) => {
  const now = dayjs();
  const fromNow = dateFromNow(date);

  const result = now.diff(date, "days");

  if (result > day) {
    return dayjs(date).format(DateFormats["Jan 1, 1970"]);
  }

  return fromNow;
};

type DateDifferenceObject = {
  milliseconds: number;
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  months: number;
  years: number;
};

export const dateDifference = <T extends DurationUnitType | null>(
  date: DayjsInput,
  olderDate: DayjsInput,
  timeUnit?: T,
): T extends null ? DateDifferenceObject : number => {
  const duration = dayjs.duration(dayjs(date).diff(olderDate));

  if (timeUnit) {
    return duration.as(timeUnit) as T extends null
      ? DateDifferenceObject
      : number;
  }

  return {
    milliseconds: duration.milliseconds(),
    seconds: duration.seconds(),
    minutes: duration.minutes(),
    hours: duration.hours(),
    days: duration.days(),
    months: duration.months(),
    years: duration.years(),
  } as T extends null ? DateDifferenceObject : number;
};
export const dateDifferenceFromNow = <T extends DurationUnitType | null>(
  date: DayjsInput,
  timeUnit: T,
): T extends null ? DateDifferenceObject : number => {
  const now = dayjs();

  if (timeUnit)
    return Math.round(dateDifference(date, now, timeUnit)) as T extends null
      ? DateDifferenceObject
      : number;

  return dateDifference(date, now);
};

export const getTimezoneByOffset = (offset: string) =>
  SETTINGS.TIMEZONE_ABBREVIATIONS.find(
    (timezone) => timezone.utc_offset === offset,
  );

export const calcPaymentStartDate = (
  invoicingFrequency: EnumType<typeof ENUMS.JobInvoiceFrequencyType>,
  startDate: DayjsInput,
  format = "YYYY-MM-DD",
) => {
  let amount = 1;
  const unitInMonths = "M";

  switch (invoicingFrequency) {
    case ENUMS.JobInvoiceFrequencyType.BIWEEKLY: {
      amount = 2;
      const unitInWeeks = "w";

      return dayjs(startDate)
        .add(amount, unitInWeeks)
        .format(format)
        .toString();
    }
    case ENUMS.JobInvoiceFrequencyType.DECIDE_LATER:
    case ENUMS.JobInvoiceFrequencyType.MONTHLY:
    case ENUMS.JobInvoiceFrequencyType.WEEKLY: {
      return dayjs(startDate)
        .add(amount, unitInMonths)
        .format(format)
        .toString();
    }
    case null:
      break;
    default: {
      assertUnreachable(invoicingFrequency);
    }
  }
};

export const displayTimezoneName = (timezoneName: string | null) => {
  if (!timezoneName) {
    return null;
  }
  return timezoneName.split("_").join(" ");
};

export const relativeDateFromNow = (date: DayjsInput = dayjs()) => {
  const daysDifference = dateDifference(dayjs(), date, "days");
  const minuteDifference = dateDifference(dayjs(), date, "minute");

  if (minuteDifference < 1) return "just now";
  if (minuteDifference < 60) return `${Math.round(minuteDifference)}m ago`;
  if (daysDifference < 1) return `${Math.round(minuteDifference / 60)}h ago`;
  if (daysDifference < 7) return `${Math.round(daysDifference)}d ago`;

  return dayjs(date).format(DateFormats["January 1, 1970"]);
};
/* Time posted
If less than 1 hr ago, display minutes
If more than 1 hr ago, but less than 24 hrs ago, display hours, rounded down to the last hour (Ex: 11 hrs and 40 mins  = 11 hrs)
If more than 24 hrs ago, but less than 6 days, display days, rounded down to the past day (ex: 3 days, and 19 hours = 3 days)
If more than 6 days, display weeks, rounded down to the past week (Ex:  3 weeks and 18 days = in weeks)
*/

type RelativeDateDifferenceConfig = {
  useDayJSRelativeStrings?: boolean;
  noArticles?: boolean;
};

export const relativeDateDifference = (
  postDate: DayjsInput,
  config?: RelativeDateDifferenceConfig,
) => {
  const publishDate = dayjs(postDate);
  const currentDate = dayjs();

  if (config?.useDayJSRelativeStrings) {
    const dajJsRelativeString = dayjs(publishDate).from(currentDate);

    if (config?.noArticles) {
      return dajJsRelativeString.replace("a few", "few").replace(/^an?/g, "1");
    }

    return dajJsRelativeString;
  }

  const differenceInMinutes = currentDate.diff(publishDate, "minutes");
  const differenceInHours = currentDate.diff(publishDate, "hours", true);
  const differenceDays = currentDate.diff(publishDate, "days", true);
  const differenceMonths = currentDate.diff(publishDate, "months", true);

  if (differenceInMinutes < 1) {
    return "few seconds ago";
  }
  //If less than 1 hr ago, display minutes
  if (differenceInMinutes < 60) {
    return `${differenceInMinutes} minute${pluralize(differenceInMinutes)} ago`;
  }
  //If more than 1 hr ago, but less than 24 hrs ago, display hours, rounded down to the last hour (Ex: 11 hrs and 40 mins  = 11 hrs)
  if (differenceInHours < 24) {
    const hour = Math.floor(differenceInHours);
    return `${hour} hour${pluralize(hour)} ago`;
  }
  //If more than 24 hrs ago, but less than 7 days, display days, rounded down to the past day (ex: 3 days, and 19 hours = 3 days)
  if (differenceDays < 7) {
    const day = Math.floor(differenceDays);
    return `${day} day${pluralize(day)} ago`;
  }
  if (differenceMonths < 1) {
    const weeks = Math.floor(differenceDays / 7);
    return `${weeks} week${pluralize(weeks)} ago`;
  }
  //If less than 12 months display months
  if (differenceMonths < 12) {
    const month = Math.floor(differenceMonths);
    return `${month} month${pluralize(month)} ago`;
  }
  //If more then 11 months display years
  if (differenceMonths >= 12) {
    const years = Math.floor(differenceMonths / 12);
    return `${years} year${pluralize(years)} ago`;
  }
};
export const getLocalTime = (timezone_offset: TimezoneOffset) => {
  const date = new Date();
  const utcInMs = date.getTime() + date.getTimezoneOffset() * 60_000;

  const sign = timezone_offset[0];
  const hours = Number(timezone_offset.slice(1, 3));
  const minutes = Number(timezone_offset.slice(3, 5));

  const localTimeInMs =
    sign === "+"
      ? utcInMs + (hours * 60 * 60 * 1000 + minutes * 60 * 1000)
      : utcInMs - (hours * 60 * 60 * 1000 + minutes * 60 * 1000);
  return new Date(localTimeInMs).toLocaleTimeString(undefined, {
    hour: "numeric",
    minute: "2-digit",
  });
};

export const get12HoursClockTime = (timeIn24HoursFormat: string) => {
  return dayjs(timeIn24HoursFormat, "HH").format(`h:mm A`);
};

export const isDaysFromTodayEqualOrHigher = (date: string, days: number) => {
  const dateObject = new Date(date);
  const today = new Date();
  if (!isDateValid(dateObject)) {
    return false;
  }
  const daysDifference = dateDifference(today, dateObject, "days");

  return daysDifference >= days;
};
