import React from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { ErrorIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

type AddressWarningProps = {
  closeModal: () => void;
};

export const AddressWarning = ({
  closeModal,
}: AddressWarningProps): JSX.Element => (
  <>
    <Typography
      mb={2}
      component="h1"
      variant="title"
      fontWeight={500}
      size="small"
    >
      <ErrorIcon className="general-warning-icon" /> No Country Selected
    </Typography>
    <Typography component="p">
      Attention: before you can be paid, you must set your country location.
    </Typography>
    <Box mt={3.75} textAlign="right">
      <Button variant="transparent" color="secondary" onClick={closeModal}>
        Cancel
      </Button>
      <Button
        variant="transparent"
        color="secondary"
        onClick={closeModal}
        href="/settings/account_info/"
        RouterLink={RouterLink}
      >
        Setup Now
      </Button>
    </Box>
  </>
);
