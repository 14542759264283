import {
  Box,
  Loader,
  Pagination,
  PaginationWrapper,
} from "@hexocean/braintrust-ui-components";
import { SearchListingHeader } from "@js/apps/common/components";
import { SavedTalentsFiltersMenu } from "@js/apps/common/components/save-filters/saved-talents-filters-menu";
import { useClearFilters } from "@js/apps/jobs/apps/listing/hooks/use-clear-filters";
import { UniversalSearchContextProvider } from "@js/apps/universal-search/context/universal-search-context";
import { RouterLink } from "@js/components/link";
import { AppLayout } from "@js/layouts/app";

import { TalentListing } from "../../components/talent-list";
import { TalentsListFilters } from "../../components/talents-list-filters";
import { TALENT_LOCATION } from "../../constants";
import { useTalentListing } from "../../hooks/listing";

import { SearchedJobTitle } from "./job-title";

import styles from "./style.module.scss";

export const TalentListingPage = (): JSX.Element => {
  const {
    filters,
    loading,
    talents,
    isAnyFilterApplied,
    loadNewFilters,
    count,
    total_count,
    fetchTalents,
  } = useTalentListing(TALENT_LOCATION.talent_listing);
  const { clearFilters } = useClearFilters();
  return (
    <UniversalSearchContextProvider initialTab="PEOPLE">
      <AppLayout
        pageTitle="Talent Search"
        className="wider"
        bgcolor="var(--soft-blue)"
      >
        <div className={styles.listingPage}>
          <div className={styles.listingPageHeader}>
            <Box className={styles.listingPageTopSection}>
              <SearchListingHeader hideUnderline>Talent</SearchListingHeader>
              <div>
                <SavedTalentsFiltersMenu onChange={loadNewFilters} />
              </div>
            </Box>
            <SearchedJobTitle />
            <TalentsListFilters
              isAnyFilterApplied={isAnyFilterApplied}
              filters={filters}
              count={count}
              total_count={total_count}
              fetchTalents={fetchTalents}
            />
          </div>
          <div className={styles.listingPageContent}>
            {loading ? (
              <Loader centered />
            ) : (
              <>
                <TalentListing
                  talentList={talents}
                  onClear={clearFilters}
                  location={TALENT_LOCATION.legacy_talent_search}
                  searchResults={isAnyFilterApplied}
                />
                <PaginationWrapper>
                  <Pagination
                    RouterLink={RouterLink}
                    count={count}
                    perPage={SETTINGS.TALENT_LISTING_TALENTS_PER_PAGE}
                  />
                </PaginationWrapper>
              </>
            )}
          </div>
        </div>
      </AppLayout>
    </UniversalSearchContextProvider>
  );
};
