import type { AnyAction } from "redux";
import _ from "underscore";

import type { NotificationsState } from "@js/types/notifications";
import { deepClone } from "@js/utils/data";

import * as actionTypes from "./action-types";

const INITIAL_STATE: NotificationsState = {
  siteNotifications: null,
  loadingSiteNotifications: false,
  loadSiteNotificationsError: null,
};

export default function (
  state = INITIAL_STATE,
  action: AnyAction,
): NotificationsState {
  switch (action.type) {
    case actionTypes.LOAD_SITE_NOTIFICATIONS:
      return {
        ...state,
        loadingSiteNotifications: true,
        loadSiteNotificationsError: null,
        siteNotifications: null,
      };
    case actionTypes.LOAD_SITE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loadingSiteNotifications: false,
        loadSiteNotificationsError: null,
        siteNotifications: action.payload,
      };
    case actionTypes.LOAD_SITE_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loadingSiteNotifications: false,
        loadSiteNotificationsError: action.payload,
        siteNotifications: null,
      };

    case actionTypes.SITE_NOTIFICATION: {
      const newSiteNotifications = state.siteNotifications || [];

      return {
        ...state,
        siteNotifications: [action.payload, ...newSiteNotifications],
      };
    }
    case actionTypes.MARK_SITE_NOTIFICATION_AS_READ: {
      const markedAsReadSiteNotifications =
        deepClone<any>(state.siteNotifications) || [];

      markedAsReadSiteNotifications[
        markedAsReadSiteNotifications.indexOf(
          _.findWhere(markedAsReadSiteNotifications, { id: action.payload }),
        )
      ].read = true;

      return { ...state, siteNotifications: markedAsReadSiteNotifications };
    }
    case actionTypes.MARK_ALL_SITE_NOTIFICATIONS_AS_READ: {
      const markedAllSiteNotificationsAsRead = [
        ...(state.siteNotifications || []),
      ].map((siteNotification) => {
        return {
          ...siteNotification,
          read: true,
          forceUnread: !siteNotification.read,
        };
      });

      return { ...state, siteNotifications: markedAllSiteNotificationsAsRead };
    }

    default:
      return state;
  }
}
