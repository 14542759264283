import { useMemo } from "react";

import { PostViewedContextProvider } from "../../context/post-viewed";
import { PostsContextProvider } from "../../context/posts";
import { PostsListEmptyState } from "../posts-list-empty-state";

import type { VirtualizedPostsListProps } from "./virtualized-posts-list";
import { VirtualizedPostsList } from "./virtualized-posts-list";

type VirtualizedPostsSectionProps = {
  activePostId?: number;
} & VirtualizedPostsListProps;

export const VirtualizedPostsSection = ({
  activePostId,
  ...postsListProps
}: VirtualizedPostsSectionProps) => {
  const { posts, fetchingPosts } = postsListProps;
  const displayEmptyState = posts?.length === 0 && !fetchingPosts;

  const initialExpandedIds = useMemo(() => {
    if (typeof activePostId === "undefined") {
      return [];
    }

    return [activePostId];
  }, [activePostId]);

  if (displayEmptyState) {
    return <PostsListEmptyState />;
  }

  return (
    <PostsContextProvider initialExpandedIds={initialExpandedIds}>
      <PostViewedContextProvider>
        <VirtualizedPostsList {...postsListProps} />
      </PostViewedContextProvider>
    </PostsContextProvider>
  );
};
