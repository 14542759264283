import { ACCESS_RULE } from "@js/routes/constants";
import type { RoutesRootObject } from "@js/routes/gather-routes/gather-routes-types";
import type {
  MappedRouteWithFlags,
  squashRoutes,
} from "@js/routes/gather-routes/index";

export const getRouteRules = (route: RoutesRootObject) => {
  if ("props" in route) {
    const element = route.props.element;
    if (element && "props" in element && "rules" in element.props) {
      return element.props.rules || [];
    }
  }
  return [];
};

export const isIgnoredRoute = (route: RoutesRootObject) => {
  if ("props" in route && route.props && "data-ignored" in route.props) {
    return route.props["data-ignored"];
  }
  return false;
};

export const mapRoutesWithRulesToRoutesWithAnonymityFlags = (
  routes: ReturnType<typeof squashRoutes>,
): MappedRouteWithFlags[] => {
  return routes.map((route) => {
    const rules = route.rules;
    const hasAnyAuthRule = rules?.some((rule) =>
      rule.includes("AUTHENTICATED"),
    );
    const hasAuthenticatedRule = rules?.some(
      (rule) => rule === ACCESS_RULE.AUTHENTICATED,
    );
    const hasNotAuthenticatedRule = rules?.some(
      (rule) =>
        rule === ACCESS_RULE.NOT_AUTHENTICATED ||
        rule === ACCESS_RULE.NOT_AUTHENTICATED_OR_IN_IFRAME,
    );
    return {
      path: route.path,
      is_anonymous: !!hasNotAuthenticatedRule && !hasAuthenticatedRule,
      might_be_anonymous: !hasAnyAuthRule,
    };
  });
};
