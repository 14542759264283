import {
  Box,
  Button,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useCancelRefundRequestMutation } from "@js/apps/give-and-get-help/api";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { LocalStorage } from "@js/services/local-storage";
import type { HelpOffer } from "@js/types/give-and-get-help";

import styles from "./styles.module.scss";

export const CancelRefundRequestModal = ({ offer }: { offer: HelpOffer }) => {
  const [cancelRefund] = useCancelRefundRequestMutation();

  const handleCancelRefund = async () => {
    await cancelRefund({ offerId: offer.id, data: offer })
      .unwrap()
      .then(() => {
        ModalInstance.close();
        LocalStorage.removeItem(LocalStorage.keys.HELP_OFFER_REFUND_REQUESTED);
        Snackbar.success("Refund request cancelled");
      })
      .catch(() => {
        Snackbar.error("Something went wrong.");
      });
  };

  return (
    <Stack className={styles.content}>
      <Typography component="h4" className={styles.title}>
        Cancel refund request
      </Typography>
      <Box>
        <Typography component="p" className={styles.info}>
          Canceling this refund request will make the offer active again.
        </Typography>
        <Typography component="p" className={styles.infoSecondary}>
          When you are ready to close out this offer, don't forget to mark the
          offer as complete.
        </Typography>
      </Box>
      <Box className={styles.button}>
        <Button variant="primary" shape="squared" onClick={handleCancelRefund}>
          Cancel refund
        </Button>
      </Box>
    </Stack>
  );
};

export const openCancelHelpOfferRefundModal = (offer: HelpOffer) => {
  ModalInstance.open({
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
    },
    keepOnBackdropClick: true,
    className: styles.modal,
    padding: false,
    children: <CancelRefundRequestModal offer={offer} />,
  });
};
