import React from "react";

import { Carousel } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import type { FreelancerPublic } from "@js/types/freelancer";
import type { HelpService } from "@js/types/give-and-get-help";

import { ServiceHelpOfferItem } from "./service-help-offer-item";
import { ServiceItem } from "./service-item";

import style from "./style.module.scss";

type ServicesListProps = {
  services?: HelpService[];
  draftServices?: HelpService[];
  profile: FreelancerPublic;
  isOwnProfile: boolean;
};

export const ServicesList = ({
  services,
  profile,
  draftServices,
  isOwnProfile,
}: ServicesListProps) => {
  const isMobile = useMediaQuery("md");
  const showDraftServices = draftServices && isOwnProfile;

  const _services = services || [];
  const _draftServices = showDraftServices ? draftServices || [] : [];

  const items = [..._draftServices, ..._services];

  return (
    <Carousel
      className={style.servicesList}
      slides={items.map((item) => {
        if (item.draft) {
          return (
            <ServiceHelpOfferItem
              service={item}
              profile={profile}
              key={item.id}
            />
          );
        }

        return <ServiceItem service={item} profile={profile} key={item.id} />;
      })}
      observer
      observeParents
      grabCursor
      direction="vertical"
      threshold={10}
      spaceBetween={isMobile ? 4 : 16}
      slidesPerGroup={1}
      mousewheel
    />
  );
};
