import cs from "classnames";

import type {
  AutocompleteBaseProps,
  AutocompleteRenderInputParams,
} from "@hexocean/braintrust-ui-components";
import {
  AutocompleteBase,
  Box,
  Loader,
  RegularTextField,
} from "@hexocean/braintrust-ui-components";

import styles from "./styles.module.scss";

type InvitationsAutocompleteProps<
  ValueType,
  Multiple extends boolean,
> = DistributiveOmit<
  AutocompleteBaseProps<ValueType, Multiple, true, false>,
  "renderInput"
> & {
  inputProps?: Partial<AutocompleteRenderInputParams["InputProps"]>;
  placeholder: string;
};

export const InvitationsAutocomplete = <ValueType, Multiple extends boolean>({
  className,
  inputProps,
  placeholder,
  ...props
}: InvitationsAutocompleteProps<ValueType, Multiple>) => {
  return (
    <AutocompleteBase
      renderTags={() => null}
      loadingText={
        <Box p={3} position={"relative"}>
          <Loader centered />
        </Box>
      }
      className={cs(styles.autocomplete, className)}
      componentsProps={{
        paper: {
          className: styles.listBox,
        },
      }}
      popupIcon={null}
      disableClearable
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderInput={(params) => {
        return (
          <RegularTextField
            {...params}
            InputProps={{ ...params.InputProps, ...inputProps }}
            className={styles.autocompleteInput}
            placeholder={placeholder}
          />
        );
      }}
      {...props}
    />
  );
};
