import React from "react";

import type { IPost } from "@js/types/give-and-get-help";

import type { Hashtag } from "../../types";
import type { PostItemProps } from "../post";
import { PostItem } from "../post";
import { PostsListContainer } from "../posts-list-container";
import type { TrendingHashtagsProps } from "../trending-hashtags/trending-hashtags";
import { shouldRenderHashtagsModule } from "../trending-hashtags/trending-hashtags";
import { TrendingHashtags } from "../trending-hashtags/trending-hashtags";
import { trendingHashtagsPosition } from "../trending-hashtags/trending-hashtags-config";

type PickedPostItemProps = Pick<
  PostItemProps,
  "onViewCommentClick" | "disablePostSubscription"
>;

export type PostsListProps = {
  posts: IPost[];
  trendingHashtagsProps?: Omit<TrendingHashtagsProps, "hashtags">;
  trendingHashtags?: Hashtag[];
} & PickedPostItemProps;

export const PostsList: React.FC<PostsListProps> = ({
  posts,
  trendingHashtagsProps,
  trendingHashtags,
  ...postItemProps
}) => {
  return (
    <PostsListContainer>
      {posts.map((post, index) => (
        <React.Fragment key={post.id}>
          <PostItem key={post.id} postData={post} {...postItemProps} />
          {!!trendingHashtags?.length &&
            shouldRenderHashtagsModule(
              posts.length,
              index,
              trendingHashtagsPosition,
            ) && (
              <TrendingHashtags
                hashtags={trendingHashtags}
                {...trendingHashtagsProps}
              />
            )}
        </React.Fragment>
      ))}
    </PostsListContainer>
  );
};
