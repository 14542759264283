import { useMemo } from "react";

import type { CreatableComboBoxProps } from "@hexocean/braintrust-ui-components";
import { CreatableComboBox } from "@hexocean/braintrust-ui-components";
import { useGetCompaniesQuery } from "@js/apps/freelancer/api";

import { useSearchPhrase } from "../../hooks/search-phrase";

export type CompaniesCreatableComboboxProps = Omit<
  CreatableComboBoxProps<true>,
  "options"
>;

export const CompaniesCreatableCombobox = (
  props: CompaniesCreatableComboboxProps,
) => {
  const { onInputChange, searchPhrase } = useSearchPhrase();

  const { data } = useGetCompaniesQuery({
    search: searchPhrase,
    page: 1,
    page_size: 100,
  });

  const options = useMemo(() => {
    return (data?.results || []).map((opt) => ({
      value: opt.name,
      label: opt.name,
    }));
  }, [data]);

  return (
    <CreatableComboBox
      onInputChange={onInputChange}
      options={options}
      disableClearable
      {...props}
    />
  );
};
