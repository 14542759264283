import React from "react";

import { Typography, useMediaQuery } from "@hexocean/braintrust-ui-components";

export const EmployerInvoicesTitle = () => {
  const isMobile = useMediaQuery("sm");
  return (
    <Typography
      component="h1"
      variant="title"
      size={isMobile ? "medium" : "large"}
      fontWeight={400}
    >
      Invoices
    </Typography>
  );
};
