import React from "react";

import { useGetJobQuery } from "@js/apps/jobs/api";
import { useQueryParams } from "@js/hooks";

export const SearchedJobTitle = () => {
  const { invite_to_job, job: jobParam } = useQueryParams();
  const jobId =
    !!invite_to_job && Number(jobParam) ? Number(jobParam) : undefined;

  const { data: job } = useGetJobQuery(
    { id: jobId as number },
    { skip: !jobId },
  );

  return job ? <h3>{job.title}</h3> : null;
};
