import { createFiltersFormAndHook } from "@js/apps/filters";

import type { GetSpaceMembersParams } from "../../types";

type PeopleFilters = Omit<GetSpaceMembersParams, "id">;

const defaultValues: PeopleFilters = {
  page: undefined,
  search: "",
};

export const [PeopleFiltersForm, usePeopleFilters] =
  createFiltersFormAndHook<PeopleFilters>({
    submitOnChange: true,
    defaultFormValues: defaultValues,
    defaultParamsValues: defaultValues,
  });
