import type { FC } from "react";
import React, { useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@hexocean/braintrust-ui-components";
import { Typography } from "@hexocean/braintrust-ui-components";
import { ExpandMoreIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./style.module.scss";

type BasicAccordionProps = {
  options: Array<{
    title: string;
    body: React.ReactNode;
  }>;
};
export const BasicAccordion = ({ options }: BasicAccordionProps) => {
  const [openKey, setOpenKey] = useState<string | null>(null);

  const handleToggle = (key: string) => {
    setOpenKey(openKey !== key ? key : null);
  };

  return (
    <div>
      {options.map(({ title, body }) => {
        return (
          <BasicAccordionItem
            key={title}
            handleToggle={handleToggle}
            open={openKey === title}
            title={title}
            body={body}
          />
        );
      })}
    </div>
  );
};

type BasicAccordionItemProps = {
  title: string;
  body: React.ReactNode;
  handleToggle: (data: string) => void;
  open: boolean;
};

const BasicAccordionItem: FC<BasicAccordionItemProps> = ({
  title,
  body,
  handleToggle,
  open,
}) => {
  return (
    <Accordion className={styles["basic-accordion"]} expanded={open}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon className={styles["basic-accordion__icon"]} />
        }
        className={styles["basic-accordion__title"]}
        onClick={() => handleToggle(title)}
      >
        <Typography
          component="p"
          variant="paragraph"
          size="small"
          fontWeight={500}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={styles["basic-accordion__body"]}>
        {typeof body === "string" ? (
          <Typography
            component="p"
            variant="paragraph"
            size="small"
            color="grey-1"
          >
            {body}
          </Typography>
        ) : (
          <>{body}</>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
