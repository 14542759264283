import { useEffect, useState } from "react";

import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";

type InterviewQuestionCardProps = {
  displayError: boolean;
  error: string;
  label: string;
  question: string;
  onChange: (question: string) => void;
  onDelete: () => void;
};

export const InterviewQuestionCard = ({
  displayError,
  error,
  label,
  onChange,
  onDelete,
  question,
}: InterviewQuestionCardProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    const textareaInput = document.getElementById(label);
    if (!textareaInput) return;

    const adjustHeight = () => {
      if (
        textareaInput.scrollHeight > parseInt(textareaInput.style.height || "0")
      ) {
        textareaInput.style.height = "auto";
        textareaInput.style.height = `${textareaInput.scrollHeight}px`;
      }
    };

    textareaInput.addEventListener("input", adjustHeight);
    adjustHeight();
  }, [label]);

  return (
    <Box paddingLeft={4}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb={1}
      >
        <Typography component="h1" fontWeight={500} size="medium">
          {label}
        </Typography>
        <IconButton
          sx={{ padding: "0px !important" }}
          variant="transparent"
          onClick={onDelete}
          size="x-small"
          aria-label="Remove question"
        >
          <img
            src={`${SETTINGS.STATIC_URL}jobs/trash.svg`}
            alt="trash"
            width="16"
            height="20"
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          border: isActive ? "2px solid" : "1px solid",
          borderColor:
            displayError && !!error
              ? "var(--negative-2)"
              : isActive
                ? "var(--black)"
                : "var(--grey-4)",
          borderRadius: "4px",
          outlineColor:
            displayError && !!error ? "var(--negative-2)" : "var(--black)",
          padding: "12px 16px 3px 16px",

          "& textarea::-webkit-resizer": {
            background: `url(${SETTINGS.STATIC_URL}jobs/resize.svg) no-repeat center center`,
          },
        }}
      >
        <textarea
          id={label}
          placeholder="What would you like to ask?"
          value={question}
          onChange={(e) => onChange(e.target.value)}
          style={{
            resize: "vertical",
            overflow: "hidden",
            padding: "0px 0px 12px",
            fontSize: "16px",
            width: "100%",
            border: "none",
            outline: "none",
            minHeight: "35px",
          }}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
        />
      </Box>
      {displayError && !!error && (
        <Typography component="span" size="small" color="negative">
          {error}
        </Typography>
      )}
    </Box>
  );
};
