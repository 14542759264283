import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { isEqual } from "underscore";

import type {
  JobFilters,
  JobFiltersFetchParams,
} from "@js/apps/common/components/filters";
import { useJobFilters } from "@js/apps/common/components/filters";
import type { RoleFormType } from "@js/apps/common/components/filters/forms/role-form";
import {
  deleteSavedFilters,
  saveFilters,
} from "@js/apps/common/components/save-filters/actions";
import { useUser } from "@js/apps/common/hooks";
import type { SavedFilters } from "@js/apps/filters/types";
import { valuesToSearchParams } from "@js/apps/filters/utils";
import { useGetJobsQuery } from "@js/apps/jobs/api";
import { DeletedSavedFiltersSnackbarContent } from "@js/apps/jobs/components";
import {
  JOB_LOCATION,
  type JobLocationKey,
} from "@js/apps/jobs/context/job-location-context";
import {
  popularSearchTabToUrl,
  searchTabToUrl,
} from "@js/apps/universal-search/constants";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector, useNavigate } from "@js/hooks/";
import { LocalStorage } from "@js/services";
import type { SavedJobFilter } from "@js/types/jobs";
import { convertValuesToString } from "@js/utils";

import { savedFilterLoaded } from "../../actions";
import { getFiltersStorageKey } from "../../utils";
import { useGetActiveFilterName } from "../active-filter-name";
import { useLoadSessionSavedFiltersOnMount } from "../session-filters";

const JOB_FILTERS_ENDPOINT = "job_filters";

export type UseJobListingArg = {
  location: JobLocationKey;
  defaultFilters?: { role: RoleFormType["role"] };
};

export const useJobListing = ({
  defaultFilters,
  location,
}: UseJobListingArg) => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const freelancerProfile = useAppSelector(
    (state) => state.freelancer.freelancerProfile,
  );

  const navigate = useNavigate();
  const { filters: filtersFromParams, isAnyFilterApplied } = useJobFilters();
  const [, setSearchParams] = useSearchParams();

  const { loadedFilters } = useGetActiveFilterName(location);

  const filters = useMemo(
    () => ({
      ...defaultFilters,
      ...filtersFromParams,
    }),
    [defaultFilters, filtersFromParams],
  );

  const loadNewFilters = useCallback(
    (savedFilters: SavedFilters) => {
      const { filters: filtersSaved } = savedFilters;
      const newSearchParams = valuesToSearchParams(filtersSaved);
      const role = Number(newSearchParams.get("role"));
      const freelancerRole = freelancerProfile?.role?.id;

      if (role && freelancerRole && role !== freelancerRole) {
        navigate(
          {
            pathname: "/jobs",
            search: `${newSearchParams.toString()}`,
          },
          { replace: true },
        );
      } else {
        setSearchParams(newSearchParams);
      }

      dispatch(savedFilterLoaded({ name: savedFilters.name, location }));
    },
    [
      freelancerProfile?.role?.id,
      dispatch,
      location,
      navigate,
      setSearchParams,
    ],
  );

  const deleteSavedFilter = async (savedFilters: SavedJobFilter) => {
    const { filters: filtersSaved, name } = savedFilters;
    await dispatch(deleteSavedFilters(JOB_FILTERS_ENDPOINT, savedFilters.id));

    Snackbar.custom(
      <DeletedSavedFiltersSnackbarContent
        savedFilter={savedFilters}
        onUndoClick={() => {
          dispatch(
            saveFilters({ filters: filtersSaved, name }, JOB_FILTERS_ENDPOINT),
          );
        }}
      />,
      {
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      },
    );
  };

  const filtersStorageKey = getFiltersStorageKey(location);

  const { isReadyToFetchListing } = useLoadSessionSavedFiltersOnMount({
    isAnyFilterApplied,
    filtersStorageKey,
  });

  const {
    data: jobsData,
    isFetching,
    refetch: fetchJobs,
    isUninitialized,
    isSuccess,
    isError,
    error,
    originalArgs,
  } = useGetJobsQuery(prepareRequestFilters(filters), {
    skip: !isReadyToFetchListing,
    refetchOnMountOrArgChange: true,
  });

  const resetLoadedFilters = useCallback(
    (jobFiltersParams: Partial<JobFilters>) => {
      if (!loadedFilters) {
        return;
      }

      if (
        !isEqual(
          convertValuesToString(jobFiltersParams),
          convertValuesToString(loadedFilters),
        )
      ) {
        dispatch(
          savedFilterLoaded({
            location,
            name: null,
          }),
        );
      }
    },
    [dispatch, loadedFilters, location],
  );

  useEffect(() => {
    const searchWithoutIgnoredParams = window.location.search?.replaceAll(
      /&*(page=\d|show_saved_searches_drawer=true)/g,
      "",
    );

    if (!isSuccess) return;

    resetLoadedFilters(filtersFromParams);

    if (isAnyFilterApplied) {
      LocalStorage.setItem(
        filtersStorageKey,
        searchWithoutIgnoredParams.slice(1),
      );
    } else {
      LocalStorage.removeItem(filtersStorageKey);
    }
  }, [
    isSuccess,
    filtersFromParams,
    resetLoadedFilters,
    isAnyFilterApplied,
    filtersStorageKey,
  ]);

  useEffect(() => {
    if (!isError) return;

    //not having error status equals to no response code from request, which means that request didn't go off due to no network connection
    if (
      error !== null &&
      typeof error === "object" &&
      !("status" in error) &&
      originalArgs
    ) {
      Snackbar.error("Failed to fetch jobs due to network error");
      //in case of error and some previous data existing in cache, we make next request (latest succeeded one) using cached response
      setSearchParams(valuesToSearchParams(originalArgs));
    } else {
      Snackbar.error("Wrong filter value provided, failed to fetch jobs");
      navigate(
        location === JOB_LOCATION.job_search
          ? searchTabToUrl["JOBS"]
          : popularSearchTabToUrl["JOBS"],
        { replace: true },
      );
    }
  }, [isError, error, navigate, originalArgs, setSearchParams, location]);

  return {
    user,
    jobs: jobsData?.results || [],
    total: jobsData?.count || 0,
    loading: isUninitialized || isFetching,
    isAnyFilterApplied,
    filters,
    filtersFromParams,
    loadNewFilters,
    deleteSavedFilter,
    refetchJobs: fetchJobs,
  };
};

const prepareRequestFilters = (filters: Partial<JobFiltersFetchParams>) => {
  const filtersCopy: Partial<JobFiltersFetchParams> = { ...filters };

  if (filtersCopy.search && filtersCopy.ordering === "-score") {
    filtersCopy.ordering = "-search_score";
  }

  return filtersCopy;
};
