import type { FC } from "react";
import PoweredByCoinbase from "@static/on-ramp/poweredByCoinbase.svg";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import {
  ArrowRightTopIcon,
  SwapVerticalIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { OfferVisual } from "@js/apps/common/components/offer-visual";
import { ReceiptDetails } from "@js/apps/common/components/price-details";
import { purchaseWalletOnrampClicked } from "@js/apps/jobs/actions";
import { openPendingTransactionModal } from "@js/apps/on-ramp/components/onramp-modal/pending-transaction-modal";
import { useCoinbasePay } from "@js/apps/on-ramp/hooks/useCoinbase";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";
import type { HelpOffer } from "@js/types/give-and-get-help";

import styles from "./styles.module.scss";

type HelpOfferBuyBTRSTModalProps = {
  offer: HelpOffer;
};

export const openHelpOfferBuyBTRSTModal = (
  props: HelpOfferBuyBTRSTModalProps,
) => {
  ModalInstance.open({
    children: <HelpOfferBuyBTRSTModalWrapper {...props} />,
  });
};

export const HelpOfferBuyBTRSTModalWrapper: FC<HelpOfferBuyBTRSTModalProps> = ({
  offer,
}) => {
  const dispatch = useAppDispatch();
  const { openOnRamp } = useCoinbasePay();

  const handleContinueAction = () => {
    dispatch(purchaseWalletOnrampClicked());
    ModalInstance.close();
    openPendingTransactionModal();
    openOnRamp({ tokensToBuy: Number(offer.missing_tokens) });
  };

  return (
    <div className={styles.onrampFirstStep}>
      <ModalContent offer={offer} onClick={handleContinueAction} />
    </div>
  );
};

const ModalContent: FC<
  Pick<HelpOfferBuyBTRSTModalProps, "offer"> & { onClick: () => void }
> = ({ offer, onClick }) => {
  return (
    <Box display="flex" flexDirection="column" gap={{ xs: 2, md: 3 }}>
      <OfferVisual
        exchangeFrom={{
          currency: "USD",
          avatar_thumbnail: `${SETTINGS.STATIC_URL}flags/us-round.svg`,
          has_avatar_set: false,
          gravatar: "",
        }}
        exchangeTo={{
          currency: "BTRST",
          avatar_thumbnail: "",
          has_avatar_set: false,
          gravatar: "",
        }}
        icon={<SwapVerticalIcon />}
        isInverse
        largePill
      />
      <Typography component="p" variant="title" size="small" fontWeight={400}>
        Purchase BTRST for this offer
      </Typography>

      <ReceiptDetails offer={offer} />

      <LegalDisclaimer />

      <Button
        onClick={onClick}
        fullWidth
        variant="positive"
        shape="squared"
        target="_blank"
        rel="noreferrer"
        title=" Initiate Coinbase Pay"
        endIcon={<ArrowRightTopIcon />}
      >
        Get started with Coinbase
      </Button>
      <Box display="flex" alignItems="center" justifyContent="center">
        <PoweredByCoinbase />
      </Box>
    </Box>
  );
};

const LegalDisclaimer = () => {
  return (
    <Typography component="p" variant="paragraph" size="medium">
      By confirming payment you accept our{" "}
      <Typography
        component="a"
        className={styles.link}
        href={SETTINGS.PRIVACY_DOC_URL}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
        RouterLink={RouterLink}
      >
        Privacy Policy
      </Typography>
      ,{" "}
      <Typography
        component="a"
        className={styles.link}
        href={SETTINGS.PROFESSIONAL_NETWORK_TERMS_DOC_URL}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
        RouterLink={RouterLink}
      >
        Refund Policy
      </Typography>{" "}
      and{" "}
      <Typography
        component="a"
        className={styles.link}
        href={SETTINGS.TERMS_DOC_URL}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
        RouterLink={RouterLink}
      >
        Payments Terms & Conditions
      </Typography>
    </Typography>
  );
};
