import { Navigate } from "react-router-dom";

import { useFreelancerMyWorkMainPath } from "../../hooks/use-freelancer-my-work-main-path";
import { FreelancerDashboardLayout } from "../../views/dashboard/layout";

export const MyWorkIndexRedirect = () => {
  const { myWorkMainPath } = useFreelancerMyWorkMainPath();

  return (
    // FreelancerDashboardLayout required to prevent screen flash
    <FreelancerDashboardLayout>
      <Navigate to={myWorkMainPath} />
    </FreelancerDashboardLayout>
  );
};
