import { Typography } from "@hexocean/braintrust-ui-components";
import { DoneAllIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Modal } from "@js/components/modal";

import styles from "./style.module.scss";

const CC_PAYMENT_SUCCESS_MODAL_ID = "cc-payment-success-modal";
const ACH_PAYMENT_SUCCESS_MODAL_ID = "ach-payment-success-modal";

export const CCPaymentSuccessModalInstance = Modal(CC_PAYMENT_SUCCESS_MODAL_ID);
export const ACHPaymentSuccessModalInstance = Modal(
  ACH_PAYMENT_SUCCESS_MODAL_ID,
);

const CCPaymentMethodSuccessModalContent = () => {
  return (
    <div className={styles.paymentSuccessModal}>
      <DoneAllIcon className={styles.paymentSuccessModalIcon} />
      <Typography component="p" size="large">
        Payment successful!
      </Typography>
      <Typography component="p">Thank you.</Typography>
    </div>
  );
};

const ACHPaymentSuccessModalContent = () => {
  return (
    <div className={styles.paymentSuccessModal}>
      <DoneAllIcon className={styles.paymentSuccessModalIcon} />
      <Typography component="p" size="large">
        Thank you. Your payment is being processed right now. You'll be notified
        with the status of it.
      </Typography>
    </div>
  );
};

export const openACHPaymentSuccessModal = ({
  onClose,
}: {
  onClose?: () => void;
}) => {
  ACHPaymentSuccessModalInstance.open({
    children: <ACHPaymentSuccessModalContent />,
    onClose,
  });
};

export const openCCPaymentMethodSuccessModal = ({
  onClose,
}: {
  onClose?: () => void;
}) => {
  CCPaymentSuccessModalInstance.open({
    children: <CCPaymentMethodSuccessModalContent />,
    onClose,
  });
};
