import React from "react";

import type { TalentFilterLocation } from "@js/apps/filters/types";

import type { TalentFiltersFetchParams } from "../../filters";
import { getFiltersToSave } from "../helpers";
import { SaveFiltersModalInstance } from "../save-filters-modal";
import { SaveSearchButton } from "../save-search-button";
import { SavedFilterTitle } from "../saved-filter-title";

import { openSaveTalentsFiltersModal } from "./save-talents-filters-modal";

type SaveFiltersProps = {
  filters: Partial<TalentFiltersFetchParams>;
  savedFilterName: string | null;
  location: TalentFilterLocation;
};

export const SaveTalentsFilters = ({
  filters,
  savedFilterName,
  location,
}: SaveFiltersProps) => {
  return (
    <>
      {savedFilterName ? (
        <SavedFilterTitle title={savedFilterName} />
      ) : (
        <SaveSearchButton
          onClick={() => {
            const filtersToSave = getFiltersToSave(filters);
            openSaveTalentsFiltersModal({
              filters: filtersToSave,
              location: location,
            });
          }}
        />
      )}
      <SaveFiltersModalInstance />
    </>
  );
};
