import React from "react";

import { Button, Chip, Typography } from "@hexocean/braintrust-ui-components";
import { PaymentsPanelItem } from "@js/apps/settings/views/payments/payments-panel-item";
import type { WithdrawalMethodType } from "@js/types/withdrawals";

export type WithdrawalMethodListItemProps = {
  withdrawalMethod: WithdrawalMethodType;
  onDelete: (withdrawalMethod: WithdrawalMethodType) => void;
  onSetAsDefault: (withdrawalMethod: WithdrawalMethodType) => void;
  onAddRecipientsClick: (withdrawalMethod: WithdrawalMethodType) => void;
  borderBottom: boolean;
};

export const WithdrawalMethodListItem = ({
  withdrawalMethod,
  onDelete,
  onSetAsDefault,
  onAddRecipientsClick,
  borderBottom,
}: WithdrawalMethodListItemProps) => {
  const isStripeWithdrawalMethod =
    withdrawalMethod.content_type ===
    ENUMS.WithdrawalContentType.StripeWithdrawalMethod;
  const withdrawalMethodTitle = isStripeWithdrawalMethod
    ? "Stripe account"
    : withdrawalMethod.method?.account_name;

  const recipientsWithPayoutEnabled =
    withdrawalMethod.method && "recipients" in withdrawalMethod.method
      ? withdrawalMethod.method.recipients
          .filter(
            (recipient) =>
              recipient.payouts_enabled && recipient.account_onboarded,
          )
          .map((recipient) => recipient.company_node_name)
          .join(", ")
      : "";

  const recipientsWithPayoutPaused =
    withdrawalMethod.method && "recipients" in withdrawalMethod.method
      ? withdrawalMethod.method.recipients
          ?.filter(
            (recipient) =>
              !recipient.payouts_enabled && recipient.account_onboarded,
          )
          .map((recipient) => recipient.company_node_name)
          .join(", ")
      : "";

  const getSubheader = () => {
    let text = "";
    if (
      recipientsWithPayoutPaused.length === 0 &&
      recipientsWithPayoutEnabled.length === 0
    )
      return `Inactive method. At least one recipient with active status is required.`;
    if (recipientsWithPayoutEnabled.length) {
      text += `Active company nodes: ${recipientsWithPayoutEnabled}.`;
    }
    if (recipientsWithPayoutPaused.length) {
      if (text.length !== 0) text += " ";
      text += `Company nodes with paused payouts: ${recipientsWithPayoutPaused}.`;
    }
    return text;
  };

  return (
    <PaymentsPanelItem
      header={
        <>
          <div>
            <Typography component="span" fontWeight={500}>
              {withdrawalMethodTitle}
            </Typography>
          </div>
          <div>
            {!isStripeWithdrawalMethod && (
              <Typography
                component="span"
                size={withdrawalMethodTitle?.length > 0 ? "medium" : "large"}
              >
                Account ending in ...
                {withdrawalMethod.method?.account_number?.slice(-4)}
              </Typography>
            )}
          </div>
        </>
      }
      subheader={
        isStripeWithdrawalMethod
          ? getSubheader()
          : [withdrawalMethod.method.currency]
      }
      onDelete={() => onDelete(withdrawalMethod)}
      renderButtons={(buttons) => {
        return [
          withdrawalMethod.is_default ? (
            <Chip color="blue" label="Default" key="default-method-chip" />
          ) : (
            <Button
              key="make-default-button"
              aria-label="make default"
              onClick={() => onSetAsDefault(withdrawalMethod)}
              variant="tertiary"
              size="small"
            >
              <span
                style={{
                  fontWeight: 400,
                  whiteSpace: "nowrap",
                }}
              >
                Make Default
              </span>
            </Button>
          ),
          isStripeWithdrawalMethod && (
            <Button
              key="manage-recipients-button"
              aria-label="manage recipients"
              onClick={() => onAddRecipientsClick(withdrawalMethod)}
              variant="tertiary"
              size="small"
            >
              <span
                style={{
                  fontWeight: 400,
                  whiteSpace: "nowrap",
                }}
              >
                Manage recipients
              </span>
            </Button>
          ),
          ...buttons,
        ];
      }}
      borderBottom={borderBottom}
    />
  );
};
