import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useFreelancerProfile } from "@js/apps/freelancer/hooks";
import { SpaceNotificationCounter } from "@js/apps/spaces/components";
import { useMySpaces } from "@js/apps/spaces/hooks/use-my-spaces";

export const GiveGetHelpLabel = () => {
  const freelancerProfile = useFreelancerProfile();
  const { notificationsCount } = useMySpaces();

  return (
    <SpaceNotificationCounter notificationCounter={notificationsCount}>
      <Box width={1} display="flex" alignItems="center">
        Career help
        {!!freelancerProfile &&
          !freelancerProfile?.user?.has_visited_career_help && (
            <Box
              title="coachmark-label"
              ml={1}
              maxWidth={100}
              bgcolor="var(--medium-violet)"
              color="var(--dark-violet)"
              borderRadius="8px"
              padding="0px 8px"
              display="flex"
              alignItems="center"
            >
              <Typography
                className="display-ellipsis"
                variant="label"
                size="small"
                component="span"
              >
                New
              </Typography>
            </Box>
          )}
      </Box>
    </SpaceNotificationCounter>
  );
};
