import {
  Box,
  Button,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { Emoji } from "@js/components/emoji";
import type { Space } from "@js/types/spaces";

import { useJoinThisSpace } from "../../hooks/use-join-this-space";

import { JoinThisSpaceModalInstance } from "./join-this-space-modal-instance";

import styles from "./styles.module.scss";

export const JoinThisSpaceModalContent = ({ space }: { space: Space }) => {
  const spaceId = space.id;
  const isMobile = useMediaQuery("sm");
  const { handleJoinSpaceClick } = useJoinThisSpace(Number(spaceId));

  const handleJoinThisSpaceClick = () => {
    JoinThisSpaceModalInstance.close();
    handleJoinSpaceClick();
  };

  return (
    <Box>
      <Box className={styles.topContent}>
        <Stack
          direction="row"
          sx={{
            gap: 1,
            alignItems: "center",
            alignSelf: { xs: "start", sm: "initial" },
          }}
        >
          <Box className={styles.handEmoji}>
            <Emoji emoji="🤝" />
          </Box>
          <Typography
            component="h2"
            fontWeight={500}
            size="medium"
            variant="label"
          >
            Become a member
          </Typography>
        </Stack>

        <img
          src={`${SETTINGS.STATIC_URL}spaces/join-modal.svg`}
          alt=""
          width={isMobile ? "343" : "616"}
          height={isMobile ? "160" : "280"}
        />
      </Box>

      <Box className={styles.bottomContent}>
        <Typography
          component="h3"
          variant="title"
          textAlign="center"
          size={isMobile ? "small" : "medium"}
          fontWeight={400}
        >
          Be part of the discussion
        </Typography>
        <Typography
          component="p"
          variant="paragraph"
          textAlign="center"
          size={isMobile ? "small" : "medium"}
        >
          Join{" "}
          <Typography
            component="span"
            variant="paragraph"
            size={isMobile ? "small" : "medium"}
            fontWeight={500}
          >
            {space?.name}
          </Typography>{" "}
          to leave comments, react, and connect with other members who share
          your interests.
        </Typography>

        <Button
          sx={{ marginTop: { xs: "16px", sm: "32px" } }}
          variant="primary"
          onClick={handleJoinThisSpaceClick}
        >
          Join this space
        </Button>
      </Box>
    </Box>
  );
};
