import { PageHead } from "@js/components/page-head";
import { useAppSelector } from "@js/hooks/redux";

export const PageTitle = ({ children = "" }: { children: string }) => {
  const notificationsNumber = useAppSelector((state) => {
    return (
      state.notifications?.siteNotifications?.filter(
        (notification) => !notification.read,
      )?.length || null
    );
  });

  const notificationsString = !!notificationsNumber
    ? `(${notificationsNumber}) `
    : "";

  const prefix = "Braintrust | ";
  const pageTitle = prefix + children.replace(prefix, "");

  return (
    <PageHead titleTemplate={`${notificationsString}%s`}>
      <title>{pageTitle}</title>
    </PageHead>
  );
};
