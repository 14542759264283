import { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import { globalTimeoutsDelays, useTimeout } from "@js/hooks/timeout";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import { logViewEvent } from "@js/services/analytics/event-logging";

import { prepareDataForViewEventLogging } from "../../utils";
import {
  disableDefaultViewEventLogging,
  enableDefaultViewEventLogging,
} from "../use-log-view-event";

export const useLogViewTalentProfilePage = (
  isProfileOwner: boolean | undefined,
): void => {
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const { pathname } = location;

  // use refs so the delayed callback is called with the latest values
  const locationRef = useEffectRef(location);
  const paramsRef = useEffectRef(params);
  const searchParamsRef = useEffectRef(searchParams);

  useEffect(() => {
    disableDefaultViewEventLogging();
    return () => {
      enableDefaultViewEventLogging();
    };
  }, []);

  const isDataComplete: boolean = isProfileOwner !== undefined;

  useTimeout(
    () => {
      if (!isDataComplete) {
        return;
      }

      const { event, eventProperties } = prepareDataForViewEventLogging(
        locationRef.current,
        searchParamsRef.current,
        paramsRef.current,
      );

      eventProperties.is_profile_owner = isProfileOwner;

      logViewEvent(event, eventProperties);
    },
    globalTimeoutsDelays.log_view_event,
    [isDataComplete, pathname, isProfileOwner],
  );
};
