import { useMemo } from "react";
import type { WrappedFieldsProps } from "redux-form";

import type { SelectChangeEvent } from "@hexocean/braintrust-ui-components";
import { Loader } from "@hexocean/braintrust-ui-components";
import { useJobSelectList } from "@js/apps/invoices/hooks/job-select-list";
import { SelectField } from "@js/forms/fields";
import { deduplicateArrayOfObjects } from "@js/utils";

import styles from "./style.module.scss";

type SelectedJob = {
  label: React.ReactNode;
  title: string;
  value: number;
};

export type SelectJobFieldProps = WrappedFieldsProps & {
  canChangeJob: boolean;
  isCopy: boolean;
};

export const SelectJobField = ({
  job,
  employer: employerField,
  canChangeJob,
  isCopy,
}: SelectJobFieldProps) => {
  const { data: jobSelectList, isLoading } = useJobSelectList();
  const jobSelectOptions: SelectedJob[] = useMemo(() => {
    const defaultTooltipTextForDisabledProjects =
      "Before you can be paid, you must set default banking information.";

    return deduplicateArrayOfObjects(
      jobSelectList.map(
        ({
          id,
          title,
          employer,
          invoice_can_be_created,
          invoice_cannot_be_created_reason,
        }) => ({
          label: (
            <>
              <span className="color-gray-2"> {employer.name}</span>&nbsp;
              {title}
            </>
          ),
          disabled: !invoice_can_be_created,
          tooltipText:
            invoice_cannot_be_created_reason ||
            defaultTooltipTextForDisabledProjects,
          title: `${employer.name} ${title}`,
          value: id,
        }),
      ),
      "value",
    );
  }, [jobSelectList]);

  const onChange = (event: SelectChangeEvent) => {
    const jobId = Number(event.target.value);

    const employerId =
      jobSelectList.find(({ id }) => jobId === id)?.employer.id || undefined;

    job.input.onChange(jobId);
    employerField.input.onChange(employerId);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !jobSelectOptions.length) {
    return (
      <SelectField
        variant="standard"
        label="Select Project"
        id="job"
        options={[]}
        input={job.input}
        meta={job.meta}
        disabled={true}
        helperText="No jobs that can be invoiced yet"
        error={job.meta.error || employerField.meta.error}
        onChange={onChange}
      />
    );
  }

  const selectedJob = jobSelectOptions.find(
    (value) => value.value === job.input.value,
  );

  const noInteractive =
    selectedJob && !job.meta.initial?.job && !canChangeJob && !isCopy;

  if (noInteractive) {
    return (
      <>
        <div className={styles.jobTitle} title={selectedJob.title}>
          {selectedJob.label}
        </div>
      </>
    );
  }

  if (canChangeJob) {
    return (
      <SelectField
        variant="standard"
        label="Select Project"
        id="job"
        options={jobSelectOptions}
        input={job.input}
        meta={job.meta}
        error={job.meta.error || employerField.meta.error}
        onChange={onChange}
      />
    );
  }

  // shouldn't happen, but it can, when we use wrong job query param (job without permission/not existing job)
  console.error("Invoice: select project: null rendered", {
    jobInput: job.input,
    jobMeta: job.meta,
    employerInput: employerField.input,
    employerMeta: employerField.meta,
    canChangeJob,
    isCopy,
    selectedJob,
  });

  return null;
};
