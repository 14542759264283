export type FreelancerBidFilterOptionType = Array<{
  value: string;
  label: string;
}>;

export const FORMS_FREELANCER_BID_FILTER_OPTION = [
  {
    value: ENUMS.FreelancerBidFilterStatus.ALL,
    label:
      ENUMS.FreelancerBidFilterStatusLabels[
        ENUMS.FreelancerBidFilterStatus.ALL
      ],
  },
  {
    value: ENUMS.FreelancerBidFilterStatus.TOP_MATCHES,
    label:
      ENUMS.FreelancerBidFilterStatusLabels[
        ENUMS.FreelancerBidFilterStatus.TOP_MATCHES
      ],
  },
  {
    value: ENUMS.FreelancerBidFilterStatus.INVITED,
    label:
      ENUMS.FreelancerBidFilterStatusLabels[
        ENUMS.FreelancerBidFilterStatus.INVITED
      ],
  },
];

const FORMS_FREELANCER_BID_FILTER_OPTION_MATCHER = [
  {
    value: ENUMS.FreelancerBidFilterStatus.NOT_APPROVED,
    label:
      ENUMS.FreelancerBidFilterStatusLabels[
        ENUMS.FreelancerBidFilterStatus.NOT_APPROVED
      ],
  },
];

export const FORMS_FREELANCER_BID_FILTER_OPTION_APPROVED = [
  {
    value: ENUMS.FreelancerBidFilterStatus.APPROVED,
    label:
      ENUMS.FreelancerBidFilterStatusLabels[
        ENUMS.FreelancerBidFilterStatus.APPROVED
      ],
  },
];

export const FORMS_FREELANCER_BID_FILTER_OPTION_ALL = [
  ...FORMS_FREELANCER_BID_FILTER_OPTION,
  ...FORMS_FREELANCER_BID_FILTER_OPTION_MATCHER,
  ...FORMS_FREELANCER_BID_FILTER_OPTION_APPROVED,
];

export const mergeFreelancerBidFilterOptions = (
  filterOptions: FreelancerBidFilterOptionType[],
) => {
  return filterOptions.reduce((merged, array) => merged.concat(array), []);
};

export const getOptions = (
  isMatcher: boolean,
  jobType: EnumType<typeof ENUMS.JobType>,
) => {
  if (isMatcher) {
    return FORMS_FREELANCER_BID_FILTER_OPTION_ALL;
  } else if (jobType === "grant") {
    return mergeFreelancerBidFilterOptions([
      FORMS_FREELANCER_BID_FILTER_OPTION,
      FORMS_FREELANCER_BID_FILTER_OPTION_APPROVED,
    ]);
  } else {
    return FORMS_FREELANCER_BID_FILTER_OPTION;
  }
};
