import { type FC } from "react";
import type { ConfigProps } from "redux-form";
import { Field, formValueSelector, isDirty } from "redux-form";

import { Box, Button, Grid } from "@hexocean/braintrust-ui-components";
import { GraterOrEqualZeroIntegerNumberFormat } from "@hexocean/braintrust-ui-components";
import { CommonConfirmationModal } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import {
  CheckboxField,
  CompaniesField,
  NumberField,
  SelectField,
  TextareaField,
  TextField,
} from "@js/forms/fields";
import {
  noFutureMonthValidator,
  noFutureYearValidator,
  required,
} from "@js/forms/validators";
import { useAppSelector } from "@js/hooks";
import { getMonths } from "@js/utils";

import { ADD_ANOTHER_ITEM } from "../../constants";
import type { CreateWorkExperienceParams } from "../../types";

export type HistoryWorkFormData = CreateWorkExperienceParams;

export const FREELANCER_WORK_HISTORY_FORM_ID = "freelancer-work-history-form";

const WorkHistoryFormInstance = createFormInstance<
  HistoryWorkFormData,
  unknown
>(FREELANCER_WORK_HISTORY_FORM_ID, {
  onChange: (values, dispatch, props) => {
    if (!!values.is_currently_working && props.change) {
      dispatch(props.change("month_to", null));
      dispatch(props.change("year_to", null));
    }
  },
  validate: (values) => {
    const errors: Record<string, string | undefined> = {};

    errors.month_from = noFutureMonthValidator(
      values.year_from,
      values.month_from,
    );
    errors.month_to = noFutureMonthValidator(values.year_to, values.month_to);

    return errors;
  },
});

const formSelector = formValueSelector(FREELANCER_WORK_HISTORY_FORM_ID);

type WorkHistoryFormProps = Pick<
  ConfigProps<HistoryWorkFormData>,
  "onSubmit" | "onSubmitSuccess" | "initialValues"
> & {
  setSubmitType?: (type: string | null) => void;
  editMode?: boolean;
  saveButtonText?: string;
};

export const WorkHistoryForm: FC<WorkHistoryFormProps> = ({
  setSubmitType,
  editMode,
  saveButtonText,
  ...props
}) => {
  const isCurrentlyWorking = useAppSelector((state) =>
    formSelector(state, "is_currently_working"),
  );
  const isFormDirty = useAppSelector((state) =>
    isDirty(FREELANCER_WORK_HISTORY_FORM_ID)(state),
  );

  const showActionButton = isFormDirty || !!props.initialValues;

  const months = [
    ...getMonths().map((month, i) => ({
      value: i + 1,
      label: month,
    })),
  ];

  return (
    <WorkHistoryFormInstance {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field
            variant="outlined"
            id="title"
            name="title"
            component={TextField}
            label="Title*"
            validate={[required]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            id="new_company"
            name="new_company"
            component={CompaniesField}
            label="Company*"
            validate={[required]}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={CheckboxField}
            id="is_currently_working"
            name="is_currently_working"
            label="I currently work here"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <Field
                component={SelectField}
                options={months}
                id="month_from"
                name="month_from"
                placeholder="Month"
                label="Start date*"
                validate={[required]}
              />
            </Grid>
            <Grid item sm={6} xs={12} marginTop="34px">
              <Field
                component={NumberField}
                inputProps={{ format: "####" }}
                NumberFormat={GraterOrEqualZeroIntegerNumberFormat}
                id="year_from"
                name="year_from"
                placeholder="Year"
                validate={[required, noFutureYearValidator]}
              />
            </Grid>
            {!isCurrentlyWorking && (
              <>
                <Grid item sm={6} xs={12}>
                  <Field
                    component={SelectField}
                    options={months}
                    id="month_to"
                    name="month_to"
                    placeholder="Month"
                    label="End date*"
                    validate={[required]}
                  />
                </Grid>
                <Grid item sm={6} xs={12} mt="34px">
                  <Field
                    component={NumberField}
                    inputProps={{ format: "####" }}
                    NumberFormat={GraterOrEqualZeroIntegerNumberFormat}
                    id="year_to"
                    name="year_to"
                    placeholder="Year"
                    validate={[required, noFutureYearValidator]}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12} mt={3}>
            <Field
              id="description"
              name="description"
              component={TextareaField}
              maxLength={10000}
              validate={[required]}
              label="Description*"
            />
          </Grid>
        </Grid>
      </Grid>
      {!editMode && showActionButton && (
        <Box mt={3}>
          <Button
            variant="tertiary"
            onClick={() => {
              if (setSubmitType) {
                setSubmitType(ADD_ANOTHER_ITEM);
              }
            }}
            type="submit"
          >
            Save and add another
          </Button>
        </Box>
      )}
      <Box mt={4} gap={2} display="flex" justifyContent="flex-end">
        {editMode && (
          <Button
            variant="black-outlined"
            shape="squared"
            onClick={() => {
              if (setSubmitType) {
                setSubmitType(null);
              }

              CommonConfirmationModal.close();
            }}
            type="submit"
          >
            Discard changes
          </Button>
        )}
        {showActionButton && (
          <Button
            variant="positive"
            shape="squared"
            type="submit"
            onClick={() => {
              if (setSubmitType) {
                setSubmitType(null);
              }
            }}
          >
            {editMode ? "Save changes" : saveButtonText || "Save"}
          </Button>
        )}
      </Box>
    </WorkHistoryFormInstance>
  );
};
