import { Typography } from "@hexocean/braintrust-ui-components";

export const PeopleTitle = () => {
  return (
    <Typography
      component="h2"
      variant="paragraph"
      size="large"
      sx={{
        flex: { xs: "1 0 100%", md: "1 1 auto" },
        whiteSpace: "nowrap",
      }}
    >
      Members in this space
    </Typography>
  );
};
