import { LocalStorage } from "@js/services";
import type { Employer } from "@js/types/employer";

import type { OnboardingOrganizationFields } from "../../components/organization/schema";
import { OnboardingOrganizationFieldsSchema } from "../../components/organization/schema";

export const getOrganizationFormInitialValues = (
  employerData: Employer | undefined,
): OnboardingOrganizationFields | Record<string, never> => {
  if (!employerData) return {};
  const { industry, name, website, location_full } = employerData;
  return {
    industry_id: industry?.id,
    name,
    location_full,
    website: website || undefined,
  };
};

export const saveOrganizationFormChangesLocally = (
  values: OnboardingOrganizationFields,
): void => {
  LocalStorage.setItem(
    LocalStorage.keys.ONBOARDING_ORGANIZATION_FORM,
    JSON.stringify(values),
  );
};

export const getOrganizationFormLocalChanges = ():
  | OnboardingOrganizationFields
  | Record<string, never> => {
  const localStorageValues = LocalStorage.getItem(
    LocalStorage.keys.ONBOARDING_ORGANIZATION_FORM,
  );

  if (!localStorageValues) return {};

  const fields = OnboardingOrganizationFieldsSchema.safeParse(
    JSON.parse(localStorageValues),
  );
  if (fields.success) return fields.data;

  return {};
};

export const clearOrganizationFormLocalStorage = () => {
  LocalStorage.removeItem(LocalStorage.keys.ONBOARDING_ORGANIZATION_FORM);
};
