import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { useAppSelector } from "@js/hooks";

export const useWorkingHoursCoachMark = () => {
  const isAvailableForWork = useAppSelector(
    (state) => state.freelancer.freelancerProfile?.availability_for_work,
  );
  const freelancerProfile = useAppSelector(
    (state) => state.freelancer.freelancerProfile,
  );

  const isShowingAvailabilityCoachMark =
    !!freelancerProfile && freelancerProfile?.show_availability_coachmark;

  const shouldShowWorkingHoursCoachmark =
    !!freelancerProfile &&
    !!isAvailableForWork &&
    !isShowingAvailabilityCoachMark;

  const { data: workingHoursCoachMarkShown, isLoading } =
    useGetStorageValueQuery(
      { key: FRONTEND_STORAGE_KEYS.working_hours_coach_mark_shown },
      { skip: !shouldShowWorkingHoursCoachmark },
    );
  const [setStorageValue] = useSetStorageValueMutation();

  const showWorkingHoursCoachMark =
    !isLoading &&
    shouldShowWorkingHoursCoachmark &&
    !workingHoursCoachMarkShown;

  const handleCoachMarkClose = () => {
    setStorageValue({
      key: FRONTEND_STORAGE_KEYS.working_hours_coach_mark_shown,
      value: true,
    });
  };

  return {
    showWorkingHoursCoachMark,
    handleWorkingHoursCoachMarkClose: handleCoachMarkClose,
  };
};
