import _ from "underscore";

type ErrorWithMessage = {
  _error: string | string[];
};

type CaptchaError = ErrorWithMessage & {
  captcha: string;
};

export type ErrorWithDetail = {
  detail: string;
};

type ErrorWithErrors = {
  errors: { [key: string]: string };
};

type ErrorWithDataAndRootMessage = { data: { _error: string } };

export const isErrorWithErrors = (error: unknown): error is ErrorWithErrors => {
  const err = error as ErrorWithErrors;
  return !!err?.errors;
};

export const isErrorWithMessage = (
  error: unknown,
): error is ErrorWithMessage => {
  const err = error as ErrorWithMessage;
  return !!err?._error;
};

export const isCaptchaError = (error: unknown): error is CaptchaError => {
  return (error as CaptchaError).captcha !== undefined;
};

export const isErrorWithDetails = (
  error: unknown,
): error is ErrorWithDetail => {
  return (error as ErrorWithDetail).detail !== undefined;
};

export const isErrorWithDataAndRootMessage = (
  error: unknown,
): error is ErrorWithDataAndRootMessage => {
  const err = error as ErrorWithDataAndRootMessage;
  return !!err?.data?._error;
};

type ErrorWithDataAndStatus<T> = {
  data: { [key in keyof T]: string[] };
  status: number;
};

export const isErrorWithDataAndStatus = <T>(
  errors: unknown,
): errors is ErrorWithDataAndStatus<T> => {
  const err = errors as ErrorWithDataAndStatus<T>;

  if (err.data && _.isObject(err.data) && Object.keys(err.data).length > 0) {
    return true;
  }

  return false;
};
