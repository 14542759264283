import { createFilterOptions } from "@hexocean/braintrust-ui-components";
import type { TimezoneOption } from "@js/types/jobs";

export const timezoneOptions: TimezoneOption[] =
  SETTINGS.TIMEZONE_ABBREVIATIONS.map((timezone) => ({
    id: timezone.abbreviation,
    value: timezone.utc_offset,
    name: timezone.name,
  }));

export const timezoneFilters = createFilterOptions<{
  name: string;
  id: string;
  value: string;
}>({
  matchFrom: "any",
  stringify: (option) => option.name,
});
