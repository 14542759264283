import {
  Button,
  type ButtonProps,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";

type HelpOfferButtonPrimaryProps = DistributiveOmit<
  ButtonProps,
  "variant" | "shape" | "size"
>;

export const HelpOfferButtonPrimary = ({
  children,
  ...props
}: HelpOfferButtonPrimaryProps) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Button
      {...props}
      variant="primary"
      shape="squared"
      sx={{ "&&": { minWidth: { xs: "130px", sm: "initial" } }, ...props.sx }}
      size={isMobile ? "x-small" : "small"}
    >
      {children}
    </Button>
  );
};
