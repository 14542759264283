import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Box, Button } from "@hexocean/braintrust-ui-components";
import { fetchPublicInvitationDetails } from "@js/apps/auth/actions";
import { unauthenticatedUserJobApplyClicked } from "@js/apps/jobs/actions";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";
import { LocalStorage } from "@js/services";

import { addViewedPublicJob } from "../../apps/public-listing/helpers/viewed-public-jobs";

type NotLoggedInApplyButtonProps = {
  jobId: number;
  variant?: ButtonProps["variant"];
  fullWidth?: boolean;
  shape?: ButtonProps["shape"];
  size?: ButtonProps["size"];
  sx?: ButtonProps["sx"];
  className?: string;
  label?: string;
  openInNewTab?: boolean;
};

export const NotLoggedInApplyButton = ({
  className,
  jobId,
  variant,
  fullWidth,
  label = "Sign up to apply",
  openInNewTab,
  ...props
}: NotLoggedInApplyButtonProps) => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const referrer =
    LocalStorage.getItem(LocalStorage.keys.SIGN_UP_REFERRER) || undefined;

  const onClick = useCallback(() => {
    addViewedPublicJob(jobId);
    dispatch(
      fetchPublicInvitationDetails({
        invitation_key: null,
        referrer,
        job_id: jobId.toString(),
      }),
    ).then(() => {
      dispatch(unauthenticatedUserJobApplyClicked(jobId));
    });
  }, [referrer, dispatch, jobId]);

  const navigateTo = `/auth/sign_up/${
    jobId ? `?job_id=${jobId}&${params.toString()}` : `?${params.toString()}`
  }`;

  return (
    <Box
      sx={{
        width: fullWidth ? "100%" : "initial",
        whiteSpace: "nowrap",
      }}
    >
      <Button
        className={className}
        variant={variant ? variant : "primary"}
        to={navigateTo}
        onClick={onClick}
        RouterLink={RouterLink}
        fullWidth={fullWidth}
        target={openInNewTab ? "_blank" : undefined}
        {...props}
      >
        {label}
      </Button>
    </Box>
  );
};
