import type { MouseEvent } from "react";
import { useEffect, useRef, useState } from "react";

import { extractExtensionFromFilename } from "@js/utils";

type AttachmentRenameInputProps = {
  value: string;
  onRename?: (newName: string) => Promise<unknown>;
};

export const useAttachmentRenameInput = ({
  value,
  onRename,
}: AttachmentRenameInputProps) => {
  const extension = extractExtensionFromFilename(value);
  const filenameWithoutExtension = value.replace(extension, "");
  const [_value, setValue] = useState(filenameWithoutExtension);
  const [beforeEditValue, setBeforeEditValue] = useState(
    filenameWithoutExtension,
  );
  const [isEditable, setEditable] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditable) {
      inputRef.current?.focus();
    }
  }, [isEditable]);

  useEffect(() => {
    setValue(filenameWithoutExtension);
  }, [filenameWithoutExtension]);

  const toggleEditable = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (isEditable) {
      setValue(beforeEditValue);
    } else {
      setBeforeEditValue(_value);
    }

    setEditable((prevState) => !prevState);
  };

  const handleRename = () => {
    if (!isEditable || !onRename || _value === value) {
      return;
    }
    setEditable(false);
    setSaving(true);
    onRename(!!_value ? `${_value}${extension}` : _value)
      .then(() => setErrorMessage(undefined))
      .catch((error) => {
        setValue(!!_value ? value : beforeEditValue);
        if (!error) {
          return setErrorMessage("Renaming the file failed.");
        }
        if (error.name) {
          setErrorMessage(error.name[0]);
        }
        if (error._error) {
          setErrorMessage(error._error);
        }
      })
      .finally(() => setSaving(false));
  };

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setValue(event.target.value);
  };

  const onClick = () => {
    return onRename && !isSaving && setEditable(true);
  };

  const onKeyDown = ({ key }: React.KeyboardEvent<HTMLDivElement>) => {
    return key === "Enter" && handleRename();
  };

  return {
    isEditable,
    isSaving,
    _value,
    extension,
    inputRef,
    errorMessage,
    handleRename,
    onChange,
    onClick,
    onKeyDown,
    toggleEditable,
  };
};
