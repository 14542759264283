export const EDIT_JOB_FORM_ID = "edit-job-form";
export const CREATE_JOB_FORM_ID = "new-add-job-form";
export const LOCATIONS_TAGS_CHARACTERS_LIMIT = 30;

export const AI_VALUE = "ai";
export const RANGE_VALUE = "range";
export const RATE_OPTIONS = [
  {
    label: "$75",
    value: "75",
  },
  {
    label: "$90",
    value: "90",
  },
  {
    label: "$100",
    value: "100",
  },
  {
    label: "$120",
    value: "120",
  },
];
