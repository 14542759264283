import { Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { BoxedEmployerLogo } from "@js/apps/employer/components/employer-logo";
import { getFullLocationLabel } from "@js/apps/jobs/components/job-basic-details/utils";
import { getWorkTimeLabel } from "@js/apps/jobs/utils";
import { SearchListItem } from "@js/apps/universal-search/components/search-results/search-list-item";
import type { Employer } from "@js/types/employer";
import type { Job } from "@js/types/jobs";
import { relativeDateDifference } from "@js/utils";

import { DetailsContentContainer } from "./details-content-container";

type JobSearchListItemProps = {
  job: Pick<
    Job,
    "title" | "expected_hours_per_week" | "created" | "locations" | "id"
  > & {
    employer: Pick<
      Employer,
      | "id"
      | "name"
      | "logo"
      | "logo_thumbnail"
      | "has_logo_set"
      | "logo_background_color"
    >;
  };
  onClick?: () => void;
};

export const JobSearchListItem = ({ job, onClick }: JobSearchListItemProps) => {
  const isMobile = useMediaQuery("sm");
  const { title, employer } = job;
  const workTimeLabel =
    job.expected_hours_per_week &&
    getWorkTimeLabel(job.expected_hours_per_week);
  const createdLabel = relativeDateDifference(job.created, {
    useDayJSRelativeStrings: true,
    noArticles: true,
  });

  const badgeComponent = (
    <BoxedEmployerLogo
      size="medium"
      employer={employer}
      borderRadius="8px"
      bgcolor={
        employer.logo_background_color
          ? `var(${employer.logo_background_color})`
          : ""
      }
      logoWrapperSize={{ width: "32px", height: "32px" }}
    />
  );

  const locationLabel = getFullLocationLabel(job.locations);

  const detailsContent = (
    <DetailsContentContainer>
      <Typography
        component="span"
        size="small"
        variant="paragraph"
        ellipsis
        sx={{ overflow: "hidden" }}
      >
        {employer.name}
      </Typography>
      {locationLabel && (
        <Typography
          component="span"
          size="small"
          variant="paragraph"
          color="tertiary"
          ellipsis
          sx={{ overflow: "hidden" }}
        >
          {locationLabel}
        </Typography>
      )}
      {!isMobile && workTimeLabel && (
        <Typography
          component="span"
          size="small"
          variant="paragraph"
          color="tertiary"
        >
          {workTimeLabel}
        </Typography>
      )}
      {!isMobile && (
        <Typography
          component="span"
          size="small"
          variant="paragraph"
          color="tertiary"
        >
          {createdLabel}
        </Typography>
      )}
    </DetailsContentContainer>
  );

  return (
    <SearchListItem
      onClick={onClick}
      to={`/jobs/${job.id}`}
      title={title}
      badgeComponent={badgeComponent}
      detailsContent={detailsContent}
    />
  );
};
