import cs from "classnames";

import type { StackProps } from "@hexocean/braintrust-ui-components";
import { Stack } from "@hexocean/braintrust-ui-components";

import styles from "./styles.module.scss";

type MessengerContentContainerProps = StackProps;

export const MessengerContentContainer = ({
  children,
  className,
  ...rest
}: MessengerContentContainerProps) => {
  return (
    <Stack {...rest} className={cs(styles.messengerContent, className)}>
      {children}
    </Stack>
  );
};
