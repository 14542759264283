import type { TrackableUserAction } from "@js/middlewares/analytics/types";
import { Events } from "@js/services/analytics";
import { logEvent } from "@js/services/analytics/event-logging";

export const handleLinkedInCsvUploadActions = (action: TrackableUserAction) => {
  switch (action.type) {
    case Events.CLICK_UPLOAD_LINKEDIN_CSV: {
      logEvent(Events.CLICK_UPLOAD_LINKEDIN_CSV, {
        success: action.payload.success,
        file_uploaded: action.payload.file_uploaded, // boolean
      });
      break;
    }

    case Events.CLICK_REUPLOAD_LINKEDIN_CSV: {
      logEvent(Events.CLICK_REUPLOAD_LINKEDIN_CSV);
      break;
    }

    default: {
      break;
    }
  }
};
