import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Box, Button, Menu, Tooltip } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { KeyboardArrowDownIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import { useIsEmployerUserVerified } from "@js/apps/common/hooks/is-employer-verified";
import { RouterLink } from "@js/components/link";

import { setIndexToScrollTo } from "../../virtualized-job-bid-list/rendered-item-index";

import style from "./style.module.scss";

type OfferButtonProps = {
  editUrl: string;
  previewUrl: string;
  index?: number;
} & Partial<ButtonProps>;

export const OfferButton = ({
  editUrl,
  previewUrl,
  index,
  ...buttonProps
}: OfferButtonProps) => {
  const isMobile = useMediaQuery(420);
  const isEmployerUserVerified = useIsEmployerUserVerified();
  const isNodeStaff = useIsNodeStaff();
  const canViewOffer = isEmployerUserVerified || isNodeStaff;

  const handleSetIndexToScrollTo = () => {
    if (!index) {
      return;
    }

    setIndexToScrollTo(index);
  };

  return (
    <Tooltip
      title={canViewOffer ? "" : "Please verify your email to see this offer."}
      disabled={canViewOffer}
    >
      <Box>
        <Button
          variant="primary"
          shape="squared"
          RouterLink={RouterLink}
          to={previewUrl}
          disabled={!canViewOffer}
          size={isMobile ? "x-small" : "small"}
          sx={{ height: "40px" }}
          style={{
            paddingRight: 0,
            borderRadius: "8px 0 0 8px",
          }}
          onClick={handleSetIndexToScrollTo}
          {...buttonProps}
        >
          View offer
        </Button>
        <Menu
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchor={
            <Button
              variant="primary"
              shape="squared"
              disabled={!canViewOffer}
              aria-label="Open view offer menu"
              endIcon={<KeyboardArrowDownIcon />}
              style={{
                borderRadius: "0 8px 8px 0",
                minWidth: "unset",
                paddingLeft: 0,
                height: "40px",
              }}
              onClick={handleSetIndexToScrollTo}
            />
          }
        >
          <Menu.Item
            className={style.viewOrEditOfferBtn}
            component={RouterLink}
            disabled={!canViewOffer}
            to={editUrl}
          >
            Edit Offer
          </Menu.Item>
        </Menu>
      </Box>
    </Tooltip>
  );
};
