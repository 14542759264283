import { useABExperiment } from "@js/apps/experiments";

export const useShowGetInspiredButton = () => {
  const { isParticipating, experiment } = useABExperiment(
    "Prompting first post in Career Help",
  );

  const isGetInspiredPromptVariant =
    experiment?.variant ===
    SETTINGS.EXPERIMENT_ENUMS[
      SETTINGS.EXPERIMENT_ENUMS.Experiments.PromptingFirstPostInCareerHelp
    ].GetInspiredPromptVariant;

  return {
    showGetInspiredButton: isParticipating && isGetInspiredPromptVariant,
  };
};
