import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";

import {
  AdditionalInfo,
  EmployerInfo,
  EmployerMessage,
  OfferDetails,
  TalentNavigation,
  TalentOfferChecklist,
  TalentTopStripeContent,
} from "../../components";
import {
  EquipmentShippingAddressFields,
  EquipmentShippingAddressFormInstance,
} from "../../forms";
import { useTalentOffer } from "../../hooks";
import { OfferLayout, OfferPreviewContentLayout } from "../../layout";

export const TalentPreviewOfferPage = () => {
  const {
    job,
    offer,
    loading,
    employer,
    onOfferReject,
    onSubmit,
    onSubmitFail,
    initialValues,
  } = useTalentOffer();

  if (loading || !offer || !job || !employer) {
    return (
      <OfferLayout navigationSocket={null}>
        <Loader />
      </OfferLayout>
    );
  }

  return (
    <EquipmentShippingAddressFormInstance
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSubmitFail={onSubmitFail}
      hideError
    >
      <OfferLayout
        topStripeSocket={<TalentTopStripeContent offer={offer} />}
        navigationSocket={
          <TalentNavigation offer={offer} onOfferReject={onOfferReject} />
        }
      >
        <OfferPreviewContentLayout
          leftSocket={
            <>
              <EmployerInfo
                jobId={job.id}
                employer={employer}
                sx={{ padding: "24px 32px !important" }}
              />
              <EmployerMessage offer={offer} />
            </>
          }
          rightSocket={
            <>
              <OfferDetails showUpdatedLabel={true} offer={offer} job={job} />
              <AdditionalInfo
                offer={offer}
                bottomSocket={
                  offer.talent_has_own_equipment ? null : (
                    <Box>
                      <Box
                        display="flex"
                        flexDirection={{ xs: "column", sm: "row" }}
                        alignItems={{ xs: "flex-start", sm: "center" }}
                      >
                        <Typography
                          mt={4}
                          mb={{ xs: 0, sm: 3 }}
                          component="p"
                          variant="label"
                          size="large"
                        >
                          Equipment shipping address
                        </Typography>
                        <Typography
                          component="p"
                          size="small"
                          color="grey"
                          mb={{ xs: 2, sm: 0 }}
                          ml={{ xs: 0, sm: 2 }}
                          mt={{ xs: 0, sm: 1.4 }}
                        >
                          Required
                        </Typography>
                      </Box>
                      <EquipmentShippingAddressFields
                        disableAll={
                          offer.status !== ENUMS.OfferStatus.NEW &&
                          offer.status !== ENUMS.OfferStatus.UPDATED
                        }
                      />
                    </Box>
                  )
                }
              />
              {offer.status === ENUMS.OfferStatus.ACCEPTED && (
                <TalentOfferChecklist offer={offer} />
              )}
            </>
          }
        />
      </OfferLayout>
    </EquipmentShippingAddressFormInstance>
  );
};

TalentPreviewOfferPage.HTTP_404_ID = "talent-offer-preview-404";
