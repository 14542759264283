import { type FC } from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  accordionContainerStyle,
  INSIGHTS_ACCORDION_CONTENT,
} from "@js/apps/jobs/components/application-onramp-modal/constants";
import { BasicAccordion } from "@js/apps/on-ramp/components/onramp-modal/accordion";

const accordionContent = [
  INSIGHTS_ACCORDION_CONTENT[0],
  INSIGHTS_ACCORDION_CONTENT[1],
];

export const Description: FC = () => {
  const isTablet = useMediaQuery("lg");

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      boxSizing="border-box"
      maxWidth={isTablet ? "100%" : "560px"}
      padding={isTablet ? "20px" : "0 80px"}
      sx={{ backgroundColor: "var(--soft-green)" }}
    >
      <Box position="absolute" top={isTablet ? 0 : -32} right={0}>
        <img
          src={`${SETTINGS.STATIC_URL}jobs/job-insight-bg.svg`}
          alt="job-insight-bg-image"
          width="239"
          height="183"
        />
      </Box>
      <Box
        display="flex"
        gap={{ xs: 1, lg: 3 }}
        flexDirection="column"
        zIndex={1}
      >
        <Typography
          component="h3"
          variant="title"
          size="medium"
          fontWeight={400}
          zIndex={1}
        >
          Apply smart with <br />
          Application Insights.
        </Typography>
        <Typography
          component="p"
          variant="paragraph"
          size="medium"
          fontWeight={400}
        >
          Identify promising opportunities and refine your job search with
          personalized feedback.
        </Typography>

        <Box sx={accordionContainerStyle}>
          <BasicAccordion options={accordionContent} />
        </Box>
      </Box>
    </Box>
  );
};
