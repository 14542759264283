import { actionTypes } from "@js/apps/give-and-get-help/action-types";
import type { PostLocationType } from "@js/apps/give-and-get-help/types";
import type { AppThunkAction } from "@js/store";

import { CreatePostModalInstance } from "./modal-instances";

type OpenClosePostModalPayload = {
  composer_location: PostLocationType;
};

export const closeCreatePostModal =
  ({ composer_location }: OpenClosePostModalPayload): AppThunkAction<void> =>
  (dispatch) => {
    dispatch({
      type: actionTypes["create/modal-closed"],
      payload: { composer_location },
    });

    CreatePostModalInstance.close();
  };
