import React, { forwardRef, useEffect } from "react";
import uuid4 from "uuid4/browser";

import { Modal, ModalConfirm } from "@js/components/modal";
import { useNavigate } from "@js/hooks";
import type { FreelancerBid } from "@js/types/jobs";

type CancelBidConfirmationModalProps = {
  onConfirm: () => void;
  bid: FreelancerBid;
};

export const CancelBidConfirmationModal = forwardRef<
  ReturnType<typeof Modal>,
  CancelBidConfirmationModalProps
>(({ onConfirm, bid }, ref) => {
  const CancelBidConfirmationModalComponent = Modal(
    `cancel-bid-confirmation-modal-$${uuid4()}`,
    {
      closeButton: false,
    },
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!ref) {
      return;
    }

    if (typeof ref === "function") {
      return ref(CancelBidConfirmationModalComponent);
    }

    ref.current = CancelBidConfirmationModalComponent;
  }, [CancelBidConfirmationModalComponent, ref]);

  const offerId = bid.current_offer?.id;

  return (
    <CancelBidConfirmationModalComponent>
      <ModalConfirm
        onCancel={CancelBidConfirmationModalComponent.close}
        cancelText="Close"
        onConfirm2={onConfirm}
        confirmText2="Cancel Application"
        onConfirm={
          offerId
            ? () => navigate(`/jobs/${bid.job.id}/offers/${offerId}`)
            : undefined
        }
        confirmText="View Offer"
      >
        <p>Are you sure you want to cancel this application?</p>
        {!!offerId && "There is a pending offer for this application."}
      </ModalConfirm>
    </CancelBidConfirmationModalComponent>
  );
});
