import React from "react";

import { Box, Button } from "@hexocean/braintrust-ui-components";
import { PersonAddIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useLoginAs } from "@js/apps/auth/hooks";
import { useUser } from "@js/apps/common/hooks";
import { shareProfileClicked } from "@js/apps/employer/actions";
import { openShareTalentWithTeamMembersModal } from "@js/apps/employer/components/share-talent-with-team-members-modal";
import { useCanShareWithTeamMembers } from "@js/apps/employer/hooks/use-can-share-with-team-members";
import { useInviteToBid } from "@js/apps/jobs/hooks";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import { useAppDispatch } from "@js/hooks";

import type { ProfnetProfileActionsProps } from "../profile-actions";

const CtaButtons = ({ profile }: ProfnetProfileActionsProps) => {
  const dispatch = useAppDispatch();
  const { openMessengerModal } = useOpenMessengerModal();

  const user = useUser();
  const { inviteAsStaff, canInviteAsStaff } = useInviteToBid({
    freelancer: profile,
  });
  const interlocutorId = profile.user.id;
  const { canLoginAs, loginAs } = useLoginAs(interlocutorId);
  const canShareTalentWithMembers = useCanShareWithTeamMembers();
  const handleShareProfileClick = () => {
    dispatch(shareProfileClicked());
    openShareTalentWithTeamMembersModal({ talentId: profile.id });
  };

  return (
    <Box className="buttons-container">
      {canLoginAs && (
        <Button
          size="medium"
          variant="primary"
          onClick={loginAs}
          shape="squared"
        >
          Log in as user
        </Button>
      )}
      {canInviteAsStaff && (
        <Button
          size="medium"
          endIcon={<PersonAddIcon />}
          onClick={inviteAsStaff}
          variant="primary"
          shape="squared"
        >
          Invite
        </Button>
      )}
      {canShareTalentWithMembers && (
        <Button
          variant="secondary"
          shape="squared"
          onClick={handleShareProfileClick}
        >
          Share profile
        </Button>
      )}
      {!!user && profile.user.can_be_messaged && (
        <Button
          size="medium"
          variant="positive"
          shape="squared"
          onClick={() =>
            openMessengerModal({
              context: {
                participants: [interlocutorId, user.id],
              },
            })
          }
        >
          Send a message
        </Button>
      )}
    </Box>
  );
};

export default CtaButtons;
