import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { JobInterviewVideoSummary } from "@js/apps/jobs/components/job-interview-video-summary";

import { BidInterviewIcon } from "./bid-interview-icon";

export const BidInterviewQuestions = ({
  openVideo,
  title,
  description,
  videoUrl,
}: {
  openVideo: () => void;
  title: string;
  description: string | null;
  videoUrl: string | null;
}) => {
  return (
    <Box mt={1}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        mb={3}
      >
        <BidInterviewIcon />
        <Typography component="h2" variant="paragraph" size="large" ml={1}>
          Video and transcript
        </Typography>
      </Box>
      <Box mb={4} pb={3}>
        <JobInterviewVideoSummary
          openVideo={openVideo}
          title={title}
          description={description ?? ""}
          videoUrl={videoUrl}
        />
      </Box>
    </Box>
  );
};
