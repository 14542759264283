import styles from "./styles.module.scss";

type AvatarSize = "x-small" | "small" | "medium" | "large";

type AvatarProps = {
  src?: string;
  size: AvatarSize;
  className?: string;
};

export const Avatar = ({ src, size, className }: AvatarProps) => {
  return (
    <div className={`${styles.avatar} ${styles[size]} ${className}`}>
      {src && (
        <img
          src={src}
          className={styles.img}
          width="100%"
          height="100%"
          alt="space avatar"
        />
      )}
    </div>
  );
};
