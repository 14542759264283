import { useMemo } from "react";

import type { PollOption } from "@js/types/give-and-get-help";

export type MappedPollOption = PollOption & {
  percentage: `${number}%`;
  winsOrDraws: boolean;
};

const percentageFormatter = Intl.NumberFormat("en-US", {
  style: "percent",
  maximumFractionDigits: 1,
});

export type GetPollResultsProps = {
  options: PollOption[];
  votes_count: number | null;
};

export const getMappedPollResults = ({
  options,
  votes_count,
}: GetPollResultsProps) => {
  const results: MappedPollOption[] = options.map((option) => {
    const winsOrDraws = options.every(
      (other) => (other.votes_count ?? 0) <= (option.votes_count ?? 0),
    );

    // When votes_count is 0/null needs to be returned as 0 because
    // 0 / 0 is NaN and will be wrongly displayed
    const optionVoteRatio = !votes_count
      ? 0
      : (option.votes_count ?? 0) / (votes_count ?? 0);

    const percentage = percentageFormatter.format(
      optionVoteRatio,
    ) as `${number}%`;

    return {
      ...option,
      percentage,
      winsOrDraws,
    };
  });

  const isAnyDecimal = results.some((result) =>
    result.percentage.includes("."),
  );
  const isAny100 = results.some((result) => result.percentage.includes("100"));

  return { results, isAny100, isAnyDecimal };
};

export const usePollResults = ({
  options,
  votes_count,
}: GetPollResultsProps) => {
  const { results, isAny100 } = useMemo(
    () => getMappedPollResults({ options, votes_count }),
    [options, votes_count],
  );

  return { results, isAny100 };
};
