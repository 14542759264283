import type React from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import type { WrappedFieldsProps } from "redux-form";
import { touch } from "redux-form";

import { useAppDispatch } from "@js/hooks";

export const useTalentAvaiabilityFilterUtils = ({
  looking_for_work,
  looking_for_work_options,
  applied_recently,
}: WrappedFieldsProps) => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const shouldDisplayOptions =
    !!looking_for_work.input.value ||
    params.get("looking_for_work") ||
    looking_for_work.meta.touched;

  const handleReset = () => {
    applied_recently.input.onChange(null);
    looking_for_work.input.onChange(null);
    looking_for_work_options.input.onChange(null);
  };

  const handleLookingForWorkChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const target = e.target;
    const newLookingForWorkOptionsValue = target.checked
      ? Object.values(ENUMS.FreelancerAvailabilityForWork)
      : null;
    looking_for_work.input.onChange(target.checked);
    looking_for_work_options.input.onChange(newLookingForWorkOptionsValue);
    dispatch(touch(looking_for_work.meta.form, looking_for_work.input.name));
  };

  useEffect(() => {
    const newLookingForWorkValue =
      !!looking_for_work_options.input.value.length;
    looking_for_work.input.onChange(newLookingForWorkValue);
  }, [looking_for_work_options, looking_for_work]);

  return {
    shouldDisplayOptions,
    handleLookingForWorkChange,
    handleReset,
  };
};
