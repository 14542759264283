import { useCallback, useState } from "react";

import { openSignUpModal } from "@js/apps/onboarding/components/sign-up-modal";

export const useSignUpBanner = ({
  onSignUpClick,
  onCloseClick,
}: {
  onSignUpClick?: () => void;
  onCloseClick?: () => void;
}) => {
  const [isSignupBannerHidden, setIsSignupBannerHidden] = useState(false);

  const handleSignUp = useCallback(() => {
    if (onSignUpClick) {
      onSignUpClick();
    }
    openSignUpModal();
  }, [onSignUpClick]);

  const handleCloseBtn = useCallback(() => {
    if (onCloseClick) {
      onCloseClick();
    }
    setIsSignupBannerHidden(true);
  }, [onCloseClick]);

  return {
    handleSignUpClick: handleSignUp,
    isSignupBannerHidden,
    handleCloseBtnClick: handleCloseBtn,
  };
};
