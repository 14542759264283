import React from "react";

import { AcceptingProposalsStatus } from "@js/apps/jobs/components/job-status/common/accepting-proposals";
import { ClientHiredBraintrustTalent } from "@js/apps/jobs/components/job-status/employer/client-hired-braintrust-talent";
import { DisabledProposalButtonWithTooltip } from "@js/apps/jobs/components/job-status/employer/components/disabled-proposal-button-with-tooltip";
import { NoBraintrustTalentHired } from "@js/apps/jobs/components/job-status/employer/no-braintrust-talent-hired";
import { NotAcceptingProposals } from "@js/apps/jobs/components/job-status/employer/not-accepting-proposals";
import { YouHiredXBraintrustTalent } from "@js/apps/jobs/components/job-status/employer/you-hired-x-braintrust-talent";
import type { Job } from "@js/types/jobs";

type EmployerStatusProps = {
  job: Job | null;
  className?: string;
};

export const EmployerStatus = ({ job, ...props }: EmployerStatusProps) => {
  if (!job) return null;

  switch (job.job_status) {
    case ENUMS.JobStatus.OPEN:
      return (
        <AcceptingProposalsStatus
          {...props}
          content={<DisabledProposalButtonWithTooltip />}
        />
      );

    case ENUMS.JobStatus.ACTIVE: {
      if (job.user_is_owner) {
        return (
          <YouHiredXBraintrustTalent
            count={job.hired_bids_count}
            status="Project Hired"
            {...props}
          />
        );
      }

      return (
        <ClientHiredBraintrustTalent
          status="Project Hired"
          count={job.hired_bids_count}
          {...props}
        />
      );
    }
    case ENUMS.JobStatus.ON_HOLD:
      return <NotAcceptingProposals {...props} />;

    case ENUMS.JobStatus.COMPLETED_HIRED:
      if (job.user_is_owner) {
        return (
          <YouHiredXBraintrustTalent
            color="var(--black)"
            status="Complete"
            count={job.hired_bids_count}
            {...props}
          />
        );
      }

      return (
        <ClientHiredBraintrustTalent
          count={job.hired_bids_count}
          color="var(--black)"
          status="Complete"
          {...props}
        />
      );

    case ENUMS.JobStatus.COMPLETED_NOT_HIRED:
      return <NoBraintrustTalentHired {...props} />;

    default:
      return null;
  }
};
