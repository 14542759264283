import { API } from "@js/api";
import type { Interview } from "@js/types/interview";

export type GetInterviewResponse = Interview;

const interviewApi = API.injectEndpoints({
  endpoints: (build) => ({
    getLastInterview: build.query<GetInterviewResponse, void>({
      query: () => ({
        url: "/interviews/last_interview",
        method: "GET",
      }),
      providesTags: [{ type: "Interviews", id: "last" }],
    }),
    markLastInterviewAsCompleted: build.mutation<void, void>({
      query: () => ({
        url: "/interviews/mark_last_as_complete",
        method: "POST",
      }),
      invalidatesTags: [{ type: "Interviews", id: "last" }],
    }),
  }),
});

export const {
  useGetLastInterviewQuery,
  useMarkLastInterviewAsCompletedMutation,
} = interviewApi;
