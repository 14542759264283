import { createContext, useContext } from "react";

import type { IPost } from "@js/types/give-and-get-help";

import type { OnPostAction } from "../../components/post/base-post-item/use-base-post-item";

export type BasePostContextType = {
  postData: IPost;
  isPublic: boolean;
  onViewCommentClick?: OnPostAction;
};

const Context = createContext<BasePostContextType | null>(null);

type ProviderProps = {
  value: BasePostContextType;
  children: React.ReactNode;
};

const Provider = ({ value, children }: ProviderProps) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const usePostContext = (): BasePostContextType => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("PostContextProvider is missing");
  }

  return context;
};

export const PostContext = {
  Provider: Provider,
  Consumer: Context.Consumer,
};
