import type { ConfigProps } from "redux-form";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { MODULE_EDIT_MODAL_PROPERTIES } from "@js/apps/freelancer/constants";
import type { EducationFormData } from "@js/apps/freelancer/forms/education-form";
import { EducationForm } from "@js/apps/freelancer/forms/education-form";
import { CommonConfirmationModal } from "@js/components/modal";
import type { FreelancerSchool } from "@js/types/freelancer";

type EditEducationModalContentProps = {
  initialValues: EducationFormData | undefined;
  onSubmit: ConfigProps<EducationFormData>["onSubmit"];
  onSubmitSuccess: ConfigProps<EducationFormData>["onSubmitSuccess"];
  setSubmitType: (type: string | null) => void;
};

type DeleteEducationModalContentProps = {
  education: FreelancerSchool;
  onDelete: (educationId: number) => Promise<void>;
};

export const openEditEducationModal = ({
  initialValues,
  onSubmit,
  onSubmitSuccess,
  setSubmitType,
}: EditEducationModalContentProps) =>
  CommonConfirmationModal.open({
    children: (
      <EditEducationModalContent
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        setSubmitType={setSubmitType}
      />
    ),
    ...MODULE_EDIT_MODAL_PROPERTIES,
    className: "profile-page-modal profile-page-modal-education",
  });

export const openDeleteEducationModal = ({
  education,
  onDelete,
}: DeleteEducationModalContentProps) =>
  CommonConfirmationModal.open({
    closeButton: false,
    children: (
      <DeleteEducationModalContent education={education} onDelete={onDelete} />
    ),
  });

const EditEducationModalContent = ({
  initialValues,
  onSubmit,
  onSubmitSuccess,
  setSubmitType,
}: EditEducationModalContentProps) => {
  return (
    <Box>
      <ModalHeaderWithArrow title="Edit degree" />

      <EducationForm
        initialValues={initialValues}
        editMode
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        setSubmitType={setSubmitType}
      />
    </Box>
  );
};

const DeleteEducationModalContent = ({
  education,
  onDelete,
}: DeleteEducationModalContentProps) => {
  return (
    <Box
      padding={{
        xs: 2,
        sm: 4,
      }}
      maxWidth="552px"
    >
      <ModalHeaderWithArrow title="Remove Degree" />
      <Typography variant="paragraph" component="p" mb={4}>
        Removing your {education.degree?.name} from your profile can’t be
        undone. Are you sure you want to remove this degree?
      </Typography>
      <Box display="flex" gap={2} justifyContent="flex-end">
        <Button
          variant="black-outlined"
          shape="squared"
          onClick={CommonConfirmationModal.close}
        >
          Keep degree
        </Button>
        <Button
          variant="destructive"
          shape="squared"
          onClick={() => onDelete(education.id)}
        >
          Remove degree
        </Button>
      </Box>
    </Box>
  );
};

const ModalHeaderWithArrow = ({ title }: { title: string }) => (
  <Box display="flex" gap={2} mb={4}>
    <span>
      <IconButton
        variant="tertiary"
        onClick={CommonConfirmationModal.close}
        aria-label="go back"
      >
        <ArrowLeftIcon />
      </IconButton>
    </span>
    <Typography variant="title" component="h3" size="small" fontWeight={400}>
      {title}
    </Typography>
  </Box>
);
