import { useMemo } from "react";

import type {
  JobFiltersFetchParams,
  TalentFiltersFetchParams,
} from "@js/apps/common/components/filters";
import { createApprovalStatusString } from "@js/apps/jobs/apps/listing/utils/create-approval-status-string";
import { createRateString } from "@js/apps/jobs/apps/listing/utils/create-rate-string";
import { createYearsOfExperienceString } from "@js/apps/jobs/apps/listing/utils/create-years-of-experience-string";
import { useGetRoleName } from "@js/apps/roles/hooks";
import type { Skill } from "@js/types/admin";

import {
  createAppliedRecentlyString,
  createCommitmentString,
  createJobLocationString,
  createJobTypeString,
  createLookingForWorkString,
  useCreateHelpCategoryString,
  useCreateSkillsLabelsString,
  useCreateTalentLocationString,
} from "./helpers";

type CreateStringDependsOnSelectedJobFilters = (
  filters: Partial<Record<keyof JobFiltersFetchParams, any>>,
  filterSkillsData: Skill[],
) => string;

export const useCreateStringDependsOnSelectedJobFilters: CreateStringDependsOnSelectedJobFilters =
  (filters, filterSkillsData) => {
    const roleFilter = filters.role;
    const parsedRoleFilter =
      !!roleFilter && !isNaN(Number(roleFilter))
        ? Number(roleFilter)
        : undefined;
    const roleLabelString = useGetRoleName(parsedRoleFilter);
    const skillsLabelsString = useCreateSkillsLabelsString(filterSkillsData);

    const filtersStringArray = useMemo(() => {
      const location = createJobLocationString(filters);
      const commitment = createCommitmentString(
        filters["availability_from"],
        filters["availability_to"],
      );
      const rate = createRateString(filters.hourly_budget_minimum_usd);
      const jobType = createJobTypeString(filters["job_type"]);

      return [
        roleLabelString,
        skillsLabelsString,
        location,
        commitment,
        rate,
        jobType,
      ];
    }, [filters, roleLabelString, skillsLabelsString]);

    return filtersStringArray.filter(Boolean).join(", ");
  };

type CreateStringDependsOnSelectedTalentFilters = (
  filters: Partial<Record<keyof TalentFiltersFetchParams, any>>,
  filterSkillsData: Skill[],
) => string;

export const useCreateStringDependsOnSelectedTalentFilters: CreateStringDependsOnSelectedTalentFilters =
  (filters, filterSkillsData) => {
    const roleFilter = filters.role;
    const parsedRoleFilter =
      !!roleFilter && !isNaN(Number(roleFilter))
        ? Number(roleFilter)
        : undefined;
    const roleLabelString = useGetRoleName(parsedRoleFilter);
    const skillsLabelsString = useCreateSkillsLabelsString(filterSkillsData);
    const helpCategoryLabelString = useCreateHelpCategoryString(
      filters["help_category"],
    );
    const locationString = useCreateTalentLocationString(filters);

    const filtersStringArray = useMemo(() => {
      const approvalStatus = createApprovalStatusString(filters.approved);
      const appliedRecently = createAppliedRecentlyString(
        filters["applied_recently"],
      );
      const lookingForWork = createLookingForWorkString(
        filters["looking_for_work"],
      );
      const experience = createYearsOfExperienceString(filters["experience"]);

      return [
        roleLabelString,
        skillsLabelsString,
        locationString,
        approvalStatus,
        experience,
        lookingForWork,
        appliedRecently,
        helpCategoryLabelString,
      ];
    }, [
      filters,
      locationString,
      roleLabelString,
      skillsLabelsString,
      helpCategoryLabelString,
    ]);

    return filtersStringArray.filter(Boolean).join(", ");
  };
