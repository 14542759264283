import type { ReactNode } from "react";
import React, { useMemo } from "react";
import AllState from "@static/public-job-page/allstate.svg";
import Billie from "@static/public-job-page/billie.svg";
import BlueCrossBlueShield from "@static/public-job-page/blue-cross-blue-shield.svg";
import Deloitte from "@static/public-job-page/deloitte.svg";
import Expedia from "@static/public-job-page/expedia.svg";
import Google from "@static/public-job-page/google.svg";
import Meta from "@static/public-job-page/meta.svg";
import Nasa from "@static/public-job-page/nasa.svg";
import Nestle from "@static/public-job-page/nestle.svg";
import NextDoor from "@static/public-job-page/next-door.svg";
import PacificLife from "@static/public-job-page/pacific-life.svg";
import Pinterest from "@static/public-job-page/pinterest.svg";
import Porsche from "@static/public-job-page/porsche.svg";
import Spotify from "@static/public-job-page/spotify.svg";
import TaskRabbit from "@static/public-job-page/task-rabbit.svg";
import Walmart from "@static/public-job-page/walmart.svg";
import WarnerMusicGroup from "@static/public-job-page/warner-music-group.svg";
import WholeFoods from "@static/public-job-page/whole-foods.svg";

import { Box, Carousel, Typography } from "@hexocean/braintrust-ui-components";
import { XIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./styles.module.scss";

export const PUBLIC_JOBS_LISTING_LOGO_ICONS = [
  { Icon: Google, key: "Google-icon" },
  { Icon: PacificLife, key: "PacificLife-icon" },
  { Icon: Nasa, key: "Nasa-icon" },
  { Icon: AllState, key: "AllState-icon" },
  { Icon: Nestle, key: "Nestle-icon" },
  { Icon: Spotify, key: "Spotify-icon" },
  { Icon: NextDoor, key: "NextDoor-icon" },
  { Icon: XIcon, key: "X-icon" },
  { Icon: Pinterest, key: "Pinterest-icon" },
  { Icon: Meta, key: "Meta-icon" },
  { Icon: TaskRabbit, key: "TaskRabbit-icon" },
  { Icon: Deloitte, key: "Deloitte-icon" },
  { Icon: WarnerMusicGroup, key: "WarnerMusicGroup-icon" },
  { Icon: Expedia, key: "Expedia-icon" },
  { Icon: WholeFoods, key: "WholeFoods-icon" },
  { Icon: Billie, key: "Billie-icon" },
  { Icon: Walmart, key: "Walmart-icon" },
  { Icon: Porsche, key: "Porsche-icon" },
  { Icon: BlueCrossBlueShield, key: "BlueCrossBlueShield-icon" },
];

export const PublicJobsListingClientsCarousel = React.memo(() => {
  const slidesGroupCount = PUBLIC_JOBS_LISTING_LOGO_ICONS.length + 1;
  // transition speed on autoplay depends on the visible slides or a group of slides
  // in order to have the swiper move in an constant speed, we need to have groups of the same width
  // thus doubling the slides and putting them in 2 equally wide groups
  const slidesDoubled = useMemo(() => {
    const slides = [
      <TrustedBy key="trusted-by-text" />,
      ...PUBLIC_JOBS_LISTING_LOGO_ICONS.map(({ Icon, key }) => {
        return (
          <SlideWrapper key={key}>
            <Icon />
          </SlideWrapper>
        );
      }),
    ];

    return [...slides, ...slides].map((slide, index) => {
      // eslint-disable-next-line react/no-array-index-key
      return <React.Fragment key={index}>{slide}</React.Fragment>;
    });
  }, []);

  return (
    <Box className={styles.wrapper}>
      <Carousel
        allowTouchMove={false}
        slidesPerGroup={slidesGroupCount}
        autoplay={{
          delay: 1,
          disableOnInteraction: true,
        }}
        speed={70000}
        loop
        className={styles.carousel}
        slideProps={{
          style: {
            width: "auto",
          },
        }}
        slides={slidesDoubled}
      />
    </Box>
  );
});

const SlideWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      width="fit-content"
      className={styles.logo}
      data-testid="client-logo-slide"
    >
      <Box>{children}</Box>
    </Box>
  );
};

const TrustedBy = () => {
  return (
    <Box className={styles.trustedBy} data-testid="client-logo-slide">
      <Typography component="p" fontWeight={500}>
        Trusted by
      </Typography>
    </Box>
  );
};
