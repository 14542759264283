import { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import { useGetEmployerPublicProfileQuery } from "@js/apps/employer/api";
import { useGetEmployerOpenJobsQuery } from "@js/apps/jobs/api";
import { useNavigate } from "@js/hooks";
import { useIdParam } from "@js/hooks/use-id-param";

export const useEmployerProfilePage = () => {
  const id = useIdParam();
  const navigate = useNavigate();

  const {
    data: openJobs,
    isLoading: isFetchingOpenJobs,
    refetch: refetchEmployerPublicProfile,
  } = useGetEmployerOpenJobsQuery(id ? { employerId: Number(id) } : skipToken);

  const {
    data: employerPublicProfile,
    isFetching: fetchingEmployerPublicProfile,
  } = useGetEmployerPublicProfileQuery(id ? id : skipToken);

  useEffect(() => {
    if (!id) {
      navigate("/page-not-found/?reloaded=true", { replace: true });
    }
  }, [id, navigate]);

  const loading =
    fetchingEmployerPublicProfile ||
    isFetchingOpenJobs ||
    employerPublicProfile?.id !== Number(id);

  return {
    refetchEmployerPublicProfile,
    openJobs: openJobs || [],
    employerPublicProfile,
    loading,
  };
};
