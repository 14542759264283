import { Typography } from "@hexocean/braintrust-ui-components";

import { BudgetLabel } from "../../post-category-and-budget-badges";

type CommentOfferBoxLabelProps = {
  budget: string;
  label: string;
};

export const CommentOfferBoxLabel = ({
  budget,
  label,
}: CommentOfferBoxLabelProps) => {
  return (
    <Typography component="p" size="small" color="inherit" fontWeight={500}>
      {label}
      <BudgetLabel
        budget={budget}
        size="small"
        style={{ color: "var(--dark-green)" }}
      />
    </Typography>
  );
};
