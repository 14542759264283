import { z } from "zod";

import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { messengerApi, useGetRoomListQuery } from "@js/apps/messenger/api";
import { WebSocketManagerContainer } from "@js/components/websocket";
import { useAppDispatch } from "@js/hooks";
import { useEffectRef } from "@js/hooks/use-effect-ref";

const REFETCH_MESSAGE_ROOMS_LISTENER = "refetch-message-rooms-lister";
const messageEventSchema = z.object({
  data: z.object({
    broadcast_type: z.literal(ENUMS.BroadcastType.MESSAGE),
    message: z.object({
      room: z.number(),
    }),
  }),
});

export const usePatchRoomListData = () => {
  const dispatch = useAppDispatch();
  const { data: roomListResponse } = useGetRoomListQuery();
  const roomsRef = useEffectRef(roomListResponse?.results);

  useEffectOnce(() => {
    const listener = (event: MessageEvent) => {
      if (!roomsRef.current) {
        return;
      }

      const parsedEvent = messageEventSchema.safeParse(event);
      if (!parsedEvent.success) {
        return;
      }

      const messageRoomId = parsedEvent.data.data.message.room;
      const messageRoomExists = roomsRef.current.some(
        (room) => room.id === messageRoomId,
      );
      const shouldInvalidateRoomsList = !messageRoomExists;
      if (!shouldInvalidateRoomsList) {
        return;
      }

      // room is patched into room list in rtk query configuration
      dispatch(
        messengerApi.endpoints.getRoom.initiate(
          { id: messageRoomId },
          { subscribe: false },
        ),
      );
    };

    WebSocketManagerContainer.attachListener(
      listener,
      REFETCH_MESSAGE_ROOMS_LISTENER,
    );

    return () => {
      WebSocketManagerContainer.removeListener(REFETCH_MESSAGE_ROOMS_LISTENER);
    };
  });
};
