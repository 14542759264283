import { Typography } from "@hexocean/braintrust-ui-components";
import { ModalConfirmAsync, ModalInstance } from "@js/components/modal";
import { formatTokenAmount } from "@js/utils";

import type { CanConfirmTransactionResponse } from "../../api";

export const openConfirmWithdrawalTransactionModal = ({
  onConfirm,
  transactionResponse,
}: {
  onConfirm: () => Promise<void>;
  transactionResponse: CanConfirmTransactionResponse;
}) =>
  ModalInstance.open({
    children: (
      <ModalConfirmAsync
        onConfirm={onConfirm}
        confirmText="Yes, transfer my tokens"
        onCancel={ModalInstance.close}
        cancelText="No, cancel"
      >
        <Typography
          component="h3"
          variant="title"
          fontWeight={400}
          mt={3}
          mb={2}
        >
          Are the details of your transfer correct?
        </Typography>
        <Typography component="p" mb={4}>
          Please confirm one last time in order to submit this transaction for
          processing. After this step, you will not be able to edit or undo this
          transfer, so please make sure everything is correct.
        </Typography>
        <Typography component="p" variant="label">
          Amount of BTRST to transfer
        </Typography>
        <Typography component="p" size="large" mb={3}>
          {formatTokenAmount(transactionResponse.amount)}
        </Typography>
        <Typography component="p" variant="label">
          Wallet address
        </Typography>
        <Typography component="p" size="large">
          {transactionResponse.wallet_address}
        </Typography>
      </ModalConfirmAsync>
    ),
    className: "max-width-600",
  });
