import React from "react";

import type {
  BoxProps,
  TypographyProps,
} from "@hexocean/braintrust-ui-components";
import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@hexocean/braintrust-ui-components";

type SectionProps = {
  title: string;
  style?: React.CSSProperties;
};

export const Section: React.FC<React.PropsWithChildren<SectionProps>> = ({
  title,
  children,
  style,
}) => {
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={4}
      style={{ marginBottom: theme.spacing(8), ...style }}
    >
      <Grid item xs={12} md={3.5}>
        <SectionTitle>{title}</SectionTitle>
      </Grid>
      <Grid item xs={12} md={8}>
        {children}
      </Grid>
    </Grid>
  );
};

type SectionTitleProps = Omit<TypographyProps, "component"> & {
  children: React.ReactNode;
};

const SectionTitle = ({ children, ...props }: SectionTitleProps) => {
  return (
    <Typography
      variant="title"
      size="small"
      fontWeight={400}
      component="h3"
      {...props}
    >
      {children}
    </Typography>
  );
};

type SectionSubtitleProps = Partial<TypographyProps> & {
  children?: React.ReactNode;
  action?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  containerProps?: BoxProps;
};

export const SectionSubtitle = ({
  children,
  title,
  action,
  description,
  containerProps = {},
  ...props
}: SectionSubtitleProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexWrap={{ xs: "wrap", sm: "nowrap" }}
      {...containerProps}
    >
      <Box width={{ xs: 1, sm: "auto" }}>
        <Typography variant="label" size="large" component="h4" {...props}>
          {title || children}
        </Typography>
        {description && (
          <Box mt={1} mb={{ xs: 1, sm: 8 }}>
            <Typography component="p" color="secondary">
              {description}
            </Typography>
          </Box>
        )}
      </Box>
      <Box width={{ xs: 1, sm: "auto" }} my={{ xs: 2, sm: 0 }} flexShrink={0}>
        {action}
      </Box>
    </Box>
  );
};

type SectionRowProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  divider?: boolean;
};

export const SectionRow: React.FC<React.PropsWithChildren<SectionRowProps>> = ({
  title,
  subtitle,
  divider,
  children,
  ...props
}) => {
  return (
    <Box flexWrap="wrap" mb={3} {...props}>
      {title && (
        <Typography component="p" variant="label" mb={1}>
          {title}
        </Typography>
      )}
      {subtitle && <Typography component="p">{subtitle}</Typography>}
      {children}
      {divider && (
        <Box mt={3}>
          <Divider color="secondary" />
        </Box>
      )}
    </Box>
  );
};

type SectionSubtitleMiddleActionProps = Partial<TypographyProps> & {
  children?: React.ReactNode;
  action?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  containerProps?: BoxProps;
  divider?: boolean;
  endButton?: React.ReactNode;
};

export const SectionSubtitleMiddleAction = ({
  children,
  title,
  action,
  description,
  divider,
  endButton,
  containerProps = {},
  ...props
}: SectionSubtitleMiddleActionProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexWrap={{ xs: "wrap", sm: "nowrap" }}
      {...containerProps}
    >
      <Box width={{ xs: 1, sm: "100%" }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          alignItems={"center"}
        >
          <Typography variant="label" size="large" component="h4" {...props}>
            {title || children}
          </Typography>
          <Box>{action}</Box>
        </Box>
        {description && (
          <Box mt={1} mb={{ xs: 1 }}>
            <Typography component="p" color="secondary" mr={4}>
              {description}
            </Typography>
          </Box>
        )}

        {endButton && endButton}

        {divider && (
          <Box my={3}>
            <Divider color="secondary" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const SectionSubtitleAlignCenterAction = ({
  children,
  title,
  action,
  description,
  divider,
  containerProps = {},
  ...props
}: SectionSubtitleMiddleActionProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexWrap={{ xs: "wrap", sm: "nowrap" }}
      {...containerProps}
    >
      <Box width={{ xs: 1, sm: "100%" }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
        >
          <Box>
            <Typography variant="label" size="medium" component="h4" {...props}>
              {title || children}
            </Typography>

            {description && (
              <Box mt={1} mb={{ xs: 1 }}>
                <Typography component="p" color="secondary" size="small">
                  {description}
                </Typography>
              </Box>
            )}
          </Box>
          <Box>{action}</Box>
        </Box>
        {divider && (
          <Box my={2}>
            <Divider color="secondary" />
          </Box>
        )}
      </Box>
    </Box>
  );
};
