import { EmployerDashboardJobsComponentLazy } from "../lazy";

import { EmployerDashboardLayout } from "./layout";

export const EmployerDashboardMyJobsPage = () => {
  return (
    <EmployerDashboardLayout
      pageTitle="My Jobs"
      bgcolor="var(--soft-blue)"
      setBgOnBody
    >
      <EmployerDashboardJobsComponentLazy />
    </EmployerDashboardLayout>
  );
};
