import type { ProviderContext } from "notistack";
import { useSnackbar } from "notistack";

// eslint-disable-next-line import/no-mutable-exports
export let useSnackbarRef: ProviderContext;

export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};
