import React from "react";
import type { WrappedFieldProps } from "redux-form";

import { Box, Button } from "@hexocean/braintrust-ui-components";

import { getPaymentTypeOrder } from "./helpers";

type PaymentTypeFieldProps = {
  job_type: WrappedFieldProps;
  payment_type: WrappedFieldProps;
};

export const PaymentTypeField = ({
  job_type,
  payment_type,
}: PaymentTypeFieldProps) => {
  // temp type until form not typed properly
  const order = getPaymentTypeOrder(
    job_type.input.value as EnumType<typeof ENUMS.JobType>,
  );
  const options = order.map((value) => {
    return {
      value,
      label: ENUMS.JobPaymentTypeLabels[value],
    };
  });

  return (
    <Box display="flex" marginLeft={-0.75}>
      {options.map((option) => (
        <Box key={option.value}>
          <Button
            variant={
              payment_type.input.value === option.value
                ? "transparent"
                : "transparent-grey"
            }
            className="fw-400"
            shape="squared"
            onClick={() => {
              payment_type.input.onChange(option.value);
            }}
            style={{ padding: "6px 8px" }}
          >
            {option.label}
          </Button>
        </Box>
      ))}
    </Box>
  );
};
