import {
  ExpressionlessCircleIcon,
  type IconElementType,
  ISupportIcon,
  JoinedHandsIcon,
  LightBulbIcon,
  QuestionMarksIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { StickerValue } from "@js/types/give-and-get-help";

export const READ_MORE_LIMIT = 500;
export const READ_MORE_MOBILE_LIMIT = 240;
export const DESCRIPTION_MAX_LENGTH = 8000;
export const DESCRIPTION_MAX_LENGTH_TO_DISPLAY_COUNTER = 7750;
export const HASHTAGS_MAX_AMOUNT = 20;
export const REVIEW_APPROVAL_DURATION = 72;

export const POST_HASHTAG_QUERY_PARAM = "post_hashtag";
export const HASHTAG_QUERY_PARAM = "hashtag";

export type Sticker = {
  IconComponent: IconElementType;
  title: string;
  value: StickerValue;
  rotateOnHoverDirection?: "left" | "right";
};

type StickerWithColors = Sticker & {
  colorVariant: "yellow" | "violet" | "orange" | "blue" | "green" | "grey";
  backgroundColor:
    | "var(--soft-yellow)"
    | "var(--soft-violet)"
    | "var(--soft-orange)"
    | "var(--soft-blue)"
    | "var(--soft-green)"
    | "var(--soft-grey)";
};

export const STICKERS: StickerWithColors[] = [
  {
    IconComponent: LightBulbIcon,
    title: "Great idea",
    value: ENUMS.StickerEnum.GREAT_IDEA,
    rotateOnHoverDirection: "left",
    colorVariant: "yellow",
    backgroundColor: "var(--soft-yellow)",
  },
  {
    IconComponent: ISupportIcon,
    title: "I support this",
    value: ENUMS.StickerEnum.PLUS_ONE,
    rotateOnHoverDirection: "right",
    colorVariant: "green",
    backgroundColor: "var(--soft-green)",
  },
  {
    IconComponent: JoinedHandsIcon,
    title: "Thank you",
    value: ENUMS.StickerEnum.THANK_YOU,
    rotateOnHoverDirection: "left",
    colorVariant: "violet",
    backgroundColor: "var(--soft-violet)",
  },
  {
    IconComponent: QuestionMarksIcon,
    title: "Tell me more",
    value: ENUMS.StickerEnum.TELL_ME_MORE,
    rotateOnHoverDirection: "right",
    colorVariant: "orange",
    backgroundColor: "var(--soft-orange)",
  },
  {
    IconComponent: ExpressionlessCircleIcon,
    title: "Not quite right",
    value: ENUMS.StickerEnum.NOT_QUITE_RIGHT,
    rotateOnHoverDirection: "right",
    colorVariant: "grey",
    backgroundColor: "var(--soft-blue)",
  },
];

export const STICKERS_MAP = Object.fromEntries(
  STICKERS.map((sticker) => [sticker.value, sticker]),
) as Record<StickerValue, StickerWithColors>;

export const FEED_MAIN_PAGE_URL = "/feed";
export const CREATED_CELEBRATORY_POST_SEARCH_PARAM = "created_celebratory_post";
