import React from "react";

import { Button, Grid } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks/use-user";
import { openRecreateOTPAuthBackupCodesModal } from "@js/apps/settings/components/otp-auth/recreate-otp-backup-codes-modal";
import { openRecreateOTPAuthModal } from "@js/apps/settings/components/otp-auth/recreate-otp-modal";
import { Section } from "@js/apps/settings/forms/helpers";

import {
  DisableOTPAuthModal,
  RecreateOTPAuthBackupCodesModal,
  RecreateOTPAuthModal,
  ToggleOTPSwitch,
} from "../index";

export const OTPAuthSection = () => {
  const user = useUser();
  const isOTPEnabled = user?.is_otp_enabled;
  const canDisableOTP = user?.can_disable_otp;

  return (
    <Section title="Two-factor authentication">
      <Grid container spacing={2}>
        <Grid item lg={5} md={12} sm={5} xs={12}>
          <ToggleOTPSwitch
            isOTPEnabled={!!isOTPEnabled}
            canDisableOTP={!!canDisableOTP}
          />
        </Grid>
        <Grid item lg={7} md={12} sm={7} xs={12} className="right">
          {isOTPEnabled && (
            <>
              <Button
                variant="secondary"
                style={{ margin: "2px 0 2px 0" }}
                onClick={openRecreateOTPAuthModal}
              >
                Change 2FA App
              </Button>
              <Button
                variant="secondary"
                style={{ margin: "2px 0 2px 8px" }}
                onClick={openRecreateOTPAuthBackupCodesModal}
              >
                Recreate 2FA Backup Codes
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <DisableOTPAuthModal />
      <RecreateOTPAuthModal />
      <RecreateOTPAuthBackupCodesModal />
    </Section>
  );
};
