import { useUser } from "@js/apps/common/hooks";
import { BoostJobButton } from "@js/apps/jobs/components/boost-job-button";
import {
  RespondToOfferButton,
  ScheduleInterviewButton,
  StartAIInterviewButton,
  StartScreeningButton,
} from "@js/apps/jobs/components/job-action-buttons/components";
import type { FreelancerBid } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

type AppliedJobsCTAProps = {
  bid: FreelancerBid;
};

export const AppliedJobsCTA = ({ bid }: AppliedJobsCTAProps) => {
  const user = useUser();

  const jobId = bid.job.id;
  const statusForFreelancer = bid.status_for_freelancer;
  const employerUserId = bid.job?.creator_user_id;
  const offerId = bid.current_offer?.id;
  const talentId = user?.freelancer;

  switch (statusForFreelancer) {
    case ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS:
    case ENUMS.JobFreelancerStatus.INVITED_BY_CLIENT:
    case ENUMS.JobFreelancerStatus.INVITED_BY_MATCHER:
    case ENUMS.JobFreelancerStatus.OFFER_ACCEPTED:
    case ENUMS.JobFreelancerStatus.APPLICATION_REJECTED:
    case ENUMS.JobFreelancerStatus.JOB_FILLED:
    case ENUMS.JobFreelancerStatus.JOB_CLOSED:
    case ENUMS.JobFreelancerStatus.JOB_ON_HOLD:
    case ENUMS.JobFreelancerStatus.IN_REVIEW_AFTER_AI_INTERVIEW:
      return null;

    case ENUMS.JobFreelancerStatus.APPLICATION_SENT:
    case ENUMS.JobFreelancerStatus.IN_REVIEW:
      if (bid.is_boosted) return null;

      return (
        <BoostJobButton
          variant="primary"
          boostCredit={user?.boost_credit}
          bidId={bid.id}
          fullWidth
          statusForFreelancer={statusForFreelancer}
          isOpen={bid.job.is_open}
        />
      );
    case ENUMS.JobFreelancerStatus.PENDING_APPROVAL:
      return <StartScreeningButton fullWidth variant="primary" withIcon />;

    case ENUMS.JobFreelancerStatus.LIVE_INTERVIEWING:
      if (!employerUserId) return null;

      return (
        <ScheduleInterviewButton
          employerUserId={employerUserId}
          fullWidth
          withIcon
          variant="primary"
        />
      );
    case ENUMS.JobFreelancerStatus.AI_INTERVIEWING:
      if (!talentId) return null;

      return (
        <StartAIInterviewButton
          variant="primary"
          jobId={jobId}
          talentId={talentId}
          withIcon
        />
      );
    case ENUMS.JobFreelancerStatus.NEW_OFFER:
    case ENUMS.JobFreelancerStatus.UPDATED_OFFER:
      if (!offerId) return;

      return (
        <RespondToOfferButton
          jobId={jobId}
          offerId={offerId}
          fullWidth
          variant="primary"
        />
      );

    default: {
      assertUnreachable(statusForFreelancer);
      return null;
    }
  }
};
