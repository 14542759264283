import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { CommonConfirmationModal, ModalConfirm } from "@js/components/modal";

type ProjectDeletionConfirmationProps = {
  projectName: string;
  onConfirm: () => Promise<void>;
};

export const openProjectDeletionConfirmation = ({
  projectName,
  onConfirm,
}: ProjectDeletionConfirmationProps) => {
  CommonConfirmationModal.open({
    children: (
      <ModalConfirm
        confirmText="Remove project"
        cancelText="Keep project"
        confirmButtonVariant="destructive"
        buttonsSquareShape
        onConfirm={async () => {
          await onConfirm();
          CommonConfirmationModal.close();
        }}
        onCancel={CommonConfirmationModal.close}
      >
        <Box>
          <Box
            display="flex"
            gap={2}
            justifyContent="flex-start"
            alignItems="center"
            mb={3}
          >
            <IconButton
              aria-label="edit"
              onClick={CommonConfirmationModal.close}
              variant="tertiary"
              size="small"
            >
              <ArrowLeftIcon />
            </IconButton>
            <Typography component="h1" variant="title" size="small">
              Remove project
            </Typography>
          </Box>
          <Typography component="p">
            Removing your <strong>{`${projectName}`} </strong> project from your
            profile can't be undone. Are you sure you want to remove this
            project?
          </Typography>
        </Box>
      </ModalConfirm>
    ),
  });
};
