import React from "react";

import { Box, Rating, Typography } from "@hexocean/braintrust-ui-components";
import { useScrollToReviewSection } from "@js/apps/freelancer/hooks";
import { useGetTalentReviewsSummaryQuery } from "@js/apps/reviews/api";
import type { FreelancerPublic } from "@js/types/freelancer";

import style from "./style.module.scss";

type ServicesReviewSectionProps = {
  isOwnProfile: boolean;
  profile: FreelancerPublic;
};

export const ServicesReviewSection = ({
  isOwnProfile,
  profile,
}: ServicesReviewSectionProps) => {
  const { data: reviewSummary } = useGetTalentReviewsSummaryQuery({
    id: profile.id,
  });

  const hasReviews = Boolean(reviewSummary?.reviews_count);

  const scrollToReviewSection = useScrollToReviewSection();

  if (!hasReviews) {
    return null;
  }

  return (
    <div className={style.servicesReviewSection}>
      {hasReviews && (
        <Box textAlign="center">
          {isOwnProfile && (
            <Typography
              component="p"
              variant="paragraph"
              size="small"
              fontWeight={500}
              mb={1}
            >
              My Reviews
            </Typography>
          )}
          <Box display="flex" alignItems="center">
            <Rating
              variant="stars"
              size="small"
              precision={0.5}
              value={Number(reviewSummary?.average_rating)}
              className={style.servicesReviewSectionStarsRating}
              readOnly
            />
            <Typography
              className={style.servicesReviewSectionReviewsLink}
              ml={1}
              component="span"
              size="medium"
              onClick={scrollToReviewSection}
            >
              {Number(reviewSummary?.average_rating)} out of 5
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
};
