import {
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowTopRightIcon,
  CloseIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import styles from "./payment-method-failed-top-bar-view.module.scss";

type PaymentMethodFailedTopBarViewProps = {
  onCloseClick: () => void;
  onEditBillingInformationClick: () => void;
};

export const PaymentMethodFailedTopBarView = ({
  onCloseClick,
  onEditBillingInformationClick,
}: PaymentMethodFailedTopBarViewProps): JSX.Element => {
  return (
    <div className={styles.paymentMethodFailedTopBar}>
      <Typography
        component="p"
        size="small"
        className={styles.paymentMethodFailedTopBarCopy}
      >
        🚨 Your payment method has failed. Please update your billing
        information.{"  "}
        <Button
          variant="no-padding"
          size="x-small"
          fontWeight={500}
          sx={{
            verticalAlign: "baseline",
            marginLeft: "4px",
          }}
          onClick={onEditBillingInformationClick}
          endIcon={<ArrowTopRightIcon sx={{ marginLeft: "-8px" }} />}
        >
          Update billing details
        </Button>
      </Typography>
      <IconButton
        size="small"
        variant="transparent"
        aria-label="Dismiss payment method failed bar"
        className={styles.paymentMethodFailedTopBarDismissBtn}
        onClick={onCloseClick}
      >
        <CloseIcon
          sx={{
            borderRadius: "50%",
            color: "white",
          }}
        />
      </IconButton>
    </div>
  );
};
