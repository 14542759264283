import { assertUnreachable } from "@js/utils";

export const getApplyButtonLabelByStatus = (
  status?: EnumType<typeof ENUMS.JobStatus>,
) => {
  const DEFAULT_LABEL = "Sign up to apply";
  if (!status) return DEFAULT_LABEL;

  switch (status) {
    case ENUMS.JobStatus.ON_HOLD:
    case ENUMS.JobStatus.COMPLETED_HIRED:
    case ENUMS.JobStatus.COMPLETED_NOT_HIRED:
    case ENUMS.JobStatus.COMPLETED:
    case ENUMS.JobStatus.ACTIVE_FILLED:
      return "Sign up to see other opportunities";
    case ENUMS.JobStatus.ACTIVE:
    case ENUMS.JobStatus.ACTIVE_NOT_FILLED:
    case ENUMS.JobStatus.OPEN:
      return DEFAULT_LABEL;
    default:
      assertUnreachable(status);
      return DEFAULT_LABEL;
  }
};
