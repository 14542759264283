import { useApplicationBoost } from "../../hooks/application-boost";

type ApplicationBoostFeatureFlagWrapperProps = {
  children: React.ReactNode;
};

export const ApplicationBoostFeatureFlagWrapper = ({
  children,
}: ApplicationBoostFeatureFlagWrapperProps) => {
  const { showApplicationBoost } = useApplicationBoost();
  if (showApplicationBoost) {
    return children;
  } else {
    return null;
  }
};
