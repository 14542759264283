import React from "react";
import _ from "underscore";

// Returns new reference only if new value object is different than the old one
// Useful for array and object values that may unintentional trigger some effects/rerenders
export const useEqualMemo = <T>(value: T) => {
  const prev = React.useRef(value);

  const memoized = React.useMemo(() => {
    if (_.isEqual(prev.current, value)) {
      return prev.current;
    }
    prev.current = value;
    return value;
  }, [value]);

  return memoized;
};
