import type { WrappedFieldProps } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { countInvoiceSubTotal, countInvoiceTax } from "@js/apps/invoices/logic";
import { formatCurrency } from "@js/utils";

import styles from "./style.module.scss";

export const InvoiceTotal = ({ input }: WrappedFieldProps) => {
  const invoiceItems = input.value;

  const subTotal = countInvoiceSubTotal(invoiceItems);
  const tax = countInvoiceTax(invoiceItems);
  const total = subTotal.plus(tax);

  return (
    <>
      <div className={styles.totalInvoiceRow}>
        <Typography
          component="span"
          color="grey"
          size="small"
          textTransform="uppercase"
          fontWeight={500}
        >
          Sub-total
        </Typography>
        <Typography
          component="span"
          size="large"
          className={styles.totalInvoiceAmount}
        >
          {formatCurrency(subTotal)}
        </Typography>
      </div>
      {!!tax && (
        <div className={styles.totalInvoiceRow}>
          <Typography
            component="span"
            color="grey"
            size="small"
            fontWeight={500}
            textTransform="uppercase"
          >
            Total Tax
          </Typography>
          <Typography
            component="span"
            size="large"
            className={styles.totalInvoiceAmount}
          >
            {formatCurrency(tax)}
          </Typography>
        </div>
      )}
      <div className={`${styles.totalInvoiceRow} ${styles.totalInvoiceTotal}`}>
        <Typography component="span" variant="title" size="small">
          TOTAL
        </Typography>
        <Typography
          component="span"
          variant="title"
          fontWeight={500}
          size="small"
          className={styles.totalInvoiceAmount}
        >
          {formatCurrency(total)}
        </Typography>
      </div>
    </>
  );
};
