import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { InterviewRequestModalContent } from "../interview-request-message";

import { useRequestLiveInterview } from "./hook";

export type RequestLiveInterviewProps = {
  bid: EmployerBidListBid;
  job: Job;
};

export const RequestLiveInterviewContent = ({
  bid,
  job,
}: RequestLiveInterviewProps) => {
  const interviewMessageData = useRequestLiveInterview(bid, job);

  if (!interviewMessageData) return null;

  const { name, initialMessage, handleSubmit } = interviewMessageData;

  return (
    <InterviewRequestModalContent
      headlineName={name}
      initialMessage={initialMessage}
      handleSubmit={handleSubmit}
      submitButtonLabel="Send interview request"
    />
  );
};
