import { useCallback } from "react";

import { useGetFreelancerLocationMismatchQuery } from "@js/apps/admin/api";
import { useUser } from "@js/apps/common/hooks";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";

export const useLocationMismatchModal = (talentId: number) => {
  const user = useUser();
  const { data, isLoading } = useGetFreelancerLocationMismatchQuery(talentId);
  const { openMessengerModal } = useOpenMessengerModal();

  const handleMessageUserClick = useCallback(() => {
    if (!data || !user?.id) return;

    return openMessengerModal({
      context: { participants: [user.id, data.user.id] },
    });
  }, [openMessengerModal, user?.id, data]);

  return {
    data,
    isLoading,
    handleMessageUserClick,
  };
};
