import { useEffect } from "react";
import type { animate } from "framer-motion";
import type { AnimationScope } from "framer-motion";
import { useAnimate } from "framer-motion";
import { random } from "underscore";

type AnimationSequence = Parameters<typeof animate>[0];

export const useClickStarAnimation = (
  prevent: boolean,
  starCount: number,
  maxRadius: number,
  minRadius: number,
  sparkleClass: string,
): AnimationScope => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (!prevent) {
      const self = scope.current;

      const onButtonClick = () => {
        const sparkles = Array.from({ length: starCount });
        const timings = sparkles.map(() => random(1, 5) / 10);

        const sparklesReset: AnimationSequence = sparkles.map((_, index) => [
          `.${sparkleClass}-${index}`,
          {
            x: 0,
            y: 0,
          },
          {
            duration: 0.000001,
          },
        ]);

        const sparklesAnimation: AnimationSequence = sparkles.map(
          (_, index) => {
            const angle = (index / (sparkles.length / 2)) * Math.PI;
            const x = random(minRadius, maxRadius) * Math.cos(angle);
            const y = random(minRadius, maxRadius) * Math.sin(angle);
            return [
              `.${sparkleClass}-${index}`,
              {
                x,
                y,
                scale: random(8, 17) / 10,
                opacity: 1,
              },
              {
                duration: timings[index],
                at: "<",
              },
            ];
          },
        );

        const sparklesFadeOut: AnimationSequence = sparkles.map((_, index) => [
          `.${sparkleClass}-${index}`,
          {
            opacity: 0,
            scale: 0,
          },
          {
            duration: timings[index],
            at: "<",
            delay: timings[index] * 1.2,
          },
        ]);

        animate([...sparklesReset, ...sparklesAnimation, ...sparklesFadeOut]);
      };

      self.addEventListener("click", onButtonClick);
      return () => {
        self.removeEventListener("click", onButtonClick);
      };
    }
  }, [prevent, animate, scope, starCount, sparkleClass, maxRadius, minRadius]);

  return scope;
};
