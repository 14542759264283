import { useTokenFeatureManagement } from "../../hooks/paid-management-feature";

type TokenFeatureManagementFeatureFlagWrapperProps = {
  children: React.ReactNode;
};

export const TokenFeatureManagementFeatureFlagWrapper = ({
  children,
}: TokenFeatureManagementFeatureFlagWrapperProps) => {
  const { showTokenFeatureManagement } = useTokenFeatureManagement();
  if (showTokenFeatureManagement) {
    return children;
  } else {
    return null;
  }
};
