import React from "react";

import type {
  PaymentMethod,
  StripeACHPayment,
  StripeCreditCardPayment,
} from "@js/types/payments";

import { AddStripeACH } from "../../components/add-stripe-ach";
import { AddStripeCreditCardModal } from "../../components/add-stripe-credit-card";
import { DeletePaymentMethodConfirmModalInstance } from "../../components/delete-payment-method-confirm-modal";
import { VerifyStripeACHPaymentMethodModalInstance } from "../../components/verify-stripe-ach-payment-method";
import type {
  CreateACHPaymentMethodStripeResult,
  CreateCCPaymentMethodStripeResult,
} from "../../types";

import { AddPaymentMethodModal } from "./add-payment-method-modal";
import { usePaymentContainer } from "./use-payment-container";

type RenderMethod = (props: {
  fetchingPaymentMethods: boolean;
  paymentMethods: PaymentMethod[];
  creatingPaymentMethod: boolean;
  onPaymentMethodDelete: (
    deletePaymentMethod: (paymentMethod: PaymentMethod) => Promise<void>,
    paymentMethod: PaymentMethod,
  ) => void;
  onVerifyStripeACHPaymentMethod: (paymentMethod: StripeACHPayment) => void;
  createStripePaymentMethod: (
    stripePaymentMethod: CreateCCPaymentMethodStripeResult,
  ) => Promise<void | StripeCreditCardPayment> | undefined;
  createStripeACHPaymentMethod: (
    stripePaymentMethod: CreateACHPaymentMethodStripeResult,
  ) => Promise<void | StripeACHPayment> | undefined;
  deletePaymentMethod: (paymentMethod: PaymentMethod) => Promise<void>;
  setAsDefaultPaymentMethod: (paymentMethod: PaymentMethod) => Promise<void>;
  addPaymentMethod: () => void;
  canAddPaymentMethod: boolean;
  cannotAddPaymentMethodReason?: string;
}) => React.ReactNode;

type PaymentMethodsContainerProps = {
  render: RenderMethod;
  inOfferFlow?: boolean;
};

export const PaymentMethodsContainer = ({
  render,
  inOfferFlow,
}: PaymentMethodsContainerProps) => {
  const {
    fetchingPaymentMethods,
    paymentMethods,
    creatingPaymentMethod,
    onPaymentMethodDelete,
    onVerifyStripeACHPaymentMethod,
    createStripePaymentMethod,
    createStripeACHPaymentMethod,
    deletePaymentMethod,
    setAsDefaultPaymentMethod,
    addPaymentMethod,
    canAddPaymentMethod,
    cannotAddPaymentMethodReason,
  } = usePaymentContainer({
    inOfferFlow,
  });

  return (
    <>
      {render({
        fetchingPaymentMethods,
        paymentMethods,
        creatingPaymentMethod,
        onPaymentMethodDelete,
        onVerifyStripeACHPaymentMethod,
        createStripePaymentMethod,
        createStripeACHPaymentMethod,
        deletePaymentMethod,
        setAsDefaultPaymentMethod,
        addPaymentMethod,
        canAddPaymentMethod: !!canAddPaymentMethod && !creatingPaymentMethod,
        cannotAddPaymentMethodReason,
      })}
      <AddStripeCreditCardModal onSuccess={createStripePaymentMethod} />
      <AddStripeACH onSubmit={createStripeACHPaymentMethod} />
      <AddPaymentMethodModal />
      <DeletePaymentMethodConfirmModalInstance />
      <VerifyStripeACHPaymentMethodModalInstance />
    </>
  );
};
