import { z } from "zod";

export const HIREFLIX_INTERVIEW_EVENT_TYPES = {
  LOADED: "interview.loaded",
  FINISHED: "interview.finished",
} as const;

const interviewEventDataSchema = z.object({
  type: z.nativeEnum(HIREFLIX_INTERVIEW_EVENT_TYPES),
  payload: z.record(z.unknown()).optional(),
  description: z.string().optional(),
});

type HireflixInterviewEventData = z.infer<typeof interviewEventDataSchema>;

// https://help.hireflix.com/article/c6l3n1qgrw-integrating-hireflix-in-your-ats
export const validateInterviewEvent = (
  eventData: unknown,
): eventData is HireflixInterviewEventData => {
  const result = interviewEventDataSchema.safeParse(eventData);

  return result.success;
};

export const isFinishInterviewEvent = (
  eventData: HireflixInterviewEventData,
) => {
  return eventData.type === HIREFLIX_INTERVIEW_EVENT_TYPES.FINISHED;
};
