import { useCallback, useMemo } from "react";
import { SubmissionError } from "redux-form";

import { useGetEmployerTeamMembersQuery } from "@js/apps/employer/api";
import { Snackbar } from "@js/components/snackbar";
import type { EmployerOwnJob } from "@js/types/jobs";

import {
  useChangeJobOwnerMutation,
  useGetJobQuery,
  useLazyGetOwnJobsQuery,
} from "../../api";
import type { ChangeJobOwnerFormData } from "../../forms/change-job-owner";

export const useChangeJobOwnersForm = (
  jobId: number,
  jobStatus: EmployerOwnJob["status"],
) => {
  const { data: job } = useGetJobQuery({ id: jobId });
  const { data: teamMembers } = useGetEmployerTeamMembersQuery();
  const [getOwnJobs] = useLazyGetOwnJobsQuery();
  const [changeJobOwner] = useChangeJobOwnerMutation();

  const onSubmit = useCallback(
    (values: ChangeJobOwnerFormData) => {
      if (!jobId) {
        return;
      }
      changeJobOwner({ jobId, newOwnerId: values.new_owner })
        .unwrap()
        .then(async () => {
          await getOwnJobs({ status: jobStatus }).unwrap();
        })
        .catch((error) => {
          throw new SubmissionError(error);
        });
    },
    [jobId, changeJobOwner, getOwnJobs, jobStatus],
  );

  const onSubmitSuccess = ({
    onSuccess,
    onCancel,
  }: {
    onSuccess: () => void;
    onCancel: () => void;
  }) => {
    onSuccess();
    Snackbar.success("Job owner successfully changed.");
    onCancel();
  };

  const initialValues = useMemo(
    () => ({
      new_owner: job?.creator?.id,
    }),
    [job?.creator?.id],
  );

  return {
    initialValues,
    onSubmit,
    onSubmitSuccess,
    jobOwnersToSelect: teamMembers || [],
  };
};
