import React from "react";
import { Field } from "redux-form";

import { Box, Button } from "@hexocean/braintrust-ui-components";
import { CommentTextField } from "@js/apps/common/forms/fields/comment-field";
import { createFormInstance } from "@js/forms/components";

const ADD_COMMENT_FORM_ID = "addCommentForm";

type AddCommentFormProps = {
  bidId: number;
  onSubmit: (values: any) => Promise<unknown>;
  onSubmitSuccess: (_result: any, _dispatch: any, props: any) => void;
};

type FormData = {
  id: number;
  content: string;
};

const AddCommentFormInstance = createFormInstance<FormData, unknown>(
  ADD_COMMENT_FORM_ID,
);

export const AddCommentForm = ({
  bidId,
  onSubmit,
  onSubmitSuccess,
}: AddCommentFormProps) => {
  const initialValues = React.useMemo(() => {
    return {
      id: bidId,
      content: "",
    };
  }, [bidId]);

  return (
    <Box className="comments-form">
      <AddCommentFormInstance
        form={`${ADD_COMMENT_FORM_ID}-${bidId}`}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        initialValues={initialValues}
      >
        <Field
          id="add-note"
          name="content"
          placeholder="Add a comment..."
          className="add-comment-field"
          fullWidth
          submitButton={
            <Button
              size="x-small"
              className="comments-form__save-comment-button"
              variant="transparent-blue"
              type="submit"
            >
              Save
            </Button>
          }
          component={CommentTextField}
        />
      </AddCommentFormInstance>
    </Box>
  );
};
