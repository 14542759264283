import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { Box } from "@hexocean/braintrust-ui-components";
import type { JobFilters } from "@js/apps/common/components/filters";
import {
  FiltersBottomActionsContainer,
  FiltersBottomContainer,
  FiltersCarousel,
  FiltersCarouselContainer,
  FiltersClearAllButton,
  FiltersSortButton,
  ResultsCount,
} from "@js/apps/common/components/filters/components";
import { SEARCH_FILTER_DATA_TYPES } from "@js/apps/common/components/filters/constants";
import {
  RoleFilter,
  SearchFilter,
  SkillsFilter,
} from "@js/apps/common/components/filters/fields";
import { WorkingTimezoneJobsFilter } from "@js/apps/common/components/filters/fields/working-timezone-jobs-filter";
import type { RoleFormType } from "@js/apps/common/components/filters/forms/role-form";
import { SaveJobsFilters } from "@js/apps/common/components/save-filters/save-jobs-filters";
import { SearchFilterLocationContext } from "@js/apps/common/context/search-filter-location-context";
import { useAccountType } from "@js/apps/common/hooks";
import type { JobFilterLocation } from "@js/apps/filters/types";
import { useGetActiveFilterName } from "@js/apps/jobs/apps/listing/hooks/active-filter-name";
import {
  getDefaultJobsSortOrdering,
  getFiltersStorageKey,
} from "@js/apps/jobs/apps/listing/utils";
import { useResetSearchEventQueryId } from "@js/apps/tracking/hooks/use-reset-search-event-query-id";
import { useResetUniversalSearchPhrase } from "@js/apps/universal-search/universal-search-slice";
import { useAppDispatch, useNavigate } from "@js/hooks";
import { LocalStorage } from "@js/services";
import { isNotNullable } from "@js/utils";

import { savedFilterLoaded } from "../../actions";
import {
  CommitmentFilter,
  JobTypeFilter,
  LocationFilter,
  RateFilter,
} from "../../fields";

export type JobsListFiltersProps = {
  isAnyFilterApplied: boolean;
  count: number;
  filters: Partial<JobFilters>;
  filtersFromParams: Partial<JobFilters>;
  location: JobFilterLocation;
  defaultRole: RoleFormType["role"] | undefined;
  refetchJobs?: () => void;
};

const searchOption = ENUMS.SearchEventOption.JOB_SEARCH_BOX;

export const JobsListFilters = ({
  isAnyFilterApplied,
  count,
  filters,
  filtersFromParams,
  location,
  defaultRole = [],
  refetchJobs,
}: JobsListFiltersProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const routerLocation = useLocation();
  const { isEmployer } = useAccountType();
  const { savedFilterName } = useGetActiveFilterName(location);
  const clearUniversalSearch = useResetUniversalSearchPhrase();

  const items = useMemo(
    () =>
      getJobsFilterItems({
        defaultRole,
        isEmployer,
        refetchJobs,
      }),
    [defaultRole, isEmployer, refetchJobs],
  );

  const { resetSearchEventQueryId } = useResetSearchEventQueryId();

  // cleanup effect
  useEffect(
    () => () => resetSearchEventQueryId(searchOption),
    [resetSearchEventQueryId],
  );

  const clearFilters = () => {
    const storageKey = getFiltersStorageKey(location);
    LocalStorage.removeItem(storageKey);

    clearUniversalSearch();
    resetSearchEventQueryId(searchOption);
    dispatch(
      savedFilterLoaded({
        location,
        name: null,
      }),
    );
    navigate(routerLocation.pathname);
  };

  return (
    <SearchFilterLocationContext.Provider value={searchOption}>
      <FiltersCarouselContainer>
        <FiltersCarousel items={items} />
      </FiltersCarouselContainer>
      <FiltersBottomContainer>
        <FiltersBottomActionsContainer>
          <ResultsCount
            dataType={SEARCH_FILTER_DATA_TYPES.jobs}
            count={count}
          />
          {isAnyFilterApplied && (
            <>
              <SaveJobsFilters
                filters={filtersFromParams}
                location={location}
                savedFilterName={savedFilterName}
              />
              <FiltersClearAllButton onClick={clearFilters} />
            </>
          )}
        </FiltersBottomActionsContainer>
        <FiltersSortButton
          location={location}
          defaultValue={getDefaultJobsSortOrdering(filters)}
        />
      </FiltersBottomContainer>
    </SearchFilterLocationContext.Provider>
  );
};

const getJobsFilterItems = ({
  defaultRole = [],
  isEmployer,
  refetchJobs,
}: {
  defaultRole: RoleFormType["role"] | undefined;
  isEmployer: boolean;
  refetchJobs?: () => void;
}) => {
  const showRoleFilter = defaultRole.length === 0;

  return [
    <Box key="search-filter" maxWidth={350}>
      <SearchFilter
        dataType={SEARCH_FILTER_DATA_TYPES.jobs}
        refetch={refetchJobs}
        placeholder="Search jobs"
      />
    </Box>,
    showRoleFilter ? <RoleFilter key="role-filter" /> : null,
    <SkillsFilter key="skills-filter" defaultRole={defaultRole} />,
    <RateFilter key="rate-filter" />,
    <LocationFilter key="location-filter" />,
    !isEmployer ? (
      <WorkingTimezoneJobsFilter key="working-timezone-jobs-filter" />
    ) : null,
    <CommitmentFilter key="commitment-filter" />,
    <JobTypeFilter key="job-type-filter" />,
  ].filter(isNotNullable);
};
