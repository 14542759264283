import React from "react";

import { Badge } from "@js/components/badge";
import type { EmployerBidListBid } from "@js/types/jobs";

type InvitedBadgeProps = {
  bid: EmployerBidListBid;
};

export const InvitedBadge = ({ bid }: InvitedBadgeProps) => {
  if (!bid.is_invited) return null;

  return (
    <Badge color="var(--dark-violet)" backgroundColor="var(--medium-violet)">
      Invited
    </Badge>
  );
};
