import type { Message } from "@js/types/messenger";

import {
  AntiSpamTombstoneWrapper,
  ReportNotSpamBtn,
} from "../anti-spam-tombstone";

import { TextMessage } from "./text-message";

type AntiSpamComponentProps = {
  isOwner: boolean;
  message: Message;
};

export const AntiSpamComponent = ({
  isOwner,
  message,
}: AntiSpamComponentProps) => {
  return (
    <AntiSpamTombstoneWrapper isOwner={isOwner}>
      <TextMessage
        message={message}
        isOwner={isOwner}
        variant={isOwner ? "soft-red" : "medium-red"}
        linkify={false}
      >
        {!isOwner && (
          <ReportNotSpamBtn messageId={message.id} roomId={message.room} />
        )}
      </TextMessage>
    </AntiSpamTombstoneWrapper>
  );
};
