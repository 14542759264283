import { useNotes } from "./hooks/notes";
import { useOfferNotes } from "./hooks/offer-notes";
import { NotesListContent } from "./notes-list-content";

export type OfferNotesFilters = {
  freelancer?: string;
  employer?: string;
  status?: EnumType<typeof ENUMS.OfferStatus>;
  page?: number;
  offer?: string;
  note?: string;
};

type OfferNotesProps = {
  id: number;
  filters?: OfferNotesFilters;
};

export const OfferNotesList = ({ id, filters }: OfferNotesProps) => {
  const offerNotesHandlers = useOfferNotes(id);
  const isHighlighted = (notesId: number) =>
    Number(filters?.note) === Number(notesId);
  return (
    <NotesListContent
      id={id}
      isHighlighted={isHighlighted}
      objectName={ENUMS.NoteContentType.Offer}
      {...offerNotesHandlers}
    />
  );
};

type NotesProps = {
  id: number;
  objectName: EnumType<typeof ENUMS.NoteContentType>;
};

export const NotesList = ({ id, objectName }: NotesProps) => {
  const notesHandlers = useNotes(id, objectName);

  return (
    <NotesListContent id={id} objectName={objectName} {...notesHandlers} />
  );
};
