import { dateDifference } from "@js/utils";

import { JobItemTextCaption } from "../text-caption";

type JobItemSecondaryCaptionPropsTypes = {
  createdTime?: string;
};

export const JobItemSecondaryCaption = ({
  createdTime,
}: JobItemSecondaryCaptionPropsTypes) => {
  const isNew =
    !!createdTime && dateDifference(undefined, createdTime, "days") <= 4;

  if (isNew) {
    return (
      <JobItemTextCaption
        tagText="New"
        style={{ color: "var(--negative-2)" }}
      />
    );
  } else return null;
};
