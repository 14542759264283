import type { TypedWrappedFieldProps } from "redux-form";

import {
  Box,
  Chip,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { capitalize } from "@js/utils";

export const SelectedGradingCriteriaList = ({
  input,
}: TypedWrappedFieldProps<string[]>) => {
  const onRemove = (index: number) => {
    const newCriteria = [...(input.value || [])];
    newCriteria.splice(index, 1);
    input.onChange(newCriteria);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={2}>
      <Typography component="span" size="small" fontWeight={500}>
        {input.value?.length}/5 criteria selected
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {input.value?.map?.((interviewCriteria, index) => (
          <Chip
            key={interviewCriteria}
            sx={{
              border: "1px solid var(--medium-beige) !important",
              padding: "2px 12px !important",
            }}
            color="grey-4"
            typographySize="small"
            label={capitalize(interviewCriteria?.toLowerCase())}
            endIcon={
              <IconButton
                sx={{ padding: "0px !important" }}
                variant="transparent"
                onClick={() => onRemove(index)}
                size="x-small"
                aria-label="Remove criteria"
              >
                <CloseIcon />
              </IconButton>
            }
          />
        ))}
      </Box>
    </Box>
  );
};
