type OpenPopupProps = {
  url: string;
};

export const openPopup = ({ url }: OpenPopupProps): boolean => {
  const newWindow = window.open(url, "_blank");

  if (!isPopupBlocked(newWindow)) {
    return true;
  }

  alert("Please enable popups for this site to ensure full functionality.");

  return false;
};

export const isPopupBlocked = (popup: Window | null) => {
  return !popup || popup.closed || typeof popup.closed === "undefined";
};
