import { ModalInstance } from "@js/components/modal";

import { MODULE_EDIT_MODAL_PROPERTIES } from "../../constants";

import { RoleModal } from "./role-modal";

export const openRoleModal = () =>
  ModalInstance.open({
    children: <RoleModal />,
    ...MODULE_EDIT_MODAL_PROPERTIES,
    className: "profile-page-modal",
  });
