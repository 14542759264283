import { useEffect } from "react";

import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import type { JobFormValues } from "@js/types/jobs";

import { useDraftJobToReview } from "../../hooks/draft-job-to-review";
import { useReviewDraftJob } from "../../hooks/review-draft-jobs-modal";

import { ReviewJobsModalHeader } from "./modal-header";
import { PostedJobSuccessfullyBadge } from "./posted-job-alert";
import { StatusFieldsForReviewDraftsModal } from "./statuses";

import style from "./style.module.scss";

const ReviewDraftsFormInstance = createFormInstance<JobFormValues, unknown>(
  "review-drafts-job-form",
  {
    enableReinitialize: true,
  },
);

export const ReviewDraftsModalContent = () => {
  const {
    draftsCountToReview,
    isDraftLoading,
    handleSkipClick,
    handleBackClick,
    currentReviewedDraftId,
    currentDraftCount,
  } = useDraftJobToReview();
  const isLastJobToPublish = draftsCountToReview === 1;
  const formProps = useReviewDraftJob({
    draftId: currentReviewedDraftId,
    isLastJobToPublish,
  });

  const noDraftsToPost = draftsCountToReview === 0;

  useEffect(() => {
    if (!isDraftLoading && noDraftsToPost) {
      ModalInstance.close();
    }
  }, [isDraftLoading, noDraftsToPost, currentReviewedDraftId]);

  return (
    <Box>
      <ReviewDraftsFormInstance {...formProps}>
        <ReviewJobsModalHeader
          handleBackClick={handleBackClick}
          handleSkipClick={handleSkipClick}
          noDraftsToPost={noDraftsToPost}
        />
        <Box className={style.reviewDraftsModalContent}>
          {isDraftLoading ? (
            <Loader text="Draft loading..." centered />
          ) : (
            <>
              <PostedJobSuccessfullyBadge />
              {!noDraftsToPost && (
                <>
                  <Typography
                    component="h4"
                    fontWeight={400}
                    variant="title"
                    size="small"
                  >
                    Let’s make sure everything looks right
                  </Typography>

                  <Typography component="p" fontWeight={500}>
                    Job {currentDraftCount} of {draftsCountToReview}
                  </Typography>
                  <StatusFieldsForReviewDraftsModal
                    id={currentReviewedDraftId}
                    isLastJobToPublish={isLastJobToPublish}
                    values={formProps.initialValues}
                  />
                </>
              )}
            </>
          )}
        </Box>
      </ReviewDraftsFormInstance>
    </Box>
  );
};

export const openReviewsDraftsJobModal = () => {
  return ModalInstance.open({
    closeButton: false,
    padding: false,
    keepOnBackdropClick: true,
    disablePortal: true,
    className: style.reviewDraftsModal,
    children: <ReviewDraftsModalContent />,
  });
};
