import React from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { REVIEW_APPROVAL_DURATION } from "@js/apps/give-and-get-help/constants";

type RequestApprovalModalProps = {
  approveRequest: () => void;
  offerReceiverName: string;
};

export const RequestApprovalModal = ({
  approveRequest,
  offerReceiverName,
}: RequestApprovalModalProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography component="p" size="large" fontWeight={500} mb={3}>
        Mark 1:1 help complete
      </Typography>
      <Box>
        <Typography component="p" variant="title" fontWeight={400} mb={1}>
          Are you finished working with {offerReceiverName}?
        </Typography>
        <Typography component="p" mb={1}>
          If so, you can mark your 1:1 help as complete, and {offerReceiverName}{" "}
          will have {REVIEW_APPROVAL_DURATION} hours to either agree or request
          a revision.
        </Typography>
        <Typography component="p" mb={3}>
          If they agree, your offer will be closed and their BTRST payment will
          be made available in your wallet.
        </Typography>
      </Box>
      <Box ml="auto">
        <Button variant="positive" shape="squared" onClick={approveRequest}>
          Mark complete
        </Button>
      </Box>
    </Box>
  );
};
