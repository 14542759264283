import type { FC } from "react";
import React, { useMemo } from "react";
import { omit } from "underscore";

import type { AvatarSrc } from "@hexocean/braintrust-ui-components";
import {
  Avatar,
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightIcon,
  VerifiedIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { insightsTooltipClicked } from "@js/apps/jobs/actions";
import { useGetJobApplicationInsightsQuery } from "@js/apps/jobs/api";
import { useAppDispatch } from "@js/hooks";
import { usePurchaseUpgrade } from "@js/hooks/purchase-upgrade";

import { ApplicationInsightsList } from "../../application-insights-list";
import { formatMatchLabel, getMatchLabelColors } from "../utils";

type InsightTooltipProps = {
  isApplied: boolean;
  jobId: number;
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null;
};

export const InsightTooltip: FC<InsightTooltipProps> = ({
  isApplied,
  jobId,
  matchLabel,
}) => {
  const { onClickPurchaseUpgrade } = usePurchaseUpgrade("insights");
  const user = useUser();
  const avatarSrc = useMemo(
    () => omit(user, "account_type") as AvatarSrc,
    [user],
  );
  const { isLoading, data } = useGetJobApplicationInsightsQuery({ id: jobId });
  const matchLabelStyleProps = getMatchLabelColors(matchLabel);
  const canViewInsights = useMemo(
    () => user?.can_view_insights,
    [user?.can_view_insights],
  );
  const isFreelancerApproved = useMemo(
    () => user?.freelancer_approved,
    [user?.freelancer_approved],
  );
  const showPreview = useMemo(
    () => !user?.can_view_insights && isFreelancerApproved,
    [user?.can_view_insights, isFreelancerApproved],
  );
  const dispatch = useAppDispatch();
  const handleButtonClick = () => {
    dispatch(insightsTooltipClicked({ jobId }));
    onClickPurchaseUpgrade();
  };

  return (
    <Box>
      <Box display="flex" mb={1.25} gap={1.25}>
        <Box display="flex" alignItems="center">
          {isFreelancerApproved && (
            <Box height="29px" width="29px" position="relative">
              <VerifiedIcon
                sx={{
                  top: 0,
                  left: 0,
                  position: "absolute",
                  alignSelf: "end",
                  fontSize: "36.33px",
                  zIndex: 2,
                }}
              />
            </Box>
          )}
          <Avatar
            src={avatarSrc}
            sx={{ height: 29, width: 29, marginLeft: -0.1, zIndex: 1 }}
          />
        </Box>
        <Box
          sx={{
            width: "fit-content",
            backgroundColor: matchLabelStyleProps.backgroundColor,
          }}
          borderRadius={9}
          py={0.75}
          px={1.5}
        >
          <Typography
            component="p"
            variant="label"
            size="small"
            sx={{
              color: matchLabelStyleProps.textColor,
            }}
          >
            {matchLabel ? (
              <>
                You're a{matchLabel === ENUMS.SmartMatchingLabel.OK ? "n" : ""}{" "}
                <span
                  style={{
                    textTransform:
                      matchLabel === ENUMS.SmartMatchingLabel.OK
                        ? "inherit"
                        : "lowercase",
                  }}
                >
                  {formatMatchLabel(matchLabel)}
                </span>{" "}
                for this job!
              </>
            ) : (
              <>Your insights are pending</>
            )}
          </Typography>
        </Box>
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <Box display="flex" flexDirection="column" gap={1.5}>
          <ApplicationInsightsList
            matchedSummaryList={data?.match_summary || []}
            matchedSkillsCount={data?.skills || ""}
            matchLabelItem={{
              matchLabel,
              totalApplicants: data?.total_applicants || 0,
              hasJobBid: isApplied,
            }}
            showMatchedSummaryList={canViewInsights}
            showPreview={showPreview}
          />
          {!canViewInsights && (
            <Button
              onClick={handleButtonClick}
              shape="squared"
              variant="positive-2"
              size="x-small"
              endIcon={
                <ArrowRightIcon
                  sx={{
                    "& > path": {
                      strokeWidth: "2.5px !important",
                    },
                  }}
                />
              }
              sx={{
                boxShadow: "0px 4px 11px 0px #0000001A",
                paddingX: "12px !important",
                maxWidth: { xs: "unset", sm: "240px" },
                marginTop: showPreview && matchLabel ? -5 : -1,

                marginLeft: {
                  xs: "unset",
                  sm: showPreview ? 1.5 : "unset",
                },
                "&.typography": {
                  fontWeight: "500 !important",
                },
              }}
            >
              Unlock full Application Insights
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
