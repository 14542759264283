import { useEffect } from "react";

import { resendVerificationEmail } from "@js/apps/auth/actions";
import { useUser, useWithRecaptcha } from "@js/apps/common/hooks";
import { getValuesWithReCaptchaCode } from "@js/forms/utils";
import { useNavigate } from "@js/hooks";
import { LocalStorage } from "@js/services";

export const useSignUpEmailConfirmPage = () => {
  const navigate = useNavigate();
  const user = useUser();
  const email =
    user?.email ??
    LocalStorage.getItem(
      LocalStorage.keys.REGISTRATION_EMAIL_LOCAL_STORAGE_KEY,
    );
  useWithRecaptcha();

  useEffect(() => {
    if (!email) {
      navigate("/auth/sign_up/");
    }
  }, [email, navigate]);

  const handleEmailResend = async () => {
    if (!email) return;

    const values = await getValuesWithReCaptchaCode(
      { email },
      ENUMS.CaptchaActions.SIGN_UP,
    );

    resendVerificationEmail(values);
  };

  return {
    handleEmailResend,
    email,
  };
};
