import { useCallback, useEffect } from "react";

import {
  FRONTEND_STORAGE_KEYS,
  useLazyGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { freddyWidgetManager } from "@js/apps/common/services/freddy-feedback";

import { useShowedFeedThirdTimeMutation } from "../../api";

type UseFeedVisitsArg = {
  visitedFeedThreeTimes: boolean | undefined;
};

export const useFeedVisits = ({ visitedFeedThreeTimes }: UseFeedVisitsArg) => {
  const [getStorageValue] = useLazyGetStorageValueQuery();
  const [setStorageValue] = useSetStorageValueMutation();
  const [showedFeedThirdTimeMutation] = useShowedFeedThirdTimeMutation();

  const handleThirdFeedVisit = useCallback(async () => {
    showedFeedThirdTimeMutation();
    freddyWidgetManager.showFreddyWidget(
      SETTINGS.FREDDY_FEEDBACK_FEED_WIDGET_ID,
    );
  }, [showedFeedThirdTimeMutation]);

  useEffect(() => {
    if (visitedFeedThreeTimes !== false) {
      return;
    }

    let isCancelled = false;

    getStorageValue({ key: FRONTEND_STORAGE_KEYS.feed_visits }, true)
      .unwrap()
      .then(async (storedFeedVisits) => {
        if (isCancelled) {
          return;
        }

        const currentFeedVisit = getCurrentFeedVisit(storedFeedVisits);
        if (typeof currentFeedVisit === "undefined") {
          return;
        }
        if (currentFeedVisit === 3) {
          handleThirdFeedVisit();
        }
        setStorageValue({
          key: FRONTEND_STORAGE_KEYS.feed_visits,
          value: currentFeedVisit,
        });
      })
      .catch((e) => void e);

    return () => {
      isCancelled = true;
    };
  }, [
    visitedFeedThreeTimes,
    getStorageValue,
    setStorageValue,
    handleThirdFeedVisit,
  ]);
};

const getCurrentFeedVisit = (storedFeedVisits: unknown): number | undefined => {
  const numericStoredFeedVisits = Number(storedFeedVisits);
  if (isNaN(numericStoredFeedVisits)) {
    return 1;
  }

  const currentFeedVisit = numericStoredFeedVisits + 1;
  if (currentFeedVisit > 3) {
    return;
  }

  return currentFeedVisit;
};
