import { useCallback } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import type { WorkingTimezoneJobs } from "@js/apps/common/components/filters/config";

export const useReloadJobsOnAvailabilityChange = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const reloadJobsOnAvailabilityChange = useCallback(() => {
    const isJobsListingPage = /^\/(role-)?jobs\/?$/.test(location.pathname);
    if (!isJobsListingPage) {
      return;
    }

    const matchMyWorkingHoursSearchKey =
      "match_my_working_hours" satisfies keyof WorkingTimezoneJobs;
    if (searchParams.get(matchMyWorkingHoursSearchKey) !== "true") {
      return;
    }

    // remount the entire page to refetch the jobs with current filters
    setSearchParams(searchParams, {
      state: { disableRemountIfTheSameLocation: false },
    });
  }, [location, searchParams, setSearchParams]);

  return { reloadJobsOnAvailabilityChange };
};
