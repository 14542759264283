import type { MAX_TOP_SKILLS_COUNT } from "../const";
import type { SkillListItem } from "../selected-skills-list/item";
import type { SimpleSkill } from "../types";

export const filterOutSelectedSkills = (args: {
  skills: SimpleSkill[];
  selectedSkills: number[];
}) => {
  const { skills, selectedSkills } = args;

  return skills?.filter((skill) => !selectedSkills?.includes(skill.id));
};

export const isMaxSelectedTopSkillsCountReached = (
  maxCount: EnumType<typeof MAX_TOP_SKILLS_COUNT>,
  skills: SkillListItem[],
) => {
  const selectedTopSkillsCount = skills.filter(
    (skill) => skill.is_superpower,
  ).length;

  return selectedTopSkillsCount >= maxCount;
};

export const sortBySuperpower = (a: SkillListItem, b: SkillListItem) => {
  return Number(b.is_superpower) - Number(a.is_superpower);
};
