export const UNIVERSAL_SEARCH_PATHS = {
  TALENT: "/search-talent",
  JOB: "/search-jobs",
  FEED: "/search-feed",
} as const;

export const UNIVERSAL_SEARCH_TABS = {
  JOBS: "JOBS",
  CAREER_HELP: "CAREER_HELP",
  PEOPLE: "PEOPLE",
} as const;

export type UniversalTabValue = keyof typeof UNIVERSAL_SEARCH_TABS;

export const searchTabToName: Record<UniversalTabValue, string> = {
  [UNIVERSAL_SEARCH_TABS.JOBS]: "jobs",
  [UNIVERSAL_SEARCH_TABS.CAREER_HELP]: "posts",
  [UNIVERSAL_SEARCH_TABS.PEOPLE]: "people",
} as const;

export const searchTabToUrl: Record<UniversalTabValue, string> = {
  [UNIVERSAL_SEARCH_TABS.JOBS]: "/search-jobs",
  [UNIVERSAL_SEARCH_TABS.CAREER_HELP]: "/search-feed",
  [UNIVERSAL_SEARCH_TABS.PEOPLE]: "/search-talent",
} as const;

export const popularSearchTabToUrl: Record<UniversalTabValue, string> = {
  [UNIVERSAL_SEARCH_TABS.JOBS]: "/jobs",
  [UNIVERSAL_SEARCH_TABS.CAREER_HELP]: "/feed",
  [UNIVERSAL_SEARCH_TABS.PEOPLE]: "/talent",
} as const;
