import type { HyphenatedCSSStyleProperties } from ".";

type ValidateStylesFunction = (
  styles: CSSStyleDeclaration,
  allowedProperties?: Array<keyof HyphenatedCSSStyleProperties>,
) => string[];

export const validateStyles: ValidateStylesFunction = (
  styles,
  allowedProperties = [],
) => {
  const output: string[] = [];

  allowedProperties.forEach((prop) => {
    const value = styles[prop as keyof CSSStyleDeclaration];
    if (value) {
      output.push(prop + ":" + value + ";");
    }
  });

  return output;
};
