import React, { useMemo } from "react";

import { useWithRecaptcha } from "@js/apps/common/hooks";
import { SpaceMembers } from "@js/apps/spaces/components";
import { AdminList } from "@js/apps/spaces/components/admin-list";
import { PublicSpacePageSignupBanner } from "@js/apps/spaces/components/public-space-page-signup-banner";
import { SpaceHeader } from "@js/apps/spaces/components/space-header";
import { TrendingHashtagsList } from "@js/apps/spaces/components/trending-hashtags";
import { PublicSpacePageTopNavigation } from "@js/apps/spaces/views/public-space-page/public-space-page-top-navigation";
import { usePublicSpacePage } from "@js/apps/spaces/views/public-space-page/use-public-space-page";
import { AppLayout } from "@js/layouts/app";

import { AboutThisSpacePublic } from "../../components/about-this-space-public";

import styles from "./styles.module.scss";

export const PublicSpacePage = ({ id }: { id: number }) => {
  useWithRecaptcha();
  const { spaceData, membersData, joinCTA, referringUser } = usePublicSpacePage(
    { id },
  );

  const admins = useMemo(() => {
    if (!membersData) {
      return [];
    }

    return membersData.admins.map(({ user }) => user);
  }, [membersData]);

  return (
    <AppLayout
      bgcolor="var(--soft-green)"
      pageTitle={spaceData?.name ?? "Spaces"}
      renderOverwriteNavigationComponent={(props) => (
        <PublicSpacePageTopNavigation {...props} handleSignUp={joinCTA} />
      )}
    >
      {!!spaceData && membersData && (
        <div className={styles.publicSpacePage}>
          <SpaceHeader
            title={spaceData.name}
            avatarSource={spaceData.avatar_thumbnail ?? ""}
            membersCount={spaceData.total_members}
            id={spaceData.id}
            className={styles.header}
          />
          <div className={styles.publicSpacePageContent}>
            <div className={styles.publicSpacePageContentAdmins}>
              <AdminList admins={admins} />
            </div>
            <div className={styles.publicSpacePageContentAbout}>
              <AboutThisSpacePublic space={spaceData} />
            </div>
            <div className={styles.publicSpacePageContentMembers}>
              <SpaceMembers
                space={spaceData}
                members={membersData.members}
                membersCount={membersData.total_members}
                showCta
                ctaAction={joinCTA}
                className={styles.publicSpacePageContentMembersContent}
              />
            </div>
            <div className={styles.publicSpacePageContentTrending}>
              <TrendingHashtagsList spaceId={id} />
            </div>
          </div>
        </div>
      )}

      {referringUser && <PublicSpacePageSignupBanner inviter={referringUser} />}
    </AppLayout>
  );
};
