class FreddyWidgetManager {
  private static instance: FreddyWidgetManager;

  private _isFreddyWidgetAttaching: boolean;

  private constructor() {
    this._isFreddyWidgetAttaching = false;
  }

  public static getInstance(): FreddyWidgetManager {
    if (!FreddyWidgetManager.instance) {
      FreddyWidgetManager.instance = new FreddyWidgetManager();
    }
    return FreddyWidgetManager.instance;
  }

  public removeFreddyWidget(): void {
    const existingScriptHead = document.getElementById("freddy-feedback-head");
    const existingScript = document.getElementById("freddy-feedback");
    const existingIframe = document.getElementById("ffiframe");

    if (existingScript) existingScript.remove();
    if (existingScriptHead) existingScriptHead.remove();
    if (existingIframe) existingIframe.remove();

    window.freddyWidget?.wg?.remove();

    delete window.ffWidgetId;
    delete window.freddyWidget;
    delete window.ffWidgetScript;
  }

  public attachFreddyWidget(widgetId: string): Promise<void> {
    const noWidgetIdOnLocal =
      !widgetId && process.env.NODE_ENV === "development";

    this.isFreddyWidgetAttaching = true;
    return new Promise((resolve) => {
      if (noWidgetIdOnLocal) return;

      window.ffWidgetId = widgetId;
      window.ffWidgetScript = document.createElement("script");
      window.ffWidgetScript.type = "text/javascript";
      window.ffWidgetScript.id = "freddy-feedback-head";
      window.ffWidgetScript.src =
        "https://freddyfeedback.com/widget/freddyfeedback.js";

      window.ffWidgetScript.addEventListener("load", () => {
        window?.freddyWidget?.iFrameReadyPromise.then(() => {
          this.isFreddyWidgetAttaching = false;
          resolve();
        });
      });
      document.head.appendChild(window.ffWidgetScript);
    });
  }

  public showFreddyWidget(widgetId: string): void {
    if (this.isFreddyWidgetAttaching) return;
    if (window.ffWidgetId === widgetId) {
      return window?.freddyWidget?.show();
    }

    this.removeFreddyWidget();
    this.attachFreddyWidget(widgetId).then(() => {
      if (window?.freddyWidget?.iFrameReady) {
        return window?.freddyWidget?.show();
      }
    });
  }

  public embedCrossPageFreddyWidget(): void {
    if (this.isFreddyWidgetAttaching) return;
    const widgetId = SETTINGS.FREDDY_FEEDBACK_CROSS_PAGE_WIDGET_ID;
    if (window.ffWidgetId && window.ffWidgetId !== widgetId) {
      this.removeFreddyWidget();
    }

    if (window.ffWidgetId !== widgetId) {
      this.attachFreddyWidget(widgetId).then(() => {
        if (window?.freddyWidget?.iFrameReady) {
          const freddyWidget = document.getElementById("ffiframe");
          if (freddyWidget) {
            freddyWidget.style.top = "unset";
            freddyWidget.style.bottom = "0";
          }
        }
      });
    }
  }

  private get isFreddyWidgetAttaching() {
    return this._isFreddyWidgetAttaching;
  }

  private set isFreddyWidgetAttaching(value: boolean) {
    this._isFreddyWidgetAttaching = value;
  }
}

export const freddyWidgetManager = FreddyWidgetManager.getInstance();
