import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import classNames from "classnames";
import _ from "underscore";

import { Typography } from "@hexocean/braintrust-ui-components";
import type { TextFieldProps } from "@js/forms/fields/text";
import { TextField } from "@js/forms/fields/text";

/**
 * Example usage:
 *
 * ```
 * <Field
 *     name="message"
 *     minLength={4}
 *     maxLength={10}
 *     label="Your Message"
 *     component={TextareaField} />
 * ```
 */

type TextareaFieldProps = {
  minLength?: number;
  maxLength?: number;
  maxLabel?: boolean;
  markdown?: boolean;
  minRowsNumber?: boolean;
  maxLengthInHelpText?: boolean;
  maxLengthCounterThreshold?: number;
} & TextFieldProps &
  TypedWrappedFieldProps<string>;

export const TextareaField = (props: TextareaFieldProps): JSX.Element => {
  const {
    minLength,
    maxLabel,
    maxLength,
    minRowsNumber,
    input: { value },
    fullWidth,
    className,
    markdown,
    helperText,
    maxLengthInHelpText,
    maxLengthCounterThreshold,
  } = props;

  const textLength = value.length;

  const showMaxLengthCounter = maxLengthCounterThreshold
    ? textLength >= maxLengthCounterThreshold
    : maxLength;

  return (
    <div
      className={classNames("textarea", className)}
      style={fullWidth ? { width: "100%" } : undefined}
    >
      {!maxLengthInHelpText && maxLength && showMaxLengthCounter && (
        <Typography
          component="p"
          className={classNames("textarea__max-length", {
            "textarea__max-length--error": textLength > maxLength,
          })}
        >
          {textLength} / {maxLength}
        </Typography>
      )}
      <TextField
        multiline
        rows={!minRowsNumber ? props.rows || 4 : undefined}
        helperText={
          maxLengthInHelpText ? (
            <div className="textarea__helper-text-wrapper">
              {helperText}

              {maxLength && (
                <Typography
                  component="p"
                  className={classNames(
                    "textarea__max-length",
                    "textarea__max-length--static",
                    {
                      "textarea__max-length--error": textLength > maxLength,
                    },
                  )}
                >
                  {textLength} / {maxLength}
                </Typography>
              )}
            </div>
          ) : (
            helperText
          )
        }
        {..._.omit(
          props,
          "rows",
          "minLength",
          "maxLength",
          "maxLabel",
          "className",
          "markdown",
          "minRowsNumber",
          "helperText",
          "maxLengthInHelpText",
          "maxLengthCounterThreshold",
        )}
      />
      <Typography component="p" size="small" pt={1}>
        {minLength && <span>Min {minLength} characters. </span>}
        {maxLabel && <span>Max {maxLength} characters. </span>}
        {markdown && (
          <span>
            You can use
            <Typography
              component="a"
              fontWeight={500}
              className="textarea__link"
              href="https://help.github.com/en/github/writing-on-github/basic-writing-and-formatting-syntax"
              target="_blank"
              rel="noopener noreferrer"
            >
              basic markdown syntax
            </Typography>
            .
          </span>
        )}
      </Typography>
    </div>
  );
};
