import { Typography } from "@hexocean/braintrust-ui-components";

import styles from "./style.module.scss";

type MessageWrapperProps = {
  children: React.ReactNode;
};

export const MessageWrapper = ({ children }: MessageWrapperProps) => (
  <div className={styles.locationWarningBadge}>🗣️ {children}</div>
);

type CTAMessageProps = MessageWrapperProps & {
  onClick: () => void;
};

export const CTAMessage = ({ children, onClick }: CTAMessageProps) => {
  return (
    <Typography
      className={styles.locationWarningBadgeMessage}
      component="span"
      fontWeight={400}
      size="medium"
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      {children}
    </Typography>
  );
};
