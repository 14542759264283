import type { AnyAction } from "redux";

import type { WebSocketClientStatus } from "./constants";
import { SocketStatus } from "./constants";

type WebSocketClientState = {
  status: WebSocketClientStatus;
  isRunning: boolean;
};

const INITIAL_STATE: WebSocketClientState = {
  status: SocketStatus.IDLE,
  isRunning: false,
};

const reducer = (
  state = INITIAL_STATE,
  action: AnyAction,
): WebSocketClientState => {
  switch (action.type) {
    case SocketStatus.IDLE:
    case SocketStatus.CONNECTING:
    case SocketStatus.CONNECTING_FAILED:
    case SocketStatus.CONNECTED:
    case SocketStatus.DISCONNECTED:
    case SocketStatus.RECONNECTING:
    case SocketStatus.NOT_RUNNING:
    case SocketStatus.TRANSITIONING:
    case SocketStatus.WAITING_FOR_PING_PONG:
      return { ...state, isRunning: false, status: action.type };
    case SocketStatus.RUNNING:
      return { ...state, isRunning: true, status: action.type };
    default:
      return state;
  }
};

export default reducer;
