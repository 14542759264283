import type { PlaceDetailsResponse } from "@js/hooks";

export const LocationValueType = {
  custom: ENUMS.JobLocationType.CUSTOM,
  google: ENUMS.JobLocationType.GOOGLE,
} as const;

export type LocationValue =
  | {
      location_type: typeof ENUMS.JobLocationType.CUSTOM;
      custom_location: string;
      location: string;
      selected?: boolean;
    }
  | {
      location_type: typeof ENUMS.JobLocationType.GOOGLE;
      place_id: string;
      location: string;
      selected?: boolean;
      /** @see `includeFullPlaceDetails` in `GoogleComboboxProps` type */
      place_details?: PlaceDetailsResponse;
    };
