import React from "react";
import { Field, Fields, type WrappedFieldProps } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { HoursRangeSelector } from "@js/apps/common/forms/fields/hours-range-field";
import { TimezoneField } from "@js/apps/common/forms/fields/timezone-field";
import { InfoTooltip } from "@js/components/info-tooltip";

type WorkingTimezoneFieldProps = {
  availability_for_work: WrappedFieldProps;
};

export const WorkingTimezoneField = ({
  availability_for_work,
}: WorkingTimezoneFieldProps) => {
  return (
    <>
      <Typography component="p" size="medium" color="grey-1" mb={2}>
        Hours I’m available to work:{" "}
        <InfoTooltip
          size="16px"
          title="Setting your working hours improves your job matches. If you’re flexible, select a longer window of time."
        />
      </Typography>
      <Fields
        names={["working_hours_start", "working_hours_end"]}
        component={HoursRangeSelector}
        disabled={availability_for_work.input.value === false}
      />
      <Field
        name="working_hours_tz_abbr"
        component={TimezoneField}
        disabled={availability_for_work.input.value === false}
        format={null}
      />
    </>
  );
};
