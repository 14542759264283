import React, { useMemo, useState } from "react";

import {
  Box,
  ComboBox,
  TextField,
  Typography,
} from "@hexocean/braintrust-ui-components";
import type { UTCOffset } from "@js/apps/filters/types";
import type { TypedWrappedFieldProps } from "@js/forms/utils";

import type { WorkingTimezoneTalents } from "../../config";

export type TimezoneSelectProps = {
  timezone: TypedWrappedFieldProps<WorkingTimezoneTalents["timezone"] | null>;
};

type TimezoneOption = {
  value: UTCOffset;
  label: string;
};

// enclose within a function to mock more easily
const getTimezoneOptions = () =>
  SETTINGS.TIMEZONE_ABBREVIATIONS.map((timezone) => ({
    value: timezone.utc_offset as UTCOffset,
    label: timezone.abbreviation,
  }));

export const TimezoneSelectField = ({ timezone }: TimezoneSelectProps) => {
  const [timezoneOptions] = useState(getTimezoneOptions);
  const selectedTimezone = useMemo(
    () =>
      timezoneOptions.find(({ value }) => value === timezone.input.value) ??
      null,
    [timezone.input.value, timezoneOptions],
  );

  return (
    <Box>
      <Typography
        id="talent-timezone-label"
        component="label"
        variant="label"
        size="large"
        sx={{ mb: 1, display: "inline-block" }}
      >
        Select Talent timezone
      </Typography>
      <ComboBox<TimezoneOption, false>
        initialTaxonomiesLoading={false}
        value={selectedTimezone}
        options={timezoneOptions}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        onChange={(_event, selectedValue: TimezoneOption | null) => {
          timezone.input.onChange(selectedValue?.value ?? null);
        }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              aria-labelledby="talent-timezone-label"
              placeholder="Select a timezone"
            />
          );
        }}
      />
    </Box>
  );
};
