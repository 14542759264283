import type { SnackbarKey } from "notistack";

import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { ClearIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

type EmailNotificationTurnedOffContentProps = {
  closeSnackbar: (key?: SnackbarKey | undefined) => void;
};

export const EmailNotificationTurnedOffContent = ({
  closeSnackbar,
}: EmailNotificationTurnedOffContentProps) => {
  const isMobile = useMediaQuery("sm");
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: 2,
        gap: 12,
        backgroundColor: "var(--soft-red)",
      }}
    >
      <Box display="flex" flexDirection="column">
        <Typography
          component="span"
          fontWeight={500}
          size={isMobile ? "small" : "medium"}
        >
          Email notifications turned off.
        </Typography>
        <Typography component="span" size="small">
          To receive email alerts for these saved searches modify in{" "}
          <Typography
            component="link"
            variant="link"
            to={"/settings/notifications/"}
            size={"small"}
            RouterLink={RouterLink}
          >
            settings
          </Typography>
          .
        </Typography>
      </Box>
      <IconButton
        size="small"
        variant="transparent"
        disableRipple
        style={{ padding: 0, margin: 0 }}
        onClick={() => closeSnackbar()}
        aria-label="Close"
      >
        <ClearIcon
          style={{
            fontSize: 20,
          }}
        />
      </IconButton>
    </Box>
  );
};
