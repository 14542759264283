import { Box, Typography } from "@hexocean/braintrust-ui-components";

export type DetailItemProps = {
  icon: JSX.Element | null;
  figure: number | null;
  label: string;
  showMobileVariant?: boolean;
};

export const DetailItem = ({
  icon,
  label,
  figure,
  showMobileVariant,
}: DetailItemProps) => {
  return (
    <Box
      display="flex"
      gap={0.5}
      alignItems="center"
      whiteSpace="nowrap"
      overflow="hidden"
    >
      {icon}
      <Typography
        component="p"
        variant="paragraph"
        size={showMobileVariant ? "small" : "medium"}
        ellipsis
      >
        {figure} {label}
      </Typography>
    </Box>
  );
};
