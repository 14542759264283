import cs from "classnames";

import { Loader, RoundedBox } from "@hexocean/braintrust-ui-components";
import { JobEmployerDetails } from "@js/apps/employer/components";
import { Rate } from "@js/apps/jobs/components/rate";
import type { EmployerPublicProfile } from "@js/types/employer";
import type { FreelancerBid, Job } from "@js/types/jobs";

import {
  JobHeader,
  JobStatus,
  MatchersListing,
  WorkTimeDetail,
} from "../../components";

import style from "./style.module.scss";

type BYOTJobDetailsProps = {
  job: Job;
  bid?: FreelancerBid;
  employer: EmployerPublicProfile | undefined;
};

export const BYOTJobDetails = ({ job, bid, employer }: BYOTJobDetailsProps) => {
  return (
    <main>
      <JobHeader job={job} bid={bid} withDetailsHeader />
      <section className={cs(style.jobDetails, style.jobDetailsByot)}>
        <div className={style.jobBasicDetails}>
          <RoundedBox className={style.jobBasicDetailsWrapper}>
            <WorkTimeDetail
              expectedHoursPerWeek={job.expected_hours_per_week}
              variant="details"
              shortenedLabel
              shortened
            />
          </RoundedBox>
        </div>
        <div className={style.jobStatusBox}>
          <JobStatus job={job} bid={bid} className={style.jobStatus} />
        </div>
        <div className={style.jobRate}>
          <Rate job={job} bid={bid} />
        </div>
        <div
          className={cs(
            style.jobEmployerColumn,
            style.jobDetailsByotEmployerColumn,
          )}
        >
          <div className={style.jobEmployer}>
            {!employer ? (
              <Loader />
            ) : (
              <RoundedBox>
                <JobEmployerDetails employer={employer} jobId={job.id} />
              </RoundedBox>
            )}
          </div>
          <div>
            <MatchersListing job={job} />
          </div>
        </div>
      </section>
    </main>
  );
};
