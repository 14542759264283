import { useMemo } from "react";
import { SubmissionError } from "redux-form";

import type { PostComment } from "@js/types/give-and-get-help";

import {
  useAddPostCommentMutation,
  useDeletePostCommentMutation,
  useUpdatePostCommentMutation,
} from "../../api";
import { getInitialExcludedLinks } from "../../forms/fields/links-from-text-adapter/helpers";
import type { ReplyFormData } from "../../forms/reply-form";
import {
  prepareCommentData,
  prepareUpdatedCommentWithOffer,
} from "../../forms/reply-form/utils";
import { filterLinks, getInitialBudget } from "../../utils";

type UseCreateOrEditReplyConfig = {
  id: number;
  commentToReply?: PostComment | null;
  commentToEdit?: PostComment | null;
  onCloseReply?: (postId: number) => void;
  onAddReply?: (commendId: number) => void;
};

export const useCreateOrEditReply = ({
  id,
  commentToEdit,
  commentToReply,
  onCloseReply,
  onAddReply,
}: UseCreateOrEditReplyConfig) => {
  const [addComment, { isLoading }] = useAddPostCommentMutation();
  const [updatePostComment] = useUpdatePostCommentMutation();
  const [deletePostComment] = useDeletePostCommentMutation();

  const isReplyToSomeoneReply =
    commentToReply?.parent_id === id && !commentToReply?.comments?.length;
  const notEmptyUsername = [
    commentToReply?.freelancer.user.first_name,
    commentToReply?.freelancer.user.last_name,
  ]
    .filter(Boolean)
    .join(" ");

  const initialTextValue = `@${notEmptyUsername} `;

  const linksAddedViaButton = useMemo(() => {
    return commentToEdit
      ? filterLinks(commentToEdit, { isAddedByButton: true })
      : [];
  }, [commentToEdit]);
  const linksAddedFromText = useMemo(() => {
    return commentToEdit
      ? filterLinks(commentToEdit, { isAddedByButton: false })
      : [];
  }, [commentToEdit]);

  const initialValues = useMemo(() => {
    const baseInitialValues = {
      openedFields: commentToEdit?.help_offer
        ? (["offer"] as ReplyFormData["openedFields"])
        : [],
    };
    if (commentToReply && isReplyToSomeoneReply) {
      return { ...baseInitialValues, text: initialTextValue };
    }

    if (!commentToEdit) {
      return baseInitialValues;
    }

    const linksFromTextInitialValue = linksAddedFromText.map(
      (link) => link.url,
    );
    const excludedLinksFromTextInitialValue = getInitialExcludedLinks({
      textValue: commentToEdit.text,
      linksFromTextValue: linksFromTextInitialValue,
    });
    return {
      ...baseInitialValues,
      ...commentToEdit,
      budget: getInitialBudget(commentToEdit.help_offer?.budget),
      links_metadata: linksAddedViaButton.map((link) => ({
        ...link,
        id: link.id || link.url,
        isFromText: false,
      })),
      links_metadata_from_text: linksAddedFromText.map((link) => ({
        ...link,
        id: link.id || link.url,
        isFromText: true,
      })),
      links_from_text: linksFromTextInitialValue,
      excluded_links_from_text: excludedLinksFromTextInitialValue,
      links: linksAddedViaButton.map((link) => {
        return {
          value: link.url,
          id: link.id || link.url,
          isFromText: false,
        };
      }),
    };
  }, [
    commentToEdit,
    commentToReply,
    initialTextValue,
    isReplyToSomeoneReply,
    linksAddedFromText,
    linksAddedViaButton,
  ]);

  const getTargetCommentId = (text: Partial<ReplyFormData>["text"]) => {
    if (!commentToReply?.id) return id;
    // if user removes or changes mention manually, we want to send id of main post (treat L3 as L2 comment)
    if (!text || !text.startsWith(initialTextValue)) return id;
    return commentToReply.id;
  };

  const onSubmit = async (data: Partial<ReplyFormData>) => {
    const preparedData = prepareCommentData(data);
    return await (async () => {
      try {
        if (commentToEdit && !data.text?.trim().length) {
          return await deletePostComment({
            id: commentToEdit.id,
            parent_id: commentToEdit.parent_id,
          }).unwrap();
        }

        if (commentToEdit) {
          return await updatePostComment({
            postId: commentToEdit.id,
            data: prepareUpdatedCommentWithOffer(preparedData as PostComment),
          }).unwrap();
        }
        const addedCommentData = await addComment({
          data: preparedData as PostComment,
          postId: getTargetCommentId(data.text),
        }).unwrap();

        onAddReply?.(addedCommentData.id);

        return addedCommentData;
      } catch (error) {
        // @ts-expect-error - SubmissionError does not accept array of errors - { text: string[]; }, but it is properly handled by redux-form
        throw new SubmissionError(error.data);
      }
    })();
  };

  const onSubmitSuccess = (
    _values: unknown,
    _dispatch: unknown,
    props: { reset: () => void },
  ) => {
    props.reset();

    if (onCloseReply) onCloseReply(id);
  };
  return {
    onSubmit,
    onSubmitSuccess,
    isLoading,
    initialValues,
  };
};
