import { useCallback, useEffect, useRef } from "react";
import {
  getFormSyncErrors,
  touch,
  type TypedWrappedFieldProps,
  untouch,
  updateSyncErrors,
} from "redux-form";

import { Snackbar } from "@js/components/snackbar";
import { useAppSelector } from "@js/hooks";
import { useEffectRef } from "@js/hooks/use-effect-ref";

export type UsePendingUploadSnackbarArg = {
  meta: TypedWrappedFieldProps["meta"];
  input: { name: string };
};

const PENDING_UPLOAD_ERROR = "Pending upload";

export const usePendingUploadSnackbar = ({
  input,
  meta,
}: UsePendingUploadSnackbarArg) => {
  const formSyncErrors = useAppSelector(getFormSyncErrors(meta.form));
  const { touched, error, submitFailed, dispatch } = meta;
  const shouldBlockSubmitRef = useRef(false);

  const setFieldError = (fieldError?: string) => {
    dispatch(
      updateSyncErrors(
        meta.form,
        {
          ...formSyncErrors,
          [input.name]: fieldError,
        },
        undefined,
      ),
    );
  };

  const setFieldErrorRef = useEffectRef(setFieldError);

  const setTouched = (shouldTouch = true) => {
    const action = shouldTouch ? touch : untouch;

    dispatch(action(meta.form, input.name));
  };
  const setTouchedRef = useEffectRef(setTouched);

  const onBeforeUpload = useCallback(() => {
    setTouchedRef.current(false);
    setFieldErrorRef.current(PENDING_UPLOAD_ERROR);
    shouldBlockSubmitRef.current = true;
  }, [setTouchedRef, setFieldErrorRef]);

  const onAfterFileDelete = () => {
    if (shouldBlockSubmitRef.current) {
      return;
    }

    setTouched();
  };

  const onAfterUpload = () => {
    shouldBlockSubmitRef.current = false;
    setFieldError(undefined);
    setTouched(true);
  };

  useEffect(() => {
    if (!shouldBlockSubmitRef.current) {
      return;
    }

    if (!error) {
      // set error back as it has been unset by other fields onChange
      return setFieldErrorRef.current(PENDING_UPLOAD_ERROR);
    }

    if (!touched) {
      return;
    }

    setTouchedRef.current(false);
    if (!submitFailed) {
      return;
    }

    Snackbar.info("File upload not completed - please wait.");
  }, [
    touched,
    error,
    setTouchedRef,
    setFieldErrorRef,
    shouldBlockSubmitRef,
    submitFailed,
  ]);

  return { onBeforeUpload, onAfterUpload, onAfterFileDelete, setTouched };
};
