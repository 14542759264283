export const FETCH_TRANSFERWISE_CURRENCIES = "FETCH_TRANSFERWISE_CURRENCIES";
export const FETCH_TRANSFERWISE_CURRENCIES_SUCCESS =
  "FETCH_TRANSFERWISE_CURRENCIES_SUCCESS";
export const FETCH_TRANSFERWISE_CURRENCIES_FAILED =
  "FETCH_TRANSFERWISE_CURRENCIES_FAILED";

export const FETCH_WITHDRAWAL_METHODS = "FETCH_WITHDRAWAL_METHODS";
export const FETCH_WITHDRAWAL_METHODS_SUCCESS =
  "FETCH_WITHDRAWAL_METHODS_SUCCESS";
export const FETCH_WITHDRAWAL_METHODS_FAILED =
  "FETCH_WITHDRAWAL_METHODS_FAILED";

export const FETCH_WITHDRAWAL_METHODS_CURRENCY_REQUIREMENTS =
  "FETCH_WITHDRAWAL_METHODS_CURRENCY_REQUIREMENTS";
export const FETCH_WITHDRAWAL_METHODS_CURRENCY_REQUIREMENTS_SUCCESS =
  "FETCH_WITHDRAWAL_METHODS_CURRENCY_REQUIREMENTS_SUCCESS";
export const FETCH_WITHDRAWAL_METHODS_CURRENCY_REQUIREMENTS_FAILED =
  "FETCH_WITHDRAWAL_METHODS_CURRENCY_REQUIREMENTS_FAILED";

export const FETCH_WITHDRAWAL_METHODS_OTHER_REQUIREMENTS =
  "FETCH_WITHDRAWAL_METHODS_OTHER_REQUIREMENTS";
export const FETCH_WITHDRAWAL_METHODS_OTHER_REQUIREMENTS_SUCCESS =
  "FETCH_WITHDRAWAL_METHODS_OTHER_REQUIREMENTS_SUCCESS";
export const FETCH_WITHDRAWAL_METHODS_OTHER_REQUIREMENTS_CLEAR =
  "FETCH_WITHDRAWAL_METHODS_OTHER_REQUIREMENTS_CLEAR";

export const CREATE_WITHDRAWAL_METHOD = "CREATE_WITHDRAWAL_METHOD";
export const CREATE_WITHDRAWAL_METHOD_SUCCESS =
  "CREATE_WITHDRAWAL_METHOD_SUCCESS";
export const CREATE_WITHDRAWAL_METHOD_FAILED =
  "CREATE_WITHDRAWAL_METHOD_FAILED";
export const FETCH_COMPANY_NODES = "FETCH_COMPANY_NODES";
export const FETCH_COMPANY_NODES_SUCCESS = "FETCH_COMPANY_NODES_SUCCESS";
export const FETCH_COMPANY_NODES_FAILED = "FETCH_COMPANY_NODES_FAILED";

export const ADD_STRIPE_RECIPIENTS = "ADD_STRIPE_RECIPIENTS";
export const ADD_STRIPE_RECIPIENTS_SUCCESS = "ADD_STRIPE_RECIPIENTS_SUCCESS";
export const ADD_STRIPE_RECIPIENTS_FAILED = "ADD_STRIPE_RECIPIENTS_FAILED";

export const ADD_STRIPE_RECIPIENTS_ONBOARDING_LINK =
  "ADD_STRIPE_RECIPIENTS_ONBOARDING_LINK";
export const REMOVE_STRIPE_RECIPIENTS_ONBOARDING_LINK =
  "REMOVE_STRIPE_RECIPIENTS_ONBOARDING_LINK";
