import { useCallback } from "react";
import _ from "underscore";

import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useIsEmployerImpersonated } from "@js/apps/jobs/hooks";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useNavigate } from "@js/hooks";

import { getRequiredFieldsWhenPostingForAClient } from "../../components/can-manage-job-on-behalf-of-client-module/post-job-for-client/helpers";
import { PostJobForClientModalInstance } from "../../components/can-manage-job-on-behalf-of-client-module/post-job-for-client/modal";
import { closeSummarizeJobModal } from "../../components/summarize-module";
import { openCreateJobThrottlingModal } from "../../views/create-job/create-job-throttling-modal";

export const useHandleFailedSubmission = (draftId: number | undefined) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEmployerImpersonated = useIsEmployerImpersonated();

  const onSubmitFail = useCallback(
    (errors: Record<PropertyKey, unknown>) => {
      const errorsKeys = Object.keys(errors);
      const hasExceededDailyJobPostingLimit =
        errorsKeys.includes("_error") &&
        _.isArray(errors["_error"]) &&
        errors["_error"].includes("daily_job_posting_limit_exceeded");
      const hasJobMarkedAsSpam =
        errorsKeys.includes("_error") &&
        _.isArray(errors["_error"]) &&
        errors["_error"].includes("job_marked_as_spam");

      if (hasJobMarkedAsSpam) {
        Snackbar.error(
          "We do periodic checks on new accounts to verify the authenticity of the company posting.",
        );
        navigate("/");
        dispatch(fetchCurrentUser());
      }

      if (hasExceededDailyJobPostingLimit) {
        openCreateJobThrottlingModal();
      }

      // post job for a client error can happen only in first step
      const errorInPostJobForClient = getRequiredFieldsWhenPostingForAClient(
        isEmployerImpersonated,
      ).some((key) => errorsKeys.includes(key));
      if (errorInPostJobForClient) {
        PostJobForClientModalInstance.open();
        return;
      }

      if (
        errorsKeys.includes("openings_number") ||
        errorsKeys.includes("other_role_name")
      ) {
        navigate(`/jobs/add_new/${draftId}/set_up/`);
      }
      closeSummarizeJobModal();
    },
    [isEmployerImpersonated, navigate, draftId, dispatch],
  );

  return onSubmitFail;
};
