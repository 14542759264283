import { getFormValues, isPristine } from "redux-form";

import {
  Box,
  Button,
  Tooltip,
  Typography,
  VerticalSeparator,
} from "@hexocean/braintrust-ui-components";
import { ArrowRightTopIcon } from "@hexocean/braintrust-ui-components/Icons";
import { EmployerLogoWithNameAndColor } from "@js/apps/employer/components/profile/employer-logo-with-name-and-color";
import { HeaderPortal } from "@js/apps/jobs/components/header-portal";
import { RouterLink } from "@js/components/link";
import { SubscribeSyncErrors } from "@js/forms/components/subscribe";
import { useAppSelector } from "@js/hooks";
import { useGoBackHistory } from "@js/hooks/go-back-history";
import type { Job } from "@js/types/jobs";
import type { FreelancerBidParams } from "@js/types/jobs";

import {
  checkForMissingRequirementAnswers,
  checkForMissingResume,
} from "./helpers";

import styles from "./style.module.scss";

type JobBidHeaderPortalProps = {
  isEditPage: boolean;
  job: Job;
  canEdit?: boolean;
};

export const JobBidHeaderPortal = ({
  job,
  isEditPage,
  canEdit,
}: JobBidHeaderPortalProps) => {
  const formNameToGetData = isEditPage
    ? "job-edit-bid-form"
    : "job-new-bid-form";
  const goBack = useGoBackHistory();
  const bidFormValues: FreelancerBidParams = useAppSelector((state) =>
    getFormValues(formNameToGetData)(state),
  ) as FreelancerBidParams;
  const isFormPristine = useAppSelector((state) =>
    isPristine(formNameToGetData)(state),
  );

  if (!job) {
    return null;
  }

  const requiredFieldsFilled = (fields: FreelancerBidParams) => {
    if (!fields) {
      return false;
    }

    const questionsWithoutAnswers =
      (!!fields["new_application_answers"] &&
        fields["new_application_answers"]?.length) > 0
        ? fields["new_application_answers"].some(
            (key) => key["answer"] === undefined,
          )
        : false;

    const paymentAmountFilled =
      fields["payment_amount"] !== undefined ||
      fields["payment_amount"] === null;

    const areRequirementAnswersMissing = isEditPage
      ? false
      : checkForMissingRequirementAnswers(fields);

    const isResumeMissing = checkForMissingResume(
      fields,
      job.is_resume_required,
    );

    return (
      paymentAmountFilled &&
      fields?.["freelancer_availability"] !== undefined &&
      questionsWithoutAnswers === false &&
      !isResumeMissing &&
      !areRequirementAnswersMissing
    );
  };

  const areRequiredFieldsFilled = requiredFieldsFilled(bidFormValues);
  const _isFormPristine = isEditPage && isFormPristine;
  const disableSubmit = isEditPage && !canEdit;

  return (
    <HeaderPortal
      leftSocket={
        <Box display="flex">
          <EmployerLogoWithNameAndColor
            employer={job.employer}
            jobId={job.id}
          />
          <VerticalSeparator height="" />
          <Typography
            flexShrink={3}
            component="p"
            variant="paragraph"
            size="large"
            noTextDecoration
            className={styles.title}
          >
            <RouterLink
              to={`/jobs/${job.id}`}
              target="_blank"
              className="flex"
              style={{ alignItems: "center" }}
            >
              {job.title}
              <ArrowRightTopIcon
                className={styles.arrow}
                style={{ marginLeft: "4px" }}
              />
            </RouterLink>
          </Typography>
        </Box>
      }
      rightSocket={
        <SubscribeSyncErrors fields={["resume"]}>
          {({ isError }) => {
            return (
              <Box display="flex" alignItems="center" gap={1}>
                <Button
                  size="medium"
                  variant="primary"
                  shape="squared"
                  onClick={() => {
                    goBack("/talent/dashboard/my_jobs/my_proposals/");
                  }}
                >
                  Go back
                </Button>
                <Tooltip
                  title="You cannot edit this application right now."
                  disabled={!disableSubmit || !isEditPage}
                >
                  <span>
                    <Button
                      disabled={disableSubmit}
                      size="medium"
                      variant={
                        isError || !areRequiredFieldsFilled || _isFormPristine
                          ? "disabled-grey"
                          : "positive"
                      }
                      shape="squared"
                      type="submit"
                    >
                      {isEditPage ? "Update application" : "Send application"}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            );
          }}
        </SubscribeSyncErrors>
      }
    />
  );
};
