import { Box } from "@hexocean/braintrust-ui-components";
import { EmployerLogo } from "@js/apps/employer/components/employer-logo";
import { useGetManagedEmployerQuery } from "@js/apps/employer/hooks";

export const ViewEmployerProfileLabel = () => {
  const { data: employerData } = useGetManagedEmployerQuery();

  return (
    <Box display="flex" gap="10px" alignItems="center">
      {employerData && (
        <EmployerLogo
          variant="menuitem"
          employer={employerData}
          listingStyle={{
            maxHeight: "25px",
            maxWidth: "25px",
          }}
        />
      )}
      View profile
    </Box>
  );
};
