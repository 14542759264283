import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { SpeechIcon } from "@hexocean/braintrust-ui-components/Icons";

import { EtiquetteRules } from "../etiquette-rules";

import style from "./styles.module.scss";

export const EtiquetteSidePanel = ({ close }: { close: () => void }) => {
  return (
    <Box className={style.sidePanel}>
      <Box mb={2} className={style.sidePanelIconWrapper}>
        <SpeechIcon className={style.sidePanelIconWrapperContent} />
      </Box>
      <Box mb={2}>
        <Typography variant="label" size="medium" component="h1" mb={3}>
          Career Help Etiquette
        </Typography>
        <Typography variant="paragraph" size="small" component="p" mb={3}>
          The Professional Network is a space for talent to give and get career
          help, it is <b>not</b> a job board.
        </Typography>
        <EtiquetteRules />
        <Typography variant="paragraph" size="small" component="p">
          Please email{" "}
          <a
            href={`mailto:${SETTINGS.SUPPORT_EMAIL}`}
            className={style.sidePanelLink}
            rel="noreferrer"
          >
            support@usebraintrust.com
          </a>{" "}
          for all Braintrust specific support questions or view our full{" "}
          <a
            href={SETTINGS.SUPPORT_URLS.FEED_COMMUNITY_GUIDELINES_ARTICLE_URL}
            className={style.sidePanelLink}
            target="_blank"
            rel="noreferrer"
          >
            community guidelines.
          </a>
        </Typography>
      </Box>
      <Button
        variant="transparent"
        size="x-small"
        onClick={close}
        className={style.sidePanelCloseButton}
      >
        Close
      </Button>
    </Box>
  );
};
