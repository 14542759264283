import cs from "classnames";

import styles from "./style.module.scss";

type DotBadgeProps = {
  show: boolean;
  children: React.ReactNode;
  className?: string;
};

export const DotBadge = ({ show, children, className }: DotBadgeProps) => {
  return (
    <div className={styles.badge}>
      {show && <div className={cs(styles.badgeDot, className)} />}
      {children}
    </div>
  );
};
