import React from "react";
import cs from "classnames";

import { Typography } from "@hexocean/braintrust-ui-components";
import {
  EarthIcon,
  PeopleIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useAccountType } from "@js/apps/common/hooks";
import { Avatar } from "@js/apps/spaces/components/avatar";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import type { MySpace } from "@js/types/spaces";
import { pluralize } from "@js/utils";

import style from "./style.module.scss";

type MySpacesListProps = {
  spaces: MySpace[];
  className?: string;
};

export const MySpacesList = ({ spaces, className }: MySpacesListProps) => {
  return (
    <div className={cs(style.spacesListContainer, className)}>
      {spaces.map((space) => (
        <MySpaceItem key={space.name} {...space} />
      ))}
    </div>
  );
};

const MySpaceItemWrapper = ({
  children,
  to,
  className,
}: {
  children: React.ReactNode;
  to: string;
  className: string;
}) => {
  const { isEmployer } = useAccountType();

  return isEmployer ? (
    <div className={className}>{children}</div>
  ) : (
    <RouterLink to={to} className={className} onClick={ModalInstance.close}>
      {children}
    </RouterLink>
  );
};

const MySpaceItem = ({
  name,
  visibility,
  total_members,
  is_admin,
  avatar_thumbnail,
  id,
}: MySpace) => {
  const spacePath = `/spaces/${id}`;

  return (
    <MySpaceItemWrapper className={style.spaceItem} to={spacePath}>
      <Avatar src={avatar_thumbnail} size="small" />
      <div className={style.spaceItemDetailsWrapper}>
        <Typography
          component="h4"
          fontWeight={500}
          ellipsis
          title={name}
          className={cs({
            [style.spaceItemWithBadge]: is_admin,
          })}
        >
          {name}
        </Typography>
        <div className={style.spaceItemDetails}>
          <div className={style.spaceItemDetail}>
            <EarthIcon />
            <Typography
              component="span"
              size="small"
              textTransform="capitalize"
            >
              {visibility}
            </Typography>
          </div>
          <div className={style.spaceItemDetail}>
            <PeopleIcon />
            <Typography component="span" size="small">
              {total_members} member{pluralize(total_members)}
            </Typography>
          </div>
        </div>
      </div>
      {is_admin && <div className={style.spaceItemAdminBadge}>Admin</div>}
    </MySpaceItemWrapper>
  );
};
