import { createAction } from "@reduxjs/toolkit";

import type { FormValue } from "@hexocean/braintrust-ui-components";
import type { FeedOrderingType } from "@js/apps/common/components/filters";
import { Events } from "@js/services/analytics/constants";
import type { IPost, PostComment } from "@js/types/give-and-get-help";

import type { FooterActions } from "./components/footer-action-buttons-section/models";
import { actionTypes } from "./action-types";
import type {
  CreateOrEditPostMode,
  PostLocationType,
  ReplyType,
} from "./types";

export type FooterActionButtonClicked = {
  name: FooterActions;
  comment?: PostComment;
  post?: IPost;
  mode: CreateOrEditPostMode | ReplyType;
  post_location: PostLocationType;
  hashtag_id?: number;
};

export const postFooterActionButtonClicked = (
  payload: FooterActionButtonClicked,
) => {
  return {
    type: actionTypes["footer-action-button-clicked"],
    payload,
  };
};

type ExpandCollapsePayload = {
  entity: IPost | PostComment;
  post_location: PostLocationType;
};

export const postExpanded = (payload: ExpandCollapsePayload) => {
  return {
    type: actionTypes["expanded"],
    payload,
  };
};

export const postCollapsed = (payload: ExpandCollapsePayload) => {
  return {
    type: actionTypes["collapsed"],
    payload,
  };
};

export const postUserNameClicked = (payload: ExpandCollapsePayload) => {
  return {
    type: actionTypes["user-name-clicked"],
    payload,
  };
};

type ViewPostPayload = {
  postId: number;
  entity: Partial<IPost | PostComment>;
  post_location: PostLocationType;
};

export const postViewed = (payload: ViewPostPayload) => {
  return {
    type: actionTypes["viewed"],
    payload,
  };
};
type NestStepClickedPayload = {
  mode: CreateOrEditPostMode;
  composer_location: PostLocationType;
};

export const nextStepClicked = (payload: NestStepClickedPayload) => {
  return {
    type: actionTypes["create/next-step-clicked"],
    payload,
  };
};

type CategoryAddedPayload = {
  value: number;
  composer_location: PostLocationType;
};

export const categoryAdded = ({
  value,
  composer_location,
}: CategoryAddedPayload) => {
  return {
    type: actionTypes["create/category-added"],
    payload: { value, composer_location },
  };
};

type BudgetAddedPayload = {
  value: number;
  composer_location: PostLocationType;
};

export const budgetAdded = ({
  value,
  composer_location,
}: BudgetAddedPayload) => {
  return {
    type: actionTypes["create/budget-added"],
    payload: {
      value,
      composer_location,
    },
  };
};

type ReplyClickedPayload = Partial<IPost | PostComment> & {
  post_location: PostLocationType;
};

export const replyClicked = (payload: ReplyClickedPayload) => {
  return {
    type: actionTypes["reply-clicked"],
    payload,
  };
};
type RespondToPostPayload = {
  post: Partial<IPost | PostComment>;
  post_location: PostLocationType;
};

export const respondedToPost = (payload: RespondToPostPayload) => {
  return {
    type: actionTypes["responded-to-post"],
    payload,
  };
};

type InterestedClickedPayload = {
  post: IPost;
  comment: PostComment;
  post_location: PostLocationType;
};

export const interestedClicked = (payload: InterestedClickedPayload) => {
  return {
    type: actionTypes["interested-clicked"],
    payload,
  };
};

type OfferPriceClickedPayload = {
  post: IPost;
  comment?: PostComment;
  data: FormValue;
  post_location: PostLocationType;
};

export const offerPriceClicked = (payload: OfferPriceClickedPayload) => {
  return {
    type: actionTypes["offer-price-clicked"],
    payload,
  };
};

type PollEventsPayload = {
  composer_location: PostLocationType;
};

export const userStartTypingPollQuestion = (payload: PollEventsPayload) => {
  return {
    type: actionTypes["create/user-started-typing-poll-question"],
    payload,
  };
};

export const userStartTypingPollOption = (payload: PollEventsPayload) => {
  return {
    type: actionTypes["create/user-started-typing-poll-option"],
    payload,
  };
};

type FinishCreatePostPayload = {
  composer_location: PostLocationType;
};

export const finishCreatePost = (payload: FinishCreatePostPayload) => {
  return {
    type: actionTypes["create/finish-create-post"],
    payload,
  };
};

type ClickPostHashtagPayload = {
  hashtag_id: number;
  post_id: number;
  post_location: PostLocationType;
  slot_position: number;
  post_level: number;
};

export type ClickTrendingHashtagPayload = Pick<
  ClickPostHashtagPayload,
  "hashtag_id"
>;

export const clickPostHashtag = createAction<
  ClickPostHashtagPayload,
  typeof Events.CLICK_POST_HASHTAG
>(Events.CLICK_POST_HASHTAG);

export const clickTrendingHashtag = createAction<
  ClickTrendingHashtagPayload,
  typeof Events.CLICK_TRENDING_HASHTAG
>(Events.CLICK_TRENDING_HASHTAG);

export type HashtagActionTypes = ReturnType<
  typeof clickPostHashtag | typeof clickTrendingHashtag
>["type"];
type HoverStatePayload = {
  talent_id: number;
};

export const viewProfileHoverStateClicked = (payload: HoverStatePayload) => {
  return {
    type: actionTypes["view-profile-hover-state-clicked"],
    payload,
  };
};

export const talentNamePostClicked = (payload: HoverStatePayload) => {
  return {
    type: actionTypes["talent-name-hover-state-clicked"],
    payload,
  };
};

export const careerHelpThroughMyPostsClicked = () => {
  return {
    type: actionTypes["career-help-through-my-posts-clicked"],
  };
};

export const getInspiredBtnClicked = () => {
  return {
    type: actionTypes["get-inspired-btn-clicked"],
  };
};

export const gghPostSortClicked = (payload: FeedOrderingType) => {
  return {
    type: actionTypes["post-sort-order-clicked"],
    payload,
  };
};
