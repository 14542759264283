import type { TypedWrappedFieldProps } from "redux-form";
import _ from "underscore";

import {
  ComboBoxMultiple,
  PaperWithVioletOptions,
  TextField,
} from "@hexocean/braintrust-ui-components";
import type { Skill } from "@js/types/admin";

import { useGetSkillsQuery } from "../api";

import { NotFoundText, SearchIcon } from "./components";
import { StyledSkillsAutocomplete } from "./styled";

export const SkillsFilterCombobox = ({
  input,
  meta,
}: TypedWrappedFieldProps<Array<Skill>>) => {
  const { data: options } = useGetSkillsQuery({
    ordering: "name",
  });

  const getUniqueSkills = <T extends { id: number }>(values: T[]): T[] => {
    const unique = _.uniq(values, false, (valueArg) => {
      return valueArg.id;
    });
    return unique;
  };

  return (
    <ComboBoxMultiple<Skill, true>
      id="skillsCombobox"
      value={input.value}
      options={options || []}
      initialTaxonomiesLoading={false}
      component={StyledSkillsAutocomplete}
      PaperComponent={PaperWithVioletOptions}
      onChange={(_ev, values) => {
        const unique = getUniqueSkills<Skill>(values);
        input.onChange(unique);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            size="small"
            helperText={meta.error}
            error={meta.error.length > 0}
            placeholder="Search skills"
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
            }}
          />
        );
      }}
      noOptionsText={<NotFoundText />}
      getOptionLabel={(option) => option.name}
      groupBy={(option) => {
        const firstLetter = option.name[0].toUpperCase();
        return /[0-9]/.test(firstLetter) ? "0-9" : firstLetter;
      }}
      disableClearable
      disableValueRemoval
      filterSelectedOptions
    />
  );
};
