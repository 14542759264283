import type { TypedUseQueryHookResult } from "@reduxjs/toolkit/dist/query/react";
import { skipToken } from "@reduxjs/toolkit/query";

import { useGetManagedEmployerByIdQuery } from "@js/apps/employer/api";
import { useEmployerId } from "@js/apps/employer/hooks/employer-id";
import type { Employer } from "@js/types/employer";

export const useGetManagedEmployerQuery = (
  options?: Parameters<typeof useGetManagedEmployerByIdQuery>[1],
) => {
  const employerId = useEmployerId();

  return useGetManagedEmployerByIdQuery(
    employerId ? employerId : skipToken,
    options,
  ) as TypedUseQueryHookResult<Employer, number, any>;
};
