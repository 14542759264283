import type { Job } from "@js/types/jobs";

import {
  LOCATION_REQUIREMENT_WARNING,
  TIMEZONE_REQUIREMENT_WARNING,
} from "./const";
import { CTAMessage, MessageWrapper } from "./message-components";
import { useLocationWarningBadge } from "./use-location-warning-badge";

export type LocationRequirementWarningProps = {
  job: RequiredWithUndefined<
    Pick<
      Job,
      | "locations"
      | "strong_location_requirement_met"
      | "strong_timezone_requirement_met"
    >
  >;
};

export const LocationRequirementWarning = ({
  job,
}: LocationRequirementWarningProps): JSX.Element | null => {
  const {
    strong_location_requirement_met,
    strong_timezone_requirement_met,
    locations,
  } = job;

  const { isFreelancer, handleCtaMessage, isLocationSet } =
    useLocationWarningBadge();

  if (!isFreelancer) return null;

  if (!isLocationSet && locations && locations.length > 0) {
    return (
      <MessageWrapper>
        You need to select your location to apply for this job. Please{" "}
        <CTAMessage onClick={handleCtaMessage}>update your profile</CTAMessage>.
      </MessageWrapper>
    );
  }

  if (
    strong_location_requirement_met === false ||
    strong_timezone_requirement_met === false
  ) {
    return (
      <MessageWrapper>
        {getWarningReason({
          strong_location_requirement_met,
          strong_timezone_requirement_met,
        })}
      </MessageWrapper>
    );
  }

  return null;
};

const getWarningReason = ({
  strong_location_requirement_met,
  strong_timezone_requirement_met,
}: {
  strong_location_requirement_met?: boolean;
  strong_timezone_requirement_met?: boolean;
}) => {
  if (
    strong_location_requirement_met === false &&
    strong_timezone_requirement_met
  ) {
    return LOCATION_REQUIREMENT_WARNING;
  }

  if (
    strong_location_requirement_met &&
    strong_timezone_requirement_met === false
  ) {
    return TIMEZONE_REQUIREMENT_WARNING;
  }

  return LOCATION_REQUIREMENT_WARNING;
};
