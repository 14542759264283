import { useAppSelector } from "@js/hooks";

export const useGetActiveFilterName = (location: string) => {
  const { savedFilterName, filtersTalents, filtersJobs } = useAppSelector(
    (state) => state.savedFilters,
  );
  const loadedFilters = [...filtersTalents, ...filtersJobs].find(
    (f) => f.name === savedFilterName[location],
  )?.filters;
  return {
    savedFilterName: savedFilterName[location],
    loadedFilters,
  };
};
