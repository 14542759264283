import React, { useEffect } from "react";

import { SignUpConfirmationContent } from "@js/apps/onboarding/components/sign-up-confirmation-content";
import { OnboardingProfNetLayout } from "@js/apps/onboarding/views";
import { useAppDispatch } from "@js/hooks";

import { viewClientSignUpEmailPage } from "../../actions";
import { useNewClientSignUpSelfServe } from "../../hooks/new-client-signup-self-serve";

export const SignUpEmailConfirmPage = () => {
  const dispatch = useAppDispatch();
  const isClientSelfServe = useNewClientSignUpSelfServe();

  useEffect(() => {
    if (isClientSelfServe) {
      dispatch(viewClientSignUpEmailPage());
    }
  }, [dispatch, isClientSelfServe]);

  return (
    <OnboardingProfNetLayout
      pageTitle="Check your email to verify your account."
      isEmailConfirmPage
    >
      <SignUpConfirmationContent />
    </OnboardingProfNetLayout>
  );
};
