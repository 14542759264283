import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import type { PurchaseUpgradeParams } from "@js/apps/auth/api";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import { useUserWalletBalance } from "@js/apps/common/hooks/use-user-wallet-balance";
import { openInsightPurchaseModal } from "@js/apps/jobs/components/application-insight-purchase-modal";
import { usePreCheckBalanceUpgrade } from "@js/apps/jobs/components/application-onramp-modal/hooks";
import { openApplicationOnrampModal } from "@js/apps/jobs/components/application-onramp-modal/utils";
import type { OpenBidCreateSuccessModalArg } from "@js/apps/jobs/components/bid-create-success-modal/types";
import { openBoostModal } from "@js/apps/jobs/components/job-list-boost-modal/job-list-boost-modal";
import { openProductDescriptionModal } from "@js/apps/jobs/components/product-description-modal";
import { useStripeFeatureFlag } from "@js/apps/jobs/hooks/stripe-feature-flag";
import { Snackbar } from "@js/components/snackbar";

export type OnClickPurchaseUpgradeArg = {
  onSuccessPurchaseBtrst?: () => void;
  onSuccessPurchaseBoost?: () => void;
  postAppBoostProps?: OpenBidCreateSuccessModalArg;
};

const usePurchaseUpgrade = (
  upgradeType: PurchaseUpgradeParams["upgrade_type"],
) => {
  const { isSufficientBtrst } = usePreCheckBalanceUpgrade(upgradeType);
  const { showStripe } = useStripeFeatureFlag();

  const onClickPurchaseUpgrade = useCallback(
    (args?: OnClickPurchaseUpgradeArg) => {
      if (showStripe) {
        openProductDescriptionModal({ upgradeType });
      } else {
        if (isSufficientBtrst) {
          if (upgradeType === "boost") {
            openBoostModal({
              onSuccessPurchaseBoost: args?.onSuccessPurchaseBoost,
            });
          } else if (upgradeType === "insights") {
            openInsightPurchaseModal();
          } else {
            Snackbar.error("Couldn't open any upgrade modal");
          }
        } else {
          openApplicationOnrampModal({
            onSuccessPurchaseBtrst: args?.onSuccessPurchaseBtrst,
            upgradeType,
          });
        }
      }
    },
    [isSufficientBtrst, showStripe, upgradeType],
  );

  return {
    onClickPurchaseUpgrade,
  };
};

const useShowPurchaseUpgrade = () => {
  const user = useUser();
  const { isFreelancer } = useAccountType();
  const { isLoading: loadingWalletBalance } = useUserWalletBalance();
  const { onClickPurchaseUpgrade: showInsightPurchase } =
    usePurchaseUpgrade("insights");
  const { onClickPurchaseUpgrade: showBoostPurchase } =
    usePurchaseUpgrade("boost");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const shouldOpenPurchaseInsights = searchParams.get("purchase_insights");
    const isPurchaseModal = searchParams.get("ispurchasemodal");

    if (!loadingWalletBalance && isFreelancer) {
      if (shouldOpenPurchaseInsights === "true") {
        showInsightPurchase();
      } else if (isFreelancer && isPurchaseModal === "true") {
        showBoostPurchase();
      }
    }
  }, [
    isFreelancer,
    loadingWalletBalance,
    searchParams,
    showBoostPurchase,
    showInsightPurchase,
    user?.can_view_insights,
  ]);
};

export { usePurchaseUpgrade, useShowPurchaseUpgrade };
