import React from "react";

import type { TypographyProps } from "@hexocean/braintrust-ui-components";
import { Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { useAnchorRedirectWarning } from "@js/hooks";
import { IMPLICIT_ANCHOR_WARNING_ATTR } from "@js/services";
import { shortenValue } from "@js/utils";

type EmployerWebsiteUrlProps = {
  url: string;
  maxLength?: number;
} & Omit<TypographyProps, "component" | "to" | "href">;

export const EmployerWebsiteUrl = ({
  url,
  maxLength,
  ...props
}: EmployerWebsiteUrlProps) => {
  const { anchorWrapperRef } = useAnchorRedirectWarning<HTMLDivElement>();
  const urlLabelWithoutHttpsPrefix = url
    .replace(/(^\w+:|^)\/\//, "")
    .replace(/\/$/, "");

  return (
    <div ref={anchorWrapperRef}>
      <Typography
        color="blue"
        component="link"
        to={url}
        target="_blank"
        rel="noopener noreferrer"
        size="medium"
        noTextDecoration
        RouterLink={RouterLink}
        {...{ [IMPLICIT_ANCHOR_WARNING_ATTR]: true }}
        {...props}
      >
        {maxLength
          ? shortenValue(urlLabelWithoutHttpsPrefix, maxLength)
          : urlLabelWithoutHttpsPrefix}
      </Typography>
    </div>
  );
};
