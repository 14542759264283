export const LOAD_SITE_NOTIFICATIONS = "LOAD_SITE_NOTIFICATIONS";
export const LOAD_SITE_NOTIFICATIONS_SUCCESS =
  "LOAD_SITE_NOTIFICATIONS_SUCCESS";
export const LOAD_SITE_NOTIFICATIONS_FAILED = "LOAD_SITE_NOTIFICATIONS_FAILED";

//dispatched by WebSocketManager forwardAction
export const SITE_NOTIFICATION = "@websocket/SITE_NOTIFICATION";
export const MARK_SITE_NOTIFICATION_AS_READ = "MARK_SITE_NOTIFICATION_AS_READ";
export const MARK_ALL_SITE_NOTIFICATIONS_AS_READ =
  "MARK_ALL_SITE_NOTIFICATIONS_AS_READ";
