import type { ReactNode } from "react";
import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";

import styles from "./styles.module.scss";

export const PostsListContainer = ({ children }: { children: ReactNode }) => {
  return <Box className={styles.postsList}>{children}</Box>;
};
