import { useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";

import { getUTMQueryParamsSearch } from "@js/utils/url";

import { useNavigate } from "../navigate";

export const useResetQueryExceptUTM = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const locationRef = useRef(location);
  locationRef.current = location;

  const resetQueryExceptUTM = useCallback(() => {
    const { pathname, search } = locationRef.current;
    const onlyUtmSearch = getUTMQueryParamsSearch(search);
    navigate(`${pathname}?${onlyUtmSearch}`);
  }, [navigate, locationRef]);

  return { resetQueryExceptUTM };
};
