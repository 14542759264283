import { Typography } from "@hexocean/braintrust-ui-components";
import { EditPenIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { PostComment } from "@js/types/give-and-get-help";

import { useGghServicesNudge } from "../../hooks/ggh-services-nudge";

type GghServicesNudgeProps = {
  postCategoryId: number;
  comment: PostComment;
};

export const GghServicesNudge = ({
  postCategoryId,
  comment,
}: GghServicesNudgeProps) => {
  const { isCategoryAlreadyAdded, openAddServiceModal } = useGghServicesNudge(
    postCategoryId,
    comment,
  );

  if (isCategoryAlreadyAdded) return null;

  return <AddServices onClick={openAddServiceModal} />;
};

type AddServicesProps = {
  onClick: () => void;
};

const AddServices = ({ onClick }: AddServicesProps) => {
  return (
    <Typography
      variant="paragraph"
      component="span"
      size="small"
      color="grey-1"
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      gap={0.5}
      mt={1}
    >
      <EditPenIcon color="inherit" sx={{ fontSize: 16 }} /> Do you want to add
      this offer{" "}
      <Typography
        component="button"
        sx={{
          textDecoration: "underline",
          border: "none",
          backgroundColor: "transparent",
          color: "currentColor",
          padding: 0,
          cursor: "pointer",
        }}
        size="small"
        onClick={onClick}
      >
        to your profile?
      </Typography>
    </Typography>
  );
};
