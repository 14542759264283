import { Box } from "@hexocean/braintrust-ui-components";
import type { MappedPollOption } from "@js/apps/give-and-get-help/components/poll/utils";

export const PollResultsBar = ({ option }: { option: MappedPollOption }) => {
  return (
    <Box display="flex" width="100%" mt={1}>
      <Box width="100%" gap={1} alignItems="center" display="flex">
        <Bar
          widthPercent={option.percentage}
          bgcolor={option.winsOrDraws ? "var(--positive-2)" : "var(--info-2)"}
        />
      </Box>
    </Box>
  );
};

const Bar = ({
  widthPercent,
  bgcolor,
}: {
  widthPercent: `${number}%`;
  bgcolor: "var(--info-2)" | "var(--positive-2)";
}) => {
  return (
    <Box bgcolor={bgcolor} borderRadius={3} height={5} width={widthPercent} />
  );
};
