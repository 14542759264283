import { Modal } from "@js/components/modal";

export const CreatePostModalInstance = Modal("create-post-modal", {
  closeButton: false,
  padding: false,
  disableEscapeKeyDown: true,
  keepOnBackdropClick: true,
});

export const EditPostModalInstance = Modal("edit-post-modal", {
  closeButton: false,
  padding: false,
  disableEscapeKeyDown: true,
  keepOnBackdropClick: true,
});
