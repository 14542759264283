import { Field, reduxForm } from "redux-form";

import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { useFetchSearchTaxonomy } from "@js/apps/common/hooks/useFetchSearchTaxonomy";
import { ModalConfirm } from "@js/components/modal";
import { Form } from "@js/forms/components";
import { SelectMultipleField } from "@js/forms/fields";
import type { Job } from "@js/types/jobs";

import { SEARCH_OPS_OPTIONS, SEARCH_SALES_OPTIONS } from "../../constants";

type ManageMatcherAndSalesFormProps = {
  onCancel: () => void;
  job: Pick<Job, "id" | "sale_owners" | "op_owners">;
};

type UsersTaxonomyData = {
  id: number;
  name_and_email: string;
};

export const MANAGE_SALES_AND_OPS_FORM_ID = "manage-sales-and-matcher-form";

export type ManageJobOwnersFormData = {
  jobId: number;
  op_owners?: number[];
  sale_owners?: number[];
};

export const ManageJobOwnersForm = reduxForm<
  ManageJobOwnersFormData,
  ManageMatcherAndSalesFormProps
>({
  form: MANAGE_SALES_AND_OPS_FORM_ID,
})(({ onCancel, job, submit, submitting, error }) => {
  const [loadingOps, opsTaxonomy] =
    useFetchSearchTaxonomy<UsersTaxonomyData>(SEARCH_OPS_OPTIONS);
  const opsOptions = opsTaxonomy.map((user) => ({
    value: user.id,
    label: user.name_and_email,
  }));

  const [loadingSales, salesTaxonomy] =
    useFetchSearchTaxonomy<UsersTaxonomyData>(SEARCH_SALES_OPTIONS);
  const salesOptions = salesTaxonomy.map((user) => ({
    value: user.id,
    label: user.name_and_email,
  }));

  const numberOfOps = job.op_owners?.length ?? 0;
  const numberOfSales = job.sale_owners?.length ?? 0;

  return loadingSales || loadingOps ? (
    <Loader />
  ) : (
    <Form error={error} onSubmit={submitting ? null : submit}>
      <ModalConfirm
        confirmText="Update owners"
        onConfirm={submit}
        onCancel={onCancel}
      >
        <Box mt={6} minHeight={232} width="70vw" maxWidth={432} px={2}>
          <Typography component="h3" variant="title" size="small">
            {!!numberOfOps || !!numberOfSales ? "Change Owners" : "Add Owners"}
          </Typography>
          <Box mt={3} maxWidth={336}>
            <Field
              id="op_owners"
              name="op_owners"
              component={SelectMultipleField}
              options={opsOptions}
              label="Talent Specialist"
            />
          </Box>
          <Box mt={3} maxWidth={336}>
            <Field
              id="sale_owners"
              name="sale_owners"
              component={SelectMultipleField}
              options={salesOptions}
              label="Sales Specialist"
            />
          </Box>
        </Box>
      </ModalConfirm>
    </Form>
  );
});
