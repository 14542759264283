import { useMemo } from "react";

import type { CreatableComboBoxProps } from "@hexocean/braintrust-ui-components";
import { CreatableComboBox } from "@hexocean/braintrust-ui-components";
import { useGetCertificatesQuery } from "@js/apps/freelancer/api";

import { useSearchPhrase } from "../../hooks/search-phrase";

export type CertificatesCreatableComboboxProps = Omit<
  CreatableComboBoxProps<false>,
  "options"
>;

export const CertificatesCreatableCombobox = (
  props: CertificatesCreatableComboboxProps,
) => {
  const { onInputChange, searchPhrase } = useSearchPhrase();

  const { data } = useGetCertificatesQuery({
    search: searchPhrase,
  });

  const options = useMemo(() => {
    return (data || []).map((opt) => ({
      value: opt.name,
      label: opt.name,
    }));
  }, [data]);

  return (
    <CreatableComboBox
      onInputChange={onInputChange}
      options={options}
      {...props}
    />
  );
};
