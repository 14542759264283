import { Box, Typography } from "@hexocean/braintrust-ui-components";
import type {
  EmployerBid,
  EmployerBidListBid,
  Job,
  JobInterviewTranscript,
} from "@js/types/jobs";

import { BidInterviewGrading } from "./bid-interview-grading";
import {
  BidInterviewModalContent,
  BidInterviewVideoModal,
} from "./bid-interview-modal";
import { BidInterviewQuestions } from "./bid-interview-questions";
import { BidInterviewSummary } from "./bid-interview-summary";

export const BidInterviewTab = ({
  bid,
  job,
}: {
  bid: EmployerBidListBid | EmployerBid;
  job: Job;
}) => {
  const { interview, freelancer } = bid;

  if (!interview) {
    return null;
  }
  const {
    overall_feedback,
    overall_score,
    job_interview_scorecards,
    interview_recording_url,
    transcript,
  } = interview;
  const { user } = freelancer;
  const { public_name } = user;
  const { title } = job;

  const openVideo = () => {
    BidInterviewVideoModal.open();
  };

  const transcriptConversations = convertTranscriptToArray(
    public_name,
    transcript,
  );

  const transcriptString = transcriptConversations.map((c) => c.text).join(" ");

  return (
    <Box>
      <Box>
        <Typography component="h1" variant="paragraph" size="large" mb={2}>
          Summary
        </Typography>
      </Box>
      <BidInterviewSummary score={overall_score} summary={overall_feedback} />
      <BidInterviewGrading scorecards={job_interview_scorecards} />
      <BidInterviewQuestions
        description={transcriptString}
        title={`${public_name}'s AI Interview`}
        openVideo={openVideo}
        videoUrl={interview_recording_url}
      />
      <BidInterviewModalContent
        transcript={transcriptConversations}
        title={public_name}
        subtitle={title}
        videoUrl={interview_recording_url}
      />
    </Box>
  );
};

const AGENT_LABEL = "Agent:";
const USER_LABEL = "User:";

export const convertTranscriptToArray = (
  freelancerName: string,
  transcript?: string | null,
): JobInterviewTranscript[] => {
  if (!transcript) return [];

  const conversation: JobInterviewTranscript[] = [];
  let previousName = "";

  const lines = transcript.split("\n");

  for (const line of lines) {
    const trimmedLine = line.trim();
    if (trimmedLine.length === 0) continue;

    let name: string;
    let text: string;

    if (trimmedLine.startsWith(AGENT_LABEL)) {
      name = "AIR";
      text = trimmedLine.slice(AGENT_LABEL.length).trim();
    } else if (trimmedLine.startsWith(USER_LABEL)) {
      name = freelancerName;
      text = trimmedLine.slice(USER_LABEL.length).trim();
    } else {
      name = previousName;
      text = trimmedLine;
    }

    conversation.push({ name, text });
    previousName = name;
  }

  return conversation;
};
