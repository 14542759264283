import React, { useCallback } from "react";

import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  EditPenIcon,
  LocationPinSmallIcon,
  WebsiteIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { openLocationMismatchModal } from "@js/apps/admin/components/location-mismatch-modal";
import { RatingCard } from "@js/apps/common/components";
import { useIsNodeStaff, useUser } from "@js/apps/common/hooks";
import { LocationWarning } from "@js/apps/freelancer/components/location-warning";
import { openGeneralInfoModal } from "@js/apps/freelancer/components/profnet-profile-page-head/open-general-info-modal";
import { useFreelancerProfile } from "@js/apps/freelancer/hooks";
import { RouterLink } from "@js/components/link";
import type {
  Address,
  FreelancerPublicExternalProfile,
} from "@js/types/common";
import type {
  FreelancerForNodeStaff,
  FreelancerPublic,
} from "@js/types/freelancer";
import { isFreelancerForNodeStaffEntity } from "@js/types/freelancer";
import { getAddressString } from "@js/utils";

import { useGetFreelancerProfileCompletionQuery } from "../../api";
import { IconWithProfileCompletionFeedback } from "../profile-completion";

import { ProfnetProfilePageAvatar } from "./avatar";
import { ProfnetProfileActions } from "./profile-actions";

type ProfnetProfilePageHeadProps = {
  profile: FreelancerPublic | FreelancerForNodeStaff;
};

export const ProfnetProfilePageHead = ({
  profile,
}: ProfnetProfilePageHeadProps) => {
  const isMobile = useMediaQuery("sm");
  const isLarge = useMediaQuery("lg");
  const user = useUser();
  const freelancer = useFreelancerProfile();

  const isOwnProfile = !!user && profile.id === user.freelancer;
  const isNodeStaffType = isFreelancerForNodeStaffEntity(profile);
  const isLocationMismatched =
    isNodeStaffType && (profile.location_mismatch || !profile.location);

  const freelancerId = profile.id;
  const { data: profileCompletion } = useGetFreelancerProfileCompletionQuery(
    { freelancerId },
    { skip: !isOwnProfile || !freelancerId },
  );

  const handleOpenEditModal = useCallback(() => {
    if (isOwnProfile && freelancer) openGeneralInfoModal(freelancer);
  }, [isOwnProfile, freelancer]);

  return (
    <Box className="proftnet-profile-page-head">
      <Box display="flex" justifyContent="space-between">
        <Box className="proftnet-profile-page-head__user-details">
          <ProfnetProfilePageAvatar profile={profile} />
          <Box sx={{ width: "100%", overflow: "hidden" }}>
            <Box
              mt={1}
              display="flex"
              flexWrap="wrap"
              alignItems={{ xs: "flex-start", lg: "center" }}
              justifyContent="space-between"
              flexDirection={{ xs: "column", lg: "row" }}
            >
              <Box display="flex" alignItems="center" maxWidth="100%">
                <Typography
                  component="h1"
                  variant="title"
                  multipleEllipsis
                  size={isMobile ? "medium" : "large"}
                  fontWeight={400}
                  lineHeight="49px"
                >
                  {profile.user?.first_name} {profile.user?.last_name}
                </Typography>
                {isOwnProfile && (
                  <Box ml="18px">
                    <IconButton
                      variant="black-outlined"
                      size="x-small"
                      aria-label="edit profile"
                      onClick={handleOpenEditModal}
                    >
                      <EditPenIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Box mb={1} mt={1}>
                <ProfnetProfileActions profile={profile} />
              </Box>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              paddingRight={1}
              gap={isLarge ? 1 : 0}
              flexWrap={isLarge ? "wrap" : "nowrap"}
            >
              <Box
                display="flex"
                alignItems="center"
                flexWrap={isMobile ? "wrap" : "nowrap"}
                gap="8px"
              >
                <Location
                  address={profile.location}
                  onAddLocationClick={handleOpenEditModal}
                  isOwnProfile={!!isOwnProfile}
                  isLocationMismatched={isLocationMismatched}
                  userId={profile.id}
                />
                <Socials
                  externalProfiles={profile.external_profiles}
                  onAddSocialsClick={handleOpenEditModal}
                  isOwnProfile={!!isOwnProfile}
                />
                {!!profileCompletion?.missing_areas.length && isOwnProfile && (
                  <IconWithProfileCompletionFeedback
                    missingAreas={profileCompletion.missing_areas}
                  />
                )}
              </Box>
              {isNodeStaffType && (
                <RatingCard freelancer={profile} color="white" />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

type LocationProps = {
  address: Address | string | null;
  onAddLocationClick: () => void;
  isOwnProfile: boolean;
  isLocationMismatched: boolean;
  userId: number;
};

const Location = ({
  address,
  onAddLocationClick,
  isOwnProfile,
  isLocationMismatched,
  userId,
}: LocationProps) => {
  const isNodeStaff = useIsNodeStaff();

  if (!address && isOwnProfile) {
    return (
      <Button
        variant="secondary"
        shape="squared"
        size="medium"
        onClick={onAddLocationClick}
      >
        <LocationPinSmallIcon />
        <Typography ml={1} component="p" variant="label" fontWeight={500}>
          Add location
        </Typography>
      </Button>
    );
  }

  const handleLocationWarningClick = () => {
    openLocationMismatchModal({ userId });
  };

  const getUserLocation = () => {
    if (address && typeof address === "object") {
      const { city, state, country } = address as Address;
      return getAddressString({ city, state, country }) || "Earth";
    }

    return address ?? "Earth";
  };

  return (
    <Box display="flex" alignItems="center">
      {isLocationMismatched && isNodeStaff ? (
        <LocationWarning onClick={handleLocationWarningClick}>
          <Typography ml={1} component="p" variant="label">
            {getUserLocation()}
          </Typography>
        </LocationWarning>
      ) : (
        <>
          <LocationPinSmallIcon />
          <Typography ml={1} component="p" variant="label">
            {getUserLocation()}
          </Typography>
        </>
      )}
    </Box>
  );
};

type SocialsProps = {
  externalProfiles: FreelancerPublicExternalProfile[];
  onAddSocialsClick: () => void;
  isOwnProfile: boolean;
};

const Socials = ({
  externalProfiles,
  onAddSocialsClick,
  isOwnProfile,
}: SocialsProps) => {
  if (!externalProfiles.length && isOwnProfile) {
    return (
      <Button
        variant="secondary"
        shape="squared"
        size="medium"
        sx={{
          marginLeft: "8px",
        }}
        onClick={onAddSocialsClick}
      >
        <WebsiteIcon />
        <Typography ml={1} component="p" variant="label">
          Add websites
        </Typography>
      </Button>
    );
  }

  return (
    <List className="proftnet-profile-page-head__socials">
      {externalProfiles?.map((externalProfile) => (
        <ListItem key={externalProfile.id} className="social-item">
          <IconButton
            variant="secondary"
            href={externalProfile.public_url}
            target="_blank"
            size="x-small"
            className="social-item__link"
            RouterLink={RouterLink}
            aria-label={`Go to user ${externalProfile.site.name} profile`}
          >
            <img
              src={externalProfile.site.logo?.thumbnail}
              alt={externalProfile.site.name}
              width="24"
              height="24"
            />
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
};
