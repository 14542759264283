import { useMemo } from "react";

import type { ManageJobOwnersFormData } from "@js/apps/admin/forms";
import { ManageJobOwnersForm } from "@js/apps/admin/forms";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type { Job } from "@js/types/jobs";
import { typeGuard } from "@js/utils";

import { useChangeJobOwnersMutation } from "../../api";

export const openManageJobOwnersModal = (arg: ManageJobOwnersModalProps) => {
  ModalInstance.open({
    children: <ManageJobOwnersModal {...arg} />,
  });
};

type ManageJobOwnersModalProps = {
  job: Pick<Job, "id" | "sale_owners" | "op_owners">;
};

const ManageJobOwnersModal = ({ job }: ManageJobOwnersModalProps) => {
  const [changeJobOwners] = useChangeJobOwnersMutation();

  const onSubmit = async (values: ManageJobOwnersFormData) => {
    return changeJobOwners(values)
      .unwrap()
      .then(() => {
        Snackbar.success("Job owners successfully updated");
      })
      .catch((error: unknown) => {
        const errorMessage =
          !!error && typeGuard<object, Record<string, string[]>>(error, "data")
            ? error.data
            : "Something went wrong";

        Snackbar.error(errorMessage);
      });
  };

  const initialValues = useMemo(() => {
    return {
      jobId: job.id,
      op_owners: job.op_owners?.map((opOwner) => opOwner.id),
      sale_owners: job.sale_owners?.map((opOwner) => opOwner.id),
    };
  }, [job]);

  return (
    <ManageJobOwnersForm
      onCancel={ModalInstance.close}
      job={job}
      onSubmitSuccess={() => {
        ModalInstance.close();
      }}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
};
