import { PaymentModalInstance } from "@js/apps/payments/components/payment-modal";
import {
  ACHPaymentSuccessModalInstance,
  CCPaymentSuccessModalInstance,
} from "@js/apps/payments/components/payment-success-modal";
import { AppLayout } from "@js/layouts/app";

import { EmployerInvoicesComponent } from "../../components/employer-invoices";

import styles from "./styles.module.scss";

export const EmployerInvoicesPage = () => {
  return (
    <AppLayout
      pageTitle="Invoices"
      flexColumn
      bgcolor="var(--soft-green)"
      setBgOnBody
      className={styles.page}
    >
      <EmployerInvoicesComponent />
      <CCPaymentSuccessModalInstance />
      <ACHPaymentSuccessModalInstance />
      <PaymentModalInstance />
    </AppLayout>
  );
};
