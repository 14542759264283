import React from "react";

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@hexocean/braintrust-ui-components";
import type { UserMinimalProfile } from "@js/types/auth";

type ReactionsDrawerListProps = {
  users: UserMinimalProfile[];
};

export const ReactionsDrawerList = ({ users }: ReactionsDrawerListProps) => {
  return (
    <List sx={{ overflow: "auto", px: 2.5 }}>
      {users.map((user) => {
        return (
          <ListItem key={user.id} sx={{ pl: 0 }}>
            <ListItemAvatar>
              <Avatar src={user} alt="User avatar" />
            </ListItemAvatar>
            <Typography
              component="span"
              variant="paragraph"
              size="small"
              fontWeight={500}
            >
              {user.public_name}
            </Typography>
          </ListItem>
        );
      })}
    </List>
  );
};
