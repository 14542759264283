import { ArrowTopRightIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./styles.module.scss";

export const HelpCenterLink = () => {
  return (
    <a
      href={SETTINGS.SUPPORT_URLS.HELP_CENTER_URL}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.link}
    >
      Help Center{" "}
      <ArrowTopRightIcon sx={{ fontSize: "14px", verticalAlign: "middle" }} />
    </a>
  );
};
