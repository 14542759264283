export const TALENT_STATUS = {
  NO_ACTION: "NO_ACTION",
  PROPOSED: "PROPOSED",
  IN_SCREENING: "IN_SCREENING",
  DECLINED: "DECLINED",
  OFFER_DECLINED: "OFFER_DECLINED",
  OFFER_MADE: "OFFER_MADE",
  APPLICATION_IN_REVIEW: "APPLICATION_IN_REVIEW",
  HIRED: "HIRED",
} as const;

export const DEFAULT_LISTING_BG_COLOR = "var(--off-white)";
export const ALTERNATIVE_LISTING_BG_COLOR = "var(--white)";
export const FALLBACK_BG_COLOR = "var(--medium-violet)";

export const BID_SUBMIT_TYPE = {
  WITH_FEEDBACK: "WITH_FEEDBACK",
  WITHOUT_FEEDBACK: "WITHOUT_FEEDBACK",
  WITH_FEEDBACK_BULK: "WITH_FEEDBACK_BULK",
} as const;

export const INTERVIEW_QUESTIONS_FORM_ID = "interview-questions-form";
export const INTERVIEW_QUESTION_MAX_ANSWER_DURATION_OPTIONS = [
  {
    label: "1 minute",
    value: "1",
  },
  {
    label: "2 minutes",
    value: "2",
  },
  {
    label: "3 minutes",
    value: "3",
  },
  {
    label: "4 minutes",
    value: "4",
  },
  {
    label: "5 minutes",
    value: "5",
  },
  {
    label: "6 minutes",
    value: "6",
  },
  {
    label: "7 minutes",
    value: "7",
  },
  {
    label: "8 minutes",
    value: "8",
  },
  {
    label: "9 minutes",
    value: "9",
  },
  {
    label: "10 minutes",
    value: "10",
  },
];
