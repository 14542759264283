import { useState } from "react";

import { parseShareWithTeamMembersError } from "@js/apps/employer/utils/team-members";
import { openDecoratedSuccessSnackbar } from "@js/components/decorated-success-snackbar";

import {
  useGetEmployerTeamMembersQuery,
  useShareTalentWithEmployerTeamMemberInvitationsMutation,
  useShareTalentWithEmployerTeamMembersMutation,
} from "../../api";
import {
  closeCommonTeamMembersModal,
  openCommonTeamMembersModal,
} from "../common-team-members-modal";

import { ShareTalentWithTeamMembersModalContent } from "./share-talent-with-team-members-modal-content";

type ShareTalentWithTeamMembersModalProps = {
  talentId: number;
};

export const ShareTalentWithTeamMembersModal = ({
  talentId,
}: ShareTalentWithTeamMembersModalProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { data: teamMembers, isLoading: isLoadingTeamMembers } =
    useGetEmployerTeamMembersQuery();
  const [
    shareTalentWithEmployerTeamMemberInvitations,
    { isLoading: isSharingWithInvitations },
  ] = useShareTalentWithEmployerTeamMemberInvitationsMutation();
  const [
    shareTalentWithEmployerTeamMembers,
    { isLoading: isSharingWithTeamMembers },
  ] = useShareTalentWithEmployerTeamMembersMutation();

  const clearErrorMessage = () => {
    setErrorMessage(undefined);
  };

  const handleShareTalent = async ({
    emailInvitations,
    teamMemberIds,
    message,
  }: {
    emailInvitations: string[];
    teamMemberIds: number[];
    message?: string;
  }) => {
    if (!emailInvitations.length && !teamMemberIds.length) {
      return closeShareTalentWithTeamMembersModal();
    }

    clearErrorMessage();

    try {
      if (emailInvitations.length) {
        await shareTalentWithEmployerTeamMemberInvitations({
          talent: talentId,
          emails: emailInvitations,
          message,
        }).unwrap();
      }

      if (teamMemberIds.length) {
        await shareTalentWithEmployerTeamMembers({
          talent: talentId,
          team_members: teamMemberIds,
          message,
        }).unwrap();
      }

      handleShareTalentSuccess();

      closeShareTalentWithTeamMembersModal();
    } catch (error) {
      setErrorMessage(parseShareWithTeamMembersError(error));
    }
  };

  const isLoading = isLoadingTeamMembers;
  const isSaving = isSharingWithInvitations || isSharingWithTeamMembers;
  return (
    <ShareTalentWithTeamMembersModalContent
      isLoading={isLoading}
      isSaving={isSaving}
      onSave={handleShareTalent}
      teamMembers={teamMembers}
      errorMessage={errorMessage}
      clearErrorMessage={clearErrorMessage}
    />
  );
};

export const openShareTalentWithTeamMembersModal = (
  arg: ShareTalentWithTeamMembersModalProps,
) => {
  openCommonTeamMembersModal({
    children: <ShareTalentWithTeamMembersModal {...arg} />,
  });
};

export const closeShareTalentWithTeamMembersModal = () => {
  closeCommonTeamMembersModal();
};

const handleShareTalentSuccess = () => {
  openDecoratedSuccessSnackbar("Shared talent profile with your team");
};
