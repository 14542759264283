import type { FC } from "react";
import type { TypedWrappedFieldProps, WrappedFieldProps } from "redux-form";
import { Field, Fields, formValueSelector } from "redux-form";

import {
  Box,
  Button,
  Select,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { createFormInstance } from "@js/forms/components";
import { RadioListField, TextField } from "@js/forms/fields";
import { RatingField } from "@js/forms/fields/rating";
import { requiredWithSpecificMessage } from "@js/forms/validators";
import { useAppSelector } from "@js/hooks";
import type { UpdateJobFeedbackData } from "@js/types/jobs";
import { enumToOptions } from "@js/utils";

import { APPLICATION_QUALITY, JOB_CLOSE_FIELDS } from "./close-job-constants";

type FormData = {
  job_filled_reason: EnumType<typeof ENUMS.JobFilledReasonType>;
  job_filled_using_platform: EnumType<
    typeof ENUMS.JobFilledUsingPlatformType
  > | null;
  reason_other: string | null;
  quality_of_applications_received_rate: string;
};

const CloseFilledJobFormInstance = createFormInstance<FormData, unknown>(
  "close-filled-job-form",
  {
    onChange: (values, dispatch, props) => {
      if (
        values.job_filled_reason !== ENUMS.JobFilledReasonType.ANOTHER_PLATFORM
      ) {
        if (props.change && !!values.job_filled_using_platform) {
          dispatch(
            props.change(JOB_CLOSE_FIELDS.job_filled_using_platform, null),
          );
        }
      }

      if (values.job_filled_reason !== ENUMS.JobFilledReasonType.OTHER) {
        if (props.change && props.untouch && !!values.reason_other) {
          dispatch(props.change(JOB_CLOSE_FIELDS.reason_other, null));
          dispatch(props.untouch(JOB_CLOSE_FIELDS.reason_other));
        }
      }
    },
  },
);

type CloseFilledJobFormProps = {
  onCancelClick: () => void;
  onSubmit: (clientFeedback: UpdateJobFeedbackData) => Promise<void>;
};

const closeFilledJobFormSelector = formValueSelector("close-filled-job-form");

const renderCloseJobAdornment = (
  option: string | boolean,
  jobFilledReason: string,
) => {
  if (
    option === ENUMS.JobFilledReasonType.ANOTHER_PLATFORM &&
    jobFilledReason === ENUMS.JobFilledReasonType.ANOTHER_PLATFORM
  ) {
    return (
      <Fields
        names={[
          JOB_CLOSE_FIELDS.job_filled_reason,
          JOB_CLOSE_FIELDS.job_filled_using_platform,
        ]}
        component={ChoosePlatform}
        validate={[requiredWithSpecificMessage("Please select a platform")]}
      ></Fields>
    );
  }
};

export const CloseFilledJobForm: FC<CloseFilledJobFormProps> = ({
  onCancelClick,
  onSubmit,
}) => {
  const jobFilledReason = useAppSelector((state) =>
    closeFilledJobFormSelector(state, "job_filled_reason"),
  );

  return (
    <CloseFilledJobFormInstance onSubmit={onSubmit}>
      <Box className="close-job-form-body">
        <Box>
          <Typography component="h1" variant="title" size="small">
            <Box component="span" mr={1}>
              🙌
            </Box>{" "}
            Congrats on filling this job!
          </Typography>
          <Typography component="p" variant="label" mt={4} mb={1.2}>
            How did this job get filled?
          </Typography>
          <Field
            name={JOB_CLOSE_FIELDS.job_filled_reason}
            options={enumToOptions(ENUMS.JobFilledReasonTypeLabels)}
            component={RadioListField}
            yellowOutline
            errorStyle={{
              marginTop: "-10px",
            }}
            validate={[requiredWithSpecificMessage("Please select an option")]}
            borderRadio={false}
            adornment={(option: string | boolean) =>
              renderCloseJobAdornment(option, jobFilledReason)
            }
          />
          {jobFilledReason === ENUMS.JobFilledReasonType.OTHER && (
            <Fields
              names={[
                JOB_CLOSE_FIELDS.job_filled_reason,
                JOB_CLOSE_FIELDS.reason_other,
              ]}
              component={OtherReasonFiled}
            ></Fields>
          )}
        </Box>

        <RatingFieldWithProps />

        <Box className="close-job-form-body__buttons">
          <Button variant="secondary" size="small" onClick={onCancelClick}>
            Go back
          </Button>
          <Button variant="primary" size="small" type="submit">
            Close job
          </Button>
        </Box>
      </Box>
    </CloseFilledJobFormInstance>
  );
};

type ChoosePlatformProps = {
  job_filled_reason: WrappedFieldProps;
  job_filled_using_platform: WrappedFieldProps;
};

const ChoosePlatform = ({
  job_filled_reason,
  job_filled_using_platform,
}: ChoosePlatformProps) => {
  if (
    job_filled_reason.input.value !== ENUMS.JobFilledReasonType.ANOTHER_PLATFORM
  ) {
    return null;
  }

  const {
    meta: { error, touched },
  } = job_filled_using_platform;

  return (
    <Select
      variant="outlined-new"
      value={job_filled_using_platform.input.value}
      options={enumToOptions(ENUMS.JobFilledUsingPlatformTypeLabels)}
      error={touched && error}
      placeholder="Which platform?"
      onChange={job_filled_using_platform.input.onChange}
      iconRotateOnOpen
      displayEmpty={() => true}
      FormControlProps={{
        style: {
          marginLeft: 40,
          width: "90%",
        },
      }}
    />
  );
};

type OtherReasonFiledProps = {
  job_filled_reason: TypedWrappedFieldProps<string>;
  reason_other: TypedWrappedFieldProps<string>;
};
const OtherReasonFiled = ({
  job_filled_reason,
  reason_other,
}: OtherReasonFiledProps) => {
  if (job_filled_reason.input.value !== ENUMS.JobFilledReasonType.OTHER) {
    return null;
  }

  return (
    <TextFieldWithProps
      fieldsName={reason_other}
      placeholder={"Enter how it was filled here"}
    />
  );
};

type TextFieldWithPropsProps = {
  placeholder: string;
  fieldsName: TypedWrappedFieldProps<string>;
};
export const TextFieldWithProps = ({
  fieldsName,
  placeholder,
}: TextFieldWithPropsProps) => {
  return (
    <TextField
      id="completeJobOtherReason"
      variant="outlined"
      placeholder={placeholder}
      input={fieldsName.input}
      meta={fieldsName.meta}
      onChange={(ev) => fieldsName.input.onChange(ev.target.value)}
      multiline
      InputProps={{
        style: {
          borderRadius: 20,
          minHeight: 85,
          width: "90%",
          marginLeft: 40,
          padding: "5px",
        },
      }}
      inputProps={{
        style: {
          alignSelf: "flex-start",
        },
      }}
      darkerBorder
      FormHelperTextProps={{
        style: {
          marginLeft: 40,
        },
      }}
    />
  );
};

export const RatingFieldWithProps = () => {
  return (
    <>
      <Typography component="p" variant="label" mt={3} mb={1}>
        How would you rate the quality of Braintrust applications you received?
      </Typography>
      <Field
        name={JOB_CLOSE_FIELDS.quality_of_applications_received_rate}
        component={RatingField}
        variant="stars"
        iconStyle={{ width: "45px", height: "45px" }}
        getLabelText={(value: number) =>
          APPLICATION_QUALITY[value as keyof typeof APPLICATION_QUALITY]
        }
        validate={[requiredWithSpecificMessage("Please select a rating")]}
        labelProps={{
          component: "p",
          variant: "label",
          mt: 1,
        }}
        FormHelperTextProps={{
          sx: {
            fontSize: "16px",
            marginBottom: "10px",
          },
        }}
        containerProps={{
          display: "flex",
          flexDirection: "column-reverse",
          justifyContent: "flex-end",
          alignItems: "center",
          height: "85px",
        }}
      />
    </>
  );
};
