import { useCallback } from "react";

import { useProductCheckoutModalContext } from "../product-checkout-modal-context";

export const useHeaderSection = () => {
  const { onClickBackModal } = useProductCheckoutModalContext();
  const onBackDescriptionModal = useCallback(() => {
    onClickBackModal();
  }, [onClickBackModal]);

  return {
    onBackDescriptionModal,
  };
};
