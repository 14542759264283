import type { OfferStatusUpdatedEventData } from "./types";

export const walletListenerRecipe = (invalidateCache: () => void) => {
  return (event: MessageEvent) => {
    if (!event.data) return;
    if (!isHelpOfferCompletedEvent(event)) return;

    invalidateCache();
  };
};

const isHelpOfferCompletedEvent = (
  event: MessageEvent,
): event is MessageEvent<OfferStatusUpdatedEventData> => {
  return (
    event.data.broadcast_type === ENUMS.BroadcastType.HELP_OFFER_UPDATED &&
    event.data.data.status === ENUMS.HelpOfferStatus.COMPLETED
  );
};
