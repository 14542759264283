import {
  Box,
  Button,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  BraintrustBoldIcon,
  BYOTIcon,
  TransferIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { ArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Errors } from "@js/apps/byot/components/errors";
import { ImpersonatedEmail } from "@js/apps/byot/components/impersonated-email";
import { UploadBYOTCsv } from "@js/apps/byot/components/importer";
import {
  MessageModalContent,
  MessageModalInstance,
  openMessageModalInstance,
} from "@js/apps/byot/components/message-modal/";
import { UploadedFileBadge } from "@js/apps/byot/components/uploaded-file-badge";
import { useUploadBYOTCsv } from "@js/apps/byot/hooks/upload-byot-csv";
import { useGoBackHistory } from "@js/hooks/go-back-history";
import { FullPageWithStickyHeaderLayout } from "@js/layouts/full-page-with-sticky-header";

import style from "./style.module.scss";

export const BYOTClientMVPPage = () => {
  const {
    onSubmit,
    control,
    isSubmitting,
    dispatch,
    error,
    isProcessing,
    fileName,
    isProcessedSuccessfully,
    disableSendOffersButton,
    numberOfOffers,
  } = useUploadBYOTCsv();

  return (
    <form onSubmit={onSubmit}>
      <FullPageWithStickyHeaderLayout
        pageTitle="BYOT"
        header={
          <div className={style.headerContent}>
            <Box ml={-2}>
              <BackButton />
            </Box>
            <Button
              variant="primary"
              size="medium"
              shape="squared"
              onClick={openMessageModalInstance}
              disabled={disableSendOffersButton}
            >
              Send offers
            </Button>
          </div>
        }
        content={
          <RoundedBox className={style.content}>
            <Header />
            <Box component="main" mt="24px">
              {isProcessedSuccessfully ? (
                <>
                  <Typography mb={1} component="p" variant="label">
                    Import summary
                  </Typography>
                  <UploadedFileBadge fileName={fileName || ""} />
                  <Typography component="p" mt={3} variant="label" size="small">
                    {numberOfOffers} Talent imported
                  </Typography>
                </>
              ) : (
                <>
                  <UploadBYOTCsv
                    isProcessingData={isProcessing}
                    onBeforeUpload={() => {
                      dispatch({ type: "start_processing" });
                    }}
                    onUpload={(uploadedFile) => {
                      dispatch({ type: "uploaded", payload: uploadedFile });
                    }}
                    onUploadError={(uploadError) => {
                      dispatch({
                        type: "uploaded_with_error",
                        payload: uploadError,
                      });
                    }}
                  />
                  {!!error && (
                    <Box display="inline-block">
                      <Errors error={error} />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </RoundedBox>
        }
      />
      <MessageModalInstance>
        <MessageModalContent
          isSubmitting={isSubmitting}
          control={control}
          numberOfOffers={numberOfOffers}
        />
      </MessageModalInstance>
    </form>
  );
};

const Header = () => {
  return (
    <Box component="header">
      <Box
        display="inline-flex"
        justifyContent="center"
        alignItems="center"
        gap={1}
        mb={2}
      >
        <BYOTIcon sx={{ width: 42, height: 42 }} />
        <TransferIcon />
        <BraintrustBoldIcon sx={{ width: 42, height: 42 }} />
      </Box>
      <Typography component="h1" variant="title" size="small" fontWeight={400}>
        Bring Your Own Talent
      </Typography>
      <Typography component="h2">
        Add your trusted professionals to the platform.
      </Typography>
      <Typography mt="32px" component="p">
        You are logged in as: <ImpersonatedEmail />.
      </Typography>
    </Box>
  );
};

const BackButton = () => {
  const goBack = useGoBackHistory();

  return (
    <Button
      variant="transparent"
      startIcon={<ArrowLeftIcon />}
      size="medium"
      shape="squared"
      onClick={() => {
        goBack("/employer/dashboard/my_talent");
      }}
    >
      Back
    </Button>
  );
};
