import { Box, Typography } from "@hexocean/braintrust-ui-components";

import { JobBidCommentList } from "../bid-comment-list";

import style from "./style.module.scss";

type ExpandedItemProps = {
  id: number;
};

export const ExpandedItem = ({ ...props }: ExpandedItemProps) => {
  return (
    <Box className={style.expandedBidItem}>
      <Typography
        component="p"
        variant="label"
        marginBottom="8px"
        fontWeight={500}
        color="grey-1"
        size="small"
      >
        Talent can't see comments.
      </Typography>
      <JobBidCommentList {...props} />
    </Box>
  );
};
