import React from "react";

import type {
  ButtonProps,
  ButtonVariants,
} from "@hexocean/braintrust-ui-components";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import { useCurrentFreelancerProfileCompletion } from "@js/apps/freelancer/hooks/use-current-freelancer-profile-completion";
import { CompleteProfileButton } from "@js/apps/jobs/components/complete-profile-button";
import { NotLoggedInApplyButton } from "@js/apps/jobs/components/not-logged-in-apply-button";
import type { FreelancerBid, MainJobListingJob } from "@js/types/jobs";

import { BidButton } from "../../apps/bids/components";

type CompleteProfileOrSubmitButtonProps = {
  job: MainJobListingJob;
  bid?: FreelancerBid;
  sx?: ButtonProps["sx"];
  variant?: ButtonVariants;
  inverse?: boolean;
  onClick?: () => void;
  openInNewTab?: boolean;
  hideBoostButton?: boolean;
};

export const CompleteProfileOrSubmitButton = ({
  job,
  bid,
  onClick,
  hideBoostButton,
  ...props
}: CompleteProfileOrSubmitButtonProps) => {
  const user = useUser();
  const { isFreelancerApproved } = useAccountType();
  const { data: profileCompletion, isLoading: isLoadingProfileCompletion } =
    useCurrentFreelancerProfileCompletion();
  const isProfileCompleted = !!profileCompletion?.profile_completion_hit_at;

  if (isLoadingProfileCompletion) {
    return <></>;
  }

  return (
    <div onClick={onClick} role="presentation">
      {!user ? (
        <NotLoggedInApplyButton jobId={job.id} {...props} />
      ) : isProfileCompleted || isFreelancerApproved ? (
        <BidButton
          job={job}
          bid={bid}
          hideBoostButton={hideBoostButton}
          {...props}
        />
      ) : (
        <CompleteProfileButton {...props} />
      )}
    </div>
  );
};
