import type { ChangeEventHandler } from "react";
import React from "react";

import {
  BasicInput,
  Box,
  FormHelperText,
} from "@hexocean/braintrust-ui-components";

export const MessageInput = ({
  value,
  error,
  displayError,
  minRows = 6,
  disabled,
  onChange,
}: {
  value: string;
  error?: string;
  displayError: boolean;
  minRows?: number;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.focus();
    // set cursor at the end
    inputRef.current.setSelectionRange(value.length, value.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        borderRadius="20px"
        p={2}
        border={`1px solid var(--${displayError ? "negative-1" : "grey-3"})`}
        bgcolor="var(--white)"
      >
        <BasicInput
          fullWidth
          multiline
          inputRef={inputRef}
          minRows={minRows}
          maxRows={20}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </Box>
      {displayError && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};
