import { useState } from "react";
import { useLocation } from "react-router-dom";

import { LocalStorage } from "@js/services";

export const useStoredReferrer = () => {
  const { state } = useLocation();
  const [localStorageReferrer] = useState(() =>
    LocalStorage.getItem(LocalStorage.keys.SIGN_UP_REFERRER),
  );
  const storedReferrer = localStorageReferrer || state?.referrer; // use state as fallback

  return typeof storedReferrer === "string" ? storedReferrer : undefined;
};
