import { SubmissionError } from "redux-form";

import {
  useAddSpaceRuleMutation,
  useEditSpaceRuleMutation,
  useGetSpaceRulesQuery,
} from "@js/apps/spaces/api";
import type { RuleFormData } from "@js/apps/spaces/components/rules/forms";
import { RTKMutationError } from "@js/apps/spaces/components/rules/forms/schema";
import { useIsSpaceAdmin } from "@js/apps/spaces/hooks/use-is-space-admin";
import { ModalInstance } from "@js/components/modal";

type useRulesArgs = {
  spaceId: number;
};

const useRules = ({ spaceId }: useRulesArgs) => {
  const { data } = useGetSpaceRulesQuery({
    id: spaceId,
  });
  const { isSpaceAdmin } = useIsSpaceAdmin({ spaceId });

  const shouldRenderEditRulesButton = isSpaceAdmin;

  return {
    rules: data || [],
    shouldRenderEditRulesButton,
  };
};

export type UseRuleFormArgs = {
  mode: "create" | "edit";
  spaceId: number;
  ruleId?: number;
};

const useRuleForm = ({ mode, spaceId, ruleId }: UseRuleFormArgs) => {
  const [addSpaceRuleMutation] = useAddSpaceRuleMutation();
  const [editSpaceRuleMutation] = useEditSpaceRuleMutation();

  const onSubmit = async (formValues: RuleFormData) => {
    switch (mode) {
      case "edit": {
        if (!ruleId) {
          return;
        }
        return editSpaceRuleMutation({ rule: formValues, spaceId, ruleId });
      }

      case "create":
        {
          try {
            await addSpaceRuleMutation({
              rule: formValues,
              spaceId,
            }).unwrap();
          } catch (error) {
            const parsedError = RTKMutationError.parse(error);
            throw new SubmissionError({ _error: parsedError.data._error });
          }
        }

        break;

      default:
        mode satisfies never;
    }
  };

  const onSubmitSuccess = () => {
    ModalInstance.close();
  };

  const onBackButtonClick = () => {
    ModalInstance.close();
  };

  return { onSubmit, onSubmitSuccess, onBackButtonClick };
};

export { useRuleForm, useRules };
