import _ from "underscore";
import uuid4 from "uuid4/browser";

import { SessionStorage } from "@js/services";
import type { RootState } from "@js/store";
import type { User, UserMinimalProfile } from "@js/types/auth";
import type { LinkMetadata } from "@js/types/common";
import type {
  HelpOffer,
  IPost,
  PostComment,
  Reaction,
  ReactionAddedDataPayload,
  ReactionDeletedDataPayload,
  StickerValue,
  ToggleReactionPayload,
} from "@js/types/give-and-get-help";
import {
  deepClone,
  formatTokenAmount,
  getQueriesByEndpoint,
  pluralize,
  sumDecimals,
  truncateBalanceToDisplay,
} from "@js/utils";

import { STICKERS_MAP } from "../../constants";
import type {
  GetMyPostsParams,
  GetPostsParams,
  GetSavedPostsParams,
  PostHashtag,
} from "../../types";

export const addReaction = (
  reactions: Reaction[],
  eventData: ReactionAddedDataPayload,
): Reaction[] => {
  const reactionsCopy = deepClone(reactions);

  const isReactionExists = reactionsCopy.some(
    (reaction) => reaction.sticker === eventData.sticker,
  );

  if (!isReactionExists) return [...reactionsCopy, newReaction(eventData)];

  const modifiedReactions: Reaction[] = reactionsCopy.map((reaction) => {
    if (reaction.sticker !== eventData.sticker) return reaction;

    if (reaction.toggled && eventData.toggled_by_current_user) return reaction;

    const toggled = eventData.toggled_by_current_user
      ? !reaction.toggled
      : reaction.toggled;

    const users = [...reaction.users, eventData.user];

    return {
      ...reaction,
      count: users.length,
      toggled,
      users,
    };
  });

  return modifiedReactions;
};

export const newReaction = (eventData: ReactionAddedDataPayload): Reaction => {
  return {
    sticker: eventData.sticker,
    count: 1,
    users: [eventData.user],
    toggled: eventData.toggled_by_current_user,
  };
};

export const deleteReaction = (
  reactions: Reaction[],
  eventData: ReactionDeletedDataPayload | undefined,
): Reaction[] => {
  if (!eventData) return reactions;

  const reactionsCopy = deepClone<Reaction[]>(reactions);

  const existingReactionIdx = reactionsCopy.findIndex(
    (reaction) => reaction.sticker === eventData.sticker,
  );

  const existingReaction = reactionsCopy[existingReactionIdx];
  if (!existingReaction) {
    return reactionsCopy;
  }

  if (existingReaction.count > 1) {
    return reactionsCopy.map((reaction) => {
      if (reaction.sticker !== eventData.sticker) return reaction;
      if (!reaction.toggled && eventData.toggled_by_current_user) {
        return reaction;
      }

      const toggled = eventData.toggled_by_current_user
        ? !existingReaction.toggled
        : existingReaction.toggled;

      return {
        ...reaction,
        count: eventData.users.length,
        users: eventData.users,
        toggled,
      };
    });
  }

  if (existingReaction.count === 1 && eventData.users.length === 0) {
    reactionsCopy.splice(existingReactionIdx, 1);
  }

  return reactionsCopy;
};

type FilterLinksOptions = {
  isAddedByButton: boolean;
};

export const filterLinks = (
  initialValues: Partial<IPost | PostComment>,
  { isAddedByButton = false }: FilterLinksOptions,
): LinkMetadata[] =>
  initialValues.links_metadata
    ? initialValues?.links_metadata?.filter((el) => {
        const link = initialValues?.links?.includes(el.url);
        return isAddedByButton ? link : !link;
      })
    : [];

export const getAllGetPostsArgs = (state: RootState) => {
  const allPostQueries = getQueriesByEndpoint(state, "getPosts");

  return allPostQueries.map((query) => query?.originalArgs as GetPostsParams);
};

export const getAllGetMyPostsArgs = (state: RootState) => {
  const allGetMyPostsQueries = getQueriesByEndpoint(state, "getMyPosts");

  return allGetMyPostsQueries.map(
    (query) => query?.originalArgs as GetMyPostsParams,
  );
};

export const getAllRelevantPostsArgs = (state: RootState) => {
  const allGetRelevantPostsQueries = getQueriesByEndpoint(
    state,
    "getRelevantPosts",
  );

  return allGetRelevantPostsQueries.map(
    (query) => query?.originalArgs as GetPostsParams,
  );
};

export const getAllSavedPostsArgs = (state: RootState) => {
  const allSavedPostQueries = getQueriesByEndpoint(state, "getSavedPosts");

  return allSavedPostQueries.map(
    (query) => query?.originalArgs as GetSavedPostsParams,
  );
};

export const getAllGetCommentsIds = (state: RootState) => {
  const allCommentQueries = getQueriesByEndpoint(state, "getPostComments");

  return allCommentQueries.map(
    (query) => (query?.originalArgs as Record<string, number>).postId,
  );
};

export const getPostPath = (
  post: Pick<IPost, "space" | "slug" | "id">,
): string => {
  return post.space
    ? `/spaces/${post.space}/post/${post.id}/${post.slug}/`
    : `/feed/${post.id}/${post.slug}/`;
};

type CreatePostIdBasedURLProps = { postId: number; spaceId?: number | null };

export const createPostIdBasedURL = ({
  postId,
  spaceId,
}: CreatePostIdBasedURLProps) => {
  return spaceId
    ? `${window.location.origin}/spaces/${spaceId}/post/${postId}/`
    : `${window.location.origin}/feed/${postId}/`;
};

type CreateURLProps = {
  post: Pick<IPost, "space" | "slug" | "id">;
  commentId?: number;
};

export const createPostOrCommentURL = ({ post, commentId }: CreateURLProps) => {
  const url = `${window.location.origin}${getPostPath(post)}`;
  if (commentId) {
    return `${url}#${commentId}`;
  }

  return url;
};

export const getLinksMetadata = (linksMetadata: LinkMetadata[]) => {
  return linksMetadata.map((metadata) => ({ ...metadata, id: uuid4() }));
};

export const checkIfCommentLastInTotal = (
  commentId: number,
  comments: PostComment[],
) => {
  const commentsIds = comments
    .flatMap((comment: PostComment) => [comment, ...(comment.comments ?? [])])
    .map((comment) => comment.id);

  return commentsIds[commentsIds.length - 1] === commentId;
};

export const getInitialBudget = (budget?: string | null) => {
  return budget ? Number(budget).toString() : undefined;
};

const mapReactionUserToText = (user: UserMinimalProfile): string =>
  user.public_name;

const getTrimmedReactionUsersText = (
  reaction: Reaction,
  authorNameToReplace = "",
) => {
  const reactionUsersNames = reaction.users.map(mapReactionUserToText);
  const firstTwoAuthors = authorNameToReplace
    ? ["You", reactionUsersNames.find((name) => name !== authorNameToReplace)]
    : reactionUsersNames.slice(0, 2);

  const authorsQuantityWithoutFirst = reaction.count - 2;
  const firstTwoAuthorsText = firstTwoAuthors.join(", ");
  const othersText = `other${pluralize(authorsQuantityWithoutFirst)}`;

  return `${firstTwoAuthorsText} and ${authorsQuantityWithoutFirst} ${othersText}`;
};

const getFullReactionUsersText = (
  reaction: Reaction,
  authorNameToReplace = "",
) => {
  const reactionUsersNames = reaction.users.map(mapReactionUserToText);
  const processedUsersNames = authorNameToReplace
    ? [
        "You",
        ...reactionUsersNames.filter((name) => name !== authorNameToReplace),
      ]
    : reactionUsersNames;

  return processedUsersNames.join(", ");
};

export const getReactionTooltipContent = (
  reaction: Reaction,
  authorNameToReplace = "",
) => {
  const reactionUsersText =
    reaction.count > 3
      ? getTrimmedReactionUsersText(reaction, authorNameToReplace)
      : getFullReactionUsersText(reaction, authorNameToReplace);

  const reactionTitle =
    STICKERS_MAP[reaction.sticker]?.title ?? reaction.sticker;
  const reactedWithText = `reacted with ${reactionTitle}`;

  return `${reactionUsersText} ${reactedWithText}`;
};
// add reaction toggle returns 'user'
export const getIsAddReactionToggle = (
  toggledReaction: ToggleReactionPayload,
): toggledReaction is ReactionAddedDataPayload => "user" in toggledReaction;

// delete reaction toggle returns 'users'
export const getIsDeleteReactionToggle = (
  toggledReaction: ToggleReactionPayload,
): toggledReaction is ReactionDeletedDataPayload => "users" in toggledReaction;

export const includesSelectedHashtag = (
  selectedHashtag: number | undefined,
  postHashtags: PostHashtag[],
): boolean => {
  return (
    !!selectedHashtag &&
    postHashtags.some(({ hashtag_id }) => hashtag_id === selectedHashtag)
  );
};

export const getAddReactionPayload = ({
  postId,
  sticker,
  currentUser,
}: {
  postId: number;
  sticker: StickerValue;
  currentUser: User;
}): ReactionAddedDataPayload => {
  const userMinimalProfile: UserMinimalProfile = _.pick(
    currentUser,
    "id",
    "avatar",
    "avatar_thumbnail",
    "first_name",
    "freelancer_approved",
    "gravatar",
    "has_avatar_set",
    "last_name",
    "profile_url",
    "public_name",
    "title",
    "boost_credit",
  );

  return {
    post_id: postId,
    toggled_by_current_user: true,
    user: userMinimalProfile,
    sticker,
  };
};

export const getDeleteReactionPayload = ({
  postId,
  sticker,
  currentUser,
  currentReaction,
}: {
  postId: number;
  sticker: StickerValue;
  currentUser: User;
  currentReaction: Reaction;
}): ReactionDeletedDataPayload => {
  return {
    post_id: postId,
    toggled_by_current_user: true, // must be true
    users: currentReaction.users.filter((user) => user.id !== currentUser.id),
    sticker,
  };
};

export const HAS_FETCHED_FEED_STORAGE_KEY = "HAS_FETCHED_FEED";

export const shouldRefreshFeedRanking = (
  getPostsArg: Pick<GetPostsParams, "page" | "ordering">,
): boolean => {
  const isFetchingFirstPage = !getPostsArg?.page || getPostsArg.page === 1;
  const isOrderingByRanked = getPostsArg?.ordering === "-ranked";
  if (!isFetchingFirstPage || !isOrderingByRanked) {
    return false;
  }

  const hasFetchedFeedOnThisTab = SessionStorage.getItem(
    HAS_FETCHED_FEED_STORAGE_KEY,
  );

  if (!hasFetchedFeedOnThisTab) {
    SessionStorage.setItem(HAS_FETCHED_FEED_STORAGE_KEY, "true");
  }

  return !!hasFetchedFeedOnThisTab;
};

export type FormatHelpOfferBudgetConfig = {
  budget: IPost["budget"];
  truncateBudget?: boolean;
  hideFreeLabel?: boolean;
};

export const formatHelpOfferBudget = ({
  budget,
  ...options
}: FormatHelpOfferBudgetConfig) => {
  const budgetNumber = Number(budget);
  if (budgetNumber === 0) {
    return options.hideFreeLabel ? "" : "Free";
  }

  const formattedCurrencyBudget = formatTokenAmount(budgetNumber);

  const truncatedBudget = options.truncateBudget
    ? truncateBalanceToDisplay(formattedCurrencyBudget as string, 5)
    : formattedCurrencyBudget;

  return truncatedBudget;
};

export const getHelpOfferTotalBudget = (
  offer: Pick<HelpOffer, "budget" | "fee">,
): number => {
  return sumDecimals(offer.budget, offer.fee);
};
