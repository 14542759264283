import { Typography } from "@hexocean/braintrust-ui-components";

import styles from "./popular-badge.module.scss";

const fireEmoji = "🔥";

export const PopularBadge = () => {
  return (
    <Typography className={styles.badge} component="span" variant="label">
      {fireEmoji} Popular
    </Typography>
  );
};
