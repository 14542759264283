import _ from "underscore";

import type { FiltersPropsConfig } from "./types";
import {
  clearObjectEmptyKeysAndEmptyArrays,
  mapAllArraysInObjectToConcatenatedString,
  parseSearchParamsToValues,
  pickValues,
  valuesToSearchParams,
} from "./utils";

export class FiltersManager<
  T extends Record<string, any>,
  K extends object = T,
> {
  config: FiltersPropsConfig<T, K>;

  constructor(config: FiltersPropsConfig<T, K>) {
    this.config = config;
  }

  getInitialValues = (searchParams: URLSearchParams): T => {
    let values = parseSearchParamsToValues(
      searchParams,
      this.config.defaultParamsValues || this.config.defaultFormValues,
    );

    if (this.config.mapParamsToValues) {
      values = this.config.mapParamsToValues(values as K);
    }

    return values as T;
  };

  transformValuesToSearchParams = (values: T): URLSearchParams => {
    let newValues = this.config.useAllURLParams
      ? values
      : pickValues(values, Object.keys(this.config.defaultFormValues));

    if (this.config.mapValuesToParams) {
      newValues = this.config.mapValuesToParams(newValues as T);
    }

    const params = valuesToSearchParams(newValues);
    return params;
  };

  shouldTriggerSubmitOnChange = (values: T, prevValues: Partial<T>) => {
    if (!this.config.submitOnChange) return false;

    const {
      submitOnChange,
      submitOnChangeFields,
      preventSubmitOnChangeFields,
    } = this.config;

    let fieldNamesToTriggerSubmit: string[];
    if (submitOnChangeFields && submitOnChangeFields?.length > 0) {
      fieldNamesToTriggerSubmit = Object.keys(
        pickValues(this.config.defaultFormValues, submitOnChangeFields),
      );
    } else {
      fieldNamesToTriggerSubmit = Object.keys(this.config.defaultFormValues);
    }

    if (
      preventSubmitOnChangeFields &&
      preventSubmitOnChangeFields?.length > 0
    ) {
      fieldNamesToTriggerSubmit = fieldNamesToTriggerSubmit.filter((value) => {
        return !preventSubmitOnChangeFields?.includes(value);
      });
    }

    const preparedValues = pickValues(values, fieldNamesToTriggerSubmit);
    const preparedPrevValues = pickValues(
      prevValues,
      fieldNamesToTriggerSubmit,
    );

    const areEqual = _.isEqual(preparedValues, preparedPrevValues);
    const triggerSubmit = submitOnChange && !areEqual;

    return triggerSubmit;
  };

  getParamsToFetchRequest = (searchParams: URLSearchParams) => {
    const val: T | K = parseSearchParamsToValues(
      searchParams,
      this.config.defaultParamsValues || this.config.defaultFormValues,
    );

    const processedValues = this.config.paramsToIgnore
      ? _.omit(val, this.config.paramsToIgnore as string[])
      : val;

    let values = clearObjectEmptyKeysAndEmptyArrays(processedValues);
    values = mapAllArraysInObjectToConcatenatedString(values);

    return values as K;
  };
}
