import { useEffect, useMemo } from "react";

import {
  ReviewBeforeSendingSummaryItem,
  type ReviewBeforeSendingSummaryItemProps,
} from "@js/apps/jobs/components";
import { useAIRInterview } from "@js/apps/jobs/hooks/use-air-interview";
import { useGetRoleName } from "@js/apps/roles/hooks";
import { sanitize } from "@js/services/dom-sanitize";
import type { JobFormValues } from "@js/types/jobs";
import {
  assertUnreachable,
  formatBudget,
  formatCurrency,
  getEnumLabel,
  pluralize,
} from "@js/utils";

import { useSkills } from "./use-skills";

type StatusesForReviewDraftsProps<
  TKeys extends keyof JobFormValues = keyof JobFormValues,
> = Pick<ReviewBeforeSendingSummaryItemProps, "isError" | "onEditClick"> & {
  values: Partial<Pick<JobFormValues, TKeys>>;
};

export const StatusForTitle = ({
  values,
  onEditClick,
}: StatusesForReviewDraftsProps<"title">) => {
  const jobTitle = values.title;

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Job title"
      sectionContent={jobTitle}
      onEditClick={onEditClick}
      isError={!jobTitle}
    />
  );
};

export const StatusForCategory = ({
  values,
  onEditClick,
}: StatusesForReviewDraftsProps<"role">) => {
  const roleValue = values.role;
  const roleName = useGetRoleName(
    typeof roleValue === "number" ? roleValue : undefined,
  );

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Category"
      sectionContent={roleName}
      onEditClick={onEditClick}
      isError={!roleName}
    />
  );
};

const getLocationAndTimezoneLabel = (
  location: string | null,
  timezone: string | null,
): string => {
  if (!location && !timezone) {
    return "no location or time zone requirements";
  } else {
    if (location && !timezone) {
      return `${location}, no time zone requirement`;
    }
    if (!location && timezone) {
      return `no location requirement, ${timezone}`;
    }
    if (location && timezone) {
      return `${location}, ${timezone}`;
    }

    // Shouldn't exist
    return "";
  }
};

export const StatusForProjectDetails = ({
  values,
  onEditClick,
}: StatusesForReviewDraftsProps<
  "timezones" | "locations" | "expected_hours_per_week" | "contract_type"
>) => {
  const { timezones, locations, expected_hours_per_week, contract_type } =
    values;
  const isError = !contract_type || !expected_hours_per_week;

  const formattedLocationValue = useMemo(() => {
    if (!locations) {
      return "";
    }
    return locations
      .map((location) => {
        return location?.location ?? "";
      })
      .join(" | ");
  }, [locations]);

  const formattedTimezoneValue = useMemo(() => {
    if (!timezones) {
      return "";
    }
    return timezones
      .map((timezone) => {
        return timezone.value;
      })
      .join(", ");
  }, [timezones]);

  const contractTypeLabel = contract_type
    ? getEnumLabel(ENUMS.JobContractTypeLabels, contract_type)
    : "";
  const expectedHoursLabel = `${expected_hours_per_week} hours`;

  const locationAndTimezoneLabel = getLocationAndTimezoneLabel(
    formattedLocationValue,
    formattedTimezoneValue,
  );

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Project details"
      sectionContent={`${contractTypeLabel}, ${expectedHoursLabel}, ${locationAndTimezoneLabel}`}
      ellipsis={false}
      onEditClick={onEditClick}
      isError={isError}
    />
  );
};

export const StatusForSkills = ({
  values,
  onEditClick,
}: StatusesForReviewDraftsProps<"new_skills" | "role" | "top_skills">) => {
  const { new_skills, role: selectedRole, top_skills: topSkills } = values;
  const newSkills = useMemo(() => new_skills ?? [], [new_skills]);
  const isError = !newSkills?.length || !topSkills?.length;
  const { skillsFromStore, fetchSkills } = useSkills(selectedRole);
  const skillsCount = newSkills?.length;

  useEffect(() => {
    if (!newSkills?.length) {
      return;
    }

    const missingSkills = newSkills.filter((id) => {
      return !skillsFromStore.some((skill) => skill.id === id);
    });

    if (missingSkills.length > 0) {
      fetchSkills({ ids: missingSkills });
    }
  }, [skillsFromStore, newSkills, fetchSkills]);

  const skillsLabels = useMemo(
    () =>
      (newSkills ?? []).map((id) => {
        return skillsFromStore.find((skill) => skill.id === id)?.name ?? "";
      }),
    [newSkills, skillsFromStore],
  );

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Skills"
      sectionContent={`${skillsLabels.join(", ")}${
        skillsCount > newSkills.length ? ` and ${skillsCount - 3} more` : ""
      }`}
      onEditClick={onEditClick}
      isError={isError}
    />
  );
};

export const StatusForExperience = ({
  onEditClick,
  values,
}: StatusesForReviewDraftsProps<"experience_level">) => {
  const experience = values.experience_level;
  const experienceLabel = experience
    ? getEnumLabel(ENUMS.JobExperienceLevelLabels, experience)
    : "None";
  const isError = !experience;

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Experience"
      sectionContent={experienceLabel}
      onEditClick={onEditClick}
      isError={isError}
    />
  );
};

export const StatusForRate = ({
  values,
  onEditClick,
}: StatusesForReviewDraftsProps<
  | "hourly_rate"
  | "min_rate"
  | "max_annual_rate"
  | "max_rate"
  | "min_annual_rate"
  | "fixed_rate"
  | "payment_type"
>) => {
  const {
    hourly_rate,
    min_rate,
    max_rate,
    fixed_rate,
    min_annual_rate,
    max_annual_rate,
    payment_type: paymentType,
  } = values;

  const checkIsMissingData = () => {
    if (!paymentType) {
      return;
    }

    switch (paymentType) {
      case ENUMS.JobPaymentType.FIXED_PRICE: {
        return !fixed_rate;
      }
      case ENUMS.JobPaymentType.HOURLY: {
        if (hourly_rate !== "range" && hourly_rate !== "ai") {
          return !hourly_rate;
        }

        return !min_rate && !max_rate;
      }
      case ENUMS.JobPaymentType.ANNUAL: {
        return !min_annual_rate && !max_annual_rate;
      }
      default: {
        assertUnreachable(paymentType);
        return false;
      }
    }
  };

  const formattedValue = () => {
    const options = { removeDecimal: true };

    const hrSuffix = "/hr";

    if (!paymentType) {
      return;
    }

    switch (paymentType) {
      case ENUMS.JobPaymentType.FIXED_PRICE: {
        return fixed_rate ? `${formatCurrency(fixed_rate, options)}` : "";
      }
      case ENUMS.JobPaymentType.HOURLY: {
        if (hourly_rate !== "range" && hourly_rate !== "ai") {
          return hourly_rate
            ? `${formatCurrency(hourly_rate, options)}${hrSuffix}`
            : "";
        }

        const rangeBudgetWithoutSigns = formatBudget(
          Math.round(Number(min_rate)),
          Math.round(Number(max_rate)),
          {
            paymentType,
            removeDecimal: true,
            hourlyRateSuffix: hrSuffix,
          },
        ).replaceAll("$", "");

        return `$${rangeBudgetWithoutSigns}`;
      }
      case ENUMS.JobPaymentType.ANNUAL: {
        if (!min_annual_rate && !max_annual_rate) {
          return undefined;
        }

        const rangeBudgetWithoutSigns = formatBudget(
          Math.round(Number(min_annual_rate)),
          Math.round(Number(max_annual_rate)),
          {
            paymentType,
            removeDecimal: true,
          },
        ).replaceAll("$", "");

        return `$${rangeBudgetWithoutSigns}`;
      }
      default: {
        assertUnreachable(paymentType);
        return "";
      }
    }
  };

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Rate"
      sectionContent={formattedValue()}
      onEditClick={onEditClick}
      isError={checkIsMissingData()}
    />
  );
};

export const StatusForJobDescription = ({
  values,
  onEditClick,
}: StatusesForReviewDraftsProps<"description" | "introduction">) => {
  const introductionContent = values.description || values.introduction || "";
  const strippedString = sanitize(introductionContent, {
    ALLOWED_TAGS: [],
  }).replace(/(\&nbsp;)/gm, " ");

  const isError = !introductionContent;

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Job description"
      sectionContent={strippedString}
      onEditClick={onEditClick}
      isError={isError}
    />
  );
};

export const StatusForApplicationQuestions = ({
  onEditClick,
  values,
}: StatusesForReviewDraftsProps<"new_application_questions">) => {
  const new_application_questions = values.new_application_questions
    ? values.new_application_questions.filter((q) => q.question)
    : [];

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Application questions"
      sectionContent={
        !!new_application_questions?.length
          ? `${
              new_application_questions?.length
            } application question${pluralize(
              new_application_questions?.length,
            )}`
          : "None"
      }
      onEditClick={onEditClick}
    />
  );
};

export const StatusForAiInterview = ({
  values,
}: {
  values: Pick<JobFormValues, "is_ai_interview_enabled">;
}) => {
  const { showAIRInterview } = useAIRInterview();
  const is_ai_interview_enabled = !!values.is_ai_interview_enabled;

  if (!showAIRInterview) {
    return null;
  }

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="AI interview"
      sectionContent={`AI interviewing is ${is_ai_interview_enabled ? "enabled" : "disabled"}`}
    />
  );
};
