import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import type { StripeCreditCardPayment } from "@js/types/payments";

import { PaymentMethodMaskedCreditCardNumber } from "../../masked-credit-card-number";

import styles from "../style.module.scss";

type StripeItemProps = {
  paymentMethod: StripeCreditCardPayment;
};

const StripeItem = ({ paymentMethod }: StripeItemProps) => (
  <>
    <div className={styles.paymentMethodsListItemDetailsItem}>
      <Typography
        component="p"
        size="small"
        fontWeight={500}
        textTransform="uppercase"
        color="grey-2"
        className={styles.paymentMethodsListItemDetailsItemName}
      >
        Card Number
      </Typography>
      <Typography component="p">
        <PaymentMethodMaskedCreditCardNumber paymentMethod={paymentMethod} />
      </Typography>
    </div>
    {paymentMethod.method.exp_month && paymentMethod.method.exp_year && (
      <div className={styles.paymentMethodsListItemDetailsItem}>
        <Typography
          component="p"
          size="small"
          fontWeight={500}
          textTransform="uppercase"
          color="grey-2"
          className={styles.paymentMethodsListItemDetailsItemName}
        >
          Expiry Date
        </Typography>
        <Typography component="p">
          {paymentMethod.method.exp_month}/{paymentMethod.method.exp_year}
        </Typography>
      </div>
    )}
  </>
);

export default StripeItem;
