import { Field } from "redux-form";

import { Box, FormHelperText } from "@hexocean/braintrust-ui-components";
import type {
  ExistingFile,
  FilesUploadFieldRenderPreviewArg,
} from "@js/apps/file-upload";
import { FilesPreviewList, FilesUploadField } from "@js/apps/file-upload";

import styles from "./styles.module.scss";

export const AttachmentsField = ({
  existingAttachments,
}: {
  existingAttachments: Array<ExistingFile> | undefined;
}) => {
  return (
    <Box className={styles.container}>
      <Field
        name="attachment_ids"
        component={FilesUploadField}
        className={styles.fileDropzone}
        uploadType={ENUMS.UploadType.INVOICE_ATTACHMENT_UPLOAD_TYPE}
        existingFiles={existingAttachments}
        renderPreview={(props: FilesUploadFieldRenderPreviewArg) => (
          <FilesPreviewList {...props} disableRename />
        )}
        options={{
          maxSize: SETTINGS.INVOICE_ATTACHMENT_FILE_SIZE_LIMIT,
        }}
      />
      <FormHelperText sx={{ mt: 2 }}>
        If your project requires an invoice approval, please include a PDF of
        the approval here. You may attach other files as well.
      </FormHelperText>
    </Box>
  );
};
