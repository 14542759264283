import { useFreelancerProfile } from "@js/apps/freelancer/hooks";
import { get12HoursClockTime } from "@js/utils";

export const useWorkAvailability = () => {
  const freelancerProfile = useFreelancerProfile();

  if (!freelancerProfile) {
    return { workAvailability: "" };
  }

  const { working_hours_start, working_hours_end, working_hours_tz_abbr } =
    freelancerProfile;

  const workingHoursStart =
    working_hours_start !== null &&
    get12HoursClockTime(working_hours_start?.toString());

  const workingHoursEnd =
    working_hours_end !== null &&
    get12HoursClockTime(working_hours_end?.toString());

  const formattedAvailability =
    (workingHoursEnd &&
      workingHoursStart &&
      `${workingHoursStart} to ${workingHoursEnd} ${working_hours_tz_abbr}`) ||
    "";

  return {
    workAvailability: !working_hours_tz_abbr
      ? undefined
      : formattedAvailability,
  };
};
