import { useEffect, useState } from "react";
import { throttle } from "underscore";

export const useGlobalScrollPosition = (): number => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = throttle(
      () => {
        const position = window.scrollY;
        setScrollPosition(position);
      },
      1000,
      { leading: false },
    );

    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return scrollPosition;
};
