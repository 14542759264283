import type { WriteReviewButtonProps } from "@js/apps/reviews/components/talent-profile-review-module/types";
import { WriteReviewBasicButton } from "@js/apps/reviews/components/talent-profile-review-module/write-review-basic-button";
import { openGGHTalentReviewForm } from "@js/apps/reviews/forms";
import { useWriteHelpOfferReviewProps } from "@js/apps/reviews/hooks/write-help-offer-review-props";

export const WriteHelpOfferReviewButton = ({
  profile,
}: WriteReviewButtonProps) => {
  const {
    offerForReview,
    onSubmit,
    onSubmitSuccess,
    isLoadingOpenReviewProcessesList,
    isFetching,
    openReviewProcessesList,
  } = useWriteHelpOfferReviewProps({ profile });

  const handleOnClick = () => {
    if (offerForReview) {
      openGGHTalentReviewForm(onSubmit, onSubmitSuccess, offerForReview);
    }
  };

  if (
    isLoadingOpenReviewProcessesList ||
    isFetching ||
    !openReviewProcessesList?.length
  )
    return null;

  return <WriteReviewBasicButton onClick={handleOnClick} />;
};
