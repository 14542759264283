import { useMemo, useState } from "react";

import { jobsApi, useGetSavedJobsQuery } from "@js/apps/jobs/api";
import { useAppDispatch } from "@js/hooks";

const PAGE_SIZE = 20;
const INITIAL_PAGE = 1;

export const useSavedJobs = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);

  const {
    data,
    isFetching: fetchingJobs,
    isLoading: loadingJobs,
    refetch,
  } = useGetSavedJobsQuery(
    { page: currentPage },
    { refetchOnMountOrArgChange: false },
  );

  const handleFetchingMore = () => {
    const currentCount = data?.results?.length ?? 0;
    const totalCount = data?.count ?? 0;

    if (!data || currentCount >= totalCount) {
      return;
    }
    const nextPage = Math.floor(data.results.length / PAGE_SIZE) + 1;
    if (nextPage === currentPage) {
      dispatch(
        jobsApi.endpoints.getSavedJobs.initiate(
          { page: nextPage },
          { forceRefetch: true, subscribe: false },
        ),
      );
    }
    setCurrentPage(nextPage);
  };

  const jobsToDisplay = useMemo(() => data?.results ?? [], [data]);

  return {
    jobsToDisplay,
    hasMore: !!data?.next,
    fetchingJobs,
    loadingJobs,
    refetchJobs: refetch,
    handleFetchingMore,
    total: data?.count || 0,
    loadingJobsToDisplay:
      loadingJobs || (!!data?.count && !jobsToDisplay.length),
  };
};
