import { useEffect } from "react";

import {
  appendRecaptchaScript,
  removeRecaptchaScriptAndBadge,
} from "@js/utils";

export const useWithRecaptcha = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "test") return;

    appendRecaptchaScript();

    return () => {
      removeRecaptchaScriptAndBadge();
    };
  }, []);
};
