import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { ModalInstance } from "@js/components/modal";

import { LIMIT_REACHED_MESSAGE } from "../../constants";

import styles from "./style.module.scss";

export const openDMLimitReachedModal = (message: string) => {
  ModalInstance.close();
  ModalInstance.open({
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "small",
      className: styles.closeButton,
    },
    children: <DMLimitReachedModal message={message} />,
    className: styles.modal,
  });
};

type Props = {
  message: string;
};

const DMLimitReachedModal = ({ message }: Props) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Box>
      <Typography
        component="h4"
        variant={isMobile ? "paragraph" : "title"}
        size={isMobile ? "large" : "small"}
        fontWeight={400}
        sx={{ maxWidth: "80%", mb: 2 }}
      >
        🚩 {LIMIT_REACHED_MESSAGE}
      </Typography>
      <Typography
        component="p"
        variant="paragraph"
        size={isMobile ? "medium" : "large"}
      >
        {message}
      </Typography>
    </Box>
  );
};
