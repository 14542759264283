import type { KeyboardEvent, MouseEvent } from "react";
import React from "react";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { AddIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import type { EmployerTeamMemberType } from "@js/types/employer";

import {
  CommonTeamMembersModalListItemContainer,
  CommonTeamMembersModalListMemberItemDetails,
} from "../common-team-members-modal";

type ShareWithTeamMemberInvitationsListMemberItemProps = {
  teamMember: EmployerTeamMemberType;
  isJobOwner: boolean;
  isSelected: boolean;
  toggleTeamMember: () => void;
};

export const ShareWithTeamMemberInvitationsListMemberItem = ({
  teamMember,
  isJobOwner,
  isSelected,
  toggleTeamMember,
}: ShareWithTeamMemberInvitationsListMemberItemProps) => {
  const user = useUser();
  const currentUserId = user?.id;
  const isTeamMemberCurrentUser = teamMember.id === currentUserId;
  const showToggleButton = !isTeamMemberCurrentUser;

  return (
    <CommonTeamMembersModalListItemContainer
      sx={{ gap: 1.5, justifyContent: "space-between" }}
      data-testid={`team-member-${teamMember.id}`}
    >
      <CommonTeamMembersModalListMemberItemDetails
        teamMember={teamMember}
        isJobOwner={isJobOwner}
      />

      {showToggleButton && (
        <ToggleShareWithTeamMemberButton
          isSelected={isSelected}
          toggleTeamMember={toggleTeamMember}
        />
      )}
    </CommonTeamMembersModalListItemContainer>
  );
};

type ToggleShareWithTeamMemberButtonProps = {
  isSelected: boolean;
  toggleTeamMember: () => void;
};

const ToggleShareWithTeamMemberButton = ({
  isSelected,
  toggleTeamMember,
}: ToggleShareWithTeamMemberButtonProps) => {
  if (!isSelected) {
    return (
      <Button
        variant="transparent-border-grey-4"
        shape="squared"
        startIcon={<AddIcon />}
        onClick={toggleTeamMember}
        size="x-small"
      >
        Share
      </Button>
    );
  }

  const handleUnsubscribeClick = (ev: MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    toggleTeamMember();
  };

  const handleUnsubscribeKeyDown = (ev: KeyboardEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    if (ev.key !== "Enter") {
      return;
    }
    toggleTeamMember();
  };

  return (
    <Typography
      component="a"
      role="button"
      href="#"
      variant="link"
      size="small"
      color="grey-1"
      tabIndex={0}
      sx={{ cursor: "pointer" }}
      onKeyDown={handleUnsubscribeKeyDown}
      onClick={handleUnsubscribeClick}
    >
      Remove
    </Typography>
  );
};
