import { paramsToObject } from "@js/apps/common/filters";
import { ReactionsFeedback } from "@js/apps/jobs/components/reactions-feedback";
import { useBidsContext } from "@js/apps/jobs/context/refetch-bids-context";
import { useSetHasReactionChanged } from "@js/apps/jobs/context/set-has-reaction-changed-context";
import type { EmployerBid } from "@js/types/jobs";

import {
  useHideProposalMutation,
  useUpdateBidReactionMutation,
} from "../../api";
import { useSelectBid } from "../../hooks/select-bid";

import type { BidReaction } from "./bid-reactions-options";

export type ReactionsFeedbackFieldProps = {
  bid: Pick<EmployerBid, "id" | "is_hidden" | "employer_reaction">;
  isDrawer?: boolean;
};

export const ReactionsFeedbackField = ({
  bid,
  isDrawer,
}: ReactionsFeedbackFieldProps) => {
  const { deselectJobBid, isSelected } = useSelectBid();
  const { refetchBidList, loadingBids } = useBidsContext();
  const { setHasReactionChanged } = useSetHasReactionChanged();
  const URLParams = paramsToObject(window.location.search);
  const [updateBidReaction] = useUpdateBidReactionMutation();
  const [hideProposal] = useHideProposalMutation();

  const deselectBid = () => {
    if (isSelected(bid.id)) {
      deselectJobBid(bid.id);
    }
  };

  const deselectBidWhenFiltering = () => {
    if ("reaction" in URLParams || URLParams.is_hidden === "true") {
      deselectBid();
    }
  };

  const afterUpdateActions = () => {
    if (!isDrawer) {
      refetchBidList?.();

      return;
    }

    setHasReactionChanged?.(true);
  };

  const hideApplication = async () => {
    return await hideProposal({ bidId: bid.id, isHidden: true }).then(() => {
      deselectBid();
      afterUpdateActions();
    });
  };

  const handleReactionClick = async (reaction: BidReaction) => {
    const shouldShowApplication = bid.is_hidden && !reaction.value;
    if (shouldShowApplication) {
      return hideProposal({ bidId: bid.id, isHidden: false }).then(() => {
        deselectBid();
        afterUpdateActions();
      });
    }

    const shouldResetReaction =
      bid.employer_reaction === reaction.value && !bid.is_hidden;
    if (shouldResetReaction) {
      return await updateBidReaction({ bidId: bid.id, reaction: null }).then(
        () => {
          deselectBidWhenFiltering();
          afterUpdateActions();
        },
      );
    }

    if (!reaction.value) {
      return hideApplication();
    }

    if (bid.is_hidden) {
      return await Promise.all([
        hideProposal({ bidId: bid.id, isHidden: false }),
        updateBidReaction({ bidId: bid.id, reaction: reaction.value }),
      ]).then(() => {
        deselectBidWhenFiltering();
        afterUpdateActions();
      });
    }

    await updateBidReaction({ bidId: bid.id, reaction: reaction.value }).then(
      () => {
        deselectBidWhenFiltering();
        afterUpdateActions();
      },
    );
  };

  return (
    <ReactionsFeedback
      disabledReactionButton={loadingBids}
      handleOnReactionClick={handleReactionClick}
      currentSelectedReaction={bid.employer_reaction}
      isHiddenBid={bid.is_hidden}
    />
  );
};
