import { useMemo } from "react";
import classNames from "classnames";

import { Carousel } from "@hexocean/braintrust-ui-components";
import {
  CLIENT_TESTIMONIALS,
  USER_TESTIMONIALS,
} from "@js/apps/onboarding/constants";
import {
  useTestimonialsSlidesVariant,
  useUserCommentsCarousel,
} from "@js/apps/onboarding/hooks/";
import { assertUnreachable } from "@js/utils";

import { ClientTestimonialSlide } from "./client-testimonial-slide";
import { UserTestimonialSlide } from "./user-testimonial-slide";

type TestimonialsCarouselProps = {
  flow: "sign-in" | "sign-up";
};

export const TestimonialsCarousel = ({ flow }: TestimonialsCarouselProps) => {
  const [initSlide, handleSlideChange] = useUserCommentsCarousel();

  const slidesVariant = useTestimonialsSlidesVariant(flow);

  const slides = useMemo(() => {
    switch (slidesVariant) {
      case "user testimonials":
        return USER_TESTIMONIALS.map(({ id, ...slide }) => (
          <UserTestimonialSlide key={id} {...slide} />
        ));

      case "client testimonials":
        return CLIENT_TESTIMONIALS.map(({ id, ...slide }) => (
          <ClientTestimonialSlide key={id} {...slide} />
        ));

      default:
        assertUnreachable(slidesVariant);
        return null;
    }
  }, [slidesVariant]);

  return (
    <div className={classNames("carousel-wrapper", flow)}>
      <Carousel
        className="buc-carousel"
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop
        grabCursor
        initialSlide={initSlide}
        onSlideChange={handleSlideChange}
        pagination={{ clickable: true }}
        slides={slides}
        spaceBetween={36}
      />
    </div>
  );
};
