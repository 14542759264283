import { matchPath, useLocation, useSearchParams } from "react-router-dom";

import { UNIVERSAL_SEARCH_PATHS } from "@js/apps/universal-search/constants";

import type { SearchResultsFilters } from "../filters/search-results-filters";

export const useUniversalSearchFiltersForm = (): {
  transformInitialValues: (
    initialValues: SearchResultsFilters,
  ) => SearchResultsFilters;
  showCounter: boolean;
} => {
  const pathname = useLocation().pathname;
  const showCounter = !!matchPath(UNIVERSAL_SEARCH_PATHS.FEED, pathname);

  const [params] = useSearchParams();

  const isOrderingApplied = !!params.get("ordering");
  const transformInitialValues = (initialValues: SearchResultsFilters) => {
    const newValues = {
      ...initialValues,
      ordering: isOrderingApplied ? initialValues.ordering : "-created",
    };

    return newValues;
  };

  return {
    transformInitialValues,
    showCounter,
  };
};
