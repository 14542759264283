import { useMemo } from "react";
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from "redux-form";

import {
  Box,
  ComboBox,
  TextField,
  Typography,
} from "@hexocean/braintrust-ui-components";

import type { TimezoneOption } from "./helpers";
import { timezoneFilters, timezoneOptions } from "./helpers";

type TimezoneFieldProps = {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  disabled?: boolean;
};

export const TimezoneField = ({
  input,
  meta,
  disabled,
}: TimezoneFieldProps) => {
  const selectedTimezone = useMemo(
    () => timezoneOptions.find(({ value }) => value === input.value) || null,
    [input.value],
  );

  const error = meta.touched && meta.error;

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        id="timezone-label-id"
        component="label"
        size="medium"
        fontWeight={500}
        mb={1}
        color={disabled ? "grey-3" : undefined}
      >
        Timezone
      </Typography>
      <ComboBox<TimezoneOption, false>
        initialTaxonomiesLoading={false}
        value={selectedTimezone}
        onChange={(_event, _selectedTimezone: TimezoneOption | null) => {
          input.onChange(_selectedTimezone?.value ?? null);
        }}
        filterOptions={timezoneFilters}
        options={timezoneOptions}
        disabled={disabled}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              placeholder="Select a timezone"
              inputProps={{
                ...params.inputProps,
                ["aria-labelledby"]: "timezone-label-id",
                ["aria-describedby"]: "timezone-description-id",
              }}
              error={!!error}
              helperText={error}
            />
          );
        }}
      />
      <Typography
        id="timezone-description-id"
        component="p"
        size="small"
        mt={0.5}
        color={error ? "negative" : disabled ? "grey-3" : "primary"}
      >
        By default, we assume you work in your location’s timezone.
      </Typography>
    </Box>
  );
};
