import React from "react";

import {
  Box,
  Button,
  Fade,
  Loader,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowCircleUpIcon,
  ArrowTopRightIcon,
  LinkedInIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { shortFileName } from "@js/apps/file-upload";
import { RouterLink } from "@js/components/link";

import {
  uploadDropzoneBackgroundColor,
  UploadLinkedinConnectionsContainer,
} from "../upload-linkedin-connections-container";

import styles from "./upload-linkedin-connections-dropzone-placeholder.module.scss";

export type UploadLinkedinConnectionsDropzonePlaceholderProps = {
  isUploading: boolean;
  uploadingFileName: string | undefined;
  isDragActive: boolean;
};

export const UploadLinkedinConnectionsDropzonePlaceholder = ({
  uploadingFileName,
  isUploading,
  isDragActive,
}: UploadLinkedinConnectionsDropzonePlaceholderProps) => {
  return (
    <UploadLinkedinConnectionsContainer
      sx={{
        p: { xs: 2, sm: 3 },
        cursor: "pointer",
        borderStyle: isDragActive ? "solid" : undefined,
        borderColor: isDragActive ? "var(--dark-violet)" : undefined,
      }}
    >
      <DropzonePlaceholderContent isDragActive={isDragActive} />
      {isUploading && (
        <DropzonePlaceholderUploading fileName={uploadingFileName} />
      )}
    </UploadLinkedinConnectionsContainer>
  );
};

const DropzonePlaceholderUploading = ({
  fileName,
}: {
  fileName: string | undefined;
}) => {
  const processedFileName = shortFileName(fileName ?? "Connections.csv", 20);
  return (
    <Fade in>
      <Box
        sx={{
          backgroundColor: uploadDropzoneBackgroundColor,
        }}
        className={styles.loaderContainer}
      >
        <Box
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
            px: 2,
          }}
        >
          <Stack sx={{ mb: "20px", alignItems: "center" }}>
            <Loader className={styles.loader} size={48} />
          </Stack>
          <Typography
            variant="label"
            size="medium"
            component="p"
            fontWeight={500}
            ellipsis
          >
            Uploading {processedFileName}...
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
};

const LINKEDIN_DASHBOARD_IMAGE_SRC = `${SETTINGS.STATIC_URL}referral/export-your-linkedin-data.svg`;
const LINKEDIN_DOWNLOAD_MY_DATA_HREF =
  "https://www.linkedin.com/mypreferences/d/download-my-data";

const DropzonePlaceholderContent = ({
  isDragActive,
}: {
  isDragActive: boolean;
}) => {
  return (
    <>
      <Box className={styles.imageWrapper}>
        <img
          src={LINKEDIN_DASHBOARD_IMAGE_SRC}
          alt="Export your linkedIn data dashboard"
          width="240"
          height="109"
          className={styles.image}
        />
        <LinkedInIcon className={styles.linkedinIcon} />
      </Box>
      <Typography component="h3" variant="label" size="large">
        {isDragActive ? "Drop" : "Upload"} your LinkedIn Connections.
      </Typography>
      <Typography
        component="h3"
        variant="paragraph"
        size="medium"
        color="grey-1"
        sx={{ mb: 1.5 }}
      >
        Go{" "}
        <Typography
          component="a"
          RouterLink={RouterLink}
          href={LINKEDIN_DOWNLOAD_MY_DATA_HREF}
          sx={{ color: "inherit" }}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(ev) => ev.stopPropagation()}
        >
          export your data from LinkedIn
          <ArrowTopRightIcon
            sx={{ fontSize: "20px", verticalAlign: "middle" }}
          />
        </Typography>{" "}
        and upload it here to get recommendations.*
      </Typography>
      <Button variant="primary" startIcon={<ArrowCircleUpIcon />}>
        Upload CSV
      </Button>
    </>
  );
};
