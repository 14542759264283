import {
  useHideProposalMutation,
  useUpdateEmployerBidFeedbackMutation,
  useUpdateEmployerBidSectionMutation,
} from "@js/apps/jobs/apps/bids/api";

export const useChangeProposalSection = () => {
  const [updateEmployerBidSection] = useUpdateEmployerBidSectionMutation();
  const [updateEmployerBidFeedback] = useUpdateEmployerBidFeedbackMutation();
  const [hideProposal] = useHideProposalMutation();

  const handleUpdateEmployerBidSection = async (
    bidId: number,
    value: EnumType<typeof ENUMS.BidStatus>,
  ) => {
    return await updateEmployerBidSection({ bidId, status: value });
  };

  const handleUpdateEmployerBidFeedback = async (
    bidId: number,
    feedback: {
      reason: EnumType<typeof ENUMS.BidNotHiringReasonType>[];
      reason_other: string | null;
    },
  ) => {
    return await updateEmployerBidFeedback({ bidId, feedback });
  };

  const handleHideProposal = async (bidId: number, isHidden: boolean) => {
    return await hideProposal({ bidId, isHidden });
  };

  return {
    handleUpdateEmployerBidSection,
    handleUpdateEmployerBidFeedback,
    handleHideProposal,
  };
};
