import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { getTruncatedText } from "@js/utils";

type ServiceDescriptionProps = {
  description?: string;
};

export const ServiceDescription = ({
  description,
}: ServiceDescriptionProps) => {
  const isMobile = useMediaQuery("sm");
  const _description = description || "";
  const MAX_DESCRIPTION_LENGTH = 17;
  const MOBILE_DESCRIPTION_LENGTH = 8;
  const truncatedServiceDescription = getTruncatedText(
    _description,
    isMobile ? MOBILE_DESCRIPTION_LENGTH : MAX_DESCRIPTION_LENGTH,
    isMobile ? MOBILE_DESCRIPTION_LENGTH : MAX_DESCRIPTION_LENGTH,
  );

  return (
    <Typography component="div" size="small" color="grey-1">
      <Typography
        component="span"
        size="small"
        color="grey-1"
        sx={{
          wordBreak: "break-word",
        }}
      >
        {truncatedServiceDescription}
      </Typography>
      {truncatedServiceDescription.length > 0 && (
        <Box component="span" px={1}>
          ·
        </Box>
      )}
      <Box
        component="span"
        sx={{ color: "var(--black)" }}
        className="pointer text-underline"
      >
        Read more
      </Box>
    </Typography>
  );
};
