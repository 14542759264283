import {
  useGetUniversalSearchJobsQuery,
  useGetUniversalSearchPostsQuery,
  useGetUniversalSearchTalentQuery,
} from "../api";
import type { UniversalTabValue } from "../constants";
import { useDebouncedUniversalSearchPhrase } from "../universal-search-slice";

export type UseUniversalSearchEmptyDataArg = {
  currentTab: UniversalTabValue;
  isFetching: boolean;
  canAccessGiveAndGetHelp: boolean;
};

export const useUniversalSearchEmptyData = ({
  currentTab,
  isFetching,
  canAccessGiveAndGetHelp,
}: UseUniversalSearchEmptyDataArg) => {
  const search = useDebouncedUniversalSearchPhrase();
  const commonSkip = isFetching;

  const { currentData: jobResults, isFetching: isFetchingJobs } =
    useGetUniversalSearchJobsQuery(
      { search },
      { skip: currentTab === "JOBS" || commonSkip },
    );
  const { currentData: postResults, isFetching: isFetchingPosts } =
    useGetUniversalSearchPostsQuery(
      { search },
      {
        skip:
          currentTab === "CAREER_HELP" ||
          commonSkip ||
          !canAccessGiveAndGetHelp,
      },
    );
  const { currentData: talentResults, isFetching: isFetchingTalents } =
    useGetUniversalSearchTalentQuery(
      { search },
      { skip: currentTab === "PEOPLE" || commonSkip },
    );

  return {
    data: { talentResults, jobResults, postResults },
    isLoading:
      isFetching || isFetchingJobs || isFetchingPosts || isFetchingTalents,
  };
};
