import { useMemo } from "react";

import { useAccountType } from "@js/apps/common/hooks";

import { useGetSimilarJobsQuery } from "../../api";

export const useSimilarJobs = (jobId: number) => {
  const filters = useMemo(() => {
    return { jobId, pageSize: 12 };
  }, [jobId]);

  const { isFreelancer } = useAccountType();

  const { data, isLoading } = useGetSimilarJobsQuery(filters, {
    // this component will not show up for non-freelancers
    // so there is no point in fetching this
    skip: !isFreelancer,
  });

  const similarJobs = data?.results;

  return {
    shouldRender: isFreelancer,
    similarJobs,
    isLoading,
  };
};
