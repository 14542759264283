export const AMPLITUDE_API_KEY = "amplitude-api-key";

export const Events = ENUMS.TrackingEvents;

export const postEventProperties = {
  slot_position: "slot_position",
  post_id: "post_id",
  post_location: "post_location",
  link_URL: "link_URL",
  post_level: "post_level",
  price_option: "price_option",
  composer_location: "composer_location",
  hashtag_id: "hashtag_id",
  report_reason: "report_reason",
  post_hashtag: "post_hashtag",
};

export const EventsProperties = {
  // sign up questionnaire
  sign_up_type: "sign_up_type",
  account_type: "account_type",
  sign_up_reasons: "sign_up_reasons",
  type_of_work: "type_of_work",
  start_time: "start_time",
  role_L1: "role_L1",
  experience: "experience",
  location: "location",
  english_proficiency: "english_proficiency",
  linked_in_url: "linked_in_url",
  website_type: "website_type",
  context: "context",
  url: "url",

  // created or edit profile
  skill: "skill",
  skills_count: "skills_count",
  skills_category: "skills_category",
  superpower_skills: "superpower_skills",
  superpower_skills_count: "superpower_skills_count",
  experience_years: "experience_years",
  character_count: "character_count",
  section_edited: "section_edited",

  // proposal
  proposal_submitted_time: "proposal_submitted_time",

  // job discovery
  filter_applied_type: "filter_applied_type",
  source: "source",
  filter_saved: "filter_saved",
  job_id: "job_id",

  // onboarding

  joining_reasons: "joining_reasons",
  interests: "interest",
  ...postEventProperties,

  // after search actions
  result_position: "result_position",
  talent_id: "talent_id",
  job_location: "job_location",
  talent_location: "talent_location",

  // search events / events after search
  search_query_id: "search_query_id",

  //connector faq
  faq_header: "faq_header",
  template_content: "template_content",

  // referrals
  sort_filter: "sort_filter",
};
export const TRACKING_PROXY_API = `${window.location.host}/api/tracking`;
export const LOG_EVENTS_INTO_CONSOLE = false;
export const SEND_TO_SERVER = true;
