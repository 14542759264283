import type { YearsOfExperience } from "@js/apps/common/components/filters";
import { createFiltersForm, FiltersManager } from "@js/apps/filters";

export const defaultExperience: YearsOfExperience = {
  experience: [],
};
export const YearsOfExperienceForm = createFiltersForm<YearsOfExperience>(
  new FiltersManager({
    useAllURLParams: true,
    defaultFormValues: defaultExperience,
  }),
);
