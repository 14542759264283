import React from "react";

import { useAccountType } from "@js/apps/common/hooks";

import { EmployerVisibility } from "./employer-visibility";
import { FreelancerVisibility } from "./freelancer-visibility";

export const VisibilitySection = () => {
  const { isFreelancer } = useAccountType();
  if (isFreelancer) {
    return <FreelancerVisibility />;
  }

  return <EmployerVisibility />;
};
