import type { AnyAction } from "redux";

import type { SavedJobFilter, SavedTalentFilter } from "@js/types/jobs";

import * as actionTypes from "./action-types";

type Location = string;
type Name = string | null;

type SavedFiltersState = {
  filtersTalents: SavedTalentFilter[];
  filtersJobs: SavedJobFilter[];
  savedFilterName: Record<Location, Name>;
  processingItems: SavedJobFilter["id"][];
};

const INITIAL_STATE: SavedFiltersState = {
  filtersTalents: [],
  filtersJobs: [],
  savedFilterName: {},
  processingItems: [],
};

export default (
  state = INITIAL_STATE,
  action: AnyAction,
): SavedFiltersState => {
  switch (action.type) {
    case actionTypes.FETCH_SAVED_FILTERS_SUCCESS_JOB:
      return { ...state, filtersJobs: action.payload };

    case actionTypes.SAVE_FILTERS_SUCCESS_JOB:
      return {
        ...state,
        filtersJobs: [...state.filtersJobs, action.payload.data],
      };

    case actionTypes.FETCH_SAVED_FILTERS_SUCCESS_TALENT:
      return { ...state, filtersTalents: action.payload };

    case actionTypes.SAVE_FILTERS_SUCCESS_TALENT:
      return {
        ...state,
        filtersTalents: [...state.filtersTalents, action.payload.data],
      };

    case actionTypes.SET_SAVED_FILTER_NAME:
      return {
        ...state,
        savedFilterName: {
          ...state.savedFilterName,
          [action.payload.location]: action.payload.name,
        },
      };
    case actionTypes.DELETE_SAVED_FILTERS:
      return {
        ...state,
        processingItems: [...state.processingItems, action.payload],
      };
    case actionTypes.DELETE_SAVED_FILTERS_SUCCESS: {
      const removedFilter = [
        ...state.filtersJobs,
        ...state.filtersTalents,
      ].find((filter) => filter.id === action.payload);

      const clearSavedFilterNames = () => {
        return Object.keys(state.savedFilterName).reduce((prev, current) => {
          return {
            ...prev,
            // if deleting filter is the one actually applied that clear it
            [current]:
              removedFilter?.name === state.savedFilterName[current]
                ? null
                : state.savedFilterName[current],
          };
        }, {});
      };

      return {
        ...state,
        filtersJobs: state.filtersJobs.filter(
          ({ id }) => id !== action.payload,
        ),
        filtersTalents: state.filtersTalents.filter(
          ({ id }) => id !== action.payload,
        ),
        savedFilterName: clearSavedFilterNames(),
        processingItems: state.processingItems.filter(
          (id) => id !== action.payload,
        ),
      };
    }
    case actionTypes.UPDATE_SAVED_FILTER: {
      const { id } = action.payload;
      return {
        ...state,
        filtersJobs: state.filtersJobs.map((filter) =>
          filter.id === id ? action.payload : filter,
        ),
      };
    }

    default:
      return state;
  }
};
