import React from "react";

import { Avatar, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { SearchListItem } from "@js/apps/universal-search/components/search-results/search-list-item";
import type { User } from "@js/types/auth";
import type { Talent } from "@js/types/freelancer";

import { DetailsContentContainer } from "./details-content-container";

const COUNTRY_ALIASES = new Map([
  ["United States of America", "United States"],
]);

type PeopleSearchListItemProps = {
  freelancer: Pick<Talent, "id" | "country"> & {
    user: Pick<
      User,
      | "has_avatar_set"
      | "avatar"
      | "avatar_thumbnail"
      | "gravatar"
      | "public_name"
      | "first_name"
      | "title"
      | "introduction_headline"
    >;
  };
  onClick?: () => void;
};

export const PeopleSearchListItem = ({
  freelancer,
  onClick,
}: PeopleSearchListItemProps) => {
  const isMobile = useMediaQuery("sm");
  const country = freelancer.country;

  const badgeComponent = <Avatar size="full" src={freelancer.user} />;
  const detailsContent = (
    <DetailsContentContainer>
      {(freelancer.user.introduction_headline || freelancer.user.title) && (
        <Typography
          component="span"
          size="small"
          variant="paragraph"
          ellipsis
          sx={{ overflow: "hidden" }}
        >
          {freelancer.user.introduction_headline || freelancer.user.title}
        </Typography>
      )}
      {!isMobile && country && (
        <Typography
          component="span"
          size="small"
          variant="paragraph"
          color="tertiary"
        >
          {COUNTRY_ALIASES.get(country) || country}
        </Typography>
      )}
    </DetailsContentContainer>
  );

  return (
    <SearchListItem
      to={`/talent/${freelancer.id}/`}
      onClick={onClick}
      title={freelancer.user.public_name}
      badgeComponent={badgeComponent}
      detailsContent={detailsContent}
    />
  );
};
