import type { ComponentPropsWithoutRef } from "react";
import type { Validator } from "redux-form";
import { Field, Fields } from "redux-form";

import { useIsNodeStaff } from "@js/apps/common/hooks";
import {
  endDateValidator,
  payRateValidator,
  startDateValidator,
} from "@js/apps/jobs/apps/create-job/validators";
import { assertUnreachable } from "@js/utils";

import { OfferErrorSection } from "../../components";
import { OFFER_FIELDS } from "../../constants";
import { useCreateOrEditOfferMeta } from "../../hooks/create-or-edit-offer-meta";
import { paymentMethodsValidator } from "../../validators";

import { BillingDetailsSection } from "./billing-details-section";
import {
  DirectHirePayDetailsSection,
  FreelancerPayDetailsSection,
  GrantPayDetailsSection,
} from "./pay-details-section";
import {
  DateSection,
  DocumentsAndEquipmentSection,
  WorkingHoursSection,
} from "./sections";

export const OfferFields = () => {
  const meta = useCreateOrEditOfferMeta();
  const isNodeStaff = useIsNodeStaff();

  if (!meta) return null;

  const hideBillingDetails =
    (!meta.isDepositRequired &&
      meta.status !== ENUMS.OfferStatus.WAITING_FOR_DEPOSIT_ACH_CONFIRMATION &&
      meta.status !== ENUMS.OfferStatus.DEPOSIT_PAYMENT_FAILED) ||
    isNodeStaff;

  switch (meta.job_type) {
    case ENUMS.JobType.FREELANCE: {
      return <FreelanceOfferFields hideBillingDetails={hideBillingDetails} />;
    }
    case ENUMS.JobType.GRANT: {
      return <GrantOfferFields hideBillingDetails={hideBillingDetails} />;
    }
    case ENUMS.JobType.DIRECT_HIRE: {
      return <DirectHireOfferFields hideBillingDetails={hideBillingDetails} />;
    }
    default: {
      assertUnreachable(meta.job_type);
      return null;
    }
  }
};

type JobTypeOfferFieldsProps = {
  hideBillingDetails: boolean;
};

const FreelanceOfferFields = ({
  hideBillingDetails,
}: JobTypeOfferFieldsProps) => {
  return (
    <>
      <Field name="" component={OfferErrorSection} />
      {!hideBillingDetails && (
        <Field
          name={OFFER_FIELDS.deposit_payment_method}
          component={BillingDetailsSection}
          validate={[paymentMethodsValidator]}
        />
      )}
      <PayDetailsFields
        names={[OFFER_FIELDS.payment_amount, OFFER_FIELDS.payment_type]}
        component={FreelancerPayDetailsSection}
      />
      <DatesField />
      <Field
        name={OFFER_FIELDS.anticipated_weekly_hours}
        component={WorkingHoursSection}
      />
      <Fields
        names={[
          OFFER_FIELDS.talent_has_own_equipment,
          OFFER_FIELDS.require_paperwork,
        ]}
        component={DocumentsAndEquipmentSection}
      />
    </>
  );
};

const DirectHireOfferFields = ({
  hideBillingDetails,
}: JobTypeOfferFieldsProps) => {
  return (
    <>
      <Field name="" component={OfferErrorSection} />
      {!hideBillingDetails && (
        <Field
          name={OFFER_FIELDS.deposit_payment_method}
          component={BillingDetailsSection}
          validate={[paymentMethodsValidator]}
        />
      )}
      <PayDetailsFields
        names={[OFFER_FIELDS.payment_amount, OFFER_FIELDS.payment_type]}
        component={DirectHirePayDetailsSection}
      />
      <DatesField />
      <Field
        name={OFFER_FIELDS.anticipated_weekly_hours}
        component={WorkingHoursSection}
      />
      <Fields
        names={[
          OFFER_FIELDS.talent_has_own_equipment,
          OFFER_FIELDS.require_paperwork,
        ]}
        component={DocumentsAndEquipmentSection}
      />
    </>
  );
};

const GrantOfferFields = ({ hideBillingDetails }: JobTypeOfferFieldsProps) => {
  return (
    <>
      <Field name="" component={OfferErrorSection} />
      {!hideBillingDetails && (
        <Field
          name={OFFER_FIELDS.deposit_payment_method}
          component={BillingDetailsSection}
          validate={[paymentMethodsValidator]}
        />
      )}
      <PayDetailsFields
        names={[OFFER_FIELDS.payment_amount, OFFER_FIELDS.payment_type]}
        component={GrantPayDetailsSection}
      />
      <DatesField />
      <Field
        name={OFFER_FIELDS.anticipated_weekly_hours}
        component={WorkingHoursSection}
      />
      <Fields
        names={[
          OFFER_FIELDS.talent_has_own_equipment,
          OFFER_FIELDS.require_paperwork,
        ]}
        component={DocumentsAndEquipmentSection}
      />
    </>
  );
};

const PAY_DETAILS_VALIDATORS: Record<string, Validator> = {
  [OFFER_FIELDS.payment_amount]: payRateValidator,
} as const;

type PayDetailsFieldsProps = {
  names: (keyof typeof OFFER_FIELDS)[];
  component: ComponentPropsWithoutRef<typeof Fields>["component"];
};

const PayDetailsFields = ({ names, component }: PayDetailsFieldsProps) => {
  const validators = names.reduce(
    (prev, current) => {
      if (PAY_DETAILS_VALIDATORS[current]) {
        prev[current] = PAY_DETAILS_VALIDATORS[current];
      }

      return prev;
    },
    {} as Record<string, Validator>,
  );

  return <Fields names={names} component={component} validate={validators} />;
};

const DatesField = () => {
  return (
    <Fields
      names={[OFFER_FIELDS.proposed_start_date, OFFER_FIELDS.proposed_end_date]}
      validate={{
        [OFFER_FIELDS.proposed_start_date]: startDateValidator,
        [OFFER_FIELDS.proposed_end_date]: endDateValidator,
      }}
      component={DateSection}
    />
  );
};
