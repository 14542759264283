import { useMemo } from "react";

import { useGetManagedJobQuery } from "@js/apps/jobs/api";
import { useJobFormSkills } from "@js/apps/skills/fields/hooks/use-job-form-skills";
import type { ManagedJob } from "@js/types/jobs";

export const useExistingJobData = (jobId: number | undefined) => {
  const { data: skills, isFetching: isFetchingSkills } = useJobFormSkills({
    skip: !jobId,
  });
  const { data: jobData, isFetching: isFetchingJobData } =
    useGetManagedJobQuery({ jobId: jobId as number }, { skip: !jobId });

  const processedJob: ManagedJob | undefined = useMemo(() => {
    if (!jobData || !skills) {
      return;
    }

    const onlyExistingTopSkills = jobData.top_skills.filter((jobTopSkill) =>
      skills.some((s) => s.id === jobTopSkill),
    );

    const onlyExistingSkills = jobData.skills.filter((jobSkill) =>
      skills.some((skill) => skill.id === jobSkill),
    );

    return {
      ...jobData,
      top_skills: onlyExistingTopSkills,
      skills: onlyExistingSkills,
    };
  }, [skills, jobData]);

  return {
    jobData: processedJob,
    isFetching: isFetchingJobData || isFetchingSkills,
  };
};
