import { useMemo } from "react";

import type { WrappedFieldProps } from "@hexocean/braintrust-ui-components";
import { Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useGetRolesQuery } from "@js/apps/roles/api";
import { ButtonSelectField } from "@js/forms/fields/button-select";
import type { OptionWithPrimitiveValue } from "@js/types/common";

type SelectYourRoleFieldProps = WrappedFieldProps;

export const SelectYourRoleField = ({
  input,
  meta,
}: SelectYourRoleFieldProps) => {
  const { data } = useGetRolesQuery();
  const options = useMemo<OptionWithPrimitiveValue<number>[]>(() => {
    return (
      data?.map((item) => ({
        label: item.name,
        value: item.id,
      })) ?? []
    );
  }, [data]);

  const isMobile = useMediaQuery("sm");
  const labelFontSize = isMobile ? "small" : "medium";
  const btnTypographySize = isMobile ? "small" : "medium";
  return (
    <>
      <Typography component="p" variant="label" size={labelFontSize} my={2}>
        Select your role*
      </Typography>
      <ButtonSelectField
        variant={meta.touched && meta.error ? "error-outlined" : "white-violet"}
        shape="squared"
        meta={meta}
        input={input}
        multiple={false}
        options={options}
        typographySize={btnTypographySize}
      />
    </>
  );
};
