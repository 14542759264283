import {
  useGetUniversalSearchJobsQuery,
  useGetUniversalSearchPostsQuery,
  useGetUniversalSearchTalentQuery,
} from "../api";
import { useDebouncedUniversalSearchPhrase } from "../universal-search-slice";

type UseUniversalSearchQueryArg = {
  options?: {
    skip?: boolean;
  };
  canAccessGiveAndGetHelp: boolean;
};

export const useUniversalSearchQuery = ({
  options,
  canAccessGiveAndGetHelp,
}: UseUniversalSearchQueryArg) => {
  const search = useDebouncedUniversalSearchPhrase();
  const { data: jobResults } = useGetUniversalSearchJobsQuery(
    { search },
    options,
  );
  const { data: postResults } = useGetUniversalSearchPostsQuery(
    { search },
    { skip: !canAccessGiveAndGetHelp || options?.skip },
  );
  const { data: talentResults } = useGetUniversalSearchTalentQuery(
    { search },
    options,
  );

  return {
    data: { talentResults, jobResults, postResults },
  };
};
