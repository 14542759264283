import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import type {
  GetEmployerReferralsFilters,
  GetEmployerReferralsParams,
} from "../../types";

import { schemaForEmployerReferralsFilters } from "./filters-schema";

export const useEmployerReferralsFilters = () => {
  const [params] = useSearchParams();
  const referralsFilters = useMemo(() => getReferralsFilters(params), [params]);
  const [filters, setFilters] = useState<GetEmployerReferralsParams>(() => {
    return { ...referralsFilters, page: 1 };
  });

  useEffect(() => {
    setFilters((prevFilters) => {
      const newFilters: GetEmployerReferralsParams = {
        ...referralsFilters,
      };

      const shouldResetPage = hasArgChanged(newFilters, prevFilters);

      return {
        ...newFilters,
        page: shouldResetPage ? 1 : prevFilters.page,
      };
    });
  }, [referralsFilters]);

  const setCurrentPage = useCallback((page: number) => {
    setFilters((prev) => ({ ...prev, page }));
  }, []);

  return {
    filters,
    setCurrentPage,
  };
};

const getReferralsFilters = (
  params: URLSearchParams,
): GetEmployerReferralsFilters => {
  const parseResult = schemaForEmployerReferralsFilters.safeParse(
    Object.fromEntries(params.entries()),
  );

  return parseResult.success ? parseResult.data : {};
};

const hasArgChanged = (
  arg: Omit<GetEmployerReferralsParams, "page">,
  prevArg: GetEmployerReferralsParams,
) => {
  return Object.entries(arg).some(
    ([key, value]) =>
      value !== prevArg[key as keyof GetEmployerReferralsParams],
  );
};
