import type { TrackableUserAction } from "@js/middlewares/analytics/types";
import { Events } from "@js/services/analytics";
import { logEvent } from "@js/services/analytics/event-logging";

export const handleUniversalSearchAnalyticsActions = (
  action: TrackableUserAction,
) => {
  switch (action.type) {
    case Events.CLICK_GLOBAL_SEARCH: {
      logEvent(Events.CLICK_GLOBAL_SEARCH);
      break;
    }
    case Events.CLICK_JOB_RESULTS_POPOVER_SEARCH: {
      logEvent(Events.CLICK_JOB_RESULTS_POPOVER_SEARCH, action.payload);
      break;
    }
    case Events.CLICK_POST_RESULTS_POPOVER_SEARCH: {
      logEvent(Events.CLICK_POST_RESULTS_POPOVER_SEARCH, action.payload);
      break;
    }
    case Events.CLICK_PEOPLE_RESULTS_POPOVER_SEARCH: {
      logEvent(Events.CLICK_PEOPLE_RESULTS_POPOVER_SEARCH, action.payload);
      break;
    }
    case Events.CLICK_VIEW_JOB_POPOVER_SEARCH: {
      logEvent(Events.CLICK_VIEW_JOB_POPOVER_SEARCH, action.payload);
      break;
    }
    case Events.CLICK_JOB_VIEW_ALL_POPOVER_SEARCH: {
      logEvent(Events.CLICK_JOB_VIEW_ALL_POPOVER_SEARCH, action.payload);
      break;
    }
    case Events.CLICK_VIEW_POST_POPOVER_SEARCH: {
      logEvent(Events.CLICK_VIEW_POST_POPOVER_SEARCH, action.payload);
      break;
    }
    case Events.CLICK_POST_VIEW_ALL_POPOVER_SEARCH: {
      logEvent(Events.CLICK_POST_VIEW_ALL_POPOVER_SEARCH, action.payload);
      break;
    }
    case Events.CLICK_TALENT_POPOVER_SEARCH: {
      logEvent(Events.CLICK_TALENT_POPOVER_SEARCH, action.payload);
      break;
    }
    case Events.CLICK_TALENT_VIEW_ALL_POPOVER_SEARCH: {
      logEvent(Events.CLICK_TALENT_VIEW_ALL_POPOVER_SEARCH, action.payload);
      break;
    }

    default: {
      break;
    }
  }
};
