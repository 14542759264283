import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightIcon,
  CloseIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import { useAirAiTopBar } from "./hooks/use-air-ai-top-bar";

import styles from "./styles.module.scss";

export const AirAiTopBar = () => {
  const { showTopBar, dismissBar, onGetStarted } = useAirAiTopBar();

  if (!showTopBar) {
    return null;
  }

  return (
    <Box className={styles.airAiBar}>
      <Box
        className={styles.airAiBarContent}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box className={styles.airAiBarIconContent}>
          <img
            src={`${SETTINGS.STATIC_URL}jobs/ai-icon.svg`}
            alt="ai job"
            width={31}
            height={31}
          />
          <Typography
            component="span"
            fontWeight={500}
            size="small"
            className={styles.airAiBarPill}
          >
            Speed up your hiring 2x with AI Interviews
          </Typography>
        </Box>
        <Typography
          component="span"
          size="small"
          className={styles.airAiBarCopy}
        >
          Enable our AI Interviewer on any open role to make fast, informed
          hiring decisions.{" "}
          <Button
            variant="no-padding"
            size="x-small"
            sx={{
              verticalAlign: "baseline",
            }}
            onClick={onGetStarted}
            endIcon={<ArrowRightIcon />}
          >
            Get started
          </Button>
        </Typography>
      </Box>
      <IconButton
        size="x-small"
        variant="primary"
        aria-label="Dismiss air ai bar"
        sx={{
          alignSelf: { xs: "start", md: "center" },
          marginTop: { xs: 1, md: 0 },
        }}
        onClick={dismissBar}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};
