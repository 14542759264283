import { useCallback, useEffect, useRef, useState } from "react";
import type { FileRejection } from "react-dropzone";

type UseUploadLinkedinConnectionsErrorArg = {
  uploadError: string | undefined;
};

const TEN_SECONDS_IN_MS = 10_000;

export const useUploadLinkedinConnectionsError = ({
  uploadError,
}: UseUploadLinkedinConnectionsErrorArg) => {
  const [fileDropError, setFileDropError] = useState<{
    errorMessage: string;
  }>();
  const [showError, setShowError] = useState(false);
  const timeoutRef = useRef<number>();

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const [rejection] = fileRejections;
    setFileDropError({ errorMessage: getErrorMessage(rejection) });
  };

  const resetFileDropError = useCallback(() => {
    setFileDropError(undefined);
  }, []);

  useEffect(() => {
    const isError = !!uploadError || !!fileDropError;
    if (!isError) {
      setShowError(false);
    } else {
      setShowError(true);
      timeoutRef.current = window.setTimeout(() => {
        setShowError(false);
      }, TEN_SECONDS_IN_MS);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [timeoutRef, fileDropError, uploadError]);

  const errorMessage = fileDropError?.errorMessage || uploadError;

  return {
    onDropRejected,
    resetFileDropError,
    errorMessage,
    showError,
  };
};

const defaultErrorMessage = "Unable to import your CSV. Please try again.";

const getErrorMessage = (rejection: FileRejection) => {
  if (rejection?.errors?.[0]?.code === "file-invalid-type") {
    return "File must be .csv";
  }

  return defaultErrorMessage;
};
