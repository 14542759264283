import { usePaidResumeHelp } from "../../hooks/get-career-help";

type GetCareerHelpFeatureFlagWrapperProps = {
  children: React.ReactNode;
};

export const GetCareerHelpFeatureFlagWrapper = ({
  children,
}: GetCareerHelpFeatureFlagWrapperProps) => {
  const { paidResumeHelp } = usePaidResumeHelp();

  if (paidResumeHelp) {
    return children;
  } else {
    return null;
  }
};
