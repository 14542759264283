import { useMemo } from "react";

import { useAccountType, useUser } from "@js/apps/common/hooks";

import { useGetMySpacesQuery } from "../../api";

export const useMySpaces = () => {
  const user = useUser();
  const { isEmployer } = useAccountType();
  const { data = [] } = useGetMySpacesQuery(undefined, {
    skip: isEmployer || !user?.is_verified,
  });

  const notificationsCount = useMemo(
    () =>
      Array.isArray(data) && data.length
        ? data.reduce((acc, space) => acc + space.notifications_count, 0)
        : 0,
    [data],
  );

  const spacesIds = useMemo(() => data.map((space) => space.id), [data]);

  return {
    notificationsCount,
    spacesIds,
  };
};
