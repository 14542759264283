import { getCurrentPathEncoded } from "@js/utils";

// Why are we using window.location.href here instead of useNavigate?
// Changing the page after updating the user's password via navigate
// leads to page re-rendering and re-fetching the data that requires authorization (which is no longer possible).

// Changing page via window.location.href does not lead to page re-rendering.
// https://git.hexocean.com/mb/braintrust/-/merge_requests/16441#note_218307
export const handleRedirectToLoginPage = () => {
  window.location.href = `/auth/login/?message=from_password_change&next=${getCurrentPathEncoded()}`;
};
