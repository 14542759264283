import type { ReactNode } from "react";
import { LazyMotion } from "framer-motion";

import type { FramerFeatureType } from "../../utils";
import { loadFramerFeature } from "../../utils";

type FramerLazyMotionWrapperProps = {
  children: ReactNode;
  featureType?: FramerFeatureType;
};
export const FramerLazyMotionWrapper = ({
  children,
  featureType = "domAnimation",
}: FramerLazyMotionWrapperProps) => {
  return (
    <LazyMotion features={loadFramerFeature(featureType)}>
      {children}
    </LazyMotion>
  );
};
