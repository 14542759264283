import type { GetMySpacesResponse, SpaceResponse } from "@js/apps/spaces/types";

export const resetMySpaceNotificationCounter = (
  draft: GetMySpacesResponse[],
  data: SpaceResponse,
) => {
  const space = draft.find((s) => s.id === data.id);
  if (space && space.notifications_count) {
    space.notifications_count = 0;
  }
};
