import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import type { TypedUseQuery } from "@reduxjs/toolkit/query/react";

import type { AppDispatch } from "@js/store";

export const mapTypedDispatchToProps = (dispatch: AppDispatch) => ({
  dispatch,
});

type QueryOptions<
  ResultType,
  BaseQuery extends BaseQueryFn,
  QueryArg,
> = Parameters<TypedUseQuery<ResultType, QueryArg, BaseQuery>>[1];

export const getUseQueryHookWithDefaultOptions =
  <ResultType, QueryArg, BaseQuery extends BaseQueryFn>(
    useQueryHook: TypedUseQuery<ResultType, QueryArg, BaseQuery>,
    defaultOptions: DistributiveOmit<
      QueryOptions<ResultType, BaseQuery, QueryArg>,
      "selectFromResult" | "skip"
    > & { refetchOnMountOrArgChange?: number | boolean },
  ): TypedUseQuery<ResultType, QueryArg, BaseQuery> =>
  (arg, options) => {
    return useQueryHook(arg, { ...defaultOptions, ...options });
  };
