import React from "react";

import { Grid, Loader } from "@hexocean/braintrust-ui-components";

import { PaymentMethodsContainer } from "../../containers/payment-methods";
import { PaymentMethodsActionBar } from "../payment-methods-action-bar";
import { PaymentMethodsList } from "../payment-methods-list";

export const PaymentMethodsSection = () => {
  return (
    <PaymentMethodsContainer
      render={(props) => {
        const {
          creatingPaymentMethod,
          addPaymentMethod,
          fetchingPaymentMethods,
          paymentMethods,
          onPaymentMethodDelete,
          onVerifyStripeACHPaymentMethod,
          deletePaymentMethod,
          setAsDefaultPaymentMethod,
          canAddPaymentMethod,
          cannotAddPaymentMethodReason,
        } = props;

        return (
          <>
            <PaymentMethodsActionBar
              canAddPaymentMethod={canAddPaymentMethod}
              cannotAddPaymentMethodReason={cannotAddPaymentMethodReason}
              creatingPaymentMethod={creatingPaymentMethod}
              title="Payment Methods"
              description="Add a bank account or credit card to pay talent on Braintrust."
              buttonLabel="Add Payment Methods"
              buttonProps={{
                variant: "secondary",
                onClick: addPaymentMethod,
              }}
            />
            <Grid item xs={12}>
              {fetchingPaymentMethods ? (
                <Loader />
              ) : (
                <PaymentMethodsList
                  paymentMethods={paymentMethods}
                  onVerifyStripeACHPaymentMethod={
                    onVerifyStripeACHPaymentMethod
                  }
                  onDelete={(paymentMethod) =>
                    onPaymentMethodDelete(deletePaymentMethod, paymentMethod)
                  }
                  onSetAsDefault={setAsDefaultPaymentMethod}
                />
              )}
            </Grid>
          </>
        );
      }}
    />
  );
};
