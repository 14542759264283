import { ButtonCore, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { TrendingHashtagIcon } from "@hexocean/braintrust-ui-components/Icons";

import type { HashtagOption } from "./trending-hashtags-config";

import styles from "./styles.module.scss";

type TrendingHashtagsButtonProps = {
  onClick: () => void;
  option: HashtagOption;
};

export const TrendingHashtagsButton = ({
  onClick,
  option,
}: TrendingHashtagsButtonProps) => {
  const { label, iconColor, iconBackgroundColor } = option;
  const isMobile = useMediaQuery("sm");

  return (
    <ButtonCore
      onClick={onClick}
      className={styles.trendingHashtagsButton}
      autoFocus={false}
    >
      <TrendingHashtagIcon
        style={{ fill: iconBackgroundColor, stroke: iconColor }}
      ></TrendingHashtagIcon>
      <Typography
        component="p"
        variant="label"
        className={styles.trendingHashtagsButtonLabel}
        size={isMobile ? "medium" : "large"}
      >
        {label}
      </Typography>
    </ButtonCore>
  );
};
