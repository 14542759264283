import type { BoxProps } from "@hexocean/braintrust-ui-components";
import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { BidAvatarWithScoreMatch } from "@js/apps/jobs/apps/bids";
import { Date } from "@js/components/date";
import { RouterLink } from "@js/components/link";
import type { EmployerBid } from "@js/types/jobs";
import { DateFormats } from "@js/utils/date/types";

import styles from "./styles.module.scss";

type TalentAvatarAndPersonalDetailsProps = {
  bid: EmployerBid;
  hideNamePrefix?: boolean;
  boldedName?: boolean;
  sx?: BoxProps["sx"];
};

export const TalentAvatarAndPersonalDetails = ({
  bid,
  hideNamePrefix,
  boldedName,
  sx,
}: TalentAvatarAndPersonalDetailsProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "16px",
        alignItems: "center",
        overflow: "hidden",
        ...sx,
      }}
    >
      <RouterLink
        to={bid.freelancer.user.profile_url}
        target="_blank"
        className={styles.avatarLink}
      >
        <BidAvatarWithScoreMatch bid={bid} />
      </RouterLink>
      <Box overflow="hidden" sx={{ wordBreak: "normal" }}>
        <Typography component="p" size="large" multipleEllipsis>
          {!hideNamePrefix && "Talent: "}
          <Typography
            component="span"
            size="large"
            variant={boldedName ? "label" : "paragraph"}
          >
            {bid.freelancer.user.public_name}
          </Typography>
        </Typography>

        <Typography component="p" size="small" color="grey-1">
          Applied on{" "}
          <Date date={bid.created} format={DateFormats["January 1, 1970"]} />
        </Typography>
      </Box>
    </Box>
  );
};
