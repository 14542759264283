import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useAppDispatch } from "@js/hooks";
import { useStorageOnOtherTabsChange } from "@js/hooks/use-storage-on-other-tabs-change";
import { LocalStorage } from "@js/services";

export const useUserInOtherTabsChanged = () => {
  const dispatch = useAppDispatch();

  useStorageOnOtherTabsChange({
    storageKey: LocalStorage.keys.USER_IN_OTHER_TABS_CHANGED_TIMESTAMP,
    callback: () => {
      dispatch(fetchCurrentUser()).catch(() => null);
    },
  });
};
