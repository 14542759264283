export const getFieldDescription = (
  boost: number,
  isEditPage: boolean | undefined,
  is_boosted: boolean | undefined,
) => {
  function incrementValue(numerator: number) {
    if (numerator === 0) {
      return "0/3";
    }

    const denominator = Math.ceil(numerator / 3) * 3;
    return `${numerator}/${denominator}`;
  }

  if (boost > 0) {
    if (isEditPage && is_boosted) return "Already boosted.";
    else return `${incrementValue(boost)} boosts available`;
  } else {
    if (isEditPage && is_boosted) return "Already boosted.";
    return "0/3 boosts available. You can purchase boosts upon completing your application and subsequently apply them.";
  }
};
