import { useUser } from "@js/apps/common/hooks/use-user";
import { NEW_EMPLOYER_INVITATION_PARAM } from "@js/apps/onboarding/hooks/new-client-signup-invitation";
import { NEW_EMPLOYER_SELF_SERVE_PARAM } from "@js/apps/onboarding/hooks/new-client-signup-self-serve";
import { isAKeyOfAnObject } from "@js/utils";

export const useUserRegistrationQueryParams = () => {
  const registrationQueryParams = useUser()?.registration_query_params;

  const checkIfIsClientSelfServe = () => {
    if (!registrationQueryParams) {
      return false;
    }

    return registrationQueryParams.some((member) => {
      if (isAKeyOfAnObject(member, "name")) {
        return member.name === NEW_EMPLOYER_SELF_SERVE_PARAM;
      }

      return false;
    });
  };

  const checkIfIsClientInvite = () => {
    if (!registrationQueryParams) {
      return false;
    }

    return registrationQueryParams.some((member) => {
      if (isAKeyOfAnObject(member, "name")) {
        return member.name === NEW_EMPLOYER_INVITATION_PARAM;
      }

      return false;
    });
  };

  return {
    isClientSelfServe: checkIfIsClientSelfServe(),
    isClientInvite: checkIfIsClientInvite(),
  };
};
