import { type FC } from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { SortableList } from "@js/components/sortable-list/motion";
import type { WorkSampleItem } from "@js/types/freelancer";

import { ProfileProjectsModuleEditListItem } from "./profile-project-module-edit-list-item";

type ProfileProjectsModuleListProps = {
  onEdit: (project?: WorkSampleItem) => void;
  onDelete: (projectId: number, projectName: string) => void;
  onAdd: () => void;
  onClose: () => void;
  onReorder: (projects: WorkSampleItem[]) => Promise<void>;
  projects?: WorkSampleItem[];
};

export const ProfileProjectsModuleList: FC<ProfileProjectsModuleListProps> = ({
  onEdit,
  onDelete,
  onAdd,
  onClose,
  onReorder,
  projects = [],
}) => {
  return (
    <Box display="flex" flexDirection="column" maxWidth="740px" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          component="h3"
          variant="title"
          size="small"
          fontWeight={400}
        >
          Projects
        </Typography>
        <IconButton
          variant="tertiary"
          size="small"
          aria-label="Close modal"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        <SortableList<WorkSampleItem>
          items={projects}
          onReorder={onReorder}
          layoutScroll
          style={{
            overflowY: "scroll",
            maxHeight: "560px",
            display: "grid",
            gap: "32px",
            marginTop: "16px",
            marginRight: "-12px", // move scroll to the side
            paddingRight: "12px", //
          }}
        >
          {(project) => (
            <ProfileProjectsModuleEditListItem
              project={project}
              onEdit={() => onEdit(project)}
              onDelete={() => onDelete(project.id, project.title)}
            />
          )}
        </SortableList>
      </Box>
      <Box>
        <Button variant="tertiary" onClick={() => onAdd()}>
          Add a project
        </Button>
      </Box>
    </Box>
  );
};
