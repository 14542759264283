import { useState } from "react";

import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useAccountType } from "@js/apps/common/hooks";
import {
  expandDetailsAfterSearch,
  jobItemHasBeenExpanded,
} from "@js/apps/jobs/actions";
import { useJobLocationContext } from "@js/apps/jobs/context/job-location-context";
import { useSearchEventQueryId } from "@js/apps/tracking/search-event-slice";
import { useAppDispatch } from "@js/hooks";
import type {
  FreelancerMainJobListingJob,
  MainJobListingJob,
  SkillForJob,
} from "@js/types/jobs";

import { getSkillForNotFreelancerView } from "../../utils";

type UseJobItemProps = {
  job: MainJobListingJob;
  searchResults?: boolean;
  resultPosition?: number;
};

export const useJobItem = ({
  job,
  searchResults,
  resultPosition,
}: UseJobItemProps) => {
  const isMobile = useMediaQuery("sm");
  const isTablet = useMediaQuery("md");
  const location = useJobLocationContext();
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState(false);
  const { isFreelancer } = useAccountType();
  const searchQueryId = useSearchEventQueryId(
    ENUMS.SearchEventOption.JOB_SEARCH_BOX,
  );

  const skillToDisplay: Array<SkillForJob> =
    isFreelancer || location === "public_job_listing"
      ? (job as FreelancerMainJobListingJob).main_skills
      : getSkillForNotFreelancerView(job.job_skills);
  const isApplied =
    isFreelancer && (job as FreelancerMainJobListingJob).has_freelancer_bid;

  const jobType = !!job.job_type ? job.job_type : ENUMS.JobType.FREELANCE;

  const handleSetExpanded = () => {
    setExpanded((p) => !p);

    const isExpanding = !expanded;
    if (!isExpanding) {
      return;
    }

    dispatch(
      jobItemHasBeenExpanded({
        context: location,
        search: window.location.search,
      }),
    );

    if (searchResults && resultPosition !== undefined) {
      dispatch(
        expandDetailsAfterSearch({
          jobId: job.id,
          location,
          resultPosition,
          searchQueryId,
        }),
      );
    }
  };

  return {
    isMobile,
    isTablet,
    jobType,
    skillToDisplay,
    expanded,
    handleSetExpanded,
    isApplied,
    location,
  };
};
