import { enumToOptions } from "@js/utils";

export const BRAINTRUST_FILTER_ID = -1;

export const SEARCH_OPS_OPTIONS = {
  phrase: "",
  endpoint: "user_search",
  immediate: true,
  keepInStore: false,
  params: { return_ops_only: true, page_size: 100 },
};

export const SEARCH_SALES_OPTIONS = {
  phrase: "",
  endpoint: "user_search",
  immediate: true,
  keepInStore: false,
  params: { return_sales_only: true, page_size: 100 },
};

export const ACCOUNT_STATUS_OPTIONS = [
  { value: "", label: "Any" },
  { value: "true", label: "Approved" },
  { value: "false", label: "Not approved" },
] as const;

export const ACCOUNT_TYPES_OPTIONS = [
  { value: "", label: "Any" },
  ...enumToOptions(ENUMS.AccountTypeLabels),
] as const;
