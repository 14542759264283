import React from "react";
import type { WrappedFieldProps } from "redux-form";

import type { BasicInputProps } from "@hexocean/braintrust-ui-components";
import { BasicInput } from "@hexocean/braintrust-ui-components";
import { shouldDisplayError } from "@js/forms/utils";

type BasicInputFieldProps = Omit<BasicInputProps, "errorMessage"> & {
  hideError?: boolean;
};

const ReduxFormBasicInputFieldFactory =
  <T extends BasicInputFieldProps>(
    Component: React.ElementType<BasicInputProps>,
  ) =>
  ({ input, meta, hideError, ...props }: T & WrappedFieldProps) => {
    return (
      <Component
        {...props}
        {...input}
        onChange={(e) => {
          if (props.onChange) {
            props?.onChange(e);
          }
          input.onChange(e);
        }}
        onDrop={
          props.disabled
            ? () => {
                return null;
              }
            : input.onDrop
        }
        errorMessage={
          !hideError && shouldDisplayError({ input, meta }) && meta.error
        }
      />
    );
  };

export const BasicInputField =
  ReduxFormBasicInputFieldFactory<BasicInputFieldProps>(BasicInput);
