import type { RoleOption } from "@js/apps/roles/hooks";
import { prepareDOMInput } from "@js/services";
import type { Skill } from "@js/types/admin";
import type {
  AIGeneratedJob,
  ManagedJob,
  NewApplicationQuestion,
} from "@js/types/jobs";
import { formatAmountString } from "@js/utils";

export type AIGeneratedJobFormData = Partial<
  Omit<ManagedJob, "application_questions">
> & {
  new_application_questions: NewApplicationQuestion[];
};

type FormatAIGeneratedJobToFormDataProps = {
  data: AIGeneratedJob;
  roles: RoleOption[];
  skills: Skill[];
};

export const formatAIGeneratedJobToFormData = ({
  data,
  roles,
  skills,
}: FormatAIGeneratedJobToFormDataProps): AIGeneratedJobFormData => {
  const topSkills =
    skills
      .filter((skill) =>
        data.job_top_skills?.includes(skill?.name?.toLocaleLowerCase()),
      )
      .map((skill) => skill.id) || [];

  return {
    title: data.job_title || undefined,
    job_type: data.job_type,
    openings_number: data.number_of_openings || 1,
    role: roles.find((e) => e.label.toLocaleLowerCase() === data?.job_category)
      ?.value,
    description: prepareDOMInput(data.detailed_description || ""),
    contract_type: data.contract_length ?? undefined,
    expected_hours_per_week: data.expected_hours_per_week,
    experience_level: data.experience_level,
    skills: [
      ...new Set(
        topSkills.concat(
          skills
            ?.filter((skill) =>
              data.job_skills?.includes(skill?.name?.toLocaleLowerCase()),
            )
            .map((skill) => skill.id) || [],
        ),
      ),
    ],
    top_skills: topSkills.slice(0, 3),
    budget_maximum_usd: formatAmountString(data.budget_maximum_usd || ""),
    budget_minimum_usd: formatAmountString(data.budget_minimum_usd || ""),
    ai_max_rate: formatAmountString(data.budget_maximum_usd || ""),
    ai_min_rate: formatAmountString(data.budget_minimum_usd || ""),
    payment_type: data.job_payment_type,
    is_resume_required: !!data.is_resume_required,
    new_application_questions:
      data.application_questions?.map((question) => ({
        question,
      })) || [],
    is_ai_generated: true,
    flow_entry: "ai",
  };
};
