import React from "react";

export const JOB_LOCATION = {
  employer_profile: "employer_profile",
  employer_own_jobs: "employer_own_jobs",
  talent_home: "talent_home",
  saved_jobs: "saved_jobs",
  job_listing: "job_listing",
  jobs_landing_page: "jobs_landing_page",
  job_search: "job_search",
  legacy_job_search: "legacy_job_search",
  job_details: "job_details",
  public_job_details: "public_job_details",
  recommended_jobs_modal: "recommended_jobs_modal",
  talent_invitations: "talent_invitations",
  public_job_listing: "public_job_listing",
} as const;

export type JobLocationKey = EnumType<typeof JOB_LOCATION>;

const JobLocationContextInstance = React.createContext<EnumType<
  typeof JOB_LOCATION
> | null>(null);

export const JobLocationContext = {
  Values: JOB_LOCATION,
  Provider: JobLocationContextInstance.Provider,
  Consumer: JobLocationContextInstance.Consumer,
};

export const useJobLocationContext = () => {
  const location = React.useContext(JobLocationContextInstance);
  if (!location) {
    throw new Error("JobLocationContext is missing");
  }

  return location;
};
