import React from "react";

import {
  Box,
  ButtonCore,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";

import {
  PostLocation,
  usePostLocationContext,
} from "../../context/post-location";

type PostsListEndMessageProps = {
  onBackToTopClick: () => void;
};

export const PostsListEndMessage = ({
  onBackToTopClick,
}: PostsListEndMessageProps) => {
  const isTablet = useMediaQuery("md");
  const postsLocation = usePostLocationContext();

  if (postsLocation === PostLocation.Values.my_posts) {
    return null;
  }

  return (
    <Box
      mt={3}
      display="flex"
      justifyContent="center"
      flexDirection={isTablet ? "column" : "row"}
      gap={isTablet ? "8px" : undefined}
    >
      <Typography component="p" textAlign={isTablet ? "center" : undefined}>
        You've reached the end of the feed. Thanks for stopping by!
      </Typography>
      <Box
        component={ButtonCore}
        sx={{ textDecoration: "underline" }}
        ml={1}
        fontWeight={400}
        fontSize={16}
        onClick={() => onBackToTopClick()}
      >
        Back to the top
      </Box>
    </Box>
  );
};
