import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { getWorkTimeLabel } from "@js/apps/jobs/utils";

export const renderValueLabelFormatForHoursPerWeekField = (
  value: number,
  isMobile: boolean,
) => {
  if (isMobile) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <div>{value} hours per week</div> ⏰ {getWorkTimeLabel(value)}
      </Box>
    );
  }
  return (
    <>
      ⏰ {value} hours per week | {getWorkTimeLabel(value)}
    </>
  );
};
