import { useMemo } from "react";

import { useAppSelector } from "@js/hooks";

import {
  canBeInterviewed,
  getBids,
} from "../../components/bulk-select-actions/bid-bulk-selection-slice";

export const useSelectedInterviewableIds = () => {
  const bids = useAppSelector(getBids);
  const selectedInterviewableIds = useMemo(
    () =>
      bids
        .filter((bid) => canBeInterviewed(bid))
        .map((selected) => selected.id),
    [bids],
  );

  return { selectedInterviewableIds };
};
