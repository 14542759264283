import React from "react";

import {
  Box,
  ButtonCore,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  BudgetLabel,
  CategoryBudgetBadgeContainer,
} from "@js/apps/give-and-get-help/components";
import type { FreelancerPublic } from "@js/types/freelancer";
import type { HelpService } from "@js/types/give-and-get-help";
import { matchDarkColorToLighterBackgroundColor } from "@js/utils";

import { ServiceCategoryIcon } from "../../ggh-services/service-card";
import { openReadMoreModal } from "../../read-more-modal/index";

import { ServiceDescription } from "./service-description";

type ServiceItemProps = {
  service: HelpService;
  profile: FreelancerPublic;
};

export const ServiceItem = ({ service, profile }: ServiceItemProps) => {
  const isTablet = useMediaQuery("lg");
  const isMobile = useMediaQuery("sm");

  const bgColor = matchDarkColorToLighterBackgroundColor(
    service.category.color,
  );

  const formattedCategoryName = (() => {
    const categoryNameLength = service.category.name.length;
    const TABLET_MAX_LENGTH = 21;
    const MOBILE_MAX_LENGTH = 15;

    if (!isTablet && categoryNameLength > TABLET_MAX_LENGTH) {
      return service.category.name.substring(0, TABLET_MAX_LENGTH) + "...";
    }

    if (isMobile && categoryNameLength > MOBILE_MAX_LENGTH) {
      return service.category.name.substring(0, MOBILE_MAX_LENGTH) + "...";
    }

    return service.category.name;
  })();

  const handleOpenReadMoreModal = () =>
    openReadMoreModal({
      profile,
      title: service.category.name,
      description: service.description,
      budget: service.budget,
      category: service.category,
    });

  return (
    <Box
      className="service-item"
      component={ButtonCore}
      onClick={handleOpenReadMoreModal}
      bgcolor={`var(${bgColor})`}
    >
      <ServiceCategoryIcon isProfileModuleVariant category={service.category} />
      <div>
        <Box textAlign="left">
          <Typography component="h2" size="medium" fontWeight={500}>
            {formattedCategoryName}
          </Typography>
          <ServiceDescription description={service.description} />
        </Box>

        <CategoryBudgetBadgeContainer
          categoryColor={service.category.color}
          className="service-budget"
        >
          <BudgetLabel
            budget={service.budget}
            size={isMobile ? "small" : "medium"}
            truncateBudget={!isTablet || isMobile}
          />
        </CategoryBudgetBadgeContainer>
      </div>
    </Box>
  );
};
