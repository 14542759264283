import { useMemo } from "react";

import { useGetPostCommentsQuery } from "@js/apps/give-and-get-help/api";
import { usePostsContext } from "@js/apps/give-and-get-help/context/posts";
import { useDisableSpecificPostActions } from "@js/apps/give-and-get-help/hooks/use-disable-specific-post-actions";
import { useAddedLvl1CommentsByUser } from "@js/apps/give-and-get-help/posts-comments-slice";
import { getIsCommentExpanded } from "@js/apps/give-and-get-help/utils/comments";
import type { IPost, PostComment } from "@js/types/give-and-get-help";

export const useCommentsSection = ({
  post,
  isPublic,
  isSinglePostView,
}: {
  post: IPost;
  isPublic: boolean | undefined;
  isSinglePostView: boolean | undefined;
}) => {
  const { expandedPostsOrCommentsIds, collapsedComments, onPostExpand } =
    usePostsContext();

  const postId = post.id;
  const postCommentsExpanded = useMemo(() => {
    return expandedPostsOrCommentsIds.some(
      (expandedId) => expandedId === postId,
    );
  }, [expandedPostsOrCommentsIds, postId]);

  const shouldUsePreloadedDataOnly = isSinglePostView || isPublic;
  const { data, isFetching } = useGetPostCommentsQuery(
    { postId: post.id },
    { skip: shouldUsePreloadedDataOnly || !postCommentsExpanded },
  );
  const addedCommentsByCurrentUser = useAddedLvl1CommentsByUser({
    postId: post.id,
  });
  const disablePostActions = useDisableSpecificPostActions();
  const shouldDisplayReplyForm = !disablePostActions && !isPublic;

  const preloadedComments = post.comments;
  const comments = useMemo(() => {
    const shouldUsePreloadedComments =
      shouldUsePreloadedDataOnly || !postCommentsExpanded || !data?.comments;

    const commentsToDisplay = shouldUsePreloadedComments
      ? preloadedComments
      : data.comments;

    return sortCommentsToDisplay(commentsToDisplay, addedCommentsByCurrentUser);
  }, [
    data?.comments,
    shouldUsePreloadedDataOnly,
    preloadedComments,
    postCommentsExpanded,
    addedCommentsByCurrentUser,
  ]);

  const toggleReplies = () => {
    onPostExpand(postId);
  };

  const totalCommentsCount = post.comments_count;

  const preloadedCommentsCount = useMemo(() => {
    return preloadedComments.reduce((accumulatedCount, preloadedComment) => {
      const isExpanded = expandedPostsOrCommentsIds.some(
        (expandedId) => expandedId === preloadedComment.id,
      );
      if (isExpanded) {
        return accumulatedCount;
      }

      const preloadedCommentRepliesCount =
        preloadedComment.comments?.length ?? 0;

      return accumulatedCount + preloadedCommentRepliesCount;
    }, preloadedComments.length);
  }, [preloadedComments, expandedPostsOrCommentsIds]);

  const visibleExpandedCommentsCount = useMemo(
    () =>
      getVisibleExpandedCommentsCount({
        comments,
        collapsedComments,
        postCommentsExpanded,
        expandedPostsOrCommentsIds,
      }),
    [
      comments,
      collapsedComments,
      postCommentsExpanded,
      expandedPostsOrCommentsIds,
    ],
  );

  const areAllCommentsVisible = useMemo(() => {
    const totalVisibleComments =
      preloadedCommentsCount + visibleExpandedCommentsCount;

    return totalVisibleComments >= post.comments_count;
  }, [
    post.comments_count,
    visibleExpandedCommentsCount,
    preloadedCommentsCount,
  ]);

  const contextValue = useMemo(
    () => ({
      comments: comments || [],
      totalCommentsCount,
    }),
    [comments, totalCommentsCount],
  );

  return {
    shouldDisplayReplyForm,
    totalCommentsCount,
    isPublic,
    comments,
    isFetching,
    contextValue,
    areAllCommentsVisible,
    preloadedCommentsCount,
    postCommentsExpanded,
    toggleReplies,
  };
};

const sortCommentsToDisplay = (
  comments: PostComment[],
  addedComments: PostComment["id"][],
) => {
  if (!addedComments.length) {
    return comments;
  }

  const commentsCopy = [...comments];

  return commentsCopy.sort((aComment, bComment) => {
    const aCommentAddedIndex = getCommentIndex(aComment.id, addedComments);
    const bCommentAddedIndex = getCommentIndex(bComment.id, addedComments);

    return aCommentAddedIndex - bCommentAddedIndex;
  });
};

const getCommentIndex = (
  commentId: number,
  addedComments: PostComment["id"][],
) => {
  const commentIndex = addedComments.indexOf(commentId);
  if (commentIndex === -1) {
    return 100; // put not added comments at the end
  }

  return commentIndex;
};

const getVisibleExpandedCommentsCount = ({
  comments,
  collapsedComments,
  expandedPostsOrCommentsIds,
  postCommentsExpanded,
}: {
  comments: PostComment[];
  expandedPostsOrCommentsIds: number[];
  collapsedComments: Array<{ id: number }>;
  postCommentsExpanded: boolean;
}) => {
  const expandedComments = comments.filter((comment) =>
    getIsCommentExpanded({
      comment,
      collapsedComments,
      expandedPostsOrCommentsIds,
      postCommentsExpanded,
    }),
  );

  return expandedComments.reduce((acc, comment) => {
    return acc + (comment.comments_count ?? 0);
  }, 0);
};
