import React from "react";

import styles from "./style.module.scss";

type OfferPreviewContentLayoutProps = {
  leftSocket: React.ReactNode;
  rightSocket: React.ReactNode;
};

export const OfferPreviewContentLayout = ({
  leftSocket,
  rightSocket,
}: OfferPreviewContentLayoutProps) => {
  return (
    <div className={styles.previewOffer}>
      <div className={styles.previewOfferLeftCol}>{leftSocket}</div>
      <div className={styles.previewOfferRightCol}>{rightSocket}</div>
    </div>
  );
};
