import { useCallback, useEffect } from "react";

import {
  hideBookmarksIntro,
  showBookmarksIntro,
} from "@js/apps/bookmarks/actions";
import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { BookmarksIntroModal } from "../bookmarks-intro-modal";
import { useBookmarksIntroModalPosition } from "../bookmarks-intro-modal/use-bookmark-intro-modal-position";
import type { BookmarksIntroduceProps } from "../bookmarks-introduce";

type UseBookmarksIntroduceProps = Pick<BookmarksIntroduceProps, "anchorRef">;
export const useBookmarksIntroduce = ({
  anchorRef,
}: UseBookmarksIntroduceProps) => {
  const dispatch = useAppDispatch();
  const { modalPosition } = useBookmarksIntroModalPosition(anchorRef);
  const bookmarkAdded = useAppSelector(
    (state) => state.bookmarks.bookmarkAdded,
  );
  const {
    data: hasBookmarksIntroduced,
    isLoading: isLoadingBookmarksIntroduced,
  } = useGetStorageValueQuery(
    { key: FRONTEND_STORAGE_KEYS.is_bookmarks_introduced },
    { skip: !bookmarkAdded },
  );
  const [setStorageValue] = useSetStorageValueMutation();

  const handleClose = useCallback(() => {
    dispatch(hideBookmarksIntro());
    setStorageValue({
      key: FRONTEND_STORAGE_KEYS.is_bookmarks_introduced,
      value: true,
    });
  }, [dispatch, setStorageValue]);

  useEffect(() => {
    if (isLoadingBookmarksIntroduced) {
      return;
    }

    if (!hasBookmarksIntroduced && bookmarkAdded) {
      dispatch(showBookmarksIntro());
      BookmarksIntroModal.open();
    }
  }, [
    bookmarkAdded,
    hasBookmarksIntroduced,
    isLoadingBookmarksIntroduced,
    dispatch,
  ]);

  return {
    hasBookmarksIntroduced,
    bookmarkAdded,
    handleClose,
    modalPosition,
  };
};
