import { useMemo } from "react";

import { useAppDispatch } from "@js/hooks";

import { jobsApi } from "../../api";
import { bidApi } from "../../apps/bids/api";
import type { AfterActionArg } from "../../components/job-actions";

export const useGetDefaultActionsContextValue = () => {
  const dispatch = useAppDispatch();

  return useMemo(() => {
    return {
      afterAction: (arg: AfterActionArg) => {
        if (!arg?.job) {
          return;
        }

        dispatch(
          jobsApi.util.invalidateTags([{ type: "Jobs", id: arg.job.id }]),
        );
        dispatch(bidApi.util.invalidateTags(["EmployerBids"]));
      },
    };
  }, [dispatch]);
};
