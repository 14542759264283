import { Box } from "@hexocean/braintrust-ui-components";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useCompletionSequenceContext } from "@js/apps/freelancer/components/profile-completion-sequence/context/completion-sequence-context";
import { SkillsEditModule } from "@js/apps/freelancer/forms/skills-edit-module";
import { useFreelancerProfile } from "@js/apps/freelancer/hooks";
import { useAppDispatch } from "@js/hooks";

import { ProfileCompletionSequenceCloseBtn } from "./close-btn";

export const ProfileCompletionSequenceSkillsStep = () => {
  const profile = useFreelancerProfile();
  const dispatch = useAppDispatch();
  const { handleStepCompleted } = useCompletionSequenceContext();

  const onSubmitSuccess = () => {
    if (!profile) return;
    dispatch(fetchCurrentUser());
    handleStepCompleted();
  };

  return (
    <Box sx={{ padding: 4 }}>
      <ProfileCompletionSequenceCloseBtn />
      <SkillsEditModule
        saveButtonText="Save and continue"
        onSubmitSuccess={onSubmitSuccess}
      />
    </Box>
  );
};
