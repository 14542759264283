import { assertUnreachable } from "@js/utils";

import type {
  GetLocationRequirementDescription,
  GetTimezoneRequirementDescription,
} from "./types";

export const getLocationRequirementDescription: GetLocationRequirementDescription =
  (locations) => {
    if (!locations?.length) {
      return "";
    }

    if (locations.length === 1) {
      return `You must be located in ${locations[0].location}.`;
    }

    const lastLocation = locations[locations.length - 1]?.location;
    return `You must be located in ${locations
      .map(({ location }) => location)
      .slice(0, -1)
      .join(" | ")
      .concat(` or ${lastLocation}`)}.`;
  };

export const getTimezoneRequirementDescription: GetTimezoneRequirementDescription =
  (timezones, overlap) => {
    if (!timezones?.length || !overlap) {
      return "";
    }

    const combinedTimezone = timezones
      .map(({ timezone }) => timezone)
      .join("/");

    switch (overlap) {
      case ENUMS.JobTimezoneOverlapType.FULL: {
        return `You must be able to overlap a full work day within the ${combinedTimezone} timezone.`;
      }
      case ENUMS.JobTimezoneOverlapType.PARTIAL: {
        return `You must be able to overlap at least 3-4 working hours within the ${combinedTimezone} timezone.`;
      }
      default: {
        return assertUnreachable(overlap) as never;
      }
    }
  };
