import { Tooltip } from "@hexocean/braintrust-ui-components";
import type { EmployerBidListBid } from "@js/types/jobs";

type CannotMakeOfferProps = {
  bid: EmployerBidListBid;
  children: JSX.Element;
};

export const CannotMakeOfferExplanation = ({
  bid,
  children,
}: CannotMakeOfferProps) => {
  return (
    <Tooltip
      title={<>{bid.can_make_offer_explanation}</>}
      disabled={bid.can_make_offer}
    >
      <span style={{ display: "grid" }}>{children}</span>
    </Tooltip>
  );
};
