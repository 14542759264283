import React from "react";

import { Avatar } from "@hexocean/braintrust-ui-components";
import type { FreelancerPublic } from "@js/types/freelancer";

import { useProfnetProfilePageAvatar } from "./hook";
import { ProfnetProfilePageAvatarOwnerComponent } from "./owner-component";
import { ProfnetProfilePageAvatarPublicComponent } from "./public-component";

type ProfnetProfilePageAvatarProps = {
  profile: FreelancerPublic;
};

export const ProfnetProfilePageAvatar: React.FC<
  ProfnetProfilePageAvatarProps
> = ({ profile }) => {
  const {
    isProfileOwner,
    customInputRef,
    tooltipTitle,
    isAvailableForWork,
    handleAvailableForWorkStatusClick,
    handleAvatarEditClick,
    handleAvatarFormSubmit,
    showAvailableForWorkCoachMark,
    showWorkingHoursCoachMark,
    handleAvailableForWorkCoachMarkClose,
    handleWorkingHoursCoachMarkClose,
  } = useProfnetProfilePageAvatar({ profile });

  if (isProfileOwner) {
    return (
      <ProfnetProfilePageAvatarOwnerComponent
        showWorkingHoursCoachMark={showWorkingHoursCoachMark}
        showAvailableForWorkCoachMark={showAvailableForWorkCoachMark}
        handleWorkingHoursCoachMarkClose={handleWorkingHoursCoachMarkClose}
        handleAvailableForWorkCoachMarkClose={
          handleAvailableForWorkCoachMarkClose
        }
        onAvatarFormSubmit={handleAvatarFormSubmit}
        onAvatarEditClick={handleAvatarEditClick}
        onAvailableForWorkStatusClick={handleAvailableForWorkStatusClick}
        customInputRef={customInputRef}
        isAvailableForWork={isAvailableForWork}
        tooltipTitle={tooltipTitle}
      />
    );
  }

  return (
    <ProfnetProfilePageAvatarPublicComponent
      avatarContent={<Avatar src={profile.user} size="xxl" />}
      isApproved={profile.approved}
      tooltipTitle={tooltipTitle}
      isAvailableForWork={isAvailableForWork}
      onAvailableForWorkStatusClick={handleAvailableForWorkStatusClick}
    />
  );
};
