import type { FormErrors } from "redux-form";
import { getFormSubmitErrors } from "redux-form";
import { z } from "zod";

import { useAppSelector } from "@js/hooks";

import type { InvoiceFormData } from "../../forms/add-invoice/types";

export const useInvoiceSubmitErrors = (form: string) => {
  const submitErrors: FormErrors<InvoiceFormData> = useAppSelector((state) =>
    getFormSubmitErrors(form)(state),
  );

  const getItemTaxError = (index: number, key: "tax_rate" | "tax_name") => {
    const newItemsErrors = submitErrors.new_items;
    const parsedItemsErrors = itemTaxErrorsSchema.safeParse(newItemsErrors);
    if (!parsedItemsErrors.success) {
      return null;
    }
    const currentItemErrors = parsedItemsErrors.data[index];
    if (!currentItemErrors) {
      return null;
    }

    return currentItemErrors[key] ?? null;
  };

  const getTaxNameError = (index: number): string[] | null => {
    return getItemTaxError(index, "tax_name");
  };

  const getTaxRateError = (index: number): string[] | null => {
    return getItemTaxError(index, "tax_rate");
  };

  return {
    getTaxNameError,
    getTaxRateError,
  };
};

const itemTaxErrorsSchema = z.array(
  z.object({
    tax_rate: z.array(z.string()).optional(),
    tax_name: z.array(z.string()).optional(),
  }),
);
