import { random } from "underscore";

import { useClickStarAnimation } from "./hooks";

import styles from "./style.module.scss";

type AnimatedStarsProps = {
  children: JSX.Element;
  prevent?: boolean;
  colors: string[];
  starCount?: number;
  maxRadius?: number;
  minRadius?: number;
  starSize?: number;
};

export const AnimatedStarsClick = ({
  children,
  prevent = false,
  colors,
  starCount = 13,
  maxRadius = 30,
  minRadius = 18,
  starSize = 6,
}: AnimatedStarsProps): JSX.Element => {
  const sparkleClass: string = styles.sparkle;

  const scope = useClickStarAnimation(
    prevent,
    starCount,
    minRadius,
    maxRadius,
    sparkleClass,
  );

  return (
    <div ref={scope} className={styles.effectContainer}>
      {children}
      <span aria-hidden className={styles.sparkleContainer}>
        {Array.from({ length: starCount }).map((_, index) => {
          const key: number = index;
          return (
            <svg
              className={`${sparkleClass}-${index} ${sparkleClass}`}
              style={{
                top: `calc(50% - ${starSize / 2}px)`,
                left: `calc(50% - ${starSize / 2}px)`,
              }}
              key={key}
              viewBox="0 0 122 117"
              width={starSize}
              height={starSize}
            >
              <path
                fill={colors[random(0, colors.length - 1)]}
                d="M64.39,2,80.11,38.76,120,42.33a3.2,3.2,0,0,1,1.83,5.59h0L91.64,74.25l8.92,39a3.2,3.2,0,0,1-4.87,3.4L61.44,96.19,27.09,116.73a3.2,3.2,0,0,1-4.76-3.46h0l8.92-39L1.09,47.92A3.2,3.2,0,0,1,3,42.32l39.74-3.56L58.49,2a3.2,3.2,0,0,1,5.9,0Z"
              />
            </svg>
          );
        })}
      </span>
    </div>
  );
};
