import { IconButton } from "@hexocean/braintrust-ui-components";
import { CloseSVGIcon } from "@hexocean/braintrust-ui-components/Icons";
import { ModalInstance } from "@js/components/modal";

export const ProfileCompletionConfirmationCloseBtn = () => {
  return (
    <IconButton
      onClick={ModalInstance.close}
      variant="gray-violet"
      aria-label="close modal"
      size="small"
      sx={{
        position: "absolute",
        top: { xs: "14px", sm: "19px" },
        right: { xs: "14px", sm: "19px" },
      }}
    >
      <CloseSVGIcon />
    </IconButton>
  );
};
