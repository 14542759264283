import React, { useMemo } from "react";

import { Loader } from "@hexocean/braintrust-ui-components";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useUser } from "@js/apps/common/hooks";
import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import {
  fetchFreelancerProfile,
  updateFreelancerProfile,
} from "@js/apps/freelancer/actions";
import { useFreelancerProfile } from "@js/apps/freelancer/hooks";
import { useAppDispatch } from "@js/hooks";

import type { VisibilityFormValues } from "../../forms/visibility";
import { VisibilityForm } from "../../forms/visibility";

export const FreelancerVisibility = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const freelancerProfile = useFreelancerProfile();

  useEffectOnce(() => {
    if (user?.freelancer) {
      dispatch(fetchFreelancerProfile(user.freelancer));
    }
  });

  const onSubmit = async (values: VisibilityFormValues) => {
    if (!freelancerProfile) {
      return;
    }
    return dispatch(
      updateFreelancerProfile({
        freelancerId: freelancerProfile.id,
        updatedValues: values,
      }),
    ).then(() => {
      dispatch(fetchCurrentUser());
    });
  };

  const initialValues = useMemo(() => {
    return {
      show_number_of_jobs: freelancerProfile?.show_number_of_jobs,
      profile_visibility: freelancerProfile?.profile_visibility,
      profile_visits_count_visibility:
        freelancerProfile?.profile_visits_count_visibility,
      hide_token_balance: freelancerProfile?.hide_token_balance,
    };
  }, [freelancerProfile]);

  if (!freelancerProfile) {
    return <Loader centered />;
  }

  return (
    <VisibilityForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      accountType={ENUMS.AccountType.FREELANCER}
    />
  );
};
