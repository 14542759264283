import React from "react";
import { NumberFormatBase, usePatternFormat } from "react-number-format";
import type { TypedWrappedFieldProps } from "redux-form";
import { touch } from "redux-form";
import type { CountryCode } from "libphonenumber-js";

import type { SelectChangeEvent } from "@hexocean/braintrust-ui-components";
import { InputAdornment } from "@hexocean/braintrust-ui-components";
import type { PhoneVerificationFormValues } from "@js/apps/settings/forms/phone-verification/phone-verification-form";

import type { TextFieldProps } from "..";
import { TextField } from "..";

import { CountrySelect } from "./country-select";
import { getPhoneNumberFormat } from "./helpers";
/**
 * Example usage:
 *
 * ```
 * <Field
 *     name="your-phone"
 *     component={PhoneField} />
 * ```
 */

export type PhoneFieldProps = TypedWrappedFieldProps<{
  country_iso2: CountryCode;
  phone_number: string;
}> &
  Pick<TextFieldProps, "helperText" | "id" | "variant" | "label">;

const PhoneField = ({
  input,
  meta,
  helperText,
  id,
  variant,
  label,
}: PhoneFieldProps) => {
  const selectedCountry = input.value["country_iso2"] || "";

  const country = SETTINGS.COUNTRY_ISO2_TO_COUNTRY_DATA.filter(
    ({ code }) => code === selectedCountry,
  );

  const handleChange = (
    key: keyof PhoneVerificationFormValues["phone_number"],
    newValue: string,
  ) => {
    const { onChange, name, value } = input;
    const { dispatch, form } = meta;

    dispatch(touch(form, name));

    onChange({
      ...value,
      [key]: newValue,
    });
  };

  const handleSelectChange = ({ target: { value } }: SelectChangeEvent) => {
    handleChange("country_iso2", value);
  };

  const value = input.value["phone_number"] || "";

  const { format } = usePatternFormat({
    format: getPhoneNumberFormat(value, selectedCountry),
    value: value,
    type: "tel",
  });

  const formattedValue = format?.(value)?.trim() || "";

  return (
    <div className="phone-select">
      <NumberFormatBase
        onValueChange={(values) => {
          handleChange("phone_number", values.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" variant="filled">
              <CountrySelect
                country={country}
                value={selectedCountry}
                onChange={handleSelectChange}
              />
            </InputAdornment>
          ),
        }}
        inputProps={{
          "aria-label": "Mobile Number",
        }}
        meta={meta}
        input={{
          ...input,
          value: formattedValue,
          onChange: () => {},
          onBlur: () => {
            handleChange("phone_number", value);
          },
        }}
        value={formattedValue}
        customInput={TextField}
        format={format}
        helperText={helperText}
        id={id}
        variant={variant}
        label={label}
      />
    </div>
  );
};

export { PhoneField };
