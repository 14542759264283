import React from "react";

import type { IconButtonProps } from "@hexocean/braintrust-ui-components";
import { Tooltip } from "@hexocean/braintrust-ui-components";
import { IconButton } from "@hexocean/braintrust-ui-components";
import type { CreatePostAttachmentFieldType } from "@js/apps/give-and-get-help/context";
import { useCreatePostOpenedFieldsContext } from "@js/apps/give-and-get-help/context";

export type FooterActionButtonProps = Pick<IconButtonProps, "aria-label"> & {
  onClick: () => void;
  fieldName: CreatePostAttachmentFieldType;
  title: string;
};

export const FooterActionButton: React.FC<
  React.PropsWithChildren<Omit<FooterActionButtonProps, "variant">>
> = ({ children, fieldName, onClick, title, ...props }) => {
  const { toggleField } = useCreatePostOpenedFieldsContext();

  return (
    <Tooltip title={title} placement="top" arrow={false}>
      <IconButton
        variant="transparent"
        size="medium"
        sx={{
          "&:hover": { backgroundColor: "var(--yellow)" },
        }}
        onClick={() => {
          toggleField(fieldName);
          if (onClick) {
            onClick();
          }
        }}
        {...props}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
};
