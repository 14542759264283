import { Typography } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";

export const ImpersonatedEmail = () => {
  const user = useUser();

  return (
    <Typography component="span" fontWeight={500}>
      {user?.email}
    </Typography>
  );
};
