import { type MouseEvent, useMemo } from "react";
import { debounce } from "underscore";

import { useDisableSpecificPostActions } from "@js/apps/give-and-get-help/hooks/use-disable-specific-post-actions";
import { getReactionTooltipContent } from "@js/apps/give-and-get-help/utils";
import type { User } from "@js/types/auth";
import type { Reaction, StickerValue } from "@js/types/give-and-get-help";

type UseReactionProps = {
  onClick: (emoji: StickerValue) => void;
  currentUser: User | null;
  reaction: Reaction;
};

export const useReaction = ({
  onClick,
  currentUser,
  reaction,
}: UseReactionProps) => {
  const disablePostActions = useDisableSpecificPostActions();
  const handleReactionClick = useMemo(() => {
    return debounce((emoji: StickerValue) => onClick(emoji), 500, true);
  }, [onClick]);

  const hasCurrentUserReacted = !!currentUser && reaction.toggled;

  const tooltipContent = useMemo(() => {
    return hasCurrentUserReacted
      ? getReactionTooltipContent(reaction, currentUser?.public_name)
      : getReactionTooltipContent(reaction);
  }, [reaction, hasCurrentUserReacted, currentUser]);

  const handleOnClick = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    handleReactionClick(reaction.sticker);
  };

  const handleOnEnter = (
    event: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    if (event.key === "Enter") {
      handleReactionClick(reaction.sticker);
    }
  };

  return {
    handleOnClick,
    handleOnEnter,
    disablePostActions,
    hasCurrentUserReacted,
    tooltipContent,
  };
};
