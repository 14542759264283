import { type FC, useCallback } from "react";
import type { WrappedFieldsProps } from "redux-form";

import type { BidReaction } from "../../apps/bids";
import { ReactionsFeedback } from "../reactions-feedback";

export const ReactionsFeedbackFilters: FC<WrappedFieldsProps> = ({
  reaction: {
    input: { value: reactionInputValue, onChange },
  },
  is_hidden: {
    input: { value: hiddenInputValue, onChange: hiddenOnChange },
  },
}) => {
  const isHiddenValue = "is_hidden";

  const handleFilterClick = (reaction: BidReaction) => {
    const _value = reaction.value || isHiddenValue;
    const isSelected =
      _value === isHiddenValue
        ? !!hiddenInputValue
        : reactionInputValue === _value;

    if (!isSelected) {
      return handleToggleOnFilter(_value);
    }

    handleToggleOffFilter();
  };

  const handleToggleOnFilter = useCallback(
    (value: EnumType<typeof ENUMS.BidReactionType> | "is_hidden") => {
      switch (value) {
        case ENUMS.BidReactionType.THINKING:
        case ENUMS.BidReactionType.THUMBS_UP: {
          hiddenOnChange(false);
          onChange(value);
          break;
        }
        case isHiddenValue: {
          hiddenOnChange(true);
          onChange(null);
          return;
        }
        default:
          break;
      }
    },
    [hiddenOnChange, onChange],
  );

  const handleToggleOffFilter = useCallback(() => {
    hiddenOnChange(false);
    return onChange(null);
  }, [hiddenOnChange, onChange]);

  return (
    <ReactionsFeedback
      handleOnReactionClick={handleFilterClick}
      currentSelectedReaction={reactionInputValue}
      isHiddenBid={hiddenInputValue}
      isInFilterBox
    />
  );
};
