import React from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

type BookmarksEmptyStateProps = {
  ctaLabel: string;
  ctaLink: string;
  description: string;
  title?: string;
  imgSrc?: string;
};

export const BookmarksEmptyState = ({
  ctaLabel,
  ctaLink,
  description,
  title = "No saved items",
  imgSrc = `${SETTINGS.STATIC_URL}bookmarks/bookmarks-empty-state.svg`,
}: BookmarksEmptyStateProps) => {
  return (
    <Box textAlign="center" px={{ xs: 2, sm: 0 }}>
      <img src={imgSrc} alt="not-saved-item-yet" width="190" height="190" />
      <Box mt={1} mb={1}>
        <Typography component="h2" fontWeight={500} size="medium">
          {title}
        </Typography>
      </Box>
      <Typography component="p" size="medium">
        {description}
      </Typography>
      <Button
        className="mt"
        variant="secondary"
        to={ctaLink}
        size="x-small"
        type="button"
        RouterLink={RouterLink}
      >
        {ctaLabel}
      </Button>
    </Box>
  );
};
