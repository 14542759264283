import type { TypedUseSelectorHook } from "react-redux";
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from "react-redux";

import type { AppDispatch, RootState } from "@js/store";

// Use throughout the app instead of plain `useSelector`
type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
