import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";

export const ReactionsDrawerTitle = () => {
  return (
    <Typography
      component="h3"
      variant="paragraph"
      size="medium"
      fontWeight={500}
      sx={{ pl: 2.5 }}
    >
      Reactions
    </Typography>
  );
};
