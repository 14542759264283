import { postFooterActionButtonClicked } from "@js/apps/give-and-get-help/actions";
import type { PostFooterButtonNameAndComponentMapItem } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/footer";
import type { CreateOrEditPostMode } from "@js/apps/give-and-get-help/types";
import { useAppDispatch } from "@js/hooks";

import { usePostLocationContext } from "../../context/post-location";

export type CreateOrEditPostFooterActionsButtonsSectionProps = {
  mode: CreateOrEditPostMode;
  buttons: PostFooterButtonNameAndComponentMapItem[];
};

export const CreateOrEditPostFooterActionsButtonsSection = ({
  mode,
  buttons,
}: CreateOrEditPostFooterActionsButtonsSectionProps) => {
  const dispatch = useAppDispatch();
  const postLocation = usePostLocationContext();

  return (
    <>
      {buttons.map(({ Component, name }) => {
        return (
          <Component
            key={name}
            onClick={() => {
              dispatch(
                postFooterActionButtonClicked({
                  mode,
                  name,
                  post_location: postLocation,
                }),
              );
            }}
          />
        );
      })}
    </>
  );
};
