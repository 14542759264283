import { Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";

type SpacesHeaderProps = {
  title: string;
};

export const SpacesHeader = ({ title }: SpacesHeaderProps) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Typography
      component="h1"
      variant="title"
      mb={isMobile ? 2 : 3}
      size={isMobile ? "small" : "medium"}
      fontWeight={400}
    >
      {title}
    </Typography>
  );
};
