import { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

import {
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { RouterLink } from "@js/components/link";
import { AppLayout } from "@js/layouts/app";
import { normalizePath } from "@js/utils";

import styles from "./styles.module.scss";

const BOOKMARKS_NAVIGATION_ITEMS = [
  {
    label: "Career Help",
    path: "/bookmarks/career_help",
  },
  {
    label: "Jobs",
    path: "/bookmarks/jobs",
  },
];

export const BookmarksPage = () => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery("sm");
  const labelSize = isMobile ? "medium" : "small";
  const labelVariant = isMobile ? "paragraph" : "title";

  const value = useMemo(() => {
    const normalizedPath = normalizePath(pathname);

    const currentTab = BOOKMARKS_NAVIGATION_ITEMS.find((item) => {
      const tabPathNormalized = normalizePath(item.path);
      return normalizedPath.includes(tabPathNormalized);
    });
    return currentTab?.path || BOOKMARKS_NAVIGATION_ITEMS[0].path;
  }, [pathname]);

  return (
    <AppLayout
      pageTitle="Bookmarks"
      bgcolor="var(--white)"
      flexColumn
      className={styles.bookmarksPage}
    >
      <Stack sx={{ px: { xs: 2, sm: 0 } }}>
        <Typography
          component="h1"
          variant="title"
          display="block"
          fontWeight={400}
          size={isMobile ? "small" : "medium"}
        >
          Your saved items
        </Typography>
        <Tabs
          aria-label="bookmarks tabs"
          value={value}
          sx={{
            mt: 1,
            mb: 3,
            "& .MuiTabs-flexContainer": {
              gap: 1,
            },
          }}
        >
          {BOOKMARKS_NAVIGATION_ITEMS.map(({ label, path }) => {
            return (
              <Tab
                key={label}
                value={path}
                component={RouterLink}
                to={path}
                className={styles.bookmarkTab}
                inactiveColorVariable="grey-2"
                label={
                  <Typography
                    component="span"
                    variant={labelVariant}
                    size={labelSize}
                    fontWeight={400}
                  >
                    {label}
                  </Typography>
                }
              />
            );
          })}
        </Tabs>
      </Stack>

      <Outlet />
    </AppLayout>
  );
};
