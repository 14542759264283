import { ModalInstance } from "@js/components/modal";

import type { RequestLiveInterviewProps } from "./request-live-interview-content";
import { RequestLiveInterviewContent } from "./request-live-interview-content";

import styles from "./style.module.scss";

export const openRequestLiveInterviewMessage = ({
  job,
  bid,
}: RequestLiveInterviewProps) => {
  ModalInstance.open({
    className: styles.interviewModal,
    keepOnBackdropClick: true,
    children: <RequestLiveInterviewContent bid={bid} job={job} />,
    padding: false,
    closeButton: true,
    closeButtonProps: {
      style: {
        marginRight: "-8px",
        top: 0,
        right: "12px",
        padding: "16px",
      },
    },
  });
};
