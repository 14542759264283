import { type RefObject, useEffect, useMemo, useRef } from "react";

//trigger callback if element is visible in the viewport
export const useOnElementVisible = (
  target: RefObject<HTMLElement>,
  callback: (isVisible: boolean) => void,
  options?: IntersectionObserverInit,
) => {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const { root, rootMargin, threshold } = options ?? {};
  const memoizedOptions = useMemo(() => {
    return { root, rootMargin, threshold };
  }, [root, rootMargin, threshold]);

  useEffect(() => {
    if (target?.current) {
      const observer = new IntersectionObserver((entries) => {
        const { isIntersecting } = entries[0];
        callbackRef.current(isIntersecting);
      }, memoizedOptions);
      observer.observe(target.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [target, callbackRef, memoizedOptions]);
};
