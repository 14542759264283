import React from "react";
import cs from "classnames";

import type { ProfileCompletion } from "@js/types/freelancer";

import {
  ProfileCompletionFeedbackLabel,
  profileCompletionFeedbackLabelPropMap,
} from "./labels";

import styles from "./style.module.scss";

export type ProfileCompletionFeedbackProps = {
  missingAreas: ProfileCompletion["missing_areas"];
  className?: string;
};

export const ProfileCompletionFeedback = ({
  missingAreas = [],
}: ProfileCompletionFeedbackProps): JSX.Element | null => {
  if (missingAreas.length === 0) {
    return null;
  }

  return (
    <ul className={cs("list-reset", styles.profileCompletionFeedback)}>
      {missingAreas.map((item) => {
        const props = profileCompletionFeedbackLabelPropMap[item.keywords];

        return (
          <li
            key={item.keywords}
            className={styles.profileCompletionFeedbackItem}
          >
            <ProfileCompletionFeedbackLabel
              {...props}
              number={item.items_count}
              progress={item.progress}
            />
          </li>
        );
      })}
    </ul>
  );
};
