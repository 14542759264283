import React from "react";

import {
  Stack,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { InfoTooltip } from "@js/components/info-tooltip";

import { MyWalletBalance } from "../my-wallet-balance";

type ReferralsStatsSummaryMainSectionProps = {
  title: string;
  totalBalance: string;
  tooltipText: string;
};

export const ReferralsStatsSummaryMainSection = ({
  title,
  totalBalance,
  tooltipText,
}: ReferralsStatsSummaryMainSectionProps) => {
  const isMobile = useMediaQuery("sm");
  return (
    <Stack
      direction="column"
      sx={{ justifyContent: "space-between", flexGrow: 1 }}
    >
      <Typography
        component="h3"
        variant={isMobile ? "paragraph" : "title"}
        size={isMobile ? "large" : "small"}
        fontWeight={400}
        mb={2}
      >
        {title}
      </Typography>
      <Stack sx={{ gap: { xs: 0.5, sm: 2 } }}>
        <MyWalletBalance
          balance={totalBalance}
          currencyName=""
          textStyle={{
            fontSize: isMobile ? 68 : 100,
            fontWeight: 600,
            lineHeight: 1,
            height: isMobile ? 84 : "112px",
          }}
          iconStyle={{
            fontSize: isMobile ? 60 : 85,
          }}
        />

        <Typography component="p" variant="label" size="large">
          BTRST earned
          <InfoTooltip title={tooltipText} size="18px" />
        </Typography>
      </Stack>
    </Stack>
  );
};
