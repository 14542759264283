import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useCtaClickedContext } from "@js/apps/common/context";
import { Date } from "@js/components/date";
import { RouterLink } from "@js/components/link";
import type { IsoDateOrIsoDateTime } from "@js/utils/date/types";
import { DateFormats } from "@js/utils/date/types";

import { SectionPanel } from "../dashboard/utils";

export type Event = {
  id: number;
  datetime: IsoDateOrIsoDateTime;
  title: string;
  subtext: string;
  cta_link: string;
  cta_text: string;
};

export const EventList = ({ eventList }: { eventList: Event[] }) => {
  const { ctaClicked } = useCtaClickedContext();
  const isMobile = useMediaQuery("sm");
  const isTablet = useMediaQuery("md");
  const showMobileLayout = isMobile || isTablet;

  if (eventList.length === 0) {
    return null;
  }

  return (
    <SectionPanel title="RSVP for Braintrust events">
      {eventList.map((event) => (
        <Box
          key={event.id}
          display="flex"
          justifyContent="flex-start"
          alignItems={{
            xs: "flex-start",
            sm: "center",
            md: "flex-start",
            lg: "center",
          }}
          borderRadius="16px"
          gap="24px"
          bgcolor="var(--off-white)"
          mt={2}
          p={{ xs: 2, sm: 3, md: 2, lg: 3 }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={{
              xs: "0 0 56px",
              sm: "0 0 72px",
              md: "0 0 56px",
              lg: "0 0 72px",
            }}
            height={{ xs: "56px", sm: "72px", md: "56px", lg: "72px" }}
            borderRadius="12px"
            bgcolor="var(--white)"
          >
            <Typography
              mb={-1}
              component="p"
              variant="paragraph"
              size={showMobileLayout ? "small" : "medium"}
            >
              <Date date={event.datetime} format={DateFormats["Jan"]} />
            </Typography>
            <Typography
              component="p"
              variant={showMobileLayout ? "label" : "title"}
              size={showMobileLayout ? "large" : "small"}
            >
              <Date date={event.datetime} format={DateFormats.dayOfTheMonth} />
            </Typography>
          </Box>
          <Box
            width="100%"
            display="flex"
            gap={{ xs: "16px", sm: "48px", md: "16px", lg: "48px" }}
            flexDirection={{ xs: "column", sm: "row", md: "column", lg: "row" }}
            flexWrap={{ xs: "wrap", sm: "nowrap", md: "wrap", lg: "nowrap" }}
            justifyContent={{
              xs: "flex-start",
              sm: "space-between",
              md: "flex-start",
              lg: "space-between",
            }}
            alignItems={{
              xs: "flex-start",
              sm: "center",
              md: "flex-start",
              lg: "center",
            }}
          >
            <Box sx={{ wordBreak: "break-word" }}>
              <Typography component="h4" variant="label" size="medium">
                {event.title}
              </Typography>
              <Typography component="p" variant="paragraph" size="small">
                {event.subtext}
              </Typography>
            </Box>
            <Box>
              <Button
                variant="primary"
                size="small"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => ctaClicked(ENUMS.UserClicksEventCTAName.RSVP)}
                href={event.cta_link}
                RouterLink={RouterLink}
              >
                {event.cta_text}
              </Button>
            </Box>
          </Box>
        </Box>
      ))}
    </SectionPanel>
  );
};
