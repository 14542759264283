const PREFIX = "view";
const SEPARATOR = "_";

export const mapRouteToViewEvent = (path: string) => {
  const parts = path.split("/");

  const prefixWithSeparator = `${PREFIX}${SEPARATOR}`;

  const partsJoined = parts.join(SEPARATOR);

  const partsJoinedWithoutColons = partsJoined.replace(/:/g, "");
  const partsJoinedWithoutColonsAndUppercase = partsJoinedWithoutColons.replace(
    /[A-Z]/g,
    (match) => `-${match.toLowerCase()}`,
  );

  return `${prefixWithSeparator}${partsJoinedWithoutColonsAndUppercase}`;
};
