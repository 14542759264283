import { Typography } from "@hexocean/braintrust-ui-components";
import { Emoji } from "@js/components/emoji";

import { SystemMessageCardContent } from "./system-message-card-content";

import styles from "./styles.module.scss";

export const ReportSystemMessageCard = ({
  name,
  cancelledReport,
}: {
  name?: string;
  cancelledReport?: boolean;
}) => {
  const title = cancelledReport
    ? `You cancelled reports of ${name}`
    : `You reported ${name}`;

  const reportedSubtitle = cancelledReport
    ? undefined
    : "A Braintrust moderator will review and respond within 72 hours.";
  const reportedMessage = (
    <Typography component="p" size="small" fontStyle={"italic"}>
      (Only you can see this message For any follow up, contact{" "}
      <a
        href={`mailto:${SETTINGS.SUPPORT_EMAIL}`}
        className={styles.additionalMessageLink}
      >
        {SETTINGS.SUPPORT_EMAIL}
      </a>{" "}
      <Emoji size={13} emoji="🤐" className={styles.emojiReported} />)
    </Typography>
  );

  return (
    <SystemMessageCardContent
      emoji="🔴"
      emojiBackground="var(--soft-red)"
      title={title}
      subtitle={reportedSubtitle}
      additionalMessage={reportedMessage}
    />
  );
};
