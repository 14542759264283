import React from "react";

import { useUser } from "@js/apps/common/hooks";

type AuthenticatedUserOnlyWrapperProps = {
  children?: React.ReactNode;
  wrapperComponent: JSX.Element;
};

export const AuthenticatedUserOnlyWrapper = ({
  children,
  wrapperComponent,
}: AuthenticatedUserOnlyWrapperProps) => {
  const user = useUser();

  if (user) {
    return React.cloneElement(wrapperComponent, { children });
  }

  return <>{children}</>;
};
