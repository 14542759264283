import { useUser } from "@js/apps/common/hooks";
import type { ApplicationInsightMatchSummary } from "@js/types/jobs";

const REQUIRED_TOTAL_APPLICANTS = 10;

type MatchPlacement = {
  label: string;
  summaryType: ApplicationInsightMatchSummary["summary_type"];
};

export const useMatchPlacement = (
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null,
  numberOfApplicants: number,
  isApplied: boolean,
): MatchPlacement => {
  const notEnoughApplicants = numberOfApplicants < REQUIRED_TOTAL_APPLICANTS;
  const isDecentMatch =
    matchLabel === ENUMS.SmartMatchingLabel.GOOD ||
    matchLabel === ENUMS.SmartMatchingLabel.GREAT;

  let placementLabel = "";

  if (isApplied && !notEnoughApplicants && isDecentMatch) {
    if (matchLabel === ENUMS.SmartMatchingLabel.GOOD) {
      placementLabel = "You're in the Top 25%";
    } else if (matchLabel === ENUMS.SmartMatchingLabel.GREAT) {
      placementLabel = "You're in the Top 10%";
    }
  } else if (isDecentMatch) {
    placementLabel = "You might be a good fit";
  }

  return {
    label: `There ${numberOfApplicants === 1 ? "is" : "are"} ${numberOfApplicants} applicant${numberOfApplicants === 1 ? "" : "s"}. ${placementLabel}`,
    summaryType:
      isDecentMatch || matchLabel === ENUMS.SmartMatchingLabel.OK
        ? "positive"
        : "constructive",
  };
};

export const useScoreMatchBadge = (
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null,
) => {
  const user = useUser();
  const showBadge =
    user &&
    (matchLabel === null ||
      matchLabel !== ENUMS.SmartMatchingLabel.RECOMMENDED_BY_MATCHER);

  return {
    showBadge,
  };
};

export const formatMatchLabel = (
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null,
) => {
  if (matchLabel === ENUMS.SmartMatchingLabel.RECOMMENDED_BY_MATCHER) {
    return "";
  }

  if (!matchLabel || matchLabel === ENUMS.SmartMatchingLabel.UNAVAILABLE) {
    return "Pending match";
  }

  const label = ENUMS.SmartMatchingLabels[matchLabel];

  return label;
};

export const getMatchLabelColors = (
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null,
  isForMainBadge: boolean = false,
) => {
  switch (matchLabel) {
    case ENUMS.SmartMatchingLabel.GREAT: {
      return {
        backgroundColor: isForMainBadge ? "var(--positive-2)" : "#E4F7EE",
        textColor: isForMainBadge ? "var(--white)" : "var(--positive-1)",
      };
    }

    case ENUMS.SmartMatchingLabel.GOOD: {
      return {
        backgroundColor: "var(--medium-blue)",
        textColor: "var(--info-1)",
      };
    }

    case ENUMS.SmartMatchingLabel.OK: {
      return {
        backgroundColor: isForMainBadge
          ? "var(--warning-2)"
          : "var(--soft-orange)",
        textColor: "var(--dark-yellow)",
      };
    }

    case ENUMS.SmartMatchingLabel.BAD: {
      return {
        backgroundColor: isForMainBadge
          ? "var(--medium-red)"
          : "var(--soft-red)",
        textColor: "var(--dark-red)",
      };
    }

    case ENUMS.SmartMatchingLabel.UNAVAILABLE:
    case null: {
      return {
        backgroundColor: "var(--grey-5)",
        textColor: "var(--grey-1)",
      };
    }

    case ENUMS.SmartMatchingLabel.RECOMMENDED_BY_MATCHER:
    default: {
      return {
        backgroundColor: "",
        textColor: "",
      };
    }
  }
};
