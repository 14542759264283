import type { MouseEvent } from "react";
import { useRef } from "react";
import { SubmissionError } from "redux-form";

import { useCombinedCoachMarks } from "@js/apps/available-for-work/hooks/use-combined-coach-marks";
import { useOptionTooltip } from "@js/apps/available-for-work/hooks/use-option-tooltip";
import { useWorkAvailability } from "@js/apps/available-for-work/hooks/use-work-availability";
import { useIsProfileOwnerContext } from "@js/apps/common/profile/is-profile-owner-context";
import { clickUpdateAvailabilityProfile } from "@js/apps/freelancer/actions";
import { useUpdateFreelancerCustomizationMutation } from "@js/apps/freelancer/api";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { FreelancerPublic } from "@js/types/freelancer";

export type AvatarFormData = {
  avatar_id: number;
  avatar: string;
};

type UseProfnetProfilePageAvatarArgs = {
  profile: FreelancerPublic;
};

export const useProfnetProfilePageAvatar = ({
  profile,
}: UseProfnetProfilePageAvatarArgs) => {
  const dispatch = useAppDispatch();

  const { isProfileOwner } = useIsProfileOwnerContext();
  const profileOwnerProfile = useAppSelector(
    (state) => state.freelancer?.freelancerProfile,
  );
  const profileForWork = isProfileOwner ? profileOwnerProfile : profile;

  const tooltipTitle = useOptionTooltip(
    profileForWork?.availability_for_work_options,
  );

  const { onClick: handleWorkStatusClick } = useWorkAvailability({
    profileId: profile.user.id,
  });

  const [updateFreelancerCustomization] =
    useUpdateFreelancerCustomizationMutation();

  const customInputRef = useRef<HTMLInputElement | undefined>();

  const isAvailableForWork = isProfileOwner
    ? !!profileOwnerProfile?.availability_for_work
    : !!profile.availability_for_work;

  const handleAvatarEditClick = () => {
    customInputRef?.current?.click();
  };

  const handleAvatarFormSubmit = async (data: AvatarFormData) => {
    await updateFreelancerCustomization({
      id: profile.id,
      data: {
        user: data,
      },
    })
      .unwrap()
      .catch((error) => {
        throw new SubmissionError(error.data);
      });
    return profile.id;
  };

  const handleAvailableForWorkStatusClick = (
    event: MouseEvent<HTMLElement>,
  ) => {
    handleWorkStatusClick(event);
    dispatch(clickUpdateAvailabilityProfile());
  };

  const combinedCoachMarksProps = useCombinedCoachMarks();

  return {
    isProfileOwner,
    customInputRef,
    tooltipTitle,
    isAvailableForWork,
    handleAvailableForWorkStatusClick,
    handleAvatarEditClick,
    handleAvatarFormSubmit,
    ...combinedCoachMarksProps,
  };
};
