import { Item } from "./item";

import styles from "./styles.module.scss";

export const TopMatches = () => {
  return (
    <Item>
      <Item.GreenBox>
        <Item.Title text="2. Get top matches" />
        <img
          src={`${SETTINGS.STATIC_URL}employer/top-matches.png`}
          className={styles.topMatches}
          width="727px"
          height="905px"
          alt=""
        />
      </Item.GreenBox>
      <Item.Description header="AI Candidate Matching">
        Our AI Matching Engine instantly connects you with vetted top talent
        from around the globe, accessing over 1 million qualified professionals.
      </Item.Description>
    </Item>
  );
};
