import { useState } from "react";
import _ from "underscore";

import {
  AutocompleteBase,
  Box,
  IconButton,
  TextField,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { AddCircleOutlineIcon } from "@hexocean/braintrust-ui-components/Icons";
import { StyledGradingCriteriaAutocomplete } from "@js/apps/jobs/styled";
import type { TypedWrappedFieldProps } from "@js/forms/utils";

export const GradingCriteriaComboBox = ({
  input,
}: TypedWrappedFieldProps<string[]>) => {
  const [criteriaInput, setCriteriaInput] = useState<string>("");

  if (input.value.length >= 5) {
    return null;
  }

  const onChange = (values: (string | { id: number; name: string })[]) => {
    const unique = _.uniq(values, false);
    const names = unique.map((val) => {
      if (typeof val === "string") {
        return val;
      } else {
        return val.name;
      }
    });
    setCriteriaInput("");
    input.onChange(names);
  };

  return (
    <Box
      mt={2}
      sx={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <IconButton
        aria-label="add criteria"
        data-testid="add-criteria"
        variant="transparent"
        sx={{
          position: "absolute",
          right: "4px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        onClick={() => {
          if (criteriaInput.trim() === "") return;
          onChange([...input.value, criteriaInput.trim()]);
        }}
      >
        <AddCircleOutlineIcon
          style={{
            color: input.value?.length >= 5 ? "var(--grey-5)" : "var(--grey-1)",
            fontSize: 24,
          }}
        />
      </IconButton>
      <AutocompleteBase<string | { id: number; name: string }, true, true, true>
        multiple
        freeSolo
        inputValue={criteriaInput}
        value={input.value || []}
        options={[]}
        disabled={input.value.length >= 5}
        isOptionEqualToValue={(option, val) => {
          if (typeof val === "string" && option && typeof option !== "string") {
            return val?.toLowerCase() === option.name?.toLowerCase();
          }

          if (
            val &&
            typeof val !== "string" &&
            option &&
            typeof option !== "string"
          ) {
            return val.name?.toLowerCase() === option.name?.toLowerCase();
          }

          return false;
        }}
        component={StyledGradingCriteriaAutocomplete}
        onChange={(_ev, values) => {
          onChange(values);
        }}
        onInputChange={(_ev, value) => setCriteriaInput(value)}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              size="small"
              placeholder="Add grading criteria"
              InputProps={{
                ...params.InputProps,
                startAdornment: <></>,
                endAdornment: <></>,
              }}
            />
          );
        }}
        noOptionsText={<NotFoundText />}
        filterSelectedOptions
        sx={{
          width: "100%",
          "& .MuiAutocomplete-inputRoot": {
            paddingLeft: "11px !important",
          },
        }}
      />
    </Box>
  );
};

const NotFoundText = () => {
  return (
    <Typography component="h4" variant="label" textAlign="center">
      Critria grade not found 🙁
    </Typography>
  );
};
