import { useEffect } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { ButtonSelectField } from "@js/forms/fields/button-select";
import type { TimezoneOption } from "@js/types/jobs";

import { JobTimezoneField } from "./job-timezone-field";

type TimezoneWithOverlapProps = {
  timezone_overlap: TypedWrappedFieldProps<EnumType<
    typeof ENUMS.JobTimezoneOverlapType
  > | null>;
  timezones: TypedWrappedFieldProps<TimezoneOption[]>;
};

export const TimezoneWithOverlap = ({
  timezone_overlap,
  timezones,
}: TimezoneWithOverlapProps) => {
  const hasTimezones = timezones.input.value.length;

  useEffect(() => {
    if (!hasTimezones) {
      timezone_overlap.input.onChange(null);
    }
  }, [timezone_overlap.input, hasTimezones]);

  return (
    <Box maxWidth={640} py={4}>
      <Typography component="p" size="medium" sx={{ mb: 2 }}>
        Talent working hours
      </Typography>

      <JobTimezoneField meta={timezones.meta} input={timezones.input} />

      <Typography
        sx={{ mt: 3, mb: "10px" }}
        component="p"
        variant="label"
        size="small"
      >
        How much of the day does the Talent need to be able to work in this
        timezone?
      </Typography>

      <ButtonSelectField
        input={timezone_overlap.input}
        meta={timezone_overlap.meta}
        multiple={false}
        disableAll={!hasTimezones}
        variant="secondary"
        canSingleValueBeEmpty
        selectedVariant="primary"
        options={[
          {
            value: ENUMS.JobTimezoneOverlapType.PARTIAL,
            label: "A few hours",
          },
          {
            value: ENUMS.JobTimezoneOverlapType.FULL,
            label: "The full work day",
          },
        ]}
      />
    </Box>
  );
};
