import { useEffect, useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { fetchPopularLocationOptions } from "@js/apps/common/actions";
import {
  type LocationValue,
  LocationValueType,
} from "@js/components/autocomplete-new/google-places/types";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { handleOptionDisabled } from "../hook/helpers";
import type { JobCustomLocationlabels } from "../types";

type UsePopularLocationsButtonsProps = {
  input: TypedWrappedFieldProps<LocationValue[] | undefined>["input"];
};

const JobCustomLocationsLabels: JobCustomLocationlabels =
  ENUMS.JobCustomLocationTypeLabels;

export const usePopularLocationsButtons = ({
  input,
}: UsePopularLocationsButtonsProps) => {
  const options = useAppSelector((state) => state.common.popularLocations);
  const dispatch = useAppDispatch();

  const mappedOptions = useMemo(() => {
    return options.map((location) => {
      if (location.location_type === LocationValueType.custom) {
        return {
          ...location,
          location: JobCustomLocationsLabels[
            location.custom_location
          ] as string,
        };
      }

      return location;
    });
  }, [options]);

  useEffect(() => {
    dispatch(fetchPopularLocationOptions());
  }, [dispatch]);

  const filterOutSelectedValue = (value: string) => {
    const filteredValues = [...(input.value || [])].filter((location) => {
      const locationId = getLocationId(location);

      return locationId !== value;
    });

    return filteredValues;
  };

  const handleOnClick = (value: LocationValue) => {
    const id = getLocationId(value);

    if (!id) {
      return;
    }

    if (isBtnSelected(value)) {
      const newValue = filterOutSelectedValue(id);

      input.onChange(newValue);

      return;
    }

    input.onChange([...(input.value || []), value]);
  };

  const isBtnSelected = (btnValue: LocationValue) => {
    const btnValueId = getLocationId(btnValue);
    return (input.value || [])?.some((val) => {
      return getLocationId(val) === btnValueId;
    });
  };

  const isBtnDisabled = (btnValue: LocationValue) => {
    return handleOptionDisabled(btnValue, input.value || []);
  };

  return {
    popularLocationOptions: mappedOptions,
    handleOnClick,
    isBtnSelected,
    isBtnDisabled,
  };
};

const getLocationId = (value: LocationValue) => {
  if (value?.location_type === LocationValueType.custom) {
    return value.custom_location;
  }

  return value?.place_id;
};
