import { assertUnreachable } from "@js/utils";

import { RATE_FIELDS_KEYS } from "./constants";

export const getFieldsToCheckMeta = (
  paymentType: EnumType<typeof ENUMS.JobPaymentType>,
  fields: any[],
) => {
  const necessaryFields = mapPaymentTypeToRequiredFieldsKeys(paymentType);

  const filtered = fields.filter((field) => {
    return necessaryFields.includes(field.input.name);
  });

  return filtered;
};

const mapPaymentTypeToRequiredFieldsKeys = (
  paymentType: EnumType<typeof ENUMS.JobPaymentType>,
) => {
  let necessaryFields: (keyof typeof RATE_FIELDS_KEYS)[] = [
    RATE_FIELDS_KEYS.min_rate,
    RATE_FIELDS_KEYS.max_rate,
    RATE_FIELDS_KEYS.custom_min_rate,
    RATE_FIELDS_KEYS.custom_max_rate,
    RATE_FIELDS_KEYS.hourly_rate,
    RATE_FIELDS_KEYS.payment_type,
  ];

  switch (paymentType) {
    case ENUMS.JobPaymentType.HOURLY: {
      break;
    }
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      necessaryFields = [
        RATE_FIELDS_KEYS.fixed_rate,
        RATE_FIELDS_KEYS.payment_type,
      ];
      break;
    }
    case ENUMS.JobPaymentType.ANNUAL: {
      necessaryFields = [
        RATE_FIELDS_KEYS.min_annual_rate,
        RATE_FIELDS_KEYS.max_annual_rate,
        RATE_FIELDS_KEYS.payment_type,
      ];
      break;
    }
    default: {
      assertUnreachable(paymentType);
      break;
    }
  }

  return necessaryFields;
};
