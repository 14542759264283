import { Button } from "@hexocean/braintrust-ui-components";
import { DownloadArrowIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

export const DownloadCSVTemplateButton = () => {
  return (
    <Button
      sx={{ ml: 1 }}
      variant="transparent"
      size="x-small"
      RouterLink={RouterLink}
      to={SETTINGS.BYOT_CSV_TEMPLATE_URL}
      download
      target="_blank"
      startIcon={<DownloadArrowIcon stroke="white" />}
    >
      Download CSV template
    </Button>
  );
};
