import {
  Box,
  IconButton,
  Loader,
  Typography,
  useSwiperRef,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { ProjectsCarousel } from "@js/apps/freelancer/components/projects/projects-carousel";
import { ApplicationQAList } from "@js/apps/jobs/components/application-qa-list";
import { RequirementsIndicator } from "@js/apps/jobs/components/requirements-indicator";
import { JobSkillChip } from "@js/apps/skills/components";
import { AttachmentList } from "@js/components/attachment-list";
import type { WorkSampleItem } from "@js/types/freelancer";
import type { EmployerBid, EmployerBidListBid, Job } from "@js/types/jobs";

import { BidApplicationMatchSummary } from "../bid-application-match-summary";

import { BidTabLoader } from "./bid-tab-loader";

export const BidDetailsApplicationTab = ({
  bid,
  job,
}: {
  bid: EmployerBidListBid | EmployerBid;
  job: Job;
}) => {
  if (!("portfolio_items" in bid.freelancer)) {
    return <BidTabLoader />;
  }

  const workSamples = bid.freelancer.portfolio_items;

  return (
    <Box>
      {job && (
        <RequirementsIndicator
          job={job}
          locationRequirementsMet={bid.location_requirement_met}
          timezoneRequirementsMet={bid.timezone_requirement_met}
        />
      )}
      {!!bid.match_summary.length && (
        <BidApplicationMatchSummary
          freelancerFirstName={bid.freelancer.user.first_name}
          matchLabel={bid.match_label}
          matchSummary={bid.match_summary}
        />
      )}

      <ApplicationQAList
        questions={bid.application_questions}
        answers={bid.application_answers}
        header={
          <Typography component="p" variant="paragraph" size="large" mb={2}>
            Application questions
          </Typography>
        }
      />

      <Box mt={6}>
        <AttachmentList attachments={bid.attachments} />
      </Box>

      {!!workSamples?.length && (
        <Box mt={6} maxWidth="100%">
          <PortfolioItemsSlider items={workSamples} />
        </Box>
      )}
      {!!bid.freelancer.freelancer_skills?.length && (
        <>
          <Typography component="p" variant="paragraph" size="large" mb={2}>
            Talent skills
          </Typography>
          <Box display="flex" gap={2} flexWrap="wrap">
            {bid.freelancer.freelancer_skills.map((item) => {
              return <JobSkillChip key={item.id} skill={item.skill} />;
            })}
          </Box>
        </>
      )}
    </Box>
  );
};

const PortfolioItemsSlider = ({ items }: { items: WorkSampleItem[] }) => {
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();

  if (!items) return <Loader />;
  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography component="h5" variant="paragraph" size="large">
          Projects
        </Typography>

        <Box display="flex" columnGap={1}>
          <IconButton
            variant="secondary"
            aria-label="previous"
            size="medium"
            ref={prevElRef}
          >
            <ArrowLeftIcon />
          </IconButton>
          <IconButton
            variant="secondary"
            aria-label="next"
            size="medium"
            ref={nextElRef}
          >
            <ArrowRightIcon />
          </IconButton>
        </Box>
      </Box>

      <Box mt={2}>
        <ProjectsCarousel
          items={items}
          itemWidth="95%"
          navigationNextRef={nextEl}
          navigationPrevRef={prevEl}
        />
      </Box>
    </Box>
  );
};
