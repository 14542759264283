import { forwardRef } from "react";

import { Button } from "@hexocean/braintrust-ui-components";
import type { AnchorProps } from "@js/apps/give-and-get-help/components/stickers-module/stickers-popover";

export const ReactButton = forwardRef<HTMLButtonElement, AnchorProps>(
  ({ isTouchDevice, isOpen, ...rest }, ref) => {
    return (
      <Button
        ref={ref}
        sx={{
          p: "6px !important",
          minWidth: "0px !important",
          touchAction: isTouchDevice ? "none" : undefined,
          ml: "-6px",
        }}
        disabledType="opacity"
        variant="transparent-blue"
        shape="squared"
        size="x-small"
        aria-label="open sticker menu"
        {...rest}
      >
        React
      </Button>
    );
  },
);
