import type { CircularProgressDecoratorProps } from "@hexocean/braintrust-ui-components";
import { CircularProgressDecoratorColors } from "@hexocean/braintrust-ui-components";

const MATCH_SCORE_CIRCLE_META: Record<
  EnumType<typeof ENUMS.SmartMatchingLabels>,
  {
    color: CircularProgressDecoratorProps["color"];
    value: number;
  }
> = {
  [ENUMS.SmartMatchingLabels.recommended_by_matcher]: {
    value: 100,
    color: CircularProgressDecoratorColors.black,
  },
  [ENUMS.SmartMatchingLabels.great]: {
    value: 95,
    color: CircularProgressDecoratorColors.green,
  },
  [ENUMS.SmartMatchingLabels.good]: {
    value: 80,
    color: CircularProgressDecoratorColors.blue,
  },
  [ENUMS.SmartMatchingLabels.ok]: {
    value: 50,
    color: CircularProgressDecoratorColors.yellow,
  },
  [ENUMS.SmartMatchingLabels.bad]: {
    value: 20,
    color: CircularProgressDecoratorColors.red,
  },
  [ENUMS.SmartMatchingLabels.unavailable]: {
    value: 0,
    color: CircularProgressDecoratorColors.red,
  },
};

export const getMatchCircleMeta = ({
  matchLabelKey,
}: {
  matchLabelKey: keyof typeof ENUMS.SmartMatchingLabels;
}) => {
  const circleMeta =
    MATCH_SCORE_CIRCLE_META[ENUMS.SmartMatchingLabels[matchLabelKey]];

  return circleMeta;
};
