import { useMemo } from "react";
import type { WrappedFieldProps } from "redux-form";
import {
  getFormError,
  getFormSubmitErrors,
  getFormSyncErrors,
  getFormSyncWarnings,
} from "redux-form";
import _ from "underscore";

import { useAppSelector } from "@js/hooks/";

export const useFormErrors = (meta: WrappedFieldProps["meta"]) => {
  const syncErrors = useAppSelector((state) =>
    getFormSyncErrors(meta.form)(state),
  );
  const submitErrors = useAppSelector((state) =>
    getFormSubmitErrors(meta.form)(state),
  );
  const formError = useAppSelector((state) =>
    getFormError(meta.form)(state),
  ) as Array<string>;

  const syncWarnings = useAppSelector((state) =>
    getFormSyncWarnings(meta.form)(state),
  );

  const generalFormError =
    Array.isArray(formError) && formError.length > 0 ? formError[0] : "";

  const errors: Record<string, string> = useMemo(() => {
    return clearFormErrorsAndTransformToObjects({
      ...submitErrors,
      ...syncErrors,
    });
  }, [syncErrors, submitErrors]);

  return {
    syncErrors,
    submitErrors,
    errors,
    generalFormError,
    syncWarnings,
  };
};

export const clearFormErrorsAndTransformToObjects = <
  T extends Record<string | number, unknown>,
>(
  obj: T,
): DeepPartial<T> => {
  if (_.isFunction(obj) || !_.isObject(obj)) return obj;

  return _.chain(obj)
    .mapObject((val) =>
      clearFormErrorsAndTransformToObjects(
        val as Record<string | number, unknown>,
      ),
    )
    .pick((p) => !(_.isObject(p) && _.isEmpty(p)) && !_.isUndefined(p))
    .value() as DeepPartial<T>;
};
