import cs from "classnames";

import type { TypographyProps } from "@hexocean/braintrust-ui-components";
import { Typography } from "@hexocean/braintrust-ui-components";
import { Emoji } from "@js/components/emoji";
import type { PostCategory } from "@js/types/give-and-get-help";

import styles from "./category-label.module.scss";

type CategoryLabelProps = {
  category: Omit<PostCategory, "budget_higher" | "budget_lower" | "color">;
  withoutName?: boolean;
  nameProps?: Partial<TypographyProps>;
  className?: string;
};

export const CategoryLabel = ({
  category,
  withoutName,
  nameProps,
  className,
}: CategoryLabelProps) => {
  if (withoutName) {
    return <Emoji emoji={category.emoji} className={styles.icon} />;
  }

  if (category.emoji.includes("emoji") || !category.emoji) {
    return (
      <Typography component="span" {...nameProps}>
        {category.name}
      </Typography>
    );
  }

  return (
    <span className={cs(styles.labelWithEmoji, className)}>
      <Emoji emoji={category.emoji} className={styles.icon} />
      <Typography component="span" {...nameProps}>
        {category.name}
      </Typography>
    </span>
  );
};
