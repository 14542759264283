import { createContext, useContext } from "react";

export type CompletionSequenceContextType = {
  handleStepCompleted: () => void;
};

export const CompletionSequenceContext =
  createContext<CompletionSequenceContextType | null>(null);

export const useCompletionSequenceContext = () => {
  const context = useContext(CompletionSequenceContext);
  if (!context) {
    throw new Error(
      "useCompletionSequenceContext must be used within a CompletionSequenceContext.Provider",
    );
  }
  return context;
};
