import { Navigate, Route } from "react-router-dom";

import { ACCESS_RULE } from "@js/routes/constants";
import { ProtectedRoute } from "@js/routes/protected-route";

import {
  NFTLayoutLazy,
  NFTManagementPageLazy,
  NFTMembershipPageLazy,
  NFTMintPageLazy,
} from "./views/lazy";

export const NFTRoutes = [
  <Route path="mint" key="mint" element={<NFTLayoutLazy />}>
    <Route index element={<NFTMembershipPageLazy />} />
  </Route>,
  <Route path="nft" key="nft" element={<NFTLayoutLazy />}>
    <Route index element={<Navigate to="/nft/mint/" />} />
    <Route
      path="mint"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.ACCOUNT_TYPE_SELECTED]}>
          <NFTMintPageLazy />
        </ProtectedRoute>
      }
    />
    <Route path=":tokenId" element={<NFTManagementPageLazy />} />
  </Route>,
];
