import { useParams } from "react-router-dom";

import { EditPostModalInstance } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/modal-instances";
import { PostLocation } from "@js/apps/give-and-get-help/context/post-location";
import { useGetSpaceMembersQuery } from "@js/apps/spaces/api";
import { RuleList } from "@js/apps/spaces/components/rules";
import { useRules } from "@js/apps/spaces/components/rules/hooks";

import {
  AboutThisSpace,
  AdminList,
  CreateSpacePost,
  SpacePostSection,
  TrendingHashtagsList,
} from "../../components";
import { SpaceSubheader } from "../../components/space-subheader";
import { useDiscussion } from "../../hooks/use-discussion";
import { useSpaceAdmins } from "../../hooks/use-space-admins";

import styles from "./styles.module.scss";

export const Discussion = () => {
  const { spaceId } = useParams();
  const { rules, shouldRenderEditRulesButton } = useRules({
    spaceId: Number(spaceId),
  });
  const {
    spaceData,
    shouldHideDetailsSection,
    postLocationValue,
    isAdmin,
    isMember,
    areNotificationsTurnOn,
  } = useDiscussion({ spaceId: Number(spaceId) });
  const { data: membersData } = useGetSpaceMembersQuery({
    id: Number(spaceId),
  });

  const admins = useSpaceAdmins({ members: membersData?.results });

  if (!spaceData || !spaceId || !membersData) {
    return null;
  }

  return (
    <div className={styles.container}>
      {!shouldHideDetailsSection && (
        <div className={styles.column}>
          <AboutThisSpace
            space={spaceData}
            members={membersData.results}
            membersCount={membersData.count}
            isAdmin={isAdmin}
            isMember={isMember}
            areNotificationsTurnOn={areNotificationsTurnOn}
            isDescriptionPreview
          />
          <AdminList
            spaceId={spaceData.id}
            admins={admins}
            showProfileCardPopover
          />
          <RuleList
            spaceId={spaceData.id}
            rules={rules}
            shouldRenderEditRulesButton={shouldRenderEditRulesButton}
            showRulesPreviewOnly
            shouldRedirectOnReadMore={isAdmin}
          />
          <TrendingHashtagsList spaceId={Number(spaceId)} />
        </div>
      )}
      <div className={styles.column}>
        <PostLocation.Provider value={postLocationValue}>
          <SpaceSubheader spaceId={spaceData.id} />
          <CreateSpacePost spaceData={spaceData} />
          <SpacePostSection spaceData={spaceData} />
          <EditPostModalInstance />
        </PostLocation.Provider>
      </div>
    </div>
  );
};
