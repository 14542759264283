import React, { useEffect, useRef, useState } from "react";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import { Box } from "@hexocean/braintrust-ui-components";

type ArcUnderlineCustomProps = {
  width?: string | number;
  color?: string;
};

type ArcUnderlineProps = BoxProps | ArcUnderlineCustomProps;

const DEFAULT_COLOR = "#E89401";

export const ArcUnderline: React.FC<ArcUnderlineProps> = ({
  width,
  color = DEFAULT_COLOR,
  ...props
}) => {
  const [realWidth, changeRealWidth] = useState<number | undefined>(0);
  const svgRef = useRef<SVGSVGElement>(null);

  const updateRealWidth = () =>
    changeRealWidth((svgRef.current?.clientWidth as number) || 0);

  useEffect(() => {
    updateRealWidth();
    window.addEventListener("resize", updateRealWidth);
    return () => {
      window.removeEventListener("resize", updateRealWidth);
    };
  });

  return (
    <Box lineHeight={0} height={6} width={width} {...props}>
      <svg
        width="100%"
        height="6"
        viewBox={`0 0 ${realWidth} 6`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
      >
        <pattern
          id="arc-pattern"
          x="50%"
          y="0"
          width="14.5"
          height="100%"
          patternUnits="userSpaceOnUse"
        >
          <path
            d="M0 4.76087 C4.88889 -0.253624 9.7778 -0.253624 14.6667 4.76087"
            stroke={color as string}
          />
        </pattern>

        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="url(#arc-pattern)"
        ></rect>
      </svg>
    </Box>
  );
};
