import { useUser } from "@js/apps/common/hooks";
import { openSignUpModal } from "@js/apps/onboarding/components/sign-up-modal";
import { useJoinThisSpace } from "@js/apps/spaces/hooks/use-join-this-space";

import { openSpaceInviteContactsModal } from "../../invite-to-space-modal";

export type CTAProps = {
  label: string;
  onClick: () => void;
  hideIcon: boolean;
  isJoining?: boolean;
};
export const useGetCTAProps = (
  spaceId: number,
  isUserMember?: boolean,
): CTAProps => {
  const user = useUser();
  const joinSpaceLabel = "Join this space";
  const inviteLabel = "Invite";
  const { handleJoinSpaceClick, isJoining } = useJoinThisSpace(spaceId, false);

  const inviteUser = () => {
    openSpaceInviteContactsModal({ spaceId });
  };

  if (!user)
    return {
      label: joinSpaceLabel,
      onClick: openSignUpModal,
      hideIcon: true,
    };

  if (!isUserMember)
    return {
      label: joinSpaceLabel,
      onClick: handleJoinSpaceClick,
      hideIcon: true,
      isJoining,
    };

  return {
    label: inviteLabel,
    onClick: inviteUser,
    hideIcon: false,
  };
};
