import { Stack } from "@hexocean/braintrust-ui-components";
import { BidTabLoader } from "@js/apps/jobs/apps/bids/components/bid-application-panel/tabs/bid-tab-loader";
import type { EmployerBidListBid } from "@js/types/jobs";

import { MessagesBox, TextBox } from "../../components";
import { MessagesProvider } from "../../context";
import { useGetOrCreateRoom } from "../../hooks";

import styles from "./styles.module.scss";

export const JobMessenger = ({ bid }: { bid: EmployerBidListBid }) => {
  const receiverUserId = bid.freelancer.user.id;
  const { isLoading, room } = useGetOrCreateRoom({ receiverUserId });

  if (isLoading || !room) {
    return <BidTabLoader />;
  }

  return (
    <Stack className={styles.jobMessenger}>
      <MessagesProvider>
        <MessagesBox room={room} />
        <TextBox room={room} />
      </MessagesProvider>
    </Stack>
  );
};
