import { ReferModalContent } from "@js/apps/common/components";
import type { EmployerOwnJob, MainJobListingJob } from "@js/types/jobs";
import { formatBudget, replaceVarsToText } from "@js/utils";

import { useGetExpandedJobDetails } from "../../hooks/use-get-expanded-job-details";

type ReferJobModalContentProps = {
  job: MainJobListingJob | EmployerOwnJob;
};

export const ReferJobModalContent = ({ job }: ReferJobModalContentProps) => {
  const { jobDetails } = useGetExpandedJobDetails(job.id);

  const isMainJobListingJob = "employer" in job;

  return (
    <ReferModalContent
      type="job"
      hideImage
      titleSize="medium"
      descriptionProps={{
        size: "medium",
      }}
      headline={replaceVarsToText(SETTINGS.JOB_REFERRAL_HEADLINE_MODAL, {
        token_reward: jobDetails?.token_reward || "",
      })}
      subtext={SETTINGS.JOB_REFERRAL_SUBTEXT}
      finePrint={SETTINGS.JOB_REFERRAL_FINE_PRINT}
      shareLinksParams={{
        subject: SETTINGS.JOB_REFERRAL_SHARE_COPY_EMAIL_SUBJECT,
        body: SETTINGS.JOB_REFERRAL_SHARE_COPY_EMAIL_BODY,
        twitterText: SETTINGS.JOB_REFERRAL_TWITTER_SHARE_COPY,
      }}
      params={{ job_id: `${job.id}` }}
      dictionary={{
        company: isMainJobListingJob ? job.employer.name : "",
        job_title: job.title,
        role_type: job.role.name,
        role_type_plural: job.role.name_plural,
        budget: isMainJobListingJob
          ? (formatBudget(job.budget_minimum_usd, job.budget_maximum_usd, {
              removeDecimal: true,
              paymentType: job.payment_type,
            }) as string)
          : "",
      }}
    />
  );
};
