export const globalConfig = {
  surpressDisplayGenericRequestError: false,
  surpressDisplaySnackbarOnBeforeUnload: false,
};

export enum NAV_ITEM_IDS {
  HOME = "Home",
  JOBS = "Jobs",
  CAREER_HELP = "Career Help",
  MY_POSTS = "My Posts",
  MY_WALLET = "My Wallet",
  EARN = "Earn",
  LEARN = "Learn",
  HEADER_SEARCH = "header-search",
  MESSENGER_OPEN = "messenger-open",
  BOOKMARKS = "bookmarks",
  NOTIFICATIONS_OPEN = "notifications-open",
}

export const UNEXPECTED_NETWORK_ERROR_MESSAGE = `Unexpected error occurred. Please make sure you have
    an internet connection and double-check if the actions you performed are properly reflected in the system.`;
