import React from "react";

import { GenericMessengerModalInstance } from "../../messengers/modal/manage-messenger-modal";

export const MessengerProvider = () => {
  return (
    <>
      <GenericMessengerModalInstance />
    </>
  );
};
