import { Stack } from "@hexocean/braintrust-ui-components";
import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CalendarIcon } from "@hexocean/braintrust-ui-components/Icons";
import { openLocationMismatchModal } from "@js/apps/admin/components/location-mismatch-modal";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import { LocationWarning } from "@js/apps/freelancer/components/location-warning";
import { useBidInterviewIntendedMutation } from "@js/apps/jobs/apps/bids/api";
import { LimitedFreelancerLinks } from "@js/apps/jobs/apps/bids/components/limited-freelancer-links";
import { ApprovedBadge } from "@js/apps/jobs/components/approved-badge-with-tooltip";
import { handleBookingTalentsCalendarClick } from "@js/apps/jobs/utils";
import { RouterLink } from "@js/components/link";
import type { EmployerBidListBid, Job } from "@js/types/jobs";
import { pluralize } from "@js/utils";

import { BidCardLabels, BidItemStatusLabel } from "../components/components";
import {
  getBidRole,
  getBidUserLocation,
  getBidUserName,
  getRateLabel,
} from "../helpers";

import style from "./style.module.scss";

type UserDetailsProps = {
  bid: EmployerBidListBid;
  job: Job;
  isMobile: boolean;
};

export const UserDetails = ({ bid, job, isMobile }: UserDetailsProps) => {
  const isNodeStaff = useIsNodeStaff();
  const isLocationMismatched =
    bid.freelancer.location_mismatch || !bid.freelancer.location;
  const displayLocationMismatch = isLocationMismatched && isNodeStaff;

  const handleLocationWarningClick = () => {
    openLocationMismatchModal({ userId: bid.freelancer.id });
  };

  const bidRoleAndUserLocation = [getBidRole(bid), getBidUserLocation(bid)]
    .filter(Boolean)
    .join(" - ");

  return (
    <Stack ml={2} justifyContent="center" minWidth={0}>
      <Box mt={-0.5}>
        <Box display="flex" gap={1}>
          <Typography
            variant="label"
            size="medium"
            component="h3"
            ellipsis
            noTextDecoration
            sx={{ display: "block" }}
            to={bid.freelancer.link}
            target="_blank"
            RouterLink={RouterLink}
          >
            {getBidUserName(bid)}
          </Typography>
          <span>
            <ApprovedBadge
              isApproved={bid.freelancer.user.freelancer_approved}
            />
          </span>
        </Box>
        <Box display="flex" gap={0.5} alignItems="flex-end">
          <Typography mt={-0.5} variant="paragraph" size="small" component="h4">
            {bidRoleAndUserLocation}
          </Typography>
          {displayLocationMismatch && (
            <LocationWarning onClick={handleLocationWarningClick} />
          )}
        </Box>
      </Box>
      {!isMobile && <BidCardLabels bid={bid} job={job} />}
    </Stack>
  );
};

type BidOverviewProps = UserDetailsProps;

export const BidOverview = ({ bid, job, isMobile }: BidOverviewProps) => {
  const [bidInterviewIntended] = useBidInterviewIntendedMutation();

  return (
    <Box className={style.freelancerOverview}>
      {isMobile && <BidCardLabels bid={bid} job={job} />}
      <Box className={style.freelancerDetails}>
        <BidItemStatusLabel bid={bid} />
        {!!bid.freelancer.years_experience && (
          <Typography component="h5" title="Experience">
            {bid.freelancer.years_experience} year
            {pluralize(bid.freelancer.years_experience)} in{" "}
            <Typography component="span" variant="label">
              {bid.freelancer.role.name}
            </Typography>
          </Typography>
        )}
        <Typography component="h5">{getRateLabel(job, bid)}</Typography>
      </Box>
      <Box className={style.freelancerLinks}>
        <LimitedFreelancerLinks
          externalProfiles={bid.freelancer.external_profiles}
          IconButtonProps={{ variant: "white-border-beige" }}
        />
        {bid.calendar_url && (
          <IconButton
            onClick={async () =>
              await handleBookingTalentsCalendarClick(bid, bidInterviewIntended)
            }
            size="x-small"
            variant="white-border-beige"
            rel="noopener noreferrer"
            aria-label="Open calendar"
          >
            <CalendarIcon style={{ fontSize: "16px", margin: "0px 1px" }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
