import type { ChangeEvent } from "react";
import React, { useEffect, useMemo } from "react";
import type {
  TypedWrappedFieldProps,
  WrappedFieldInputProps,
} from "redux-form";
import { Field, Fields, formValueSelector } from "redux-form";

import type { SelectProps } from "@hexocean/braintrust-ui-components";
import { Box, Stack, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { actionTypes } from "@js/apps/give-and-get-help/action-types";
import { categoryAdded } from "@js/apps/give-and-get-help/actions";
import {
  useGetPostCategoriesQuery,
  useGetPostQuery,
} from "@js/apps/give-and-get-help/api";
import type { PostCategoryOption } from "@js/apps/give-and-get-help/components/category-and-budget-utils";
import {
  getBudgetOptionsObj,
  getPostCategoriesOptions,
} from "@js/apps/give-and-get-help/components/category-and-budget-utils";
import { PostAttachmentsWrapper } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/attachments-wrapper";
import { CreatePostBudget } from "@js/apps/give-and-get-help/components/create-post-category-and-budget";
import { CreatePostAttachmentFields } from "@js/apps/give-and-get-help/components/openable-fields";
import type { CreatePostAttachmentFieldType } from "@js/apps/give-and-get-help/context";
import { useCreatePostOpenedFieldsContext } from "@js/apps/give-and-get-help/context";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import {
  CREATE_POST_FORM_ID,
  EDIT_POST_FORM_ID,
} from "@js/apps/give-and-get-help/form/constants";
import { PostDescriptionField } from "@js/apps/give-and-get-help/forms/fields/post-description-field";
import { useOnUserStartedTyping } from "@js/apps/give-and-get-help/hooks/";
import { formatPostTitleValue } from "@js/apps/give-and-get-help/utils/posts";
import type { FieldType } from "@js/forms/fields";
import { SelectField } from "@js/forms/fields";
import { BasicInputField } from "@js/forms/fields/basic-input";
import { maxLength, required } from "@js/forms/validators";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { PostCategory } from "@js/types/give-and-get-help";

import styles from "./style.module.scss";

const postTitleLengthValidator = maxLength(100);

export type CreateOrEditPostStepOneContentProps = {
  disablePoll?: boolean;
  errors: string[];
  isEditingPost?: boolean;
  linksEditOnClickActive?: boolean;
  openField: (field: CreatePostAttachmentFieldType) => void;
};

export const CreateOrEditPostStepOneContent = ({
  disablePoll,
  errors,
  isEditingPost,
  linksEditOnClickActive,
  openField,
}: CreateOrEditPostStepOneContentProps) => {
  const isMobile = useMediaQuery("md");
  const { data } = useGetPostCategoriesQuery();
  const { openedFields } = useCreatePostOpenedFieldsContext();
  const categoriesRawData = useExtendCategoriesWithPostCategory(data);
  const location = usePostLocationContext();
  const categories = useMemo(
    () => getPostCategoriesOptions(categoriesRawData, false),
    [categoriesRawData],
  );

  const selector = formValueSelector(
    !!isEditingPost ? EDIT_POST_FORM_ID : CREATE_POST_FORM_ID,
  );

  const category = useAppSelector(
    (state) => selector(state, "category") as unknown[],
  );
  const noCategory = errors.includes("category") && !category;

  const attachmentsCount = useAppSelector(
    (state) => selector(state, "attachments")?.length ?? 0,
  );
  const linksCount = useAppSelector(
    (state) => selector(state, "links")?.length ?? 0,
  );
  const linksFromTextCount = useAppSelector(
    (state) => selector(state, "links_from_text")?.length ?? 0,
  );

  const shouldAttachmentFieldsRender =
    !!openedFields.length ||
    !!linksCount ||
    !!attachmentsCount ||
    !!linksFromTextCount;

  return (
    <Stack sx={{ height: "100%" }}>
      <Box>
        <Field
          name="title"
          component={TitleField}
          isMobile={isMobile}
          validate={[required, postTitleLengthValidator]}
        />
      </Box>
      <Box
        className={styles.editorWrapper}
        sx={{ mt: { sm: 0.5 }, flexGrow: 1 }}
      >
        <PostDescriptionField
          location={location}
          shouldAttachmentFieldsRender={shouldAttachmentFieldsRender}
        />
      </Box>

      {shouldAttachmentFieldsRender ? (
        <Box display="flex" gap={2} mb={2.5} flexDirection="column">
          <CreatePostAttachmentFields
            disablePoll={disablePoll}
            isEditingPost={isEditingPost}
          />
          <Fields
            names={[
              "links",
              "links_metadata",
              "attachments",
              "links_metadata_from_text",
              "links_from_text",
              "excluded_links_from_text",
            ]}
            linksEditOnClickActive={linksEditOnClickActive}
            handleEditLinkClick={() => {
              openField("link");
            }}
            component={PostAttachmentsWrapper}
          />
        </Box>
      ) : null}
      <Field
        id="category"
        name="category"
        component={CategoryField}
        error={noCategory}
        options={categories}
      />
    </Stack>
  );
};

const TitleField = ({
  isMobile,
  input,
  meta,
}: TypedWrappedFieldProps<string> & { isMobile: boolean }) => {
  const location = usePostLocationContext();
  useOnUserStartedTyping({
    input,
    meta,
    action: {
      type: actionTypes["create/user-started-typing-title"],
      payload: {
        composer_location: location,
      },
    },
  });

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const valueWithoutBreakLines = formatPostTitleValue(event.target.value);
    input.onChange(valueWithoutBreakLines);
  };

  return (
    <BasicInputField
      size="medium"
      className={styles.title}
      fontSize={isMobile ? 26 : 36}
      fullWidth
      multiline
      autoComplete="off"
      placeholder="Add a title"
      input={{ ...input, onChange } as WrappedFieldInputProps}
      meta={meta}
      autoFocus={true}
    />
  );
};

type SelectPropsWithoutOptions = Omit<SelectProps, "options">;

type CategoryFieldProps = FieldType<SelectPropsWithoutOptions> & {
  options: PostCategoryOption[];
};

const CategoryField = ({ input, meta, error, options }: CategoryFieldProps) => {
  const dispatch = useAppDispatch();
  const location = usePostLocationContext();

  useEffect(() => {
    if (!!input.value) {
      dispatch(
        categoryAdded({ value: input.value, composer_location: location }),
      );
    }
  }, [dispatch, input.value, location]);

  useEffect(() => {
    const finded = options?.find(
      (item) => item.name === "Portfolio + Resume Help",
    );
    if (location === "talent" && finded?.id && options?.length)
      input.onChange(finded?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, options]);

  const { value } = input;
  const processedValue = useMemo(() => {
    if (!options.length) {
      return ""; // silence mui error
    }

    return value;
  }, [value, options]);

  return (
    <SelectField
      input={{ ...input, value: processedValue }}
      meta={meta}
      id="category"
      className={styles.categorySelect}
      error={error}
      placeholder="Choose a category"
      style={{
        borderRadius: "10px",
        borderColor: "var(--soft-beige)",
        color: "var(--black)",
      }}
      options={options}
    />
  );
};

type CreateOrEditPostStepTwoContentProps = {
  editedPostId?: number;
};

export const CreateOrEditPostStepTwoContent = ({
  editedPostId,
}: CreateOrEditPostStepTwoContentProps) => {
  const { data, isLoading } = useGetPostCategoriesQuery();

  const isMobile = useMediaQuery("md");
  const categoriesRawData = useExtendCategoriesWithPostCategory(
    data,
    editedPostId,
  );

  if (isLoading || !categoriesRawData) return null;

  const budgetOptions = getBudgetOptionsObj(categoriesRawData);

  return (
    <>
      <Typography
        component="h2"
        variant="title"
        size={isMobile ? "small" : "medium"}
        fontWeight={400}
      >
        Add a BTRST price to your post (Optional)
      </Typography>

      <Typography component="p" variant="paragraph" mt={1} mb={4}>
        Let professionals know how much you are willing to pay for their advice.
        Then, select the right mentor, pay in BTRST for your session, and
        uplevel your career.
      </Typography>

      <Fields
        names={["budget", "category"]}
        component={CreatePostBudget}
        budgetOptions={budgetOptions}
      />
    </>
  );
};

const useExtendCategoriesWithPostCategory = (
  categories?: PostCategory[],
  postId?: number,
) => {
  const { data: post } = useGetPostQuery(
    { id: postId as number },
    { skip: !postId },
  );
  const postCategory = post?.category;
  const isExtendCategories =
    postCategory &&
    categories?.length &&
    !categories?.find((category) => category.id === postCategory?.id);

  /* If edited post have deprecated category we still want to show it or edit budget.
   *  To do this we want to extract the category from the post because the backend no longer returns it */
  return isExtendCategories ? [...categories, postCategory] : categories;
};
