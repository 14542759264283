import type React from "react";
import { useEffect } from "react";
import _ from "underscore";

type Args<T> = {
  formValue: unknown;
  initialTaxonomyLoading: boolean;
  setValue: React.Dispatch<React.SetStateAction<T | null>>; // null for initial state
  options: T[] | undefined; // undefined for initial state
  isValueEqualFormValue: boolean;
  findOption: (option: T) => boolean;
};

/**
 * @description required in cases like:
 * - form value is changed by different field/action
 * - reset value after form submission
 * @arg findOption -- `array.find` function callback. Option should be compared to `formValue`
 */
export const useSynchronizeSingleValueInternalStateWithFormValue = <T>({
  formValue,
  initialTaxonomyLoading,
  setValue,
  options,
  isValueEqualFormValue,
  findOption,
}: Args<T>) => {
  useEffect(() => {
    if (
      !initialTaxonomyLoading &&
      !!formValue &&
      !_.isObject(formValue) &&
      !isValueEqualFormValue
    ) {
      const found = options?.find(findOption);

      // universal object with all possible  keys
      const fallbackValue = {
        id: Number(formValue),
        value: String(formValue),
        name: String(formValue),
        label: String(formValue),
        name_and_email: String(formValue),
        title: String(formValue),
      } as unknown as T;

      setValue(found || fallbackValue);
    } else if (!formValue) {
      // sometimes `options` contains `null` or `` on purpose
      const found = options?.find(findOption);
      setValue(found || null);
    }

    // we want to react only on external value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue]);
};
