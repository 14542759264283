import { useCallback, useEffect } from "react";

import { Snackbar } from "@js/components/snackbar";

import {
  useGetEmployerReferralsQuery,
  useGetEmployerReferralsSummaryQuery,
} from "../../api";
import { getReferralParams } from "../../helpers";
import { useEmployerReferralsFilters } from "../use-employer-referrals-filters";

export const useEmployerReferrals = () => {
  const { filters, setCurrentPage } = useEmployerReferralsFilters();

  const {
    data: summary,
    isLoading: isLoadingSummary,
    error: fetchingSummaryError,
  } = useGetEmployerReferralsSummaryQuery();

  const {
    data: referrals,
    isFetching: isFetchingReferrals,
    error: isReferralsError,
  } = useGetEmployerReferralsQuery(filters);

  const nextPage = referrals?.next ? getReferralParams(referrals?.next) : null;
  const error = isReferralsError || fetchingSummaryError;

  useEffect(() => {
    if (error) {
      Snackbar.error("Failed to fetch referrals");
    }
  }, [error]);

  const fetchMore = useCallback(() => {
    if (nextPage) {
      setCurrentPage(nextPage);
    }
  }, [nextPage, setCurrentPage]);

  return {
    referrals,
    fetchMore,
    summary,
    isLoadingSummary,
    isFetchingReferrals,
    filters,
  };
};
