import React from "react";
import { Field } from "redux-form";

import { Grid } from "@hexocean/braintrust-ui-components";
import { RadioListField } from "@js/forms/fields";
import type { PaymentMethod } from "@js/types/payments";

import type { PaymentMethodListItemProps } from "./item";
import { PaymentMethodListItem } from "./item";

type PaymentMethodsListProps = {
  paymentMethods: PaymentMethod[];
} & Omit<PaymentMethodListItemProps, "paymentMethod">;

export const PaymentMethodsList = ({
  paymentMethods,
  ...props
}: PaymentMethodsListProps) => {
  if (!paymentMethods.length) {
    return null;
  }

  return (
    <Grid container spacing={4}>
      {paymentMethods.map((paymentMethod) => (
        <Grid item lg={10} xs={12} key={paymentMethod.id}>
          <PaymentMethodListItem {...props} paymentMethod={paymentMethod} />
        </Grid>
      ))}
    </Grid>
  );
};

type SelectablePaymentMethodsListProps = {
  paymentMethodsOptions: {
    value: PaymentMethod;
    label: JSX.Element;
  }[];
  disabled?: boolean;
  name?: string;
};

export const SelectablePaymentMethodsList = ({
  paymentMethodsOptions,
  disabled,
  name,
}: SelectablePaymentMethodsListProps) => {
  return (
    <Field
      name={name || "payment_method"}
      label="Payment method"
      component={RadioListField}
      options={paymentMethodsOptions}
      disabled={disabled}
    />
  );
};
