import type { AvatarColors } from "@hexocean/braintrust-ui-components";

export const getColor = (
  type: "freelancer" | "employer" | undefined | null,
  color?: AvatarColors,
  thumbnail?: string | null,
) => {
  if (thumbnail) return "white";
  return color ? color : type === "freelancer" ? "dark-blue" : "medium-violet";
};
