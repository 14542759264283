import cs from "classnames";

import type { IconButtonProps } from "@hexocean/braintrust-ui-components";
import { IconButton } from "@hexocean/braintrust-ui-components";
import { BookmarkIcon } from "@hexocean/braintrust-ui-components/Icons";
import { AnimatedStarsClick } from "@js/components/animations";
import type { MainJobListingJob } from "@js/types/jobs";

import { useBookmarkJobButton } from "./use-bookmark-job";

import styles from "./styles.module.scss";

type BookmarkJobButtonProps = Pick<
  IconButtonProps,
  "disabled" | "className"
> & {
  job: MainJobListingJob;
};

export const BookmarkJobButton = ({
  job,
  disabled,
  className,
}: BookmarkJobButtonProps) => {
  const { isBookmarked, handleClick, loading } = useBookmarkJobButton({
    job,
  });

  return (
    <AnimatedStarsClick
      prevent={isBookmarked}
      colors={["var(--warning-2)", "var(--medium-violet)", "#FFBB8D"]}
      starSize={10}
      starCount={15}
      maxRadius={40}
      minRadius={25}
    >
      <IconButton
        aria-label="save-job-button"
        aria-selected={isBookmarked}
        disableRipple
        variant={
          isBookmarked ? "positive-green-with-hover" : "transparent-with-hover"
        }
        disabledType="opacity"
        name="save-job-button"
        size="small"
        onClick={handleClick}
        disabled={disabled || loading}
        className={cs(styles.saveJobIconButton, className)}
      >
        {isBookmarked ? (
          <BookmarkIcon className={styles.saveJobIconButtonActive} />
        ) : (
          <BookmarkIcon />
        )}
      </IconButton>
    </AnimatedStarsClick>
  );
};
