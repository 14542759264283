import type { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/react";

import { API } from "@js/api";

import { universalSearchMiddleware } from "./apps/universal-search/universal-search-slice";
import { CLOSE_MODAL, OPEN_MODAL } from "./components/modal/action-types";
import { PING, PONG } from "./components/websocket/action-types";
import { analyticsMiddleware } from "./middlewares/analytics";
import { reduxFormScrollToFirstErrorMiddleware } from "./middlewares/scroll-to-first-error-middleware";
import { appReducer } from "./reducers";

const SENTRY_ACTIONS_TO_SKIP = [PING, PONG];
const SENTRY_ACTIONS_WITH_PAYLOAD = [OPEN_MODAL, CLOSE_MODAL];

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Return null to not send the state.
  stateTransformer: () => null,
  // Return an action without payload.
  actionTransformer: (action) => {
    if (SENTRY_ACTIONS_TO_SKIP.includes(action.type)) {
      return null;
    }

    if (!SENTRY_ACTIONS_WITH_PAYLOAD.includes(action.type)) {
      const { payload, ...actionWithoutPayload } = action;
      return actionWithoutPayload;
    }

    return action;
  },
});

const middlewares = [
  reduxFormScrollToFirstErrorMiddleware,
  analyticsMiddleware,
  universalSearchMiddleware,
  API.middleware,
];

const rootReducer = combineReducers(appReducer);

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middlewares),
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers().concat(sentryReduxEnhancer),
  });
}

export const store = setupStore();

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkAction<T = unknown> = ThunkAction<
  T,
  RootState,
  unknown,
  AnyAction
>;
