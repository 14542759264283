import type { WrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { isError, isTouched } from "@js/forms/utils";

import { GradingCriteriaComboBox } from "./grading-criteria-combobox";
import { SectionHeader } from "./section-header";
import { SelectedGradingCriteriaList } from "./selected-grading-criteria-list";

export const JobInterviewGradingCriteria = (
  interview_criteria: WrappedFieldProps,
) => {
  const isErrorInSection = isError([interview_criteria]);
  const isAnyTouched = isTouched([interview_criteria]);
  const displayError = isErrorInSection && isAnyTouched;

  return (
    <>
      <SectionHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "32px",
          borderRadius: "16px",
          backgroundColor: "var(--white)",
          marginTop: "-40px",
          marginBottom: "32px",
          border: displayError
            ? "1px solid var(--negative-2)"
            : "1px solid var(--grey-4)",
        }}
      >
        <Typography component="h1" size="large" fontWeight={400}>
          Grading criteria
        </Typography>
        <Typography component="span" size="small" fontWeight={400}>
          Questions will be automatically graded on a scale from 1 to 5 (1 least
          proficient, 5 most proficient). Add your own by removing one of the
          suggested criteria below.
        </Typography>
        <Field name="interview_criteria" component={GradingCriteriaComboBox} />
        <Field
          name="interview_criteria"
          component={SelectedGradingCriteriaList}
        />
        {displayError && (
          <Box
            sx={{
              background: "#F7626240",
              padding: "8px 16px",
              borderRadius: "8px",
            }}
          >
            <Typography component="p" size="small" fontWeight={500}>
              Need additional grading criteria
            </Typography>
            <Typography component="p" size="small">
              {interview_criteria.meta?.error}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
