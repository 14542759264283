import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import type { Space } from "@js/types/spaces";

import type { SpaceMemberAvatarData } from "../space-members";
import { SpaceMembers } from "../space-members";

import type { SpaceActivitiesProps } from "./space-activities";
import { SpaceActivities } from "./space-activities";

import style from "./style.module.scss";

interface SpaceActivityProps extends SpaceActivitiesProps {
  space: Space;
  members: SpaceMemberAvatarData[];
  membersCount: number;
  membersSrc: string;
}

export const SpaceActivity = (props: SpaceActivityProps) => {
  return (
    <div className={style.activityContainer}>
      <Typography component="h2" size="large" mb={2}>
        Activity
      </Typography>
      <SpaceActivityContent {...props} />
    </div>
  );
};

export const SpaceActivityContent = ({
  members,
  membersCount,
  membersSrc,
  space,
  ...spaceActivitiesProps
}: SpaceActivityProps) => {
  return (
    <div>
      <SpaceActivities {...spaceActivitiesProps} sx={{ mb: 2 }} />
      <SpaceMembers
        space={space}
        members={members}
        membersCount={membersCount}
        membersSrc={membersSrc}
      />
    </div>
  );
};
