import { Typography } from "@hexocean/braintrust-ui-components";

type WriteReviewBasicButtonProps = {
  onClick: () => void;
};
export const WriteReviewBasicButton = ({
  onClick,
}: WriteReviewBasicButtonProps) => {
  return (
    <Typography
      onClick={onClick}
      component="p"
      variant="paragraph"
      pointer
      sx={{
        textDecoration: "underline",
        float: "right",
      }}
    >
      Write a review
    </Typography>
  );
};
