import React, { useMemo } from "react";

import { CardCarouselSection } from "@js/apps/freelancer/components/home-carousel";
import { JobForYouCarouselItem } from "@js/apps/freelancer/components/home-carousel/job-carousel-item";
import { useGetNewestJobsQuery } from "@js/apps/jobs/api";
import { getViewedPublicJobs } from "@js/apps/jobs/apps/public-listing/helpers/viewed-public-jobs";

export const NewestJobsSection = React.forwardRef((props, ref) => {
  const filters = useMemo(() => {
    const viewedPublicJobs = getViewedPublicJobs();
    const newestJobsFilters: { page_size: number; jobs_ids?: number[] } = {
      page_size: 12,
    };
    if (viewedPublicJobs.length) {
      newestJobsFilters.jobs_ids = viewedPublicJobs;
    }

    return newestJobsFilters;
  }, []);

  const { data: newestJobs, isLoading } = useGetNewestJobsQuery(filters);

  return (
    <CardCarouselSection
      title="Jobs for you"
      url="/jobs"
      items={newestJobs?.results}
      loading={isLoading}
      ref={ref}
      transitionProps={props}
      renderItem={(item) => {
        return <JobForYouCarouselItem job={item} />;
      }}
      extendedWidth
    />
  );
});
