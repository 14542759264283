import React, { useMemo } from "react";

import { CardCarouselSection } from "@js/apps/freelancer/components/home-carousel";
import { JobForYouCarouselItem } from "@js/apps/freelancer/components/home-carousel/job-carousel-item";
import useApplicationInsightIntervalAds, {
  ApplicationInsightType,
} from "@js/apps/freelancer/hooks/application-insight-interval-ads";
import { useGetRelevantJobsQuery } from "@js/apps/jobs/api";
import { useShowInsightFeatureFlag } from "@js/apps/jobs/components/application-insight-feature-flag-wrapper/hooks";

export const JobsForYouSection = React.forwardRef((props, ref) => {
  const filters = useMemo(() => ({ page_size: 12 }), []);
  const { isLoading, data } = useGetRelevantJobsQuery(filters);
  const { showJobInsightAds, setCloseJobInsightAds } =
    useApplicationInsightIntervalAds(
      ApplicationInsightType.HOME_PAGE_NEWEST_MATCH_CARD,
    );
  const isEnabledInsightFeatureFlag = useShowInsightFeatureFlag();

  const relevantJobs = data?.results;

  return (
    <CardCarouselSection
      title="Your newest matches"
      url="/jobs"
      items={relevantJobs}
      loading={isLoading}
      ref={ref}
      transitionProps={props}
      renderItem={(item) => <JobForYouCarouselItem job={item} />}
      extendedWidth
      showJobInsightsAds={showJobInsightAds && isEnabledInsightFeatureFlag}
      onCloseJobInsightAds={setCloseJobInsightAds}
    />
  );
});
