import { useEffect, useMemo } from "react";

import { fetchSavedFilters } from "@js/apps/common/components/save-filters/actions";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { SavedJobFilter } from "@js/types/jobs";

type SavedSearchesType = () => {
  savedFilters: SavedJobFilter[];
  processingItems: SavedJobFilter["id"][];
  savedFiltersSkillsIds: number[];
};
export const useSavedSearches: SavedSearchesType = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSavedFilters("job_filters"));
  }, [dispatch]);

  const savedFilters = useAppSelector(
    (state) => state.savedFilters.filtersJobs,
  );
  const processingItems = useAppSelector(
    (state) => state.savedFilters.processingItems,
  );
  const savedFiltersSkillsIds = useMemo(() => {
    return [
      ...new Set(
        savedFilters
          .filter((filter) => !!filter?.filters?.skills)
          .map((filter) => {
            return (filter.filters.skills as string)
              .split(",")
              .map((skillString: string) => Number(skillString));
          })
          .flat(),
      ),
    ];
  }, [savedFilters]);
  return {
    savedFilters,
    processingItems,
    savedFiltersSkillsIds,
  };
};
