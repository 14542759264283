import { useCallback } from "react";
import type { DecoratedFormProps } from "redux-form";
import { SubmissionError } from "redux-form";

import { ModalInstance } from "@js/components/modal";
import type { AppDispatch } from "@js/store";

import {
  useSaveFreelancerRoleMutation,
  useUpdateFreelancerRoleMutation,
} from "../../api";
import type { RolesFormData } from "../../forms/role-form";

type UseRoleModuleFormProps = {
  onSave: (addAnother?: boolean) => void;
  isEdit?: boolean;
  persistModalInstance?: boolean;
};

export const useRoleModuleForm = ({
  onSave,
  isEdit,
  persistModalInstance,
}: UseRoleModuleFormProps) => {
  const [saveRole] = useSaveFreelancerRoleMutation();
  const [updateRole] = useUpdateFreelancerRoleMutation();

  const onSubmitSuccess = useCallback(
    (_result: undefined, _dispatch: AppDispatch, props: DecoratedFormProps) => {
      onSave(props.values?.add_another);

      if (props.reset) {
        props.reset();
      }

      if (!props.values?.add_another && !isEdit && !persistModalInstance) {
        ModalInstance.close();
      }
    },
    [onSave, isEdit, persistModalInstance],
  );

  const onSubmit = useCallback(
    async (data: RolesFormData) => {
      if (!data.selected_role || !data.years_experience) {
        return;
      }

      try {
        if (isEdit && data.id) {
          await updateRole({
            id: data.id,
            primary: !!data.primary,
            role: data.selected_role,
            years_experience: data.years_experience,
            primary_freelancer_role: data.primary_freelancer_role,
          }).unwrap();
        } else {
          await saveRole({
            primary: !!data.primary,
            role: data.selected_role,
            years_experience: data.years_experience,
          }).unwrap();
        }
      } catch (err: any) {
        if (isValidSubmissionError(err)) {
          throw new SubmissionError(err.data);
        }
        throw new SubmissionError({ _error: "Unknown error" });
      }
    },
    [isEdit, saveRole, updateRole],
  );

  return { onSubmit, onSubmitSuccess };
};

// TODO: remove this function once issue with wrong error format gets fixed on backend (https://app.asana.com/0/1202098411876250/1204235388985845/f)
const isValidSubmissionError = (error: any) => {
  const knownProperties: Array<keyof RolesFormData> = [
    "add_another",
    "primary",
    "primary_freelancer_role",
    "selected_role",
    "years_experience",
  ];
  return (
    !!error &&
    !!error.data &&
    typeof error.data === "object" &&
    ("_error" in error.data || knownProperties.some((p) => p in error.data))
  );
};
