import type { ComponentType, ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import type {
  ConfigProps,
  DecoratedFormProps,
  InjectedFormProps as NativeInjectedFormProps,
} from "redux-form";
import { reduxForm as nativeReduxForm } from "redux-form";

import type { UseUnsavedChangesWarningArg } from "@js/forms/hooks/unsaved-changes-warning";
import { useUnsavedChangesWarning } from "@js/forms/hooks/unsaved-changes-warning";

type NativeReduxFormProps<FormData, P, ErrorType> =
  | ConfigProps<FormData, P, ErrorType>
  | Partial<ConfigProps<FormData, P, ErrorType>>;

type CustomReduxFormProps = Omit<UseUnsavedChangesWarningArg, "formId"> & {
  form: string;
};

type DefaultFormComponentExtendedProps = { children?: ReactNode };

export type ReduxFormConfiguration<
  FormData = object,
  FormComponentExtendedProps = DefaultFormComponentExtendedProps,
  ErrorType = string,
> = NativeReduxFormProps<FormData, FormComponentExtendedProps, ErrorType> &
  CustomReduxFormProps;

export type InjectedFormProps<
  FormData = object,
  FormComponentExtendedProps = DefaultFormComponentExtendedProps,
  ErrorType = string,
> = NativeInjectedFormProps<FormData, FormComponentExtendedProps, ErrorType> &
  FormComponentExtendedProps;

// overload the function to indicate that returned component defaultPops are not undefined
export function reduxForm<
  FormData = object,
  FormComponentExtendedProps = DefaultFormComponentExtendedProps,
  ErrorType = string,
  Config extends ReduxFormConfiguration<
    FormData,
    FormComponentExtendedProps,
    ErrorType
  > = ReduxFormConfiguration<FormData, FormComponentExtendedProps, ErrorType>,
>(
  reduxFormConfiguration: Config,
): (
  FormComponent: ComponentType<
    InjectedFormProps<FormData, FormComponentExtendedProps, ErrorType>
  >,
) => {
  (formProps: Config & FormComponentExtendedProps): JSX.Element | null;
  defaultProps: Config;
};
export function reduxForm<
  FormData = object,
  FormComponentExtendedProps = DefaultFormComponentExtendedProps,
  ErrorType = string,
  Config extends ReduxFormConfiguration<
    FormData,
    FormComponentExtendedProps,
    ErrorType
  > = ReduxFormConfiguration<FormData, FormComponentExtendedProps, ErrorType>,
>(
  reduxFormConfiguration?: undefined,
): (
  FormComponent: ComponentType<
    InjectedFormProps<FormData, FormComponentExtendedProps, ErrorType>
  >,
) => {
  (formProps: Config & FormComponentExtendedProps): JSX.Element | null;
  defaultProps: undefined;
};
export function reduxForm<
  FormData = object,
  FormComponentExtendedProps = DefaultFormComponentExtendedProps,
  ErrorType = string,
  Config extends ReduxFormConfiguration<
    FormData,
    FormComponentExtendedProps,
    ErrorType
  > = ReduxFormConfiguration<FormData, FormComponentExtendedProps, ErrorType>,
>(reduxFormConfiguration?: Config) {
  return (
    FormComponent: ComponentType<
      InjectedFormProps<FormData, FormComponentExtendedProps, ErrorType>
    >,
  ) => {
    const ReduxForm = nativeReduxForm<
      FormData,
      FormComponentExtendedProps,
      ErrorType
    >({})(
      FormComponent as ComponentType<
        FormComponentExtendedProps &
          InjectedFormProps<FormData, FormComponentExtendedProps, ErrorType>
      >,
    );

    const Result = (formProps: Config & FormComponentExtendedProps) => {
      const _formProps = useMemo(
        () => ({ ...reduxFormConfiguration, ...formProps }),
        [formProps],
      );
      const {
        leaveMessage,
        unsavedChangesWarning,
        hideUnsavedChangesWarningURLs,
        unsavedChangesWarningModelType,
        ...rest
      } = _formProps;

      const [unmounted, setUnmounted] = useState(false);
      useEffect(() => {
        // to fix warning about update of unmounted component
        return () => {
          setUnmounted(true);
        };
      }, []);

      useUnsavedChangesWarning({
        formId: rest.form,
        hideUnsavedChangesWarningURLs,
        leaveMessage,
        unsavedChangesWarning,
        unsavedChangesWarningModelType,
      });

      if (unmounted) {
        return null;
      }

      return (
        <ReduxForm
          {...(rest as DecoratedFormProps<
            FormData,
            FormComponentExtendedProps,
            ErrorType
          >)}
        />
      );
    };

    Result.defaultProps = reduxFormConfiguration;

    return Result;
  };
}

export { WARNING_MODAL_TYPE } from "../../hooks/unsaved-changes-warning";
