import type { ChangeEventHandler } from "react";
import React from "react";
import cs from "classnames";

import type { TextFieldUnionProps } from "@hexocean/braintrust-ui-components";
import {
  InputAdornment,
  RegularTextField,
} from "@hexocean/braintrust-ui-components";
import { SearchSVGIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { TypedWrappedFieldProps } from "@js/forms/utils";

import styles from "./style.module.scss";

export type InversedSearchFieldProps = {
  input: TypedWrappedFieldProps<string>["input"];
  meta: { error?: string; touched: boolean };
  InputProps?: TextFieldUnionProps["InputProps"];
  placeholder: string;
  className?: string;
  id?: string;
  onKeyDown?: TextFieldUnionProps["onKeyDown"];
};

export const InversedSearchField = ({
  InputProps,
  input: { value, onChange },
  meta: { error, touched },
  placeholder,
  onKeyDown,
  ...rest
}: InversedSearchFieldProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    onChange(ev.target.value);
  };

  return (
    <RegularTextField
      value={value}
      onChange={handleChange}
      {...rest}
      onKeyDown={onKeyDown}
      variant="outlined"
      fullWidth
      placeholder={placeholder}
      error={!!error && touched}
      helperText={error && touched ? error : null}
      className={cs(styles.search, rest.className)}
      InputProps={{
        ...InputProps,
        notched: false,
        startAdornment: (
          <InputAdornment position="start">
            <SearchSVGIcon className={styles.icon} />
          </InputAdornment>
        ),
      }}
    />
  );
};
