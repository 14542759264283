import { List } from "@hexocean/braintrust-ui-components";
import type { EmployerTeamMemberType } from "@js/types/employer";

import { CommonTeamMembersModalInvitationItem } from "../common-team-members-modal";

import { ShareWithTeamMemberInvitationsListMemberItem } from "./share-with-team-member-invitations-list-member-item";

type ShareWithTeamMemberInvitationsListProps = {
  jobOwnerId?: number;
  teamMembers: EmployerTeamMemberType[];
  teamMemberIdsToShare: number[];
  invitationsToShare: string[];
  toggleShareWithTeamMember: (teamMemberId: number) => void;
  toggleShareWithTeamMemberInvitation: (emailInvitation: string) => void;
};

export const ShareWithTeamMemberInvitationsList = ({
  jobOwnerId,
  teamMembers,
  teamMemberIdsToShare,
  invitationsToShare,
  toggleShareWithTeamMember,
  toggleShareWithTeamMemberInvitation,
}: ShareWithTeamMemberInvitationsListProps) => {
  return (
    <List sx={{ display: "flex", flexDirection: "column", p: 0, gap: 2 }}>
      {invitationsToShare.map((invitationEmail) => {
        const handleEmailInvitationRemove = () => {
          toggleShareWithTeamMemberInvitation(invitationEmail);
        };
        return (
          <CommonTeamMembersModalInvitationItem
            key={invitationEmail}
            invitationEmail={invitationEmail}
            onRemove={handleEmailInvitationRemove}
          />
        );
      })}
      {teamMembers.map((teamMember) => {
        const isJobOwner = teamMember.id === jobOwnerId;
        const isSelected = teamMemberIdsToShare.includes(teamMember.id);

        return (
          <ShareWithTeamMemberInvitationsListMemberItem
            key={teamMember.id}
            teamMember={teamMember}
            isJobOwner={isJobOwner}
            isSelected={isSelected}
            toggleTeamMember={() => toggleShareWithTeamMember(teamMember.id)}
          />
        );
      })}
    </List>
  );
};
