import type { BoxProps } from "@hexocean/braintrust-ui-components";
import { Box } from "@hexocean/braintrust-ui-components";

export const Spacer = ({
  width = "auto",
  height = "auto",
  className,
}: Pick<BoxProps, "width" | "height" | "className">) => {
  return <Box className={className} width={width} height={height} />;
};
