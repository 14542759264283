import React from "react";

import { JobStatusBox } from "@js/apps/jobs/components/job-status/components/job-status-box";
import type { ClosedStatusProps } from "@js/apps/jobs/components/job-status/employer/client-hired-braintrust-talent";

export const YouHiredXBraintrustTalent = ({
  count,
  status,
  ...props
}: ClosedStatusProps) => {
  return (
    <JobStatusBox color="var(--dark-blue)" {...props}>
      <div>
        <JobStatusBox.Header>{status}</JobStatusBox.Header>
        <JobStatusBox.Subheader>
          You Hired {count} Braintrust Talent
        </JobStatusBox.Subheader>
      </div>
    </JobStatusBox>
  );
};
