import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { ModalInstance } from "@js/components/modal";

import { useOnboardingPopup } from "./hook";

import styles from "./style.module.scss";

type OnboardingPopupProps = {
  onClose: () => void;
  onEmployerAccountClick: () => void;
  onPersonalAccountClick: () => void;
};

export const OnboardingPopup = ({
  onClose,
  onEmployerAccountClick,
  onPersonalAccountClick,
}: OnboardingPopupProps) => {
  const { handleEmployerAccountClick, handlePersonalAccountClick } =
    useOnboardingPopup({
      onEmployerAccountClick,
      onPersonalAccountClick,
    });

  return (
    <>
      <Header onClose={onClose} />
      <Content />
      <Footer
        onEmployerAccountClick={handleEmployerAccountClick}
        onPersonalAccountClick={handlePersonalAccountClick}
      />
    </>
  );
};

const Header = ({ onClose }: Pick<OnboardingPopupProps, "onClose">) => (
  <div className={styles.header}>
    <IconButton
      variant="tertiary"
      size="small"
      aria-label="Close onboarding confirmation popup"
      onClick={onClose}
      className={styles.closeButton}
    >
      <CloseIcon />
    </IconButton>
  </div>
);

const Content = () => (
  <Box mt={3} mb={6.5}>
    <Typography variant="title" size="medium" fontWeight={400} component="p">
      Ready to hire? Create an Employer account.
    </Typography>
    <Typography variant="paragraph" component="p" mt={1}>
      <Typography variant="label" component="span">
        To hire Talent, you'll need an Employer account.{" "}
      </Typography>
      To access the rest of Braintrust, including searching jobs, earning BTRST,
      getting help from the community, and more, continue creating a personal
      account.
    </Typography>
  </Box>
);

const Footer = ({
  onEmployerAccountClick,
  onPersonalAccountClick,
}: Pick<
  OnboardingPopupProps,
  "onEmployerAccountClick" | "onPersonalAccountClick"
>) => {
  const isMobile = useMediaQuery("sm");
  return (
    <Box
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      gap={1}
      justifyContent="flex-end"
    >
      <Button
        variant="secondary"
        shape="squared"
        onClick={onPersonalAccountClick}
      >
        Continue with a personal account
      </Button>
      <Button
        variant="primary"
        shape="squared"
        onClick={onEmployerAccountClick}
      >
        Get an Employer account
      </Button>
    </Box>
  );
};

export const openOnboardingConfirmationPopup = ({
  onEmployerAccountClick,
  onPersonalAccountClick,
}: Pick<
  OnboardingPopupProps,
  "onEmployerAccountClick" | "onPersonalAccountClick"
>) => {
  ModalInstance.open({
    children: (
      <OnboardingPopup
        onClose={ModalInstance.close}
        onEmployerAccountClick={onEmployerAccountClick}
        onPersonalAccountClick={onPersonalAccountClick}
      />
    ),
    className: styles.modalWrapper,
    keepOnBackdropClick: true,
    closeButton: false,
    padding: false,
  });
};
