import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { Tab, Tabs, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { normalizePath } from "@js/utils/url";

const OPTIONS = [
  {
    name: "All invoices",
    to: "/employer/invoices/",
  },
  {
    name: "Invoice batches",
    to: "/employer/invoices/invoice_batches/",
  },
];

export const EmployerInvoicesTabs = () => {
  const { pathname } = useLocation();
  const selectedSpaceTabVal = useMemo(() => {
    const currentTab = OPTIONS.find(
      ({ to }) => normalizePath(pathname) === normalizePath(to),
    );

    return currentTab ?? OPTIONS[0];
  }, [pathname]);

  return (
    <Tabs
      aria-label="Invoices page tabs"
      id="invoicePageTabs" // used as an anchor to scroll the element into the view
      value={selectedSpaceTabVal.to}
      sx={{ minHeight: "38px" }}
    >
      {OPTIONS.map(({ name, to }) => {
        return (
          <Tab
            key={name}
            value={to}
            to={to}
            component={RouterLink}
            sx={{
              minHeight: "38px",
              py: "6px",
            }}
            label={
              <Typography component="span" variant="label" size="medium">
                {name}
              </Typography>
            }
          />
        );
      })}
    </Tabs>
  );
};
