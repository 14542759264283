import React from "react";

export type MessengerContextData = {
  activeRoom: number | undefined;
  setActiveRoom: (roomId?: number) => void;
};

export const MessengerContext = React.createContext<MessengerContextData>({
  activeRoom: undefined,
  setActiveRoom: () => {},
});

export const useMessengerContext = () => {
  const data = React.useContext(MessengerContext);
  if (!data) {
    throw new Error(
      "useMessengerContext must be used within a MessengerContext",
    );
  }
  return data;
};
