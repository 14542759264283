import { useEffect } from "react";

import { useWebsocket } from "@js/hooks";

export const useSubscribeFeed = () => {
  const { send, isRunning } = useWebsocket();

  useEffect(() => {
    if (isRunning) {
      send(ENUMS.StreamType.POSTS_FEED_SUBSCRIBE);
    }

    return () => {
      send(ENUMS.StreamType.POSTS_UNSUBSCRIBE, {});
    };
  }, [isRunning, send]);
};
