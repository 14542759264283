import { Navigate } from "react-router-dom";

import { useIdParam } from "@js/hooks/use-id-param";

import { JobBidsPageContent } from "../../components/job-bids-page-content";
import { JobManagement } from "../../layout";

const JobBidsPage = () => {
  const jobId = useIdParam();
  if (!jobId) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  return (
    <JobManagement.Layout
      pageTitle="Job Applications"
      data-testid="job-bids-page"
    >
      <JobBidsPageContent jobId={jobId} />
    </JobManagement.Layout>
  );
};

export default JobBidsPage;
