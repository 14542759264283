import React from "react";

import { useAutoBlockUserWarningModal } from "../../hooks/auto-block-user-warning";

import { AutoBlockWarningModalInstance } from "./auto-block-modal-instance";

export const AutoBlockUserWarningModalProvider = () => {
  useAutoBlockUserWarningModal();
  return <AutoBlockWarningModalInstance />;
};
