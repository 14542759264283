import { useCallback } from "react";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { RootState } from "@js/store";

import { setSearchEventQueryId } from "../tracking/search-event-slice";

type UniversalSearchState = {
  search: string;
  debouncedSearch: string;
  searchPaused: boolean;
};

const initialState: UniversalSearchState = {
  search: "",
  debouncedSearch: "",
  searchPaused: false,
};

export const universalSearchSlice = createSlice({
  name: "universal-search",
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setDebouncedSearch(state, action: PayloadAction<string>) {
      state.debouncedSearch = action.payload;
    },
    setSearchPaused(state, action: PayloadAction<boolean>) {
      state.searchPaused = action.payload;
    },
  },
});

export const { setSearch, setDebouncedSearch, setSearchPaused } =
  universalSearchSlice.actions;

const getUniversalSearchPhrase = (state: RootState) =>
  state.universalSearch.search;

const getDebouncedUniversalSearchPhrase = (state: RootState) =>
  state.universalSearch.debouncedSearch;

const getUniversalSearchPaused = (state: RootState) =>
  state.universalSearch.searchPaused;

export const useUniversalSearchPhrase = () => {
  return useAppSelector(getUniversalSearchPhrase);
};

export const useDebouncedUniversalSearchPhrase = () => {
  return useAppSelector(getDebouncedUniversalSearchPhrase);
};

export const useUniversalSearchPaused = () => {
  return useAppSelector(getUniversalSearchPaused);
};

export const useResetUniversalSearchPhrase = () => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(setSearch(""));
    dispatch(
      setSearchEventQueryId({
        id: undefined,
        option: ENUMS.SearchEventOption.MAIN_SEARCH_BOX,
      }),
    );
  }, [dispatch]);
};

let timeout: NodeJS.Timeout;
const universalSearchListener = createListenerMiddleware();
universalSearchListener.startListening({
  actionCreator: setSearch,
  effect: async (action, universalSearch) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      universalSearch.dispatch(setDebouncedSearch(action.payload));
    }, 500);
  },
});

export const { middleware: universalSearchMiddleware } =
  universalSearchListener;
