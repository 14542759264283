import type { WriteReviewButtonProps } from "@js/apps/reviews/components/talent-profile-review-module/types";
import { WriteHelpOfferReviewButton } from "@js/apps/reviews/components/talent-profile-review-module/write-help-offer-review-button";
import { WriteJobReviewButton } from "@js/apps/reviews/components/talent-profile-review-module/write-job-review-button";
import { useCheckIfRedirectedToReview } from "@js/apps/reviews/hooks/check-if-redirected-to-review";
import { useAppSelector } from "@js/hooks";

export const WriteReviewButton = ({ profile }: WriteReviewButtonProps) => {
  const { reviewType, isRedirectedToReview } = useCheckIfRedirectedToReview();

  const isWriteReviewSuccessModalOpen = useAppSelector(
    (state) => !!state.modal["write-review-success-modal"],
  );

  if (
    isRedirectedToReview &&
    reviewType === ENUMS.ReviewType.COWORKER_REVIEW &&
    profile.can_user_review_job
  ) {
    return <WriteJobReviewButton />;
  }

  if (
    isRedirectedToReview &&
    reviewType === ENUMS.ReviewType.HELP_OFFER_REVIEW &&
    profile.can_user_review_help_offer
  ) {
    return <WriteHelpOfferReviewButton profile={profile} />;
  }

  if (profile.can_user_review_job || isWriteReviewSuccessModalOpen) {
    return <WriteJobReviewButton />;
  }

  if (profile.can_user_review_help_offer && !isWriteReviewSuccessModalOpen) {
    return <WriteHelpOfferReviewButton profile={profile} />;
  }

  return null;
};
