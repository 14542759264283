import type { ReactNode } from "react";
import React, { useMemo } from "react";

import type {
  ExpandBid,
  IsBidExpanded,
} from "@js/apps/jobs/hooks/expanded-bids";
import { useExpandedBids } from "@js/apps/jobs/hooks/expanded-bids";

export type RefetchBidList = () => void;

const RefetchBidsContext = React.createContext<{
  refetchBidList: RefetchBidList | null;
  isBidExpanded: IsBidExpanded | null;
  expandBid: ExpandBid | null;
  loadingBids: boolean;
  fetchingBids: boolean;
}>({
  refetchBidList: null,
  isBidExpanded: null,
  expandBid: null,
  loadingBids: false,
  fetchingBids: false,
});
export const useBidsContext = () => {
  const context = React.useContext(RefetchBidsContext);

  if (!context) throw new Error("There is no RefetchBidContextProvider");

  return context;
};

type RefetchBidContextProviderProps = {
  children: ReactNode;
  refetchBidList: RefetchBidList;
  loadingBids: boolean;
  fetchingBids: boolean;
};

export const RefetchBidContextProvider = ({
  children,
  refetchBidList,
  loadingBids,
  fetchingBids,
}: RefetchBidContextProviderProps) => {
  const { isBidExpanded, expandBid } = useExpandedBids();

  const refetchBidsContextProviderValue = useMemo(
    () => ({
      refetchBidList,
      isBidExpanded,
      expandBid,
      loadingBids,
      fetchingBids,
    }),
    [refetchBidList, isBidExpanded, expandBid, loadingBids, fetchingBids],
  );

  return (
    <RefetchBidsContext.Provider value={refetchBidsContextProviderValue}>
      {children}
    </RefetchBidsContext.Provider>
  );
};
