import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import { clickApplyAfterSearch } from "@js/apps/jobs/actions";
import { CompleteProfileOrSubmitButton } from "@js/apps/jobs/components/complete-profile-or-submit-button";
import {
  ContractTypeDetail,
  DeadlineDetail,
  LocationDetail,
  TimezoneDetail,
  WorkTimeDetail,
} from "@js/apps/jobs/components/job-basic-details";
import { LocationRequirementWarning } from "@js/apps/jobs/components/location-requirement-warning-badge";
import { ShareThisJobCard } from "@js/apps/jobs/components/share-this-job-card";
import { ViewJobButton } from "@js/apps/jobs/components/view-job-button";
import type { JOB_LOCATION } from "@js/apps/jobs/context";
import { useGetExpandedJobDetails } from "@js/apps/jobs/hooks/use-get-expanded-job-details";
import { useSearchEventQueryId } from "@js/apps/tracking/search-event-slice";
import { Sanitize } from "@js/components/sanitize";
import { useAppDispatch } from "@js/hooks";
import type { MainJobListingJob } from "@js/types/jobs";
import { showRelativeDateAfterDays } from "@js/utils";

import styles from "./styles.module.scss";

type JobItemExpandedContentProps = {
  job: MainJobListingJob;
  isApplied: boolean;
  location: EnumType<typeof JOB_LOCATION>;
  resultPosition?: number;
  searchResults?: boolean;
  isEmbeddedView?: boolean;
};

export const JobItemExpandedContent = ({
  job,
  isApplied,
  location,
  resultPosition,
  searchResults,
  isEmbeddedView,
}: JobItemExpandedContentProps) => {
  const user = useUser();
  const { isEmployer } = useAccountType();
  const dispatch = useAppDispatch();
  const { jobDetails, isFetchError, isLoading } = useGetExpandedJobDetails(
    job.id,
  );

  const { strong_location_requirement_met, strong_timezone_requirement_met } =
    jobDetails || {};

  const searchQueryId = useSearchEventQueryId(
    ENUMS.SearchEventOption.JOB_SEARCH_BOX,
  );

  const description = jobDetails?.description || jobDetails?.introduction;

  if (isLoading) {
    return (
      <Box mt={4} mb={2}>
        <Loader className="center" />
      </Box>
    );
  }

  if (isFetchError) {
    return (
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={2}
        mb={2}
      >
        <Typography
          component="p"
          variant="label"
          color="negative"
          size="medium"
        >
          Unable to fetch details. Please try to refresh the page and check your
          internet connection.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.warningBadge}>
        <LocationRequirementWarning
          job={{
            ...job,
            strong_location_requirement_met,
            strong_timezone_requirement_met,
          }}
        />
      </Box>
      <Box className={styles.details}>
        <LocationDetail locations={job.locations} variant="listing-expanded" />
        <TimezoneDetail
          timezones={job.timezones}
          timezoneOverlap={job.timezone_overlap}
          variant="listing-expanded"
        />
        <WorkTimeDetail
          expectedHoursPerWeek={job.expected_hours_per_week}
          variant="listing-expanded"
        />
        <ContractTypeDetail job={job} variant="listing-expanded" />
        <DeadlineDetail job={job} variant="listing-expanded" />
      </Box>
      <Box className={styles.createdAt}>
        <Typography
          component="p"
          variant="label"
          whiteSpace="nowrap"
          size="small"
        >
          Posted {showRelativeDateAfterDays(job.created, 3)}
        </Typography>
      </Box>
      <Box className={styles.description}>
        <Typography component="span" variant="paragraph" size="medium">
          What you’ll be working on
        </Typography>
        <Box
          display="flex"
          className={styles.descriptionContent}
          flexGrow={1}
          justifyContent="space-between"
          flexDirection="column"
          position="relative"
        >
          <Typography
            component="div"
            variant="paragraph"
            size="small"
            sx={{
              mt: 2,
              maxHeight: "150px",
              overflow: "hidden",
            }}
          >
            {jobDetails && description && (
              <Sanitize
                input={
                  description.length > 350
                    ? description.substring(0, 350) + "..."
                    : description
                }
              />
            )}
          </Typography>

          <Box className={styles.fade} />

          <Box
            className={styles.descriptionBtns}
            flexDirection={{ xs: "column", sm: "row" }}
            gap={1}
            display="flex"
          >
            <ViewJobButton
              variant="primary"
              job={job}
              sx={{ whiteSpace: "nowrap", height: 40 }}
            />
            {!isEmployer && !isApplied && (
              <CompleteProfileOrSubmitButton
                job={job}
                variant="secondary"
                sx={{ height: "40px", width: { xs: "100%", sm: "auto" } }}
                onClick={() => {
                  if (searchResults && resultPosition !== undefined)
                    dispatch(
                      clickApplyAfterSearch({
                        jobId: job.id,
                        location,
                        resultPosition,
                        searchQueryId,
                      }),
                    );
                }}
                openInNewTab={isEmbeddedView}
              />
            )}
          </Box>
        </Box>
      </Box>
      {user && (
        <Box className={styles.referJobCard}>
          <ShareThisJobCard job={job} isEmbeddedView={isEmbeddedView} />
        </Box>
      )}
    </Box>
  );
};
