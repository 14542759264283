import { useEffect, useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { PaymentMethodInline } from "@js/apps/payments/components/payment-method-inline";
import type { PaymentMethod } from "@js/types/payments";

type UsePaymentMethodsListingProps = {
  paymentMethods: PaymentMethod[];
  meta: TypedWrappedFieldProps<PaymentMethod>["meta"];
  onChange: (value: PaymentMethod | null) => void;
};

export const usePaymentMethodsListing = ({
  onChange,
  paymentMethods,
  meta,
}: UsePaymentMethodsListingProps) => {
  useEffect(() => {
    let methodToSet: PaymentMethod | undefined;

    const isInitialMethodInMethodsCollection = paymentMethods.some(
      (method) => method.id === meta.initial?.id,
    );

    if (meta.initial && !meta.touched && isInitialMethodInMethodsCollection) {
      methodToSet = meta.initial;
    } else {
      methodToSet =
        paymentMethods.find((method) => method.is_default) || paymentMethods[0];
    }

    onChange(methodToSet || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods]);

  const paymentMethodOptions = useMemo(
    () =>
      paymentMethods.map((paymentMethod) => ({
        value: paymentMethod,
        label: <PaymentMethodInline paymentMethod={paymentMethod} />,
      })),
    [paymentMethods],
  );

  const isOnlyOneMethod = paymentMethods.length === 1;

  return {
    paymentMethodOptions,
    isOnlyOneMethod,
  };
};
