import { useRef } from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { Emoji } from "@js/components/emoji";
import { RouterLink } from "@js/components/link";

import { closeGenericMessenger } from "../../messengers/modal/manage-messenger-modal";

import { useTruncateAndWrapQuote } from "./use-truncate-and-wrap-quote";

import styles from "./styles.module.scss";

export type SystemMessageCardContentProps = {
  emoji: string;
  emojiBackground: string;
  title: JSX.Element | string;
  subtitle?: JSX.Element | string;
  additionalMessage?: JSX.Element | string;
};

export const SystemMessageCardContent = ({
  emoji,
  emojiBackground,
  title,
  subtitle,
  additionalMessage,
}: SystemMessageCardContentProps): JSX.Element => {
  const isMobile = useMediaQuery("sm");

  return (
    <div className={styles.systemMessageContent}>
      <Box className={styles.emoji} sx={{ backgroundColor: emojiBackground }}>
        <Emoji emoji={emoji} size="24px" />
      </Box>
      <Typography size={isMobile ? "medium" : "large"} component="p">
        {title}
      </Typography>
      <Typography size={isMobile ? "small" : "medium"} component="p">
        {subtitle}
      </Typography>
      {additionalMessage && (
        <div className={styles.additionalMessage}>{additionalMessage}</div>
      )}
    </div>
  );
};

type QuotedHelpOfferMessageProps = {
  comment: {
    text: string;
    main_post_id: number;
    main_post_title: string;
    id: number;
  };
};

export const QuotedHelpOfferMessage = ({
  comment,
}: QuotedHelpOfferMessageProps) => {
  const quoteContainerRef = useRef<null | HTMLDivElement>(null);

  // quote and ellipsis - see comment inside useTruncateAndWrapQuote
  const quotedMessage = useTruncateAndWrapQuote(
    comment.text,
    quoteContainerRef,
  );

  if (!quotedMessage) return null;

  const postUrl = `${window.location.origin}/feed/${comment.main_post_id}/#${comment.id}`;

  return (
    <>
      <div ref={quoteContainerRef} className={styles.additionalMessageContent}>
        <Typography
          component="p"
          multilineEllipsis={2}
          className="system-message-content__additional-message--quote"
        >
          {quotedMessage}
        </Typography>
      </div>
      <div className={styles.readMoreContainer}>
        <Typography size="small" component="span" className={styles.readMore}>
          Read more:{" "}
        </Typography>
        <Typography
          size="small"
          onClick={() => closeGenericMessenger()}
          component="link"
          RouterLink={RouterLink}
          className={styles.additionalMessageLink}
          to={postUrl}
        >
          {comment.main_post_title}
        </Typography>
      </div>
    </>
  );
};
