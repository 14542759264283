import React from "react";

export type AfterActionArg = void | {
  job?: { id: number };
  actionType?: EnumType<typeof ENUMS.JobActions>;
};

export type AfterAction = (arg: AfterActionArg) => void | Promise<unknown>;

export type WithJobActionsContextData = {
  afterAction: AfterAction;
};

export const WithJobActionsContext =
  React.createContext<WithJobActionsContextData | null>(null);

export const useWithJobActionsContext = () => {
  const context = React.useContext(WithJobActionsContext);
  if (!context) {
    throw new Error("WithJobActionsContext is missing");
  }

  return context;
};
