import { useCallback } from "react";
import { generateOnRampURL } from "@coinbase/cbpay-js";
import uuid4 from "uuid4/browser";

import {
  useCreateOnrampTransactionMutation,
  useLazyGetOnRampSessionTokenQuery,
} from "@js/apps/on-ramp/api";
import { RETURNED_FROM_COINBASE_PARAM } from "@js/apps/on-ramp/constants";
import { Snackbar } from "@js/components/snackbar";
import { deleteQueryStringParameters, openPopup } from "@js/utils";

type OnRampParams = {
  transactionId?: string;
  tokensToBuy?: number;
};

type OpenOnRampParams = {
  tokensToBuy?: number;
  onOpen?: () => void;
};

type GenerateCoinbaseOnRampURLParams = OnRampParams & { sessionToken?: string };

export const useCoinbasePay = () => {
  const [getOnRampSessionToken] = useLazyGetOnRampSessionTokenQuery();
  const [createOnrampTransaction] = useCreateOnrampTransactionMutation();

  const getOnRampURL = useCallback(
    async ({ transactionId, tokensToBuy }: OnRampParams) => {
      try {
        const { token: sessionToken } = await getOnRampSessionToken().unwrap();

        return generateCoinbaseOnRampURL({
          sessionToken,
          transactionId,
          tokensToBuy,
        });
      } catch (_error) {
        Snackbar.error("Failed to get onRamp URL");
        return null;
      }
    },
    [getOnRampSessionToken],
  );

  const openOnRamp = useCallback(
    async ({ tokensToBuy, onOpen }: OpenOnRampParams = {}) => {
      const transactionId = uuid4();
      const onRampURL = await getOnRampURL({ transactionId, tokensToBuy });

      if (!onRampURL) {
        return;
      }

      const isPopupOpened = openPopup({ url: onRampURL });
      if (!isPopupOpened) {
        return;
      }

      try {
        await createOnrampTransaction({ uuid: transactionId }).unwrap();
      } catch (_error) {
        Snackbar.error("Failed to create onramp transaction");
      }

      onOpen?.();
    },
    [getOnRampURL, createOnrampTransaction],
  );

  return { openOnRamp };
};

export const generateCoinbaseOnRampURL = ({
  sessionToken,
  transactionId,
  tokensToBuy,
}: GenerateCoinbaseOnRampURLParams): string | null => {
  if (!sessionToken || !transactionId) return null;

  const redirectUrl = `${window.location.origin + window.location.pathname}?${RETURNED_FROM_COINBASE_PARAM}`;
  const generatedUrl = generateOnRampURL({
    appId: SETTINGS.COINBASE_API_ID,
    defaultExperience: "buy",
    presetCryptoAmount: tokensToBuy || 1,
    partnerUserId: transactionId,
    sessionToken,
    redirectUrl: encodeURIComponent(redirectUrl),
    addresses: {}, // NOTE: we don't need to pass addresses because we are using session token
  });

  if (!generatedUrl) return null;

  // NOTE: we have to delete addresses manually because there is a bug in the coinbase pay library
  // that requires us to pass addresses when generating the URL using generateOnRampURL function
  return deleteQueryStringParameters("addresses", generatedUrl);
};
