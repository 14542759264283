import type { FormErrors } from "redux-form";
import { Fields } from "redux-form";

import { Modal } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";

import type { MessageOfferProps } from ".";
import { HelpOfferField } from ".";

export const HelpOfferModal = Modal("help-offer-modal-id", {
  disablePortal: true,
  keepOnBackdropClick: true,
});

type HelpOfferModalProps = Omit<MessageOfferProps, "className"> & {
  onSubmit: (formValues: HelpOfferFormValues) => void;
};

type HelpOfferFormValues = { category: number; budget: string | null };

const HELP_OFFER_MODAL_FORM_ID = "help-offer-modal-form";

const validateFormFields = (formValues: Partial<HelpOfferFormValues>) => {
  const errors: FormErrors<HelpOfferFormValues> = {
    budget: undefined,
  };

  const minBudget = 0;
  const maxBudget = 100000000000;

  const { budget } = formValues;

  const isBudgetSelected = budget !== null && budget !== undefined;

  if (isBudgetSelected && parseInt(budget) < minBudget) {
    errors.budget = "Ensure this value is greater than or equal to 0";
  }

  if (isBudgetSelected && parseInt(budget) > maxBudget) {
    errors.budget = `Please enter an amount no greater than ${maxBudget}`;
  }

  return errors;
};

const HelpOfferModalForm = createFormInstance<HelpOfferFormValues, unknown>(
  HELP_OFFER_MODAL_FORM_ID,
  {
    validate: validateFormFields,
  },
);

export const openHelpOfferModal = ({
  onSubmit,
  postCategories,
  canNotSendMessageReason,
  canSendMessage,
}: HelpOfferModalProps) => {
  HelpOfferModal.open({
    className: "max-width-600",
    children: (
      <HelpOfferModalForm onSubmit={onSubmit}>
        <Fields
          names={["category", "budget"]}
          component={HelpOfferField}
          postCategories={postCategories}
          canNotSendMessageReason={canNotSendMessageReason}
          canSendMessage={canSendMessage}
        />
      </HelpOfferModalForm>
    ),
  });
};

export const closeHelpOfferModal = () => {
  HelpOfferModal.close();
};
