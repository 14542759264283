import { useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import cs from "classnames";

import { SortableList } from "@js/components/sortable-list";

import { FILE_UPLOAD_STATE_STATUS } from "../../constants";
import { arrayMove, getFileIndexInValue } from "../../helpers/file-upload";
import type { FilePreviewData } from "../../types";

import { ImagePreviewPanelItem } from "./image-preview-panel-item";

import styles from "./sortable-images-preview-panel.module.scss";

type SortableImagesPreviewPanelProps = {
  filesPreviewData: FilePreviewData[];
  onFileDelete: (data: FilePreviewData) => void;
  input: TypedWrappedFieldProps<number[]>["input"];
};

export const SortableImagesPreviewPanel = ({
  filesPreviewData,
  input,
  onFileDelete,
}: SortableImagesPreviewPanelProps) => {
  const { value } = input;
  const filesPreviewDataWithIds = useMemo(
    () =>
      filesPreviewData
        .map((filePreviewData) => ({
          ...filePreviewData,
          id: filePreviewData.fileId,
        }))
        .sort((fileA, fileB) => {
          const fileAIndexInValue = getFileIndexInValue(fileA, value);
          const fileBIndexInValue = getFileIndexInValue(fileB, value);

          return fileAIndexInValue - fileBIndexInValue;
        }),
    [filesPreviewData, value],
  );

  const isUploading = useMemo(
    () =>
      filesPreviewDataWithIds.some(
        ({ status }) => status === FILE_UPLOAD_STATE_STATUS.UPLOADING,
      ),
    [filesPreviewDataWithIds],
  );

  return (
    <SortableList
      className={cs(styles.container, { [styles.loading]: isUploading })}
      useDragHandle={false}
      ElementProps={{
        render: ({ children, ...draggableProps }) => (
          <li
            {...(isUploading ? {} : { ...draggableProps })}
            tabIndex={0}
            role="option"
            aria-labelledby={SortableList.INSTRUCTIONS_ID}
          >
            {children}
          </li>
        ),
      }}
      draggingConfig={{
        activationConstraint: {
          distance: 10,
        },
      }}
      items={filesPreviewDataWithIds}
      getUniqueItemKey={(item) => item.id}
      reorderAction={(_items, from, to) => {
        if (isUploading) {
          return;
        }

        input.onChange(arrayMove(input.value, from, to));
      }}
    >
      {(filePreviewData) => (
        <ImagePreviewPanelItem
          key={filePreviewData.id}
          filePreviewData={filePreviewData}
          onFileDelete={() => onFileDelete(filePreviewData)}
        />
      )}
    </SortableList>
  );
};
