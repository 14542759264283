import React, { useEffect } from "react";
import { QueryStatus } from "@reduxjs/toolkit/query";

import { Box } from "@hexocean/braintrust-ui-components";
import { useGetFreelancerHoverStateDataQuery } from "@js/apps/freelancer/api";
import type { ProfileCardProps } from "@js/apps/give-and-get-help/components/profile-card/profile-card.types";
import { ProfileCardHeader } from "@js/apps/give-and-get-help/components/profile-card/profile-card-header";
import { ProfilePersonalData } from "@js/apps/give-and-get-help/components/profile-card/profile-card-personal-data";
import { ProfileCardRoles } from "@js/apps/give-and-get-help/components/profile-card/profile-card-roles";
import {
  ProfileCardSection,
  ProfileCardSectionTitle,
} from "@js/apps/give-and-get-help/components/profile-card/profile-card-section";
import { ProfileCardSectionPlaceholder } from "@js/apps/give-and-get-help/components/profile-card/profile-card-section/profile-card-section-placeholder";
import {
  ProfileCardRating,
  ProfileCardServices,
} from "@js/apps/give-and-get-help/components/profile-card/profile-card-services-with-rating";
import { ProfileCardTopSkills } from "@js/apps/give-and-get-help/components/profile-card/profile-card-top-skills";
import type { FreelancerHoverState } from "@js/types/freelancer";
import { shortenValue } from "@js/utils/string";

import styles from "./styles.module.scss";

export const ProfileCard = ({
  avatarData,
  talentId,
  firstName,
  showMessageButton,
  onDataLoad,
}: ProfileCardProps) => {
  const { data, isLoading, isError, status } =
    useGetFreelancerHoverStateDataQuery({
      id: talentId,
    });

  useEffect(() => {
    if (onDataLoad && status === QueryStatus.fulfilled) {
      onDataLoad();
    }
  }, [onDataLoad, status]);

  if (isLoading || isError || !data) {
    return null;
  }

  const firstNameTruncated = getTruncatedFirstName(
    firstName,
    data.review_count,
  );

  const { showRoles, showTopSkills, showServices, showEmptyCard } =
    showSection(data);

  return (
    <div className={styles.profileCard}>
      <ProfileCardHeader
        avatarData={avatarData}
        coverPhoto={data.cover_photo}
        talentId={talentId}
        showMessageButton={showMessageButton}
      />
      <div className={styles.profileCardContent}>
        <ProfilePersonalData
          name={data.public_name}
          isApproved={data.approved}
          location={data.location}
          headline={data.introduction_headline}
        />

        {showEmptyCard ? (
          <ProfileCardSectionPlaceholder
            text={`${firstName} hasn't added any information, yet.`}
          />
        ) : (
          <>
            {showRoles && (
              <ProfileCardSection
                title={null}
                content={<ProfileCardRoles roles={data.roles} />}
                gap={1}
                divider={showTopSkills || showServices}
              />
            )}

            {showTopSkills && (
              <ProfileCardSection
                title={<ProfileCardSectionTitle text="Top skills" />}
                content={<ProfileCardTopSkills topSkills={data.top_skills} />}
                gap={1.5}
                divider={showServices}
              />
            )}

            {showServices && (
              <ProfileCardSection
                title={
                  <Box display="flex" justifyContent="space-between" gap={2}>
                    <ProfileCardSectionTitle
                      text={`${firstNameTruncated} helps people with...`}
                    />
                    <ProfileCardRating
                      averageRating={data.average_rating}
                      reviewCount={data.review_count}
                      talentId={talentId}
                    />
                  </Box>
                }
                content={<ProfileCardServices services={data.services} />}
                gap={1.5}
                placeholder={`${firstName} hasn't added any services, yet.`}
                isEmpty={!data.services.length}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const showSection = (data: FreelancerHoverState) => {
  const showRoles = !!data.roles.length;
  const showTopSkills = !!data.top_skills.length;
  const showServices = !!data.services.length || !!data.review_count;
  const showEmptyCard = !showRoles && !showTopSkills && !showServices;

  return {
    showRoles,
    showTopSkills,
    showServices,
    showEmptyCard,
  };
};

const getTruncatedFirstName = (firstName: string, reviewCount: number) => {
  if (reviewCount) {
    return shortenValue(firstName, 8);
  }

  return shortenValue(firstName, 22);
};
