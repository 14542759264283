import React from "react";

import { ReferralsStatsLoader } from "../../components";
import { TalentReferrals } from "../../components/talent-referrals";
import { useTalentReferrals } from "../../hooks/use-talent-referrals";

export const DashboardReferTalentPage = () => {
  const {
    isFetchingReferrals,
    isLoadingSummary,
    referrals,
    summary,
    fetchMore,
    filters,
  } = useTalentReferrals();

  if (!summary || isLoadingSummary) {
    return <ReferralsStatsLoader />;
  }

  return (
    <TalentReferrals
      referrals={referrals}
      loading={isFetchingReferrals}
      summary={summary}
      fetchMore={fetchMore}
      filters={filters}
    />
  );
};
