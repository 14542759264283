import { useGetFreelancerBidsQuery } from "@js/apps/freelancer/api";

export const useInitialInvoiceItemPrice = ({
  jobId,
}: {
  jobId: number | null;
}) => {
  const { data: currentJobBids } = useGetFreelancerBidsQuery(
    { job: jobId as number, accepted: true },
    { skip: !jobId },
  );

  const jobBidOfferPayment = currentJobBids?.[0]?.current_offer?.payment_amount;
  const initialInvoiceItemPrice = jobBidOfferPayment
    ? Number(jobBidOfferPayment)
    : null;

  return initialInvoiceItemPrice;
};
