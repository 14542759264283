import { useEffect } from "react";

import {
  holdProfileCompletionSuccessModal,
  resumeProfileCompletionSuccessModal,
} from "@js/apps/freelancer/hooks/use-profile-completion-success/store/slice";
import { useAppDispatch } from "@js/hooks";

export const useSuppressProfileCompletionSuccessModal = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(holdProfileCompletionSuccessModal());

    return () => {
      dispatch(resumeProfileCompletionSuccessModal());
    };
  }, [dispatch]);
};
