import { useGetManagedEmployerQuery } from "@js/apps/employer/hooks";

export const useEmployerMaxOpeningsNumber = () => {
  const { data: employer } = useGetManagedEmployerQuery();

  return (
    employer?.override_maximum_openings_number ??
    SETTINGS.JOB_MAX_OPENINGS_NUMBER
  );
};
