import { useSearchParams } from "react-router-dom";
import { z } from "zod";

export const useHashtagSearchParam = () => {
  const [params] = useSearchParams();
  const parsedParams = feedSearchParamsSchema.safeParse(
    Object.fromEntries(params.entries()),
  );

  return parsedParams.success ? parsedParams.data.hashtag : undefined;
};

const feedSearchParamsSchema = z.object({
  hashtag: z.coerce.number().positive().int(),
});
