import { getFormSyncWarnings } from "redux-form";

import { useAppSelector } from "@js/hooks";

import type {
  CREATE_POST_FORM_ID,
  EDIT_POST_FORM_ID,
} from "../../form/constants";
import { CREATE_POST_FORM_SELF_PROMOTION_FIELD_ID } from "../../hooks/use-check-for-self-promotion";
import { useEtiquette } from "../etiquette-side-panel/hook";

export const useShowEtiquetteReminder = (
  formId: typeof CREATE_POST_FORM_ID | typeof EDIT_POST_FORM_ID,
) => {
  const { showEtiquettePanel } = useEtiquette();
  const syncWarnings = useAppSelector(getFormSyncWarnings(formId));

  const hasUsersPostViolatedGuidelines =
    CREATE_POST_FORM_SELF_PROMOTION_FIELD_ID in syncWarnings &&
    !!syncWarnings[CREATE_POST_FORM_SELF_PROMOTION_FIELD_ID];

  const showEtiquetteReminder =
    hasUsersPostViolatedGuidelines && !showEtiquettePanel;

  return {
    showEtiquetteReminder,
  };
};
