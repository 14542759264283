import React from "react";
import { createRoot } from "react-dom/client";

import { applyReduxFieldPatch } from "./utils/redux-form";
import App from "./app";
import { configureServices } from "./configure";

configureServices();
applyReduxFieldPatch();

const renderApp = () => {
  const container = document.getElementById("root");
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  root.render(<App />);
};

renderApp();
