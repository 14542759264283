import {
  actionTypes,
  getFormError,
  getFormSubmitErrors,
  getFormSyncErrors,
} from "redux-form";
import type { Action, Dispatch, Middleware } from "@reduxjs/toolkit";
import _ from "underscore";

import type { RootState } from "@js/store";

import { scrollToFirstError } from "../forms/utils";
import { clearEmpties, deepClone } from "../utils/data";

type SubmitFailedAction = Action<typeof actionTypes.SET_SUBMIT_FAILED> & {
  meta: {
    form: string;
  };
};

export const reduxFormScrollToFirstErrorMiddleware: Middleware<
  Record<string, never>,
  RootState,
  Dispatch<SubmitFailedAction>
> = (store) => (next) => (action: any) => {
  if (action.type === actionTypes.SET_SUBMIT_FAILED) {
    const formId = action.meta.form;

    const generalFormError = getFormError(formId)(store.getState());
    const syncErrors = getFormSyncErrors(formId)(store.getState());
    const submitErrors = getFormSubmitErrors(formId)(store.getState());

    const _generalFormError = generalFormError && deepClone(generalFormError);
    const _syncErrors = syncErrors && deepClone(syncErrors);
    const _submitErrors = submitErrors && deepClone(submitErrors);

    const clearedErrorObject = clearEmpties(
      _syncErrors as Record<string, unknown>,
    );

    const submitOrSyncError = _.isEmpty(clearedErrorObject)
      ? _submitErrors
      : _syncErrors;

    const errors = _generalFormError || submitOrSyncError;

    scrollToFirstError(formId, errors, "");
  }

  return next(action);
};
