import { useEffect } from "react";

import { useEffectRef } from "@js/hooks/use-effect-ref";

type UseStorageOnOtherTabsChangeProps = {
  storageKey: string;
  callback: (newStorageValue: unknown) => void;
};

export const useStorageOnOtherTabsChange = ({
  storageKey,
  callback,
}: UseStorageOnOtherTabsChangeProps) => {
  const callbackRef = useEffectRef(callback);
  useEffect(() => {
    const storageEventChangeListener = (event: StorageEvent) => {
      if (event.key === storageKey) {
        callbackRef.current(event.newValue);
      }
    };

    window.addEventListener("storage", storageEventChangeListener);

    return () => {
      window.removeEventListener("storage", storageEventChangeListener);
    };
  }, [callbackRef, storageKey]);
};
