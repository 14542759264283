import React from "react";

import {
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  Stack,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { VerifiedIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import type { FreelancerReferralType } from "@js/types/dashboard";
import { formatCurrency } from "@js/utils/currency";

import { ReferralTokenBalance } from "../referral-stats";

import { TalentCardApprovalBadge } from "./talent-card-approval-badge";

const TalentReferralCardBase = ({
  referral,
  isCompact,
}: {
  referral: FreelancerReferralType;
  isCompact?: boolean;
}) => {
  const freelancerHref = `/talent/${referral.id}/`;
  const role = referral.role?.name;
  const isApproved =
    referral?.approval_status === ENUMS.FreelancerApprovalStatus.APPROVED;
  const showRole = isApproved && !!referral.accepted_offers_count && role;

  return (
    <Stack
      direction="row"
      gap={1.5}
      minWidth={0}
      sx={{ alignItems: { xs: "start", lg: "center" } }}
    >
      <RouterLink to={freelancerHref}>
        <Avatar
          data-testid={`talent-avatar-${referral.id}`}
          src={referral.user}
          size="md"
          sx={{ mt: { xs: "2px", lg: 0 } }}
        />
      </RouterLink>
      <Stack direction="column" className="display-ellipsis">
        <Stack direction="row" alignItems="center">
          <Typography
            component="link"
            variant="label"
            noWrap
            noTextDecoration
            RouterLink={RouterLink}
            href={freelancerHref}
            size={isCompact ? "medium" : "large"}
          >
            {referral.user.public_name}
          </Typography>
          {isApproved && (
            <Tooltip
              title={
                <Typography component="p" variant="paragraph" size="medium">
                  {referral.user.first_name} is approved talent.
                </Typography>
              }
              placement="top"
            >
              <VerifiedIcon
                sx={{ position: "relative", zIndex: 10 }}
                data-testid="verified-icon"
              />
            </Tooltip>
          )}
        </Stack>
        <Stack
          direction={isCompact ? "column" : "row"}
          sx={{
            rowGap: "2px",
            columnGap: 1,
            flexWrap: "wrap",
            alignItems: isCompact ? "start" : "center",
          }}
        >
          <Stack
            direction="row"
            divider={<Box px={0.5}>·</Box>}
            overflow="hidden"
            maxWidth="100%"
          >
            <Typography
              component="p"
              variant="paragraph"
              size="medium"
              color="grey-1"
              flexShrink={0}
            >
              Joined {referral.created}
            </Typography>
            {showRole && (
              <Typography
                component="p"
                variant="paragraph"
                color="grey-1"
                size="medium"
                ellipsis
              >
                {role}
              </Typography>
            )}
          </Stack>
          <TalentCardApprovalBadge referral={referral} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const TalentReferralDetails = ({
  referral,
  isCompact,
}: {
  referral: FreelancerReferralType;
  isCompact?: boolean;
}) => {
  const statVariant = isCompact ? "paragraph" : "title";
  const statSize = isCompact ? "large" : "small";
  const tokenSize = isCompact ? "medium" : "large";
  const showLabels = isCompact;

  const talentTotalEarned = formatCurrency(referral.talent_total_earned ?? 0, {
    removeDecimal: true,
  });

  return (
    <Grid
      container
      spacing={showLabels ? 2.5 : 2}
      sx={{ alignItems: "center", justifyContent: "end" }}
    >
      <Grid item xs={2.5} lg sx={{ maxWidth: { lg: "130px" } }}>
        <Stack direction="column">
          {showLabels && (
            <Typography
              component="span"
              variant="paragraph"
              size="small"
              color="grey-1"
            >
              Applied
            </Typography>
          )}
          <Typography
            component="span"
            variant={statVariant}
            size={statSize}
            fontWeight={400}
          >
            {referral.bids_count}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={2.5} lg sx={{ maxWidth: { lg: "130px" } }}>
        <Stack direction="column">
          {showLabels && (
            <Typography
              component="span"
              variant="paragraph"
              size="small"
              color="grey-1"
            >
              Hired
            </Typography>
          )}
          <Typography
            component="span"
            variant={statVariant}
            size={statSize}
            fontWeight={400}
          >
            {referral.accepted_offers_count}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={7} lg="auto" sx={{ width: { lg: "300px" } }}>
        <Stack sx={{ pr: { lg: 1 } }}>
          {showLabels && (
            <Typography
              component="span"
              variant="paragraph"
              size="small"
              color="grey-1"
            >
              Billings
            </Typography>
          )}
          <Stack direction="row" gap={1} flexWrap="wrap" alignItems="center">
            <Typography
              component="span"
              variant={statVariant}
              size={statSize}
              fontWeight={400}
            >
              {talentTotalEarned}
            </Typography>
            <ReferralTokenBalance
              colorVariant="green"
              tokenSize={tokenSize}
              tokenBalance={referral.total_earned_from_referral}
            />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export const TalentCardList = ({
  referrals,
}: {
  referrals: FreelancerReferralType[];
}) => {
  const isNotDesktop = useMediaQuery("lg");

  return (
    <List
      sx={{
        mb: "20px",
        gap: 2,
        display: "flex",
        flexDirection: "column",
        p: 0,
      }}
    >
      {referrals.map((referral) => {
        return (
          <Box
            key={referral.id}
            component="li"
            sx={{
              bgcolor: "var(--white)",
              borderRadius: 2,
              py: 2,
              px: { xs: 2, lg: 0 },
            }}
          >
            <Grid container alignItems="center">
              <Grid item xs={12} lg={6} sx={{ pl: { lg: 2 }, pr: { lg: 4 } }}>
                <TalentReferralCardBase
                  referral={referral}
                  isCompact={isNotDesktop}
                />
              </Grid>
              {isNotDesktop && (
                <Divider
                  color="beige"
                  sx={{
                    mt: 2,
                    mb: 1,
                  }}
                />
              )}
              <Grid item xs={12} lg={6}>
                <TalentReferralDetails
                  referral={referral}
                  isCompact={isNotDesktop}
                />
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </List>
  );
};
