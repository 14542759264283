import type { PurchaseUpgradeParams } from "@js/apps/auth/api";
import { ModalInstance } from "@js/components/modal";

import { ProductDescriptionModal } from "./product-description-modal";

import styles from "./styles.module.scss";

type OpenProductDescriptionModalProps = {
  upgradeType: PurchaseUpgradeParams["upgrade_type"];
};

export const openProductDescriptionModal = ({
  upgradeType,
}: OpenProductDescriptionModalProps) => {
  ModalInstance.open({
    padding: false,
    containerScrollableNoMobilePadding: false,
    className: styles.productDescriptionModal,
    children: (
      <ProductDescriptionModal
        openProductDescriptionModal={openProductDescriptionModal}
        upgradeType={upgradeType}
        ModalInstance={ModalInstance}
      />
    ),
  });
};
