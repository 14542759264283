import { Navigate } from "react-router-dom";

import { useUser } from "@js/apps/common/hooks";

export const EarnIndexRedirectPage = () => {
  const user = useUser();

  if (!user) {
    return <></>;
  }

  const isInReferClientProgram = !!user?.refer_client_program_opted_in_at;
  const redirectToPath = isInReferClientProgram
    ? "refer_clients"
    : "refer_clients_program";

  return <Navigate to={redirectToPath} replace />;
};
