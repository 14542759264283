import { useGetPopularSkillsQuery, useGetSkillsQuery } from "./api";

export const useFetchRolePopularSkills = (roles: number[] | undefined) => {
  const hasRoles = Boolean(roles && roles.length);

  const { data: popularSkills, isFetching } = useGetPopularSkillsQuery({
    roleIds: hasRoles ? roles : undefined,
  });

  return { popularSkills, isFetching };
};

export const useSkillsData = (ids: number[]) => {
  const { data } = useGetSkillsQuery({ ids }, { skip: !ids.length });

  return {
    skillsData: Array.isArray(data) ? data : [],
  };
};
