import { openWriteReviewModal } from "@js/apps/reviews/components/review-modal/write-review-modal-instance";
import { WriteReviewBasicButton } from "@js/apps/reviews/components/talent-profile-review-module/write-review-basic-button";
import { showWriteReviewFormAfterOpen } from "@js/apps/reviews/slice";
import { useAppDispatch } from "@js/hooks";

export const WriteJobReviewButton = () => {
  const dispatch = useAppDispatch();
  const handleOnClick = () => {
    dispatch(showWriteReviewFormAfterOpen(true));
    openWriteReviewModal();
  };
  return <WriteReviewBasicButton onClick={handleOnClick} />;
};
