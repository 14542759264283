import { useABExperiment } from "@js/apps/experiments";

export const useGenerateAIJobPost = () => {
  const { isParticipating, experiment } = useABExperiment(
    "Generate Job Posts using AI",
  );

  const isGenerateJobPostsUsingAIEnabled =
    experiment?.variant ===
    SETTINGS.EXPERIMENT_ENUMS[
      SETTINGS.EXPERIMENT_ENUMS.Experiments.GenerateJobPostsUsingAI
    ].GenerateJobPostAIVariant;

  return {
    showGenerateAIButton: isParticipating && isGenerateJobPostsUsingAIEnabled,
  };
};
