import React from "react";

import type { TypographySizes } from "@hexocean/braintrust-ui-components";
import { JobStatusBox } from "@js/apps/jobs/components/job-status/components/job-status-box";

export const NotAcceptingProposalsPublic = ({
  size,
  status,
  ...props
}: {
  size?: TypographySizes;
  status: string;
}) => {
  return (
    <JobStatusBox color="var(--black)" {...props}>
      <div>
        <JobStatusBox.Header>{status}</JobStatusBox.Header>
        <JobStatusBox.Subheader>
          Sign up to see other opportunities
        </JobStatusBox.Subheader>
      </div>
    </JobStatusBox>
  );
};
