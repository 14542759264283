import { Typography } from "@hexocean/braintrust-ui-components";
import type { IconElementType } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./team-member-invitations-action-button.module.scss";

export type TeamMemberInvitationsActionButtonType = {
  label: string;
  Icon: IconElementType;
};

export const TeamMemberInvitationsActionButton = ({
  label,
  Icon,
}: TeamMemberInvitationsActionButtonType) => {
  return (
    <Typography
      component="button"
      variant="paragraph"
      sx={{
        textDecoration: "none",
        border: "none",
        backgroundColor: "transparent",
        color: "currentColor",
        padding: 0,
        cursor: "pointer",
      }}
      size="small"
      color="blue"
      fontWeight={500}
      className={styles.button}
    >
      <span className={styles.iconWrapper}>
        <Icon className={styles.icon} />
      </span>
      {label}
    </Typography>
  );
};
