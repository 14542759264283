import cs from "classnames";

import {
  Box,
  IconButton,
  Typography,
  VerticalSeparator,
} from "@hexocean/braintrust-ui-components";
import {
  CloseIcon,
  EditPenIcon,
  EducationIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { Date } from "@js/components/date";
import type { FreelancerSchool } from "@js/types/freelancer";
import { DateFormats } from "@js/utils/date/types";

type EducationCardProps = {
  school: FreelancerSchool;
  editMode?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
};

export const EducationCard = ({
  school,
  editMode = false,
  onEdit,
  onDelete,
}: EducationCardProps) => {
  return (
    <>
      <Box className="education-card">
        <Box
          className={cs("education-card__icon", {
            "education-card__icon--large": !editMode,
            "education-card__icon--small": editMode,
          })}
        >
          <EducationIcon />
        </Box>

        <Box className="education-card__content">
          <Typography
            component="h3"
            variant={editMode ? "paragraph" : "title"}
            size={editMode ? "large" : "small"}
            ellipsis
            fontWeight={400}
          >
            {school.degree?.name}
          </Typography>

          <Box className="education-card__name-date">
            <Typography
              component="p"
              variant="label"
              sx={{
                maxWidth: { xs: "100px", sm: editMode ? "200px" : "initial" },
              }}
              ellipsis
            >
              {school.school.name}
            </Typography>
            {school.graduation_date && <VerticalSeparator color="light-grey" />}

            <Typography component="p" noWrap flexShrink={0}>
              {school.graduation_date && (
                <Date
                  date={school.graduation_date}
                  format={DateFormats["yearFourDigits"]}
                />
              )}
            </Typography>
          </Box>
        </Box>

        {editMode && (
          <Box className="education-card__actions">
            <IconButton
              aria-label="Edit education"
              onClick={onEdit}
              variant="tertiary"
              size="small"
            >
              <EditPenIcon />
            </IconButton>
            <IconButton
              aria-label="Remove education"
              onClick={onDelete}
              variant="tertiary"
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  );
};
