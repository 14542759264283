import { type LocationValue, LocationValueType } from "./types";

export const GOOGLE_ADDRESS_COMPONENTS_TYPE = {
  city: "locality",
  state: "administrative_area_level_1",
  country: "country",
} as const;

type GoogleAddressComponentsType = EnumType<
  typeof GOOGLE_ADDRESS_COMPONENTS_TYPE
>;

type AddressComponents = google.maps.places.PlaceResult["address_components"];

export const findAddressComponentType = (
  addressComponents: AddressComponents,
  type: GoogleAddressComponentsType,
) => {
  return addressComponents?.find((component) => component.types.includes(type));
};

export const mapAddressComponentsToAddress = (
  addressComponents: AddressComponents,
) => {
  const city = findAddressComponentType(
    addressComponents,
    GOOGLE_ADDRESS_COMPONENTS_TYPE.city,
  )?.long_name;
  const state = findAddressComponentType(
    addressComponents,
    GOOGLE_ADDRESS_COMPONENTS_TYPE.state,
  )?.long_name;
  const country = findAddressComponentType(
    addressComponents,
    GOOGLE_ADDRESS_COMPONENTS_TYPE.country,
  )?.short_name;

  return {
    city,
    state,
    country,
  };
};

export const mapAddressComponentsToAddressForJobSearch = (
  addressComponents: AddressComponents,
) => {
  const city = findAddressComponentType(
    addressComponents,
    GOOGLE_ADDRESS_COMPONENTS_TYPE.city,
  )?.short_name;
  const state = findAddressComponentType(
    addressComponents,
    GOOGLE_ADDRESS_COMPONENTS_TYPE.state,
  )?.short_name;
  const country = findAddressComponentType(
    addressComponents,
    GOOGLE_ADDRESS_COMPONENTS_TYPE.country,
  )?.short_name;

  return {
    city,
    state,
    country,
  };
};

export const getLocationValueUniqueId = (val: LocationValue) => {
  if (val.location_type === LocationValueType.custom) {
    return val.custom_location;
  }

  return val.place_id;
};

/** To cover case when custom and google options are selected. */
export const filterOutDuplicatedUSAValue = (
  values: LocationValue[],
): LocationValue[] => {
  let isSelected = false;

  return values.filter((val) => {
    const isCustom =
      val.location_type === "custom" &&
      val.custom_location === ENUMS.JobCustomLocationType.UNITED_STATES_ONLY;

    const isGoogle =
      val.location_type === "google" && val.place_id === SETTINGS.USA_PLACE_ID; // 'United States', 'USA', 'America'

    if ((isCustom || isGoogle) && !isSelected) {
      isSelected = true;
      return true;
    }

    if ((isCustom || isGoogle) && isSelected) {
      return false;
    }

    return true;
  });
};
