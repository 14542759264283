export const APPLICATION_QUALITY = {
  1: "Poor",
  2: "Below Average",
  3: "Average",
  4: "Good",
  5: "Excellent",
};

export const JOB_CLOSE_FIELDS = {
  job_filled_reason: "job_filled_reason",
  job_not_filled_reason: "job_not_filled_reason",
  job_filled_using_platform: "job_filled_using_platform",
  reason_other: "reason_other",
  quality_of_applications_received_rate:
    "quality_of_applications_received_rate",
  client_feedback_was_job_filled: "client_feedback_was_job_filled",
};
