import React from "react";

import { Grid, Typography } from "@hexocean/braintrust-ui-components";

import { Section } from "../../forms/helpers";

import { SessionList } from "./list";

export const SessionsSection = () => {
  return (
    <Section title="Active sessions">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography component="p">
            See your logged in sessions from different devices and delete
            unrecognized or unnecessary sessions.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SessionList />
        </Grid>
      </Grid>
    </Section>
  );
};
