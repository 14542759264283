import { useState } from "react";

import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";

import {
  closeMatchSummaryModal,
  MatchSummaryChipInteractive,
  MatchSummaryTooltip,
  openMatchSummaryModal,
} from "../match-summary";

import type { BidMatchSummaryProps } from "./bid-match-summary";
import { BidMatchSummary } from "./bid-match-summary";

export type BidMatchScoreBadgeProps = Pick<BidMatchSummaryProps, "job" | "bid">;

export const BidMatchScoreBadge = (props: BidMatchScoreBadgeProps) => {
  const isMobileOrTablet = useMediaQuery("md");
  const label = ENUMS.SmartMatchingLabels[props.bid.match_label];

  if (!label || label === ENUMS.SmartMatchingLabels.unavailable) {
    return <></>;
  }

  return isMobileOrTablet ? (
    <BidMatchScoreBadgeWithModal {...props} />
  ) : (
    <BidMatchScoreBadgeWithTooltip {...props} />
  );
};

const BidMatchScoreBadgeWithTooltip = (props: BidMatchScoreBadgeProps) => {
  const [open, setOpen] = useState(false);
  return (
    <MatchSummaryTooltip
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      matchLabel={props.bid.match_label}
    >
      <BidMatchSummary {...props} closeSummary={() => setOpen(false)} />
    </MatchSummaryTooltip>
  );
};

const BidMatchScoreBadgeWithModal = (props: BidMatchScoreBadgeProps) => {
  const handleChipClick = () => {
    openMatchSummaryModal({
      children: (
        <BidMatchSummary
          {...props}
          closeSummary={closeMatchSummaryModal}
          userWithBadgeContainerProps={{ sx: { maxWidth: "85%" } }}
        />
      ),
    });
  };

  return (
    <MatchSummaryChipInteractive
      matchLabel={props.bid.match_label}
      onClick={handleChipClick}
    />
  );
};
