import { useEffect, useState } from "react";
import { SubmissionError } from "redux-form";

import {
  Box,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import { Datetime } from "@js/components/date";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { FreelancerPublic, Talent } from "@js/types/freelancer";
import { isTalentEntity } from "@js/types/freelancer";
import type { InvitationHistoryItem } from "@js/types/jobs";
import { getEnumLabel } from "@js/utils";
import { DateTimeFormats } from "@js/utils/date/types";

import {
  fetchInvitationHistory,
  fetchProposalHistory,
  inviteTalentAsStaff,
  inviteToBid,
} from "../../actions";
import type { InviteTalentToBidFormValues } from "../../forms/invite-talent-to-bid";
import { InviteTalentToBidForm } from "../../forms/invite-talent-to-bid";

type UseInviteToBidArg = {
  jobId?: number;
  freelancer: Talent | FreelancerPublic;
};

export const useInviteToBid = ({ jobId, freelancer }: UseInviteToBidArg) => {
  const [state, setState] = useState({
    invited: false,
    loading: false,
    disabled: false,
  });

  const canInviteAsStaff = useIsNodeStaff();

  const invite = () => {
    if (!jobId) return;

    const { invited, loading } = state;

    if (loading || invited) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    inviteToBid(jobId, [freelancer.id])
      .then(() =>
        setState((prevState) => ({
          ...prevState,
          invited: true,
          loading: false,
        })),
      )
      .catch(() =>
        setState((prevState) => ({
          ...prevState,
          invited: false,
          loading: false,
          disabled: true,
        })),
      );
  };

  const inviteAsStaff = () => {
    if (!canInviteAsStaff) return;

    ModalInstance.open({
      className: "max-width-1000 width-100",
      onClose: ModalInstance.close,
      children: (
        <>
          <section className="center max-width-500">
            <InviteTalentToBidForm
              initialValues={{
                freelancer: freelancer.id,
                jobs: [],
              }}
              onSubmit={(values: InviteTalentToBidFormValues) => {
                const { freelancer: freelancerToInvite, jobs } = values;

                setState((prevState) => ({
                  ...prevState,
                  loading: true,
                  disabled: true,
                }));
                return inviteTalentAsStaff({
                  freelancer: freelancerToInvite,
                  jobs: jobs.filter((job) => !!job?.id).map((job) => job.id),
                })
                  .then(() => {
                    Snackbar.success("Invited successfully.");
                    setState((prevState) => ({
                      ...prevState,
                      invited: true,
                      loading: false,
                      disabled: false,
                    }));
                    ModalInstance.close();
                  })
                  .catch((error) => {
                    setState((prevState) => ({
                      ...prevState,
                      invited: false,
                      loading: false,
                      disabled: false,
                    }));
                    throw new SubmissionError(error);
                  });
              }}
              onCancel={ModalInstance.close}
            />
          </section>
          <InvitationHistory freelancerId={freelancer.id} />
          <ProposalHistory freelancerId={freelancer.id} />
        </>
      ),
    });
  };

  const dependentOnEntityType = isTalentEntity(freelancer)
    ? {
        isInvited: state.invited || freelancer.is_invited_to_this_job,
        canBeInvited: !!jobId && freelancer.can_be_invited_to_bid,
      }
    : {
        isInvited: state.invited,
        canBeInvited: !!jobId,
      };

  return {
    ...dependentOnEntityType,
    loading: state.loading,
    disabled: state.disabled,
    invite,
    canInviteAsStaff,
    inviteAsStaff,
  };
};

const defaultInvitationHistory = [] satisfies InvitationHistoryItem[];

type InvitationHistoryProps = { freelancerId: number };

const InvitationHistory = ({ freelancerId }: InvitationHistoryProps) => {
  const dispatch = useAppDispatch();

  const invitationHistory = useAppSelector(
    (state) => state.jobs.invitationHistory || defaultInvitationHistory,
  );
  const loading = useAppSelector(
    (state) => state.jobs.fetchingInvitationHistory,
  );

  useEffect(() => {
    dispatch(fetchInvitationHistory({ freelancer: freelancerId }));
  }, [dispatch, freelancerId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <section>
      <h3 className="fw-500">
        Invitation History
        <Typography ml={1} component="span" size="small">
          (five last updates)
        </Typography>
      </h3>
      {invitationHistory.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Job Title</TableCell>
              <TableCell>OP</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invitationHistory.map((invitation) => (
              <TableRow key={invitation.id}>
                <TableCell>{invitation.id}</TableCell>
                <TableCell>
                  <RouterLink
                    onClick={() => ModalInstance.close()}
                    style={{ textDecoration: "underline" }}
                    to={`/jobs/${invitation.job.id}`}
                    className="capitalize-first-letter"
                  >
                    {invitation.job.title} ({invitation.job.employer_name})
                  </RouterLink>
                </TableCell>
                <TableCell>{invitation.matcher.public_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        "No records yet."
      )}
    </section>
  );
};

type ProposalHistoryProps = { freelancerId: number };

const ProposalHistory = ({ freelancerId }: ProposalHistoryProps) => {
  const dispatch = useAppDispatch();
  const proposalsHistory = useAppSelector(
    (state) => state.jobs.proposalHistory.results || [],
  );
  const loading = useAppSelector((state) => state.jobs.fetchingProposalHistory);

  useEffect(() => {
    dispatch(fetchProposalHistory({ freelancer: freelancerId }));
  }, [dispatch, freelancerId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box component="section" mt={3}>
      <h3 className="fw-500">
        Application History
        <Typography ml={1} component="span" size="small">
          (recent created)
        </Typography>
      </h3>
      {proposalsHistory.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Job Title</TableCell>
              <TableCell>Application State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {proposalsHistory.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                  <Datetime
                    date={row.created}
                    format={DateTimeFormats["January 1st 1970, 0:00:00 pm"]}
                  />
                </TableCell>
                <TableCell>
                  <RouterLink
                    onClick={() => ModalInstance.close()}
                    style={{ textDecoration: "underline" }}
                    to={`/jobs/${row.job.id}`}
                    className="capitalize-first-letter"
                  >
                    {row.job.title}
                  </RouterLink>
                </TableCell>
                <TableCell>
                  {getEnumLabel(ENUMS.BidStatusLabels, row.status)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        "No records yet."
      )}
    </Box>
  );
};
