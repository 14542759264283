import { useSearchParams } from "react-router-dom";

import type { ChipProps } from "@hexocean/braintrust-ui-components";
import { Chip } from "@hexocean/braintrust-ui-components";
import { StarFilledSVGIcon as Star } from "@hexocean/braintrust-ui-components/Icons";
import type { FreelancerSuperpower } from "@js/types/freelancer";
import type { SkillForJob } from "@js/types/jobs";

type SkillChipPropsTypes<T> = {
  skill: T;
  className?: string;
};

export const JobSkillChip = ({
  className,
  skill,
}: SkillChipPropsTypes<SkillForJob>) => {
  const skillChipIcon =
    skill && skill.matched && skill.is_superpower ? (
      <Star
        style={{
          marginLeft: "-4px",
          fontSize: "15px",
          marginBottom: "2px",
        }}
      />
    ) : undefined;

  return (
    <SkillChipBase
      className={className}
      icon={skillChipIcon}
      title={skill?.name}
      label={skill?.name}
      matched={skill?.matched}
    />
  );
};

export const FreelancerSkillChip = ({
  skill,
}: SkillChipPropsTypes<FreelancerSuperpower>) => {
  const [params] = useSearchParams();

  const searchedSkills = params.get("skills")?.split(",").map(Number);
  const isSkillMatched = !!searchedSkills?.some((id) => id === skill.id);

  return (
    <SkillChipBase
      title={skill?.name}
      label={skill?.name}
      matched={isSkillMatched}
    />
  );
};

type SkillChipBaseProps = {
  matched: boolean;
} & ChipProps;

const SkillChipBase = ({
  className,
  matched,
  icon,
  ...props
}: SkillChipBaseProps) => {
  return (
    <Chip
      className={className}
      variant="rounded"
      LabelProps={{
        component: "span",
        variant: "label",
        size: "small",
        textOverflow: "ellipsis",
      }}
      size="small"
      color={matched ? "positive-green" : "soft-grey"}
      icon={icon}
      {...props}
    />
  );
};
