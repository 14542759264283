import type { User } from "@js/types/auth";
import type { Employer } from "@js/types/employer";
import type { LinkMetadataModel } from "@js/types/give-and-get-help";
import type { JobSkill } from "@js/types/jobs";
import type { Role } from "@js/types/roles";
import type { Attachment } from "@js/types/uploads";
import { typeGuard } from "@js/utils";

export type UploadedPostFileAttachmentData = {
  fileId: string;
  name: string;
  size: number;
  isImage?: boolean;
  isLoading: boolean;
  id?: number;
  attachment?: Attachment;
};

export type PostFileAttachmentPreviewData = {
  id: number;
  thumbnail?: string | null;
  attachment: {
    id: string;
    file: string | null;
    file_size?: number;
    mime: string | null;
    name: string | null;
  };
};

export type SimplePostFileAttachment = {
  id: number | string;
  name: string;
  size: number;
  isImage: boolean;
  thumbnail?: string | null;
  file?: string;
  previewSource?: string;
  isLoading?: boolean;
};

export type CreatePostAttachment =
  | LinkMetadataModel
  | LinkAttachment
  | UploadedPostFileAttachmentData;

export type SimplePostLinkAttachment = LinkMetadataModel & {
  isLink: true;
  source: "default";
  id: string;
  url: string;
};

export type SimplePostTalentLinkAttachment = LinkMetadataModel & {
  isLink: true;
  source: "freelancer";
  user: User;
  location: string;
  rate: number;
  role: Role;
  years_experience: number;
  approved: boolean;
};
export type SimplePostJobLinkAttachment = LinkMetadataModel & {
  isLink: true;
  source: "job";
  title: string;
  image: string;
  employer: Employer;
  job_type: EnumType<typeof ENUMS.JobType>;
  location: string;
  job_skills: JobSkill[];
  payment_type: EnumType<typeof ENUMS.JobPaymentType>;
  budget_minimum_usd: string;
  budget_maximum_usd: string;
};

export type LinksFormData = Array<{ value: string; id: string } | string>;

export type LinkAttachment =
  | SimplePostLinkAttachment
  | SimplePostJobLinkAttachment
  | SimplePostTalentLinkAttachment;

export type PreparedAttachment = SimplePostFileAttachment | LinkAttachment;

export const isLinkAttachment = (
  attachment: PreparedAttachment,
): attachment is LinkAttachment => {
  const isLink = typeGuard<
    SimplePostFileAttachment | LinkAttachment,
    LinkAttachment
  >(attachment, "url");

  return isLink;
};
