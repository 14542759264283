import type { WrappedFieldProps } from "redux-form";
import { Field, formValueSelector } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { LongArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { usePostJobButton } from "@js/apps/employer/hooks/post-job-button";
import { goBackToSecondJobScreen } from "@js/apps/jobs/actions";
import { ReviewBeforeSendingSummaryBox } from "@js/apps/jobs/components/review-before-sending";
import { useGetJobFlowEntry } from "@js/apps/jobs/hooks/use-get-job-flow-entry";
import { Modal } from "@js/components/modal";
import { SwitchField } from "@js/forms/fields/switch";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { JobFormValues } from "@js/types/jobs";

import { CREATE_JOB_FORM_ID, EDIT_JOB_FORM_ID } from "../../constants";

import {
  StatusForAiInterview,
  StatusForApplicationQuestions,
  StatusForCategory,
  StatusForExperience,
  StatusForJobDescription,
  StatusForProjectDetails,
  StatusForRate,
  StatusForSkills,
  StatusForTitle,
} from "./statuses";

import styles from "./style.module.scss";

const PostJobButton = () => {
  const { handleBtnClick, disabled } = usePostJobButton();

  return (
    <Button
      onClick={handleBtnClick}
      variant="positive"
      type="submit"
      shape="squared"
      disabled={disabled}
    >
      Post job
    </Button>
  );
};

type SummarizeJobModalContentProps = StatusForModalFieldsProps;

export const SummarizeJobModalContent = ({
  values,
}: SummarizeJobModalContentProps) => {
  return (
    <Box>
      <SummarizeModalHeader />
      <Box
        width={{ xs: "95vw", md: "80vw" }}
        maxWidth={1000}
        display="flex"
        flexDirection="column"
        py={6}
        px={{ xs: 3, md: 5, lg: 7 }}
        rowGap={5}
      >
        <Typography
          component="h4"
          fontWeight={400}
          variant="title"
          size="small"
        >
          Let’s make sure everything looks right
        </Typography>

        <div className={styles.statusWrapper}>
          <StatusForModalFields values={values} />
          <AutoInviteContainer />
        </div>
      </Box>
    </Box>
  );
};

const SummarizeModalHeader = () => {
  const dispatch = useAppDispatch();
  const { flow_entry } = useGetJobFlowEntry();

  const handleBtnClick = () => {
    closeSummarizeJobModal();
    dispatch(goBackToSecondJobScreen({ flow_entry }));
  };

  return (
    <Box
      display="flex"
      py={2.5}
      px={4}
      borderBottom="1px solid var(--grey-4)"
      alignItems="center"
      justifyContent="space-between"
    >
      <Button
        variant="transparent"
        shape="squared"
        onClick={handleBtnClick}
        sx={{ display: "grid", placeItems: "center" }}
        startIcon={<LongArrowLeftIcon />}
      >
        Back
      </Button>

      <PostJobButton />
    </Box>
  );
};

type StatusForModalFieldsProps = { values: JobFormValues };
export const StatusForModalFields = ({ values }: StatusForModalFieldsProps) => {
  return (
    <ReviewBeforeSendingSummaryBox sx={{ maxWidth: { xs: "100%", lg: "60%" } }}>
      <StatusForTitle values={values} />
      <StatusForCategory values={values} />
      <StatusForProjectDetails values={values} />
      <StatusForSkills values={values} />
      <StatusForExperience values={values} />
      <StatusForRate values={values} />
      <StatusForJobDescription values={values} />
      <StatusForApplicationQuestions values={values} />
      <StatusForAiInterview values={values} />
    </ReviewBeforeSendingSummaryBox>
  );
};

type AutoInviteProps = {
  jobToEdit?: boolean;
};

export const AutoInviteContainer = ({ jobToEdit }: AutoInviteProps) => {
  return (
    <Field
      name="is_private"
      component={AutoInviteWrapper}
      jobToEdit={jobToEdit}
    />
  );
};

const AutoInviteWrapper = ({
  input,
  jobToEdit,
}: AutoInviteProps & WrappedFieldProps) => {
  const isJobPrivate = input.value;

  if (isJobPrivate) return null;
  return <AutoInviteContent jobToEdit={jobToEdit} />;
};

const AutoInviteContent = ({ jobToEdit }: AutoInviteProps) => {
  const formId = jobToEdit ? EDIT_JOB_FORM_ID : CREATE_JOB_FORM_ID;

  const selector = formValueSelector(formId);
  const autoInviteTalentOn: boolean = useAppSelector((state) =>
    selector(state, "auto_invite_talent"),
  );

  return (
    <div className={styles.autoInviteContainer}>
      <Box>
        <Field
          component={SwitchField}
          name="auto_invite_talent"
          label={`Auto invite ${autoInviteTalentOn ? "on" : "off"}`}
          size="small"
          color="success"
        />
        <p className={styles.content}>
          We will automatically invite any relevant talent that looks like a
          good match for this job.
        </p>
      </Box>
    </div>
  );
};

export const SummarizeJobModalInstance = Modal("summarize-job-post", {
  closeButton: false,
  padding: false,
  keepOnBackdropClick: false,
  disablePortal: true,
});

export const openSummarizeJobModal = (values: JobFormValues) => {
  SummarizeJobModalInstance.open({
    children: <SummarizeJobModalContent values={values} />,
  });
};

export const closeSummarizeJobModal = () => {
  SummarizeJobModalInstance.close();
};
