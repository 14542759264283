import type { FreelancerRole } from "@js/types/freelancer";
import { pluralize } from "@js/utils";

type ExperienceData = {
  label: string;
  legend?: string;
  experienceAsArrow?: number;
};

export const getExperienceData = (roles?: FreelancerRole[]): ExperienceData => {
  const primary = roles?.find((role) => role.primary);
  if (!roles || roles.length === 0 || !primary) {
    return { label: "Role" };
  }

  const label = primary.years_experience
    ? `${primary.years_experience} year${pluralize(
        primary.years_experience,
      )} in ${primary?.role.name}`
    : primary?.role.name;

  const legend = [...roles]
    .sort((roleA, roleB) => {
      if (roleA.years_experience < roleB.years_experience) {
        return 1;
      }
      if (roleA.years_experience > roleB.years_experience) {
        return -1;
      }
      return 0;
    })
    .reduce((text, role) => {
      if (role.primary) {
        return text;
      }

      if (text === "") {
        return `+ ${role.years_experience} in ${role.role.name}`;
      }

      return `${text}, ${role.years_experience} in ${role.role.name}`;
    }, "");

  const experienceAsArrow =
    primary.experience_as_arrow || roles[0].experience_as_arrow;

  return {
    label,
    legend,
    experienceAsArrow,
  };
};
