import { useCallback } from "react";

import { useUser } from "@js/apps/common/hooks";
import { useReport } from "@js/apps/common/hooks/use-report";
import { openDeleteOfferConfirmationModal } from "@js/apps/give-and-get-help/components/delete-offer-confirmation-modal";
import { useDeleteOfferFromMessageMutation } from "@js/apps/messenger/api";
import { Snackbar } from "@js/components/snackbar";
import type { HelpOffer } from "@js/types/give-and-get-help";

export type ReportPayload = { reason: string; message_to_moderator?: string };

export const useGetOfferActions = (offer: HelpOffer) => {
  const user = useUser();
  const { handleReportHelpOffer } = useReport();
  const [deleteOfferFromMessage] = useDeleteOfferFromMessageMutation();

  const isOfferActive =
    !!offer.accepted_at && offer.status !== ENUMS.HelpOfferStatus.COMPLETED;
  const isOfferAuthor = offer.author_id === user?.id;

  const reportOffer = handleReportHelpOffer({
    id: offer.id,
    type: offer.refund_requested ? "refund" : "offer",
    name: offer.author.user.first_name,
    isActive: isOfferActive,
    isAsker: !isOfferAuthor,
  });

  const deleteOffer = useCallback(() => {
    openDeleteOfferConfirmationModal({
      onConfirm: () => {
        return deleteOfferFromMessage(offer.id)
          .unwrap()
          .then(() => {
            Snackbar.success("Offer deleted successfully.");
          })
          .catch(() => Snackbar.error("Something went wrong."));
      },
      offerReceiverName: offer.receiver.user.first_name,
    });
  }, [deleteOfferFromMessage, offer]);

  return {
    reportOffer,
    deleteOffer,
  };
};
