import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useEffectRef } from "@js/hooks/use-effect-ref";

import { openInterviewModal } from "./interview-modal";

const INTERVIEW_QUERY_PARAM = "interview";

export const OpenInterviewModalProvider = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const interviewParamValue = searchParams.get(INTERVIEW_QUERY_PARAM);
  const interviewParamIsSet = interviewParamValue !== null;

  const setSearchParamsRef = useEffectRef(setSearchParams);
  useEffect(() => {
    if (!interviewParamIsSet) {
      return;
    }

    openInterviewModal({
      onClose: () => {
        setSearchParamsRef.current((previousParams) => {
          const searchParamsCopy = new URLSearchParams(previousParams);
          searchParamsCopy.delete(INTERVIEW_QUERY_PARAM);

          return searchParamsCopy;
        });
      },
    });
  }, [interviewParamIsSet, setSearchParamsRef]);

  return <></>;
};
