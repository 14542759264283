import React from "react";

import { useAccountType } from "@js/apps/common/hooks";
import { ClientNotificationSettings } from "@js/apps/settings/views/client-notifications";

import { SettingsLayout } from "../layout";
import { TalentNotificationSettings } from "../talent-notifications";

const NotificationsPage = () => {
  const { isEmployer, isFreelancer } = useAccountType();

  return (
    <SettingsLayout pageTitle="Notifications">
      {isEmployer && <ClientNotificationSettings />}
      {isFreelancer && <TalentNotificationSettings />}
    </SettingsLayout>
  );
};

export default NotificationsPage;
