import { Fields } from "redux-form";

import { Box, Divider, Typography } from "@hexocean/braintrust-ui-components";

import { JobLocationField } from "./job-location-field";
import { TimezoneWithOverlap } from "./timezone-with-overlap";
import { timezoneOverlapValidator } from "./validators";

export const LocationAndTimezoneFields = () => {
  return (
    <Box>
      <Box maxWidth={550} py={4}>
        <Typography component="p" size="medium" sx={{ mb: 2 }}>
          Talent location
        </Typography>
        <JobLocation />
      </Box>

      <Divider sx={{ borderColor: "var(--grey-4)" }} />

      <Fields
        names={["timezones", "timezone_overlap"]}
        format={null}
        component={TimezoneWithOverlap}
        validate={timezoneOverlapValidator}
      />

      <Divider sx={{ borderColor: "var(--grey-4)" }} />
    </Box>
  );
};

export const JobLocation = () => {
  return (
    <Fields
      id="locations"
      names={["locations", "locations_strongly_required"]}
      component={JobLocationField}
    />
  );
};
