import React from "react";

import { Grid } from "@hexocean/braintrust-ui-components";
import type {
  StripeWithdrawalMethod,
  TransferWiseWithdrawalMethod,
} from "@js/types/withdrawals";

import { WithdrawalMethodListItem } from "./item";

type Method = TransferWiseWithdrawalMethod | StripeWithdrawalMethod;

type WithdrawalMethodsListProps = {
  withdrawalMethods: Array<Method>;
  onDelete: (withdrawalMethod: Method) => void;
  onSetAsDefault: (withdrawalMethod: Method) => void;
  onAddRecipientsClick: (withdrawalMethod: Method) => void;
};

export const WithdrawalMethodsList = ({
  withdrawalMethods,
  onAddRecipientsClick,
  ...props
}: WithdrawalMethodsListProps) => {
  return (
    <Grid container>
      {withdrawalMethods.map((withdrawalMethod, index) => (
        <Grid item xs={12} key={withdrawalMethod.id}>
          <WithdrawalMethodListItem
            {...props}
            borderBottom={index === withdrawalMethods.length - 1}
            withdrawalMethod={withdrawalMethod}
            onAddRecipientsClick={onAddRecipientsClick}
          />
        </Grid>
      ))}
    </Grid>
  );
};
