import { Suspense } from "react";

import { Loader } from "@hexocean/braintrust-ui-components";
import type { PdfViewerProps } from "@js/apps/common/components/pdf-viewer/pdf-viewer";
import { lazyWithRetry } from "@js/utils/lazy-with-retry";

const PdfViewerComponentLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "pdf-viewer" */ "@js/apps/common/components/pdf-viewer/pdf-viewer"
  );

  return { default: module.PdfViewerComponent };
});

export type { PdfViewerProps };

export const PdfViewer = (props: PdfViewerProps) => {
  return (
    <Suspense fallback={<Loader centered />}>
      <PdfViewerComponentLazy {...props} />
    </Suspense>
  );
};
