import React from "react";
import { Field } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import type { ModalInstanceProps } from "@js/components/modal";
import { Modal, ModalConfirm, ModalInstance } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import { CheckboxField } from "@js/forms/fields/checkbox";
import { capitalize } from "@js/utils/string";

import { OTPAuthCodeField } from "./otp-auth-code";

export type OTPFieldValueType = { code: string; is_backup_code: boolean };

export type CreateModalInstanceReturnType<Type extends string> = {
  [K in `${"open" | "close"}${Capitalize<Type>}`]: () => void;
} & {
  [K in `${Capitalize<Type>}Instance`]: () => JSX.Element;
};

/**
 * @param name "OTPModal" suffix is required
 * @example
 * const {
 *   openExampleOTPModal,
 *   closeExampleOTPModal,
 *   ExampleOTPModalInstance,
 * } = createOTPModalInstance("exampleOTPModal");
 */
export const createOTPModalInstance = <T extends `${string}OTPModal`>(
  name: T,
  modalProps?: ModalInstanceProps,
): CreateModalInstanceReturnType<T> => {
  const result = createOTPModal({
    name,
    modalProps,
    createModalContent: _createOTPAuthVerificationModal,
  });

  return result as CreateModalInstanceReturnType<T>;
};

type CreateOTPModalType<T extends `${string}OTPModal`> = {
  name: T;
  modalProps?: ModalInstanceProps;
  createModalContent: (
    OTPModalInstance: ReturnType<typeof Modal>,
  ) => () => React.ReactNode;
};

export const createOTPModal = <T extends `${string}OTPModal`>({
  name,
  modalProps,
  createModalContent,
}: CreateOTPModalType<T>) => {
  const OTPModalInstance = Modal(name, {
    className: "otp-auth-modal",
    closeButton: false,
    disablePortal: true,
    ...(modalProps || {}),
  });

  const { open, close } = OTPModalInstance;

  const capitalizedName = capitalize(name);

  const result: Record<string, any> = {};

  result[`open${capitalizedName}`] = open;
  result[`close${capitalizedName}`] = close;
  result[`${capitalizedName}Instance`] = createModalContent(OTPModalInstance);

  return result as CreateModalInstanceReturnType<T>;
};

const _createOTPAuthVerificationModal =
  (ModalComponent: ReturnType<typeof Modal>) => (): React.ReactNode => {
    return (
      <ModalComponent>
        <ModalContent onCancel={ModalComponent.close}>
          <OTPAuthCodeField
            inputProps={{
              "aria-describedby": "otp-description",
              autoFocus: true,
            }}
          />
          <Field
            name="is_backup_code"
            component={CheckboxField}
            label="Use backup code instead"
          />
        </ModalContent>
      </ModalComponent>
    );
  };

type ModalContentProps = {
  children: React.ReactNode;
  onCancel: () => void;
};

export const ModalContent = ({ children, onCancel }: ModalContentProps) => {
  return (
    <>
      <div id="otp-description">
        <Typography component="h4" variant="title" fontWeight={400} mb={2}>
          2-step authentication
        </Typography>
        <Typography component="p" mb={4}>
          Enter the 6-digit code generated by your authenticator app.
        </Typography>
      </div>
      <ModalConfirm
        onCancel={onCancel}
        onConfirm="submit"
        confirmText="Verify"
        buttonsSquareShape
        confirmButtonVariant="positive-2"
        buttonsOnEnds
      >
        {children}
      </ModalConfirm>
    </>
  );
};

type OTPAuthVerificationModalProps = {
  onSubmit:
    | ((values: OTPFieldValueType) => void)
    | ((values: OTPFieldValueType) => Promise<void>);
  onSubmitSuccess?: () => void;
};

type OTPFormProps = OTPAuthVerificationModalProps & {
  onClose: () => void;
  onSubmitFail?: () => void;
};

const initialValues: { is_backup_code: boolean } = { is_backup_code: false };

const OTPFormInstance = createFormInstance<OTPFieldValueType, unknown>(
  "otp-auth-verification-modal-form",
);

const OTPForm = ({
  onSubmit,
  onSubmitSuccess,
  onClose,
  onSubmitFail = () => {},
}: OTPFormProps) => {
  return (
    <>
      <OTPFormInstance
        onSubmit={onSubmit}
        onSubmitFail={onSubmitFail}
        onSubmitSuccess={onSubmitSuccess}
        initialValues={initialValues}
      >
        <div id="otp-description">
          <Typography component="h4" variant="title" fontWeight={400} mb={2}>
            2-step authentication
          </Typography>
          <Typography component="p" mb={4}>
            Enter the 6-digit code generated by your authenticator app.
          </Typography>
        </div>
        <ModalConfirm
          onCancel={onClose}
          onConfirm="submit"
          buttonsSquareShape
          confirmButtonVariant="positive-2"
          buttonsOnEnds
          confirmText="Verify"
        >
          <OTPAuthCodeField
            inputProps={{
              "aria-describedby": "otp-description",
              autoFocus: true,
            }}
          />
          <Field
            name="is_backup_code"
            component={CheckboxField}
            label="Use backup code instead"
          />
        </ModalConfirm>
      </OTPFormInstance>
    </>
  );
};

/**
 * @summary Avoid using this function from within form as it will not propagate backend response to the original form. When responses for both OTP and form's onSubmit need to be handled use createOTPModalInstance @see createOTPModalInstance
 */
export const submitActionAfterOTPConfirmation = ({
  onSubmit,
  onSubmitSuccess,
}: OTPAuthVerificationModalProps) => {
  return ModalInstance.open({
    className: "otp-auth-modal",
    closeButton: false,
    children: (
      <OTPForm
        onClose={ModalInstance.close}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess || ModalInstance.close}
      />
    ),
  });
};
