import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import cs from "classnames";

import type { TabsProps } from "@hexocean/braintrust-ui-components";
import { Box, Button, Tab, Tabs } from "@hexocean/braintrust-ui-components";
import { ArrowDropDownIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { normalizePath } from "@js/utils";

import { useSidebarNavButton } from "../../hooks/use-app-layout-sidebar";
import type { DashboardNavItem } from "../../types";
import { checkIfAnySubLinkIsActive } from "../../utils/common-navigation";

import styles from "./styles.module.scss";
type AppLayoutSidebarNavProps = {
  items: Array<DashboardNavItem>;
  itemVariant: NonNullable<DashboardNavItem["variant"]>;
  pathname?: string;
  className?: string;
} & Partial<TabsProps>;

export const AppLayoutSidebarNav: React.FC<
  React.PropsWithChildren<AppLayoutSidebarNavProps>
> = ({ className, ...props }) => {
  return (
    <nav className={className}>
      <SidebarNavTabs {...props} />
    </nav>
  );
};

const SidebarNavTabs = ({
  items,
  itemVariant,
  className,
  ...props
}: AppLayoutSidebarNavProps) => {
  const { pathname } = useLocation();
  const selectedTab = useMemo(() => {
    const normalizedPath = normalizePath(pathname);

    const currentTab = items.find((item) => {
      const tabPathNormalized = normalizePath(item.path);
      return normalizedPath.includes(tabPathNormalized);
    });

    return currentTab?.path || items[0].path;
  }, [pathname, items]);

  return (
    <Tabs
      aria-label="Sidebar navigation"
      value={selectedTab}
      variant="scrollable"
      orientation="vertical"
      scrollButtons={false}
      className={className}
      TabIndicatorProps={{ sx: { display: "none" } }}
      visibleScrollbar
      sx={{
        "& .MuiTabs-flexContainer": { gap: 1 },
        "& .MuiTabs-scroller": {
          "&::-webkit-scrollbar": { width: "3px" },
          "&::-webkit-scrollbar-track": { background: "transparent" },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "black",
            borderRadius: "24px",
            border: "6px solid black",
            borderLeft: 0,
          },
        },
      }}
      {...props}
    >
      {items.map((item) => {
        return (
          <Tab
            key={item.path}
            value={item.path}
            to={item.path}
            component="div"
            label={
              <NavLabel
                item={item}
                itemVariant={itemVariant}
                pathname={pathname}
              />
            }
            sx={{ p: 0 }}
            disableRipple
          />
        );
      })}
    </Tabs>
  );
};

const isItemActive = ({
  pathname,
  item,
}: {
  pathname: string;
  item: DashboardNavItem;
}) => {
  if (item.getIsActive) {
    return item.getIsActive({ pathname });
  }

  const normalizedPath = normalizePath(pathname);
  const normalizedItemPath = normalizePath(item.path);
  const isActive = normalizedPath.startsWith(normalizedItemPath);

  return isActive;
};

type NavLabelProps = {
  item: DashboardNavItem;
  itemVariant: NonNullable<DashboardNavItem["variant"]>;
  pathname: string;
};

const NavLabel = ({ item, itemVariant, pathname }: NavLabelProps) => {
  const isActive = isItemActive({ item, pathname });

  if (item.subLinks?.length) {
    return (
      <SidebarNavButtonBox
        isActive={isActive}
        item={item}
        itemVariant={itemVariant}
        pathname={pathname}
      />
    );
  }
  return (
    <SidebarNavButton
      isActive={isActive}
      item={item}
      itemVariant={itemVariant}
      pathname={pathname}
    />
  );
};

type SidebarNavButtonBoxProps = Pick<
  AppLayoutSidebarNavProps,
  "itemVariant"
> & {
  isActive: boolean;
  item: DashboardNavItem;
  pathname: string;
};

const SidebarNavButtonBox = ({
  isActive,
  item,
  itemVariant,
  pathname,
}: SidebarNavButtonBoxProps) => {
  const [isExpanded, setIsExpanded] = useState(
    isActive ||
      item.defaultExpanded ||
      checkIfAnySubLinkIsActive(item.subLinks || []),
  );

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const isLastLevelOfNestedList = !item.subLinks?.some((s) => !!s.subLinks);

  return (
    <Box className={styles.wrapper}>
      <SidebarNavButton
        isActive={isActive}
        item={item}
        itemVariant={itemVariant}
        expandable={!!item.subLinks?.length}
        isExpanded={!!item.subLinks?.length ? isExpanded : undefined}
        handleExpand={!!item.subLinks?.length ? handleExpand : undefined}
        pathname={pathname}
      />

      {!!item.subLinks?.length && isExpanded && (
        <SidebarNavTabs
          className={cs(styles.subNav, {
            [styles.subNavLast]: isLastLevelOfNestedList,
          })}
          items={item.subLinks}
          pathname={pathname}
          itemVariant={itemVariant}
        />
      )}
    </Box>
  );
};

export type SidebarNavButtonProps = Pick<
  AppLayoutSidebarNavProps,
  "itemVariant"
> &
  Pick<SidebarNavButtonBoxProps, "isActive" | "item" | "pathname"> & {
    expandable?: boolean;
    isExpanded?: boolean;
    handleExpand?: () => void;
  };

const SidebarNavButton = ({
  item,
  isActive,
  expandable,
  itemVariant,
  isExpanded,
  handleExpand,
  pathname,
}: SidebarNavButtonProps) => {
  const {
    path,
    label,
    btnVariant,
    isAnEmployerNavItem,
    Icon,
    hasSubLinkActive,
    sidebarItemSelect,
  } = useSidebarNavButton({
    item,
    itemVariant,
    pathname,
    expandable,
    handleExpand,
  });

  return (
    <Button
      to={path}
      onClick={sidebarItemSelect}
      variant={btnVariant}
      fontWeight={isAnEmployerNavItem ? 500 : 400}
      inverse={isAnEmployerNavItem}
      className={cs(styles.item, {
        "is-active": isActive && !hasSubLinkActive,
      })}
      size="medium"
      startIcon={Icon ? <Icon /> : undefined}
      RouterLink={RouterLink}
    >
      {label}
      {expandable ? (
        <ExpandButton isExpanded={isExpanded} handleExpand={handleExpand} />
      ) : null}
    </Button>
  );
};

type ExpandButtonProps = {
  isExpanded?: boolean;
  handleExpand?: () => void;
};

type ClickEventType = React.MouseEvent<HTMLButtonElement, MouseEvent> &
  React.MouseEvent<HTMLAnchorElement, MouseEvent>;

const ExpandButton = ({ isExpanded, handleExpand }: ExpandButtonProps) => {
  const handleOnClick = (event: ClickEventType) => {
    event.preventDefault();
    event.stopPropagation();

    if (handleExpand) {
      handleExpand();
    }
  };

  return (
    <Button
      variant="transparent"
      onClick={handleOnClick}
      disableRipple
      style={{
        padding: 0,
        minWidth: "initial",
        marginLeft: "auto",
      }}
    >
      <ArrowDropDownIcon
        style={{
          transform: isExpanded ? "rotate(180deg)" : "initial",
        }}
      />
    </Button>
  );
};
