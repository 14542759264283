import React from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";

import { Button } from "@hexocean/braintrust-ui-components";
import { FileUploadField } from "@js/apps/file-upload";
import { Form } from "@js/forms/components/form";
import { useAppSelector } from "@js/hooks";

import type { TaxAttachmentItem } from "./add-w8-form";

import styles from "./add-w-form.module.scss";

const ADD_W9_FORM_ID = "add-w9-form";

const selector = formValueSelector(ADD_W9_FORM_ID);

type AddW9FormProps = {
  onClose: () => void;
};

export type AddW9FormData = {
  w9_form: number;
};

const defaultExistingAttachments: TaxAttachmentItem[] = [];

export const AddW9Form = reduxForm<AddW9FormData, AddW9FormProps>({
  form: ADD_W9_FORM_ID,
})(({ submit, error, submitting, onClose }) => {
  const existingAttachments = useAppSelector(
    (state) => selector(state, "attachments") || defaultExistingAttachments,
  );

  const w9FormValue = useAppSelector((state) => selector(state, "w9_form"));

  return (
    <Form onSubmit={submitting ? null : submit} error={error}>
      <Field
        id="w9_form"
        name="w9_form"
        component={FileUploadField}
        className={styles.fileDropzone}
        uploadType={ENUMS.UploadType.FREELANCER_W9_ATTACHMENT_UPLOAD_TYPE}
        existingFiles={existingAttachments.map((i: TaxAttachmentItem) => ({
          id: i.id,
          attachment: i.attachment,
        }))}
        options={{
          maxSize: SETTINGS.W_FORM_FILE_SIZE_LIMIT,
        }}
      />

      <div className="buttons right mt++">
        <Button variant="primary" onClick={onClose}>
          Upload Later
        </Button>
        <Button
          disabled={!w9FormValue}
          variant="primary"
          onClick={submitting ? undefined : submit}
        >
          Finish
        </Button>
      </div>
    </Form>
  );
});
