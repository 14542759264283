import type { JobLocation, JobTimezone } from "@js/types/jobs";

export const getLocationLabel = (
  locations: JobLocation[] | null,
  maxNumberOfLocations = 3,
  characterLimit?: number,
): string => {
  if (!locations || locations.length === 0) {
    return "Work from anywhere";
  }

  if (locations.length === 1) {
    return transformLocationObjectToLabel(locations[0]);
  }

  if (locations.length === 2 && !characterLimit) {
    const firstLabel = transformLocationObjectToLabel(locations[0]);
    const secondLabel = transformLocationObjectToLabel(locations[1]);

    return firstLabel + " | " + secondLabel;
  }

  return transformMultipleLocationObjectsToLabel(
    locations,
    maxNumberOfLocations,
    characterLimit,
  );
};

const transformMultipleLocationObjectsToLabel = (
  locations: JobLocation[],
  maxNumberOfLocations: number,
  characterLimit?: number,
) => {
  const result: string[] = [];
  let totalCharacters = 0;

  for (let i = 0; i < locations.length; i++) {
    if (result.length >= maxNumberOfLocations) break;

    const label = transformLocationObjectToLabel(locations[i]);
    totalCharacters += label.length;

    if (characterLimit && totalCharacters > characterLimit) {
      if (i === 0) result.push(label);

      break;
    }

    result.push(label);
  }

  const remainingLocations = locations.length - result.length;
  const plusMore =
    remainingLocations > 0 ? ` + ${remainingLocations} more` : "";

  return result.join(" | ") + plusMore;
};

const transformLocationObjectToLabel = (location: JobLocation) => {
  return location.location;
};

export const getFullLocationLabel = (
  locations: JobLocation[] | null,
): string | null => {
  if (!locations || locations.length === 0) {
    return null;
  }

  return locations.map((location) => location.location).join(" | ");
};

export const getTimezoneLabel = (
  timezones: JobTimezone[] | null,
  maxNumberOfTimezones = 3,
): string => {
  if (!timezones || timezones.length === 0) {
    return "Any time";
  }

  if (timezones.length === 1) {
    return timezones[0].timezone;
  }

  return transformMultipleTimeZonesToLabel(timezones, maxNumberOfTimezones);
};

const transformMultipleTimeZonesToLabel = (
  timezones: JobTimezone[],
  maxNumberOfTimezones: number,
) => {
  const result: string[] = [];

  for (let i = 0; i < timezones.length; i++) {
    if (result.length >= maxNumberOfTimezones) break;
    result.push(timezones[i].timezone);
  }

  const remainingTimezones = timezones.length - maxNumberOfTimezones;
  const plusMore =
    remainingTimezones > 0 ? ` + ${remainingTimezones} more` : "";

  return result.join(", ") + plusMore;
};

export const getFullTimezoneLabel = (
  timezone: JobTimezone[] | null,
): string | null => {
  if (!timezone || timezone.length === 0) {
    return null;
  }

  return timezone.map((location) => location.timezone).join(", ");
};
