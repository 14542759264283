import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const useActiveRoom = () => {
  const [queryParams, setQueryParams] = useSearchParams();

  const activeRoom = useMemo(() => {
    const roomId = queryParams.get("room-id");
    if (roomId) return Number(roomId);
    return undefined;
  }, [queryParams]);

  const setActiveRoom = useCallback(
    (roomId?: number) => {
      if (roomId) {
        const newSearchParams = new URLSearchParams(queryParams);
        newSearchParams.set("room-id", roomId.toString());
        setQueryParams(newSearchParams, { replace: true });
      } else {
        const newSearchParams = new URLSearchParams(queryParams);
        newSearchParams.delete("room-id");
        setQueryParams(newSearchParams, { replace: true });
      }
    },
    [queryParams, setQueryParams],
  );

  return { activeRoom, setActiveRoom };
};
