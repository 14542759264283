import React from "react";

import { useIsNodeStaff } from "@js/apps/common/hooks";
import { JobStatusBox } from "@js/apps/jobs/components/job-status/components/job-status-box";
import type { JobStatusBoxProps } from "@js/apps/jobs/components/job-status/types";

export type ClosedStatusProps = JobStatusBoxProps & {
  count: number;
  status: string;
};
export const ClientHiredBraintrustTalent = ({
  count,
  status,
  ...props
}: ClosedStatusProps) => {
  const isNodeStaff = useIsNodeStaff();

  return (
    <JobStatusBox color="var(--dark-blue)" {...props}>
      <div>
        <JobStatusBox.Header>{status}</JobStatusBox.Header>
        <JobStatusBox.Subheader>
          {isNodeStaff && count
            ? `Client Hired ${count} Braintrust Talent`
            : "Client Hired Braintrust Talent"}
        </JobStatusBox.Subheader>
      </div>
    </JobStatusBox>
  );
};
