import type { FormErrors } from "redux-form";
import { SubmissionError } from "redux-form";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";

import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";

import { useEditSpaceDescriptionMutation } from "../../api";

import type { AboutThisSpaceProps } from "./types";

export const useAboutThisSpaceForm = ({
  id,
  description,
}: AboutThisSpaceProps) => {
  const [saveDescription] = useEditSpaceDescriptionMutation();
  const onSubmit = (data: AboutThisSpaceProps) => {
    return saveDescription({ ...data, id })
      .unwrap()
      .catch((error: FetchBaseQueryError) => {
        const errorMessage =
          typeof error?.data === "object" &&
          error?.data !== null &&
          "description" in error.data
            ? (error.data as FormErrors<AboutThisSpaceProps>)
            : null;

        if (errorMessage) {
          throw new SubmissionError(errorMessage);
        }

        Snackbar.error("Could not save description");
        throw new SubmissionError({ _errors: "Could not save description" });
      });
  };

  const onSubmitSuccess = () => {
    Snackbar.success("Description saved");
    ModalInstance.close();
  };

  const initialValues = {
    description,
  };

  return {
    onSubmit,
    onSubmitSuccess,
    initialValues,
  };
};
