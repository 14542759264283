import type { Dispatch, ReactNode, SetStateAction } from "react";
import { useMemo } from "react";
import React, { createContext, useState } from "react";

type PostViewedType = {
  viewedIds: Map<number, number[]>;
  setViewedIds: Dispatch<SetStateAction<Map<number, number[]>>>;
};

export const PostViewedContext = createContext<PostViewedType>({
  viewedIds: new Map(),
  setViewedIds: () => {},
});

const Provider: React.Provider<PostViewedType> = PostViewedContext.Provider;

export const PostViewedContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [viewedIds, setViewedIds] = useState<Map<number, number[]>>(new Map());

  const contextValue = useMemo(() => {
    return { viewedIds, setViewedIds };
  }, [viewedIds]);

  return <Provider value={contextValue}>{children}</Provider>;
};
