import cs from "classnames";

import { IconButton, Tooltip } from "@hexocean/braintrust-ui-components";

import {
  BID_REACTIONS,
  type BidReaction,
} from "../../apps/bids/components/bid-reactions/bid-reactions-options";

import style from "./style.module.scss";

type ReactionsFeedbackProps = {
  handleOnReactionClick: (reaction: BidReaction) => void;
  disabledReactionButton?: boolean;
  currentSelectedReaction: EnumType<typeof ENUMS.BidReactionType> | null;
  isInFilterBox?: boolean;
  isHiddenBid?: boolean;
};

export const ReactionsFeedback = ({
  handleOnReactionClick,
  disabledReactionButton,
  currentSelectedReaction,
  isInFilterBox,
  isHiddenBid,
}: ReactionsFeedbackProps) => {
  return (
    <div
      className={cs(style.reactionsFeedbackContainer, {
        [style.isActive]: !!currentSelectedReaction || isHiddenBid,
        [style.reactionsFeedbackContainerIsInFilterBox]: !!isInFilterBox,
      })}
    >
      {BID_REACTIONS.map((reaction) => (
        <Tooltip
          key={reaction.value || reaction.title}
          enterDelay={300}
          enterNextDelay={300}
          title={reaction.title}
          placement="top"
        >
          <span>
            <IconButton
              disabled={disabledReactionButton}
              variant="transparent"
              className={cs(style.reactionsFeedbackContainerReactionButton, {
                [style[
                  `reactions-feedback-container__reaction-button--${reaction.title.toLowerCase()}`
                ]]: true,
                [style.selected]:
                  (reaction.value === currentSelectedReaction &&
                    !isHiddenBid) ||
                  (!reaction.value && !!isHiddenBid),
              })}
              onClick={() => handleOnReactionClick(reaction)}
              aria-label={`Toggle ${reaction.title} reaction`}
              data-reaction={reaction.value}
              shape="squared"
              size="x-small"
            >
              {reaction.icon}
            </IconButton>
          </span>
        </Tooltip>
      ))}
    </div>
  );
};
