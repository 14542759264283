import type { WrappedFieldsProps } from "redux-form";

import { Stack, Tooltip, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import { CheckboxField, SelectField } from "@js/forms/fields";
import type { FreelancerRole } from "@js/types/freelancer";

type PrimaryRoleCheckboxFieldProps = {
  wasPrimary?: boolean;
  roles?: FreelancerRole[];
  firstRole?: boolean;
} & WrappedFieldsProps;

export const PrimaryRoleCheckboxField = ({
  wasPrimary,
  primary,
  primary_freelancer_role,
  roles,
  firstRole,
}: PrimaryRoleCheckboxFieldProps) => {
  const isMobile = useMediaQuery("sm");
  const labelFontSize = isMobile ? "small" : "medium";
  const isRoleSingle = firstRole || roles?.length === 0;

  return (
    <>
      <Stack direction="row" alignItems="center">
        <CheckboxField
          input={primary.input}
          meta={primary.meta}
          disabled={isRoleSingle}
          label={
            <Typography component="span" fontWeight={500} size={labelFontSize}>
              This is my primary role
            </Typography>
          }
        />
        <Tooltip
          placement="top"
          title="This role will be highlighted on your profile and used to match you to relevant jobs."
          color="var(--black)"
          textAlign="center"
        >
          <InfoIcon style={{ fontSize: 24 }} />
        </Tooltip>
      </Stack>
      {wasPrimary && !primary.input.value && (
        <SelectField
          placeholder="Select your primary role*"
          input={primary_freelancer_role.input}
          meta={primary_freelancer_role.meta}
          options={
            roles?.map((role) => ({ value: role.id, label: role.role.name })) ??
            []
          }
        />
      )}
    </>
  );
};
