/**
 * TODO
 * Do we need to use mapEmailErrorCode?
 * Add checkbox field
 */

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import {
  fetchCurrentUser,
  // fetchPublicInvitationDetails,
  postUserLogin,
} from "@js/apps/auth/actions";
import { useRegisterBYOTTalentMutation } from "@js/apps/byot/api";
import { useAppDispatch, useNavigate, useQueryParams } from "@js/hooks";
import { RhfTextField } from "@js/rhf/fields";
import type { RhfForm } from "@js/rhf/types";
import { mapServerErrors } from "@js/rhf/utils";

const signUpByot = z.object({
  first_name: z.string().min(1, "Required"),
  last_name: z.string().min(1, "Required"),
  email: z.string().min(1, "Required"),
  password: z.string().min(1, "Required"),
  offerId: z.string(),
  accept_terms_of_use: z.boolean(),
});

type SignUpBYOTForm = RhfForm<typeof signUpByot>;

export const SignUpBYOT = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [register] = useRegisterBYOTTalentMutation();
  const { control, handleSubmit, setValue, setError } = useForm<
    SignUpBYOTForm["Values"]
  >({
    defaultValues: {
      accept_terms_of_use: true, // until no field
    },
    resolver: zodResolver(signUpByot),
  });
  const { offer_id } = useQueryParams();

  const onSubmit = async (values: SignUpBYOTForm["Values"]) => {
    try {
      await register(values).unwrap();
      const user = await dispatch(fetchCurrentUser());
      await dispatch(postUserLogin(user));
      navigate("/sign_up_byo_talent/accept_offer");
    } catch (error: any) {
      const errors = mapServerErrors(error.data);

      errors.forEach((err) => {
        setError(err.name as keyof SignUpBYOTForm["Values"], err.error);
      });
    }
  };

  useEffect(() => {
    setValue("offerId", offer_id);

    // dispatch(
    //   fetchPublicInvitationDetails({
    //     invitation_key: offer_id,
    //     referrer: undefined,
    //   }),
    // ).then((data) => {
    //   console.log("--data: ", data);
    // });
  }, [offer_id, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" maxWidth={500} gap={2} p={2}>
        <Typography component="p" variant="title">
          Step 1 - Sign up
        </Typography>
        <RhfTextField
          control={control}
          id="first-name"
          label="First name"
          name="first_name"
        />
        <RhfTextField
          control={control}
          id="last-name"
          label="Last name"
          name="last_name"
        />
        <RhfTextField control={control} id="email" label="Email" name="email" />
        <RhfTextField
          control={control}
          id="password"
          label="Password"
          name="password"
          type="password"
        />
        <Button variant="primary" type="submit">
          Sign up
        </Button>
      </Box>
    </form>
  );
};
