import { useCallback, useMemo } from "react";

import type { GetSkillsQueryParams } from "@js/apps/skills/api";
import {
  useGetPopularSkillsQuery,
  useLazyGetSkillsQuery,
} from "@js/apps/skills/api";
import type { Skill } from "@js/types/admin";

export const useSkills = (
  roleId: number | undefined,
): {
  skillsFromStore: Skill[];
  fetchSkills: (params: GetSkillsQueryParams) => void;
} => {
  const [getSkills, { data }] = useLazyGetSkillsQuery();
  const skills = useMemo(() => data ?? [], [data]);
  const { data: popularSkills } = useGetPopularSkillsQuery({
    roleIds: roleId ? [roleId] : undefined,
  });

  const skillsFromStore = useMemo(() => {
    if (!popularSkills) return [...skills];

    return [...popularSkills, ...skills];
  }, [skills, popularSkills]);

  const fetchSkills = useCallback(
    (params: GetSkillsQueryParams) => {
      getSkills(params, true);
    },
    [getSkills],
  );

  return {
    skillsFromStore,
    fetchSkills,
  };
};
