import { useParams, useSearchParams } from "react-router-dom";
import { isArray, isObject, isString } from "underscore";

import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { Snackbar } from "@js/components/snackbar";
import { typeGuard } from "@js/utils";

import { useSendInvitationMutation } from "../../api";
import { useInvitationNotificationManager } from "../../hooks/invitation-notification-manager";

export const URL_TALENT_INVITATION_PARAM = "freelancer";
export const URL_TALENT_INVITATION_GENERIC_ERROR_MESSAGE =
  "Failed to send invitation";

export const useInviteTalentByUrl = (): { hasInvitationUrl: boolean } => {
  const { id } = useParams();

  const [params, setParams] = useSearchParams();
  const freelancerId = params.get(URL_TALENT_INVITATION_PARAM);
  const hasInvitationUrl = !!id && !!freelancerId;

  const [sendInvitation] = useSendInvitationMutation();
  const { handleInviteNotification } = useInvitationNotificationManager();

  const sendInvite = () => {
    if (!hasInvitationUrl) return;
    sendInvitation({
      jobId: Number(id),
      freelancersIds: [Number(freelancerId)],
    })
      .unwrap()
      .then(() => {
        handleInviteNotification();
      })
      .catch((error: unknown) => {
        if (
          !!error &&
          typeGuard<unknown, { data: { _error: string } }>(error, "data") &&
          error.data._error
        ) {
          return Snackbar.info(error?.data?._error);
        }

        const errorMessage =
          isObject(error) &&
          isArray(error?.data?.freelancers) &&
          isString(error?.data?.freelancers[0])
            ? error?.data?.freelancers[0]
            : URL_TALENT_INVITATION_GENERIC_ERROR_MESSAGE;
        Snackbar.error(errorMessage);
      })
      .finally(() => {
        params.delete(URL_TALENT_INVITATION_PARAM);
        setParams(params);
      });
  };

  useEffectOnce(() => {
    if (hasInvitationUrl) {
      sendInvite();
    }
  });

  return { hasInvitationUrl };
};
