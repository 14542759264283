import { useMemo } from "react";

import { useUser } from "@js/apps/common/hooks/use-user";
import type { BudgetOptions } from "@js/apps/give-and-get-help/components";
import { getAvailableBudgetOptions } from "@js/apps/give-and-get-help/components/category-and-budget-utils/utils";
import { usePostContext } from "@js/apps/give-and-get-help/context";

type UseReplyFn = (isCelebratoryPost: boolean) => {
  budget: BudgetOptions[];
  canGiveOffer: boolean;
};
export const useReply: UseReplyFn = (isCelebratoryPost) => {
  const { postData } = usePostContext();
  const user = useUser();
  const budget = useMemo(() => {
    return postData
      ? getAvailableBudgetOptions(postData.budget, postData.category)
      : [];
  }, [postData]);

  const canGiveOffer = useMemo(() => {
    if (!user || !postData || isCelebratoryPost) {
      return false;
    }
    return user.id !== postData.freelancer.user.id;
  }, [user, postData, isCelebratoryPost]);

  return {
    budget,
    canGiveOffer,
  };
};
