import type { HelpOfferButtonsStatusProps } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/types";
import { useIssueHelpOfferRefund } from "@js/apps/messenger/hooks/use-issue-offer-refund";

import { openCancelHelpOfferRefundModal } from "../../cancel-refund-request-modal";

import { HelpOfferButtonPrimary } from "./help-offer-button-primary";

export const HelpOfferButtonsRefundRequested = ({
  offer,
  isOfferAuthor,
}: HelpOfferButtonsStatusProps): JSX.Element | null => {
  const { openIssueHelpOfferRefundModal } = useIssueHelpOfferRefund(offer);

  const handleCancelRefundClick = () => {
    openCancelHelpOfferRefundModal(offer);
  };

  if (isOfferAuthor)
    return (
      <HelpOfferButtonPrimary onClick={openIssueHelpOfferRefundModal}>
        Issue a refund
      </HelpOfferButtonPrimary>
    );

  return (
    <>
      <HelpOfferButtonPrimary onClick={handleCancelRefundClick}>
        Cancel refund
      </HelpOfferButtonPrimary>
    </>
  );
};
