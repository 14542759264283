import { useState } from "react";

import { LocalStorage } from "@js/services";

type UseAccordionType = ({
  storageKey,
  defaultExpanded,
}: {
  storageKey: string;
  defaultExpanded?: boolean;
}) => {
  toggle: (arg?: { saveInStore?: boolean }) => void;
  expanded: boolean | undefined;
};

export const useAccordionWithLocalStorage: UseAccordionType = ({
  storageKey,
  defaultExpanded,
}) => {
  const [expanded, setExpanded] = useState(() =>
    getInitialExpanded(storageKey, defaultExpanded),
  );

  const toggle = ({ saveInStore = true }: { saveInStore?: boolean } = {}) => {
    setExpanded((prevState) => {
      if (saveInStore) {
        LocalStorage.setItem(storageKey, !prevState ? "true" : "false");
      }

      return !prevState;
    });
  };

  return {
    toggle,
    expanded,
  };
};

const getInitialExpanded = (
  storageKey: string,
  defaultExpanded?: boolean,
): boolean | undefined => {
  const storedValue = LocalStorage.getItem(storageKey);
  if (typeof storedValue === "undefined" || storedValue === null) {
    return defaultExpanded;
  }

  return storedValue === "true";
};
