import { useMemo } from "react";

import type { SpaceUser } from "@js/types/spaces";

type UseSpaceAdminsArg = {
  members?: SpaceUser[];
};

export const useSpaceAdmins = ({ members }: UseSpaceAdminsArg) => {
  const admins = useMemo(() => {
    if (!members) {
      return [];
    }

    return members.filter(({ is_admin }) => is_admin).map(({ user }) => user);
  }, [members]);

  return admins;
};
