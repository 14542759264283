import { useAppSelector } from "@js/hooks/redux";

export const useFreelancerRole = () => {
  const freelancerProfile = useAppSelector(
    (state) => state.freelancer.freelancerProfile,
  );
  const role = freelancerProfile?.role;

  return {
    freelancerProfile,
    role,
  };
};
