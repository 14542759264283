import { useState } from "react";
import { SubmissionError } from "redux-form";
import { skipToken } from "@reduxjs/toolkit/query";

import { useUser } from "@js/apps/common/hooks";
import { useAppDispatch, useDebouncedMutation } from "@js/hooks";
import type { WorkSampleItem } from "@js/types/freelancer";

import {
  addWorkSample,
  deleteWorkSample,
  updateWorkSample,
} from "../../actions";
import {
  useAddPortfolioItemMutation,
  useDeletePortfolioItemMutation,
  useGetFreelancerPublicProfileQuery,
  useUpdatePortfolioItemMutation,
  useUpdatePortfolioItemsPositionsMutation,
} from "../../api";

export const useFreelancerProjectsManager = () => {
  const user = useUser();
  const { data: profile } = useGetFreelancerPublicProfileQuery(
    user?.freelancer ?? skipToken,
  );
  const [projectList, setProjectList] = useState<WorkSampleItem[]>(
    profile?.portfolio_items || [],
  );
  const dispatch = useAppDispatch();
  const [addPortfolioItem] = useAddPortfolioItemMutation();
  const [updatePortfolioItem] = useUpdatePortfolioItemMutation();
  const [deletePortfolioItem] = useDeletePortfolioItemMutation();
  const [updatePortfolioItemsPositions] = useDebouncedMutation(
    useUpdatePortfolioItemsPositionsMutation,
  );

  const handleAddProject = async (values: Omit<WorkSampleItem, "id">) => {
    await addPortfolioItem(values)
      .unwrap()
      .then((data) => {
        dispatch(addWorkSample());
        setProjectList([...projectList, data]);
      })
      .catch((error) => {
        throw new SubmissionError(error.data);
      });
  };

  const handleEditProject = async (values: WorkSampleItem) => {
    await updatePortfolioItem(values)
      .unwrap()
      .then((data) => {
        dispatch(updateWorkSample());
        setProjectList((prev) => {
          const updatedProjects = prev.map((project) =>
            project.id === data.id ? data : project,
          );

          return updatedProjects;
        });
      })
      .catch((error) => {
        throw new SubmissionError(error.data);
      });
  };

  const handleDeleteProject = async (projectId: number) => {
    await deletePortfolioItem(projectId).then(() => {
      dispatch(deleteWorkSample());
      setProjectList((prev) =>
        prev.filter((project) => project.id !== projectId),
      );
    });
  };

  const handleProjectListReorder = async (projects: WorkSampleItem[]) => {
    setProjectList(projects);
    await updatePortfolioItemsPositions({
      itemPositions: projects,
    });
  };

  return {
    projects: projectList,
    handleAddProject,
    handleEditProject,
    handleDeleteProject,
    handleProjectListReorder,
  };
};
