import type { WrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { useJobRole } from "@js/apps/roles/hooks";
import { TextField } from "@js/forms/fields";
import { ButtonSelectField } from "@js/forms/fields/button-select";
import { requiredWithSpecificMessage } from "@js/forms/validators";
import type { OptionWithPrimitiveValue } from "@js/types/common";

import styles from "./style.module.scss";

export const JobRoleField = ({
  hideError,
}: {
  hideError?: boolean;
}): JSX.Element => {
  const { roleOptions } = useJobRole();

  if (!roleOptions.length) {
    return <Loader />;
  }

  return (
    <>
      <Box className={styles.roleField}>
        <Typography
          id="category-label"
          mb={2}
          component="label"
          display="block"
          variant="label"
        >
          Choose a category for this job
        </Typography>
        <Field
          name="role"
          validate={[roleRequired]}
          options={roleOptions}
          labelledBy="category-label"
          // https://github.com/sargant/DefinitelyTyped/blob/32a4039140aa835b73b1faccd454cf4a00ebff6a/types/redux-form/redux-form-tests.tsx
          component={RoleField}
          hideError={hideError}
        />
      </Box>
    </>
  );
};

const roleRequired = requiredWithSpecificMessage(
  "Please choose correct role for your job.",
);

const otherRoleRequired = requiredWithSpecificMessage(
  "Please add a job role name.",
);

const onlyLettersSpacesAndAmpersand = (value: string) => {
  const regex = /^[A-Za-z\s&]+$/;
  if (!regex.test(value)) {
    return "No special symbols allowed.";
  }
  return undefined;
};

const RoleField = ({
  input,
  meta,
  options,
  labelledBy,
  hideError,
}: WrappedFieldProps & {
  labelledBy: string;
  options: OptionWithPrimitiveValue<number>[];
  hideError?: boolean;
}) => {
  return (
    <>
      {meta.error && meta.touched && !hideError && (
        <Typography component="p" color="negative" size="small">
          {meta.error}
        </Typography>
      )}
      <ButtonSelectField
        labelledBy={labelledBy}
        fontWeight={500}
        options={options}
        multiple={false}
        canSingleValueBeEmpty
        variant="white-violet"
        shape="squared"
        input={input}
        meta={meta}
        className="role-field-button"
        size="medium"
        renderAfterButtonSlot={({ value }, isSelected) => {
          if (value === SETTINGS.OTHER_ROLE_ID && isSelected) {
            return (
              <Field
                id="other_role_name"
                name="other_role_name"
                placeholder="Enter in a category"
                validate={[otherRoleRequired, onlyLettersSpacesAndAmpersand]}
                maxLength={50}
                component={TextField}
                className={styles.otherRoleField}
              />
            );
          }
        }}
      />
    </>
  );
};
