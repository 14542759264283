import React from "react";

import {
  ThinkingEmojiIcon,
  ThumbDownEmojiIcon,
  ThumbUpEmojiIcon,
} from "@hexocean/braintrust-ui-components/Icons";

export type BidReaction = {
  icon: JSX.Element;
  title: string;
  value: EnumType<typeof ENUMS.BidReactionType> | undefined;
};

export const BID_REACTIONS: BidReaction[] = [
  {
    icon: <ThumbUpEmojiIcon id="Thumbs up" />,
    title: "Like",
    value: "thumbs_up",
  },
  {
    icon: <ThinkingEmojiIcon id="Thinking" />,
    title: "Thinking",
    value: "thinking",
  },
  {
    icon: <ThumbDownEmojiIcon id="Thumbs down" />,
    title: "Dislike",
    value: undefined,
  },
];
