import type { WithdrawalFormRequirements } from "@js/types/withdrawals";

export const sortLanguagesFirst =
  (languages?: string[]) =>
  (lang1: { code: string }, lang2: { code: string }) => {
    const firstLanguages = languages || ["en"];
    const lang1Index = firstLanguages.indexOf(lang1.code);
    const lang2Index = firstLanguages.indexOf(lang2.code);

    if (lang1Index > -1 && lang2Index > -1) {
      return lang1Index < lang2Index ? -1 : 1;
    }

    return lang1Index > -1 ? -1 : lang2Index > -1 ? 1 : 0;
  };

export const sortTransferWiseRequirements = (
  requirements: WithdrawalFormRequirements,
) => {
  Object.keys(requirements).forEach((recipientType) => {
    const fields = requirements[recipientType].fields;

    requirements[recipientType].fields = fields.sort((a) =>
      a.is_address_field ? 0 : -1,
    );
  });

  return requirements;
};
