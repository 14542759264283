import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { useEqualMemo } from "../equal-memo";

const parseParams = (params: URLSearchParams) => {
  const obj: Record<string, string | string[] | null> = {};

  for (const [key] of params) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

export const useQueryParams = (): Record<string, any> => {
  const [params] = useSearchParams();

  const parsedParams = useMemo(() => parseParams(params), [params]);
  const memoizedParams = useEqualMemo(parsedParams);

  return memoizedParams;
};
