import { useEffect, useRef } from "react";

import { useAppDispatch } from "@js/hooks/redux";

import type { UniversalTabValue } from "../constants";
import { setSearchPaused } from "../universal-search-slice";

import { useSearchEventsUniversalSearch } from "./use-search-events-universal-search";

type UseToggleSearchPausedArg = {
  isLoading: boolean;
  currentTab: UniversalTabValue;
};

export const useToggleSearchPaused = ({
  isLoading,
  currentTab,
}: UseToggleSearchPausedArg) => {
  const dispatch = useAppDispatch();
  const setSearchPausedTimeoutRef = useRef<NodeJS.Timeout>();
  const { handleSearchEvent: handleSubmitDebounced } =
    useSearchEventsUniversalSearch(currentTab);

  const handleSearchEnter = () => {
    if (isLoading) return;

    clearTimeout(setSearchPausedTimeoutRef.current);
    dispatch(setSearchPaused(true));
  };

  const handleSearchChange = (search: string) => {
    handleSubmitDebounced(search);
    clearTimeout(setSearchPausedTimeoutRef.current);
    dispatch(setSearchPaused(false));
    setSearchPausedTimeoutRef.current = setTimeout(() => {
      dispatch(setSearchPaused(true));
    }, 5500);
  };

  const handleTabChange = () => {
    clearTimeout(setSearchPausedTimeoutRef.current);
    setSearchPausedTimeoutRef.current = setTimeout(() => {
      dispatch(setSearchPaused(true));
    }, 5000);
  };

  useEffect(
    () => () => {
      clearTimeout(setSearchPausedTimeoutRef.current);
    },
    [],
  );

  return {
    handleSearchEnter,
    handleSearchChange,
    handleTabChange,
  };
};
