import { LocalStorage } from "@js/services/local-storage";

const viewedPublicJobsLimit = 12;

export const getViewedPublicJobs = (): number[] => {
  const storedViewedPublicJobs = LocalStorage.getItem(
    LocalStorage.keys.VIEWED_PUBLIC_JOBS,
  );

  if (!storedViewedPublicJobs) {
    return [];
  }

  try {
    const viewedPublicJobs: unknown = JSON.parse(storedViewedPublicJobs);
    if (!isValidViewedJobsList(viewedPublicJobs)) {
      return [];
    }

    return viewedPublicJobs.slice(0, viewedPublicJobsLimit);
  } catch (_e) {
    return [];
  }
};

export const setViewedPublicJobs = (jobs: number[]) => {
  try {
    LocalStorage.setItem(
      LocalStorage.keys.VIEWED_PUBLIC_JOBS,
      JSON.stringify(jobs.slice(0, viewedPublicJobsLimit)),
    );
  } catch (error) {
    void error;
  }
};

export const addViewedPublicJob = (jobId: number) => {
  const viewedPublicJobs = getViewedPublicJobs();

  const newUniqueViewedPublicJobs = [...new Set([jobId, ...viewedPublicJobs])];

  setViewedPublicJobs(newUniqueViewedPublicJobs);
};

const isValidViewedJobsList = (
  viewedPublicJobs: unknown,
): viewedPublicJobs is number[] => {
  if (!Array.isArray(viewedPublicJobs)) {
    return false;
  }

  return viewedPublicJobs.every((jobId) => Number.isInteger(jobId));
};
