import { useMemo } from "react";

import { useGetFreelancerJobsToIssueInvoiceQuery } from "@js/apps/invoices/api";

export const useJobSelectList = () => {
  const result = useGetFreelancerJobsToIssueInvoiceQuery();

  const data = useMemo(() => {
    return {
      ...result,
      data: result.data || [],
    };
  }, [result]);

  return data;
};
