import { motion } from "framer-motion";

import { Box, Typography } from "@hexocean/braintrust-ui-components";

export const ReviewMock = () => {
  return (
    <Box
      className="review-mock"
      display="flex"
      flexDirection="row"
      alignItems="center"
      borderRadius={8}
      gap={0.5}
    >
      <Typography variant="paragraph" component="span">
        ⭐
      </Typography>

      <motion.div
        className="review-mock__word"
        animate={{ width: 37 }}
        transition={{
          type: "spring",
          stiffness: 50,
        }}
      ></motion.div>

      <motion.div
        className="review-mock__word"
        animate={{ width: 23 }}
        transition={{
          type: "spring",
          stiffness: 50,
        }}
      ></motion.div>

      <div className="review-mock__you-container">
        <motion.div
          className="review-mock__you"
          initial={{
            opacity: 0,
            translateX: "200%",
            translateY: "-200%",
          }}
          animate={{ opacity: 1, translateX: "0%", translateY: "0%" }}
          transition={{
            delay: 1,
          }}
        >
          <Typography
            component="p"
            variant="paragraph"
            fontWeight={500}
            size="small"
          >
            You
          </Typography>

          <img
            src={`${SETTINGS.STATIC_URL}give-get-help/triangle-rounded.svg`}
            className="triangle"
            alt=""
            width="15"
            height="15"
          />
        </motion.div>
      </div>

      <motion.div
        className="review-mock__button"
        initial={{ opacity: 0, scale: 0, translateY: "-50%" }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          delay: 1,
        }}
      >
        <span className="review-mock__button-plus"></span>
      </motion.div>
    </Box>
  );
};
