import { Modal, ModalConfirm } from "@js/components/modal";

const DELETE_PAYMENT_METHOD_CONFIRM_MODAL_ID =
  "DELETE_PAYMENT_METHOD_CONFIRM_MODAL";

export const DeletePaymentMethodConfirmModalInstance = Modal(
  DELETE_PAYMENT_METHOD_CONFIRM_MODAL_ID,
  { closeButton: false },
);

export const openDeletePaymentMethodModal = (onConfirm: () => void) => {
  DeletePaymentMethodConfirmModalInstance.open({
    children: (
      <ModalConfirm
        onConfirm={onConfirm}
        onCancel={DeletePaymentMethodConfirmModalInstance.close}
      >
        Are you sure you want to delete this payment method?
      </ModalConfirm>
    ),
  });
};
