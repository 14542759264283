import React from "react";

import type { EmployerStatsSummaryType } from "@js/types/dashboard";
import { formatCurrency } from "@js/utils";

import {
  ReferralsStatsSummaryState,
  ReferralsStatsSummaryStateColumn,
  ReferralsStatsSummaryStateContainer,
} from "../referral-stats";

export type EmployerReferralsStatsSummaryProps = {
  summary: EmployerStatsSummaryType;
};

export const EmployerReferralsStatsSummary = ({
  summary,
}: EmployerReferralsStatsSummaryProps) => {
  const totalBillings = String(
    summary.total_billed === 0
      ? 0
      : formatCurrency(summary.total_billed, { removeDecimal: true }),
  );

  return (
    <ReferralsStatsSummaryStateContainer>
      <ReferralsStatsSummaryStateColumn>
        <ReferralsStatsSummaryState
          label="Clients referred"
          value={summary.referred_count}
          tooltip="Total number of signed clients you referred to Braintrust."
        />
        <ReferralsStatsSummaryState
          label="Total billings"
          value={totalBillings}
          tooltip="Total amount billed from clients you referred to Braintrust.
                    Note that BTRST are rewarded only when invoices are paid."
        />
      </ReferralsStatsSummaryStateColumn>
    </ReferralsStatsSummaryStateContainer>
  );
};
