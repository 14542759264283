import React from "react";
import { Field } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { AssignOwnersOptionsField } from "@js/apps/employer/forms/assign-owners-options-field";
import { UserSearchMultipleField } from "@js/components/autocomplete-new/combobox-multiple/user";
import { ModalInstance } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import type { EmployerPublicProfile } from "@js/types/employer";
import { enumToOptions } from "@js/utils";

import { useAssignOwners } from "../../hooks/assign-owners";

export type AssignOwnersFormDataTypes = {
  default_op_owners: number[] | null;
  default_sale_owners: number[] | null;
  assign_owner_options: string[] | null;
};

const AssignOwnersForm = createFormInstance<AssignOwnersFormDataTypes, unknown>(
  "assign-job-owners-form",
);

type openAssignOwnersModalArgs = {
  employer: EmployerPublicProfile;
};
export const openAssignOwnersModal = ({
  employer,
}: openAssignOwnersModalArgs) => {
  ModalInstance.open({
    closeButton: false,
    keepOnBackdropClick: true,
    disableEscapeKeyDown: true,
    disablePortal: true,
    children: (
      <AssignOwnersModalContent
        employer={employer}
        onSubmitSuccess={ModalInstance.close}
        onCancel={ModalInstance.close}
      />
    ),
  });
};

type AssignOwnersModalProps = {
  employer: EmployerPublicProfile;
  onCancel: () => void;
  onSubmitSuccess: () => void;
};

export const AssignOwnersModalContent = ({
  employer,
  onCancel,
  onSubmitSuccess: onSuccess,
}: AssignOwnersModalProps) => {
  const {
    initialValues,
    onSubmit,
    onSubmitSuccess,
    employerDefaultJobOwnersFetched,
  } = useAssignOwners({
    employerId: employer.id,
    onSubmitSuccess: onSuccess,
  });

  if (!employerDefaultJobOwnersFetched) return null;

  return (
    <Box minWidth={500} maxWidth={500}>
      <Typography component="h2" variant="title" size="small" fontWeight={400}>
        Assign Owners
      </Typography>
      <Typography mb={2} component="p" size="medium" variant="paragraph">
        Will auto-assign new open jobs to these team members
      </Typography>

      <AssignOwnersForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
      >
        <Box>
          <Box mb={2}>
            <Field
              name="default_op_owners"
              label="Add Talent Specialist"
              placeholder="Select Owner(s)"
              component={UserSearchMultipleField}
              endpointParams={{ return_ops_only: true, page_size: 100 }}
            />
          </Box>

          <Box>
            <Field
              name="default_sale_owners"
              label="Add Sales Specialist"
              placeholder="Select Owner(s)"
              component={UserSearchMultipleField}
              endpointParams={{ return_sales_only: true, page_size: 100 }}
            />
          </Box>
          <Box mt={2}>
            <Field
              name="assign_owner_options"
              options={enumToOptions(ENUMS.AssignOwnersOptionsLabels)}
              component={AssignOwnersOptionsField}
            />
          </Box>
        </Box>
        <Box display="flex" gap={2} mt={2}>
          <Button
            size="medium"
            variant="positive"
            shape="squared"
            type="submit"
          >
            Change owners
          </Button>
          <Button
            onClick={onCancel}
            variant="transparent"
            shape="squared"
            size="medium"
          >
            Cancel
          </Button>
        </Box>
      </AssignOwnersForm>
    </Box>
  );
};
