import { useGetWalletBalanceQuery } from "@js/apps/dashboard/api";

import { useUser } from "../use-user";

type UseUserWalletBalanceArg = { skip?: boolean };

export const useUserWalletBalance = ({
  skip,
}: UseUserWalletBalanceArg = {}) => {
  const user = useUser();
  return useGetWalletBalanceQuery(
    { balanceHolderId: Number(user?.balance_holder) },
    { skip: !user?.balance_holder || skip },
  );
};
