import type { RootState } from "@js/store";
import type { EmployerBid } from "@js/types/jobs";
import { getQueriesByEndpoint } from "@js/utils/rtkq";

import type {
  FetchBidsParams,
  FetchBidsResponse,
  UpdateBidReactionParams,
} from "./types";

export const getAllFetchBidsArgs = (state: RootState) => {
  const allPostQueries = getQueriesByEndpoint(state, "fetchBids");

  return allPostQueries.map((query) => query?.originalArgs as FetchBidsParams);
};

export const patchBidsOnReactionUpdate = ({
  draft,
  bidId,
  reaction,
}: {
  draft: FetchBidsResponse;
  bidId: number;
  reaction: UpdateBidReactionParams["reaction"];
}) => {
  const updatedBid = draft.results.find((bid) => bid.id === bidId);
  if (!updatedBid) {
    return;
  }

  patchBidOnReactionUpdate({ bid: updatedBid, reaction });
};

export const patchBidOnReactionUpdate = ({
  bid,
  reaction,
}: {
  bid: Pick<EmployerBid, "employer_reaction">;
  reaction: UpdateBidReactionParams["reaction"];
}) => {
  bid.employer_reaction = reaction;
};

export const patchBidsOnHideToggle = ({
  draft,
  bidId,
  isHidden,
}: {
  draft: FetchBidsResponse;
  bidId: number;
  isHidden: boolean;
}) => {
  const updatedBid = draft.results.find((bid) => bid.id === bidId);
  if (!updatedBid) {
    return;
  }

  patchBidOnHideToggle({ bid: updatedBid, isHidden });
};

export const patchBidOnHideToggle = ({
  bid,
  isHidden,
}: {
  bid: Pick<EmployerBid, "is_hidden">;
  isHidden: boolean;
}) => {
  bid.is_hidden = isHidden;
};

export const patchBidsOnMatcherRecommendedToggle = ({
  draft,
  bidId,
  isRecommended,
}: {
  draft: FetchBidsResponse;
  bidId: number;
  isRecommended: boolean;
}) => {
  const updatedBid = draft.results.find((bid) => bid.id === bidId);
  if (!updatedBid) {
    return;
  }

  patchBidOnMatcherRecommendedToggle({ bid: updatedBid, isRecommended });
};

export const patchBidOnMatcherRecommendedToggle = ({
  bid,
  isRecommended,
}: {
  bid: Pick<
    EmployerBid,
    "recommended_by_matcher" | "match_label" | "employer_reaction"
  >;
  isRecommended: boolean;
}) => {
  bid.recommended_by_matcher = isRecommended;

  if (isRecommended) {
    bid.match_label = "recommended_by_matcher";
    bid.employer_reaction = "thumbs_up";
  } else {
    // match label is unknown here ...
    // ... it will be updated with a bid/bids list refetch
    bid.employer_reaction = null;
  }
};

export const patchBidsOnViewedUpdate = ({
  draft,
  bidId,
}: {
  draft: FetchBidsResponse;
  bidId: number;
}) => {
  const updatedBid = draft.results.find((bid) => bid.id === bidId);
  if (!updatedBid) {
    return;
  }

  patchBidOnViewedUpdate({ bid: updatedBid });
};

export const patchBidOnViewedUpdate = ({
  bid,
}: {
  bid: Pick<EmployerBid, "status">;
}) => {
  if (bid.status === "new") {
    bid.status = "viewed";
  }
};

export const patchBidsNotesCounterOnNoteAdd = ({
  draft,
  bidId,
}: {
  draft: FetchBidsResponse;
  bidId: number;
}) => {
  const updatedBid = draft.results.find((bid) => bid.id === bidId);
  if (!updatedBid) {
    return;
  }

  updatedBid.notes_count += 1;
};

export const patchBidNotesCounterOnNoteAdd = ({
  bid,
}: {
  bid: Pick<EmployerBid, "notes_count">;
}) => {
  bid.notes_count += 1;
};

export const patchBidsNotesCounterOnNoteDelete = ({
  draft,
  bidId,
}: {
  draft: FetchBidsResponse;
  bidId: number;
}) => {
  const updatedBid = draft.results.find((bid) => bid.id === bidId);
  if (!updatedBid) {
    return;
  }

  updatedBid.notes_count -= 1;
};

export const patchBidNotesCounterOnNoteDelete = ({
  bid,
}: {
  bid: Pick<EmployerBid, "notes_count">;
}) => {
  bid.notes_count -= 1;
};
