import { useLayoutEffect, useState } from "react";

/*
  This function was made to truncate to two lines and quote text ("Message...")
  Shortening and adding ellipsis is simple, multilineEllipsis={2} gets the job done.
  Adding " to the end after ellpisis is the tricky part. However if you have simpler, preferably 
  CSS only solution feel free to modify it.
*/
export const useTruncateAndWrapQuote = (
  message: string | undefined,
  ref: React.MutableRefObject<HTMLDivElement | null>,
): string | null => {
  const [length, setLength] = useState(0);

  /* Experimentally found factor, based on lorem ipsum text. 
  But in case where there are many long letters or long words it may be wrapped differently. 
  That's why multilineEllipsis={2} is still there (there won't be closing quotation mark but at least it looks good)
  */
  const letterLengthFactor = 5;

  useLayoutEffect(() => {
    if (!ref.current || !message) return;

    setLength(Math.floor(ref.current.clientWidth / letterLengthFactor));
  }, [ref, message, setLength]);

  if (!message) return null;
  if (length < message.length) return `"${message?.slice(0, length)}..."`;
  return `"${message}"`;
};
