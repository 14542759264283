import React from "react";

import { Stack, Typography } from "@hexocean/braintrust-ui-components";
import {
  InfoTooltip,
  type InfoTooltipProps,
} from "@js/components/info-tooltip";

type ReferralsStatsSummaryStateProps = {
  label: string;
  value: string | number;
  tooltip: InfoTooltipProps["title"];
};

export const ReferralsStatsSummaryState = ({
  label,
  value,
  tooltip,
}: ReferralsStatsSummaryStateProps) => {
  return (
    <Stack sx={{ mb: 0.5 }}>
      <Typography component="p" variant="title" size="large" fontWeight={400}>
        {value}
      </Typography>
      <Typography component="p" variant="label" size="small">
        {label}
        <InfoTooltip title={tooltip} size="18px" />
      </Typography>
    </Stack>
  );
};
