import type { TypedWrappedFieldProps, WrappedFieldProps } from "redux-form";
import { Field } from "redux-form";
import cs from "classnames";

import { Box, FormHelperText } from "@hexocean/braintrust-ui-components";
import { actionTypes } from "@js/apps/give-and-get-help/action-types";
import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH_TO_DISPLAY_COUNTER,
} from "@js/apps/give-and-get-help/constants";
import {
  useCommentContext,
  usePostContext,
  usePostLocationContext,
} from "@js/apps/give-and-get-help/context";
import { PostLexicalTextField } from "@js/apps/give-and-get-help/forms/fields/post-lexical-textfield";
import { useOnUserStartedTyping } from "@js/apps/give-and-get-help/hooks/on-user-started-typing";
import { useDisableSpecificPostActions } from "@js/apps/give-and-get-help/hooks/use-disable-specific-post-actions";
import type { ReplyType } from "@js/apps/give-and-get-help/types";
import { getError, shouldDisplayError } from "@js/forms/utils";
import type { User } from "@js/types/auth";
import type { IPost, PostComment } from "@js/types/give-and-get-help";
import { assertUnreachable } from "@js/utils";

import { CharacterIndicator } from "../character-indicator";
import { LinksFromTextAdapterWrapper } from "../links-from-text-adapter";
import { POST_FIELD_TEXT } from "../post-description-field/constants";

import styles from "./style.module.scss";

type ReplyTextFieldProps = {
  user: User | null;
  type: ReplyType;
  onChange?: () => void;
  "data-testid"?: string;
};

export const ReplyTextField = ({
  user,
  type,
  onChange,
  "data-testid": dataTestId,
}: ReplyTextFieldProps) => {
  const disablePostActions = useDisableSpecificPostActions();

  if (!user) return null;

  const Component = getComponentByType(type);

  return (
    <Box className={styles.replyField}>
      <Field
        data-testid={dataTestId}
        name={POST_FIELD_TEXT}
        component={Component}
        disabled={disablePostActions}
        type={type}
        onChange={onChange}
      />
      <LinksFromTextAdapterWrapper />
    </Box>
  );
};

const getComponentByType = (replyType: ReplyType) => {
  switch (replyType) {
    case "edit-comment":
    case "reply-to-comment":
      return CommentReplyField;
    case "reply-to-post":
      return PostReplyField;
    default: {
      assertUnreachable(replyType);
      return PostReplyField;
    }
  }
};

type ReplyFieldWrapperProps = Omit<
  ReplyFieldProps,
  "onUserStartedTypingPayload"
> & { type: ReplyType };

export const PostReplyField = (props: ReplyFieldWrapperProps) => {
  const { postData } = usePostContext();
  const postLocation = usePostLocationContext();
  const contextData = {
    post: postData,
    post_location: postLocation,
  };

  return <ReplyField {...props} onUserStartedTypingPayload={contextData} />;
};

export const CommentReplyField = (props: ReplyFieldWrapperProps) => {
  const { postData } = usePostContext();
  const { comment } = useCommentContext();
  const postLocation = usePostLocationContext();

  const contextData = {
    post: postData,
    comment,
    post_location: postLocation,
  };

  return <ReplyField {...props} onUserStartedTypingPayload={contextData} />;
};

type ReplyFieldProps = {
  "data-testid"?: string;
  disabled?: boolean;
  onUserStartedTypingPayload?: {
    post?: IPost;
    comment?: PostComment;
    post_location?: ReturnType<typeof usePostLocationContext>;
  };
} & TypedWrappedFieldProps<string>;

const ReplyField = ({
  disabled = false,
  onUserStartedTypingPayload,
  "data-testid": dataTestId = "reply-field",
  ...textField
}: ReplyFieldProps) => {
  useOnUserStartedTyping({
    input: textField.input,
    meta: textField.meta,
    action: {
      type: actionTypes["reply-user-started-typing"],
      payload: onUserStartedTypingPayload,
    },
  });

  const onChange = (textContent: string) => {
    const wasTextFieldClearedAfterSubmitting =
      textField.meta.pristine && textContent === "";

    // We don't want to trigger onChange here,
    // or else the Field will be "touched" and user will see validation errors
    if (wasTextFieldClearedAfterSubmitting) {
      return;
    }

    textField.input.onChange(textContent);
  };

  return (
    <>
      <PostLexicalTextField
        onChange={onChange}
        data-testid={dataTestId}
        placeholderElement={
          <span className={styles.placeholder}>Post a reply...</span>
        }
        disabled={disabled}
        text={textField as WrappedFieldProps}
        inputClassName={cs(styles.input)}
      />
      <Box className={styles.helperTextWrapper}>
        <CharacterIndicator
          input={textField.input as WrappedFieldProps["input"]}
          maxLength={DESCRIPTION_MAX_LENGTH}
          minimumLengthToDisplayCounter={
            DESCRIPTION_MAX_LENGTH_TO_DISPLAY_COUNTER
          }
        />
        {shouldDisplayError([textField]) && (
          <FormHelperText className={styles.error} error>
            {getError([textField])}
          </FormHelperText>
        )}
      </Box>
    </>
  );
};
