import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import cs from "classnames";

import { IconButton } from "@hexocean/braintrust-ui-components";
import { BookmarkIcon } from "@hexocean/braintrust-ui-components/Icons";
import { hideBookmarksIntro } from "@js/apps/bookmarks/actions";
import { RouterLink } from "@js/components/link";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { BookmarksIntroduce } from "../bookmarks-introduce";

import styles from "./styles.module.scss";

type BookmarkNavLinkProps = {
  onClick: () => void;
  className?: string;
};

export const BookmarkNavLink = ({
  onClick,
  className,
}: BookmarkNavLinkProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isBookmarksPage = location.pathname.startsWith("/bookmarks/");
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isBookmarksIntroModalVisible = useAppSelector(
    (state) => state.bookmarks.isBookmarksIntroModalVisible,
  );

  return (
    <>
      <IconButton
        href="/bookmarks/"
        aria-label="bookmarks-page-link"
        variant="transparent"
        name="bookmarks link"
        size="medium"
        className={cs(className, {
          [styles.bookmarkNavLinkHighlight]: isBookmarksIntroModalVisible,
        })}
        RouterLink={RouterLink}
        onClick={() => {
          dispatch(hideBookmarksIntro());
          onClick();
        }}
        ref={buttonRef}
      >
        <BookmarkIcon
          className={cs(styles.bookmarkIcon, {
            [styles.bookmarkIconHighlight]: isBookmarksIntroModalVisible,
            [styles.bookmarkIconOutline]: !isBookmarksPage,
          })}
        />
      </IconButton>
      <BookmarksIntroduce anchorRef={buttonRef} />
    </>
  );
};
