import { API } from "@js/api";

import type {
  CreateDashboardLinkResultType,
  CreateOnboardingLinkResultType,
} from "./types";

const withdrawalApi = API.injectEndpoints({
  endpoints: (build) => ({
    createDashboardLink: build.mutation<CreateDashboardLinkResultType, number>({
      query: (id) => ({
        url: `/stripe_recipients/${id}/dashboard_link/`,
        method: "POST",
      }),
    }),
    createOnboardingLink: build.mutation<
      CreateOnboardingLinkResultType,
      number
    >({
      query: (id) => ({
        url: `/stripe_recipients/${id}/onboarding_link/`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useCreateDashboardLinkMutation,
  useCreateOnboardingLinkMutation,
} = withdrawalApi;
