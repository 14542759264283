import type { ReactNode } from "react";
import { useContext } from "react";
import { useMemo } from "react";
import { createContext, useState } from "react";

type ResourcesContextType = ReturnType<typeof useLinkMetadataLoading>;

const ResourcesContext = createContext<ResourcesContextType | null>(null);

type ResourcesContextProviderProps = {
  children: ReactNode;
};

export const ResourcesContextProvider = ({
  children,
}: ResourcesContextProviderProps) => {
  const { isLinkMetaLoading, setIsLinkMetaLoading } = useLinkMetadataLoading();

  const memoizedValue = useMemo(() => {
    return { isLinkMetaLoading, setIsLinkMetaLoading };
  }, [isLinkMetaLoading, setIsLinkMetaLoading]);

  return (
    <ResourcesContext.Provider value={memoizedValue}>
      {children}
    </ResourcesContext.Provider>
  );
};

const useLinkMetadataLoading = () => {
  const [isLinkMetaLoading, setIsLinkMetaLoading] = useState(false);

  return { isLinkMetaLoading, setIsLinkMetaLoading };
};

export const useResourcesContext = () => {
  const context = useContext(ResourcesContext);

  if (!context) {
    throw new Error("useResourcesContext is missing!");
  }

  return context;
};
