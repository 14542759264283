import React from "react";

import { useAccountType } from "@js/apps/common/hooks";
import { useCurrentFreelancerProfileCompletion } from "@js/apps/freelancer/hooks/use-current-freelancer-profile-completion";
import { CompleteProfileStatusBox } from "@js/apps/jobs/components/job-status/freelancer/complete-profile-status";
import type { FreelancerBid, Job } from "@js/types/jobs";

import { EmployerStatus } from "./employer/employer";
import { StatusForFreelancer } from "./freelancer/status-for-freelancer";

type StatusProps = {
  job: Job | null;
  bid: FreelancerBid | undefined;
  className?: string;
  longTile?: boolean;
};

export const JobStatus = ({
  job,
  bid,
  longTile,
  className,
  ...props
}: StatusProps) => {
  const { isFreelancer, isEmployer, isFreelancerApproved } = useAccountType();
  const { data: profileCompletion, isLoading: isLoadingProfileCompletion } =
    useCurrentFreelancerProfileCompletion();

  const isProfileCompleted = !!profileCompletion?.profile_completion_hit_at;

  if (isLoadingProfileCompletion) {
    return <></>;
  }

  if (
    isFreelancer &&
    !isFreelancerApproved &&
    !isProfileCompleted &&
    job?.status_for_freelancer ===
      ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS
  ) {
    return <CompleteProfileStatusBox longTile={longTile} />;
  }

  if (isEmployer) {
    return <EmployerStatus job={job} className={className} {...props} />;
  }

  return (
    <StatusForFreelancer
      job={job}
      bid={bid}
      longTile={longTile}
      statusForFreelancer={job?.status_for_freelancer}
      className={className}
      {...props}
    />
  );
};
