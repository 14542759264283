import { useState } from "react";

import { Fade } from "@hexocean/braintrust-ui-components";
import { useAppSelector } from "@js/hooks";

import { JobsForYouSection } from "./jobs-for-you-section";
import { NewestJobsSection } from "./newest-jobs-section";

export const JobsSection = () => {
  const hasRole = !!useAppSelector(
    (state) => state.freelancer.freelancerProfile?.role,
  );
  const [hadRoleSelectedOnInitialLoad] = useState(hasRole);
  const [newestJobsHidden, setNewestJobsHidden] = useState(hasRole);

  return (
    <>
      {hasRole && newestJobsHidden && (
        <Fade
          in={hasRole}
          timeout={1500}
          appear={!hadRoleSelectedOnInitialLoad}
        >
          <JobsForYouSection />
        </Fade>
      )}

      <Fade
        in={!hasRole}
        timeout={1500}
        appear={false}
        onExited={() => setNewestJobsHidden(true)}
        unmountOnExit
      >
        <NewestJobsSection />
      </Fade>
    </>
  );
};
