import { type MouseEvent, useCallback } from "react";

import { useUser } from "@js/apps/common/hooks";
import {
  replyClicked,
  respondedToPost,
} from "@js/apps/give-and-get-help/actions";
import { useToggleReactionOnPostMutation } from "@js/apps/give-and-get-help/api";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import { openSignUpModal } from "@js/apps/onboarding/components/sign-up-modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type {
  IPost,
  PostComment,
  StickerValue,
} from "@js/types/give-and-get-help";

import { usePostContext } from "../../context";

type UseInteractsSectionProps = {
  entityData: IPost | PostComment;
  restrictTogglingReplies?: boolean;
  onReply?: () => void;
};

export const useInteractsSection = ({
  entityData,
  restrictTogglingReplies,
  onReply,
}: UseInteractsSectionProps) => {
  const { isPublic } = usePostContext();
  const user = useUser();
  const postLocation = usePostLocationContext();
  const [toggleReaction] = useToggleReactionOnPostMutation();
  const dispatch = useAppDispatch();

  const shouldBePublicAction = isPublic && restrictTogglingReplies;

  const isDisabled = !!user?.banned_from_posting || !user?.is_verified;

  const publicOnClick = () => {
    openSignUpModal();
  };

  const handleReactionSelect = useCallback(
    async (sticker: StickerValue): Promise<void> => {
      await toggleReaction({
        postId: entityData.id,
        mainPostId: entityData.main_post_id || entityData.id,
        data: { sticker },
      })
        .unwrap()
        .then(() => {
          dispatch(
            respondedToPost({ post: entityData, post_location: postLocation }),
          );
        })
        .catch((error) => {
          if (error.status === 404) {
            Snackbar.error(error.data);
          }
        });
    },
    [toggleReaction, entityData, dispatch, postLocation],
  );

  const handleReplyButtonClick = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    dispatch(replyClicked({ ...entityData, post_location: postLocation }));
    onReply?.();
  };

  return {
    user,
    isDisabled,
    handleReactionSelect: isPublic ? publicOnClick : handleReactionSelect,
    handleReplyButtonClick: shouldBePublicAction
      ? publicOnClick
      : handleReplyButtonClick,
  };
};
