import {
  Box,
  EmployerLogo,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ConditionalRouterLink } from "@js/components/link";
import type { JobEmployer } from "@js/types/jobs";

import { getBackgroundColor } from "../../utils";

import styles from "./styles.module.scss";

type JobItemEmployerDataPropsTypes = {
  employer: JobEmployer;
  openInNewTab?: boolean;
  isSmallJobItemCard?: boolean;
  noLink?: boolean;
};

export const JobItemEmployerData = ({
  employer,
  openInNewTab,
  isSmallJobItemCard,
  noLink,
}: JobItemEmployerDataPropsTypes) => {
  const {
    logo_background_color,
    name_color,
    logo_thumbnail,
    has_logo_set,
    name,
    id,
  } = employer;

  const { backgroundColor, logoBackgroundColor } = getBackgroundColor(
    logo_background_color,
    logo_thumbnail,
  );
  return (
    <Box className={styles.wrapper}>
      <Stack
        justifyContent="center"
        width="100%"
        height="100%"
        borderRadius={2}
        bgcolor={backgroundColor}
      >
        <ConditionalRouterLink
          noLink={!!noLink}
          to={`/employers/${id}/`}
          style={{ textDecoration: "none" }}
          target={openInNewTab ? "_blank" : undefined}
        >
          <Box className={styles.content}>
            <Typography
              component="span"
              variant="paragraph"
              title={name}
              fontWeight={500}
              mb={isSmallJobItemCard ? 1 : 2}
              mt={1}
              textAlign="center"
              sx={{
                overflowWrap: "anywhere",
                width: "calc(100% - 32px)",
                lineHeight: "1.2 !important",
                color: `var(${name_color || "--dark-violet"})`,
              }}
              size="small"
              multilineEllipsis={2}
            >
              {name}
            </Typography>
            <Box
              width={63}
              height={63}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <EmployerLogo
                bgColor={logoBackgroundColor}
                className={!has_logo_set ? styles.fallback : ""}
                src={{
                  name,
                  logo_thumbnail: has_logo_set ? logo_thumbnail : undefined,
                }}
                fallbackTextColor="dark-violet"
                fallbackTextSize="small"
              />
            </Box>
          </Box>
        </ConditionalRouterLink>
      </Stack>
    </Box>
  );
};
