import React from "react";

import { TokenFeatureManagementFeatureFlagWrapper } from "@js/apps/jobs/components/paid-management-feature-flag-wrapper";

import { SettingsLayout } from "../layout";

import BillingSection from "./billing/billing";
import UpgradeSection from "./upgrades/upgrades";

const PurchasePage = (): JSX.Element => {
  return (
    <SettingsLayout pageTitle="Payments">
      <TokenFeatureManagementFeatureFlagWrapper>
        <FreelancerContent />
      </TokenFeatureManagementFeatureFlagWrapper>
    </SettingsLayout>
  );
};

const FreelancerContent = (): JSX.Element => {
  return (
    <>
      <UpgradeSection />
      <BillingSection />
    </>
  );
};

export default PurchasePage;
