import React from "react";

import { Button, Stack } from "@hexocean/braintrust-ui-components";

import { closeSaveFiltersModal } from "./save-filters-modal";

export const SaveFiltersModalButtons = () => {
  return (
    <Stack direction="row" sx={{ gap: 1, justifyContent: "end", mt: 4 }}>
      <Button variant="secondary" size="small" onClick={closeSaveFiltersModal}>
        Cancel
      </Button>
      <Button variant="primary" size="small" type="submit">
        Save
      </Button>
    </Stack>
  );
};
