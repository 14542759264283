import React from "react";
import { Field, reduxForm } from "redux-form";

import { Box, Button } from "@hexocean/braintrust-ui-components";
import { Form } from "@js/forms/components/form";
import { PasswordField } from "@js/forms/fields/password";

export const ResetPasswordForm = reduxForm<{
  new_password1: string;
}>({
  form: "resetPassword",
})(({ submit, error, submitting, pristine }) => {
  return (
    <Box
      component={Form}
      justifyContent="center"
      onSubmit={submitting ? null : submit}
      error={error}
    >
      <Box>
        <Field
          id="new_password1"
          variant="outlined"
          color="secondary"
          name="new_password1"
          component={PasswordField}
          helperText="Password must be at least 10 characters."
          label="Create password"
        />
      </Box>
      <Box mt={3}>
        <Button
          fullWidth
          variant="primary"
          disabled={pristine || submitting}
          type="submit"
        >
          Save and return to login
        </Button>
      </Box>
    </Box>
  );
});
