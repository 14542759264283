import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import {
  FRONTEND_STORAGE_KEYS,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { useUser } from "@js/apps/common/hooks";

import { openEmployerWelcomeModal } from "../../components/employer-welcome-modal";

export const WELCOME_MODAL_PARAM = "welcome_modal";

export const useEmployerWelcomeModal = () => {
  const user = useUser();
  const { isWelcomeModalParam, deleteParam } = useWelcomeModalParam();

  const [setStorageValue] = useSetStorageValueMutation();

  const dismissWelcomeModal = useCallback(() => {
    setStorageValue({
      key: FRONTEND_STORAGE_KEYS.is_employer_dashboard_modal_introduced,
      value: true,
    });
  }, [setStorageValue]);

  const openModal = () => {
    openEmployerWelcomeModal({
      onModalView: () => {
        dismissWelcomeModal();
        deleteParam();
      },
      firstName: user?.first_name || "",
    });
  };

  return {
    canOpenAnnouncement: isWelcomeModalParam,
    openAnnouncement: openModal,
  };
};

export const useWelcomeModalParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isWelcomeModalParam = !!searchParams.get(WELCOME_MODAL_PARAM);

  const deleteParam = useCallback(() => {
    setSearchParams(
      (prevState) => {
        prevState.delete(WELCOME_MODAL_PARAM);
        return prevState;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  return { isWelcomeModalParam, deleteParam };
};

export const useSetWelcomeModalParam = () => {
  const [, setSearchParams] = useSearchParams();

  const setSearchParam = () => {
    setSearchParams(
      (prev) => {
        prev.set(WELCOME_MODAL_PARAM, "true");
        return prev;
      },
      { state: { disableRemountIfTheSameLocation: true } },
    );
  };

  return setSearchParam;
};
