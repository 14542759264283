import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useJobFormContext } from "@js/apps/jobs/context/job-form-context/job-form-context";
import { useCanManageJobsOnBehalfOfClient } from "@js/apps/jobs/hooks";

import { PostJobForClientModalInstance } from "./modal";

export const useOpenPostJobForAClientModal = () => {
  const { id } = useParams();
  const { jobDraft } = useJobFormContext();
  const canManageJobsOnBehalfOfClient = useCanManageJobsOnBehalfOfClient();

  const jobDraftFillSupportType = jobDraft?.job_fill_support_type;
  const jobDraftHasNoFillSupportType = !!jobDraft && !jobDraftFillSupportType;
  const shouldOpenPostJobForAClientModal =
    canManageJobsOnBehalfOfClient && (!id || jobDraftHasNoFillSupportType);

  useEffect(() => {
    if (shouldOpenPostJobForAClientModal) {
      PostJobForClientModalInstance.open();
    }
  }, [shouldOpenPostJobForAClientModal]);
};
