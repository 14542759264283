import type { OfferStatusUpdatedEventData } from "@js/apps/dashboard/types";
import type { OfferReceivedEventData } from "@js/types/give-and-get-help";
import { assertUnreachable } from "@js/utils";

export const helpOffersUpdateRecipe = (invalidateCache: () => void) => {
  return (event: MessageEvent) => {
    if (!event.data) return;
    if (!isHelpOfferRelatedEvent(event)) return;
    const eventData = event.data;
    const { message_type } = eventData.message;

    switch (message_type) {
      case ENUMS.HelpOfferMessageType.HELP_OFFER_ACCEPTED:
      case ENUMS.HelpOfferMessageType.HELP_OFFER_MARKED_COMPLETE:
      case ENUMS.HelpOfferMessageType.HELP_OFFER_BUDGET_CHANGED:
      case ENUMS.HelpOfferMessageType.HELP_OFFER_COMPLETED:
      case ENUMS.HelpOfferMessageType.HELP_OFFER_CREATED:
      case ENUMS.HelpOfferMessageType.HELP_OFFER_DECLINED:
      case ENUMS.HelpOfferMessageType.HELP_OFFER_DELETED:
      case ENUMS.HelpOfferMessageType.HELP_OFFER_EXPIRED:
      case ENUMS.HelpOfferMessageType.HELP_OFFER_REFUND_REQUESTED:
      case ENUMS.HelpOfferMessageType.HELP_OFFER_REFUND_ISSUED:
      case ENUMS.HelpOfferMessageType.HELP_OFFER_REFUND_REQUEST_CANCELED:
      case ENUMS.HelpOfferMessageType.HELP_OFFER_REPORTED:
      case ENUMS.HelpOfferMessageType.HELP_OFFER_REVISION_REQUESTED:
      case ENUMS.HelpOfferMessageType.HELP_OFFER_REVIEW_SENT:
        invalidateCache();
        break;
      default:
        assertUnreachable(message_type);
    }
  };
};

export const isHelpOfferRelatedEvent = (
  event: MessageEvent,
): event is MessageEvent<OfferReceivedEventData> => {
  return (
    event.data.broadcast_type === ENUMS.BroadcastType.MESSAGE &&
    event.data.message.message_type !== ENUMS.MessageType.USER_MESSAGE &&
    event.data.message.message_type !== ENUMS.MessageType.USER_REPORTED &&
    Object.values(ENUMS.HelpOfferMessageType).includes(
      event.data.message.message_type,
    )
  );
};

export const isHelpOfferStatusChangedRelatedEvent = (
  event: MessageEvent,
): event is MessageEvent<OfferStatusUpdatedEventData> => {
  return event.data.broadcast_type === ENUMS.BroadcastType.HELP_OFFER_UPDATED;
};
