import { useMemo } from "react";

import { MessageIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { TeamMemberInvitationsAutocompleteProps } from "@js/apps/employer/components/team-member-invitations-autocomplete";
import {
  TeamMemberInvitationsActionButton,
  TeamMemberInvitationsAutocomplete,
} from "@js/apps/employer/components/team-member-invitations-autocomplete";
import type { JobSubscriberInvitationData } from "@js/types/jobs";

type SubscribeTeamMembersAutocompleteProps = {
  jobSubscriberInvitationsValue: JobSubscriberInvitationData[];
} & Pick<
  TeamMemberInvitationsAutocompleteProps,
  "toggleInvitation" | "errorMessage"
>;

export const SubscribeTeamMembersAutocomplete = ({
  jobSubscriberInvitationsValue,
  ...rest
}: SubscribeTeamMembersAutocompleteProps) => {
  const selectedInvitations = useMemo(() => {
    return jobSubscriberInvitationsValue.map(
      (jobSubscriberInvitation) => jobSubscriberInvitation.email,
    );
  }, [jobSubscriberInvitationsValue]);

  return (
    <TeamMemberInvitationsAutocomplete
      {...rest}
      selectedInvitations={selectedInvitations}
      invitationActionButton={<SubscribeTeamMembersInviteBtn />}
    />
  );
};

const SubscribeTeamMembersInviteBtn = () => {
  return (
    <TeamMemberInvitationsActionButton label="Invite" Icon={MessageIcon} />
  );
};
