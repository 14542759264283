import { useCallback } from "react";

import {
  useAddOfferNoteMutation,
  useDeleteOfferNoteMutation,
  useGetOfferNotesQuery,
} from "@js/apps/admin/api";
import type { AddNoteArgs, DeleteNoteArgs } from "@js/apps/admin/types";

import { useAddOrDeleteNotes } from "./add-delete-notes-handlers";
import { useScrollToHighlightedAnchor } from "./scroll-to-highlighted-notes";
import type { useOfferNotesTypes } from "./types";

export const useOfferNotes: useOfferNotesTypes = (id) => {
  const highlightedNote = useScrollToHighlightedAnchor();
  const { data: offerNotes, isLoading: isLoadingOfferNotes } =
    useGetOfferNotesQuery({ target_id: id });
  const [addOfferNoteHandler] = useAddOfferNoteMutation();
  const [deleteOfferNoteHandler] = useDeleteOfferNoteMutation();

  const addOfferNote = async (args: AddNoteArgs) => {
    return addOfferNoteHandler(args).unwrap();
  };

  const deleteOfferNote = async (args: DeleteNoteArgs) => {
    return deleteOfferNoteHandler(args).unwrap();
  };

  const { openDeleteNoteModal, onSubmit, onSubmitSuccess } =
    useAddOrDeleteNotes(addOfferNote, deleteOfferNote);

  const getOfferNoteUrl = useCallback(
    (offerId: number, noteId: number) =>
      `/admin/offers_report/?offer=${offerId}&note=${noteId}`,
    [],
  );

  return {
    fetchingNotes: isLoadingOfferNotes,
    notes: offerNotes ?? [],
    onDeleteNote: openDeleteNoteModal,
    highlightedNote,
    onSubmit,
    onSubmitSuccess,
    getNoteUrl: getOfferNoteUrl,
  };
};
