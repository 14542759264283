import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Button } from "@hexocean/braintrust-ui-components";
import { ArrowTopRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

type ViewProfileButtonProps = {
  profileLink: string;
  onClick?: () => void;
} & Pick<ButtonProps, "size">;

export const ViewProfileButton = ({
  profileLink,
  onClick,
  ...buttonProps
}: ViewProfileButtonProps) => {
  return (
    <Button
      shape="squared"
      variant="white-border-beige"
      to={profileLink}
      rel="noopener noreferrer"
      target="_blank"
      RouterLink={RouterLink}
      onClick={onClick}
      sx={{ whiteSpace: "nowrap" }}
      {...buttonProps}
    >
      View profile{" "}
      <ArrowTopRightIcon
        style={{
          fontSize: "1.5em",
          marginLeft: 8,
        }}
      />
    </Button>
  );
};
