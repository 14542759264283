import _ from "underscore";

import { Box } from "@hexocean/braintrust-ui-components";
import { useGetNotificationsSettingsQuery } from "@js/apps/settings/api";
import { AllowNotificationsSwitch } from "@js/apps/settings/components/allow-notifications-switch";
import { NotificationsSection } from "@js/apps/settings/components/notifications-section";

const TalentNotificationSettings = () => {
  const { data, isLoading } = useGetNotificationsSettingsQuery();

  if (!data || isLoading) {
    return null;
  }
  const notificationsByGroup = _.groupBy(
    data.general_notifications_settings,
    (item) => item.group,
  );

  const sortOrder = {
    [ENUMS.NotificationGroup.JOBS]: 1,
    [ENUMS.NotificationGroup.INVOICES]: 2,
    [ENUMS.NotificationGroup.REFERRALS]: 3,
    [ENUMS.NotificationGroup.CAREER_HELP]: 4,
    [ENUMS.NotificationGroup.MESSAGING]: 5,
    [ENUMS.NotificationGroup.NEWSLETTER]: 6,
    default: 6,
  };

  const sortedNotifications = Object.keys(notificationsByGroup).sort(
    (groupA, groupB) => {
      const aKey = groupA as keyof typeof sortOrder;
      const bKey = groupB as keyof typeof sortOrder;
      return (
        (sortOrder[aKey] || sortOrder["default"]) -
        (sortOrder[bKey] || sortOrder["default"])
      );
    },
  );

  return (
    <Box>
      {sortedNotifications.map((group) => {
        const notifications = notificationsByGroup[group];

        if (group === ENUMS.NotificationGroup.NO_GROUP) {
          return (
            <AllowNotificationsSwitch
              key={group}
              notification={notifications[0]}
            />
          );
        }

        return (
          <NotificationsSection
            key={group}
            title={formatTitle(group)}
            notifications={notifications}
          />
        );
      })}
    </Box>
  );
};

const formatTitle = (title: string) => title.split("_").join(" ");

export { TalentNotificationSettings };
