import {
  IconButton,
  Menu,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  FlagIcon,
  MessageIcon,
  MoreVertIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useReport } from "@js/apps/common/hooks/use-report";
import type { Participant } from "@js/types/messenger";

import { openCancelReportModal } from "../cancel-report-modal/cancel-report-modal";

type ActionMenuProps = {
  user: Participant;
  isReportedByUser: boolean;
};

export const ActionsMenu = ({ user, isReportedByUser }: ActionMenuProps) => {
  const { handleReportUser } = useReport();

  const handleCancelReport = () => openCancelReportModal(user);

  const report = handleReportUser({
    id: user.id,
    type: "user",
    name: user.public_name,
  });

  const shouldShowContactSupport = isReportedByUser;

  return (
    <Menu
      anchor={
        <IconButton
          aria-label="Open message actions menu"
          aria-haspopup="true"
          variant="transparent"
          size="medium"
          sx={{
            alignSelf: "flex-start",
          }}
        >
          <MoreVertIcon style={{ color: "inherit" }} />
        </IconButton>
      }
    >
      <Menu.Item onClick={report}>
        <FlagIcon sx={{ color: "var(--negative-1)" }} />
        <Typography component="span" color="negative" ml={1}>
          Report
        </Typography>
      </Menu.Item>

      {isReportedByUser && (
        <Menu.Item onClick={handleCancelReport}>
          <FlagIcon sx={{ color: "var(--negative-1)" }} />
          <Typography component="span" color="negative" ml={1}>
            Cancel report
          </Typography>
        </Menu.Item>
      )}

      {shouldShowContactSupport && (
        <Menu.Item
          component="a"
          target="_blank"
          href={`mailto:${SETTINGS.SUPPORT_EMAIL}`}
          rel="noreferrer"
          className="no-decoration"
        >
          <MessageIcon />
          <Typography component="span" color="grey-1" ml={1}>
            Contact support
          </Typography>
        </Menu.Item>
      )}
    </Menu>
  );
};
