import type { HelpOfferCompletedSystemMessage } from "@js/types/messenger";

export const getHelpOfferCompletedSystemMessageCardTitle = (
  helpOffer: HelpOfferCompletedSystemMessage["system_message_target"],
  isAskerView: boolean,
) => {
  if (helpOffer.refund_issued) {
    return "Offer is complete";
  }

  if (isAskerView) {
    return helpOffer.completed_by
      ? "You marked the offer as complete"
      : `We automatically marked the offer as complete after ${SETTINGS.NUMBER_OF_DAYS_FOR_AN_OFFER_TO_COMPLETE} days`;
  }
  return `${helpOffer.receiver.user.first_name} marked the offer as complete`;
};
