import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Fields, submit } from "redux-form";

import { Box, Button } from "@hexocean/braintrust-ui-components";
import { SaveIcon } from "@hexocean/braintrust-ui-components/Icons";
import { setLayoutBackgroundColor } from "@js/apps/common/actions";
import {
  AddTeamMemberDrawerTrigger,
  CreateJobHeaderPortal,
} from "@js/apps/jobs/apps/create-job/components";
import { EditJobNextStepButton } from "@js/apps/jobs/apps/create-job/components/buttons/edit-job-next-step-button";
import { CreateOrEditJobMainFields } from "@js/apps/jobs/apps/create-job/forms/main-step";
import { CreateOrEditJobSetUpFields } from "@js/apps/jobs/apps/create-job/forms/set-up-step";
import { useAppDispatch, useQueryParams } from "@js/hooks";

import { UploadJobDescription } from "../../components/upload-job-description";

export const EditJobMainPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const query = useQueryParams();

  useEffect(() => {
    dispatch(setLayoutBackgroundColor("var(--off-white)"));
    return () => {
      dispatch(setLayoutBackgroundColor(undefined));
    };
  }, [dispatch]);

  const SubmitButton = (
    <Button
      onClick={() => dispatch(submit("edit-job-form"))}
      variant="positive"
      shape="squared"
      type="submit"
      startIcon={<SaveIcon />}
    >
      Update job
    </Button>
  );

  return (
    <Box width={1}>
      <CreateJobHeaderPortal
        isEdit
        displaySettings
        backLink={`/jobs/${id}/${query.back ?? ""}`}
        rightSocket={
          <Box display="flex" gap={1} flexWrap="wrap">
            <Fields
              names={[
                "job_subscribers",
                "job_subscriber_invitations",
                "jobOwnerId",
              ]}
              component={AddTeamMemberDrawerTrigger}
            />
            {SubmitButton}
          </Box>
        }
        leftSocket={<UploadJobDescription />}
      />
      <CreateOrEditJobMainFields isEditForm />

      {SubmitButton}
    </Box>
  );
};

export const EditJobSetUpPage = () => {
  return (
    <Box width={1}>
      <CreateJobHeaderPortal
        isEdit
        backLink="/employer/dashboard/my_jobs/"
        rightSocket={
          <Fields names={["role"]} component={EditJobNextStepButton} />
        }
        leftSocket={<UploadJobDescription />}
      />
      <CreateOrEditJobSetUpFields isEditForm />
    </Box>
  );
};
