import {
  ListItem,
  ListItemButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { TrendingHashtagIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./style.module.scss";

type TrendingHashtagsListItemProps = {
  iconBackgroundColor: string;
  iconColor: string;
  label: string;
  onClick: () => void;
};

export const TrendingHashtagsListItem = ({
  iconBackgroundColor,
  iconColor,
  label,
  onClick,
}: TrendingHashtagsListItemProps) => {
  return (
    <ListItem className={styles.item} onClick={onClick}>
      <ListItemButton className={styles.btn}>
        <TrendingHashtagIcon
          style={{ fill: iconBackgroundColor, stroke: iconColor }}
        />
        <Typography component="h3" variant="label" size="medium">
          {label}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
};
