import { Box, Rating, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  ChatIcon,
  PersonIcon,
  SuitcaseIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { TalentReviewsSummary } from "@js/types/reviews";
import { pluralize } from "@js/utils";

type ReviewsTopBarProps = {
  reviewsSummary?: TalentReviewsSummary;
};

export const ReviewsTopBar = ({ reviewsSummary }: ReviewsTopBarProps) => {
  const isMobile = useMediaQuery("sm");

  if (!reviewsSummary) return null;

  const {
    completed_jobs_count,
    completed_help_offers_count,
    reviews_count,
    average_rating,
  } = reviewsSummary;

  return (
    <Box
      bgcolor="var(--off-white)"
      borderRadius={4}
      gap={isMobile ? 1 : 3}
      paddingY={2}
      paddingX={isMobile ? 2 : 3}
      display="flex"
      flexWrap="wrap"
      alignItems="center"
    >
      <Wrapper>
        <Typography component="h3" variant="title" size="small">
          {average_rating}
        </Typography>
        <Rating
          variant="stars"
          size="small"
          precision={0.5}
          value={Number(average_rating)}
          className="review-module__stars-rating"
          readOnly
        />
      </Wrapper>

      <Wrapper
        icon={<ChatIcon style={{ fontSize: "23px" }} />}
        label={`Reviewed by ${reviews_count} ${
          reviews_count === 1 ? "person" : "people"
        }`}
      />

      {completed_help_offers_count > 0 && (
        <Wrapper
          icon={<PersonIcon style={{ fontSize: "23px" }} />}
          label={`Helped ${completed_help_offers_count} ${
            completed_help_offers_count === 1 ? "person" : "people"
          }`}
        />
      )}

      {completed_jobs_count > 0 && (
        <Wrapper
          icon={<SuitcaseIcon />}
          label={`Completed ${completed_jobs_count} job${pluralize(
            completed_jobs_count,
          )}`}
        />
      )}
    </Box>
  );
};

type WrapperProps = {
  label?: string;
  icon?: JSX.Element;
  children?: React.ReactNode;
};

export const Wrapper = ({ label, icon, children }: WrapperProps) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Box display="flex" alignItems="center" gap={isMobile ? 0.5 : 1}>
      {icon}
      {!!label && (
        <Typography component="p" variant="paragraph" size="small">
          {label}
        </Typography>
      )}
      {children}
    </Box>
  );
};
