import { useEffect } from "react";

import { useEffectRef } from "../use-effect-ref";

export const globalTimeoutsDelays = {
  log_view_event: 500,
  log_exposure_event: 600, // must be bigger than log_view_event delay
} as const;

// credits: https://usehooks-ts.com/react-hook/use-timeout + support of custom deps
export function useTimeout(
  callback: () => void,
  delay: number | null,
  deps?: Array<unknown>,
) {
  const savedCallback = useEffectRef(callback);

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setTimeout(() => savedCallback.current(), delay);

    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...(deps || [])]);
}
