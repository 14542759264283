import { Snackbar } from "@js/components/snackbar";

import { useUnmarkMessageAsSpamMutation } from "../../api";

type useUnmarkMessageAsSpamArgs = {
  messageId: number;
  roomId: number;
};

type useUnmarkMessageAsSpamReturnData = (args: useUnmarkMessageAsSpamArgs) => {
  handleUnMarkAsSpam: () => Promise<void>;
};

export const useUnmarkMessageAsSpam: useUnmarkMessageAsSpamReturnData = ({
  messageId,
  roomId,
}: useUnmarkMessageAsSpamArgs) => {
  const [unmarkMessageAsSpam] = useUnmarkMessageAsSpamMutation();

  const handleUnMarkAsSpam = async () => {
    await unmarkMessageAsSpam({ messageId, room: roomId })
      .unwrap()
      .then(() =>
        Snackbar.success(
          "Thank you for your feedback! This message will be unmarked as spam.",
        ),
      )
      .catch(() => Snackbar.error("Something went wrong"));
  };

  return {
    handleUnMarkAsSpam,
  };
};
