import cs from "classnames";

import type { ChipProps } from "@hexocean/braintrust-ui-components";
import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { EditPenIcon } from "@hexocean/braintrust-ui-components/Icons";
import { SkillList } from "@js/apps/common/components";
import { AddSkills } from "@js/apps/freelancer/forms/skills-edit-module/add-skills";
import { ModalInstance } from "@js/components/modal";
import type { User } from "@js/types/auth";
import type { FreelancerPublic } from "@js/types/freelancer";

import { MODULE_EDIT_MODAL_PROPERTIES } from "../../constants";
import { SkillsEditModule } from "../../forms/skills-edit-module";

type TopSkillsSectionProps = {
  profile: FreelancerPublic;
  user: User | null;
  chipColor?: ChipProps["color"];
};

export const TopSkillsSection = ({
  profile,
  user,
  chipColor,
}: TopSkillsSectionProps) => {
  const { id, freelancer_skills: freelancerSkills } = profile;

  const isOwnProfile = user && id === user.freelancer;
  const hasSomeSkills = !!freelancerSkills.length;
  const prominent = freelancerSkills.filter((skill) => skill.is_superpower);
  const skills = freelancerSkills.filter((skill) => !skill.is_superpower);

  return (
    <Box
      className={cs("skills", {
        "profile-empty-state": !hasSomeSkills && isOwnProfile,
      })}
    >
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          className="flex-left-right"
          component="h3"
          variant="paragraph"
          size="large"
        >
          Skills
        </Typography>
        {isOwnProfile && hasSomeSkills && (
          <IconButton
            variant="black-outlined"
            size="x-small"
            onClick={openSkillsModal}
            aria-label="Edit skills"
          >
            <EditPenIcon />
          </IconButton>
        )}
      </Box>
      {hasSomeSkills ? (
        <SkillList
          prominent={prominent}
          skills={skills}
          chipColor={chipColor}
          prominentChipColor="positive-green"
        />
      ) : (
        <EmptyState
          profile={profile}
          ownEmptyState={!hasSomeSkills && isOwnProfile}
        />
      )}
    </Box>
  );
};

type EmptyStateProps = {
  profile: FreelancerPublic;
  ownEmptyState: boolean | null;
};

const EmptyState = ({ profile, ownEmptyState }: EmptyStateProps) => {
  return (
    <Box mt={1.5}>
      <Typography
        component="p"
        fontStyle="italic"
        mt={-1}
        mb={2}
        multipleEllipsis
      >
        {!ownEmptyState &&
          `${profile.user.first_name} is skilled at many things, they’re just being humble at the moment.`}
      </Typography>
      {ownEmptyState && <AddSkills onClick={openSkillsModal} />}
    </Box>
  );
};

export const openSkillsModal = () =>
  ModalInstance.open({
    children: (
      <SkillsEditModule
        onSubmitSuccess={() => {
          ModalInstance.close();
        }}
      />
    ),
    ...MODULE_EDIT_MODAL_PROPERTIES,
  });
