import { useState } from "react";

export const useShareWithTeamMembers = ({
  clearErrorMessage,
}: {
  clearErrorMessage: () => void;
}) => {
  const [
    shareWithTeamMemberEmailInvitations,
    setShareWithTeamMemberEmailInvitations,
  ] = useState<string[]>([]);
  const [shareWithTeamMemberIds, setShareWithTeamMemberIds] = useState<
    number[]
  >([]);
  const [message, setMessage] = useState("");

  const toggleShareWithTeamMemberInvitation = (invitationEmail: string) => {
    clearErrorMessage();
    setShareWithTeamMemberEmailInvitations((previousInvitations) => {
      const invitationIndex = previousInvitations.findIndex(
        (previousInvitation) => {
          return previousInvitation === invitationEmail;
        },
      );

      if (invitationIndex === -1) {
        return [...previousInvitations, invitationEmail];
      }

      const previousInvitationsCopy = [...previousInvitations];
      previousInvitationsCopy.splice(invitationIndex, 1);

      return previousInvitationsCopy;
    });
  };

  const toggleShareWithTeamMember = (teamMemberId: number) => {
    clearErrorMessage();
    setShareWithTeamMemberIds((previousTeamMemberIds) => {
      const teamMemberIdIndex = previousTeamMemberIds.findIndex(
        (previousTeamMemberId) => {
          return previousTeamMemberId === teamMemberId;
        },
      );

      if (teamMemberIdIndex === -1) {
        return [...previousTeamMemberIds, teamMemberId];
      }

      const previousSubscribersCopy = [...previousTeamMemberIds];
      previousSubscribersCopy.splice(teamMemberIdIndex, 1);

      return previousSubscribersCopy;
    });
  };

  return {
    message,
    setMessage,
    shareWithTeamMemberEmailInvitations,
    shareWithTeamMemberIds,
    toggleShareWithTeamMemberInvitation,
    toggleShareWithTeamMember,
  };
};
