import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { EditPenIcon } from "@hexocean/braintrust-ui-components/Icons";

import type { UseHelpServiceTipArg } from "./use-help-service-tip";
import { useHelpServiceTip } from "./use-help-service-tip";

export type AddHelpServiceTipProps = UseHelpServiceTipArg;

export const AddHelpServiceTip = ({
  postCategory,
  ...rest
}: AddHelpServiceTipProps) => {
  const { showTip, openServices } = useHelpServiceTip({
    ...rest,
    postCategory,
  });
  if (!showTip) {
    return <></>;
  }

  return (
    <Box onClick={(ev) => ev.stopPropagation()}>
      <Box
        component={"span"}
        sx={{
          lineHeight: 1,
        }}
      >
        <EditPenIcon
          sx={{
            fontSize: "16px",
            verticalAlign: "text-bottom",
          }}
        />
      </Box>
      <Typography
        component="p"
        variant="paragraph"
        size="small"
        paddingLeft="4px"
        whiteSpace="break-spaces"
        sx={{
          display: "inline",
        }}
      >
        Offer help with {postCategory.name}{" "}
        <Typography
          component="a"
          variant="link"
          size="small"
          sx={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={openServices}
        >
          on your profile
        </Typography>
      </Typography>
    </Box>
  );
};
