import { useEffect, useState } from "react";

import { getHelpOfferLocalStorageKey, LocalStorage } from "@js/services";
import type { HelpOffer } from "@js/types/give-and-get-help";

export const useHideHelpOffersExtraMsg = (helpOffers?: HelpOffer[]) => {
  const [offersWithExtraMsgNotShown, setOffersWithExtraMsgNotShown] = useState<
    number[]
  >([]);

  useEffect(() => {
    helpOffers?.forEach((offer) => {
      const helpOfferKey = getHelpOfferLocalStorageKey(offer);
      // getting saved ids for the status of this offer (e.g., reported, new etc.)
      const savedOfferIds = JSON.parse(
        LocalStorage.getItem(helpOfferKey) || "[]",
      );
      const isOfferAlreadySaved = Boolean(
        savedOfferIds.find((id: number) => id === offer.id),
      );

      // if this offer's id is not saved in the LocalStorage for its current status, the extra msg has not been shown yet
      if (!isOfferAlreadySaved) {
        const updatedSavedOfferIds = JSON.stringify([
          ...savedOfferIds,
          offer.id,
        ]);
        LocalStorage.setItem(helpOfferKey, updatedSavedOfferIds);
        setOffersWithExtraMsgNotShown((previousOffers) => [
          ...previousOffers,
          offer.id,
        ]);
      }
    });
  }, [helpOffers]);

  return { offersWithExtraMsgNotShown };
};
