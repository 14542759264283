import type { AnyAction } from "redux";

import { USER_BANNED } from "./actions";

type BanListState = {
  banList: Record<number, boolean>;
};

const INITIAL_STATE: BanListState = { banList: {} };

export default function (state = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case USER_BANNED:
      return {
        ...state,
        banList: { ...state.banList, [action.payload]: true },
      };
    default:
      return state;
  }
}
