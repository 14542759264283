import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { MODULE_EDIT_MODAL_PROPERTIES } from "@js/apps/freelancer/constants";
import { CertificatesForm } from "@js/apps/freelancer/forms/certificates-form";
import { useCertificates } from "@js/apps/freelancer/hooks/edit-certificates";
import { ModalInstance } from "@js/components/modal";
import { SortableList } from "@js/components/sortable-list/motion";
import { useAppDispatch } from "@js/hooks";
import type { FreelancerCertificate } from "@js/types/freelancer";

import { CertificatesCard } from "../card";

import {
  openDeleteCertificatesModal,
  openEditCertificatesModal,
} from "./edit-and-remove-certificates";

export const openCreateCertificateModal = () =>
  ModalInstance.open({
    children: <ModalContent />,
    ...MODULE_EDIT_MODAL_PROPERTIES,
  });

export const ModalContent = () => {
  const certificationsManager = useCertificates();
  const dispatch = useAppDispatch();

  return (
    <ModalContentComponent {...certificationsManager} dispatch={dispatch} />
  );
};

export type ModalContentComponentProps = ReturnType<typeof useCertificates> & {
  dispatch: ReturnType<typeof useAppDispatch>;
};

export const ModalContentComponent = ({
  getInitialValues,
  onSubmit,
  onDeleteCertificate,
  onSubmitSuccess,
  certificateList,
  setSubmitType,
  onUpdateCertificateSuccess,
  onReorderCertificates,
}: ModalContentComponentProps) => {
  return (
    <Box>
      <Box display="flex" gap={1} alignItems="center">
        <Typography
          component="h3"
          variant="title"
          fontWeight={400}
          size="small"
        >
          Certificates
        </Typography>
      </Box>
      {certificateList.length ? (
        <>
          <SortableList<FreelancerCertificate>
            items={certificateList}
            onReorder={async (itemPositions) => {
              await onReorderCertificates(itemPositions);
            }}
            style={{
              display: "grid",
              gap: "24px",
              marginTop: "32px",
            }}
          >
            {(certificate) => {
              return (
                <Box borderBottom="1px solid var(--grey-4)">
                  <CertificatesCard
                    key={certificate.id}
                    certificate={certificate}
                    editMode
                    onEdit={() =>
                      openEditCertificatesModal({
                        initialValues: getInitialValues(certificate.id),
                        onSubmit,
                        onSubmitSuccess: onUpdateCertificateSuccess,
                        setSubmitType,
                      })
                    }
                    onDelete={() =>
                      openDeleteCertificatesModal({
                        certificate,
                        onDelete: onDeleteCertificate,
                      })
                    }
                  />
                </Box>
              );
            }}
          </SortableList>
          <Box mt={3}>
            <CertificatesForm
              onSubmit={onSubmit}
              onSubmitSuccess={onSubmitSuccess}
              setSubmitType={setSubmitType}
            />
          </Box>
        </>
      ) : (
        <Box mt={2}>
          <CertificatesForm
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
            setSubmitType={setSubmitType}
            emptyState={true}
          />
        </Box>
      )}
    </Box>
  );
};
