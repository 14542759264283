import type { RefObject } from "react";
import { useCallback } from "react";

export const useScrollToEnd = <T extends HTMLElement>(
  elementRef: RefObject<T>,
) => {
  const scrollToEnd = useCallback(() => {
    const element = elementRef.current;

    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [elementRef]);

  return { scrollToEnd };
};
