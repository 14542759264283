import { Box, Button } from "@hexocean/braintrust-ui-components";
import { CommonConfirmationModal } from "@js/components/modal";

export const openRedirectionWarningModal = (
  href: string,
  report: () => void,
) => {
  const handleReportClick = () => {
    CommonConfirmationModal.close();
    report();
  };
  const handleConfirmClick = () => {
    CommonConfirmationModal.close();
    window.open(href, "_blank", "noopener,noreferrer");
  };

  CommonConfirmationModal.open({
    keepOnBackdropClick: true,
    className: "max-width-500",
    children: (
      <RedirectionWarningModalContent
        onCancelClick={CommonConfirmationModal.close}
        onReportClick={handleReportClick}
        onConfirmClick={handleConfirmClick}
      />
    ),
  });
};

type RedirectionWarningModalContentProps = {
  onCancelClick: () => void;
  onReportClick: () => void;
  onConfirmClick: () => void;
};

const RedirectionWarningModalContent = ({
  onCancelClick,
  onReportClick,
  onConfirmClick,
}: RedirectionWarningModalContentProps) => {
  return (
    <Box>
      <div>
        Interact with employers directly on this platform only. Wait for a
        formal job offer before proceeding with any requests. Encounter anything
        unusual? Report it straightaway to{" "}
      </div>
      <a
        href={`mailto:${SETTINGS.SUPPORT_EMAIL}`}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "underline" }}
      >
        {SETTINGS.SUPPORT_EMAIL}
      </a>
      .
      <Box display="flex" mt={4} justifyContent="space-between">
        <Button variant="secondary" shape="squared" onClick={onCancelClick}>
          Cancel
        </Button>
        <Box>
          <Button
            variant="destructive"
            shape="squared"
            onClick={onReportClick}
            sx={{ marginRight: "8px" }}
          >
            Report
          </Button>
          <Button variant="primary" shape="squared" onClick={onConfirmClick}>
            Open link
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
