import { Box } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { NotLoggedInApplyButton } from "@js/apps/jobs/components//not-logged-in-apply-button";
import { ViewJobButton } from "@js/apps/jobs/components//view-job-button";
import type { MainJobListingJob } from "@js/types/jobs";

import styles from "./styles.module.scss";

type JobItemButtonsProps = {
  isTablet: boolean;
  isMobile: boolean;
  job: MainJobListingJob;
};

export const JobItemButtonsEmbedded = ({
  isMobile,
  job,
}: JobItemButtonsProps) => {
  const user = useUser();
  return (
    <Box className={styles.wrapper}>
      {!user && (
        <NotLoggedInApplyButton
          jobId={job.id}
          variant="secondary"
          fullWidth={isMobile}
          sx={{ height: "40px" }}
          openInNewTab
        />
      )}
      <ViewJobButton
        job={job}
        variant="primary"
        color="primary"
        fullWidth={isMobile}
        sx={{
          display: { xs: "none", sm: "flex" },
          flexShrink: 0,
          height: 40,
        }}
      />
    </Box>
  );
};
