import { useCallback, useState } from "react";

import { openWriteReviewSuccessModal } from "@js/apps/reviews/components/review-modal/write-review-success-modal-instance";
import { getShouldShowWriteReviewFormAfterOpenReviewsModal } from "@js/apps/reviews/slice";
import { useAppSelector } from "@js/hooks";

type UseReviewModalProps = {
  closeReviewModal: () => void;
};
export const useReviewModal = ({ closeReviewModal }: UseReviewModalProps) => {
  const [showWriteReviewForm, setShowWriteReviewForm] = useState(false);
  const showWriteReviewFormAfterOpenReviewsModal = useAppSelector(
    getShouldShowWriteReviewFormAfterOpenReviewsModal,
  );

  const onSuccess = useCallback(() => {
    openWriteReviewSuccessModal();
    closeReviewModal();
  }, [closeReviewModal]);

  return {
    onSuccess,
    showWriteReviewFormAfterOpenReviewsModal,
    showWriteReviewForm,
    setShowWriteReviewForm,
  };
};
