import { useEffect } from "react";

import { hideAllChangesSavedMarkers } from "@js/apps/jobs/apps/create-job/actions";
import { useIsSavingJobDraft } from "@js/apps/jobs/apps/create-job/hooks/use-is-saving-job-draft";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import type { SectionsWithChangesSavedType } from "./constant";
import { shouldDisplayMarker } from "./helpers";

export const useShouldDisplayChangesMarker = (
  sections: SectionsWithChangesSavedType,
) => {
  const { isSavingJobDraft } = useIsSavingJobDraft();

  const savedSections = useAppSelector((state) => state.jobs.savedSections);

  return {
    shouldDisplayLoader: isSavingJobDraft,
    shouldDisplayMarker: shouldDisplayMarker(sections, savedSections),
  };
};

export const useHandleMarkerAutoHide = (autoHide: boolean) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (autoHide) {
      // AutoHide after 10s for components that won't get unmounted
      setTimeout(() => {
        dispatch(hideAllChangesSavedMarkers());
      }, 10000);
    }
    return () => {
      dispatch(hideAllChangesSavedMarkers());
    };
  }, [dispatch, autoHide]);
};
