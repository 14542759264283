import type { ChangeEvent } from "react";
import React from "react";
import type { WrappedFieldProps } from "redux-form";
import { touch } from "redux-form";

import type { SwitchProps } from "@hexocean/braintrust-ui-components";
import { Switch } from "@hexocean/braintrust-ui-components";

import type { SwitchButtonProps } from "./swith-button";
import { SwitchButton } from "./swith-button";

type SwitchFieldProps = SwitchProps &
  WrappedFieldProps & {
    customValue?: unknown;
    customUncheckedValue?: unknown;
  };

const ConnectedSwitchFieldFactory =
  <T extends SwitchProps>(Component: React.ElementType) =>
  ({
    input: { name, value, onChange },
    meta: { dispatch, form },
    customValue,
    customUncheckedValue = null,
    ...rest
  }: T & SwitchFieldProps) => {
    const isChecked = customValue ? value === customValue : !!value;

    const handleOnChange = (_: ChangeEvent, checked: boolean) => {
      dispatch(touch(form, name));
      onChange(
        customValue ? (checked ? customValue : customUncheckedValue) : checked,
      );
    };

    return (
      <Component onChange={handleOnChange} checked={isChecked} {...rest} />
    );
  };

export const SwitchField = ConnectedSwitchFieldFactory<SwitchProps>(Switch);
export const SwitchButtonField =
  ConnectedSwitchFieldFactory<SwitchButtonProps>(SwitchButton);
