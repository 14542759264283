import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "underscore";

import {
  useGetInviteRecommendedMembersQuery,
  useLazyVerifyEmailInvitationQuery,
} from "@js/apps/spaces/api";
import { Snackbar } from "@js/components/snackbar";

import type { VerifySuggestedEmailParams } from "../../types";

type ErrorType = { data: Record<string, string> };

export const useSpaceInviteMemberAutocomplete = ({
  spaceId,
}: {
  spaceId: number;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSearch(inputValue);
    }, 500);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  const { data: members = [], isFetching } =
    useGetInviteRecommendedMembersQuery({
      spaceId,
      searchPhrase: debouncedSearch,
    });

  const [
    verifyInvitationEmail,
    { data: isEmailVerifiedData, isLoading: isEmailVerifying },
  ] = useLazyVerifyEmailInvitationQuery();

  const handleVerifyInvitationEmail = useCallback(
    (data: VerifySuggestedEmailParams) => {
      verifyInvitationEmail(data, true)
        .unwrap()
        .then(() => setEmailInvalid(false))
        .catch((error: ErrorType) => {
          setEmailInvalid(true);
          Snackbar.error(error.data);
        });
    },
    [verifyInvitationEmail],
  );

  const isOpenAndNotEmpty = isOpen && (!!members.length || !!debouncedSearch);

  const debouncedCheckIsEmailAlreadyInvited = useMemo(
    () =>
      _.debounce((data: VerifySuggestedEmailParams) => {
        return handleVerifyInvitationEmail(data);
      }, 150),
    [handleVerifyInvitationEmail],
  );

  return {
    members,
    isLoading: isFetching,
    inputValue,
    setInputValue,
    isOpen: isOpenAndNotEmpty,
    emailInvalid,
    setIsOpen,
    checkIsEmailAlreadyInvited: debouncedCheckIsEmailAlreadyInvited,
    isEmailAlreadySent: isEmailVerifying
      ? false
      : isEmailVerifiedData?.already_invited,
  };
};
