import React from "react";

import { Switch, Tooltip } from "@hexocean/braintrust-ui-components";
import { CheckIcon, CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { openDisableOTPAuthModal } from "@js/apps/settings/components/otp-auth/disable-otp-modal";

import { openEnableOTPAuthModal } from "../enable-otp-modal";

type ToggleOTPSwitchProps = {
  isOTPEnabled: boolean;
  canDisableOTP: boolean;
};

export const ToggleOTPSwitch = ({
  isOTPEnabled,
  canDisableOTP,
  ...props
}: ToggleOTPSwitchProps) => {
  const disabled = isOTPEnabled && !canDisableOTP;

  return (
    <Tooltip
      title="Can't disable 2FA because of permissions level of this account."
      disabled={!disabled}
    >
      <span>
        <Switch
          checked={isOTPEnabled}
          icon={<CloseIcon />}
          checkedIcon={<CheckIcon />}
          disabled={disabled}
          onClick={
            isOTPEnabled
              ? openDisableOTPAuthModal
              : () => openEnableOTPAuthModal()
          }
          label={isOTPEnabled ? "Enabled" : "Disabled"}
          {...props}
        />
      </span>
    </Tooltip>
  );
};
