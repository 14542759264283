import { POST_JOB_FOR_CLIENT_FIELDS } from "./constants";

export const getRequiredFieldsWhenPostingForAClient = (
  isEmployerImpersonated?: boolean,
) => {
  if (isEmployerImpersonated) {
    return [POST_JOB_FOR_CLIENT_FIELDS.job_fill_support_type];
  }

  return [
    POST_JOB_FOR_CLIENT_FIELDS.employer,
    POST_JOB_FOR_CLIENT_FIELDS.hiring_manager,
    POST_JOB_FOR_CLIENT_FIELDS.job_fill_support_type,
  ];
};
