export const APPROVAL_STATUS_VALUES = {
  approved: "approved",
  notApproved: "not_approved",
};
export const APPROVAL_STATUS_LABELS = {
  default: "Approval status",
  approved: "Approved",
  notApproved: "Not approved",
  both: "Approved & Not approved",
};
export const APPROVAL_STATUS_VALUE_TO_LABEL = {
  true: APPROVAL_STATUS_LABELS.approved,
  false: APPROVAL_STATUS_LABELS.notApproved,
  both: APPROVAL_STATUS_LABELS.both,
  default: APPROVAL_STATUS_LABELS.default,
};
