import { useRequestHelpOfferApprovalMutation } from "@js/apps/give-and-get-help/api";
import { RequestApprovalModal } from "@js/apps/messenger/components/request-approval-modal";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type { HelpOffer } from "@js/types/give-and-get-help";

import styles from "./styles.module.scss";

type UseRequestHelpOfferApprovalConfig = {
  offer: HelpOffer;
};

export const useRequestHelpOfferApproval = ({
  offer,
}: UseRequestHelpOfferApprovalConfig) => {
  const [requestApproval, { isLoading: isRequestingApproval }] =
    useRequestHelpOfferApprovalMutation();

  const requestApprovalHandler = () => {
    requestApproval({
      offerId: offer.id,
      data: { budget: String(offer.budget) },
    })
      .unwrap()
      .then(() => Snackbar.info("Final review requested"))
      .catch(() => Snackbar.error("Something went wrong."))
      .finally(() => ModalInstance.close());
  };

  const handleRequestApproval = () => {
    ModalInstance.open({
      closeButton: true,
      keepOnBackdropClick: false,
      closeButtonProps: {
        variant: "tertiary",
        size: "x-small",
      },
      className: styles.markCompleteModal,
      children: (
        <RequestApprovalModal
          approveRequest={requestApprovalHandler}
          offerReceiverName={offer.receiver.user.first_name}
        />
      ),
      onClose: ModalInstance.close,
    });
  };

  return {
    handleRequestApproval,
    isRequestingApproval,
  };
};
