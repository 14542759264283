import React, { useState } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import type { TextFieldUnionProps } from "@hexocean/braintrust-ui-components";
import { IconButton, InputAdornment } from "@hexocean/braintrust-ui-components";
import {
  VisibilityOffOutlinedIcon,
  VisibilityOutlinedIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import { TextField } from "../text";

type PasswordFieldProps = TypedWrappedFieldProps<string> &
  TextFieldUnionProps & {
    //special data atribute to prevent LastPass from filling this field
    "data-lpignore"?: "true";
    id: string;
    label: string;
  };

export const PasswordField = (props: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment variant="filled" position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={() => setShowPassword((value) => !value)}
              variant="transparent"
            >
              {showPassword ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <VisibilityOutlinedIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
