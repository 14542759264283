import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

const referrerQueryParamsSchema = z.object({
  job_id: z.coerce.number().positive().int().optional(),
  jobs: z
    .string()
    .transform((val) => typeof val !== "undefined")
    .optional(),
});

type ReferrerQueryParams = z.infer<typeof referrerQueryParamsSchema>;

const defaultReferrerQueryParams: ReferrerQueryParams = {};

export const useReferrerQueryParams = () => {
  const [params] = useSearchParams();

  return useMemo(() => {
    const paramsParseResult = referrerQueryParamsSchema.safeParse(
      Object.fromEntries(params.entries()),
    );
    if (!paramsParseResult.success) {
      return defaultReferrerQueryParams;
    }

    return paramsParseResult.data;
  }, [params]);
};
