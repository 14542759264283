import type { OrderingType } from "@js/apps/common/components/filters";
import type { FilterLocation } from "@js/apps/filters/types";
import { TALENT_LOCATION } from "@js/apps/freelancer/constants";
import { JOB_LOCATION } from "@js/apps/jobs/context";
import { LocalStorage } from "@js/services";

const defaultFiltersStorageKey = LocalStorage.keys.JOB_LISTING_FILTERS;

export const getFiltersStorageKey = (location?: FilterLocation) => {
  if (location === JOB_LOCATION.jobs_landing_page) {
    return LocalStorage.keys.JOB_LANDING_FILTERS;
  }

  if (location === JOB_LOCATION.job_search) {
    return LocalStorage.keys.JOBS_SEARCH_FILTERS;
  }

  if (location === TALENT_LOCATION.talent_listing) {
    return LocalStorage.keys.TALENT_LISTING_FILTERS;
  }

  if (location === TALENT_LOCATION.talent_search) {
    return LocalStorage.keys.TALENT_SEARCH_FILTERS;
  }

  if (location === TALENT_LOCATION.legacy_talent_search) {
    return LocalStorage.keys.TALENT_SEARCH_FILTERS;
  }

  return defaultFiltersStorageKey;
};

export const getDefaultJobsSortOrdering = (filters: {
  search?: string;
}): OrderingType => {
  return !!filters?.search ? "-score" : "-created";
};
