import cs from "classnames";

import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { shortenValue } from "@js/utils";

import styles from "./styles.module.scss";

type PreviewLoadingFilesProps = {
  isImage: boolean;
  fileName?: string;
};

export const PreviewLoadingFiles = ({
  isImage,
  fileName,
}: PreviewLoadingFilesProps) => {
  const classes = cs(styles.getHelpPreviewFile, {
    [styles.getHelpPreviewFileImage]: isImage,
  });
  return (
    <div className={classes}>
      <CustomLoader />
      <div>
        <Typography component="p" variant="label">
          {fileName ? shortenValue(fileName, 12) : "New file"}
        </Typography>
        <Typography
          component="p"
          variant="paragraph"
          size="small"
          sx={{ color: isImage ? "var(--dark-yellow)" : "var(--dark-violet)" }}
        >
          Uploading...
        </Typography>
      </div>
    </div>
  );
};

const CustomLoader = () => {
  return (
    <Box
      width="48px"
      height="48px"
      borderRadius={100}
      bgcolor="var(--white)"
      position="relative"
      flexShrink={0}
    >
      <Box
        width="24px"
        height="24px"
        border="2.6px solid var(--soft-beige)"
        borderRadius={100}
        className={styles.getHelpPreviewLoader}
      />
      <Loader
        size={24}
        color="success"
        className={styles.getHelpPreviewLoader}
      />
    </Box>
  );
};
