import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Field, Fields } from "redux-form";

import { Box } from "@hexocean/braintrust-ui-components";
import { setLayoutBackgroundColor } from "@js/apps/common/actions";
import {
  AddTeamMemberDrawerTrigger,
  CreateJobHeaderPortal,
} from "@js/apps/jobs/apps/create-job/components";
import { DeleteDraftButton } from "@js/apps/jobs/apps/create-job/components/buttons/delete-draft";
import { SetupNewJobButton } from "@js/apps/jobs/apps/create-job/components/buttons/set-up-new-job";
import { SubmitButton } from "@js/apps/jobs/apps/create-job/components/buttons/submit";
import { CreateOrEditJobMainFields } from "@js/apps/jobs/apps/create-job/forms/main-step";
import { CreateOrEditJobSetUpFields } from "@js/apps/jobs/apps/create-job/forms/set-up-step";
import { useCreateJobBackButton } from "@js/apps/jobs/apps/create-job/hooks/create-job-back-button";
import { useAppDispatch } from "@js/hooks";

export const CreateJobSetupPage = () => {
  return (
    <Box width={1}>
      <CreateJobHeaderPortal
        backLink="/employer/dashboard/my_jobs/"
        rightSocket={<SetupNewJobButton />}
      />
      <CreateOrEditJobSetUpFields />
    </Box>
  );
};

export const CreateJobMainPage = () => {
  const { id } = useParams();

  const { onBackBtnClick } = useCreateJobBackButton();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLayoutBackgroundColor("var(--off-white)"));
    return () => {
      dispatch(setLayoutBackgroundColor(undefined));
    };
  }, [dispatch]);

  return (
    <Box width={1}>
      <CreateJobHeaderPortal
        displaySettings
        backLink={`/jobs/add_new/${id}/set_up/`}
        rightSocket={
          <Box display="flex" gap={1} flexWrap="wrap">
            <Field name="isDeletingJobDraft" component={DeleteDraftButton} />
            <Fields
              names={[
                "job_subscribers",
                "job_subscriber_invitations",
                "jobOwnerId",
              ]}
              component={AddTeamMemberDrawerTrigger}
            />
            <SubmitButton />
          </Box>
        }
        onClick={onBackBtnClick}
      />
      <CreateOrEditJobMainFields />
      <SubmitButton />
    </Box>
  );
};
