import type { MouseEvent } from "react";
import cs from "classnames";

import { Box, Button } from "@hexocean/braintrust-ui-components";

import styles from "./styles.module.scss";

type ReadMoreButtonProps = {
  showFullText: boolean;
  onClick: (ev: MouseEvent<HTMLElement>) => void;
};

export const ReadMoreButton = ({
  showFullText,
  onClick,
}: ReadMoreButtonProps) => {
  return (
    <>
      {!showFullText && (
        <Box component="span" px={1}>
          ·
        </Box>
      )}
      <Button
        fontWeight={400}
        className={cs(styles.button, {
          [styles.buttonMore]: !showFullText,
          [styles.buttonLess]: showFullText,
        })}
        variant="no-padding"
        onClick={onClick}
      >
        Read {showFullText ? "less" : "more"}
      </Button>
    </>
  );
};
