import { useMemo } from "react";
import type { WrappedFieldProps } from "redux-form";

import type { ComboBoxProps } from "@hexocean/braintrust-ui-components";
import { ComboBox, TextField } from "@hexocean/braintrust-ui-components";
import type { CompanyNodeBase } from "@js/types/common";

type CompanyNodeSearchComboboxFieldProps = WrappedFieldProps &
  Partial<Omit<ComboBoxProps<CompanyNodeBase, false>, "component">> & {
    label?: string;
  };

export const CompanyNodeSearchComboboxField = ({
  input,
  meta,
  label,
  options,
  ...props
}: CompanyNodeSearchComboboxFieldProps) => {
  const value = useMemo(() => {
    if (!input.value) {
      return null;
    }

    const found = (options || []).find((option) => {
      return option.slug === input.value;
    });

    return found ?? null;
  }, [input, options]);

  return (
    <ComboBox<CompanyNodeBase, false>
      initialTaxonomiesLoading={false}
      options={options || []}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="standard"
            label={label || "Company node"}
            error={!!meta.error && meta.touched}
            helperText={meta.touched && meta.error}
          />
        );
      }}
      value={value}
      onChange={(_ev, valueArg) => {
        input.onChange(valueArg?.slug || null);
      }}
      {...props}
    />
  );
};
