import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import {
  Box,
  Button,
  Grid,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { openConfirmationClientInviteModal } from "@js/apps/onboarding/components/confirmation-client-invite-modal";
import { ModalInstance } from "@js/components/modal";
import {
  RhfReferralLinksSearchComboboxField,
  RhfSelect,
  RhfTextField,
} from "@js/rhf/fields";
import { mapServerErrors } from "@js/rhf/utils";
import { isErrorWithDataAndStatus } from "@js/types/errors";

import { useCreateNewEmployer } from "./hook";

export const openCreateNewEmployerModal = () =>
  ModalInstance.open({
    closeButton: true,
    keepOnBackdropClick: true,
    disableEscapeKeyDown: true,
    disablePortal: true,
    children: <CreateNewEmployerModalContent onClose={ModalInstance.close} />,
  });

const formSchema = z.object({
  first_name: z.string().min(1, { message: "Required." }),
  last_name: z.string().min(1, { message: "Required." }),
  email: z
    .string()
    .min(1, { message: "Required." })
    .email("Please enter a valid email address."),
  organization_name: z.string().min(1, { message: "Required." }),
  company_node: z.number().min(1, { message: "Required." }),
  referral_link: z
    .number()
    .nullish()
    .transform((val) => (!!val ? val : undefined)),
});

type CreateNewEmployerFormValues = z.infer<typeof formSchema>;

type CreateNewEmployerProps = {
  onClose: () => void;
};

export const CreateNewEmployerModalContent = ({
  onClose,
}: CreateNewEmployerProps) => {
  const { handleSubmit, control, formState, setError } =
    useForm<CreateNewEmployerFormValues>({
      mode: "onTouched",
      resolver: zodResolver(formSchema),
    });

  const { companyNodeOptions, createEmployerInvitation } =
    useCreateNewEmployer();

  const onSubmit = async (data: CreateNewEmployerFormValues) => {
    try {
      await createEmployerInvitation({ ...data });
      onClose();
      openConfirmationClientInviteModal(data);
    } catch (error) {
      if (isErrorWithDataAndStatus<CreateNewEmployerFormValues>(error)) {
        const mappedErrors = mapServerErrors(error.data);
        mappedErrors.forEach((err) => {
          setError(err.name as keyof CreateNewEmployerFormValues, err.error);
        });
      }
    }
  };

  return (
    <Box maxWidth={728} minWidth={320} minHeight={220}>
      <Typography component="h2" variant="title" size="small" mb={4}>
        Create a new client
      </Typography>
      {!companyNodeOptions ? (
        <Loader centered />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <RhfTextField
                control={control}
                id="first_name"
                name="first_name"
                label="First name*"
                placeholder="Enter their first name"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <RhfTextField
                control={control}
                id="last_name"
                name="last_name"
                label="Last name*"
                placeholder="Enter their last name"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <RhfTextField
                control={control}
                id="email"
                name="email"
                label="Email*"
                placeholder="Enter their work email address"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <RhfTextField
                control={control}
                id="organization_name"
                name="organization_name"
                label="Organization*"
                placeholder="Enter their organization"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <RhfSelect
                control={control}
                id="company_node"
                name="company_node"
                label="Node*"
                options={companyNodeOptions}
                placeholder="Select node assigned to client"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <RhfReferralLinksSearchComboboxField
                control={control}
                id="referral_link"
                name="referral_link"
                placeholder="Select referral link"
              />
            </Grid>
            <Grid item sm={9} xs={12}>
              <Typography component="p" variant="paragraph" size="small">
                After you click “Create client”, the client will receive an
                email to activate their account and create their own password.
              </Typography>
            </Grid>
            <Grid item sm={3} xs={12} justifyContent="flex-end" display="flex">
              <Button
                variant="positive"
                shape="squared"
                size="medium"
                type="submit"
                disabled={!formState.isValid}
              >
                Create client
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};
