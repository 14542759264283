import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { useAccountType } from "@js/apps/common/hooks";
import {
  isInterviewAvailable,
  useGetLastInterviewQuery,
} from "@js/apps/interview";
import { ModalInstance } from "@js/components/modal";

import { FreelancerScreenedModalContent } from "./freelancer-screened-modal-content";
import { StartScreeningModalContent } from "./start-screening-modal-content";
import type { OpenBidCreateSuccessModalArg } from "./types";

import styles from "./styles.module.scss";

export const closeBidCreateSuccessModal = ModalInstance.close;

export const openBidCreateSuccessModal = ({
  roleId,
  onKeyDown,
  onPurchaseBoostCredits,
  isPreBoosted,
  jobId,
  bidId,
}: OpenBidCreateSuccessModalArg) =>
  ModalInstance.open({
    children: (
      <BidSentSuccessfullyModalContent
        closeModal={ModalInstance.close}
        roleId={roleId}
        onPurchaseBoostCredits={onPurchaseBoostCredits}
        isPreBoosted={isPreBoosted}
        jobId={jobId}
        bidId={bidId}
      />
    ),
    closeButton: false,
    className: styles.recommendedJobsModal,
    keepOnBackdropClick: true,
    padding: false,
    ...(onKeyDown ? { onKeyDown } : ({} as any)),
  });

type BidSentSuccessfullyModalContentProps = {
  closeModal: () => void;
} & OpenBidCreateSuccessModalArg;

const BidSentSuccessfullyModalContent = ({
  closeModal,
  roleId,
  onPurchaseBoostCredits,
  isPreBoosted,
  jobId,
  bidId,
}: BidSentSuccessfullyModalContentProps) => {
  const mounted = useRef(false);
  const location = useLocation();
  const { isFreelancerApproved } = useAccountType();
  const { data: interview, isLoading } = useGetLastInterviewQuery(undefined, {
    skip: isFreelancerApproved,
  });
  const showStartScreeningModal = isInterviewAvailable(interview);

  useEffect(() => {
    return () => {
      if (mounted.current) {
        closeModal();
      } else {
        mounted.current = true;
      }
    };
  }, [location, closeModal]);

  if (isLoading) {
    return <></>;
  }

  if (showStartScreeningModal) {
    return <StartScreeningModalContent closeModal={closeModal} />;
  }

  return (
    <FreelancerScreenedModalContent
      roleId={roleId}
      onPurchaseBoostCredits={onPurchaseBoostCredits}
      closeModal={closeModal}
      isPreBoosted={isPreBoosted}
      jobId={jobId}
      bidId={bidId}
    />
  );
};
