// Credits: https://github.com/iamhosseindhv/notistack/issues/30#issuecomment-492424819
import type { ReactNode } from "react";
import { useEffect } from "react";
import type { To } from "react-router-dom";
import type { OptionsObject } from "notistack";
import { SnackbarProvider } from "notistack";

import { Box, IconButton } from "@hexocean/braintrust-ui-components";
import {
  CheckCircleIcon,
  CloseIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { SnackbarToastProps } from "@js/components/snackbar/toast";
import { ToastSnackbarContent } from "@js/components/snackbar/toast";
import { useNavigate } from "@js/hooks/navigate";
import { SessionStorage } from "@js/services";
import { formatErrorForSnackbar } from "@js/utils/string";

import { useSnackbarRef } from "./snackbar-utils-configurator";

import styles from "./style.module.scss";

type Message = React.ReactNode;

type ErrorMessage = Message | Record<string, string[] | string>;

export type SnackbarModel = {
  info: (m: Message, p?: OptionsObject) => void;
  success: (m: Message, p?: OptionsObject) => void;
  warning: (m: Message, p?: OptionsObject) => void;
  error: (m: ErrorMessage, p?: OptionsObject) => void;
  black: (m: Message, p?: OptionsObject) => void;
  custom: (m: Message, p?: OptionsObject) => void;
  toast: (m: SnackbarToastProps, p?: OptionsObject) => void;
  render: (m: ErrorMessage | SnackbarToastProps, p?: OptionsObject) => void;
};

export const StyledSnackbarProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <SnackbarProvider
      classes={{
        root: styles.snackbar,
        containerRoot: styles.snackbarContainer,
      }}
      iconVariant={{
        success: (
          <CheckCircleIcon
            style={{
              color: "var(--white)",
              fontSize: 22,
              marginRight: "8px",
            }}
          />
        ),
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export const Snackbar: SnackbarModel = {
  info: function (message, props?) {
    this.render(message, {
      variant: "info",
      ...(props || {}),
    });
  },

  success: function (message, props?) {
    this.render(message, {
      variant: "success",
      ...(props || {}),
    });
  },

  warning: function (message, props?) {
    this.render(message, {
      variant: "warning",
      ...(props || {}),
    });
  },

  error: function (message, props?) {
    if (message && Object.keys(message).length !== 0) {
      this.render(message, {
        variant: "error",
        ...(props || {}),
      });
    }
  },

  black: function (message, props?) {
    this.render(message, {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      preventDuplicate: true,
      content: (key, mess) => (
        <Box
          id={`${key}`}
          sx={{
            background: "var(--black)",
            color: "var(--soft-teal)",
            p: 2,
            minWidth: "360px",
            maxWidth: "410px",
            borderRadius: "46px",
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          {mess}
        </Box>
      ),
      ...(props || {}),
    });
  },

  custom: function (message, props?) {
    this.render(message, {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      preventDuplicate: true,
      content: (key, mess) => <Box id={`${key}`}>{mess}</Box>,
      ...(props || { persist: true }),
    });
  },

  toast: function (message, props?) {
    this.render(message, {
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      preventDuplicate: true,
      autoHideDuration: 20000,
      content: (key) => <ToastSnackbarContent {...message} id={key} />,
      ...props,
    });
  },

  render: (message, props?) =>
    useSnackbarRef?.enqueueSnackbar(formatErrorForSnackbar(message), {
      action: (key) => (
        <IconButton
          size="small"
          variant="transparent"
          onClick={() => useSnackbarRef.closeSnackbar(key)}
          aria-label="Close"
        >
          <CloseIcon
            style={{
              color: "var(--white)",
              fontSize: 22,
            }}
          />
        </IconButton>
      ),
      ...props,
    }),
};

type NavigateWithSnackbarToastProps = Omit<
  SnackbarToastProps,
  "color" | "key" | "id"
> &
  Omit<OptionsObject, "content"> & {
    to: To;
    replace?: boolean;
  };

// navigates to page and stores toast info
export const NavigateWithSnackbarToast = ({
  to,
  replace,
  header,
  content,
  ...props
}: NavigateWithSnackbarToastProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    SessionStorage.setItem(
      SessionStorage.keys.NAVIGATE_WITH_SNACKBAR_DATA,
      JSON.stringify({ header, content, options: props }),
    );
    navigate(to, { replace: replace });
  }, [content, header, navigate, props, replace, to]);

  return null;
};

// shows stored toast when this component is rendered
// prevents toast being dismissed by reloads or redirects happening after NavigateWithSnackbarToast
export const NavigateWithSnackbarToastReceiver = () => {
  useHandleSnackbarNavigation();
  return <></>;
};

const useHandleSnackbarNavigation = () => {
  useEffect(() => {
    const snackbarDataString = SessionStorage.getItem(
      SessionStorage.keys.NAVIGATE_WITH_SNACKBAR_DATA,
    );

    if (snackbarDataString === null) return;

    SessionStorage.removeItem(SessionStorage.keys.NAVIGATE_WITH_SNACKBAR_DATA);
    const { header, content, options } = JSON.parse(
      snackbarDataString,
    ) as SnackbarToastProps & { options: OptionsObject };

    Snackbar.toast({ header, content }, options);
  }, []);
};
