import { useABExperiment } from "@js/apps/experiments";

export const useShowInsightFeatureFlag = (): boolean => {
  const { isParticipating, experiment } = useABExperiment("Insights");
  const isApplicationBoostEnabled =
    experiment?.variant ===
    SETTINGS.EXPERIMENT_ENUMS[SETTINGS.EXPERIMENT_ENUMS.Experiments.Insights]
      ?.InsightsEnabled;

  return isApplicationBoostEnabled && isParticipating;
};
