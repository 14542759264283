import type { Group, User } from "@js/types/auth";

export const hasPermission = (
  user: Pick<User, "is_superuser" | "permissions"> | null,
  permission: User["permissions"][number],
) => {
  if (!user) return false;
  if (user.is_superuser) return true;
  return user.permissions.includes(permission);
};

// for action purpose - if user can trigger some action
export const hasGroupPermission = (
  user: Pick<User, "is_superuser" | "groups"> | null,
  group: Group,
) => {
  if (!user) return false;
  if (user.is_superuser) return true;
  if (!user.groups) return false;
  return user.groups.includes(group);
};

// for UI purpose - e.g if group needs different UI than admin
export const hasGroup = (user: Pick<User, "groups"> | null, group: Group) => {
  if (!user) return false;
  if (!user.groups) return false;
  return user.groups.includes(group);
};
