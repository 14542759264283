import type { JobFormValues } from "@js/types/jobs";

export const gradingCriteriaValidator = (
  _value: unknown,
  allValues: JobFormValues,
) => {
  const { interview_criteria, is_ai_interview_enabled } = allValues;

  if (is_ai_interview_enabled && interview_criteria?.length === 0) {
    return "Grading criteria must have a minimum of 1 and maximum of 5 criteria.";
  }
};

export const questionsValidator = (
  _value: unknown,
  allValues: JobFormValues,
) => {
  const { interview_questions, is_ai_interview_enabled } = allValues;

  if (!is_ai_interview_enabled) {
    return;
  }

  if (!interview_questions || interview_questions?.length === 0) {
    return "AI interview must have a minimum of 1 question and maximum of 5 questions.";
  } else if (interview_questions?.length > 0) {
    const errors = [""];
    let hasError = false;

    for (const { question } of interview_questions) {
      errors.push(!question ? "|This is required.|" : "||");
      hasError = hasError || !question;
    }

    return hasError && errors.join(";");
  }
};
