import { useMatch } from "react-router-dom";

import { Snackbar } from "@js/components/snackbar";
import { useNavigate } from "@js/hooks";

import { useCancelJobOffer } from "../cancel-job-offer";

type UseCancelOfferButtonProps = {
  offerId: number;
  jobId: number;
};

export const useCancelOfferButton = ({
  jobId,
  offerId,
}: UseCancelOfferButtonProps) => {
  const viewOfferLink = `/jobs/${jobId}/offers/${offerId}`;
  const match = useMatch(viewOfferLink);
  const navigate = useNavigate();
  const cancelOffer = useCancelJobOffer({
    offerId,
    onSuccess: () => {
      Snackbar.success("Offer successfully cancelled.");
      if (!match) navigate(viewOfferLink);
    },
  });

  return {
    cancelOffer,
  };
};
