import React from "react";

import { assertUnreachable } from "@js/utils";

import type { LinkAttachment } from "../post-attachments";

import { JobCard } from "./job-image-card";
import { LinkPreview } from "./link";
import { TalentProfileCard } from "./talent-profile-card";

type LinkPreviewManagerProps = {
  linkAttachment: LinkAttachment;
  openNewTab?: boolean;
};

export const LinkPreviewManager = ({
  linkAttachment,
  ...props
}: LinkPreviewManagerProps) => {
  switch (linkAttachment.source) {
    case "default":
      return (
        <LinkPreview
          url={linkAttachment.url}
          title={linkAttachment.title}
          image={linkAttachment.image}
        />
      );

    case "freelancer":
      return <TalentProfileCard {...linkAttachment} {...props} />;

    case "job":
      return <JobCard {...linkAttachment} {...props} />;

    default:
      assertUnreachable(linkAttachment);
      return null;
  }
};
