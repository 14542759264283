import { createContext, useContext } from "react";

export const IsProfileOwnerContext = createContext<{ isProfileOwner: boolean }>(
  { isProfileOwner: false },
);

export const useIsProfileOwnerContext = () => {
  const value = useContext(IsProfileOwnerContext);

  if (!value) throw new Error("There is no IsProfileOwnerProvider");

  return value;
};
