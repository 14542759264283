import type { TypedWrappedFieldProps } from "redux-form";

import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import {
  PaymentMethodsList,
  SelectablePaymentMethodsList,
} from "@js/apps/payments/components/payment-methods-list";
import type { PaymentMethod } from "@js/types/payments";

import { usePaymentMethodsListing } from "./use-payment-methods-listing";

import styles from "./styles.module.scss";

export type PaymentMethodsListingProps = {
  paymentMethods: PaymentMethod[];
  fetchingPaymentMethods: boolean;
  disabled?: boolean;
  meta: TypedWrappedFieldProps<PaymentMethod>["meta"];
  onDelete: (paymentMethod: PaymentMethod) => void;
  onVerifyStripeACHPaymentMethod?: (paymentMethod: PaymentMethod) => void;
  onChange: (value: PaymentMethod | null) => void;
};

export const PaymentMethodsListing = ({
  paymentMethods,
  fetchingPaymentMethods,
  onChange,
  disabled,
  meta,
  ...props
}: PaymentMethodsListingProps) => {
  const { paymentMethodOptions, isOnlyOneMethod } = usePaymentMethodsListing({
    onChange,
    paymentMethods,
    meta,
  });

  if (fetchingPaymentMethods) return <Loader />;

  return (
    <Box className={styles.paymentMethodListing}>
      {isOnlyOneMethod ? (
        <PaymentMethodsList
          paymentMethods={paymentMethods}
          hideVerifyButton
          {...props}
        />
      ) : (
        <SelectablePaymentMethodsList
          paymentMethodsOptions={paymentMethodOptions}
          disabled={disabled}
          name="deposit_payment_method"
        />
      )}

      {paymentMethods.length > 0 && (
        <div className={styles.paymentMethodInfo}>
          <Typography component="p" size="small">
            This deposit is 100% refundable.
          </Typography>
        </div>
      )}
    </Box>
  );
};
