import { useCloseAvailabilityCoachMarkMutation } from "@js/apps/available-for-work/api";
import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import { useAppDispatch, useAppSelector } from "@js/hooks";

export const useAvailableForWorkCoachMark = () => {
  const [closeAvailabilityCoachMark] = useCloseAvailabilityCoachMarkMutation();
  const freelancerProfile = useAppSelector(
    (state) => state.freelancer.freelancerProfile,
  );
  const showAvailabilityCoachMark =
    freelancerProfile?.show_availability_coachmark;

  const freelancerId = freelancerProfile?.id;
  const dispatch = useAppDispatch();

  const showAvailableForWorkCoachMark =
    !!freelancerProfile && !!showAvailabilityCoachMark;

  const handleCoachMarkClose = async () => {
    if (!freelancerId || !showAvailableForWorkCoachMark) {
      return;
    }

    await closeAvailabilityCoachMark({ id: freelancerId });
    dispatch(fetchFreelancerProfile(freelancerId));
  };

  return {
    showAvailableForWorkCoachMark,
    handleAvailableForWorkCoachMarkClose: handleCoachMarkClose,
  };
};
