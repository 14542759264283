import type { ReactNode } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { createContext, useContext, useMemo } from "react";
import { destroy } from "redux-form";
import _ from "underscore";

import { useAppDispatch } from "@js/hooks";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import type { PostComment } from "@js/types/give-and-get-help";

type CollapsedCommentData = { id: number; postId: number };

type PostsContextType = {
  expandedPostsOrCommentsIds: number[];
  editedCommentsIds: number[];
  replyOpenCommentsIds: number[];
  commentsToReplyInThread: Record<number, PostComment | undefined>;
  collapsedComments: CollapsedCommentData[];
  postOrCommentContentExpandedIds: number[];
  expandedReplyForms: string[];
  togglePostOrCommentContent: (postOrCommentId: number) => void;
  setCommentToReplyInThread: (arg: {
    commentToReplyInThread: PostComment | undefined;
    commentId: number;
  }) => void;
  toggleCommentReplyOpen: (arg: { commentId: number; isOpen: boolean }) => void;
  toggleCommentEdit: (arg: { commentId: number; isEditing: boolean }) => void;
  onPostExpand: (id: number) => void;
  onCommentCollapse: (arg: CollapsedCommentData) => void;
  onCommentExpand: (commentId: number) => void;
  onReplyFormInit: (formId: string) => void;
  onReplyFormExpand: (formId: string) => void;
};

const PostsContext = createContext<PostsContextType | null>(null);

type PostsContextProviderProps = {
  children: ReactNode;
  initialExpandedIds?: number[];
};
export const PostsContextProvider = ({
  children,
  initialExpandedIds = [],
}: PostsContextProviderProps) => {
  const dispatch = useAppDispatch();
  const [formsToDestroy, setFormsToDestroy] = useState<string[]>([]);
  const [expandedReplyForms, setExpandedReplyForms] = useState<string[]>([]);
  const [postOrCommentContentExpandedIds, setPostOrCommentContentExpandedIds] =
    useState<number[]>([]);
  const [editedCommentsIds, setEditedCommentsIds] = useState<number[]>([]);
  const [replyOpenCommentsIds, setReplyOpenCommentsIds] = useState<number[]>(
    [],
  );
  const [commentsToReplyInThread, setCommentsToReplyInThread] = useState<
    Record<number, PostComment | undefined>
  >({});
  const [expandedPostsOrCommentsIds, setExpandedPostsOrCommentsIds] =
    useState<number[]>(initialExpandedIds);
  const [collapsedComments, setCollapsedComments] = useState<
    CollapsedCommentData[]
  >([]);

  const onReplyFormInit = useCallback((formId: string) => {
    setFormsToDestroy((prev) => _.uniq([...prev, formId]));
  }, []);

  const onReplyFormExpand = useCallback((formId: string) => {
    setExpandedReplyForms((prev) => _.uniq([...prev, formId]));
  }, []);

  const togglePostOrCommentContent = useCallback((postOrCommentId: number) => {
    setPostOrCommentContentExpandedIds((prev) => {
      const isExpanded = prev.includes(postOrCommentId);
      if (isExpanded) {
        return prev.filter((expandedItem) => expandedItem !== postOrCommentId);
      }

      return [...prev, postOrCommentId];
    });
  }, []);

  const onCommentCollapse = useCallback(
    ({ id, postId }: CollapsedCommentData) => {
      setCollapsedComments((prev) => {
        const isCollapsed = prev.some(
          (collapsedComment) => collapsedComment.id === id,
        );
        if (isCollapsed) {
          return prev;
        }

        return [...prev, { id, postId }];
      });
      setExpandedPostsOrCommentsIds((prev) =>
        prev.filter((expandedId) => expandedId !== id),
      );
    },
    [],
  );

  const toggleCommentEdit = useCallback(
    ({ isEditing, commentId }: { isEditing: boolean; commentId: number }) => {
      if (isEditing) {
        setEditedCommentsIds((prev) => _.uniq([...prev, commentId]));
      } else {
        setEditedCommentsIds((prev) =>
          prev.filter((editedCommentId) => editedCommentId !== commentId),
        );
      }
    },
    [],
  );

  const setCommentToReplyInThread = useCallback(
    ({
      commentToReplyInThread,
      commentId,
    }: {
      commentToReplyInThread: PostComment | undefined;
      commentId: number;
    }) => {
      setCommentsToReplyInThread((prev) => {
        return {
          ...prev,
          [commentId]: commentToReplyInThread,
        };
      });
    },
    [],
  );

  const toggleCommentReplyOpen = useCallback(
    ({ isOpen, commentId }: { isOpen: boolean; commentId: number }) => {
      if (isOpen) {
        setReplyOpenCommentsIds((prev) => _.uniq([...prev, commentId]));
      } else {
        setReplyOpenCommentsIds((prev) =>
          prev.filter((replyOpenCommentId) => replyOpenCommentId !== commentId),
        );
      }
    },
    [],
  );

  const onPostExpand = useCallback((postId: number) => {
    setExpandedPostsOrCommentsIds((prev) => {
      const isExpanded = prev.some((expandedId) => expandedId === postId);
      if (isExpanded) {
        return prev.filter((expandedId) => expandedId !== postId);
      }

      return _.uniq([...prev, postId]);
    });

    setCollapsedComments((prev) =>
      prev.filter((collapsedComment) => collapsedComment.postId !== postId),
    );
  }, []);

  const onCommentExpand = useCallback((commentId: number) => {
    setExpandedPostsOrCommentsIds((prev) => _.uniq([...prev, commentId]));
    setCollapsedComments((prev) =>
      prev.filter((collapsedComment) => collapsedComment.id !== commentId),
    );
  }, []);

  const value = useMemo(() => {
    return {
      expandedPostsOrCommentsIds,
      collapsedComments,
      editedCommentsIds,
      replyOpenCommentsIds,
      commentsToReplyInThread,
      postOrCommentContentExpandedIds,
      expandedReplyForms,
      onPostExpand,
      onCommentCollapse,
      onCommentExpand,
      toggleCommentEdit,
      toggleCommentReplyOpen,
      setCommentToReplyInThread,
      togglePostOrCommentContent,
      onReplyFormExpand,
      onReplyFormInit,
    };
  }, [
    expandedPostsOrCommentsIds,
    collapsedComments,
    editedCommentsIds,
    replyOpenCommentsIds,
    commentsToReplyInThread,
    postOrCommentContentExpandedIds,
    expandedReplyForms,
    onPostExpand,
    onCommentCollapse,
    onCommentExpand,
    toggleCommentEdit,
    toggleCommentReplyOpen,
    setCommentToReplyInThread,
    togglePostOrCommentContent,
    onReplyFormExpand,
    onReplyFormInit,
  ]);

  const formsToDestroyRef = useEffectRef(formsToDestroy);
  // cleanupEffect
  useEffect(
    () => () => {
      if (!formsToDestroyRef.current?.length) {
        return;
      }

      dispatch(destroy(...formsToDestroyRef.current));
    },
    [dispatch, formsToDestroyRef],
  );

  return (
    <PostsContext.Provider value={value}>{children}</PostsContext.Provider>
  );
};

export const usePostsContext = () => {
  const context = useContext(PostsContext);

  if (!context) {
    throw new Error("PostsContext is missing!");
  }

  return context;
};
