import type { PostCategory } from "@js/types/give-and-get-help";

export const createHelpCategoryString = (
  id?: number,
  data?: PostCategory[],
) => {
  if (!id || !data) {
    return null;
  }

  const matchedCategory = data.find((category) => category.id === id);
  if (!matchedCategory) {
    return null;
  }

  return matchedCategory.name;
};
