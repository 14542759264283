import { Button } from "@hexocean/braintrust-ui-components";

import styles from "../style.module.scss";

type SpaceCardJoinButtonProps = {
  onJoinSpaceClick: () => void;
  disabled?: boolean;
};

export const SpaceCardJoinButton = ({
  onJoinSpaceClick,
  disabled,
}: SpaceCardJoinButtonProps) => {
  return (
    <Button
      disabled={disabled}
      onClick={onJoinSpaceClick}
      variant="primary"
      className={styles.cardJoinButton}
    >
      Join this space
    </Button>
  );
};
