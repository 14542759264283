import React, { useMemo } from "react";

import {
  Box,
  Button,
  Menu,
  Popover,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { SettingsRoundedIcon } from "@hexocean/braintrust-ui-components/Icons";
import { ChangeJobVisibilityForm } from "@js/apps/employer/forms/change-job-visibility";
import { JobActionMenu } from "@js/apps/jobs/components/job-action-menu";
import { RouterLink } from "@js/components/link";
import { useNavigate } from "@js/hooks";
import type { Job } from "@js/types/jobs";
import { dateFromNow, pluralize } from "@js/utils";

import { useAIRInterview } from "../../hooks/use-air-interview";
import { WithJobActionsContext } from "../job-actions";

import { JobManagementHeaderAddTeamMembersButton } from "./job-management-header-add-team-members-button";
import { useJobManagementHeader } from "./use-job-management-header";

type JobManagementHeaderProps = {
  job: Job;
};

export const JobManagementHeader: React.FC<JobManagementHeaderProps> = ({
  job,
}) => {
  const {
    jobActionsContextValue,
    showJobTitleTooltip,
    updateTooltipVisibility,
    onChangeVisibility,
  } = useJobManagementHeader({ job });
  const { showAIRInterview } = useAIRInterview();

  const navigate = useNavigate();
  const additionalMenuItems = useMemo(() => {
    const menuItems: React.ReactElement[] = [];
    if (!job.completed_at) {
      menuItems.push(
        <Popover
          key="Job Visibility"
          anchor={
            <Button
              variant="transparent"
              sx={{
                width: "100%",
                padding: "0px !important",
              }}
            >
              <Menu.Item dense key="Job Visibility" sx={{ width: "100%" }}>
                <Typography component="p" paddingLeft="7px">
                  Job Visibility
                </Typography>
              </Menu.Item>
            </Button>
          }
        >
          <Box p={3} mr={1} display="flex" flexDirection="column" gap={1}>
            <Typography component="p">Post visibility</Typography>
            <ChangeJobVisibilityForm
              initialValues={{ is_private: job.is_private }}
              onSubmit={onChangeVisibility}
            />
          </Box>
        </Popover>,
      );
    }

    menuItems.push(
      <Menu.Item
        key="View Job Post"
        onClick={() => navigate(`/jobs/${job.id}`)}
      >
        View Job Post
      </Menu.Item>,
    );

    return menuItems;
  }, [job, navigate, onChangeVisibility]);

  const linkSuffix = getLinkSuffix(job.id);

  return (
    <WithJobActionsContext.Provider value={jobActionsContextValue}>
      <RoundedBox
        component="header"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          rowGap: 1,
          columnGap: 5.5,
          justifyContent: "space-between",
          p: 3,
          pr: { xs: 3, sm: 8.5 },
          mb: 2,
          position: "relative",
        }}
      >
        <Box
          sx={{
            flex: 1,
            overflow: "hidden",
            minWidth: "230px",
          }}
        >
          <Typography
            component="h2"
            color="primary"
            variant="label"
            size="large"
            ellipsis
            paddingRight={{ xs: 6, sm: 0 }}
            className="capitalize-first-letter"
            fontWeight={400}
            title={showJobTitleTooltip ? job.title : ""}
            onMouseEnter={updateTooltipVisibility}
          >
            {job.title}
          </Typography>
          <Typography component="p" size="small">
            {`Posted ${dateFromNow(job.published_at)}. `}
            {job.openings_number > 1 &&
              `${job.openings_number} open role${pluralize(
                job.openings_number,
              )}.`}
          </Typography>
        </Box>
        <Box
          sx={{
            gap: "8px",
            display: "flex",
            flexWrap: "wrap",
            flexShrink: 0,
            alignItems: "center",
            maxWidth: "100%",
          }}
        >
          {!job.completed_at && (
            <>
              <JobManagementHeaderAddTeamMembersButton job={job} />
              {showAIRInterview && (
                <Button
                  variant="white-violet"
                  shape="squared"
                  endIcon={
                    <img
                      src={`${SETTINGS.STATIC_URL}jobs/ai-job-icon.svg`}
                      alt="ai job"
                      width="21"
                      height="21"
                      style={{ filter: "brightness(0%)" }}
                    />
                  }
                  to={`/jobs/${job.id}/interview-questions`}
                  RouterLink={RouterLink}
                >
                  {job.is_ai_interview_enabled
                    ? "Edit AI interview"
                    : "Create AI interview"}
                </Button>
              )}
              <Button
                variant="white-violet"
                shape="squared"
                inverse
                endIcon={<SettingsRoundedIcon />}
                to={`/jobs/${job.id}/edit${linkSuffix ? `?back=${linkSuffix}` : ""}`}
                RouterLink={RouterLink}
              >
                Edit job
              </Button>
            </>
          )}
          <Box
            sx={{
              position: "absolute",
              top: "28px",
              right: "16px",
            }}
          >
            <JobActionMenu
              job={job}
              filterActions={filterJobActions}
              additionalMenuItems={additionalMenuItems}
            />
          </Box>
        </Box>
      </RoundedBox>
    </WithJobActionsContext.Provider>
  );
};

const filterJobActions = (jobActions: Job["job_actions"]) =>
  jobActions.filter(
    (jobAction) => jobAction.action !== ENUMS.JobActions.ADD_TEAM_MEMBERS,
  );

const getLinkSuffix = (jobId: number): string | null => {
  const pattern = new RegExp(`${jobId}/(.*?)/?$`);
  const match = window.location.pathname.match(pattern);
  return match?.[1] ?? null;
};
