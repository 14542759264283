import type { RefetchBidList } from "@js/apps/jobs/context/refetch-bids-context";

export const hideApplication = (
  bidId: number,
  refetchBidList: RefetchBidList | null,
  hideProposal: (
    bidId: number,
    isHidden: boolean,
  ) => Promise<{ data: void } | { error: unknown }>,
) =>
  hideProposal(bidId, true).then(() => {
    if (refetchBidList) {
      refetchBidList();
    }
  });
