import { Field } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { SelectField } from "@js/forms/fields";
import { required } from "@js/forms/validators";

import { INTERVIEW_QUESTION_MAX_ANSWER_DURATION_OPTIONS } from "../../constants";

export const JobInterviewSettings = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        padding: "32px",
        borderRadius: "16px",
        backgroundColor: "#fff",
        border: "1px solid var(--grey-4)",
      }}
    >
      <Typography component="h1" size="large" fontWeight={400}>
        Settings
      </Typography>
      <Typography component="span" size="small" fontWeight={400} mb={2}>
        These apply to all of the questions.
      </Typography>
      <Field
        name="ai_interview_max_duration"
        component={SelectField}
        label="Max answer duration"
        validate={[required]}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: "var(--white)",
              border: "1px solid var(--grey-5)",
              borderRadius: "8px",
              "& .MuiMenuItem-root": {
                padding: "6px 8px",
                marginBottom: "4px",
              },
              "& .Mui-selected, & .MuiMenuItem-root:hover": {
                backgroundColor: "var(--soft-violet) !important",
              },
              "& .MuiMenuItem-root:active": {
                backgroundColor: "var(--medium-violet) !important",
              },
            },
          },
        }}
        sx={{
          "& .MuiSvgIcon-root": {
            marginRight: "12px",
          },
          "& .MuiInputBase-input": {
            padding: "8px 16px !important",
          },
        }}
        options={INTERVIEW_QUESTION_MAX_ANSWER_DURATION_OPTIONS}
      />
    </Box>
  );
};
