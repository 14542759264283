import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";

type ProfileProjectsModuleNavHeaderProps = {
  onClose: () => void;
  isEditMode: boolean;
};

export const ProfileProjectsModuleNavHeader = ({
  onClose,
  isEditMode,
}: ProfileProjectsModuleNavHeaderProps) => {
  return (
    <Box
      display="flex"
      gap={2}
      justifyContent="flex-start"
      alignItems="center"
      mb={3}
    >
      <IconButton
        aria-label="Back to project list"
        onClick={onClose}
        variant="tertiary"
        size="small"
      >
        <ArrowLeftIcon />
      </IconButton>
      <Typography component="h1" variant="title" size="small">
        {isEditMode ? "Edit Project" : "Add a project"}
      </Typography>
    </Box>
  );
};
