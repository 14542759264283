import React from "react";
import type { DecoratedFormProps } from "redux-form";

import { updateUser } from "@js/apps/auth/actions";
import { useUser } from "@js/apps/common/hooks";
import type { ReduxFormConfiguration } from "@js/forms/components/redux-form";
import type { AppDispatch } from "@js/store";

export type PersonalInfoFormValues = {
  avatar: string | null;
  first_name: string;
  last_name: string | null;
  title: string | null;
  introduction: string | null;
  user_languages: Array<{
    language_id: number;
    skill_level: string;
  }>;
};

type PersonalInfoFormConfigProps =
  ReduxFormConfiguration<PersonalInfoFormValues>;

type usePersonalInfoFormFun = () => {
  onSubmit: PersonalInfoFormConfigProps["onSubmit"];
  initialValues: PersonalInfoFormConfigProps["initialValues"];
};

export const usePersonalInfoForm: usePersonalInfoFormFun = () => {
  const user = useUser();

  const onSubmit: PersonalInfoFormConfigProps["onSubmit"] = (
    values: PersonalInfoFormValues,
    dispatch: AppDispatch,
    { reset }: DecoratedFormProps<PersonalInfoFormValues>,
  ) => {
    return dispatch(updateUser(values)).then(() => {
      reset?.();
    });
  };

  const initialValues = React.useMemo(() => {
    if (!user) return {};

    return {
      avatar: user.avatar,
      first_name: user.first_name,
      last_name: user.last_name,
      title: user.title,
      introduction: user.introduction,
      user_languages: user.user_languages.map((lang) => ({
        language_id: lang.language.id,
        skill_level: lang.skill_level || "",
      })),
    };
  }, [user]);

  return { onSubmit, initialValues };
};
