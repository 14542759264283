import React from "react";
import { Field, reduxForm } from "redux-form";

import { Button, Grid, Typography } from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { REVIEW_SUBJECT } from "@js/apps/reviews/components/constants";
import { Form } from "@js/forms/components/form";
import {
  RadioField,
  RatingField,
  SelectField,
  TextareaField,
} from "@js/forms/fields";

import type { SaveReviewProps } from "../../api";

const WRITE_REVIEW_FORM_ID = "write-review-form";

type WriteReviewFormProps = {
  onClose: () => void;
  openReviewProcessesList: any[];
  hideSubjectInput?: boolean;
  reviewSubject: (typeof REVIEW_SUBJECT)[keyof typeof REVIEW_SUBJECT];
};

export const WriteReviewForm = reduxForm<SaveReviewProps, WriteReviewFormProps>(
  {
    form: WRITE_REVIEW_FORM_ID,
    enableReinitialize: true,
  },
)(({
  submit,
  submitting,
  onClose,
  openReviewProcessesList,
  hideSubjectInput,
  reviewSubject,
}) => {
  const subjectIsEmployer = reviewSubject === ENUMS.AccountType.EMPLOYER;

  return (
    <Grid
      className="write-review"
      container
      spacing={4}
      component={Form}
      onSubmit={submitting ? undefined : submit}
    >
      <Grid
        item
        xs={12}
        style={{ display: hideSubjectInput ? "none" : "block" }}
      >
        <Field
          label="Your review subject"
          id="review_process"
          name="review_process"
          options={openReviewProcessesList.map(({ id, job }) => ({
            value: id,
            label: (
              <div className="select-field-item-content capitalize-first-letter">
                <span className="color-gray-2">{job.title}</span> Overall Rating
              </div>
            ),
            labelText: `${job.title} - Overall Rating`,
          }))}
          component={SelectField}
        />
        <Field id="subject" name="subject" type="hidden" component="input" />
      </Grid>
      <Grid item xs={6}>
        <Typography component="p">
          {subjectIsEmployer ? "Clear expectations and scope" : "Work Quality"}
        </Typography>
        <Field
          id="work_quality"
          name="work_quality_score"
          size="small"
          component={RatingField}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography component="p">
          {subjectIsEmployer ? "Communication" : "Responsiveness"}
        </Typography>
        <Field
          id="responsiveness"
          name="responsiveness_score"
          size="small"
          component={RatingField}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component="p" mb={1}>
          {subjectIsEmployer
            ? "Would you work with this organization again?"
            : "Would work with this person again?"}
        </Typography>
        <Field
          id="would_work_with_subject_again"
          name="would_work_with_subject_again"
          color="primary"
          row={true}
          component={RadioField}
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          rows={4}
          maxLength={5000}
          id="additional_comments"
          name="additional_comments"
          component={TextareaField}
          label={
            subjectIsEmployer
              ? "Tell us about your experience with this employer"
              : "Your review"
          }
        />
        <Typography mt={1} size="small" color="grey-2" component="p">
          <InfoIcon
            style={{
              fontSize: "24px !important",
              verticalAlign: "-7px",
              marginRight: "7px",
              strokeWidth: "1.2px",
            }}
          />
          Reviews are double-blinded and it will display after both parties
          evaluate each other.
        </Typography>
      </Grid>

      <Grid item xs={12} className="buttons right">
        <Button variant="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" disabled={submitting} onClick={submit}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
});
