import { Box, Typography } from "@hexocean/braintrust-ui-components";
import type { ReportType } from "@js/apps/give-and-get-help/components";

import styles from "./styles.module.scss";

type ReportBodyProps = {
  children: React.ReactNode;
  name: string;
  reportType: ReportType;
};

export const ReportBody = ({ children, name, reportType }: ReportBodyProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <ReportTypeHeader reportType={reportType} />
      <ReportTitle reportType={reportType} name={name} />
      <ReportSubtitle reportType={reportType} name={name} />
      {children}
    </Box>
  );
};

type ReportTypeHeaderProps = {
  reportType: ReportType;
};

const ReportTypeHeader = ({ reportType }: ReportTypeHeaderProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={3}
    >
      <Typography component="p" size="large" fontWeight={500}>
        Report {reportType}
      </Typography>
    </Box>
  );
};

type ReportTitleProps = {
  name: string;
  reportType: ReportType;
};

const ReportTitle = ({ reportType, name }: ReportTitleProps) => {
  const isACommentOrPostReport =
    reportType === "comment" || reportType === "post";

  if (isACommentOrPostReport) {
    return (
      <Typography variant="title" size="medium" component="p" mb={4}>
        Why are you reporting this {reportType}?
      </Typography>
    );
  }

  return (
    <Box
      display="flex"
      className={styles.reportBodyDesc}
      alignItems="center"
      justifyContent="space-between"
      flexDirection="column"
      mb={3}
    >
      <Typography component="p" fontWeight={500} variant="title" mb={3}>
        Request help from Braintrust to resolve any issues or disputes with{" "}
        {name}.
      </Typography>
      <Typography
        component="p"
        size="large"
        fontWeight={400}
        className={styles.reportBodyInfo}
      >
        <span className={styles.reportBodyIcon}>🔎</span>A moderator from
        Braintrust will review and get back to you within 72 hours.
      </Typography>
    </Box>
  );
};

type ReportSubtitleProps = {
  name: string;
  reportType: ReportType;
};

const ReportSubtitle = ({ reportType, name }: ReportSubtitleProps) => {
  const shouldRender = reportType !== "post" && reportType !== "comment";

  if (!shouldRender) return null;

  return (
    <Typography
      component="p"
      fontWeight={400}
      mb={1}
      size="large"
      fontSize={26}
    >
      Why are you reporting {name}?
    </Typography>
  );
};
