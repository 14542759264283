import { useMemo } from "react";

import type { ListItemProps } from "@hexocean/braintrust-ui-components";
import {
  List,
  ListItem,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ErrorIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { EmployerInvoiceMinimal } from "@js/types/invoices";
import { formatCurrency } from "@js/utils";

import styles from "./style.module.scss";

type PaymentModalInvoicesListProps = {
  error?: Record<string, string[]>;
  invoices: EmployerInvoiceMinimal[];
};

export const PaymentModalInvoicesList = ({
  error,
  invoices,
}: PaymentModalInvoicesListProps) => {
  return (
    <List
      sx={{
        minHeight: "300px",
        maxHeight: "calc(90vh - 450px)",
        py: 0,
        overflow: "auto",
      }}
    >
      <StyledListItem divider sx={{ mb: 1, pt: 0 }}>
        <Typography
          component="span"
          variant="paragraph"
          size="medium"
          color="grey-1"
        >
          Invoice number
        </Typography>
        <Typography
          component="span"
          variant="paragraph"
          size="medium"
          color="grey-1"
        >
          Amount
        </Typography>
      </StyledListItem>
      {invoices.map((invoice, index) => {
        const isLast = index === invoices.length - 1;
        return (
          <ConfirmationInvoiceItem
            key={invoice.id}
            error={error}
            invoice={invoice}
            isLast={isLast}
          />
        );
      })}
    </List>
  );
};

type StyledListItemProps = ListItemProps & { divider?: boolean };

const StyledListItem = ({
  children,
  divider,
  ...props
}: StyledListItemProps) => {
  return (
    <ListItem
      {...props}
      sx={{
        px: 0,
        py: 1,
        justifyContent: "space-between",
        borderBottom: divider ? "1px solid var(--medium-beige)" : undefined,
        gap: 1,
        ...props.sx,
      }}
    >
      {children}
    </ListItem>
  );
};

type ConfirmationInvoiceItemProps = {
  error?: Record<string, string[]>;
  invoice: EmployerInvoiceMinimal;
  isLast: boolean;
};

const ConfirmationInvoiceItem = ({
  error,
  invoice,
  isLast,
}: ConfirmationInvoiceItemProps) => {
  const isError = useMemo(() => {
    return (
      !!error &&
      !!error.invoices &&
      error.invoices.map((id) => parseInt(id)).includes(invoice.id)
    );
  }, [error, invoice.id]);

  return (
    <StyledListItem divider={!isLast}>
      <Stack>
        <Typography component="span" variant="label" size="medium">
          {isError ? <ErrorIcon className={styles.errorIcon} /> : null}
          {invoice.payee_name}
        </Typography>
        <Typography component="span" variant="paragraph" size="medium">
          {invoice.number}
        </Typography>
      </Stack>

      <Typography
        component="span"
        variant="paragraph"
        size="medium"
        sx={{ whiteSpace: "nowrap" }}
      >
        {formatCurrency(invoice.gross_total)}
      </Typography>
    </StyledListItem>
  );
};
