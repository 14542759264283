import { useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Fields } from "redux-form";

import type { PopoverActions } from "@hexocean/braintrust-ui-components";
import { SkillIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { RoleFormType } from "@js/apps/common/components/filters/forms/role-form";
import {
  SkillsForm,
  type SkillsParams,
} from "@js/apps/common/components/filters/forms/skills-form";
import { SKILLS_FILTER_SCHEMA } from "@js/apps/common/components/filters/schemas/skills-filter-schema";
import { useHandleFilterApplied } from "@js/apps/freelancer/hooks/use-handle-filter-applied";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components/job-popover-filter-button";
import { useGetSkillsQuery } from "@js/apps/skills/api";
import { PossibleFilters } from "@js/types/common";
import { pluralize } from "@js/utils";

import { SkillsFilterContent } from "./skills-filter-content";

type SkillsFilterProps = {
  defaultRole?: RoleFormType["role"];
};

const formId = "filters__skills";
const skillsQueryParamKey: keyof SkillsParams = "skills";

export const SkillsFilter = ({ defaultRole }: SkillsFilterProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const skillsQueryParam = searchParams.get(skillsQueryParamKey);
  const skillsValue = useMemo(() => {
    return SKILLS_FILTER_SCHEMA.parse(skillsQueryParam);
  }, [skillsQueryParam]);

  const { data } = useGetSkillsQuery(
    { ids: [skillsValue[0]] },
    { skip: !skillsValue[0] },
  );
  const firstSkillName = data?.[0]?.name;
  const popoverActionRef = useRef<PopoverActions>(null);
  const { handleFilterApplied } = useHandleFilterApplied();
  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    handleFilterApplied({
      filter_type: PossibleFilters.SKILLS,
      filter_value: values,
    });
  };

  const label = useMemo(() => {
    if (!firstSkillName || !skillsValue.length) return "Skills";
    if (skillsValue.length === 1) return firstSkillName;
    return (
      firstSkillName +
      ` & ${skillsValue.length - 1} other${pluralize(skillsValue.length - 1)}`
    );
  }, [firstSkillName, skillsValue.length]);

  const handleNewSkills = () => {
    if (!popoverActionRef.current) return;
    popoverActionRef.current.updatePosition();
  };

  return (
    <JobPopoverFilterButton
      action={popoverActionRef}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      PaperProps={{ sx: { width: "520px" } }}
      isActive={!!skillsValue.length}
      label={label}
      startIcon={<SkillIcon />}
      popoverContent={
        <SkillsForm
          form={formId}
          onSubmitSuccess={() => {
            setIsOpen(false);
          }}
          onSubmitSideAction={handleSubmitSideAction}
        >
          <Fields
            names={["role", "skills"]}
            component={SkillsFilterContent}
            defaultRole={defaultRole}
            onSkillsLoaded={handleNewSkills}
          />
        </SkillsForm>
      }
    />
  );
};
