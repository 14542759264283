import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";

type JobInterviewLoaderProps = {
  size?: number;
  subtext?: string;
  text?: string;
};

export const JobInterviewLoader = ({
  size = 50,
  subtext,
  text,
}: JobInterviewLoaderProps) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
      <Loader size={size} />
      <Typography component="span" size="large" fontWeight={500} mt={1}>
        {text}
      </Typography>
      <Typography component="span" size="medium" fontWeight={400}>
        {subtext}
      </Typography>
    </Box>
  );
};
