import { API } from "@js/api";
import type { Skill } from "@js/types/admin";
import type { PaginatedResult } from "@js/types/generic";
import { getUseQueryHookWithDefaultOptions } from "@js/utils/store";

export type GetPaginatedSkillsQueryParams = {
  page?: number;
  ordering?: string;
};

export type GetSkillsQueryParams = {
  ids?: number[];
  ordering?: "name";
  search?: string;
  limit?: number;
  status?: "any";
} | void;

const skillsAPI = API.injectEndpoints({
  endpoints: (build) => ({
    getPopularSkills: build.query<Skill[], { roleIds?: number[] } | void>({
      query: (params) => {
        return {
          url: "/popular_skills/",
          method: "GET",
          params: { role: params?.roleIds?.join(",") },
        };
      },
      keepUnusedDataFor: 10000000,
    }),
    getSkills: build.query<Skill[], GetSkillsQueryParams>({
      query: (params) => ({
        url: "/skills/",
        method: "GET",
        params: { ...params, ids: params?.ids?.join(",") },
      }),
      keepUnusedDataFor: 10000000,
    }),
    getSkill: build.query<Skill, { id: number }>({
      query: ({ id }) => ({
        url: `/skills/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 10000000,
    }),
    getPaginatedSkills: build.query<
      PaginatedResult<Skill>,
      GetPaginatedSkillsQueryParams
    >({
      query: (params) => ({
        url: "/skills/",
        method: "GET",
        params: { ...params, pagination: true },
      }),
      keepUnusedDataFor: 10000000,
    }),
  }),
});

export const { useLazyGetSkillsQuery } = skillsAPI;

export const useGetSkillsQuery = getUseQueryHookWithDefaultOptions(
  skillsAPI.useGetSkillsQuery,
  { refetchOnMountOrArgChange: false },
);

export const useGetPopularSkillsQuery = getUseQueryHookWithDefaultOptions(
  skillsAPI.useGetPopularSkillsQuery,
  { refetchOnMountOrArgChange: false },
);

export const useGetSkillQuery = getUseQueryHookWithDefaultOptions(
  skillsAPI.useGetSkillQuery,
  { refetchOnMountOrArgChange: false },
);
