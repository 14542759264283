import type { ReactNode } from "react";

import { Stack, Typography } from "@hexocean/braintrust-ui-components";

type DetailsContentContainerProps = { children: ReactNode };

export const DetailsContentContainer = ({
  children,
}: DetailsContentContainerProps) => {
  return (
    <Stack direction="row" gap={0.5} divider={<Separator />}>
      {children}
    </Stack>
  );
};

const Separator = () => {
  return (
    <Typography
      component="span"
      variant="paragraph"
      size="small"
      color="tertiary"
    >
      ·
    </Typography>
  );
};
