import type { ResultsCountDataType } from "@js/apps/common/components/filters/components";
import { pluralize } from "@js/utils";

export const getLabelForResultsCount = (
  dataType: ResultsCountDataType,
  total: number,
) => {
  if (!dataType) {
    return "result" + pluralize(total, { zeroPlural: true });
  }

  const labelsByType = {
    JOBS: "job" + pluralize(total, { zeroPlural: true }),
    TALENT: total === 1 ? "person" : "people",
    BIDS: "bid" + pluralize(total, { zeroPlural: true }),
  };

  return labelsByType[dataType];
};
