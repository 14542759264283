import { lazyWithRetry } from "@js/utils/lazy-with-retry";

export const EmployerMyTalentComponentLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "employer-views-my-talent" */ "@js/apps/employer/components/employer-my-talent"
  );

  return { default: module.EmployerMyTalentComponent };
});

export const EmployerDashboardJobsComponentLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "employer-views" */ "@js/apps/employer/views/dashboard/my-jobs/jobs"
  );
  return { default: module.EmployerDashboardJobsComponent };
});

export const EmployerDashboardWelcomeLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "employer-views" */ "@js/apps/employer/components/employer-dashboard-welcome"
  );
  return { default: module.EmployerDashboardWelcome };
});

export const ATSIntegrationViewLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "employer-views-edit-profile" */ "@js/apps/employer/components/ats-integration-view"
  );
  return { default: module.AtsIntegrationView };
});

export const EmployerTeamMembersLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "employer-views-edit-profile" */ "@js/apps/employer/components/team-members"
  );
  return { default: module.EmployerTeamMembers };
});

export const EmployerPersonalInfoViewLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "employer-views-edit-profile" */ "@js/apps/employer/components/employer-personal-info-view"
  );
  return { default: module.EmployerPersonalInfoView };
});

export const EmployerOrganizationInfoViewLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "employer-views-edit-profile" */ "@js/apps/employer/components/employer-organization-info-view"
  );
  return { default: module.EmployerOrganizationInfoView };
});

export const EmployerInvoiceBatchesViewLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "employer-views-invoices" */ "@js/apps/employer/components/employer-invoices/employer-invoice-batches-view"
  );

  return { default: module.EmployerInvoiceBatchesView };
});

export const EmployerInvoicesTableViewLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "employer-views-invoices" */ "@js/apps/employer/components/employer-invoices/employer-invoices-table-view"
  );

  return { default: module.EmployerInvoicesTableView };
});
