import type { TypedWrappedFieldProps } from "redux-form";

import { Button } from "@hexocean/braintrust-ui-components";
import { AddCircleOutlineIcon } from "@hexocean/braintrust-ui-components/Icons";

type AddInterviewQuestionButtonProps = TypedWrappedFieldProps & {
  onAddQuestion: () => void;
};

export const AddInterviewQuestionButton = ({
  onAddQuestion,
}: AddInterviewQuestionButtonProps) => {
  return (
    <Button
      variant="transparent-grey"
      onClick={onAddQuestion}
      shape="squared"
      startIcon={<AddCircleOutlineIcon />}
      sx={{
        border: "1px dashed #A5A5A5",
        justifyContent: "start !important",
      }}
    >
      Add a question
    </Button>
  );
};
