import { styled } from "@hexocean/braintrust-ui-components";

export const StyledPopoverForm = styled("div")(() => ({
  "& .MuiFormControl-root": {
    width: "100%",
    display: "flex",
    minWidth: "180px",
    "& .MuiFormControlLabel-label": {
      width: "100%",
      marginLeft: "8px",
    },
    "& .MuiFormControlLabel-root": {
      padding: "12px",
      margin: 0,
      borderRadius: "6px",
      boxShadow:
        "0 2px 4px rgba(20, 20, 20, 0.04), 0 4px 16px rgba(20, 20, 20, 0.04)",
    },
  },
}));
