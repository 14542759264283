import { SubmissionError } from "redux-form";
import axios from "axios";

import type { FilterLocation } from "@js/apps/filters/types";
import { Snackbar } from "@js/components/snackbar";
import type { AppThunkAction } from "@js/store";
import type { SavedJobFilter } from "@js/types/jobs";

import * as actionTypes from "./action-types";
import type { SavedFiltersEndpoint } from "./const";

export const fetchSavedFilters =
  (endpoint: SavedFiltersEndpoint): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve) =>
      axios
        .get(`/api/${endpoint}/`)
        .then((response) => {
          dispatch({
            type:
              endpoint === "job_filters"
                ? actionTypes.FETCH_SAVED_FILTERS_SUCCESS_JOB
                : actionTypes.FETCH_SAVED_FILTERS_SUCCESS_TALENT,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() => {
          Snackbar.error("Failed to fetch saved filters");
        }),
    );

type FilterValues = {
  filters: Record<string, any>;
  name: string;
  new_job_notification_enabled?: boolean;
};

export const saveFilters =
  (
    values: FilterValues,
    endpoint: SavedFiltersEndpoint,
  ): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const filters = values.filters;
      const role = filters.role && filters.role.join(",");
      const skills = filters.skills && filters.skills.join(",");

      axios
        .post(`/api/${endpoint}/`, {
          ...values,
          filters: { ...filters, role: role, skills: skills },
        })
        .then((response) => {
          dispatch({
            type:
              endpoint === "job_filters"
                ? actionTypes.SAVE_FILTERS_SUCCESS_JOB
                : actionTypes.SAVE_FILTERS_SUCCESS_TALENT,
            payload: {
              data: response.data,
            },
          });

          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data)));
    });

export const updateNewJobNotification =
  (
    filterId: number,
    enableNotifications: boolean,
  ): AppThunkAction<Promise<SavedJobFilter>> =>
  (dispatch) => {
    return new Promise<SavedJobFilter>((resolve, reject) =>
      axios
        .patch(`/api/job_filters/${filterId}/`, {
          new_job_notification_enabled: enableNotifications,
        })
        .then((response) => {
          resolve(response.data);
          dispatch({
            type: actionTypes.UPDATE_SAVED_FILTER,
            payload: response.data,
          });
        })
        .catch((error) => reject(new SubmissionError(error.response.data))),
    );
  };

export const deleteSavedFilters =
  (endpoint: SavedFiltersEndpoint, id: number): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: actionTypes.DELETE_SAVED_FILTERS,
        payload: id,
      });
      return axios
        .delete(`/api/${endpoint}/${id}/`)
        .then(() => {
          dispatch({
            type: actionTypes.DELETE_SAVED_FILTERS_SUCCESS,
            payload: id,
          });

          resolve(id);
        })
        .catch((error) => Snackbar.error(error.response.data));
    });

export const setSavedFiltersName = ({
  location,
  name,
}: {
  location?: FilterLocation;
  name: string | null;
}) => ({
  type: actionTypes.SET_SAVED_FILTER_NAME,
  payload: {
    location,
    name,
  },
});
