import { useEffect } from "react";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";

import {
  Alert,
  Loader,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { showAlert } from "@hexocean/braintrust-ui-components/utils";
import { ACCESS_DENIED } from "@js/apps/common/constants";
import { JobEmployerDetails } from "@js/apps/employer/components";
import { ExperienceBox } from "@js/apps/jobs/components/experience-box";
import { RoleAndJobTypeBox } from "@js/apps/jobs/components/role-and-job-type-box";
import { JOB_LOCATION, JobLocationContext } from "@js/apps/jobs/context";
import { ExplainerCarousel } from "@js/apps/jobs/views/public-job-details/explainer-carousel";
import { AttachmentList } from "@js/components/attachment-list";
import { PageTitle } from "@js/components/page-title";
import { PublicTopNavigation } from "@js/components/public-top-navigation";
import { useAppDispatch, useNavigate } from "@js/hooks";
import { globalTimeoutsDelays, useTimeout } from "@js/hooks/timeout";
import { AppLayout } from "@js/layouts/app";

import { viewJobDetails } from "../../actions";
import { PublicJobsSeeAllJobsButton } from "../../apps/public-listing/components";
import { JobDetailsBasicDetails, JobHeader } from "../../components";
import { JobDescription } from "../../components/job-description";
import { PublicSimilarJobsSection } from "../../components/public-similar-jobs-carousel";
import { usePublicJobDetails } from "../../hooks/use-public-job-details";

import {
  PublicJobStatus,
  PublicRateComponent,
  SocialProofModule,
} from "./components";

type PublicJobDetailsProps = { jobId: number };

export const PublicJobDetails = ({ jobId }: PublicJobDetailsProps) => {
  const md = useMediaQuery("md");
  const dispatch = useAppDispatch();
  const {
    loading,
    job,
    employerProfile: employer,
    error,
  } = usePublicJobDetails({ jobId });
  const navigate = useNavigate();

  useTimeout(
    () => {
      if (!jobId) {
        return;
      }
      dispatch(
        viewJobDetails({
          source: JOB_LOCATION.public_job_details,
          jobUrl: window.location.href,
          jobId,
        }),
      );
    },
    globalTimeoutsDelays.log_view_event,
    [dispatch, jobId],
  );

  useEffect(() => {
    if (error) {
      const status = (error as FetchBaseQueryError)?.status;

      if (status === 401) {
        navigate(`/access-denied/?message=${ACCESS_DENIED.JOB_ACCESS_DENIED}`, {
          replace: true,
        });
        return;
      }

      navigate(`/jobs/`);
    }
  }, [error, navigate]);

  return (
    <JobLocationContext.Provider value={JobLocationContext.Values.job_details}>
      <AppLayout
        className="wider job-details-page"
        bgcolor="var(--soft-blue)"
        renderOverwriteNavigationComponent={() => <PublicTopNavigation />}
      >
        {loading ? (
          <Loader />
        ) : (
          job && (
            <>
              <PageTitle>{`Job: ${job.title}`}</PageTitle>
              {!!job.referring_user &&
                showAlert(
                  <Alert type="info" withIcon>
                    <Typography
                      component="p"
                      variant="paragraph"
                      size="medium"
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      <Typography component="span" fontWeight={500}>
                        {job.referring_user}
                      </Typography>{" "}
                      referred you for this job, please review and place an
                      application if you think you’d be a good fit!
                    </Typography>
                  </Alert>,
                )}
              <main>
                <PublicJobsSeeAllJobsButton />
                <JobHeader
                  job={job}
                  className="job-details-page__top-section"
                  withDetailsHeader
                />
                <section className="job-details-page__details-public">
                  <div className="job-details-page__basic-details">
                    <JobDetailsBasicDetails
                      boxProps={{
                        className: "job-details-page__basic-details-wrapper",
                      }}
                      job={job}
                    />
                  </div>
                  <div className="job-details-page__top-skills">
                    <RoleAndJobTypeBox
                      jobRole={job.role}
                      jobType={job.job_type}
                    />
                  </div>
                  <div className="job-details-page__seniority">
                    <ExperienceBox
                      experienceAsArrow={job.experience_as_arrow}
                      experienceLevel={job.experience_level}
                      jobRole={job.role}
                    />
                  </div>
                  <div className="job-details-page__rate">
                    <PublicRateComponent job={job} />
                  </div>
                  <div className="job-details-page__status-box--public">
                    <PublicJobStatus
                      job={job}
                      className="job-details-page__status--public"
                    />
                  </div>
                  {md && (
                    <div className="job-details-page__explainer-carousel">
                      <ExplainerCarousel job={job} />
                    </div>
                  )}
                </section>
                <section className="job-details-page__content-public">
                  <section style={{ overflow: "hidden" }}>
                    <JobDescription job={job} />
                    {!!job.attachments.length && (
                      <RoundedBox mt={3}>
                        <AttachmentList attachments={job.attachments} />
                      </RoundedBox>
                    )}
                    <SocialProofModule
                      jobId={job.id}
                      jobStatus={job.job_status}
                    />
                  </section>
                  <section className="job-details-page__employer-column">
                    {!md && <ExplainerCarousel job={job} />}
                    <div className="job-details-page__employer">
                      {!employer ? (
                        <Loader />
                      ) : (
                        <RoundedBox>
                          <JobEmployerDetails
                            employer={employer}
                            jobId={job.id}
                            hideOpenJobs
                          />
                        </RoundedBox>
                      )}
                    </div>
                  </section>
                </section>
                <section className="job-details-page__public-similar-jobs">
                  <PublicSimilarJobsSection jobId={job.id} />
                </section>
              </main>
            </>
          )
        )}
      </AppLayout>
    </JobLocationContext.Provider>
  );
};
