import { useCallback, useMemo } from "react";

import type { PurchaseUpgradeParams } from "@js/apps/auth/api";
import type { ModalInstance as ModalInstanceComponent } from "@js/components/modal";

import {
  BOOST_BTRST_COST,
  INSIGHT_BTRST_COST,
} from "../../application-onramp-modal/constants";
import { openProductCheckoutModal } from "../../product-checkout-modal";

type UseProductDescriptionModalParams = {
  openProductDescriptionModal: (param: {
    upgradeType: PurchaseUpgradeParams["upgrade_type"];
  }) => void;
  upgradeType: PurchaseUpgradeParams["upgrade_type"];
};

export const useProductDescriptionModal = (
  {
    upgradeType,
    openProductDescriptionModal,
  }: UseProductDescriptionModalParams,
  ModalInstance: typeof ModalInstanceComponent,
) => {
  const buttonProceedLabel = useMemo(
    () =>
      `Purchase Application ${upgradeType}${upgradeType === "boost" ? "s" : ""}`,
    [upgradeType],
  );

  const onClickCheckoutProduct = useCallback(() => {
    ModalInstance.close();
    openProductCheckoutModal({
      upgradeType,
      onClickBack: () => openProductDescriptionModal({ upgradeType }),
    });
  }, [ModalInstance, openProductDescriptionModal, upgradeType]);

  const productCost = useMemo(() => {
    switch (upgradeType) {
      case "boost":
        return BOOST_BTRST_COST;

      case "insights":
        return INSIGHT_BTRST_COST;

      default:
        return 0;
    }
  }, [upgradeType]);

  const productPackage = useMemo(() => {
    switch (upgradeType) {
      case "boost":
        return "3 Boost credits";

      case "insights":
        return "30 days of insights";

      default:
        return "";
    }
  }, [upgradeType]);

  return {
    onClickCheckoutProduct,
    buttonProceedLabel,
    productCost,
    productPackage,
  };
};
