import { SubmissionError } from "redux-form";

import { useSavePasswordMutation } from "../../api";
import {
  DeleteAccount,
  OTPAuthSection,
  SessionsSection,
  VisibilitySection,
} from "../../components";
import { PasswordForm, type PasswordFormData } from "../../forms/password";
import { SettingsLayout } from "../layout";

import { handleRedirectToLoginPage } from "./helpers";

type Error = { data: Record<string, string> };

const PrivacySecurityPage = () => {
  const [savePassword] = useSavePasswordMutation();

  const handlePasswordChangeOnSubmit = async (values: PasswordFormData) => {
    try {
      await savePassword(values)
        .unwrap()
        .then(() => {
          handleRedirectToLoginPage();
        });
    } catch (err) {
      throw new SubmissionError((err as Error).data);
    }
  };

  return (
    <SettingsLayout pageTitle="Security">
      <PasswordForm onSubmit={handlePasswordChangeOnSubmit} />
      <OTPAuthSection />
      <VisibilitySection />
      <DeleteAccount />
      <SessionsSection />
    </SettingsLayout>
  );
};

export default PrivacySecurityPage;
