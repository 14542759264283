import React from "react";

import type { Job } from "@js/types/jobs";

import { JobActionMenu } from "../../components/job-action-menu";
import { WithJobActionsContext } from "../../components/job-actions";
import { useGetDefaultActionsContextValue } from "../../hooks/get-default-job-actions";

type JobActionsMenuProps = {
  job: Job;
  color?: string;
};

export const JobActionsMenu = ({ job, color }: JobActionsMenuProps) => {
  const jobActionsContextValue = useGetDefaultActionsContextValue();

  return (
    <WithJobActionsContext.Provider value={jobActionsContextValue}>
      <JobActionMenu job={job} iconColor={color} />
    </WithJobActionsContext.Provider>
  );
};
