import React from "react";

import type { SearchEventOption } from "@js/types/tracking";

const SearchFilterLocationContextInstance = React.createContext<
  SearchEventOption | undefined
>(undefined);

export const SearchFilterLocationContext = {
  Values: ENUMS.SearchEventOption,
  Provider: SearchFilterLocationContextInstance.Provider,
  Consumer: SearchFilterLocationContextInstance.Consumer,
};

export const useSearchFilterLocationContext = () => {
  const location = React.useContext(SearchFilterLocationContextInstance);

  return location;
};
