import { useMemo, useState } from "react";

import {
  postsBookmarksApi,
  useGetSavedPostsQuery,
} from "@js/apps/give-and-get-help/api";
import { useAppDispatch } from "@js/hooks";

const PAGE_SIZE = 10;
const INITIAL_PAGE = 1;

export const usePostsBookmarkPage = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
  const { data, isLoading } = useGetSavedPostsQuery(
    { page: currentPage },
    { refetchOnMountOrArgChange: false },
  );

  const handleFetchingMore = () => {
    const currentCount = data?.results?.length ?? 0;
    const totalCount = data?.count ?? 0;

    if (!data || currentCount >= totalCount) {
      return;
    }

    const nextPage = Math.floor(data.results.length / PAGE_SIZE) + 1;

    if (nextPage === currentPage) {
      dispatch(
        postsBookmarksApi.endpoints.getSavedPosts.initiate(
          { page: nextPage },
          { forceRefetch: true, subscribe: false },
        ),
      );
    }
    setCurrentPage(nextPage);
  };

  const posts = useMemo(() => data?.results ?? [], [data]);

  return {
    posts,
    hasMore: !!data?.next,
    isLoading,
    postsCount: data?.count || 0,
    handleFetchingMore,
  };
};
