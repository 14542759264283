import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import { useGetRoomListQuery, useGetRoomQuery } from "@js/apps/messenger/api";

export const useActiveRoomData = (roomId?: number) => {
  const { data: roomListResponse, isLoading: isLoadingRooms } =
    useGetRoomListQuery();

  const activeRoomInRooms = useMemo(() => {
    return (
      !!roomId && roomListResponse?.results.find((room) => room.id === roomId)
    );
  }, [roomId, roomListResponse]);

  const shouldUseSingleRoomData =
    !!roomId && !activeRoomInRooms && !isLoadingRooms;

  const { currentData: roomResponse, isLoading } = useGetRoomQuery(
    roomId && shouldUseSingleRoomData ? { id: roomId } : skipToken,
  );

  return {
    activeRoomData: activeRoomInRooms || roomResponse,
    isLoading,
  };
};
