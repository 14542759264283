import { useEffect, useRef } from "react";

import { useUser } from "@js/apps/common/hooks";
import { Snackbar } from "@js/components/snackbar";
import { WebSocketManagerContainer } from "@js/components/websocket";
import { useAppDispatch } from "@js/hooks";
import type { IPost } from "@js/types/give-and-get-help";

import { postsApi } from "../../api";
import { isPostRelatedEvent } from "../../utils/update-recipes";
import { useFixedDeletePostMutation } from "../use-fixed-delete-post-mutation";

const DELETE_SINGLE_POST_LISTENER_ID = "delete-single-post-listener";

export const useHandleDeleteSinglePost = (post: IPost | undefined) => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const postId = post?.id;
  const [, { isUninitialized }] = useFixedDeletePostMutation({
    postId,
  });

  // Only react to delete post events if they were not triggered by the current user
  const isDeletedByCurrentUserRef = useRef(false);
  if (!isUninitialized) {
    isDeletedByCurrentUserRef.current = true;
  }

  useEffect(() => {
    if (!user || !postId) {
      return;
    }

    let wasDeleted = false;
    const listener = (event: MessageEvent) => {
      if (!isCurrentPostDeleted(event) || isDeletedByCurrentUserRef.current) {
        return;
      }

      wasDeleted = true;
      Snackbar.error("Post got deleted.");
    };

    WebSocketManagerContainer.attachListener(
      listener,
      DELETE_SINGLE_POST_LISTENER_ID,
    );

    return () => {
      WebSocketManagerContainer.removeListener(DELETE_SINGLE_POST_LISTENER_ID);
      if (wasDeleted && !isDeletedByCurrentUserRef.current) {
        dispatch(postsApi.util.invalidateTags([{ type: "Posts", id: postId }]));
      }
    };
  }, [dispatch, postId, user]);
};

const isCurrentPostDeleted = (event: MessageEvent): boolean => {
  return (
    !!event.data &&
    isPostRelatedEvent(event) &&
    event.data.broadcast_type === ENUMS.BroadcastType.POST_DELETED
  );
};
