import React from "react";

import { ReferralsStatsLoader } from "../../components";
import { EmployerReferrals } from "../../components/employer-referrals";
import { useEmployerReferrals } from "../../hooks";

export const DashboardReferClientsPage = () => {
  const {
    isLoadingSummary,
    isFetchingReferrals,
    referrals,
    summary,
    fetchMore,
    filters,
  } = useEmployerReferrals();

  if (!summary || isLoadingSummary) {
    return <ReferralsStatsLoader />;
  }

  return (
    <EmployerReferrals
      referrals={referrals}
      loading={isFetchingReferrals}
      summary={summary}
      fetchMore={fetchMore}
      filters={filters}
    />
  );
};
