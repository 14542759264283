import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { SpeechIcon } from "@hexocean/braintrust-ui-components/Icons";

import { EtiquetteRules } from "../etiquette-rules";

type EtiquetteReminderProps = {
  onContinue: () => void;
  onBack: () => void;
};

export const EtiquetteReminder = ({
  onContinue,
  onBack,
}: EtiquetteReminderProps) => {
  return (
    <Box width={{ md: 750, xs: "calc(100vw - 32px)" }}>
      <Box py={3} mb={3} bgcolor="var(--soft-green)">
        <Typography component="p" variant="label" size="large" align="center">
          ✍️ Career Help Etiquette
        </Typography>
      </Box>

      <Box paddingX={3} mb={2}>
        <Typography
          component="p"
          variant="paragraph"
          size="large"
          fontWeight={400}
          mb={3.5}
        >
          <SpeechIcon
            viewBox="0 0 18 18"
            sx={{
              width: 20,
              height: 20,
              position: "relative",
              right: 2,
              top: 3,
            }}
          />
          Career Help is a space for the community to help one another advance
          in their careers..
        </Typography>
        <EtiquetteRules />
        <Typography component="p" variant="paragraph" size="small">
          Please email{" "}
          <a
            href={`mailto:${SETTINGS.SUPPORT_EMAIL}`}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline" }}
          >
            {SETTINGS.SUPPORT_EMAIL}
          </a>{" "}
          for all Braintrust specific support questions or view our{" "}
          <a
            href={SETTINGS.SUPPORT_URLS.FEED_COMMUNITY_GUIDELINES_ARTICLE_URL}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline" }}
          >
            full community guidelines.
          </a>
        </Typography>
      </Box>

      <Box display="flex" gap={3} px={3} mb={4.5} justifyContent="flex-end">
        <Button variant="no-padding" onClick={onBack}>
          Go back
        </Button>
        <Button variant="positive-2" shape="squared" onClick={onContinue}>
          Continue
        </Button>
      </Box>
    </Box>
  );
};
