import type { ConfigProps, InjectedFormProps } from "redux-form";
import { reduxForm } from "redux-form";

import type { FormProps } from "../form";
import { Form } from "../form";

type DefaultProps<V, P> = {
  preventSubmitOnEnter?: FormProps["preventSubmitOnEnter"];
  hideError?: boolean;
  children?:
    | React.ReactNode
    | ((props: InjectedFormProps<V>) => React.ReactNode);
  className?: string;
  DEPRECATED_p: P; // temporary, just to satisfy TS
};

// P will be deleted
export const createFormInstance = <V, P>(
  id: string,
  config?: Omit<ConfigProps<V>, "form">,
) => {
  return reduxForm<V, Omit<DefaultProps<V, P>, "DEPRECATED_p">>({
    form: id,
    ...config,
  })(
    ({
      error,
      className,
      form,
      submit,
      submitting,
      children,
      hideError = false,
      preventSubmitOnEnter = false,
      ...props
    }) => {
      return (
        <Form
          id={form}
          preventSubmitOnEnter={preventSubmitOnEnter}
          onSubmit={submitting ? undefined : submit}
          error={hideError ? null : error}
          className={className}
          noValidate
        >
          {typeof children === "function"
            ? children({
                submit,
                submitting,
                ...props,
              } as InjectedFormProps<V>)
            : children}
        </Form>
      );
    },
  );
};
