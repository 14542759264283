import { useUser } from "@js/apps/common/hooks/use-user";
import { canFreelancerUseJobRelatedFeatures } from "@js/routes/utils";

export const useFreelancerMyWorkMainPath = () => {
  const user = useUser();
  const canUseFeaturesRelatedToJob = canFreelancerUseJobRelatedFeatures(user);

  return {
    myWorkMainPath: `/talent/dashboard/my_jobs${canUseFeaturesRelatedToJob ? "/history/" : "/invites/"}`,
  };
};
