import { useEffect } from "react";

import { useAppDispatch } from "@js/hooks";
import type { Reaction } from "@js/types/give-and-get-help";

import {
  updateReactions,
  useReactionsDrawerState,
} from "../store/stickers-module-slice";

export const useUpdateDrawerState = ({
  entityId,
  reactions,
}: {
  entityId: number;
  reactions: Reaction[];
}) => {
  const reactionsDrawerState = useReactionsDrawerState();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { isOpen, data } = reactionsDrawerState;
    if (!isOpen) {
      return;
    }
    const { entityId: selectedEntityId, entityReactions } = data;
    if (selectedEntityId !== entityId) {
      return;
    }

    if (entityReactions === reactions) {
      return;
    }

    dispatch(updateReactions(reactions));
  }, [entityId, reactions, reactionsDrawerState, dispatch]);
};
