import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "@js/store";
import type { User } from "@js/types/auth";
import type { EmployerBid, EmployerBidListBid } from "@js/types/jobs";

export type SelectionBid = {
  id: EmployerBid["id"];
  selected: boolean;
  freelancerId: User["id"];
  isFreelancerApproved: boolean;
  status: EmployerBid["status"];
  can_make_offer: EmployerBid["can_make_offer"];
  is_hidden: EmployerBid["is_hidden"];
};

type BidBulkSelectionSlice = {
  bids: SelectionBid[];
};

const initialState: BidBulkSelectionSlice = {
  bids: [],
};

const bidBulkSelectionSlice = createSlice({
  name: "bidBulkSelectionSlice",
  initialState,
  reducers: {
    initiateBidSelectionData(
      state,
      action: PayloadAction<EmployerBidListBid[]>,
    ) {
      const jobApplicationBids = action.payload;

      state.bids = jobApplicationBids.map((jobBid) => ({
        id: jobBid.id,
        selected:
          state.bids.find((bid) => bid.id === jobBid.id)?.selected || false,
        freelancerId: jobBid.freelancer.user.id,
        isFreelancerApproved: jobBid.freelancer.approved,
        status: jobBid.status,
        can_make_offer: jobBid.can_make_offer,
        is_hidden: jobBid.is_hidden,
      }));
    },

    selectAllBids(state) {
      state.bids.forEach((bid) => {
        bid.selected = true;
      });
    },

    deselectAllBids(state) {
      state.bids.forEach((bid) => {
        bid.selected = false;
      });
    },

    resetBidsTotal(state) {
      state.bids = [];
    },

    selectBid(state, action: PayloadAction<number>) {
      const id = action.payload;
      const bidToSelect = state.bids.find((bid) => bid.id === id);

      if (!bidToSelect) return;

      bidToSelect.selected = true;
    },

    deselectBid(state, action: PayloadAction<number>) {
      const id = action.payload;
      const bidToSelect = state.bids.find((bid) => bid.id === id);

      if (!bidToSelect) return;

      bidToSelect.selected = false;
    },
  },
});

export const {
  initiateBidSelectionData,
  selectAllBids,
  deselectAllBids,
  resetBidsTotal,
  selectBid,
  deselectBid,
} = bidBulkSelectionSlice.actions;

export const getBidsTotal = (state: RootState) => {
  return state.bidBulkSelection.bids.length;
};

export const getSelectedBidsTotal = (state: RootState) => {
  let selectedBidsTotal = 0;

  const bids = state.bidBulkSelection.bids;

  bids.forEach((bid) => {
    if (bid.selected) selectedBidsTotal++;
  });

  return selectedBidsTotal;
};

export const getHiddenUnselectedBidsTotal = (state: RootState) => {
  return state.bidBulkSelection.bids.filter(
    (bid) => bid.is_hidden && !bid.selected,
  ).length;
};

export const canBeInterviewed = (bid: SelectionBid) =>
  bid.selected && ["new", "viewed"].includes(bid.status);

export const canBeRejected = (bid: SelectionBid) =>
  bid.selected && !["hired", "rejected"].includes(bid.status);

export const getBids = (state: RootState) => {
  return state.bidBulkSelection.bids;
};

export const getIsAnyOfSelectedBidsUnapproved = (state: RootState) => {
  return state.bidBulkSelection.bids.some(
    (el) => el.selected && !el.isFreelancerApproved,
  );
};

export const isBidSelected = (state: RootState, bidId: number) => {
  const bids = state.bidBulkSelection.bids;
  const foundBid = bids.find((bid) => bid.id === bidId);

  if (!foundBid) return false;
  return foundBid.selected;
};

export default bidBulkSelectionSlice.reducer;
