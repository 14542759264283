import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import { useUser } from "@js/apps/common/hooks";
import { useGetEmployerPublicProfileQuery } from "@js/apps/employer/api";
import { useGetFreelancerPublicProfileQuery } from "@js/apps/freelancer/api";
import {
  useGetEmployerReviewsQuery,
  useGetTalentReviewsQuery,
} from "@js/apps/reviews/api";
import { useCombinedQueryState } from "@js/hooks/combined-query-state";
import { useIdParam } from "@js/hooks/use-id-param";
import { assertUnreachable } from "@js/utils";

type UseReviewListProps = {
  reviewSubject: EnumType<typeof ENUMS.AccountType>;
  reviewSubjectId: number;
};

export const useReviewList = ({
  reviewSubject,
  reviewSubjectId,
}: UseReviewListProps) => {
  const freelancerOrEmployerId = useIdParam();

  const { data: freelancerPublicProfile } = useGetFreelancerPublicProfileQuery(
    freelancerOrEmployerId ?? skipToken,
    {
      skip: reviewSubject !== ENUMS.AccountType.FREELANCER,
    },
  );

  const { data: employerPublicProfile } = useGetEmployerPublicProfileQuery(
    freelancerOrEmployerId ?? skipToken,
    {
      skip: reviewSubject !== ENUMS.AccountType.EMPLOYER,
      refetchOnMountOrArgChange: false,
    },
  );

  const profile = useMemo(() => {
    if (reviewSubject === ENUMS.AccountType.EMPLOYER) {
      return employerPublicProfile;
    }

    if (reviewSubject === ENUMS.AccountType.FREELANCER) {
      return freelancerPublicProfile;
    }

    assertUnreachable(reviewSubject);
  }, [employerPublicProfile, freelancerPublicProfile, reviewSubject]);

  const user = useUser();

  const { data: talentReviews, ...talentReviewsQueryState } =
    useGetTalentReviewsQuery(
      reviewSubject === ENUMS.AccountType.FREELANCER && reviewSubjectId
        ? { id: reviewSubjectId, count: 0 }
        : skipToken,
    );

  const { data: employerReviews, ...employerReviewsQueryState } =
    useGetEmployerReviewsQuery(
      reviewSubject === ENUMS.AccountType.EMPLOYER && reviewSubjectId
        ? { id: reviewSubjectId, count: 0 }
        : skipToken,
    );

  const { isLoading } = useCombinedQueryState([
    talentReviewsQueryState,
    employerReviewsQueryState,
  ]);

  const isOwnProfile =
    !!profile &&
    (reviewSubject === ENUMS.AccountType.EMPLOYER
      ? profile.id === user?.employer
      : profile.id === user?.freelancer);

  const reviewList = (employerReviews || talentReviews) ?? [];

  return {
    profile,
    loading: isLoading,
    reviewList,
    isOwnProfile,
  };
};
