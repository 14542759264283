import type { MutableRefObject } from "react";
import { useLayoutEffect, useState } from "react";

import { useGlobalScrollPosition } from "@js/apps/common/hooks";
import { isInViewport } from "@js/utils";

export const useShouldShowCreatePostFAB = (
  showCreatePostFAB: boolean,
  createPostSectionRef: MutableRefObject<HTMLDivElement | null>,
): boolean => {
  const [isVisibleCreatePostFAB, setIsVisibleCreatePostFAB] = useState(false);

  const scrollPosition = useGlobalScrollPosition();

  useLayoutEffect(() => {
    if (!showCreatePostFAB || !createPostSectionRef.current) return;

    setIsVisibleCreatePostFAB(!isInViewport(createPostSectionRef.current));
  }, [createPostSectionRef, showCreatePostFAB, scrollPosition]);

  return isVisibleCreatePostFAB;
};
