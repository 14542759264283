import { useContext, useMemo } from "react";

import { WebSocketContext } from "@js/components/websocket";
import { useAppSelector } from "@js/hooks/redux";

export const useWebsocket = () => {
  const isRunning = useAppSelector((state) => state.websocketManager.isRunning);
  const context = useContext(WebSocketContext);

  if (!context) {
    throw new Error(
      "useWebsocket must be in scope of WebSocketContext.Provider",
    );
  }

  const data = useMemo(() => {
    return {
      ...context,
      isRunning,
    };
  }, [isRunning, context]);

  return data;
};
