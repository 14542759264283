import classNames from "classnames";

import { Checkbox, Typography } from "@hexocean/braintrust-ui-components";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import {
  getBidsTotal,
  getHiddenUnselectedBidsTotal,
  getSelectedBidsTotal,
} from "./bid-bulk-selection-slice";
import { useBidSelectionCheckbox } from "./use-bid-selection-checkbox";

import styles from "./style.module.scss";

export const BidSelectionCheckbox = () => {
  const dispatch = useAppDispatch();

  const bidsTotal = useAppSelector(getBidsTotal);
  const selectedBidsTotal = useAppSelector(getSelectedBidsTotal);
  const hiddenUnselectedBidsTotal = useAppSelector(
    getHiddenUnselectedBidsTotal,
  );

  const allBidsSelected =
    bidsTotal > 0 &&
    bidsTotal - hiddenUnselectedBidsTotal === selectedBidsTotal &&
    selectedBidsTotal > 0;

  const someBidsSelected = !allBidsSelected && selectedBidsTotal > 0;
  const showLabel = selectedBidsTotal > 0;

  const label = (
    <Typography component="p" variant="paragraph" size="medium">
      <Typography component="span" fontWeight={500}>
        {selectedBidsTotal}
      </Typography>{" "}
      of{" "}
      <Typography component="span" fontWeight={500}>
        {bidsTotal}
      </Typography>{" "}
      selected
    </Typography>
  );

  const handleBulkSelection = useBidSelectionCheckbox(allBidsSelected);

  return (
    <Checkbox
      className={classNames(styles.bulkCheckbox, {
        [styles.bulkCheckboxWithLabel]: showLabel,
      })}
      label={showLabel ? label : undefined}
      checked={allBidsSelected}
      indeterminate={someBidsSelected}
      onChange={() => dispatch(handleBulkSelection())}
    />
  );
};
