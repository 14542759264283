import type { FC } from "react";
import OfferVisualBackground from "@static/give-get-help/AcceptOfferVisualBackground.svg";
import cs from "classnames";
import { pick } from "underscore";

import { Avatar } from "@hexocean/braintrust-ui-components";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { ActivityHelpOfferUser } from "@js/apps/dashboard/types";
import { Confetti } from "@js/components/animations";

import style from "./styles.module.scss";

type OfferVisualProps = {
  exchangeFrom: OfferExchangeUserOrCurrency;
  exchangeTo: OfferExchangeUserOrCurrency;
  icon?: React.ReactElement;
  isInverse?: boolean;
  largePill?: boolean;
  success?: boolean;
  wrapperClassName?: string;
};

export const OfferVisual: FC<OfferVisualProps> = (props) => {
  const {
    exchangeFrom,
    exchangeTo,
    icon,
    wrapperClassName,
    isInverse = false,
    largePill = false,
    success = false,
  } = props;
  const backgroundColor = success
    ? "var(--medium-green)"
    : "var(--medium-blue)";

  return (
    <div className={cs(style.offerVisual, wrapperClassName)}>
      <OfferVisualBackground color={backgroundColor} />
      {success && (
        <Confetti
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 1,
            width: "100%",
          }}
        />
      )}
      <div className={style.offerVisualContent}>
        <VisualPill userOrCurrency={exchangeFrom} largePill={largePill} />
        <AgreementIcon icon={icon} isInverse={isInverse} success={success} />
        <VisualPill userOrCurrency={exchangeTo} largePill={largePill} />
      </div>
    </div>
  );
};

type OfferExchangeUserOrCurrency =
  | ActivityHelpOfferUser
  | {
      currency: string;
      has_avatar_set: false;
      gravatar: "";
      avatar_thumbnail: string;
    };

type VisualPillProps = {
  userOrCurrency: OfferExchangeUserOrCurrency;
  icon?: React.ReactElement;
  largePill?: boolean;
};

export const VisualPill: FC<VisualPillProps> = (props) => {
  const { userOrCurrency, largePill = false } = props;

  const isBTRST =
    "currency" in userOrCurrency && userOrCurrency.currency === "BTRST";

  const classes = cs(style.visualPill, {
    [style.visualPillLarge]: largePill,
  });

  let name;

  if ("first_name" in userOrCurrency) {
    name = userOrCurrency.first_name;
  } else {
    name = userOrCurrency.currency;
  }
  const avatarSrc = {
    ...pick(userOrCurrency, "has_avatar_set", "avatar_thumbnail", "gravatar"),
    first_name: name as string,
    last_name:
      "last_name" in userOrCurrency
        ? (userOrCurrency.last_name as string | null)
        : "",
  };

  return (
    <div className={classes}>
      {isBTRST ? (
        <BraintrustIcon sx={{ width: "32px", height: "32px" }} />
      ) : (
        <Avatar src={avatarSrc} size="sm" />
      )}
      {name}
    </div>
  );
};

export const AgreementIcon: FC<{
  isInverse: boolean;
  icon?: React.ReactElement;
  success?: boolean;
}> = ({ icon = "🤝", isInverse, success }) => {
  const classes = cs(style.visualIcon, {
    [style.visualIconInverse]: isInverse,
    [style.visualIconGreenVariant]: success,
  });
  return (
    <div className={classes}>
      <div className={style.visualIconAgreement}>{icon}</div>
    </div>
  );
};
