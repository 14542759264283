import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Box, Button } from "@hexocean/braintrust-ui-components";
import { getMakeOfferUrl } from "@js/apps/jobs/utils";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { openMakeOfferMessageModal } from "../../make-offer-message";
import { setIndexToScrollTo } from "../../virtualized-job-bid-list/rendered-item-index";

import { CannotMakeOfferExplanation } from "./cannot-make-offer-explanation";

type MakeOfferButtonProps = {
  bid: EmployerBidListBid;
  job: Job;
  children: string;
  buttonProps?: Partial<ButtonProps>;
  index?: number;
};

export const MakeOfferButton = ({
  bid,
  job,
  children,
  buttonProps,
  index,
}: MakeOfferButtonProps) => {
  const makeOfferUrl = getMakeOfferUrl({ jobId: job.id, bidId: bid.id });

  return (
    <CannotMakeOfferExplanation bid={bid}>
      <Box sx={{ display: "flex" }}>
        <Button
          disabled={!bid.can_make_offer}
          onClick={() => {
            if (index) {
              setIndexToScrollTo(index);
            }

            openMakeOfferMessageModal({
              bid,
              nextUrl: makeOfferUrl,
            });
          }}
          variant="primary"
          shape="squared"
          sx={{ flexShrink: 0, height: "42px" }}
          {...buttonProps}
        >
          {children}
        </Button>
      </Box>
    </CannotMakeOfferExplanation>
  );
};
