import type { AnyAction } from "redux";

import {
  FETCH_CURRENT_USER,
  FETCH_CURRENT_USER_FAILED,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_PUBLIC_INVITATION_DETAILS,
  FETCH_PUBLIC_INVITATION_DETAILS_FAILED,
  FETCH_PUBLIC_INVITATION_DETAILS_SUCCESS,
  FETCH_TIMEZONES_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  UPDATE_USER,
} from "@js/apps/auth/action-types";

import type { AuthState } from "../../types/auth";

const INITIAL_STATE: AuthState = {
  refreshed: false,
  user: null,
  fetchingCurrentUser: false,

  forgotPasswordMessage: null,
  resetPasswordMessage: null,

  timezones: [],

  publicInvitationDetails: null,
  fetchPublicInvitationDetails: false,
};

export default function (state = INITIAL_STATE, action: AnyAction): AuthState {
  switch (action.type) {
    case FETCH_CURRENT_USER:
      return { ...state, fetchingCurrentUser: true };
    case FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        fetchingCurrentUser: false,
        refreshed: true,
      };
    case FETCH_CURRENT_USER_FAILED:
      return {
        ...state,
        user: null,
        fetchingCurrentUser: false,
        refreshed: true,
      };

    case FETCH_PUBLIC_INVITATION_DETAILS:
      return { ...state, fetchPublicInvitationDetails: true };
    case FETCH_PUBLIC_INVITATION_DETAILS_SUCCESS:
      return {
        ...state,
        publicInvitationDetails: action.payload,
        fetchPublicInvitationDetails: false,
      };
    case FETCH_PUBLIC_INVITATION_DETAILS_FAILED:
      return {
        ...state,
        publicInvitationDetails: INITIAL_STATE.publicInvitationDetails,
        fetchPublicInvitationDetails: false,
      };

    case UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload } };

    case FORGOT_PASSWORD:
      return { ...state, forgotPasswordMessage: null };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgotPasswordMessage: action.payload };

    case RESET_PASSWORD:
      return { ...state, resetPasswordMessage: null };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordMessage: action.payload };

    case FETCH_TIMEZONES_SUCCESS:
      return { ...state, timezones: action.payload };

    default:
      return state;
  }
}
