import { useSearchParams } from "react-router-dom";
import { Field } from "redux-form";

import { Box } from "@hexocean/braintrust-ui-components";
import type {
  FeedOrdering,
  FeedOrderingType,
} from "@js/apps/common/components/filters";
import {
  defaultFeedOrdering,
  FeedSortForm,
} from "@js/apps/common/components/filters";
import { gghPostSortClicked } from "@js/apps/give-and-get-help/actions";
import { useAppDispatch } from "@js/hooks";

import { FeedSortButton } from "./feed-sort-button";

export const FeedSorting = () => {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();

  const isOrderingApplied = !!params.get("ordering");
  const handleOnClick = (value: FeedOrderingType) => {
    dispatch(gghPostSortClicked(value));
  };

  return (
    <Box sx={{ marginLeft: "auto", marginTop: "8px" }}>
      <FeedSortForm
        form="feed-sorting-form"
        transformInitialValues={(values: FeedOrdering) => {
          const newValues = {
            ...values,
            ordering: isOrderingApplied
              ? values.ordering
              : defaultFeedOrdering.ordering,
          };

          return newValues;
        }}
      >
        <Field
          name="ordering"
          component={FeedSortButton}
          onClick={handleOnClick}
        />
      </FeedSortForm>
    </Box>
  );
};
