import { Box } from "@hexocean/braintrust-ui-components";
import { ProfileCard } from "@js/apps/give-and-get-help/components/profile-card";
import { ProfileCardPopover } from "@js/apps/give-and-get-help/components/profile-card-popover";
import type { SpaceUser } from "@js/types/spaces";

import { MemberAvatarWithDetails } from "../member-avatar-with-details";

import { MessageButton } from "./message-button";

type AdminListItemProps = AdminMemberAvatarWithDetailsProps;

export const AdminListItem = ({
  admin,
  showProfileCardPopover,
}: AdminListItemProps) => {
  return (
    <Box display="flex" alignItems="center" width="100%">
      <AdminMemberAvatarWithDetails
        admin={admin}
        showProfileCardPopover={showProfileCardPopover}
      />

      <MessageButton admin={admin} />
    </Box>
  );
};

type AdminMemberAvatarWithDetailsProps = {
  admin: SpaceUser["user"];
  showProfileCardPopover?: boolean;
};

const AdminMemberAvatarWithDetails = ({
  admin,
  showProfileCardPopover,
}: AdminMemberAvatarWithDetailsProps) => {
  if (!showProfileCardPopover) {
    return <MemberAvatarWithDetails member={{ is_admin: true, user: admin }} />;
  }

  const freelancerId = admin.freelancer_id;

  return (
    <ProfileCardPopover
      anchor={
        <MemberAvatarWithDetails member={{ is_admin: true, user: admin }} />
      }
      renderContent={({ updatePosition }) => (
        <ProfileCard
          talentId={freelancerId}
          avatarData={admin}
          firstName={admin.first_name}
          showMessageButton
          onDataLoad={updatePosition}
        />
      )}
    />
  );
};
