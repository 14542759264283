import { useMemo } from "react";

import { Tab, Tabs } from "@hexocean/braintrust-ui-components";
import { useSearchEventQueryId } from "@js/apps/tracking/search-event-slice";
import { logTabClicked } from "@js/apps/universal-search/actions";
import { useAppDispatch } from "@js/hooks";

import type { UniversalTabValue } from "../../constants";
import type { UniversalSearchCurrentData } from "../../hooks/use-universal-search-form";
import { useUniversalSearchQuery } from "../../hooks/use-universal-search-query";
import {
  useDebouncedUniversalSearchPhrase,
  useUniversalSearchPaused,
} from "../../universal-search-slice";
import { TAB_TO_RESULTS_MAP } from "../universal-search-empty-state/helpers";

import styles from "./universal-search-tabs.module.scss";

export type UniversalSearchTab = {
  label: string;
  value: UniversalTabValue;
};

type UniversalSearchTabsProps = {
  currentTab: UniversalTabValue;
  onTabChange: (tab: UniversalTabValue) => void;
  initialTab?: UniversalTabValue;
  canAccessGiveAndGetHelp: boolean;
  currentTabHasData: boolean;
};

export const UniversalSearchTabs = ({
  currentTab,
  onTabChange,
  canAccessGiveAndGetHelp,
  currentTabHasData,
}: UniversalSearchTabsProps) => {
  const searchPaused = useUniversalSearchPaused();
  const search = useDebouncedUniversalSearchPhrase();
  const showOtherTabsResults = getShouldShowOtherTabsResults({
    search,
    searchPaused,
    currentTabHasData,
  });

  const { data } = useUniversalSearchQuery({
    options: { skip: !showOtherTabsResults },
    canAccessGiveAndGetHelp,
  });

  const searchQueryId = useSearchEventQueryId(
    ENUMS.SearchEventOption.MAIN_SEARCH_BOX,
  );
  const dispatch = useAppDispatch();
  const tabs = useMemo(
    () => getUniversalSearchTabs(canAccessGiveAndGetHelp),
    [canAccessGiveAndGetHelp],
  );

  return (
    <Tabs
      value={currentTab}
      onChange={(_e, newValue: UniversalTabValue) => {
        onTabChange(newValue);
        if (search) {
          dispatch(logTabClicked(newValue, currentTab, searchQueryId));
        }
      }}
      variant="scrollable"
      aria-label="Search tabs"
      className={styles.tabs}
      data-testid="universal-search-tabs"
    >
      {tabs.map((tab) => {
        const label =
          showOtherTabsResults && data
            ? getLabelWithResults(tab, data)
            : tab.label;
        return (
          <Tab
            key={tab.value}
            label={label}
            value={tab.value}
            aria-label={label}
            className={styles.tab}
            inactiveColorVariable="grey-2"
            sx={{ px: showOtherTabsResults ? 1 : 2 }}
          />
        );
      })}
    </Tabs>
  );
};

const getShouldShowOtherTabsResults = ({
  searchPaused,
  search,
  currentTabHasData,
}: {
  search: string;
  searchPaused: boolean;
  currentTabHasData: boolean;
}) => {
  if (!search) {
    return false;
  }

  return searchPaused || currentTabHasData;
};

const getLabelWithResults = (
  tab: UniversalSearchTab,
  results: UniversalSearchCurrentData,
) => {
  let currentTabResultsCount: number;
  const resultsType = TAB_TO_RESULTS_MAP[tab.value];
  if (resultsType === "jobResults") {
    currentTabResultsCount = results[resultsType]?.count || 0;
  } else {
    currentTabResultsCount = results[resultsType]?.total_count || 0;
  }

  return `${tab.label} (${currentTabResultsCount.toLocaleString("en-US")})`;
};

const getUniversalSearchTabs = (
  canAccessGiveAndGetHelp: boolean,
): UniversalSearchTab[] => {
  const tabs: UniversalSearchTab[] = [
    {
      label: "Jobs",
      value: "JOBS",
    },
    {
      label: "People",
      value: "PEOPLE",
    },
  ];

  if (canAccessGiveAndGetHelp) {
    tabs.splice(1, 0, {
      label: "Career Help",
      value: "CAREER_HELP",
    });
  }

  return tabs;
};
