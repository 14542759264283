import type { TypedUseQueryHookResult } from "@reduxjs/toolkit/dist/query/react";
import { skipToken } from "@reduxjs/toolkit/query";

import type { GetEmployerCurrentOfferDepositResponse } from "@js/apps/employer/api";
import { useGetEmployerCurrentOfferDepositByIdQuery } from "@js/apps/employer/api";

import { useEmployerId } from "../employer-id";

export const useGetEmployerCurrentOfferDepositQuery = (
  options?: Parameters<typeof useGetEmployerCurrentOfferDepositByIdQuery>[1],
) => {
  const employerId = useEmployerId();

  return useGetEmployerCurrentOfferDepositByIdQuery(
    employerId ? employerId : skipToken,
    options,
  ) as TypedUseQueryHookResult<
    GetEmployerCurrentOfferDepositResponse,
    number,
    any
  >;
};
