import { useMemo } from "react";

import { useGetPostCommentsQuery } from "@js/apps/give-and-get-help/api";
import { usePostsContext } from "@js/apps/give-and-get-help/context/posts";
import type { PostComment } from "@js/types/give-and-get-help";

const REPLIES_TO_DISPLAY = {
  PRELOADED: "PRELOADED",
  ALL: "ALL",
  NONE: "NONE",
} as const;
type RepliesToDisplay = ObjectValues<typeof REPLIES_TO_DISPLAY>;

export const useCommentReplies = ({
  comment,
  postId,
}: {
  comment: PostComment;
  postId: number;
}) => {
  const {
    collapsedComments,
    onCommentExpand,
    onCommentCollapse,
    expandedPostsOrCommentsIds,
  } = usePostsContext();

  const repliesTypeToDisplay = useMemo(
    () =>
      getRepliesTypeToDisplay({
        comment,
        expandedPostsOrCommentsIds,
        collapsedComments,
      }),
    [comment, expandedPostsOrCommentsIds, collapsedComments],
  );

  const showAllCurrentCommentReplies =
    repliesTypeToDisplay === REPLIES_TO_DISPLAY.ALL;

  const showPreloadedCurrentCommentReplies =
    repliesTypeToDisplay === REPLIES_TO_DISPLAY.PRELOADED;

  const shouldHideComments = repliesTypeToDisplay === REPLIES_TO_DISPLAY.NONE;

  const { data: repliesToExpandedComment, isLoading: isLoadingReplies } =
    useGetPostCommentsQuery(
      { postId: comment.id },
      { skip: !showAllCurrentCommentReplies || !comment.comments_count },
    );

  const replies = repliesToExpandedComment?.comments;

  const expandReplies = () => {
    onCommentExpand(comment.id);
  };

  const hideReplies = () => {
    onCommentCollapse({ id: comment.id, postId });
  };

  const repliesToDisplay = useMemo(() => {
    if (showAllCurrentCommentReplies) {
      return (
        replies ??
        comment.comments ?? // return preloaded comments while replies are being loaded
        defaultCommentsToDisplay
      );
    }

    if (showPreloadedCurrentCommentReplies) {
      return comment.comments ?? defaultCommentsToDisplay;
    }

    return defaultCommentsToDisplay;
  }, [
    comment,
    showAllCurrentCommentReplies,
    showPreloadedCurrentCommentReplies,
    replies,
  ]);

  return {
    showAllCurrentCommentReplies,
    showPreloadedCurrentCommentReplies,
    shouldHideComments,
    replies,
    isLoadingReplies,
    expandReplies,
    hideReplies,
    repliesToDisplay,
  };
};

const defaultCommentsToDisplay: PostComment[] = [];

const getRepliesTypeToDisplay = ({
  comment,
  expandedPostsOrCommentsIds,
  collapsedComments,
}: {
  expandedPostsOrCommentsIds: number[];
  collapsedComments: { id: number }[];
  comment: PostComment;
}): RepliesToDisplay => {
  const commentRepliesHidden = collapsedComments.some(
    (collapsedData) => collapsedData.id === comment.id,
  );
  if (commentRepliesHidden) {
    return REPLIES_TO_DISPLAY.NONE;
  }

  const preloadedCommentsCount = comment.comments?.length ?? 0;
  const totalCommentsCount = comment.comments_count ?? 0;
  const commentRepliesExpanded = expandedPostsOrCommentsIds.some(
    (expandedId) => expandedId === comment.id,
  );
  if (commentRepliesExpanded && preloadedCommentsCount < totalCommentsCount) {
    return REPLIES_TO_DISPLAY.ALL;
  }

  return REPLIES_TO_DISPLAY.PRELOADED;
};
