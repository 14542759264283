import type { IconButtonProps } from "@hexocean/braintrust-ui-components";
import { IconButton } from "@hexocean/braintrust-ui-components";
import { CloseSVGIcon } from "@hexocean/braintrust-ui-components/Icons";

type BidCreateSuccessModalCloseButtonProps = Pick<
  IconButtonProps,
  "sx" | "variant" | "onClick"
>;

export const BidCreateSuccessModalCloseButton = ({
  ...props
}: BidCreateSuccessModalCloseButtonProps) => {
  return (
    <IconButton aria-label="close modal" size="medium" {...props}>
      <CloseSVGIcon />
    </IconButton>
  );
};
