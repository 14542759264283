export const SECTIONS_WITH_CHANGES_SAVED = [
  "new_skills",
  "top_skills",
  "locations",
  "locations_strongly_required",
  "timezones",
  "timezone_overlap",
] as const;

export type SectionsWithChangesSavedType =
  (typeof SECTIONS_WITH_CHANGES_SAVED)[number][];
