import { createFilterOptions } from "@hexocean/braintrust-ui-components";

export type TimezoneOption = {
  value: string;
  name: string;
};

export const timezoneOptions: TimezoneOption[] =
  SETTINGS.TIMEZONE_ABBREVIATIONS.map((timezone) => ({
    value: timezone.abbreviation,
    name: timezone.name,
  }));

export const timezoneFilters = createFilterOptions<{
  name: string;
  value: string;
}>({
  matchFrom: "any",
  stringify: (option) => option.name,
});
