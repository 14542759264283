import { Field, Fields } from "redux-form";

import { Grid } from "@hexocean/braintrust-ui-components";
import { InvoiceNumberField } from "@js/apps/invoices/fields/invoice-number-field";
import { IssuedAtField } from "@js/apps/invoices/fields/issued-at-field";
import { SelectJobField } from "@js/apps/invoices/fields/select-job-field";

import { EmployerAddress } from "../employer-address";

type HeaderProps = {
  canChangeJob: boolean;
  isCopy: boolean;
  isEdit?: boolean;
};

import styles from "./style.module.scss";

export const Header = ({ canChangeJob, isCopy, isEdit }: HeaderProps) => {
  return (
    <div className={styles.invoiceHead}>
      <Grid container spacing={4} justifyContent="space-between">
        <Grid item xs={12} md={7}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={8}>
              <Fields
                names={["job", "employer"]}
                component={SelectJobField}
                canChangeJob={canChangeJob}
                isCopy={isCopy}
              />
            </Grid>
            <Grid item xs={12} sm={4} className="posr">
              <Field
                isEdit={isEdit}
                id="number"
                name="number"
                component={InvoiceNumberField}
              />
            </Grid>
            <Grid item sm={6} md={5} lg={4}>
              <Field name="issued_at" component={IssuedAtField} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          className={styles.invoiceHeadEmployersAddress}
        >
          <Field name="job" component={EmployerAddress} />
        </Grid>
      </Grid>
    </div>
  );
};
