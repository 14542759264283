import { Chip } from "@hexocean/braintrust-ui-components";
import type { Skill } from "@js/types/admin";

type SelectedPopularSkillsProps = {
  skills?: Pick<Skill, "id" | "name">[];
  handleClick: (skill: Pick<Skill, "id" | "name">) => void;
  selected?: boolean;
};

export const SelectablePopularSkills = ({
  skills,
  selected,
  handleClick,
}: SelectedPopularSkillsProps) => {
  return (
    <>
      {skills?.map((skill) => {
        return (
          <Chip
            key={skill.id}
            className="soft-violet-hover"
            role="checkbox"
            onClick={() => handleClick(skill)}
            color={selected ? "violet" : "grey-4"}
            label={skill.name}
            aria-checked={!!selected}
          />
        );
      })}
    </>
  );
};
