import type { ApplicationInsightMatchSummary } from "@js/types/jobs";

const REQUIRED_TOTAL_APPLICANTS = 10;

type MatchPlacement = {
  label: string;
  summaryType: ApplicationInsightMatchSummary["summary_type"];
};

export const useMatchPlacement = (
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null,
  numberOfApplicants: number,
  isApplied: boolean,
): MatchPlacement => {
  const notEnoughApplicants = numberOfApplicants < REQUIRED_TOTAL_APPLICANTS;
  const isDecentMatch =
    matchLabel === ENUMS.SmartMatchingLabel.GOOD ||
    matchLabel === ENUMS.SmartMatchingLabel.GREAT;

  let placementLabel = "";

  if (isApplied && !notEnoughApplicants && isDecentMatch) {
    if (matchLabel === ENUMS.SmartMatchingLabel.GOOD) {
      placementLabel = "You're in the Top 25%";
    } else if (matchLabel === ENUMS.SmartMatchingLabel.GREAT) {
      placementLabel = "You're in the Top 10%";
    }
  } else if (isDecentMatch) {
    placementLabel = "You might be a good fit";
  }

  return {
    label: `There ${numberOfApplicants === 1 ? "is" : "are"} ${numberOfApplicants} applicant${numberOfApplicants === 1 ? "" : "s"}. ${placementLabel}`,
    summaryType:
      isDecentMatch || matchLabel === ENUMS.SmartMatchingLabel.OK
        ? "positive"
        : "constructive",
  };
};
