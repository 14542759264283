import {
  CHECK_STATUS_INTERVAL,
  PING_INTERVAL_MS,
  SEND_PENDING_MESSAGES_INTERVAL,
} from "../constants";

type InitializeParams = {
  checkStatus: () => void;
  repeatSendInTheAbsenceOfConnection: () => void;
  ping: () => void;
};

export class Intervals {
  private checkStatusTimer: number | undefined;

  private sendPendingMessagesTimer: number | undefined;

  private pingTimer: number | undefined;

  public initialize = (params: InitializeParams) => {
    const { ping, repeatSendInTheAbsenceOfConnection, checkStatus } = params;

    if (
      this.checkStatusTimer ||
      this.sendPendingMessagesTimer ||
      this.pingTimer
    ) {
      this.cleanUp();
    }

    this.checkStatusTimer = window.setInterval(
      checkStatus,
      CHECK_STATUS_INTERVAL,
    );
    this.sendPendingMessagesTimer = window.setInterval(
      repeatSendInTheAbsenceOfConnection,
      SEND_PENDING_MESSAGES_INTERVAL,
    );
    this.pingTimer = window.setInterval(ping, PING_INTERVAL_MS);
  };

  public cleanUp = () => {
    clearInterval(this.checkStatusTimer);
    clearInterval(this.sendPendingMessagesTimer);
    clearInterval(this.pingTimer);
  };
}
