import { AddRecipientsModal } from "@js/apps/withdrawal/components/add-recipients/recipients-listing/recipients-modal";
import type { WithdrawalMethodType } from "@js/types/withdrawals";

import type { AddRecipientsFormData } from "../../forms/add-recipients";

import { AddRecipientsModalContent } from "./add-recipients-modal-content";

type AddRecipientsProps = {
  addRecipients: (recipients: AddRecipientsFormData) => Promise<void>;
  handledMethod: WithdrawalMethodType | null;
  deleteRecipient: (recipient: number) => void;
};

export const AddRecipients = (props: AddRecipientsProps) => {
  return (
    <AddRecipientsModal>
      <AddRecipientsModalContent {...props} />
    </AddRecipientsModal>
  );
};
