import { Suspense } from "react";

import { Loader } from "@hexocean/braintrust-ui-components";
import { lazyWithRetry } from "@js/utils/lazy-with-retry";

import type { HlsPlayerProps } from "./hls-player";

const HlsPlayerComponentLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "video-player" */ "@js/components/video-player/hls-player"
  );

  return { default: module.HlsPlayerComponent };
});

export const HlsPlayer = (props: HlsPlayerProps) => {
  return (
    <Suspense fallback={<Loader centered />}>
      <HlsPlayerComponentLazy {...props} />
    </Suspense>
  );
};
