import type { WrappedFieldProps } from "redux-form";

import { Box } from "@hexocean/braintrust-ui-components";

export const InterviewQuestionFormCover = (
  is_ai_interview_enabled: WrappedFieldProps,
) => {
  if (is_ai_interview_enabled.input.value) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        backgroundColor: "var(--grey-1)",
        opacity: 0.6,
        zIndex: 1,
        cursor: "not-allowed",
      }}
    ></Box>
  );
};
