import { useCallback } from "react";

import { addBookmark } from "@js/apps/bookmarks/actions";
import { jobHasBeenSavedAsFavorite } from "@js/apps/bookmarks/actions";
import type { AfterAction } from "@js/apps/jobs/components/job-actions";
import {
  JobLocationContext,
  useJobLocationContext,
} from "@js/apps/jobs/context";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import { LocalStorage } from "@js/services";
import type { MainJobListingJob } from "@js/types/jobs";

import { useBookmarkJobMutation, useUnbookmarkJobMutation } from "../../api";
import {
  showSnackbarOnRemoveBookmark,
  showSnackbarOnSaveBookmark,
} from "../job-bookmark-snackbar";

type UseBookmarkJobButtonProps = {
  job: MainJobListingJob;
  afterAction?: AfterAction;
};

export const useBookmarkJobButton = ({ job }: UseBookmarkJobButtonProps) => {
  const isBookmarked = !!job.saved_job_id;
  const [bookmarkJobMutationTrigger, { isLoading: bookmarking }] =
    useBookmarkJobMutation({ fixedCacheKey: `bookmarkJob-${job.id}` });
  const [unbookmarkJobMutationTrigger, { isLoading: unbookmarking }] =
    useUnbookmarkJobMutation({ fixedCacheKey: `unbookmarkJob-${job.id}` });

  const location = useJobLocationContext();
  const dispatch = useAppDispatch();

  const appendAppliedFiltersToAnalytics =
    location === JobLocationContext.Values.job_listing ||
    location === JobLocationContext.Values.jobs_landing_page;

  const bookmarkJob = useCallback(
    (jobId: number) => {
      bookmarkJobMutationTrigger(jobId)
        .unwrap()
        .then((savedJob) => {
          dispatch(
            jobHasBeenSavedAsFavorite({
              savedJob,
              appendFilters: appendAppliedFiltersToAnalytics,
              search: window.location.search,
            }),
          );

          dispatch(addBookmark());

          const alreadySavedJob = LocalStorage.getItem("savedJob");

          if (!alreadySavedJob) {
            LocalStorage.setItem("savedJob", "true");

            showSnackbarOnSaveBookmark(job);
          }
        })
        .catch(() => {
          Snackbar.error("Something went wrong, please try again.");
        });
    },
    [
      appendAppliedFiltersToAnalytics,
      bookmarkJobMutationTrigger,
      dispatch,
      job,
    ],
  );

  const unbookmarkJob = useCallback(
    (args: { savedJobId: number; jobId: number }) => {
      unbookmarkJobMutationTrigger(args)
        .unwrap()
        .then(() => {
          const alreadyRemovedSavedJob = LocalStorage.getItem("removedJob");

          if (!alreadyRemovedSavedJob) {
            LocalStorage.setItem("removedJob", "true");

            showSnackbarOnRemoveBookmark(job, bookmarkJob);
          }
        })
        .catch(() => {
          Snackbar.error("Something went wrong, please try again.");
        });
    },
    [bookmarkJob, job, unbookmarkJobMutationTrigger],
  );

  const isLoading = bookmarking || unbookmarking;

  const handleClick = useCallback(() => {
    if (isLoading) {
      return;
    }

    if (job.saved_job_id) {
      unbookmarkJob({ savedJobId: job.saved_job_id, jobId: job.id });
    } else {
      bookmarkJob(job.id);
    }
  }, [isLoading, job.saved_job_id, job.id, unbookmarkJob, bookmarkJob]);

  return {
    isBookmarked,
    savedJobId: job.saved_job_id,
    handleClick,
    loading: isLoading,
  };
};
