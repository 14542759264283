import type { ReactNode } from "react";
import { useState } from "react";
import { useCallback, useMemo } from "react";
import type { DropzoneOptions } from "react-dropzone";

import { Box } from "@hexocean/braintrust-ui-components";

import { FILE_UPLOAD_STATE_STATUS } from "../../constants";
import { getFileWithId, validateFilesCount } from "../../helpers/file-upload";
import { useUploadFiles } from "../../hooks/use-upload-files";
import type {
  ExistingFile,
  FileData,
  FilePreviewData,
  UploadedFile,
  UploadType,
} from "../../types";
import type { RenderFileDropzonePlaceholderArg } from "../file-dropzone";
import { FileDropzone } from "../file-dropzone";
import { FileDropzonePlaceholder } from "../file-dropzone-placeholder";
import { FilesPreviewList } from "../files-preview-list";

export type RenderPreviewArg = {
  filesPreviewData: FilePreviewData[];
  onFileDelete: (fileData: FilePreviewData) => void;
  uploadType: UploadType;
};

export type RenderPlaceholderArg = RenderFileDropzonePlaceholderArg & {
  isUploading: boolean;
  className: string | undefined;
  subtitle: string | undefined;
};

export type FilesUploadProps = {
  id?: string;
  uploadType: UploadType;
  existingFiles?: Array<ExistingFile>;
  subtitle?: string;
  onBeforeUpload?: () => void;
  onAfterUpload?: () => void;
  onUpload?: (uploadedFiles: UploadedFile[]) => void;
  onFileDelete?: (id: string | number) => void;
  className?: string;
  renderPreview?: null | ((arg: RenderPreviewArg) => ReactNode);
  renderPlaceholder?: (arg: RenderPlaceholderArg) => ReactNode;
  options?: Pick<DropzoneOptions, "maxSize" | "maxFiles" | "accept">;
};

export const FilesUpload = ({
  id,
  uploadType,
  subtitle,
  className,
  options = {},
  existingFiles,
  onBeforeUpload,
  onAfterUpload,
  onUpload,
  onFileDelete,
  renderPreview = defaultRenderPreview,
  renderPlaceholder = defaultRenderPlaceholder,
}: FilesUploadProps) => {
  const [filesValidationError, setFilesValidationError] = useState<
    string | null
  >(null);
  const { uploadFiles, fileUploadsState, deleteFile, errors, isUploading } =
    useUploadFiles({
      onBeforeUpload,
      onUpload,
      onAfterUpload,
    });

  const currentFilesCount = useMemo(() => {
    const validFilesUploads = fileUploadsState.filter(
      (uploadState) => uploadState.status !== FILE_UPLOAD_STATE_STATUS.ERROR,
    );

    return validFilesUploads.length + (existingFiles?.length ?? 0);
  }, [fileUploadsState, existingFiles]);

  const onDrop = useCallback(
    (files: File[]) => {
      setFilesValidationError(null);

      if (!files.length) {
        return;
      }

      const validateFilesCountError = validateFilesCount({
        newFiles: files,
        currentFilesCount,
        maxFiles: options.maxFiles,
      });

      if (validateFilesCountError) {
        setFilesValidationError(validateFilesCountError);
        return;
      }

      const filesWithIds = files.map((file) => getFileWithId(file));
      uploadFiles({
        files: filesWithIds,
        uploadType,
      });
    },
    [uploadType, uploadFiles, options.maxFiles, currentFilesCount],
  );

  const allFilesPreviewData = useMemo<FilePreviewData[]>(() => {
    const mappedExistingFiles =
      existingFiles?.map((fileData) => {
        return {
          fileId: String(fileData.id),
          name: fileData.attachment.name,
          status: FILE_UPLOAD_STATE_STATUS.COMPLETED,
          file: fileData,
        };
      }) ?? [];

    const result = [
      ...mappedExistingFiles,
      ...fileUploadsState.filter(
        (fileState) => fileState.status !== FILE_UPLOAD_STATE_STATUS.ERROR,
      ),
    ].reduce((acc: FilePreviewData[], curr: FilePreviewData) => {
      const fileIds: (FileData["id"] | undefined)[] = acc.map(
        (el) => el?.file?.id,
      );
      if (fileIds.includes(curr?.file?.id)) {
        return acc;
      } else {
        return [...acc, curr];
      }
    }, []);

    return result;
  }, [existingFiles, fileUploadsState]);

  const handleDeleteFile = (fileData: FilePreviewData) => {
    deleteFile(fileData.fileId);
    setFilesValidationError(null);
    if (!fileData.file || !onFileDelete) {
      return;
    }

    onFileDelete(fileData.file.id);
  };

  return (
    <>
      <FileDropzone
        id={id}
        dropzoneOptions={{
          onDrop,
          multiple: options?.maxFiles !== 1,
          accept: options?.accept,
          maxSize: options?.maxSize,
        }}
        error={filesValidationError ?? errors?.[0]}
      >
        {({ isDragActive, isFocused }) =>
          renderPlaceholder({
            isDragActive,
            isFocused,
            subtitle,
            className,
            isUploading,
          })
        }
      </FileDropzone>
      {!!renderPreview && (
        <Box sx={{ pt: allFilesPreviewData.length ? 2 : undefined }}>
          {renderPreview({
            filesPreviewData: allFilesPreviewData,
            onFileDelete: handleDeleteFile,
            uploadType,
          })}
        </Box>
      )}
    </>
  );
};

const defaultRenderPreview = (props: RenderPreviewArg) => (
  <FilesPreviewList {...props} />
);

const defaultRenderPlaceholder = ({
  isDragActive,
  isFocused,
  className: placeholderClassName,
  subtitle: placeholderSubtitle,
}: RenderPlaceholderArg) => (
  <FileDropzonePlaceholder
    isDragActive={isDragActive}
    isFocused={isFocused}
    subtitle={placeholderSubtitle}
    className={placeholderClassName}
  />
);
