import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Box, Loader, Stack } from "@hexocean/braintrust-ui-components";

import { EmployerInvoicesStatistics } from "./employer-invoices-statistics";
import { EmployerInvoicesTabs } from "./employer-invoices-tabs";
import { EmployerInvoicesTitle } from "./employer-invoices-title";

export const EmployerInvoicesComponent = () => {
  return (
    <Stack sx={{ gap: 3 }}>
      <EmployerInvoicesTitle />
      <EmployerInvoicesStatistics />
      <EmployerInvoicesTabs />
      <Suspense fallback={<FallbackLoader />}>
        <Outlet />
      </Suspense>
    </Stack>
  );
};

const FallbackLoader = () => {
  return (
    <Box sx={{ position: "relative", minHeight: "10rem" }}>
      <Loader centered />
    </Box>
  );
};
