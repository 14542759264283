import { useCallback, useEffect, useRef, useState } from "react";

import { useAppSelector } from "@js/hooks";
import type { SiteNotification } from "@js/types/notifications";
import { isInIframe } from "@js/utils";

export const useLiveSiteNotifications = () => {
  const [notificationsToDisplay, setNotificationsToDisplay] = useState<
    SiteNotification[]
  >([]);
  const [classNames, setClassNames] = useState<
    Record<SiteNotification["id"], string>
  >({});
  const prevNotificationsRef = useRef<SiteNotification[]>([]);
  const hasMounted = useRef(false);

  const siteNotifications = useAppSelector(
    (state) => state.notifications.siteNotifications,
  );
  const activeMessengerRoom = useAppSelector(
    (state) => state.common.activeMessengerRoom,
  );

  const canNewNotificationBeDisplayed = useCallback(
    (notification?: SiteNotification) => {
      if (!notification) return false;
      if (isInIframe()) return false;
      if (notification.silent) return false;
      if (prevNotificationsRef.current.some((n) => n.id === notification.id)) {
        return false;
      }

      const roomId = notification.context && notification.context.room_id;

      return !(roomId && roomId === activeMessengerRoom);
    },
    [activeMessengerRoom],
  );

  const displayNotification = (newNotification: SiteNotification) => {
    setNotificationsToDisplay((prev) => {
      return [...prev, newNotification];
    });
  };

  useEffect(() => {
    if (!siteNotifications) {
      hasMounted.current = false;
      return;
    }

    if (!hasMounted.current) {
      prevNotificationsRef.current = siteNotifications || [];
      hasMounted.current = true;
      return;
    }

    const newNotification = siteNotifications[0];

    if (canNewNotificationBeDisplayed(newNotification)) {
      displayNotification(newNotification);
    }
  }, [canNewNotificationBeDisplayed, siteNotifications]);

  const hideNotification = (notificationId: SiteNotification["id"]) => {
    setClassNames((prev) => ({
      ...prev,
      [notificationId]: "hiding",
    }));

    setTimeout(() => {
      setNotificationsToDisplay((prev) => {
        return prev.filter((n) => n.id !== notificationId);
      });
    }, 1000);
  };

  return {
    notificationsToDisplay,
    hideNotification,
    classNames,
  };
};
