import type { IPost } from "@js/types/give-and-get-help";

import { useGetMyPostsQuery } from "../../api";
import type { UseGetPostQueryArg } from "../use-get-post-query-arg";
import { useGetPostQueryArg } from "../use-get-post-query-arg";

const PAGE_SIZE = 10;
const defaultPosts: IPost[] = [];

export const useInfinityPostLoadingMyPosts = (
  arg?: Omit<UseGetPostQueryArg, "ordering">,
) => {
  const {
    setCurrentPage,
    queryArg: getPostQueryArg,
    currentPage,
  } = useGetPostQueryArg(arg);

  const {
    currentData: data,
    isFetching: fetchingMyPosts,
    originalArgs,
    refetch,
  } = useGetMyPostsQuery(
    { page: getPostQueryArg.page, page_size: getPostQueryArg.page_size },
    {
      refetchOnFocus: false,
      refetchOnMountOrArgChange: false,
      refetchOnReconnect: false,
    },
  );

  const handleFetchingMore = () => {
    const currentCount = data?.results?.length ?? 0;
    const totalCount = data?.count ?? 0;

    if (!data || currentCount >= totalCount) {
      return;
    }
    const nextPage =
      Math.floor(data.results.length / (originalArgs?.page_size || PAGE_SIZE)) +
      1;
    if (nextPage === currentPage) {
      // post has been deleted, so we need to refetch the current page ...
      // ... to fill the missing slots
      refetch();
    }
    setCurrentPage(nextPage);
  };

  const isLoading = !data && fetchingMyPosts;

  return {
    posts: data?.results || defaultPosts,
    hasMore: !!data?.next,
    loadingPosts: isLoading,
    fetchingPosts: fetchingMyPosts,
    handleFetchingMore,
    visitedFeedThreeTimes: data?.visited_feed_three_times,
    postsCount: data?.count || 0,
  };
};
