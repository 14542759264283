import cs from "classnames";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { EditPenIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { FreelancerPublic } from "@js/types/freelancer";

import { useClearAddServicesForm } from "../../forms/add-ggh-services-form";
import { openAddServices } from "../ggh-services/modals/add-ggh-services";
import { ServicesList } from "../services-list";
import { ServicesReviewSection } from "../services-review-section";

import { servicesSectionId, useServicesModule } from "./use-services-module";

type GiveGetHelpProfileModuleProps = {
  profile: FreelancerPublic;
};

export const GiveGetHelpProfileModule = ({
  profile,
}: GiveGetHelpProfileModuleProps) => {
  const {
    handleEditButton,
    isHelpServicesEmptyAndOwnProfile,
    isOwnProfile,
    helpServices,
    helpServicesDraft,
    showServicesListWithPendingState,
    showEditButton,
    isNotLoggedIn,
  } = useServicesModule({ profile });

  if (isNotLoggedIn) return null;

  return (
    <Box
      id={servicesSectionId}
      className={cs("give-and-get-help-module", {
        "profile-empty-state": isHelpServicesEmptyAndOwnProfile,
      })}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          gap={1}
        >
          <Typography component="h2" size="large" color="grey-1">
            I help the community with...
          </Typography>
          {showEditButton && (
            <IconButton
              variant="black-outlined"
              size="x-small"
              className="experience__edit-btn"
              aria-label="Edit experience"
              onClick={handleEditButton}
            >
              <EditPenIcon />
            </IconButton>
          )}
        </Box>

        {showServicesListWithPendingState ? (
          <>
            <ServicesList
              services={helpServices}
              profile={profile}
              draftServices={helpServicesDraft}
              isOwnProfile={isOwnProfile}
            />
            <ServicesReviewSection
              profile={profile}
              isOwnProfile={isOwnProfile}
            />
          </>
        ) : (
          <EmptyState
            profile={profile}
            ownEmptyState={isHelpServicesEmptyAndOwnProfile}
          />
        )}
      </Box>
    </Box>
  );
};

type EmptyStateProps = {
  profile: FreelancerPublic;
  ownEmptyState: boolean;
};

const EmptyState = ({ profile, ownEmptyState }: EmptyStateProps) => {
  const { destroyForm } = useClearAddServicesForm();
  return (
    <Box mt={1.5}>
      <Typography
        component="p"
        fontStyle="italic"
        mt={-1}
        mb={2}
        multipleEllipsis
      >
        {ownEmptyState
          ? "Add services you want to offer to the Braintrust community."
          : `${profile.user.first_name} hasn’t added any services, yet.`}
      </Typography>
      {ownEmptyState && (
        <Button
          variant="black-outlined"
          size="medium"
          shape="squared"
          onClick={() =>
            openAddServices({
              onClose: destroyForm,
              freelancerId: profile.id,
            })
          }
        >
          Add services
        </Button>
      )}
    </Box>
  );
};
