import React from "react";

import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowLeftIcon,
  LockSmallIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import { useHeaderSection } from "./hooks/use-header-section";

export const HeaderSection = () => {
  const { onBackDescriptionModal } = useHeaderSection();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <IconButton
        aria-label="back-button"
        variant="tertiary"
        onClick={onBackDescriptionModal}
      >
        <ArrowLeftIcon />
      </IconButton>
      <Box display="flex">
        <img
          width="auto"
          height="24px"
          src={`${SETTINGS.STATIC_URL}logo/logo.svg`}
          alt="logo"
        />
      </Box>
      <Box display="flex" gap={0.5} alignItems="center">
        <LockSmallIcon sx={{ flex: 1, fontSize: "12px" }} />
        <Typography component="p" variant="paragraph" size="small">
          Secure Checkout
        </Typography>
      </Box>
    </Box>
  );
};
