import { useABExperiment } from "@js/apps/experiments";

export const useApplicationBoost = () => {
  const { isParticipating, experiment } = useABExperiment("Boost Purchase");

  const isApplicationBoostEnabled =
    experiment?.variant ===
    SETTINGS.EXPERIMENT_ENUMS[
      SETTINGS.EXPERIMENT_ENUMS.Experiments.BoostPurchase
    ]?.BoostPurchaseEnabled;

  return {
    showApplicationBoost: isParticipating && isApplicationBoostEnabled,
  };
};
