import type { FC } from "react";
import { Field } from "redux-form";

import { Box, Button, Grid } from "@hexocean/braintrust-ui-components";
import type { FilesUploadFieldRenderPreviewArg } from "@js/apps/file-upload";
import {
  FilesUploadField,
  SortableImagesPreviewPanel,
} from "@js/apps/file-upload";
import { ImageUploadWithCropperField } from "@js/apps/file-upload/components/image-upload-with-cropper";
import { createFormInstance } from "@js/forms/components";
import { TextareaField, TextField } from "@js/forms/fields";
import { required } from "@js/forms/validators";
import type { PortfolioImage, WorkSampleItem } from "@js/types/freelancer";

import { ProfileProjectsModuleEmptyStateHeader } from "../../components/profile-projects-module/profile-projects-module-empty-state-header";
import { ProfileProjectsModuleNavHeader } from "../../components/profile-projects-module/profile-projects-module-nav-header";

const ProjectsModuleEditFormInstance = createFormInstance<
  WorkSampleItem,
  unknown
>("projects-module-form");

type ProjectsModuleEditFormProps = {
  onSubmit: (data: WorkSampleItem) => void;
  onSubmitSuccess: () => void;
  onClose: () => void;
  initialValues: object;
  existingImages: PortfolioImage[];
  isEditMode: boolean;
  isEmptyState: boolean;
};

export const ProjectsModuleEditForm: FC<ProjectsModuleEditFormProps> = (
  props,
) => {
  return (
    <ProjectsModuleEditFormInstance {...props}>
      {props.isEmptyState ? (
        <ProfileProjectsModuleEmptyStateHeader />
      ) : (
        <ProfileProjectsModuleNavHeader
          isEditMode={props.isEditMode}
          onClose={props.onClose}
        />
      )}
      <Grid container spacing={4} maxWidth={800}>
        <Grid item sm={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                id="title"
                name="title"
                component={TextField}
                validate={[required]}
                label="Project Name*"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                id="description"
                name="description"
                component={TextareaField}
                maxLength={2000}
                rows={8}
                validate={[required]}
                label="Description*"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                id="url"
                name="url"
                component={TextField}
                normalize={(value) => value || null}
                label="Project URL"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                label="Project thumbnail"
                name="main_image_id"
                existingImages={props.existingImages}
                component={ImageUploadWithCropperField}
                uploadType={ENUMS.UploadType.PORTFOLIO_ITEM_IMAGE_UPLOAD}
                subtitle="Recommended image size: 336 x 164 px"
                cropperOptions={{
                  minWidth: 336,
                  minHeight: 164,
                  cropperWidth: 336,
                  cropperHeight: 164,
                  squared: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                label="Project images"
                name="images_ids"
                subtitle="Recommended image size: 800 x 600 px"
                component={FilesUploadField}
                existingFiles={props.existingImages}
                uploadType={ENUMS.UploadType.PORTFOLIO_ITEM_IMAGE_UPLOAD}
                options={{
                  accept: { "image/*": [] },
                }}
                renderPreview={(arg: FilesUploadFieldRenderPreviewArg) => (
                  <SortableImagesPreviewPanel {...arg} />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box pt={4} display="flex" gap={2}>
        <Button
          variant="secondary"
          shape="squared"
          onClick={() => props.onClose()}
          sx={{ marginLeft: "auto" }}
        >
          Discard changes
        </Button>
        <Button variant="positive" shape="squared" type="submit">
          Save changes
        </Button>
      </Box>
    </ProjectsModuleEditFormInstance>
  );
};
