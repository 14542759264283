import { Modal } from "@js/components/modal";

const REVIEW_MODAL_ID = "review-modal";

export const WriteReviewModalInstance = Modal(REVIEW_MODAL_ID, {
  padding: false,
  className: "review-modal",
  keepOnBackdropClick: true,
  closeButtonProps: {
    style: { color: "var(--white)" },
  },
});

export const openWriteReviewModal = () => WriteReviewModalInstance.open();
export const closeWriteReviewModal = () => WriteReviewModalInstance.close();
