import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CircleCheckIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import type { MappedPollOption } from "@js/apps/give-and-get-help/components/poll/utils";
import { pluralize } from "@js/utils";

type PollResultsIndicatorProps = {
  option: MappedPollOption;
  isAny100: boolean;
};

export const PollResultsIndicator = ({
  option,
  isAny100,
}: PollResultsIndicatorProps) => {
  const user = useUser();
  return (
    <Box display="flex" gap={1} alignItems="center" justifyContent="end">
      <Box display="flex" gap={0.5} alignItems="center">
        {option.user_voted && user && (
          <AvatarGroup sx={{ alignItems: "center" }}>
            <Avatar
              src={user}
              sx={{
                width: 16,
                height: 16,
                "&.MuiAvatar-root": {
                  border: "none",
                },
              }}
            />
            <Box
              border="1px solid var(--white)"
              boxSizing="content-box"
              borderRadius={500}
              width={16}
              height={16}
              ml="-6px"
              zIndex={1}
              display="flex"
            >
              <CircleCheckIcon
                style={{ color: "var(--info-2)", width: 16, height: 16 }}
              />
            </Box>
          </AvatarGroup>
        )}
        <Typography
          component="p"
          variant="label"
          size="small"
          whiteSpace="nowrap"
        >
          {option.votes_count} vote
          {pluralize(option.votes_count ?? 0, { zeroPlural: true })}
        </Typography>
      </Box>
      <Chip
        color="grey-5"
        size="x-small"
        LabelProps={{
          size: "small",
          sx: {
            minWidth: isAny100 ? "38px" : "28px",
            textAlign: "center",
          },
        }}
        label={option.percentage}
      />
    </Box>
  );
};
