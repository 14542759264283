import type { ReactNode } from "react";
import React from "react";

import { Typography, useMediaQuery } from "@hexocean/braintrust-ui-components";

type CommonTeamMembersModalTextProps = {
  children: ReactNode;
};

export const CommonTeamMembersModalText = ({
  children,
}: CommonTeamMembersModalTextProps) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Typography
      component="p"
      variant="paragraph"
      size={isMobile ? "small" : "medium"}
    >
      {children}
    </Typography>
  );
};

type CommonTeamMembersModalHeaderTextProps = {
  children: ReactNode;
};

export const CommonTeamMembersModalHeaderText = ({
  children,
}: CommonTeamMembersModalHeaderTextProps) => {
  const isMobile = useMediaQuery("sm");
  return (
    <Typography
      component="h2"
      variant={isMobile ? "paragraph" : "title"}
      size={isMobile ? "large" : "small"}
      fontWeight={400}
    >
      {children}
    </Typography>
  );
};
