import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

import { Button } from "@hexocean/braintrust-ui-components";
import { TrashIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useIsProfileOwnerContext } from "@js/apps/common/profile/is-profile-owner-context";
import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import {
  useDeleteResumeMutation,
  useGetFreelancerPublicProfileQuery,
} from "@js/apps/freelancer/api";
import { openDeleteResumeModal } from "@js/apps/freelancer/components/profile-resume/modals/delete-resume";
import { useAppDispatch } from "@js/hooks";

export const DeleteResumeButton = () => {
  const [deleteResumeMutation] = useDeleteResumeMutation();
  const { isProfileOwner } = useIsProfileOwnerContext();
  const { id } = useParams();
  const { data: profile } = useGetFreelancerPublicProfileQuery(
    id ? Number(id) : skipToken,
  );
  const hasUploadedResume = profile?.resume;
  const freelancerId = profile?.id;

  const dispatch = useAppDispatch();

  const deleteResume = async () => {
    if (freelancerId) {
      await deleteResumeMutation({ id: freelancerId });
      await dispatch(fetchFreelancerProfile(freelancerId));
    }
  };
  const openConfirmationModal = () => {
    openDeleteResumeModal({
      onConfirm: deleteResume,
    });
  };

  if (!hasUploadedResume || !isProfileOwner) return null;

  return (
    <Button
      variant="transparent"
      startIcon={<TrashIcon />}
      onClick={openConfirmationModal}
    >
      Delete
    </Button>
  );
};
