import {
  PROPOSAL_CANCEL,
  PROPOSAL_SUBMITTED,
  SUBMIT_PROPOSAL_CLICKED,
  UPDATE_PROPOSAL_STATUS,
} from "@js/apps/jobs/action-types";
import { CANCEL_OFFER } from "@js/apps/jobs/apps/offers/action-types";
import type { TrackableUserAction } from "@js/middlewares/analytics/types";
import { getKeysOfAppliedURLParams } from "@js/middlewares/analytics/utils";
import { Events, EventsProperties } from "@js/services/analytics";
import { logEvent } from "@js/services/analytics/event-logging";
import { dateDifference, round } from "@js/utils";

const proposalViewedDateMap = new Map();
const appliedJobFiltersWhileCreatingProposal = new Map();

export const handleBidsAnalyticsActions = (action: TrackableUserAction) => {
  switch (action.type) {
    case SUBMIT_PROPOSAL_CLICKED: {
      const { job, search } = action.payload;
      const appliedFilters = getKeysOfAppliedURLParams(search);
      proposalViewedDateMap.set(job.id, new Date().toISOString());
      appliedJobFiltersWhileCreatingProposal.set(job.id, appliedFilters);
      logEvent(Events.START_PROPOSAL, {
        job_id: job.id,
        [EventsProperties.filter_applied_type]: appliedFilters,
      });
      break;
    }

    case PROPOSAL_SUBMITTED: {
      const proposal = action.payload;
      const proposalViewedAt = proposalViewedDateMap.get(proposal.job.id);
      const appliedJobFilters = appliedJobFiltersWhileCreatingProposal.get(
        proposal.job.id,
      );
      const currentDate = new Date().toISOString();
      const timeBetweenViewAndProposalSubmission = round(
        dateDifference(currentDate, proposalViewedAt, "minutes"),
        0.25,
      );

      logEvent(Events.SUBMIT_PROPOSAL, {
        bid_id: proposal.id,
        proposal_submitted_time: timeBetweenViewAndProposalSubmission,
        [EventsProperties.filter_applied_type]: appliedJobFilters,
      });
      break;
    }

    case PROPOSAL_CANCEL: {
      const { id } = action.payload;

      logEvent(Events.CANCEL_PROPOSAL, { bid_id: id });
      break;
    }

    case UPDATE_PROPOSAL_STATUS: {
      const proposal = action.payload;

      if (proposal.status === ENUMS.BidStatus.APPLICATION_CANCELED) {
        logEvent(Events.DECLINE_PROPOSAL, { bid_id: proposal.id });
      } else if (proposal.status === ENUMS.BidStatus.INTERVIEWING) {
        logEvent(Events.IN_INTERVIEWING_STATUS, { bid_id: proposal.id });
      }
      break;
    }

    case CANCEL_OFFER: {
      const offer = action.payload;
      logEvent(Events.CANCEL_OFFER, { offer_id: offer.id });
      break;
    }

    default: {
      break;
    }
  }
};
