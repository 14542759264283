import { useMemo } from "react";

import { useUser } from "@js/apps/common/hooks";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { useSendInterviewScheduleLinkMutation } from "../../api";
import type { SchedulingFormData } from "../../forms";

export const useSendSchedulingLink = ({
  bid,
  job,
}: {
  bid: EmployerBidListBid;
  job: Job;
}) => {
  const user = useUser();
  const [sendInterviewScheduleLink] = useSendInterviewScheduleLinkMutation();

  const onSubmit = (values: SchedulingFormData) => {
    return sendInterviewScheduleLink({
      bidId: bid.id,
      calendar_link: values.calendar_link || "",
      save_as_default: values.save_as_default,
      same_link_for_all: !!values.same_link_for_all,
    }).unwrap();
  };

  const initialValues: SchedulingFormData = useMemo(() => {
    return {
      calendar_link:
        job?.extension?.employer_calendar_link || user?.calendar_link,
      save_as_default: false,
      freelancer_id: bid.freelancer.id,
      same_link_for_all: false,
    };
  }, [bid, job, user]);

  return {
    onSubmit,
    initialValues,
    hasCalendarLink: !!initialValues.calendar_link,
    freelancer: bid.freelancer,
  };
};
