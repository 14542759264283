export const SET_POSTS_TO_SHOW_PULSE_ANIMATION =
  "SET_POSTS_TO_SHOW_PULSE_ANIMATION";
export const TOGGLE_HIDE_REQUEST_LOADER = "TOGGLE_HIDE_REQUEST_LOADER";
export const FORCE_PAGE_REMOUNT = "FORCE_PAGE_REMOUNT";
export const FETCH_INDUSTRIES_SUCCESS = "FETCH_INDUSTRIES_SUCCESS";
export const SEARCH_TAXONOMY_SUCCESS = "SEARCH_TAXONOMY_SUCCESS";
export const SHOW_LEFT_SIDEBAR = "SHOW_LEFT_SIDEBAR";
export const HIDE_LEFT_SIDEBAR = "HIDE_LEFT_SIDEBAR";
export const SET_LAYOUT_BG_COLOR = "SET_LAYOUT_BG_COLOR";
export const FETCH_POPULAR_LOCATION_OPTIONS_SUCCESS =
  "FETCH_POPULAR_LOCATION_OPTIONS_SUCCESS";
export const CTA_CLICKED_EVENT = "CTA_CLICKED_EVENT";
export const SET_ACTIVE_MESSENGER_ROOM = "SET_ACTIVE_MESSENGER_ROOM";
