import type { JobSkill } from "@js/types/jobs";

export const getBackgroundColor = (
  logo_background_color: string | null,
  logo_thumbnail?: string,
) => {
  const backgroundColor = `var(${logo_background_color || "--soft-violet"})`;
  const logoThumbnailBg = logo_background_color
    ? `var(${logo_background_color})`
    : "transparent";

  const logoBackgroundColor = logo_thumbnail
    ? logoThumbnailBg
    : `var(${"--medium-violet"})`;
  return { backgroundColor, logoBackgroundColor };
};

export const getSkillForNotFreelancerView = (skills: JobSkill[]) => {
  const topSkills = skills
    ?.filter((skill) => skill.is_top)
    ?.map(({ skill }) => skill);
  const isAnyTopSkills = topSkills?.length > 0 || false;
  const skillsToDisplayForNotFreelancerView = isAnyTopSkills
    ? topSkills
    : skills?.map(({ skill }) => skill)?.slice(0, 4);

  return skillsToDisplayForNotFreelancerView;
};

export const jobBudgetTooltipTitle = (
  type: EnumType<typeof ENUMS.JobPaymentType>,
): string => {
  switch (type) {
    case ENUMS.JobPaymentType.FIXED_PRICE:
      return (
        "This job pays a fixed rate, which may be paid via recurring invoices. " +
        "Final pay details are discussed with the Client."
      );
    case ENUMS.JobPaymentType.HOURLY:
      return "This is the posted hourly rate. Final pay details are discussed with the Client";
    case ENUMS.JobPaymentType.ANNUAL:
      return "This job pays a yearly salary. Final pay details are discussed with the Client.";
    default:
      return "";
  }
};
