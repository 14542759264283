import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useAppSelector } from "@js/hooks/redux";

import { FILE_UPLOAD_STATE_STATUS } from "../../constants";
import type {
  FileId,
  FileUploadWithMetaState,
  FileWithId,
  UploadFileResponse,
} from "../../types";

// uploads state is broadcasted through websocket conenction
// we need to monitor the uploadsStatusUpdates to determine the files upload status

export const useUploadsState = () => {
  const [fileUploadsState, setFileUploadsState] = useState<
    Omit<FileUploadWithMetaState, "file">[]
  >([]);
  const uploadTimeoutsRef = useRef<Record<string, number>>({});

  // do not use websocket attachListener as it would require ...
  // ... storing all of the uploads updates in each instance of the hook.
  // Websocket can broadcast status updates even before we receive the ...
  // ... uuid for the file we just triggered the upload on
  const uploadsStatusUpdates = useAppSelector(
    (state) => state.uploads.uploadsStatusUpdates,
  );

  const mergedFileUploadsState: FileUploadWithMetaState[] = useMemo(() => {
    return fileUploadsState.map((fileUploadState) => {
      if (!fileUploadState.uuid) {
        return fileUploadState;
      }

      const isErrorOnFileUploadState =
        fileUploadState.status === FILE_UPLOAD_STATE_STATUS.ERROR;
      if (isErrorOnFileUploadState) {
        return fileUploadState;
      }

      const currentFileStatusUpdate = uploadsStatusUpdates.find(
        (uploadStatusUpdate) =>
          uploadStatusUpdate.uuid === fileUploadState.uuid,
      );
      if (!currentFileStatusUpdate) {
        return fileUploadState;
      }

      if (
        !currentFileStatusUpdate.success ||
        !currentFileStatusUpdate.attachment ||
        !currentFileStatusUpdate.id
      ) {
        return {
          ...fileUploadState,
          status: FILE_UPLOAD_STATE_STATUS.ERROR,
          errorMessage:
            currentFileStatusUpdate.message ?? "Failed to upload file.",
        };
      }

      return {
        ...fileUploadState,
        status: FILE_UPLOAD_STATE_STATUS.COMPLETED,
        file: {
          id: currentFileStatusUpdate.id,
          attachment: currentFileStatusUpdate.attachment,
        },
      };
    });
  }, [uploadsStatusUpdates, fileUploadsState]);

  const clearUploadTimeout = useCallback(
    (fileId: FileId) => {
      if (typeof uploadTimeoutsRef.current[fileId] === "undefined") {
        return;
      }
      clearTimeout(uploadTimeoutsRef.current[fileId]);
      delete uploadTimeoutsRef.current[fileId];
    },
    [uploadTimeoutsRef],
  );

  const removeFileUpload = useCallback(
    (fileId: FileId) => {
      setFileUploadsState((prev) =>
        prev.filter((uploadState) => uploadState.fileId !== fileId),
      );
      clearUploadTimeout(fileId);
    },
    [clearUploadTimeout],
  );

  const resetFileUploadsState = useCallback(() => {
    setFileUploadsState([]);
    Object.keys(uploadTimeoutsRef.current).forEach((fileId) => {
      clearUploadTimeout(fileId);
    });
  }, [clearUploadTimeout]);

  const onPrepareUploadStart = useCallback(
    (files: FileWithId[], uploadTimeoutMs?: number) => {
      setFileUploadsState((previousFileUploadsState) => {
        const processedPreviousUploadsState = previousFileUploadsState.map(
          (uploadState) => {
            const previousMergedState = mergedFileUploadsState.find(
              (mergedFileUploadState) =>
                mergedFileUploadState.fileId === uploadState.fileId,
            );
            const previousStatus = previousMergedState
              ? previousMergedState.status
              : uploadState.status;
            const isStillUploading =
              previousStatus === FILE_UPLOAD_STATE_STATUS.UPLOADING;

            return {
              ...uploadState,
              isLatestUpload: isStillUploading,
            };
          },
        );

        return [
          ...processedPreviousUploadsState,
          ...files.map((file) => ({
            fileId: file.fileId,
            name: file.name,
            isLatestUpload: true,
            status: FILE_UPLOAD_STATE_STATUS.UPLOADING,
          })),
        ];
      });

      if (!uploadTimeoutMs) {
        return;
      }

      files.forEach((file) => {
        uploadTimeoutsRef.current[file.fileId] = window.setTimeout(() => {
          setFileUploadsState((previousFileUploadsState) => {
            const currentFileUploadStateIndex =
              previousFileUploadsState.findIndex(
                (previousUploadState) =>
                  previousUploadState.fileId === file.fileId,
              );

            const currentFileUploadState =
              previousFileUploadsState[currentFileUploadStateIndex];
            if (currentFileUploadStateIndex === -1 || !currentFileUploadState) {
              return previousFileUploadsState;
            }

            const uploadTimeoutFileUploadState: FileUploadWithMetaState = {
              ...currentFileUploadState,
              status: FILE_UPLOAD_STATE_STATUS.ERROR,
              errorMessage: "File upload timed out. Please try again.",
            };

            const fileUploadStateCopy = [...previousFileUploadsState];
            fileUploadStateCopy.splice(
              currentFileUploadStateIndex,
              1,
              uploadTimeoutFileUploadState,
            );

            return fileUploadStateCopy;
          });
        }, uploadTimeoutMs);
      });
    },
    [mergedFileUploadsState],
  );

  const onPrepareUploadFinish = useCallback(
    (
      finishedUploadsPrepareData: Array<{
        fileId: FileId;
        uploadResponse: UploadFileResponse;
      }>,
    ) => {
      setFileUploadsState((previousUploadsState) =>
        previousUploadsState.map((uploadState) => {
          if (uploadState.status === FILE_UPLOAD_STATE_STATUS.ERROR) {
            return uploadState;
          }

          const finishedUploadPrepareData = finishedUploadsPrepareData.find(
            ({ fileId }) => fileId === uploadState.fileId,
          );
          if (!finishedUploadPrepareData) {
            return uploadState;
          }

          const { uploadResponse } = finishedUploadPrepareData;
          const uploadStatus = getUploadStatus(uploadResponse);

          return {
            ...uploadState,
            status: uploadStatus,
            uuid: uploadResponse.uuid,
            errorMessage:
              uploadStatus === FILE_UPLOAD_STATE_STATUS.ERROR
                ? uploadResponse.error
                : undefined,
          };
        }),
      );
    },
    [],
  );

  useEffect(() => {
    mergedFileUploadsState.forEach((mergedFileUploadState) => {
      const { fileId } = mergedFileUploadState;
      if (typeof uploadTimeoutsRef.current[fileId] === "undefined") {
        return;
      }

      const shouldClearTimeout =
        mergedFileUploadState.status !== FILE_UPLOAD_STATE_STATUS.UPLOADING;
      if (!shouldClearTimeout) {
        return;
      }

      clearTimeout(uploadTimeoutsRef.current[fileId]);
      delete uploadTimeoutsRef.current[fileId];
    });
  }, [mergedFileUploadsState, uploadTimeoutsRef]);

  // cleanup effect
  useEffect(
    () => () => {
      Object.values(uploadTimeoutsRef.current).forEach((timeout) => {
        clearTimeout(timeout);
      });
    },
    [],
  );

  const uploading = useMemo(
    () =>
      fileUploadsState.some(
        ({ status }) => status === FILE_UPLOAD_STATE_STATUS.UPLOADING,
      ),
    [fileUploadsState],
  );

  return {
    uploading,
    fileUploadsState: mergedFileUploadsState,
    removeFileUpload,
    resetFileUploadsState,
    onPrepareUploadStart,
    onPrepareUploadFinish,
  };
};

const getUploadStatus = (uploadResponse: UploadFileResponse) => {
  if (uploadResponse.cancelled) {
    return FILE_UPLOAD_STATE_STATUS.NONE;
  }
  if (uploadResponse.error) {
    return FILE_UPLOAD_STATE_STATUS.ERROR;
  }

  return FILE_UPLOAD_STATE_STATUS.UPLOADING;
};
