import { Box, Rating, Typography } from "@hexocean/braintrust-ui-components";
import { ChatIcon, PersonIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useScrollToReviewSection } from "@js/apps/freelancer/hooks";
import { Wrapper } from "@js/apps/reviews/components/talent-profile-review-module/reviews-top-bar";
import { ModalInstance } from "@js/components/modal";
import type { TalentReviewsSummary } from "@js/types/reviews";
import { pluralize } from "@js/utils";

type ReviewsTopBarProps = {
  reviewsSummary?: TalentReviewsSummary;
};

export const ReviewsStatsBar = ({ reviewsSummary }: ReviewsTopBarProps) => {
  const scrollToReviewSection = useScrollToReviewSection();

  if (!reviewsSummary) return null;

  const { reviews_count, average_rating } = reviewsSummary;

  const handleAllReviewsClick = () => {
    ModalInstance.close();
    scrollToReviewSection();
  };

  return (
    <div className="reviews-stats-bar">
      <Wrapper>
        <Rating
          variant="stars"
          size="small"
          precision={0.5}
          value={Number(average_rating)}
          className="reviews-stats-bar__rating-stars"
          readOnly
        />
        <Typography
          component="h3"
          variant="paragraph"
          size="small"
          color="grey-1"
        >
          {average_rating}
        </Typography>
      </Wrapper>

      <Box display="flex" gap={2}>
        <Wrapper
          icon={<ChatIcon style={{ fontSize: "23px" }} />}
          label={`${reviews_count} review${pluralize(reviews_count, {
            zeroPlural: true,
          })}`}
        />

        <Wrapper
          icon={<PersonIcon />}
          label={`${reviewsSummary.completed_help_offers_count} helped`}
        />

        {Number(reviews_count) > 0 && (
          <Wrapper>
            <Typography
              className="reviews-stats-bar__all-reviews-link"
              onClick={handleAllReviewsClick}
              component="span"
              size="small"
            >
              All reviews
            </Typography>
          </Wrapper>
        )}
      </Box>
    </div>
  );
};
