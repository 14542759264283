import React from "react";
import { Field } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useIsNodeStaff } from "@js/apps/common/hooks/is-node-staff";
import { useIsEmployerImpersonated } from "@js/apps/jobs/hooks/employer-impersonated-session";
import { createFormInstance } from "@js/forms/components";
import { RadioListField } from "@js/forms/fields";
import { requiredWithSpecificMessage } from "@js/forms/validators";

import { JOB_CLOSE_FIELDS } from "./close-job-constants";

export const UNSURE_CLIENT_UNREACHABLE = "unsure_client_unreachable";

export type CloseJobFormData = {
  client_feedback_was_job_filled:
    | boolean
    | null
    | typeof UNSURE_CLIENT_UNREACHABLE;
};

const CloseJobFormInstance = createFormInstance<CloseJobFormData, unknown>(
  "close-job-form",
);

const JOB_FILLED_OPTIONS = [
  {
    label: "Yes, this job was filled",
    value: true,
  },
  {
    label: "No, this job was not filled",
    value: false,
  },
];

type CloseJobFormProps = {
  onCancel: () => void;
  onSubmit: (values: CloseJobFormData) => Promise<void>;
  initialValues: CloseJobFormData;
};

export const CloseJobForm = ({
  onCancel,
  onSubmit,
  initialValues,
}: CloseJobFormProps) => {
  const isNodeStaff = useIsNodeStaff();
  const isEmployerImpersonated = useIsEmployerImpersonated();

  const options =
    isNodeStaff || isEmployerImpersonated
      ? [
          ...JOB_FILLED_OPTIONS,
          {
            label: "Unsure - Client is unreachable",
            value: UNSURE_CLIENT_UNREACHABLE,
          },
        ]
      : JOB_FILLED_OPTIONS;

  return (
    <CloseJobFormInstance onSubmit={onSubmit} initialValues={initialValues}>
      <Box className="close-job-form-body">
        <Box>
          <Typography
            component="h1"
            variant="title"
            size="medium"
            pt={3}
            pb={4}
          >
            Before you close this job...
          </Typography>
          <Typography
            component="p"
            variant="paragraph"
            size="medium"
            fontWeight={500}
            pb={2}
          >
            Let us know if it was filled.
          </Typography>
          <Field
            name={JOB_CLOSE_FIELDS.client_feedback_was_job_filled}
            options={options}
            component={RadioListField}
            borderRadio={false}
            yellowOutline
            errorStyle={{
              marginTop: "-15px",
              marginBottom: "15px",
            }}
            validate={[requiredWithSpecificMessage("Please select an option")]}
          />
        </Box>
        <Box className="close-job-form-body__buttons">
          <Button variant="secondary" size="medium" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" size="medium" type="submit">
            Next
          </Button>
        </Box>
      </Box>
    </CloseJobFormInstance>
  );
};
