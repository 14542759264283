import type { WrappedFieldsProps } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { FieldSectionPanel } from "@js/apps/jobs/forms/fields";
import { useGetRoleName } from "@js/apps/roles/hooks";
import { OptionTileButtons } from "@js/components/option-tile-buttons";
import { shouldDisplayError } from "@js/forms/utils";
import { enumToOptions } from "@js/utils";

const EXPERIENCE_LEVEL_OPTIONS = enumToOptions(ENUMS.JobExperienceLevelLabels);

export const ExperienceField = ({
  experience_level,
  role,
}: WrappedFieldsProps) => {
  const { input, meta } = experience_level;

  const displayError = shouldDisplayError(experience_level);

  const roleId = role.input.value;
  const roleName = useGetRoleName(roleId);

  return (
    <FieldSectionPanel
      title="Experience"
      id="experience"
      description="Talent's years of experience"
      isError={displayError}
    >
      {displayError && (
        <Typography mb={2} component="p" variant="paragraph" error>
          {meta.error}
        </Typography>
      )}

      <Typography component="p" mb={2}>
        Select number of years in{" "}
        <Typography component="span" variant="label">
          {roleName}
        </Typography>
      </Typography>

      <OptionTileButtons
        value={input.value}
        onChange={input.onChange}
        options={EXPERIENCE_LEVEL_OPTIONS}
      />
    </FieldSectionPanel>
  );
};
