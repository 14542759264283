import type { AnyAction } from "redux";

import * as actionTypes from "./action-types";

type ModalSliderReduxState = {
  itemIndex: Record<string, number>;
};

const INITIAL_STATE: ModalSliderReduxState = {
  itemIndex: {},
};

export default function (
  state = INITIAL_STATE,
  action: AnyAction,
): ModalSliderReduxState {
  switch (action.type) {
    case actionTypes.SET_CURRENT_ITEM_INDEX:
      return {
        ...state,
        itemIndex: {
          ...state.itemIndex,
          [action.payload.modalSliderId]: action.payload.index,
        },
      };

    default:
      return state;
  }
}
