export enum PostFooterIcons {
  "post-file" = "post-file",
  "post-image" = "post-image",
  "post-link" = "post-link",
  "post-poll" = "post-poll",
}

export type PostFooterAction = `${PostFooterIcons}`;
export type ReplyFooterActions = "add-offer" | "add-attachment-reply";

export type FooterActions = PostFooterAction | ReplyFooterActions;
