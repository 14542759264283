import { useDeletePostMutation } from "../../api";

export const useFixedDeletePostMutation = ({
  postId,
}: {
  postId: number | undefined;
}) => {
  return useDeletePostMutation({
    fixedCacheKey: postId ? `DELETE_POST_${postId}` : undefined,
  });
};
