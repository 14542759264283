import { useMemo, useState } from "react";
import { Field } from "redux-form";

import { ClockIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useHandleFilterApplied } from "@js/apps/freelancer/hooks/use-handle-filter-applied";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components/job-popover-filter-button";
import { PossibleFilters } from "@js/types/common";

import {
  useJobFilters,
  type WorkingTimezoneJobs,
  WorkingTimezoneJobsForm,
} from "../../config";

import { useWorkAvailability } from "./hooks";
import {
  fieldName,
  WorkingTimezoneJobsField,
} from "./working-timezone-jobs-field";

const formId = "filters__working_timezone_jobs";

export const WorkingTimezoneJobsFilter = () => {
  const { workAvailability } = useWorkAvailability();
  const [isOpen, setIsOpen] = useState(false);
  const { filters } = useJobFilters();

  const { handleFilterApplied } = useHandleFilterApplied();
  const handleSubmitSideAction = (values: WorkingTimezoneJobs) => {
    handleFilterApplied({
      filter_type: PossibleFilters.WORKING_TIMEZONE,
      filter_value: values,
    });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const isActive = !!filters.match_my_working_hours;
  const label = useMemo(
    () => getLabel(isActive, workAvailability),
    [isActive, workAvailability],
  );

  return (
    <JobPopoverFilterButton
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      PaperProps={{ sx: { maxHeight: "580px", width: "520px" } }}
      isActive={isActive}
      label={label}
      startIcon={<ClockIcon />}
      filterDisabled={!workAvailability}
      tooltipText={
        !workAvailability
          ? "You must set your work availability or provide location in the account settings"
          : undefined
      }
      popoverContent={
        <>
          <WorkingTimezoneJobsForm
            form={formId}
            onSubmitSuccess={() => {
              setIsOpen(false);
            }}
            onSubmitSideAction={handleSubmitSideAction}
          >
            {({ submit }) => (
              <Field
                name={fieldName}
                component={WorkingTimezoneJobsField}
                submit={submit}
                close={handleClose}
                workAvailability={workAvailability as string}
              />
            )}
          </WorkingTimezoneJobsForm>
        </>
      }
    />
  );
};

const getLabel = (isActive: boolean, workAvailability?: string) => {
  if (!isActive || !workAvailability) {
    return "Working hours";
  }

  return workAvailability;
};
