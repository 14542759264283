import { REPORT_REASONS } from "@js/apps/common/constants";
import { ReportForm } from "@js/apps/common/forms/report";
import type { ReportPayload } from "@js/apps/give-and-get-help/hooks";
import { ModalInstance } from "@js/components/modal";

import styles from "./styles.module.scss";

export const openReportReviewModalInstance = (
  onReportReview: (reason: ReportPayload) => void,
) =>
  ModalInstance.open({
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
    },
    keepOnBackdropClick: true,
    className: styles.reportModal,
    children: (
      <ReportForm
        type="review"
        onSubmit={(reportReason) => onReportReview(reportReason)}
        reasons={REPORT_REASONS}
        closeModal={ModalInstance.close}
      />
    ),
  });
