import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useCtaClickedContext } from "@js/apps/common/context";
import type { Article } from "@js/apps/freelancer/components/dashboard/news-and-articles-section/types";
import { RouterLink } from "@js/components/link";

type FeaturedProfileArticleProps = {
  article: Article;
};

export const FeaturedProfileArticle = ({
  article,
}: FeaturedProfileArticleProps) => {
  const { ctaClicked } = useCtaClickedContext();
  const isMobile = useMediaQuery("sm");
  const isTablet = useMediaQuery("md");
  const showMobileLayout = isMobile || isTablet;

  return (
    <div className="news-and-articles-section__featured-profile">
      <div
        className="news-and-articles-section__image news-and-articles-section__image--featured"
        style={{ backgroundImage: `url(${article.feature_image})` }}
      />
      <Box width="auto" mt={2} sx={{ wordBreak: "break-word" }}>
        <Typography component="p" variant="paragraph" size="small">
          Featured
        </Typography>
        <Typography
          component="h4"
          variant="label"
          size={showMobileLayout ? "medium" : "large"}
        >
          {article.title}
        </Typography>
        {article.cta_link && (
          <Box mt={2.4}>
            <Button
              variant="primary"
              size="small"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                ctaClicked(ENUMS.UserClicksEventCTAName.READ_ARTICLE_OR_NEWS)
              }
              href={article.cta_link}
              RouterLink={RouterLink}
            >
              {article.cta_text}
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
};
