import { useMemo } from "react";

import { useAppSelector } from "@js/hooks";

import {
  canBeInterviewed,
  getBids,
} from "../../components/bulk-select-actions/bid-bulk-selection-slice";

export const useSelectedInterviewableBids = () => {
  const bids = useAppSelector(getBids);
  const selectedInterviewableBids = useMemo(
    () => bids.filter((bid) => canBeInterviewed(bid)),
    [bids],
  );

  return { selectedInterviewableBids };
};
