import { API } from "@js/api";
import type { SearchEvent } from "@js/middlewares/analytics/user-tracking";

const searchTrackingApi = API.injectEndpoints({
  endpoints: (build) => ({
    sendSearchEvent: build.mutation<SearchEvent & { id: number }, SearchEvent>({
      query: (searchEventPayload) => {
        return {
          url: `/search_events/`,
          method: "POST",
          data: { ...searchEventPayload },
        };
      },
    }),
  }),
});

export const { useSendSearchEventMutation } = searchTrackingApi;
