import React from "react";

import { Button } from "@hexocean/braintrust-ui-components";

type FiltersClearAllButtonProps = {
  onClick: () => void;
  text?: string;
};

export const FiltersClearAllButton = ({
  text,
  onClick,
}: FiltersClearAllButtonProps) => {
  return (
    <Button
      variant="white-grey"
      shape="squared"
      size="x-small"
      fontWeight={400}
      onClick={onClick}
    >
      {text || "Clear all"}
    </Button>
  );
};
