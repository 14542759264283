import type { RefObject } from "react";
import { useEffect, useState } from "react";
import { debounce } from "underscore";

import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";

export const useBookmarksIntroModalPosition = (
  anchorRef: RefObject<HTMLButtonElement>,
) => {
  const isMobile = useMediaQuery("sm");

  const [modalPosition, setModalPosition] = useState({
    top: "auto",
    right: "auto",
  });

  useEffect(() => {
    const getModalPosition = () => {
      if (anchorRef.current) {
        const rect = anchorRef.current.getBoundingClientRect();
        const introTop = rect.bottom;
        const introRight = window.innerWidth - rect.right;

        setModalPosition({
          top: `${introTop}px`,
          right: isMobile ? "auto" : `${introRight}px`,
        });
      }
    };

    const handleResize = debounce(getModalPosition, 100);

    getModalPosition();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile, anchorRef]);

  return { modalPosition, anchorRef };
};
