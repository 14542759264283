import type React from "react";
import { useCallback, useState } from "react";
import _ from "underscore";

export const useScrollTop = <T extends HTMLElement>() => {
  const [scrollTop, setScrollTop] = useState(0);

  const setScrollTopHandler = _.throttle(
    (input: number) => {
      setScrollTop(input);
    },
    100,
    { leading: true, trailing: true },
  );

  const onScroll = useCallback((event: React.UIEvent<T>) => {
    setScrollTopHandler(event.currentTarget.scrollTop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { scrollTop, onScroll };
};
