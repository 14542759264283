import React from "react";

import {
  Button,
  Card,
  IconButton,
  Menu,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useGetEmployerCurrentOfferDepositQuery } from "@js/apps/employer/hooks/get-employer-current-offer-deposit";
import type { PaymentMethod, StripeACHPayment } from "@js/types/payments";

import PaymentMethodVendorImage from "../vendor-image";

import StripeACHBankAccount from "./card-type/stripe-ach-bank-account";
import StripeCreditDebitCard from "./card-type/stripe-credit-debit-card";

import styles from "./style.module.scss";

export type PaymentMethodListItemProps = {
  paymentMethod: PaymentMethod;
  hideVerifyButton?: boolean;
  onDelete?: (paymentMethod: PaymentMethod) => void;
  onVerifyStripeACHPaymentMethod?: (paymentMethod: StripeACHPayment) => void;
  onSetAsDefault?: (paymentMethod: PaymentMethod) => void;
};

export const PaymentMethodListItem = ({
  paymentMethod,
  hideVerifyButton = false,
  onDelete,
  onSetAsDefault,
  onVerifyStripeACHPaymentMethod,
}: PaymentMethodListItemProps) => {
  const { data: offerDeposit } = useGetEmployerCurrentOfferDepositQuery();
  const currentOfferDeposit = offerDeposit?.current_offer_deposit;

  const isDeleteOptionDisabled =
    !!currentOfferDeposit &&
    currentOfferDeposit.payment_method_id === paymentMethod.id;

  return (
    <Card className={styles.paymentMethodsListItem} elevation={0}>
      <div className={styles.paymentMethodsListItemHeader}>
        <PaymentMethodVendorImage
          paymentMethod={paymentMethod}
          className={styles.paymentMethodsListItemVendorLogo}
        />
        {paymentMethod.content_type ===
          ENUMS.PaymentContentType.StripeACHPaymentMethod &&
          !paymentMethod.method.verified_at && (
            <Typography
              component="p"
              size="small"
              className={styles.paymentMethodsListItemDetailsItemWarning}
            >
              Payment Method Not Verified
            </Typography>
          )}
        <Menu
          id={`payment-method-${paymentMethod.id}-details-menu`}
          anchor={
            <IconButton
              aria-controls={`payment-method-${paymentMethod.id}-details-menu`}
              aria-haspopup="true"
              aria-label="more menu"
              variant="transparent"
            >
              <MoreVertIcon />
            </IconButton>
          }
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
        >
          <Menu.Item
            key="delete"
            disabled={isDeleteOptionDisabled}
            tooltipText="Cannot remove payment method used in the deposit. Please change the deposit payment method and then remove this one."
            onClick={() => {
              if (onDelete) {
                onDelete(paymentMethod);
              }
            }}
          >
            Delete
          </Menu.Item>
          {paymentMethod.can_be_set_as_default && !paymentMethod.is_default && (
            <Menu.Item
              key="default"
              onClick={() => {
                if (onSetAsDefault) {
                  onSetAsDefault(paymentMethod);
                }
              }}
            >
              Make Default
            </Menu.Item>
          )}
        </Menu>
      </div>
      <div className={styles.paymentMethodsListItemDetails}>
        {paymentMethod.content_type ===
        ENUMS.PaymentContentType.StripeACHPaymentMethod ? (
          <StripeACHBankAccount paymentMethod={paymentMethod} />
        ) : (
          <StripeCreditDebitCard paymentMethod={paymentMethod} />
        )}
      </div>
      {paymentMethod.content_type ===
        ENUMS.PaymentContentType.StripeACHPaymentMethod &&
        !paymentMethod.method.verified_at &&
        onVerifyStripeACHPaymentMethod &&
        !hideVerifyButton && (
          <Button
            size="x-small"
            variant="primary"
            color="secondary"
            className={styles.paymentMethodsListItemDefaultButton}
            onClick={() => onVerifyStripeACHPaymentMethod(paymentMethod)}
          >
            Verify
          </Button>
        )}
      {paymentMethod.is_default && (
        <Button
          disabled
          size="x-small"
          variant="primary"
          color="secondary"
          className={styles.paymentMethodsListItemDefaultButton}
        >
          DEFAULT
        </Button>
      )}
    </Card>
  );
};
