import type { WrappedFieldProps } from "redux-form";

import type { LocationValue } from "@js/components/autocomplete-new/google-places/types";
import { isError } from "@js/forms/utils";

type UseJobLocationFilterFieldManagerProps = {
  work_from_anywhere: WrappedFieldProps;
  work_from_location: WrappedFieldProps;
  location: WrappedFieldProps; // just for input string purpose
  custom_location: WrappedFieldProps; // custom option like United states only, Europe etc
  city: WrappedFieldProps;
  state: WrappedFieldProps;
  country_code: WrappedFieldProps;
};

export const useJobLocationFilterFieldManager = ({
  work_from_anywhere,
  work_from_location,
  location,
  custom_location,
  city,
  state,
  country_code,
}: UseJobLocationFilterFieldManagerProps) => {
  const isWorkFromAnywhereSelected = work_from_anywhere.input.value;
  const isWorkFromLocationSelected =
    work_from_location.input.value && !!location.input.value;

  const handleReset = () => {
    work_from_anywhere.input.onChange(null);
    work_from_location.input.onChange(null);
    location.input.onChange(null);
    custom_location.input.onChange(null);
    city.input.onChange(null);
    state.input.onChange(null);
    country_code.input.onChange(null);
  };

  const error = isError([
    work_from_anywhere,
    work_from_location,
    location, // just for input string purpose
  ]);

  const handleAutocompleteChange = (value: LocationValue) => {
    location.input.onChange(value);
    work_from_location.input.onChange(true);
  };

  return {
    handleReset,
    handleAutocompleteChange,
    error,
    disableApply: error,
    options: {
      isWorkFromAnywhereSelected,
      isWorkFromLocationSelected,
    },
  };
};
