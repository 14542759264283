import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import {
  AttachmentFileIcon,
  AttachmentImageIcon,
  AttachmentLinkIcon,
  PollIcon,
  PostRightIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import styles from "./styles.module.scss";

interface PostTextFieldProps extends PostBtnProps {
  createPostPrompt: string;
  isTablet: boolean;
  hideActionButtons: boolean;
}

export const PostTextField = ({
  createPostPrompt,
  onClick,
  disabled,
  isMobile,
  isTablet,
  hideActionButtons,
}: PostTextFieldProps) => {
  return (
    <Button
      variant="transparent"
      className={styles.textField}
      onClick={onClick}
      disableTouchRipple
      fullWidth
      disabled={disabled}
    >
      <Typography
        component="p"
        size={isMobile ? "small" : "large"}
        color="grey-1"
        ellipsis
      >
        {createPostPrompt}
      </Typography>

      {!hideActionButtons && <AttachmentIcons isTablet={isTablet} />}
    </Button>
  );
};

const AttachmentIcons = ({ isTablet }: { isTablet: boolean }) => {
  if (isTablet) {
    return (
      <Box
        className={styles.createPostSectionMobileIconBox}
        data-testid="attachment-icons"
      >
        <AttachmentFileIcon style={{ width: "16px" }} />
      </Box>
    );
  }

  return (
    <Box
      marginLeft="auto"
      display="flex"
      alignItems="center"
      gap={2}
      data-testid="attachment-icons"
    >
      <AttachmentFileIcon />
      <AttachmentImageIcon />
      <AttachmentLinkIcon />
      <PollIcon />
    </Box>
  );
};

type PostBtnProps = {
  onClick: () => void;
  disabled?: boolean;
  isMobile: boolean;
};

export const PostBtn = ({ onClick, disabled, isMobile }: PostBtnProps) => {
  return (
    <Button
      variant="primary"
      size={isMobile ? "x-small" : "medium"}
      shape="squared"
      onClick={onClick}
      endIcon={
        !isMobile ? (
          <PostRightIcon
            style={{
              fontSize: "25px",
              color: disabled ? "var(--grey-3)" : "var(--white)",
            }}
          />
        ) : undefined
      }
      disabled={!!disabled}
    >
      Post
    </Button>
  );
};
