import { useSyncExternalStore } from "react";

export const useIsTouchDevice = () => {
  return useSyncExternalStore(
    subscribe,
    // the actual value returned when subscription callback is called
    () => window.matchMedia("(pointer: coarse)").matches,
  );
};

const subscribe = (callback: () => void) => {
  // trigger check on resize and on mousedown
  window.addEventListener("mousedown", callback);
  window.addEventListener("resize", callback);
  return () => {
    window.removeEventListener("mousedown", callback);
    window.removeEventListener("resize", callback);
  };
};
