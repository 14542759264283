import { useRef } from "react";

import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";

export const useScrollToHighlightedAnchor = () => {
  const highlightedNote = useRef<HTMLElement>(null);

  useEffectOnce(() => {
    if (highlightedNote.current) {
      highlightedNote.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  });

  return highlightedNote;
};
