import { useMemo, useRef } from "react";
import { destroy } from "redux-form";

import { useUser } from "@js/apps/common/hooks";
import { useCommentSectionContext } from "@js/apps/give-and-get-help/context";
import { usePostsContext } from "@js/apps/give-and-get-help/context/posts";
import {
  EDIT_REPLY_FORM_ID,
  REPLY_FORM_ID,
} from "@js/apps/give-and-get-help/form/constants";
import {
  checkIfCommentLastInTotal,
  createPostOrCommentURL,
  getLinksMetadata,
} from "@js/apps/give-and-get-help/utils";
import { useAppDispatch } from "@js/hooks";
import type { IPost, PostComment } from "@js/types/give-and-get-help";

export type UseCommentProps = {
  postData: IPost;
  comment: PostComment;
  replies: PostComment[] | undefined;
};

export const useComment = ({ postData, comment, replies }: UseCommentProps) => {
  const dispatch = useAppDispatch();
  const {
    replyOpenCommentsIds,
    toggleCommentReplyOpen,
    editedCommentsIds,
    toggleCommentEdit,
    commentsToReplyInThread,
    setCommentToReplyInThread,
  } = usePostsContext();

  const ref = useRef<HTMLDivElement>(null);
  // We only want scroll to the reply form immediately after toggling it, so it is ...
  // ... fine if this value gets reset to false by virtualization
  const scrollCommentReplyFormIntoViewRef = useRef(false);
  const isReplyOpen = useMemo(
    () => replyOpenCommentsIds.includes(comment.id),
    [comment.id, replyOpenCommentsIds],
  );
  const isCommentEdit = useMemo(
    () => editedCommentsIds.includes(comment.id),
    [comment.id, editedCommentsIds],
  );
  const { comments, totalCommentsCount } = useCommentSectionContext();
  const commentToReplyInThread = commentsToReplyInThread[comment.id];
  const user = useUser();
  const freelancerId = user?.freelancer;
  const isOwnComment = freelancerId === comment.freelancer.id;

  const commentUrl = createPostOrCommentURL({
    post: postData,
    commentId: comment.id,
  });
  const commentLinksMetadata = useMemo(
    () => getLinksMetadata(comment.links_metadata),
    [comment.links_metadata],
  );

  const hasAttachments = Boolean(comment.attachments.length);
  const hasLinks = Boolean(comment.links_metadata.length);
  const shouldDisplayPostAttachments = hasAttachments || hasLinks;

  const commentFormId = `${REPLY_FORM_ID}-${comment.id}`;
  const editCommentFormId = `${EDIT_REPLY_FORM_ID}-${comment.id}`;

  const toggleReply = (id: number) => {
    const repliesList = replies ?? comment.comments;
    const commentToReply =
      comment.id === id ? comment : repliesList?.find((el) => el.id === id);

    if (!!commentToReply) {
      scrollCommentReplyFormIntoViewRef.current = true;
      setCommentToReplyInThread({
        commentId: comment.id,
        commentToReplyInThread: commentToReply,
      });
    }

    toggleCommentReplyOpen({ isOpen: !isReplyOpen, commentId: comment.id });
    toggleCommentEdit({ isEditing: false, commentId: comment.id });

    const isClosingReply = isReplyOpen;
    if (isClosingReply) {
      dispatch(destroy(commentFormId));
    }
  };

  const handleEditClick = () => {
    toggleCommentEdit({ isEditing: true, commentId: comment.id });
    toggleCommentReplyOpen({ isOpen: false, commentId: comment.id });
  };

  const handleEditReplyClose = () => {
    toggleCommentEdit({ isEditing: false, commentId: comment.id });
    dispatch(destroy(editCommentFormId));
  };

  const commentContextValue = useMemo(() => {
    if (commentToReplyInThread) return { comment: commentToReplyInThread }; // for 2 level comments
    return { comment };
  }, [comment, commentToReplyInThread]);

  const isPostSignupPromptDisplayed =
    checkIfCommentLastInTotal(comment.id, comments) && totalCommentsCount === 4;

  return {
    shouldDisplayPostAttachments,
    isPostSignupPromptDisplayed,
    isReplyOpen,
    isCommentEdit,
    toggleReply,
    handleEditClick,
    handleEditReplyClose,
    commentToReplyInThread,
    commentUrl,
    commentLinksMetadata,
    commentContextValue,
    ref,
    isOwnComment,
    commentFormId,
    editCommentFormId,
    scrollCommentReplyFormIntoView: scrollCommentReplyFormIntoViewRef.current,
  };
};
