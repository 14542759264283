import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { Sanitize } from "@js/components/sanitize";
import { UserAvatar } from "@js/components/user-avatar";
import type { User } from "@js/types/auth";
import type { FreelancerOffer } from "@js/types/jobs";

import { EmployerStatusBox } from "../offer-status-preview/employer-status-box";

type EmployerMessageProps = {
  offer: FreelancerOffer;
};

export const EmployerMessage = ({ offer }: EmployerMessageProps) => {
  if (!offer.note_to_talent) {
    return null;
  }
  const employerNames = getInitialsFromPublicName(offer.creator?.public_name);

  return (
    <EmployerStatusBox
      color="green"
      label="📬  Message from employer"
      description={
        <>
          <Typography component="div" size="large" mt={0} mb={2}>
            <Sanitize
              input={offer.note_to_talent}
              config={{ ALLOWED_TAGS: ["p"] }}
              whiteSpacePreLine
            />
          </Typography>
          <Box display="flex" gap={3}>
            {offer.creator && (
              <UserAvatar
                size="md"
                hideBadge
                user={{
                  ...employerNames,
                  ...(offer.creator as User),
                }}
              />
            )}
            <Box overflow="hidden">
              <Typography component="h5" variant="label" multipleEllipsis>
                {getCreatorCaption(offer)}
              </Typography>
              <Typography component="p" size="small">
                {offer.creator?.title}
              </Typography>
            </Box>
          </Box>
        </>
      }
    />
  );
};

const getCreatorCaption = (offer: FreelancerOffer) => {
  if (offer.job.creator?.first_name) {
    return `${offer.job.creator.first_name}, ${offer.job.employer.name}`;
  }

  if (offer.creator?.public_name) {
    return `${offer.creator?.public_name}, ${offer.job.employer.name}`;
  }

  return `${offer.job.employer.name}`;
};

const getInitialsFromPublicName = (publicName?: string) => {
  if (!publicName) return;
  const publicNameWords = publicName.split(" ");
  return {
    first_name: publicNameWords[0],
    last_name: publicNameWords[1] || null,
  };
};
