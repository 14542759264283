import React from "react";
import type { WrappedFieldsProps } from "redux-form";
import { Fields } from "redux-form";

import type { ToggleDrawer } from "@hexocean/braintrust-ui-components";
import {
  Box,
  Button,
  Divider,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  CloseRoundedIcon,
  MessageWarningIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { ChangesSavedMarker } from "@js/apps/jobs/apps/create-job/components/changes-saved-marker";

import { LocationAndTimezoneFields } from "./location-and-timezone-fields";

type LocationAndTimezoneDrawerContentProps = {
  toggleDrawer: ToggleDrawer;
};

export const LocationAndTimezoneDrawerContent = ({
  toggleDrawer,
}: LocationAndTimezoneDrawerContentProps) => {
  return (
    <Box
      width={{ xs: "calc(100vw)", sm: 480, md: 800 }}
      display="flex"
      flexGrow={1}
      flexDirection="column"
      justifyContent="space-between"
      p={{ xs: 2, sm: 4, md: 5 }}
      gap={3}
    >
      <Box>
        <ChangesSavedMarker
          variant="full"
          sections={[
            "timezones",
            "locations",
            "locations_strongly_required",
            "timezone_overlap",
          ]}
        />
        <Box display="flex" justifyContent="space-between">
          <Typography
            component="h3"
            variant="title"
            fontWeight={400}
            sx={{ mr: 2 }}
            size="small"
          >
            Requirements
          </Typography>
          <Button
            startIcon={<CloseRoundedIcon />}
            shape="squared"
            variant="white-violet"
            onClick={toggleDrawer}
          >
            Close
          </Button>
        </Box>

        <Divider
          sx={{
            mt: 4,
            borderColor: "var(--grey-4)",
            backgroundColor: "var(--grey-4) !important",
          }}
        />

        <LocationAndTimezoneFields />
      </Box>
      <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
        <JobRequirementsWarningCardWrapped />
      </Box>
    </Box>
  );
};

const JobRequirementsWarningCardWrapped = () => {
  return (
    <Fields
      names={["timezones", "locations"]}
      component={JobRequirementsWarningCard}
    />
  );
};

const JobRequirementsWarningCard = (props: WrappedFieldsProps) => {
  const showWarning =
    props.locations?.input?.value?.length ||
    props.timezones?.input?.value?.length;

  if (!showWarning) return null;

  return (
    <Box
      bgcolor="var(--yellow)"
      borderRadius={125}
      width="fit-content"
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={2}
    >
      <MessageWarningIcon sx={{ mr: 1 }} />
      <Typography component="p" variant="paragraph" size="small">
        Adding location and timezone requirements can lower your Talent options
      </Typography>
    </Box>
  );
};
