import { PROF_NET_ONBOARDING_PATHS } from "@js/apps/onboarding/constants";
import type { AccountType, User } from "@js/types/auth";
import { getCurrentPathEncoded } from "@js/utils";

export const getRoutesAvailableForUnverifiedFreelancer = (
  freelancerId?: User["freelancer"],
) => {
  return [
    `/talent/dashboard/welcome/`,
    `/talent/${freelancerId ?? ""}/`,
    "/",
    "/email_confirm/",
    "/auth/logout/",
    "/verify_email/",
  ];
};

export const UNVERIFIED_FREELANCER_NAVIGATE_TO_FROM_RESTRICTED_ROUTE =
  "/email_confirm/";

export const getIndexPageRedirect = (
  userId: number | undefined,
  accountType: AccountType | undefined,
  search: string,
) => {
  if (!userId) {
    return "/auth/login/?next=" + getCurrentPathEncoded();
  }

  if (accountType === ENUMS.AccountType.FREELANCER) {
    return "/talent/dashboard/welcome/" + search;
  }

  if (accountType === ENUMS.AccountType.EMPLOYER) {
    return "/employer/dashboard/welcome/" + search;
  }

  return PROF_NET_ONBOARDING_PATHS.GOALS_PAGE.path;
};

export const BLOCKED_EMPLOYER_NAVIGATE_TO_FROM_RESTRICTED_ROUTE = "/";

export const getRoutesAvailableForBlockedEmployer = (
  employerId?: User["employer"],
) => {
  return [
    `/employer/dashboard/welcome/`,
    `/employer/dashboard/my_jobs/`,
    `/employers/${employerId ?? ""}/`,
    "/",
    "/auth/logout/",
  ];
};
