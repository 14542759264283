import { JobsEmptyState } from "@js/apps/jobs/components/listing/jobs-empty-state";

export const PublicJobsEmptyState = () => {
  return (
    <JobsEmptyState
      title="Sorry, there are no jobs available for that search"
      description="Try removing filters or changing some of your search criteria"
    />
  );
};
