const prefixes = {
  post: "@post/",
  ggh: "@ggh/",
};

export const actionTypes = {
  "footer-action-button-clicked": `${prefixes.post}footer-action-button-clicked`,
  "create/modal-opened": `${prefixes.post}create/modal-opened`,
  "create/modal-closed": `${prefixes.post}create/modal-closed`,
  "create/user-started-typing-title": `${prefixes.post}create/user-started-typing-title`,
  "create/user-started-typing-description": `${prefixes.post}create/user-started-typing-description`,
  "report-clicked": `${prefixes.post}-report-clicked`,
  "report-sent": `${prefixes.post}-report-sent`,
  "copy-clicked": `${prefixes.post}-copy-clicked`,
  "save-post-clicked": `${prefixes.post}-save-post-clicked`,
  viewed: `${prefixes.post}-viewed`,
  expanded: `${prefixes.post}-expanded`,
  collapsed: `${prefixes.post}-collapsed`,
  "user-name-clicked": `${prefixes.post}-user-name-clicked`,
  "create/next-step-clicked": `${prefixes.post}create/next-step-clicked`,
  "create/category-added": `${prefixes.post}create/category-added`,
  "create/budget-added": `${prefixes.post}create/budget-added`,
  "create/user-started-typing-poll-question": `${prefixes.post}-create/user-started-typing-poll-question`,
  "create/user-started-typing-poll-option": `${prefixes.post}-create/user-started-typing-poll-option`,
  "create/finish-create-post": `${prefixes.post}create/finish-create-post`,
  "menu-clicked": `${prefixes.post}post-menu-clicked`,
  "reply-clicked": `${prefixes.post}reply-clicked`,
  "reply-user-started-typing": `${prefixes.post}reply-user-started-typing`,
  "responded-to-post": `${prefixes.post}responded-to-post`,
  "interested-clicked": `${prefixes.post}interested-clicked`,
  "offer-price-clicked": `${prefixes.post}-offer-price-clicked`,
  "career-help-through-my-posts-clicked": `${prefixes.ggh}career-help-through-my-posts-clicked`,
  "view-profile-hover-state-clicked": "view-profile-hover-state-clicked",
  "talent-name-hover-state-clicked": `${prefixes.post}-talent-name-hover-state-clicked`,
  "get-inspired-btn-clicked": `${prefixes.ggh}-get-inspired-btn-clicked`,
  "post-sort-order-clicked": `${prefixes.post}-post-sort-order-clicked`,
} as const;

export type GghActionTypes = (typeof actionTypes)[keyof typeof actionTypes];
