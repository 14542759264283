import type { Action } from "redux";

import {
  ADD_BOOKMARK,
  HIDE_BOOKMARKS_INTRO,
  SHOW_BOOKMARKS_INTRO,
} from "./actions";

export type BookmarksState = {
  isBookmarksIntroModalVisible: boolean;
  bookmarkAdded: boolean | null;
};

const INITIAL_STATE: BookmarksState = {
  isBookmarksIntroModalVisible: false,
  bookmarkAdded: false,
};

export default (state = INITIAL_STATE, action: Action): BookmarksState => {
  switch (action.type) {
    case SHOW_BOOKMARKS_INTRO:
      return {
        ...state,
        isBookmarksIntroModalVisible: true,
      };
    case HIDE_BOOKMARKS_INTRO:
      return {
        ...state,
        isBookmarksIntroModalVisible: false,
      };
    case ADD_BOOKMARK:
      return {
        ...state,
        bookmarkAdded: true,
      };

    default:
      return state;
  }
};
