import React from "react";

import type { SelectChangeEvent } from "@hexocean/braintrust-ui-components";
import {
  CountrySelectInputBase,
  SelectStyled,
} from "@hexocean/braintrust-ui-components";
import { KeyboardArrowDownIcon } from "@hexocean/braintrust-ui-components/Icons";
import { countrySelectOptions } from "@js/utils";

import Flag from "./flag";

type CountrySelectProps = {
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  country: typeof SETTINGS.COUNTRY_ISO2_TO_COUNTRY_DATA;
};

export const CountrySelect = ({
  value,
  onChange,
  country,
}: CountrySelectProps) => {
  return (
    <SelectStyled
      InputBase={<CountrySelectInputBase />}
      noIcon
      FormControlComponent={undefined}
      renderValue={() => {
        if (value)
          return (
            <>
              <div className="flag">
                <KeyboardArrowDownIcon className="flag-drop-down-arrow-icon" />
                <Flag countryCode={value} />
                {value && country && country.length && (
                  <div className="flag-code">+{country[0].dial_code}</div>
                )}
              </div>
            </>
          );

        return null;
      }}
      className="country-select"
      aria-label="Country"
      value={value}
      onChange={onChange}
      options={countrySelectOptions}
    />
  );
};
