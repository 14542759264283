import { Box, Divider } from "@hexocean/braintrust-ui-components";
import { JobActionButton } from "@js/apps/jobs/components/job-action-buttons/job-action-button";
import type { MainJobListingJob } from "@js/types/jobs";

import { JobSkills } from "../job-skills";

import {
  JobItemBasicInfo,
  JobItemBudgetShareAndBookmarkSection,
  JobItemButtons,
  JobItemEmployerData,
  JobItemExpandBtn,
  JobItemExpandedContent,
  JobItemHeader,
  JobItemTags,
} from "./components";
import { useJobItem } from "./hooks";

import styles from "./styles.module.scss";

type PublicJobItemProps = {
  job: MainJobListingJob;
};

export const PublicJobItem = ({ job }: PublicJobItemProps) => {
  const {
    isMobile,
    isTablet,
    jobType,
    skillToDisplay,
    expanded,
    handleSetExpanded,
    isApplied,
    location,
  } = useJobItem({
    job,
  });

  return (
    <Box className={styles.wrapper} tabIndex={0}>
      <Box className={styles.topSection}>
        <JobItemEmployerData employer={job.employer} />
        <Box className={styles.header}>
          <JobItemHeader title={job.title} id={job.id} />
          <JobItemButtons
            isMobile={isMobile}
            isTablet={isTablet}
            job={job}
            isPublic
          >
            <JobActionButton job={job} size={isMobile ? "x-small" : "medium"} />
          </JobItemButtons>
          <JobItemTags job={job} jobType={jobType} />
          <JobItemBasicInfo job={job} />
          <JobItemBudgetShareAndBookmarkSection job={job} />
          <Box className={styles.divider}>
            <Divider color="beige" />
          </Box>
          <Box className={styles.footer}>
            <Box marginTop="8px">
              <JobSkills skills={skillToDisplay} />
            </Box>
            <JobItemExpandBtn
              expanded={expanded}
              onExpand={handleSetExpanded}
              jobId={job.id}
            />
          </Box>
        </Box>
      </Box>

      {expanded && (
        <JobItemExpandedContent
          job={job}
          isApplied={isApplied}
          location={location}
        />
      )}
    </Box>
  );
};
