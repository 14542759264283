import React, { forwardRef, useRef, useState } from "react";
import cs from "classnames";

import type {
  PopoverProps,
  StackProps,
} from "@hexocean/braintrust-ui-components";
import {
  Box,
  ButtonCore,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";

import type { FilterFooterProps } from "../filter-footer";
import { FilterFooter } from "../filter-footer";

import styles from "./style.module.scss";

export type JobPopoverFilterButtonProps = Partial<PopoverProps> & {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  label: string | JSX.Element;
  title?: string;
  startIcon?: JSX.Element;
  onClick?: () => void;
  popoverContent: JSX.Element;
  isActive?: boolean;
  tooltipText?: string;
  filterDisabled?: boolean;
  anchorClassName?: string;
};

export const JobPopoverFilterButton = ({
  label,
  title,
  startIcon,
  onClick,
  popoverContent,
  isActive,
  isOpen,
  setIsOpen,
  PaperProps,
  tooltipText,
  filterDisabled,
  anchorClassName,
  ...PopoverProps
}: JobPopoverFilterButtonProps) => {
  const [anchorPosition, setAnchorPosition] = useState<{
    top: number;
    left: number;
  }>();

  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover
      open={isOpen}
      className={styles.button}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      disableRestoreFocus
      PaperProps={{
        className: styles.popoverPaper,
        ...PaperProps,
      }}
      onOpenChange={(open) => {
        if (!buttonRef.current) return;

        const { bottom, left } = buttonRef.current.getBoundingClientRect();
        setAnchorPosition({ top: bottom, left });
        setIsOpen(open);
      }}
      anchor={
        <Anchor
          ref={buttonRef}
          label={label}
          title={title}
          startIcon={startIcon}
          onClick={onClick}
          isActive={isActive}
          isOpen={isOpen}
          filterDisabled={filterDisabled}
          tooltipText={tooltipText}
          anchorClassName={anchorClassName}
        />
      }
      {...PopoverProps}
    >
      {popoverContent}
    </Popover>
  );
};

type AnchorProps = Pick<
  JobPopoverFilterButtonProps,
  | "label"
  | "title"
  | "startIcon"
  | "onClick"
  | "isActive"
  | "isOpen"
  | "filterDisabled"
  | "tooltipText"
  | "anchorClassName"
>;

const Anchor = forwardRef<HTMLButtonElement, AnchorProps>(
  (
    {
      label,
      title,
      startIcon,
      onClick,
      isActive,
      isOpen,
      filterDisabled,
      tooltipText,
      anchorClassName,
    },
    buttonRef,
  ) => {
    return (
      <Tooltip
        title={tooltipText ?? ""}
        disabled={!tooltipText}
        textAlign="center"
      >
        <div>
          <ButtonCore
            disabled={filterDisabled}
            onClick={onClick}
            ref={buttonRef}
            className={cs(anchorClassName, {
              "button-variant--white-violet": !isOpen,
              "button-variant--medium-violet": isActive && !isOpen,
              "button-variant--violet-with-dark-border": isOpen,
              "button-shape--squared": true,
            })}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={1}
              px={2}
            >
              {startIcon && (
                <span
                  className={cs({
                    [styles.buttonIcon]: true,
                    [styles.buttonIconActive]: isActive,
                    [styles.buttonIconOpen]: isOpen,
                  })}
                >
                  {startIcon}
                </span>
              )}
              <Typography
                title={typeof label === "string" ? label : title}
                ml={startIcon ? 1 : 0}
                component="span"
                size="small"
                ellipsis
              >
                {label}
              </Typography>
            </Box>
          </ButtonCore>
        </div>
      </Tooltip>
    );
  },
);

const Wrapper = (props: StackProps) => {
  const maxWidthProp = props.maxWidth;
  const maxWidth = maxWidthProp ? `${maxWidthProp}px` : "520px";

  return (
    <Stack
      className={styles.popover}
      {...props}
      sx={{ maxWidth, ...props?.sx }}
    />
  );
};

type ContentProps = StackProps & {
  noBottomPadding?: boolean;
  maxWidth?: number;
  noHeightRestrict?: boolean;
  showOverflow?: boolean;
};

const Content = ({
  noBottomPadding,
  noHeightRestrict,
  className,
  showOverflow,
  ...props
}: ContentProps) => {
  return (
    <Box
      className={cs(className, styles.popoverContent, {
        [styles.popoverContentNoBottomPadding]: noBottomPadding,
        [styles.popoverContentRestrictedHeight]: !noHeightRestrict,
        [styles.popoverContentShownOverflow]: showOverflow,
      })}
      {...props}
    />
  );
};

type FooterProps = Pick<
  PopoverContentProps,
  "onReset" | "onApply" | "hideClear" | "disableApply" | "singleValue"
>;

const Footer = ({
  onReset,
  onApply,
  hideClear,
  disableApply,
  singleValue,
}: FooterProps) => {
  return (
    <Box>
      <FilterFooter
        onReset={onReset}
        onApply={onApply}
        hideClear={hideClear}
        disableApply={disableApply}
        singleValue={singleValue}
      />
    </Box>
  );
};

type PopoverContentProps = ContentProps & FilterFooterProps;

export const PopoverContent = ({
  onReset,
  onApply,
  disableApply,
  noBottomPadding,
  hideClear,
  children,
  noHeightRestrict,
  showOverflow,
  singleValue,
  ...props
}: PopoverContentProps) => {
  return (
    <Wrapper {...props}>
      <Content
        noBottomPadding={noBottomPadding}
        noHeightRestrict={noHeightRestrict}
        showOverflow={showOverflow}
      >
        {children}
      </Content>
      <Footer
        disableApply={disableApply}
        hideClear={hideClear}
        singleValue={singleValue}
        onReset={onReset}
        onApply={onApply}
      />
    </Wrapper>
  );
};

JobPopoverFilterButton.Content = PopoverContent;
