import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";

type Color = `var(--${string})`;

export type EmptyStateProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
  iconBackgroundColor: Color;
  cta?: React.ReactNode;
};

export const MyWorkEmptyState = ({
  title,
  description,
  icon,
  iconBackgroundColor,
  cta,
}: EmptyStateProps) => {
  return (
    <div className="my-work__wrapper">
      <Box className="my-work__icon-container" bgcolor={iconBackgroundColor}>
        {icon}
      </Box>
      <Typography component="p" variant="label" size="large">
        {title}
      </Typography>
      <Typography component="p" variant="paragraph" size="medium">
        {description}
      </Typography>
      {cta && <div>{cta}</div>}
    </div>
  );
};
