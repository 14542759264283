import type { JobFormValues } from "@js/types/jobs";

export const timezoneOverlapValidator = (
  timezoneOverlap: EnumType<typeof ENUMS.JobTimezoneOverlapType> | null,
  allFormValues: JobFormValues,
) => {
  const { timezones } = allFormValues;

  if (!timezones || !timezones.length) return;

  if (!timezoneOverlap) {
    return "Please select working hours overlap";
  }
};
