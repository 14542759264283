import React, { useMemo } from "react";
import { Field } from "redux-form";

import { required } from "@js/forms/validators";
import type { Job } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

import { DirectHireJobBidRateField } from "./direct-hire-rate-field";
import { FreelanceJobBidRateField } from "./freelance-rate-field";
import { getFieldDescription, getTitle } from "./utils";

type BidRateSectionProps = {
  job: Job;
  id?: string;
};

export const BidRateSection = ({ job }: BidRateSectionProps) => {
  const title = getTitle(job.payment_type, job.job_type);
  const description = getFieldDescription(
    job.payment_type,
    job.budget_minimum_usd,
    job.budget_maximum_usd,
  );

  const BidRateComponent = useMemo(() => {
    switch (job.job_type) {
      case ENUMS.JobType.GRANT: {
        return null;
      }
      case ENUMS.JobType.DIRECT_HIRE: {
        return DirectHireJobBidRateField;
      }
      case ENUMS.JobType.FREELANCE: {
        return FreelanceJobBidRateField;
      }
      default: {
        assertUnreachable(job.job_type);
        return null;
      }
    }
  }, [job.job_type]);

  if (!BidRateComponent) {
    return <></>;
  }

  return (
    <Field
      id="payment_amount"
      name="payment_amount"
      job={job}
      title={title}
      description={description}
      component={BidRateComponent}
      validate={[required]}
    />
  );
};
