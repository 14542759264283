import type { Skill } from "@js/types/admin";
import { pluralize } from "@js/utils/string";

export const createSkillsString = (skills: Skill[]) => {
  if (!skills) return null;
  const _skills = skills
    .map((skill) => skill.name)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    .join(", ");

  const skillLabels = _skills.split(",").map((skillLabel) => skillLabel.trim());

  if (skillLabels.length > 10) {
    const slicedSkillLabels = skillLabels.slice(0, 10);
    const slicedSkillLabelsText = slicedSkillLabels.join(", ");
    const moreSkillLabelsCount = skillLabels.length - slicedSkillLabels.length;

    return `${slicedSkillLabelsText} and ${moreSkillLabelsCount} more skill${pluralize(
      moreSkillLabelsCount,
    )}`;
  }

  return _skills;
};
