import { useState } from "react";

import { Box, Stack } from "@hexocean/braintrust-ui-components";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useUser } from "@js/apps/common/hooks";
import { UploadLinkedinConnectionsContainer } from "@js/apps/dashboard/components/upload-linkedin-connections/upload-linkedin-connections-container";
import { useAppDispatch } from "@js/hooks";
import { Events } from "@js/services/analytics";

import { ReuploadCsv } from "./reupload-csv";
import { UploadCsvSuccess } from "./upload-csv-success";
import { UploadLinkedinConnectionsDropzone } from "./upload-linkedin-connections-dropzone";

export const UploadLinkedinConnections = () => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const hasUploaded = !!user?.linked_connections_uploaded_at;
  const [showUploader, setShowUploader] = useState(!hasUploaded);

  const handleReuploadClick = () => {
    setShowUploader(true);
    dispatch({
      type: Events.CLICK_REUPLOAD_LINKEDIN_CSV,
    });
  };

  const handleConnectionsUpload = () => {
    setShowUploader(false);
    dispatch(fetchCurrentUser());
  };

  return (
    <Box>
      {showUploader && (
        <>
          <UploadLinkedinConnectionsDropzone
            onConnectionsUpload={handleConnectionsUpload}
          />
        </>
      )}
      {!showUploader && (
        <Stack alignItems="center">
          <UploadLinkedinConnectionsContainer
            justifyContent="center"
            sx={{ minHeight: "314px", p: { xs: 2, sm: 3 } }}
          >
            <UploadCsvSuccess />
          </UploadLinkedinConnectionsContainer>
          <ReuploadCsv onClick={handleReuploadClick} />
        </Stack>
      )}
    </Box>
  );
};
