import type { ApprovalStatus } from "@js/apps/common/components/filters";
import { createFiltersForm, FiltersManager } from "@js/apps/filters";
import { APPROVAL_STATUS_VALUES } from "@js/apps/freelancer/views/talent-list/fields/approval-status-filter/constants";

const defaultApprovalStatus: ApprovalStatus = {
  approved: undefined,
  talent_status: [],
};
export const ApprovalStatusForm = createFiltersForm<
  ApprovalStatus,
  ApprovalStatus
>(
  new FiltersManager({
    useAllURLParams: true,
    defaultFormValues: defaultApprovalStatus,
    mapValuesToParams: (values) => {
      const newFilters = { ...values };

      if (newFilters.talent_status) {
        if (newFilters.talent_status.length > 1) {
          newFilters.approved = undefined;
        } else if (newFilters.talent_status.length === 1) {
          newFilters.approved = newFilters.talent_status.includes(
            APPROVAL_STATUS_VALUES.approved,
          );
        }
      } else {
        newFilters.approved = undefined;
      }

      return newFilters;
    },
  }),
);
