import { useEffect } from "react";
import { useParams } from "react-router-dom";

import type { MenuItemProps } from "@hexocean/braintrust-ui-components";
import {
  useLeaveSpaceMutation,
  useUpdateNotificationSettingsMutation,
} from "@js/apps/spaces/api";
import { Snackbar } from "@js/components/snackbar";

export type ActionType = {
  isAdmin?: boolean;
  userId?: number;
  areNotificationsTurnOn?: boolean;
};
type ActionOption = MenuItemProps & { label: string };

export const useGetActions = ({
  areNotificationsTurnOn,
  isAdmin,
  userId,
}: ActionType) => {
  const onOff = areNotificationsTurnOn ? "off" : "on";
  const { spaceId } = useParams();
  const [leaveSpaceRequest, { isError }] = useLeaveSpaceMutation();
  const [updateNotificationSettings] = useUpdateNotificationSettingsMutation();

  const toggleNotifications = () => {
    if (!spaceId || !userId) return;

    updateNotificationSettings({
      spaceId: Number(spaceId),
      memberId: Number(userId),
      notificationEnabled: !areNotificationsTurnOn,
    }).then(() => {
      Snackbar.success(`Notifications are turned ${onOff}`);
    });
  };

  const leaveSpace = async () => {
    if (!spaceId || !userId) return;

    await leaveSpaceRequest({
      spaceId: Number(spaceId),
      memberId: Number(userId),
    });
  };

  useEffect(() => {
    if (isError) {
      Snackbar.error("Something went wrong please try again in a few minutes");
    }
  }, [isError]);

  return [
    !isAdmin
      ? {
          label: `Leave the space`,
          onClick: leaveSpace,
        }
      : false,
    {
      label: `Turn ${onOff} notifications`,
      onClick: toggleNotifications,
    },
  ].filter(Boolean) as ActionOption[];
};
