import URI from "urijs";

export type DownloadFileParams = {
  endpoint: string;
  params?: Record<string, unknown>;
  blank?: boolean;
};

export const handleDownloadFile = ({
  endpoint,
  params,
  blank,
}: DownloadFileParams) => {
  const uri = URI(endpoint);
  if (params) {
    uri.addSearch(params);
  }

  const href = uri.href();

  if (blank) {
    window.open(href, "_blank");
  } else {
    window.location.href = href;
  }
};
