import type { TypedWrappedFieldProps } from "redux-form";

import type { FeedOrderingType } from "@js/apps/common/components/filters";
import {
  defaultFeedOrdering,
  FEED_ORDERING,
} from "@js/apps/common/components/filters";
import { GenericSortButton } from "@js/apps/common/components/filters/components/generic-sort-button";

const feedSortingOptions = [
  { label: "Newest", value: FEED_ORDERING.CREATED },
  { label: "Best match", value: FEED_ORDERING.TOP },
] as const;

type FeedSortButtonType = TypedWrappedFieldProps<FeedOrderingType> & {
  onClick: (arg: FeedOrderingType) => void;
};

export const FeedSortButton = ({
  input,
  meta,
  onClick,
}: FeedSortButtonType) => {
  return (
    <GenericSortButton<FeedOrderingType>
      input={input}
      meta={meta}
      options={feedSortingOptions}
      defaultValue={defaultFeedOrdering["ordering"]}
      onClick={onClick}
    />
  );
};
