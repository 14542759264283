import { AvatarGridGraphic } from "@js/apps/common/components/avatar-grid-graphic";
import type { User } from "@js/types/auth";
import type { FreelancerPublic } from "@js/types/freelancer";
import type { PostCategory } from "@js/types/give-and-get-help";

type ReadMoreGraphicProps = {
  helpCategory: PostCategory;
  profile: FreelancerPublic;
  currentUser: User;
};

export const ReadMoreGraphic = ({
  helpCategory,
  profile,
  currentUser,
}: ReadMoreGraphicProps): JSX.Element => {
  const isAuthor = profile.id === currentUser.freelancer;

  return (
    <AvatarGridGraphic
      leftName={profile.user.first_name}
      leftAvatarProfile={profile.user}
      rightName={isAuthor ? undefined : currentUser.first_name}
      rightAvatarProfile={isAuthor ? undefined : currentUser}
      backgroundColor={helpCategory.color}
      categoryEmoji={helpCategory.emoji}
      forceMobile
    ></AvatarGridGraphic>
  );
};
