import { useCallback } from "react";
import cs from "classnames";

import {
  IconButton,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  DragHorizontalVariantIcon,
  StarFilledSVGIcon,
  StarSVGIcon,
  TrashIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { Skill } from "@js/types/admin";
import type { SkillForJob } from "@js/types/jobs";

import styles from "./style.module.scss";

export type SkillListItem = {
  is_superpower: SkillForJob["is_superpower"];
  skill: Skill;
  id: number;
};

type SelectedSkillItemProps = {
  skill: SkillListItem;
  onFavorite: (skill: SkillListItem) => void;
  onRemove: (skill: SkillListItem) => void;
  quantityFavoriteSkills: number;
  maxTopSkillsCountReached?: boolean;
};

export const SelectedSkillItem = ({
  skill,
  onFavorite,
  onRemove,
  quantityFavoriteSkills,
  maxTopSkillsCountReached,
}: SelectedSkillItemProps) => {
  const handleClickStar = useCallback(() => {
    onFavorite(skill);
  }, [skill, onFavorite]);

  const handleClickBin = useCallback(() => {
    onRemove(skill);
  }, [skill, onRemove]);

  const handleStarClickCapture = useCallback(
    (e: React.MouseEvent) => {
      if (maxTopSkillsCountReached && !skill.is_superpower) {
        e.stopPropagation();
      }
    },
    [skill, maxTopSkillsCountReached],
  );

  return (
    <div
      className={cs(styles.item, {
        [`${styles.itemSuperpower}`]: skill.is_superpower,
      })}
    >
      {!!skill.is_superpower && quantityFavoriteSkills > 1 && (
        <DragHorizontalVariantIcon className={styles.itemDrag} />
      )}
      <IconButton
        variant="transparent"
        onClick={handleClickStar}
        onClickCapture={handleStarClickCapture}
        aria-label={`Toggle top skill ${skill.skill.name}`}
      >
        <SelectedSkillItemStar
          favorite={skill.is_superpower}
          maxTopSkillsCountReached={maxTopSkillsCountReached}
        />
      </IconButton>
      <Typography
        component="p"
        size="medium"
        variant="paragraph"
        sx={{ flex: 1 }}
      >
        {skill.skill.name}
      </Typography>
      <IconButton
        variant="transparent"
        onClick={handleClickBin}
        aria-label="Delete skill"
      >
        <TrashIcon style={{ fontSize: 16 }} />
      </IconButton>
    </div>
  );
};

type SelectedSkillItemStarProps = {
  favorite?: boolean;
  maxTopSkillsCountReached?: boolean;
};

const SelectedSkillItemStar = ({
  favorite,
  maxTopSkillsCountReached,
}: SelectedSkillItemStarProps) => {
  if (favorite) {
    return (
      <StarFilledSVGIcon
        data-testid="selected-skill-filled-star"
        style={{ color: "var(--positive-2)" }}
      />
    );
  }

  if (maxTopSkillsCountReached) {
    return (
      <Tooltip
        title="You have the max number of top skills. Remove one of your top skills in order to select a new one."
        placement="top"
        color="rgba(var(--black-rgb), 0.8)"
        maxWidth={216}
      >
        <StarFilledSVGIcon
          data-testid="selected-skill-filled-star"
          style={{ color: "var(--grey-4)" }}
        />
      </Tooltip>
    );
  }

  return <StarSVGIcon />;
};
