import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { openPendingTransactionModal } from "@js/apps/on-ramp/components/onramp-modal/pending-transaction-modal";
import { RETURNED_FROM_COINBASE_PARAM } from "@js/apps/on-ramp/constants";

const useShowPendingOnrampTransaction = () => {
  const [params] = useSearchParams();

  const isReturnedFromCoinbase =
    params.get(RETURNED_FROM_COINBASE_PARAM) !== null;

  useEffect(() => {
    if (isReturnedFromCoinbase) {
      openPendingTransactionModal();
    }
  }, [isReturnedFromCoinbase]);
};

export { useShowPendingOnrampTransaction };
