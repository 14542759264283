import React, { useMemo } from "react";

import type { UniversalTabValue } from "../constants";
import { UNIVERSAL_SEARCH_TABS } from "../constants";

type UniversalSearchContextProps = {
  initialTab: UniversalTabValue;
};

type UniversalSearchContextProviderProps = {
  children: React.ReactNode;
  initialTab: UniversalTabValue;
};

const UniversalSearchContext = React.createContext<
  UniversalSearchContextProps | undefined
>(undefined);

export const useUniversalSearchContext = (): UniversalSearchContextProps => {
  const context = React.useContext(UniversalSearchContext);

  if (!context) {
    return {
      initialTab: UNIVERSAL_SEARCH_TABS.JOBS,
    };
  }

  return context;
};

export const UniversalSearchContextProvider = ({
  children,
  initialTab,
}: UniversalSearchContextProviderProps) => {
  const value = useMemo(() => {
    return {
      initialTab,
    };
  }, [initialTab]);

  return (
    <UniversalSearchContext.Provider value={value}>
      {children}
    </UniversalSearchContext.Provider>
  );
};
