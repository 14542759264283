import type { ReactNode } from "react";
import { useCallback, useMemo } from "react";

import { getFileWithId } from "../../helpers/file-upload";
import { useUploadSingleFile } from "../../hooks/use-upload-single-file";
import type { UploadedFile, UploadType } from "../../types";
import type { FileDropzoneProps } from "../file-dropzone";
import { FileDropzone } from "../file-dropzone";
import type { FileDropzonePlaceholderProps } from "../file-dropzone-placeholder";
import { FileDropzonePlaceholder } from "../file-dropzone-placeholder";

export type ImageUploadProps = {
  uploadType: UploadType;
  subtitle?: string;
  showUploadedImagePreview?: boolean;
  displayedImage?: string;
  children?: ReactNode;
  onBeforeUpload?: () => void;
  onAfterUpload?: () => void;
  onUpload?: (uploadFile: UploadedFile) => void;
  fileDropzonePlaceholderProps?: Pick<
    FileDropzonePlaceholderProps,
    "className" | "sx"
  >;
  fileDropzoneProps?: Pick<
    FileDropzoneProps,
    "sx" | "className" | "tabIndex" | "customRef"
  >;
};

export const ImageUpload = ({
  uploadType,
  subtitle,
  children,
  showUploadedImagePreview = true,
  onBeforeUpload,
  onAfterUpload,
  onUpload,
  displayedImage,
  fileDropzonePlaceholderProps,
  fileDropzoneProps,
}: ImageUploadProps) => {
  const { uploadSingleFile, uploadedFile, isUploading, error, resetFileState } =
    useUploadSingleFile({ onBeforeUpload, onUpload, onAfterUpload });

  const onDrop = useCallback(
    (files: File[]) => {
      resetFileState();

      const [file] = files;
      if (!file) {
        return;
      }

      const fileWithId = getFileWithId(file);
      uploadSingleFile({
        file: fileWithId,
        uploadType,
      });
    },
    [uploadType, uploadSingleFile, resetFileState],
  );

  const displayedImagePreview = useMemo(() => {
    if (displayedImage) {
      return displayedImage;
    }

    if (!showUploadedImagePreview) {
      return;
    }

    return uploadedFile?.attachment.file;
  }, [displayedImage, showUploadedImagePreview, uploadedFile]);

  return (
    <FileDropzone
      dropzoneOptions={{ onDrop, multiple: false, accept: { "image/*": [] } }}
      error={error}
      {...fileDropzoneProps}
    >
      {({ isDragActive, isFocused }) => (
        <FileDropzonePlaceholder
          isDragActive={isDragActive}
          isFocused={isFocused}
          subtitle={subtitle}
          imagePreview={displayedImagePreview}
          isLoading={isUploading}
          {...fileDropzonePlaceholderProps}
        >
          {children}
        </FileDropzonePlaceholder>
      )}
    </FileDropzone>
  );
};
