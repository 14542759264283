import { API } from "@js/api";
import type { Role } from "@js/types/roles";
import { getUseQueryHookWithDefaultOptions } from "@js/utils/store";

const rolesAPI = API.injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.query<Role[], void>({
      query: (params) => ({
        url: "/roles/",
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 10000000,
    }),
  }),
});

export const useGetRolesQuery = getUseQueryHookWithDefaultOptions(
  rolesAPI.useGetRolesQuery,
  { refetchOnMountOrArgChange: false },
);
