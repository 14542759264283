import React, { useState } from "react";
import type { FieldValues } from "react-hook-form";

import { IconButton, InputAdornment } from "@hexocean/braintrust-ui-components";
import {
  VisibilityOffOutlinedIcon,
  VisibilityOutlinedIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { RhfTextFieldProps } from "@js/rhf/fields/text/";
import { RhfTextField } from "@js/rhf/fields/text/";

type RhfPasswordFieldProps<T extends FieldValues> = RhfTextFieldProps<T> & {
  //special data atribute to prevent LastPass from filling this field
  "data-lpignore"?: "true";
};

export const RhfPasswordField = <T extends FieldValues>(
  props: RhfPasswordFieldProps<T>,
) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <RhfTextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment variant="filled" position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={() => setShowPassword((value) => !value)}
              variant="transparent"
            >
              {showPassword ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <VisibilityOutlinedIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
