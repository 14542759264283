import { useEffect } from "react";

import { useWebsocket } from "@js/hooks";

export const useSubscribePostOrComment = (post_ids: number[]) => {
  const { send, isRunning } = useWebsocket();

  useEffect(() => {
    if (post_ids.length && isRunning) {
      send(ENUMS.StreamType.POSTS_SUBSCRIBE, { post_ids });
    }

    return () => {
      if (post_ids.length && isRunning) {
        send(ENUMS.StreamType.POSTS_UNSUBSCRIBE, { post_ids });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(post_ids), isRunning]);

  return isRunning;
};
