import { useUser } from "@js/apps/common/hooks";
import { RouterLink } from "@js/components/link";
import type { EmployerOfferBid } from "@js/types/jobs";

type CreateOrEditOfferSnackbarContentVerifiedProps = {
  closeSnackbar: () => void;
  bidDetails: EmployerOfferBid | undefined;
};

export const CreateOrEditOfferSnackbarContentVerified = ({
  closeSnackbar,
  bidDetails,
}: CreateOrEditOfferSnackbarContentVerifiedProps) => {
  const user = useUser();

  const bidPath = `/jobs/${bidDetails?.job.id}/proposals/${bidDetails?.id}?tab=messages`;
  const userName = bidDetails?.freelancer.user.first_name;
  const isEmployersOwnProfile = bidDetails?.job.employer.id === user?.employer;

  return (
    <>
      Your offer is on its way to {bidDetails?.freelancer.user.public_name}!{" "}
      {isEmployersOwnProfile && (
        <>
          If you need to follow up, you can get in touch from the{" "}
          <RouterLink
            style={{ textDecoration: "underline" }}
            onClick={closeSnackbar}
            to={bidPath}
          >
            messages tab
          </RouterLink>
          . You’ll be notified as soon as {userName} responds or accepts.
        </>
      )}
    </>
  );
};

type CreateOrEditOfferSnackbarContentUnVerifiedProps = {
  userName: string | undefined;
};

export const CreateOrEditOfferSnackbarContentUnVerified = ({
  userName,
}: CreateOrEditOfferSnackbarContentUnVerifiedProps) => {
  return (
    <>
      We’ll send this offer to {userName} automatically once your banking
      details have been verified and your deposit has been received. This could
      take 3 - 5 business days.
    </>
  );
};

type CreateOrEditOfferSnackbarContentMethodFailedProps = {
  offerId: number;
  jobId: number;
  closeSnackbar: () => void;
};

export const CreateOrEditOfferSnackbarContentMethodFailed = ({
  offerId,
  jobId,
  closeSnackbar,
}: CreateOrEditOfferSnackbarContentMethodFailedProps) => {
  return (
    <>
      We will auto-resend your offer once the payment is successful.{" "}
      <RouterLink
        style={{ textDecoration: "underline" }}
        onClick={closeSnackbar}
        to={`/jobs/${jobId}/offers/${offerId}/edit`}
      >
        Please update your billing details.
      </RouterLink>
    </>
  );
};
