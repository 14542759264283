import { dateDifferenceFromNow } from "@js/utils";
import { getOrderingParam, parseListQueryParam } from "@js/utils/rtkq";

import type { GetEmployerInvoicesParams } from "../types";

export const getBlockedPaymentTime = (blockedUntil: string | null) => {
  const dateDifference = dateDifferenceFromNow(blockedUntil, "minutes");
  if (dateDifference > 1) return `${dateDifference} minutes`;
  if (dateDifference === 1) return "a minute";
  return "a few seconds";
};

export const isPaymentStillBlocked = (blockedUntil: string | null) => {
  if (!blockedUntil) return false;

  const nowDate = new Date();
  const blockedUntilDate = new Date(blockedUntil);

  return blockedUntilDate > nowDate;
};

export const processEmployerInvoicesCommonParams = <
  T extends Pick<
    GetEmployerInvoicesParams,
    "jobs" | "status" | "job_owners" | "freelancers"
  >,
>(
  params: T,
) => {
  const { status, jobs, job_owners, freelancers, ...rest } = params ?? {};
  const jobsParam = parseListQueryParam(jobs);
  const statusParam = parseListQueryParam(status);
  const jobOwnersParam = parseListQueryParam(job_owners);
  const freelancersParam = parseListQueryParam(freelancers);

  return {
    ...rest,
    jobs: jobsParam,
    status: statusParam,
    job_owners: jobOwnersParam,
    freelancers: freelancersParam,
  };
};

export const processEmployerInvoicesParams = (
  params: GetEmployerInvoicesParams,
) => {
  const commonProcessedParams = processEmployerInvoicesCommonParams(params);
  const { order_by, order_dir, ...rest } = commonProcessedParams;
  const ordering = getOrderingParam({ order_by, order_dir });

  return {
    ...rest,
    ordering,
  };
};
