import { Modal } from "@js/components/modal";

import styles from "./style.module.scss";

export const SaveFiltersModalInstance = Modal("save-filters", {
  className: styles.saveFiltersModal,
  closeButton: true,
  closeButtonProps: {
    variant: "tertiary",
    size: "x-small",
  },
  keepOnBackdropClick: true,
});

export const closeSaveFiltersModal = () => {
  SaveFiltersModalInstance.close();
};
