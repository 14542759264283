import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { MessageIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { UserAvatar } from "@js/components/user-avatar";
import type { User } from "@js/types/auth";

import styles from "../../styles.module.scss";

export const TALENT_BLOCKLIST_FORM =
  "https://docs.google.com/forms/d/e/1FAIpQLSceKnF1QI8eC1LPHtZNFjf1PvuiEtgMLmkXKAyiUCRZgmY8rQ/viewform";

export type LocationMismatchModalFooterProps = {
  user: User;
  onMessageUserClick: () => void;
};

export const LocationMismatchModalFooter = ({
  user,
  onMessageUserClick,
}: LocationMismatchModalFooterProps) => {
  return (
    <div className={styles.modalFooter}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignSelf="flex-start"
        gap={1}
      >
        <UserAvatar user={user} disableShadow hideBadge />
        <Typography variant="label" component="p">
          {user.public_name}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignSelf="flex-end"
        gap={1}
      >
        <IconButton
          variant="white-border-beige"
          shape="squared"
          size="small"
          aria-label="Message user"
          onClick={onMessageUserClick}
        >
          <MessageIcon />
        </IconButton>
        <Button
          variant="primary"
          RouterLink={RouterLink}
          href={TALENT_BLOCKLIST_FORM}
          shape="squared"
          size="small"
          target="_blank"
        >
          Flag for blocklist
        </Button>
      </Box>
    </div>
  );
};
