import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "@js/store";

type ATSJobBulkSelectionSlice = {
  selectedAtsJobsIds: string[];
  shouldShowPostImportInfoBar: boolean;
  atsJobsMovedToDraftCount: number;
  atsJobsPublishedCount: number;
  currentPostedATSJobId: number | undefined;
};

const initialState: ATSJobBulkSelectionSlice = {
  selectedAtsJobsIds: [],
  shouldShowPostImportInfoBar: false,
  atsJobsMovedToDraftCount: 0,
  atsJobsPublishedCount: 0,
  currentPostedATSJobId: undefined,
};

export const ATSJobsSlice = createSlice({
  name: "ATSJobsSlice",
  initialState,
  reducers: {
    selectAllJobs(state, action: PayloadAction<string[]>) {
      state.selectedAtsJobsIds = action.payload;
    },

    deselectAllJobs(state) {
      state.selectedAtsJobsIds = [];
    },

    selectJob(state, action: PayloadAction<string>) {
      const newSelectedJobId = action.payload;

      state.selectedAtsJobsIds = [
        ...state.selectedAtsJobsIds,
        newSelectedJobId,
      ];
    },

    deselectJob(state, action: PayloadAction<string>) {
      const newDeselectedJobId = action.payload;
      const jobToSelect = state.selectedAtsJobsIds.filter(
        (jobId) => jobId !== newDeselectedJobId,
      );

      state.selectedAtsJobsIds = jobToSelect;
    },

    toggleShowPostImportInfoBar(state) {
      const currentState = state.shouldShowPostImportInfoBar;

      state.shouldShowPostImportInfoBar = !currentState;
    },

    setAtsJobsPublishedCount(state, action: PayloadAction<number>) {
      state.atsJobsPublishedCount = action.payload;
    },

    setAtsJobsMovedToDraftCount(state, action: PayloadAction<number>) {
      state.atsJobsMovedToDraftCount = action.payload;
    },

    setCurrentPostedATSJobId(state, action: PayloadAction<number | undefined>) {
      state.currentPostedATSJobId = action.payload;
    },
  },
});

export const {
  selectAllJobs,
  deselectAllJobs,
  selectJob,
  deselectJob,
  toggleShowPostImportInfoBar,
  setAtsJobsPublishedCount,
  setAtsJobsMovedToDraftCount,
  setCurrentPostedATSJobId,
} = ATSJobsSlice.actions;

export const getSelectedATSJobsIds = (state: RootState) => {
  return state.ATSJobs.selectedAtsJobsIds;
};

export const getShouldShowPostImportInfoBar = (state: RootState) => {
  return state.ATSJobs.shouldShowPostImportInfoBar;
};

export const getAtsJobsPublishedCount = (state: RootState) => {
  return state.ATSJobs.atsJobsPublishedCount;
};

export const getAtsJobsMovedToDraftCount = (state: RootState) => {
  return state.ATSJobs.atsJobsMovedToDraftCount;
};

export const getCurrentPostedATSJobId = (state: RootState) => {
  return state.ATSJobs.currentPostedATSJobId;
};
