import React from "react";
import type { User } from "@sentry/react";
import classNames from "classnames";

import { Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { UserAvatarProps } from "@js/components/user-avatar";
import { UserAvatar } from "@js/components/user-avatar";
import { UserPublicName } from "@js/components/user-public-name";

import type { AvatarWithBadgeProps } from "../avatar-with-badge";

import styles from "./style.module.scss";

type UserProfileUser = UserAvatarProps["user"] & {
  employer?: number | null;
  approved?: boolean;
} & Optional<User, "public_name" | "profile_url">;

type UserProfileProps = {
  profile: UserProfileUser | { user: UserProfileUser };
  className?: string;
  title?: string;
  size?: AvatarWithBadgeProps["size"];
};

export const UserProfile = ({
  profile,
  className,
  title = "Project Manager",
  size,
}: UserProfileProps) => {
  if (!profile) {
    return null;
  }

  // in case if someone pass "user", not "profile" (like job.creator)
  const user = (
    (profile as { user: UserProfileUser })?.user
      ? (profile as { user: UserProfileUser }).user
      : profile
  ) as UserProfileUser;

  return (
    <div className={classNames(className, styles.userProfile)}>
      <RouterLink to={user.profile_url}>
        <UserAvatar
          user={user}
          size={size || "md"}
          className={styles.userProfileThumbnail}
          hideBorder
          alt={
            user.employer
              ? "Employer Avatar"
              : user.approved
                ? "Approved Freelancer Avatar"
                : "Freelancer Avatar"
          }
        />
      </RouterLink>
      <div className={styles.userProfileContent}>
        <Typography
          className="display-ellipsis"
          component="p"
          size="large"
          RouterLink={RouterLink}
          title={user.public_name}
          to={user.profile_url}
        >
          <UserPublicName name={user.public_name} />
        </Typography>
        <Typography component="p" size="small" color="grey-1">
          {title}
        </Typography>
      </div>
    </div>
  );
};
