import { createAction } from "@reduxjs/toolkit";
import type { ChainId } from "@uniswap/sdk";

export type SerializableTransactionReceipt = {
  to: string;
  from: string;
  contractAddress: string;
  transactionIndex: number;
  blockHash: string;
  transactionHash: string;
  blockNumber: number;
  status?: number;
};

export const addTransaction = createAction<{
  chainId: ChainId;
  hash: string;
  from: string;
  approval?: { tokenAddress: string; spender: string };
  claim?: { recipient: string };
  summary?: string;
}>("transactions/addTransaction");
export const addTransactions = createAction<{
  chainId: ChainId;
  txs: {
    [x: string]: {
      hash: string;
      from: string;
      approval?: { tokenAddress: string; spender: string };
      claim?: { recipient: string };
      summary?: string;
      addedTime: number;
    };
  };
}>("transactions/addTransactions");
export const clearAllTransactions = createAction<{ chainId: ChainId }>(
  "transactions/clearAllTransactions",
);
export const finalizeTransaction = createAction<{
  chainId: ChainId;
  hash: string;
  receipt: SerializableTransactionReceipt;
}>("transactions/finalizeTransaction");
export const checkedTransaction = createAction<{
  chainId: ChainId;
  hash: string;
  blockNumber: number;
}>("transactions/checkedTransaction");
