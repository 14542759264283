import { useCallback, useState } from "react";
import cs from "classnames";

import {
  Box,
  IconButton,
  Typography,
  VerticalSeparator,
} from "@hexocean/braintrust-ui-components";
import {
  CloseIcon,
  EditPenIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import {
  CalendarIcon,
  ExpandMoreIcon,
  SuitcaseIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { Date } from "@js/components/date";
import { Sanitize } from "@js/components/sanitize";
import type { FreelancerWorkExperience } from "@js/types/freelancer";
import { insertLeadingZerosInDate, shortenValue } from "@js/utils";
import type { IsoDate } from "@js/utils/date/types";
import { DateFormats } from "@js/utils/date/types";

type WorkHistoryCardProps = {
  work: FreelancerWorkExperience;
  editMode?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
};

export const WorkHistoryCard = ({
  work,
  editMode,
  onEdit,
  onDelete,
}: WorkHistoryCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const workTitle = shortenValue(work.title, 45);
  const workCompanyTitle = shortenValue(work.company.name, 34);

  const HistoryDescription = useCallback(() => {
    return <Sanitize input={work.description} />;
  }, [work.description]);

  return (
    <Box className="work-history-card">
      <Box
        className={cs("work-history-card__icon", {
          "work-history-card__icon--large": !editMode,
          "work-history-card__icon--small": editMode,
        })}
      >
        <SuitcaseIcon />
      </Box>

      <Typography
        className="work-history-card__title"
        component="h3"
        variant={editMode ? "paragraph" : "title"}
        size={editMode ? "large" : "small"}
        ellipsis
        fontWeight={400}
      >
        {workTitle}
      </Typography>

      <Box className="work-history-card__name-date">
        <Typography
          component="p"
          variant="label"
          sx={{ wordBreak: "break-all" }}
        >
          {workCompanyTitle}
        </Typography>

        <VerticalSeparator color="light-grey" />

        <Typography component="p" className="work-history-card__date">
          <CalendarIcon />
          <Date
            date={
              insertLeadingZerosInDate(
                work.year_from,
                work.month_from,
              ) as IsoDate
            }
            format={DateFormats["Jan 1970"]}
          />
          {" - "}
          {!!work.year_to && !!work.month_to ? (
            <Date
              date={
                insertLeadingZerosInDate(work.year_to, work.month_to) as IsoDate
              }
              format={DateFormats["Jan 1970"]}
            />
          ) : (
            "Present"
          )}
        </Typography>
      </Box>

      {editMode ? (
        <Box display="flex" gap={1.5}>
          <IconButton
            variant="tertiary"
            onClick={() => onEdit && onEdit()}
            aria-label="edit work history"
          >
            <EditPenIcon />
          </IconButton>

          <IconButton
            variant="tertiary"
            aria-label="delete work history"
            onClick={() => onDelete && onDelete()}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ) : (
        <IconButton
          onClick={handleExpand}
          variant="tertiary"
          className={cs("work-history-card__expand-btn", {
            "work-history-card__expand-btn--expanded": isExpanded,
          })}
          aria-label="expand card"
        >
          <ExpandMoreIcon />
        </IconButton>
      )}

      {isExpanded && (
        <Box className="work-history-card__description">
          <Typography component="p">
            <HistoryDescription />
          </Typography>
        </Box>
      )}
    </Box>
  );
};
