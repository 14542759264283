import { z } from "zod";

import { API } from "@js/api";
import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { useGetEmployerCurrentOfferDepositQuery } from "@js/apps/employer/hooks/get-employer-current-offer-deposit";
import { WebSocketManagerContainer } from "@js/components/websocket";
import { useAppDispatch } from "@js/hooks";

import { employerApi } from "../../api";
import { useEmployerId } from "../../hooks";

const CURRENT_OFFER_DEPOSIT_STATUS_CHANGED =
  "current-offer-deposit-status-changed";

const currentOfferDepositStatusChanged = z.object({
  data: z.object({
    broadcast_type: z.literal(
      ENUMS.BroadcastType.CURRENT_OFFER_DEPOSIT_STATUS_CHANGED,
    ),
  }),
});

export const CurrentOfferDepositProvider = () => {
  const dispatch = useAppDispatch();
  const employerId = useEmployerId();
  useGetEmployerCurrentOfferDepositQuery();

  useEffectOnce(() => {
    const listener = (event: MessageEvent) => {
      const parsedEvent = currentOfferDepositStatusChanged.safeParse(event);
      if (!parsedEvent.success || !employerId) {
        return;
      }

      dispatch(
        employerApi.endpoints.getEmployerCurrentOfferDepositById.initiate(
          employerId,
          { forceRefetch: true },
        ),
      );

      // to make sure status in bid list item will be up-to date when payment will be finalized with some delay (eg ACH payment)
      dispatch(API.util.invalidateTags([{ type: "EmployerBids", id: "LIST" }]));
      dispatch(API.util.invalidateTags(["EmployerOffers"]));
    };

    WebSocketManagerContainer.attachListener(
      listener,
      CURRENT_OFFER_DEPOSIT_STATUS_CHANGED,
    );

    return () => {
      WebSocketManagerContainer.removeListener(
        CURRENT_OFFER_DEPOSIT_STATUS_CHANGED,
      );
    };
  });

  return <></>;
};
