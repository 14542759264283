import { formatCalendarDate, typeGuard } from "@js/utils";

export const formatSentDate = (date: string) =>
  formatCalendarDate(date, {
    sameDay: "[Today] h:mm A",
    lastDay: "[Yesterday] h:mm A",
    lastWeek: "MMM DD[,] YYYY h:mm A",
    sameElse: "MMM DD[,] YYYY h:mm A",
  });

export const getHasReachedRoomCreationLimit = (
  error: unknown,
): error is {
  data: {
    maximum_new_room_limit_reached: string;
    interlocutor_talent_id: string;
  };
} => {
  return (
    !!error &&
    typeGuard<unknown, { data: unknown }>(error, "data") &&
    !!error.data &&
    typeGuard<unknown, { maximum_new_room_limit_reached: string }>(
      error.data,
      "maximum_new_room_limit_reached",
    ) &&
    typeGuard<unknown, { interlocutor_talent_id: string }>(
      error.data,
      "interlocutor_talent_id",
    )
  );
};
