import { useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import { useGetEmployerPublicProfileQuery } from "@js/apps/employer/api";
import { LocalStorage } from "@js/services/local-storage";

import { useGetJobQuery } from "../../api";
import { addViewedPublicJob } from "../../apps/public-listing/helpers/viewed-public-jobs";

type UsePublicJobDetailsArg = {
  jobId: number;
};

export const usePublicJobDetails = ({ jobId }: UsePublicJobDetailsArg) => {
  const [referrer] = useState(
    () => LocalStorage.getItem(LocalStorage.keys.SIGN_UP_REFERRER) ?? undefined,
  );

  const {
    data: job,
    isFetching,
    error,
  } = useGetJobQuery({ id: jobId, referrer });

  const jobEmployerId = job?.employer?.id;
  const { data: employerProfile } = useGetEmployerPublicProfileQuery(
    jobEmployerId ?? skipToken,
  );

  useEffect(() => {
    addViewedPublicJob(jobId);
  }, [jobId]);

  const loading = isFetching;

  return {
    loading,
    job,
    employerProfile,
    error,
  };
};
