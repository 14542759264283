import React from "react";

import { Tooltip } from "@hexocean/braintrust-ui-components";

type TimezoneOverlapTooltipProps = {
  overlap: EnumType<typeof ENUMS.JobTimezoneOverlapType> | null;
  children: React.ReactNode;
};

export const TimezoneOverlapTooltip = ({
  overlap,
  children,
}: TimezoneOverlapTooltipProps) => {
  if (!overlap) {
    return null;
  }

  const title =
    overlap === ENUMS.JobTimezoneOverlapType.PARTIAL
      ? "Talent needs to be able to overlap at least 3-4 of their working hours with the time zone shown."
      : "Talent needs to be able to overlap a full work day with the time zone shown.";

  return (
    <Tooltip title={title}>
      <span>{children}</span>
    </Tooltip>
  );
};
