import { Box, Tooltip } from "@hexocean/braintrust-ui-components";
import { ApprovedIcon } from "@hexocean/braintrust-ui-components/Icons";

type ApprovedBadgeProps = {
  isApproved: boolean;
};

export const ApprovedBadge = ({ isApproved }: ApprovedBadgeProps) => {
  if (!isApproved) return null;

  return (
    <Tooltip
      title="Braintrust Approved Talent"
      color="var(--black)"
      placement="top"
    >
      <Box
        width="25px"
        height="25px"
        borderRadius="50%"
        zIndex={100}
        bgcolor="var(--yellow)"
      >
        <ApprovedIcon
          style={{
            fontSize: "20px",
            transform: "translate(3px, 2.5px)",
          }}
        />
      </Box>
    </Tooltip>
  );
};
