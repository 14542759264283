import { useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";
import { debounce } from "underscore";

import {
  InputAdornment,
  RegularTextField,
} from "@hexocean/braintrust-ui-components";
import { SearchSVGIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useEffectRef } from "@js/hooks/use-effect-ref";

import { PeopleFiltersForm } from "../people-filters-form";

export type PeopleSearchProps = {
  input: TypedWrappedFieldProps<string>["input"];
};

export const PeopleSearchField = ({
  input: { value, onChange },
}: PeopleSearchProps) => {
  const onChangeRef = useEffectRef(onChange);

  const debouncedOnChange = useMemo(
    () => debounce((newValue: string) => onChangeRef.current(newValue), 250),
    [onChangeRef],
  );

  return (
    <RegularTextField
      variant="outlined"
      size="medium"
      defaultValue={value}
      onChange={(ev) => debouncedOnChange(ev.target.value)}
      placeholder="Search people"
      inputProps={{
        "aria-label": "Search people",
      }}
      sx={{
        maxWidth: { xs: "initial", sm: "16.5rem" },
        ".MuiInputBase-root": {
          pl: 3,
        },
        ".MuiInputBase-input": {
          py: "12px",
          pl: 1,
          borderRadius: "5rem",
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderRadius: "5rem",
        },
        "input:-webkit-autofill": {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchSVGIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export const PeopleSearch = () => {
  return (
    <PeopleFiltersForm form="people-search">
      <Field name="search" component={PeopleSearchField} />
    </PeopleFiltersForm>
  );
};
