import type { CountryCode } from "libphonenumber-js";
import {
  AsYouType,
  getCountryCallingCode,
  validatePhoneNumberLength,
} from "libphonenumber-js";

const INTERNATIONAL_PHONE_NUMBER_MAX_LENGTH = 15;

export const getPhoneNumberFormat = (
  value: Parameters<AsYouType["input"]>[0],
  countryCode: CountryCode,
): ReturnType<AsYouType["getTemplate"]> => {
  // this allows copy-paste
  const defaultTemplate = "#".repeat(INTERNATIONAL_PHONE_NUMBER_MAX_LENGTH);
  if (value === "") {
    return defaultTemplate;
  }

  const asYouType = new AsYouType(countryCode);
  asYouType.input(value);

  // replace x used by libphonenumber-js with # used in react-number-format
  const template = asYouType.getTemplate();
  const hashedTemplate = template.replace(/x/g, "#");

  let countryCallingCode = "";

  // just in case, because this function can throw
  try {
    countryCallingCode = getCountryCallingCode(countryCode);
  } catch (error) {
    console.error(error);
  }

  const valueWithCountryCallingCode = countryCallingCode + value;

  const isMaximumLength =
    validatePhoneNumberLength(valueWithCountryCallingCode, countryCode) ===
    "TOO_LONG";

  if (!isMaximumLength) {
    return hashedTemplate + "#";
  }

  return hashedTemplate;
};
