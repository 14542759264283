import { useState } from "react";
import { useNavigationType } from "react-router-dom";

import { useGetPostsQuery } from "@js/apps/give-and-get-help/api";
import type { UseGetPostQueryArg } from "@js/apps/give-and-get-help/hooks/use-get-post-query-arg";
import { useGetPostQueryArg } from "@js/apps/give-and-get-help/hooks/use-get-post-query-arg";

const PAGE_SIZE = 10;

let spacesPostsSessionIdx = 1;

export const useSpacesInfinityPostLoading = (
  arg: Pick<UseGetPostQueryArg, "space">,
) => {
  const navigationType = useNavigationType();
  const [postsSessionIdx] = useState(() => {
    if (navigationType === "POP") {
      return spacesPostsSessionIdx;
    }
    return ++spacesPostsSessionIdx;
  });

  const { setCurrentPage, queryArg, currentPage } = useGetPostQueryArg(arg);

  const {
    currentData: data,
    isFetching: fetchingPosts,
    refetch,
  } = useGetPostsQuery(
    { ...queryArg, postsSessionIdx: postsSessionIdx },
    {
      refetchOnFocus: false,
      refetchOnMountOrArgChange: false,
      refetchOnReconnect: false,
    },
  );

  const handleFetchingMore = () => {
    const currentCount = data?.results?.length ?? 0;
    const totalCount = data?.count ?? 0;

    if (!data || currentCount >= totalCount) {
      return;
    }
    const nextPage = Math.floor(data.results.length / PAGE_SIZE) + 1;
    if (nextPage === currentPage) {
      // post has been deleted, so we need to refetch the current page ...
      // ... to fill the missing slots
      refetch();
    }
    setCurrentPage(nextPage);
  };

  const isLoading = !data && fetchingPosts;

  return {
    posts: data?.results || [],
    hasMore: !!data?.next,
    loadingPosts: isLoading,
    fetchingPosts: fetchingPosts,
    handleFetchingMore,
    visitedFeedThreeTimes: data?.visited_feed_three_times,
    postsCount: data?.count || 0,
  };
};
