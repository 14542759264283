import { useMemo } from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { NavigateWithSnackbarToast } from "@js/components/snackbar";
import { useQueryParams } from "@js/hooks";
import { AppLayout } from "@js/layouts/app";

import { ACCESS_DENIED } from "../constants";

import styles from "./styles.module.scss";

const AccessDenied = () => {
  const { message } = useQueryParams();

  const accessDeniedMessage = useMemo(() => {
    switch (message) {
      case ACCESS_DENIED.JOB_ACCESS_DENIED:
        return (
          <NavigateWithSnackbarToast
            header="Sorry, this job is set to private 🙈"
            content="This can happen if the Client changes the job visibility settings.
                    Only invited Talent can view and apply to this job."
            to="/jobs"
            replace
            autoHideDuration={8000}
          />
        );
      default:
        break;
    }
  }, [message]);

  return (
    <AppLayout
      pageTitle="Page Not Found"
      flexColumn
      className={styles.notFoundPage}
    >
      {!!accessDeniedMessage ? accessDeniedMessage : <DefaultAccessDenied />}
    </AppLayout>
  );
};

const DefaultAccessDenied = () => {
  return (
    <>
      <Typography component="h2" variant="title" fontWeight={500}>
        You don't have permission to open this page
      </Typography>
      <Typography
        component="p"
        size="large"
        className={styles.notFoundPageInfo}
      >
        Return to the <RouterLink to="/">homepage</RouterLink>
      </Typography>
    </>
  );
};

export default AccessDenied;
