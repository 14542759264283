import React from "react";

import { wait } from "../common";

type ImportFn<ComponentProps> = () => Promise<{
  default: React.ComponentType<ComponentProps>;
}>;

export function lazyWithRetry<T extends object>(importFn: ImportFn<T>) {
  return React.lazy(() => retry<ImportFn<T>>(importFn));
}

export async function retry<T extends () => Promise<any>>(
  importFn: T,
  retries = 10,
  interval = 1000,
): Promise<Awaited<ReturnType<T>>> {
  try {
    return await importFn();
  } catch (error) {
    if (retries) {
      await wait(interval);
      return retry(importFn, retries - 1, interval);
    } else {
      throw error;
    }
  }
}
