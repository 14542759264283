import { ModalInstance } from "@js/components/modal";

import { AboutThisSpaceForm } from "../about-space-form";
import type { AboutThisSpaceProps } from "../types";
import { useAboutThisSpaceForm } from "../use-about-this-space-form";

import styles from "./styles.module.scss";

export const openAboutThisSpaceModalForm = (props: AboutThisSpaceProps) =>
  ModalInstance.open({
    children: <AboutThisSpaceModalForm {...props} />,
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
      className: styles.closeButton,
    },
    className: styles.modal,
  });

const AboutThisSpaceModalForm = ({ id, description }: AboutThisSpaceProps) => {
  const { initialValues, onSubmit, onSubmitSuccess } = useAboutThisSpaceForm({
    id,
    description,
  });

  return (
    <AboutThisSpaceForm
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      initialValues={initialValues}
    />
  );
};
