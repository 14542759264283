import { useMemo } from "react";
import { hasSubmitFailed, isInvalid } from "redux-form";

import { useUpdateFreelancerAvailabilityMutation } from "@js/apps/available-for-work/api";
import type { AvailabilityFormValue } from "@js/apps/available-for-work/forms";
import { AVAILABILITY_MODAL_FORM_ID } from "@js/apps/available-for-work/forms";
import { timezoneOptions } from "@js/apps/common/forms/fields/timezone-field/helpers";
import {
  FRONTEND_STORAGE_KEYS,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";

type AvailabilityFormErrors = Partial<
  Record<keyof AvailabilityFormValue, string>
>;

export const useAvailabilityForm = (onClose: () => void) => {
  const dispatch = useAppDispatch();
  const [setStorageValue] = useSetStorageValueMutation();
  const freelancerProfile = useAppSelector(
    (state) => state.freelancer?.freelancerProfile,
  );
  const fetchingFreelancerProfile = useAppSelector(
    (state) => state.freelancer.fetchingFreelancerProfile,
  );
  const [updateFreelancerAvailability] =
    useUpdateFreelancerAvailabilityMutation();

  const isSubmitFailed = useAppSelector((state) =>
    hasSubmitFailed(AVAILABILITY_MODAL_FORM_ID)(state),
  );

  const isFormInvalid = useAppSelector((state) =>
    isInvalid(AVAILABILITY_MODAL_FORM_ID)(state),
  );

  const disableSubmit = isSubmitFailed && isFormInvalid;

  const initialValues: AvailabilityFormValue | undefined = useMemo(() => {
    if (fetchingFreelancerProfile) {
      return;
    }

    const initialWorkingHoursTimezoneData = timezoneOptions.find(
      (timezone) => freelancerProfile?.working_hours_tz_abbr === timezone.value,
    );
    return {
      availability_for_work: freelancerProfile?.availability_for_work || false,
      availability_for_work_options:
        freelancerProfile?.availability_for_work_options || [],
      working_hours_start: freelancerProfile?.working_hours_start ?? 9,
      working_hours_end: freelancerProfile?.working_hours_end ?? 17,
      working_hours_tz_abbr: initialWorkingHoursTimezoneData?.value || "",
    };
  }, [freelancerProfile, fetchingFreelancerProfile]);

  const onSubmit = async (data: AvailabilityFormValue) => {
    if (!freelancerProfile || disableSubmit) {
      return;
    }

    if (data.availability_for_work) {
      setStorageValue({
        key: FRONTEND_STORAGE_KEYS.working_hours_coach_mark_shown,
        value: true,
      });
    }

    const preparedData = prepareSubmitData(data);

    await updateFreelancerAvailability({
      id: freelancerProfile?.id,
      ...preparedData,
    })
      .unwrap()
      .then(() => {
        Snackbar.success(`The availability status has been changed!`);
        onClose();
        dispatch(fetchFreelancerProfile(freelancerProfile?.id));
      })
      .catch(() => Snackbar.error(`Failed to change availability status.`));
  };

  return {
    disableSubmit,
    initialValues,
    onSubmit,
    validate,
    isLoading: fetchingFreelancerProfile,
  };
};

const validate = (data: AvailabilityFormValue) => {
  const errors: AvailabilityFormErrors = {};
  if (!data.availability_for_work) {
    return errors;
  }

  if (
    data.availability_for_work &&
    data.availability_for_work_options?.length === 0
  ) {
    errors["availability_for_work_options"] =
      "Please select which types of roles you’re open to and when you’re available to work.";
  }

  if (
    !data.working_hours_tz_abbr ||
    data.working_hours_tz_abbr?.trim()?.length === 0
  ) {
    errors["working_hours_tz_abbr"] = "Please select a timezone";
  }

  if (
    data.working_hours_end !== null &&
    data.working_hours_end === data.working_hours_start
  ) {
    errors.working_hours_end = errors.working_hours_start =
      "The end time must be different than the start time";
  }

  return errors;
};

const prepareSubmitData = (data: AvailabilityFormValue) => {
  if (!data.availability_for_work) {
    return { availability_for_work: false } as const;
  }

  return data;
};
