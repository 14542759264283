import { API } from "@js/api";
import type {
  FreelancerExternalProfile,
  PopularLocation,
} from "@js/types/common";
import type {
  Freelancer,
  FreelancerCertificate,
  FreelancerForNodeStaff,
  FreelancerHoverState,
  FreelancerPublic,
  FreelancerRole,
  FreelancerSkill,
  FreelancerWorkExperience,
  JobInvitations,
  ProfileCompletion,
  TalentExampleProfile,
  WorkSampleItem,
} from "@js/types/freelancer";
import type { Talent } from "@js/types/freelancer";
import type { PaginatedResult } from "@js/types/generic";
import type {
  FreelancerBid,
  FreelancerBidParams,
  FreelancerOffer,
  Job,
} from "@js/types/jobs";
import type { UploadFile } from "@js/types/uploads";
import { getUseQueryHookWithDefaultOptions } from "@js/utils/store";

import type { TalentFiltersFetchParams } from "../common/components/filters";

import type { Article } from "./components";
import type { CreateWorkExperienceParams } from "./types";

type FreelancerBidsParams = {
  current?: boolean;
  historical?: boolean;
  accepted?: boolean;
  job?: number;
  ordering?: "-created";
  not_rejected?: boolean;
};

type FreelancerBidsPaginatedParams = FreelancerBidsParams & {
  page_size?: number;
};

type FreelancerJobsParams = {
  completed?: boolean;
  show_jobs_of_removed_users?: boolean;
};

type SaveFreelancerRolePayload = {
  role: number;
  primary: boolean;
  years_experience: number;
};

type UpdateFreelancerRolePayload = {
  id: number;
  role: number;
  primary: boolean;
  years_experience: number;
  primary_freelancer_role?: number;
};

export type TaxFormType = "w8" | "w9";

type GetTaxFormResponse = {
  form_url: string | null;
  form_uploaded_at: string | null;
};

type GetTaxFormsParams = {
  freelancerId: number;
  formType: TaxFormType;
};

type DeleteTaxFormsParams = {
  freelancerId: number;
  formType: TaxFormType;
};

type UpdateTaxFormsResponse = {
  form_url: string | null;
  form_uploaded_at: string | null;
};

type UpdateTaxFormsParams = {
  freelancerId: number;
  formType: TaxFormType;
  formFileId: number;
};

type AddPortfolioItemParams = Omit<WorkSampleItem, "id">;
type AddPortfolioItemResponse = WorkSampleItem;

export type UpdateFreelancerCustomizationPayload = {
  user?: {
    first_name?: string;
    last_name?: string | null;
    cover_photo_id?: number;
    avatar_id?: number;
    avatar?: string;
    introduction_headline?: string | null;
    introduction?: string | null;
  };
  external_profiles?: Array<{ new_site: number; public_url: string }>;
};

type GetFreelancerSkillsResult = Array<Omit<FreelancerSkill, "role">>;

type EditFreelancerSkillsParams = {
  id: number;
  new_skills: Array<{ new_skill: number; is_superpower: boolean }>;
};

export type TalentSearchMinimalResult = {
  id: number;
  name: string;
};

export type TalentSearchParams = {
  search?: string;
  public_name_search?: boolean;
};

type CertificateSearchResult = {
  id: number;
  name: string;
  is_default: boolean;
  order: number | null;
};

type CertificateSearchParams = {
  search?: string;
  limit?: number;
};

type CompaniesSearchResult = PaginatedResult<{
  id: number;
  name: string;
  is_default: boolean;
  order: number | null;
}>;

type CompaniesSearchParams = {
  search?: string;
  page: number;
  page_size: number;
  limit?: number;
};

type DegreesSearchResult = {
  id: number;
  name: string;
  is_default: boolean;
  order: number | null;
};

type DegreesSearchParams = {
  search?: string;
  limit?: number;
};

type SchoolsSearchResult = {
  id: number;
  name: string;
  is_default: boolean;
  order: number | null;
};

type SchoolsSearchParams = {
  search?: string;
  limit?: number;
};

type GetFreelancerJobOffersParams = {
  section: EnumType<typeof ENUMS.TalentOffersSectionFilter>;
  order_by?: "id";
};

type FreelancerArticlesParams = {
  page_size?: number;
};

export const freelancerApi = API.injectEndpoints({
  endpoints: (build) => ({
    getFreelancerPublicProfile: build.query<
      FreelancerPublic | FreelancerForNodeStaff,
      number
    >({
      query: (id) => ({
        url: `/freelancers/${id}`,
        method: "GET",
      }),
      providesTags: ["FreelancerPublicProfile"],
    }),

    boostFreelancerBid: build.mutation<void, { id?: number }>({
      query: ({ id }) => ({
        url: `/freelancer_bids/${id}/boost`,
        method: "POST",
      }),

      invalidatesTags: [
        "FreelancerBids",
        { type: "Jobs", id: "LIST" },
        "EmployerOpenJobs",
      ],
    }),
    getTalentExampleProfiles: build.query<
      PaginatedResult<{
        id: number;
        freelancer: TalentExampleProfile;
      }>,
      { role?: number } | void
    >({
      query: (params) => ({
        url: "/talent_example_profiles/",
        method: "GET",
        params,
      }),
    }),
    getTalents: build.query<
      PaginatedResult<Talent>,
      Partial<TalentFiltersFetchParams> | void
    >({
      query: (filters) => {
        const role = filters?.role;
        const skills = filters?.skills;

        return {
          url: `/talent/`,
          method: "GET",
          params: {
            ...filters,
            role: role?.join(","),
            skills: skills?.join(","),
          },
        };
      },
    }),
    getTalentSearch: build.query<
      TalentSearchMinimalResult[],
      TalentSearchParams
    >({
      query: (params?) => {
        return {
          url: `/talent/`,
          method: "GET",
          params: {
            limit: 100,
            public_name_search: true,
            ...params,
          },
        };
      },
      transformResponse: (
        result: PaginatedResult<TalentSearchMinimalResult>,
      ) => {
        return result.results;
      },
    }),
    getTalent: build.query<
      TalentSearchMinimalResult,
      { id: number; public_name_search?: boolean }
    >({
      query: ({ id, ...params }) => ({
        url: `/talent/${id}/`,
        method: "GET",
        params,
      }),
    }),
    getEliteTalents: build.query<PaginatedResult<Freelancer>, void>({
      query: () => ({
        url: `/talent/explore_elite/`,
        method: "GET",
      }),
    }),
    updatePortfolioItemsPositions: build.mutation<
      void,
      { itemPositions: WorkSampleItem[] }
    >({
      query: ({ itemPositions }) => ({
        url: "/manage_portfolio_items/update_positions/",
        method: "POST",
        data: {
          item_positions: itemPositions,
        },
      }),
      invalidatesTags: ["FreelancerPublicProfile"],
    }),
    updateCertificatesPositions: build.mutation<
      void,
      { itemPositions: FreelancerCertificate[] }
    >({
      query: ({ itemPositions }) => ({
        url: "/freelancer_certificates/update_positions/",
        method: "POST",
        data: {
          item_positions: itemPositions,
        },
      }),
      invalidatesTags: ["FreelancerPublicProfile"],
    }),
    getFreelancerRoles: build.query<FreelancerRole[], void>({
      query: () => ({
        url: "/freelancer_roles/",
        method: "GET",
      }),
      providesTags: ["FreelancerRoles"],
    }),

    saveFreelancerRole: build.mutation<
      Pick<FreelancerRole, "id">,
      SaveFreelancerRolePayload
    >({
      query: (role) => ({
        url: "/freelancer_roles/",
        method: "POST",
        data: role,
      }),
      invalidatesTags: [
        "FreelancerHoverStateData",
        "FreelancerRoles",
        "FreelancerProfileCompletion",
      ],
    }),

    updateFreelancerRole: build.mutation<
      Pick<FreelancerRole, "id">,
      UpdateFreelancerRolePayload
    >({
      query: ({ id, ...data }) => ({
        url: `/freelancer_roles/${id}`,
        method: "PUT",
        data,
      }),
      invalidatesTags: ["FreelancerRoles", "FreelancerHoverStateData"],
    }),

    removeFreelancerRole: build.mutation<void, FreelancerRole["id"]>({
      query: (id) => ({
        url: `/freelancer_roles/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FreelancerRoles", "FreelancerHoverStateData"],
    }),
    getFreelancerBids: build.query<
      FreelancerBid[],
      FreelancerBidsParams | void
    >({
      query: (params) => ({
        url: "/freelancer_bids/",
        method: "GET",
        params: {
          ...params,
          page_size: 0, // backend returns a non-paginated response when page_size 0 is passed
        },
      }),
      providesTags: ["FreelancerBids"],
    }),
    getFreelancerBidsPaginated: build.query<
      PaginatedResult<FreelancerBid>,
      FreelancerBidsPaginatedParams | void
    >({
      query: (params) => ({
        url: "/freelancer_bids/",
        method: "GET",
        params: {
          ...params,
          page_size: params?.page_size === 0 ? 1 : params?.page_size,
        },
      }),
      providesTags: ["FreelancerBids"],
    }),
    getFreelancerBid: build.query<FreelancerBid, { id?: string }>({
      query: ({ id }) => ({
        url: `/freelancer_bids/${id}/`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    createFreelancerBid: build.mutation<FreelancerBid, FreelancerBidParams>({
      query: (data) => ({
        url: "/freelancer_bids/",
        method: "POST",
        data,
      }),
      invalidatesTags: [
        { type: "Jobs", id: "LIST" },
        "FreelancerBids",
        "SavedJobs",
        "Interviews",
        "RelevantJobs",
      ],
    }),
    updateFreelancerBid: build.mutation<
      FreelancerBid,
      { id?: number; values: FreelancerBidParams }
    >({
      query: ({ id, values }) => ({
        url: `/freelancer_bids/${id}/`,
        method: "PUT",
        data: values,
      }),
      invalidatesTags: ["FreelancerBids"],
    }),
    cancelFreelancerBid: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/freelancer_bids/${id}/cancel_bid/`,
        method: "POST",
      }),
      invalidatesTags: ["FreelancerBids"],
    }),
    getFreelancerJobs: build.query<Job[], FreelancerJobsParams | void>({
      query: (params) => ({
        url: "/freelancer_jobs/",
        method: "GET",
        params,
      }),
    }),
    getFreelancerJobOffers: build.query<
      FreelancerOffer[],
      GetFreelancerJobOffersParams
    >({
      query: ({ section, order_by }) => ({
        url: "/freelancer_offers/",
        method: "GET",
        params: { section, order_by },
      }),
      providesTags: ["FreelancerJobOffers"],
    }),
    deleteResume: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/manage_freelancers/${id}/delete_resume`,
        method: "DELETE",
      }),
      invalidatesTags: ["FreelancerPublicProfile"],
    }),
    uploadResume: build.mutation<
      Freelancer,
      { id: number; resume: UploadFile["id"] }
    >({
      query: ({ id, resume }) => ({
        url: `/manage_freelancers/${id}/`,
        method: "PATCH",
        data: {
          resume,
        },
      }),
      invalidatesTags: ["FreelancerPublicProfile"],
    }),
    updateFreelancerCustomization: build.mutation<
      Freelancer,
      { id: number; data: UpdateFreelancerCustomizationPayload }
    >({
      query: ({ id, data }) => ({
        url: `/manage_talent_customization/${id}/`,
        method: "PUT",
        data,
      }),
      invalidatesTags: [
        "SpaceMembers",
        "FreelancerExternalProfiles",
        "FreelancerPublicProfile",
      ],
    }),
    getFreelancerHoverStateData: build.query<
      FreelancerHoverState,
      {
        id: Freelancer["id"];
      }
    >({
      query: ({ id }) => ({
        url: `/freelancers/${id}/hover_state_data`,
        method: "GET",
      }),
      providesTags: ["FreelancerHoverStateData"],
    }),

    getTaxForm: build.query<GetTaxFormResponse, GetTaxFormsParams>({
      query: ({ freelancerId, formType }) => ({
        url: `/manage_freelancers/${freelancerId}/${formType}_form`,
        method: "GET",
      }),
      providesTags: (_result, _error, arg) => [
        { type: "TaxForm", id: arg.formType },
      ],
    }),

    deleteTaxForm: build.mutation<void, DeleteTaxFormsParams>({
      query: ({ freelancerId, formType }) => ({
        url: `/manage_freelancers/${freelancerId}/${formType}_form`,
        method: "DELETE",
      }),

      async onQueryStarted(
        { freelancerId, formType },
        { dispatch, queryFulfilled },
      ) {
        const patch = dispatch(
          freelancerApi.util.updateQueryData(
            "getTaxForm",
            { freelancerId, formType },
            () => {
              return { form_url: null, form_uploaded_at: null };
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (_error) {
          patch.undo();

          dispatch(
            freelancerApi.util.invalidateTags([
              { type: "TaxForm", id: formType },
            ]),
          );
        }
      },
    }),

    updateTaxForm: build.mutation<UpdateTaxFormsResponse, UpdateTaxFormsParams>(
      {
        query: ({ freelancerId, formType, formFileId }) => ({
          url: `/manage_freelancers/${freelancerId}/${formType}_form`,
          method: "PATCH",
          data: { form: formFileId },
        }),

        async onQueryStarted(
          { freelancerId, formType },
          { dispatch, queryFulfilled },
        ) {
          try {
            const { data: updatedFormData } = await queryFulfilled;
            dispatch(
              freelancerApi.util.updateQueryData(
                "getTaxForm",
                { freelancerId, formType },
                () => {
                  return updatedFormData;
                },
              ),
            );
          } catch (_error) {
            dispatch(
              freelancerApi.util.invalidateTags([
                { type: "TaxForm", id: formType },
              ]),
            );
          }
        },
      },
    ),
    getFreelancerJobInvitations: build.query<JobInvitations, void>({
      query: () => ({
        url: "/jobs/freelancer_invitations/",
        method: "GET",
      }),
      providesTags: ["FreelancerJobInvitations"],
    }),
    getFreelancerWorkExperience: build.query<FreelancerWorkExperience[], void>({
      query: () => ({
        url: "/freelancer_work_experience/",
        method: "GET",
      }),
      providesTags: ["FreelancerWorkExperience"],
      keepUnusedDataFor: 100000000,
    }),
    getFreelancerSkills: build.query<GetFreelancerSkillsResult, { id: number }>(
      {
        query: ({ id }) => ({
          url: `/manage_freelancers/${id}/skills`,
          method: "GET",
        }),
        providesTags: ["FreelancerSkills"],
        keepUnusedDataFor: 100000000,
        transformResponse: (response: {
          freelancer_skills: GetFreelancerSkillsResult;
        }): GetFreelancerSkillsResult => {
          return response.freelancer_skills ?? [];
        },
      },
    ),
    editFreelancerSkills: build.mutation<void, EditFreelancerSkillsParams>({
      query: ({ id, ...data }) => ({
        url: `/manage_freelancers/${id}/skills`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: [
        "FreelancerSkills",
        "FreelancerHoverStateData",
        "FreelancerProfileCompletion",
        "FreelancerPublicProfile",
      ],
    }),
    addPortfolioItem: build.mutation<
      AddPortfolioItemResponse,
      AddPortfolioItemParams
    >({
      query: (data) => ({
        url: `/manage_portfolio_items/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "FreelancerProfileCompletion",
        "FreelancerPublicProfile",
      ],
    }),
    deletePortfolioItem: build.mutation<void, number>({
      query: (id) => ({
        url: `/manage_portfolio_items/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["FreelancerPublicProfile"],
    }),
    updatePortfolioItem: build.mutation<WorkSampleItem, WorkSampleItem>({
      query: (data) => ({
        url: `/manage_portfolio_items/${data.id}/`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["FreelancerPublicProfile"],
    }),
    getTalentPopularLocations: build.query<Array<PopularLocation>, void>({
      query: () => ({
        url: "/frequent_talent_location_filters/",
        method: "GET",
      }),
    }),
    getFreelancerProfileCompletion: build.query<
      ProfileCompletion,
      { freelancerId: number }
    >({
      query: ({ freelancerId }) => ({
        url: `/freelancers/${freelancerId}/get_profile_completion_percent/`,
        method: "GET",
      }),
      keepUnusedDataFor: 1000000000,
      providesTags: ["FreelancerProfileCompletion"],
    }),

    getFreelancerExternalProfiles: build.query<
      FreelancerExternalProfile[],
      void
    >({
      query: () => ({
        url: `/freelancer_external_profiles/`,
        method: "GET",
      }),
      keepUnusedDataFor: 1000000000,
      providesTags: ["FreelancerExternalProfiles"],
    }),

    createWorkExperience: build.mutation<
      FreelancerWorkExperience,
      CreateWorkExperienceParams
    >({
      query: (values) => ({
        url: "/freelancer_work_experience/",
        method: "POST",
        data: values,
      }),
      invalidatesTags: [
        "FreelancerWorkExperience",
        "FreelancerPublicProfile",
        "FreelancerProfileCompletion",
      ],
    }),
    deleteWorkExperience: build.mutation<undefined, number>({
      query: (id) => ({
        url: `/freelancer_work_experience/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["FreelancerWorkExperience", "FreelancerPublicProfile"],
    }),
    updateWorkExperience: build.mutation<
      FreelancerWorkExperience,
      CreateWorkExperienceParams
    >({
      query: ({ id, ...values }) => ({
        url: `/freelancer_work_experience/${id}/`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["FreelancerWorkExperience", "FreelancerPublicProfile"],
    }),
    getCertificates: build.query<
      CertificateSearchResult[],
      CertificateSearchParams
    >({
      query: ({ limit = 100, ...params }) => ({
        url: `/certificates/`,
        method: "GET",
        params: {
          limit,
          ...params,
        },
      }),
    }),
    getCompanies: build.query<CompaniesSearchResult, CompaniesSearchParams>({
      query: ({ limit = 100, ...params }) => ({
        url: `/companies/`,
        method: "GET",
        params: {
          limit,
          ...params,
        },
      }),
    }),
    getDegrees: build.query<DegreesSearchResult[], DegreesSearchParams>({
      query: ({ limit = 100, ...params }) => ({
        url: `/degrees/`,
        method: "GET",
        params: {
          limit,
          ...params,
        },
      }),
    }),
    getSchools: build.query<SchoolsSearchResult[], SchoolsSearchParams>({
      query: ({ limit = 100, ...params }) => ({
        url: `/schools/`,
        method: "GET",
        params: {
          limit,
          ...params,
        },
      }),
    }),
    getFreelancerArticles: build.query<
      PaginatedResult<Article>,
      FreelancerArticlesParams
    >({
      query: (params) => ({
        url: "/articles/",
        method: "GET",
        params,
      }),
      providesTags: ["FreelancerArticles"],
    }),
  }),
});

export const {
  useBoostFreelancerBidMutation,
  useGetTalentsQuery,
  useGetEliteTalentsQuery,
  useGetTalentExampleProfilesQuery,
  useUpdatePortfolioItemsPositionsMutation,
  useUpdateCertificatesPositionsMutation,
  useGetFreelancerRolesQuery,
  useSaveFreelancerRoleMutation,
  useUpdateFreelancerRoleMutation,
  useRemoveFreelancerRoleMutation,
  useGetFreelancerBidQuery,
  useGetFreelancerBidsQuery,
  useCreateFreelancerBidMutation,
  useUpdateFreelancerBidMutation,
  useCancelFreelancerBidMutation,
  useUpdateFreelancerCustomizationMutation,
  useGetFreelancerJobsQuery,
  useDeleteResumeMutation,
  useUploadResumeMutation,
  useGetFreelancerHoverStateDataQuery,
  useGetTaxFormQuery,
  useDeleteTaxFormMutation,
  useUpdateTaxFormMutation,
  useGetFreelancerJobInvitationsQuery,
  useGetFreelancerWorkExperienceQuery,
  useGetFreelancerSkillsQuery,
  useEditFreelancerSkillsMutation,
  useAddPortfolioItemMutation,
  useDeletePortfolioItemMutation,
  useUpdatePortfolioItemMutation,
  useGetTalentPopularLocationsQuery,
  useGetFreelancerBidsPaginatedQuery,
  useLazyGetFreelancerBidsPaginatedQuery,
  useGetFreelancerJobOffersQuery,
  useGetFreelancerPublicProfileQuery,
  useCreateWorkExperienceMutation,
  useDeleteWorkExperienceMutation,
  useUpdateWorkExperienceMutation,
  useGetTalentQuery,
  useGetTalentSearchQuery,
  useGetCertificatesQuery,
  useGetCompaniesQuery,
  useGetDegreesQuery,
  useGetSchoolsQuery,
  useGetFreelancerArticlesQuery,
  useLazyGetFreelancerProfileCompletionQuery,
} = freelancerApi;

export const { useQueryState: useFreelancerHoverStateQueryState } =
  freelancerApi.endpoints.getFreelancerHoverStateData;

export const useGetFreelancerExternalProfilesQuery =
  getUseQueryHookWithDefaultOptions(
    freelancerApi.useGetFreelancerExternalProfilesQuery,
    { refetchOnMountOrArgChange: false },
  );

export const useGetFreelancerProfileCompletionQuery =
  getUseQueryHookWithDefaultOptions(
    freelancerApi.useGetFreelancerProfileCompletionQuery,
    { refetchOnMountOrArgChange: false },
  );
