import { Box } from "@hexocean/braintrust-ui-components";
import { PostItem } from "@js/apps/give-and-get-help/components/post/post-item";
import { PostViewedContextProvider } from "@js/apps/give-and-get-help/context/post-viewed";
import { PostsContextProvider } from "@js/apps/give-and-get-help/context/posts";
import { useRedirectToActivePost } from "@js/apps/give-and-get-help/hooks/redirect-on-post-element-click";
import { BlockInteractionWrapper } from "@js/components/block-interaction-wrapper";
import type { IPost } from "@js/types/give-and-get-help";

import styles from "./bookmarks-posts-section.module.scss";

type BookmarksPostsSectionProps = {
  posts: IPost[];
};

export const BookmarksPostsSection = ({
  posts,
}: BookmarksPostsSectionProps) => {
  const redirectToActivePost = useRedirectToActivePost();

  return (
    <PostsContextProvider>
      <PostViewedContextProvider>
        <Box className={styles.container} pt={2}>
          {posts.map((post) => {
            return (
              <BlockInteractionWrapper
                key={post.id}
                onBlockedInteraction={() => {
                  redirectToActivePost(post);
                }}
              >
                <PostItem postData={post} disablePostSubscription />
              </BlockInteractionWrapper>
            );
          })}
        </Box>
      </PostViewedContextProvider>
    </PostsContextProvider>
  );
};
