import { Box, Typography } from "@hexocean/braintrust-ui-components";

export const ProfileProjectsModuleEmptyStateHeader = () => {
  return (
    <Box
      display="flex"
      gap={3}
      justifyContent="center"
      flexDirection="column"
      alignItems="flex-start"
      mb={3}
    >
      <Typography component="h3" variant="title" size="small" fontWeight={400}>
        Projects
      </Typography>
      <Typography
        component="label"
        variant="label"
        size="large"
        fontWeight={400}
      >
        Add a project
      </Typography>
    </Box>
  );
};
