import React from "react";
import { Field, reduxForm } from "redux-form";
import _ from "underscore";

import { VisibilitySettingsSelectField } from "@js/apps/jobs/apps/create-job/fields/visibility-setting-field";
import { Form } from "@js/forms/components/form";

const CHANGE_JOB_VISIBILITY = "change-job-visibility";

export type ChangeJobVisibilityFormData = {
  is_private: boolean;
};

export const ChangeJobVisibilityForm = reduxForm<ChangeJobVisibilityFormData>({
  form: CHANGE_JOB_VISIBILITY,
  onChange: (_values, _dispatch, props, previousValues) => {
    if (!_.isEmpty(previousValues)) props.submit?.();
  },
})(({ error, submit, submitting }) => {
  return (
    <Form onSubmit={submitting ? null : submit} error={error}>
      <Field name="is_private" component={VisibilitySettingsSelectField} />
    </Form>
  );
});
