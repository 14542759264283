import { EMAIL_VERIFIED, REGISTERED } from "@js/apps/auth/action-types";
import type { TrackableUserAction } from "@js/middlewares/analytics/types";
import { Events, EventsProperties } from "@js/services/analytics";
import { logEvent } from "@js/services/analytics/event-logging";
import { setDeviceId } from "@js/services/analytics/utils";

export const handleAuthAnalyticsActions = (action: TrackableUserAction) => {
  switch (action.type) {
    case REGISTERED: {
      logEvent(Events.SIGN_UP, {
        [EventsProperties.sign_up_type]: action.payload,
      });
      break;
    }

    case EMAIL_VERIFIED: {
      setDeviceId(action.payload.amplitudeDeviceId);
      break;
    }

    default: {
      break;
    }
  }
};
