import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { debounce } from "underscore";

import { useSearchFilterLocationContext } from "@js/apps/common/context/search-filter-location-context";
import { useSendSearchEventMutation } from "@js/apps/tracking/api";
import { setSearchEventQueryId } from "@js/apps/tracking/search-event-slice";
import { useAppDispatch } from "@js/hooks";
import { createSearchEventTrackingPayload } from "@js/middlewares/analytics/user-tracking";
import type { SearchEventEntityTypeKey } from "@js/types/tracking";

export const useSearchEvents = (entityTypeKey: SearchEventEntityTypeKey) => {
  const location = useLocation();
  const searchContext = useSearchFilterLocationContext();
  const dispatch = useAppDispatch();
  const [trackSearchEvent] = useSendSearchEventMutation();

  if (!searchContext) {
    throw new Error("SearchFilterLocationContext is missing");
  }

  const sendSearchEvent = useCallback(
    (value: string | undefined) => {
      const eventPayload = createSearchEventTrackingPayload({
        searchQuery: value,
        searchContext: searchContext,
        entityTypeKey,
        location,
      });
      trackSearchEvent(eventPayload)
        .unwrap()
        .then((res) => {
          dispatch(
            setSearchEventQueryId({
              id: res.id,
              option: searchContext,
            }),
          );
        })
        .catch(() => null);
    },
    [dispatch, trackSearchEvent, location, searchContext, entityTypeKey],
  );

  const sendSearchEventDebounced = useMemo(
    () => debounce(sendSearchEvent, 500),
    [sendSearchEvent],
  );

  return { sendSearchEventDebounced };
};
