import { type RoleFilter } from "@js/apps/common/components/filters/schemas/role-filter-schema";
import { type SkillsFilter } from "@js/apps/common/components/filters/schemas/skills-filter-schema";
import { createFiltersForm, FiltersManager } from "@js/apps/filters";

import type { RoleParams } from "./role-form";

export type SkillsFormType = { skills: SkillsFilter; role: RoleFilter };
export type SkillsParams = { skills: SkillsFilter };

export const defaultSkills: SkillsFormType = {
  skills: [],
  role: [],
};

export const defaultSkillsParams: SkillsParams & RoleParams = {
  skills: [],
  role: [],
};

export const SkillsForm = createFiltersForm<
  SkillsFormType,
  SkillsParams & RoleParams
>(
  new FiltersManager({
    useAllURLParams: true,
    defaultFormValues: defaultSkills,
    defaultParamsValues: defaultSkillsParams,
  }),
);
