import React, { useState } from "react";
import { isPristine as isPristineSelector, reset, submit } from "redux-form";

import { useWithRecaptcha } from "@js/apps/common/hooks";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { ACCOUNT_INFO_FORM_ID } from "../../forms/account-info";
import { PhoneVerification } from "../../forms/phone-verification";
import { SettingsLayout } from "../layout";

import { SettingsAccountInfoSection } from "./account-info-section";

const SettingsAccountInfoPage = () => {
  useWithRecaptcha();
  const dispatch = useAppDispatch();

  const isPristine = useAppSelector((state) =>
    isPristineSelector(ACCOUNT_INFO_FORM_ID)(state),
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  return (
    <SettingsLayout
      isSaveDisabled={isSaveDisabled}
      pageTitle="Account Info"
      onSave={() => dispatch(submit(ACCOUNT_INFO_FORM_ID))}
      onCancel={() => dispatch(reset(ACCOUNT_INFO_FORM_ID))}
      onCancelDisabled={isPristine}
    >
      <SettingsAccountInfoSection setIsSaveDisabled={setIsSaveDisabled} />
      <PhoneVerification />
    </SettingsLayout>
  );
};

export default SettingsAccountInfoPage;
