import { useGetFreelancerBidsQuery } from "@js/apps/freelancer/api";

export const useFreelancerBids = (showClosedApplications: boolean) => {
  const { isLoading: isLoadingCurrentBids, data: currentBids = [] } =
    useGetFreelancerBidsQuery({
      current: true,
    });

  const { isLoading: isLoadingHistoricalBids, data: historicalBids = [] } =
    useGetFreelancerBidsQuery(
      {
        historical: true,
      },
      { skip: !showClosedApplications },
    );

  const bids = showClosedApplications
    ? [...currentBids, ...historicalBids]
    : currentBids;

  return {
    isLoading: isLoadingCurrentBids || isLoadingHistoricalBids,
    bids,
  };
};
