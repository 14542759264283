import type { PostEventProperties } from "@js/middlewares/analytics/actions-handlers/feed";
import { postEventProperties } from "@js/services/analytics/constants";

type handleHashtagsParams = {
  post_location: string;
  eventProperties: PostEventProperties;
};

export const handleHashtags = ({
  post_location,
  eventProperties,
}: handleHashtagsParams) => {
  const params = new URL(document.location.toString()).searchParams;
  const hashtag_id = params.get("hashtag");
  const post_hashtag = params.get("post_hashtag");

  if (post_location === "hashtag") {
    if (hashtag_id) {
      eventProperties[
        postEventProperties.hashtag_id as keyof typeof postEventProperties
      ] = hashtag_id;
    }
    if (post_hashtag) {
      eventProperties[
        postEventProperties.post_hashtag as keyof typeof postEventProperties
      ] = post_hashtag;
    }
  }
};
