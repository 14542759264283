import React from "react";

import { RoundedBox, Typography } from "@hexocean/braintrust-ui-components";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import {
  isInterviewAvailable,
  useGetLastInterviewQuery,
} from "@js/apps/interview";
import { StartScreeningButton } from "@js/apps/jobs/components/job-action-buttons/components";

export const ScreeningCard = (): JSX.Element | null => {
  const { isFreelancerApproved, isEmployer } = useAccountType();
  const { data: interview } = useGetLastInterviewQuery(undefined, {
    skip: isFreelancerApproved || isEmployer,
  });
  const isInterviewAvailableForUser = isInterviewAvailable(interview);
  const user = useUser();

  if (isInterviewAvailableForUser && user && user.freelancer) {
    return (
      <ScreeningCardBase
        title="Start your screening!"
        content={
          "Only applications from individuals who have been approved as Talent are shown to clients, so it's essential to complete your screening to land a job on Braintrust."
        }
      />
    );
  }

  return null;
};

type ScreeningCardBaseProps = {
  title: string;
  content: JSX.Element | string;
};

const ScreeningCardBase = ({ title, content }: ScreeningCardBaseProps) => {
  return (
    <RoundedBox bgcolor="var(--medium-green)">
      <Typography component="p" variant="paragraph" size="large" mb={2}>
        {title}
      </Typography>
      <Typography component="p" variant="paragraph">
        {content}
      </Typography>
      <StartScreeningButton variant="primary" sx={{ mt: 2 }} withIcon />
    </RoundedBox>
  );
};
