import type { AccountType } from "@js/types/auth";

import { useUser } from "../use-user";

export const useAccountType = (): {
  isFreelancerApproved: boolean;
  isFreelancer: boolean;
  isEmployer: boolean;
  accountType: AccountType | undefined;
  isUserVerified: boolean;
} => {
  const user = useUser();

  const accountType = user?.account_type;
  const isFreelancerApproved = user?.freelancer_approved || false;
  const isFreelancer = accountType === ENUMS.AccountType.FREELANCER;
  const isEmployer = accountType === ENUMS.AccountType.EMPLOYER;
  const isUserVerified = user?.is_verified || false;

  return {
    isFreelancerApproved,
    isFreelancer,
    isEmployer,
    accountType,
    isUserVerified,
  };
};
