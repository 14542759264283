import {
  AI_VALUE,
  RANGE_VALUE,
  RATE_OPTIONS,
} from "@js/apps/jobs/apps/create-job/constants";
import type { JobFormRateValues, JobRate } from "@js/types/jobs";
import { assertUnreachable, deepClone, typedObjectKeys } from "@js/utils";

export const mapRateValuesToBackendFormat = <T extends JobFormRateValues>(
  values: T,
): Omit<T, keyof JobFormRateValues> & JobRate => {
  const originalValues = deepClone(values);

  const defaultJobRateValues: Partial<JobRate> = {
    payment_type: originalValues.payment_type,
    budget_minimum_usd: undefined,
    budget_maximum_usd: undefined,
  };

  const newValues: T & Partial<JobRate> = {
    ...originalValues,
    ...defaultJobRateValues,
  };

  const paymentType = originalValues.payment_type;

  switch (paymentType) {
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      newValues.budget_minimum_usd = originalValues.fixed_rate;
      newValues.budget_maximum_usd = originalValues.fixed_rate;
      break;
    }
    case ENUMS.JobPaymentType.HOURLY: {
      if (
        originalValues.hourly_rate === RANGE_VALUE ||
        originalValues.hourly_rate === AI_VALUE
      ) {
        newValues.budget_minimum_usd =
          originalValues.min_rate || originalValues.max_rate;
        newValues.budget_maximum_usd =
          originalValues.max_rate || originalValues.min_rate;
      } else {
        newValues.budget_minimum_usd = originalValues.hourly_rate;
        newValues.budget_maximum_usd = originalValues.hourly_rate;
      }
      break;
    }
    case ENUMS.JobPaymentType.ANNUAL: {
      newValues.budget_minimum_usd =
        originalValues.min_annual_rate || originalValues.max_annual_rate;
      newValues.budget_maximum_usd =
        originalValues.max_annual_rate || originalValues.min_annual_rate;
      break;
    }
    case undefined: {
      break;
    }
    default: {
      assertUnreachable(paymentType);
      break;
    }
  }

  delete newValues.hourly_rate;
  delete newValues.fixed_rate;
  delete newValues.min_rate;
  delete newValues.max_rate;
  delete newValues.max_annual_rate;
  delete newValues.min_annual_rate;
  delete newValues.custom_min_rate;
  delete newValues.custom_max_rate;

  typedObjectKeys(newValues).forEach((key) => {
    if (
      newValues[key as keyof typeof newValues] === undefined &&
      key in defaultJobRateValues
    ) {
      delete newValues[key];
    }
  });

  return newValues as T & JobRate;
};

export const mapRateValuesToFrontendFormat = <
  T extends Partial<JobRate> & { is_ai_generated?: boolean },
>(
  values: T,
):
  | (Omit<T, keyof JobRate> & JobFormRateValues)
  | { payment_type: undefined } => {
  const originalValues = deepClone(values);
  const originalPaymentType = originalValues.payment_type;

  const defaultJobRateValues: JobFormRateValues = {
    payment_type: originalPaymentType,
    fixed_rate: undefined,
    ai_max_rate: undefined,
    ai_min_rate: undefined,
    min_rate: undefined,
    max_rate: undefined,
    hourly_rate: undefined,
    min_annual_rate: undefined,
    max_annual_rate: undefined,
  };

  const newValues: T & JobFormRateValues = {
    ...defaultJobRateValues,
    ...values,
  };

  const paymentType = originalPaymentType;

  switch (paymentType) {
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      // in real world min and max will be always the same, but in case we choose bigger value
      newValues.fixed_rate = originalValues.budget_minimum_usd;
      newValues.fixed_rate = originalValues.budget_maximum_usd;
      break;
    }
    case ENUMS.JobPaymentType.HOURLY: {
      const isRateTheSame =
        originalValues.budget_minimum_usd === originalValues.budget_maximum_usd;
      const isRangeSelected = isRateTheSame
        ? !RATE_OPTIONS.find(
            (option) => option.value === originalValues.budget_minimum_usd,
          )
        : true;

      // backend should always provide budget_minimum_usd
      if (
        values.is_ai_generated &&
        isRangeSelected &&
        originalValues.budget_minimum_usd === originalValues.ai_min_rate &&
        originalValues.budget_maximum_usd === originalValues.ai_max_rate
      ) {
        newValues.min_rate = originalValues.budget_minimum_usd;
        newValues.max_rate = originalValues.budget_maximum_usd;
        newValues.hourly_rate = AI_VALUE;
      } else if (isRangeSelected && newValues.budget_minimum_usd) {
        newValues.min_rate = newValues.custom_min_rate =
          originalValues.budget_minimum_usd;
        newValues.max_rate = newValues.custom_max_rate =
          originalValues.budget_maximum_usd;
        newValues.hourly_rate = RANGE_VALUE;
      } else {
        newValues.hourly_rate = originalValues.budget_minimum_usd;
        newValues.hourly_rate = originalValues.budget_maximum_usd;
      }
      break;
    }
    case ENUMS.JobPaymentType.ANNUAL: {
      newValues.min_annual_rate = originalValues.budget_minimum_usd;
      newValues.max_annual_rate = originalValues.budget_maximum_usd;
      break;
    }
    case undefined: {
      break;
    }
    default: {
      assertUnreachable(paymentType);
      break;
    }
  }

  delete newValues.budget_minimum_usd;
  delete newValues.budget_maximum_usd;

  typedObjectKeys(newValues).forEach((key) => {
    if (newValues[key] === undefined && key in defaultJobRateValues) {
      delete newValues[key];
    }
  });

  return newValues;
};
