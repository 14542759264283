import { useEffect } from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { useGetEmployerInvoiceStatisticsQuery } from "@js/apps/invoices/api";
import { Snackbar } from "@js/components/snackbar";

import { EmployerInvoicesDueStatistic } from "./employer-invoices-due-statistic";
import { EmployerInvoicesOverdueStatistic } from "./employer-invoices-overdue-statistic";

import styles from "./styles.module.scss";

export const EmployerInvoicesStatistics = () => {
  const { data, isLoading, isError } = useGetEmployerInvoiceStatisticsQuery();

  useEffect(() => {
    if (!isError) {
      return;
    }

    Snackbar.error("Failed to fetch invoices statistics");
  }, [isError]);

  return (
    <Box className={styles.container}>
      <EmployerInvoicesDueStatistic isLoading={isLoading} data={data} />
      <EmployerInvoicesOverdueStatistic isLoading={isLoading} data={data} />
    </Box>
  );
};
