import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { ThunderIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./styles.module.scss";

type AvailableForWorkStatusProps = {
  short?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const AvailableForWorkStatus = ({
  short,
  onClick,
}: AvailableForWorkStatusProps) => {
  if (short) {
    return (
      <Box
        className={`${styles.availableForWorkStatus} ${styles.availableForWorkStatusShort}`}
      >
        <ThunderIcon />
      </Box>
    );
  }

  return (
    <Box
      className={`${styles.availableForWorkStatus} ${styles.availableForWorkStatusRegular}`}
      onClick={onClick}
    >
      <ThunderIcon />
      <Typography
        component="span"
        size="small"
        color="dark-violet"
        fontWeight={500}
      >
        Looking for Work
      </Typography>
    </Box>
  );
};
