import React from "react";

import type {
  ButtonProps,
  ButtonVariants,
} from "@hexocean/braintrust-ui-components";
import { Button, Typography } from "@hexocean/braintrust-ui-components";

type SnackbarActionButtonProps = {
  withUnderline?: boolean;
  variant?: ButtonVariants;
} & Partial<ButtonProps>;

export const SnackbarActionButton: React.FC<
  React.PropsWithChildren<SnackbarActionButtonProps>
> = ({ children, withUnderline, variant = "transparent", ...props }) => {
  return (
    <Button variant={variant} inverse size="small" {...props}>
      <Typography
        component="span"
        style={{ textDecoration: withUnderline ? "underline" : "none" }}
      >
        {children}
      </Typography>
    </Button>
  );
};
