import { submit } from "redux-form";

import { PERSONAL_INFO_FORM_ID } from "@js/apps/common/constants";
import { EmployerEditWizard } from "@js/apps/employer/components/employer-edit-wizard";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { EmployerPersonalInfoViewLazy } from "../../lazy";

export const EmployerEditPersonalInfoPage = () => {
  const dispatch = useAppDispatch();

  const onContinue = () => dispatch(submit(PERSONAL_INFO_FORM_ID));

  const isAvatarUploading = useAppSelector(
    (state) => state.employer.avatarFieldUploading,
  );

  return (
    <EmployerEditWizard
      pageTitle="Edit Profile | Personal Info"
      continueButtonColor="secondary"
      onContinue={onContinue}
      disableSubmit={isAvatarUploading}
    >
      <EmployerPersonalInfoViewLazy />
    </EmployerEditWizard>
  );
};
