import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { forcePageRemount } from "@js/apps/common/actions";
import { useAppDispatch } from "@js/hooks";

let previousLocation: ReturnType<typeof useLocation>;
export const useForcePageRemount = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    if (
      location?.pathname === previousLocation?.pathname &&
      location?.search === previousLocation?.search &&
      location?.hash === previousLocation?.hash &&
      !(location?.state as any)?.disableRemountIfTheSameLocation
    ) {
      dispatch(forcePageRemount());
    }

    previousLocation = location;
  }, [dispatch, location]);
};
