import type { Job, JobLocation, JobTimezone } from "@js/types/jobs";
import { isNotNullable } from "@js/utils";

type UseRequirementsIndicator = (arg: {
  locationRequirementsMet: boolean | null;
  timezoneRequirementsMet: boolean | null;
  job: Job;
}) => {
  displayLocationRequirement: boolean;
  displayTimezoneRequirement: boolean;
  multipleLocationsRequired: boolean;
  multipleTimezonesRequired: boolean;
};

export const useRequirementsIndicator: UseRequirementsIndicator = ({
  locationRequirementsMet,
  timezoneRequirementsMet,
  job,
}) => {
  const {
    locations: jobLocations,
    locations_strongly_required: locationRequired,
    timezones: jobTimezones,
    timezone_required: timezoneRequired,
  } = job;

  const multipleLocationsRequired = hasMoreThanOne<JobLocation>(jobLocations);
  const multipleTimezonesRequired = hasMoreThanOne<JobTimezone>(jobTimezones);

  const displayLocationRequirement =
    isNotNullable(locationRequirementsMet) && locationRequired;
  const displayTimezoneRequirement =
    isNotNullable(timezoneRequirementsMet) && timezoneRequired;

  return {
    multipleLocationsRequired,
    multipleTimezonesRequired,
    displayLocationRequirement,
    displayTimezoneRequirement,
  };
};

const hasMoreThanOne = <T>(arr?: T[] | null) => {
  return !!(arr && arr.filter(isNotNullable).length > 1);
};
