import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useUser } from "@js/apps/common/hooks/use-user";
import type { TaxFormType } from "@js/apps/freelancer/api";
import {
  useDeleteTaxFormMutation,
  useGetTaxFormQuery,
} from "@js/apps/freelancer/api";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";

import { AddW8Modal } from "../components/tax-documents/add-w8-modal";
import { AddW9Modal } from "../components/tax-documents/add-w9-modal";

export const useTaxDocuments = () => {
  const dispatch = useAppDispatch();
  const user = useUser();

  const country = user?.address?.country;

  const w8requiredBecauseOfCountry =
    !!country && !SETTINGS.COUNTRIES_WHERE_W9_IS_REQUIRED.includes(country);

  const isW8FormRequired =
    user?.is_w8_form_required || w8requiredBecauseOfCountry;

  const w9requiredBecauseOfCountry =
    !!country && SETTINGS.COUNTRIES_WHERE_W9_IS_REQUIRED.includes(country);
  const isW9FormRequired =
    user?.is_w9_form_required || w9requiredBecauseOfCountry;

  const freelancerId = user?.freelancer;

  const { data: w8TaxFormData, isLoading: isLoadingW8TaxForm } =
    useGetTaxFormQuery(
      { freelancerId: freelancerId as number, formType: "w8" },
      { skip: !isW8FormRequired },
    );
  const { data: w9TaxFormData, isLoading: isLoadingW9TaxForm } =
    useGetTaxFormQuery(
      { freelancerId: freelancerId as number, formType: "w9" },
      { skip: !isW9FormRequired },
    );

  const [deleteTaxForm] = useDeleteTaxFormMutation();

  const { form_uploaded_at: w9FormUploadedAt, form_url: w9FormUrl } =
    w9TaxFormData ?? {};
  const { form_uploaded_at: w8FormUploadedAt, form_url: w8FormUrl } =
    w8TaxFormData ?? {};

  const deleteTaxDocument = (type: TaxFormType) => {
    if (!freelancerId) {
      return;
    }

    deleteTaxForm({ freelancerId, formType: type }).then(() => {
      dispatch(fetchCurrentUser());
      ModalInstance.close();
      Snackbar.success(`Deleted Successfully.`);
    });
  };

  const openDeleteTaxDocumentConfirmation = (type: TaxFormType) =>
    ModalInstance.open({
      children: (
        <ModalConfirm
          onCancel={ModalInstance.close}
          confirmText="Delete"
          onConfirm={() => deleteTaxDocument(type)}
        >
          Are you sure you want to delete{" "}
          <span className="capitalize">{type}</span> form?
        </ModalConfirm>
      ),
    });

  const deleteW8Form = () => openDeleteTaxDocumentConfirmation("w8");
  const deleteW9Form = () => openDeleteTaxDocumentConfirmation("w9");

  const uploadW8Form = () => AddW8Modal.open();
  const uploadW9Form = () => AddW9Modal.open();

  const returnForFormType = <T,>({
    w8,
    w9,
  }: Record<"w8" | "w9", T>): T | null => {
    if (isW8FormRequired) {
      return w8;
    }

    if (isW9FormRequired) {
      return w9;
    }

    return null;
  };

  const formTypeName = returnForFormType({
    w8: "W8",
    w9: "W9",
  });
  const isFormRequired = returnForFormType({
    w8: isW8FormRequired,
    w9: isW9FormRequired,
  });
  const uploadedAt = returnForFormType({
    w8: w8FormUploadedAt,
    w9: w9FormUploadedAt,
  });
  const uploadedFormUrl = returnForFormType({
    w8: w8FormUrl,
    w9: w9FormUrl,
  });
  const uploadForm = returnForFormType({
    w8: uploadW8Form,
    w9: uploadW9Form,
  });
  const deleteForm = returnForFormType({
    w8: deleteW8Form,
    w9: deleteW9Form,
  });

  return {
    country,
    isW8FormRequired,
    isW9FormRequired,
    isLoading: isLoadingW8TaxForm || isLoadingW9TaxForm,
    deleteForm,
    uploadForm,
    isFormRequired,
    uploadedAt,
    uploadedFormUrl,
    returnForFormType,
    formTypeName,
  };
};
