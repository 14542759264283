import { Date } from "@js/components/date";
import type { FreelancerBid } from "@js/types/jobs";
import { DateFormats } from "@js/utils/date/types";

export const AppliedJobsCardLastActionDate = ({
  bid,
}: {
  bid: FreelancerBid;
}) => {
  switch (bid.status_for_freelancer) {
    case ENUMS.JobFreelancerStatus.NEW_OFFER:
    case ENUMS.JobFreelancerStatus.UPDATED_OFFER:
      if (!bid.current_offer_created_at) return null;
      return (
        <>
          Offer sent{" "}
          <Date
            date={bid.current_offer_created_at}
            format={DateFormats["Jan 1"]}
          />
        </>
      );
    case ENUMS.JobFreelancerStatus.IN_REVIEW:
    case ENUMS.JobFreelancerStatus.PENDING_APPROVAL:
    case ENUMS.JobFreelancerStatus.APPLICATION_SENT:
    case ENUMS.JobFreelancerStatus.JOB_ON_HOLD:
    default: {
      return (
        <>
          Applied <Date date={bid.created} format={DateFormats["Jan 1"]} />
        </>
      );
    }
  }
};
