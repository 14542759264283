import {
  Box,
  Button,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { EditPostModalInstance } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/modal-instances";
import { CreatePostSection } from "@js/apps/give-and-get-help/components/create-post";
import { PostLocation } from "@js/apps/give-and-get-help/context/post-location";
import { RouterLink } from "@js/components/link";

import { DasboardPostsSection } from "./dashboard-posts-section";

import style from "./style.module.scss";

export const DashboardGiveGetHelpModule = () => {
  const isMobile = useMediaQuery(720);

  return (
    <PostLocation.Provider value={PostLocation.Values.trending_posts_module}>
      <RoundedBox p={{ xs: "24px 0px", sm: 4, md: 3, lg: 4 }}>
        <Box className={style.header}>
          <Typography
            component="h3"
            variant={isMobile ? "paragraph" : "title"}
            size={isMobile ? "large" : "small"}
            fontWeight={400}
          >
            Get career help from the community
          </Typography>
          <Typography
            component="link"
            variant="paragraph"
            size={isMobile ? "medium" : "large"}
            RouterLink={RouterLink}
            to="/feed"
            target="_blank"
          >
            View all posts
          </Typography>
        </Box>
        <Box className={style.createPostContainer}>
          <CreatePostSection hideActionButtons />
        </Box>
        <DasboardPostsSection />
        <Box className={style.allPostsBtn}>
          <Button
            variant="secondary"
            to="/feed"
            shape="squared"
            target="_blank"
            RouterLink={RouterLink}
          >
            View all posts
          </Button>
        </Box>
      </RoundedBox>
      <EditPostModalInstance />
    </PostLocation.Provider>
  );
};
