import React from "react";

import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";

import { PublicTopNavigationDesktop } from "./public-top-navigation-desktop";
import { PublicTopNavigationMobile } from "./public-top-navigation-mobile";

export const PublicTopNavigation = () => {
  const isMobile = useMediaQuery("lg");

  return isMobile ? (
    <PublicTopNavigationMobile />
  ) : (
    <PublicTopNavigationDesktop />
  );
};
