import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { useNavigate, useQueryParams } from "@js/hooks";

import { getConfirmAction } from "./config";

import styles from "./styles.module.scss";

export const useConfirmAction = () => {
  const navigate = useNavigate();
  const query = useQueryParams();
  const location = useLocation();

  const { action, key } = query;

  const confirmationConfig = useMemo(() => {
    return getConfirmAction(action, {
      closeModal: ModalInstance.close,
      navigate,
      sourceLocation: location,
    });
  }, [navigate, action, location]);

  const shouldShowConfirmationModal = !!confirmationConfig && key;

  const onConfirm = useCallback(() => {
    if (confirmationConfig) {
      confirmationConfig.onConfirm(query);
    }
  }, [confirmationConfig, query]);

  const onCancel = useCallback(() => {
    if (confirmationConfig) {
      ModalInstance.close();
      navigate(confirmationConfig.initialPathForAction);
    }
  }, [confirmationConfig, navigate]);

  useEffect(() => {
    if (shouldShowConfirmationModal) {
      ModalInstance.open({
        children: (
          <ModalConfirm
            onConfirm={onConfirm}
            confirmText="Confirm"
            onCancel={onCancel}
          >
            <div className="pt+">{confirmationConfig.content}</div>
          </ModalConfirm>
        ),
        className: styles.modal,
        closeButton: false,
      });
    }
  }, [
    shouldShowConfirmationModal,
    confirmationConfig,
    onCancel,
    onConfirm,
    action,
    key,
  ]);

  return {
    shouldShowConfirmationModal,
  };
};
