import React from "react";

import { useIsNodeStaff } from "@js/apps/common/hooks";
import { useAccountType } from "@js/apps/common/hooks";
import { FreelancerActionBarForJobDetailsPage } from "@js/apps/freelancer/components/freelancer-action-bar";
import type { Job } from "@js/types/jobs";

import { CoreTeamActionBarProvider } from "./core-team/core-team-context";
import { CoreTeamActionBar } from "./core-team";
import { EmployerActionBar } from "./employer";
import { JobActionBarProvider } from "./job-context";

type JobDetailsActionBarProps = {
  job: Job;
};

export const JobDetailsActionBar = ({ job }: JobDetailsActionBarProps) => {
  const { isEmployer, isFreelancer } = useAccountType();
  const isNodeStaff = useIsNodeStaff();

  if (isNodeStaff) {
    return (
      <CoreTeamActionBarProvider job={job}>
        <CoreTeamActionBar />
      </CoreTeamActionBarProvider>
    );
  }

  if (isEmployer) {
    return (
      <JobActionBarProvider job={job}>
        <EmployerActionBar />
      </JobActionBarProvider>
    );
  }

  if (isFreelancer) {
    return (
      <JobActionBarProvider job={job}>
        <FreelancerActionBarForJobDetailsPage />
      </JobActionBarProvider>
    );
  }

  return null;
};
