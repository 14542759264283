import React from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";

type CompleteHelpOfferModalProps = {
  completeOffer: () => void;
  offerAuthorFirstName: string;
};

export const CompleteHelpOfferModal = ({
  completeOffer,
  offerAuthorFirstName,
}: CompleteHelpOfferModalProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography component="p" size="large" fontWeight={500} mb={3}>
        Mark 1:1 help complete
      </Typography>
      <Box>
        <Typography component="p" variant="title" fontWeight={400} mb={1}>
          Are you finished working with {offerAuthorFirstName}?
        </Typography>
        <Typography component="p" mb={3}>
          If so, you can mark their 1:1 help as complete and leave a review on{" "}
          their profile.
        </Typography>
        <Typography component="p" mb={3}>
          This will close out the offer and complete the BTRST transaction.
        </Typography>
      </Box>
      <Box ml="auto">
        <Button variant="positive" shape="squared" onClick={completeOffer}>
          Mark complete
        </Button>
      </Box>
    </Box>
  );
};
