import { CTA_CLICKED_EVENT } from "@js/apps/common/action-types";
import { NAV_ITEM_CLICKED } from "@js/apps/freelancer/action-types";
import type { TrackableUserAction } from "@js/middlewares/analytics/types";
import { Events, EventsProperties } from "@js/services/analytics";
import { logEvent } from "@js/services/analytics/event-logging";

export const handleCommonAnalyticsActions = (action: TrackableUserAction) => {
  switch (action.type) {
    case CTA_CLICKED_EVENT: {
      const { ctaName, ctaLocation } = action.payload;

      logEvent(Events.USER_CLICKS, {
        cta_name: ctaName,
        cta_location: ctaLocation,
      });
      break;
    }

    case NAV_ITEM_CLICKED: {
      logEvent(Events.NAV_ITEM_CLICK, action.payload);
      break;
    }

    case Events.FILTER_SEARCH: {
      logEvent(Events.FILTER_SEARCH, action.payload);
      break;
    }

    case Events.CLICK_REFERRAL_FAQ: {
      const { faq_header } = action.payload;
      logEvent(Events.CLICK_REFERRAL_FAQ, {
        [EventsProperties.faq_header]: faq_header,
      });
      break;
    }

    case Events.COPY_REFERRAL_TEMPLATE: {
      const { template_content } = action.payload;
      logEvent(Events.COPY_REFERRAL_TEMPLATE, {
        [EventsProperties.template_content]: template_content,
      });
      break;
    }
    default: {
      break;
    }
  }
};
