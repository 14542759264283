import { Box, Loader } from "@hexocean/braintrust-ui-components";
import type { Skill } from "@js/types/admin";

import { SelectablePopularSkills } from "../selectable-popular-skills";

import styles from "./selected-skills.module.scss";

export type SelectedSkillsProps = {
  skillsValue: number[];
  selectedSkills: Pick<Skill, "id" | "name">[];
  toggleSkill: (skill: Pick<Skill, "id" | "name">) => void;
};

export const SelectedSkills = ({
  skillsValue,
  selectedSkills,
  toggleSkill,
}: SelectedSkillsProps) => {
  if (!skillsValue.length) return null;

  return (
    <Box display="flex" gap={1} className={styles.container}>
      {!selectedSkills.length ? (
        <Loader size={18} centered />
      ) : (
        <SelectablePopularSkills
          skills={selectedSkills}
          handleClick={toggleSkill}
          selected
        />
      )}
    </Box>
  );
};
