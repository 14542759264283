import React from "react";

import { Stack, Typography } from "@hexocean/braintrust-ui-components";
import { Member } from "@js/apps/spaces/components/invite-to-space-modal/member";
import { MembersList } from "@js/apps/spaces/components/invite-to-space-modal/members-list/members-list";
import { MembersListItem } from "@js/apps/spaces/components/invite-to-space-modal/members-list/members-list-item";
import type { MemberInviteData } from "@js/apps/spaces/components/invite-to-space-modal/types";

type SelectedMembersListProps = {
  members: MemberInviteData[];
  onRemove: (member: MemberInviteData) => void;
};
export const SelectedMembersList = ({
  members,
  onRemove,
}: SelectedMembersListProps) => {
  return (
    <Stack sx={{ flex: 1, overflow: "hidden" }}>
      {Boolean(members.length) && (
        <>
          <Typography
            component="span"
            display="block"
            variant="label"
            size="small"
            fontWeight={500}
            sx={{ mb: 2 }}
          >
            Send invite to
          </Typography>
          <MembersList>
            {members.map((member) => {
              return (
                <MembersListItem
                  key={member.id || member.inputValue}
                  onRemove={() => onRemove(member)}
                >
                  <Member member={member} />
                </MembersListItem>
              );
            })}
          </MembersList>
        </>
      )}
    </Stack>
  );
};
