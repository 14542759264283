import React from "react";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import { Box, RoundedBox } from "@hexocean/braintrust-ui-components";
import { JobTypeTag, RolesTag } from "@js/apps/jobs/components";
import { JobSectionTitle } from "@js/apps/jobs/components/job-section-title";
import type { Role } from "@js/types/roles";

type RoleAndJobTypeBoxProps = BoxProps & {
  jobType: EnumType<typeof ENUMS.JobType>;
  jobRole: Role;
};

export const RoleAndJobTypeBox = ({
  jobType,
  jobRole,
  ...props
}: RoleAndJobTypeBoxProps) => {
  return (
    <RoundedBox height="100%" {...props}>
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <JobSectionTitle>Role and Job Type</JobSectionTitle>
      </Box>
      <Box className="job-details-page__skills-wrapper">
        <RolesTag role={jobRole} />
        <JobTypeTag type={jobType} />
      </Box>
    </RoundedBox>
  );
};
