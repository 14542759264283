import { createContext, useContext } from "react";

type CloseSearchContextType = null | (() => void);

export const CloseSearchContext = createContext<CloseSearchContextType>(null);

export const useCloseSearchContext = () => {
  const closeSearch = useContext(CloseSearchContext);
  const handleCloseSearch = () => {
    if (!closeSearch) return;
    closeSearch();
  };

  return handleCloseSearch;
};
