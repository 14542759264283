import { createContext, useContext, useMemo } from "react";

import type { CtaClickedActionTypes } from "@js/apps/common/actions";
import { CtaClickedAction } from "@js/apps/common/actions";
import { useAppDispatch } from "@js/hooks";

type CtaClickedContextType = {
  ctaClicked: (ctaName: CtaClickedActionTypes["ctaName"]) => void;
};

const CtaClickedContext = createContext<CtaClickedContextType>({
  ctaClicked: () => {
    /* Placeholder function to avoid multiple if checks. */
  },
});

type CtaClickedProviderProps = {
  children: React.ReactNode;
  location: CtaClickedActionTypes["ctaLocation"];
};

export const CtaClickedProvider = ({
  children,
  location,
}: CtaClickedProviderProps) => {
  const dispatch = useAppDispatch();

  const value: CtaClickedContextType = useMemo(
    () => ({
      ctaClicked: (ctaName) =>
        dispatch(
          CtaClickedAction({
            ctaName,
            ctaLocation: location,
          }),
        ),
    }),
    [dispatch, location],
  );

  return (
    <CtaClickedContext.Provider value={value}>
      {children}
    </CtaClickedContext.Provider>
  );
};

export const useCtaClickedContext = () => {
  const value = useContext(CtaClickedContext);
  return value;
};
