import { useEffect } from "react";

import {
  FRONTEND_STORAGE_KEYS,
  useLazyGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { useUser } from "@js/apps/common/hooks/use-user";
import { logEvent } from "@js/services/analytics/event-logging";
import { useLogViewEvent } from "@js/services/analytics/hooks/use-log-view-event";

import { Events, EventsProperties } from "../../constants";

import { initializeAmplitude } from "./initialize-amplitude";

export { Events, EventsProperties };

export const useInitializeAmplitudeAnalytics = () => {
  const user = useUser();
  const [getStorageValue] = useLazyGetStorageValueQuery();
  const [setStorageValue] = useSetStorageValueMutation();

  const {
    registered_via_linkedin: registeredViaLinkedin,
    id: userId,
    last_login: lastLogin,
  } = user ?? {};

  useLogViewEvent(user);

  useEffect(() => {
    if (!userId) {
      return initializeAmplitude(undefined);
    }

    initializeAmplitude(userId, { token: true });

    if (!registeredViaLinkedin) {
      return;
    }

    // it's not possible to tell when linkedin account is created
    // so we send event when user enters the app for the first time
    let isCancelled = false;
    getStorageValue(
      { key: FRONTEND_STORAGE_KEYS.entered_the_page_first_time_at },
      true,
    )
      .unwrap()
      .then((result) => {
        const hasEnteredBefore = !!result;
        if (hasEnteredBefore || isCancelled) {
          return;
        }

        logEvent(Events.CREATE_ACCOUNT);
        setStorageValue({
          key: FRONTEND_STORAGE_KEYS.entered_the_page_first_time_at,
          value: String(new Date().toISOString()),
        });
      })
      .catch(() => null);

    return () => {
      isCancelled = true;
    };
  }, [
    userId,
    registeredViaLinkedin,
    lastLogin,
    getStorageValue,
    setStorageValue,
  ]);
};
