import React from "react";
import { Field } from "redux-form";

import { PasswordField } from "@js/forms/fields/password";

export const SignupPasswordField: React.FC = () => {
  return (
    <Field
      variant="outlined"
      color="secondary"
      name="password"
      id="password"
      component={PasswordField}
      placeholder="Use at least 10 characters"
      label="Create password*"
    />
  );
};
