import type { ComponentProps } from "react";
import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { touch } from "redux-form";

import type { ToggleButtonProps } from "@hexocean/braintrust-ui-components";
import {
  FormHelperText,
  ToggleButton,
  ToggleButtonGroup,
} from "@hexocean/braintrust-ui-components";

type ToggleButtonGroupProps = ComponentProps<typeof ToggleButtonGroup>;
type ToggleButtonGroupFieldProps = TypedWrappedFieldProps & {
  options: ({ label: React.ReactNode } & ToggleButtonProps)[];
  disableUnselecting?: boolean;
} & ToggleButtonGroupProps;

export const ToggleButtonGroupField = ({
  options,
  meta: { dispatch, form, error, touched },
  input,
  disableUnselecting,
  ...rest
}: ToggleButtonGroupFieldProps): JSX.Element => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: unknown,
  ) => {
    if (!disableUnselecting || value) {
      // When clicking on text inside button, field value is not passed, it fixes it
      event.target = event.currentTarget;
      input.onChange(event);
      dispatch(touch(form, input.name));
    }
  };

  return (
    <ToggleButtonGroup
      exclusive
      value={input.value}
      onChange={handleChange}
      {...rest}
    >
      {options.map(({ label, ...option }) => (
        <ToggleButton key={JSON.stringify(option.value)} {...option}>
          {label}
        </ToggleButton>
      ))}
      {error && touched && <FormHelperText error>{error}</FormHelperText>}
    </ToggleButtonGroup>
  );
};
