import type { FreelancerLocationMismatch } from "@js/apps/admin/types";
import { isNotNullable } from "@js/utils";

import { LocationMismatchItem } from "./location-mismatch-item";

import styles from "../../styles.module.scss";

export type LocationMismatchModalContentProps = {
  billingLocationMismatch: FreelancerLocationMismatch["billing_location_mismatch"];
  lastActiveSessionMismatch: FreelancerLocationMismatch["last_active_session_mismatch"];
  billingLocation: FreelancerLocationMismatch["billing_location"];
  lastActiveSession: FreelancerLocationMismatch["last_active_session"];
  profileLocation: FreelancerLocationMismatch["profile_location"];
};

export const LocationMismatchModalContent = ({
  billingLocationMismatch,
  lastActiveSessionMismatch,
  billingLocation,
  lastActiveSession,
  profileLocation,
}: LocationMismatchModalContentProps) => {
  const displayLastActiveSessionMismatch = shouldRenderItem(
    !!lastActiveSessionMismatch,
    lastActiveSession?.location,
    profileLocation,
  );
  const displayBillingLocationMismatch = shouldRenderItem(
    !!billingLocationMismatch,
    billingLocation,
    profileLocation,
  );

  return (
    <div className={styles.modalContent}>
      <LocationMismatchItem
        label="Profile location"
        location={getProfileLocationContent(profileLocation)}
      />
      {displayLastActiveSessionMismatch && (
        <LocationMismatchItem
          label="Last active session"
          location={lastActiveSession.location}
          date={lastActiveSession.active_at}
        />
      )}
      {displayBillingLocationMismatch && (
        <LocationMismatchItem
          label="Billing location"
          location={billingLocation}
        />
      )}
    </div>
  );
};

const getProfileLocationContent = (profileLocation: string | null) =>
  isNotNullable(profileLocation) ? profileLocation : "Profile location not set";

const shouldRenderItem = (
  isItemMismatched: boolean,
  itemLocation: string | undefined,
  profileLocation: string | null,
) => isItemMismatched || (!isNotNullable(profileLocation) && itemLocation);
