import { useState } from "react";

import { parseShareWithTeamMembersError } from "@js/apps/employer/utils/team-members";
import { openDecoratedSuccessSnackbar } from "@js/components/decorated-success-snackbar";
import { Snackbar } from "@js/components/snackbar";
import { pluralize } from "@js/utils/string";

import {
  useGetEmployerTeamMembersQuery,
  useShareBidsWithEmployerTeamMemberInvitationsMutation,
  useShareBidsWithEmployerTeamMembersMutation,
} from "../../api";
import {
  closeCommonTeamMembersModal,
  openCommonTeamMembersModal,
} from "../common-team-members-modal";

import { ShareBidsWithTeamMembersModalContent } from "./share-bids-with-team-members-modal-content";

type ShareBidsWithTeamMembersModalProps = {
  bidIds: number[];
  jobOwnerId: number | undefined;
};

export const ShareBidsWithTeamMembersModal = ({
  bidIds,
  jobOwnerId,
}: ShareBidsWithTeamMembersModalProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { data: teamMembers, isLoading: isLoadingTeamMembers } =
    useGetEmployerTeamMembersQuery();
  const [
    shareBidsWithEmployerTeamMemberInvitations,
    { isLoading: isSharingWithInvitations },
  ] = useShareBidsWithEmployerTeamMemberInvitationsMutation();
  const [
    shareBidsWithEmployerTeamMembers,
    { isLoading: isSharingWithTeamMembers },
  ] = useShareBidsWithEmployerTeamMembersMutation();

  const clearErrorMessage = () => {
    setErrorMessage(undefined);
  };

  const handleShareBids = async ({
    emailInvitations,
    teamMemberIds,
    message,
  }: {
    emailInvitations: string[];
    teamMemberIds: number[];
    message?: string;
  }) => {
    if (!emailInvitations.length && !teamMemberIds.length) {
      return closeShareBidsWithTeamMembersModal();
    }

    clearErrorMessage();

    try {
      if (emailInvitations.length) {
        await shareBidsWithEmployerTeamMemberInvitations({
          bids: bidIds,
          emails: emailInvitations,
          message,
        }).unwrap();
      }

      if (teamMemberIds.length) {
        await shareBidsWithEmployerTeamMembers({
          bids: bidIds,
          team_members: teamMemberIds,
          message,
        }).unwrap();
      }

      handleShareBidsSuccess({
        applicantsCount: bidIds.length,
      });

      closeShareBidsWithTeamMembersModal();
    } catch (error) {
      setErrorMessage(parseShareWithTeamMembersError(error));
    }
  };

  const isLoading = isLoadingTeamMembers;
  const isSaving = isSharingWithInvitations || isSharingWithTeamMembers;

  return (
    <ShareBidsWithTeamMembersModalContent
      jobOwnerId={jobOwnerId}
      onSave={handleShareBids}
      teamMembers={teamMembers}
      isLoading={isLoading}
      isSaving={isSaving}
      bidsToShareCount={bidIds.length}
      errorMessage={errorMessage}
      clearErrorMessage={clearErrorMessage}
    />
  );
};

export const openShareBidsWithTeamMembersModal = ({
  bidIds,
  jobOwnerId,
}: ShareBidsWithTeamMembersModalProps) => {
  if (!bidIds.length) {
    Snackbar.error("At least one applicant must be shared");
    return;
  }
  openCommonTeamMembersModal({
    children: (
      <ShareBidsWithTeamMembersModal bidIds={bidIds} jobOwnerId={jobOwnerId} />
    ),
  });
};

export const closeShareBidsWithTeamMembersModal = () => {
  closeCommonTeamMembersModal();
};

const handleShareBidsSuccess = ({
  applicantsCount,
}: {
  applicantsCount: number;
}) => {
  openDecoratedSuccessSnackbar(
    `Shared ${applicantsCount} applicant${pluralize(applicantsCount)} with your team`,
  );
};
