import { useAvailableForWorkCoachMark } from "./use-available-for-work-coach-mark";
import { useWorkingHoursCoachMark } from "./use-working-hours-coach-mark";

export const useCombinedCoachMarks = () => {
  const { showWorkingHoursCoachMark, handleWorkingHoursCoachMarkClose } =
    useWorkingHoursCoachMark();

  const {
    showAvailableForWorkCoachMark,
    handleAvailableForWorkCoachMarkClose,
  } = useAvailableForWorkCoachMark();

  return {
    showAvailableForWorkCoachMark,
    showWorkingHoursCoachMark,
    handleAvailableForWorkCoachMarkClose,
    handleWorkingHoursCoachMarkClose,
  };
};
