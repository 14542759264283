import { Field } from "redux-form";

import { TextareaField } from "@js/forms/fields";

import styles from "./style.module.scss";

export const NotesToRecipientField = () => {
  return (
    <Field
      className={styles.addNotesForm}
      variant="standard"
      label="NOTES TO RECIPIENT"
      placeholder="Notes"
      InputLabelProps={{ shrink: true }}
      id="notes_to_receipt"
      name="notes_to_receipt"
      rows={3}
      component={TextareaField}
    />
  );
};
