export const hideApplications = (
  bidIds: number[],
  hideProposals: (bidIds: number[], isHidden: boolean) => Promise<void>,
  isShowingHiddenBids: boolean,
) => {
  if (isShowingHiddenBids) {
    return hideProposals(bidIds, false);
  } else {
    return hideProposals(bidIds, true);
  }
};
