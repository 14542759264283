import { useIsNodeStaff, useUser } from "@js/apps/common/hooks";
import {
  useGetOrCreateMessageRoomMutation,
  useManualSendMessageMutation,
} from "@js/apps/messenger/api";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { useUpdateEmployerBidInterviewSectionMutation } from "../../api";
import type { SendMessageFormValues } from "../../forms";
import { getRoomContext } from "../../helpers";

export const useRequestLiveInterview = (bid: EmployerBidListBid, job: Job) => {
  const [getOrCreateMessageRoom] = useGetOrCreateMessageRoomMutation();
  const [manualSendMessage] = useManualSendMessageMutation();
  const [updateEmployerBidInterviewSection] =
    useUpdateEmployerBidInterviewSectionMutation();

  const isNodeStaff = useIsNodeStaff();
  const user = useUser();

  if (!user) return null;

  const name = bid?.freelancer.user.first_name || "there";
  const initialMessage =
    `Hi ${name} 👋,\n\n` +
    `Thanks for applying to this role.\n\n` +
    `I'd love to schedule a call with you. Please let me know a few times that work for you.\n\n` +
    `Thanks!`;

  const authorId = isNodeStaff ? job.creator?.id : user?.id;

  const handleSendMessageError = () => {
    Snackbar.error(
      "Sending message failed. Please refresh the page and try again",
    );

    ModalInstance.close();
  };

  const handleSubmit = async (values: SendMessageFormValues) => {
    const { message } = values;

    if (typeof authorId === "undefined") {
      return;
    }

    const roomResponse = await getOrCreateMessageRoom({
      context: { participants: [authorId, bid.freelancer.user.id] },
    });

    if ("error" in roomResponse) {
      return handleSendMessageError();
    }

    const { data: room } = roomResponse;

    updateEmployerBidInterviewSection({
      bidId: bid.id,
      status: ENUMS.JobInterviewStatus.ROUND_2,
    });

    return manualSendMessage({
      payload: { message_content: message, author: authorId },
      room_context: {
        content_type: ENUMS.RoomTypeContentType.P2P_ROOM_TYPE,
        context: getRoomContext(user, isNodeStaff, room, authorId),
      },
    })
      .unwrap()
      .then(() => ModalInstance.close())
      .catch(() => handleSendMessageError());
  };

  return { name, initialMessage, handleSubmit };
};
