import { useMemo } from "react";

import type { ExistingFile } from "../../types";

export type UseSelectedDisplayedImageArg = {
  displayedImage?: string;
  existingImages?: ExistingFile[];
  value: string | number;
};

export const useSelectedDisplayedImage = ({
  displayedImage,
  existingImages,
  value,
}: UseSelectedDisplayedImageArg) => {
  const selectedDisplayedImage = useMemo(() => {
    if (displayedImage) {
      return displayedImage;
    }

    if (!existingImages?.length) {
      return;
    }

    const imageSelectedFromExisting = existingImages.find(
      ({ id }) => String(id) === String(value),
    )?.attachment.file;

    return imageSelectedFromExisting;
  }, [displayedImage, existingImages, value]);

  return { selectedDisplayedImage };
};
