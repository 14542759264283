import type { TypographyProps } from "@hexocean/braintrust-ui-components";
import {
  Button,
  Divider,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";

export type AddTeamMemberDrawerHeaderProps = {
  onSave: () => void;
  onClose: () => void;
};

export const AddTeamMemberDrawerHeader = ({
  onSave,
  onClose,
}: AddTeamMemberDrawerHeaderProps) => {
  const isMobile = useMediaQuery("sm");
  const titleProps: Pick<TypographyProps, "variant" | "size"> = isMobile
    ? {
        variant: "paragraph",
        size: "large",
      }
    : {
        variant: "title",
        size: "small",
      };

  return (
    <Stack
      direction={{ xs: "column-reverse", sm: "row" }}
      gap={{
        xs: 3,
        sm: 2,
      }}
      justifyContent="space-between"
      alignItems={{
        xs: "flex-start",
        sm: "center",
      }}
    >
      <Typography component="h2" fontWeight={400} {...titleProps}>
        Subscribe team members to this job
      </Typography>
      {isMobile && <Divider color="light-grey" />}
      <Stack
        direction="row"
        gap={1}
        alignSelf={{
          xs: "flex-end",
          sm: "center",
        }}
      >
        <Button
          variant="positive"
          shape="squared"
          onClick={onSave}
          size="medium"
        >
          Save
        </Button>
        <Button
          variant="white-grey"
          shape="squared"
          startIcon={<CloseIcon />}
          onClick={onClose}
          size="medium"
        >
          Close
        </Button>
      </Stack>
    </Stack>
  );
};
