import { useMemo } from "react";

import { useAppSelector } from "@js/hooks";

import { getBids } from "../../components/bulk-select-actions/bid-bulk-selection-slice";

export const useSelectedBidsIds = () => {
  const bids = useAppSelector(getBids);
  const selectedBids = useMemo(
    () => bids.filter((bid) => bid.selected),
    [bids],
  );
  const selectedBidsIds = useMemo(
    () => selectedBids.map((selected) => selected.id),
    [selectedBids],
  );
  return { selectedBidsIds };
};
