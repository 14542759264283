import { useUser } from "@js/apps/common/hooks";
import {
  PostLocation,
  usePostLocationContext,
} from "@js/apps/give-and-get-help/context/post-location";

import { usePostContext } from "../../context";

export const useDisableSpecificPostActions = () => {
  const user = useUser();
  const postLocation = usePostLocationContext();
  const post = usePostContext();

  if (
    postLocation === PostLocation.Values.space ||
    postLocation === PostLocation.Values.single_space_post_view ||
    (postLocation === PostLocation.Values.bookmark && post?.postData?.space) ||
    (postLocation === PostLocation.Values.my_posts && post?.postData?.space)
  )
    return false;

  return !!user?.is_banned_from_posting;
};
