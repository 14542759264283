import { useLocation } from "react-router-dom";

import { Box } from "@hexocean/braintrust-ui-components";
import { ArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { getUTMQueryParamsSearch } from "@js/utils";

import styles from "./styles.module.scss";

export const PublicJobsSeeAllJobsButton = () => {
  const { search } = useLocation();
  return (
    <RouterLink
      to={`/jobs?${getUTMQueryParamsSearch(search)}`}
      className={styles.wrapper}
    >
      <Box className={styles.button}>
        <ArrowLeftIcon />
        See all jobs
      </Box>
    </RouterLink>
  );
};
