import type { RefObject } from "react";
import { useEffect, useRef } from "react";

import { useWebsocket } from "@js/hooks";
import type { IPost } from "@js/types/give-and-get-help";

type UseVisiblePostSubscriptionProps = {
  postData: IPost;
  postElementRef: RefObject<HTMLDivElement>;
  visibilityMarginY?: number;
  timeoutOffset?: number;
  disablePostSubscription?: boolean;
};

export const useVisiblePostSubscription = ({
  postData,
  postElementRef,
  visibilityMarginY = 250,
  timeoutOffset = 3000,
  disablePostSubscription,
}: UseVisiblePostSubscriptionProps) => {
  const { send, isRunning } = useWebsocket();

  const isSubscribedRef = useRef(false);
  const isVisibleRef = useRef(false);
  const setIsSubscribed = (value: boolean) => (isSubscribedRef.current = value);

  const postId = postData.id;

  const toggleSubscription = (value: boolean) => {
    const command = value
      ? ENUMS.StreamType.POSTS_SUBSCRIBE
      : ENUMS.StreamType.POSTS_UNSUBSCRIBE;

    // prevent un/subscription when someone is scrolling fast
    const timeout = setTimeout(() => {
      if (isVisibleRef.current === value) {
        send(command, { post_ids: [postId] });
        setIsSubscribed(value);
      } else {
        clearTimeout(timeout);
      }
    }, timeoutOffset);
  };

  useEffect(() => {
    if (disablePostSubscription) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        const isSubscribed = isSubscribedRef.current;
        const isVisible = entry.isIntersecting;
        isVisibleRef.current = isVisible;

        if (isRunning) {
          if (isVisible && !isSubscribed) {
            toggleSubscription(true);
          }

          if (!isVisible && isSubscribed) {
            toggleSubscription(false);
          }
        }
      },
      {
        threshold: 0,
        rootMargin: `${visibilityMarginY}px 0px`,
      },
    );

    const postElement = postElementRef.current;

    if (postElement) {
      observer.observe(postElement);
    }

    return () => {
      if (postElement) {
        observer.unobserve(postElement);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning]);
};
