import React from "react";
import type { Stripe } from "@stripe/stripe-js";

import { Button } from "@hexocean/braintrust-ui-components";

type NullStripeFeedbackProps = {
  children: React.ReactNode;
  stripe: Stripe | null;
};

const NullStripeFeedback = ({ children, stripe }: NullStripeFeedbackProps) => {
  if (stripe) {
    return <>{children}</>;
  } else {
    return (
      <>
        <p>
          We couldn't connect with payments provider. Please refresh the page
          and try again
        </p>
        <div className="buttons right">
          <Button
            variant="transparent"
            color="secondary"
            onClick={() => window.location.reload()}
          >
            Refresh the page
          </Button>
        </div>
      </>
    );
  }
};

export default NullStripeFeedback;
