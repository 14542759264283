import type { TypedWrappedFieldProps } from "redux-form";
import { Fields } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { FieldSectionPanel } from "@js/apps/jobs/forms/fields";
import { CreateJobSkillsField } from "@js/apps/skills/fields";
import { shouldDisplayError } from "@js/forms/utils";

type SkillsSectionProps = {
  new_skills: TypedWrappedFieldProps<number[]>;
  top_skills: TypedWrappedFieldProps<number[]>;
};

export const SkillsSection = ({
  new_skills,
  top_skills,
}: SkillsSectionProps) => {
  const fields = [new_skills, top_skills];
  const displayError = shouldDisplayError(fields);

  return (
    <FieldSectionPanel
      title="Skills"
      description="Target the right Talent with the skills you need"
      isError={displayError}
    >
      <Box display="flex" flexDirection="column" maxHeight="750px">
        <Typography component="p" size="large" mb={2}>
          Add as many skills as you need to, marking at least 1 top skill to
          strengthen your matches
        </Typography>
        <Fields
          names={["is_ai_generated", "role", "new_skills", "top_skills"]}
          component={CreateJobSkillsField}
        />
      </Box>
    </FieldSectionPanel>
  );
};
