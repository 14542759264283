import type { TypedWrappedFieldProps } from "redux-form";
import cx from "classnames";

import {
  Box,
  ButtonCore,
  IconButton,
  Tooltip,
  Typography,
  useButtonSelect,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useIsNodeStaff, useUser } from "@js/apps/common/hooks";
import { useGetManagedEmployerQuery } from "@js/apps/employer/hooks";
import { getJobTypeLabels } from "@js/apps/jobs/utils";
import { RouterLink } from "@js/components/link";
import { enumToOptions } from "@js/utils";

import styles from "./style.module.scss";

const optionsVariants = {
  grant: "gray-red",
  direct_hire: "gray-orange",
  freelance: "gray-yellow",
};

type JobType = Exclude<EnumType<typeof ENUMS.JobType>, "grant">;

export const JobTypeField = ({
  input,
  meta,
}: TypedWrappedFieldProps<JobType | undefined>) => {
  const isMobile = useMediaQuery("sm");
  const { handleChange, isSelected, containerA11yProps, itemA11yProps } =
    useButtonSelect({
      onChange: (value) => input.onChange(value as JobType),
      value: input.value,
    });

  const isStaff = useIsNodeStaff();

  const { data: employerProfile } = useGetManagedEmployerQuery();
  const { can_create_grant_job } = employerProfile || {};

  const canCreateGrantJob = can_create_grant_job || isStaff;

  const user = useUser();
  const JobTypeLabels = getJobTypeLabels(user?.account_type);

  const options = canCreateGrantJob
    ? enumToOptions(JobTypeLabels)
    : enumToOptions(JobTypeLabels).filter(
        (option) => option.value !== ENUMS.JobType.GRANT,
      );

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography component="p" display="block" variant="label">
          What type of job is this?
        </Typography>
        <Tooltip
          title={
            <Typography component="p" variant="paragraph">
              The main difference between these is the contract type and how
              Braintrust fees are handled.{" "}
              <Typography
                variant="link"
                component="a"
                href={SETTINGS.SUPPORT_URLS.JOB_TYPES_AND_FEES_ARTICLE_URL}
                rel="noopener noreferrer"
                target="_blank"
                color="inherit"
                RouterLink={RouterLink}
              >
                Learn more about job types and fees ↗️
              </Typography>
            </Typography>
          }
          placement={isMobile ? "top" : "right"}
        >
          <span>
            <IconButton variant="transparent" size="small" aria-label="Info">
              <InfoIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      {meta.error && meta.touched && (
        <Typography component="p" color="negative" size="small">
          {meta.error}
        </Typography>
      )}
      <Box className={styles.jobTypeField} {...containerA11yProps}>
        {options.map((option) => {
          const _isSelected = isSelected(option);

          return (
            <Box
              key={option.value}
              {...itemA11yProps(_isSelected)}
              className={cx(
                styles.jobTypeFieldOption,
                `button-variant--${optionsVariants[option.value]}`,
                {
                  selected: _isSelected,
                },
              )}
              component={ButtonCore}
              onClick={() => {
                handleChange(option);
              }}
            >
              <Typography variant="label" size="large" component="span">
                {option.label}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </>
  );
};
