import { useCallback, useEffect, useRef, useState } from "react";
import type { WrappedFieldProps } from "redux-form";

import type { OptionWithPrimitiveValue } from "@js/types/common";

type CustomRateButtonProps = {
  input: WrappedFieldProps["input"];
  meta: WrappedFieldProps["meta"];
  onClick: () => void;
  isSelected: boolean;
  options: OptionWithPrimitiveValue[];
  setActiveIndex: (arg: number) => void;
};

export const useCustomRateButton = ({
  input,
  meta,
  isSelected,
  options,
  onClick,
  setActiveIndex,
}: CustomRateButtonProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const [amountValue, setAmountValue] = useState<string | undefined>(undefined);
  const [focus, setFocus] = useState<boolean>(false);

  const inputWidth =
    !!amountValue && String(amountValue).length
      ? (String(amountValue).length + 1) * 8
      : 10;

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    const shouldUnselect = !amountValue;
    if (shouldUnselect) {
      setActiveIndex(-1);
    }

    setFocus(false);
  };

  const handleButtonOnClick = () => {
    onClick();
    if (inputRef.current) inputRef.current.focus();
  };

  const handleValueChange = (value?: string) => {
    setAmountValue(value ?? undefined);
    input.onChange(value ?? undefined);
  };

  const setInitialValue = useCallback(() => {
    if (!meta.initial || meta.dirty) return;

    const isStandardValueInitial = options.some((option) => {
      return Number(option.value) === Number(meta.initial);
    });

    if (isStandardValueInitial) return;

    setAmountValue(meta.initial);
    onClick();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.initial]);

  useEffect(() => {
    if (isSelected) {
      input.onChange(amountValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  useEffect(() => {
    setInitialValue();
  }, [setInitialValue]);

  return {
    handleButtonOnClick,
    onFocus,
    onBlur,
    handleValueChange,
    inputWidth,
    inputRef,
    focus,
    amountValue,
  };
};
