import { useCallback, useState } from "react";

import { globalSearchClickAndTyped } from "@js/apps/universal-search/actions";
import { useAppDispatch } from "@js/hooks";

export const useGlobalSearchEventTracking = () => {
  const [globalSearchReported, setGlobalSearchReported] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const resetGlobalSearchReported = useCallback(() => {
    setGlobalSearchReported(false);
  }, []);

  const trackGlobalSearchEvent = useCallback(() => {
    if (!globalSearchReported) {
      setGlobalSearchReported(true);
      dispatch(globalSearchClickAndTyped());
    }
  }, [dispatch, setGlobalSearchReported, globalSearchReported]);

  return {
    trackGlobalSearchEvent,
    resetGlobalSearchReported,
  };
};
