import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { useUser } from "@js/apps/common/hooks";
import { useGetSpaceMembersQuery } from "@js/apps/spaces/api";

type UseIsSpaceAdminProps = {
  spaceId?: number;
};

export const useIsSpaceAdmin = ({ spaceId }: UseIsSpaceAdminProps) => {
  const user = useUser();

  const { currentData: membersData } = useGetSpaceMembersQuery(
    typeof spaceId !== "undefined" && !isNaN(spaceId)
      ? { id: spaceId }
      : skipToken,
  );

  const isSpaceAdmin = useMemo(() => {
    return !!membersData?.results?.find((member) => member.user.id === user?.id)
      ?.is_admin;
  }, [user, membersData]);

  return {
    isSpaceAdmin,
  };
};
