import { createAction } from "@reduxjs/toolkit";

import type { UniversalTabValue } from "@js/apps/universal-search/constants";
import { Events } from "@js/services/analytics";
import type { Freelancer } from "@js/types/freelancer";
import type { IPost } from "@js/types/give-and-get-help";
import type { Job } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

type SearchQueryId = number;
type DefaultPopover = "jobs" | "career_help" | "talent";
type JobId = Job["id"];
type PostId = IPost["id"];
type TalentId = Freelancer["id"];
type ResultPosition = number;

type SearchPopoverTabClickedPayload = {
  search_query_id?: SearchQueryId;
  default_popover: DefaultPopover;
};

type JobResultItemClickedPayload = {
  job_id: JobId;
  search_query_id?: SearchQueryId;
  result_position: ResultPosition;
};

type PostResultItemClickedPayload = {
  post_id: PostId;
  search_query_id?: SearchQueryId;
  result_position: ResultPosition;
};

type TalentResultListItemClickedPayload = {
  talent_id: TalentId;
  search_query_id?: SearchQueryId;
  result_position: ResultPosition;
};

type JobViewAllPopoverClickedPayload = {
  search_query_id?: SearchQueryId;
};

type PostViewAllPopoverClickedPayload = {
  search_query_id?: SearchQueryId;
};

type TalentViewAllPopoverClickedPayload = {
  search_query_id?: SearchQueryId;
};

export const globalSearchClickAndTyped = createAction(
  Events.CLICK_GLOBAL_SEARCH,
);
const jobTabClicked = createAction<SearchPopoverTabClickedPayload>(
  Events.CLICK_JOB_RESULTS_POPOVER_SEARCH,
);
export const jobResultListItemClicked =
  createAction<JobResultItemClickedPayload>(
    Events.CLICK_VIEW_JOB_POPOVER_SEARCH,
  );
export const postResultListItemClicked =
  createAction<PostResultItemClickedPayload>(
    Events.CLICK_VIEW_POST_POPOVER_SEARCH,
  );
export const talentResultListItemClicked =
  createAction<TalentResultListItemClickedPayload>(
    Events.CLICK_TALENT_POPOVER_SEARCH,
  );

const postTabClicked = createAction<SearchPopoverTabClickedPayload>(
  Events.CLICK_POST_RESULTS_POPOVER_SEARCH,
);
const peopleTabClicked = createAction<SearchPopoverTabClickedPayload>(
  Events.CLICK_PEOPLE_RESULTS_POPOVER_SEARCH,
);
export const jobViewAllPopoverClicked =
  createAction<JobViewAllPopoverClickedPayload>(
    Events.CLICK_JOB_VIEW_ALL_POPOVER_SEARCH,
  );
export const postViewAllPopoverClicked =
  createAction<PostViewAllPopoverClickedPayload>(
    Events.CLICK_POST_VIEW_ALL_POPOVER_SEARCH,
  );
export const talentViewAllPopoverClicked =
  createAction<TalentViewAllPopoverClickedPayload>(
    Events.CLICK_TALENT_VIEW_ALL_POPOVER_SEARCH,
  );

export const logTabClicked = (
  clickedTab: UniversalTabValue,
  currentTab: UniversalTabValue,
  searchEventId?: number,
) => {
  const defaultTab: Record<UniversalTabValue, DefaultPopover> = {
    JOBS: "jobs",
    CAREER_HELP: "career_help",
    PEOPLE: "talent",
  };
  const payload = {
    default_popover: defaultTab[currentTab],
    search_query_id: searchEventId,
  };

  switch (clickedTab) {
    case "JOBS":
      return jobTabClicked(payload);
    case "PEOPLE":
      return peopleTabClicked(payload);
    case "CAREER_HELP":
      return postTabClicked(payload);
    default:
      assertUnreachable(clickedTab);
  }
};
