import { useABExperiment } from "@js/apps/experiments";

export const useStripeFeatureFlag = () => {
  const { isParticipating, experiment } = useABExperiment(
    "Dual Payment Purchase",
  );

  const isStripeEnabled =
    experiment?.variant ===
    SETTINGS.EXPERIMENT_ENUMS[
      SETTINGS.EXPERIMENT_ENUMS.Experiments.DualPaymentPurchase
    ]?.DualPaymentPurchaseEnabled;

  return {
    showStripe: isParticipating && isStripeEnabled,
  };
};
