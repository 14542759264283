import { useCallback, useRef } from "react";

import { useAppDispatch } from "@js/hooks";
import type { EmployerBid } from "@js/types/jobs";

import {
  deselectBid,
  selectBid,
} from "../../components/bulk-select-actions/bid-bulk-selection-slice";
import { useSelectedBidsIds } from "../use-selected-bids-ids";

type BidId = EmployerBid["id"];

export const useSelectBid = () => {
  const dispatch = useAppDispatch();
  const { selectedBidsIds } = useSelectedBidsIds();

  const selectedBidsIdsRef = useRef<BidId[]>(selectedBidsIds);
  selectedBidsIdsRef.current = selectedBidsIds;

  const isSelected = useCallback(
    (id: EmployerBid["id"]) => {
      return selectedBidsIdsRef.current.some((selectedId) => selectedId === id);
    },
    [selectedBidsIdsRef],
  );

  const deselectJobBid = useCallback(
    (id: BidId) => dispatch(deselectBid(id)),
    [dispatch],
  );

  const selectJobBid = useCallback(
    (id: BidId) => dispatch(selectBid(id)),
    [dispatch],
  );

  const toggleSelect = useCallback(
    (id: EmployerBid["id"]) => {
      if (isSelected(id)) {
        return deselectJobBid(id);
      }

      return selectJobBid(id);
    },
    [deselectJobBid, selectJobBid, isSelected],
  );

  return { toggleSelect, deselectJobBid, selectJobBid, isSelected };
};
