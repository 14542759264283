import { useMemo } from "react";

import type { JobFilters } from "@js/apps/common/components/filters";
import {
  FiltersBottomActionsContainer,
  FiltersBottomContainer,
  FiltersCarousel,
  FiltersCarouselContainer,
  FiltersClearAllButton,
  FiltersSortButton,
  ResultsCount,
} from "@js/apps/common/components/filters/components";
import { SEARCH_FILTER_DATA_TYPES } from "@js/apps/common/components/filters/constants";
import {
  RoleFilter,
  SkillsFilter,
} from "@js/apps/common/components/filters/fields";
import { WorkingTimezoneJobsFilter } from "@js/apps/common/components/filters/fields/working-timezone-jobs-filter";
import { SaveJobsFilters } from "@js/apps/common/components/save-filters/save-jobs-filters";
import { useAccountType } from "@js/apps/common/hooks";
import { useGetActiveFilterName } from "@js/apps/jobs/apps/listing/hooks/active-filter-name";
import {
  getDefaultJobsSortOrdering,
  getFiltersStorageKey,
} from "@js/apps/jobs/apps/listing/utils";
import { JOB_LOCATION } from "@js/apps/jobs/context/job-location-context";
import { useResetUniversalSearchPhrase } from "@js/apps/universal-search/universal-search-slice";
import { useAppDispatch, useNavigate } from "@js/hooks";
import { LocalStorage } from "@js/services";
import { isNotNullable } from "@js/utils";

import { savedFilterLoaded } from "../../actions";
import {
  CommitmentFilter,
  JobTypeFilter,
  LocationFilter,
  RateFilter,
} from "../../fields";

type SearchJobsListFiltersProps = {
  isAnyFilterApplied: boolean;
  count: number;
  filters: Partial<JobFilters>;
};

const location = JOB_LOCATION.job_search;

export const SearchJobsListFilters = ({
  isAnyFilterApplied,
  count,
  filters,
}: SearchJobsListFiltersProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isEmployer } = useAccountType();
  const { savedFilterName } = useGetActiveFilterName(location);
  const clearUniversalSearch = useResetUniversalSearchPhrase();

  const items = useMemo(
    () => getSearchJobsFilterItems({ isEmployer }),
    [isEmployer],
  );

  const clearFilters = () => {
    const storageKey = getFiltersStorageKey(location);
    LocalStorage.removeItem(storageKey);

    clearUniversalSearch();
    dispatch(
      savedFilterLoaded({
        location,
        name: null,
      }),
    );
    navigate(window.location.pathname);
  };

  return (
    <>
      <FiltersCarouselContainer>
        <FiltersCarousel items={items} />
      </FiltersCarouselContainer>
      <FiltersBottomContainer>
        <FiltersBottomActionsContainer>
          <ResultsCount
            dataType={SEARCH_FILTER_DATA_TYPES.jobs}
            count={count}
          />
          {isAnyFilterApplied && (
            <>
              <SaveJobsFilters
                filters={filters}
                location={location}
                savedFilterName={savedFilterName}
              />
              <FiltersClearAllButton onClick={clearFilters} />
            </>
          )}
        </FiltersBottomActionsContainer>
        <FiltersSortButton
          location={location}
          defaultValue={getDefaultJobsSortOrdering(filters)}
        />
      </FiltersBottomContainer>
    </>
  );
};

const getSearchJobsFilterItems = ({ isEmployer }: { isEmployer: boolean }) => {
  return [
    <RoleFilter key="role-filter" />,
    <SkillsFilter key="skills-filter" />,
    <RateFilter key="rate-filter" />,
    <LocationFilter key="location-filter" />,
    !isEmployer ? (
      <WorkingTimezoneJobsFilter key="working-timezone-jobs-filter" />
    ) : null,
    <CommitmentFilter key="commitment-filter" />,
    <JobTypeFilter key="job-type-filter" />,
  ].filter(isNotNullable);
};
