import { ModalInstance } from "@js/components/modal";

import { BoostModalContent } from "./boost-modal-content";

type OpenBoostModalProps = {
  boostCredit: number;
  bidId: number;
  onClose?: () => void;
  onCompleteBoost?: () => void;
};

export const openBoostModal = ({
  boostCredit,
  bidId,
  onClose,
  onCompleteBoost,
}: OpenBoostModalProps) =>
  ModalInstance.open({
    padding: false,
    closeButtonProps: {
      style: {
        backgroundColor: "rgba(var(--black-rgb), 0.04)",
      },
    },
    children: (
      <BoostModalContent
        boostCredit={boostCredit}
        bidId={bidId}
        onClose={onClose}
        onCompleteBoost={onCompleteBoost}
      />
    ),
  });
