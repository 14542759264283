import { useEffect, useMemo } from "react";
import { Navigate } from "react-router-dom";

import { Box, Loader } from "@hexocean/braintrust-ui-components";
import { TOGGLE_HIDE_REQUEST_LOADER } from "@js/apps/common/action-types";
import { useUser } from "@js/apps/common/hooks";
import { EditPostModalInstance } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/modal-instances";
import { CreatePostSection } from "@js/apps/give-and-get-help/components/create-post";
import { PostItem } from "@js/apps/give-and-get-help/components/post";
import { PostMeta } from "@js/apps/give-and-get-help/components/post-meta";
import { useMarkCareerHelpVisited } from "@js/apps/give-and-get-help/hooks";
import { useRedirectFromFeedPostToSpacesPost } from "@js/apps/give-and-get-help/hooks/use-redirect-from-feed-post-to-spaes-post";
import { UniversalSearchContextProvider } from "@js/apps/universal-search/context/universal-search-context";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { useIdParam } from "@js/hooks/use-id-param";
import { AppLayout } from "@js/layouts/app";
import type { IPost } from "@js/types/give-and-get-help";

import { useGetPostQuery } from "../../api";
import { FeedHeader } from "../../components";
import { PostsListEmptyState } from "../../components/posts-list-empty-state";
import { PostLocation } from "../../context/post-location";
import { PostViewedContextProvider } from "../../context/post-viewed";
import { PostsContextProvider } from "../../context/posts";
import { useSubscribePostOrComment } from "../../hooks";
import { useRedirectToSlugBasedPost } from "../../hooks/use-redirect-to-slug-based-post";
import { useShowSnackbarOnCelebratoryPostCreation } from "../../hooks/use-show-snackbar-on-celebratory-post-creation";
import { useHandleDeleteSinglePost } from "../../hooks/websocket-subscribe/handle-delete-single-post";
import { PublicPostPage } from "../public-single-post/index";

export const SinglePostPageWrapper = () => {
  const id = useIdParam();
  const user = useUser();

  if (!id) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  if (!user) {
    return <PublicPostPage id={id} />;
  }

  return <SinglePostPage id={id} />;
};

export const SinglePostPage = ({ id }: { id: number }) => {
  const { currentData: post, isFetching: loading } = useGetPostQuery({ id });
  useRedirectToSlugBasedPost(post);

  useHandleDeleteSinglePost(post);
  useShowSnackbarOnCelebratoryPostCreation(post);

  const postToSubscribe = useMemo(() => (post ? [post.id] : []), [post]);
  useSubscribePostOrComment(postToSubscribe);

  const dispatch = useAppDispatch();
  const hideRequestLoader = useAppSelector(
    (state) => state.common.hideRequestLoader,
  );

  useMarkCareerHelpVisited();

  useRedirectFromFeedPostToSpacesPost(post);

  useEffect(() => {
    if (!hideRequestLoader) {
      dispatch({
        type: TOGGLE_HIDE_REQUEST_LOADER,
      });
    }

    return () => {
      dispatch({
        type: TOGGLE_HIDE_REQUEST_LOADER,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <UniversalSearchContextProvider initialTab="CAREER_HELP">
        <PostLocation.Provider value={PostLocation.Values.single_post_view}>
          <AppLayout bgcolor="var(--soft-green)" hideMetaTags>
            <Box
              display="flex"
              flexDirection="column"
              justifyItems="center"
              mx="auto"
              width="100%"
              maxWidth="944px"
            >
              <FeedHeader showBackButton isSinglePostView />

              <CreatePostSection />

              <Box mt={4}>
                <PostViewedContextProvider>
                  <Post post={post} loading={loading} />
                </PostViewedContextProvider>
              </Box>
            </Box>
            <EditPostModalInstance />
          </AppLayout>
        </PostLocation.Provider>
      </UniversalSearchContextProvider>
      <PostMeta post={post} />
    </>
  );
};

type PostProps = {
  post?: IPost | null;
  loading: boolean;
};

export const Post = ({ post, loading }: PostProps) => {
  const postId = post?.id;
  const initialExpandedIds = useMemo(() => {
    if (typeof postId === "undefined") {
      return [];
    }

    return [postId];
  }, [postId]);

  if (loading) {
    return <PostLoader />;
  }

  if (!post) {
    return <PostsListEmptyState>Post not found</PostsListEmptyState>;
  }

  return (
    <PostsContextProvider initialExpandedIds={initialExpandedIds}>
      <PostItem postData={post} isSinglePostView />
    </PostsContextProvider>
  );
};

const PostLoader = () => {
  return (
    <Box sx={{ position: "relative", minHeight: "8rem" }}>
      <Loader centered />
    </Box>
  );
};
