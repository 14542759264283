import { useState } from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useGetFreelancerJobOffersQuery } from "@js/apps/freelancer/api";
import { useAcceptJobOfferMutation } from "@js/apps/jobs/apps/offers/api";
import { Snackbar } from "@js/components/snackbar";
import { useNavigate } from "@js/hooks";

export const SignUpBYOTAcceptOffer = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [acceptOffer] = useAcceptJobOfferMutation();
  const { data } = useGetFreelancerJobOffersQuery({
    section: ENUMS.TalentOffersSectionFilter.CURRENT,
    order_by: "id",
  });

  const newestOffer = data ? data[data.length - 1] : null;

  const onAcceptOfferClick = async () => {
    if (!newestOffer) {
      return;
    }

    try {
      setIsSubmitting(true);
      await acceptOffer({
        id: newestOffer?.id,
        checksum: newestOffer?.checksum,
        address: null,
      }).unwrap();
      navigate("/sign_up_byo_talent/congrats");
    } catch (error: any) {
      Snackbar.error(error.data._error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" maxWidth={500} gap={2} p={2}>
      <Typography component="p" variant="title">
        Step 2 - Accept offer
      </Typography>
      <Button
        variant="primary"
        onClick={onAcceptOfferClick}
        disabled={isSubmitting}
      >
        Accept offer
      </Button>
      <div style={{ fontSize: 24 }}>offer json below:</div>
      <div>{JSON.stringify(newestOffer)}</div>
    </Box>
  );
};
