import { Badge } from "@hexocean/braintrust-ui-components";
import { VerifiedIcon } from "@hexocean/braintrust-ui-components/Icons";

type Props = {
  children: React.ReactNode;
  isApproved: boolean;
};

export const AvatarWithApprovedBadge = ({ isApproved, children }: Props) => {
  const badgeContent = isApproved ? (
    <VerifiedIcon style={{ width: 44, height: 44 }} />
  ) : (
    0
  );

  return (
    <Badge badgeContent={badgeContent} overlap="circular">
      {children}
    </Badge>
  );
};
