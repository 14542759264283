import React from "react";

import { Box, Stack, Typography } from "@hexocean/braintrust-ui-components";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";

type JobTokenRewardProps = {
  tokenReward: string;
  m?: number;
};

export const JobTokenReward = ({
  tokenReward,
  ...props
}: JobTokenRewardProps) => {
  return (
    <Box {...props}>
      <Typography component="div" variant="paragraph" size="large">
        <Stack direction="row" mt="4px">
          <BraintrustIcon
            titleAccess="BTRST reward"
            style={{
              marginRight: 9,
              width: 22,
              height: 22,
            }}
          />
          <Box
            sx={{
              borderBottom: "1px dashed black",
              lineHeight: 1.25,
            }}
          >
            +{tokenReward}
          </Box>
        </Stack>
      </Typography>
    </Box>
  );
};
