import { Button } from "@hexocean/braintrust-ui-components";
import { DownloadArrowIcon } from "@hexocean/braintrust-ui-components/Icons";
import type {
  GetEmployerReferralsParams,
  GetTalentReferralsParams,
  UserReferralType,
} from "@js/apps/dashboard/types";
import { useAppDispatch } from "@js/hooks";
import { handleDownloadFile } from "@js/utils/download";

import { clickReferralsCSV } from "../../actions";

type DownloadReferralsCSVBtnProps = {
  endpoint: string;
  filters: GetTalentReferralsParams | GetEmployerReferralsParams;
  type: UserReferralType;
};

export const DownloadReferralsCSVBtn = ({
  endpoint,
  filters,
  type,
}: DownloadReferralsCSVBtnProps) => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(clickReferralsCSV(type));
    handleDownloadFile({
      endpoint,
      params: { ...filters, format: "csv" },
      blank: true,
    });
  };

  return (
    <Button
      size="small"
      variant="secondary-thin"
      onClick={onClick}
      sx={{ py: "7px !important" }}
    >
      <DownloadArrowIcon
        style={{ width: "20px", height: "20px", marginRight: "8px" }}
      />
      Download .csv
    </Button>
  );
};
