import { useParams } from "react-router-dom";

import {
  useGetSpaceDetailsQuery,
  useGetSpaceMembersQuery,
} from "@js/apps/spaces/api";
import { useRules } from "@js/apps/spaces/components/rules/hooks";
import { useIsSpaceAdmin } from "@js/apps/spaces/hooks/use-is-space-admin";
import { useIsSpaceMember } from "@js/apps/spaces/hooks/use-is-space-member";
import { useSpaceNotifications } from "@js/apps/spaces/hooks/use-space-notifications";

export const useAboutSpace = () => {
  const { spaceId } = useParams();
  const { rules, shouldRenderEditRulesButton } = useRules({
    spaceId: Number(spaceId),
  });
  const { data: spaceData } = useGetSpaceDetailsQuery({
    id: Number(spaceId),
  });

  const { data: membersData } = useGetSpaceMembersQuery({
    id: Number(spaceId),
  });

  const { isSpaceAdmin } = useIsSpaceAdmin({
    spaceId: Number(spaceId),
  });

  const { isSpaceMember } = useIsSpaceMember({ spaceId: Number(spaceId) });
  const { areNotificationsTurnOn } = useSpaceNotifications();

  return {
    spaceData,
    membersData,
    rules,
    shouldRenderEditRulesButton,
    isAdmin: isSpaceAdmin,
    isMember: isSpaceMember,
    areNotificationsTurnOn,
  };
};
