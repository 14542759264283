import { useCallback } from "react";

export const useScrollToReviewSection = () => {
  const scrollToReviewSection = useCallback(() => {
    const reviewSection = document.getElementById("review-section");

    if (reviewSection) {
      reviewSection.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return scrollToReviewSection;
};
