import { useMemo } from "react";

import { TAB_TO_RESULTS_MAP } from "../components/universal-search-empty-state/helpers";
import { EMPTY_STATE_TABS } from "../components/universal-search-empty-state/helpers";

import type { UseUniversalSearchEmptyDataArg } from "./use-universal-search-empty-data";
import { useUniversalSearchEmptyData } from "./use-universal-search-empty-data";

export type UseUniversalSearchEmptyState = UseUniversalSearchEmptyDataArg;

export const useUniversalSearchEmptyState = ({
  currentTab,
  isFetching,
  canAccessGiveAndGetHelp,
}: UseUniversalSearchEmptyState) => {
  const emptyStateSettings = EMPTY_STATE_TABS[currentTab];
  const { data, isLoading } = useUniversalSearchEmptyData({
    currentTab,
    isFetching,
    canAccessGiveAndGetHelp,
  });

  const tabsWithData = useMemo(() => {
    return emptyStateSettings.tabs.filter((tab) => {
      const resultKey = TAB_TO_RESULTS_MAP[tab.value];
      return data[resultKey]?.results?.length;
    });
  }, [emptyStateSettings, data]);

  return {
    currentData: data,
    tabsWithData,
    emptyStateSettings,
    isLoading,
  };
};
