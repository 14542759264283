import React from "react";

import type { TypographyColors } from "@hexocean/braintrust-ui-components";
import {
  Box,
  type BoxProps,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";

export type StatusBoxProps = BoxProps & {
  color: string;
  children: React.ReactNode;
  longTile?: boolean;
};

export const JobStatusBox = ({
  color,
  children,
  longTile,
  ...props
}: StatusBoxProps) => {
  return (
    <RoundedBox
      height="100%"
      width="100%"
      gap={1}
      flexDirection={longTile ? "row" : "column"}
      alignContent="flex-start"
      justifyContent="space-between"
      alignItems={longTile ? "center" : "flex-start"}
      bgcolor={color}
      flexWrap="wrap"
      p="32px"
      display="flex"
      {...props}
    >
      {children}
    </RoundedBox>
  );
};

type SubComponentProps = {
  children: React.ReactNode;
  color?: TypographyColors;
};

const Header = ({ children, color = "white" }: SubComponentProps) => {
  return (
    <div>
      <Typography
        component="span"
        variant="label"
        color={color}
        size="medium"
        fontWeight={500}
      >
        Status
      </Typography>
      <Typography
        component="h2"
        variant="title"
        color={color}
        size="small"
        fontWeight={400}
      >
        {children}
      </Typography>
    </div>
  );
};

const Subheader = ({ children, color = "white" }: SubComponentProps) => {
  return (
    <Typography
      component="p"
      variant="label"
      size="small"
      fontWeight={500}
      color={color}
    >
      {children}
    </Typography>
  );
};

type ContentProps = BoxProps & {
  children: React.ReactNode;
  longTile?: boolean;
};

const Content = ({ children, longTile, ...boxProps }: ContentProps) => {
  return (
    <Box
      width={longTile ? "unset" : "100%"}
      mt={2}
      display="flex"
      gap={1}
      flexDirection="column"
      {...boxProps}
    >
      {children}
    </Box>
  );
};

JobStatusBox.Header = Header;
JobStatusBox.Subheader = Subheader;
JobStatusBox.Content = Content;
