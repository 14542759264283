import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { useAppSelector } from "@js/hooks/redux";
import type { RootState } from "@js/store";
import type {
  IPost,
  PostComment,
  StickerValue,
} from "@js/types/give-and-get-help";

type EntityWithReactions = IPost | PostComment;

type EntityId = EntityWithReactions["id"];

type ReactionsDrawerStateData = {
  entityId: EntityId;
  entityReactions: EntityWithReactions["reactions"];
  selectedReactionValue: StickerValue;
};

type ReactionsDrawerState =
  | {
      isOpen: true;
      data: ReactionsDrawerStateData;
    }
  | {
      isOpen: false;
      data?: ReactionsDrawerStateData;
    };

type StickersModuleState = {
  reactionsDrawer: ReactionsDrawerState;
};

const initialState: StickersModuleState = {
  reactionsDrawer: {
    isOpen: false,
  },
};

export const stickersModuleSlice = createSlice({
  name: "stickers-module",
  initialState,
  reducers: {
    showReactionsDrawer(
      state,
      action: PayloadAction<ReactionsDrawerStateData>,
    ) {
      state.reactionsDrawer = {
        isOpen: true,
        data: action.payload,
      };
    },
    selectReaction(state, action: PayloadAction<StickerValue>) {
      if (!state.reactionsDrawer.isOpen) {
        return;
      }
      state.reactionsDrawer.data.selectedReactionValue = action.payload;
    },
    updateReactions(
      state,
      action: PayloadAction<EntityWithReactions["reactions"]>,
    ) {
      if (!state.reactionsDrawer.isOpen) {
        return;
      }
      state.reactionsDrawer.data.entityReactions = action.payload;
    },
    hideReactionsDrawer(state) {
      state.reactionsDrawer.isOpen = false;
    },
  },
});

export const {
  showReactionsDrawer,
  hideReactionsDrawer,
  selectReaction,
  updateReactions,
} = stickersModuleSlice.actions;

const getReactionsDrawerState = (state: RootState) =>
  state.stickersModule.reactionsDrawer;

const getReactionsDrawerData = (state: RootState) =>
  state.stickersModule.reactionsDrawer.data;

const getReactionsDrawerOpen = (state: RootState) =>
  state.stickersModule.reactionsDrawer.isOpen;

export const useReactionsDrawerData = () => {
  return useAppSelector(getReactionsDrawerData);
};

export const useReactionsDrawerState = () => {
  return useAppSelector(getReactionsDrawerState);
};

export const useReactionsDrawerOpen = () => {
  return useAppSelector(getReactionsDrawerOpen);
};
