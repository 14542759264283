import { useMemo } from "react";
import type {
  BaseQueryFn,
  TypedUseMutation,
} from "@reduxjs/toolkit/query/react";
import _ from "underscore";

type BaseUseMutation<
  ResultType,
  QueryArg,
  BaseQuery extends BaseQueryFn,
> = TypedUseMutation<ResultType, QueryArg, BaseQuery>;

export const useDebouncedMutation = <
  ResultType = any,
  QueryArg = any,
  BaseQuery extends BaseQueryFn = BaseQueryFn,
  UseMutation extends BaseUseMutation<
    ResultType,
    QueryArg,
    BaseQuery
  > = BaseUseMutation<ResultType, QueryArg, BaseQuery>,
>(
  useMutation: UseMutation,
  wait = 1000,
): ReturnType<UseMutation> => {
  const [mutation, results] = useMutation();
  const debouncedMutation = useMemo(
    () => _.debounce(mutation, wait),
    [mutation, wait],
  );

  return [debouncedMutation, results] as ReturnType<UseMutation>;
};
