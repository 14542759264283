import { Loader } from "@hexocean/braintrust-ui-components";
import { Modal } from "@js/components/modal";

import { LocationMismatchModalComponent } from "./component";
import { useLocationMismatchModal } from "./hook";

import styles from "./styles.module.scss";

type LocationMismatchModalProps = {
  userId: number;
  onClose: () => void;
};

const LocationMismatchModal = ({
  userId,
  onClose,
}: LocationMismatchModalProps) => {
  const { data, isLoading, handleMessageUserClick } =
    useLocationMismatchModal(userId);

  if (isLoading || !data) {
    return <Loader centered />;
  }

  return (
    <LocationMismatchModalComponent
      user={data.user}
      profileLocation={data.profile_location}
      billingLocation={data.billing_location}
      lastActiveSession={data.last_active_session}
      lastActiveSessionMismatch={!!data.last_active_session_mismatch}
      billingLocationMismatch={!!data.billing_location_mismatch}
      onMessageUserClick={handleMessageUserClick}
      onClose={onClose}
    />
  );
};

export const LocationMismatchModalInstance = Modal("location-mismatch-modal", {
  closeButton: false,
  className: styles.modalWrapper,
});

export const openLocationMismatchModal = (
  props: Omit<LocationMismatchModalProps, "onClose">,
) => {
  LocationMismatchModalInstance.open({
    children: (
      <LocationMismatchModal
        onClose={LocationMismatchModalInstance.close}
        {...props}
      />
    ),
  });
};
