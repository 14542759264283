import { Paper, Typography } from "@hexocean/braintrust-ui-components";
import type { Space } from "@js/types/spaces";

import { SpaceActivities } from "../space-activity/space-activities";

type AboutThisSpacePublicProps = {
  space: Space;
};

export const AboutThisSpacePublic = ({ space }: AboutThisSpacePublicProps) => {
  return (
    <Paper variant="outlined" sx={{ p: { xs: 3, sm: 4 }, borderRadius: 4 }}>
      <Typography
        component="h3"
        variant="paragraph"
        size="large"
        sx={{ mb: 1 }}
      >
        About {space.name}
      </Typography>
      <Typography
        component="p"
        variant="paragraph"
        size="medium"
        sx={{ mb: 3, overflowWrap: "anywhere" }}
      >
        {space.description}
      </Typography>
      <SpaceActivities
        spaceType={space.visibility}
        created={space.created}
        lastWeekPosts={space.number_of_posts_for_last_7_days}
        lastMonthPosts={space.number_of_posts_for_last_30_days}
      />
    </Paper>
  );
};
