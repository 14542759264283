import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import type {
  ButtonSelectProps,
  ButtonTileSelectProps,
  OptionBase,
  PrimitiveValue,
} from "@hexocean/braintrust-ui-components";
import {
  ButtonSelect,
  ButtonTileSelect,
} from "@hexocean/braintrust-ui-components";

export const ReduxFormButtonSelectFactory = <
  C extends React.ComponentType<
    React.ComponentProps<C> & TypedWrappedFieldProps
  >,
  T,
>(
  component: C,
): (({
  input,
  meta,
  ...props
}: Omit<React.ComponentProps<C>, "value" | "onChange"> &
  TypedWrappedFieldProps<T>) => JSX.Element) => {
  return ({ input, meta, ...props }) => {
    const Component = component as React.ComponentType<React.ComponentProps<C>>;

    return (
      <Component
        {...(props as React.ComponentProps<C>)}
        {...input}
        error={meta.touched && meta.error}
      />
    );
  };
};

type ButtonSelectFieldProps<SelectOption extends OptionBase> = Omit<
  ButtonSelectProps<SelectOption>,
  "value" | "onChange" | "mapObjectValueToKey"
> & {
  mapValueToFormValue?: (value: Record<string, unknown>) => unknown;
  mapObjectValueToKey?: (
    value: Exclude<SelectOption["value"], PrimitiveValue>,
  ) => string;
} & TypedWrappedFieldProps<any>;

export const ButtonSelectField: <T extends OptionBase>(
  props: ButtonSelectFieldProps<T>,
) => JSX.Element = ReduxFormButtonSelectFactory(
  ButtonSelect<{ label: React.ReactNode; value: any }>,
);

type ButtonTileSelectFieldProps<SelectOption extends OptionBase> = Omit<
  ButtonTileSelectProps<SelectOption>,
  "value" | "onChange"
> &
  TypedWrappedFieldProps<any>;

export const ButtonTileSelectField: <T extends OptionBase>(
  props: ButtonTileSelectFieldProps<T>,
) => JSX.Element = ReduxFormButtonSelectFactory(
  ButtonTileSelect<{ label: React.ReactNode; value: any }>,
);
