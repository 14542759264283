import type { FreelancerBidParams } from "@js/types/jobs";

export const checkForMissingResume = (
  fields: Pick<FreelancerBidParams, "resume">,
  isResumeRequired: boolean,
): boolean => {
  if (!isResumeRequired) {
    return false;
  }

  if (
    isResumeRequired &&
    fields["resume"] !== null &&
    fields["resume"] !== undefined
  ) {
    return false;
  }

  return true;
};

export const checkForMissingRequirementAnswers = (
  fields: Pick<
    FreelancerBidParams,
    "timezone_requirement_met" | "location_requirement_met"
  >,
) => {
  return (
    ("timezone_requirement_met" in fields &&
      fields["timezone_requirement_met"] === null) ||
    ("location_requirement_met" in fields &&
      fields["location_requirement_met"] === null)
  );
};
