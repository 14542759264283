import { useEffect, useMemo, useRef, useState } from "react";

import type { GetRoomListReturnType } from "@js/types/messenger";

type UseSortedRoomsArg = {
  activeRoom?: number;
  roomListResponse: GetRoomListReturnType | undefined;
};

export const useSortedRooms = ({
  activeRoom,
  roomListResponse,
}: UseSortedRoomsArg) => {
  const [initialActiveRoom] = useState(activeRoom);
  const [placeInitialActiveRoomOnTop, setPlaceInitialActiveRoomOnTop] =
    useState(false);
  const initialActiveRoomCheckedRef = useRef(false);

  useEffect(() => {
    if (
      !roomListResponse ||
      !initialActiveRoom ||
      initialActiveRoomCheckedRef.current
    ) {
      return;
    }

    initialActiveRoomCheckedRef.current = true;

    const isActiveRoomInTheList = roomListResponse.results.some(
      (room) => room.id === initialActiveRoom,
    );

    setPlaceInitialActiveRoomOnTop(!isActiveRoomInTheList);
  }, [roomListResponse, initialActiveRoom]);

  useEffect(() => {
    if (initialActiveRoom === activeRoom) {
      return;
    }

    setPlaceInitialActiveRoomOnTop(false);
  }, [activeRoom, initialActiveRoom]);

  const roomList = useMemo(() => {
    const resultsCopy = roomListResponse?.results
      ? [...roomListResponse.results]
      : [];

    return resultsCopy.sort((a, b) => {
      // we want to put the initial active room on top in case it wasn't returned in the first page of rooms ...
      // ... so we don't immediately trigger fetching of the 2nd page by scrolling all the way down to that ...
      // ... active room
      if (
        placeInitialActiveRoomOnTop &&
        initialActiveRoom &&
        a.id === initialActiveRoom
      ) {
        return -1;
      }

      return (
        new Date(b.last_message_timestamp ?? b.created).getTime() -
        new Date(a.last_message_timestamp ?? a.created).getTime()
      );
    });
  }, [roomListResponse, initialActiveRoom, placeInitialActiveRoomOnTop]);

  return { roomList };
};
