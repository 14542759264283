import { Box, Typography } from "@hexocean/braintrust-ui-components";
import type { BudgetFieldProps } from "@js/apps/give-and-get-help/forms/fields/budget-field";
import { BudgetField } from "@js/apps/give-and-get-help/forms/fields/budget-field";

type MessageBudgetFieldProps = Optional<BudgetFieldProps, "options"> & {
  label?: string;
};

export const MessageOfferBudget = ({
  options,
  label,
  ...rest
}: MessageBudgetFieldProps) => {
  if (!options) return null;
  return (
    <Box mt={3}>
      {label && (
        <Typography component="p" variant="paragraph" size="large" mb={1}>
          {label}
        </Typography>
      )}
      <BudgetField options={options} {...rest} />
    </Box>
  );
};
