import {
  CANCEL_OFFER,
  MADE_OFFER,
} from "@js/apps/jobs/apps/offers/action-types";
import type { EmployerOffer } from "@js/types/jobs";

// event tracking
export const offerMade = (payload: EmployerOffer["id"]) => {
  return {
    type: MADE_OFFER,
    payload,
  };
};

export const offerCancel = (payload: EmployerOffer["id"]) => {
  return {
    type: CANCEL_OFFER,
    payload,
  };
};
