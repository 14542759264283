import { EmployerDashboardWelcomeLazy } from "../../lazy";
import { EmployerDashboardLayout } from "../layout";

import style from "./style.module.scss";

export const EmployerDashboardWelcomePage = () => {
  return (
    <EmployerDashboardLayout
      contentClassName={style.employerDashboardContainer}
      pageTitle="Welcome"
      bgcolor="var(--soft-violet)"
    >
      <EmployerDashboardWelcomeLazy />
    </EmployerDashboardLayout>
  );
};
