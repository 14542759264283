import { useEffect } from "react";

import { ModalInstance } from "@js/components/modal";

import {
  useGetLastInterviewQuery,
  useMarkLastInterviewAsCompletedMutation,
} from "../../api";

import { InterviewModalContent } from "./interview-modal-content";
import {
  isFinishInterviewEvent,
  validateInterviewEvent,
} from "./validate-interview-event";

import styles from "./interview-modal.module.scss";

export const openInterviewModal = ({ onClose }: { onClose: () => void }) => {
  ModalInstance.close();
  ModalInstance.open({
    closeButton: false,
    children: <InterviewModal />,
    className: styles.modal,
    keepOnBackdropClick: true,
    padding: false,
    containerScrollableNoMobilePadding: true,
    onClose,
  });
};

const closeInterviewModal = () => {
  ModalInstance.close();
};

const INTERVIEW_BASE_URL = "https://app.hireflix.com";

const InterviewModal = () => {
  const { data: interview, isLoading } = useGetLastInterviewQuery();
  const [markLastInterviewAsCompletedMutation] =
    useMarkLastInterviewAsCompletedMutation();

  const interviewIsCompleted = !!interview?.completed_at;
  const interviewUrl = interview?.interview_hash
    ? `${INTERVIEW_BASE_URL}/${interview.interview_hash}`
    : undefined;

  useEffect(() => {
    if (!interviewUrl || interviewIsCompleted) {
      return;
    }

    const onEventReceived = (event: MessageEvent<unknown>) => {
      const eventData = event.data;
      if (
        !interviewUrl.includes(event.origin) ||
        !validateInterviewEvent(eventData)
      ) {
        return;
      }

      if (isFinishInterviewEvent(eventData)) {
        markLastInterviewAsCompletedMutation();
      }
    };

    window.addEventListener("message", onEventReceived);

    return () => {
      window.removeEventListener("message", onEventReceived);
    };
  }, [
    interviewUrl,
    interviewIsCompleted,
    markLastInterviewAsCompletedMutation,
  ]);

  return (
    <InterviewModalContent
      isLoading={isLoading}
      interviewUrl={interviewUrl}
      onClose={closeInterviewModal}
    />
  );
};
