import React from "react";

import { JobStatusBox } from "@js/apps/jobs/components/job-status/components/job-status-box";
import type { JobStatusBoxProps } from "@js/apps/jobs/components/job-status/types";

type TalentProposalsProps = JobStatusBoxProps & {
  content: JSX.Element;
};
export const AcceptingProposalsStatus = ({
  content,
  ...props
}: TalentProposalsProps) => {
  return (
    <JobStatusBox color="var(--positive-1)" {...props}>
      <JobStatusBox.Header>Accepting Applications</JobStatusBox.Header>
      <JobStatusBox.Content>{content}</JobStatusBox.Content>
    </JobStatusBox>
  );
};
