import {
  CLIENT_SIGN_UP_SELECTED,
  GOAL_SELECTED,
  INTEREST_SELECTED,
} from "@js/apps/onboarding/action-types";
import type { SubmitTypes } from "@js/apps/onboarding/hooks";
import type { TrackableUserAction } from "@js/middlewares/analytics/types";
import { Events, EventsProperties } from "@js/services/analytics";
import { logEvent } from "@js/services/analytics/event-logging";

export const handleOnboardingAnalyticsActions = (
  action: TrackableUserAction,
) => {
  switch (action.type) {
    case CLIENT_SIGN_UP_SELECTED: {
      logEvent(Events.CLIENT_SIGN_UP_SELECTED);
      break;
    }

    case GOAL_SELECTED: {
      const { joining_reasons } = action.payload;

      logEvent(Events.STEP_1_SELECTED, {
        [EventsProperties.joining_reasons]: joining_reasons,
      });
      break;
    }

    case INTEREST_SELECTED: {
      const { interests } = action.payload;

      logEvent(Events.STEP_2_SELECTED, {
        [EventsProperties.interests]: interests,
      });
      break;
    }

    case "onboardingNextStepClicked": {
      const currentStep: SubmitTypes = action.payload;

      if (currentStep === "goals") {
        logEvent(Events.CONTINUE_AFTER_FIRST_NAME);
        logEvent(Events.FACT_1_VIEWED);
      }

      if (currentStep === "interests") {
        logEvent(Events.CONTINUE_AFTER_INTEREST);
      }

      break;
    }

    case Events.CHOOSE_PERSONAL_ACCOUNT: {
      logEvent(Events.CHOOSE_PERSONAL_ACCOUNT);
      break;
    }

    default: {
      break;
    }
  }
};
