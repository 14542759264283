import { useGetEmployerAddressQuery } from "@js/apps/invoices/api";

export const useEmployerAddress = (employer: number | "all") => {
  const { data, isFetching } = useGetEmployerAddressQuery(
    employer === "all" ? "all" : employer,
    {
      skip: employer === "all",
    },
  );

  return {
    fetchingEmployersAddress: isFetching,
    employersAddress: data || null,
  };
};
