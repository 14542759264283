import { useMemo, useState } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import cs from "classnames";

import {
  Button,
  Menu,
  menuItemClasses,
  styled,
} from "@hexocean/braintrust-ui-components";
import { KeyboardArrowDownIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./style.module.scss";

export type GenericSortButtonProps<T> = {
  defaultValue?: T;
  options: ReadonlyArray<{ label: string; value: T }>;
  onClick?: (arg: T) => void;
} & TypedWrappedFieldProps<T>;

export const ButtonLabel = ({ label }: { label: string }) => (
  <span>
    Sorted by <b>{label}</b>
  </span>
);

export const GenericSortButton = <T,>({
  input: { value, onChange },
  defaultValue,
  options,
  onClick,
}: GenericSortButtonProps<T>) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  const buttonValue = value || defaultValue;
  const buttonLabel = useMemo(
    () => options.find((option) => option.value === buttonValue)?.label || "",
    [options, buttonValue],
  );

  return (
    <Menu
      anchor={
        <Button
          variant="transparent"
          size="x-small"
          onClick={() => setIsMenuExpanded(true)}
          className={styles.sortButton}
          fontWeight={400}
        >
          <ButtonLabel label={buttonLabel} />

          <KeyboardArrowDownIcon
            className={cs(styles.sortButtonArrow, {
              [styles.sortButtonArrowExpanded]: isMenuExpanded,
            })}
          />
        </Button>
      }
      minMenuWidth="152px"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      className={styles.sortButtonMenu}
      open={isMenuExpanded}
      onClose={() => setIsMenuExpanded(false)}
    >
      {options.map((option) => (
        <StyledMenuItem
          key={String(option.label)}
          selected={option.value === buttonValue}
          onClick={() => {
            onChange(option.value);
            setIsMenuExpanded(false);
            if (onClick) {
              onClick(option.value);
            }
          }}
        >
          {option.label}
        </StyledMenuItem>
      ))}
    </Menu>
  );
};

export type MenuItemColorVariantType =
  | "red"
  | "orange"
  | "yellow"
  | "green"
  | "blue"
  | "teal"
  | "violet"
  | "grey"
  | "beige";

export const StyledMenuItem = styled(Menu.Item, {
  shouldForwardProp: (prop) => prop !== "colorVariant",
})(({
  colorVariant = "violet",
}: {
  colorVariant?: MenuItemColorVariantType;
}) => {
  return {
    [`&.${menuItemClasses.root}`]: {
      "&:hover": {
        backgroundColor: `var(--soft-${colorVariant})`,
      },
      "&:focus": {
        backgroundColor: `var(--medium-${colorVariant})`,
      },
    },
    [`&.${menuItemClasses.selected}`]: {
      color: `var(--dark-${colorVariant})`,
      backgroundColor: `var(--medium-${colorVariant})`,
    },
    [`&.${menuItemClasses.focusVisible}`]: {
      backgroundColor: `var(--soft-${colorVariant})`,

      [`&.${menuItemClasses.selected}`]: {
        color: `var(--dark-${colorVariant})`,
        backgroundColor: `var(--medium-${colorVariant})`,
      },
    },
  };
});
