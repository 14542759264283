import { useMemo } from "react";

import { Box } from "@hexocean/braintrust-ui-components";

import { AttachmentsPreview } from "./attachments-preview";
import type {
  PostFileAttachmentPreviewData,
  PreparedAttachment,
} from "./model";
import { type CreatePostAttachment, isLinkAttachment } from "./model";
import { prepareAttachments } from "./utils";

export type PostAttachmentsProps = {
  attachments: (CreatePostAttachment | PostFileAttachmentPreviewData)[];
  removeAttachment?: (
    id: string | number,
    isEditMode: boolean,
    isFromText?: boolean,
  ) => void;
  onEditLinkClick?: (url: string) => void;
  openNewTab?: boolean;
};

export const PostAttachments = ({
  attachments,
  removeAttachment,
  onEditLinkClick,
  ...props
}: PostAttachmentsProps) => {
  const processedAttachments = useMemo(() => {
    const preparedAttachments = prepareAttachments(attachments);
    const cards: PreparedAttachment[] = [];
    const genericAttachments: PreparedAttachment[] = [];
    preparedAttachments.forEach((attachment) => {
      if (
        isLinkAttachment(attachment) &&
        (attachment.source === "job" || attachment.source === "freelancer")
      ) {
        cards.push(attachment);
        return;
      }

      genericAttachments.push(attachment);
      return;
    });
    return {
      cards,
      genericAttachments,
    };
  }, [attachments]);

  if (
    !processedAttachments.cards.length &&
    !processedAttachments.genericAttachments.length
  ) {
    return null;
  }

  const isMultipleAttachments =
    processedAttachments.cards.length +
      processedAttachments.genericAttachments.length >
    1;

  return (
    <Box
      display="flex"
      flexDirection="column"
      data-testid="post-attachments-container"
      gap="16px"
      position="relative"
    >
      {!!processedAttachments.genericAttachments.length && (
        <AttachmentsPreview
          attachments={processedAttachments.genericAttachments}
          isMultipleAttachments={isMultipleAttachments}
          removeAttachment={removeAttachment}
          onEditLinkClick={onEditLinkClick}
          {...props}
        />
      )}

      {!!processedAttachments.cards.length && (
        <AttachmentsPreview
          attachments={processedAttachments.cards}
          grid
          isMultipleAttachments={isMultipleAttachments}
          removeAttachment={removeAttachment}
          onEditLinkClick={onEditLinkClick}
          {...props}
        />
      )}
    </Box>
  );
};
