import { useMemo } from "react";

import { useJobFormContext } from "@js/apps/jobs/context/job-form-context/job-form-context";
import { useAppSelector } from "@js/hooks";
import type { JobFlowEntryType } from "@js/types/jobs";

const JOB_FLOW_ENTRY = {
  AI: "ai",
  CUSTOM: "custom",
  UPLOAD: "upload",
} as const;

export const useGetJobFlowEntry = () => {
  const { jobDraft } = useJobFormContext();
  const jobToEdit = useAppSelector((state) => state.jobs);

  const flow_entry: JobFlowEntryType = useMemo(() => {
    if (jobDraft?.flow_entry) {
      return jobDraft?.flow_entry;
    } else if (jobToEdit.aiGenerated) {
      return JOB_FLOW_ENTRY.AI;
    } else if (jobToEdit.uploadedJobDescription) {
      return JOB_FLOW_ENTRY.UPLOAD;
    } else {
      return JOB_FLOW_ENTRY.CUSTOM;
    }
  }, [jobToEdit, jobDraft?.flow_entry]);

  return {
    flow_entry,
  };
};
