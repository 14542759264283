import { Decimal } from "decimal.js";

import type { EmployerInvoiceMinimal, InvoiceItem } from "@js/types/invoices";

export const countInvoiceSubTotal = (
  items: Array<Pick<InvoiceItem, "quantity" | "unit_price">>,
) =>
  items.length
    ? items.reduce(
        (prevVal, currentVal) =>
          prevVal.plus(
            countItemTotal(currentVal.unit_price, currentVal.quantity),
          ),
        new Decimal(0),
      )
    : new Decimal(0);

export const countInvoiceTax = (
  items: Array<Pick<InvoiceItem, "quantity" | "tax_rate" | "unit_price">>,
) =>
  items.length
    ? items.reduce((prevVal, currentVal) => {
        if (currentVal.tax_rate && +currentVal.tax_rate > 0) {
          const taxRate = new Decimal(currentVal.tax_rate).div(
            new Decimal(100),
          );
          return prevVal.plus(
            countItemTotal(currentVal.unit_price, currentVal.quantity).mul(
              taxRate,
            ),
          );
        }

        return prevVal;
      }, new Decimal(0))
    : new Decimal(0);

export const countItemTotal = (unitPrice: string | undefined, qty: string) =>
  !!unitPrice && +unitPrice > 0 && +qty > 0
    ? new Decimal(unitPrice).mul(new Decimal(qty))
    : new Decimal(0);

export const getInvoicesTotal = (invoices: Array<{ gross_total: string }>) =>
  invoices.reduce(
    (total, invoice) => total.plus(invoice.gross_total),
    new Decimal(0),
  );

export const getEmployerInvoicesThatCannotBePaid = (
  invoicesToPay: EmployerInvoiceMinimal[],
) => {
  const invoicesToPayIds = invoicesToPay.map(({ id }) => id);
  const cannotBePaid = invoicesToPay.filter(
    ({ dependent_on: dependentOn }) =>
      !!dependentOn && invoicesToPayIds.indexOf(dependentOn) === -1,
  );

  if (cannotBePaid.length) {
    return cannotBePaid;
  }

  return null;
};
