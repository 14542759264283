import { API } from "@js/api";
import type { CompanyNode } from "@js/types/common";

export type NewClientInvitationDetails = {
  first_name: string;
  last_name: string;
  email: string;
  organization_name: string | null;
  company_node?: CompanyNode;
  invitation_error: string | null;
  invitation_type: string | null;
};
const onboardingApi = API.injectEndpoints({
  endpoints: (build) => ({
    getInvitationDetails: build.query<
      NewClientInvitationDetails,
      { key: string }
    >({
      query: ({ key }) => ({
        url: `/users/new_employer_invitation_details/?new_employer_invitation_key=${key}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetInvitationDetailsQuery } = onboardingApi;
