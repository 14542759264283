import type { FC } from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { CheckIcon, CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import useSortedToolTip from "@js/hooks/use-sorted-tooltip";
import type {
  ApplicationInsightMatchSummary,
  JobApplicationInsightsResponse,
} from "@js/types/jobs";

import { useMatchPlacement } from "./hooks";

type ApplicationInsightsItemProps = {
  content: string;
  summaryType: ApplicationInsightMatchSummary["summary_type"];
};

export const ApplicationInsightsItem: FC<ApplicationInsightsItemProps> = ({
  content,
  summaryType,
}) => {
  return (
    <Box display="flex" gap={1}>
      {summaryType === "positive" ? (
        <CheckIcon sx={{ fontSize: "20px", color: "var(--positive-2)" }} />
      ) : (
        <CloseIcon sx={{ fontSize: "20px", color: "var(--negative-2)" }} />
      )}
      <Typography
        sx={{ color: "var(--black)" }}
        component="p"
        variant="paragraph"
        size="small"
      >
        {content}
      </Typography>
    </Box>
  );
};

type MatchLabelItem = {
  hasJobBid?: boolean;
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null;
  totalApplicants: JobApplicationInsightsResponse["total_applicants"];
};

type ApplicationInsightsListProps = {
  matchLabelItem?: MatchLabelItem;
  matchedSummaryList: ApplicationInsightMatchSummary[];
  matchedSkillsCount?: JobApplicationInsightsResponse["skills"];
  showMatchedSummaryList?: boolean;
  showPreview?: boolean;
};

const PREVIEW_INSIGHTS = [
  "Selectively applying to jobs right now",
  "Top candidate for multiple past job positions",
];

export const ApplicationInsightsList: FC<ApplicationInsightsListProps> = ({
  matchLabelItem,
  matchedSummaryList,
  matchedSkillsCount = "",
  showMatchedSummaryList,
  showPreview,
}) => {
  const matchPlacement = useMatchPlacement(
    matchLabelItem?.matchLabel || null,
    matchLabelItem?.totalApplicants || 0,
    matchLabelItem?.hasJobBid || false,
  );
  const hasTopSkills = !matchedSkillsCount.includes("0") || false;
  const [currentSkills, totalSkills] = matchedSkillsCount.split("/") || [0, 0];
  const sortedToolTipData = useSortedToolTip(matchedSummaryList);

  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      {(showPreview || showMatchedSummaryList) && (
        <ApplicationInsightsItem
          content={`${currentSkills} of ${totalSkills} top skills for this job`}
          summaryType={hasTopSkills ? "positive" : "constructive"}
        />
      )}
      {matchPlacement?.label && showMatchedSummaryList && (
        <ApplicationInsightsItem
          content={matchPlacement.label}
          summaryType={matchPlacement.summaryType}
        />
      )}
      {showMatchedSummaryList && (
        <>
          {sortedToolTipData.map(({ sentence, summary_type }) => (
            <ApplicationInsightsItem
              key={sentence}
              content={sentence}
              summaryType={summary_type}
            />
          ))}
          {sortedToolTipData.length === 0 && (
            <Box display="flex" gap={1}>
              <img
                height="20px"
                width="auto"
                src={`${SETTINGS.STATIC_URL}jobs/cloud-crossed.svg`}
                alt="cloud-crossed"
              />
              <Typography
                sx={{ color: "var(--black)" }}
                component="p"
                variant="paragraph"
                size="small"
              >
                Please wait while we cook up more insights.
              </Typography>
            </Box>
          )}
        </>
      )}
      {showPreview && matchLabelItem?.matchLabel && (
        <Box
          sx={{
            position: "relative",
            "& > ::after": {
              content: "''",
              position: "absolute",
              inset: 0,
              backdropFilter: "blur(2.5px)",
              "-webkit-backdrop-filter": "blur(2.5px)",
              pointerEvents: "none",
              background: "rgba(255, 255, 255, 0.5)",
            },
          }}
        >
          <Box display="flex" flexDirection="column" gap={1.5}>
            {PREVIEW_INSIGHTS.map((preview) => (
              <ApplicationInsightsItem
                key={preview}
                content={preview}
                summaryType={
                  !matchLabelItem ||
                  matchLabelItem?.matchLabel === ENUMS.SmartMatchingLabel.BAD
                    ? "constructive"
                    : "positive"
                }
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
