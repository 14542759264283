import { Typography } from "@hexocean/braintrust-ui-components";

type BudgetLabelProps = {
  children: string;
  optional?: boolean;
};

export const BudgetLabel = ({ children, optional }: BudgetLabelProps) => {
  return (
    <Typography
      component="label"
      htmlFor="budget-label"
      variant="label"
      size="medium"
      mb={1}
    >
      {children}
      {optional && <Typography component="span">{` (Optional)`}</Typography>}
    </Typography>
  );
};
