import type { ChangeEvent, KeyboardEvent } from "react";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import {
  IconButton,
  InputAdornment,
  RegularTextField,
} from "@hexocean/braintrust-ui-components";
import {
  CloseIcon,
  SearchSVGIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useAppDispatch } from "@js/hooks";

import { UNIVERSAL_SEARCH_PATHS } from "../constants";
import {
  setSearch,
  useResetUniversalSearchPhrase,
  useUniversalSearchPhrase,
} from "../universal-search-slice";

export type UniversalSearchFieldProps = {
  onFocus: () => void;
  onEnter: () => void;
  onChange: (search: string) => void;
  canAccessGiveAndGetHelp: boolean;
};

export const UniversalSearchField = ({
  onFocus,
  onEnter,
  onChange,
  canAccessGiveAndGetHelp,
}: UniversalSearchFieldProps) => {
  const dispatch = useAppDispatch();
  const search = useUniversalSearchPhrase();
  const resetSearch = useResetUniversalSearchPhrase();
  const initialLocationRef = useRef(useLocation());

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(ev.target.value));
    onChange(ev.target.value);
  };

  const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter") {
      onEnter();
    }
  };

  const handleClear = () => {
    dispatch(setSearch(""));
  };

  useEffect(() => {
    const { pathname } = initialLocationRef.current;
    const shouldResetUniversalSearch = getShouldResetUniversalSearch(pathname);
    if (!shouldResetUniversalSearch) {
      return;
    }

    resetSearch();
  }, [initialLocationRef, resetSearch]);

  return (
    <RegularTextField
      value={search}
      onFocus={onFocus}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={getUniversalSearchPlaceholder(canAccessGiveAndGetHelp)}
      autoComplete="off"
      inputProps={{
        "aria-label": "Search",
      }}
      sx={{
        ".MuiInputBase-root": {
          pl: 2,
          height: "48px",
        },
        ".MuiInputBase-input": {
          padding: "13px 8px 11px 0",
          lineHeight: "24px",
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderRadius: "5rem",
        },
        fieldset: {
          border: "none",
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchSVGIcon />
          </InputAdornment>
        ),
        endAdornment: search ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              onClick={handleClear}
              variant="transparent"
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : undefined,
      }}
    />
  );
};

const getUniversalSearchPlaceholder = (
  canAccessGiveAndGetHelp: boolean,
): string => {
  if (canAccessGiveAndGetHelp) {
    return "Search for jobs, career help, and people...";
  }

  return "Search for jobs and people...";
};

const searchPaths = Object.values(UNIVERSAL_SEARCH_PATHS);
const getShouldResetUniversalSearch = (pathname?: string) => {
  if (typeof pathname === "undefined") {
    return;
  }

  const isSearchPage = searchPaths.some((searchPath) =>
    pathname.includes(searchPath),
  );
  if (isSearchPage) {
    return false;
  }

  return true;
};
