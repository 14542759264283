import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useGetInvitationDetailsQuery } from "@js/apps/onboarding/api";
import { Snackbar } from "@js/components/snackbar";

export const NEW_EMPLOYER_INVITATION_PARAM = "new_employer_invitation_key";
const NEW_EMPLOYER_INVITATION_COMPANY_NODE = "company_node";

export const useNewClientSignUpInvitation = () => {
  const [searchParams] = useSearchParams();
  const clientInvitationKey = searchParams.get(NEW_EMPLOYER_INVITATION_PARAM);
  const clientCompanyNode = searchParams.get(
    NEW_EMPLOYER_INVITATION_COMPANY_NODE,
  );
  const [isClientInvitation, setIsClientInvitation] = useState(
    Boolean(clientInvitationKey),
  );
  const { data, isLoading } = useGetInvitationDetailsQuery(
    {
      key: clientInvitationKey as string,
    },
    {
      skip: !clientInvitationKey,
    },
  );

  useEffect(() => {
    if (!isLoading && data?.invitation_error?.length) {
      Snackbar.error(data?.invitation_error, {
        preventDuplicate: true,
      });
      setIsClientInvitation(false);
    }
  }, [data, isLoading]);

  return {
    isClientInvitation,
    isClientCompanyNodePresent: Boolean(clientCompanyNode),
    newClientInvitationData: data?.invitation_error ? undefined : data,
  };
};
