import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { Modal } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type { PaymentMethod } from "@js/types/payments";

import type { VerifyStripeACHFormData } from "../../forms/verify-stripe-ach-payment-method";
import { VerifyStripeACHForm } from "../../forms/verify-stripe-ach-payment-method";

import styles from "./style.module.scss";

const VERIFY_PAYMENT_METHOD_MODAL_ID = "verify-payment-method";

export const VerifyStripeACHPaymentMethodModalInstance = Modal(
  VERIFY_PAYMENT_METHOD_MODAL_ID,
);

type VerifyStripeACHPaymentMethodModalProps = {
  paymentMethod: PaymentMethod;
  disableSnackbarOnSuccess?: boolean;
  onSubmit: (
    paymentMethod: PaymentMethod,
    values: VerifyStripeACHFormData,
  ) => Promise<void>;
};

const VerifyStripeACHPaymentMethodModal = ({
  paymentMethod,
  disableSnackbarOnSuccess = false,
  onSubmit,
}: VerifyStripeACHPaymentMethodModalProps) => {
  const handleOnSubmit = async (values: VerifyStripeACHFormData) => {
    await onSubmit(paymentMethod, values);

    if (!disableSnackbarOnSuccess) {
      Snackbar.success("ACH payment method verified!");
    }

    VerifyStripeACHPaymentMethodModalInstance.close();
  };

  return (
    <div className={styles.verifyPaymentMethodModal}>
      <Typography
        component="h1"
        variant="title"
        fontWeight={400}
        size="small"
        className="mt0"
      >
        Verify Payment Method
      </Typography>
      <Typography component="p">
        The payment method has to be verified before you can transfer the
        refundable deposit. To verify this payment method, you will need to
        enter in the value of the two small deposits. Two small deposits should
        be in the bank account selected in 1-2 days. If you are sending an offer
        to talent and using ACH, the offer will be sent once the total offer
        deposit has been collected. For immediate verification, enter in a
        credit card instead.
      </Typography>
      <VerifyStripeACHForm
        onSubmit={handleOnSubmit}
        onCancel={VerifyStripeACHPaymentMethodModalInstance.close}
      />
    </div>
  );
};

export const openVerifyStripeACHPaymentMethodModal = ({
  paymentMethod,
  disableSnackbarOnSuccess,
  onSubmit,
}: VerifyStripeACHPaymentMethodModalProps) => {
  VerifyStripeACHPaymentMethodModalInstance.open({
    children: (
      <VerifyStripeACHPaymentMethodModal
        paymentMethod={paymentMethod}
        disableSnackbarOnSuccess={disableSnackbarOnSuccess}
        onSubmit={onSubmit}
      />
    ),
  });
};
