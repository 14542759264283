import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import type { DecoratedFormProps } from "redux-form";
import { change } from "redux-form";

import { useUser } from "@js/apps/common/hooks";
import { useMessagesContext } from "@js/apps/messenger/context";
import { getFormId } from "@js/apps/messenger/utils/get-messenger-form-id";
import { useAppDispatch, useWebsocket } from "@js/hooks";
import type {
  MessageFileAttachmentData,
  MessageRoom,
} from "@js/types/messenger";
import { deepClone } from "@js/utils";

import { HelpOfferModal } from "../components/help-offer/help-offer-modal";

import { useSendMessage } from "./use-send-message";

type UseTextBoxParams = {
  room?: MessageRoom;
  initialMessage?: string;
};

export type TextBoxFormValues = {
  message?: string;
  attachments?: MessageFileAttachmentData[] | "";
  calendar_link?: string;
};

export const useTextBox = ({ room, initialMessage }: UseTextBoxParams) => {
  const user = useUser();
  const { isRunning } = useWebsocket();
  const messagesStore = useMessagesContext();
  const [params, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const roomId = room?.id;
  const formId = getFormId(roomId ?? 0);

  const canNotSendMessageReason = isRunning
    ? ""
    : "Sorry! Websocket service is currently unavailable.";
  const participant = room?.participants.find(
    ({ id }) => id === room.interlocutors[0],
  );

  const { canSendMessage, sendMessage, messageError } = useSendMessage({
    room: room?.id,
  });

  useEffect(() => {
    const initialMessageParam = params.get("initialMessage");
    if (!!initialMessageParam && roomId !== undefined) {
      dispatch(change(formId, "message", initialMessageParam));
      messagesStore.set(roomId, initialMessageParam);
      setSearchParams(
        (prevState) => {
          prevState.delete("initialMessage");
          return prevState;
        },
        { replace: true },
      );
    }
  }, [params, setSearchParams, dispatch, formId, roomId, messagesStore]);

  const initialValues = useMemo(
    () => ({
      message: initialMessage,
    }),
    [initialMessage],
  );

  const cachedMessage = useMemo(() => {
    return roomId !== undefined ? messagesStore.get(roomId) : "";
  }, [messagesStore, roomId]);

  useEffect(() => {
    if (roomId !== undefined && cachedMessage) {
      dispatch(change(formId, "message", cachedMessage));
    }
  }, [cachedMessage, formId, dispatch, roomId]);

  const onSubmit = useCallback(
    (
      formValues: TextBoxFormValues,
      _dispatch: unknown,
      formProps: DecoratedFormProps<TextBoxFormValues>,
    ) => {
      const values: TextBoxFormValues & {
        attachment_ids?: number[];
      } = deepClone(formValues);

      const attachmentsIds = (values.attachments || [])
        .map((attachment) => attachment.id)
        .filter((id): id is number => Boolean(id));
      if (attachmentsIds.length) {
        values.attachment_ids = attachmentsIds;
      }

      delete values.attachments;

      if (!values.calendar_link) {
        delete values.calendar_link;
      }

      sendMessage(values, { onSent: formProps.reset });
      messagesStore.set(roomId, undefined);
      dispatch(change(formId, "message", ""));
      HelpOfferModal.close();
    },
    [roomId, sendMessage, messagesStore, formId, dispatch],
  );

  return {
    user,
    isParticipantFreelancer:
      participant?.account_type === ENUMS.AccountType.FREELANCER,
    canSendMessage,
    canNotSendMessageReason,
    messageError,
    initialValues,
    formId,
    onSubmit,
  };
};
