import { API } from "@js/api";
import type {
  CommonLanguage,
  InternalTalentReview,
  LinkMetadata,
  Stats,
} from "@js/types/common";
import { isFreelancerForNodeStaffEntity } from "@js/types/freelancer";

import { freelancerApi } from "../freelancer/api";
import { bidApi } from "../jobs/apps/bids/api";

export type ReviewsProps = {
  id: number;
};

export type AddInternalReviewPayload = {
  text: string;
  rating: string | null;
  freelancer: number;
};

type GetInternalReviewsResultType = {
  internal_reviews: InternalTalentReview[];
  average_internal_review_rating: string | null;
  internal_review_ratings_count: number;
  id: number;
};

type GetLinkMetadataProps = { url: string };
type GetLinkMetadataResultType = LinkMetadata;

export type GetLanguageListResultType = {
  id: number;
  name: string;
  code: string;
}[];

const internalTalentReviewsApi = API.injectEndpoints({
  endpoints: (build) => ({
    getInternalReviews: build.query<GetInternalReviewsResultType, ReviewsProps>(
      {
        query: ({ id }) => ({
          url: `/freelancers/${id}/internal_talent_reviews/`,
          method: "GET",
        }),
        providesTags: ["InternalReviews"],
        async onQueryStarted({ id }, { queryFulfilled, dispatch }) {
          try {
            const { data } = await queryFulfilled;

            dispatch(
              freelancerApi.util.updateQueryData(
                "getFreelancerPublicProfile",
                id,
                (draft) => {
                  if (draft && isFreelancerForNodeStaffEntity(draft)) {
                    draft.average_internal_review_rating =
                      data.average_internal_review_rating;
                    draft.internal_review_ratings_count =
                      data.internal_review_ratings_count;
                  }
                },
              ),
            );
          } catch {
            /* Do nothing. */
          }
        },
        async onCacheEntryAdded(_arg, { dispatch, cacheDataLoaded }) {
          try {
            const { data } = await cacheDataLoaded;

            dispatch(
              bidApi.util.updateQueryData("fetchBids", undefined, (draft) => {
                if (draft) {
                  draft.results.forEach((bid) => {
                    if (bid.freelancer.id === data.id) {
                      bid.freelancer = {
                        ...bid.freelancer,
                        average_internal_review_rating:
                          data.average_internal_review_rating,
                        internal_review_ratings_count:
                          data.internal_review_ratings_count,
                      };
                    }
                  });
                }
              }),
            );
          } catch {
            /* Do nothing. */
          }
        },
      },
    ),
    addInternalReview: build.mutation<ReviewsProps, AddInternalReviewPayload>({
      query: (data) => ({
        url: "/internal_talent_reviews/",
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "InternalReviews",
        "TalentInviteSuggestions",
        "EmployerBids",
      ],
    }),
    deleteInternalReview: build.mutation<ReviewsProps, ReviewsProps>({
      query: ({ id }) => ({
        url: `/internal_talent_reviews/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["InternalReviews", "TalentInviteSuggestions"],
    }),
  }),
});

const linksMetadataApi = API.injectEndpoints({
  endpoints: (build) => ({
    getLinkMetadata: build.query<
      GetLinkMetadataResultType,
      GetLinkMetadataProps
    >({
      query: ({ url }) => ({
        url: "/page_metadata/",
        method: "GET",
        params: { url },
      }),
    }),
  }),
});

const languageListApi = API.injectEndpoints({
  endpoints: (build) => {
    return {
      getLanguageList: build.query<GetLanguageListResultType, void>({
        query: () => {
          return {
            url: "/languages/",
            method: "GET",
          };
        },
      }),
    };
  },
});

const statsApi = API.injectEndpoints({
  endpoints: (build) => {
    return {
      stats: build.query<Stats, void>({
        query: () => {
          return {
            url: "/stats/",
            method: "GET",
          };
        },
      }),
    };
  },
});

const transformLanguageListToCommonFormat = (
  languageList: GetLanguageListResultType,
): CommonLanguage[] => {
  return languageList.map((language) => {
    return {
      label: language.name,
      value: language.id,
      code: language.code,
    };
  });
};

export const {
  useGetInternalReviewsQuery,
  useAddInternalReviewMutation,
  useDeleteInternalReviewMutation,
} = internalTalentReviewsApi;

export const { useLazyGetLinkMetadataQuery, useGetLinkMetadataQuery } =
  linksMetadataApi;

export const { useGetLanguageListQuery } = languageListApi;
export const { useStatsQuery } = statsApi;

export { transformLanguageListToCommonFormat };
