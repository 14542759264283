import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { Box } from "@hexocean/braintrust-ui-components";
import { UploadLinkedinConnectionsNote } from "@js/apps/dashboard/components/upload-linkedin-connections/upload-linkedin-connections-note";
import type { UploadType } from "@js/apps/file-upload";
import { getFileWithId, useUploadSingleFile } from "@js/apps/file-upload";
import { useAppDispatch } from "@js/hooks";
import { Events } from "@js/services/analytics";

import { UploadLinkedinConnectionsDropzonePlaceholder } from "./upload-linkedin-connections-dropzone-placeholder";
import { UploadLinkedinConnectionsError } from "./upload-linkedin-connections-error";
import { useUploadLinkedinConnectionsError } from "./use-upload-linkedin-connections-error";

const uploadLinkedinUploadType: UploadType =
  ENUMS.UploadType.LINKEDIN_USER_DATA_CSV;

type UploadLinkedinConnectionsDropzoneProps = {
  onConnectionsUpload: () => void;
};

export const UploadLinkedinConnectionsDropzone = ({
  onConnectionsUpload,
}: UploadLinkedinConnectionsDropzoneProps) => {
  const dispatch = useAppDispatch();

  const onCancel = () => {
    dispatch({
      type: Events.CLICK_UPLOAD_LINKEDIN_CSV,
      payload: {
        success: false,
        file_uploaded: false,
      },
    });
  };
  const onUpload = () => {
    dispatch({
      type: Events.CLICK_UPLOAD_LINKEDIN_CSV,
      payload: {
        success: true,
        file_uploaded: true,
      },
    });
    onConnectionsUpload();
  };

  const onUploadError = () => {
    dispatch({
      type: Events.CLICK_UPLOAD_LINKEDIN_CSV,
      payload: {
        success: false,
        file_uploaded: false,
      },
    });
  };

  const {
    error: uploadError,
    uploadSingleFile,
    isUploading,
    resetFileState,
    uploadingFileName,
  } = useUploadSingleFile({ onUpload, onUploadError });

  const { resetFileDropError, onDropRejected, showError, errorMessage } =
    useUploadLinkedinConnectionsError({
      uploadError,
    });

  const onDrop = useCallback(
    (files: File[]) => {
      resetFileDropError();
      resetFileState();

      const [file] = files;
      if (!file) {
        return;
      }

      const fileWithId = getFileWithId(file);
      uploadSingleFile({
        file: fileWithId,
        uploadType: uploadLinkedinUploadType,
        timeoutMs: 45_000,
      });
    },
    [uploadSingleFile, resetFileState, resetFileDropError],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    minSize: 1,
    maxSize: SETTINGS.DEFAULT_MAX_SIZE_FILE,
    onDrop,
    onDropRejected,
    accept: { "text/csv": [".csv"] },
    onFileDialogCancel: onCancel,
    disabled: isUploading,
  });

  const handleErrorClear = () => {
    resetFileDropError();
    resetFileState();
  };

  return (
    <>
      <Box
        {...getRootProps()}
        role="button"
        aria-label="Upload linkedIn connections"
        tabIndex={-1}
        sx={{
          position: "relative",
          overflow: "hidden",
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <UploadLinkedinConnectionsError
          showError={showError}
          error={errorMessage}
          onErrorClear={handleErrorClear}
        />
        <input
          style={{ display: "none" }}
          {...getInputProps()}
          data-testid="upload-linkedin-connections"
        />
        <UploadLinkedinConnectionsDropzonePlaceholder
          isDragActive={isDragActive}
          isUploading={isUploading}
          uploadingFileName={uploadingFileName}
        />
      </Box>
      <UploadLinkedinConnectionsNote transparent={isUploading} />
    </>
  );
};
