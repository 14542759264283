import type { ReactNode } from "react";

import { ModalInstance } from "@js/components/modal";

import styles from "./common-team-members-modal.module.scss";

export const openCommonTeamMembersModal = ({
  children,
}: {
  children: ReactNode;
}) => {
  ModalInstance.open({
    children,
    closeButton: true,
    padding: false,
    className: styles.modal,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
      className: styles.closeBtn,
    },
  });
};

export const closeCommonTeamMembersModal = () => {
  ModalInstance.close();
};
