import { useCallback, useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { uniq } from "underscore";

import { useGetSkillsQuery } from "@js/apps/skills/api";
import { sortBySuperpower } from "@js/apps/skills/fields/helpers";
import type { Skill } from "@js/types/admin";

import { MAX_TOP_SKILLS_COUNT } from "../const";
import { SelectedSkillsList } from "../selected-skills-list";
import type { SkillListItem } from "../selected-skills-list/item";

type JobCreateSelectedSkillsListProps = TypedWrappedFieldProps<number[]> & {
  error?: string;
  topSkillsInput: TypedWrappedFieldProps<number[]>["input"];
  skills?: Skill[];
};
export const CreateJobSelectedSkillsList = ({
  input,
  error,
  topSkillsInput,
  skills,
}: JobCreateSelectedSkillsListProps) => {
  const { handleFavorite, handleRemove, handleReorder, options } =
    useJobCreateSelectedSkillsList({ input, topSkillsInput, skills });

  const selectedSkillsCount = input.value.length;

  return (
    <SelectedSkillsList
      error={error}
      selectedSkillsCount={selectedSkillsCount}
      handleFavorite={handleFavorite}
      handleRemove={handleRemove}
      handleReorder={handleReorder}
      options={options}
      maxTopSkillsCount={MAX_TOP_SKILLS_COUNT.create_job}
    />
  );
};

const mapSkillsToSkillOption = ({
  skills,
  selectedSkillsId,
  topSkillsIds,
}: {
  skills: Skill[];
  selectedSkillsId: number[];
  topSkillsIds: number[];
}) => {
  if (!skills?.length) return [];

  return selectedSkillsId.reduce((acc, selectedSkillId) => {
    const _skill = skills.find((skill) => skill.id === selectedSkillId);

    if (_skill) {
      acc.push({
        skill: _skill,
        id: selectedSkillId,
        is_superpower: topSkillsIds?.includes(selectedSkillId),
      });
    }

    return acc as SkillListItem[];
  }, [] as SkillListItem[]);
};

const useJobCreateSelectedSkillsList = ({
  input,
  topSkillsInput,
}: Pick<
  JobCreateSelectedSkillsListProps,
  "input" | "topSkillsInput" | "skills"
>) => {
  const { data: skills } = useGetSkillsQuery(
    { ids: input.value },
    { skip: !input.value?.length },
  );

  const options = useMemo(() => {
    const _skills = Array.isArray(skills) ? skills : [];

    return mapSkillsToSkillOption({
      skills: _skills,
      selectedSkillsId: input.value,
      topSkillsIds: topSkillsInput.value,
    });
  }, [skills, input.value, topSkillsInput.value]);

  const handleChangeWithSort = useCallback(
    (value: number[], topSkills: number[]) => {
      topSkillsInput.onChange(topSkills);
      return input.onChange(uniq([...topSkills, ...value], false));
    },
    [input, topSkillsInput],
  );

  const handleFavorite = useCallback(
    (selectedSkill: SkillListItem) => {
      const newTopSkills = topSkillsInput.value.includes(selectedSkill.id)
        ? topSkillsInput.value.filter((id) => id !== selectedSkill.id)
        : [...topSkillsInput.value, selectedSkill.id];

      topSkillsInput.onChange(newTopSkills);

      handleChangeWithSort(input.value, newTopSkills);
    },
    [input, handleChangeWithSort, topSkillsInput],
  );

  const handleRemove = useCallback(
    (selectedSkill: SkillListItem) => {
      handleChangeWithSort(
        input.value.filter((skillId) => skillId !== selectedSkill.id),
        topSkillsInput.value.length > 0
          ? topSkillsInput.value.filter(
              (skillId) => skillId !== selectedSkill.id,
            )
          : [],
      );
    },
    [input, handleChangeWithSort, topSkillsInput],
  );

  const handleReorder = useCallback(
    (value: SkillListItem[]) => {
      const sortedNewValue = value.sort(sortBySuperpower);
      const sortedOnlyTopSkills = sortedNewValue
        .filter((skill) => !!skill.is_superpower)
        .map((skill) => skill.id);
      const sortedAllSkills = sortedNewValue.map((skill) => skill.id);
      topSkillsInput.onChange(sortedOnlyTopSkills);
      input.onChange(sortedAllSkills);
    },
    [input, topSkillsInput],
  );

  return {
    handleFavorite,
    handleRemove,
    handleReorder,
    options,
  };
};
