import { API } from "@js/api";
import type {
  PaymentMethod,
  PaymentTransaction,
  StripeACHPayment,
  StripeCreditCardPayment,
} from "@js/types/payments";

import type { VerifyStripeACHFormData } from "./forms/verify-stripe-ach-payment-method";
import type {
  CreateACHPaymentMethodPayload,
  CreateCCPaymentMethodPayload,
} from "./types";

type CreateStripeACHPaymentMethodParams = {
  data: CreateACHPaymentMethodPayload;
  in_offer_flow?: boolean;
};

type PayInvoicesPayload = {
  amount?: string;
  invoices: number[];
  payment_method: number;
};

export const paymentsApi = API.injectEndpoints({
  endpoints: (build) => ({
    getPaymentMethods: build.query<PaymentMethod[], void>({
      query: () => ({
        url: "/payment_methods/",
        method: "GET",
      }),
      providesTags: ["PaymentMethods"],
    }),
    deletePaymentMethod: build.mutation<void, number>({
      query: (id) => ({
        url: `/payment_methods/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, error) => (error ? [] : ["PaymentMethods"]),
    }),
    setAsDefaultPaymentMethod: build.mutation<void, number>({
      query: (id) => ({
        url: `/payment_methods/${id}/set_as_default/`,
        method: "POST",
      }),
      invalidatesTags: (_result, error) => (error ? [] : ["PaymentMethods"]),
    }),
    verifyStripeACHPaymentMethod: build.mutation<
      void,
      { paymentMethodId: number; values: VerifyStripeACHFormData }
    >({
      query: ({ paymentMethodId, values }) => ({
        url: `/stripe_ach_payment_methods/${paymentMethodId}/verify/`,
        method: "POST",
        data: values,
      }),
      invalidatesTags: (_result, error) => (error ? [] : ["PaymentMethods"]),
    }),
    getPaymentTransaction: build.query<
      { status: EnumType<typeof ENUMS.PaymentStatus> },
      number
    >({
      query: (id) => ({
        url: `/payment_transactions/${id}/`,
        method: "GET",
      }),
    }),
    createStripeCCPaymentMethod: build.mutation<
      StripeCreditCardPayment,
      CreateCCPaymentMethodPayload
    >({
      query: (data) => ({
        url: "/stripe_credit_cards/",
        method: "POST",
        data,
      }),
      invalidatesTags: (_result, error) => (error ? [] : ["PaymentMethods"]),
    }),
    createStripeACHPaymentMethod: build.mutation<
      StripeACHPayment,
      CreateStripeACHPaymentMethodParams
    >({
      query: ({ in_offer_flow = false, data }) => ({
        url: "/stripe_ach_payment_methods/",
        method: "POST",
        params: { in_offer_flow: in_offer_flow ? "true" : undefined },
        data,
      }),
      invalidatesTags: (_result, error) => (error ? [] : ["PaymentMethods"]),
    }),
    payInvoices: build.mutation<PaymentTransaction, PayInvoicesPayload>({
      query: (data) => ({
        url: "/pay/",
        method: "POST",
        data,
      }),
      invalidatesTags: ["EmployerInvoicesIds"],
    }),
  }),
});

export const {
  useGetPaymentMethodsQuery,
  useDeletePaymentMethodMutation,
  useSetAsDefaultPaymentMethodMutation,
  useVerifyStripeACHPaymentMethodMutation,
  useLazyGetPaymentTransactionQuery,
  useCreateStripeCCPaymentMethodMutation,
  useCreateStripeACHPaymentMethodMutation,
  usePayInvoicesMutation,
} = paymentsApi;
