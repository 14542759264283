import type { TypedWrappedFieldProps } from "redux-form";

import { useCreateOrEditFormWarnings } from "../../hooks/create-or-edit-post-form-warnings";

import { WarningMessage } from "./warning-message";

type CreateOrEditPostWarningSectionProps = TypedWrappedFieldProps & {
  onCloseWarning: () => void;
};

export const CreateOrEditPostWarningSection = ({
  meta,
  onCloseWarning,
}: CreateOrEditPostWarningSectionProps) => {
  const { warningMessage } = useCreateOrEditFormWarnings({ meta });

  return <WarningMessage message={warningMessage} onClose={onCloseWarning} />;
};
