import type { SchedulingFormData } from "./index";

export const calendarLinkValidator = (values: SchedulingFormData) => {
  const errors: any = {};
  const { calendar_link } = values;

  const urlPattern = new RegExp(
    "^((https?):\\/\\/)" + // protocol
      "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.?)+[a-zA-Z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ipv4 address
      "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-zA-Z\\d_]*)?$",
    "i", // fragment locator
  );

  if (!calendar_link) {
    errors.calendar_link = "Link field empty. Please input a scheduling link.";
  } else if (!urlPattern.test(calendar_link)) {
    errors.calendar_link = "Please input a valid link.";
  }

  return errors;
};
