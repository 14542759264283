import { useCallback, useEffect } from "react";

import { Snackbar } from "@js/components/snackbar";

import {
  useGetFreelancerReferralsQuery,
  useGetFreelancerReferralsSummaryQuery,
} from "../../api";
import { getReferralParams } from "../../helpers";
import { useTalentReferralsFilters } from "../use-talent-referrals-filters";

export const useTalentReferrals = () => {
  const { filters, setCurrentPage } = useTalentReferralsFilters();

  const {
    data: summary,
    isLoading: isLoadingSummary,
    error: fetchingSummaryError,
  } = useGetFreelancerReferralsSummaryQuery();
  const {
    data: referrals,
    isFetching: isFetchingReferrals,
    error: isReferralsError,
  } = useGetFreelancerReferralsQuery(filters);

  const nextPage = referrals?.next ? getReferralParams(referrals?.next) : null;
  const error = isReferralsError || fetchingSummaryError;

  useEffect(() => {
    if (error) {
      Snackbar.error("Failed to fetch referrals");
    }
  }, [error]);

  const fetchMore = useCallback(() => {
    if (nextPage) {
      setCurrentPage(nextPage);
    }
  }, [nextPage, setCurrentPage]);

  return {
    referrals,
    fetchMore,
    summary,
    isLoadingSummary,
    isFetchingReferrals,
    filters,
  };
};
