import { PaymentMethodFailedTopBarView } from "./payment-method-failed-top-bar-view";
import { usePaymentMethodFailedTopBar } from "./use-payment-method-failed-top-bar";

export const PaymentMethodFailedTopBar = (): JSX.Element | null => {
  const { showTopBar, dismissBar, editBillingInformation } =
    usePaymentMethodFailedTopBar();

  if (!showTopBar) return null;

  return (
    <PaymentMethodFailedTopBarView
      onEditBillingInformationClick={editBillingInformation}
      onCloseClick={dismissBar}
    />
  );
};
