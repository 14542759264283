import {
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import type { VirtuosoHandle } from "react-virtuoso";

import {
  getIndexToScrollTo,
  resetIndexToScrollTo,
} from "../../components/virtualized-job-bid-list/rendered-item-index";

type UseJobBidListScrollProps = {
  ref: RefObject<VirtuosoHandle>;
  count: number;
};

export const useJobBidListScroll = ({
  ref,
  count,
}: UseJobBidListScrollProps) => {
  const [params] = useSearchParams();
  const page = useMemo(() => parseInt(params.get("page") ?? "0"), [params]);
  const previousPageRef = useRef(page);
  const { indexToScroll, resetIndexToScroll } = useIndexToScroll();

  useEffect(() => {
    if (!count || !ref.current) {
      return;
    }

    const hasPageChanged = page !== previousPageRef.current;
    if (hasPageChanged) {
      requestAnimationFrame(() => {
        if (!ref.current) {
          return;
        }
        ref.current.scrollToIndex({
          index: 0,
          behavior: "smooth",
          offset: -500,
        });
      });

      resetIndexToScroll();
      previousPageRef.current = page;
      return;
    }

    if (!indexToScroll) {
      return;
    }

    ref.current.scrollToIndex({
      index: indexToScroll,
      align: "center",
      behavior: "auto",
    });

    resetIndexToScroll();
  }, [count, indexToScroll, page, previousPageRef, resetIndexToScroll, ref]);
};

const useIndexToScroll = () => {
  const [indexToScroll, setIndexToScroll] = useState(getIndexToScrollTo);

  const resetIndexToScroll = useCallback(() => {
    resetIndexToScrollTo();
    setIndexToScroll(undefined);
  }, []);

  return { indexToScroll, resetIndexToScroll };
};
