import { useState } from "react";

import type { UniversalTabValue } from "../constants";
import { useUniversalSearchContext } from "../context/universal-search-context";

export const useUniversalSearchModalTab = () => {
  const { initialTab } = useUniversalSearchContext();
  const [currentTab, setCurrentTab] = useState(initialTab);

  const handleSetCurrentTab = (tab: UniversalTabValue) => {
    setCurrentTab(tab);
  };
  return {
    currentTab,
    changeCurrentTab: handleSetCurrentTab,
  };
};
