import React from "react";
import type { WrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { TextField } from "@js/forms/fields";
import { isError } from "@js/forms/utils";

import { FieldSectionPanelWithImportance } from "../field-section-panel";

export const CalendarField = (field: WrappedFieldProps) => {
  const { error } = field.meta;
  const errorToDisplay = Array.isArray(error)
    ? error.map((err) => (
        <Typography component="p" error color="negative" key={err}>
          {err}
        </Typography>
      ))
    : error;

  return (
    <FieldSectionPanelWithImportance
      title="Add your booking calendar URL"
      isError={isError(field)}
      fieldImportance="recommended"
      tooltipMessage={
        <>
          <Typography component="p" mb={1.5}>
            This makes it a lot easier and faster for Clients to schedule an
            interview with you.{" "}
            <Typography
              component="link"
              color="white"
              href="https://calendly.com/"
              target="_blank"
              rel="noopener noreferrer"
              RouterLink={RouterLink}
            >
              Calendly
            </Typography>{" "}
            is a popular choice!
          </Typography>
          <Typography component="p">
            We also prioritize showing applications with calendar URLs to
            Clients first.
          </Typography>
        </>
      }
    >
      <Field
        variant="outlined"
        id="calendar_url"
        hideError
        name="calendar_url"
        placeholder="Enter calendar URL"
        component={TextField}
      />
      {errorToDisplay}
    </FieldSectionPanelWithImportance>
  );
};
