import { createFormInstance } from "@js/forms/components";

import type { OnboardingOrganizationFields } from "./schema";

const ONBOARDING_ORGANIZATION_FORM = "onboarding-organization-form";

export const OnboardingOrganizationFormInstance = createFormInstance<
  OnboardingOrganizationFields,
  unknown
>(ONBOARDING_ORGANIZATION_FORM, {});
