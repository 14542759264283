import { useCallback, useEffect, useRef, useState } from "react";

import { dateDifference } from "@js/utils";

// Really simple hook which manages seconds only. Please consider some hook from npm if you need more features
export const useCountdown = (seconds = 3) => {
  const [start, setStartCountdown] = useState(false);
  const [countdown, setCountDown] = useState(seconds);
  const [inactiveLastFocusTime, setInactiveLastFocusTime] = useState(
    new Date(),
  );
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  const startCountdown = useCallback(() => setStartCountdown(true), []);

  const onPageBlur = useCallback(() => {
    setInactiveLastFocusTime(new Date());
  }, []);

  const synchronizeCountdown = useCallback(() => {
    if (start) {
      const inactiveTime = Math.ceil(
        dateDifference(new Date(), inactiveLastFocusTime, "s"),
      );

      setCountDown((prevCountdown) => {
        const activeInactiveTimeDifference = prevCountdown - inactiveTime;

        return activeInactiveTimeDifference > 0
          ? activeInactiveTimeDifference
          : 0;
      });
    }
  }, [inactiveLastFocusTime, start]);

  useEffect(() => {
    window.addEventListener("blur", onPageBlur);
    window.addEventListener("focus", synchronizeCountdown);

    return () => {
      window.removeEventListener("blur", onPageBlur);
      window.removeEventListener("focus", synchronizeCountdown);
    };
  }, [onPageBlur, synchronizeCountdown]);

  useEffect(() => {
    if (start) {
      interval.current = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [start]);

  useEffect(() => {
    if (countdown === 0 && interval.current) {
      clearInterval(interval.current);
    }
  }, [countdown]);

  return {
    startCountdown,
    countdown,
    countdownFinished: countdown === 0,
  };
};
