import { API } from "@js/api";
import type { NotificationSetting } from "@js/types/notifications";
import type { SessionItem } from "@js/types/settings";

export type NotificationsSettingsResponse = {
  general_notifications_settings: Array<NotificationSetting>;
};
type UpdateNotificationsSettingsParams = Array<NotificationSetting>;

const settingsApi = API.injectEndpoints({
  endpoints: (build) => ({
    getNotificationsSettings: build.query<NotificationsSettingsResponse, void>({
      query: () => ({
        url: "/notification_settings/",
        method: "GET",
      }),
    }),
    updateNotificationsSettings: build.mutation<
      NotificationsSettingsResponse,
      UpdateNotificationsSettingsParams
    >({
      query: (data) => ({
        url: "/notification_settings/",
        method: "PATCH",
        data: {
          general_notifications_settings: data,
        },
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedNotifications } = await queryFulfilled;

          dispatch(
            settingsApi.util.updateQueryData(
              "getNotificationsSettings",
              undefined,
              (draft) => {
                return Object.assign(draft, updatedNotifications);
              },
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    savePassword: build.mutation<
      void,
      {
        current_password: string;
        password1: string;
        password2: string;
      }
    >({
      query: (payload) => ({
        url: `/account_settings/change_password/`,
        method: "POST",
        data: payload,
      }),
    }),
  }),
});

export const {
  useGetNotificationsSettingsQuery,
  useUpdateNotificationsSettingsMutation,
  useSavePasswordMutation,
} = settingsApi;

type GetActiveSessionsResponse = SessionItem[];

type DeleteActiveSessionId = number;

const sessionsApi = API.injectEndpoints({
  endpoints: (build) => ({
    getActiveSessions: build.query<GetActiveSessionsResponse, void>({
      query: () => ({
        url: "/user_sessions/",
        method: "GET",
      }),
      providesTags: ["ActiveSessions"],
    }),
    deleteActiveSession: build.mutation<void, DeleteActiveSessionId>({
      query: (id) => ({
        url: `/user_sessions/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ActiveSessions"],
    }),
  }),
});

export const { useGetActiveSessionsQuery, useDeleteActiveSessionMutation } =
  sessionsApi;
