import { MyWalletLazy } from "../../lazy";
import { FreelancerDashboardLayout } from "../layout";

import styles from "./styles.module.scss";

export const MyWalletPage = () => {
  return (
    <FreelancerDashboardLayout
      pageTitle="My Wallet"
      pageColor="var(--soft-green)"
      contentClassName={styles.myWalletPage}
    >
      <MyWalletLazy />
    </FreelancerDashboardLayout>
  );
};
