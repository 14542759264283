import React from "react";

import { Box, IconButton, Menu } from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";
import { BookmarkJobButton } from "@js/apps/bookmarks/components";
import { useAccountType } from "@js/apps/common/hooks";
import { ShareJobButton } from "@js/apps/jobs/components/share-job-button";
import type { MainJobListingJob } from "@js/types/jobs";

type JobItemActionsMenuSectionProps = {
  job: MainJobListingJob;
};

export const JobItemShareAndBookmarkActionMenu = ({
  job,
}: JobItemActionsMenuSectionProps) => {
  const { isFreelancer } = useAccountType();
  return (
    <Box>
      <Menu
        anchor={
          <IconButton
            aria-label="share and bookmark menu"
            aria-controls={`job-action-menu-old-${job.id}`}
            aria-haspopup="true"
            variant="transparent"
            size="medium"
            sx={{ mr: "-16px !important" }}
          >
            <MoreVertIcon style={{ color: "inherit" }} />
          </IconButton>
        }
        id={`job-action-menu-old-${job.id}`}
      >
        {isFreelancer && (
          <Menu.Item
            tooltipText="Bookmark this job"
            sx={{ justifyContent: "center" }}
            onClick={handleMenuItemClick}
          >
            <BookmarkJobButton job={job} />
          </Menu.Item>
        )}
        <Menu.Item tooltipText="Share this job" onClick={handleMenuItemClick}>
          <ShareJobButton job={job} />
        </Menu.Item>
      </Menu>
    </Box>
  );
};

const handleMenuItemClick = (e: React.MouseEvent<HTMLLIElement>) => {
  const eventTarget = e.target as HTMLLIElement;
  const closestButton = eventTarget.querySelector("button");
  if (closestButton) {
    closestButton.click();
  }
};
