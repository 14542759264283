import { useMemo, useState } from "react";
import { SubmissionError } from "redux-form";

import { fetchCurrentUser, fetchTimezones } from "@js/apps/auth/actions";
import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { useUser } from "@js/apps/common/hooks/use-user";
import {
  closePasswordConfirmationModal,
  openPasswordConfirmationModal,
} from "@js/apps/common/password-confirmation-modal/";
import { useGetManagedEmployerQuery } from "@js/apps/employer/hooks";
import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import { freelancerApi } from "@js/apps/freelancer/api";
import { useFreelancerProfile } from "@js/apps/freelancer/hooks";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { deepClone, typeGuard } from "@js/utils";

import { saveAccountInfo } from "../../actions";
import type { AccountInfoFormValues } from "../../forms/account-info";
import {
  closeAccountInfoOTPModal,
  openAccountInfoOTPModal,
  SettingsAccountInfoForm,
} from "../../forms/account-info";

type SettingsAccountInfoSectionProps = {
  setIsSaveDisabled: (arg: boolean) => void;
};

export const SettingsAccountInfoSection = ({
  setIsSaveDisabled,
}: SettingsAccountInfoSectionProps) => {
  const user = useUser();
  const timezones = useAppSelector((state) => state.auth.timezones);
  const freelancerProfile = useFreelancerProfile();
  const { refetch: refetchEmployerProfile } = useGetManagedEmployerQuery();

  const [currentLocation, setCurrentLocation] = useState(
    freelancerProfile?.location_full,
  );

  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(fetchTimezones());
  });

  const initialValues = useMemo(() => {
    if (!user) {
      return {};
    }

    return {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      address: user.address,
      timezone: user.timezone,
      referral_link: user.referral_link?.full_url,
      user_languages: user.user_languages.map((lang) => ({
        language_id: lang.language.id,
        skill_level: lang.skill_level,
      })),
      calendar_link: user.calendar_link,
      location_full: currentLocation,
    };
  }, [user, currentLocation]);

  const onSubmit = async (values: AccountInfoFormValues) => {
    const formValues: Partial<AccountInfoFormValues> = deepClone({
      ...values,
      password: values.code || undefined,
      email: values.email?.toLowerCase(),
    });

    const previousConfirmedEmail = initialValues.email;
    const displayAdditionalEmailChangeWarning =
      formValues.email !== previousConfirmedEmail?.toLowerCase();
    const freelancerId = user?.freelancer;

    delete formValues.referral_link;

    try {
      await saveAccountInfo(formValues);

      setCurrentLocation(formValues.location_full);

      const updatedUser = await dispatch(fetchCurrentUser());

      if (updatedUser.employer) {
        refetchEmployerProfile();
      } else if (
        updatedUser.account_type === ENUMS.AccountType.FREELANCER &&
        freelancerId
      ) {
        dispatch(fetchFreelancerProfile(freelancerId));
        dispatch(
          freelancerApi.util.invalidateTags(["FreelancerProfileCompletion"]),
        );
      }

      if (displayAdditionalEmailChangeWarning) {
        Snackbar.info(
          "To finish changing your email address, please verify new email.",
        );
      }
      closeAccountInfoOTPModal();
    } catch (error: unknown) {
      if (
        !error ||
        typeof error !== "object" ||
        !typeGuard<unknown, { errors: unknown }>(error, "errors") ||
        !error.errors ||
        typeof error.errors !== "object"
      ) {
        throw error;
      }

      if ("password_is_required" in error.errors) {
        openPasswordConfirmationModal();
      }

      if ("code_is_required" in error.errors) {
        openAccountInfoOTPModal();
      }
      throw new SubmissionError(error.errors);
    }
  };

  const onSubmitSuccess = (
    _result: unknown,
    _dispatch: unknown,
    { reset }: { reset?: () => void },
  ) => {
    Snackbar.success("Saved successfully");
    closePasswordConfirmationModal();
    reset?.();
  };

  return (
    <SettingsAccountInfoForm
      setIsSaveDisabled={setIsSaveDisabled}
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      initialValues={initialValues}
      timezones={timezones}
    />
  );
};
