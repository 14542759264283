import { createContext, type ReactNode, useContext } from "react";

import type { JobDraft, ManagedJob } from "@js/types/jobs";

export const JOB_FORM_SUBMIT_TYPE = {
  job: "job",
  job_dry_run: "job_dry_run",
  draft: "draft",
  draft_set_up: "draft_set_up",
  job_copy_draft_set_up: "job_copy_draft_set_up",
  ats_job_draft_set_up: "ats_job_draft_set_up",
} as const;

export type JobFormSubmitType = keyof typeof JOB_FORM_SUBMIT_TYPE;

type JobFormContextValue = {
  updateSubmitType?: (type: JobFormSubmitType) => void;
  jobDraft?: JobDraft;
  jobToEdit?: ManagedJob;
  updateJobFormWithFileDataError: string | null;
  isFetchingJobDataFromUploadedFile: boolean;
  updateJobFormWithFileDataFinished: boolean;
  uploadJobDescriptionFile: (file: File) => void;
  uploadingFileName: string | undefined;
  lastFormUpdateSourceFileId: number | undefined;
  resetUploadedFileState: () => void;
  uploadError: string | undefined;
  isUploading: boolean;
  setUploadDescriptionError: React.Dispatch<
    React.SetStateAction<string | null>
  >;
};

const JobFormContext = createContext<JobFormContextValue | null>(null);

type JobFormContextProviderProps = {
  children: ReactNode;
} & JobFormContextValue;

export const JobFormContextProvider = ({
  children,
  ...contextValue
}: JobFormContextProviderProps) => {
  return (
    <JobFormContext.Provider value={contextValue}>
      {children}
    </JobFormContext.Provider>
  );
};

export const useJobFormContext = () => {
  const context = useContext(JobFormContext);
  if (!context) {
    throw new Error("JobFormContext is missing.");
  }

  return context;
};

export const useUpdateSubmitType = () => {
  const { updateSubmitType } = useJobFormContext();
  if (!updateSubmitType) {
    throw new Error("Context is missing updateSubmitType");
  }

  return updateSubmitType;
};
