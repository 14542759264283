import { useParams } from "react-router-dom";

import { castToInteger } from "@js/utils/numbers";

const onlyNumbersRegexp = /^\d+$/;

export const useIdParam = (idParamName = "id") => {
  const params = useParams();
  const idParam = params[idParamName];

  const parsedId =
    !!idParam && onlyNumbersRegexp.test(idParam)
      ? castToInteger(idParam)
      : undefined;

  return parsedId;
};
