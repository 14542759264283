import { useMemo } from "react";

import { PageHead } from "@js/components/page-head";
import { PageTitle } from "@js/components/page-title";
import type { IPost } from "@js/types/give-and-get-help";
import { getTruncatedText } from "@js/utils/string";
import { removeTrailingSlash } from "@js/utils/url";

import { getPostPath } from "../../utils/common";

type PostMetaProps = {
  post: IPost | undefined;
};

export const PostMeta = ({ post }: PostMetaProps) => {
  const metaDescriptionContent = useMemo(
    () => getTruncatedText(post?.text ?? "", 150, 150),
    [post],
  );
  if (!post) {
    return <></>;
  }

  const postUrl = `${removeTrailingSlash(SETTINGS.BASE_URL || "")}${getPostPath(post)}`;

  return (
    <>
      <PageHead>
        <meta name="description" content={metaDescriptionContent} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={metaDescriptionContent} />
        <meta
          property="og:image"
          content={SETTINGS.STATIC_URL + SETTINGS.OG_TAG_DEFAULT_IMAGE}
        />
        <link rel="canonical" href={postUrl} />
      </PageHead>
      <PageTitle>{post.title}</PageTitle>
    </>
  );
};
