import React, { useContext, useMemo } from "react";

import type { Job } from "@js/types/jobs";

const JobActionBarContext = React.createContext<{
  job: Job | null;
}>({
  job: null,
});

type JobActionBarProviderProps = {
  job: Job;
  children: React.ReactNode;
};

export const JobActionBarProvider = ({
  job,
  children,
}: JobActionBarProviderProps) => {
  const value = useMemo(
    () => ({
      job,
    }),
    [job],
  );

  return (
    <JobActionBarContext.Provider value={value}>
      {children}
    </JobActionBarContext.Provider>
  );
};

export const useJobActionBarContext = () => {
  const value = useContext(JobActionBarContext);

  if (!value) {
    throw new Error("There is no JobActionBarProvider");
  }

  return value;
};
