import { useEffect, useState } from "react";

import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import {
  accordionContainerStyle,
  BOOST_ACCORDION_CONTENT,
} from "@js/apps/jobs/components/application-onramp-modal/constants";
import { BasicAccordion } from "@js/apps/on-ramp/components/onramp-modal/accordion";
import { LoadingOverlay } from "@js/apps/onboarding/views/create-account-page/loading-overlay";

import { ApplicationBoostModalLeft } from "./boost-modal-left-content";
import { ApplicationBoostModalRight } from "./boost-modal-right-content";

import styles from "./styles.module.scss";

export type ApplicationBoostModalProps = {
  onSuccessPurchaseBoost?: () => void;
};

const withBtrstAccData = [
  BOOST_ACCORDION_CONTENT[0],
  BOOST_ACCORDION_CONTENT[1],
];

export const ApplicationBoostModal = ({
  onSuccessPurchaseBoost,
}: ApplicationBoostModalProps) => {
  const [isloadingModal, setIsloadingModal] = useState(true);
  const isMobile = useMediaQuery("sm");

  useEffect(() => {
    setTimeout(() => setIsloadingModal(false), 100);
  }, []);

  if (isloadingModal) {
    return (
      <div>
        <LoadingOverlay loaderProps={{ size: 64 }} show={isloadingModal} />
      </div>
    );
  }

  const modalLeftContent = () => (
    <>
      <Typography
        component="h1"
        variant="title"
        size={isMobile ? "small" : "medium"}
        fontWeight={400}
        mb={isMobile ? 1 : 4}
      >
        Boost 3 job applications
      </Typography>
      <Typography component="p" size={"medium"} fontWeight={400} mb={2}>
        Get to the top of the applicant list to increase your chances of getting
        hired.
      </Typography>
      <Box sx={accordionContainerStyle}>
        <BasicAccordion options={withBtrstAccData} />
      </Box>
    </>
  );

  return (
    <Box className={styles.boxmodal}>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12} md={5}>
          <ApplicationBoostModalLeft>
            {modalLeftContent()}
          </ApplicationBoostModalLeft>
        </Grid>
        <Grid item xs={12} md={7}>
          <ApplicationBoostModalRight
            onSuccessPurchaseBoost={onSuccessPurchaseBoost}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
