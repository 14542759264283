import {
  FRONTEND_STORAGE_KEYS,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { useShowCompleteProfileTopBar } from "@js/apps/dashboard/hooks/use-show-complete-profile-top-bar";
import { openProfileCompletionSequenceModal } from "@js/apps/freelancer/components/profile-completion-sequence/profile-completion-sequence";

export const useCompleteProfileTopBar = () => {
  const [setStorageValue] = useSetStorageValueMutation();
  const { showCompleteProfileTopBar } = useShowCompleteProfileTopBar();

  const dismissBar = () => {
    const currentDate = new Date().toUTCString();
    return setStorageValue({
      key: FRONTEND_STORAGE_KEYS.complete_profile_top_bar_dismissed_at,
      value: currentDate,
    });
  };

  const completeProfile = () => {
    openProfileCompletionSequenceModal();
  };

  return {
    dismissBar,
    showTopBar: showCompleteProfileTopBar,
    completeProfile,
  };
};
