import { useRef } from "react";

export const useOnce = (callback: () => void) => {
  const wasCalled = useRef(false);
  if (wasCalled.current) {
    return;
  }

  wasCalled.current = true;

  callback();
};
