import type { ReactNode } from "react";
import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";

import styles from "./feed-hashtags-header-container.module.scss";

type FeedHashtagHeaderProps = { children: ReactNode };

export const FeedHashtagsHeaderContainer = ({
  children,
}: FeedHashtagHeaderProps) => {
  return (
    <Box component="header" sx={{ mb: 4 }} className={styles.feedHashtagHeader}>
      {children}
    </Box>
  );
};
