import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import type { BYOTFormValues } from "@js/apps/byot/types";
import { MakeOfferMessageBox } from "@js/apps/jobs/apps/bids/components/make-offer-message/box";
import { MessageInput } from "@js/apps/jobs/apps/bids/components/make-offer-message/input";
import modalStyle from "@js/apps/jobs/apps/bids/components/make-offer-message/style.module.scss";
import { Modal } from "@js/components/modal";
import { pluralize } from "@js/utils";
export const MessageModalInstance = Modal("message-modal-instance", {
  disablePortal: true,
});

export const openMessageModalInstance = () => {
  MessageModalInstance.open({
    className: modalStyle.makeOfferMessageModal,
    padding: false,
    closeButton: true,
    closeButtonProps: {
      style: { marginRight: "-16px", marginTop: "-14px" },
    },
  });
};

type MessageModalContentProps = {
  numberOfOffers: number | undefined;
  control: Control<BYOTFormValues>;
  isSubmitting: boolean;
};

export const MessageModalContent = ({
  numberOfOffers,
  control,
  isSubmitting,
}: MessageModalContentProps) => {
  return (
    <MakeOfferMessageBox>
      <Typography component="h2" variant="label">
        Send {numberOfOffers} offer
        {pluralize(numberOfOffers || 0)} to Talent
      </Typography>
      <Typography component="p" size="small" mb={2}>
        ✍️ Personalize your message below with any special instructions!
      </Typography>
      <Controller
        name="note_to_talent"
        control={control}
        rules={{
          maxLength: {
            value: 600,
            message: "Must be 600 characters or less",
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <MessageInput
              value={value}
              onChange={onChange}
              displayError={!!error?.message}
              error={error?.message}
              minRows={4}
            />
          );
        }}
      />
      <Box mt={2}>
        <Button
          type="submit"
          disabled={isSubmitting}
          shape="squared"
          variant="medium-green"
        >
          <Typography component="span" variant="label" size="small">
            Send offer{pluralize(numberOfOffers || 0)}
          </Typography>
        </Button>
      </Box>
    </MakeOfferMessageBox>
  );
};
