import type { ReactNode } from "react";
import React from "react";
import DOMPurify from "dompurify";
import { isNumber } from "underscore";

import { deepClone } from "../data";
import { round } from "../numbers";
import { assertUnreachable } from "../typescript";

export const capitalizeEachFirstLetter = (str: string): string => {
  return str
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export const capitalize = (str: string): Capitalize<string> => {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<string>;
};

export const decamelize = (str: string, separator?: string) => {
  const _separator = typeof separator === "undefined" ? "_" : separator;

  return str
    .replace(/([a-z\d])([A-Z])/g, "$1" + _separator + "$2")
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + _separator + "$2")
    .toLowerCase();
};

export function pluralize(
  num: number,
  options?: { zeroPlural: boolean },
  customPluralNoun?: string,
) {
  if (num > 1) {
    return customPluralNoun || "s";
  }

  if (options && num === 0 && options.zeroPlural) {
    return customPluralNoun || "s";
  }

  return "";
}

export const getTextCount = (dirtyText = "") => {
  let text = DOMPurify.sanitize(dirtyText, { ALLOWED_TAGS: [] });
  text = text.replace(/&nbsp;/g, " ");

  return text.trim().length;
};

export const splitByComma = (string: string) =>
  string.split(",").filter((value) => !!value && value !== ",");

export const stripTrailingSlash = (str: string): string => {
  return str.endsWith("/") ? str.slice(0, -1) : str;
};

export const stripLeadingSlash = (str: string): string => {
  return str.startsWith("/") ? str.slice(1) : str;
};

export const stripSlashes = (str: string) =>
  stripTrailingSlash(stripLeadingSlash(str));

export const arrayToCommasString = (array: (string | number)[] = []) => {
  if (!array.length) return "";
  const input = deepClone(array);

  if (input.length === 1) return input[0];

  const last = input.pop();
  const result = input.join(", ") + " and " + last;

  return result;
};

export const normalizeTaxonomyName = (name: string) =>
  name.trim().toLowerCase();

export const isEqualTaxonomyName = (name1: string, name2: string) =>
  normalizeTaxonomyName(name1) === normalizeTaxonomyName(name2);

// Credits: https://stackoverflow.com/a/18650828
export const formatBytes = (bytes: number, decimals: number) => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / k ** i).toFixed(dm)) + " " + sizes[i];
};

export const getAddressString = ({
  city,
  state,
  country,
}: {
  city?: string | null;
  state?: string | null;
  country?: string | null;
}) => {
  return [city, state, country].filter(Boolean).join(", ");
};

export const getCursorText = (
  page: number,
  startAtOne: boolean,
  itemsCount: number,
  total: number,
  perPage: number,
) =>
  page === (startAtOne ? 1 : 0)
    ? Math.min(itemsCount, perPage)
    : `${(startAtOne ? page - 1 : page) * perPage + 1} -
    ${Math.min(((startAtOne ? page - 1 : page) + 1) * perPage, total)}`;

export const getExperienceString = (
  years:
    | number
    | EnumType<typeof ENUMS.ExperienceLevel>
    | EnumType<typeof ENUMS.JobExperienceLevel>
    | null
    | undefined,
) => {
  if (years === null || years === undefined) return "";
  if (isNumber(years)) return `${years} year${pluralize(years)}`;

  switch (years) {
    case ENUMS.ExperienceLevel.LESS_THREE_YEARS:
    case ENUMS.JobExperienceLevel.LESS_THREE_YEARS:
      return "1 - 3 years";
    case ENUMS.ExperienceLevel.THREE_FIVE_YEARS:
    case ENUMS.JobExperienceLevel.THREE_FIVE_YEARS:
      return "3 - 5 years";
    case ENUMS.ExperienceLevel.FIVE_EIGHT_YEARS:
      return "5 - 8 years";
    case ENUMS.JobExperienceLevel.FIVE_TEN_YEARS:
      return "5 - 10 years";
    case ENUMS.ExperienceLevel.EIGHT_OR_MORE_YEARS:
      return "8+ years";
    case ENUMS.JobExperienceLevel.TEN_OR_MORE_YEARS:
      return "10+ years";
    default:
      assertUnreachable(years);
      return "";
  }
};

export const replaceVarsToText = (
  text: string,
  dictionary: { [key: string]: string },
): string =>
  Object.entries(dictionary).reduce(
    (acc, [key, value]) => acc.split(`{${key}}`).join(value || ""),
    text,
  );

export const shortenValue = (value: string, max: number): string =>
  value.length > max ? value.slice(0, max) + "... " : value;

export const formatTokenAmount = (
  valueToFormat: string | number | undefined,
): string => {
  if (typeof valueToFormat === "undefined" || valueToFormat === "") {
    return "";
  }
  const _value = String(valueToFormat);

  const searchSymbol = ".";
  const indexOfDecimalSeparator = _value.indexOf(searchSymbol);

  if (indexOfDecimalSeparator < 0) {
    return Number(_value).toLocaleString("en-US");
  }

  const integerPart = _value.slice(0, indexOfDecimalSeparator);
  const decimalPart = _value.slice(indexOfDecimalSeparator, _value.length);

  const formattedInteger = Number(integerPart).toLocaleString("en-US");

  return formattedInteger + decimalPart;
};

export const calculateContentLengthWithNewlineWeight = (
  content: string,
  newLineLength = 50,
) => {
  return content
    .split("\n")
    .reduce((acc, line) => acc + line.length + newLineLength, -newLineLength);
};

export const truncateContentWithNewlineWeight = (
  content: string,
  limit: number,
  newLineLength = 50,
) => {
  let currentLength = 0;
  let processedContent = "";

  for (let i = 0; i < content.length; i++) {
    const char = content[i];
    currentLength += char === "\n" ? newLineLength : 1;

    if (currentLength > limit) {
      break;
    }

    processedContent += char;
  }

  return processedContent;
};

export const truncateBalanceToDisplay = (
  balanceToDisplay: string,
  maxDigits = 10,
) => {
  const negativeSign = /-/;
  let commaOrDot = /,|\./g;

  const justTheDigits = balanceToDisplay
    .replace(negativeSign, "")
    .replace(commaOrDot, "");

  const numberOfDigits = justTheDigits.length;

  if (numberOfDigits <= maxDigits) {
    return balanceToDisplay;
  }

  let currentNumberOfDigits = numberOfDigits;
  let truncatedBalanceToDisplay = balanceToDisplay;

  // remove global flag
  commaOrDot = new RegExp(commaOrDot, "");

  while (currentNumberOfDigits > maxDigits) {
    const lastCharacter =
      truncatedBalanceToDisplay[truncatedBalanceToDisplay.length - 1];

    if (commaOrDot.test(lastCharacter)) {
      truncatedBalanceToDisplay = truncatedBalanceToDisplay.slice(0, -1);
      continue;
    }

    truncatedBalanceToDisplay = truncatedBalanceToDisplay.slice(0, -1);
    currentNumberOfDigits -= 1;
  }

  return commaOrDot.test(
    truncatedBalanceToDisplay[truncatedBalanceToDisplay.length - 1],
  )
    ? truncatedBalanceToDisplay.slice(0, -1) + "..."
    : truncatedBalanceToDisplay + "...";
};

/** Rounds balance to 0.01 precision, formats thousands with ',' separator and truncates it to 10 numbers */
export const roundBalance = (balance?: string | number) => {
  if (balance === undefined) return "...";
  return truncateBalanceToDisplay(formatTokenAmount(round(balance, 0.01)));
};

export const formatErrorForSnackbar = (
  message: ReactNode | object | undefined,
) => {
  if (!message) {
    return;
  }

  if (React.isValidElement(message)) return message;
  if (typeof message === "string") return message;
  if (typeof message === "object")
    return Object.values(message).flat(1).join(" ");
};

// converts seconds to "MM minutes, SS seconds", without leading zeros
export const formatSeconds = (timeInSeconds?: number): string | undefined => {
  if (!timeInSeconds) return;
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  const minutesString = `${minutes} minute${pluralize(minutes, {
    zeroPlural: true,
  })}`;
  const secondsString = `${seconds} second${pluralize(seconds, {
    zeroPlural: true,
  })}`;
  return `${minutesString}, ${secondsString}`;
};

export const isNumeric = (num: any) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num as number);

export const getLabelFromPath = (path: string) => {
  const pathParts = path.split("/")?.filter(Boolean);
  const lastPathPart = pathParts[pathParts.length - 1];
  if (!lastPathPart) {
    return "";
  }

  return capitalizeEachFirstLetter(lastPathPart.split("_")?.join(" "));
};

type GetTruncatedTextType = (
  text: string,
  minLengthToTruncate?: number,
  maxLengthOfResult?: number,
) => string;

export const getTruncatedText: GetTruncatedTextType = (
  text,
  minLengthToTruncate = 190,
  maxLengthOfResult = 210,
) => {
  if (text.length < minLengthToTruncate) return text;

  let result = "";

  for (const word of text.split(" ")) {
    const nextResult = `${result} ${word}`;
    if (nextResult.length > maxLengthOfResult) {
      if (result.length < minLengthToTruncate) {
        result = nextResult.slice(0, maxLengthOfResult);
      }
      break;
    }
    result = nextResult;
  }
  return result.trim();
};

export const removeHTMLTags = (text: string): string => {
  return text.replace(/(<([^>]+)>)/gi, "");
};

export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};
