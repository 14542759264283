import React from "react";

import type { TypographyProps } from "@hexocean/braintrust-ui-components";
import { Box, Tooltip, Typography } from "@hexocean/braintrust-ui-components";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";
import { formatTokenAmount, roundBalance } from "@js/utils";

export type MyWalletBalanceProps = {
  balance: string | number;
  currencyName?: string;
  textStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  hideCurrency?: boolean;
  variant?: TypographyProps["variant"];
  size?: TypographyProps["size"];
  className?: string;
  fontWeight?: TypographyProps["fontWeight"];
  iconStyle?: React.CSSProperties;
};

export const MyWalletBalance = ({
  balance,
  currencyName = "BTRST",
  textStyle = {},
  variant = "title",
  size,
  hideCurrency = false,
  style,
  className,
  fontWeight = 500,
  iconStyle = {},
}: MyWalletBalanceProps) => {
  let balanceToDisplay = "0";

  if (balance) {
    balanceToDisplay = roundBalance(balance);
  }

  return (
    <Box
      display="flex"
      alignItems="baseline"
      style={style}
      className={className}
    >
      <Typography
        component="span"
        variant={variant}
        fontWeight={fontWeight}
        size={size}
        style={textStyle}
        className="available-balance-amount"
        noWrap
      >
        <BraintrustIcon
          style={{
            marginRight: 12,
            verticalAlign: "middle",
            ...iconStyle,
          }}
        />
        <Tooltip
          style={{ cursor: "pointer", verticalAlign: "middle" }}
          title={formatTokenAmount(balance)}
        >
          <span>{balanceToDisplay}</span>
        </Tooltip>
      </Typography>

      {currencyName && !hideCurrency && (
        <Typography
          variant="label"
          component="span"
          size="small"
          color="primary"
          ml={1}
          mb={1}
        >
          {currencyName}
        </Typography>
      )}
    </Box>
  );
};
