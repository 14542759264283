import { z } from "zod";

const shareWithTeamMembersForbiddenEmailErrorSchema = z.object({
  data: z.object({ emails: z.object({ email: z.string() }) }),
});

const parseForbiddenShareWithTeamMembersEmailError = (
  error: unknown,
): string | undefined => {
  const forbiddenEmailErrorParseResult =
    shareWithTeamMembersForbiddenEmailErrorSchema.safeParse(error);
  if (!forbiddenEmailErrorParseResult.success) {
    return;
  }

  return forbiddenEmailErrorParseResult.data.data.emails.email;
};

const shareWithTeamMembersInvalidEmailErrorSchema = z.object({
  data: z.object({
    emails: z
      .array(z.union([z.array(z.string()), z.object({})]))
      .transform((value) => {
        const errorWithValidMessage = value.find(
          (errorArrayElement): errorArrayElement is string =>
            Array.isArray(errorArrayElement) &&
            errorArrayElement.length &&
            errorArrayElement[errorArrayElement.length - 1],
        );

        return errorWithValidMessage?.[0];
      }),
  }),
});

const parseInvalidShareWithTeamMembersEmailError = (
  error: unknown,
): string | undefined => {
  const invalidEmailParseResult =
    shareWithTeamMembersInvalidEmailErrorSchema.safeParse(error);
  if (!invalidEmailParseResult.success) {
    return;
  }

  return invalidEmailParseResult.data.data.emails;
};

export const parseShareWithTeamMembersError = (error: unknown) => {
  const forbiddenEmailParsedError =
    parseForbiddenShareWithTeamMembersEmailError(error);
  if (forbiddenEmailParsedError) {
    return forbiddenEmailParsedError;
  }

  const invalidEmailParsedError =
    parseInvalidShareWithTeamMembersEmailError(error);
  if (invalidEmailParsedError) {
    return invalidEmailParsedError;
  }

  return "Something went wrong!";
};

const updateJobSubscribersForbiddenEmailErrorSchema = z.object({
  data: z.object({ email: z.string() }),
});

const parseForbiddenJobSubscribersEmailError = (
  error: unknown,
): string | undefined => {
  const forbiddenEmailErrorParseResult =
    updateJobSubscribersForbiddenEmailErrorSchema.safeParse(error);
  if (!forbiddenEmailErrorParseResult.success) {
    return;
  }

  return forbiddenEmailErrorParseResult.data.data.email;
};

const updateJobSubscribersInvalidEmailErrorSchema = z.object({
  data: z
    .array(z.object({ email: z.coerce.string().optional() }))
    .transform(
      (value) =>
        value.find((emailObjectError) => !!emailObjectError.email)?.email,
    ),
});

const parseInvalidJobSubscribersEmailError = (
  error: unknown,
): string | undefined => {
  const invalidEmailParseResult =
    updateJobSubscribersInvalidEmailErrorSchema.safeParse(error);
  if (!invalidEmailParseResult.success) {
    return;
  }

  return invalidEmailParseResult.data.data;
};

export const parseUpdateJobSubscribersError = (error: unknown) => {
  const forbiddenEmailParsedError =
    parseForbiddenJobSubscribersEmailError(error);
  if (forbiddenEmailParsedError) {
    return forbiddenEmailParsedError;
  }

  const invalidEmailParsedError = parseInvalidJobSubscribersEmailError(error);
  if (invalidEmailParsedError) {
    return invalidEmailParsedError;
  }

  return "Something went wrong!";
};
