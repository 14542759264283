import { useCompleteProfileTopBar } from "./hooks/use-complete-profile-top-bar";
import { CompleteProfileTopBarComponent } from "./complete-profile-top-bar-component";

export const CompleteProfileTopBar = () => {
  const { showTopBar, dismissBar, completeProfile } =
    useCompleteProfileTopBar();

  return (
    <CompleteProfileTopBarComponent
      showTopBar={showTopBar}
      onCompleteProfileClick={completeProfile}
      onCloseClick={() => {
        dismissBar();
      }}
    />
  );
};
