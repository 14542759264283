import {
  autocompleteClasses,
  MUIAutocomplete,
  styled,
} from "@hexocean/braintrust-ui-components";

export const StyledSkillsAutocomplete = styled(MUIAutocomplete)({
  [`& .${autocompleteClasses.inputRoot}`]: {
    paddingLeft: "20px !important",
    padding: "8px 0 8px 8px",
    borderRadius: "100px",

    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--grey-4)",
    },

    "&.Mui-focused, &.Mui-active": {
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--dark-violet)",
        boxShadow: "none",
      },
    },
  },
}) as typeof MUIAutocomplete;
