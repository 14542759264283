import { Divider, List } from "@hexocean/braintrust-ui-components";
import type { SpaceUser } from "@js/types/spaces";

import { PeopleListItem } from "./people-list-item";

export type PeopleListProps = {
  members: SpaceUser[];
  spaceId: number;
};

export const PeopleList = ({ members, spaceId }: PeopleListProps) => {
  return (
    <>
      <Divider color="beige" />
      <List sx={{ p: 0 }}>
        {members.map((member) => (
          <PeopleListItem
            key={member.user.id}
            member={member}
            spaceId={spaceId}
          />
        ))}
      </List>
    </>
  );
};
