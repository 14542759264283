import type { NavigationType } from "react-router-dom";

import type { PostsLocation } from "../../context/post-location";

// we do not want to use local/session storage as we don't need to ...
// ... restore the scroll after page refresh
const renderedItemIndexByLocation: Partial<
  Record<PostsLocation, number | undefined>
> = {};

export const setFeedRenderedItemIndex = ({
  index,
  location,
}: {
  index?: number;
  location: PostsLocation;
}) => {
  renderedItemIndexByLocation[location] = index;
};

export const getFeedRenderedItemIndex = ({
  navigationType,
  location,
}: {
  navigationType: NavigationType;
  location: PostsLocation;
}): number | undefined => {
  if (navigationType !== "POP") {
    return;
  }

  return renderedItemIndexByLocation[location];
};
