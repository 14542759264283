import { Avatar, Box, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { EmployerReview } from "@js/types/employer";
import type { TalentReview } from "@js/types/reviews";

import { ReviewCardActionMenu } from "../review-card-action-menu";

type ReviewerCardDataProps = {
  reviewer: TalentReview["reviewer"] | EmployerReview["reviewer"];
  isMobile: boolean;
  id: number;
  isOwnProfile: boolean;
  profileId: number;
};

export const ReviewerCardData = ({
  reviewer,
  isMobile,
  id,
  isOwnProfile,
  profileId,
}: ReviewerCardDataProps) => {
  const { public_name, introduction_headline, title, profile_url } = reviewer;

  return (
    <div className="review-card__reviewer-data">
      <Avatar size="md" src={reviewer} />
      <div className="review-card__reviewer-data__name">
        <Typography
          mt={{ xs: 0, sm: 1 }}
          component="p"
          fontWeight={500}
          to={profile_url}
          RouterLink={RouterLink}
          noTextDecoration
        >
          {public_name}
        </Typography>
        <Typography component="p" size="small" multilineEllipsis={2}>
          {introduction_headline || title}
        </Typography>
      </div>
      {isMobile && (
        <Box ml="auto">
          <ReviewCardActionMenu
            id={id}
            isOwnProfile={isOwnProfile}
            profileId={profileId}
          />
        </Box>
      )}
    </div>
  );
};
