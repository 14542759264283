export const FETCH_SAVED_FILTERS_SUCCESS_JOB =
  "FETCH_SAVED_FILTERS_SUCCESS_JOB";
export const SAVE_FILTERS_SUCCESS_JOB = "SAVE_FILTERS_SUCCESS_JOB";
export const FETCH_SAVED_FILTERS_SUCCESS_TALENT =
  "FETCH_SAVED_FILTERS_SUCCESS_TALENT";
export const SAVE_FILTERS_SUCCESS_TALENT = "SAVE_FILTERS_SUCCESS_TALENT";
export const SET_SAVED_FILTER_NAME = "SET_SAVED_FILTER_NAME";
export const DELETE_SAVED_FILTERS_SUCCESS = "DELETE_SAVED_FILTERS_SUCCESS";
export const DELETE_SAVED_FILTERS = "DELETE_SAVED_FILTERS";
export const UPDATE_SAVED_FILTER = "UPDATE_SAVED_FILTER";
