import type { ReactNode } from "react";
import React from "react";

import type { StackProps } from "@hexocean/braintrust-ui-components";
import { Stack, Typography } from "@hexocean/braintrust-ui-components";
import { ScrollableContainer } from "@js/components/scrollable-container";

import { CommonTeamMembersModalLoader } from "./common-team-members-modal-loader";

type CommonTeamMembersModalListContainerProps = {
  children: ReactNode;
  isLoading: boolean;
} & Pick<StackProps, "sx">;

export const CommonTeamMembersModalListContainer = ({
  children,
  isLoading,
  sx,
}: CommonTeamMembersModalListContainerProps) => {
  return (
    <Stack
      sx={{
        overflow: "hidden",
        px: "0 !important", // override padding set by parent
        ...sx,
      }}
    >
      <Typography
        component="p"
        variant="label"
        size="small"
        mb="10px"
        sx={{ px: { xs: "18px", sm: 4 } }}
      >
        Team members
      </Typography>

      {isLoading && <CommonTeamMembersModalLoader />}
      {!isLoading && (
        <ScrollableContainer sx={{ px: { xs: "18px", sm: 4 } }}>
          {children}
        </ScrollableContainer>
      )}
    </Stack>
  );
};
