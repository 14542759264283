import { useCallback, useState } from "react";
import type { Dispatch } from "redux";
import type { FormAction, FormErrors } from "redux-form";
import { Fields, reduxForm, touch } from "redux-form";

import { Box } from "@hexocean/braintrust-ui-components";
import { Form } from "@js/forms/components";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { EmployerBidListBid } from "@js/types/jobs";

import { SendCalendarLink } from "./send-calendar-link";
import { SetupCalendarFields } from "./setup-calendar-fields";
import { calendarLinkValidator } from "./validator";

export type SchedulingFormCustomProps = {
  hasCalendarLink: boolean;
  freelancer: EmployerBidListBid["freelancer"];
};

export type SchedulingFormData = {
  calendar_link?: string;
  save_as_default: boolean;
  freelancer_id: number;
  same_link_for_all: boolean;
};

const SEND_SCHEDULING_LINK_FORM = "send-scheduling-link-form";

export const BidSendSchedulingLinkForm = reduxForm<
  SchedulingFormData,
  SchedulingFormCustomProps
>({
  form: SEND_SCHEDULING_LINK_FORM,
  validate: calendarLinkValidator,
})(({ error, submit, submitting, hasCalendarLink, freelancer }) => {
  const dispatch = useAppDispatch() as Dispatch<FormAction>;
  const [isConfirmingPage, setIsConfirmingPage] =
    useState<boolean>(hasCalendarLink);

  const formErrors: FormErrors<{ calendar_link?: string }> = useAppSelector(
    (state: any) => state.form[SEND_SCHEDULING_LINK_FORM].syncErrors,
  );

  const onConfirmCalendarLink = useCallback(() => {
    dispatch(touch(SEND_SCHEDULING_LINK_FORM, "calendar_link"));

    if (!formErrors?.calendar_link) {
      setIsConfirmingPage(true);
    }
  }, [dispatch, formErrors]);

  return (
    <Form
      onSubmit={submitting ? null : submit}
      error={error}
      onKeyPress={(evt) => {
        if (evt.key === "Enter") {
          evt.preventDefault();
        }
      }}
    >
      <Box display={isConfirmingPage ? "none" : "block"}>
        <Fields
          names={["calendar_link", "save_as_default"]}
          component={SetupCalendarFields}
          freelancer={freelancer}
          onConfirm={onConfirmCalendarLink}
        />
      </Box>
      <Box display={!isConfirmingPage ? "none" : "block"}>
        <Fields
          names={["calendar_link", "same_link_for_all"]}
          component={SendCalendarLink}
          freelancer={freelancer}
          onEditLink={() => setIsConfirmingPage(false)}
        />
      </Box>
    </Form>
  );
});
