import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { TOGGLE_HIDE_REQUEST_LOADER } from "@js/apps/common/action-types";
import { setPostsToShowPulseAnimation } from "@js/apps/common/actions";
import { useSubscribeFeed } from "@js/apps/give-and-get-help/hooks";
import { useSpacesInfinityPostLoading } from "@js/apps/spaces/hooks/use-spaces-infinity-post-loading";
import { useAppDispatch, useAppSelector } from "@js/hooks";

export const useDiscussionPost = () => {
  const { spaceId } = useParams();
  const {
    posts,
    hasMore,
    handleFetchingMore,
    loadingPosts,
    fetchingPosts,
    postsCount,
  } = useSpacesInfinityPostLoading({
    space: Number(spaceId),
  });

  const dispatch = useAppDispatch();
  const hideRequestLoader = useAppSelector(
    (state) => state.common.hideRequestLoader,
  );

  useEffect(() => {
    if (!hideRequestLoader) {
      dispatch({
        type: TOGGLE_HIDE_REQUEST_LOADER,
      });
    }

    return () => {
      dispatch({
        type: TOGGLE_HIDE_REQUEST_LOADER,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setPostsToShowPulseAnimation([]));
    };
  }, [dispatch]);

  useSubscribeFeed();

  return {
    loading: loadingPosts,
    posts,
    hasMore,
    handleFetchingMore,
    fetchingPosts,
    postsCount,
  };
};
