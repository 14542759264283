import { useRemoveHelpOfferMutation } from "@js/apps/give-and-get-help/api";
import { openDeleteOfferConfirmationModal } from "@js/apps/give-and-get-help/components/delete-offer-confirmation-modal";
import { Snackbar } from "@js/components/snackbar";

export const useRemoveHelpOffer = (
  offerId: number,
  offerReceiverName: string,
): (() => void) => {
  const [removeOffer] = useRemoveHelpOfferMutation();

  const handleRemove = () => {
    openDeleteOfferConfirmationModal({
      onConfirm: async () => {
        return removeOffer({ offerId })
          .unwrap()
          .then(() => Snackbar.success("Offer removed."))
          .catch(() => Snackbar.error("Failed to remove the offer."));
      },
      offerReceiverName,
    });
  };
  return handleRemove;
};
