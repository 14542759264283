import cs from "classnames";

import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  CloseIcon,
  EditPenIcon,
  FlowerIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { BudgetLabel } from "@js/apps/give-and-get-help/components/post-category-and-budget-badges";
import { Emoji } from "@js/components/emoji";
import type { HelpService } from "@js/types/give-and-get-help";

type ServiceCardProps = {
  service: HelpService;
  onEdit: () => void;
  onDelete: () => void;
};

export const ServiceCard = ({
  service,
  onEdit,
  onDelete,
}: ServiceCardProps) => {
  const isSmall = useMediaQuery("sm");

  return (
    <Box className="service-card">
      <ServiceCategoryIcon category={service.category} />
      <Box ml={isSmall ? 1 : 2} overflow="hidden" flex={1}>
        <Typography
          component="h3"
          variant="paragraph"
          size={isSmall ? "medium" : "large"}
          maxWidth={{
            xs: "155px",
            sm: "revert",
          }}
          ellipsis
        >
          {service.category.name}
        </Typography>
        <BudgetLabel budget={service.budget} />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ gap: isSmall ? 0.5 : 1.5 }}
      >
        <IconButton
          aria-label="Edit service"
          onClick={onEdit}
          variant="tertiary"
          size={isSmall ? "x-small" : "small"}
        >
          <EditPenIcon />
        </IconButton>
        <IconButton
          aria-label="Remove service"
          onClick={onDelete}
          variant="tertiary"
          size={isSmall ? "x-small" : "small"}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export const ServiceCategoryIcon = ({
  category,
  isProfileModuleVariant,
}: {
  category: HelpService["category"];
  isProfileModuleVariant?: boolean;
}) => {
  return (
    <Box
      className={cs("service-card__category-icon", {
        "service-card__category-icon--profile-module": isProfileModuleVariant,
      })}
    >
      <FlowerIcon
        style={{
          width: isProfileModuleVariant ? 48 : 64,
          height: isProfileModuleVariant ? 48 : 64,
          color: `var(${category.color})`,
        }}
      />

      <Box className="service-card__category-icon-emoji-wrapper">
        <Emoji
          emoji={category.emoji}
          className="service-card__category-icon-emoji"
          size={isProfileModuleVariant ? "21px" : "26px"}
        />
      </Box>
    </Box>
  );
};
