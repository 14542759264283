import { Box, Stack } from "@hexocean/braintrust-ui-components";
import { LimitedFreelancerLinks } from "@js/apps/jobs/apps/bids/components";
import type { EmployerBid } from "@js/types/jobs";

import { TalentAvatarAndPersonalDetails } from "../talent-avatar-and-personal-details";

type TalentInfoProps = {
  bid: EmployerBid;
};

export const TalentInfo = ({ bid }: TalentInfoProps) => {
  if (!bid) return null;

  return (
    <Stack
      direction="row"
      sx={{ gap: "4px 16px", alignItems: "center", flexWrap: "wrap" }}
    >
      <TalentAvatarAndPersonalDetails
        bid={bid}
        sx={{ mr: "auto" }}
        boldedName
      />
      <Box sx={{ m: { xs: "0 auto", sm: "0" } }}>
        <LimitedFreelancerLinks
          externalProfiles={bid.freelancer.external_profiles}
          IconButtonProps={{
            size: "small",
            variant: "white-grey",
          }}
        />
      </Box>
    </Stack>
  );
};
