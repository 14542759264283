import type { ReactNode } from "react";

import { Stack } from "@hexocean/braintrust-ui-components";

type ReferralsStatsSummaryContainerProps = { children: ReactNode };

export const ReferralsStatsSummaryContainer = ({
  children,
}: ReferralsStatsSummaryContainerProps) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        columnGap: 4,
        rowGap: 3,
      }}
    >
      {children}
    </Stack>
  );
};
