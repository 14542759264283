import { useCallback } from "react";

import { useAppDispatch } from "@js/hooks";
import type { SearchEventOption } from "@js/types/tracking";

import { setSearchEventQueryId } from "../../search-event-slice";

export const useResetSearchEventQueryId = () => {
  const dispatch = useAppDispatch();

  const resetSearchEventQueryId = useCallback(
    (option: SearchEventOption) => {
      dispatch(setSearchEventQueryId({ id: undefined, option }));
    },
    [dispatch],
  );

  return { resetSearchEventQueryId };
};
