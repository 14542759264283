import { Rating } from "@hexocean/braintrust-ui-components";

type ReviewCardRatingProps = {
  value: number;
};

export const ReviewCardRating = ({ value }: ReviewCardRatingProps) => {
  return (
    <Rating
      variant="stars"
      size="medium"
      iconStyle={{ height: "20px", marginRight: "-10px" }}
      containerProps={{
        lineHeight: "20px",
        marginRight: "8px",
        marginLeft: "-8px",
      }}
      color="orange"
      readOnly
      value={value}
    />
  );
};
