import { useInitializeInterviewQuestionGeneratorMutation } from "@js/apps/jobs/api";

export const useInitializeInterviewQuestion = () => {
  const [initializeInterviewQuestionGenerator] =
    useInitializeInterviewQuestionGeneratorMutation();

  const generateInterviewQuestion = () =>
    initializeInterviewQuestionGenerator().unwrap();

  return {
    generateInterviewQuestion,
  };
};
