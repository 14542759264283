import { SECTIONS_WITH_CHANGES_SAVED } from "@js/apps/jobs/apps/create-job/components/changes-saved-marker/constant";
import type { JobDraft } from "@js/types/jobs";

import * as actionTypes from "./action-types";

export const clearJobFormState = () => ({
  type: actionTypes.CLEAR_JOB_FORM_STATE,
});

export const setFieldsToDisplayChangesSavedMarker = (
  changedFields: Partial<JobDraft>,
) => {
  const keys = Object.keys(changedFields);

  return {
    type: actionTypes.SET_FIELDS_TO_DISPLAY_CHANGES_SAVED_MARKER,
    payload: keys.filter((field) =>
      SECTIONS_WITH_CHANGES_SAVED.some((section) => section === field),
    ),
  };
};

export const hideAllChangesSavedMarkers = () => ({
  type: actionTypes.SET_FIELDS_TO_DISPLAY_CHANGES_SAVED_MARKER,
  payload: [],
});
