import { type RoleFilter } from "@js/apps/common/components/filters/schemas/role-filter-schema";
import type { SkillsFilter } from "@js/apps/common/components/filters/schemas/skills-filter-schema";
import { createFiltersForm, FiltersManager } from "@js/apps/filters";

import type { SkillsParams } from "./skills-form";

export type RoleFormType = { role: RoleFilter; skills: SkillsFilter };
export type RoleParams = { role: RoleFilter };

export const defaultRole: RoleFormType = {
  role: [],
  skills: [],
};

export const defaultRoleParams: RoleParams & SkillsParams = {
  role: [],
  skills: [],
};

export const RoleForm = createFiltersForm<
  RoleFormType,
  RoleParams & SkillsParams
>(
  new FiltersManager({
    useAllURLParams: true,
    defaultFormValues: defaultRole,
    defaultParamsValues: defaultRoleParams,
  }),
);
