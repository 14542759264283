import { useEffect, useState } from "react";

import {
  areJobSubscriberInvitationsChanged,
  areJobSubscribersChanged,
} from "@js/apps/jobs/components/job-subscribers/job-subscribers-list/helpers";
import type {
  JobSubscriberData,
  JobSubscriberInvitationData,
} from "@js/types/jobs";
import { removeIds } from "@js/utils/data";

type WithOptionalId<T extends object> = T & { id?: number };

type UseEditedJobSubscribersParams = {
  jobSubscribers: WithOptionalId<JobSubscriberData>[] | undefined;
  jobSubscriberInvitations:
    | WithOptionalId<JobSubscriberInvitationData>[]
    | undefined;
};

export const useEditedJobSubscribers = ({
  jobSubscribers,
  jobSubscriberInvitations,
}: UseEditedJobSubscribersParams) => {
  const [editedJobSubscribers, setEditedJobSubscribers] = useState<
    JobSubscriberData[]
  >(removeIds(jobSubscribers));
  const [editedJobSubscriberInvitations, setEditedJobSubscriberInvitations] =
    useState<JobSubscriberInvitationData[]>(
      removeIds(jobSubscriberInvitations),
    );

  useEffect(() => {
    setEditedJobSubscribers(removeIds(jobSubscribers));
  }, [jobSubscribers]);

  useEffect(() => {
    setEditedJobSubscriberInvitations(removeIds(jobSubscriberInvitations));
  }, [jobSubscriberInvitations]);

  const toggleJobSubscriber = (jobSubscriberData: JobSubscriberData) => {
    setEditedJobSubscribers((previousSubscribers) => {
      const jobSubscriberIndex = previousSubscribers.findIndex(
        (jobSubscriber) => {
          return (
            jobSubscriber.team_member_id === jobSubscriberData.team_member_id
          );
        },
      );

      if (jobSubscriberIndex === -1) {
        return [...previousSubscribers, jobSubscriberData];
      }

      const previousSubscribersCopy = [...previousSubscribers];
      previousSubscribersCopy.splice(jobSubscriberIndex, 1);

      return previousSubscribersCopy;
    });
  };

  const toggleJobSubscriberInvitation = (
    jobSubscriberInvitationData: JobSubscriberInvitationData,
  ) => {
    setEditedJobSubscriberInvitations((previousSubscriberInvitations) => {
      const jobSubscriberInvitationIndex =
        previousSubscriberInvitations.findIndex((jobSubscriberInvitation) => {
          return (
            jobSubscriberInvitation.email === jobSubscriberInvitationData.email
          );
        });

      if (jobSubscriberInvitationIndex === -1) {
        return [...previousSubscriberInvitations, jobSubscriberInvitationData];
      }

      const previousSubscribersCopy = [...previousSubscriberInvitations];
      previousSubscribersCopy.splice(jobSubscriberInvitationIndex, 1);

      return previousSubscribersCopy;
    });
  };

  const hasChangedSubscribers =
    jobSubscribers &&
    areJobSubscribersChanged({
      jobSubscribers,
      editedJobSubscribers,
    });

  const hasChangedSubscriberInvitations =
    jobSubscriberInvitations &&
    areJobSubscriberInvitationsChanged({
      jobSubscriberInvitations,
      editedJobSubscriberInvitations,
    });

  return {
    editedJobSubscribers,
    hasChangedSubscribers,
    jobSubscriberInvitations,
    editedJobSubscriberInvitations,
    hasChangedSubscriberInvitations,
    toggleJobSubscriber,
    toggleJobSubscriberInvitation,
  };
};
