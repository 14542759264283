import type { ReactNode } from "react";

import { Badge } from "@hexocean/braintrust-ui-components";

type BadgeNotificationProps = {
  children: ReactNode;
  notificationLength: number;
};

export const BadgeNotification = ({
  children,
  notificationLength,
}: BadgeNotificationProps) => (
  <Badge
    sx={{
      "& .MuiBadge-badge": {
        backgroundColor: "var(--negative-2)",
        color: "var(--white)",
      },
    }}
    badgeContent={notificationLength}
  >
    {children}
  </Badge>
);
