import cs from "classnames";

import { Stack } from "@hexocean/braintrust-ui-components";
import {
  EarthIcon,
  PeopleIcon,
  ReplyIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { capitalizeEachFirstLetter, pluralize } from "@js/utils";

import { DetailItem } from "./detail-item";

import styles from "../style.module.scss";

type SpaceCardDetailsProps = {
  visibility: string;
  memberCount: number;
  postsLastWeek: number;
  showMobileVariant?: boolean;
};

export const SpaceCardDetails = ({
  visibility,
  memberCount,
  postsLastWeek,
  showMobileVariant,
}: SpaceCardDetailsProps) => {
  return (
    <Stack className={styles.cardDetails} justifyContent="flex-start">
      <DetailItem
        icon={<EarthIcon className={cs(styles.icon)} />}
        figure={null}
        label={capitalizeEachFirstLetter(visibility)}
        showMobileVariant={showMobileVariant}
      />
      <DetailItem
        icon={<PeopleIcon className={cs(styles.icon, styles.iconPeople)} />}
        figure={memberCount || 0}
        label={`member${pluralize(memberCount, { zeroPlural: true })}`}
        showMobileVariant={showMobileVariant}
      />
      <DetailItem
        icon={<ReplyIcon className={cs(styles.icon)} />}
        figure={postsLastWeek || 0}
        label="new posts last week"
        showMobileVariant={showMobileVariant}
      />
    </Stack>
  );
};
