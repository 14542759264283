import { useCallback } from "react";
import PostSignUpGraphic from "@static/give-get-help/post-signup-prompt-graphic.svg";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { openSignUpModal } from "@js/apps/onboarding/components/sign-up-modal";

import styles from "./styles.module.scss";

const headline = {
  hasComments: "Sign up to read every answer",
  hasNoComments: "Sign up to reply",
};

export const PostSignupPrompt: React.FC<{ visibleCommentsCount: number }> = ({
  visibleCommentsCount,
}) => {
  const handleSignUp = useCallback(() => {
    openSignUpModal();
  }, []);

  return (
    <Box
      className={styles.prompt}
      style={{ position: visibleCommentsCount === 4 ? "absolute" : "relative" }}
      sx={{ pt: visibleCommentsCount === 0 ? { xs: 2, sm: 5 } : undefined }}
    >
      <PostSignUpGraphic
        style={{ fill: "none", height: "160px", width: "250px" }}
        viewBox="10 8 255 170"
      />

      <Box>
        <Typography component="h2" variant="title" size="small">
          {!!visibleCommentsCount
            ? headline.hasComments
            : headline.hasNoComments}
        </Typography>

        <Typography component="p" variant="paragraph">
          Share your knowledge on Braintrust
        </Typography>

        <Button sx={{ mt: 2 }} onClick={handleSignUp} variant="primary">
          Sign up
        </Button>
      </Box>
    </Box>
  );
};
