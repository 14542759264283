import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type { Participant } from "@js/types/messenger";

import { useCancelReportsMutation } from "../../api";

export const CancelReportModalContent = ({ user }: { user: Participant }) => {
  const [cancelReports] = useCancelReportsMutation();

  const handleCancelReports = async () => {
    await cancelReports(user.id)
      .unwrap()
      .then(() => Snackbar.success("Reports cancelled"))
      .catch(() => Snackbar.error("Something went wrong"))
      .finally(() => ModalInstance.close());
  };

  return (
    <Box display="flex" flexDirection="column">
      <Typography component="h1" size="large" fontWeight={500} mb={4}>
        Cancel report
      </Typography>
      <Typography component="p" size="large" mb={2}>
        Are you sure you want to cancel {user.public_name}'s report?
      </Typography>
      <Box ml="auto">
        <Button
          variant="destructive"
          shape="squared"
          onClick={handleCancelReports}
        >
          Cancel report
        </Button>
      </Box>
    </Box>
  );
};

export const openCancelReportModal = (user: Participant) => {
  ModalInstance.open({
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
    },
    children: <CancelReportModalContent user={user} />,
  });
};
