import { Suspense } from "react";

import { Box, Loader } from "@hexocean/braintrust-ui-components";

import { FreelancerDashboardInvitesLazy } from "../lazy";

import { FreelancerDashboardLayout } from "./layout";

export const DashboardMyInvitesPage = () => {
  return (
    <FreelancerDashboardLayout pageTitle="Invites">
      <Box className="listings-content-wrapper">
        <Suspense fallback={<Loader centered />}>
          <FreelancerDashboardInvitesLazy />
        </Suspense>
      </Box>
    </FreelancerDashboardLayout>
  );
};
