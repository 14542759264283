import type { ReactNode } from "react";

import { Box } from "@hexocean/braintrust-ui-components";

import { TeamMemberInvitationsAddMemberAvatar } from "./team-member-invitations-add-member-avatar";
import { TeamMemberInvitationsInviteTag } from "./team-member-invitations-invite-tag";
import { TeamMemberInvitationsItemContainer } from "./team-member-invitations-item-container";
import { TeamMemberInvitationsOptionLabel } from "./team-member-invitations-option-label";

export type TeamMemberInvitationsInvitationItemProps = {
  option: string;
  isInvited?: boolean;
  invitationActionButton: ReactNode;
};

export const TeamMemberInvitationsInvitationItem = ({
  option,
  isInvited,
  invitationActionButton,
}: TeamMemberInvitationsInvitationItemProps) => {
  return (
    <TeamMemberInvitationsItemContainer>
      <TeamMemberInvitationsAddMemberAvatar />
      <Box
        display="flex"
        gap={0.5}
        alignItems="center"
        justifyContent={{ xs: "space-between", sm: "flex-start" }}
      >
        <TeamMemberInvitationsOptionLabel
          publicName={option}
          isInvited={isInvited}
        />
        {isInvited && <TeamMemberInvitationsInviteTag />}
      </Box>
      {!isInvited && <>{invitationActionButton}</>}
    </TeamMemberInvitationsItemContainer>
  );
};
