import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { NavLayoutContainer } from "@js/layouts/app";
import { CoreLayout } from "@js/layouts/core";

type JobInterviewLayoutProps = {
  children: React.ReactNode;
  header?: React.ReactNode;
};

export const JobInterviewLayout = ({
  children,
  header,
}: JobInterviewLayoutProps) => {
  return (
    <CoreLayout className="job-interview">
      <NavLayoutContainer className="job-interview__header">
        {header}
      </NavLayoutContainer>
      <Box className="job-interview__content">{children}</Box>
    </CoreLayout>
  );
};
