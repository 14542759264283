import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import dayjs from "dayjs";

import type {
  DatePickerProps,
  DatePopoverProps,
  DoubleDatePopoverPickerProps,
} from "@hexocean/braintrust-ui-components";
import {
  DATE_FORMAT,
  DatePicker,
  DatePopover,
  DoubleDatePopoverPicker,
} from "@hexocean/braintrust-ui-components";

export const ReduxFormDatePickerFieldFactory =
  (DatePickerComponent: React.FC<React.PropsWithChildren<DatePickerProps>>) =>
  ({
    id,
    input,
    meta,
    ...rest
  }: DistributiveOmit<DatePickerProps, "value" | "onChange"> &
    TypedWrappedFieldProps<string> &
    Pick<DatePopoverProps, "Anchor" | "anchorProps">) => {
    const error =
      (meta.submitFailed || meta.touched) && meta.error
        ? meta.error
        : undefined;

    return (
      <DatePickerComponent
        id={id}
        label="Date"
        error={error}
        helperText={error}
        value={dayjs.utc(input.value)}
        onChange={(value) => {
          input.onChange(value ? value.format("YYYY-MM-DD") : null);
        }}
        onBlur={input.onBlur}
        {...rest}
      />
    );
  };

export const ReduxFormDoubleDatePickerFieldFactory =
  (
    DoubleDatePickerComponent: React.FC<
      React.PropsWithChildren<DoubleDatePopoverPickerProps>
    >,
  ) =>
  ({
    id,
    hideError = false,
    names, // field names in proper order to make the field name agnostic eg ['start_date', 'end_date',]
    ...rest
  }: {
    id: string;
    hideError?: boolean;
    names: string[];
  } & any) => {
    const start = rest[names[0]];
    const end = rest[names[1]];

    const startError =
      (start.meta.submitFailed || start.meta.touched) && start.meta.error
        ? (start.meta.error as string)
        : undefined;
    const endError =
      (end.meta.submitFailed || end.meta.touched) && end.meta.error
        ? (end.meta.error as string)
        : undefined;

    const startDate = start.input.value;
    const endDate = end.input.value;

    return (
      <DoubleDatePickerComponent
        id={id}
        error={startError || endError}
        startDate={startDate ? dayjs.utc(startDate) : null}
        onStartDateChange={(value) => {
          start.input.onChange(value ? value.format("YYYY-MM-DD") : null);
        }}
        onStartBlur={() => {
          // we don't want to pass event as argument because
          // value will be set from null to invalid keyboard input value for some reason
          start.input.onBlur();
        }}
        endDate={endDate ? dayjs.utc(endDate) : null}
        onEndDateChange={(value) => {
          end.input.onChange(value ? value.format("YYYY-MM-DD") : null);
        }}
        onEndBlur={() => {
          end.input.onBlur();
        }}
        {...rest}
        hideError={hideError}
      />
    );
  };

export const DateField = ReduxFormDatePickerFieldFactory(DatePicker);
export const DatePopoverField = ReduxFormDatePickerFieldFactory(
  DatePopover as React.FC<React.PropsWithChildren<DatePickerProps>>,
);

export const DoubleDatePopoverPickerField =
  ReduxFormDoubleDatePickerFieldFactory(DoubleDatePopoverPicker);

export { DATE_FORMAT };
