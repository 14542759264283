import type { PostComment } from "@js/types/give-and-get-help";

export const getIsCommentExpanded = ({
  comment,
  collapsedComments,
  expandedPostsOrCommentsIds,
  postCommentsExpanded,
}: {
  comment: PostComment;
  expandedPostsOrCommentsIds: number[];
  collapsedComments: Array<{ id: number }>;
  postCommentsExpanded: boolean;
}): boolean => {
  if (postCommentsExpanded) {
    return collapsedComments.every(
      (collapsedComment) => collapsedComment.id !== comment.id,
    );
  }

  return expandedPostsOrCommentsIds.includes(comment.id);
};
