import { useEffect, useMemo, useRef } from "react";

import { setActiveMessengerRoom } from "@js/apps/common/actions";
import { useAppDispatch, useScrollToEnd } from "@js/hooks";
import type { MessageRoom } from "@js/types/messenger";

import { useMarkMessagesAsRead } from "./use-mark-messages-as-read";
import { useRoomMessages } from "./use-room-messages";

export const useMessageBox = (room: MessageRoom) => {
  const scrollBoxRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const { isLoading, currentData } = useRoomMessages({
    roomId: room.id,
    elementRef: scrollBoxRef,
  });
  const latestMessageId =
    !isLoading && currentData ? currentData.results[0]?.id : undefined;
  const isReady = !isLoading && !!currentData;
  const messages = useMemo(
    () => (currentData ? [...currentData.results].reverse() : undefined),
    [currentData],
  );

  const { scrollToEnd } = useScrollToEnd(scrollBoxRef);
  useMarkMessagesAsRead(messages);

  useEffect(() => {
    if (latestMessageId) scrollToEnd();
  }, [latestMessageId, scrollToEnd]);

  useEffect(() => {
    if (room) dispatch(setActiveMessengerRoom(room.id));

    return () => {
      dispatch(setActiveMessengerRoom(undefined));
    };
  }, [dispatch, room]);

  return {
    isReady,
    messages,
    scrollBoxRef,
  };
};
