import { SubmissionError } from "redux-form";

import {
  HELP_OFFER_ACCEPTED_REPORT_REASONS,
  HELP_OFFER_REFUND_REQUESTED_REPORT_REASONS,
  REPORT_POST_REASONS,
  REPORT_REASONS,
} from "@js/apps/common/constants";
import { actionTypes } from "@js/apps/give-and-get-help/action-types";
import {
  useReportHelpOfferMutation,
  useReportPostMutation,
  useReportPublicPostMutation,
} from "@js/apps/give-and-get-help/api";
import type { ReportPayload } from "@js/apps/give-and-get-help/hooks";
import {
  useReportMessageMutation,
  useReportUserMutation,
} from "@js/apps/messenger/api";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import { capitalize } from "@js/utils";

import { handleOpenReportModal } from "../../forms/report";

type CommonHandlerPayload = {
  id: number;
  room?: number;
};

type HandleReportPostPayload = CommonHandlerPayload & {
  type: "post" | "comment" | "user";
};

type HandleReportOfferPayload = CommonHandlerPayload & {
  type: "offer" | "refund";
  isActive: boolean;
  isAsker: boolean;
  name?: string;
};

type HandleReportUserPayload = CommonHandlerPayload & {
  type: "user" | "message";
  name: string;
};

type ErrorType = { data: Record<string, string> };

export const useReport = () => {
  const dispatch = useAppDispatch();
  const [reportPost] = useReportPostMutation();
  const [reportPublicPost] = useReportPublicPostMutation();
  const [reportHelpOffer] = useReportHelpOfferMutation();
  const [reportUser] = useReportUserMutation();
  const [reportMessage] = useReportMessageMutation();

  const reportErrorHandler = (error: ErrorType) => {
    throw new SubmissionError(error.data);
  };

  const reportPostHandler =
    ({ id, type }: HandleReportPostPayload) =>
    (data: ReportPayload): Promise<void> => {
      return reportPost({ id: id, data })
        .unwrap()
        .then(() => {
          dispatch({
            type: actionTypes["report-sent"],
            payload: { postId: id, reportReason: data.reason },
          });

          Snackbar.success(`${capitalize(type)} reported!`);
        })
        .catch(reportErrorHandler);
    };

  const reportPublicPostHandler =
    ({ id, type }: HandleReportPostPayload) =>
    (data: ReportPayload): Promise<void> => {
      return reportPublicPost({ id: id, data })
        .unwrap()
        .then(() => Snackbar.success(`${capitalize(type)} reported!`))
        .catch(reportErrorHandler);
    };

  const reportHelpOfferHandler =
    ({ id }: CommonHandlerPayload) =>
    (data: ReportPayload): Promise<void> => {
      return reportHelpOffer({ id, data })
        .unwrap()
        .then(() => Snackbar.success(`Offer reported!`))
        .catch(reportErrorHandler);
    };

  const reportUserHandler =
    ({ id }: CommonHandlerPayload) =>
    (data: ReportPayload): Promise<void> => {
      return reportUser({ id, ...data })
        .unwrap()
        .then(() => Snackbar.success("User reported"))
        .catch(reportErrorHandler);
    };
  const reportMessageHandler =
    ({ id, room }: CommonHandlerPayload) =>
    (data: ReportPayload): Promise<void> => {
      return reportMessage({ id, room, ...data })
        .unwrap()
        .then(() => Snackbar.success("Message reported"))
        .catch(reportErrorHandler);
    };

  const handleReportPost =
    ({ type, id }: HandleReportPostPayload) =>
    () =>
      handleOpenReportModal({
        type,
        onSubmit: reportPostHandler({ id, type }),
        reasons: REPORT_POST_REASONS,
      });

  const handleReportPublicPost =
    ({ type, id }: HandleReportPostPayload) =>
    () =>
      handleOpenReportModal({
        type,
        onSubmit: reportPublicPostHandler({ id, type }),
        reasons: REPORT_REASONS,
      });

  const handleReportHelpOffer =
    ({ type, id, name, isActive, isAsker }: HandleReportOfferPayload) =>
    () => {
      const reasons = getHelpOfferReasons({
        type,
        isActive,
        isAsker,
      });

      return handleOpenReportModal({
        name,
        type,
        onSubmit: reportHelpOfferHandler({ id }),
        reasons,
      });
    };

  const handleReportUser =
    ({ type, id, name }: HandleReportUserPayload) =>
    () =>
      handleOpenReportModal({
        name,
        type,
        onSubmit: reportUserHandler({ id }),
        reasons: REPORT_REASONS,
      });

  const handleReportMessage =
    ({ type, id, room, name }: HandleReportUserPayload) =>
    () =>
      handleOpenReportModal({
        type,
        onSubmit: reportMessageHandler({ id, room }),
        reasons: REPORT_REASONS,
        name,
      });

  return {
    handleReportPost,
    handleReportHelpOffer,
    handleReportUser,
    handleReportMessage,
    handleReportPublicPost,
  };
};

const getHelpOfferReasons = ({
  type,
  isActive,
  isAsker,
}: Pick<HandleReportOfferPayload, "type" | "isActive" | "isAsker">) => {
  if (type === "refund" && isAsker) {
    return HELP_OFFER_REFUND_REQUESTED_REPORT_REASONS;
  }
  if (isActive) {
    return HELP_OFFER_ACCEPTED_REPORT_REASONS;
  }
  return REPORT_REASONS;
};
