import { useCallback } from "react";

import { Snackbar } from "@js/components/snackbar";
import { useNavigate } from "@js/hooks";
import type { BasicError } from "@js/types/common";

import { useJoinSpacesMutation } from "../../api";

export const useJoinThisSpace = (spaceId: number, navigateToSpace = true) => {
  const navigate = useNavigate();
  const [join, { isLoading }] = useJoinSpacesMutation();
  const handleJoinSpaceClick = useCallback(async () => {
    try {
      await join({
        spaceId,
      }).unwrap();

      Snackbar.success("You joined the space");

      if (navigateToSpace) {
        navigate(`/spaces/${spaceId}/discussion/`);
      }
    } catch (error: unknown) {
      const parsedError = error as BasicError;
      const errorMessage =
        parsedError?.data?._error ||
        "Something went wrong, please refresh the page";

      Snackbar.error(errorMessage);
    }
  }, [join, spaceId, navigate, navigateToSpace]);

  return {
    handleJoinSpaceClick,
    isJoining: isLoading,
  };
};
