import { useEffect, useState } from "react";

import type { LoadScriptProps } from "@js/utils";
import { loadScript, unloadScript } from "@js/utils";

import { useEffectRef } from "../use-effect-ref";

type UseScriptProps = LoadScriptProps & {
  onLoad?: () => void;
  onUnload?: () => void;
  onError?: () => void;
};

export const useScript = ({
  src,
  async,
  id,
  defer,
  onLoad,
  onUnload,
  onError,
}: UseScriptProps) => {
  const [loading, setLoading] = useState(true);

  const onErrorRef = useEffectRef(onError);
  useEffect(() => {
    loadScript({
      src,
      id,
      async,
      defer,
    })
      .then(() => {
        setLoading(false);
        if (onLoad) {
          onLoad();
        }
      })
      .catch(() => {
        onErrorRef.current?.();
      });

    return () => {
      unloadScript(id).then(() => {
        if (onUnload) {
          onUnload();
        }
      });
    };
  }, [async, defer, id, src, onLoad, onUnload, onErrorRef]);

  return {
    loading,
  };
};
