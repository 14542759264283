import { useMemo } from "react";
import { SubmissionError } from "redux-form";

import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useUpdateEmployerInfoMutation } from "@js/apps/employer/api";
import { useGetManagedEmployerQuery } from "@js/apps/employer/hooks";
import { useAppDispatch } from "@js/hooks";

import type { OnboardingOrganizationFields } from "../../components/organization/schema";
import {
  ORGANIZATION_NAME_USED_ERROR,
  ORGANIZATION_NAME_USED_ERROR_MESSAGE,
} from "../../constants";

import {
  clearOrganizationFormLocalStorage,
  getOrganizationFormInitialValues,
  getOrganizationFormLocalChanges,
  saveOrganizationFormChangesLocally,
} from "./utils";

export const useOrganizationOnboardingForm = () => {
  const dispatch = useAppDispatch();

  const { data, isLoading: isLoadingEmployerData } =
    useGetManagedEmployerQuery();

  const [updateEmployerInfo, { isLoading: isUpdatingEmployerData }] =
    useUpdateEmployerInfoMutation();

  const onSubmit = (values: OnboardingOrganizationFields) => {
    if (!data) return;

    return updateEmployerInfo({
      employerId: data.id,
      newData: values,
      params: { set_account_address: true },
    })
      .unwrap()
      .finally(() => {
        dispatch(fetchCurrentUser());
      })
      .catch((error) => {
        if (error.data.name?.includes(ORGANIZATION_NAME_USED_ERROR)) {
          throw new SubmissionError({
            name: ORGANIZATION_NAME_USED_ERROR_MESSAGE,
          });
        }
        throw new SubmissionError(error.data);
      });
  };

  const initialValues = useMemo(() => {
    const serverOrganizationFormData = getOrganizationFormInitialValues(data);

    return {
      ...serverOrganizationFormData,
      ...getOrganizationFormLocalChanges(),
    };
  }, [data]);

  return {
    isLoadingEmployerData,
    isUpdatingEmployerData,
    onSubmit,
    saveChangesLocally: saveOrganizationFormChangesLocally,
    resetForm: clearOrganizationFormLocalStorage,
    initialValues,
  };
};
