import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";

type ReferralEmptyStateProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  bgColor: string;
};

export const ReferralEmptyState = ({
  title,
  description,
  bgColor,
}: ReferralEmptyStateProps) => {
  return (
    <Box
      bgcolor={bgColor}
      borderRadius={2}
      p={"20px 24px"}
      textAlign="center"
      minWidth={{ xs: "330px", md: "420px" }}
      flex={1}
    >
      <Typography
        component="h4"
        variant="title"
        fontWeight={400}
        size="small"
        mb={2}
      >
        {title}
      </Typography>
      <Typography component="p">{description}</Typography>
    </Box>
  );
};
