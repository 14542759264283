import { Modal } from "@js/components/modal";

export const WriteReviewSuccessModalInstance = Modal(
  "write-review-success-modal",
  {
    closeButton: false,
  },
);

export const openWriteReviewSuccessModal = WriteReviewSuccessModalInstance.open;
export const closeWriteReviewSuccessModal =
  WriteReviewSuccessModalInstance.close;
