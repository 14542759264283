type CelebratoryPostMeta = {
  backgroundColor: string;
  celebratoryBackground: string;
  confettiFileName: string;
};

export const getCelebratoryPostMeta = (
  color: EnumType<typeof ENUMS.PostCelebratoryColorEnum> | null,
): CelebratoryPostMeta => {
  switch (color) {
    case ENUMS.PostCelebratoryColorEnum.BLUE:
      return {
        backgroundColor: "var(--soft-blue)",
        celebratoryBackground: "var(--medium-blue)",
        confettiFileName: "confetti-blue.png",
      };
    case ENUMS.PostCelebratoryColorEnum.PURPLE:
      return {
        backgroundColor: "var(--soft-violet)",
        celebratoryBackground: "var(--medium-violet)",
        confettiFileName: "confetti-violet.png",
      };
    case ENUMS.PostCelebratoryColorEnum.TEAL:
      return {
        backgroundColor: "var(--soft-teal)",
        celebratoryBackground: "var(--medium-teal)",
        confettiFileName: "confetti-teal.png",
      };
    case ENUMS.PostCelebratoryColorEnum.ORANGE:
      return {
        backgroundColor: "var(--soft-orange)",
        celebratoryBackground: "var(--medium-orange)",
        confettiFileName: "confetti-orange.png",
      };
    case ENUMS.PostCelebratoryColorEnum.WHITE:
    default:
      return {
        backgroundColor: "var(--white)",
        celebratoryBackground: "var(--soft-green)",
        confettiFileName: "confetti-green.png",
      };
  }
};
