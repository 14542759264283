import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { ErrorSection } from "@js/components/error-section";

import { useCreateOrEditFormErrors } from "../../hooks/create-or-edit-post-form-errors";

type CreateOrEditPostErrorSectionProps = TypedWrappedFieldProps & {
  isSecondStep?: boolean;
};

export const CreateOrEditPostErrorSection = ({
  meta,
}: CreateOrEditPostErrorSectionProps) => {
  const { requiredErrors, errorMessage, generalFormError } =
    useCreateOrEditFormErrors({ meta });

  return (
    <ErrorSection
      meta={meta}
      requiredErrors={requiredErrors}
      errorMessage={errorMessage}
      generalFormError={generalFormError}
    />
  );
};
