import type { PaymentMethod } from "@js/types/payments";

import PaymentMethodVendorImage from "../vendor-image";

import { StripeACH } from "./stripe-ach-payment";
import { StripeCreditCard } from "./stripe-credit-card";

import styles from "./styles.module.scss";

type PaymentMethodInlineProps = {
  paymentMethod: PaymentMethod;
};

export const PaymentMethodInline = ({
  paymentMethod,
}: PaymentMethodInlineProps) => {
  return (
    <div className={styles.paymentMethodInline}>
      <PaymentMethodVendorImage
        paymentMethod={paymentMethod}
        className={styles.paymentMethodInlineVendorImage}
      />
      {paymentMethod.content_type ===
      ENUMS.PaymentContentType.StripeCreditCardPaymentMethod ? (
        <StripeCreditCard paymentMethod={paymentMethod} />
      ) : (
        <StripeACH paymentMethod={paymentMethod} />
      )}
      {paymentMethod.is_default && (
        <div className={styles.paymentMethodInlineDefaultIndicator}>
          DEFAULT
        </div>
      )}
    </div>
  );
};
