import * as React from "react";
import classNames from "classnames";

import styles from "./style.module.scss";

type UserPublicNameProps = {
  name: string;
  approved?: boolean;
  className?: string;
  singleLine?: boolean;
};

export const UserPublicName = ({
  approved,
  className,
  name,
  singleLine,
}: UserPublicNameProps) => {
  return (
    <h6 className={classNames(styles.publicName, className)} title={name}>
      <span
        className={classNames(styles.publicNameText, {
          [styles.publicNameTextWithImg]: approved,
          [styles.publicNameTextSingleLine]: singleLine,
        })}
      >
        {name}
      </span>
    </h6>
  );
};
