import { Typography } from "@hexocean/braintrust-ui-components";
import { CircleCheckOutlineIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./styles.module.scss";

type JobActionsSubscriptionSnackbarContentProps = {
  children: string[];
};

export const JobActionsSubscriptionSnackbarContent = ({
  children,
}: JobActionsSubscriptionSnackbarContentProps) => {
  return (
    <div className={styles.wrapper}>
      <CircleCheckOutlineIcon />
      <Typography variant="paragraph" component="p">
        {children}
      </Typography>
    </div>
  );
};
