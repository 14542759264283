import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import { isArray } from "underscore";

import { useGetHelpOffersQuery } from "@js/apps/give-and-get-help/api";
import { SessionStorage } from "@js/services";
import type { HelpOffer } from "@js/types/give-and-get-help";
import type { Participant } from "@js/types/messenger";

export const usePulseCalendarIcon = (
  participantId?: Participant["id"],
): {
  calendarIconPulse: boolean;
  setCalendarIconPulse: Dispatch<SetStateAction<boolean>>;
} => {
  const [calendarIconPulse, setCalendarIconPulse] = useState(false);

  const {
    error,
    isLoading,
    data: helpOffers,
  } = useGetHelpOffersQuery(
    {
      other_user: participantId,
    },
    {
      skip: typeof participantId !== "number",
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    setCalendarIconPulse(false);
  }, [participantId]);

  if (
    !!error ||
    isLoading ||
    !helpOffers ||
    helpOffers.length === 0 ||
    participantId === null
  ) {
    return { calendarIconPulse, setCalendarIconPulse };
  }

  const pendingOffers = helpOffers.filter(
    (offer) => offer.status === ENUMS.HelpOfferStatus.PENDING,
  );

  const acceptedOffers = helpOffers.filter(
    (offer) => offer.status === ENUMS.HelpOfferStatus.ACCEPTED,
  );

  if (pendingOffers.length > 0 || acceptedOffers.length === 0) {
    return { calendarIconPulse, setCalendarIconPulse };
  }

  const sessionStorageName = `accepted-offers-${participantId}`;
  const currentAcceptedOffers = acceptedOffers.flatMap(({ id }) => id);

  if (checkIfPresentInStorage(sessionStorageName, currentAcceptedOffers)) {
    return { calendarIconPulse, setCalendarIconPulse };
  }

  setCalendarIconPulse(true);
  SessionStorage.setItem(
    sessionStorageName,
    JSON.stringify(currentAcceptedOffers),
  );

  return {
    calendarIconPulse,
    setCalendarIconPulse,
  };
};

const checkIfPresentInStorage = (
  storageName: string,
  helpOffersIds: HelpOffer["id"][],
): boolean => {
  const storage = SessionStorage.getItem(storageName);
  if (!storage) return false;
  try {
    const storageObject = JSON.parse(storage);
    if (!isArray(storageObject)) return false;

    return helpOffersIds.every((offer) => storageObject.includes(offer));
  } catch {
    return false;
  }
};
