import type { DayjsInput } from "@js/utils/date";
import { formatDate, formatDatetime } from "@js/utils/date";

import type {
  DateFormats,
  DateOffset,
  DateTimeFormats,
  IsoDateTime,
} from "../../utils/date/types";

export type DateProps = {
  date: NonNullable<DayjsInput>;
  format: DateFormats;
};

export const Date = ({ date, format }: DateProps) => {
  const result = useFormatDate({ date, format });
  return <>{result}</>;
};

const useFormatDate = ({ date, format }: DateProps) => {
  return formatDate(date, format);
};

type DatetimeProps = {
  date: IsoDateTime;
  offset?: DateOffset;
  format: DateTimeFormats;
};

export const Datetime = ({ date, format }: DatetimeProps) => {
  const result = useFormatDatetime({ date, format });
  return <>{result}</>;
};

const useFormatDatetime = ({ date, format }: DatetimeProps) => {
  return formatDatetime(date, format);
};
