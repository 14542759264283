import type { IPost } from "@js/types/give-and-get-help";

import { useGetPostsQuery } from "../../api";
import type { UseGetPostQueryArg } from "../use-get-post-query-arg";
import { useGetPostQueryArg } from "../use-get-post-query-arg";

const defaultPosts: IPost[] = [];

export const useInfinityPostLoading = (arg?: UseGetPostQueryArg) => {
  const {
    setCurrentPage,
    queryArg: getPostQueryArg,
    currentPage,
  } = useGetPostQueryArg(arg);

  const {
    currentData: data,
    isFetching: fetchingPosts,
    originalArgs,
  } = useGetPostsQuery(getPostQueryArg, {
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
    refetchOnReconnect: false,
  });

  const page = originalArgs?.page || currentPage;

  const handleFetchingMore = () => {
    if (data?.results.length === data?.count) return; // although it should not happen since handleFetchingMore is run when hasMore is true

    setCurrentPage(page + 1);
  };

  const isLoading = !data && fetchingPosts;

  return {
    posts: data?.results || defaultPosts,
    hasMore: !!data?.next,
    loadingPosts: isLoading,
    fetchingPosts: fetchingPosts,
    handleFetchingMore,
    visitedFeedThreeTimes: data?.visited_feed_three_times,
    postsCount: data?.count || 0,
  };
};
