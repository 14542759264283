import { useLocation } from "react-router-dom";
import { z } from "zod";

import { UPDATE_USER } from "@js/apps/auth/action-types";
import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { WebSocketManagerContainer } from "@js/components/websocket";
import { useAppDispatch, useNavigate } from "@js/hooks";
import { useEffectRef } from "@js/hooks/use-effect-ref";

const CAN_VIEW_INVOICES_LISTENER = "can-view-invoices-listener";

const canViewInvoicesEventSchema = z.object({
  data: z.object({
    broadcast_type: z.literal(ENUMS.BroadcastType.CAN_VIEW_EMPLOYER_INVOICES),
    can_view_employer_invoices: z.boolean(),
  }),
});

export const CanViewInvoicesProvider = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pathnameRef = useEffectRef(pathname);

  useEffectOnce(() => {
    const listener = (event: MessageEvent) => {
      const parsedEvent = canViewInvoicesEventSchema.safeParse(event);
      if (!parsedEvent.success) {
        return;
      }

      const canViewInvoices = parsedEvent.data.data.can_view_employer_invoices;
      dispatch({
        type: UPDATE_USER,
        payload: {
          can_view_employer_invoices: canViewInvoices,
        },
      });

      // /employer/invoices or /employer/invoice-batches
      const isOnInvoicesPage =
        pathnameRef.current.startsWith("/employer/invoice");

      const shouldRedirectUser = !canViewInvoices && isOnInvoicesPage;
      if (shouldRedirectUser) {
        navigate("/", { replace: true });
      }
    };

    WebSocketManagerContainer.attachListener(
      listener,
      CAN_VIEW_INVOICES_LISTENER,
    );

    return () => {
      WebSocketManagerContainer.removeListener(CAN_VIEW_INVOICES_LISTENER);
    };
  });

  return <></>;
};
