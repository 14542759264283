import { Tooltip, Typography } from "@hexocean/braintrust-ui-components";
import {
  EarthIcon,
  PeopleIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { pluralize } from "@js/utils";

import styles from "./styles.module.scss";

type DetailsSectionProps = VisibilityProps & MembersProps;

export const DetailsSection = ({
  isPrivate,
  membersCount,
}: DetailsSectionProps) => {
  return (
    <div className={styles.detailsSection}>
      <Visibility isPrivate={isPrivate} />
      <Members membersCount={membersCount} />
    </div>
  );
};

type VisibilityProps = {
  isPrivate?: boolean;
};

export const Visibility = ({ isPrivate }: VisibilityProps) => {
  const label = isPrivate ? "Private" : "Public";
  const tooltipContent = (
    <>
      <Typography component="p" variant="label" size="large" color="inherit">
        Public space
      </Typography>
      <Typography component="p" variant="paragraph" color="inherit">
        Everyone can see posts in this space. Anyone can join.
      </Typography>
    </>
  );

  return (
    <Tooltip placement="top" disabled={!!isPrivate} title={tooltipContent}>
      <div>
        <Typography component="p" variant="paragraph" className={styles.detail}>
          {!isPrivate && <EarthIcon sx={{ fontSize: "15px" }} />}
          {label}
        </Typography>
      </div>
    </Tooltip>
  );
};

type MembersProps = {
  membersCount: number;
};

export const Members = ({ membersCount }: MembersProps) => {
  return (
    <Typography component="p" variant="paragraph" className={styles.detail}>
      <PeopleIcon sx={{ fontSize: "20px" }} />
      {membersCount} member{pluralize(membersCount, { zeroPlural: true })}
    </Typography>
  );
};
