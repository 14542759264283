import { useGetRoomListQuery } from "../api";

import { usePatchRoomListData } from "./use-patch-room-list-data";
import { useRoomListFilters } from "./use-room-list-filters";
import { useSortedRooms } from "./use-sorted-rooms";

const PAGE_SIZE = 50;

type UseRoomListArg = {
  activeRoom?: number;
};

export const useRoomList = ({ activeRoom }: UseRoomListArg) => {
  const { filters, search, setSearch, debouncedSearch, setPage } =
    useRoomListFilters();
  const {
    isFetching: isRoomListFetching,
    currentData: roomListResponse,
    refetch,
  } = useGetRoomListQuery(filters);

  const { roomList } = useSortedRooms({ roomListResponse, activeRoom });

  usePatchRoomListData();

  const hasMore = !!roomListResponse?.next;

  const fetchNextRooms = () => {
    const currentCount = roomList.length;
    const totalCount = roomListResponse?.count ?? 0;
    if (!roomListResponse || currentCount >= totalCount) {
      return;
    }

    const nextPage = Math.floor(currentCount / PAGE_SIZE) + 1;
    if (nextPage === filters.page) {
      refetch();

      return;
    }

    setPage(nextPage);
  };

  return {
    roomList,
    isRoomListFetching,
    fetchNextRooms,
    search,
    isSearching: !!debouncedSearch,
    setSearch,
    hasMore,
  };
};
