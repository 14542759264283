import { useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { usePendingUploadSnackbar } from "../../hooks/use-pending-upload-snackbar";
import type { ExistingFile, UploadedFile } from "../../types";
import { FileUploadFieldLabel } from "../file-upload-field-label";

import { FileUpload, type FileUploadProps } from "./file-upload";

export type FileUploadFieldProps = {
  label?: string;
  existingFiles?: ExistingFile[];
} & TypedWrappedFieldProps<number> &
  Omit<
    FileUploadProps,
    | "onUpload"
    | "existingFile"
    | "onFileDelete"
    | "onBeforeUpload"
    | "onAfterUpload"
  >;

export const FileUploadField = ({
  label,
  input,
  meta,
  existingFiles,
  ...rest
}: FileUploadFieldProps) => {
  const { onBeforeUpload, onAfterUpload, onAfterFileDelete } =
    usePendingUploadSnackbar({
      input,
      meta,
    });

  const onUpload = (uploadedFile: UploadedFile) => {
    input.onChange(uploadedFile.id);
  };

  const existingFile = useMemo(
    () =>
      existingFiles?.find(
        ({ id }) => input.value && Number(input.value) === Number(id),
      ),
    [existingFiles, input.value],
  );

  const onFileDelete = () => {
    input.onChange(null);
    onAfterFileDelete();
  };

  return (
    <>
      {!!label && <FileUploadFieldLabel>{label}</FileUploadFieldLabel>}
      <FileUpload
        {...rest}
        existingFile={existingFile}
        onUpload={onUpload}
        onBeforeUpload={onBeforeUpload}
        onAfterUpload={onAfterUpload}
        onFileDelete={onFileDelete}
      />
    </>
  );
};
