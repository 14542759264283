import type { WrappedFieldProps } from "@hexocean/braintrust-ui-components";
import { FieldSectionPanel } from "@js/apps/jobs/forms/fields";
import { OptionTileButtons } from "@js/components/option-tile-buttons";

const RESUME_REQUIREMENT_OPTIONS = [
  { value: false, label: "Resume is optional" },
  { value: true, label: "Resume is required" },
];

export const ResumeRequirementField = (reduxFieldProps: WrappedFieldProps) => {
  const { input } = reduxFieldProps;

  return (
    <FieldSectionPanel
      title="Resume"
      description="Let Talent know if a resume file is required to apply"
    >
      <OptionTileButtons
        options={RESUME_REQUIREMENT_OPTIONS}
        value={input.value}
        onChange={input.onChange}
        variant="white-grey"
        selectedVariant="black-outlined"
      />
    </FieldSectionPanel>
  );
};
