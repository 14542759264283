import {
  Box,
  Button,
  Loader,
  Typography,
  VerticalSeparator,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";
import { openReferralModal } from "@js/apps/common/components";
import { useCtaClickedContext } from "@js/apps/common/context";
import { useUser } from "@js/apps/common/hooks";
import { useGetWalletBalanceQuery } from "@js/apps/dashboard/api";
import { RouterLink } from "@js/components/link";
import { roundBalance } from "@js/utils";

export const DashboardHeader = () => {
  const user = useUser();
  const isMobile = useMediaQuery("sm");
  const { ctaClicked } = useCtaClickedContext();

  return (
    <header
      className="freelancer-dashboard-header"
      data-testid="freelancer-dashboard-header"
    >
      <Typography
        component="h1"
        variant="title"
        size={isMobile ? "medium" : "large"}
        className="freelancer-dashboard-header__greeting"
        data-testid="dashboard-user-name"
      >
        Hi, {user?.first_name}!
      </Typography>

      <div className="freelancer-dashboard-header__actions">
        <DashboardBalance />
        <Button
          className="freelancer-dashboard-header__refer"
          variant="primary"
          shape="squared"
          size="medium"
          data-testid="dashboard-header-refer-button"
          disabled={!user?.is_verified}
          onClick={() => {
            openReferralModal();
            ctaClicked(ENUMS.UserClicksEventCTAName.REFER_TALENT);
          }}
        >
          Refer & Earn
        </Button>
      </div>
    </header>
  );
};

const DashboardBalance = () => {
  const user = useUser();
  const balanceHolderId = Number(user?.balance_holder);
  const { data, isLoading } = useGetWalletBalanceQuery({ balanceHolderId });

  const balance = data?.available_tokens ? data?.available_tokens : "...";
  const balanceRounded = roundBalance(data?.available_tokens);
  const isMD = useMediaQuery(750);

  if (user?.freelancer_hide_token_balance) return null;

  return (
    <Box
      className="freelancer-dashboard-header__balance-wrapper"
      component={RouterLink}
      to={"/talent/dashboard/my_wallet"}
      sx={{ textDecoration: "none" }}
      data-testid="dashboard-balance-wrapper"
    >
      <Typography component="p" variant="label" whiteSpace="nowrap">
        BTRST Balance
      </Typography>

      <VerticalSeparator height={48} mx={isMD ? 1 : 2} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="freelancer-dashboard-header__balance">
          <BraintrustIcon className="freelancer-dashboard-header__icon" />
          <Typography
            component="p"
            className="display-ellipsis"
            size={isMD ? "small" : "medium"}
            fontWeight={400}
            title={`${balance}`}
            variant="title"
          >
            {balanceRounded}
          </Typography>
        </div>
      )}
    </Box>
  );
};
