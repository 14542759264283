import { Typography } from "@hexocean/braintrust-ui-components";
import {
  CircleCheckGreenIcon,
  CircleUncheckedIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components";
import type { TypedWrappedFieldProps } from "@js/forms/utils";

import { FiltersEnhancedCheckbox } from "../../components/filters-enhanced-checkbox";
import type { WorkingTimezoneJobs } from "../../config";

export const fieldName: keyof WorkingTimezoneJobs = "match_my_working_hours";

type WorkingTimezoneJobsFieldProps = {
  input: TypedWrappedFieldProps<boolean | null>["input"];
  meta: TypedWrappedFieldProps<boolean>["meta"];
  close: () => void;
  submit: () => void;
  workAvailability: string;
};

export const WorkingTimezoneJobsField = ({
  input: match_my_working_hours,
  meta,
  close,
  submit,
  workAvailability,
}: WorkingTimezoneJobsFieldProps) => {
  const handleReset = () => {
    match_my_working_hours.onChange(null);
  };

  const handleApply = () => {
    submit();
    close();
  };

  return (
    <JobPopoverFilterButton.Content
      onApply={handleApply}
      onReset={handleReset}
      sx={{
        "& > .MuiBox-root": {
          overflow: "visible",
        },
      }}
    >
      <FiltersEnhancedCheckbox
        input={
          match_my_working_hours as TypedWrappedFieldProps<unknown>["input"] // satisfy types
        }
        meta={meta}
        labelVariant={match_my_working_hours.value ? "violet" : "transparent"}
        label={
          <>
            <Typography
              component="h3"
              variant="label"
              size="large"
              lineHeight={1.4}
              fontWeight={500}
            >
              Jobs that match my working hours
            </Typography>
            <Typography
              variant="label"
              component="span"
              size="small"
              fontWeight={400}
              fontSize={13}
            >
              Jobs that overlap with {workAvailability}
            </Typography>
          </>
        }
        icon={<CircleUncheckedIcon />}
        checkedIcon={<CircleCheckGreenIcon />}
      />
    </JobPopoverFilterButton.Content>
  );
};
