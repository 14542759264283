import type { PrimitiveValue } from "@hexocean/braintrust-ui-components";
import { Chip } from "@hexocean/braintrust-ui-components";
import type { OptionWithPrimitiveValue } from "@js/types/common";
import type { PostCategory } from "@js/types/give-and-get-help";

import { BudgetOptionLabel } from "../adornment";
import { CategoryLabel } from "../category-label";

export type BudgetOptions = {
  label: string | JSX.Element;
  value: string;
};

export const getBudgetOptions = (
  category: Partial<Pick<PostCategory, "budget_lower" | "budget_higher">>,
): BudgetOptions[] => {
  const budgetOptions: BudgetOptions[] = [
    {
      label: "Free",
      value: "0",
    },
  ];

  if (category.budget_lower) {
    budgetOptions.push({
      label: (
        <>
          <BudgetOptionLabel>{category.budget_lower}</BudgetOptionLabel>
          {!category.budget_higher && (
            <Chip
              size="x-small"
              typographySize="small"
              color="medium-yellow"
              sx={{
                marginLeft: "4px",
                paddingInline: "12px !important",
              }}
              label="Original budget"
            />
          )}
        </>
      ),
      value: category.budget_lower,
    });
  }
  if (category.budget_higher) {
    budgetOptions.push({
      label: <BudgetOptionLabel>{category.budget_higher}</BudgetOptionLabel>,
      value: category.budget_higher,
    });
  }

  return budgetOptions;
};

export const getBudgetOptionsObj = (
  data: PostCategory[],
): Record<number, BudgetOptions[] | undefined> =>
  data.reduce(
    (prev, current) => ({
      ...prev,
      [current.id]: getBudgetOptions(current),
    }),
    {},
  );

export const getAvailableBudgetOptions = (
  budget: string | null,
  category: PostCategory | null,
) => {
  if (budget !== null && Number(budget) !== 0) {
    return getBudgetOptions({ budget_lower: String(budget) });
  }

  if (category && category.name !== "Other") {
    return getBudgetOptions(category);
  }

  return getBudgetOptions({ budget_lower: "25", budget_higher: "50" });
};

export type PostCategoryOption = OptionWithPrimitiveValue & PostCategory;

export const getPostCategoriesOptions = (
  categories?: PostCategory[],
  insertCombinedCategory = true,
): PostCategoryOption[] => {
  if (!categories?.length) return [];

  const _categories = insertCombinedCategory
    ? [
        {
          id: 0,
          name: "All",
          budget_lower: "0",
          budget_higher: "0",
          emoji: "",
          color: ENUMS.PostCategoryColor.MEDIUM_VIOLET,
        },
        ...categories,
      ]
    : categories;

  return _categories.map((category) => {
    return {
      ...category,
      label: <CategoryLabel category={category} />,
      value: category.id,
    };
  });
};

export const isFreeBudgetOption = (value: PrimitiveValue) => {
  return Number(value) === 0;
};

export const LockElement = () => <>🔒</>;

export const transformBudgetOptions = (
  options: OptionWithPrimitiveValue[],
  paymentButtonsDisabled: boolean,
  paymentButtonsHidden: boolean,
) => {
  if (paymentButtonsHidden) {
    return options.filter((option) => isFreeBudgetOption(option.value));
  }

  const transformedOptions = options.map((option) => {
    const disabled =
      paymentButtonsDisabled && !isFreeBudgetOption(option.value);

    return {
      ...option,
      icon: disabled ? LockElement : undefined,
      disabled,
      disabledTooltipTitle: disabled ? "Feature locked during Beta" : undefined,
    };
  });

  if (paymentButtonsDisabled) {
    transformedOptions.push({
      icon: LockElement,
      disabled: true,
      disabledTooltipTitle: "Feature locked during Beta",
      label: <b>Custom amount</b>,
      value: null,
    });
  }

  return transformedOptions;
};
