import type { User } from "@js/types/auth";
import type { Reaction, StickerValue } from "@js/types/give-and-get-help";

import { StickerReactionButton } from "./sticker-reaction-button";
import { useReaction } from "./use-reaction";

type StickerReactionProps = {
  onClick: (sticker: StickerValue) => void;
  reaction: Reaction;
  currentUser: User | null;
  entityReactions: Reaction[];
  entityId: number;
};

export const StickerReaction = ({
  onClick,
  reaction,
  currentUser,
  entityReactions,
  entityId,
}: StickerReactionProps) => {
  const {
    handleOnClick,
    handleOnEnter,
    disablePostActions,
    hasCurrentUserReacted,
    tooltipContent,
  } = useReaction({
    onClick,
    currentUser,
    reaction,
  });

  return (
    <StickerReactionButton
      entityReactions={entityReactions}
      reaction={reaction}
      tooltipContent={tooltipContent}
      onClick={handleOnClick}
      onKeyDown={handleOnEnter}
      disabled={disablePostActions}
      selected={hasCurrentUserReacted}
      entityId={entityId}
    />
  );
};
