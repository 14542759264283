import { openAvailabilityModal } from "@js/apps/available-for-work/components";
import { useAvailableForWorkCoachMark } from "@js/apps/available-for-work/hooks/use-available-for-work-coach-mark";
import { useUser } from "@js/apps/common/hooks";
import { useAppSelector } from "@js/hooks/redux";

type UseWorkAvailabilityConfig = {
  profileId?: number;
  handleClose?: () => void;
  hideCoachMarkOnClick?: boolean;
};

export const useWorkAvailability = ({
  profileId,
  hideCoachMarkOnClick = false,
  handleClose,
}: UseWorkAvailabilityConfig = {}) => {
  const user = useUser();
  const {
    handleAvailableForWorkCoachMarkClose,
    showAvailableForWorkCoachMark,
  } = useAvailableForWorkCoachMark();

  const isProfileOwner = profileId ? user?.id === profileId : !!user?.id;

  const isAvailableForWork = useAppSelector(
    (state) => state.freelancer?.freelancerProfile?.availability_for_work,
  );

  const onClick = async (e: React.MouseEvent<HTMLElement>) => {
    const shouldHideCoachMark =
      showAvailableForWorkCoachMark && hideCoachMarkOnClick;

    if (shouldHideCoachMark) {
      await handleAvailableForWorkCoachMarkClose();
    }

    if (isProfileOwner) {
      e.preventDefault();
      e.stopPropagation();
      openAvailabilityModal({ onClose: handleClose });
    }

    return;
  };

  return {
    onClick,
    isProfileOwner,
    isAvailableForWork,
  };
};
