import React from "react";

import { ButtonCore } from "@hexocean/braintrust-ui-components";
import { Snackbar } from "@js/components/snackbar";
import type { WebSocketClient } from "@js/components/websocket/websocket-client";
import type { AppDispatch } from "@js/store";

import { PING } from "../action-types";
import {
  BACKEND_REVISION,
  PING_MESSAGE,
  PING_PONG_FAILURE_TIME_LIMIT,
} from "../constants";

export class PingPong {
  private firstPingSentAt?: number | null;

  private lastPongReceivedAt?: number | null;

  private customWebSocket: WebSocketClient;

  private readonly dispatch: AppDispatch;

  constructor(webSocket: WebSocketClient, dispatch: AppDispatch) {
    this.customWebSocket = webSocket;
    this.dispatch = dispatch;
  }

  public resetTimestamps = (): void => {
    this.firstPingSentAt = null;
    this.lastPongReceivedAt = null;
  };

  public isUp = (): boolean => {
    if (this.lastPongReceivedAt === null) {
      return false;
    }

    return (
      Date.now() - (this.lastPongReceivedAt || 0) < PING_PONG_FAILURE_TIME_LIMIT
    );
  };

  public isInitializing = (): boolean => {
    if (this.firstPingSentAt === null) {
      return true;
    }

    return !this.isInitializingTimeLimitExceeded();
  };

  public isInitializingTimeLimitExceeded = (): boolean => {
    return (
      Date.now() - (this.firstPingSentAt || 0) > PING_PONG_FAILURE_TIME_LIMIT
    );
  };

  public ping = (): void => {
    if (this.customWebSocket.isOpen()) {
      this.customWebSocket.send(PING_MESSAGE as any);
      this.dispatch({
        type: PING,
      });
      if (!this.firstPingSentAt) {
        this.firstPingSentAt = Date.now();
      }
    }
  };

  public onReceive = (payload: any): void => {
    this.lastPongReceivedAt = Date.now();

    if (this._isNewerBackendRevision(payload)) {
      this._displaySnackbarWithWarning();
    }
  };

  private _isNewerBackendRevision = (payload: any): boolean => {
    return (
      BACKEND_REVISION in payload &&
      payload.BACKEND_REVISION !== SETTINGS.BACKEND_REVISION
    );
  };

  private _displaySnackbarWithWarning = (): void => {
    Snackbar.warning(
      `A new version of the app is available. It's highly recommended to
            refresh the site to make sure you won't experience any issues.`,
      {
        persist: true,
        preventDuplicate: true,
        action: (
          <ButtonCore
            disableRipple
            className="snackbar__link snackbar__link--warning"
            onClick={() => window.location.reload()}
          >
            Refresh
          </ButtonCore>
        ),
      },
    );
  };
}
