import React from "react";
import { Field } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { CheckboxField } from "@js/forms/fields/checkbox";
import { PasswordField } from "@js/forms/fields/password";
import { required } from "@js/forms/validators";

import { OTPAuthCodeField } from "../../forms/fields/otp/otp-auth-code";

export const TwoFactorAuthForPhoneVerification = ({
  isOtpEnabled,
}: {
  isOtpEnabled?: boolean;
}) => {
  return (
    <>
      <Typography component="h4" size="large" fontWeight={400} mb={1}>
        2-step authentication
      </Typography>
      {isOtpEnabled ? (
        <>
          <Typography component="p" mb={2}>
            Enter the 6-digit code generated by your authenticator app.
          </Typography>
          <OTPAuthCodeField name="code" simpleFormField />
          <Field
            name="is_backup_code"
            component={CheckboxField}
            label="Use backup code instead"
          />
        </>
      ) : (
        <>
          <Typography component="p" mb={2}>
            Enter your password to authenticate your identity.
          </Typography>
          <Field
            data-lpignore="true"
            id="password"
            name="password"
            validate={[required]}
            color="secondary"
            variant="standard"
            component={PasswordField}
            label="Password"
          />
        </>
      )}
    </>
  );
};
