import cs from "classnames";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { EditPenIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import type { FreelancerPublic } from "@js/types/freelancer";

import { EducationCard } from "./card";
import { openCreateEducationModal } from "./modals";

type EducationModuleProps = {
  profile: FreelancerPublic;
};

export const EducationModule = ({ profile }: EducationModuleProps) => {
  const user = useUser();
  const isOwnProfile = user && profile.id === user.freelancer;
  const ownEmptyState = !profile.freelancer_schools.length && isOwnProfile;

  return (
    <>
      <Box
        className={cs("education", {
          "profile-empty-state": ownEmptyState,
        })}
      >
        <Box height="100%">
          <Box display="flex" justifyContent="space-between" mb={5}>
            <Typography component="h2" size="large">
              Education
            </Typography>
            {isOwnProfile && !!profile.freelancer_schools.length && (
              <IconButton
                variant="black-outlined"
                size="x-small"
                aria-label="Edit education"
                onClick={openCreateEducationModal}
              >
                <EditPenIcon />
              </IconButton>
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap={4}>
            {!!profile.freelancer_schools.length ? (
              profile.freelancer_schools.map((school) => {
                return <EducationCard key={school.id} school={school} />;
              })
            ) : (
              <EmptyState profile={profile} ownEmptyState={ownEmptyState} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

type EmptyStateProps = {
  profile: FreelancerPublic;
  ownEmptyState: boolean | null;
};

const EmptyState = ({ profile, ownEmptyState }: EmptyStateProps) => {
  return (
    <Box mt={1.5}>
      <Typography
        component="p"
        fontStyle="italic"
        mt={-1}
        mb={2}
        multipleEllipsis
      >
        {ownEmptyState
          ? "Add your education and degrees. "
          : `${profile.user.first_name} hasn’t added their education, yet.`}
      </Typography>
      {ownEmptyState && (
        <Button
          variant="black-outlined"
          size="medium"
          shape="squared"
          onClick={openCreateEducationModal}
        >
          Add Education
        </Button>
      )}
    </Box>
  );
};
