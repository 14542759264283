import { useEffect } from "react";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";

export const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (SETTINGS.GOOGLE_ANALYTICS_ID) {
      ReactGA.pageview(`${location.pathname}${location.search}`);
    }
    if (SETTINGS.GOOGLE_ANALYTICS_V4_ID) {
      ReactGA4.send({
        hitType: "pageview",
        page: `${location.pathname}${location.search}`,
      });
    }
  }, [location]);

  return null;
};
