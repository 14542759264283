import { useMemo } from "react";

import { useGetFreelancerBidsQuery } from "@js/apps/freelancer/api";
import { useGetJobQuery } from "@js/apps/jobs/api";
import { ShareJobButton } from "@js/apps/jobs/components";
import { CompleteProfileOrSubmitButton } from "@js/apps/jobs/components/complete-profile-or-submit-button";
import { DefaultActionBar } from "@js/apps/jobs/components/job-details-action-bar/default-action-bar";

type FreelancerTopBarProps = {
  jobId: number;
};

export const FreelancerTopBar = ({ jobId }: FreelancerTopBarProps) => {
  const { data: job } = useGetJobQuery({ id: jobId });
  const { data: currentJobFreelancerBids } = useGetFreelancerBidsQuery({
    job: jobId,
  });

  const bid = useMemo(
    () => currentJobFreelancerBids?.find((el) => el?.job?.id === job?.id),
    [currentJobFreelancerBids, job],
  );

  const backUrl = `/jobs/${job?.id}/`;

  if (!job || !job.is_open) return null;

  return (
    <DefaultActionBar
      bgcolor="var(--dark-blue)"
      title={job.title}
      subtitle="Back to Job Details"
      actions={[
        <ShareJobButton
          key="share-job-button"
          job={job}
          iconColor="var(--white)"
        />,
        <CompleteProfileOrSubmitButton
          key="complete-profile-or-submit-button"
          job={job}
          bid={bid}
          variant="primary"
          inverse
        />,
      ]}
      backUrl={backUrl}
    />
  );
};
