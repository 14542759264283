import { useState } from "react";

import { EmployerGlobalProvider } from "@js/apps/employer/employer-global-provider";
import { FreelancerGlobalProvider } from "@js/apps/freelancer/freelancer-global-provider";
import { isInIframe } from "@js/utils/iframe";

import { useAccountType } from "../../hooks";

export const AccountDependantProviders = () => {
  const [isIframe] = useState(isInIframe);
  const { isFreelancer, isEmployer } = useAccountType();

  if (isIframe) {
    return <></>;
  }

  return (
    <>
      {isFreelancer && <FreelancerGlobalProvider />}
      {isEmployer && <EmployerGlobalProvider />}
    </>
  );
};
