import React from "react";
import type { WrappedFieldProps } from "redux-form";

import { useOfferErrorSectionErrors } from "@js/apps/jobs/apps/offers/components/offer-error-section/use-offer-error-section-errors";
import { ErrorItem, ErrorSection } from "@js/components/error-section";

import { OFFER_FIELDS } from "../../constants";

export const OfferErrorSection = ({ meta }: WrappedFieldProps) => {
  const { requiredErrors, getMessage, generalFormError } =
    useOfferErrorSectionErrors(meta);

  if (!meta.submitFailed) return null;

  return (
    <ErrorSection
      meta={meta}
      requiredErrors={requiredErrors}
      errorMessage={getMessage()}
      generalFormError={generalFormError}
    >
      <>
        <ErrorItem
          names={[OFFER_FIELDS.deposit_payment_method]}
          sectionName="Payment method"
          message="Please add a payment method"
          requiredErrors={requiredErrors}
          sectionLink={false}
        />
        <ErrorItem
          names={[OFFER_FIELDS.payment_amount]}
          sectionName="Pay Details"
          message="Please add pay rate"
          requiredErrors={requiredErrors}
          sectionLink={false}
        />
        <ErrorItem
          names={[
            OFFER_FIELDS.proposed_start_date,
            OFFER_FIELDS.proposed_end_date,
          ]}
          sectionName="Dates"
          message="Please add start and end date"
          requiredErrors={requiredErrors}
          sectionLink={false}
        />
        <ErrorItem
          names={[OFFER_FIELDS.anticipated_weekly_hours]}
          sectionName="Dates"
          message="Please add an anticipated weekly hours"
          requiredErrors={requiredErrors}
          sectionLink={false}
        />
        <ErrorItem
          names={[OFFER_FIELDS.require_paperwork]}
          sectionName="Dates"
          message="Please add required paperwork"
          requiredErrors={requiredErrors}
          sectionLink={false}
        />
      </>
    </ErrorSection>
  );
};
