import type { FormHTMLAttributes } from "react";
import React from "react";

/**
 * Example usage:
 *
 * ```
 * <Form
 *     onSubmit={submit}
 *     error={error}>
 *     FIELDS!
 *     <button type="submit">
 *         Submit
 *     </button>
 * </Form>
 * ```
 */

export type FormProps = {
  children: React.ReactNode;
  error?: unknown | null;
  onSubmit:
    | ((ev?: any) => void)
    | React.FormEventHandler<any>
    | null
    | undefined;
  preventSubmitOnEnter?: boolean;
  className?: string;
  id?: string;
} & Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit" | "onKeyDown">;

export const Form = ({
  children,
  error,
  onSubmit,
  preventSubmitOnEnter,
  ...props
}: FormProps) => (
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  <form
    onSubmit={(e) => {
      e.preventDefault();
      (document.activeElement as HTMLElement | undefined)?.blur();
      if (onSubmit) onSubmit(e);
    }}
    onKeyDown={(ev) => {
      const target = ev.target as HTMLFormElement;
      if (
        preventSubmitOnEnter &&
        target.type !== "submit" &&
        target.type !== "textarea" &&
        target.contentEditable !== "true" &&
        ev.key === "Enter"
      ) {
        ev.preventDefault();
      }
    }}
    {...props}
  >
    {children}
    {!!error && <div className="general-form-error">{formatError(error)}</div>}
  </form>
);

const formatError = (error: unknown) => {
  if (!error) {
    return "";
  }

  if (typeof error === "string") {
    return error;
  }

  if (Array.isArray(error)) {
    return error.join(", ");
  }

  return JSON.stringify(error);
};
