import React from "react";
import type { WrappedFieldMetaProps } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { ErrorSVGIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { URL } from "@js/services";

type ErrorSectionProps = {
  meta: WrappedFieldMetaProps;
  errorMessage?: string;
  generalFormError?: string;
  children?: JSX.Element;
  requiredErrors: Partial<Record<string, string>>;
};

export const ErrorSection = ({
  meta,
  errorMessage,
  generalFormError = "",
  children,
  requiredErrors,
}: ErrorSectionProps) => {
  if (
    (!meta.submitFailed || !Object.keys(requiredErrors).length) &&
    !generalFormError &&
    !errorMessage
  ) {
    return <></>;
  }

  return (
    <ErrorMessage message={generalFormError || errorMessage}>
      {children}
    </ErrorMessage>
  );
};

export const ErrorMessage = ({
  children,
  message,
}: {
  children?: React.ReactNode;
  message?: string | JSX.Element;
}) => {
  if (!message && !children) return <></>;

  return (
    <Box mt={3} className="error-section" data-testid="error-section">
      <Box
        px={2}
        py={1.5}
        mb={3}
        width="100%"
        bgcolor="var(--soft-red)"
        borderRadius={1}
        display="flex"
      >
        <ErrorSVGIcon style={{ color: "var(--negative-2)" }} />
        <Box ml={1.5}>
          <Typography
            fontWeight={500}
            color="negative"
            component="p"
            variant="paragraph"
            size="small"
          >
            {message}
          </Typography>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

type ErrorItemProps = {
  names: string[];
  sectionName: string;
  customLink?: string;
  message?: string | undefined;
  sectionLink?: boolean;
  requiredErrors: Record<string, string>;
};

export const ErrorItem = ({
  names,
  sectionName,
  customLink,
  message,
  sectionLink = true,
  requiredErrors,
}: ErrorItemProps) => {
  const error = names.reduce((prev, current) => {
    return !!requiredErrors[current] || prev;
  }, false);

  if (!error) return null;

  const errorMessages = [`${sectionName}: ${message}`];

  const linkToSection = sectionName.toLowerCase().replaceAll(" ", "-");
  const newUrl = new URL().hash(linkToSection);
  const url = `/${newUrl.toString()}`;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="fit-content"
      height="fit-content"
      sx={{ padding: "1px 0" }}
    >
      <Typography
        component="p"
        variant="paragraph"
        fontWeight={400}
        sx={{ pr: 1, height: "fit-content" }}
        size="small"
      >
        {errorMessages}
      </Typography>
      {sectionLink && (
        <Typography
          to={customLink || url}
          component="link"
          variant="link"
          ellipsis
          sx={{ height: 24 }}
          size="small"
          RouterLink={RouterLink}
        >
          Add
        </Typography>
      )}
    </Box>
  );
};
