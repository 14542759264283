import React, { Component } from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import type { AnyAction } from "redux";
import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";

import { UPDATE_USER } from "@js/apps/auth/action-types";
import { clearLocalStorageDataRelatedToJobAndTalentFilters } from "@js/services";
import type { AppDispatch, RootState } from "@js/store";
import { wait } from "@js/utils/common";
import { isInIframe } from "@js/utils/iframe";
import { getCurrentPathEncoded } from "@js/utils/url";

import { changeSocketStatus } from "./actions";
import type { WebSocketClientStatus } from "./constants";
import { SocketReadyStateMap, SocketStatus } from "./constants";
import { Intervals } from "./intervals";
import { PingPong } from "./ping-pong";
import { WebSocketClient } from "./websocket-client";

type SendProps = {
  send: (
    stream: EnumType<typeof ENUMS.StreamType>,
    data?: SocketSendQueueData,
  ) => void;
};

type WebSocketManagerState = {
  context: SendProps;
};

type SocketSendQueueData = Record<string, unknown> | undefined;

export type SocketSendQueue = {
  data: SocketSendQueueData;
  stream: EnumType<typeof ENUMS.StreamType>;
};

type WebSocketManagerProps = ConnectedProps<typeof connector> & {
  status: WebSocketClientStatus;
  children?: React.ReactNode;
};

type WebSocketPayloads = {
  [ENUMS.BroadcastType.USER_POST_BAN]: {
    ban: boolean;
    broadcast_type: EnumType<typeof ENUMS.BroadcastType>;
    type: string;
  };
};

export const WebSocketContext = React.createContext<SendProps | null>(null);

class WebSocketManager extends Component<
  WebSocketManagerProps,
  WebSocketManagerState
> {
  intervals = new Intervals();

  // @ts-ignore: No intializer, probably should be fixed
  webSocketClient: WebSocketClient;

  // @ts-ignore: No intializer, probably should be fixed
  pingPong: PingPong;

  socketEnqueued = false;

  socketSendQueue: SocketSendQueue[] = [];

  reconnectionDelay = 0;

  static wsListeners: {
    listener: (event: MessageEvent) => void;
    listenerId: string;
  }[] = [];

  public static attachListener = (
    listener: (event: MessageEvent) => void,
    listenerId: string,
  ) => {
    WebSocketManagerContainer.wsListeners.push({ listener, listenerId });
  };

  public static removeListener = (listenerId: string) => {
    WebSocketManagerContainer.wsListeners = [
      ...WebSocketManagerContainer.wsListeners.filter(
        (listeners) => listeners.listenerId !== listenerId,
      ),
    ];
  };

  _changeSocketStatus = (status: WebSocketClientStatus) => {
    this.props.dispatch(changeSocketStatus(status));
  };

  constructor(props: WebSocketManagerProps) {
    super(props);
    this._initWebSocket();

    this.state = {
      context: {
        send: this.send,
      },
    };
  }

  _initWebSocket = async (): Promise<void> => {
    if (this.reconnectionDelay) {
      await wait(this.reconnectionDelay);
    }

    this.webSocketClient = new WebSocketClient(
      this._onWebsocketOpen,
      this._onWebsocketClose,
      this._onReceive,
      this._onError,
      this._updateStatus,
    );

    this.pingPong = new PingPong(this.webSocketClient, this.props.dispatch);

    return Promise.resolve();
  };

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    this.socketEnqueued = true;
    this.webSocketClient.connect();
    this.intervals.initialize({
      checkStatus: this._checkStatus,
      repeatSendInTheAbsenceOfConnection: this._trySend,
      ping: this.pingPong.ping,
    });
  }

  componentWillUnmount() {
    this.webSocketClient.disconnect();

    this.intervals.cleanUp();

    this.socketEnqueued = false;
  }

  send = (
    stream: EnumType<typeof ENUMS.StreamType>,
    data?: SocketSendQueueData,
  ): void => {
    this.socketSendQueue.push({ stream, data });

    if (this.socketEnqueued) {
      this._trySend();
    }
  };

  _trySend = (): void => {
    if (this._isRunning()) {
      this._sendBatch();
    }
  };

  _isRunning = (): boolean => {
    return this.webSocketClient.isOpen() && this.pingPong.isUp();
  };

  _sendBatch = (): void => {
    while (this.socketSendQueue.length) {
      const value = this.socketSendQueue.shift();
      this.webSocketClient.send(value);
    }
  };

  _checkStatus = (): void => {
    if (this._isRunning()) {
      this._updateStatus(SocketStatus.RUNNING);
      return;
    }

    if (this.webSocketClient.isConnecting()) {
      this._updateStatus(SocketStatus.CONNECTING);
      return;
    }

    if (this.webSocketClient.isClosed()) {
      this._updateStatus(SocketStatus.NOT_RUNNING);
      this.webSocketClient.connect();
      return;
    }

    if (this.webSocketClient.isTransitioning()) {
      this._updateStatus(SocketStatus.TRANSITIONING);
    }

    if (this.pingPong.isInitializing()) {
      this._updateStatus(SocketStatus.WAITING_FOR_PING_PONG);
      return;
    }

    if (this.pingPong.isInitializingTimeLimitExceeded()) {
      this.webSocketClient.disconnect();
    }
  };

  _onWebsocketClose = async (): Promise<void> => {
    this.reconnectionDelay = 2000;
    this.pingPong.resetTimestamps();
    await this._initWebSocket().then(() => {
      this.intervals.initialize({
        checkStatus: this._checkStatus,
        repeatSendInTheAbsenceOfConnection: this._trySend,
        ping: this.pingPong.ping,
      });
      this.webSocketClient.connect();
    });
  };

  _onWebsocketOpen = (): void => {
    this._updateStatus(SocketStatus.CONNECTED);
    // Send the first ping eagerly
    this.pingPong.ping();
    this.reconnectionDelay = 1000;
  };

  _onReceive = (
    event: MessageEvent<{
      broadcast_type: EnumType<typeof ENUMS.BroadcastType>;
    }>,
  ): void => {
    try {
      const parsedEvent = {
        ...event,
        data:
          typeof event.data === "string" ? JSON.parse(event.data) : event.data,
      };
      const payload = parsedEvent.data;
      const { broadcast_type: broadcastType } = payload;

      this.props.forwardAction(payload);

      switch (broadcastType) {
        case ENUMS.BroadcastType.PONG:
          this._updateStatus(SocketStatus.RUNNING);
          this.pingPong.onReceive(payload);
          break;
        case ENUMS.BroadcastType.USER_LOGGED_OUT: {
          Cookies.remove(SETTINGS.SESSION_COOKIE_NAME);
          clearLocalStorageDataRelatedToJobAndTalentFilters();
          if (isInIframe()) {
            window.location.reload();
            break;
          }

          const isLoggingOutInCurrentSession =
            location.pathname.startsWith("/auth/logout");
          if (!isLoggingOutInCurrentSession) {
            window.location.href = `/auth/login/?message=user_logged_out&next=${getCurrentPathEncoded()}`;
          }

          break;
        }
        case ENUMS.BroadcastType.USER_POST_BAN:
          this.props.updateUser(payload);
          break;
        case ENUMS.BroadcastType.USER_VERIFY_ACCOUNT:
          this.props.setUserVerified();
          break;
        default:
          WebSocketManagerContainer.wsListeners.forEach(({ listener }) => {
            listener(parsedEvent);
          });
          break;
      }
    } catch (e: any) {
      Sentry.captureException("Failed to parse websocket message payload", {
        extra: { error: e },
      });

      if (process.env.DEPLOYMENT !== "production") {
        throw new Error(e);
      }
    }
  };

  _onError = (event: WebSocketEventMap["error"]): void => {
    if (
      (event.target as WebSocket)?.readyState === SocketReadyStateMap.CLOSED
    ) {
      this._updateStatus(SocketStatus.DISCONNECTED);
    }
  };

  _updateStatus = (status: WebSocketClientStatus) => {
    if (this.props.status !== status) {
      this._changeSocketStatus(status);
    }
  };

  render() {
    return (
      <WebSocketContext.Provider value={this.state.context}>
        {this.props.children}
      </WebSocketContext.Provider>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  status: state.websocketManager.status,
});

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    dispatch,
    forwardAction: (action: AnyAction) => {
      dispatch({
        type: `@websocket/${action["broadcast_type"]}`,
        payload: action,
      });
    },
    updateUser: (payload: WebSocketPayloads["USER_POST_BAN"]) =>
      dispatch({
        type: UPDATE_USER,
        payload: {
          is_banned_from_posting: payload.ban,
        },
      }),
    setUserVerified: () =>
      dispatch({
        type: UPDATE_USER,
        payload: {
          is_verified: true,
        },
      }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const WebSocketManagerContainer = connector(WebSocketManager);
