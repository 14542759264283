import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useCtaClickedContext } from "@js/apps/common/context";
import type { Article } from "@js/apps/freelancer/components/dashboard/news-and-articles-section/types";
import { RouterLink } from "@js/components/link";

type ProfileResourceProps = {
  article: Article;
};

export const ProfileArticle = ({ article }: ProfileResourceProps) => {
  const { ctaClicked } = useCtaClickedContext();
  const isMobile = useMediaQuery("sm");
  const isTablet = useMediaQuery("md");
  const showMobileLayout = isMobile || isTablet;

  return (
    <div className="news-and-articles-section__profile">
      <div className="news-and-articles-section__title-cta">
        <Typography
          component="h4"
          variant="label"
          size={showMobileLayout ? "small" : "medium"}
        >
          {article.title}
        </Typography>
        {article.cta_link && (
          <Box mt={1}>
            <Button
              variant="black-outlined"
              size="x-small"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                ctaClicked(ENUMS.UserClicksEventCTAName.READ_ARTICLE_OR_NEWS)
              }
              href={article.cta_link}
              RouterLink={RouterLink}
            >
              {article.cta_text}
            </Button>
          </Box>
        )}
      </div>
      <div
        className="news-and-articles-section__image news-and-articles-section__image--profile"
        style={{ backgroundImage: `url(${article.feature_image})` }}
      />
    </div>
  );
};
