import { useEffect, useState } from "react";

import { useAppDispatch } from "@js/hooks";

import { searchTaxonomyItems } from "../../actions";

export type SearchTaxonomyOptions = {
  phrase: string;
  endpoint: string;
  immediate: boolean;
  keepInStore: boolean;
  params: Record<string, any>;
};

export const useFetchSearchTaxonomy = <ResponseType>(
  options: SearchTaxonomyOptions,
): [boolean, ResponseType[]] => {
  const [loading, setLoading] = useState(true);
  const [taxonomy, setTaxonomy] = useState<ResponseType[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    searchTaxonomyItems({
      ...options,
      dispatch,
    }).then((value) => {
      setTaxonomy(value as ResponseType[]);
      setLoading(false);
    });
  }, [options, dispatch]);

  return [loading, taxonomy];
};
