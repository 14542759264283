import type { PurchaseUpgradeParams } from "@js/apps/auth/api";
import { ModalInstance } from "@js/components/modal";

import { ProductCheckoutModal } from "./product-checkout-modal";
import { ProductCheckoutModalContextProvider } from "./product-checkout-modal-context";

import styles from "./styles.module.scss";

type OpenProductCheckoutModalProps = {
  onClickBack: () => void;
  upgradeType: PurchaseUpgradeParams["upgrade_type"];
};

export const openProductCheckoutModal = ({
  onClickBack,
  upgradeType,
}: OpenProductCheckoutModalProps) => {
  ModalInstance.open({
    padding: false,
    containerScrollableNoMobilePadding: false,
    className: styles.productCheckoutModal,
    children: (
      <ProductCheckoutModalContextProvider
        upgradeType={upgradeType}
        ModalInstance={ModalInstance}
        onClickBack={onClickBack}
      >
        <ProductCheckoutModal />
      </ProductCheckoutModalContextProvider>
    ),
  });
};
