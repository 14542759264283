import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { Button } from "@hexocean/braintrust-ui-components";
import { UploadIcon } from "@hexocean/braintrust-ui-components/Icons";
import { getErrorMessage } from "@js/apps/file-upload/components/file-rejections";
import { createUploadJobDescriptionClicked } from "@js/apps/jobs/actions";
import { useJobFormContext } from "@js/apps/jobs/context/job-form-context/job-form-context";
import { useAppDispatch } from "@js/hooks";

export const UploadJobDescription = () => {
  const dispatch = useAppDispatch();

  const {
    uploadJobDescriptionFile,
    resetUploadedFileState,
    setUploadDescriptionError,
  } = useJobFormContext();

  const onDrop = useCallback(
    (files: File[]) => {
      resetUploadedFileState();

      const [file] = files;
      if (!file) {
        return;
      }

      uploadJobDescriptionFile(file);
    },
    [uploadJobDescriptionFile, resetUploadedFileState],
  );

  const { getInputProps, open } = useDropzone({
    multiple: false,
    minSize: 1,
    maxSize: SETTINGS.DEFAULT_MAX_SIZE_FILE,
    onDrop,
    onDropRejected: (fileRejections) => {
      const error = fileRejections[0].errors[0];
      const errorMessage = getErrorMessage(error);

      setUploadDescriptionError(errorMessage);
    },
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
  });

  const handleUploadButtonClick = () => {
    open();
    dispatch(createUploadJobDescriptionClicked());
  };

  return (
    <>
      <Button
        variant="white-violet"
        shape="squared"
        onClick={handleUploadButtonClick}
        startIcon={<UploadIcon />}
      >
        Upload job description
      </Button>
      <input
        data-testid="upload-job-description-input"
        {...getInputProps()}
        style={{ display: "none" }}
      />
    </>
  );
};
