import { Navigate, Route } from "react-router-dom";

import { FreelancerProfilePageResume } from "@js/apps/freelancer/components";

import FreelancerProfilePage from "../../views/profile";
import { FreelancerProfilePageAbout } from "../../views/profile-about";

export const FreelancerProfilePageRoutes = [
  <Route
    path=""
    key="freelancer_profile_page"
    element={<FreelancerProfilePage />}
  >
    <Route index element={<FreelancerProfilePageAbout />} />
    <Route path="resume" element={<FreelancerProfilePageResume />} />
    <Route path="*" element={<Navigate to="/page-not-found" replace />} />
  </Route>,
];
