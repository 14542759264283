import React from "react";

import {
  Button,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { Job } from "@js/types/jobs";

import { getRecommendedTalentListURL } from "../../utils";
import { CannotMakeAction } from "../cannot-make-action";

type JobManagementEmptyStateProps = {
  job: Job;
  title: string;
  subtitle: string;
  action: {
    path: string;
    name: string;
  };
};

export const JobManagementEmptyState = ({
  job,
  title,
  subtitle,
  action,
}: JobManagementEmptyStateProps) => {
  return (
    <RoundedBox
      sx={{
        backgroundColor: "var(--soft-blue)",
        textAlign: "center",
      }}
    >
      <img
        src={`${SETTINGS.STATIC_URL}jobs/bids-empty-state.svg`}
        alt=""
        aria-label="no-bids-in-job"
        width="159"
        height="125"
      />
      <Typography component="h4" variant="label" mb={1}>
        {title}
      </Typography>
      <Typography component="p" mb={4}>
        {subtitle}
      </Typography>
      <CannotMakeAction job={job}>
        <Button
          variant="secondary"
          to={action.path}
          disabled={!!job.completed_at}
          RouterLink={RouterLink}
        >
          {action.name}
        </Button>
      </CannotMakeAction>
    </RoundedBox>
  );
};

export const InviteTalentEmptyState = ({ job }: { job: Job }) => {
  return (
    <JobManagementEmptyState
      job={job}
      title="There is no recommended Talent."
      subtitle="When Talent match with this job, they will show up here.
                                        Haven’t found the right fit yet?"
      action={{
        name: "Browse all Talent",
        path: getRecommendedTalentListURL(job),
      }}
    />
  );
};

export const BidListEmptyState = ({ job }: { job: Job }) => {
  return (
    <JobManagementEmptyState
      job={job}
      title="This job doesn’t have any applications, yet."
      subtitle="Having trouble finding the right Talent?"
      action={{ name: "Edit job requirements", path: `/jobs/${job.id}/edit/` }}
    />
  );
};
