import React from "react";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import {
  Box,
  ButtonCore,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { useAnchorRedirectWarning } from "@js/hooks";
import { IMPLICIT_ANCHOR_WARNING_ATTR } from "@js/services";
import type { LinkMetadataModel } from "@js/types/give-and-get-help";

import styles from "./styles.module.scss";

type LinkPreviewProps = Pick<LinkMetadataModel, "url" | "title" | "image"> &
  BoxProps;

export const LinkPreview = ({
  url,
  title,
  image,
  ...boxProps
}: LinkPreviewProps) => {
  const { anchorWrapperRef } = useAnchorRedirectWarning<HTMLDivElement>();

  return (
    <div ref={anchorWrapperRef}>
      <Box
        component={ButtonCore}
        to={url}
        RouterLink={RouterLink}
        target="_blank"
        className={`${styles.postAttachment} post-attachment--link`}
        height="100%"
        width={271}
        display="flex"
        alignItems="center"
        bgcolor="var(--off-white)"
        justifyContent="flex-start"
        borderRadius={4}
        gap={2}
        padding={2}
        {...{ [IMPLICIT_ANCHOR_WARNING_ATTR]: true }}
        {...boxProps}
      >
        <img
          className={styles.postAttachmentImage}
          alt="site icon preview"
          src={image || `${SETTINGS.STATIC_URL}give-get-help/loading-small.png`}
          width={64}
          height={64}
        />
        <Box minWidth={0}>
          <Typography
            component="p"
            className={`${styles.defaultAttachmentLinkTitle} attachment-link__title`}
            variant="label"
          >
            {title}
          </Typography>
          <Typography
            component="p"
            variant="paragraph"
            className={`${styles.defaultAttachmentLinkLink} attachment-link__link`}
            target="_blank"
            size="small"
            minWidth={0}
            noTextDecoration
            ellipsis
            display="inline-block"
            width="100%"
          >
            {url}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
