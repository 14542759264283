import React from "react";

import { Button } from "@hexocean/braintrust-ui-components";

import { deleteAccount } from "../../actions";
import { Section, SectionSubtitle } from "../../forms/helpers";

export const DeleteAccount = () => {
  return (
    <Section title="My account">
      <SectionSubtitle
        title="Delete my account"
        description={`Braintrust makes it easy to delete your account and all data associated with it. 
                You must remove any BTRST tokens from your Braintrust wallet before deleting your account, 
                or they will be lost forever. You cannot undo this. `}
        action={
          <Button variant="secondary" onClick={() => deleteAccount()}>
            Delete Account
          </Button>
        }
      />
    </Section>
  );
};
