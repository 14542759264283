import * as Sentry from "@sentry/react";

import { retry } from "@js/utils/lazy-with-retry";

import { loadScript, unloadScript } from "../script";

export const appendRecaptchaScript = async () => {
  if (SETTINGS.RECAPTCHA_SITE_KEY) {
    await retry(() =>
      loadScript({
        id: "recaptcha-script",
        src: `https://www.google.com/recaptcha/enterprise.js?render=${SETTINGS.RECAPTCHA_SITE_KEY}`,
      }),
    );
  } else {
    Sentry.captureException("No recaptcha site key configured.");
  }
};

export const removeRecaptchaScriptAndBadge = (id?: string) => {
  unloadScript(id || "recaptcha-script").then(() => {
    window.grecaptcha = undefined;
  });

  const captchaBadges = document.querySelectorAll(".grecaptcha-badge");
  Array.from(captchaBadges).forEach((badge) => {
    badge.parentElement?.remove();
  });
};

export type ExecuteRecaptchaConfig = {
  isEnterprise?: boolean;
  key?: string;
};

export const executeRecaptcha = async (
  action: string,
  { isEnterprise = true, key }: ExecuteRecaptchaConfig = {},
) => {
  try {
    const token: string = await new Promise((resolve, reject) => {
      const recaptchaObject = isEnterprise
        ? window?.grecaptcha?.enterprise
        : window?.grecaptcha;
      recaptchaObject?.ready(async () => {
        try {
          const res = await recaptchaObject?.execute(
            key || SETTINGS.RECAPTCHA_SITE_KEY,
            { action },
          );
          resolve(res);
        } catch (error) {
          reject(
            new Error(
              "Recaptcha - token has not been generated: " + error?.toString(),
            ),
          );
        }
      });
    });

    return token;
  } catch (error) {
    console.error(error);
    Sentry.captureException("Recaptcha execute issue", {
      extra: { action, error },
    });
  }
};
