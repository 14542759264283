import React, { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import type { ConfigProps, DecoratedFormProps } from "redux-form";
import { reduxForm } from "redux-form";
import _ from "underscore";

import { Form } from "@js/forms/components/form";
import type { AppDispatch } from "@js/store";

import { FiltersManager } from "./manager";
import type {
  defaultFormValues,
  FiltersPropsConfig,
  FormClassName,
} from "./types";
import type { FormInstanceProps } from "./types";
import {
  clearObjectEmptyKeysAndEmptyArrays,
  mapAllArraysInObjectToConcatenatedString,
} from "./utils";

export { FiltersManager };

export const createFiltersFormAndHook = <
  T extends defaultFormValues,
  K extends object = T,
>(
  config: FiltersPropsConfig<T, K>,
  formClassName?: FormClassName,
) => {
  const filtersManager = new FiltersManager(config);

  const hook = createFiltersSearchParamsHook<T, K>(filtersManager);
  const FormFilters = createFiltersForm<T, K>(filtersManager, formClassName);

  return [FormFilters, hook, filtersManager] as const;
};

export const createFiltersSearchParamsHook = <
  T extends defaultFormValues,
  K extends object = T,
>(
  manager: FiltersManager<T, K>,
) => {
  const useFormSearchParamsAsValues = (): {
    filters: Partial<K>;
    areFiltersDefault: boolean;
    isAnyFilterApplied: boolean;
  } => {
    const [searchParams] = useSearchParams();
    const prevParamsRef = useRef<Partial<K>>({});
    const values = useMemo(() => {
      const newValues = manager.getParamsToFetchRequest(searchParams);

      if (manager.config.mapParamsToFetchValues) {
        manager.config.mapParamsToFetchValues(newValues);
      }

      if (_.isEqual(newValues, prevParamsRef.current)) {
        return prevParamsRef.current;
      }

      prevParamsRef.current = newValues;

      return prevParamsRef.current;
    }, [searchParams]);

    const isAnyFilterApplied = useMemo(() => {
      if (!manager.config.defaultParamsValues) {
        return !_.isEmpty(values);
      }

      const filterRelevantKeys = Object.keys(
        manager.config.defaultParamsValues,
      );
      const onlyRelevantFilters = Object.fromEntries(
        Object.entries(values).filter(([key]) =>
          filterRelevantKeys.includes(key),
        ),
      );

      return !_.isEmpty(onlyRelevantFilters);
    }, [values]);

    const areFiltersDefault = useMemo(() => {
      const toCompare = manager.config.defaultParamsValues
        ? clearObjectEmptyKeysAndEmptyArrays(manager.config.defaultParamsValues)
        : mapAllArraysInObjectToConcatenatedString(
            clearObjectEmptyKeysAndEmptyArrays(
              manager.config.defaultFormValues,
            ),
          );

      return _.isEqual(values, toCompare);
    }, [values]);

    return {
      filters: values,
      areFiltersDefault,
      isAnyFilterApplied,
    };
  };

  return useFormSearchParamsAsValues;
};

export const createFiltersForm = <
  T extends defaultFormValues,
  K extends object = T,
>(
  manager: FiltersManager<T, K>,
  formClassName?: FormClassName,
) => {
  const FormInstance = createFiltersReduxFormInstance<T, K>(formClassName);

  type FiltersFormProps = ConfigProps<
    T,
    AdditionalProps<T> & FormInstanceProps<T, K>
  > & {
    children: Children;
    transformInitialValues?: (values: T) => Partial<T>;
    prepareToSubmit?: (values: T) => unknown;
    onSubmitSideAction?: (values: Record<string, unknown>) => void;
  };

  const FiltersForm = (props: FiltersFormProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const initialValues = React.useMemo(() => {
      const values = manager.getInitialValues(searchParams);
      const transformedValues = props.transformInitialValues
        ? props.transformInitialValues(values)
        : values;

      return transformedValues;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    return (
      <FormInstance
        {...props}
        manager={manager}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        initialValues={
          props.initialValues ? props.initialValues : initialValues
        }
        prepareToSubmit={props.prepareToSubmit}
        onSubmitSideAction={props.onSubmitSideAction}
      />
    );
  };

  return FiltersForm;
};

type Children = React.ReactNode | ((props: any) => React.ReactNode);

type AdditionalProps<FormValues> = {
  children: Children;
  searchParams: URLSearchParams;
  setSearchParams: ReturnType<typeof useSearchParams>[1];
  prepareToSubmit?: (values: FormValues) => unknown;
  onSubmitSideAction?: (values: FormValues) => void;
};

const createFiltersReduxFormInstance = <
  T extends Record<string, any>,
  K extends object = T,
>(
  formClassName?: FormClassName,
) =>
  reduxForm<T, AdditionalProps<T> & FormInstanceProps<T, K>>({
    enableReinitialize: true,
    onSubmit: (
      valuesArg: T,
      _dispatch: AppDispatch,
      props: DecoratedFormProps<
        T,
        AdditionalProps<T> & FormInstanceProps<T, K>,
        string
      >,
    ) => {
      if (!props.manager.config.allowToSubmitPristineForm && props.pristine)
        return;

      const resetPageIfFiltersAreDifferent = () => {
        const initialValues = props.initialValues;
        const _initialValues = props.manager.config.useAllURLParams
          ? initialValues
          : {};

        const values = { ..._initialValues, ...valuesArg };

        if (!_.isEqual(_.omit(initialValues, "page"), _.omit(values, "page"))) {
          delete values.page;
        }

        return values;
      };

      const values = resetPageIfFiltersAreDifferent();
      const preparedValues = props.prepareToSubmit
        ? (props.prepareToSubmit(values) as T)
        : values;

      if (props.onSubmitSideAction) props.onSubmitSideAction(valuesArg);

      const params =
        props.manager.transformValuesToSearchParams(preparedValues);

      props.setSearchParams(params);
    },
    onChange: (values: T, _dispatch, props, prevValues) => {
      if (!props) return;

      const { manager, submit, pristine } = props;

      const triggerSubmit = manager.shouldTriggerSubmitOnChange(
        values,
        prevValues,
      );

      if (pristine && !manager.config.allowToSubmitPristineForm) return;
      if (submit && triggerSubmit) submit();
    },
  })((props) => {
    const { children, submitting, submit } = props;

    return (
      <Form
        onSubmit={submitting ? null : submit}
        error={null}
        className={formClassName?.className}
      >
        {typeof children === "function" ? children(props) : children}
      </Form>
    );
  });
