import React from "react";
import { Outlet } from "react-router-dom";

import { ReactionsDrawer } from "../stickers-module/reactions-drawer";

export const GGHPageOutlet = () => {
  return (
    <>
      <Outlet />
      <ReactionsDrawer />
    </>
  );
};
