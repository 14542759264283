import { ModalInstance } from "@js/components/modal";

import { BoostSuccessModal } from "./boost-success-modal-content";

export const openBoostSuccessModal = (): void => {
  ModalInstance.open({
    padding: false,
    keepOnBackdropClick: true,
    children: <BoostSuccessModal />,
  });
};
