import { useCallback, useEffect, useState } from "react";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { ArrowRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { WELCOME_MODAL_PARAM } from "@js/apps/employer/hooks/use-employer-welcome-modal";
import { OnboardingPage } from "@js/apps/onboarding/components/onboarding-page";
import { RouterLink } from "@js/components/link";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useScript } from "@js/hooks/";
import { typeGuard } from "@js/utils";

import { clickClientInviteSignupContinue } from "../../actions";

import styles from "./styles.module.scss";

const iframeContainerClass = "meetings-iframe-container";

export const OnboarddingScheduleMeeting = () => {
  const user = useUser();

  const dispatch = useAppDispatch();
  const [hubspotIFrame, setHubspotIFrame] = useState<HTMLIFrameElement | null>(
    null,
  );

  const nextUrl = user?.is_verified ? `/?${WELCOME_MODAL_PARAM}=true` : "/";
  const onError = useCallback(() => {
    Snackbar.error(
      "Failed to load the meeting calendar. This is most likely caused by your browser blocking 3rd party resources",
    );
  }, []);

  const { loading } = useScript({
    id: "hubspot-script",
    src: SETTINGS.HUB_SPOT_SCRIPT_SRC,
    async: true,
    onError,
  });

  useEffect(() => {
    if (loading) {
      return;
    }

    const hubspotContainer = document.querySelector(`.${iframeContainerClass}`);
    const iframeElement =
      hubspotContainer?.firstChild as HTMLIFrameElement | null;

    if (!iframeElement) {
      onError();

      return;
    }

    const checkIframeLoadedTimeout = setTimeout(() => {
      onError();
    }, 4500);

    const iframeLoadListener = () => {
      clearTimeout(checkIframeLoadedTimeout);
    };

    iframeElement.addEventListener("load", iframeLoadListener);
    setHubspotIFrame(iframeElement);

    return () => {
      clearTimeout(checkIframeLoadedTimeout);
      if (!iframeElement) {
        return;
      }
      iframeElement.removeEventListener("load", iframeLoadListener);
    };
  }, [loading, onError]);

  useEffect(() => {
    if (!hubspotIFrame) {
      return;
    }

    const hubspotOrigin = getHubspotOrigin();
    if (!hubspotOrigin) {
      return;
    }

    const messageEventListener = (event: MessageEvent) => {
      if (
        event.source !== hubspotIFrame.contentWindow ||
        event.origin !== hubspotOrigin
      ) {
        return;
      }

      const isConfirmMeetingClick = isBookMeetingEventData(event.data);
      if (!isConfirmMeetingClick) {
        return;
      }

      dispatch(clickClientInviteSignupContinue());
    };

    window.addEventListener("message", messageEventListener);

    return () => {
      window.removeEventListener("message", messageEventListener);
    };
  }, [hubspotIFrame, dispatch]);

  const url = user
    ? `${SETTINGS.HUB_SPOT_LINK}&firstName=${user.first_name}&email=${
        user.email
      }${user.last_name ? `&lastname=${user.last_name}` : ""}`
    : SETTINGS.HUB_SPOT_LINK;

  return (
    <OnboardingPage>
      <Typography variant="paragraph" size="medium" component="p">
        Thanks! That’ll help you connect with new Talent.
      </Typography>
      <Typography variant="title" component="p" size="small" fontWeight={400}>
        Our team is here to help you post your job and hire the talent you need.
        Schedule your free consultation below.
      </Typography>

      <div
        className={`${iframeContainerClass} ${styles.hubSpotContainer}`}
        data-src={url}
      ></div>
      <div className={styles.hubSpotCts}>
        <Button
          className={styles.hubSpotButton}
          RouterLink={RouterLink}
          variant="secondary"
          shape="squared"
          size="medium"
          to="/onboarding/organization/"
        >
          Back
        </Button>
        <Button
          RouterLink={RouterLink}
          to={nextUrl}
          variant="secondary"
          shape="squared"
          size="medium"
          endIcon={<ArrowRightIcon />}
        >
          I don’t want a consultation
        </Button>
      </div>
    </OnboardingPage>
  );
};

const getHubspotOrigin = () => {
  try {
    return new URL(SETTINGS.HUB_SPOT_LINK).origin;
  } catch (error) {
    void error;
  }
};

type BookMeetingEventData = {
  meetingBookSucceeded?: true;
  meetingBookFailed?: true;
  meetingsPayload: object;
};

const isBookMeetingEventData = (
  data: unknown,
): data is BookMeetingEventData => {
  if (!data || typeof data !== "object") {
    return false;
  }

  if (
    !typeGuard<object, { meetingsPayload: object }>(data, "meetingsPayload")
  ) {
    return false;
  }

  return !!data.meetingsPayload;
};
