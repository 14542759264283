import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import type { IPost } from "@js/types/give-and-get-help";

import { showSuccessToast } from "../../components";
import { CREATED_CELEBRATORY_POST_SEARCH_PARAM } from "../../constants";

export const useShowSnackbarOnCelebratoryPostCreation = (
  post: IPost | undefined,
) => {
  const [params, setParams] = useSearchParams();
  const createdCelebratoryPost = params.get(
    CREATED_CELEBRATORY_POST_SEARCH_PARAM,
  );

  useEffect(() => {
    if (post?.id && createdCelebratoryPost === "true") {
      showSuccessToast({
        post,
        bgcolor: "var(--medium-green)",
      });
      setParams(
        (prevState) => {
          prevState.delete(CREATED_CELEBRATORY_POST_SEARCH_PARAM);
          return prevState;
        },
        { replace: true },
      );
    }
  }, [post, createdCelebratoryPost, setParams]);
};
