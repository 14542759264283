export const ONBOARDING_FORM_ID = "onboarding-form";
export const PROF_NET_ONBOARDING_PATHS = {
  GOALS_PAGE: {
    path: "/auth/sign_up/goals",
    routePath: "goals",
  },
  INTERESTS_PAGE: {
    path: "/auth/sign_up/interests",
    routePath: "interests",
  },
  CREATE_ACCOUNT_PAGE: {
    path: "/auth/sign_up/create_account",
    routePath: "create_account",
  },
};

export const EXAMPLE_USERS = [
  {
    id: 3,
    avatar: `${SETTINGS.STATIC_URL}example-avatars/example-3.png`,
    first_name: "Chris",
    has_avatar_set: true,
    avatar_thumbnail: `${SETTINGS.STATIC_URL}example-avatars/example-3.png`,
    gravatar: "",
  },
  {
    id: 2,
    avatar: `${SETTINGS.STATIC_URL}example-avatars/example-2.jpg`,
    first_name: "Masha",
    has_avatar_set: true,
    avatar_thumbnail: `${SETTINGS.STATIC_URL}example-avatars/example-2.jpg`,
    gravatar: "",
  },
  {
    id: 1,
    avatar: `${SETTINGS.STATIC_URL}example-avatars/example-1.png`,
    first_name: "Aya",
    has_avatar_set: true,
    avatar_thumbnail: `${SETTINGS.STATIC_URL}example-avatars/example-1.png`,
    gravatar: "",
  },
  {
    id: 4,
    avatar: `${SETTINGS.STATIC_URL}example-avatars/example-4.jpg`,
    first_name: "Justin",
    has_avatar_set: true,
    avatar_thumbnail: `${SETTINGS.STATIC_URL}example-avatars/example-4.jpg`,
    gravatar: "",
  },
];

export const CLIENT_TESTIMONIALS = [
  {
    id: "slide1",
    header: "What you get with Braintrust",
    title: "Accelerate your hiring",
    text: "Find top-tier talent on Braintrust in minutes. Connect with exceptional candidates ready to make an impact, without the hassle of assessments.",
  },
  {
    id: "slide1",
    header: "What you get with Braintrust",
    title: `Industry low fees`,
    text: `With Braintrust’s low fee, Talent keeps 100% of what they earn, creating a wider pool of talent at a lower cost to you.`,
  },
];

export const USER_TESTIMONIALS = [
  {
    id: "slide1",
    comment:
      "“Braintrust is so transparent. I don’t need to guess what budget the client has and if it’s worth it to apply. Braintrust is creating a win-win-win situation for everyone.”",
    user: {
      avatar: `${SETTINGS.STATIC_URL}user-comments-avatars/patryk-pawlowski.png`,
      public_name: "Patryk Pawlowski",
      first_name: "Patryk",
      title: "Full Stack JavaScript Developer",
      has_avatar_set: true,
      avatar_thumbnail: `${SETTINGS.STATIC_URL}user-comments-avatars/patryk-pawlowski.png`,
      gravatar: "",
    },
  },
  {
    id: "slide2",
    comment:
      "“Braintrust offers the best pay and amazing opportunities. Braintrust is the best thing that has happened to the hiring market in a very long time.”",
    user: {
      avatar: `${SETTINGS.STATIC_URL}user-comments-avatars/saurabh-lodha.png`,
      public_name: "Saurabh Lodha",
      first_name: "Saurabh",
      title: "Full Stack Developer",
      has_avatar_set: true,
      avatar_thumbnail: `${SETTINGS.STATIC_URL}user-comments-avatars/saurabh-lodha.png`,
      gravatar: "",
    },
  },
  {
    id: "slide3",
    comment:
      "“Braintrust is exceptional! The pay is the best, they don’t take a cut, clients are professional, and the work is fulfilling. I love that talent owns a part of the platform through BTRST tokens.”",
    user: {
      avatar: `${SETTINGS.STATIC_URL}user-comments-avatars/mehedi-khan.png`,
      public_name: "Mehedi Khan",
      first_name: "Mehedi",
      title: "Senior Software Engineer, Engineering Lead",
      has_avatar_set: true,
      avatar_thumbnail: `${SETTINGS.STATIC_URL}user-comments-avatars/mehedi-khan.png`,
      gravatar: "",
    },
  },
  {
    id: "slide4",
    comment:
      "“Braintrust is a revelation. Other platforms shroud their payment structure in secrecy or take a sizable chunk out of your pay, but Braintrust is transparent and fair.”",
    user: {
      avatar: `${SETTINGS.STATIC_URL}user-comments-avatars/udoka-uzoka.png`,
      public_name: "Udoka Uzoka",
      first_name: "Udoka",
      title: "Application Development",
      has_avatar_set: true,
      avatar_thumbnail: `${SETTINGS.STATIC_URL}user-comments-avatars/udoka-uzoka.png`,
      gravatar: "",
    },
  },
  {
    id: "slide5",
    comment:
      "“It’s a huge benefit to the client, to be able to quickly hire a talented, vetted person. And the costs at Braintrust are low, which means the client pays less and I earn more.”",
    user: {
      avatar: `${SETTINGS.STATIC_URL}user-comments-avatars/kate-bagoy.png`,
      public_name: "Kate Bagoy",
      first_name: "Kate",
      title: "Product Designer",
      has_avatar_set: true,
      avatar_thumbnail: `${SETTINGS.STATIC_URL}user-comments-avatars/kate-bagoy.png`,
      gravatar: "",
    },
  },
];
export const ORGANIZATION_NAME_USED_ERROR = "already_used";
export const ORGANIZATION_NAME_USED_ERROR_MESSAGE =
  "This organization name is already being used.";
