import { createSlice } from "@reduxjs/toolkit";

export const profileCompletionSuccessSlice = createSlice({
  name: "profileCompletionSuccess",
  initialState: {
    suppressProfileCompletionSuccessModal: false,
  },
  reducers: {
    holdProfileCompletionSuccessModal: (state) => {
      state.suppressProfileCompletionSuccessModal = true;
    },

    resumeProfileCompletionSuccessModal: (state) => {
      state.suppressProfileCompletionSuccessModal = false;
    },
  },
});

export const {
  holdProfileCompletionSuccessModal,
  resumeProfileCompletionSuccessModal,
} = profileCompletionSuccessSlice.actions;
