import { Button, Typography } from "@hexocean/braintrust-ui-components";

type AddSkillsProps = {
  onClick: () => void;
};

export const AddSkills = (props: AddSkillsProps) => {
  const { onClick } = props;

  return (
    <>
      <Typography component="p" size="medium" fontStyle="italic" mb={2}>
        Show off your top skills to connect with other people in the community
        and match to open jobs.
      </Typography>
      <Button variant="black-outlined" shape="squared" onClick={onClick}>
        Add skills
      </Button>
    </>
  );
};
