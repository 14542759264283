import { CardCarouselSection } from "@js/apps/freelancer/components/home-carousel";
import { JobForYouCarouselItem } from "@js/apps/freelancer/components/home-carousel/job-carousel-item";

import { useSimilarJobs } from "./use-similar-jobs";

type SimilarJobsSectionProps = {
  jobId: number;
};

export const SimilarJobsSection = ({ jobId }: SimilarJobsSectionProps) => {
  const { shouldRender, isLoading, similarJobs } = useSimilarJobs(jobId);

  if (!shouldRender) {
    return null;
  }

  return (
    <CardCarouselSection
      title="Similar jobs"
      loading={isLoading}
      items={similarJobs}
      renderItem={(item) => <JobForYouCarouselItem job={item} />}
      extendedWidth
    />
  );
};
