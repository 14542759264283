import type { ApplicationInsightMatchSummary } from "@js/types/jobs";

type MatchSummaryArray = Array<ApplicationInsightMatchSummary>;

const useSortedToolTip = (
  matchSummaryData?: Array<ApplicationInsightMatchSummary>,
): MatchSummaryArray => {
  const matchSummary = [...(matchSummaryData || [])];

  if (matchSummary.length) {
    const sortedToolTip = matchSummary.sort((firstItem, secondItem) => {
      if (firstItem.sentence === "Great reviews for previous jobs") return -1;
      if (secondItem.sentence === "Great reviews for previous jobs") return 1;
      if (firstItem.summary_type === secondItem.summary_type) return 0;
      return firstItem.summary_type === "positive" ? -1 : 1;
    });
    return sortedToolTip;
  }

  return matchSummary;
};

export default useSortedToolTip;
