import type { StackProps } from "@hexocean/braintrust-ui-components";
import { Stack } from "@hexocean/braintrust-ui-components";

export const EmployerInvoicesStatisticWrapper = ({
  children,
  sx,
  ...rest
}: StackProps) => {
  return (
    <Stack
      sx={{
        ...sx,
        p: { xs: 3, md: "30px" },
        background: "var(--white)",
        borderRadius: 4,
        minHeight: { xs: "154px", sm: "162px", md: "188px" },
      }}
      {...rest}
    >
      {children}
    </Stack>
  );
};
