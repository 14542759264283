import { SubmissionError } from "redux-form";

import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { Freelancer } from "@js/types/freelancer";

import { fetchFreelancerProfile } from "../actions";
import { useUploadResumeMutation } from "../api";

export const useUploadResume = (id: Freelancer["id"]) => {
  const [uploadResume, { error }] = useUploadResumeMutation();
  const dispatch = useAppDispatch();

  const onSubmit = ({ resume }: { resume: { id: number } }) => {
    return uploadResume({ id, resume: resume.id })
      .unwrap()
      .then(() => {
        dispatch(fetchFreelancerProfile(id));
      })
      .catch((err) => {
        console.error(err.data);
        Snackbar.error("Resume upload error");
        throw new SubmissionError(err.data);
      });
  };

  return {
    onSubmit,
    error,
  };
};
