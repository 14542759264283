import {
  Button,
  type ButtonShape,
  type ButtonSizesType,
  type ButtonVariants,
} from "@hexocean/braintrust-ui-components";
import { useGetJobDraftsQuery } from "@js/apps/jobs/api";

import { openReviewsDraftsJobModal } from "../review-drafts-modal/modal-content";

type ReviewDraftsButtonProps = {
  label?: string;
  variant?: ButtonVariants;
  size?: ButtonSizesType;
  shape?: ButtonShape["shape"];
};

export const ReviewDraftsButton = ({
  variant = "secondary",
  size = "x-small",
  label = "Review drafts imported from ATS",
  shape = "squared",
}: ReviewDraftsButtonProps) => {
  const { data: drafts } = useGetJobDraftsQuery({});
  const shouldShowReviewDraftButton = drafts?.results.some(
    (draft) => !!draft.ats_imported,
  );

  if (!shouldShowReviewDraftButton) {
    return <></>;
  }

  return (
    <Button
      variant={variant}
      size={size}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        openReviewsDraftsJobModal();
      }}
      shape={shape}
    >
      {label}
    </Button>
  );
};
