import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";

import { ProfileCompletionConfirmationCloseBtn } from "./close-btn";

import styles from "./style.module.scss";

export const ProfileCompleteConfirmationModalContent = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.topSection}>
        <ProfileCompletionConfirmationCloseBtn />

        <Typography component="p" variant="label" mb={1}>
          Your profile is 100% complete 🎉
        </Typography>

        <Typography
          component="p"
          variant="title"
          size="small"
          mb={2}
          fontWeight={400}
        >
          Apply to your first job to get approved as a top talent
        </Typography>
        <Typography component="p" variant="paragraph" size="medium">
          After you've submitted your first application, we'll start the
          10-minute screening process. If you pass the screening test, you'll
          earn an Approved Talent badge, and your application will be sent to
          the client.
        </Typography>
        <div className={styles.btnWrapper}>
          <Button
            variant="primary"
            shape="squared"
            className={styles.browseJobBtn}
            to="/jobs"
            RouterLink={RouterLink}
            onClick={ModalInstance.close}
          >
            Browse jobs
          </Button>
        </div>
      </div>
      <div className={styles.bottomSection}>
        <Typography component="p" variant="paragraph" size="medium">
          Note: Only applications from individuals who have been approved as
          Talent are shown to clients, so it's essential to complete your
          screening to land a job on Braintrust.
        </Typography>
      </div>
    </div>
  );
};
