import { findEntityIndex } from "@js/utils";

import type { GetSpacesResponse } from "../../types";

export const filterOutSpace = (draft: GetSpacesResponse, spaceId: number) => {
  const spaceIndex = findEntityIndex(draft.results, spaceId);
  if (spaceIndex === -1) return;

  draft.count -= 1;
  draft.results.splice(spaceIndex, 1);
};
