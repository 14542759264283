import { useCallback } from "react";
import { getFormValues, isDirty, isInvalid } from "redux-form";

import { goBackToFirstJobScreen } from "@js/apps/jobs/actions";
import { CREATE_JOB_FORM_ID } from "@js/apps/jobs/apps/create-job/constants";
import { useJobFormContext } from "@js/apps/jobs/context/job-form-context/job-form-context";
import { useGetJobFlowEntry } from "@js/apps/jobs/hooks/use-get-job-flow-entry";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { JobFormValues } from "@js/types/jobs";

import { useSaveLastJobDraft } from "../use-save-last-job-draft";

export const useCreateJobBackButton = () => {
  const { jobDraft } = useJobFormContext();
  const isCreateJobFormInvalid = useAppSelector(isInvalid(CREATE_JOB_FORM_ID));
  const isCreateJobFormDirty = useAppSelector(isDirty(CREATE_JOB_FORM_ID));
  const { saveLastJobDraft } = useSaveLastJobDraft();
  const formValues = useAppSelector(
    getFormValues(CREATE_JOB_FORM_ID),
  ) as JobFormValues;
  const dispatch = useAppDispatch();
  const { flow_entry } = useGetJobFlowEntry();

  const onBackBtnClick = useCallback(() => {
    if (isCreateJobFormDirty && !isCreateJobFormInvalid) {
      saveLastJobDraft({
        values: formValues,
        lastSavedDraft: jobDraft,
      });
    }
    dispatch(goBackToFirstJobScreen({ flow_entry }));
  }, [
    isCreateJobFormDirty,
    isCreateJobFormInvalid,
    flow_entry,
    formValues,
    saveLastJobDraft,
    dispatch,
    jobDraft,
  ]);

  return {
    onBackBtnClick,
  };
};
