import { useMemo } from "react";

import { useFeaturedRoles } from "@js/apps/roles/hooks";
import type { Role } from "@js/types/roles";

type UseCustomRoleProps = {
  selectedRolesIds: number[];
};

export type CustomRole = {
  roleOptions: {
    value: number;
    label: string;
  }[];
  selectedRoleData: Role[];
};

export const useCustomRole = ({
  selectedRolesIds,
}: UseCustomRoleProps): CustomRole => {
  const { featuredRoles } = useFeaturedRoles();

  const selectedRoleData = useMemo(() => {
    return featuredRoles?.filter((role) => selectedRolesIds?.includes(role.id));
  }, [featuredRoles, selectedRolesIds]);

  const roleOptions = useMemo(() => {
    return featuredRoles.map((role) => {
      return {
        label: role.name,
        value: role.id,
      };
    });
  }, [featuredRoles]);

  return {
    roleOptions,
    selectedRoleData,
  };
};
