import { useCallback, useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import { API } from "@js/api";
import { useUser } from "@js/apps/common/hooks";
import { useIsEmployerUserVerified } from "@js/apps/common/hooks/is-employer-verified";
import { useGetJobDraftQuery } from "@js/apps/jobs/api";
import { useHandleFailedSubmission } from "@js/apps/jobs/apps/create-job/hooks/handle-failed-submission";
import { useCreateJobAndRemoveJobDraft } from "@js/apps/jobs/apps/create-job/hooks/use-create-job-and-remove-job-draft";
import {
  getDraftInitialValues,
  getEmptyJobInitialValues,
} from "@js/apps/jobs/apps/create-job/utils/form-initial-values";
import { prepareCreateJobSubmitFormValues } from "@js/apps/jobs/apps/create-job/utils/prepare-data";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useNavigate } from "@js/hooks";
import type { CreateJobResponse, JobFormValues } from "@js/types/jobs";

import { setCurrentPostedATSJobId } from "../../components/ats-integration-modal/ats-jobs-slice";

type UseReviewDraftJobArgs = {
  draftId: number | undefined;
  isLastJobToPublish?: boolean;
};

type UseReviewDraftJobReturn = {
  onSubmit: (values: JobFormValues) => Promise<CreateJobResponse | undefined>;
  onSubmitSuccess: (response: CreateJobResponse) => void;
  onSubmitFail: (errors: { [key: string]: string[] }) => void;
  initialValues: Partial<JobFormValues>;
};

export const useReviewDraftJob = ({
  draftId,
  isLastJobToPublish,
}: UseReviewDraftJobArgs): UseReviewDraftJobReturn => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useUser();
  const userId = user?.id;
  const isEmployerVerified = useIsEmployerUserVerified();
  const onSubmitFail = useHandleFailedSubmission(draftId);
  const { data: draft } = useGetJobDraftQuery(
    draftId ? { id: draftId } : skipToken,
  );

  const { createJobAndDeleteJobDraft } = useCreateJobAndRemoveJobDraft();

  const handleJobSubmit = useCallback(
    async (values: JobFormValues) => {
      const data = prepareCreateJobSubmitFormValues(values);
      const shouldCreateDraft = !isEmployerVerified;
      if (!data) {
        return;
      }
      if (!shouldCreateDraft) {
        return createJobAndDeleteJobDraft({
          ...data,
          dry_run: false,
        });
      }

      return createJobAndDeleteJobDraft({
        ...data,
        dry_run: true,
        public_after_verification: true,
      });
    },
    [createJobAndDeleteJobDraft, isEmployerVerified],
  );

  const onSubmit = useCallback(
    async (values: JobFormValues) => {
      return await handleJobSubmit(values);
    },
    [handleJobSubmit],
  );

  const onSubmitSuccess = useCallback(
    (response: CreateJobResponse) => {
      {
        if (!isEmployerVerified) {
          navigate("/employer/dashboard/my_jobs/", {
            state: { disableRemountIfTheSameLocation: true },
          });
          dispatch(
            API.util.invalidateTags([{ type: "JobDrafts", id: draftId }]),
          );
          Snackbar.toast({
            header: "Your account is not verified",
            buttonText: "Got it",
            content: `Your job offer has been saved as a draft, to make it public,
                    verify your email address by clicking the verification link in
                    the email sent to the address you provided during
                    registration.`,
          });
          return;
        }

        dispatch(API.util.invalidateTags([{ type: "JobDrafts", id: "LIST" }]));
        dispatch(API.util.invalidateTags(["ManagedJobs"]));

        if (isLastJobToPublish) {
          ModalInstance.close();
          navigate(`/jobs/${response.id}/invite_talent/`);

          return;
        }

        dispatch(setCurrentPostedATSJobId(response.id));
        return;
      }
    },
    [dispatch, isEmployerVerified, isLastJobToPublish, navigate, draftId],
  );

  const initialValues = useMemo(() => {
    if (!userId) {
      return {};
    }

    if (!draft) {
      return getEmptyJobInitialValues(userId);
    }

    return getDraftInitialValues({ draft, userId });
  }, [draft, userId]);

  return {
    onSubmit,
    onSubmitSuccess,
    onSubmitFail,
    initialValues,
  };
};
