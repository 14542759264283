import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import type { WrappedFieldsProps } from "redux-form";
import { Field } from "redux-form";
import { Fields } from "redux-form";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { AddReviewAnimatedBox } from "@js/apps/reviews/components/add-review-animated-box";
import { useWriteHelpOfferReviewProps } from "@js/apps/reviews/hooks/write-help-offer-review-props";
import {
  getShouldAutoOpenHelpOfferReviewsModal,
  setAutoOpenHelpOfferReviewsModal,
} from "@js/apps/reviews/slice";
import { Modal } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import { RatingField, TextareaField } from "@js/forms/fields";
import { ButtonSelectField } from "@js/forms/fields/button-select";
import { required } from "@js/forms/validators";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { FreelancerPublic } from "@js/types/freelancer";
import type { HelpOffer } from "@js/types/give-and-get-help";

import { useGetReviewTagsQuery } from "../../api";
export type HelpReviewFormData = {
  score: string;
  additional_comments: string;
  tags: number[];
};

const ReviewFormInstance = createFormInstance<HelpReviewFormData, unknown>(
  "ggh-talent-review-form",
);

export const GGHTalentReviewFormModalInstance = Modal(
  "ggh-talent-review-form-modal",
  {
    closeButton: false,
  },
);

export const GGHTalentReviewModal = ({
  profile,
}: {
  profile: FreelancerPublic;
}) => {
  const dispatch = useAppDispatch();
  const { offerForReview, openReviewProcessesList, onSubmit, onSubmitSuccess } =
    useWriteHelpOfferReviewProps({ profile });
  const shouldOpenModalImmediately = useAppSelector(
    getShouldAutoOpenHelpOfferReviewsModal,
  );

  useEffect(() => {
    if (
      shouldOpenModalImmediately &&
      offerForReview &&
      !!openReviewProcessesList?.length
    ) {
      dispatch(setAutoOpenHelpOfferReviewsModal(false));
      openGGHTalentReviewForm(onSubmit, onSubmitSuccess, offerForReview);
    }
  }, [
    dispatch,
    offerForReview,
    onSubmit,
    onSubmitSuccess,
    openReviewProcessesList?.length,
    shouldOpenModalImmediately,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setAutoOpenHelpOfferReviewsModal(false));
    };
  }, [dispatch]);

  return <GGHTalentReviewFormModalInstance />;
};

type ReviewFormProps = {
  helpOffer: HelpOffer;
  onSubmit: (data: HelpReviewFormData) => void;
  onSubmitSuccess: () => void;
};

export const GGHelpTalentReviewForm = ({
  helpOffer,
  onSubmit,
  onSubmitSuccess,
}: ReviewFormProps) => {
  const isOtherCategory = helpOffer.category.name === "Other";
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const handleRemoveSearchParams = () => {
      if (Array.from(searchParams).length > 0) {
        setSearchParams({});
      }
    };
    return () => handleRemoveSearchParams();
  }, [searchParams, setSearchParams]);

  return (
    <ReviewFormInstance onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
      <div className="ggh-talent-review-form">
        <Typography component="h2" variant="label" size="large" mb={3}>
          Leave a review
        </Typography>
        <div className="ggh-talent-review-form__image-container">
          <AddReviewAnimatedBox reviewer={helpOffer.author.user} />
        </div>
        <Typography
          component="h3"
          variant="title"
          size="small"
          mt={3}
          mb={1.5}
          fontWeight={400}
        >
          {isOtherCategory ? (
            `How well did ${helpOffer.author.user.first_name} help you?`
          ) : (
            <>
              How well did {helpOffer.author.user.first_name} help you with{" "}
              <b>{helpOffer.category.name}</b>
              <b>?</b>
            </>
          )}
        </Typography>
        <Field
          id="score"
          name="score"
          component={RatingField}
          variant="stars"
          iconStyle={{ width: "64px", height: "64px" }}
          validate={[required]}
        />
        <Fields
          names={["score", "tags", "additional_comments"]}
          component={TagsAndFeedbackField}
        />
      </div>
    </ReviewFormInstance>
  );
};

const TagsAndFeedbackField = ({
  score,
  tags,
  additional_comments,
}: WrappedFieldsProps) => {
  const { isLoading, data: reviewTags } = useGetReviewTagsQuery();

  if (!score.input.value || !reviewTags || isLoading) return null;

  const tagOptions = reviewTags?.map((tag) => ({
    value: tag.id,
    label: tag.name,
  }));

  return (
    <>
      <Typography component="p" variant="paragraph" mt={4} mb={2}>
        <b>What made them helpful to you?</b> (Optional)
      </Typography>
      <ButtonSelectField
        options={tagOptions}
        meta={tags.meta}
        input={tags.input}
        multiple={true}
        variant="white-violet"
        selectedVariant="violet-with-dark-border"
        fontWeight={400}
        size="medium"
      />

      <TextareaField
        id="additional_comments"
        className="ggh-talent-review-form__text-field"
        label={
          <Typography component="p" variant="paragraph" mb={1}>
            <b>Leave additional feedback</b> (Optional)
          </Typography>
        }
        placeholder="Text"
        maxLengthInHelpText
        maxLength={1000}
        rows={4}
        input={additional_comments.input}
        meta={additional_comments.meta}
        onChange={additional_comments.input.onChange}
        InputProps={{
          style: { padding: 0 },
        }}
      />

      <Button
        className="ggh-talent-review-form__submit-btn"
        variant="primary"
        size="medium"
        shape="squared"
        type="submit"
      >
        Submit
      </Button>
    </>
  );
};

export const openGGHTalentReviewForm = (
  onSubmit: (data: HelpReviewFormData) => void,
  onSubmitSuccess: () => void,
  helpOffer: HelpOffer,
) => {
  GGHTalentReviewFormModalInstance.open({
    children: (
      <GGHelpTalentReviewForm
        onSubmit={onSubmit}
        onSubmitSuccess={() => {
          onSubmitSuccess();
          GGHTalentReviewFormModalInstance.close();
        }}
        helpOffer={helpOffer}
      />
    ),
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
    },
    className: "ggh-talent-review-modal",
  });
};
