import { useEffect, useMemo, useState } from "react";

import { useGetJobDraftsQuery } from "@js/apps/jobs/api";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";

import { setCurrentPostedATSJobId } from "../../components/ats-integration-modal/ats-jobs-slice";

type UseDraftJobToReviewReturnType = {
  draftsCountToReview: number;
  isDraftLoading: boolean;
  currentReviewedDraftId: number | undefined;
  currentDraftCount: number;
  handleSkipClick: () => void;
  handleBackClick: () => void;
};

export const useDraftJobToReview = (): UseDraftJobToReviewReturnType => {
  const dispatch = useAppDispatch();
  const { data: drafts, isFetching: isLoading } = useGetJobDraftsQuery({});
  const draftsToReview = useMemo(
    () =>
      drafts?.results
        .filter((draft) => !!draft.ats_imported)
        .map((d) => d.id) || [],
    [drafts?.results],
  );
  const [currentReviewedDraftId, setCurrentReviewedDraftId] =
    useState<number>();

  const indexOfCurrentReview =
    typeof currentReviewedDraftId !== "undefined"
      ? draftsToReview?.indexOf(currentReviewedDraftId)
      : -1;

  const handleSkipClick = () => {
    if (indexOfCurrentReview < draftsToReview.length - 1) {
      setCurrentReviewedDraftId(draftsToReview[indexOfCurrentReview + 1]);
      return;
    }
    ModalInstance.close();
  };

  const handleBackClick = () => {
    if (indexOfCurrentReview > 0) {
      setCurrentReviewedDraftId(draftsToReview[indexOfCurrentReview - 1]);
      return;
    }
    ModalInstance.close();
  };

  useEffect(() => {
    if (isLoading || !draftsToReview.length) {
      return;
    }

    setCurrentReviewedDraftId(draftsToReview[0]);
  }, [draftsToReview, isLoading]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPostedATSJobId(undefined));
    };
  }, [dispatch]);

  return {
    draftsCountToReview: draftsToReview.length,
    isDraftLoading: isLoading,
    currentReviewedDraftId,
    currentDraftCount: indexOfCurrentReview + 1,
    handleSkipClick,
    handleBackClick,
  };
};
