import React from "react";

import { ModalInstance } from "@js/components/modal";

export const LearMoreAboutTokenButton = (): JSX.Element => (
  <a
    href={SETTINGS.TOKEN_LANDING_PAGE_URL}
    target="_blank"
    rel="noopener noreferrer"
    style={{ fontWeight: 600, color: "white" }}
    onClick={ModalInstance.close}
  >
    Learn more about BTRST
  </a>
);
