import {
  Box,
  Button,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { AddCircleOutlineIcon } from "@hexocean/braintrust-ui-components/Icons";

import { openSpaceInviteContactsModal } from "../invite-to-space-modal";

import styles from "./people-no-data.module.scss";

export const PeopleNoData = ({ spaceId }: { spaceId: number }) => {
  return (
    <Stack className={styles.container} sx={{ alignItems: "center" }}>
      <Box className={styles.iconContainer}>
        <Typography variant="title" component="span" size="medium">
          🧐
        </Typography>
      </Box>
      <Typography variant="label" component="h2" size="large">
        We couldn't find that member
      </Typography>
      <Typography
        variant="paragraph"
        component="p"
        size="medium"
        sx={{ maxWidth: "485px", mb: "10px" }}
      >
        Double-check the spelling of the name, and give it another shot. If
        they're not yet part of this space, consider inviting them to join!
      </Typography>
      <Button
        variant="primary"
        size="small"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => openSpaceInviteContactsModal({ spaceId })}
      >
        Invite
      </Button>
    </Stack>
  );
};
