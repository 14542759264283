import type { ReactNode } from "react";
import { useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { usePendingUploadSnackbar } from "../../hooks/use-pending-upload-snackbar";
import type { UploadedFile } from "../../types";
import { FileUploadFieldLabel } from "../file-upload-field-label";

import type { RenderPreviewArg } from "./files-upload";
import { FilesUpload, type FilesUploadProps } from "./files-upload";

export type FilesUploadFieldRenderPreviewArg = RenderPreviewArg &
  TypedWrappedFieldProps<number[]>;

export type FilesUploadFieldProps = {
  label?: string;
  existingFiles?: FilesUploadProps["existingFiles"];
  renderPreview?: null | ((arg: FilesUploadFieldRenderPreviewArg) => ReactNode);
} & TypedWrappedFieldProps<number[]> &
  Omit<
    FilesUploadProps,
    | "onUpload"
    | "existingFiles"
    | "onFileDelete"
    | "renderPreview"
    | "onBeforeUpload"
    | "onAfterUpload"
  >;

export const FilesUploadField = ({
  label,
  input,
  meta,
  existingFiles,
  renderPreview,
  ...rest
}: FilesUploadFieldProps) => {
  const { onBeforeUpload, onAfterUpload, onAfterFileDelete } =
    usePendingUploadSnackbar({
      input,
      meta,
    });

  const onUpload = (uploadedFiles: UploadedFile[]) => {
    const uploadedFileIds = uploadedFiles.map(({ id }) => id);
    const uniqueIds = [...new Set([...input.value, ...uploadedFileIds])];
    input.onChange(uniqueIds);
  };

  const filteredExistingFiles = useMemo(
    () =>
      existingFiles?.filter(({ id }) => !!input.value?.includes(Number(id))) ??
      [],
    [existingFiles, input.value],
  );

  const onFileDelete = (id: string | number) => {
    if (!input.value) {
      return;
    }
    const filteredValue = input.value.filter(
      (currentId) => String(currentId) !== String(id),
    );

    input.onChange(filteredValue);
    onAfterFileDelete();
  };

  return (
    <>
      {!!label && <FileUploadFieldLabel>{label}</FileUploadFieldLabel>}
      <FilesUpload
        {...rest}
        existingFiles={filteredExistingFiles}
        onUpload={onUpload}
        onBeforeUpload={onBeforeUpload}
        onAfterUpload={onAfterUpload}
        onFileDelete={onFileDelete}
        renderPreview={
          !!renderPreview
            ? (arg) => renderPreview({ ...arg, input, meta })
            : renderPreview
        }
      />
    </>
  );
};
