import React, { Suspense } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import cs from "classnames";

import {
  Box,
  FormHelperText,
  FormLabel,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CommonLexicalEditorLazy } from "@js/components/rich-text-editor/common-editor";
import type { CommonLexicalEditorProps } from "@js/components/rich-text-editor/common-editor/common-editor";
import type { ToolbarPluginProps } from "@js/components/rich-text-editor/custom-plugins/toolbar";
import { getTextCount } from "@js/utils";

import styles from "./rich-textarea.module.scss";

type RichTextareaFieldProps = Omit<
  CommonLexicalEditorProps,
  "onChange" | "name"
> & {
  className?: string;
  id?: string;
  label?: string;
  maxLabel?: boolean;
  maxTextLength?: number;
  minTextLength?: number;
  required?: boolean;
  borderless?: boolean;
  maxHeight?: number;
  hideError?: boolean;
  placeholder?: React.ReactNode;
  pasteAsText?: boolean;
  toolbar?: ToolbarPluginProps["toolbar"];
  minHeight?: React.CSSProperties["height"];
} & TypedWrappedFieldProps<string>;

export const RichTextareaField = ({
  className,
  maxHeight = 500,
  id,
  input,
  label,
  maxLabel,
  maxTextLength,
  meta,
  minTextLength,
  required,
  borderless = false,
  hideError,
  pasteAsText,
  toolbar,
  minHeight = 300,
  ...props
}: RichTextareaFieldProps) => {
  const hasError = meta.error && meta.touched;

  return (
    <Box
      sx={{
        minHeight,
        minWidth: "100%",
      }}
      className={cs(styles.richTextareaWrapper, className, {
        borderless,
      })}
    >
      <Suspense fallback={<Loader className="center" />}>
        {!!label && (
          <FormLabel htmlFor={id} error={hasError} required={required}>
            <Typography
              component="p"
              style={{ display: "inline-block" }}
              variant="label"
              pb={1}
            >
              {label}
            </Typography>
          </FormLabel>
        )}
        {maxTextLength && (
          <Typography component="p" className={styles.textLengthIndicator}>
            {getTextCount(input.value)} / {maxTextLength}
          </Typography>
        )}
        <CommonLexicalEditorLazy
          id={id}
          meta={meta}
          input={input}
          onChange={input.onChange}
          toolbar={toolbar}
          maxHeight={maxHeight}
          pasteAsText={pasteAsText}
          minHeight={minHeight}
          name={input.name}
          {...props}
        />
        {(minTextLength || maxLabel) && (
          <Typography
            component="p"
            pt={1}
            sx={{ fontSize: "12px !important", color: "var(--grey-3)" }}
          >
            {minTextLength && <span>Min {minTextLength} characters. </span>}
            {maxLabel && <span>Max {maxTextLength} characters. </span>}
          </Typography>
        )}
        {hasError && !hideError && (
          <FormHelperText error>{meta.error}</FormHelperText>
        )}
      </Suspense>
    </Box>
  );
};
