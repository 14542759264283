import { Field } from "redux-form";

import { Box } from "@hexocean/braintrust-ui-components";
import type { BudgetOptions } from "@js/apps/give-and-get-help/components";
import { BudgetField } from "@js/apps/give-and-get-help/forms/fields/budget-field";

import { BudgetLabel } from "./budget-label";

export type BudgetFieldProps = {
  options: BudgetOptions[];
};

export const ServicesBudgetField = ({ options }: BudgetFieldProps) => {
  if (!options) return null;

  return (
    <Box my={3}>
      <BudgetLabel>What do you want to charge?</BudgetLabel>

      <Box mt={2}>
        <Field name="budget" component={BudgetField} options={options} />
      </Box>
    </Box>
  );
};
