import type { TextFieldProps } from "@hexocean/braintrust-ui-components";
import {
  Stack,
  TextField,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ErrorSVGIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { TypedWrappedFieldProps } from "@js/forms/utils";

import styles from "./styles.module.scss";

export const CalendarLinkTextField = ({
  input,
  meta,
  ...props
}: TypedWrappedFieldProps<string> & Partial<TextFieldProps>) => {
  return (
    <Stack>
      <TextField
        id="calendar_link"
        className={styles.calendarLinkInput}
        value={input.value}
        error={meta?.touched && !!meta?.error}
        onChange={(evt) => input.onChange(evt.target.value)}
        {...props}
      />
      {meta?.touched && meta?.error && (
        <Typography
          component="span"
          variant="paragraph"
          color="negative"
          size="small"
          marginTop={1}
          display="flex"
          alignItems="center"
          gap={0.5}
        >
          <ErrorSVGIcon fontSize="small" />
          {meta?.error}
        </Typography>
      )}
    </Stack>
  );
};
