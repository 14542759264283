import { useCallback } from "react";

import { useUser } from "@js/apps/common/hooks";
import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import { useFreelancerProfile } from "@js/apps/freelancer/hooks";
import { useMarkCareerHelpAsVisitedMutation } from "@js/apps/give-and-get-help/api";
import { useAppDispatch } from "@js/hooks";

export const useMarkCareerHelpVisited = () => {
  const user = useUser();
  const freelancerProfile = useFreelancerProfile();
  const dispatch = useAppDispatch();
  const [markCareerHelpAsVisited] = useMarkCareerHelpAsVisitedMutation();

  const markGGHAsVisited = useCallback(async (): Promise<void | string> => {
    if (!user?.freelancer) {
      return;
    }

    await markCareerHelpAsVisited();
    dispatch(fetchFreelancerProfile(user.freelancer));
  }, [markCareerHelpAsVisited, dispatch, user?.freelancer]);

  useEffectOnce(() => {
    if (
      !!freelancerProfile &&
      !freelancerProfile?.user?.has_visited_career_help &&
      !user?.is_impersonated_session
    ) {
      markGGHAsVisited();
    }
  });
};
