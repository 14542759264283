import type { TypedWrappedFieldProps } from "redux-form";

import { usePendingUploadSnackbar } from "../../hooks/use-pending-upload-snackbar";
import { useSelectedDisplayedImage } from "../../hooks/use-selected-displayed-image";
import type { ExistingFile, UploadedFile } from "../../types";
import { FileUploadFieldLabel } from "../file-upload-field-label";

import { ImageUpload, type ImageUploadProps } from "./image-upload";

export type ImageUploadFieldProps = {
  label?: string;
  existingImages?: Array<ExistingFile>;
} & TypedWrappedFieldProps<number> &
  Omit<ImageUploadProps, "onUpload" | "onBeforeUpload" | "onAfterUpload">;

export const ImageUploadField = ({
  label,
  input,
  meta,
  displayedImage,
  existingImages,
  ...rest
}: ImageUploadFieldProps) => {
  const { onBeforeUpload, onAfterUpload } = usePendingUploadSnackbar({
    input,
    meta,
  });

  const onUpload = (uploadedFile: UploadedFile) => {
    input.onChange(uploadedFile.id);
  };

  const { value } = input;
  const { selectedDisplayedImage } = useSelectedDisplayedImage({
    displayedImage,
    existingImages,
    value,
  });

  return (
    <>
      {!!label && <FileUploadFieldLabel>{label}</FileUploadFieldLabel>}
      <ImageUpload
        {...rest}
        displayedImage={selectedDisplayedImage}
        onBeforeUpload={onBeforeUpload}
        onAfterUpload={onAfterUpload}
        onUpload={onUpload}
      />
    </>
  );
};
