import { useCallback, useEffect, useState } from "react";

import { useAccountType } from "@js/apps/common/hooks";
import { useLazyGetFreelancerBidsPaginatedQuery } from "@js/apps/freelancer/api";
import { useAppSelector } from "@js/hooks";
import { LocalStorage } from "@js/services";

export enum ApplicationInsightType {
  HOME_PAGE_NEWEST_MATCH_CARD = "application_insight_newest_match_homepage_card",
  HOME_PAGE_APPLIED_JOBS_CARD = "application_insight_homepage_applied_jobs_card",
  JOB_LIST_BANNER = "application_insight_job_list_banner",
}

type ApplicationInsightIntervalAds = {
  isVerifying: boolean;
  showJobInsightAds: boolean;
  setCloseJobInsightAds: () => void;
};

const useApplicationInsightIntervalAds = (
  type: ApplicationInsightType,
): ApplicationInsightIntervalAds => {
  const [getFreelancerBids] = useLazyGetFreelancerBidsPaginatedQuery();
  const [show, setShow] = useState<boolean>(false);
  const [isVerifying, setIsVerifying] = useState<boolean>(true);

  const user = useAppSelector((state) => state.auth.user);
  const { isFreelancer } = useAccountType();

  const getDismissTimeKey = useCallback(() => {
    switch (type) {
      case ApplicationInsightType.HOME_PAGE_NEWEST_MATCH_CARD:
        return LocalStorage.keys
          .APPLICATION_INSIGHT_HOMEPAGE_NEWEST_MATCH_CARD_CLOSE_TIMESTAMP;
      case ApplicationInsightType.HOME_PAGE_APPLIED_JOBS_CARD:
        return LocalStorage.keys
          .APPLICATION_INSIGHT_HOMEPAGE_APPLIED_JOBS_CARD_CLOSE_TIMESTAMP;
      case ApplicationInsightType.JOB_LIST_BANNER:
        return LocalStorage.keys
          .APPLICATION_INSIGHT_JOB_LIST_BANNER_CLOSE_TIMESTAMP;
      default:
        return null;
    }
  }, [type]);

  const isPastTwoHours = (dismissTime: string | null): boolean => {
    if (!dismissTime) {
      return true;
    }
    const twoHoursLater = new Date(parseInt(dismissTime) + 2 * 60 * 60 * 1000);
    return new Date() > twoHoursLater;
  };

  const verifyJobsBidCount = useCallback(async () => {
    if (
      type === ApplicationInsightType.HOME_PAGE_NEWEST_MATCH_CARD ||
      type === ApplicationInsightType.JOB_LIST_BANNER
    ) {
      const { data = { count: 0 } } = await getFreelancerBids({
        ordering: "-created",
        page_size: 1,
        current: true,
      });

      return data?.count < 1;
    }

    return true;
  }, [getFreelancerBids, type]);

  const verifyShowingAds = useCallback(async () => {
    if (!isFreelancer) {
      setIsVerifying(false);
      return;
    }

    const dismissTimeKey = getDismissTimeKey();
    const dismissTime = dismissTimeKey
      ? LocalStorage.getItem(dismissTimeKey)
      : null;
    const hasNoJobsBid = await verifyJobsBidCount();

    if (
      !user?.can_view_insights &&
      isPastTwoHours(dismissTime) &&
      hasNoJobsBid
    ) {
      setShow(true);
    } else {
      setShow(false);
    }

    setIsVerifying(false);
  }, [
    getDismissTimeKey,
    verifyJobsBidCount,
    isFreelancer,
    user?.can_view_insights,
  ]);

  useEffect(() => {
    verifyShowingAds();
  }, [verifyShowingAds, type]);

  const handleCloseJobAds = useCallback(() => {
    setShow(false);
    const localStorageTimeStampKey = getDismissTimeKey();

    if (localStorageTimeStampKey) {
      LocalStorage.setItem(
        localStorageTimeStampKey,
        String(new Date().getTime()),
      );
    }
  }, [getDismissTimeKey]);

  return {
    isVerifying,
    showJobInsightAds: show,
    setCloseJobInsightAds: handleCloseJobAds,
  };
};

export default useApplicationInsightIntervalAds;
