import { useEffect } from "react";

import { useNavigate } from "@js/hooks";
import { typeGuard } from "@js/utils";

export const useHandleGetSpaceError = (error: unknown) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!error) {
      return;
    }

    const errorStatus = typeGuard<unknown, { status: number }>(error, "status")
      ? error.status
      : undefined;

    const isServerError = errorStatus && String(errorStatus).startsWith("5"); // 5xx
    if (isServerError) {
      throw new Error("Space details error"); // Something went wrong page
    }

    if (errorStatus === 403) {
      return navigate("/access-denied", { replace: true });
    }

    return navigate("/page-not-found/?reloaded=true", { replace: true });
  }, [error, navigate]);
};
