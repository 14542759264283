import type { ButtonVariants } from "@hexocean/braintrust-ui-components";
import {
  BraintrustIcon,
  MarketingFlagIcon,
  PeopleIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import type {
  EmployerReferralsOrderByType,
  TalentReferralsOrderByType,
} from "./types";

export const WALLET_INFO_CARDS = {
  governance: {
    header: "You found a better way on Braintrust. Make it even better.",
    text: `Braintrust was created to ensure that the people who rely on it also
      have control over the way it runs. By cutting out the middleman, and
      giving that control back to our users, we ensure that the platform
      evolves in the way that is serving everyone best.`,
    buttonText: "Learn more",
    buttonVariant: "dark-violet" as ButtonVariants,
    icon: MarketingFlagIcon,
    link: "https://www.usebraintrust.com/governance",
  },
  feed: {
    header: "Give and get community professional development help.",
    text: `Provide quality advice to Braintrust community members and earn BTRST
      by offering direct 1:1 help.  Set your rate based on your expertise and the
      longevity of help you can provide.
      Not ready to give advice? Uplevel your career by asking questions and engaging
      in 1:1 help with community experts.
      `,
    buttonText: "View feed",
    buttonVariant: "positive-2" as ButtonVariants,
    icon: PeopleIcon,
    link: "/feed",
  },
  escrow: {
    header: "Unvested tokens and escrow: what it means",
    text: `Braintrust offers a payment guarentee for accepted 1:1 help engagements.
    The agreed upon BTRST amount is held in the Braintrust treasury as an escrow account.
    These tokens cannot be used by either party until the 1:1 engagement has been ended.`,
    buttonText: "Learn more",
    buttonVariant: "dark-orange" as ButtonVariants,
    icon: BraintrustIcon,
    link: SETTINGS.SUPPORT_URLS.HOW_TO_EARN_AND_USE_BTRST_URL,
  },
};

export const EMPLOYER_REFERRALS_ORDER_BY = {
  CREATED: "created",
  NAME: "name",
  TOTAL_JOBS: "total_jobs",
  TOTAL_HIRED_COUNT: "total_talent_hired_count",
  TOTAL_BILLED: "total_billed",
} as const satisfies Record<string, EmployerReferralsOrderByType>;

export const TALENT_REFERRALS_ORDER_BY = {
  CREATED: "created",
  PUBLIC_NAME: "public_name",
  BIDS_COUNT: "bids_count",
  ACCEPTED_OFFERS_COUNT: "accepted_offers_count",
  TALENT_TOTAL_EARNED: "talent_total_earned",
} as const satisfies Record<string, TalentReferralsOrderByType>;

export const CLIENT_REFERRAL_SORT_VALUES_MAP: Record<
  EmployerReferralsOrderByType,
  string
> = {
  [EMPLOYER_REFERRALS_ORDER_BY.CREATED]: "sign up date",
  [EMPLOYER_REFERRALS_ORDER_BY.NAME]: "client referral",
  [EMPLOYER_REFERRALS_ORDER_BY.TOTAL_JOBS]: "jobs",
  [EMPLOYER_REFERRALS_ORDER_BY.TOTAL_HIRED_COUNT]: "hires",
  [EMPLOYER_REFERRALS_ORDER_BY.TOTAL_BILLED]: "billings",
};

export const TALENT_REFERRAL_SORT_VALUES_MAP: Record<
  TalentReferralsOrderByType,
  string
> = {
  [TALENT_REFERRALS_ORDER_BY.CREATED]: "sign up date",
  [TALENT_REFERRALS_ORDER_BY.PUBLIC_NAME]: "talent referral",
  [TALENT_REFERRALS_ORDER_BY.BIDS_COUNT]: "applied",
  [TALENT_REFERRALS_ORDER_BY.ACCEPTED_OFFERS_COUNT]: "hired",
  [TALENT_REFERRALS_ORDER_BY.TALENT_TOTAL_EARNED]: "billings",
};

export const DEFAULT_TALENT_REFERRALS_ORDER_BY =
  TALENT_REFERRALS_ORDER_BY.CREATED;

export const DEFAULT_EMPLOYER_REFERRALS_ORDER_BY =
  EMPLOYER_REFERRALS_ORDER_BY.CREATED;

export const TALENT_REFERRALS_ORDER_BY_OPTIONS = [
  {
    value: TALENT_REFERRALS_ORDER_BY.CREATED,
    label: "Sign up date",
  },
  {
    value: TALENT_REFERRALS_ORDER_BY.PUBLIC_NAME,
    label: "Talent name",
  },
  {
    value: TALENT_REFERRALS_ORDER_BY.BIDS_COUNT,
    label: "Applied",
  },
  {
    value: TALENT_REFERRALS_ORDER_BY.ACCEPTED_OFFERS_COUNT,
    label: "Hired",
  },
  {
    value: TALENT_REFERRALS_ORDER_BY.TALENT_TOTAL_EARNED,
    label: "Billings",
  },
] as const;

export const EMPLOYER_REFERRALS_ORDER_BY_OPTIONS = [
  {
    value: EMPLOYER_REFERRALS_ORDER_BY.CREATED,
    label: "Sign up date",
  },
  {
    value: EMPLOYER_REFERRALS_ORDER_BY.NAME,
    label: "Client name",
  },
  {
    value: EMPLOYER_REFERRALS_ORDER_BY.TOTAL_JOBS,
    label: "Jobs",
  },
  {
    value: EMPLOYER_REFERRALS_ORDER_BY.TOTAL_HIRED_COUNT,
    label: "Hires",
  },
  {
    value: EMPLOYER_REFERRALS_ORDER_BY.TOTAL_BILLED,
    label: "Billings",
  },
] as const;
