import { useEffect, useState } from "react";

import { useDebounce } from "@js/hooks/use-debounce";

export const useRoomListFilters = () => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [filters, setFilters] = useState({ page: 1, name: debouncedSearch });

  useEffect(() => {
    setFilters((prev) => {
      const hasSearchChanged = prev.name !== debouncedSearch;

      return {
        name: debouncedSearch,
        page: hasSearchChanged ? 1 : prev.page,
      };
    });
  }, [debouncedSearch]);

  const setPage = (page: number) => {
    setFilters((prev) => {
      return {
        ...prev,
        page,
      };
    });
  };

  return { search, setSearch, filters, debouncedSearch, setPage };
};
