import type { RefObject } from "react";
import { useRef } from "react";

import { insightsBannerViewed } from "@js/apps/jobs/actions";
import { useAppDispatch } from "@js/hooks";
import { useOnElementVisible } from "@js/hooks/use-on-element-visible";

export const useViewInsightsBanner = (ref: RefObject<HTMLElement>) => {
  const dispatch = useAppDispatch();
  const hasDispatched = useRef(false);

  const insightsBannerVisible = (isVisible: boolean) => {
    if (isVisible && !hasDispatched.current) {
      dispatch(insightsBannerViewed());
      hasDispatched.current = true;
    }
  };
  useOnElementVisible(ref, insightsBannerVisible, {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  });
};
