import { z } from "zod";

export const SKILLS_FILTER_SCHEMA = z
  .string()
  .nullable()
  .transform((value) => {
    if (!value) return [];
    return value
      .split(",")
      .map(Number)
      .filter((num) => !isNaN(num));
  })
  .catch([]);

export type SkillsFilter = z.infer<typeof SKILLS_FILTER_SCHEMA>;
