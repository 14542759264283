import { useMemo } from "react";
import type { z } from "zod";

import { useQueryParams } from "@js/hooks/query-params";
import type { RhfForm } from "@js/rhf/types";

export const useParsedQueryParams = <T extends z.ZodRawShape>(
  zodSchema: z.ZodEffects<z.ZodObject<T>> | z.ZodObject<T>,
) => {
  const originalParams = useQueryParams();
  const parsedFilters = useMemo(
    () => zodSchema.safeParse(originalParams),
    [originalParams, zodSchema],
  );

  return {
    data: parsedFilters.success ? parsedFilters.data : undefined,
    success: parsedFilters.success,
    parsedObject: parsedFilters,
    originalParams,
  };
};

export const useParsedQueryParamsWithDefaults = <T extends z.ZodRawShape>(
  zodSchema: z.ZodEffects<z.ZodObject<T>> | z.ZodObject<T>,
  defaultValues: RhfForm<z.ZodObject<T>>["DefaultValues"],
) => {
  const { parsedObject, ...result } = useParsedQueryParams(zodSchema);

  const data = useMemo(() => {
    return parsedObject.success
      ? { ...defaultValues, ...parsedObject.data }
      : defaultValues;
  }, [parsedObject, defaultValues]);

  return {
    ...result,
    data,
  };
};
