import { useCallback } from "react";

import { useNavigate } from "@js/hooks";
import type { IPost } from "@js/types/give-and-get-help";

import { getPostPath } from "../../utils";

export const useRedirectToActivePost = () => {
  const navigate = useNavigate();

  const redirectToActivePost = useCallback(
    (post: IPost) => {
      if (post.space) {
        navigate(getPostPath(post));
        return;
      }
      navigate(`/feed?activePost=${post.id}`);
    },
    [navigate],
  );

  return redirectToActivePost;
};
