import React from "react";

import {
  Box,
  Loader,
  RoundedBox,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { PaymentMethodFailedTopBar } from "@js/apps/employer/components/payment-method-failed-top-bar";

import {
  AdditionalInfo,
  EmployerNavigation,
  EmployerOfferPreviewStatus,
  OfferDetails,
  TalentAvatarAndPersonalDetails,
} from "../../components";
import {
  EquipmentShippingAddressFields,
  EquipmentShippingAddressFormInstance,
} from "../../forms";
import { useEmployerOffer } from "../../hooks";
import { OfferLayout, OfferPreviewContentLayout } from "../../layout";

import styles from "./style.module.scss";

export const EmployerPreviewOfferPage = () => {
  return (
    <Stack className={styles.container}>
      <PaymentMethodFailedTopBar />
      <EmployerPreviewOfferPageContent />
    </Stack>
  );
};

const EmployerPreviewOfferPageContent = () => {
  const { loading, offer, offerId, job } = useEmployerOffer();

  if (loading || !offer || !job) {
    return (
      <OfferLayout navigationSocket={null}>
        <Loader />
      </OfferLayout>
    );
  }

  return (
    <OfferLayout
      navigationSocket={
        <EmployerNavigation
          jobId={offer.bid.job.id}
          jobTitle={offer.job_title}
          offerStatus={offer.status}
          offerId={offerId}
          interlocutor={offer.bid.freelancer.user.id}
          previewPage
        />
      }
    >
      <OfferPreviewContentLayout
        leftSocket={
          <>
            <RoundedBox px={4} py={3} bgcolor="var(--medium-green)">
              <TalentAvatarAndPersonalDetails
                hideNamePrefix
                bid={offer.bid}
                boldedName={false}
              />
            </RoundedBox>
            <EmployerOfferPreviewStatus offer={offer} />
          </>
        }
        rightSocket={
          <>
            <OfferDetails showUpdatedLabel={false} offer={offer} job={job} />
            <AdditionalInfo
              offer={offer}
              bottomSocket={
                !offer.shipping_address ? null : (
                  <Box>
                    <Typography
                      mt={4}
                      mb={3}
                      component="p"
                      variant="label"
                      size="large"
                    >
                      Equipment shipping address
                    </Typography>
                    <EquipmentShippingAddressFormInstance
                      initialValues={{ address: offer.shipping_address }}
                    >
                      <EquipmentShippingAddressFields
                        countryAsTextField
                        disableAll
                      />
                    </EquipmentShippingAddressFormInstance>
                  </Box>
                )
              }
            />
          </>
        }
      />
    </OfferLayout>
  );
};
