import { useEffect, useState } from "react";

import { useGetJobDraftsQuery, useGetOwnJobsQuery } from "@js/apps/jobs/api";
import { Snackbar } from "@js/components/snackbar";
import type { EmployerOwnJob, GetJobDraftResponse } from "@js/types/jobs";

import type { DRAFT_STATUS } from ".";
import { useAccordionWithLocalStorage } from "./accordion";
import { type JobStatusDisplayed } from ".";

type UseJobAccordionReturn = {
  expanded: boolean | undefined;
  toggle: (arg?: { saveInStore?: boolean | undefined } | undefined) => void;
  loading: boolean;
  count: number | undefined;
};

const useGenericAccordion = ({
  defaultExpanded,
  status,
}: {
  defaultExpanded?: boolean;
  status: JobStatusDisplayed | typeof DRAFT_STATUS;
}): Omit<UseJobAccordionReturn, "loading" | "count"> & {
  isInitialFetch: boolean;
  limit: undefined | 0;
  setIsInitialFetch: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const { toggle, expanded } = useAccordionWithLocalStorage({
    storageKey: `job-accordion-${status}`,
    defaultExpanded,
  });

  const [isInitialFetch, setIsInitialFetch] = useState<boolean>(true);
  const limit = expanded || isInitialFetch ? undefined : 0;

  return {
    expanded,
    toggle,
    isInitialFetch,
    limit,
    setIsInitialFetch,
  };
};

export const useJobAccordion = ({
  status,
  defaultExpanded,
}: {
  status: JobStatusDisplayed;
  defaultExpanded?: boolean;
}): UseJobAccordionReturn & {
  results: EmployerOwnJob[];
} => {
  const { expanded, toggle, isInitialFetch, limit, setIsInitialFetch } =
    useGenericAccordion({
      status,
      defaultExpanded: defaultExpanded,
    });

  const { data, isLoading, error } = useGetOwnJobsQuery(
    { status: status, limit },
    { refetchOnMountOrArgChange: true, skip: !isInitialFetch && !expanded },
  );

  useEffect(() => {
    if (!isLoading) {
      setIsInitialFetch(false);
    }
  }, [isLoading, setIsInitialFetch]);

  useHandleError(error);

  return {
    expanded: expanded,
    toggle,
    results: data?.results || [],
    loading: isLoading,
    count: data?.count,
  };
};

type UseDraftJobAccordionReturn = UseJobAccordionReturn & {
  results: GetJobDraftResponse[];
  showReviewButton: boolean;
};

export const useDraftAccordion = ({
  status,
}: {
  status: typeof DRAFT_STATUS;
}): UseDraftJobAccordionReturn => {
  const { expanded, toggle, isInitialFetch, limit, setIsInitialFetch } =
    useGenericAccordion({
      status,
    });

  const { data, isLoading, error } = useGetJobDraftsQuery(
    { limit },
    { refetchOnMountOrArgChange: true, skip: !isInitialFetch && !expanded },
  );

  const showReviewButton =
    data &&
    data.results.length > 0 &&
    data?.results.some((draft) => !!draft.ats_imported);

  useEffect(() => {
    if (!isLoading) {
      setIsInitialFetch(false);
    }
  }, [isLoading, setIsInitialFetch]);

  useHandleError(error);

  return {
    expanded: expanded,
    toggle,
    results: data?.results || [],
    loading: isLoading,
    count: data?.count,
    showReviewButton: !!showReviewButton,
  };
};

const useHandleError = (error: unknown) => {
  useEffect(() => {
    if (error) {
      Snackbar.error("Something went wrong, please try again later.");
    }
  }, [error]);
};
