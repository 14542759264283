import { Box, Button } from "@hexocean/braintrust-ui-components";
import {
  EditPenIcon,
  PersonAddIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import type { Job } from "@js/types/jobs";

type JobButtonProps = {
  job: Job;
};

export const EditJobButton = ({ job }: JobButtonProps) => {
  if (!job) return null;

  return (
    <Button
      variant="secondary"
      inverse
      to={`/jobs/${job.id}/edit/`}
      RouterLink={RouterLink}
    >
      Edit Job
      <Box display="flex" ml={1} alignItems="center">
        <EditPenIcon height={22} />
      </Box>
    </Button>
  );
};

export const ViewJobBidsButton = ({ job }: JobButtonProps) => {
  if (!job) return null;

  return (
    <Button
      variant="primary"
      inverse
      to={`/jobs/${job?.id}/proposals/?back=${encodeURIComponent(
        window.location.pathname,
      )}`}
      RouterLink={RouterLink}
    >
      View Applications
    </Button>
  );
};

export const InviteTalentsButton = ({ job }: JobButtonProps) => {
  if (!job) return null;

  return (
    <Button
      variant="secondary"
      inverse
      to={`/jobs/${job?.id}/invite_matched_talent/`}
      RouterLink={RouterLink}
    >
      Invite matched Talent
      <Box display="flex" ml={1} alignItems="center">
        <PersonAddIcon height={22} />
      </Box>
    </Button>
  );
};
