import type { FC } from "react";
import { Field } from "redux-form";

import {
  Box,
  Button,
  Divider,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { createFormInstance } from "@js/forms/components";
import { TextareaField } from "@js/forms/fields";
import { requiredWithSpecificMessage } from "@js/forms/validators";

import type { AboutThisSpaceFormProps, AboutThisSpaceProps } from "../types";

import styles from "./styles.module.scss";

const AboutThisSpaceFormInstance = createFormInstance<
  AboutThisSpaceProps,
  unknown
>("about-this-space-form");

const descriptionFieldLabel = "What is this space about?*";
const requiredErrorMessage = "Enter a description of this space";
const descriptionValidator = [
  requiredWithSpecificMessage(requiredErrorMessage),
];

export const AboutThisSpaceForm: FC<AboutThisSpaceFormProps> = (props) => {
  return (
    <AboutThisSpaceFormInstance {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" gap={1} alignItems="center">
          <Typography
            component="h3"
            variant="title"
            size="small"
            fontWeight={400}
          >
            About this space
          </Typography>
        </Box>

        <Field
          label={descriptionFieldLabel}
          name="description"
          id="description"
          component={TextareaField}
          InputProps={{
            className: styles.input,
          }}
          maxLength={1000}
          aria-label={descriptionFieldLabel}
          validate={descriptionValidator}
        />
        <Divider sx={{ borderColor: "var(--grey-4)" }} />
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="positive"
            shape="squared"
            type="submit"
            size="medium"
            aria-label="save-space-description"
          >
            Save
          </Button>
        </Box>
      </Box>
    </AboutThisSpaceFormInstance>
  );
};
