import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Button } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

type ViewOfferButtonProps = {
  job: Job;
  bid: EmployerBidListBid;
} & Partial<ButtonProps>;

export const ViewOfferButton = ({
  job,
  bid,
  ...buttonProps
}: ViewOfferButtonProps) => {
  const previewUrl = `/jobs/${job.id}/offers/${bid.current_offer}/`;

  return (
    <Button
      to={previewUrl}
      variant="primary"
      sx={{ height: 42 }}
      shape="squared"
      RouterLink={RouterLink}
      {...buttonProps}
    >
      View offer
    </Button>
  );
};
