import { useEffect } from "react";

import { useWebsocket } from "@js/hooks";
import type { Message } from "@js/types/messenger";

export const useMarkMessagesAsRead = (messages?: Message[]) => {
  const { send } = useWebsocket();
  const shouldMarkAsRead = !!messages?.length;

  useEffect(() => {
    if (shouldMarkAsRead) {
      const { room, created } = messages[messages.length - 1];

      send(ENUMS.StreamType.MESSAGE_MARK_AS_READ, {
        room,
        timestamp: created,
      } as any);
    }
  }, [send, shouldMarkAsRead, messages]);
};
