import React, { useMemo } from "react";
import classNames from "classnames";

import type { Job, MainJobListingJob } from "@js/types/jobs";
import { formatAbbreviatedBudgetWithPrefix, formatBudget } from "@js/utils";

type JobBudgetProps = {
  job: Pick<
    Job | MainJobListingJob,
    "payment_type" | "budget_minimum_usd" | "budget_maximum_usd"
  >;
  className?: string;
  round?: boolean;
  removeDecimal?: boolean;
  formatWithPrefix?: boolean;
};

export const JobBudget = ({
  job,
  className,
  removeDecimal,
  formatWithPrefix = false,
}: JobBudgetProps) => {
  const budget = useMemo(() => {
    if (formatWithPrefix) {
      return formatAbbreviatedBudgetWithPrefix(
        job.budget_minimum_usd,
        job.budget_maximum_usd,
        { paymentType: job.payment_type, hourlyRateSuffix: "/hr" },
      );
    }

    return formatBudget(job.budget_minimum_usd, job.budget_maximum_usd, {
      removeDecimal: removeDecimal,
      paymentType: job.payment_type,
      hourlyRateSuffix: "/hr",
    });
  }, [formatWithPrefix, job, removeDecimal]);

  return <span className={classNames(className)}>{budget}</span>;
};
