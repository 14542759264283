import { Box, Divider, Typography } from "@hexocean/braintrust-ui-components";
import { ReviewBeforeSendingSummaryBox } from "@js/apps/jobs/components";
import { Modal } from "@js/components/modal";

import { useCreateOrEditOfferMeta } from "../../hooks/create-or-edit-offer-meta";
import type { CreateOrEditOfferFormData } from "../../types";
import { TalentAvatarAndPersonalDetails } from "../talent-avatar-and-personal-details";

import { ReviewBeforeSendingModalHeader, SummaryItems } from "./components";

import styles from "./style.module.scss";

const REVIEW_BEFORE_SENDING_MODAL_ID = "REVIEW_BEFORE_SENDING_MODAL";

export const ReviewBeforeSendingModal = Modal(REVIEW_BEFORE_SENDING_MODAL_ID, {
  className: styles.modalWrapper,
  disablePortal: true,
  closeButton: false,
  padding: false,
});

type ReviewBeforeSendingModalContentProps = {
  onSubmit?: (x?: CreateOrEditOfferFormData) => void;
  values?: CreateOrEditOfferFormData;
};

export const ReviewBeforeSendingModalContent = ({
  onSubmit,
  values,
}: ReviewBeforeSendingModalContentProps) => {
  const meta = useCreateOrEditOfferMeta();

  const showACHNotVerifiedInfo =
    values?.deposit_payment_method?.content_type ===
      ENUMS.PaymentContentType.StripeACHPaymentMethod &&
    !values.deposit_payment_method.method.verified_at;

  return (
    <>
      <ReviewBeforeSendingModalHeader
        onSubmit={onSubmit}
        onGoBack={ReviewBeforeSendingModal.close}
        values={values}
        meta={meta}
      />
      <Divider color="beige" />
      <div className={styles.content}>
        <Typography
          variant="title"
          size="small"
          component="h2"
          fontWeight={400}
          className={styles.info}
        >
          Make sure these details are right before sending your offer
        </Typography>

        <div className={styles.summaryWrapper}>
          {meta && (
            <div className={styles.summaryWrapperTalentDetails}>
              <TalentAvatarAndPersonalDetails boldedName bid={meta.bid} />
            </div>
          )}

          <Box
            sx={{ flex: 1, width: "100%", maxWidth: { sx: "100%", lg: "60%" } }}
          >
            {showACHNotVerifiedInfo && (
              <div className={styles.achInfo}>
                <Typography component="p" size="small">
                  This offer won’t be sent to {meta?.talent_name} until we can
                  verify your banking details. This could take 3 - 5 days.
                </Typography>
              </div>
            )}

            <ReviewBeforeSendingSummaryBox>
              <SummaryItems meta={meta} />
            </ReviewBeforeSendingSummaryBox>
          </Box>
        </div>
      </div>
    </>
  );
};
