import type { RefObject } from "react";
import { useContext } from "react";

import { useAppDispatch } from "@js/hooks";
import { useOnElementVisible } from "@js/hooks/use-on-element-visible";
import type { IPost, PostComment } from "@js/types/give-and-get-help";

import { postViewed } from "../../actions";
import { usePostLocationContext } from "../../context/post-location";
import { PostViewedContext } from "../../context/post-viewed";

export const useTrackPostVisible = (
  entity: IPost | PostComment,
  ref: RefObject<HTMLElement>,
) => {
  const postId = entity.main_post_id || entity.id;
  const commentId = entity.main_post_id ? entity.id : null;

  const postLocation = usePostLocationContext();
  const { viewedIds, setViewedIds } = useContext(PostViewedContext);
  const dispatch = useAppDispatch();

  const trackPostVisible = (isVisible: boolean) => {
    const isPostIdAlreadyAdded = viewedIds.has(postId);
    const isCommentIdAlreadyAdded =
      commentId && viewedIds.get(postId)?.includes(commentId);

    if (!isVisible) return;

    if (!isPostIdAlreadyAdded) {
      dispatch(postViewed({ postId, entity, post_location: postLocation }));
      setViewedIds((prevViewedIds) => prevViewedIds.set(postId, []));
      return;
    }

    if (!isCommentIdAlreadyAdded && commentId) {
      dispatch(postViewed({ postId, entity, post_location: postLocation }));
      setViewedIds((prevViewedIds) => {
        const prevCommentIds = prevViewedIds.get(postId);
        if (!prevCommentIds) return prevViewedIds;

        const newCommentIds = [...prevCommentIds, commentId];
        return prevViewedIds.set(postId, newCommentIds);
      });
    }
  };
  useOnElementVisible(ref, trackPostVisible, { threshold: 0.5 });
};
