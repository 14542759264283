import type { ReactNode } from "react";

import { Box } from "@hexocean/braintrust-ui-components";

import style from "./style.module.scss";

type OfferNavigationProps = {
  leftSocket?: ReactNode;
  centerSocket?: ReactNode;
  rightSocket?: ReactNode;
};

export const OfferNavigation = ({
  leftSocket,
  centerSocket,
  rightSocket,
}: OfferNavigationProps) => {
  return (
    <Box className={style.navigationBar}>
      {leftSocket && <SocketContainer>{leftSocket}</SocketContainer>}
      {centerSocket}
      <SocketContainer>{rightSocket}</SocketContainer>
    </Box>
  );
};

type SocketContainerProps = { children: ReactNode };

const SocketContainer = ({ children }: SocketContainerProps) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        whiteSpace: "nowrap",
        gap: 1,
      }}
    >
      {children}
    </Box>
  );
};
