import { Box, Typography } from "@hexocean/braintrust-ui-components";
import type { Job, TalentInviteSuggestion } from "@js/types/jobs";

import { useInvitationNotificationManager } from "../../hooks/invitation-notification-manager";
import { InviteTalentCardItem } from "../invite-talent-to-bid-card";
import { FindMoreTalentToInviteCTA } from "../job-management-requirements-cta";

type TalentInviteSuggestionListProps = {
  talentInviteSuggestions: TalentInviteSuggestion[];
  job: Job;
};

export const TalentInviteSuggestionList = ({
  talentInviteSuggestions,
  job,
}: TalentInviteSuggestionListProps) => {
  return (
    <>
      <Box mt={4.5} data-testid="invite-talent-content">
        <Box
          mb={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={{
            xs: "wrap",
            md: "nowrap",
          }}
          gap="8px"
        >
          <Typography fontWeight={400} component="h1" variant="title">
            Invite matched Talent to apply
          </Typography>
        </Box>
        <Box display="grid" gap="16px">
          {talentInviteSuggestions.map((suggestedTalent) => {
            return (
              <TalentInviteSuggestionListItem
                key={suggestedTalent.id}
                talentInviteSuggestion={suggestedTalent}
                job={job}
              />
            );
          })}
        </Box>
      </Box>
      <Box my={6}>
        <FindMoreTalentToInviteCTA job={job} />
      </Box>
    </>
  );
};

type TalentInviteSuggestionListItemProps = {
  talentInviteSuggestion: TalentInviteSuggestion;
  job: Job;
};

const TalentInviteSuggestionListItem = ({
  job,
  talentInviteSuggestion,
}: TalentInviteSuggestionListItemProps) => {
  const { handleInviteNotification } = useInvitationNotificationManager();
  return (
    <Box sx={{ minWidth: 0 }} key={talentInviteSuggestion.id}>
      <InviteTalentCardItem
        freelancer={talentInviteSuggestion}
        job={job}
        notificationHandler={handleInviteNotification}
      />
    </Box>
  );
};
