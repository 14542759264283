import cs from "classnames";

import { RoundedBox, Typography } from "@hexocean/braintrust-ui-components";

import style from "./style.module.scss";

type EmployerStatusBoxProps = {
  label: string;
  description?: JSX.Element | string | null;
  color: "red" | "green" | "orange" | "blue";
};

export const EmployerStatusBox = ({
  label,
  description,
  color,
}: EmployerStatusBoxProps) => {
  return (
    <RoundedBox
      overflow="hidden"
      className={cs({
        [`${style.offerStatus} ${style[color]}`]: !!color,
      })}
    >
      <Typography mb={3} component="h2" fontWeight={500} size="large">
        {label}
      </Typography>
      {description}
    </RoundedBox>
  );
};
