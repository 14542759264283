import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box } from "@hexocean/braintrust-ui-components";
import { CommentsBudget } from "@js/apps/give-and-get-help/components/comments-budget";
import { CreatePostLinkField } from "@js/apps/give-and-get-help/components/link-field";
import type { ReplyType } from "@js/apps/give-and-get-help/types";
import type { OptionWithPrimitiveValue } from "@js/types/common";

import { PostFilesUploadField } from "../../components/post-files-upload-field";

import type { ReplyOpenedField } from "./reply-form";

type ReplyAttachmentFieldsProps = {
  postBudget?: OptionWithPrimitiveValue[];
  type?: ReplyType;
};
export const ReplyAttachmentFields = (props: ReplyAttachmentFieldsProps) => {
  return (
    <Field
      name="openedFields"
      component={ReplyAttachmentFieldsContent}
      {...props}
    />
  );
};

type ReplyAttachmentFieldsContentProps = ReplyAttachmentFieldsProps &
  TypedWrappedFieldProps<"" | ReplyOpenedField[]>;

const ReplyAttachmentFieldsContent = ({
  input,
  postBudget,
  type,
}: ReplyAttachmentFieldsContentProps) => {
  const openedFields = input.value;
  if (!openedFields?.length) {
    return <></>;
  }

  const shouldShowUploader = openedFields.includes("attachment_file");

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Field
        name="attachments"
        component={PostFilesUploadField}
        shouldShowUploader={shouldShowUploader}
      />

      {openedFields.includes("link") && <CreatePostLinkField />}

      {openedFields.includes("offer") && (
        <Field
          name="budget"
          component={CommentsBudget}
          options={postBudget}
          type={type}
        />
      )}
    </Box>
  );
};
