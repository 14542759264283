import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "@js/store";

type ReviewsModalAutoOpensState = {
  autoOpenJobReviewsModal: boolean;
  autoOpenHelpOfferReviewsModal: boolean;
  showWriteReviewFormAfterOpenReviewsModal: boolean;
};

const initialState: ReviewsModalAutoOpensState = {
  autoOpenJobReviewsModal: false,
  autoOpenHelpOfferReviewsModal: false,
  showWriteReviewFormAfterOpenReviewsModal: false,
};

export const reviewsModalAutoOpenSlice = createSlice({
  name: "reviewsModalsAutoOpenSlice",
  initialState,
  reducers: {
    setAutoOpenJobReviewsModal(state, action: PayloadAction<boolean>) {
      state.autoOpenJobReviewsModal = action.payload;
    },
    setAutoOpenHelpOfferReviewsModal(state, action: PayloadAction<boolean>) {
      state.autoOpenHelpOfferReviewsModal = action.payload;
    },
    showWriteReviewFormAfterOpen(state, action: PayloadAction<boolean>) {
      state.showWriteReviewFormAfterOpenReviewsModal = action.payload;
    },
  },
});

export const {
  setAutoOpenJobReviewsModal,
  setAutoOpenHelpOfferReviewsModal,
  showWriteReviewFormAfterOpen,
} = reviewsModalAutoOpenSlice.actions;

export const getShouldAutoOpenJobReviewsModal = (state: RootState) =>
  state.reviewsModals.autoOpenJobReviewsModal;

export const getShouldAutoOpenHelpOfferReviewsModal = (state: RootState) =>
  state.reviewsModals.autoOpenHelpOfferReviewsModal;

export const getShouldShowWriteReviewFormAfterOpenReviewsModal = (
  state: RootState,
) => state.reviewsModals.showWriteReviewFormAfterOpenReviewsModal;
