import { useState } from "react";

import type { LinksFormData } from "@js/apps/give-and-get-help/components";
import type { LinkMetadata } from "@js/types/common";

export const useRemoveLinks = (
  linksValue: LinksFormData,
  linksMetadataValue: LinkMetadata[],
) => {
  const [originalLinks, setOriginalLinks] = useState<LinksFormData>(linksValue);
  const [previousMetadataLink, setPreviousMetadataLink] = useState({});

  const remainingLinks = (
    id: string | number,
    isEditMode: boolean,
    attachmentToRemove: { url: string },
  ) => {
    const filteredValue = linksValue.filter((link) => {
      // when creating a post, and immediately removing a link before posting
      if (typeof link === "object") {
        return link.id !== id;
      }

      // when removing a link after posting (i.e. editing a post)
      return link !== attachmentToRemove.url;
    });

    if (!isEditMode) {
      return filteredValue;
    }

    // when editing the first link either before or after submitting changes
    if (
      originalLinks.length === linksValue.length ||
      !Object.keys(previousMetadataLink).length
    ) {
      setOriginalLinks(linksValue);
      return filteredValue;
    }

    const originalLinksArray = originalLinks.map((link) => {
      if (typeof link === "string") {
        return link;
      }

      return link.id;
    });

    // linksValue doesn't have all the initial links anymore, filtering the original links instead
    const updatedFilteredValue = originalLinksArray.filter((link) => {
      return link !== id;
    });
    return updatedFilteredValue;
  };

  const remainingAttachmentLinks = (
    id: string | number,
    isEditMode: boolean,
    attachmentToRemove: object,
  ) => {
    const filteredMetadataValue = linksMetadataValue.filter(
      (link) => link.id !== id && link.url !== id,
    );

    if (!isEditMode) {
      return filteredMetadataValue;
    }

    setPreviousMetadataLink(attachmentToRemove);

    // choosing different links to edit without submitting the changes
    if (
      originalLinks.length !== linksValue.length &&
      Object.keys(previousMetadataLink).length
    ) {
      return [...filteredMetadataValue, previousMetadataLink];
    }

    return filteredMetadataValue;
  };

  return {
    remainingLinks,
    remainingAttachmentLinks,
  };
};
