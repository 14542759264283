import { mapObject } from "underscore";
import type { QueryDataMap } from "urijs";
import URI from "urijs";

import { useUser } from "@js/apps/common/hooks/use-user";
import { Snackbar } from "@js/components/snackbar";
import { useCopyToClipboard } from "@js/hooks";
import { replaceVarsToText } from "@js/utils";

import { type OptionalParams, ShareLink } from "../../services";

type UseReferModalContentProps = {
  params?: QueryDataMap;
  shareLinksParams?: OptionalParams;
  dictionary?: { [key: string]: string };
};

export const useReferModalContent = ({
  params = {},
  shareLinksParams,
  dictionary,
}: UseReferModalContentProps) => {
  const { handleCopy } = useCopyToClipboard(() =>
    Snackbar.success("URL successfully copied!"),
  );
  const user = useUser();

  const updateVariablesToText = (
    textDictionary: { [key: string]: string },
    variables?: OptionalParams,
  ) => {
    if (!variables) return;
    return mapObject(variables, (val) =>
      replaceVarsToText(val, textDictionary),
    );
  };

  const referralLink = user
    ? new URI(user.referral_link.full_url).search(params).toString()
    : "";

  const referralDictionary = {
    link: referralLink,
    referrer_name: user?.public_name || "",
    ...dictionary,
  };

  const shareLinks = ShareLink.createAllLinks(
    referralLink,
    updateVariablesToText(
      referralDictionary,
      shareLinksParams,
    ) as OptionalParams,
  );

  return {
    handleCopy,
    shareLinks,
    referralLink,
  };
};
