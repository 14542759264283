import type { MutableRefObject } from "react";
import { useCallback, useEffect, useState } from "react";
import _ from "underscore";

export const useAlertsPortal = (
  alertsPortalRef: MutableRefObject<HTMLDivElement | undefined>,
): number => {
  const [alertsHeight, setAlertsHeight] = useState(0);

  const updateAlertsHeight = useCallback(() => {
    if (alertsPortalRef.current) {
      return setAlertsHeight(
        _.reduce(
          alertsPortalRef.current?.children,
          (totalHeight, element) => totalHeight + element.scrollHeight,
          0,
        ),
      );
    }
  }, [alertsPortalRef]);

  const observer = new MutationObserver(updateAlertsHeight);

  useEffect(() => {
    if (alertsPortalRef.current && observer) {
      observer.observe(alertsPortalRef.current, { childList: true });
    }

    updateAlertsHeight();

    return () => observer && observer.disconnect();
  });

  useEffect(() => {
    window.addEventListener("resize", updateAlertsHeight);
    return () => window.removeEventListener("resize", updateAlertsHeight);
  }, [updateAlertsHeight]);

  return alertsHeight;
};
