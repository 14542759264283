import { useCallback } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import type { UseUploadSingleFileArg } from "@js/apps/file-upload";
import {
  FileDropzone,
  FileDropzonePlaceholderDashed,
  usePendingUploadSnackbar,
  useUploadSingleFile,
} from "@js/apps/file-upload";
import { getFileWithId } from "@js/apps/file-upload/helpers/file-upload";
import type { Attachment } from "@js/types/uploads";

type ResumeUploadProps = TypedWrappedFieldProps<{
  id: number;
  attachment: Attachment;
}> & {
  onUpload: UseUploadSingleFileArg["onUpload"];
};

export const ResumeUpload = ({ input, meta, onUpload }: ResumeUploadProps) => {
  const { onBeforeUpload, onAfterUpload } = usePendingUploadSnackbar({
    input,
    meta,
  });
  const { uploadSingleFile, isUploading, error, resetFileState } =
    useUploadSingleFile({ onBeforeUpload, onUpload, onAfterUpload });

  const onDrop = useCallback(
    (files: File[]) => {
      resetFileState();

      const [file] = files;
      if (!file) {
        return;
      }

      const fileWithId = getFileWithId(file);
      uploadSingleFile({
        file: fileWithId,
        uploadType: ENUMS.UploadType.RESUME_ATTACHMENT_UPLOAD_TYPE,
      });
    },
    [uploadSingleFile, resetFileState],
  );

  return (
    <FileDropzone
      dropzoneOptions={{
        onDrop,
        multiple: false,
        accept: { "application/pdf": [] },
        maxSize: SETTINGS.BID_FILE_SIZE_LIMIT,
      }}
      error={error}
    >
      {({ isDragActive, isFocused }) => (
        <FileDropzonePlaceholderDashed
          isFocused={isFocused}
          isDragActive={isDragActive}
          isLoading={isUploading}
          subtitle="Attach up to 1 file, max 10 MB, PDFs only"
        />
      )}
    </FileDropzone>
  );
};
