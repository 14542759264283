import type { Employer } from "@js/types/employer";

import type { SIZE } from "../../employer-logo";
import { EmployerLogoWithName } from "../employer-logo-with-name";

type EmployerLogoWithNameAndColorProps = {
  employer: Pick<
    Employer,
    "id" | "logo" | "name" | "logo_thumbnail" | "has_logo_set" | "name_color"
  >;
  jobId: number;
  size?: keyof typeof SIZE;
  nameStyle?: React.CSSProperties;
  gap?: number;
  openInNewTab?: boolean;
};

export const EmployerLogoWithNameAndColor = ({
  employer,
  jobId,
  size = "small",
  nameStyle = { fontSize: "18px" },
  gap = 10,
  openInNewTab,
}: EmployerLogoWithNameAndColorProps) => {
  const { logo_thumbnail, name_color } = employer;

  return (
    <EmployerLogoWithName
      jobId={jobId}
      imageSource={logo_thumbnail}
      color={`var(${name_color || "--link"})`}
      employer={employer}
      size={size}
      nameVariant="label"
      nameSize="medium"
      nameStyle={nameStyle}
      gap={gap}
      openInNewTab={openInNewTab}
    />
  );
};
