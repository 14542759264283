import { RouterLink } from "@js/components/link";

export const PostSelfPromotionWarningMessage = () => {
  return (
    <>
      Your posts contains a violation to our community guidelines. The objective
      of Career Help is to get specific help, like career-related questions or
      reviews of your profile, resume, portfolio, etc., so you can advance in
      your career. Please edit your post so that the Braintrust community can
      better help you and refer to our{" "}
      <RouterLink
        href={SETTINGS.SUPPORT_URLS.FEED_COMMUNITY_GUIDELINES_ARTICLE_URL}
        target="_blank"
        rel="noreferrer"
        style={{ color: "inherit", textDecoration: "underline" }}
      >
        community guidelines
      </RouterLink>{" "}
      for posting tips.
    </>
  );
};
