import type { ReactNode } from "react";

import { BlockInteractionWrapper } from "@js/components/block-interaction-wrapper";

export type SpacePostsWrapperProps = {
  isSpaceMember: boolean;
  children: ReactNode;
  onBlockedInteraction: () => void;
};

export const SpacePostsWrapper = ({
  isSpaceMember,
  onBlockedInteraction,
  children,
}: SpacePostsWrapperProps) => {
  const shouldBlockInteraction = !isSpaceMember;

  if (!shouldBlockInteraction) {
    return <>{children}</>;
  }

  return (
    <BlockInteractionWrapper onBlockedInteraction={onBlockedInteraction}>
      {children}
    </BlockInteractionWrapper>
  );
};
