import type { Event, EventHint, StackFrame } from "@sentry/react";
import { isObject } from "underscore";
import { z } from "zod";

import { store } from "@js/store";

const itIsFreddyFeedback = (event: Event) => {
  const values = event?.exception?.values;
  if (!values) return false;

  const _itIsFreddyFeedback = values.some((value) => {
    const frames = value.stacktrace?.frames;

    if (!frames) return false;

    const isFramesArray = Array.isArray(frames);

    const conditionMet = (_frame: StackFrame) =>
      isObject(_frame) &&
      _frame.filename === "https://freddyfeedback.com/widget/freddyfeedback.js";

    if (isFramesArray) {
      return frames.some((frame) => conditionMet(frame));
    } else {
      return conditionMet(frames);
    }
  });

  return _itIsFreddyFeedback;
};

const isGoogleTagManagerError = (event: Event) =>
  event.exception?.values?.some((value) =>
    value.stacktrace?.frames?.some((frame) =>
      frame.filename?.includes("googletagmanager.com"),
    ),
  );

const indexDBOpenErrorSchema = z.object({
  target: z.literal("[object IDBOpenDBRequest]"),
});
const isIndexDBOpenRequestError = (event: Event) =>
  indexDBOpenErrorSchema.safeParse(event.extra?.__serialized__).success;

const isTimeoutError = (_event: Event, hint: EventHint) =>
  hint.originalException &&
  typeof hint.originalException === "object" &&
  "message" in hint.originalException &&
  hint.originalException.message === "Timeout";

export const isErrorUnwantedToLog = (event: Event, hint: EventHint) => {
  if (itIsFreddyFeedback(event)) {
    return true;
  }

  if (isGoogleTagManagerError(event)) {
    return true;
  }

  if (isIndexDBOpenRequestError(event)) {
    return true;
  }

  if (isTimeoutError(event, hint)) {
    return true;
  }

  return false;
};

const eventUrlSanitizers: Array<{
  check: (url: URL) => boolean;
  sanitize: (url: URL) => string;
}> = [
  {
    check: (url: URL) =>
      /\/reset_password\/[^/]+\/[^/]+\/?$/.test(url.pathname),
    sanitize: (url: URL) => url.origin + "/reset_password/:uid/:token/",
  },
];

export const sanitizeEventUrl = (url: string): string => {
  try {
    const urlObject = new URL(url);
    for (const { check, sanitize } of eventUrlSanitizers) {
      if (!check(urlObject)) {
        continue;
      }

      return sanitize(urlObject);
    }

    return url;
  } catch (_error) {
    return url;
  }
};

export const processEvent = <T extends Event>(event: T): T => {
  if (event.request?.url) {
    event.request.url = sanitizeEventUrl(event.request.url);
  }

  try {
    const modalsData = store.getState().modal;
    event.extra = {
      ...event.extra,
      modalsData,
      backendRevision: SETTINGS.BACKEND_REVISION,
    };
    return event;
  } catch (_error) {
    return event;
  }
};
