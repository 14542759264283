import type { RefObject } from "react";

import {
  postCollapsed,
  postExpanded,
  postUserNameClicked,
} from "@js/apps/give-and-get-help/actions";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context";
import { useTrackPostVisible } from "@js/apps/give-and-get-help/hooks/use-track-post-visible";
import { useAppDispatch } from "@js/hooks";
import type { IPost, PostComment } from "@js/types/give-and-get-help";

type UseHandlePostCommentActionsProps = {
  entity: PostComment | IPost;
  entityRef: RefObject<HTMLDivElement>;
};

export const useHandlePostCommentActions = ({
  entity,
  entityRef,
}: UseHandlePostCommentActionsProps) => {
  const dispatch = useAppDispatch();
  const location = usePostLocationContext();

  useTrackPostVisible(entity, entityRef);

  const handleExpandPost = () => {
    dispatch(postExpanded({ entity, post_location: location }));
  };

  const handleCollapsePost = () => {
    dispatch(postCollapsed({ entity, post_location: location }));
  };

  const handleUserNameClicked = () => {
    dispatch(postUserNameClicked({ entity, post_location: location }));
  };

  return {
    handleCollapsePost,
    handleExpandPost,
    handleUserNameClicked,
  };
};
