import {
  Box,
  Button,
  ButtonCore,
  Loader,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { HelpOutlineIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useClearAddServicesForm } from "@js/apps/freelancer/forms/add-ggh-services-form/service-category-form";
import { ADD_SERVICE_STEP } from "@js/apps/freelancer/hooks/ggh-services";
import { useDeleteHelpServiceMutation } from "@js/apps/give-and-get-help/api";
import { getInitialBudget } from "@js/apps/give-and-get-help/utils";
import type { FreelancerPublic } from "@js/types/freelancer";
import type { HelpService } from "@js/types/give-and-get-help";

import { openAddServices } from "../../ggh-services/modals/add-ggh-services";
import { ServiceCategoryIcon } from "../../ggh-services/service-card";

type ServiceHelpOfferItemProps = {
  service: HelpService;
  profile: FreelancerPublic;
};

export const ServiceHelpOfferItem = ({
  service,
  profile,
}: ServiceHelpOfferItemProps) => {
  const isTablet = useMediaQuery("lg");
  const isMobile = useMediaQuery("sm");
  const [deleteHelpService] = useDeleteHelpServiceMutation();

  const formattedCategoryName = (() => {
    const categoryNameLength = service.category.name.length;
    const TABLET_MAX_LENGTH = 21;
    const MOBILE_MAX_LENGTH = 15;

    if (!isTablet && categoryNameLength > TABLET_MAX_LENGTH) {
      return service.category.name.substring(0, TABLET_MAX_LENGTH) + "...";
    }

    if (isMobile && categoryNameLength > MOBILE_MAX_LENGTH) {
      return service.category.name.substring(0, MOBILE_MAX_LENGTH) + "...";
    }

    return service.category.name;
  })();
  const { destroyForm } = useClearAddServicesForm();

  if (!profile) return <Loader centered />;

  return (
    <Box
      className="service-item service--help-offer-item  profile-empty-state"
      component={ButtonCore}
      bgcolor="var(--white)"
    >
      <ServiceCategoryIcon isProfileModuleVariant category={service.category} />
      <Box textAlign="left" display="flex" flexDirection="column">
        <Typography component="h2" size="medium" fontWeight={500}>
          {formattedCategoryName}
        </Typography>
        <div className="service--help-offer-item__actions">
          <Button
            variant="black-outlined"
            size="x-small"
            onClick={() =>
              openAddServices({
                onClose: destroyForm,
                freelancerId: profile.id,
                initialStep: ADD_SERVICE_STEP.BUDGET_FORM_MODAL_OPENED,
                preselectedValues: {
                  id: service.id,
                  category: service.category.id,
                  description: service.description,
                  budget: getInitialBudget(service.budget),
                },
              })
            }
          >
            Add this offer
          </Button>
          <button
            className="btn-reset pointer text-underline"
            onClick={() => deleteHelpService({ id: service.id })}
          >
            Remove
          </button>
        </div>
        <Tooltip
          title={
            <Typography component="p" size="small">
              You’ve offered this kind of 1:1
              <br />
              help before
            </Typography>
          }
          color="var(--black)"
          placement="top"
        >
          <span>
            <Typography
              size="small"
              component="span"
              justifyContent="center"
              alignItems="center"
              display="flex"
              gap={0.5}
              color="grey-1"
            >
              <HelpOutlineIcon style={{ width: "16px", height: "16px" }} /> Why
              was this suggested?
            </Typography>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};
