import type { FC } from "react";
import React, { useEffect } from "react";
import { SubmissionError } from "redux-form";
import * as Sentry from "@sentry/react";

import { Loader, Typography } from "@hexocean/braintrust-ui-components";
import type { FetchOTPAuthURIResponse } from "@js/apps/auth/actions";
import {
  enableOTPAuth,
  fetchCurrentUser,
  fetchOTPAuthURI,
} from "@js/apps/auth/actions";
import { Modal, ModalConfirm } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { createFormInstance } from "@js/forms/components";
import { useAppDispatch } from "@js/hooks";
import { isErrorWithErrors } from "@js/types/errors";

import { openAuthBackupCodesModal } from "../backup-codes-modal";

import { EnableOTPContent } from "./content";

const ENABLE_OTP_AUTH_MODAL_ID = "enable-otp-auth-modal";
const ENABLE_OTP_AUTH_FORM_ID = "enable-otp-auth-form";

export const EnableOTPAuthModalInstance = Modal(ENABLE_OTP_AUTH_MODAL_ID, {
  className: "enable-otp-auth-modal",
  closeButton: false,
});

type FormData = {
  code: string;
  current_password: string;
};

const FormInstance = createFormInstance<FormData, unknown>(
  ENABLE_OTP_AUTH_FORM_ID,
);

type EnableOTPAuthFormProps = {
  onSuccessAction?: (codes: string[]) => void;
  onCancelAction?: () => void;
};
export const EnableOTPAuthForm: FC<EnableOTPAuthFormProps> = ({
  onSuccessAction = openAuthBackupCodesModal,
  onCancelAction = EnableOTPAuthModalInstance.close,
}) => {
  const dispatch = useAppDispatch();
  const [OTPAuthURI, setOTPAuthURI] = React.useState<
    FetchOTPAuthURIResponse | ""
  >("");

  useEffect(() => {
    if (!OTPAuthURI) {
      fetchOTPAuthURI()
        .then(setOTPAuthURI)
        .catch(() =>
          Snackbar.error(
            "Inconsistent 2FA status - maybe it was already enabled (please try refreshing the page)?",
            { preventDuplicate: true },
          ),
        );
    }
  }, [OTPAuthURI]);

  const onSubmit = async (values: FormData) => {
    try {
      const data = await enableOTPAuth({
        code: values["code"],
        current_password: values["current_password"],
      });

      await onSuccessAction(data.backup_codes);
      EnableOTPAuthModalInstance.close();
      await dispatch(fetchCurrentUser());
      Snackbar.success("2-Step Authentication enabled.");
      setOTPAuthURI("");
    } catch (error: unknown) {
      if (isErrorWithErrors(error)) {
        throw new SubmissionError(error.errors);
      }

      Snackbar.error("Failed to enable 2FA");
      Sentry.captureException("Enable OTP exception", {
        extra: { error },
      });
      throw new SubmissionError({});
    }
  };

  return (
    <FormInstance onSubmit={onSubmit}>
      <Typography component="h3" variant="title" size="small">
        Set up 2-step authentication
      </Typography>
      {!!OTPAuthURI ? (
        <ModalConfirm
          onCancel={onCancelAction}
          onConfirm={"submit"}
          confirmText="Enable"
          buttonsSquareShape
          buttonsOnEnds
          cancelText="Cancel"
          confirmButtonVariant="positive-2"
        >
          <EnableOTPContent
            OTPAuthURI={OTPAuthURI.otp_auth_uri}
            OTPAuthSecret={OTPAuthURI.otp_secret}
          />
        </ModalConfirm>
      ) : (
        <div style={{ marginTop: "30px" }}>
          <Loader centered className="posr" />
        </div>
      )}
    </FormInstance>
  );
};

export const openEnableOTPAuthModal = (config?: EnableOTPAuthFormProps) => {
  EnableOTPAuthModalInstance.open({
    children: <EnableOTPAuthForm {...config} />,
  });
};
