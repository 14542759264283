import { useEffect, useState } from "react";

import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useUser } from "@js/apps/common/hooks";
import {
  useGetTalentReviewsSummaryQuery,
  useLazyGetTalentReviewsQuery,
} from "@js/apps/reviews/api";
import { DEFAULT_REVIEWS_COUNT } from "@js/apps/reviews/constants";
import { Snackbar } from "@js/components/snackbar";
import { useCombinedQueryState } from "@js/hooks/combined-query-state";
import type { FreelancerPublic } from "@js/types/freelancer";

type UseTalentProfileReviewModuleProps = {
  profile: FreelancerPublic;
};

export const useTalentProfileReviewModule = ({
  profile,
}: UseTalentProfileReviewModuleProps) => {
  const isMobile = useMediaQuery("sm");

  const [isLoadAllAvailable, setIsLoadAllAvailable] = useState(true);

  const user = useUser();

  const { data: reviewsSummary, ...reviewsSummaryState } =
    useGetTalentReviewsSummaryQuery({
      id: profile.id,
    });

  const [triggerGetTalentReviews, { data: reviews, ...reviewsState }] =
    useLazyGetTalentReviewsQuery();

  const { isLoading, isFetching, isError } = useCombinedQueryState([
    reviewsState,
    reviewsSummaryState,
  ]);

  const getReviews = ({ count }: { count: number }) => {
    triggerGetTalentReviews(
      {
        id: profile.id,
        count,
      },
      true,
    );
  };

  const handleLoadClick = ({ loadAll } = { loadAll: false }) => {
    setIsLoadAllAvailable(!loadAll);
    getReviews({ count: loadAll ? 0 : DEFAULT_REVIEWS_COUNT });
  };

  useEffect(() => {
    getReviews({ count: DEFAULT_REVIEWS_COUNT });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reviewsSummaryState.isError) {
      Snackbar.error("Failed to fetch reviews summary");
    }
  }, [reviewsSummaryState.isError]);

  useEffect(() => {
    if (reviewsState.isError) {
      Snackbar.error("Failed to fetch reviews");
    }
  }, [reviewsState.isError]);

  return {
    isMobile,
    isLoadAllAvailable,
    user,
    reviewsSummary,
    reviews,
    isLoading,
    isFetching,
    isError,
    handleLoadClick,
  };
};
