import { getOrderingParam, parseListQueryParam } from "@js/utils";

import type { GetEmployerMyTalentParams } from "../../api";

export const processGetMyTalentParams = (
  params: GetEmployerMyTalentParams | void,
) => {
  const {
    status,
    order_by,
    order_dir,
    jobs,
    roles,
    rate_types,
    job_owners,
    ...rest
  } = params ?? {};

  const ordering = getOrderingParam({ order_by, order_dir });
  const jobsParam = parseListQueryParam(jobs);
  const rateTypesParam = parseListQueryParam(rate_types);
  const statusParam = parseListQueryParam(status);
  const rolesParam = parseListQueryParam(roles);
  const jobOwnersParam = parseListQueryParam(job_owners);

  return {
    ...rest,
    ordering,
    jobs: jobsParam,
    rate_types: rateTypesParam,
    status: statusParam,
    roles: rolesParam,
    job_owners: jobOwnersParam,
  };
};
