import type { WrappedFieldMetaProps } from "redux-form";

import { useFormErrors } from "@js/forms/hooks";
import { getErrorsForRequiredFields, pluralize } from "@js/utils";

const REQUIRED_FIELDS = [
  "title",
  "openings_number",
  "new_skills",
  "top_skills",
  "experience_level",
  "introduction",
  "fixed_rate",
  "hourly_rate",
  "min_annual_rate",
  "max_annual_rate",
  "min_rate",
  "max_rate",
  "payment_type",
  "description",
  "contract_type",
  "other_role_name",
] as const;

type RequiredErrorsKeys = readonly (typeof REQUIRED_FIELDS)[number][];

export const useJobErrorSectionErrors = (meta: WrappedFieldMetaProps) => {
  const { errors } = useFormErrors(meta);

  const requiredErrors = getErrorsForRequiredFields<RequiredErrorsKeys>(
    errors,
    REQUIRED_FIELDS,
  );

  const errorsCount = Object.keys(requiredErrors).length;

  const areSkillsFieldsEmpty =
    !!requiredErrors["new_skills"] && !!requiredErrors["top_skills"];

  const skillsErrorsLength = ["new_skills", "top_skills"].filter(
    (key) => !!requiredErrors[key],
  ).length;

  const rateErrorsLength = [
    "fixed_rate",
    "hourly_rate",
    "min_annual_rate",
    "max_annual_rate",
    "min_rate",
    "max_rate",
    "payment_type",
    "job_type",
  ].filter((key) => !!requiredErrors[key]).length;

  const getMessage = () => {
    if (errors.job_subscribers) {
      return errors.job_subscribers;
    }

    if (errors.job_subscriber_invitations) {
      return errors.job_subscriber_invitations;
    }

    const length =
      errorsCount -
      skillsErrorsLength +
      (areSkillsFieldsEmpty ? 1 : 0) -
      rateErrorsLength +
      (rateErrorsLength > 0 ? 1 : 0);
    if (length) {
      return `You are missing ${length} required field${pluralize(length)}`;
    }
  };

  return {
    requiredErrors,
    errors,
    getMessage,
  };
};
