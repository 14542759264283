export const mapServerErrors = (
  err: Record<string, string[] | string | undefined | null>,
) => {
  const arr: Array<{ name: string; error: { message: string } }> = [];
  Object.keys(err).forEach((key) => {
    const value = parseToString(err[key]);
    if (value) {
      arr.push({ name: key, error: { message: value } });
    }
  });
  return arr;
};

const parseToString = (error: string[] | string | null | undefined) => {
  if (typeof error === "string") {
    return error;
  }

  if (Array.isArray(error)) {
    return error[0];
  }

  return error;
};
