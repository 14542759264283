import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { FeedHeader } from "@js/apps/give-and-get-help/components";
import { PublicPostItem } from "@js/apps/give-and-get-help/components/post";
import { PostMeta } from "@js/apps/give-and-get-help/components/post-meta";
import { PostLocation } from "@js/apps/give-and-get-help/context/post-location";
import { AppLayout } from "@js/layouts/app";

import { PostsContextProvider } from "../../context/posts";

import { PublicPostPageTopNavigation } from "./public-post-page-top-navigation";
import { SignupBanner } from "./sign-up-banner";
import { usePublicPostPage } from "./use-public-post-page";

export const PublicPostPage = ({ id }: { id: number }) => {
  const {
    handleSignUp,
    handleBannerClose,
    isCtaBannerHidden,
    mainPostData,
    isMainPostLoading,
    relevantPostData,
    isRelevantPostsLoading,
  } = usePublicPostPage(id);

  return (
    <>
      <PostLocation.Provider value={PostLocation.Values.single_post_view}>
        <AppLayout
          bgcolor="var(--soft-green)"
          renderOverwriteNavigationComponent={(props) => (
            <PublicPostPageTopNavigation
              {...props}
              handleSignUp={handleSignUp}
            />
          )}
          hideMetaTags
        >
          <PostsContextProvider>
            <Box
              display="flex"
              flexDirection="column"
              justifyItems="center"
              mx="auto"
              width="100%"
              maxWidth="944px"
            >
              <FeedHeader isSinglePostView />

              <Box my={4}>
                {isMainPostLoading && <PublicPostsLoader />}
                {!isMainPostLoading && mainPostData && (
                  <PublicPostItem
                    postData={mainPostData}
                    isSinglePostView
                    disablePostSubscription
                    restrictTogglingReplies
                  />
                )}
              </Box>

              <Typography component="h3" variant="paragraph" size="large">
                You might also be interested in
              </Typography>

              <Box mt={4} display="flex" flexDirection="column" gap={2}>
                {isRelevantPostsLoading && <PublicPostsLoader />}
                {!isRelevantPostsLoading &&
                  !!relevantPostData?.length &&
                  relevantPostData.map((post) => (
                    <PublicPostItem
                      key={post.id}
                      postData={post}
                      disablePostSubscription
                    />
                  ))}
              </Box>
            </Box>
          </PostsContextProvider>
        </AppLayout>
        {!isCtaBannerHidden && (
          <SignupBanner
            onSignUpClick={handleSignUp}
            onCloseClick={handleBannerClose}
          />
        )}
      </PostLocation.Provider>
      <PostMeta post={mainPostData} />
    </>
  );
};

const PublicPostsLoader = () => {
  return (
    <Box sx={{ position: "relative", minHeight: "8rem" }}>
      <Loader centered />
    </Box>
  );
};
