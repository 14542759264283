import type { ConfigProps } from "redux-form";
import { SubmissionError } from "redux-form";

import { Snackbar } from "@js/components/snackbar";

import type { AddInternalReviewPayload } from "../../api";
import {
  useAddInternalReviewMutation,
  useDeleteInternalReviewMutation,
  useGetInternalReviewsQuery,
} from "../../api";
import type { InternalRatingProfile } from "../../components";

type UseInternalTalentReviewsProps = {
  talent: InternalRatingProfile;
};

export const useInternalTalentReviews = ({
  talent,
}: UseInternalTalentReviewsProps) => {
  const {
    data: reviews,
    isLoading: loadingReviews,
    isFetching,
  } = useGetInternalReviewsQuery({ id: talent.id });
  const [addReview] = useAddInternalReviewMutation();
  const [deleteReview] = useDeleteInternalReviewMutation();

  const onSubmit = async (
    values: Omit<AddInternalReviewPayload, "freelancer">,
  ) => {
    const data = await addReview({ ...values, freelancer: talent.id });
    if ("error" in data) {
      throw new SubmissionError((data.error as any).data);
    }
    Snackbar.success(
      "A review has been added. However it will not be immediately included in the score on the talent page",
      { persist: true },
    );
    return data;
  };

  const onSubmitSuccess: ConfigProps["onSubmitSuccess"] = (
    _values,
    _dispatch,
    props,
  ) => props?.reset && props.reset();

  const onDelete = async (id: number) => deleteReview({ id });

  return {
    reviews,
    loadingReviews: loadingReviews || isFetching,
    onSubmit,
    onSubmitSuccess,
    onDelete,
  };
};
