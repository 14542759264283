import React from "react";

import { Box, Loader } from "@hexocean/braintrust-ui-components";

export const ReferralsStatsLoader = () => {
  return (
    <Box sx={{ position: "relative", minHeight: "300px" }}>
      <Loader centered />
    </Box>
  );
};
