import React, { useCallback, useEffect, useRef } from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { Modal } from "@js/components/modal";
import { IMPLICIT_ANCHOR_WARNING_ATTR } from "@js/services/dom-sanitize";

import { allowedUrls } from "./allowed-urls";

export const useAnchorRedirectWarning = <T extends HTMLElement>() => {
  const ref = useRef<T | null>(null);

  const refCallback = useCallback((node: T | null) => {
    if (!node) return;
    node.addEventListener("click", handleImplicitLinkClick);
    ref.current = node;
  }, []);

  useEffect(() => {
    return () => {
      if (!!ref.current) {
        ref.current.removeEventListener("click", handleImplicitLinkClick);
      }
    };
  }, []);

  return {
    anchorWrapperRef: refCallback,
  };
};

export const handleImplicitLinkClick = (
  e: MouseEvent | React.MouseEvent,
  anchorSelector?: string,
) => {
  const href = getRedirectionLink(e, anchorSelector);

  if (!href) return;

  displayRedirectWarningModal({
    onConfirm: () => {
      window.open(href, "_blank", "noopener,noreferrer");
    },
    description: `You will be redirected to ${href}. Would you like to proceed?`,
  });
};

export const getRedirectionLink = (
  e: MouseEvent | React.MouseEvent,
  anchorSelector?: string,
): string | undefined => {
  const target = e.target as Element;
  const closestAnchor = target.closest(
    anchorSelector ?? `a[${IMPLICIT_ANCHOR_WARNING_ATTR}]`,
  );

  if (!closestAnchor) return;

  const href = closestAnchor.getAttribute("href");
  if (!href) return;

  // Check if href is an email
  if (href.startsWith("mailto:")) {
    return;
  }

  // no modal if url in allowed list
  for (const urlMatcher of allowedUrls) {
    if (urlMatcher.test(href)) return;
  }

  try {
    // no modal if url within current hostname
    const targetUrl = new URL(href);
    const currentUrl = new URL(window.location.href);
    if (currentUrl.hostname === targetUrl.hostname) return;

    e.preventDefault();

    return href;
  } catch (_error) {
    return;
  }
};

type DisplayWarningModalProps = {
  onConfirm: () => void;
  description: string;
};

const displayRedirectWarningModal = ({
  onConfirm,
  description,
}: DisplayWarningModalProps) => {
  const confirm = () => {
    onConfirm();
    RedirectWarningnModal.close();
  };

  const cancel = () => {
    RedirectWarningnModal.close();
  };

  RedirectWarningnModal.open({
    className: "max-width-500",
    closeButton: true,
    children: (
      <ModalContent
        description={description}
        onPrimaryClick={confirm}
        onSecondaryClick={cancel}
      />
    ),
  });
};

const ModalContent = ({
  onSecondaryClick,
  onPrimaryClick,
  description,
}: {
  onSecondaryClick: () => void;
  onPrimaryClick: () => void;
  description: string;
}) => {
  return (
    <Box mt={4}>
      <Typography component="p" mb={1} variant="title">
        Redirect warning
      </Typography>
      <Typography component="p">{description}</Typography>
      <Box mt={4} gap={1} display="flex" justifyContent="flex-end">
        <Button variant="secondary" onClick={onSecondaryClick}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onPrimaryClick}>
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export const RedirectWarningnModal = Modal("redirect-warning-modal", {
  closeButton: true,
});
