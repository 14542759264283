import type { FC } from "react";
import React from "react";

import { Box, IconButton, Menu } from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";
import { openNotesModal } from "@js/apps/common/components";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import { Snackbar } from "@js/components/snackbar";
import { useCopyToClipboard } from "@js/hooks";
import type {
  FreelancerForNodeStaff,
  FreelancerPublic,
} from "@js/types/freelancer";

import CtaButtons from "../cta-buttons";

export type ProfnetProfileActionsProps = {
  profile: FreelancerPublic | FreelancerForNodeStaff;
};

export const ProfnetProfileActions: FC<ProfnetProfileActionsProps> = ({
  profile,
}) => {
  const isNodeStaff = useIsNodeStaff();

  const { handleCopy } = useCopyToClipboard(() => {
    Snackbar.success("Profile URL successfully copied!");
  });

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <CtaButtons profile={profile} />
      {isNodeStaff && (
        <Menu
          anchor={
            <IconButton
              size="medium"
              aria-label="open actions menu"
              variant="transparent"
            >
              <MoreVertIcon style={{ width: 22.5, height: 22.5 }} />
            </IconButton>
          }
        >
          <Menu.Item
            onClick={() => {
              handleCopy(window.location.href);
            }}
          >
            Share
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              openNotesModal(profile.id, ENUMS.NoteContentType.Freelancer);
            }}
          >
            Add Notes
          </Menu.Item>
        </Menu>
      )}
    </Box>
  );
};
