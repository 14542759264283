import { Button } from "@hexocean/braintrust-ui-components";
import { useCtaClickedContext } from "@js/apps/common/context";
import { RouterLink } from "@js/components/link";
import type { FreelancerBid } from "@js/types/jobs";

import { AppliedJobsCard } from "./applied-jobs-card";

type AppliedJobsListProps = {
  bids: FreelancerBid[];
};

export const AppliedJobsList = ({ bids }: AppliedJobsListProps) => {
  const { ctaClicked } = useCtaClickedContext();

  return (
    <div className="applied-jobs-section__list">
      {bids.map((bid) => (
        <AppliedJobsCard bid={bid} key={bid.id} />
      ))}

      <Button
        variant="transparent-border-beige"
        shape="squared"
        onClick={() =>
          ctaClicked(ENUMS.UserClicksEventCTAName.VIEW_ALL_APPLICATIONS)
        }
        to="/talent/dashboard/my_jobs/my_proposals"
        RouterLink={RouterLink}
      >
        View all applications
      </Button>
    </div>
  );
};
