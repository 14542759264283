import { Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { Space } from "@js/types/spaces";

import styles from "../style.module.scss";

type SpaceCardNameProps = Pick<Space, "name" | "id"> & {
  showMobileVariant?: boolean;
};

export const SpaceCardName = ({
  name,
  id,
  showMobileVariant,
}: SpaceCardNameProps) => {
  return (
    <>
      <Typography
        className={styles.cardName}
        target="_blank"
        rel="noopener"
        RouterLink={RouterLink}
        size={showMobileVariant ? "large" : "small"}
        variant={showMobileVariant ? "paragraph" : "title"}
        to={`/spaces/${id}/discussion`}
        component="h3"
        fontWeight={400}
        whiteSpace="nowrap"
        noTextDecoration
        ellipsis
        maxWidth="100%"
      >
        {name}
      </Typography>
    </>
  );
};
