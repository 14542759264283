import _ from "underscore";

const filtersToOmit = [
  "page",
  "utm_medium",
  "utm_source",
  "utm_content",
  "utm_campaign",
  "utm_channel",
  "page",
  "vgo_ee",
  "back",
];

export const getFiltersToSave = <T extends object>(filters: T): Partial<T> => {
  return _.omit(filters, filtersToOmit);
};

export const generateSuggestedFilterName = (
  prefix: "Talent" | "Job",
  savedFilters: Array<{ name: string }>,
) => {
  const subString = `${prefix} Preferences #`;

  const numbers = savedFilters
    .filter((savedFilter) => {
      return savedFilter.name.startsWith(subString);
    })
    .map((filter) => {
      return +filter.name.slice(subString.length);
    });

  const maxNumberOfDefaultSuggestedNames = 10_000;
  // Job/Talent Preference #1
  // Job/Talent Preference #10000

  for (let i = 1; i < maxNumberOfDefaultSuggestedNames; i++) {
    if (!numbers.includes(i)) {
      return `${prefix} Preferences #${i}`;
    }
  }
};

export const filterSkillsIds = (filters: { skills?: unknown }): number[] => {
  if (typeof filters.skills === "string") {
    return filters.skills
      .split(",")
      .map((skill) => Number(skill))
      .filter(Boolean);
  }
  if (typeof filters.skills === "number") {
    return [filters.skills];
  }

  return [];
};
