import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { formatBytes } from "@js/utils";

type FileCommonBoxProps = {
  size?: number;
  name: React.ReactNode;
  color: string;
  previewThumbnail: React.ReactNode;
  additionalContent?: React.ReactNode;
};

export const FileCommonBox: React.FC<FileCommonBoxProps> = ({
  name,
  size,
  previewThumbnail,
  color,
}) => {
  return (
    <>
      <Box
        width="64px"
        height="64px"
        bgcolor="var(--white)"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        flexShrink={0}
      >
        {previewThumbnail}
      </Box>
      <Box display="flex" flexDirection="column" minWidth={0} overflow="hidden">
        <Typography component="p" variant="label" ellipsis>
          {name}
        </Typography>
        {size && (
          <Typography
            component="p"
            variant="paragraph"
            size="small"
            sx={{ color }}
          >
            {formatBytes(size, 2)}
          </Typography>
        )}
      </Box>
    </>
  );
};
