import { Typography } from "@hexocean/braintrust-ui-components";

import styles from "../style.module.scss";

type SpaceCardDescriptionProps = {
  description?: string;
  showMobileVariant?: boolean;
};

export const SpaceCardDescription = ({
  description,
  showMobileVariant,
}: SpaceCardDescriptionProps) => {
  return (
    <Typography
      className={styles.cardDescription}
      component="p"
      variant="paragraph"
      size={showMobileVariant ? "small" : "medium"}
      multilineEllipsis={2}
    >
      {description}
    </Typography>
  );
};
