import { useCallback, useRef } from "react";

import {
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ArrowCircleUpIcon } from "@hexocean/braintrust-ui-components/Icons";
import { DownloadCSVTemplateButton } from "@js/apps/byot/components/download-csv-template-button";
import type { UseUploadSingleFileArg } from "@js/apps/file-upload";
import {
  FileDropzone,
  getFileWithId,
  useUploadSingleFile,
} from "@js/apps/file-upload";

type UploadBYOTCsvProps = Pick<
  UseUploadSingleFileArg,
  "onUpload" | "onBeforeUpload" | "onUploadError"
> & {
  isProcessingData: boolean;
};

export const UploadBYOTCsv = ({
  isProcessingData,
  onBeforeUpload,
  onUpload,
  onUploadError,
}: UploadBYOTCsvProps) => {
  const { uploadingFileName, uploadSingleFile, isUploading, resetFileState } =
    useUploadSingleFile({
      onBeforeUpload,
      onUpload,
      onUploadError,
    });

  const openRef = useRef<{ open: () => void }>();

  const onDrop = useCallback(
    (files: File[]) => {
      resetFileState();

      const [file] = files;
      if (!file) {
        return;
      }

      const fileWithId = getFileWithId(file);
      uploadSingleFile({
        file: fileWithId,
        uploadType: ENUMS.UploadType.BYOT_CSV_FILE_UPLOAD_TYPE,
      });
    },
    [uploadSingleFile, resetFileState],
  );

  return (
    <FileDropzone
      customRef={openRef}
      dropzoneOptions={{
        onDrop,
        multiple: false,
        noClick: true,
        accept: { "text/csv": [".csv"] },
      }}
      // we display custom error box outisde of the component
      error={undefined}
    >
      {({ isDragActive }) => {
        const borderColor = isDragActive ? "--dark-violet" : "--medium-violet";

        return (
          <Box
            p={4}
            border={`1px dashed var(${borderColor})`}
            textAlign="center"
            borderRadius="8px"
          >
            {(isUploading || isProcessingData) && uploadingFileName ? (
              <UploadingState fileName={uploadingFileName} />
            ) : (
              <InitialState onUploadCSVClick={openRef.current?.open} />
            )}
          </Box>
        );
      }}
    </FileDropzone>
  );
};

const InitialState = ({
  onUploadCSVClick,
}: {
  onUploadCSVClick: (() => void) | undefined;
}) => {
  return (
    <>
      <Typography component="h3" variant="label">
        Bulk import talent using CSV
      </Typography>
      <Typography component="p" size="small" mb="12px">
        Download the template, fill in the required fields, and upload the file
        (with up to 500 entries) to quickly invite your talent.
      </Typography>
      <Button
        variant="primary"
        onClick={onUploadCSVClick}
        size="x-small"
        startIcon={<ArrowCircleUpIcon sx={{ width: 18, height: 18 }} />}
      >
        Upload CSV
      </Button>
      <DownloadCSVTemplateButton />
    </>
  );
};

const UploadingState = ({ fileName }: { fileName: string }) => {
  return (
    <>
      <Box mb={1} display="flex" justifyContent="center">
        <Loader color="success" size={46} />
      </Box>
      <Typography component="p" variant="label">
        Uploading {fileName}...
      </Typography>
      <Typography component="p" size="small" mb="12px">
        Please wait while your file is being uploaded
      </Typography>
    </>
  );
};
