import cs from "classnames";

import { Box, Chip, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { ProfileCard } from "@js/apps/give-and-get-help/components/profile-card";
import { ProfileCardPopover } from "@js/apps/give-and-get-help/components/profile-card-popover";
import { RouterLink } from "@js/components/link";
import type { Role, Subrole } from "@js/types/roles";
import { relativeDateDifference } from "@js/utils";

import type { ProfileCardAvatar } from "../../profile-card/profile-card.types";

import styles from "../styles.module.scss";

type DetailsProps = {
  firstName: string;
  lastName: string | null;
  role: Role | Subrole;
  id: number;
  avatarData: ProfileCardAvatar;
  introductionHeadline: string | null;
  created: string;
  variant: "comment" | "post";
  onUserNameClick?: () => void;
  shouldDisplayAdminBadge: boolean;
};

export const PostOrCommentDetails = ({
  firstName,
  lastName,
  role,
  id,
  avatarData,
  introductionHeadline,
  created,
  variant,
  onUserNameClick,
  shouldDisplayAdminBadge,
}: DetailsProps) => {
  const isSmall = useMediaQuery("sm");
  const isComment = variant === "comment";

  const name = `${firstName} ${lastName || ""}`;

  return (
    <Box
      className={cs(styles.feedHeaderDetails, {
        [styles["feed-header-details--comment"]]: isComment,
      })}
    >
      <Box sx={{ overflow: "hidden" }}>
        <ProfileCardPopover
          renderContent={({ updatePosition }) => (
            <ProfileCard
              talentId={id}
              avatarData={avatarData}
              firstName={firstName}
              onDataLoad={updatePosition}
            />
          )}
          anchor={
            <Typography
              component="link"
              noTextDecoration
              size={isComment ? "small" : "medium"}
              onClick={(ev) => {
                ev.stopPropagation();
                onUserNameClick?.();
              }}
              fontWeight={500}
              to={`/talent/${id}/`}
              RouterLink={RouterLink}
              target="_blank"
              ellipsis
              sx={{
                display: "block",
                maxWidth: "100%",
              }}
            >
              {name}

              {shouldDisplayAdminBadge && (
                <Chip
                  label="Admin"
                  variant="squared"
                  color="violet"
                  size="x-small"
                  typographySize="small"
                  LabelProps={{ fontWeight: 400 }}
                  sx={{ ml: 1 }}
                />
              )}
            </Typography>
          }
        />
      </Box>

      <Box className={styles.feedHeaderDetailsRoleTimestamp}>
        {(!isSmall || !isComment) && (introductionHeadline || role) && (
          <>
            <Typography
              component="link"
              noTextDecoration
              size={isSmall || isComment ? "small" : "medium"}
              color="tertiary"
              to={`/talent/${id}/`}
              RouterLink={RouterLink}
              target="_blank"
              onClick={(ev) => ev.stopPropagation()}
            >
              {!isSmall && (
                <span className={styles.feedHeaderDetailsDotSeparator}>·</span>
              )}
              {introductionHeadline || role?.name}
            </Typography>
          </>
        )}

        {(!isSmall || !!role) && (
          <span className={styles.feedHeaderDetailsDotSeparator}>·</span>
        )}

        <Typography
          component="span"
          variant="label"
          size={isSmall || isComment ? "small" : "medium"}
          color="tertiary"
          fontWeight={400}
        >
          {relativeDateDifference(created)}
        </Typography>
      </Box>
    </Box>
  );
};
