import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { useAppSelector } from "@js/hooks";
import type { RootState } from "@js/store";
import type { SearchEventOption } from "@js/types/tracking";

type SearchEventState = {
  searchEventQueryData: Record<SearchEventOption, number | undefined>;
};

const initialState: SearchEventState = {
  searchEventQueryData: {
    [ENUMS.SearchEventOption.MAIN_SEARCH_BOX]: undefined,
    [ENUMS.SearchEventOption.JOB_SEARCH_BOX]: undefined,
    [ENUMS.SearchEventOption.TALENT_SEARCH_BOX]: undefined,
    [ENUMS.SearchEventOption.BID_SEARCH_BOX]: undefined,
  },
};

export const searchEventSlice = createSlice({
  name: "search-event",
  initialState,
  reducers: {
    setSearchEventQueryId(
      state,
      action: PayloadAction<{
        id: number | undefined;
        option: SearchEventOption;
      }>,
    ) {
      const { id, option } = action.payload;
      state.searchEventQueryData[option] = id;
    },
  },
});

export const { setSearchEventQueryId } = searchEventSlice.actions;

const getSearchEventQuery = (state: RootState) =>
  state.searchEvent.searchEventQueryData;

export const useSearchEventQueryId = (
  option: SearchEventOption | undefined,
) => {
  return useAppSelector((state) => {
    const searchEventQuery = getSearchEventQuery(state);
    if (!option) {
      return undefined;
    }

    return searchEventQuery[option];
  });
};
