import { Typography } from "@hexocean/braintrust-ui-components";
import type { StripeCreditCardPayment } from "@js/types/payments";

import { PaymentMethodMaskedCreditCardNumber } from "../../masked-credit-card-number";

import styles from "./styles.module.scss";

type StripeCreditCardProps = {
  paymentMethod: StripeCreditCardPayment;
};

export const StripeCreditCard = ({ paymentMethod }: StripeCreditCardProps) => {
  return (
    <div className={styles.stripeCreditCard}>
      <PaymentMethodMaskedCreditCardNumber paymentMethod={paymentMethod} />
      <Typography
        component="p"
        size="small"
        textTransform="uppercase"
        color="grey-2"
      >
        Exp: {paymentMethod.method.exp_month}/{paymentMethod.method.exp_year}
      </Typography>
    </div>
  );
};
