import { IconButton } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { EnvelopeIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import type { SpaceUser } from "@js/types/spaces";

export const MessageButton = ({ admin }: { admin: SpaceUser["user"] }) => {
  const { openMessengerModal } = useOpenMessengerModal();
  const user = useUser();
  const isMobile = useMediaQuery("sm");

  if (!user) return null;

  if (!isMobile) return null;

  if (user.id === admin.id) return null;

  return (
    <IconButton
      aria-label="message"
      variant="transparent-border-grey-4"
      data-testid="message-admin-button"
      size="x-small"
      onClick={() =>
        openMessengerModal({
          context: {
            participants: [admin.id, user.id],
          },
        })
      }
      style={{ marginLeft: "auto" }}
    >
      <EnvelopeIcon />
    </IconButton>
  );
};
