import type { UpdatePostCommentPropsParamsType } from "@js/apps/give-and-get-help/types";
import type { PostComment } from "@js/types/give-and-get-help";

import type { ReplyFormData } from "../reply-form";

export const checkIfIsAddOffer = (name: string): boolean =>
  name === "add-offer";

export const prepareCommentData = (data: Partial<ReplyFormData>) => {
  const preparedData: Partial<ReplyFormData> & { attachments_ids?: number[] } =
    { ...data };

  delete preparedData.excluded_links_from_text;
  delete preparedData.openedFields;

  const attachmentsIds = preparedData?.attachments
    ?.map((attachment) => attachment.id)
    .filter((id): id is number => Boolean(id));

  delete preparedData.attachments;
  if (attachmentsIds?.length) {
    preparedData.attachments_ids = attachmentsIds;
  }

  if (!preparedData.links) {
    return preparedData;
  }

  // we may have an array of objects, but BE expects array of strings
  const linksArray = preparedData.links.map((link) => {
    if (typeof link === "string") {
      return link;
    }

    return link.value;
  });

  return {
    ...preparedData,
    links: linksArray,
  };
};

export const prepareUpdatedCommentWithOffer = (
  data: PostComment,
): UpdatePostCommentPropsParamsType => {
  if (!data?.budget && data?.help_offer) {
    const budget = data?.help_offer?.budget;

    return { ...data, budget: budget.toString() };
  }

  return data;
};
