import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import type {
  GetTalentReferralsFilters,
  GetTalentReferralsParams,
} from "../../types";

import { schemaForTalentReferralsFilters } from "./filters-schema";

export const useTalentReferralsFilters = () => {
  const [params] = useSearchParams();
  const referralsFilters = useMemo(() => getReferralsFilters(params), [params]);
  const [filters, setFilters] = useState<GetTalentReferralsParams>(() => {
    return { ...referralsFilters, page: 1 };
  });

  useEffect(() => {
    setFilters((prevFilters) => {
      const newFilters: GetTalentReferralsParams = {
        ...referralsFilters,
      };

      const shouldResetPage = hasArgChanged(newFilters, prevFilters);

      return {
        ...newFilters,
        page: shouldResetPage ? 1 : prevFilters.page,
      };
    });
  }, [referralsFilters]);

  const setCurrentPage = useCallback((page: number) => {
    setFilters((prev) => ({ ...prev, page }));
  }, []);

  return {
    filters,
    setCurrentPage,
  };
};

const getReferralsFilters = (
  params: URLSearchParams,
): GetTalentReferralsFilters => {
  const parseResult = schemaForTalentReferralsFilters.safeParse(
    Object.fromEntries(params.entries()),
  );

  return parseResult.success ? parseResult.data : {};
};

const hasArgChanged = (
  arg: Omit<GetTalentReferralsParams, "page">,
  prevArg: GetTalentReferralsParams,
) => {
  return Object.entries(arg).some(
    ([key, value]) => value !== prevArg[key as keyof GetTalentReferralsParams],
  );
};
