import { useState } from "react";

import { useAccountType } from "@js/apps/common/hooks";

export const useAddTeamMemberDrawer = () => {
  const { isEmployer } = useAccountType();
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {};
  const openDrawer = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return {
    isAddingTeamMembersEnabled: isEmployer,
    isOpen,
    handleOpen,
    handleClose,
    openDrawer,
  };
};
