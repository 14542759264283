import React, { useState } from "react";

import { SignUpModalContentStepOne } from "@js/apps/onboarding/components/sign-up-modal/first-step-content";
import { SignUpModalContentStepTwo } from "@js/apps/onboarding/components/sign-up-modal/second-step-content";
import { ModalInstance } from "@js/components/modal";

/** @description Remember to include `useWithRecaptcha` in a page you are using this modal */
export const openSignUpModal = () => {
  if (!window.grecaptcha && process.env.NODE_ENV === "development") {
    throw new Error(
      "You need to have google recaptcha script included in a page you are using this modal (useWithRecaptcha hook)",
    );
  }

  ModalInstance.open({
    padding: false,
    children: <SignUpModalContent />,
  });
};

const SignUpModalContent = () => {
  const [currentStep, setCurrentStep] = useState<1 | 2>(1);
  const goToNextStep = () => {
    return setCurrentStep(2);
  };

  if (currentStep === 1) {
    return <SignUpModalContentStepOne goToNextStep={goToNextStep} />;
  }

  if (currentStep === 2) {
    return <SignUpModalContentStepTwo />;
  }

  return null;
};
