import React from "react";
import type { WrappedFieldProps } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { FieldSectionPanel } from "@js/apps/jobs/forms/fields";
import { RichTextareaField } from "@js/forms/fields/rich-textarea";

export const RequirementsSection = (props: WrappedFieldProps) => {
  const { meta } = props;
  const displayError = !!meta.error && meta.touched;

  return (
    <FieldSectionPanel
      title="Requirements"
      description="List specific requirements about Talent, the job, and more "
      titleProps={{
        component: "label",
        htmlFor: "requirements",
      }}
      isError={displayError}
    >
      {displayError && (
        <Typography mb={2} component="p" variant="paragraph" error>
          {meta.error}
        </Typography>
      )}
      <RichTextareaField
        id="requirements"
        {...props}
        // Note: The empty line in the placeholder is required for proper display. Don't remove it.
        placeholder={`- Help Talent understand any requirements related to the job, such as unique experience needed, a specific deadline, or special contract details.

- You can also copy/paste the “Requirements” section of your job here`}
        maxHeight={798}
        toolbar={[
          ["bold", "italic", "underline", "emoji"],
          ["undo", "redo"],
        ]}
        hideError
      />
    </FieldSectionPanel>
  );
};
