import { useCallback, useRef } from "react";
import { change } from "redux-form";
import { skipToken } from "@reduxjs/toolkit/query";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useLazyGetLinkMetadataQuery } from "@js/apps/common/api";
import { CreatePostModalInstance } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/modal-instances";
import { openCreatePostModal } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/open-modal";
import { CreatePostFAB } from "@js/apps/give-and-get-help/components/create-post";
import { useOpenCreatePostModalFromParams } from "@js/apps/give-and-get-help/components/create-post/hooks";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context";
import { CREATE_POST_FORM_ID } from "@js/apps/give-and-get-help/form/constants";
import { useShouldShowCreatePostFAB } from "@js/apps/give-and-get-help/hooks";
import { openEmailConfirmationModal } from "@js/apps/onboarding/components/sign-up-confirmation-content";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { useGetFreelancerPublicProfileQuery } from "../../api";

type GetCareerHelpProps = { onDismiss: () => void };

export const GetCareerHelp = ({ onDismiss }: GetCareerHelpProps) => {
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const postLocation = usePostLocationContext();

  const userProfileSelector = useAppSelector(
    (state) => state?.freelancer?.freelancerProfile,
  );

  const [getLinkMetadata] = useLazyGetLinkMetadataQuery();

  // Redux form field references
  const updateLinksMetadata = (links: [], links_metada: []) => {
    dispatch(change(CREATE_POST_FORM_ID, "links", links));
    if (userProfilePublicProfile?.resume?.attachment?.file) {
      const resume = userProfilePublicProfile?.resume;

      dispatch(change(CREATE_POST_FORM_ID, "attachments", [resume]));
    }
    dispatch(change(CREATE_POST_FORM_ID, "links_metadata", links_metada));
  };

  // Getting linkedin and resume url also creating user's braintrust profile url
  const urls: string[] = [];
  const { data: userProfilePublicProfile } = useGetFreelancerPublicProfileQuery(
    user?.freelancer ?? skipToken,
  );

  if (userProfilePublicProfile?.external_profiles?.length) {
    for (
      let i = 0;
      userProfilePublicProfile?.external_profiles?.length > i;
      i++
    ) {
      if (
        userProfilePublicProfile?.external_profiles[i]?.public_url.includes(
          "linkedin.com",
        )
      ) {
        urls.push(userProfilePublicProfile?.external_profiles[i].public_url);
        break;
      }
    }
  }

  const baseUrl = window.location.origin;
  const brainTrustProfile =
    baseUrl + userProfilePublicProfile?.user?.profile_url;
  if (brainTrustProfile) urls.push(brainTrustProfile);

  // Conditionally rendering subTitle on the basis
  const role = userProfileSelector?.role?.name;
  const roleSentence =
    role === null || role === "other" ? "" : `I’m targeting ${role} jobs.`;
  const subTitle = `Hi everyone, I’d really appreciate some constructive feedback on my resume, LinkedIn, and Braintrust profiles. ${roleSentence} Thanks in advance!`;

  // Updating data in redux form
  const setValues = async () => {
    try {
      const linkPromises = urls.map((link) =>
        getLinkMetadata({ url: link }, true),
      );
      const results = await Promise.all(linkPromises);

      const links: any = [];
      const links_metada: any = [];

      for (let i = 0; results.length > i; i++) {
        links.push({
          id: results[i]?.data?.url,
          value: results[i]?.data?.url,
        });
        links_metada.push({ ...results[i].data, id: results[i]?.data?.url });
      }
      updateLinksMetadata(links, links_metada);
    } catch (error) {
      console.error("An error occurred while fetching metadata:", error);
    }
  };

  const handleOpenCreatePostModal = useCallback(() => {
    if (user?.is_verified)
      dispatch(openCreatePostModal({ composer_location: postLocation }));
    else openEmailConfirmationModal();

    setTimeout(() => {
      dispatch(
        change(
          CREATE_POST_FORM_ID,
          "title",
          "Feedback on my resume and profiles",
        ),
      );
      dispatch(change(CREATE_POST_FORM_ID, "text", subTitle));
    }, 100);

    setValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, postLocation, user?.is_verified, userProfilePublicProfile]);

  useOpenCreatePostModalFromParams(handleOpenCreatePostModal);
  const createPostSectionRef = useRef<HTMLDivElement | null>(null);
  const shouldShowCreatePostFAB = useShouldShowCreatePostFAB(
    true,
    createPostSectionRef,
  );

  return (
    <>
      <Box
        maxWidth={1280}
        bgcolor={"#E9D7FF"}
        borderRadius="20px"
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={"wrap"}
        gap={2}
        padding={3}
        position={"relative"}
      >
        <Box>
          <Box
            position={"absolute"}
            top={"-12px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            right={"-10px"}
            bgcolor={"#FFFFFF"}
            borderRadius={"500px"}
            onClick={onDismiss}
            padding="14px"
            width={"13px"}
            height={"13px"}
            sx={{
              cursor: "pointer",
            }}
            role="button"
            aria-label="Close"
            data-testid="get-career-help-close-button"
          >
            <CloseIcon fontSize="small" />
          </Box>
          <Typography component="p" fontWeight={500} size="large">
            Need help polishing your resume, LinkedIn, and Braintrust profile?
          </Typography>
          <Typography component="p" fontWeight={400}>
            Get 1:1 help from one of our community experts.
          </Typography>
        </Box>
        <Box>
          <PostBtn
            onClick={handleOpenCreatePostModal}
            isMobile={false}
            disabled={false}
          />

          {shouldShowCreatePostFAB && <CreatePostFAB disabled={false} />}
          <CreatePostModalInstance />
        </Box>
      </Box>
    </>
  );
};

type PostBtnProps = {
  onClick: () => void;
  disabled?: boolean;
  isMobile: boolean;
};
export const PostBtn = ({ onClick, disabled, isMobile }: PostBtnProps) => {
  return (
    <Button
      variant="primary"
      size={isMobile ? "x-small" : "medium"}
      shape="squared"
      onClick={onClick}
      disabled={!!disabled}
    >
      Get Career Help
    </Button>
  );
};
