import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "underscore";

import type { ComboBoxProps } from "@hexocean/braintrust-ui-components";

export const useSearchPhrase = () => {
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const setSearchPhraseDebounced = useMemo(() => {
    return _.debounce((value: string) => {
      setSearchPhrase(value);
    }, 300);
  }, [setSearchPhrase]);

  useEffect(() => {
    return () => setSearchPhraseDebounced.cancel();
  }, [setSearchPhraseDebounced]);

  const onInputChange = useCallback<
    NonNullable<ComboBoxProps<unknown, false>["onInputChange"]>
  >(
    (_ev, value) => {
      const isEmptyInput = value.replaceAll(" ", "").length === 0;

      setSearchPhraseDebounced(isEmptyInput ? "" : value);
    },
    [setSearchPhraseDebounced],
  );

  return {
    onInputChange,
    searchPhrase,
  };
};
