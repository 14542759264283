import { SubmissionError } from "redux-form";
import { createAction } from "@reduxjs/toolkit";
import axios from "axios";

import {
  CLICK_APPLY_AFTER_SEARCH,
  CLICK_INSIGHTS_TOOLTIP,
  EXPAND_DETAILS_AFTER_SEARCH,
  FETCHING_INVITATION_HISTORY,
  FETCHING_INVITATION_HISTORY_FAILED,
  FETCHING_INVITATION_HISTORY_SUCCESS,
  FETCHING_JOB_EVENTS,
  FETCHING_JOB_EVENTS_FAILED,
  FETCHING_JOB_EVENTS_SUCCESS,
  FETCHING_PROPOSAL_HISTORY,
  FETCHING_PROPOSAL_HISTORY_FAILED,
  FETCHING_PROPOSAL_HISTORY_SUCCESS,
  JOB_DETAILS_VISITED,
  JOB_FILTER_SAVED,
  JOB_ITEM_HAS_BEEN_EXPANDED,
  JOB_TITLE_CLICKED_AFTER_SEARCH,
  PROPOSAL_CANCEL,
  PROPOSAL_SUBMITTED,
  SUBMIT_PROPOSAL_CLICKED,
  UNAUTHENTICATED_USER_JOB_APPLY_CLICKS,
  UPDATE_PROPOSAL_STATUS,
  VIEW_INSIGHTS_TOOLTIP,
  VIEW_JOB_CLICKED_AFTER_SEARCH,
  VIEW_JOB_CONFIRMATION,
  VIEW_JOB_LINK_CLICKED,
} from "@js/apps/jobs/action-types";
import { Snackbar } from "@js/components/snackbar";
import { Events } from "@js/services/analytics";
import type { AppThunkAction } from "@js/store";
import type {
  InvitationHistoryItem,
  Job,
  JobFlowEntryType,
  MainJobListingJob,
  Proposal,
} from "@js/types/jobs";

import type { JOB_LOCATION } from "./context";

type JobFlowEventPayload = {
  flow_entry: JobFlowEntryType;
};

export const fetchJobEvents =
  (params: { job: number; page: number }): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: FETCHING_JOB_EVENTS,
      });

      return axios
        .get(`/api/job_events/`, { params })
        .then((response) => {
          dispatch({
            type: FETCHING_JOB_EVENTS_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() => {
          dispatch({
            type: FETCHING_JOB_EVENTS_FAILED,
          });
        });
    });

export const holdJob = (jobId: number) =>
  new Promise((resolve, reject) =>
    axios
      .post(`/api/manage_jobs/${jobId}/on_hold/`, { on_hold: true })
      .then(resolve)
      .catch((e) => reject(e)),
  );

export const releaseJob = (jobId: number) =>
  new Promise((resolve, reject) =>
    axios
      .post(`/api/manage_jobs/${jobId}/on_hold/`, { on_hold: false })
      .then(resolve)
      .catch((e) => reject(e)),
  );

export const jobPostDataRefresh = (
  jobId: number,
): Promise<{ success: boolean }> =>
  new Promise((resolve, reject) => {
    return axios
      .post(`/api/manage_jobs/${jobId}/repost/`)
      .then((response) => resolve(response.data))
      .catch((e) => reject(e));
  });

export const toggleJobInvoicing = (
  jobId: number,
  data: {
    closed: boolean;
  },
): Promise<{ closed: boolean }> => {
  return axios
    .post(`/api/manage_jobs/${jobId}/toggle_invoicing/`, data)
    .then((response) => response.data)
    .catch((e) => new SubmissionError(e?._error));
};

export const inviteToBid = (jobId: number, freelancersIds: number[]) =>
  new Promise((resolve, reject) =>
    axios
      .post(`/api/manage_jobs/${jobId}/invite/`, {
        freelancers: freelancersIds,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        Snackbar.error(`Sorry - this talent can't be invited.`);
        reject(error.response);
      }),
  );

export const updateInvitationInterestedState = (
  jobId: number,
  interested: boolean,
) =>
  new Promise((resolve, reject) =>
    axios
      .post(`/api/jobs/${jobId}/update_invitation_interested_state/`, {
        interested,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        Snackbar.error(`Sorry - something went wrong.`);
        reject(error.response);
      }),
  );

type InviteTalentAsStaffArgs = {
  freelancer: number;
  jobs: number[];
};

export const inviteTalentAsStaff = ({
  freelancer,
  jobs,
}: InviteTalentAsStaffArgs) =>
  new Promise((resolve, reject) => {
    return axios
      .post(`/api/ops_invitations/`, { freelancer, jobs })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });

export const fetchInvitationHistory =
  ({
    freelancer,
  }: {
    freelancer: number;
  }): AppThunkAction<Promise<InvitationHistoryItem[]>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: FETCHING_INVITATION_HISTORY,
      });

      return axios
        .get<InvitationHistoryItem[]>(
          `/api/ops_invitations/recent/?freelancer=${freelancer}`,
        )
        .then((response) => {
          dispatch({
            type: FETCHING_INVITATION_HISTORY_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((error) => {
          dispatch({
            type: FETCHING_INVITATION_HISTORY_FAILED,
          });
          reject(error.response.data);
        });
    });

export const fetchProposalHistory =
  ({ freelancer }: { freelancer: number }): AppThunkAction<Promise<Proposal>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: FETCHING_PROPOSAL_HISTORY,
      });

      return axios
        .get<Proposal>(`/api/ops_freelancer_bids/?freelancer=${freelancer}`)
        .then((response) => {
          dispatch({
            type: FETCHING_PROPOSAL_HISTORY_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((error) => {
          dispatch({
            type: FETCHING_PROPOSAL_HISTORY_FAILED,
          });
          reject(error.response.data);
        });
    });

type SubmitProposalClickedArgs = {
  job: Job | MainJobListingJob;
  search: string;
};

export const submitProposalClicked = ({
  job,
  search,
}: SubmitProposalClickedArgs) => {
  return {
    type: SUBMIT_PROPOSAL_CLICKED,
    payload: { job, search },
  };
};

export const proposalSubmitted = (payload: Record<PropertyKey, unknown>) => {
  return {
    type: PROPOSAL_SUBMITTED,
    payload,
  };
};

export const proposalCancel = (payload: Record<PropertyKey, unknown>) => {
  return {
    type: PROPOSAL_CANCEL,
    payload,
  };
};

export const updateBidStatus = (payload: Record<PropertyKey, unknown>) => {
  return {
    type: UPDATE_PROPOSAL_STATUS,
    payload,
  };
};

export const viewJobConfirmation = () => {
  return { type: VIEW_JOB_CONFIRMATION };
};

type ViewJobClickedArgs = {
  source: EnumType<typeof JOB_LOCATION>;
  search: string;
};

export const viewJobClicked = ({ source, search }: ViewJobClickedArgs) => {
  return {
    type: VIEW_JOB_LINK_CLICKED,
    payload: { source, search },
  };
};

type ViewJobDetailsArgs = {
  source: EnumType<typeof JOB_LOCATION>;
  jobUrl: string;
  jobId: number;
};

export const viewJobDetails = ({
  source,
  jobUrl,
  jobId,
}: ViewJobDetailsArgs) => {
  return {
    type: JOB_DETAILS_VISITED,
    payload: { source, jobUrl, jobId },
  };
};

type JobItemHasBeenExpandedArgs = {
  context: EnumType<typeof JOB_LOCATION>;
  search: string;
};

export const jobItemHasBeenExpanded = ({
  context,
  search,
}: JobItemHasBeenExpandedArgs) => {
  return {
    type: JOB_ITEM_HAS_BEEN_EXPANDED,
    payload: { context, search },
  };
};

export const jobFilterSaved = () => {
  return {
    type: JOB_FILTER_SAVED,
  };
};

export const unauthenticatedUserJobApplyClicked = (jobId: number) => {
  return {
    type: UNAUTHENTICATED_USER_JOB_APPLY_CLICKS,
    payload: { jobId },
  };
};

export type JobAfterSearchPayload = {
  jobId: number;
  resultPosition: number;
  location: EnumType<typeof JOB_LOCATION>;
  searchQueryId: number | undefined;
};

export const clickViewJobAfterSearch = (payload: JobAfterSearchPayload) => {
  return {
    type: VIEW_JOB_CLICKED_AFTER_SEARCH,
    payload,
  };
};

export const clickJobTitleAfterSearch = (payload: JobAfterSearchPayload) => {
  return {
    type: JOB_TITLE_CLICKED_AFTER_SEARCH,
    payload,
  };
};

export const expandDetailsAfterSearch = (payload: JobAfterSearchPayload) => {
  return {
    type: EXPAND_DETAILS_AFTER_SEARCH,
    payload,
  };
};

export const clickApplyAfterSearch = (payload: JobAfterSearchPayload) => {
  return {
    type: CLICK_APPLY_AFTER_SEARCH,
    payload,
  };
};

export const goBackToFirstJobScreen = createAction<JobFlowEventPayload>(
  Events.BACK_TO_FIRST_JOB_POSTING_SCREEN,
);

export const goBackToSecondJobScreen = createAction<JobFlowEventPayload>(
  Events.BACK_TO_SECOND_JOB_POSTING_SCREEN,
);

export const createJobFromMyJobsClicked = createAction(
  Events.CLICK_CREATE_JOB_MY_JOBS,
);

export const createUploadJobDescriptionClicked = createAction(
  Events.CLICK_UPLOAD_JD,
);

export const postJobClicked = createAction<JobFlowEventPayload>(
  Events.CLICK_POST_JOB,
);

export const reviewJobClicked = createAction<JobFlowEventPayload>(
  Events.CLICK_REVIEW_JOB,
);

export const setUpNewJobClicked = createAction<JobFlowEventPayload>(
  Events.CLICK_SET_UP_NEW_JOB,
);

export const deleteJobDraftClicked = createAction<JobFlowEventPayload>(
  Events.DELETE_JOB_DRAFT_CREATION_FLOW,
);

export const createCustomJobClicked = createAction(
  Events.CLICK_EVENT_CREATE_CUSTOM,
);

export const createAiGeneratedJobClicked = createAction(
  Events.CLICK_EVENT_CREATE_AI,
);

export const createJobByUploadClicked = createAction(
  Events.CLICK_EVENT_CREATE_UPLOAD,
);

export const addTeamMembersClicked = createAction(
  Events.CLICK_ADD_TEAM_MEMBERS,
);

export const clickBrowseOpenJobsButton = createAction(
  Events.CLICK_JOB_CARD_BROWSE_JOBS,
);

export const purchaseBoostOnrampClicked = createAction(
  Events.CLICK_ONRAMP_BOOST,
);

export const purchaseInsightsOnrampClicked = createAction(
  Events.CLICK_ONRAMP_INSIGHTS,
);

export const purchaseWalletOnrampClicked = createAction(
  Events.CLICK_ONRAMP_WALLET,
);

export const insightsBannerClicked = createAction(Events.CLICK_INSIGHTS_BANNER);

export const insightsBannerViewed = createAction(Events.VIEW_INSIGHTS_BANNER);

export const insightsPurchaseModalClicked = createAction(
  Events.CLICK_INSIGHTS_PURCHASE_MODAL,
);

export const boostPurchaseModalClicked = createAction(
  Events.CLICK_BOOST_PURCHASE_MODAL,
);

export const boostBannerClicked = createAction(Events.CLICK_BOOST_BANNER);

export const boostBannerViewed = createAction(Events.VIEW_BOOST_BANNER);

export const boostButtonClicked = createAction(Events.CLICK_BOOST_BUTTON);

export const insightsSectionViewed = createAction(Events.VIEW_INSIGHTS_SECTION);

export const insightsTooltipClicked = ({ jobId }: { jobId: number }) => {
  return {
    type: CLICK_INSIGHTS_TOOLTIP,
    payload: { jobId },
  };
};

export const insightsTooltipViewed = ({ jobId }: { jobId: number }) => {
  return {
    type: VIEW_INSIGHTS_TOOLTIP,
    payload: { jobId },
  };
};
