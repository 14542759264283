import { useEffect, useRef, useState } from "react";
import copy from "copy-to-clipboard";

type CopyToClipboard = (onCopied?: () => void) => {
  copied: boolean;
  handleCopy: (text: string) => void;
};

export const useCopyToClipboard: CopyToClipboard = (onCopied) => {
  const [copied, setCopied] = useState(false);
  const timeout = useRef<any>();

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const handleCopy = (text: string) => {
    copy(text);
    setCopied(true);
    onCopied?.();

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return {
    copied,
    handleCopy,
  };
};
