import { useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { useGetRolesQuery } from "@js/apps/roles/api";
import { ButtonSelectField } from "@js/forms/fields/button-select";
import type { Role } from "@js/types/roles";

import type { FreelancerSkillOption, JobSkillOption } from "../types";

import styles from "./style.module.scss";

type PopularSkillsFieldProps = TypedWrappedFieldProps<number[]> & {
  selectedRole?: Role | number;
  options: (FreelancerSkillOption | JobSkillOption)[];
};

export const PopularSkillsField = ({
  input,
  meta,
  selectedRole,
  options,
}: PopularSkillsFieldProps) => {
  const { data: roles = [] } = useGetRolesQuery();

  const selectedRoleData = useMemo(() => {
    return typeof selectedRole === "number"
      ? roles?.find((role) => role.id === selectedRole)
      : selectedRole;
  }, [selectedRole, roles]);

  return (
    <>
      <div className={styles.label}>
        <Typography component="p" size="small">
          Popular skills{selectedRole && " for"}
          {selectedRoleData && (
            <Typography component="span" size="small" fontWeight={500}>
              &nbsp;{selectedRoleData.name_plural}
            </Typography>
          )}
        </Typography>
      </div>
      <div className={styles.buttons}>
        <ButtonSelectField
          mapObjectValueToKey={(value) => String(value.id)}
          variant="off-white-grey"
          meta={meta}
          input={input}
          options={options}
        />
      </div>
    </>
  );
};
