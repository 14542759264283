import type { FC } from "react";
import React, { useCallback, useState } from "react";
import cs from "classnames";

import {
  Box,
  Button,
  Carousel,
  IconButton,
  Typography,
  useSwiperRefs,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { EditPenIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import type {
  FreelancerForNodeStaff,
  FreelancerPublic,
  WorkSampleItem,
} from "@js/types/freelancer";

import { CarouselHeader } from "../home-carousel";

import { ProfileProjectsModuleItem } from "./profile-projects-module-item";
import type { ProjectView } from "./profile-projects-module-modal-manager";
import {
  ProfileProjectModuleModalManager,
  ProfileProjectsModuleModal,
} from "./profile-projects-module-modal-manager";

type ProfileProjectsModuleProps = {
  workSampleItems: WorkSampleItem[];
  profile: FreelancerPublic | FreelancerForNodeStaff;
};

const MAX_NUMBER_PER_PAGE = 3;
const TABLET_PAGE_SIZE = 2;
const MINIMUM_PAGE_SIZE = 1;

export const ProfileProjectsModule: FC<ProfileProjectsModuleProps> = ({
  workSampleItems,
  profile,
}) => {
  const user = useUser();
  const isOwnProfile = !!user && profile.id === user.freelancer;
  const [initialView, setInitialView] = useState<ProjectView>("ADD_MODAL");

  const { nextEl, nextElRef, prevEl, prevElRef } = useSwiperRefs();

  const isMobile = useMediaQuery(960);
  const isTablet = useMediaQuery(1280);

  const itemsPerPage = (() => {
    if (isMobile) {
      return MINIMUM_PAGE_SIZE;
    }

    if (isTablet) {
      return TABLET_PAGE_SIZE;
    }

    return MAX_NUMBER_PER_PAGE;
  })();

  const hideNavigationButtons = Number(workSampleItems.length) <= itemsPerPage;

  const handleOpenEditProjectList = useCallback(() => {
    if (initialView !== "PROJECT_LIST") setInitialView("PROJECT_LIST");
    ProfileProjectsModuleModal.open();
  }, [initialView]);

  const handleOpenAddProject = useCallback(() => {
    if (initialView !== "ADD_MODAL") setInitialView("ADD_MODAL");
    ProfileProjectsModuleModal.open();
  }, [initialView]);

  return (
    <>
      <ProfileProjectsModuleModal>
        <ProfileProjectModuleModalManager
          onClose={ProfileProjectsModuleModal.close}
          initialView={initialView}
        />
      </ProfileProjectsModuleModal>
      <Box
        className={cs("projects", {
          "profile-empty-state": isOwnProfile && !workSampleItems.length,
        })}
      >
        <Box display="flex">
          <Box flex={1}>
            <CarouselHeader
              title="Projects"
              titleProps={{
                variant: "paragraph",
                size: "large",
                fontWeight: 400,
              }}
              prevRef={prevElRef}
              nextRef={nextElRef}
              hideNavigationButtons={hideNavigationButtons}
              mb={3}
            />
          </Box>
          <Box className="projects--owner-edit-button-box">
            {isOwnProfile && !!workSampleItems.length && (
              <IconButton
                variant="black-outlined"
                size="x-small"
                aria-label="Edit project"
                onClick={handleOpenEditProjectList}
              >
                <EditPenIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        {!workSampleItems.length ? (
          <EmptyState
            name={profile.user.first_name}
            isOwnProfile={isOwnProfile}
            handleOpenAddProject={handleOpenAddProject}
          />
        ) : (
          <Carousel
            slides={workSampleItems.map((item) => {
              return <ProfileProjectsModuleItem item={item} key={item.id} />;
            })}
            slideProps={{
              style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
            observer
            observeParents
            slidesPerView={itemsPerPage}
            spaceBetween={24}
            navigation={{
              prevEl,
              nextEl,
            }}
          />
        )}
      </Box>
    </>
  );
};

type EmptyStateProps = {
  isOwnProfile: boolean;
  name: string;
  handleOpenAddProject: () => void;
};

const EmptyState = ({
  name,
  handleOpenAddProject,
  isOwnProfile,
}: EmptyStateProps) => {
  return (
    <Box mt={-2}>
      <Typography component="p" fontStyle="italic" multipleEllipsis>
        {isOwnProfile
          ? "Add your best projects and portfolio pieces to showcase your strengths and impact."
          : `${name} hasn't added any projects, yet.`}
      </Typography>
      {isOwnProfile && (
        <Button
          variant="black-outlined"
          size="medium"
          shape="squared"
          onClick={handleOpenAddProject}
          sx={{ mt: 4 }}
        >
          Add projects
        </Button>
      )}
    </Box>
  );
};
