import type { AccountInfoFormData } from "@js/apps/freelancer/forms/account-info";

export const prepareUpdateFreelancerCustomizationExternalProfilesValue = (
  values: AccountInfoFormData,
) => {
  const externalProfiles = values?.external_profiles;

  if (!externalProfiles) return;

  const mappedProfiles = externalProfiles.map((profile) => {
    if (!profile.public_url) {
      return null;
    }

    return {
      public_url: profile.public_url,
      new_site: profile.id,
    };
  });

  return mappedProfiles.filter(
    (
      externalProfile,
    ): externalProfile is {
      new_site: number;
      public_url: string;
    } => Boolean(externalProfile),
  );
};
