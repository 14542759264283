import { useABExperiment } from "@js/apps/experiments";

export const useTokenFeatureManagement = () => {
  const { isParticipating, experiment } = useABExperiment(
    "Token Feature Management",
  );

  const isTokenFeatureManagementEnabled =
    experiment?.variant ===
    SETTINGS.EXPERIMENT_ENUMS[
      SETTINGS.EXPERIMENT_ENUMS.Experiments.TokenFeatureManagement
    ]?.TokenFeatureManagementEnabled;

  return {
    showTokenFeatureManagement:
      isParticipating && isTokenFeatureManagementEnabled,
  };
};
