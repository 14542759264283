import _ from "underscore";

import {
  CHECKLIST_ITEMS,
  OnboardingChecklist,
} from "@js/apps/freelancer/components/onboarding-checklist";
import type { FreelancerOffer } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

type TalentOfferChecklistProps = {
  offer: FreelancerOffer;
};

export const TalentOfferChecklist = ({ offer }: TalentOfferChecklistProps) => {
  switch (offer.job.job_type) {
    case ENUMS.JobType.FREELANCE:
      return <OnboardingChecklist />;
    case ENUMS.JobType.GRANT: {
      return (
        <OnboardingChecklist
          filterActions={(actions) =>
            actions.filter(
              (action) => action.type !== CHECKLIST_ITEMS.INVOICING,
            )
          }
        />
      );
    }
    case ENUMS.JobType.DIRECT_HIRE: {
      return null;
    }
    default: {
      assertUnreachable(offer.job.job_type);
      return null;
    }
  }
};
