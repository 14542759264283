import { Typography } from "@hexocean/braintrust-ui-components";
import { RefreshIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./style.module.scss";

export const ImportedFromAtsLabel = () => {
  return (
    <span className={styles.atsLabel}>
      <RefreshIcon style={{ width: "16px", height: "16px" }} />
      <Typography component="p" size="small" color="primary">
        Imported from ATS
      </Typography>
    </span>
  );
};
