import {
  getCopyDraftInitialValues,
  getCopyJobInitialValues,
  getDraftInitialValues,
  getEmptyJobInitialValues,
  getUploadJobDescriptionInitialValues,
} from "@js/apps/jobs/apps/create-job/utils/form-initial-values";
import { getCreateJobInitialValuesOnBehalfOfAClient } from "@js/apps/jobs/apps/create-job/utils/form-initial-values-on-behalf-of-a-client";
import type { GetCreateJobInitialValuesArg } from "@js/apps/jobs/apps/create-job/utils/types";
import type { JobFormValues } from "@js/types/jobs";

export const getCreateJobInitialValues = (
  args: GetCreateJobInitialValuesArg,
): Partial<JobFormValues> | undefined => {
  const {
    userId,
    draft,
    canManageJobAsCoreMember,
    jobToCopy,
    aiGenerated,
    copyDraftId,
    uploadedJobDescription,
  } = args;

  if (!userId) {
    return;
  }

  if (canManageJobAsCoreMember) {
    return getCreateJobInitialValuesOnBehalfOfAClient({ ...args, userId });
  }

  if (copyDraftId && draft) {
    return getCopyDraftInitialValues({ draft, userId });
  }

  if (draft) {
    return getDraftInitialValues({
      draft,
      userId,
    });
  }

  if (jobToCopy) {
    return getCopyJobInitialValues(jobToCopy);
  }

  if (aiGenerated) {
    return getCopyJobInitialValues(aiGenerated);
  }

  if (uploadedJobDescription) {
    return getUploadJobDescriptionInitialValues(uploadedJobDescription, userId);
  }

  return getEmptyJobInitialValues(userId);
};
