import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { useCompletionSequenceContext } from "@js/apps/freelancer/components/profile-completion-sequence/context/completion-sequence-context";
import { AccountInfoForm } from "@js/apps/freelancer/forms/account-info";
import {
  useAccountInfo,
  useFreelancerProfile,
} from "@js/apps/freelancer/hooks";

import { ProfileCompletionSequenceCloseBtn } from "./close-btn";

export const ProfileCompletionSequenceAboutStep = () => {
  const profile = useFreelancerProfile();
  const {
    onSubmitSuccess: _onSubmitSuccess,
    isLoading,
    ...formConfig
  } = useAccountInfo(profile);

  const { handleStepCompleted } = useCompletionSequenceContext();

  const onSubmitSuccess = () => {
    _onSubmitSuccess().then(() => {
      handleStepCompleted();
    });
  };

  if (isLoading) {
    return <Loader centered />;
  }

  return (
    <Box className="profile-page-modal-account-info" sx={{ padding: 4 }}>
      <ProfileCompletionSequenceCloseBtn />
      <AccountInfoForm
        {...formConfig}
        onSubmitSuccess={onSubmitSuccess}
        headerComponent={
          <Typography component="h3" variant="title" size="small" mb={4}>
            👋 Bio
          </Typography>
        }
      />
    </Box>
  );
};
