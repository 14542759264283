import type { ConfigProps } from "redux-form";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { MODULE_EDIT_MODAL_PROPERTIES } from "@js/apps/freelancer/constants";
import type { HistoryWorkFormData } from "@js/apps/freelancer/forms/work-history-form";
import { WorkHistoryForm } from "@js/apps/freelancer/forms/work-history-form";
import { CommonConfirmationModal } from "@js/components/modal";

type EditWorkHistoryModalProps = Pick<
  ConfigProps<HistoryWorkFormData>,
  "onSubmit" | "onSubmitSuccess" | "initialValues"
>;

export const openEditWorkHistoryModal = ({
  onSubmit,
  onSubmitSuccess,
  initialValues,
}: EditWorkHistoryModalProps) =>
  CommonConfirmationModal.open({
    children: (
      <EditJobModalContent
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        initialValues={initialValues}
      />
    ),
    ...MODULE_EDIT_MODAL_PROPERTIES,
  });

type OpenRemoveWorkHistoryModalProps = {
  title: string;
  onDelete: () => Promise<void>;
};

export const openRemoveWorkHistoryModal = ({
  title,
  onDelete,
}: OpenRemoveWorkHistoryModalProps) =>
  CommonConfirmationModal.open({
    children: (
      <RemoveJobConfirmationModalContent onDelete={onDelete} title={title} />
    ),
    ...MODULE_EDIT_MODAL_PROPERTIES,
  });

const EditJobModalContent = ({
  onSubmit,
  onSubmitSuccess,
  initialValues,
}: EditWorkHistoryModalProps) => {
  return (
    <Box padding={4} maxWidth="740px">
      <ModalHeaderWithArrow>Edit job</ModalHeaderWithArrow>
      <WorkHistoryForm
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        initialValues={initialValues}
        editMode
      />
    </Box>
  );
};

const RemoveJobConfirmationModalContent = ({
  title,
  onDelete,
}: OpenRemoveWorkHistoryModalProps) => {
  return (
    <Box padding={4} maxWidth="740px">
      <ModalHeaderWithArrow>Remove job</ModalHeaderWithArrow>
      <Typography variant="paragraph" component="p" mb={4}>
        Removing your <b>{title}</b> job from your profile can’t be undone. Are
        you sure you want to remove this job?
      </Typography>
      <Box display="flex" gap={2} justifyContent="flex-end">
        <Button
          variant="black-outlined"
          shape="squared"
          onClick={CommonConfirmationModal.close}
        >
          Keep job
        </Button>
        <Button variant="destructive" shape="squared" onClick={onDelete}>
          Remove job
        </Button>
      </Box>
    </Box>
  );
};

const ModalHeaderWithArrow = ({ children }: { children: string }) => (
  <Box display="flex" gap={2} mb={4}>
    <span>
      <IconButton
        variant="tertiary"
        onClick={CommonConfirmationModal.close}
        aria-label="close confirmation modal"
      >
        <ArrowLeftIcon />
      </IconButton>
    </span>
    <Typography variant="title" component="h3" size="small" fontWeight={400}>
      {children}
    </Typography>
  </Box>
);
