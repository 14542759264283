import { useSearchParams } from "react-router-dom";

import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { PostLocation } from "@js/apps/give-and-get-help/context/post-location";

import { useGetSpaceDetailsQuery } from "../../api";
import { useIsSpaceAdmin } from "../use-is-space-admin";
import { useIsSpaceMember } from "../use-is-space-member";
import { useSpaceNotifications } from "../use-space-notifications";

type useDiscussionArgs = {
  spaceId: number;
};

export const useDiscussion = ({ spaceId }: useDiscussionArgs) => {
  const { data } = useGetSpaceDetailsQuery({ id: spaceId });
  const shouldHideDetailsSection = useMediaQuery(1200);
  const { isSpaceMember } = useIsSpaceMember({ spaceId });

  const { isSpaceAdmin } = useIsSpaceAdmin({
    spaceId,
  });
  const { areNotificationsTurnOn } = useSpaceNotifications();

  const [params] = useSearchParams();
  const hashtag = params.get("hashtag");

  const postLocationValue = hashtag
    ? PostLocation.Values.hashtag
    : PostLocation.Values.space;
  return {
    spaceData: data,
    shouldHideDetailsSection,
    postLocationValue,
    isAdmin: isSpaceAdmin,
    isMember: isSpaceMember,
    areNotificationsTurnOn,
  };
};
