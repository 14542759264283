import React from "react";

import { NavLink } from "@js/components/nav-link";

export type CrumbProps = {
  title: string;
  path?: string;
  onClick?: () => void;
};

export const Crumb = ({ title, path, onClick }: CrumbProps) => {
  if (path) {
    return (
      <NavLink onClick={onClick} to={path} className="crumb link">
        {title}
      </NavLink>
    );
  }

  return <span className="crumb">{title}</span>;
};
