import React, { useState } from "react";

import { AuthComponent } from "@js/apps/auth/components";
import { useWithRecaptcha } from "@js/apps/common/hooks";
import { getValuesWithReCaptchaCode } from "@js/forms/utils";
import { useAppDispatch } from "@js/hooks";

import { forgotPassword } from "../actions";
import { CheckMailStatus } from "../components/check-mail";
import type { ForgotPasswordFormValues } from "../forms";
import { ForgotPasswordForm } from "../forms";

import { AuthSignInLayout } from "./layout/sign-in-layout";

const ForgotPasswordPage = () => {
  useWithRecaptcha();
  const [showForm, setShowForm] = useState(true);
  const dispatch = useAppDispatch();

  const onSubmit = async (values: ForgotPasswordFormValues) => {
    const valuesWithRecaptchaCode = await getValuesWithReCaptchaCode(
      values,
      ENUMS.CaptchaActions.PASSWORD_RESET,
    );

    return dispatch(forgotPassword(valuesWithRecaptchaCode)).then(() =>
      setShowForm(false),
    );
  };

  return (
    <AuthSignInLayout pageTitle="Reset password">
      <AuthComponent title="Reset your password" titleSize="medium">
        {showForm ? (
          <ForgotPasswordForm onSubmit={onSubmit} />
        ) : (
          <CheckMailStatus />
        )}
      </AuthComponent>
    </AuthSignInLayout>
  );
};

export default ForgotPasswordPage;
