import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Fields } from "redux-form";

import {
  Box,
  Checkbox,
  Loader,
  PaperWithVioletOptions,
  StyleAutocompleteMediumVioletChips,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  LocationPinIcon,
  MenuArrowDownIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { TalentLocationFormValues } from "@js/apps/common/components/filters/config";
import { TalentLocationForm } from "@js/apps/common/components/filters/config";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import { useTalentLocationFilter } from "@js/apps/common/hooks/talent-location-filter";
import { useGetTalentPopularLocationsQuery } from "@js/apps/freelancer/api";
import { RecommendedLocationButtonSelect } from "@js/apps/freelancer/forms/fields/recommended-location-button-select";
import { useHandleFilterApplied } from "@js/apps/freelancer/hooks/use-handle-filter-applied";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components";
import type { LocationValue } from "@js/components/autocomplete-new/google-places/types";
import { GoogleComboboxMultipleField } from "@js/forms/fields/autocomplete/";
import type { TypedWrappedFieldInputProps } from "@js/forms/utils";
import { isError } from "@js/forms/utils";
import { PossibleFilters } from "@js/types/common";

const formId = "talent-filters__location";

export const TalentLocationFilter = () => {
  const { label, isOpen, setIsOpen, initialLocation, isActive } =
    useTalentLocationFilter();
  const { handleFilterApplied } = useHandleFilterApplied();
  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    handleFilterApplied({
      filter_type: PossibleFilters.LOCATION,
      filter_value: values,
    });
  };

  const handleSubmitSuccess = () => {
    setIsOpen(false);
  };

  return (
    <JobPopoverFilterButton
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      startIcon={<LocationPinIcon />}
      isActive={isActive}
      label={label}
      popoverContent={
        <TalentLocationForm
          transformInitialValues={(values) => {
            return {
              ...values,
              location: initialLocation,
            } as unknown as TalentLocationFormValues;
          }}
          form={formId}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitSideAction={handleSubmitSideAction}
        >
          {({ submit }) => {
            return (
              <Fields
                submit={submit}
                names={["location", "include_verified_locations_only"]}
                component={TalentLocationFilterField}
              />
            );
          }}
        </TalentLocationForm>
      }
    />
  );
};

type TalentLocationFilterFieldProps = {
  submit: () => void;
  location: TypedWrappedFieldProps<LocationValue[]>;
} & {
  include_verified_locations_only: TypedWrappedFieldProps<boolean>;
};

const TalentLocationFilterField = ({
  submit,
  location,
  include_verified_locations_only,
}: TalentLocationFilterFieldProps) => {
  const handleReset = () => {
    location.input.onChange([]);
    include_verified_locations_only?.input.onChange(null);
  };

  const error = isError([
    location,
    // no error in others
  ]);

  return (
    <JobPopoverFilterButton.Content
      onReset={handleReset}
      onApply={submit}
      disableApply={error}
    >
      <Box p={1}>
        <Typography
          component="h3"
          mb={1}
          variant="label"
          size="large"
          lineHeight={1.4}
          fontWeight={500}
        >
          Select talent location 📌
        </Typography>
        <GoogleComboboxMultipleField<true>
          id="location"
          data-testid="multiple-location-field"
          label={undefined}
          placeholder={
            !!location.input.value?.length
              ? ""
              : "Select a country, state, or city"
          }
          noErrorOnTouch
          disableClearable
          fullWidth
          useCustomLocations
          placesServiceTypes={"regions_without_sublocality"}
          component={StyleAutocompleteMediumVioletChips}
          PaperComponent={PaperWithVioletOptions}
          popupIcon={<MenuArrowDownIcon />}
          locationIcon={false}
          input={{
            ...location.input,
            onChange: (value) => {
              location.input.onChange(value as LocationValue[]);
            },
          }}
          meta={location.meta}
        />
        <PopularLocationsButtonSelect
          input={location.input}
          meta={location.meta}
        />
        <VerifiedLocationsOnlyCheckbox
          input={include_verified_locations_only.input}
        />
      </Box>
    </JobPopoverFilterButton.Content>
  );
};

const PopularLocationsButtonSelect = ({
  input,
  meta,
}: TypedWrappedFieldProps<LocationValue[]>) => {
  const { data: options, isLoading } = useGetTalentPopularLocationsQuery();

  if (!options?.length || isLoading) return <Loader />;

  return (
    <RecommendedLocationButtonSelect
      input={input}
      meta={meta}
      options={options}
      label="Popular Talent locations"
    />
  );
};

const VerifiedLocationsOnlyCheckbox: React.FC<{
  input: TypedWrappedFieldInputProps<boolean | null>;
}> = ({ input }) => {
  const isNodeStaff = useIsNodeStaff();

  if (!isNodeStaff) return null;

  return (
    <Box display="flex" mt={2}>
      <Checkbox
        checked={Boolean(input.value)}
        // set this field's value to null so that we don't send the include_verified_locations_only=false search param
        // instead, we complete remove this param if the checkbox is unchecked
        onChange={() => input.onChange(input.value ? null : true)}
        label={
          <Typography component="p" variant="paragraph">
            Only include Talent with a verified location
          </Typography>
        }
      />
    </Box>
  );
};
