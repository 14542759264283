import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { Date } from "@js/components/date";
import type { EmployerOffer, FreelancerOffer } from "@js/types/jobs";
import { typeGuard } from "@js/utils";
import { DateFormats } from "@js/utils/date/types";

type TalentOrEmployerType = EmployerOffer | FreelancerOffer;

type OfferDetailsHeaderProps = {
  offer: TalentOrEmployerType;
};

export const OfferDetailsHeader = ({ offer }: OfferDetailsHeaderProps) => {
  let date = offer.created;
  let suffix = "Sent";

  if (typeGuard<TalentOrEmployerType, FreelancerOffer>(offer, "updated_at")) {
    if (offer.updated_at) {
      date = offer.updated_at;
      suffix = "Updated";
    }
  }

  return (
    <Box>
      <Box
        component="header"
        display="flex"
        justifyContent="space-between"
        gap={1}
        flexWrap="wrap"
      >
        <Typography component="h2" size="large" variant="label">
          🗓 Offer details
        </Typography>
        <Typography component="span" variant="label">
          {suffix} <Date date={date} format={DateFormats["January 1, 1970"]} />
        </Typography>
      </Box>
    </Box>
  );
};
