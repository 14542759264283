import type { TypedWrappedFieldProps } from "redux-form";

import { SkillsFilterCombobox } from "@js/apps/skills/fields/skills-filter-combobox";
import type { Skill } from "@js/types/admin";

type SkillsComboboxProps = {
  skills: TypedWrappedFieldProps<number[]>;
  onChangeSearchSkill: (skills: Skill[] | null) => void;
  selectedSkills: Skill[];
};

export const SkillsCombobox = ({
  skills,
  onChangeSearchSkill,
  selectedSkills,
}: SkillsComboboxProps) => {
  const skillsFilterInput = {
    ...skills.input,
    onChange: onChangeSearchSkill,
    value: selectedSkills,
  };

  const meta = {
    ...skills.meta,
    error: Array.isArray(skills.meta.error?.skills)
      ? skills.meta.error?.skills
      : [],
  };

  return <SkillsFilterCombobox input={skillsFilterInput} meta={meta} />;
};
