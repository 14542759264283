import React, { useContext } from "react";

const Values = {
  feed: "feed",
  space: "space",
  trending_posts_module: "trending_posts_module",
  single_post_view: "single_post_view",
  single_space_post_view: "single_space_post_view",
  hashtag: "hashtag",
  my_posts: "my_posts",
  dashboard_help_offers: "dashboard_help_offers",
  talent_home: "talent_home",
  bookmark: "bookmark",
  notification: "notification",
  post_search: "post_search",
  role_jobs: "talent",
} as const;

export type PostsLocation = EnumType<typeof Values>;

const PostLocationContext = React.createContext<PostsLocation | null>(null);

type ProviderProps = {
  value: PostsLocation;
  children: React.ReactNode;
};

const Provider = ({ value, children }: ProviderProps) => {
  return (
    <PostLocationContext.Provider value={value}>
      {children}
    </PostLocationContext.Provider>
  );
};

export const usePostLocationContext = () => {
  const context = useContext(PostLocationContext);

  if (!context) {
    throw new Error("PostLocationProvider is missing.");
  }

  return context;
};

export const PostLocation = {
  Provider: Provider,
  Values: Values,
  Consumer: PostLocationContext.Consumer,
};
