import type { LazyFeatureBundle } from "framer-motion";

const FramerFeatureLoader = {
  domAnimation: () =>
    import(
      /* webpackChunkName: "framer-dom-animation" */ "../features/domAnimationLazy"
    ),
  domMax: () =>
    import(/* webpackChunkName: "framer-dom-max" */ "../features/domMaxLazy"),
} as const;

export type FramerFeatureType = keyof typeof FramerFeatureLoader;

export const loadFramerFeature =
  (feature: FramerFeatureType): LazyFeatureBundle =>
  () =>
    FramerFeatureLoader[feature]().then((res) => res.default);
