import { Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useSearchEventQueryId } from "@js/apps/tracking/search-event-slice";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";

import type { UniversalTabValue } from "../constants";
import { useCloseSearchContext } from "../context/close-search-context";
import {
  getResultsLabel,
  getResultsLogEvent,
  getResultsURL,
  handleFiltersReset,
} from "../helpers";

export const UniversalSearchResultsLink = ({
  searchPhrase,
  searchTab,
}: {
  searchPhrase: string;
  searchTab: UniversalTabValue;
}) => {
  const handleCloseSearch = useCloseSearchContext();
  const searchQueryId = useSearchEventQueryId(
    ENUMS.SearchEventOption.MAIN_SEARCH_BOX,
  );
  const isMobile = useMediaQuery("sm");
  const logClickEvent = getResultsLogEvent(searchTab);
  const dispatch = useAppDispatch();

  const handleOnClick = () => {
    if (!searchPhrase) {
      handleFiltersReset(searchTab);
    }

    if (searchPhrase && logClickEvent) {
      dispatch(logClickEvent({ search_query_id: searchQueryId }));
    }

    handleCloseSearch();
  };

  return (
    <Typography
      component="span"
      variant="paragraph"
      size="small"
      aria-label="Search results"
      color="blue"
    >
      <RouterLink
        to={getResultsURL(searchPhrase, searchTab)}
        onClick={handleOnClick}
        style={{ color: "inherit" }}
      >
        {getResultsLabel(searchPhrase, searchTab, isMobile ? 26 : 38)}
      </RouterLink>
    </Typography>
  );
};
