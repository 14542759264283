import { useSnackbar } from "notistack";

import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";

type JobInterviewQuestionSnackbarContentProps = {
  header: string;
  message: string;
};

export const JobInterviewQuestionSnackbarContent = ({
  header,
  message,
}: JobInterviewQuestionSnackbarContentProps) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <Box
      bgcolor="#FFDAD2"
      p={2}
      maxWidth="366px"
      boxSizing="border-box"
      boxShadow="var(--elevation-4)"
      borderRadius="10px"
    >
      <Box position="absolute" top={0} right={0}>
        <IconButton
          size="small"
          variant="transparent"
          onClick={() => closeSnackbar()}
          aria-label="Close"
        >
          <CloseIcon
            style={{
              color: "var(--black)",
              fontSize: 20,
              alignItems: "start",
            }}
          />
        </IconButton>
      </Box>
      <Typography
        component="p"
        variant="label"
        sx={{ wordBreak: "break-word" }}
        size="medium"
        fontWeight={500}
        mb={1}
      >
        {header}
      </Typography>
      <Typography
        component="p"
        sx={{ wordBreak: "break-word" }}
        size="small"
        fontWeight={400}
      >
        {message}
      </Typography>
    </Box>
  );
};
