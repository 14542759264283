import { useCallback, useEffect, useMemo } from "react";

import {
  Alert,
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { BoltIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { JobCarouselItem } from "@js/apps/freelancer/components/home-carousel/job-carousel-item";
import { viewJobConfirmation } from "@js/apps/jobs/actions";
import { useGetRelevantJobsQuery } from "@js/apps/jobs/api";
import { useLazyGetTotalJobBidsQuery } from "@js/apps/jobs/api";
import { openBoostModal } from "@js/apps/jobs/components/job-item/components/open-boost-modal/open-boost-modal";
import { JobLocationContext } from "@js/apps/jobs/context/job-location-context";
import { RouterLink } from "@js/components/link";
import { useAppDispatch, useNavigate } from "@js/hooks";

import { BidCreateSuccessModalCloseButton } from "../bid-create-success-modal-close-button";

import styles from "./freelancer-screened-modal-content.module.scss";

type FreelancerScreenedModalContentProps = {
  closeModal?: () => void;
  onPurchaseBoostCredits?: () => void;
  roleId: number;
  isPreBoosted: boolean;
  jobId: number;
  bidId: number;
};

export const FreelancerScreenedModalContent = ({
  closeModal,
  roleId,
  isPreBoosted,
  jobId,
  bidId,
  onPurchaseBoostCredits,
}: FreelancerScreenedModalContentProps) => {
  const isMobile = useMediaQuery("sm");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useUser();

  const fetchRelevantJobsFilters = useMemo(
    () => ({ role: roleId, page_size: 3 }),
    [roleId],
  );
  const showBoostButton = useMemo(
    () => user?.freelancer_approved && !isPreBoosted,
    [isPreBoosted, user?.freelancer_approved],
  );
  const hasBoostCredit = useMemo(
    () => !!user?.boost_credit,
    [user?.boost_credit],
  );

  const { data, isLoading } = useGetRelevantJobsQuery(fetchRelevantJobsFilters);

  const [getTotalJobBidsQuery, { data: totalBidsData }] =
    useLazyGetTotalJobBidsQuery();

  const similarJobs = data?.results;
  const totalApplicants = totalBidsData?.bids_count || 0;

  const returnToJobsList = useCallback(() => {
    navigate("/jobs/");
    closeModal?.();
  }, [closeModal, navigate]);

  const viewApplications = () => {
    navigate("/talent/dashboard/my_jobs/my_proposals");
    closeModal?.();
  };

  const boostApplication = useCallback(() => {
    closeModal?.();
    openBoostModal({
      boostCredit: user?.boost_credit ?? 0,
      bidId,
      onClose: returnToJobsList,
      onCompleteBoost: returnToJobsList,
    });
  }, [bidId, closeModal, returnToJobsList, user?.boost_credit]);

  const purchaseBoostCredits = useCallback(() => {
    closeModal?.();
    onPurchaseBoostCredits?.();
  }, [closeModal, onPurchaseBoostCredits]);

  const title = useMemo(() => {
    if (user?.freelancer_approved) {
      return isPreBoosted ? (
        `Your application is now at the top${totalApplicants < 10 ? "." : ` of ${totalBidsData?.bids_count || 0} applicants`}`
      ) : (
        <>
          Application submitted! <br />
          Boost it now to get the hiring manager’s attention faster.
        </>
      );
    }

    return "Your application was sent! Good luck!";
  }, [
    isPreBoosted,
    totalApplicants,
    totalBidsData?.bids_count,
    user?.freelancer_approved,
  ]);

  useEffect(() => {
    dispatch(viewJobConfirmation());
  }, [dispatch]);

  useEffect(() => {
    if (isPreBoosted && typeof jobId === "number") {
      getTotalJobBidsQuery({ jobId });
    }
  }, [getTotalJobBidsQuery, isPreBoosted, jobId]);

  return (
    <>
      <Box
        className={styles.topSection}
        sx={{
          backgroundColor: isPreBoosted ? "var(--blue)" : "var(--medium-green)",
        }}
      >
        <BidCreateSuccessModalCloseButton
          variant="transparent"
          onClick={returnToJobsList}
          sx={{
            position: "absolute",
            top: "28px",
            right: "32px",
          }}
        />
        {isPreBoosted ? (
          <Box display="flex">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              zIndex={1}
            >
              <Box
                border="4px solid var(--blue)"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="46.5px"
                height="93px"
                width="93px"
                sx={{ backgroundColor: "var(--white)" }}
              >
                <img
                  height="42.214px"
                  width="auto"
                  src={`${SETTINGS.STATIC_URL}jobs/boost-icon-rounded.svg`}
                  alt="boosted-rounded-icon"
                />
              </Box>
              <Box
                mt="-20px"
                width="103px"
                sx={{
                  backgroundImage: `url(${SETTINGS.STATIC_URL}jobs/boosted-ribbon-bg.svg)`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <Typography
                  color="white"
                  component="p"
                  variant="label"
                  size="small"
                  fontWeight={500}
                >
                  Boosted!
                </Typography>
              </Box>
            </Box>
            <Box
              marginLeft="-29px"
              height="97px"
              width="97px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundImage: `url(${SETTINGS.STATIC_URL}jobs/flower-bg.svg)`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <Typography
                component="p"
                variant="title"
                size="medium"
                lineHeight="0 !important"
              >
                🥳
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography component="p" variant="title" size="large">
            🥳
          </Typography>
        )}
        <Typography
          component="p"
          variant="title"
          size="small"
          mb={3}
          mt={isPreBoosted ? 2 : 1.5}
        >
          {title}
        </Typography>
        <Box maxWidth={638} mb={3.25}>
          {isPreBoosted
            ? "Boosted applications typically receive faster responses from employers in a few days. Keep an eye out for interview requests and continue applying to jobs on Braintrust!"
            : "Most employers review applications within a few days. " +
              (user?.freelancer_approved
                ? "Boost your application and move it to the top of the list for quicker review."
                : "Keep an eye out for interview requests or messages and keep applying to jobs on Braintrust!")}
        </Box>
        <div className={styles.ctaButtons}>
          {showBoostButton && (
            <Button
              onClick={hasBoostCredit ? boostApplication : purchaseBoostCredits}
              startIcon={
                hasBoostCredit ? (
                  <BoltIcon fill="var(--white) !important" />
                ) : undefined
              }
              variant="primary"
            >
              {hasBoostCredit
                ? "Boost this application"
                : "Get Application Boost"}
            </Button>
          )}
          <Button
            variant={isPreBoosted ? "primary" : "secondary"}
            onClick={returnToJobsList}
          >
            Browse more jobs
          </Button>
          <Button variant="secondary" onClick={viewApplications}>
            View application
          </Button>
        </div>
        <Alert
          withIcon
          alignIcon={isMobile ? "center" : "top"}
          className={styles.alert}
        >
          <Typography component="p">
            Only accept job offers on Braintrust. For more information on how to
            stay safe when job searching, visit our{" "}
            <Typography
              component="link"
              href={SETTINGS.SUPPORT_URLS.REPORT_SCAMS_AND_FRAUD_ARTICLE_URL}
              target="_blank"
              RouterLink={RouterLink}
            >
              help center
            </Typography>
            .
          </Typography>
        </Alert>
      </Box>

      <Box className={styles.bottomSection}>
        {isLoading ? (
          <Loader centered />
        ) : (
          <>
            <Typography component="p" variant="label">
              👉 You’re a good match for these similar jobs
            </Typography>
            <JobLocationContext.Provider
              value={JobLocationContext.Values.recommended_jobs_modal}
            >
              <div className={styles.jobsList}>
                {similarJobs?.map((job) => (
                  <Box key={job.id} height="auto" width={350}>
                    <JobCarouselItem
                      job={job}
                      hideSkills
                      borderColor="var(--soft-beige)"
                    />
                  </Box>
                ))}
              </div>
            </JobLocationContext.Provider>
          </>
        )}
      </Box>
    </>
  );
};
