import AvatarBackground from "@static/freelancer/bg-top-talent.svg";

import {
  Avatar,
  Box,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { LocationPinSmallIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useGetExampleTalentProfiles } from "@js/apps/dashboard/hooks/get-example-talent-profiles";
import { RouterLink } from "@js/components/link";
import { useAppSelector } from "@js/hooks";
import type { TalentExampleProfile } from "@js/types/freelancer";

import styles from "./style.module.scss";

export const GetInspiredByTopTalentSection = () => {
  const freelancerRole = useAppSelector(
    (state) => state.freelancer.freelancerProfile?.role,
  );
  const { exampleTalents: topTalents, isLoading } =
    useGetExampleTalentProfiles(freelancerRole);
  const isMobile = useMediaQuery("sm");

  if (isLoading) {
    return <Loader />;
  }

  if (!topTalents) {
    return null;
  }

  return (
    <Box className={styles.getInspiredSection}>
      <Typography
        component="h3"
        variant={isMobile ? "label" : "title"}
        mb={2}
        size={isMobile ? "large" : "small"}
        fontWeight={400}
      >
        Get inspired by top talent
      </Typography>
      <Box className={styles.getInspiredSectionList}>
        {topTalents.map(({ id, freelancer }) => (
          <TopTalentItem key={id} freelancer={freelancer} />
        ))}
      </Box>
    </Box>
  );
};

type TopTalentItemProps = {
  freelancer: TalentExampleProfile;
};

const TopTalentItem = ({
  freelancer: { user, location, id },
}: TopTalentItemProps) => {
  return (
    <Box
      className={styles.topTalentItemContainer}
      component={RouterLink}
      to={`/talent/${id}`}
    >
      <div className={styles.topTalentItemAvatarContainer}>
        <AvatarBackground className={styles.topTalentItemAvatarBackground} />
        <Avatar className={styles.topTalentItemAvatar} src={user} />
      </div>
      <div className={styles.topTalentItemIntroduction}>
        <Typography component="h4" fontWeight={500}>
          {user.public_name}
        </Typography>
        <Typography component="p" size="small">
          {user.title}
        </Typography>
      </div>
      {!!location && (
        <div className={styles.topTalentItemLocation}>
          <LocationPinSmallIcon className={styles.topTalentItemLocationIcon} />
          <Typography component="span" size="small">
            {location}
          </Typography>
        </div>
      )}
    </Box>
  );
};
