import type { MouseEvent, ReactNode } from "react";

import { Box } from "@hexocean/braintrust-ui-components";

type CommentContentWrapperProps = {
  children: ReactNode;
  onHideReplies: () => void;
  isShowingReplies: boolean;
};

export const CommentContentWrapper = ({
  children,
  onHideReplies,
  isShowingReplies,
}: CommentContentWrapperProps) => {
  const isClickEnabled = isShowingReplies;
  const handleContentClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!isClickEnabled || isALink(event.target)) {
      return;
    }
    onHideReplies();
  };

  return (
    <Box
      onClick={handleContentClick}
      sx={{ cursor: isClickEnabled ? "default" : undefined }}
      role={isClickEnabled ? "button" : undefined}
      aria-label={isClickEnabled ? "Hide comment replies" : undefined}
    >
      {children}
    </Box>
  );
};

const isALink = (target: EventTarget) => {
  if (!(target instanceof Element)) {
    return false;
  }

  return target.tagName === "A";
};
