import React from "react";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { FreelancerForNodeStaff } from "@js/types/freelancer";

type NodeStaffViewProps = BoxProps & {
  profile: FreelancerForNodeStaff;
};

export const NodeStaffView: React.FC<
  React.PropsWithChildren<NodeStaffViewProps>
> = ({ profile, children, ...rest }) => {
  return (
    <Box {...rest}>
      <Typography variant="title" size="large" fontWeight={400} component="h2">
        Node staff view
      </Typography>
      <Box mt={4}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography component="h5">Email</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">{profile.email}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">Acquisition Source</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.acquisition_source || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">Billing Address</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.location_data?.reported_address || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">User Location</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.location || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">IP Address Location</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile?.location_data?.location_from_ip || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">Location Check</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.location_data?.ip_check ? "Pass" : "Fail"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">UTM source</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.utm_source || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">UTM campaign</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.utm_campaign || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">Sign Up Date</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.signed_up_at || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">Wants to Get A Job</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.joining_reasons?.includes(
                    ENUMS.JoiningReason.GET_A_JOB,
                  )
                    ? "Yes"
                    : "No"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">Refer and Earn BTRST</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.joining_reasons?.includes(
                    ENUMS.JoiningReason.REFER_AND_EARN_BTRST,
                  )
                    ? "Yes"
                    : "No"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">Wants to Hire Talent</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.joining_reasons?.includes(
                    ENUMS.JoiningReason.HIRE_TALENT,
                  )
                    ? "Yes"
                    : "No"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">Preferred work type</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.preferred_work_type || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">Can start in</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.preferred_start_time || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">Role</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.role?.name || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">Years of Experience</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.years_experience || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">English Proficiency</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.english_proficiency || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="h5">Hireflix interview link</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {profile.hireflix_interview_link ? (
                    <RouterLink
                      to={profile.hireflix_interview_link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {profile.hireflix_interview_link}
                    </RouterLink>
                  ) : (
                    "N/A"
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      {children}
    </Box>
  );
};
