import { useSearchParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { openSignUpModal } from "@js/apps/onboarding/components/sign-up-modal";
import {
  useGetReferringUserQuery,
  useGetSpaceDetailsQuery,
  useGetSpaceMembersPublicQuery,
  useGetSpaceTrendingHashtagsQuery,
} from "@js/apps/spaces/api";

export const usePublicSpacePage = ({ id }: { id: number }) => {
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get("referrer");

  const { data: spaceData, isLoading } = useGetSpaceDetailsQuery({
    id: Number(id),
  });
  const { data: trendingHashtags } = useGetSpaceTrendingHashtagsQuery({
    id: Number(id),
  });

  const { data: publicMembersData } = useGetSpaceMembersPublicQuery({ id });

  const { data: referringUser } = useGetReferringUserQuery(
    referrer ? { referrer } : skipToken,
  );

  return {
    spaceData,
    membersData: publicMembersData,
    joinCTA: openSignUpModal,
    hashtags: trendingHashtags,
    isSpaceDetailsLoading: isLoading,
    referringUser,
  };
};
