import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { CheckIcon } from "@hexocean/braintrust-ui-components/Icons";

type CardProps = {
  icon: React.ReactNode;
  iconBgColor?: string;
  children: React.ReactNode;
  onClick?: () => void;
  missingItems: number;
};

export const Card = ({
  icon,
  iconBgColor,
  children,
  onClick,
  missingItems,
}: CardProps) => {
  const isMissing = missingItems > 0;

  return (
    <Box className="profile-completion-nudge-section__card-item">
      <Box
        className="profile-completion-nudge-section__card-icon"
        bgcolor={iconBgColor}
      >
        {icon}
      </Box>
      <Typography
        component="p"
        variant="label"
        size="medium"
        flex={1}
        whiteSpace="nowrap"
      >
        {children}
      </Typography>
      <Box>
        {isMissing ? (
          <Button
            className="profile-completion-nudge-section__card-button"
            variant={"primary"}
            onClick={onClick}
          >
            Add
          </Button>
        ) : (
          <Button
            className="profile-completion-nudge-section__card-button"
            variant="positive"
            onClick={undefined}
          >
            Added <CheckIcon sx={{ fontSize: "16px" }} />
          </Button>
        )}
      </Box>
    </Box>
  );
};
