import { Stack, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";

import type { UniversalTabValue } from "../constants";
import { UNIVERSAL_SEARCH_TABS } from "../constants";
import { getResultsCountWithLabel } from "../helpers";
import type { UniversalSearchCurrentData } from "../hooks/use-universal-search-form";
import { useDebouncedUniversalSearchPhrase } from "../universal-search-slice";

import { PopularBadge } from "./popular-badge";
import { UniversalSearchResultsLink } from "./search-results-link";

type UniversalSearchResultsHeaderProps = {
  currentTab: UniversalTabValue;
  results: UniversalSearchCurrentData | undefined;
};

export const UniversalSearchResultsHeader = ({
  currentTab,
  results,
}: UniversalSearchResultsHeaderProps): JSX.Element | null => {
  const searchPhrase = useDebouncedUniversalSearchPhrase();
  const isMobile = useMediaQuery("sm");

  if (!currentTab) return null;

  const largeResultsCount =
    (currentTab === "PEOPLE" &&
      results?.talentResults?.total_count &&
      results?.talentResults?.total_count > 10000) ||
    (currentTab === "CAREER_HELP" &&
      results?.postResults?.total_count &&
      results?.postResults?.total_count > 10000);

  const stackItemsForMobileView = isMobile && largeResultsCount;

  return (
    <Stack
      direction={stackItemsForMobileView ? "column" : "row"}
      sx={{
        justifyContent: "space-between",
        alignItems: stackItemsForMobileView ? "unset" : "center",
        whiteSpace: "nowrap",
        flexWrap: "wrap",
        gap: 1,
      }}
    >
      <ResultsHeaderInfo
        currentTab={currentTab}
        searchPhrase={searchPhrase}
        results={results}
      />
      <UniversalSearchResultsLink
        searchPhrase={searchPhrase}
        searchTab={currentTab}
      />
    </Stack>
  );
};

type ResultsHeaderInfoProps = {
  searchPhrase: string;
  currentTab: UniversalTabValue;
  results: UniversalSearchCurrentData | undefined;
};
const ResultsHeaderInfo = ({
  searchPhrase,
  currentTab,
  results,
}: ResultsHeaderInfoProps) => {
  const isMobile = useMediaQuery(600);
  if (!searchPhrase && currentTab === UNIVERSAL_SEARCH_TABS.PEOPLE) {
    return (
      <Typography
        component="span"
        variant="paragraph"
        size="small"
        fontWeight={500}
      >
        Search results
      </Typography>
    );
  }

  if (!searchPhrase) {
    return (
      <>
        <PopularBadge />
        {!isMobile && (
          <Typography
            component="span"
            variant="paragraph"
            size="small"
            fontWeight={500}
            flexGrow={1}
          >
            People are searching for...
          </Typography>
        )}
      </>
    );
  }

  return (
    <Typography
      size="small"
      component="span"
      variant="paragraph"
      fontWeight={500}
    >
      {getResultsCountWithLabel(currentTab, results)}
    </Typography>
  );
};
