import type { TypedWrappedFieldProps } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";

type InfoTagProps = {
  is_ai_generated: TypedWrappedFieldProps<boolean>;
  message: string;
};

export const InfoTag = ({ is_ai_generated, message }: InfoTagProps) => {
  if (!is_ai_generated.input.value) {
    return null;
  }

  return (
    <Box
      mb={2}
      sx={{
        backgroundColor: "#f5f8ff",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "row",
        gap: "12px",
        padding: "12px",
        alignItems: "center",
        width: "fit-content",
      }}
    >
      <InfoIcon
        style={{
          fontSize: "24px !important",
          color: "var(--info-2)",
        }}
      />
      <Typography size="small" color="primary" component="p" fontWeight={400}>
        {message}
      </Typography>
    </Box>
  );
};
