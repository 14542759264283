import React from "react";

import { Button } from "@hexocean/braintrust-ui-components";

export const DisabledProposalButtonWithTooltip = () => {
  return (
    <Button
      style={{ padding: "6.5px 27px", width: "100%" }}
      variant="positive"
      children="Apply"
      shape="squared"
      disabled
      disabledType="opacity"
    />
  );
};
