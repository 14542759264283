import { useEffect, useRef } from "react";
import type {
  TypedWrappedFieldProps,
  Validator,
  WrappedFieldArrayProps,
} from "redux-form";
import { Field } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";

export type CustomTextFieldProps = {
  id?: string;
  fields: WrappedFieldArrayProps["fields"];
  disabled?: boolean;
  isFirstRender: boolean;
  item: string;
  index: number;
} & TypedWrappedFieldProps<string>;

export type TextFieldArrayProps = {
  CustomTextField: (props: CustomTextFieldProps) => JSX.Element;
  CustomPlaceholder: (props: { onClick: () => void }) => JSX.Element;
  maxQuestionsNumber?: number;
  maxQuestionsLabel?: string;
  fields: WrappedFieldArrayProps["fields"];
  optionsGap?: number;
  // In option item it will be { optionItemContentKey: 'content' }
  optionItemContentKey?: string;
  disabled?: boolean;
  singleFieldValidator?: Validator[];
};

export const TextFieldArray = ({
  CustomTextField,
  CustomPlaceholder,
  maxQuestionsNumber = 2,
  maxQuestionsLabel,
  fields,
  optionsGap = 1,
  optionItemContentKey = "question",
  disabled,
  singleFieldValidator,
}: TextFieldArrayProps) => {
  const ref = useRef(true);
  useEffect(() => {
    ref.current = false;
  }, []);
  // required to prevent focus on input while initializing the form
  const isFirstRender = ref.current;

  const shouldDisplayAddQuestionButton = () => {
    const questions = fields.getAll();
    const allQuestionsAdded = questions?.length >= maxQuestionsNumber;

    if (!questions?.length) {
      return true;
    }

    const lastQuestion = questions[questions?.length - 1];
    if (lastQuestion[optionItemContentKey]) {
      return !allQuestionsAdded;
    }

    return false;
  };

  if (!fields) return null;

  return (
    <Box
      id="application-questions"
      component="ul"
      className="list-reset"
      display="flex"
      flexDirection="column"
      gap={optionsGap}
    >
      {fields.map((item, index) => {
        return (
          <li key={item}>
            <Field
              fields={fields}
              index={index}
              item={item}
              id={`your-${optionItemContentKey}-${index}`}
              name={`${item}.${optionItemContentKey}`}
              component={CustomTextField}
              isFirstRender={isFirstRender}
              disabled={disabled}
              validate={singleFieldValidator}
            />
          </li>
        );
      })}
      {shouldDisplayAddQuestionButton() && (
        <CustomPlaceholder
          onClick={() => fields.push({ can_be_changed: true })}
        />
      )}
      {!!maxQuestionsLabel && (
        <Typography mt={1} component="p" variant="paragraph" size="small">
          {maxQuestionsLabel}
        </Typography>
      )}
    </Box>
  );
};
