import type { TrackableUserAction } from "@js/middlewares/analytics/types";
import { assertUnreachable } from "@js/utils";

const isExperimentExposureAction = (action: {
  type: string;
  payload?: unknown;
}): action is ExposureAction => {
  if ((EXPOSURE_EVENTS as ReadonlyArray<string>).includes(action.type)) {
    return true;
  }

  return false;
};

/* all exposure events needs to be here, otherwise action won't be catch in middleware exmaple array:
[ ENUMS.TrackingEvents.TALENT_HOME_REFER_BUTTON_VIEWED,"example_next_event",] */
const EXPOSURE_EVENTS = [] as const;

export type ExposureAction = {
  // typeof ENUMS.TrackingEvents.TALENT_HOME_REFER_BUTTON_VIEWED;
  type: any;
  // IExperiment<typeof SETTINGS.EXPERIMENT_ENUMS.Experiments.ReferTalentButton>
  payload: any;
};

export const handleExperimentExposureActions = (
  action: TrackableUserAction,
) => {
  if (isExperimentExposureAction(action)) {
    const type = action.type;

    switch (type) {
      case "example_next_event": {
        // to remove when some other event will be added
        break;
      }
      default: {
        //@ts-ignore: Fix types
        assertUnreachable(type);
      }
    }
  }
};

export const experimentExposure = (action: ExposureAction) => {
  return action;
};
