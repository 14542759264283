import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { CommonConfirmationModal } from "@js/components/modal";

type DeleteResumeModalContentProps = {
  onConfirm: () => void;
};

export const openDeleteResumeModal = ({
  onConfirm,
}: DeleteResumeModalContentProps) =>
  CommonConfirmationModal.open({
    closeButton: true,
    children: <DeleteResumeModalContent onConfirm={onConfirm} />,
    className: "delete-resume-modal",
  });

const DeleteResumeModalContent = ({
  onConfirm,
}: DeleteResumeModalContentProps) => {
  const handleConfirm = () => {
    onConfirm?.();
    CommonConfirmationModal.close();
  };

  return (
    <Box pt={5}>
      <Typography
        fontWeight={400}
        component="p"
        variant="title"
        size="small"
        mb={4}
      >
        Are you sure you want to delete your resume?
      </Typography>
      <Box className="delete-resume-modal__buttons" gap={2}>
        <Button
          variant="black-outlined"
          shape="squared"
          onClick={CommonConfirmationModal.close}
        >
          Cancel
        </Button>
        <Button variant="destructive" shape="squared" onClick={handleConfirm}>
          Yes, delete my resume
        </Button>
      </Box>
    </Box>
  );
};
