import type { TypedWrappedFieldProps } from "redux-form";

import { Box } from "@hexocean/braintrust-ui-components";

import { useCreateJobSkillsLogic } from "../hooks/use-skills-logic";
import { PopularSkillsField } from "../popular-skills-field";
import type { JobSkillOption, SimpleSkill } from "../types";

import { CreateJobSelectedSkillsList } from "./create-job-selected-skill-list";
import { CreateJobSkillsCombobox } from "./create-job-skills-combobox";

const mapCreateJobPopularSkillToOption = (
  skill: SimpleSkill,
): JobSkillOption => ({
  value: skill.id,
  label: skill.name,
});

type SkillsFieldProps = {
  is_ai_generated: TypedWrappedFieldProps<boolean>;
  role: TypedWrappedFieldProps<number>;
  new_skills: TypedWrappedFieldProps<number[]>;
  top_skills: TypedWrappedFieldProps<number[]>;
};

export const CreateJobSkillsField = ({
  is_ai_generated,
  role,
  new_skills,
  top_skills,
}: SkillsFieldProps) => {
  const { displayError, newMeta, error, popularSkillsOptions } =
    useCreateJobSkillsLogic({
      newSkillsFormField: new_skills,
      topSkillsFormField: top_skills,
      roleId: role.input.value,
      mapSkillToOption: mapCreateJobPopularSkillToOption,
    });

  return (
    <>
      <Box mb={2}>
        <CreateJobSkillsCombobox input={new_skills.input} meta={newMeta} />
      </Box>
      {Boolean(popularSkillsOptions.length) && !is_ai_generated.input.value && (
        <PopularSkillsField
          input={new_skills.input}
          meta={newMeta}
          options={popularSkillsOptions}
          selectedRole={role.input.value}
        />
      )}
      <CreateJobSelectedSkillsList
        input={new_skills.input}
        meta={newMeta}
        error={displayError ? error : undefined}
        topSkillsInput={top_skills.input}
      />
    </>
  );
};
