import { assertUnreachable, formatBudget } from "@js/utils";

export const getFieldDescription = (
  paymentType: EnumType<typeof ENUMS.JobPaymentType>,
  budgetMin: string,
  budgetMax: string,
) => {
  const budget = formatBudget(budgetMin, budgetMax, {
    paymentType,
    removeDecimal: true,
    hourlyRateSuffix: "/hr",
  });
  switch (paymentType) {
    case ENUMS.JobPaymentType.HOURLY: {
      return `The client has input a budget of ${budget}`;
    }
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      return `The client has input a budget of ${budget}`;
    }
    case ENUMS.JobPaymentType.ANNUAL: {
      return `The client has input a budget of ${budget}`;
    }
    default: {
      assertUnreachable(paymentType);
      return "";
    }
  }
};

export const getTitle = (
  paymentType: EnumType<typeof ENUMS.JobPaymentType>,
  jobType: EnumType<typeof ENUMS.JobType>,
) => {
  if (jobType === ENUMS.JobType.DIRECT_HIRE)
    return `Are you okay with the posted pay rate?`;

  switch (paymentType) {
    case ENUMS.JobPaymentType.HOURLY: {
      return `What is your hourly rate for this job?`;
    }
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      return `Are you okay with the posted pay rate?`;
    }
    case ENUMS.JobPaymentType.ANNUAL: {
      return `Are you okay with the posted pay rate?`;
    }
    default: {
      assertUnreachable(paymentType);
      return "";
    }
  }
};
