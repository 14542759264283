import * as React from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import { RhfTextField } from "../fields";

/**
 * Dumb component to test field with the form. Adjust it to your needs, but don't push changes until it is some fix/improvement.
 * Rendered at: /rhf/field
 **/

const formSchema = z.object({
  ["field-name"]: z
    .string()
    .min(1, { message: "Required" })
    .max(3, { message: "Too long" }),
});

type FormValues = z.infer<typeof formSchema>;

export const SingleFieldForm = () => {
  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: {
      ["field-name"]: "",
    },
    mode: "onTouched",
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (data: FormValues) => {
    // eslint-disable-next-line no-console
    console.log("onSubmit: ", data);
    reset();
  };

  return (
    <div>
      <ul>
        Notes:
        <li>make sure field is reset properly</li>
        <li>make sure field displays error</li>
      </ul>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RhfTextField
          id="field-id"
          label="Field label"
          control={control}
          name="field-name"
        />

        <DisplayFieldState control={control} name="field-name" />

        <input type="submit" />
        <input type="reset" onClick={() => reset()} />
      </form>
    </div>
  );
};

type DisplayFieldStateProps<T extends FieldValues> = UseControllerProps<T>;

const DisplayFieldState = <T extends FieldValues>(
  props: DisplayFieldStateProps<T>,
) => {
  const { fieldState } = useController(props);

  return (
    <div>
      <p>{fieldState.isTouched && "Touched"}</p>
      <p>{fieldState.isDirty && "Dirty"}</p>
      <p>{fieldState.invalid ? "invalid" : "valid"}</p>
    </div>
  );
};
