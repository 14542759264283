import { createFormInstance } from "@js/forms/components";

export const AVAILABILITY_MODAL_FORM_ID = "availability-modal-form";

export type AvailabilityFormValue = {
  availability_for_work: boolean;
  availability_for_work_options: string[];
  working_hours_tz_abbr: string;
  working_hours_start: number;
  working_hours_end: number;
};
const AvailabilityFormInstance = createFormInstance<
  AvailabilityFormValue,
  unknown
>(AVAILABILITY_MODAL_FORM_ID);

export { AvailabilityFormInstance };
