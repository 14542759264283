import { useUser } from "@js/apps/common/hooks";
import type { IPost, PostComment } from "@js/types/give-and-get-help";

export type FeedItem = PostComment | IPost;

export function useIsCreator(feedItem: FeedItem) {
  const creatorId = feedItem.freelancer.user.id;
  const user = useUser();
  return user?.id === creatorId;
}
