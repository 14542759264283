import { useCallback } from "react";
import type { FileRejection } from "react-dropzone";
import { useDropzone } from "react-dropzone";

import { getErrorMessage } from "@js/apps/file-upload/components/file-rejections";
import { CREATE_JOB_FORM_ID } from "@js/apps/jobs/apps/create-job/constants";
import { useJobDescriptionUpdate } from "@js/apps/jobs/apps/create-job/hooks/use-job-description-update";

export const useUploadJobDescriptionModule = () => {
  const {
    uploadJobDescriptionFile,
    resetUploadedFileState,
    setUploadDescriptionError,
    isFetchingJobDataFromUploadedFile,
    updateJobFormWithFileDataError,
    updateJobFormWithFileDataFinished,
    uploadingFileName,
    uploadError,
    isUploading,
  } = useJobDescriptionUpdate({ formId: CREATE_JOB_FORM_ID });

  const onDrop = useCallback(
    (files: File[]) => {
      resetUploadedFileState();

      const [file] = files;
      if (!file) {
        return;
      }

      uploadJobDescriptionFile(file);
    },
    [uploadJobDescriptionFile, resetUploadedFileState],
  );

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      const error = fileRejections[0].errors[0];
      const errorMessage = getErrorMessage(error);

      setUploadDescriptionError(errorMessage);
    },
    [setUploadDescriptionError],
  );

  const { getInputProps, open } = useDropzone({
    multiple: false,
    minSize: 1,
    maxSize: SETTINGS.DEFAULT_MAX_SIZE_FILE,
    onDrop,
    onDropRejected,
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
  });

  const onFileUpload = () => {
    open();
  };

  return {
    getInputProps,
    onFileUpload,
    onDrop,
    onDropRejected,
    isFetchingJobDataFromUploadedFile,
    updateJobFormWithFileDataError,
    updateJobFormWithFileDataFinished,
    uploadingFileName,
    uploadError,
    isUploading,
  };
};
