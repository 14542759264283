import React from "react";
import classNames from "classnames";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import { Box } from "@hexocean/braintrust-ui-components";
import type { EmployerPublicProfile } from "@js/types/employer";
import type { FreelancerPublic } from "@js/types/freelancer";

import { ReviewsSummary } from "../reviews-summary";

type ReviewsSummaryPanelProps = BoxProps & {
  profile: EmployerPublicProfile | FreelancerPublic;
  subjectIsEmployer: boolean;
  openReviewModal: () => void;
  canOpenReviewModal: boolean;
};

export const ReviewsSummaryPanel = ({
  profile,
  openReviewModal,
  subjectIsEmployer,
  canOpenReviewModal,
  className,
  ...props
}: ReviewsSummaryPanelProps) => {
  const averageWorkQuality = Number(profile.average_work_quality);
  const averageResponsiveness = Number(profile.average_responsiveness);
  return (
    <Box
      {...props}
      className={classNames(className, { pointer: canOpenReviewModal })}
      onClick={openReviewModal}
    >
      {!!averageWorkQuality && (
        <ReviewsSummary
          key="average_work_quality"
          rate={averageWorkQuality.toFixed(1)}
          count={profile.review_count}
          label={
            subjectIsEmployer ? "Clear expectations and scope" : "Work Quality"
          }
        />
      )}
      {!!averageResponsiveness && (
        <ReviewsSummary
          key="average_responsiveness"
          rate={averageResponsiveness.toFixed(1)}
          count={profile.review_count}
          label={subjectIsEmployer ? "Communication" : "Responsiveness"}
        />
      )}
    </Box>
  );
};
