import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Loader, Stack } from "@hexocean/braintrust-ui-components";
import { useSpacesList } from "@js/apps/spaces/hooks/use-spaces-list";

import { EndSpacesMessage, SpacesLoadingState } from "../scroll-helpers";
import { SpaceCard } from "../space-card";

export const SpaceList = () => {
  const { spaces, isLoading, fetchMore, hasMore } = useSpacesList();

  if (isLoading || !spaces) {
    return <Loader />;
  }

  return (
    <InfiniteScroll
      dataLength={spaces.length}
      next={fetchMore}
      hasMore={hasMore}
      scrollThreshold={0.6}
      loader={<SpacesLoadingState />}
      endMessage={<EndSpacesMessage />}
    >
      <Stack direction="column" spacing={2} width="100%">
        {spaces.map((space) => (
          <SpaceCard key={space.id} {...space} />
        ))}
      </Stack>
    </InfiniteScroll>
  );
};
