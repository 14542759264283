import { Avatar, Typography } from "@hexocean/braintrust-ui-components";
import type { User } from "@js/types/auth";

type UserTestimonialSlideProps = {
  comment: string;
  user: Pick<
    User,
    | "first_name"
    | "public_name"
    | "avatar"
    | "has_avatar_set"
    | "avatar_thumbnail"
    | "gravatar"
    | "title"
  >;
};

export const UserTestimonialSlide = ({
  comment,
  user,
}: UserTestimonialSlideProps) => {
  return (
    <div className="comment-slide">
      <Typography
        component="span"
        variant="title"
        size="small"
        fontWeight={400}
      >
        {comment}
      </Typography>
      <div className="comment-slide__user">
        <Avatar
          sx={{ width: 46, height: 46 }}
          alt={user.public_name}
          src={user}
        />
        <div className="comment-slide__user__name">
          <Typography component="span" variant="label" size="large">
            {user.public_name}
          </Typography>
          <Typography component="span" variant="paragraph" size="medium">
            {user.title}
          </Typography>
        </div>
      </div>
    </div>
  );
};
