import { useLocation } from "react-router-dom";

import { CardCarouselSection } from "@js/apps/freelancer/components/home-carousel";
import { JobForYouCarouselItem } from "@js/apps/freelancer/components/home-carousel/job-carousel-item";
import { getUTMQueryParamsSearch } from "@js/utils/url";

import { useGetSimilarJobsQuery } from "../api";

type PublicSimilarJobsSectionProps = {
  jobId: number;
};

export const PublicSimilarJobsSection = ({
  jobId,
}: PublicSimilarJobsSectionProps) => {
  const { search } = useLocation();
  const { isLoading, data } = useGetSimilarJobsQuery({
    jobId,
    pageSize: 12,
  });

  const similarJobs = data?.results;

  return (
    <CardCarouselSection
      url={`/jobs?${getUTMQueryParamsSearch(search)}`}
      title="Similar jobs"
      loading={isLoading}
      items={similarJobs}
      renderItem={(item) => <JobForYouCarouselItem job={item} />}
      carouselHeaderProps={{ mb: "50px" }}
      extendedWidth
    />
  );
};
