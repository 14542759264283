import { useSearchParams } from "react-router-dom";

import { HASHTAG_QUERY_PARAM, POST_HASHTAG_QUERY_PARAM } from "../../constants";

export const useSelectedHashtag = () => {
  const [searchParams] = useSearchParams();

  const hashtagParam = searchParams.get(HASHTAG_QUERY_PARAM);
  const selectedHashtagId = parseQueryParamToNumber(hashtagParam);

  const postHashtagParam = searchParams.get(POST_HASHTAG_QUERY_PARAM);
  const selectedPostHashtagId = parseQueryParamToNumber(postHashtagParam);

  return { selectedHashtagId, selectedPostHashtagId };
};

const parseQueryParamToNumber = (param: string | null) =>
  param && !isNaN(+param) ? Number(param) : undefined;
