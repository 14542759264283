import type { OfferStatusUpdatedEventData } from "@js/apps/dashboard/types";
import type {
  AddedEventData,
  CommentsEventPayload,
  DeletedPostCommentData,
  GetCommentsReturnPayload,
  UpdatedEventData,
} from "@js/apps/give-and-get-help/types";
import type { User } from "@js/types/auth";
import type {
  PostComment,
  ReactionAddedEventData,
  ReactionDeletedEventData,
  StickerValue,
} from "@js/types/give-and-get-help";
import { findEntity, findEntityIndex } from "@js/utils";

import {
  addReaction,
  deleteReaction,
  getAddReactionPayload,
  getDeleteReactionPayload,
} from "../../common";

export const addComment = (
  eventData: Optional<
    AddedEventData<PostComment>,
    "is_main_post" | "broadcast_type"
  >,
  draft: GetCommentsReturnPayload,
  postId: number,
) => {
  const isCommentingCurrentPost = eventData.data.main_post_id === postId;

  if (!isCommentingCurrentPost) {
    return;
  }

  const isL1Comment = eventData.data.main_post_id === eventData.data.parent_id;

  if (isL1Comment) {
    if (findEntity(draft.comments, eventData.data.id)) return;

    draft.comments.push({ ...eventData.data, comments: [] });
    return;
  }

  const parentComment = findEntity(draft.comments, eventData.data.parent_id);
  if (!parentComment) {
    return;
  }

  const addedCommentExitst = !!findEntity(
    parentComment.comments,
    eventData.data.id,
  );
  if (addedCommentExitst) {
    return;
  }

  const currentCommentRepliesCount = parentComment.comments_count ?? 0;
  parentComment.comments_count = currentCommentRepliesCount + 1;
  parentComment.comments ??= [];
  parentComment.comments.push({
    ...eventData.data,
    comments: null,
  });
};

export const addReplyToComment = ({
  addedReply,
  draft,
}: {
  addedReply: PostComment;
  draft: GetCommentsReturnPayload;
}) => {
  const commentExists = findEntity(draft.comments, addedReply.id);
  if (commentExists) {
    return;
  }

  draft.comments.push({
    ...addedReply,
    comments: null,
  });
};

export const patchCommentsOnCommentDeleted = (
  data: DeletedPostCommentData,
  draft: GetCommentsReturnPayload,
) => {
  const firstLevelCommentIndex = findEntityIndex(draft.comments, data.id);

  // if comment is first level
  if (firstLevelCommentIndex !== -1) {
    draft.comments.splice(firstLevelCommentIndex, 1);
    return;
  }

  // if comment is second level
  const nestedCommentsIndexes = findNestedCommentIndex(draft, data.id);

  if (!nestedCommentsIndexes) {
    return;
  }

  const { firstLevelCommentIndex: firstLevelIndex, secondLevelCommentIndex } =
    nestedCommentsIndexes;

  const deletedFromComment = draft.comments[firstLevelIndex];
  const { comments: secondLevelComments } = deletedFromComment;

  if (secondLevelComments) {
    secondLevelComments.splice(secondLevelCommentIndex, 1);
  }

  const currentCommentRepliesCount = deletedFromComment.comments_count ?? 0;
  deletedFromComment.comments_count = currentCommentRepliesCount
    ? currentCommentRepliesCount - 1
    : 0;
};

export const updateComment = (
  eventData: Optional<
    UpdatedEventData<PostComment>,
    "is_main_post" | "broadcast_type"
  >,
  draft: GetCommentsReturnPayload,
  postId: number,
) => {
  if (eventData.data.main_post_id !== postId) {
    return;
  }

  const flattened = flattenComments(draft.comments);
  const commentToUpdate = findEntity(flattened, eventData.data.id);

  if (!commentToUpdate) {
    return;
  }

  Object.assign(commentToUpdate, eventData.data);
};

export const updateCommentHelpOfferStatus = (
  eventData: Optional<OfferStatusUpdatedEventData, "broadcast_type">,
  draft: GetCommentsReturnPayload,
) => {
  const { post_id, status } = eventData.data;

  if (!post_id) return;

  const flattened = flattenComments(draft.comments);

  const searchedComment = findEntity(flattened, post_id);

  if (!searchedComment || !searchedComment.help_offer) return;

  searchedComment.help_offer.status = status;
};

export const addCommentReaction = (
  eventData: Optional<
    ReactionAddedEventData,
    "is_main_post" | "broadcast_type" | "type"
  >,
  draft: GetCommentsReturnPayload,
) => {
  const flattened = flattenComments(draft.comments);

  const commentWithReactionAdded = findEntity(
    flattened,
    eventData.data.post_id,
  );

  if (commentWithReactionAdded) {
    commentWithReactionAdded.reactions = addReaction(
      commentWithReactionAdded.reactions,
      eventData.data,
    );
  }
};

export const deleteCommentReaction = (
  eventData: Optional<
    ReactionDeletedEventData,
    "is_main_post" | "broadcast_type" | "type"
  >,
  draft: GetCommentsReturnPayload,
) => {
  const flattened = flattenComments(draft.comments);

  const commentWithReactionDeleted = findEntity(
    flattened,
    eventData.data.post_id,
  );

  if (commentWithReactionDeleted) {
    commentWithReactionDeleted.reactions = deleteReaction(
      commentWithReactionDeleted.reactions,
      eventData.data,
    );
  }
};

export const isCommentRelatedEvent = (
  event: MessageEvent,
): event is MessageEvent<CommentsEventPayload> => {
  return event.data.is_main_post === false;
};

type FindCommentIndexesValue =
  | {
      firstLevelCommentIndex: number;
      secondLevelCommentIndex: number;
    }
  | undefined;

export const findNestedCommentIndex = (
  draft: GetCommentsReturnPayload,
  id: number,
): FindCommentIndexesValue => {
  let commentsIndexes: FindCommentIndexesValue;

  draft.comments.forEach((comment, index) => {
    if (comment.comments) {
      const secondLevelCommentIndex = findEntityIndex(comment.comments, id);

      if (
        secondLevelCommentIndex !== -1 &&
        secondLevelCommentIndex !== undefined
      ) {
        commentsIndexes = {
          firstLevelCommentIndex: index,
          secondLevelCommentIndex,
        };
      }
    }
  });

  return commentsIndexes;
};

export const toggleUserCommentReaction = ({
  user: currentUser,
  draft,
  postId,
  sticker,
}: {
  user: User;
  draft: GetCommentsReturnPayload;
  postId: number;
  sticker: StickerValue;
}) => {
  if (!draft) return;
  const flattened = flattenComments(draft.comments);
  // postId is actually a commentId here (postId == postOrCommentId)
  const currentComment = flattened.find((comment) => comment.id === postId);
  if (!currentComment) {
    return;
  }

  const currentReaction = currentComment.reactions.find(
    (reaction) => reaction.sticker === sticker,
  );
  const hasCurrentUserReacted = !!currentReaction?.users.some(
    (user) => user.id === currentUser.id,
  );
  if (!currentReaction || !hasCurrentUserReacted) {
    currentComment.reactions = addReaction(
      currentComment.reactions,
      getAddReactionPayload({ sticker, postId, currentUser }),
    );

    return;
  }

  currentComment.reactions = deleteReaction(
    currentComment.reactions,
    getDeleteReactionPayload({ currentReaction, currentUser, postId, sticker }),
  );
};

const flattenComments = (comments: PostComment[]): PostComment[] => {
  return comments.flatMap((comment) => [comment, ...(comment.comments ?? [])]);
};
