export const RATE_FIELDS_KEYS = {
  hourly_rate: "hourly_rate",
  payment_type: "payment_type",
  fixed_rate: "fixed_rate",
  min_rate: "min_rate",
  max_rate: "max_rate",
  min_annual_rate: "min_annual_rate",
  max_annual_rate: "max_annual_rate",
  job_type: "job_type",
  ai_min_rate: "ai_min_rate",
  ai_max_rate: "ai_max_rate",
  custom_min_rate: "custom_min_rate",
  custom_max_rate: "custom_max_rate",
} as const;
