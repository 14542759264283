import React from "react";

import styles from "./how-to-post-video.module.scss";

export const HowToPostVideo = () => {
  return (
    <video
      width="452"
      height="255"
      controls
      autoPlay
      muted
      className={styles.video}
      playsInline
    >
      <source
        src={`${SETTINGS.STATIC_URL}give-get-help/how-to-post-on-ggh.mp4`}
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  );
};
