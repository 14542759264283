import { Button } from "@hexocean/braintrust-ui-components";
import { AddCircleOutlineIcon } from "@hexocean/braintrust-ui-components/Icons";

import { openSpaceInviteContactsModal } from "../invite-to-space-modal";

export const PeopleInviteButton = ({ spaceId }: { spaceId: number }) => {
  return (
    <Button
      variant="black-outlined"
      size="medium"
      startIcon={<AddCircleOutlineIcon />}
      onClick={() => openSpaceInviteContactsModal({ spaceId })}
      sx={{ flexGrow: { xs: 1, sm: 0 } }}
    >
      Invite
    </Button>
  );
};
