import { API } from "@js/api";

type UploadedJobDescriptionFileId = number;

type GetUploadedJobDescriptionReturnType = {
  id: UploadedJobDescriptionFileId;
  result: UploadedJobDescriptionData;
};

export type UploadedJobDescriptionData = {
  title: string | null;
  description: string | null;
};

export const createJobApi = API.injectEndpoints({
  endpoints: (build) => ({
    getUploadedJobDescriptionData: build.query<
      GetUploadedJobDescriptionReturnType,
      UploadedJobDescriptionFileId
    >({
      query: (fileId) => ({
        url: `/job_description_files/${fileId}/`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetUploadedJobDescriptionDataQuery } = createJobApi;
