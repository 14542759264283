import React from "react";

import {
  Button,
  Divider,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { Modal } from "@js/components/modal";

const OTP_AUTH_BACKUP_CODES_MODAL_ID = "otp-auth-backup-codes-modal";

export const OTPAuthBackupCodesModalInstance = Modal(
  OTP_AUTH_BACKUP_CODES_MODAL_ID,
  {
    className: "otp-auth-backup-codes-modal",
    closeButton: false,
    keepOnBackdropClick: true,
  },
);

type OTPAuthBackupCodesModalProps = {
  backupCodes: string[];
  onClickContinueAction?: () => void;
};

export const OTPAuthBackupCodes = ({
  backupCodes,
  onClickContinueAction,
}: OTPAuthBackupCodesModalProps) => {
  return (
    <div className="otp-auth-backup-codes-modal">
      <h3 className="otp-auth-backup-codes-modal__header">
        2-Step Authentication Backup Codes
      </h3>
      <Typography component="p" className="otp-auth-backup-codes-modal__text">
        If you lose your phone or access to your one time password secret, each
        of these backup codes can be used one time to regain access to your
        account. Please save them in a safe place, or you may lose access to
        your account.
      </Typography>
      <div className="otp-auth-backup-codes-modal__wrapper">
        <ul className="otp-auth-backup-codes-modal__list">
          {backupCodes.map((code) => (
            <li key={code}>
              <Typography variant="paragraph" component="p" fontWeight={500}>
                {code}
              </Typography>
            </li>
          ))}
        </ul>
        <Button
          variant="primary"
          shape="squared"
          to={`data:application/txt,${backupCodes.join("%0A")}`}
          download="braintrust_2fa_backup_codes.txt"
          RouterLink={RouterLink}
        >
          Download Codes
        </Button>
      </div>
      <Divider color="secondary" />
      <div className="otp-auth-backup-codes-modal__buttons">
        <Button
          shape="squared"
          variant="positive-2"
          onClick={
            onClickContinueAction || OTPAuthBackupCodesModalInstance.close
          }
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export const openAuthBackupCodesModal = (
  backupCodes: string[],
  config?: Omit<OTPAuthBackupCodesModalProps, "backupCodes">,
) => {
  OTPAuthBackupCodesModalInstance.open({
    children: <OTPAuthBackupCodes backupCodes={backupCodes} {...config} />,
  });
};
