import {
  BriefcaseIcon,
  EarnIcon,
  JobsIcon,
  StarSVGIcon,
  WelcomeIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import type { DashboardNavItem } from "../types";

type GetEmployerNavItemsProps = {
  canViewInvoices: boolean;
};

const StyledStarIcon = () => {
  return <StarSVGIcon sx={{ path: { strokeWidth: 2 } }} />;
};

export const EMPLOYER_NAV_ITEMS = {
  HOME: {
    label: "Home",
    path: "/employer/dashboard/welcome/",
    icon: WelcomeIcon,
  },
  MY_JOBS: {
    path: "/employer/dashboard/my_jobs/",
    label: "My Jobs",
    icon: JobsIcon,
  },
  MY_TALENT: {
    path: "/employer/dashboard/my_talent/",
    label: "My Talent",
    icon: StyledStarIcon,
  },
  BROWSE_TALENT: {
    path: "/talent/",
    label: "Browse Talent",
    icon: BriefcaseIcon,
  },
  INVOICES: {
    path: "/employer/invoices/",
    label: "Invoices",
    icon: EarnIcon,
  },
};

export const getEmployerNavItems = ({
  canViewInvoices,
}: GetEmployerNavItemsProps): DashboardNavItem[] =>
  [
    EMPLOYER_NAV_ITEMS.HOME,
    EMPLOYER_NAV_ITEMS.MY_JOBS,
    EMPLOYER_NAV_ITEMS.MY_TALENT,
    EMPLOYER_NAV_ITEMS.BROWSE_TALENT,
    canViewInvoices && EMPLOYER_NAV_ITEMS.INVOICES,
  ].filter(Boolean) as [];
