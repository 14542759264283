import {
  Box,
  IconButton,
  Typography,
  VerticalSeparator,
} from "@hexocean/braintrust-ui-components";
import {
  BriefcaseIcon,
  CertificateIcon,
  CloseIcon,
  EditPenIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { FreelancerCertificate } from "@js/types/freelancer";
import { shortenValue } from "@js/utils";

type CertificatesCardProps = {
  certificate: FreelancerCertificate;
  editMode?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
};

export const CertificatesCard = ({
  certificate,
  editMode = false,
  onEdit,
  onDelete,
}: CertificatesCardProps) => {
  const certificateName = shortenValue(certificate.certificate.name, 65);
  const certificateIssuer = certificate?.issuer
    ? shortenValue(certificate.issuer, 65)
    : undefined;
  return (
    <>
      {editMode ? (
        <Box className="certificates-card" data-testid="certificates-card">
          <Box className="certificates-card__icon-container">
            <CertificateIcon />
          </Box>
          <Box className="certificates-card__content">
            <Typography
              component="h3"
              variant="paragraph"
              size="large"
              maxWidth={{
                xs: "155px",
                sm: "revert",
              }}
              ellipsis
              fontWeight={400}
            >
              {certificateName}
            </Typography>
            <Box className="certificates-card__name-date">
              {certificateIssuer && (
                <Typography component="p" variant="label" ellipsis>
                  {certificateIssuer}
                </Typography>
              )}
              <VerticalSeparator className="show-desktop" />
              <Typography
                component="div"
                size="small"
                display="flex"
                gap="7px"
                alignItems="center"
                flexShrink={0}
              >
                <BriefcaseIcon style={{ width: "14px", height: "14px" }} />
                {certificate.year_of_issuing && (
                  <div className="certificates-card__name-date--year">
                    {certificate.year_of_issuing}
                  </div>
                )}
              </Typography>
            </Box>
          </Box>
          <Box className="certificates-card__actions">
            <IconButton
              aria-label="Edit certificates"
              onClick={onEdit}
              variant="tertiary"
              size="small"
            >
              <EditPenIcon />
            </IconButton>
            <IconButton
              aria-label="Remove certificates"
              onClick={onDelete}
              variant="tertiary"
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box
          className="certificates-card__tile"
          bgcolor="var(--soft-yellow)"
          padding={2}
          borderRadius={2}
          gap={2}
          display="flex"
          data-testid="certificates-card"
        >
          <CertificateIcon className="certificates-card__tile-icon" />

          <Box display="flex" flexDirection="column" overflow="hidden">
            <Typography component="h3" ellipsis variant="label">
              {certificateName}
            </Typography>
            <Typography component="p" size="small" ellipsis color="grey-1">
              {certificateIssuer}
            </Typography>

            {certificate.year_of_issuing && (
              <Typography
                component="div"
                size="small"
                display="flex"
                gap="7px"
                alignItems="center"
                style={{ lineHeight: "1.4rem" }}
              >
                <BriefcaseIcon style={{ width: "14px", height: "14px" }} />
                {certificate.year_of_issuing}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
