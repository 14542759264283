import { useCallback, useEffect, useState } from "react";

import type { FeedOrderingType } from "@js/apps/common/components/filters";
import { useSelectedHashtag } from "@js/apps/give-and-get-help/hooks/use-selected-hashtag";
import type { GetPostsParams } from "@js/apps/give-and-get-help/types";

const INITIAL_PAGE = 1;

type GetQueryArgParams = {
  selectedHashtagId?: number;
  selectedPostHashtagId?: number;
  ordering?: FeedOrderingType;
  search?: string;
  space?: number;
  postsSessionIdx?: number;
};

const getQueryArg = ({
  selectedHashtagId,
  selectedPostHashtagId,
  ordering,
  space,
  search,
  postsSessionIdx,
}: GetQueryArgParams): Omit<GetPostsParams, "page"> => {
  return {
    hashtag: selectedHashtagId,
    post_hashtag: selectedPostHashtagId,
    ordering,
    search,
    space,
    postsSessionIdx,
  };
};

const getInitialQueryArg = (params: GetQueryArgParams): GetPostsParams => {
  const queryArg = getQueryArg(params);
  return {
    ...queryArg,
    page: INITIAL_PAGE,
  };
};

const hasArgChanged = (
  arg: Omit<GetPostsParams, "page">,
  prevArg: GetPostsParams,
) => {
  return Object.entries(arg).some(
    ([key, value]) => value !== prevArg[key as keyof GetPostsParams],
  );
};

export type UseGetPostQueryArg = {
  search?: string;
  ordering?: FeedOrderingType;
  space?: number;
  postsSessionIdx?: number;
};

export const useGetPostQueryArg = ({
  search,
  ordering,
  space,
  postsSessionIdx,
}: UseGetPostQueryArg = {}) => {
  const { selectedHashtagId, selectedPostHashtagId } = useSelectedHashtag();
  const [queryArg, setQueryArg] = useState<GetPostsParams>(() =>
    getInitialQueryArg({
      selectedHashtagId,
      selectedPostHashtagId,
      ordering,
      search,
      space,
      postsSessionIdx,
    }),
  );

  const setCurrentPage = useCallback((page: number) => {
    setQueryArg((prev) => ({ ...prev, page }));
  }, []);

  useEffect(() => {
    setQueryArg((prev) => {
      const newQueryArg = getQueryArg({
        selectedHashtagId,
        selectedPostHashtagId,
        ordering,
        search,
        space,
      });

      const shouldResetPage = hasArgChanged(newQueryArg, prev);

      return {
        ...newQueryArg,
        page: shouldResetPage ? INITIAL_PAGE : prev?.page,
      };
    });
  }, [selectedHashtagId, selectedPostHashtagId, ordering, search, space]);

  return {
    setCurrentPage,
    queryArg,
    currentPage: queryArg.page ?? INITIAL_PAGE,
  };
};
