import React from "react";
import { Field } from "redux-form";

import type { FilterLocation } from "@js/apps/filters/types";
import { JOB_LOCATION } from "@js/apps/jobs/context";

import type { OrderingType } from "../../config";
import { SortForm } from "../../config";
import { GenericSortButton } from "../generic-sort-button";

const SORTING_OPTIONS_TALENT = [
  { label: "Best match", value: "-score" },
  { label: "Newest", value: "newest" },
] as const;

const SORTING_OPTIONS_JOB = [
  { label: "Newest", value: "-created" },
  { label: "Best match", value: "-score" },
] as const;

type FiltersSortButtonProps = {
  location: FilterLocation;
  defaultValue: OrderingType;
};

export const FiltersSortButton = ({
  location,
  defaultValue,
}: FiltersSortButtonProps) => {
  const isJobLocation = location in JOB_LOCATION;

  const SORTING_OPTIONS = isJobLocation
    ? SORTING_OPTIONS_JOB
    : SORTING_OPTIONS_TALENT;

  return (
    <SortForm form="filters-sort-button">
      <Field
        name="ordering"
        component={GenericSortButton}
        options={SORTING_OPTIONS}
        defaultValue={defaultValue}
      />
    </SortForm>
  );
};
