import { useUser } from "@js/apps/common/hooks";
import { useAppDispatch } from "@js/hooks";
import { globalTimeoutsDelays, useTimeout } from "@js/hooks/timeout";
import type { ExposureAction } from "@js/middlewares/analytics/actions-handlers/exposure-events";
import type { ExperimentNames, IExperiment } from "@js/types/experiments";

type HookReturnType<T extends ExperimentNames> =
  | { isParticipating: true; experiment: IExperiment<T> }
  | { isParticipating: false; experiment: undefined };

export const useABExperiment = <T extends ExperimentNames>(
  experimentName: T extends ExperimentNames ? T : never,
): HookReturnType<T> => {
  const experiments = useUserExperiments();

  const currentExp = experiments?.find((e) => e.experiment === experimentName);
  const isParticipating = !!currentExp;

  if (!isParticipating) {
    return {
      isParticipating: false,
      experiment: undefined,
    };
  }

  return {
    isParticipating: true,
    experiment: currentExp as unknown as IExperiment<T>,
  };
};

type useExperimentExposureArg = {
  isParticipating: boolean;
  action: ExposureAction | null;
  skip?: boolean;
};

/**
 * @param action new reference will trigger dispatch, remember to use `useMemo`. If `null` action won't be dispatched.
 * @param skip skip action dispatch
 */
export const useExperimentExposure = ({
  isParticipating,
  action,
  skip,
}: useExperimentExposureArg) => {
  const dispatch = useAppDispatch();

  useTimeout(
    () => {
      if (!skip && isParticipating && action) {
        dispatch(action);
      }
    },
    globalTimeoutsDelays.log_exposure_event,
    [skip, isParticipating, action],
  );
};

const useUserExperiments = () => {
  const user = useUser();

  if (!user) return [];

  return user.experiments;
};
