import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

type DynamicIds = {
  space_id?: string;
  post_id?: string;
};

const pathSegments = {
  feed: {
    queryParam: "post_id",
    urlRouteParam: "id",
  },
  spaces: {
    queryParam: "space_id",
    urlRouteParam: "spaceId",
  },
} as const;

export const usePublicPagesDynamicIds = (): DynamicIds => {
  const { pathname } = useLocation();
  const params = useParams();
  const dynamicIds: DynamicIds = useMemo(() => {
    const ids: DynamicIds = {};
    Object.entries(pathSegments).forEach(([pathSegment, paramData]) => {
      if (pathname.includes(pathSegment)) {
        ids[paramData?.queryParam] = params[paramData.urlRouteParam];
      }
    });
    return ids;
  }, [pathname, params]);

  return dynamicIds;
};
