import { Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

export const LearnEtiquetteLink = () => {
  return (
    <Typography
      target="_blank"
      href={SETTINGS.SUPPORT_URLS.FEED_COMMUNITY_GUIDELINES_ARTICLE_URL}
      rel="noopener noreferrer"
      component="link"
      variant="label"
      size="small"
      color="blue"
      noTextDecoration
      pointer
      RouterLink={RouterLink}
      mt={-0.5}
    >
      Learn posting etiquette
    </Typography>
  );
};
