import { Box } from "@hexocean/braintrust-ui-components";
import {
  HideDetailsIcon,
  ShowDetailsIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { jobsApi } from "@js/apps/jobs/api";
import { useAppDispatch } from "@js/hooks";

import styles from "./styles.module.scss";

type JobItemExpandBtnProps = {
  expanded: boolean;
  onExpand: () => void;
  jobId: number;
};

export const JobItemExpandBtn = ({
  expanded,
  onExpand,
  jobId,
}: JobItemExpandBtnProps) => {
  const dispatch = useAppDispatch();

  const handleOnMouseEnter = () => {
    dispatch(
      jobsApi.util.prefetch("getExpandedJobDetails", jobId, {
        ifOlderThan: 30,
      }),
    );
  };

  return (
    <Box marginLeft="auto" marginTop="8px" textAlign="right">
      <button
        onClick={onExpand}
        onMouseEnter={handleOnMouseEnter}
        onFocus={handleOnMouseEnter}
        className={styles.wrapper}
      >
        {expanded ? (
          <>
            Close details
            <HideDetailsIcon sx={{ ml: 1, color: "var(--black)" }} />
          </>
        ) : (
          <>
            Expand details
            <ShowDetailsIcon sx={{ ml: 1, color: "var(--black)" }} />
          </>
        )}
      </button>
    </Box>
  );
};
