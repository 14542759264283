import React from "react";
import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import type { NumberInputProps } from "@hexocean/braintrust-ui-components";
import { NumberInput } from "@hexocean/braintrust-ui-components";

export type RhfNumberFieldProps<T extends FieldValues> = UseControllerProps<T> &
  NumberInputProps & {
    control: Control<T>;
  };

/** @description operates on string, you can transform value on submit if needed */
export const RhfNumberField = <T extends FieldValues>({
  helperText,
  ...props
}: RhfNumberFieldProps<T>) => {
  const { field, fieldState } = useController(props);

  return (
    <NumberInput
      {...props}
      {...field}
      onChange={(value) => {
        props.onChange?.(value);
        if (value === "") {
          // set to default value to avoid `dirty` state
          field.onChange(undefined);
        } else {
          field.onChange(value);
        }
      }}
      ref={undefined}
      error={fieldState.invalid}
      helperText={fieldState.error?.message || helperText}
    />
  );
};
