import { useCallback, useState } from "react";

import { useWithRecaptcha } from "@js/apps/common/hooks";
import {
  useGetPublicPostQuery,
  useGetRelevantPublicPostsQuery,
} from "@js/apps/give-and-get-help/api";
import { openSignUpModal } from "@js/apps/onboarding/components/sign-up-modal";

import { useRedirectToSlugBasedPost } from "../../hooks/use-redirect-to-slug-based-post";

export const usePublicPostPage = (id: number) => {
  useWithRecaptcha();
  const { data: mainPostData, isLoading: isMainPostLoading } =
    useGetPublicPostQuery({ id });
  const { data: relevantPostData, isLoading: isRelevantPostsLoading } =
    useGetRelevantPublicPostsQuery({ id });
  const [isCtaBannerHidden, setIsCtaBannerHidden] = useState(false);
  useRedirectToSlugBasedPost(mainPostData);

  const handleBannerClose = useCallback(() => {
    setIsCtaBannerHidden(true);
  }, []);

  const handleSignUp = useCallback(() => {
    openSignUpModal();
  }, []);
  return {
    handleSignUp,
    handleBannerClose,
    isCtaBannerHidden,
    relevantPostData,
    isRelevantPostsLoading,
    isMainPostLoading,
    mainPostData,
  };
};
