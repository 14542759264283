import React from "react";

import {
  Box,
  ButtonCore,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { AvailableForWorkStatus } from "@js/apps/available-for-work/components";
import { useOptionTooltip } from "@js/apps/available-for-work/hooks/use-option-tooltip";
import { UserAvatar } from "@js/components/user-avatar";
import { useAppSelector } from "@js/hooks";
import type { User } from "@js/types/auth";

import styles from "./style.module.scss";

type AvailabilityEntryPointButtonProps = {
  user: User;
  isAvailabilitySet: boolean;
  setAvailabilityStatus?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const AvailabilityEntryPointButton = ({
  user,
  isAvailabilitySet,
  setAvailabilityStatus,
}: AvailabilityEntryPointButtonProps) => {
  const freelancerProfile = useAppSelector(
    (state) => state.freelancer.freelancerProfile,
  );
  const tooltipTitle = useOptionTooltip(
    freelancerProfile?.availability_for_work_options,
  );

  return (
    <Box
      display="flex"
      component={ButtonCore}
      gap="10px"
      onClick={setAvailabilityStatus}
      disableRipple
    >
      <UserAvatar
        user={user}
        hideBadge
        disableShadow
        hideBorder
        avatarClassName={styles.availabilityEntryPointButtonAvatar}
        size="full"
      />

      <Box textAlign="left">
        <Typography
          component="p"
          fontWeight={500}
          className={styles.availabilityEntryPointButtonUsername}
          title={user.public_name}
        >
          {user.public_name}
        </Typography>
        {!isAvailabilitySet ? (
          <Typography component="p" color="grey" size="small">
            Set work availability
          </Typography>
        ) : (
          <Tooltip title={tooltipTitle} color="var(--black)" disableInteractive>
            <Box maxWidth="140px">
              <AvailableForWorkStatus />
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
