import type { ReactNode } from "react";

import { Stack } from "@hexocean/braintrust-ui-components";

export const ReferralsStatsSummaryStateColumn = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Stack sx={{ justifyContent: "space-between", gap: 2 }}>{children}</Stack>
  );
};
