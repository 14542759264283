/**
 * A utility function to handle 'keydown' event for interactive elements.
 * It triggers the provided action if the "Enter" or "Space" key is pressed.
 */
export const handleKeyDownConfirm = (action: (...args: any[]) => void) => {
  return (event: React.KeyboardEvent) => {
    // Check if the key pressed is 'Enter' or 'Space'
    if (
      event.key === "Enter" ||
      event.key === " " ||
      event.code === "Enter" ||
      event.code === "Space"
    ) {
      // Prevent the default action to stop scrolling when space is pressed
      event.preventDefault();

      // Call the provided action
      action(event);
    }
  };
};
