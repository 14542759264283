import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Snackbar } from "@js/components/snackbar";

import { useLazyGetSmartMatchingStatusQuery } from "../../api";

type UsePollingSmartMatchingStatusProps = {
  jobId?: number;
};

export const usePollingSmartMatchingStatus = ({
  jobId,
}: UsePollingSmartMatchingStatusProps) => {
  const timeoutRef = useRef<number>();
  const isPollingInitializedRef = useRef(false);
  const [isSmartMatchingInProgress, setIsSmartMatchingInProgress] =
    useState<boolean>(true);
  const [getSmartMatchingStatus, { data }] =
    useLazyGetSmartMatchingStatusQuery();
  const analyzingProfilesCount = useMemo(
    () => data?.analyzing_profiles,
    [data?.analyzing_profiles],
  );

  const pollSmartMatchingStatus = useCallback(
    async (id: number) => {
      try {
        setIsSmartMatchingInProgress(true);

        const status = await getSmartMatchingStatus({ id }).unwrap();

        if (
          status.smart_matching_status === ENUMS.SmartMatchingStatus.COMPLETED
        ) {
          setIsSmartMatchingInProgress(false);
          return;
        }
        if (
          status.smart_matching_status === ENUMS.SmartMatchingStatus.PROCESSING
        ) {
          timeoutRef.current = window.setTimeout(
            () => pollSmartMatchingStatus(id),
            2000,
          );
        }
      } catch (_error) {
        setIsSmartMatchingInProgress(false);
        Snackbar.error("Something went wrong during smart matching");
      }
    },
    [getSmartMatchingStatus],
  );

  useEffect(() => {
    if (!jobId || isPollingInitializedRef.current) {
      return;
    }

    isPollingInitializedRef.current = true;
    pollSmartMatchingStatus(jobId);
  }, [jobId, pollSmartMatchingStatus]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return {
    isSmartMatchingInProgress,
    pollSmartMatchingStatus,
    analyzingProfilesCount,
  };
};
