import { SessionStorage } from "@js/services";

export const setIndexToScrollTo = (index: number) => {
  SessionStorage.setItem("scrollToIndex", index.toString());
};

export const getIndexToScrollTo = (): number | undefined => {
  const index = SessionStorage.getItem("scrollToIndex");

  if (index === null) {
    return;
  }

  return parseInt(index);
};

export const resetIndexToScrollTo = () => {
  SessionStorage.removeItem("scrollToIndex");
};
