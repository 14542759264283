import { Fragment } from "react";

import {
  Box,
  Button,
  Divider,
  Loader,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ReviewModal } from "@js/apps/reviews/components/review-modal";
import { DEFAULT_REVIEWS_COUNT } from "@js/apps/reviews/constants";
import { GGHTalentReviewModal } from "@js/apps/reviews/forms";
import { useAutomaticallyOpenWriteReviewModal } from "@js/apps/reviews/hooks/automatically-open-review-modal";
import { useTalentProfileReviewModule } from "@js/apps/reviews/hooks/talent-profile-review-module";
import type { FreelancerPublic } from "@js/types/freelancer";

import { ReviewCard } from "../review-card";

import { ReviewTags } from "./review-tags";
import { ReviewsTopBar } from "./reviews-top-bar";
import { WriteReviewButton } from "./write-review-button";

type ReviewModuleForTalentProfileProps = {
  profile: FreelancerPublic;
};

export const TalentProfileReviewModule = ({
  profile,
}: ReviewModuleForTalentProfileProps) => {
  const {
    isMobile,
    isLoadAllAvailable,
    user,
    reviewsSummary,
    reviews,
    isLoading,
    isFetching,
    isError,
    handleLoadClick,
  } = useTalentProfileReviewModule({ profile });
  useAutomaticallyOpenWriteReviewModal({ profile });

  if (isError) return null;

  if (isLoading) {
    return (
      <RoundedBox display="flex" justifyContent="center">
        <Loader />
      </RoundedBox>
    );
  }

  const tags = reviewsSummary?.tags;
  const reviewsCount = reviewsSummary?.reviews_count || 0;
  const shouldDisplayButton =
    !isFetching && reviewsCount > DEFAULT_REVIEWS_COUNT;
  const isOwnProfile = profile.id === user?.freelancer;

  if (
    reviewsCount === 0 &&
    !profile.can_user_review_job &&
    !profile.can_user_review_help_offer
  ) {
    return null;
  }

  return (
    <RoundedBox id="review-section">
      <WriteReviewButton profile={profile} />

      <Typography
        component="h3"
        variant="paragraph"
        size="large"
        mb={isMobile ? 2 : 3}
      >
        Reviews
      </Typography>

      <ReviewsTopBar reviewsSummary={reviewsSummary} />

      {!!tags?.length && (
        <Box>
          <Typography component="p" variant="label" size="small" mt={3} mb={1}>
            {`${
              isOwnProfile ? "You are" : `${profile.user.first_name} is`
            } great at...`}
          </Typography>

          <ReviewTags tags={tags} />
        </Box>
      )}

      <Box sx={{ mt: 3 }}>
        {reviews?.map((review) => {
          return (
            <Fragment key={review.id}>
              <Divider color="beige" />
              <ReviewCard
                review={review}
                profile={profile}
                isOwnProfile={isOwnProfile}
              />
            </Fragment>
          );
        })}
        {shouldDisplayButton && <Divider color="beige" />}
      </Box>

      <Box className="review-module__actions">
        {shouldDisplayButton && (
          <Box>
            {isLoadAllAvailable ? (
              <Button
                onClick={() => handleLoadClick({ loadAll: true })}
                disabled={isFetching}
                shape="squared"
                variant="transparent-border-beige"
              >
                Load all
              </Button>
            ) : (
              <Button
                onClick={() => handleLoadClick()}
                disabled={isFetching}
                shape="squared"
                variant="transparent-border-beige"
              >
                Back to top
              </Button>
            )}
          </Box>
        )}

        <WriteReviewButton profile={profile} />
        <ReviewModal
          profile={profile}
          reviewSubject={ENUMS.AccountType.FREELANCER}
        />
        {user?.account_type === ENUMS.AccountType.FREELANCER && (
          <GGHTalentReviewModal profile={profile} />
        )}
      </Box>
    </RoundedBox>
  );
};
