import type { WrappedFieldMetaProps } from "redux-form";

import { useFormErrors } from "@js/forms/hooks";
import { getErrorsForRequiredFields, pluralize } from "@js/utils";

export const REQUIRED_FIELDS = [
  "freelancer_availability",
  "payment_amount",
  "new_application_answers",
  "location_requirement_met",
  "timezone_requirement_met",
];

export const useBidErrorSectionErrors = (meta: WrappedFieldMetaProps) => {
  const { errors } = useFormErrors(meta);
  const requiredErrors = getErrorsForRequiredFields(errors, REQUIRED_FIELDS);

  const _errorsLength = Object.keys(requiredErrors).length;

  const getMessage = () => {
    const length = _errorsLength;
    if (length) {
      return `You are missing ${length} required field${pluralize(length)}`;
    }
  };

  return {
    requiredErrors,
    getMessage,
  };
};
