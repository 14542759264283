import { type RefObject, useLayoutEffect, useRef } from "react";
import URI from "urijs";

import { useTriggerActionOnScroll } from "@js/hooks/trigger-action-on-scroll";
import type { RoomMessagesPaginatedResult } from "@js/types/messenger";

type UseLoadMoreMessagesOnScrollParams = {
  elementRef: RefObject<HTMLElement>;
  isFetching: boolean;
  currentData: RoomMessagesPaginatedResult | undefined;
  setCursor: (cursor: string) => void;
};

export const useLoadMoreMessagesOnScroll = ({
  elementRef,
  isFetching,
  currentData,
  setCursor,
}: UseLoadMoreMessagesOnScrollParams) => {
  const prevScrolllHeight = useRef(0);

  const fetchNextMessages = (element: HTMLElement) => {
    const next = currentData?.next;
    if (!next || isFetching) {
      return;
    }
    const args = URI.parseQuery(URI(next).search());
    if (typeof args.cursor !== "string") {
      return;
    }

    prevScrolllHeight.current = element.scrollHeight;
    setCursor(args.cursor);
  };

  useTriggerActionOnScroll({
    elementRef,
    scrollTopOffset: 10,
    action: fetchNextMessages,
  });

  useLayoutEffect(() => {
    if (!prevScrolllHeight.current || !elementRef.current) {
      return;
    }

    elementRef.current.scrollTop =
      elementRef.current.scrollHeight - prevScrolllHeight.current;
  }, [currentData, elementRef]);
};
