import { Typography } from "@hexocean/braintrust-ui-components";
import type { UniversalTabValue } from "@js/apps/universal-search/constants";
import { assertUnreachable, pluralize } from "@js/utils";

type UniversalSearchItemsCounterProps = {
  tab: UniversalTabValue;
  itemsLength?: number;
  isHeader?: boolean;
};

export const UniversalSearchItemsCounter = ({
  tab,
  itemsLength = 0,
  isHeader = false,
}: UniversalSearchItemsCounterProps) => {
  const tabName = (() => {
    switch (tab) {
      case "CAREER_HELP":
        return `post${pluralize(itemsLength, { zeroPlural: true })}`;
      case "PEOPLE":
        return itemsLength === 1 ? "person" : "people";
      case "JOBS":
        return `job${pluralize(itemsLength, { zeroPlural: true })}`;
      default: {
        assertUnreachable(tab);
      }
    }
  })();

  return (
    <Typography
      size="small"
      component="span"
      variant="paragraph"
      fontWeight={500}
    >
      {itemsLength}{" "}
      <Typography
        size="small"
        component="span"
        variant="paragraph"
        fontWeight={isHeader ? 500 : 400}
      >
        {tabName}
      </Typography>
    </Typography>
  );
};
