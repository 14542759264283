import React from "react";
import { Field } from "redux-form";

import { mapEmailErrorCode } from "@js/apps/auth/utils";
import { TextField } from "@js/forms/fields/text";

type SignUpEmailFieldProps = {
  disabled?: boolean;
  variant?: "talent" | "business";
  required?: boolean;
};
export const SignUpEmailField = ({
  disabled,
  variant = "talent",
  required = true,
}: SignUpEmailFieldProps) => {
  const placeholderEnd =
    variant === "business" ? "business email" : "email address";

  const label =
    variant === "business"
      ? `Business email${required ? "*" : ""}`
      : `Email${required ? "*" : ""}`;

  return (
    <Field
      variant="outlined"
      color="secondary"
      name="email"
      id="email"
      component={TextField}
      placeholder={`Enter your ${placeholderEnd}`}
      mapErrorCode={(email: string) => mapEmailErrorCode(email, true)}
      label={label}
      disabled={disabled}
    />
  );
};
