import type { NotificationSetting } from "@js/types/notifications";

type UseSubscribableNotificationsProps = {
  notifications: NotificationSetting[];
};
export const useSubscribableNotifications = ({
  notifications,
}: UseSubscribableNotificationsProps) => {
  const [subscribableNotifications, owned_notifications] = notifications.reduce<
    [NotificationSetting[], NotificationSetting[]]
  >(
    (acc, notification) => {
      const subscribable = acc[0];
      const ownedNotification = acc[1];

      if (!notification.is_subscribable) {
        ownedNotification.push(notification);
      } else {
        subscribable.push(notification);
      }

      return [subscribable, ownedNotification];
    },
    [[], []],
  );

  const anySubscribable = subscribableNotifications?.length;

  const _ownedNotification = owned_notifications.map((notification) => {
    const subscribableVariantIdx = subscribableNotifications?.findIndex(
      (sub) => sub.label === notification.label,
    );

    return { ...notification, subscribableIdx: subscribableVariantIdx };
  });

  return {
    notifications: _ownedNotification,
    anySubscribable,
    subscribableNotifications,
  };
};
