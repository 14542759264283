import type { MouseEventHandler } from "react";
import { useEffect, useRef } from "react";

import { useAppDispatch } from "@js/hooks";
import type { Reaction, StickerValue } from "@js/types/give-and-get-help";

import { StickerIconButton } from "../../sticker-icon-button";
import { showReactionsDrawer } from "../../store/stickers-module-slice";

export type StickerReactionButtonMobileProps = {
  onClick: MouseEventHandler<HTMLElement>;
  disabled: boolean;
  selected: boolean;
  reactionValue: StickerValue;
  entityReactions: Reaction[];
  entityId: number;
};

export const StickerReactionButtonMobile = ({
  onClick,
  disabled,
  selected,
  reactionValue,
  entityReactions,
  entityId,
}: StickerReactionButtonMobileProps) => {
  const dispatch = useAppDispatch();
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  const handleTouchStart = () => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      dispatch(
        showReactionsDrawer({
          selectedReactionValue: reactionValue,
          entityReactions,
          entityId,
        }),
      );
    }, 500);
  };

  const handleTouchEnd = () => {
    clearTimeout(timeoutRef.current);
  };

  return (
    <StickerIconButton
      onClick={onClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      disabled={disabled}
      selected={selected}
      reactionValue={reactionValue}
      data-testid="reaction-icon-button-mobile"
      sx={{
        "&.MuiButtonBase-root": {
          fontSize: "1.25rem",
          p: "6px",
        },
        "&:hover": { ...(!selected && { "--btn-border-width": "0px" }) },
      }}
    />
  );
};
