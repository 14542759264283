import type { TypedWrappedFieldProps, WrappedFieldProps } from "redux-form";

import { Box } from "@hexocean/braintrust-ui-components";
import type { Role } from "@js/types/roles";

import { useEditFreelancerProfileSkillsLogic } from "../hooks/use-skills-logic";
import { PopularSkillsField } from "../popular-skills-field";
import type { FreelancerSkillOption, SimpleSkill } from "../types";

import { TalentProfileSelectedSkillsList } from "./talent-profile-selected-skill-list";
import { TalentProfileSkillsCombobox } from "./talent-profile-skills-combobox";

const mapTalentPopularSkillToOption = (
  skill: SimpleSkill,
): FreelancerSkillOption => ({
  value: { id: skill.id, skill, is_superpower: false },
  label: skill.name,
});

type TalentProfileSkillsFieldProps = {
  role: TypedWrappedFieldProps<Role>;
  new_skills: WrappedFieldProps;
};

export const TalentProfileSkillsField = ({
  role,
  new_skills,
}: TalentProfileSkillsFieldProps) => {
  const { displayError, newMeta, error, popularSkillsOptions } =
    useEditFreelancerProfileSkillsLogic({
      newSkillsFormField: new_skills,
      roleId: role.input.value.id,
      mapSkillToOption: mapTalentPopularSkillToOption,
    });

  return (
    <>
      <Box mb={2}>
        <TalentProfileSkillsCombobox input={new_skills.input} meta={newMeta} />
      </Box>
      {Boolean(popularSkillsOptions.length) && (
        <PopularSkillsField
          input={new_skills.input}
          meta={newMeta}
          options={popularSkillsOptions}
          selectedRole={role.input.value}
        />
      )}
      <TalentProfileSelectedSkillsList
        input={new_skills.input}
        meta={newMeta}
        error={displayError ? error : undefined}
      />
    </>
  );
};
