import { ModalInstance } from "@js/components/modal";

import type { InterviewRequestMessageProps } from "../../models";
import { InterviewRequestMessage } from "../interview-request-message";

import style from "./style.module.scss";

export const openInterviewRequestMessage = ({
  job,
  refetchBidList,
  bid,
  interviewableBids,
}: InterviewRequestMessageProps) => {
  if (!refetchBidList) return;

  ModalInstance.open({
    className: style.interviewMessageModal,
    keepOnBackdropClick: true,
    children: (
      <InterviewRequestMessage
        bid={bid}
        job={job}
        refetchBidList={refetchBidList}
        interviewableBids={interviewableBids}
      />
    ),
    padding: false,
    closeButton: true,
    closeButtonProps: {
      style: {
        marginRight: "-8px",
        top: 0,
        right: "12px",
        padding: "16px",
      },
    },
  });
};
