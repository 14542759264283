import { createContext, useContext } from "react";

import type { PostComment } from "@js/types/give-and-get-help";

type ContextType = {
  comments: PostComment[];
  totalCommentsCount: number;
};

const Context = createContext<ContextType | null>(null);

type ProviderProps = {
  value: ContextType;
  children: React.ReactNode;
};

const Provider = ({ value, children }: ProviderProps) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCommentSectionContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("CommentsSectionContext is missing!");
  }

  return context;
};

export const CommentSectionContext = {
  Provider: Provider,
  Consumer: Context.Consumer,
};
