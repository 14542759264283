import { useEffect, useMemo, useRef } from "react";
import {
  change,
  getFormSyncWarnings,
  getFormValues,
  registerField,
  updateSyncWarnings,
} from "redux-form";
import * as Sentry from "@sentry/react";
import { isEqual } from "underscore";

import { useAppDispatch, useAppSelector } from "@js/hooks";
import { deepClone } from "@js/utils/data";

import {
  useSelfPromotionPostCheckMutation,
  useSelfPromotionPostServerInitMutation,
} from "../../api";
import type { CreateOrEditPostFormData } from "../../form";
import type {
  CREATE_POST_FORM_ID,
  EDIT_POST_FORM_ID,
} from "../../form/constants";
import type { AddPostParams } from "../../types";

export const useCheckForSelfPromotion = (
  formId: typeof CREATE_POST_FORM_ID | typeof EDIT_POST_FORM_ID,
) => {
  const [warmup] = useSelfPromotionPostServerInitMutation();
  const [check, { isLoading }] = useSelfPromotionPostCheckMutation();

  const {
    values,
    updateFormState,
    clearWarning,
    isWarningIgnored,
    ignoreWarning,
  } = useSelfPromotionFormStateUpdate(formId);

  const selfPromotionServerWarmUp = () => {
    warmup();
  };

  const checkPostForSelfPromotion = async () => {
    try {
      const { is_violation, id } = await check(values).unwrap();
      updateFormState(is_violation, id);
      return is_violation;
    } catch (error) {
      Sentry.captureException("Self promotion check failed", {
        extra: { error },
      });
    }
  };

  return {
    selfPromotionServerWarmUp,
    checkPostForSelfPromotion,
    isCheckInProgress: isLoading,
    clearWarning,
    isWarningIgnored,
    ignoreWarning,
  };
};

export const CREATE_POST_FORM_SELF_PROMOTION_FIELD_ID =
  "self_promotion_response";

export const CREATE_POST_FORM_SELF_PROMOTION_RESULT_IGNORE =
  "self_promotion_response_ignored";

type PreparedAddPostParams = Pick<
  AddPostParams,
  "title" | "text" | "poll_options" | "poll_text"
>;

const defaultPostValues = {};

const useSelfPromotionFormStateUpdate = (
  formId: typeof CREATE_POST_FORM_ID | typeof EDIT_POST_FORM_ID,
) => {
  const dispatch = useAppDispatch();

  const postValues: CreateOrEditPostFormData = useAppSelector(
    (state) => getFormValues(formId)(state) ?? defaultPostValues,
  ) as CreateOrEditPostFormData;

  const syncWarnings = useAppSelector(getFormSyncWarnings(formId));
  const isSelfPromotionDetected =
    CREATE_POST_FORM_SELF_PROMOTION_FIELD_ID in syncWarnings &&
    !!syncWarnings[CREATE_POST_FORM_SELF_PROMOTION_FIELD_ID];

  const preparedValues = useMemo(() => {
    return cleansePostData({
      title: postValues.title,
      text: postValues.text,
      poll_options: postValues.poll_options,
      poll_text: postValues.poll_text,
    });
  }, [
    postValues.title,
    postValues.text,
    postValues.poll_text,
    postValues.poll_options,
  ]);

  const previousValues = useRef({ preparedValues }).current;
  const isWarningIgnored = postValues.self_promotion_response_ignored || false;

  const updateFormState = (isViolation: boolean, id: number | null) => {
    dispatch(
      registerField(formId, CREATE_POST_FORM_SELF_PROMOTION_FIELD_ID, "Field"),
    );
    dispatch(
      registerField(
        formId,
        CREATE_POST_FORM_SELF_PROMOTION_RESULT_IGNORE,
        "Field",
      ),
    );
    dispatch(
      change(formId, CREATE_POST_FORM_SELF_PROMOTION_FIELD_ID, id, true),
    ); // adds value to field so it can be send in create post request
    resetIgnoreWarning();
    if (isViolation) {
      dispatch(
        updateSyncWarnings(
          formId,
          {
            [CREATE_POST_FORM_SELF_PROMOTION_FIELD_ID]:
              "Self promotion detected",
          },
          undefined,
        ),
      );
    }
  };

  const clearWarning = () => {
    dispatch(updateSyncWarnings(formId, {}, undefined));
  };

  const ignoreWarning = () => {
    dispatch(
      change(
        formId,
        CREATE_POST_FORM_SELF_PROMOTION_RESULT_IGNORE,
        true,
        false,
      ),
    );
  };
  const resetIgnoreWarning = () => {
    dispatch(
      change(
        formId,
        CREATE_POST_FORM_SELF_PROMOTION_RESULT_IGNORE,
        false,
        false,
      ),
    );
  };
  useEffect(() => {
    if (!isSelfPromotionDetected) {
      return;
    }

    // If user changes one of the elements that affect self promotion score, remove warning
    if (comparePostContent(previousValues.preparedValues, preparedValues)) {
      clearWarning();
      resetIgnoreWarning();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousValues, preparedValues]);

  return {
    values: preparedValues,
    updateFormState,
    clearWarning,
    isWarningIgnored,
    isSelfPromotionDetected,
    ignoreWarning,
  };
};

const cleansePostData = (
  values: PreparedAddPostParams,
): PreparedAddPostParams => {
  const valuesCopy = deepClone(values);
  if (!valuesCopy.poll_text || !valuesCopy.poll_options) {
    delete valuesCopy.poll_text;
    delete valuesCopy.poll_options;

    return valuesCopy;
  }

  valuesCopy.poll_options = valuesCopy.poll_options.filter(
    (pollOption) => !!pollOption.text,
  );

  return valuesCopy;
};

const comparePostContent = (
  previousValues: PreparedAddPostParams,
  currentValues: PreparedAddPostParams,
) => {
  return !isEqual(previousValues, currentValues);
};
