import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import type { IPost } from "@js/types/give-and-get-help";

import type { PostsLocation } from "../../context";
import type { Hashtag } from "../../types";
import type { PostItemProps } from "../post";
import { PostItem } from "../post";
import type { TrendingHashtagsProps } from "../trending-hashtags/trending-hashtags";
import { shouldRenderHashtagsModule } from "../trending-hashtags/trending-hashtags";
import { TrendingHashtags } from "../trending-hashtags/trending-hashtags";
import { trendingHashtagsPosition } from "../trending-hashtags/trending-hashtags-config";

import { setFeedRenderedItemIndex } from "./rendered-item-index";

export type PickedPostItemProps = Pick<
  PostItemProps,
  "onViewCommentClick" | "disablePostSubscription"
>;

export type VirtualizedPostsListItemProps = {
  post: IPost;
  index: number;
  postsCount: number;
  location: PostsLocation;
  trendingHashtagsProps?: Omit<TrendingHashtagsProps, "hashtags">;
  trendingHashtags?: Hashtag[];
} & PickedPostItemProps;

export const VirtualizedPostsListItem = React.memo(
  ({
    post,
    index,
    postsCount,
    trendingHashtagsProps,
    trendingHashtags,
    location,
    ...postItemProps
  }: VirtualizedPostsListItemProps) => {
    return (
      <Box onClick={() => setFeedRenderedItemIndex({ index, location })}>
        <PostItem key={post.id} postData={post} {...postItemProps} />
        {!!trendingHashtags?.length &&
          shouldRenderHashtagsModule(
            postsCount,
            index,
            trendingHashtagsPosition,
          ) && (
            <TrendingHashtags
              hashtags={trendingHashtags}
              {...trendingHashtagsProps}
              sx={{ mt: 2, ...trendingHashtagsProps?.sx }}
            />
          )}
      </Box>
    );
  },
);

/** This wrapper exists just to silent eslint rule  */
export const postItemContent = (props: VirtualizedPostsListItemProps) => {
  return <VirtualizedPostsListItem {...props} />;
};
