import { API } from "@js/api";
import type { Talent } from "@js/types/freelancer";
import type { IPost } from "@js/types/give-and-get-help";
import type { Job } from "@js/types/jobs";

export type UniversalSearchPostsResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  total_count: number | null;
  results: IPost[];
  visited_feed_three_times: boolean;
  page: number;
};

export type UniversalSearchPopularPostsResponse = Omit<
  UniversalSearchPostsResponse,
  "visited_feed_three_times" | "page"
>;

export type UniversalSearchJobsResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Job[];
};

export type UniversalSearchTalentResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  total_count: number | null;
  results: Talent[];
};

type UniversalSearchArgs = {
  search?: string;
  page_size?: number;
  page?: number;
};

type UniversalSearchPopularArgs = Omit<UniversalSearchArgs, "page_size">;

const universalSearchApi = API.injectEndpoints({
  endpoints: (build) => ({
    getUniversalSearchPosts: build.query<
      UniversalSearchPostsResponse,
      UniversalSearchArgs
    >({
      query: ({ search, page_size = 5 }) => ({
        method: "GET",
        url: `/posts/`,
        params: { search, page_size },
      }),
    }),
    getUniversalSearchJobs: build.query<
      UniversalSearchJobsResponse,
      UniversalSearchArgs
    >({
      query: ({ search, page_size = 5 }) => ({
        method: "GET",
        url: `/job_search/`,
        params: { search, page_size },
      }),
    }),
    getUniversalSearchTalent: build.query<
      UniversalSearchTalentResponse,
      UniversalSearchArgs
    >({
      query: ({ search, page_size = 5 }) => ({
        method: "GET",
        url: `/talent/`,
        params: { search, page_size },
      }),
    }),
    getUniversalSearchPopularPosts: build.query<
      UniversalSearchPopularPostsResponse,
      UniversalSearchPopularArgs
    >({
      query: ({ search }) => ({
        method: "GET",
        url: `/popular_posts/`,
        params: { search },
      }),
      transformResponse: (
        response: UniversalSearchPostsResponse["results"],
      ) => {
        return {
          count: response.length,
          total_count: null,
          next: null,
          previous: null,
          results: response,
        };
      },
    }),
    getUniversalSearchPopularJobs: build.query<
      UniversalSearchJobsResponse,
      UniversalSearchPopularArgs
    >({
      query: ({ search }) => ({
        method: "GET",
        url: `/popular_jobs/`,
        params: { search },
      }),
      transformResponse: (response: UniversalSearchJobsResponse["results"]) => {
        return {
          count: response.length,
          next: null,
          previous: null,
          results: response,
        };
      },
    }),
    getUniversalSearchPopularTalent: build.query<
      UniversalSearchTalentResponse,
      UniversalSearchPopularArgs
    >({
      query: ({ search }) => ({
        method: "GET",
        url: `/popular_people/`,
        params: { search },
      }),
      transformResponse: (
        response: UniversalSearchTalentResponse["results"],
      ) => {
        return {
          count: response.length,
          total_count: null,
          next: null,
          previous: null,
          results: response,
        };
      },
    }),
  }),
});

export const {
  useGetUniversalSearchJobsQuery,
  useGetUniversalSearchPostsQuery,
  useGetUniversalSearchTalentQuery,
  useGetUniversalSearchPopularJobsQuery,
  useGetUniversalSearchPopularPostsQuery,
  useGetUniversalSearchPopularTalentQuery,
} = universalSearchApi;
