export const useCombinedQueryState = (
  queryStates: {
    isLoading?: boolean;
    isFetching?: boolean;
    isError?: boolean;
  }[],
) => {
  return {
    isLoading: queryStates.some((s) => s.isLoading),
    isFetching: queryStates.some((s) => s.isFetching),
    isError: queryStates.some((s) => s.isError),
  };
};
