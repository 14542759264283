import { Box, Tooltip } from "@hexocean/braintrust-ui-components";
import { AvailableForWorkStatus } from "@js/apps/available-for-work/components";

import { AvatarWithApprovedBadge } from "./avatar-with-badge";

type ProfnetProfilePageAvatarPublicComponentProps = {
  onAvailableForWorkStatusClick: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  isAvailableForWork: boolean;
  tooltipTitle: React.ReactNode;
  avatarContent: JSX.Element;
  isApproved: boolean;
};

export const ProfnetProfilePageAvatarPublicComponent = ({
  avatarContent,
  isApproved,
  tooltipTitle,
  isAvailableForWork,
  onAvailableForWorkStatusClick,
}: ProfnetProfilePageAvatarPublicComponentProps) => {
  return (
    <Box className="profnet-profile-page-avatar">
      <AvatarWithApprovedBadge isApproved={isApproved}>
        {avatarContent}
        <Box className="availability-status-container">
          {isAvailableForWork ? (
            <Tooltip title={tooltipTitle} color="var(--black)">
              <Box>
                <AvailableForWorkStatus
                  onClick={onAvailableForWorkStatusClick}
                />
              </Box>
            </Tooltip>
          ) : null}
        </Box>
      </AvatarWithApprovedBadge>
    </Box>
  );
};
