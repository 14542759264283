import { useEffect, useState } from "react";

import { normalizeNumberInput } from "@js/utils";

export const convertNumberToWords = async (input: string): Promise<string> => {
  if (!input) return "";

  const { toWords } = await import(
    /* webpackChunkName: "num-to-words" */ "number-to-words"
  );
  const splitter = ".";
  if (!input.includes(splitter)) {
    return toWords(input);
  }
  let result = "";
  const decimalIndex = input.indexOf(splitter);
  const decimalsArray = input.slice(decimalIndex + 1).split("");
  result = toWords(input.slice(0, decimalIndex));
  if (decimalsArray.length === 0) {
    return result;
  }
  return `${result} point ${decimalsArray.map((d) => toWords(d)).join(" ")}`;
};

type UseNumberToWords = (input: string) => {
  result: string;
  normalizedValue: string;
  error: string;
};

export const useNumberToWords: UseNumberToWords = (input: string) => {
  const [result, setResult] = useState<string>("");
  const [normalizedValue, setNormalizedValue] = useState<string>("");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    (async () => {
      try {
        const _normalizedValue = normalizeNumberInput(input);
        const computed = await convertNumberToWords(_normalizedValue);
        setResult(computed);
        setNormalizedValue(_normalizedValue);
        setError("");
      } catch (_e) {
        setResult("");
        setNormalizedValue("");
        setError("A valid number is required");
      }
    })();
  }, [input]);

  return {
    result,
    normalizedValue,
    error,
  };
};
