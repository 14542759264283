import type { To } from "react-router-dom";

import {
  jobViewAllPopoverClicked,
  postViewAllPopoverClicked,
  talentViewAllPopoverClicked,
} from "@js/apps/universal-search/actions";
import { LocalStorage } from "@js/services";
import { assertUnreachable, pluralize } from "@js/utils";
import { getTruncatedText } from "@js/utils/string";

import type { UniversalSearchCurrentData } from "./hooks/use-universal-search-form";
import type { UniversalTabValue } from "./constants";
import {
  popularSearchTabToUrl,
  searchTabToName,
  searchTabToUrl,
} from "./constants";

export const getResultsURL = (
  searchPhrase: string,
  searchTab: UniversalTabValue,
): To => {
  if (!searchPhrase) {
    return { pathname: popularSearchTabToUrl[searchTab] };
  }

  return {
    pathname: searchTabToUrl[searchTab],
    search: `search=${encodeURIComponent(searchPhrase)}`,
  };
};

export const getResultsLabel = (
  searchPhrase: string,
  searchTab: UniversalTabValue,
  maxCharacters = 26,
): string => {
  const title = searchTabToName[searchTab];

  if (!searchPhrase) {
    return `View all ${title}`;
  }

  const truncatedSearchPhrase = getTruncatedText(
    searchPhrase,
    maxCharacters - 6,
    maxCharacters,
  );
  const isTruncated = truncatedSearchPhrase.length < searchPhrase.length;
  const textToDisplay = truncatedSearchPhrase + (isTruncated ? "..." : "");

  return `View all "${textToDisplay}" ${title}`;
};

export const getResultsCountWithLabel = (
  currentTab: UniversalTabValue,
  results?: UniversalSearchCurrentData,
) => {
  const options = { zeroPlural: true };
  const limit = 10000;

  let count: number;
  let total_count: number;

  switch (currentTab) {
    case "JOBS":
      count = results?.jobResults?.count ?? 0;
      return `${count.toLocaleString("en-US")} job${pluralize(count, options)}`;
    case "CAREER_HELP":
      count = results?.postResults?.count ?? 0;
      total_count = results?.postResults?.total_count ?? 0;

      if (total_count && total_count > limit) {
        return `${count.toLocaleString("en-US")} result${pluralize(
          count,
          options,
        )} returned of ${total_count.toLocaleString("en-US")} posts`;
      }

      return `${count.toLocaleString("en-US")} post${pluralize(
        count,
        options,
      )}`;
    case "PEOPLE":
      count = results?.talentResults?.count ?? 0;
      total_count = results?.talentResults?.total_count ?? 0;

      if (total_count && total_count > limit) {
        return `${count.toLocaleString("en-US")} result${pluralize(
          count,
          options,
        )} returned of ${total_count.toLocaleString("en-US")} people`;
      }

      return `${count.toLocaleString("en-US")} ${
        count === 1 ? "person" : "people"
      }`;
    default:
      assertUnreachable(currentTab);
      return "";
  }
};

export const handleFiltersReset = (searchTab: UniversalTabValue) => {
  clearFiltersTalentsAndJobsUniversalSearch(searchTab);
};

export const clearFiltersTalentsAndJobsUniversalSearch = (
  universalSearchTabValue: UniversalTabValue,
) => {
  if (universalSearchTabValue === "PEOPLE") {
    LocalStorage.removeItem(LocalStorage.keys.TALENT_LISTING_FILTERS);
  } else {
    LocalStorage.removeItem(LocalStorage.keys.JOBS_SEARCH_FILTERS);
    LocalStorage.removeItem(LocalStorage.keys.JOB_LISTING_FILTERS);
  }
};

export const getResultsLogEvent = (searchTab: UniversalTabValue) => {
  switch (searchTab) {
    case "JOBS":
      return jobViewAllPopoverClicked;
    case "PEOPLE":
      return talentViewAllPopoverClicked;
    case "CAREER_HELP":
      return postViewAllPopoverClicked;
    default:
      assertUnreachable(searchTab);
  }
};
