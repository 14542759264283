import { useMemo } from "react";

import { useUser } from "@js/apps/common/hooks";
import {
  findCurrentNavItem,
  getNavMenuColorVariant,
} from "@js/layouts/app/utils/common-navigation";
import { getEmployerNavItems } from "@js/layouts/app/utils/employer-navigation";

export const useEmployerSidebar = () => {
  const user = useUser();
  const canViewInvoices = !!user?.can_view_employer_invoices;

  const employerNavItems = useMemo(
    () => getEmployerNavItems({ canViewInvoices }),
    [canViewInvoices],
  );
  const currentLinkItem = useMemo(
    () =>
      findCurrentNavItem({
        items: employerNavItems,
        exact: true,
      }),
    [employerNavItems],
  );
  const currentLinkVariant = getNavMenuColorVariant(currentLinkItem);

  return {
    sidebarItems: employerNavItems,
    itemVariant: currentLinkVariant,
  };
};
