import type { TrackableUserAction } from "@js/middlewares/analytics/types";
import { Events } from "@js/services/analytics";
import { logEvent } from "@js/services/analytics/event-logging";

export const handleATSAnalyticsActions = (action: TrackableUserAction) => {
  switch (action.type) {
    case Events.CLICK_SYNC_JOBS_ATS_HOME_DASHBOARD: {
      logEvent(Events.CLICK_SYNC_JOBS_ATS_HOME_DASHBOARD);
      break;
    }

    case Events.CLICK_NAV_ATS_INTEGRATION: {
      logEvent(Events.CLICK_NAV_ATS_INTEGRATION);
      break;
    }

    case Events.CLICK_ADD_INTEGRATION_ATS: {
      logEvent(Events.CLICK_ADD_INTEGRATION_ATS);
      break;
    }

    case Events.CLICK_RESYNC_ATS: {
      logEvent(Events.CLICK_RESYNC_ATS);
      break;
    }

    case Events.CLICK_DISCONNECT_ATS: {
      logEvent(Events.CLICK_DISCONNECT_ATS);
      break;
    }

    case Events.CLICK_SELECT_JOBS_ATS: {
      logEvent(Events.CLICK_SELECT_JOBS_ATS);
      break;
    }

    case Events.CLICK_MOVE_TO_DRAFTS_ATS: {
      logEvent(Events.CLICK_MOVE_TO_DRAFTS_ATS);
      break;
    }

    case Events.CLICK_PUBLISH_JOBS_ATS: {
      logEvent(Events.CLICK_PUBLISH_JOBS_ATS);
      break;
    }

    case Events.CLICK_WELCOME_MODAL_CLOSE: {
      logEvent(Events.CLICK_WELCOME_MODAL_CLOSE);
      break;
    }

    case Events.CLICK_WELCOME_MODAL_LEARN_MORE: {
      logEvent(Events.CLICK_WELCOME_MODAL_LEARN_MORE);
      break;
    }

    case Events.CLICK_WELCOME_MODAL_POST_JOB: {
      logEvent(Events.CLICK_WELCOME_MODAL_POST_JOB);
      break;
    }

    default: {
      break;
    }
  }
};
