import type { ReactNode } from "react";
import React, { useContext, useMemo } from "react";

import type { Job } from "@js/types/jobs";

const CoreTeamActionBarContext = React.createContext<{
  job: Job | null;
}>({
  job: null,
});

type CoreTeamActionBarProviderProps = {
  job: Job;
  children: ReactNode;
};
export const CoreTeamActionBarProvider = ({
  job,
  children,
}: CoreTeamActionBarProviderProps) => {
  const value = useMemo(
    () => ({
      job,
    }),
    [job],
  );

  return (
    <CoreTeamActionBarContext.Provider value={value}>
      {children}
    </CoreTeamActionBarContext.Provider>
  );
};

export const useCoreTeamActionBarContext = () => {
  const value = useContext(CoreTeamActionBarContext);

  if (!value) {
    throw new Error("There is no CoreTeamActionBarProvider");
  }

  return value;
};
