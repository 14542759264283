import { useMemo } from "react";

import { useUser } from "@js/apps/common/hooks";
import { openAddServices } from "@js/apps/freelancer/components/ggh-services/modals/add-ggh-services";
import { useClearAddServicesForm } from "@js/apps/freelancer/forms/add-ggh-services-form";
import { useGetHelpServicesQuery } from "@js/apps/give-and-get-help/api";
import type { User } from "@js/types/auth";
import type {
  HelpService,
  IPost,
  PostCategory,
  PostComment,
} from "@js/types/give-and-get-help";

export type UseHelpServiceTipArg = {
  postCategory: PostCategory;
  postFreelancer: IPost["freelancer"];
  postBudget: IPost["budget"];
  comment: PostComment;
};

export const useHelpServiceTip = ({
  postCategory,
  postBudget,
  postFreelancer,
  comment,
}: UseHelpServiceTipArg) => {
  const categoryId = postCategory.id;
  const user = useUser();
  const freelancerId = user?.freelancer;
  const shouldSkip = getShouldSkip({
    user,
    comment,
    postFreelancer,
    postBudget,
  });

  const { data: helpServices } = useGetHelpServicesQuery(
    { freelancerId: freelancerId as number },
    { skip: shouldSkip },
  );
  const { destroyForm } = useClearAddServicesForm();

  const shouldHideTooltip = useMemo(
    () => getShouldHideTooltip({ helpServices, categoryId }),
    [helpServices, categoryId],
  );

  if (shouldHideTooltip || !freelancerId) {
    return hiddenTooltip;
  }

  return {
    showTip: true,
    openServices: () =>
      openAddServices({
        onClose: destroyForm,
        freelancerId,
        initialStep: "CATEGORY_FORM_MODAL_OPENED",
        preselectedValues: {
          category: categoryId,
        },
      }),
  };
};

const hiddenTooltip = { showTip: false, openServices: () => {} };

const getShouldSkip = ({
  user,
  comment,
  postBudget,
  postFreelancer,
}: Omit<UseHelpServiceTipArg, "postCategory"> & {
  user: User | null;
}) => {
  if (!user) {
    return true;
  }

  const freelancerId = user.freelancer;
  if (!freelancerId) {
    return true;
  }

  const isOwnComment = freelancerId === comment.freelancer.id;
  if (!isOwnComment) {
    return true;
  }

  const isOwnPost = freelancerId === postFreelancer.id;
  if (isOwnPost) {
    return true;
  }

  const postAskingForHelp = !!postBudget;
  if (!postAskingForHelp) {
    return true;
  }

  const commentHasHelpOffer = !!comment.help_offer;
  if (commentHasHelpOffer) {
    return true;
  }

  return false;
};

const getShouldHideTooltip = ({
  helpServices,
  categoryId,
}: {
  helpServices: HelpService[] | undefined;
  categoryId: number;
}) => {
  if (!helpServices) {
    return true;
  }

  return helpServices.some(
    (helpService) => helpService.category.id === categoryId,
  );
};
