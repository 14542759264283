import type { TypedWrappedFieldProps } from "redux-form";

import { Stack } from "@hexocean/braintrust-ui-components";
import { FilePreview } from "@js/apps/give-and-get-help/components/attachment-preview/file";
import { ImagePreview } from "@js/apps/give-and-get-help/components/attachment-preview/image";
import { PreviewLoadingFiles } from "@js/apps/give-and-get-help/components/file-preview";
import { AttachmentActionsWrapper } from "@js/apps/give-and-get-help/components/post-attachments/attachment-actions-wrapper";
import type { MessageFileAttachmentData } from "@js/types/messenger";

type TextboxAttachmentsPreviewProps = TypedWrappedFieldProps<
  MessageFileAttachmentData[] | ""
>;

export const TextboxAttachmentsPreview = ({
  input: { value, onChange },
}: TextboxAttachmentsPreviewProps) => {
  const removeAttachment = (fileId: string) => {
    if (!value) {
      return;
    }

    onChange(value.filter((attachment) => attachment.fileId !== fileId));
  };

  return (
    <MessageAttachmentsPreview
      messageAttachments={value || []}
      removeAttachment={removeAttachment}
    />
  );
};

export type MessageAttachmentsPreviewProps = {
  messageAttachments: MessageFileAttachmentData[] | null;
  removeAttachment: (fileId: string) => void;
};

export const MessageAttachmentsPreview = ({
  messageAttachments,
  removeAttachment,
}: MessageAttachmentsPreviewProps) => {
  const showAttachments = !!messageAttachments?.length;

  if (!showAttachments) {
    return <></>;
  }

  return (
    <Stack
      component="ul"
      direction="row"
      sx={{ gap: 1, flexWrap: "wrap", listStyle: "none", m: 0, p: 0 }}
    >
      {messageAttachments.map((messageAttachment) => {
        return (
          <li key={messageAttachment.fileId}>
            <AttachmentActionsWrapper
              onRemoveClick={() => removeAttachment(messageAttachment.fileId)}
            >
              <MessageFileAttachmentPreview
                key={messageAttachment.id}
                data={messageAttachment}
              />
            </AttachmentActionsWrapper>
          </li>
        );
      })}
    </Stack>
  );
};

const MessageFileAttachmentPreview = ({
  data,
}: {
  data: MessageFileAttachmentData;
}) => {
  const { isImage, isLoading, name, attachment } = data;

  if (isLoading || !attachment) {
    return <PreviewLoadingFiles isImage={isImage} fileName={name} />;
  }

  const { file, file_size, mime } = attachment;
  const isMimeImage = Boolean(mime?.startsWith("image"));
  if (isMimeImage && file) {
    return (
      <ImagePreview title={name} src={file} showThumbnail size={file_size} />
    );
  }

  return <FilePreview name={name} fileLink={file} size={file_size} />;
};
