import type Decimal from "decimal.js";

import type { FreelancerBid } from "@js/types/jobs";

const formatCurrencyDefaultOptions = {
  locale: "en-US",
  currency: SETTINGS.DEFAULT_CURRENCY,
  removeDecimal: false,
};

type FormatCurrencyOptions = {
  removeTrailingZeros?: boolean;
  removeDecimal?: boolean;
  locale?: Intl.LocalesArgument;
  currency?: Intl.NumberFormatOptions["currency"];
  disableGrouping?: boolean;
};

export const formatCurrency = (
  value: string | number | Decimal,
  options: FormatCurrencyOptions = {},
) => {
  const _options = { ...formatCurrencyDefaultOptions, ...options };

  const fractionDigits = getFractionDigits({
    removeDecimal: _options.removeDecimal,
    removeTrailingZeros: _options.removeTrailingZeros,
  });

  return Number(value).toLocaleString(_options.locale, {
    style: "currency",
    currency: _options.currency,
    minimumFractionDigits: fractionDigits.minimumFractionDigits,
    maximumFractionDigits: fractionDigits.maximumFractionDigits,
    useGrouping: !options.disableGrouping,
  });
};

const getFractionDigits = ({
  removeDecimal,
  removeTrailingZeros,
}: Pick<FormatCurrencyOptions, "removeDecimal" | "removeTrailingZeros">) => {
  if (removeDecimal) {
    return { minimumFractionDigits: 0, maximumFractionDigits: 0 };
  }

  if (removeTrailingZeros) {
    return { minimumFractionDigits: 0, maximumFractionDigits: undefined };
  }

  return {
    minimumFractionDigits: 2,
    maximumFractionDigits: undefined,
  };
};

type FormatBudgetSuffixOptions = {
  paymentType?: EnumType<typeof ENUMS.JobPaymentType>;
  hourlyRateSuffix?: string;
};

type FormatBudgetOptions = FormatCurrencyOptions & FormatBudgetSuffixOptions;

export const formatBudget = (
  min?: number | string,
  max?: number | string,
  options: FormatBudgetOptions = {},
) => {
  const { paymentType, hourlyRateSuffix, ...currencyOptions } = options;

  let content = "";

  if (!min && !max) {
    return "";
  } else if (min && !max) {
    content = `${formatCurrency(min, currencyOptions)}`;
  } else if (!min && max) {
    content = `${formatCurrency(max, currencyOptions)}`;
  } else if (min && max) {
    if (min === max) {
      content = `${formatCurrency(max, currencyOptions)}`;
    } else {
      content = `${formatCurrency(min, currencyOptions)} – ${formatCurrency(
        max,
        currencyOptions,
      )}`;
    }
  }

  if (paymentType && paymentType === ENUMS.JobPaymentType.HOURLY) {
    content += hourlyRateSuffix ?? "/hour";
  }

  if (paymentType && paymentType === ENUMS.JobPaymentType.ANNUAL) {
    content += "/yr";
  }

  return content;
};

export const formatAbbreviatedBudgetWithPrefix = (
  min?: number | string,
  max?: number | string,
  options: FormatBudgetSuffixOptions = {},
) => {
  const { paymentType, hourlyRateSuffix } = options;

  let content = "";

  if (!min && !max) {
    return "";
  } else if (min && !max) {
    content = `$${abbreviateNumber(min)}`;
  } else if (!min && max) {
    content = `$${abbreviateNumber(max)}`;
  } else if (min && max) {
    if (min === max) {
      content = `$${abbreviateNumber(max)}`;
    } else {
      content = `$${abbreviateNumber(min)} – ${abbreviateNumber(max)}`;
    }
  }

  if (paymentType && paymentType === ENUMS.JobPaymentType.HOURLY) {
    content += hourlyRateSuffix ?? "/hour";
  }

  if (paymentType && paymentType === ENUMS.JobPaymentType.ANNUAL) {
    content += "/yr";
  }

  return content;
};

export const abbreviateNumber = (num: number | string) => {
  const parsedNumber = Number(num);

  if (parsedNumber >= 1000000000) {
    return (
      Math.floor(parsedNumber / 1000000000)
        .toString()
        .replace(/\.0$/, "") + "G"
    );
  }
  if (parsedNumber >= 1000000) {
    return (
      Math.floor(parsedNumber / 1000000)
        .toString()
        .replace(/\.0$/, "") + "M"
    );
  }
  if (parsedNumber >= 1000) {
    return (
      Math.floor(parsedNumber / 1000)
        .toString()
        .replace(/\.0$/, "") + "k"
    );
  }
  return String(Math.floor(parsedNumber));
};

export const getBidAmountWithSuffix = (
  bid: Pick<FreelancerBid, "payment_amount" | "payment_type">,
) => {
  return getPaymentAmountWithSuffix(bid.payment_amount, bid.payment_type);
};

export const getPaymentAmountWithSuffix = (
  amount: FreelancerBid["payment_amount"],
  paymentType?: FreelancerBid["payment_type"],
  options?: { amountOptions: FormatCurrencyOptions },
) => {
  const suffix =
    paymentType === ENUMS.JobPaymentType.HOURLY
      ? "/hr"
      : paymentType === ENUMS.JobPaymentType.ANNUAL
        ? "/yr"
        : "";

  return formatCurrency(amount, options?.amountOptions) + suffix;
};

export const formatAmountString = (amountStr: string) => {
  const amount = Number(amountStr);
  return amount % 1 === 0 ? String(amount) : amountStr;
};
