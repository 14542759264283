import type { ReactNode } from "react";
import { useEffect, useRef } from "react";
import cs from "classnames";
import { Reorder, useDragControls } from "framer-motion";

import { ReorderIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./style.module.scss";

type SortableListProps<T> = {
  items: T[];
  children: (item: T, index: number) => React.ReactNode;
  onReorder: (items: T[]) => void;
} & Omit<
  React.ComponentProps<typeof Reorder.Group>,
  "values" | "children" | "onReorder"
>;

export const SortableList = <T extends { id: number | string }>({
  items,
  onReorder,
  children,
  ...props
}: SortableListProps<T>) => {
  return (
    <Reorder.Group
      axis="y"
      as="div"
      values={items}
      onReorder={onReorder}
      {...props}
    >
      {items.map((item, index) => {
        return (
          <Item key={item.id} item={item}>
            {children(item, index)}
          </Item>
        );
      })}
    </Reorder.Group>
  );
};

const Item = ({ children, item }: { children: ReactNode; item: unknown }) => {
  const reorderItemRef = useRef<SVGSVGElement | null>(null);
  const controls = useDragControls();

  // Note: Fix for touchable devices.
  useEffect(() => {
    const touchHandler = (e: TouchEvent) => {
      e.preventDefault();
    };

    const reorderItem = reorderItemRef.current;

    if (reorderItem) {
      reorderItem.addEventListener("touchstart", touchHandler, {
        passive: false,
      });

      return () => {
        reorderItem.removeEventListener("touchstart", touchHandler);
      };
    }
    return;
  }, [reorderItemRef]);

  return (
    <Reorder.Item
      value={item}
      dragListener={false}
      dragControls={controls}
      as="div"
      className={cs(styles.motionSortableListItem, "motion-sortable-list-item")}
    >
      <ReorderIcon
        ref={reorderItemRef}
        className={styles.motionSortableListItemControl}
        onPointerDown={(e) => controls.start(e)}
      />
      {children}
    </Reorder.Item>
  );
};
