import React from "react";

import {
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  ArrowRightIcon,
  CloseIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import styles from "./styles.module.scss";

type SignupBannerProps = {
  onSignUpClick: () => void;
  onCloseClick: () => void;
};

export const SignupBanner = ({
  onSignUpClick,
  onCloseClick,
}: SignupBannerProps) => {
  const isMobile = useMediaQuery("sm");
  const ctaCopy = isMobile
    ? "Join the conversation."
    : "This is only a preview. Join the conversation by creating an account.";

  return (
    <aside className={styles.publicPostSignupBanner}>
      <div className={styles.publicPostSignupBannerCopy}>
        <div className={styles.publicPostSignupBannerHello}>
          <Typography component="span" variant="paragraph" size="small">
            👋
          </Typography>
        </div>
        <Typography component="span" variant="paragraph" size="small">
          {ctaCopy}
        </Typography>{" "}
        <Button
          variant="transparent"
          className={styles.publicPostSignupBannerCta}
          endIcon={<ArrowRightIcon />}
          onClick={onSignUpClick}
          size="x-small"
        >
          Sign up
        </Button>
      </div>
      <IconButton
        variant="transparent"
        size="small"
        title="close button"
        aria-label="close banner"
        onClick={onCloseClick}
      >
        <CloseIcon />
      </IconButton>
    </aside>
  );
};
