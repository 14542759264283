import type { AppDispatch } from "@js/store";
import type { IPost } from "@js/types/give-and-get-help";

import { actionTypes } from "../give-and-get-help/action-types";
import type { PostLocationType } from "../give-and-get-help/types";

export const SHOW_BOOKMARKS_INTRO = "SHOW_BOOKMARKS_INTRO";
export const HIDE_BOOKMARKS_INTRO = "HIDE_BOOKMARKS_INTRO";
export const ADD_BOOKMARK = "ADD_BOOKMARK";
export const JOB_HAS_BEEN_SAVED_AS_FAVORITE = "JOB_HAS_BEEN_SAVED_AS_FAVORITE";

export const showBookmarksIntro = () => (dispatch: AppDispatch) => {
  dispatch({
    type: SHOW_BOOKMARKS_INTRO,
  });
};
export const addBookmark = () => (dispatch: AppDispatch) => {
  dispatch({
    type: ADD_BOOKMARK,
  });
};

export const hideBookmarksIntro = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: HIDE_BOOKMARKS_INTRO,
  });
};

type JobSavedAsFavorite = {
  savedJob: { id: string; job: string };
  appendFilters: boolean;
  search: string;
};

export const jobHasBeenSavedAsFavorite = ({
  savedJob,
  appendFilters,
  search,
}: JobSavedAsFavorite) => {
  return {
    type: JOB_HAS_BEEN_SAVED_AS_FAVORITE,
    payload: {
      savedJob,
      search,
      appendFilters,
    },
  };
};

type SavePostButtonClickedPayload = Partial<IPost> & {
  post_location: PostLocationType;
};

export const savePostButtonClicked = (
  payload: SavePostButtonClickedPayload,
) => {
  return {
    type: actionTypes["save-post-clicked"],
    payload,
  };
};
