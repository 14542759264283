import type { ReactNode } from "react";
import { useCallback, useRef } from "react";

import type { PopoverActions } from "@hexocean/braintrust-ui-components";
import { Box, Popover } from "@hexocean/braintrust-ui-components";

import styles from "./styles.module.scss";

type ProfileCardPopoverProps = {
  anchor: ReactNode;
  renderContent: (props: PopoverActions) => JSX.Element;
};

export const ProfileCardPopover = ({
  anchor,
  renderContent,
}: ProfileCardPopoverProps) => {
  const actionsRef = useRef<PopoverActions>(null);
  const handleUpdatePosition = useCallback(() => {
    actionsRef.current?.updatePosition();
  }, [actionsRef]);

  return (
    <Popover
      sx={{ pointerEvents: "none" }}
      action={actionsRef}
      slotProps={{
        paper: {
          sx: {
            borderRadius: "16px",
            minWidth: 0,
            minHeight: 0,
            mt: 1,
          },
        },
      }}
      disableAutoFocus
      disableScrollLock
      disableEnforceFocus
      openOnHover
      openOnHoverDelay={250}
      anchor={<Box>{anchor}</Box>}
    >
      <Box className={styles.contentWrapper}>
        {renderContent({ updatePosition: handleUpdatePosition })}
      </Box>
    </Popover>
  );
};
