import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";
import type { SpaceRule } from "@js/types/spaces";

import { RuleForm } from "./forms";

import style from "./style.module.scss";

type EditRuleProps = {
  rule: SpaceRule;
  ruleNumber: number;
  spaceId: number;
};

const EditRule = (props: EditRuleProps) => {
  return (
    <Box className={style.container}>
      <Typography component="p" variant="paragraph" size="large" mb={4}>
        Edit rule
      </Typography>

      <RuleForm mode="edit" {...props} />
    </Box>
  );
};

export type OpenEditRuleModalArg = {
  onClose: () => void;
} & EditRuleProps;

const openEditRuleModal = ({
  onClose,
  ...editRuleProps
}: OpenEditRuleModalArg) => {
  // close the sortable rule list modal
  ModalInstance.close();

  ModalInstance.open({
    className: style.ruleListModal,
    padding: false,
    closeButton: true,
    closeButtonProps: { variant: "tertiary", size: "x-small" },
    children: <EditRule {...editRuleProps} />,
    onClose: onClose,
  });
};

export { openEditRuleModal };
