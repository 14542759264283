import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { ClearOutlinedIcon } from "@hexocean/braintrust-ui-components/Icons";
import { ModalInstance } from "@js/components/modal";

import styles from "./styles.module.scss";
export const openCreateJobThrottlingModal = () => {
  ModalInstance.open({
    closeButton: true,
    className: styles["create-job-throttling-modal"],
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
    },
    children: (
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography
          component="label"
          variant="title"
          size="small"
          textAlign="start"
          fontWeight={400}
        >
          Job posting limit reached
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={0.5}
        >
          <ClearOutlinedIcon sx={{ width: 36, height: 36 }} color="error" />
          <Typography
            component="p"
            color="negative"
            variant="label"
            size="medium"
          >
            Maximum number of job posts reached
          </Typography>
        </Box>
        <Typography component="p">
          You've reached the maximum number of job postings allowed for this
          account. To continue posting more jobs, contact our support team.
        </Typography>
        <Box alignSelf="center">
          <Button
            href={SETTINGS.SUPPORT_URLS.CONTACT_SUPPORT_URL}
            variant="black-outlined"
            shape="squared"
          >
            Contact support
          </Button>
        </Box>
      </Box>
    ),
  });
};
