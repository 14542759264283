import { useEffect } from "react";

import { useUser } from "@js/apps/common/hooks";

import { useGetOrCreateMessageRoomMutation } from "../api";

type UseGetOrCreateRoomTypes = {
  receiverUserId?: number;
  senderUserId?: number;
  isHelpOfferRequest?: boolean;
  jobId?: number;
  contentType?: EnumType<typeof ENUMS.RoomTypeContentType>;
};

export const useGetOrCreateRoom = ({
  receiverUserId,
  senderUserId,
  jobId,
  contentType,
  isHelpOfferRequest,
}: UseGetOrCreateRoomTypes) => {
  const [
    getOrCreateMessageRoom,
    { isLoading: isGetOrCreateMessageRoomLoading, data: room, error },
  ] = useGetOrCreateMessageRoomMutation();
  const { id: userId } = useUser() || {};

  const senderId = senderUserId || userId;
  useEffect(() => {
    if (!senderId || !receiverUserId) return;
    getOrCreateMessageRoom({
      context: {
        participants: [senderId, receiverUserId],
        initiated_by_help_offer_request: isHelpOfferRequest ? true : undefined,
      },
      jobId,
      contentType,
    });
  }, [
    senderId,
    isHelpOfferRequest,
    receiverUserId,
    getOrCreateMessageRoom,
    jobId,
    contentType,
  ]);

  return {
    isLoading: isGetOrCreateMessageRoomLoading,
    room,
    error,
  };
};
