import _ from "underscore";

import type { FreelancerInvoice } from "@js/types/invoices";

export const getEditInvoiceInitialValues = (invoice: FreelancerInvoice) => {
  return {
    number: invoice.number,
    job: invoice.subject?.id,
    issued_at: invoice.issued_at,
    notes_to_receipt: invoice.notes_to_receipt,
    attachment_ids: (invoice.attachments || []).map((attach) => attach.id),
    new_items: (invoice.items || []).map((item) =>
      _.pick(
        item,
        "id",
        "date_from",
        "date_to",
        "description",
        "unit_price",
        "tax_rate",
        "tax_name",
        "quantity",
        "po_number",
      ),
    ),
  };
};
